import React from 'react';
import { useTranslation } from 'react-i18next';
import './Catalogs.scss';

type Props = {};

const Catalogs: React.FC<Props> = () => {
  const {t}= useTranslation();

  return (
    <div className="catalogs-container">
      {t('Catalogs')}
    </div>
  );
};

Catalogs.displayName = 'Catalogs';

export default Catalogs;
