import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import i18n from 'i18n';
import { Marketplaces } from './constants/applications';
import { getApi, getCurrentMarketplace, notify } from './marketplace';
import useModuleRequestStatistics from './hooks/statistics/moduleRequest';
import { isPublic } from './utils';

const rootAxios = axios.create({
  baseURL: getApi(),
  timeout: 5 * 60 * 1000,
});

const orgasysAxios = axios.create({
  baseURL: `${process.env.REACT_APP_URL_ORGASYS_RECETTE}/commercial`,
  timeout: 5 * 60 * 1000,
});

const rmiAxios = axios.create({
  baseURL: process.env.REACT_APP_RMI_SERVICES, // url = base url + request url
});

const HTTP_UNAUTHORIZED_STATUS_CODE = 401;
const HTTP_ACCESS_DENIED_STATUS_CODE = 403;
const HTTP_BAD_GATEWAY_CODE = 502;
let isExpired = false;

rootAxios.interceptors.response.use((response) => {
  if (!response.headers['x-version']) {
    return response;
  }

  if (response.headers['x-version'] && !localStorage.getItem('x-version')) {
    localStorage.setItem('x-version', response.headers['x-version']);
  }

  if (localStorage.getItem('x-version') && response.headers['x-version'] !== localStorage.getItem('x-version')) {
    localStorage.setItem('x-version', response.headers['x-version']);
    window.location.reload();
    window.location.replace(window.location.origin);
  }

  return response;
});

rootAxios.interceptors.response.use(
  (response: AxiosResponse): AxiosResponse => {
    return response;
  },
  (error) => {
    if (error.request && error.request.status == HTTP_ACCESS_DENIED_STATUS_CODE) {
      notify(i18n.t('PERMISSION_DENIED'));
    }

    if (error.request && error.request.status == HTTP_BAD_GATEWAY_CODE) {
      notify(i18n.t('_bad_gateway'));
    }

    if (
      error.request &&
      error.request.status == HTTP_UNAUTHORIZED_STATUS_CODE &&
      getCurrentMarketplace() !== Marketplaces.PUNCHOUT &&
      !isPublic() &&
      !isExpired
    ) {
      isExpired = true;
      localStorage.clear();

      const redirectedHref = window.location.href;

      notify(i18n.t('_unauthorized'));
      window.location.replace(`/login?redirectedHref=${redirectedHref}`);

      throw new axios.Cancel('requests canceled');
    }

    if (error.request && error.request.status == HTTP_UNAUTHORIZED_STATUS_CODE && getCurrentMarketplace() === Marketplaces.PUNCHOUT) {
      // localStorage.clear();
      if (localStorage.getItem('sessionExpired') == null) {
        window.location.replace('/session-expired');
        localStorage.setItem('sessionExpired', 'true');
      }


      throw new axios.Cancel('requests canceled');
    }

    return Promise.reject(error?.response?.data);
  },
);

rmiAxios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token-tec-rmi');

    if (token) {
      config.headers!['Authorization'] = token;
      config.headers!['Accept'] = 'application/json';
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);
rmiAxios.interceptors.request.use(
  (request) => {
    useModuleRequestStatistics(request, serverAxios);

    return request;
  },
  (error) => {
    return error;
  },
);

rmiAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const { data } = await serverAxios.get('/reliable/rmi-proxy/token', { params: { origin: window.location.origin } });

        localStorage.setItem('token-tec-rmi', data);
      } catch (e) {
        console.error(e);
      }

      return rmiAxios(originalRequest);
    }

    // if (!originalRequest?.url.includes('AdcTypeIdByTcdTypeAndClass')) {
    //
    //   notify(i18n.t('_internal_server_error'));
    // }

    return Promise.reject(error);
  },
);

rootAxios.interceptors.request.use((config: AxiosRequestConfig) => {
  const token = getCurrentMarketplace() === Marketplaces.PUNCHOUT ? sessionStorage.getItem('token') : localStorage.getItem('token');

  config.baseURL = getApi();

  if (token) {
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
  }

  return config;
});

rootAxios.interceptors.request.use(
  (request) => {
    if (localStorage.getItem('infoSelectClientErp')) {
      const info = JSON.parse(localStorage.getItem('infoSelectClientErp') as string);

      if (info != null) {
        if (
          request?.params?.is_platform_admin == true ||
          request.url!.includes('consumable/list') ||
          request.url!.includes('oil/list/platform_admin') ||
          request.url!.includes('battery/list_admin') ||
          request.url!.includes('catalog_non_tec_dock/parts') ||
          request.url!.includes('tyres/list/platform_admin')
        ) {
          if (request.params) {
            request.params = {
              ...request.params,
              erp_client_id: info.erpClientId,
            };
          } else {
            request.params = { erp_client_id: info.erpClientId };
          }
        }

        return request;
      }
    }

    return request;
  },
  (error) => {
    return error;
  },
);

export const commercialAxios = orgasysAxios;

export const serverAxios = rootAxios;

export const tecRMIAxios = rmiAxios;

export type IHttpResponse<P> = {
  data: P;
  status: number;
  type: string;
};

export type IDataPagination<P> = {
  page: number;
  per_page: number;
  total_count: number;
  total_pages: number;
  data: P[];
};

export type RequestParams = {
  params?: { [property: string]: string | number | undefined };
  search?: { [property: string]: string | number | undefined };
};

export function addRouteParam(path: string, params?: { [key: string]: number | string | undefined }): string {
  if (!params) {
    return path;
  }

  Object.keys(params).forEach((key) => {
    if (params[key]) {
      path = path.replace(`:${key}`, (params[key] as string).toString());
    }
  });

  return path;
}

export const searchParamsToObject = (): Object => {
  const pairs = window.location.search.substring(1).split('&'),
    obj = {};

  let pair, i;

  for (i in pairs) {
    if (pairs[i] === '') {
      continue;
    }

    pair = pairs[i].split('=');
    obj[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
  }

  return obj;
};

export const getQueryParamsEntryLikeObject = (urlSearchParams: URLSearchParams): { [key: string]: string } => {
  const obj = {};

  urlSearchParams.forEach((value, key) => {
    obj[key] = value;
  });

  return obj;
};
