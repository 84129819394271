import { IHttpResponse, serverAxios } from '../../http';
import { IFile, IImportFileNew } from '../../../interfaces/price';

class ProformaHttp {
  private static uploadProformaUrl = 'proforma-upload-csv/v2';
  private static sendproformaAndEstimationURL = 'proforma/v2';
  private static sendDataEmailEstimation = 'proforma-email-status';
  private static exportPart = 'export-csv/v2';
  public async uploadProforma(data: FormData): Promise<IHttpResponse<IUploadFileProformaResponse>> {
    return serverAxios.post(ProformaHttp.uploadProformaUrl, data, { headers: { 'content-type': 'multipart/form-data' } });
  }

  public async sendproformaAndEstimation(data: IUploadFileProformaResponse): Promise<IHttpResponse<IProformaResponse>> {
    return serverAxios.post(ProformaHttp.sendproformaAndEstimationURL, data);
  }

  public async sendDataEmailEstimation(data: IImportFileNew[]): Promise<IHttpResponse<null>> {
    return serverAxios.post(ProformaHttp.sendDataEmailEstimation, data);
  }

  public async exportPartNotFond(data: { rows: Array<IRowTable>; type: string }[]): Promise<IHttpResponse<Blob>> {
    return serverAxios.post(ProformaHttp.exportPart, data, { responseType: 'blob' });
  }
}

type IRowTable = {
  // total: { totalPrice: string, index: number},
  [key: string]: string | number | null;
};
type IRow = {
  data: Array<string | number>;
  info: string | null;
  section: string;
};

export type IUploadFileProformaResponse = {
  count: number;
  filename: string;
  header: Array<string>;
  rows: Array<IRow>;
  estimation: Array<IRow>;
  delivery_date?: string;
  partsWithReplacements?: IImportFileNew[];
  is_submit_quotation?: number;
};

export type IProformaResponse = {
  email: number;
  order: number;
  proforma: number;
  proformaId: string;
  estimationId: number;
  m3_order?: number;
  aol_order?: number;
  waiting_offer?: number;
  is_m3_connected?: boolean;
  status?: string;
  import_status?: string;
};

export type IProformaAmerigoRequest = {
  csv: IFile;
  supplier_mapping_dlnr: string;
};

export interface IProformaAmerigoResponse {
  count: number;
  header: string[];
  rows: IProformaAmerigoRow;
}

export enum rowsEnum {
  AVAILABLE = 'AVAILABLE',
  REFERENCE_NOT_FOUND = 'REFERENCE_NOT_FOUND',
  AVAILABILITY_NOT_FOUND = 'AVAILABILITY_NOT_FOUND',
}

export type IProformaAmerigoRow = {
  [key in rowsEnum]: IProformaAmerigoRowItem[];
};

export interface IProformaAmerigoRowItem {
  index: string;
  reference: string;
  quantity: number;
  available: number;
  price: number;
}

export default ProformaHttp;
