import React, { PropsWithChildren, useState } from 'react';
import { faChevronLeft, faChevronRight, faComment, faWarning } from '@fortawesome/free-solid-svg-icons';
import DefaultButton from '../../../../../components/buttons/DefaultButton/DefaultButton';
import { IEspaceTabItem } from '../../../views/espace/Espace.model';
import LogoItem from '../../../../../components/Item/LogoItem/LogoItem';
import { Trans } from 'react-i18next';
import IconNotification from 'components/notification/IconNotification/IconNotification';
import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';

import './EspaceTab.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Spinner from 'components/spinner/Spinner';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';

type Props = {
  type: string | JSX.Element;
  onClick: () => void;
  description: string;
  buttonText: string;
  isLoading?: boolean;
  buttonLink: string;
  items: IEspaceTabItem[][];
  itemsMobile: IEspaceTabItem[];
  disableCatalog?: boolean;
  enabled?: boolean;
  messagesNumber: number;
  warningsNumber: number;
  from?: string;
};

type ISwiper = { slidePrev: () => void; slideNext: () => void };

const EspaceTabView: React.FC<Props> = (props: PropsWithChildren<Props>) => {
  const navigate = useNavigate();
  const { isMobile } = useDeviceDetect();
  const [swiper, setSwiper] = useState<ISwiper | null>(null);

  const handleNextClick = () => {
    swiper?.slideNext();
  };

  const handlePrevClick = () => {
    swiper?.slidePrev();
  };

  const handleItemClick = (item: IEspaceTabItem) => {
    if (!props.disableCatalog) {
      if (props.from == 'clients') {
        { navigate(`/espace-clients/my-clients/${item.id}`); }
      } else {
        { navigate(`/catalog/${item.id}`); }
      }

    }
  };


  return (
    <div className={`espace-tab-container ${props.enabled === false ? 'tab-disabled' : ''}`}>
      <div className="notifications">
        <div className="notification-item" onClick={() => props.from == 'clients' ? navigate('/espace-clients/messages') : navigate('messages')}>
          <IconNotification icon={faComment} number={props.messagesNumber} onClick={() => null} />
        </div>
        <div className="notification-item">
          <IconNotification icon={faWarning} number={props.warningsNumber} onClick={() => null} />
        </div>
      </div>
      <div className="title">
        <Trans
          i18nKey={props.type.toString()}
          components={{ b: <b /> }}
        />
      </div>
      <div className="subtitle">
        <span className="text-ellipsis">{props.description}</span>
      </div>
      <div className="button">
        <DefaultButton text={props.buttonText} onClick={() => navigate(props.buttonLink)} />
      </div>
      {
        props.isLoading ?
          <div className="loading">
            <Spinner class="extra-small"></Spinner>
          </div>
          :
          props.items.length ?
            <div className="items">
              <FontAwesomeIcon className="navigation" icon={faChevronLeft} onClick={handlePrevClick} />

              <Swiper
                slidesPerView={isMobile ? 2 : 'auto'}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay]}
                className="mySwiper"
                onSwiper={(s) => {
                  setSwiper(s as unknown as ISwiper);
                }}
              >
                {props.isLoading ? (
                  <SwiperSlide className="swiper-slide">
                    <Spinner class="extra-small"></Spinner>
                  </SwiperSlide>
                ) : (
                  isMobile ? (
                    props.itemsMobile.map((item: IEspaceTabItem, index: number) => {
                      return (
                        <SwiperSlide className="swiper-slide" key={index}>
                          <LogoItem image={item.logo} onClick={() => handleItemClick(item)} key={index} />
                        </SwiperSlide>
                      );
                    })
                  ) : (
                    props.items.map((item: IEspaceTabItem[], index: number) => {
                      return (
                        <SwiperSlide className="swiper-slide" key={index}>
                          {item.map((item: IEspaceTabItem, index: number) => {
                            return <LogoItem image={item.logo} onClick={() => handleItemClick(item)} key={index} />;
                          })}
                        </SwiperSlide>
                      );
                    })
                  )
                )}
              </Swiper>
              <FontAwesomeIcon className="navigation" icon={faChevronRight} onClick={handleNextClick} />
            </div>
            : null
      }
    </div>
  );
};

EspaceTabView.displayName = 'EspaceTabView';

export default EspaceTabView;
