import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getCurrentMarketplace } from 'utils/marketplace';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import './Disponibility.scss';

type Props = {
  value?: number;
  limit?;
  loading?: boolean;
  title?: string | boolean;
  price?;
};
const Disponibility: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();
  const [stockColor, setStockColor] = useState('');
  const currentMarketplace = getCurrentMarketplace();

  const userInfo = JSON.parse(localStorage.getItem('userData')!);

  function getPrice() {
    if (props.price?.price) {
      return props.price.price;
    }

    return props.price;
  }

  function calcLimit() {
    if (props.limit?.quantity) {
      return props.limit.quantity;
    }

    if (props.limit && typeof props.limit === 'object') {
      const newLimit: { [key: string]: { quantity } } = { ...props.limit };

      delete newLimit.uv;

      return Object.values(newLimit).reduce((acc, item) => +acc! + Number(item?.quantity), 0);
    }

    return props.limit;
  }

  useEffect(() => {
    if (props.limit && typeof props.limit.warehouse_main === 'object') {
      const warehouses = { ...props.limit };

      let totalQuantity = 0;

      Object.keys(warehouses).forEach((key) => {
        if (key !== 'uv' && warehouses.hasOwnProperty!(key)) {
          totalQuantity += Number(warehouses[key].quantity);
        }
      });

      if (!getPrice()) {
        setStockColor('');
      }

      if (props.value! > 0 && props.value! <= Number(warehouses.warehouse_main?.quantity)) {
        setStockColor('green');
      } else if (props.value! > Number(warehouses.warehouse_main?.quantity) && props.value! <= totalQuantity) {
        setStockColor('blue');
      } else {
        setStockColor('');
      }
    }

    if (props.limit?.quantity) {
      if (props.limit?.quantity >= props.value!) {
        setStockColor('green');
      } else {
        setStockColor('');
      }
    }

    if (typeof props.limit === 'number') {
      if (props.limit && props.limit >= props.value!) {
        setStockColor('green');
      } else {
        setStockColor('');
      }
    }
  }, [props.limit, props.value]);

  return (
    <div className="quantity-disponibility">
      <div className="quantity-availability-stok-title">
        {props.title ? (
          props.title
        ) : (
          <React.Fragment>
            {currentMarketplace == process.env.REACT_APP_MARKETPLACE_RETAILER ||
            currentMarketplace == process.env.REACT_APP_MARKETPLACE_PUNCHOUT ? null : (
                <span className="disponibility">
                  <span>{t('_dispo')}:</span>
                </span>
              )}
            <div
              className={`disponible-title ${!calcLimit() && Number(calcLimit()) <= (props.value! && props?.value) ? 'unavailable' : ''}`}
            >
              {currentMarketplace == process.env.REACT_APP_MARKETPLACE_RETAILER ||
              currentMarketplace == process.env.REACT_APP_MARKETPLACE_PUNCHOUT ? (
                  calcLimit() && Number(calcLimit()) >= (props.value! && props?.value) && getPrice() ? (
                    <span className="stock-availability">{t('_disponible')}</span>
                  ) : (
                    <div className="contact_number">
                      <span className="stock-unavailability title">{t('_non_disponible')}</span>
                      {userInfo?.phone ? (
                        <span className="stock-unavailability">
                          {t('_contact_number')}
                          <div>
                            <a href={`tel:${userInfo?.distributor_info?.main_address?.tel}`}>
                              {' '}
                              <FontAwesomeIcon icon={faPhone} />
                              {userInfo?.distributor_info?.main_address?.tel}
                            </a>
                          </div>
                        </span>
                      ) : (
                        ''
                      )}
                    </div>
                  )
                ) : null}
            </div>
          </React.Fragment>
        )}
      </div>
      <div className="quantity-availability-stok">
        <span className={`quantity-stock-availability ${stockColor}`}></span>
      </div>
    </div>
  );
};

Disponibility.displayName = 'Disponibility';

export default Disponibility;
