import React, { useEffect, useRef, useState } from 'react';
import DefaultIcon from 'components/buttons/DefaultIcon/DefaultIcon';
import './NavbarGroupBtns.scss';
import { faUpload, faComment, faPlus, faGear, faXmark, faUserTie } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { setProformaActive } from 'store/slices/proforma.slice';
import { setNewClientModal } from 'store/slices/clients.slice';

import { RootState } from 'store/store';
import { useOnClickOutside } from 'utils/hooks/useOnClickOutside';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import useTimer from 'utils/hooks/useTimer';
import useCountNewMessages from 'utils/hooks/useCountNewMessages';
import { getCurrentMarketplace } from 'utils/marketplace';
import { Marketplaces } from 'utils/constants/applications';
import SelectClientErpId from 'applications/automotor/components/espace/selectClientErpId/SelectClientErpId';
import useHttp from 'utils/hooks/useHttp';


type Props = {};

const ClientFloatableButtons: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: catalogId } = useParams();
  const { isMobile } = useDeviceDetect();
  const [buttonsAreaExpanded, setButtonsAreaExpanded] = useState<boolean>(false);

  useTimer();
  useCountNewMessages();
  const clickRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const { newMessagesNumber } = useSelector((state: RootState) => state.messenger);
  const totalNotificationsNumber = newMessagesNumber + 0;
  const [openModalClientErp, setOpenModalClientErp] = useState<boolean>(false);

  const [clients, setClients] = useState<{ label: string; value: string; erpClientId: string; }[]>([]);

  const http = useHttp();

  useOnClickOutside(clickRef, () => {
    setButtonsAreaExpanded(false);
  });


  const changeAreaExpanded = () => {
    setButtonsAreaExpanded((prevState) => !prevState);
  };

  const goToCatalogMessages = () => {
    // if (catalogId) {
    //   navigate(`catalog/${catalogId}/messages`);
    // } else {
    //   navigate('espace-clients/messages');
    // }
    navigate('espace-clients/messages');
  };

  const { t } = useTranslation();

  const handleProformaUpload = () => {
    dispatch(setProformaActive(true));
  };

  const handleNewClient = () => {
    dispatch(setNewClientModal(true));
  };

  useEffect(() => {
    http.clients.listClientErpId().then((res) => {

      setClients(
        res.data.map((item) => ({
          label: item.name ? item.name : 'No Name' + (item.erpClientId ? ' ( Erp Id : ' + item.erpClientId + ')' : ''),
          value: String(item.id),
          erpClientId: item.erpClientId
        }))
      );

    });
  }, []);

  return (
    isMobile ? (
      <div className="floatable-buttons-wrapper" ref={clickRef}>
        <DefaultIcon onClick={changeAreaExpanded} number={buttonsAreaExpanded ? 0 : totalNotificationsNumber} icon={buttonsAreaExpanded ? faXmark : faGear} />
        <div className={`buttons ${buttonsAreaExpanded ? 'show' : 'hidden'}`}>
          {getCurrentMarketplace() !== Marketplaces.AUTOMOTOR && (
            <DefaultIcon onClick={handleProformaUpload} icon={faUpload} />
          )}
          <DefaultIcon onClick={goToCatalogMessages} number={newMessagesNumber} icon={faComment} />
          <DefaultIcon onClick={() => setOpenModalClientErp(true)} icon={faUserTie} />
          <DefaultIcon onClick={handleNewClient} icon={faPlus} />
          <SelectClientErpId openModalClientErp={openModalClientErp} setOpenModalClientErp={setOpenModalClientErp} clients={clients} />
        </div>
      </div>
    ) :
      (
        <React.Fragment>
          {getCurrentMarketplace() !== Marketplaces.AUTOMOTOR && (
            <DefaultIcon onClick={handleProformaUpload} icon={faUpload} text={t('_import_csv')} />
          )}
          <DefaultIcon onClick={goToCatalogMessages} icon={faComment} number={newMessagesNumber} text={t('_messages')} />
          <DefaultIcon onClick={handleNewClient} icon={faPlus} text={t('_new_client')} />
          <DefaultIcon onClick={() => setOpenModalClientErp(true)} icon={faUserTie} text={t('_select_client')} />
          <SelectClientErpId openModalClientErp={openModalClientErp} setOpenModalClientErp={setOpenModalClientErp} clients={clients} />
        </React.Fragment>)

  );
};

ClientFloatableButtons.displayName = 'ClientFloatableButtons';

export default ClientFloatableButtons;
