import React from 'react';
import MainNewsView from './MainNews.view';

type Props = {
  data?
};

const MainNews: React.FC<Props> = (props) => {
  return <MainNewsView data={props.data} />;
};

MainNews.displayName = 'MainNews';

export default MainNews;
