
import TextInput from 'components/inputs/TextInput/TextInput';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useHttp from 'utils/hooks/useHttp';
import './BrandCreate.scss';

import Spinner from 'components/spinner/Spinner';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { notify } from 'utils/marketplace';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { IBrandValidate } from 'interfaces/batteryBrand';
import UploadImage from 'components/UploadImage/UploadImage';

type Props = {
  setOpenModalCreate: (e: boolean) => void;
  setSubmit: (e: boolean) => void;
};


const BrandCreate: React.FC<Props> = (props) => {

  const { t } = useTranslation();
  const http = useHttp();

  const [loading, setLoading] = useState<boolean>(false);


  const validate = Yup.object().shape({
    name: Yup.string().trim().required(t('_fild_required')),
    erp_brand_id: Yup.string().trim().required(t('_fild_required')),
    image: Yup.string().required(t('_fild_required')),

  });

  const { getValues, register, handleSubmit, clearErrors, setValue, formState: { errors }, } = useForm<IBrandValidate>({ resolver: yupResolver(validate) });


  const onSubmit = async () => {


    setLoading(true);
    const values = getValues();
    const formData: FormData = new FormData();

    formData.append('name', values.name.trim());
    formData.append('erp_brand_id', values.erp_brand_id.trim());
    formData.append('image', values?.image);
    formData.append('is_partner', String(values.is_partner));

    http.batteryBrandHttp.create(formData).then(() => {
      notify(t('_battery_brand_created'), 'success', t('_success'));
      props.setOpenModalCreate(false);
      props.setSubmit(true);
    }).catch((error) => {
      let erorrs = '';

      Object.entries(error).forEach(([key, value]) => {
        erorrs += `${t('_' + key)} : ${value} `;
      });
      notify(erorrs, 'error', t('_error'));
      setLoading(false);
    });

  };


  return (
    <div className="battery-brand-create-container" >
      <div className="close-modal" onClick={() => { props.setOpenModalCreate(false); }}><FontAwesomeIcon icon={faClose} /></div>

      <div className="title">
        {t('_battery_title_create')}

      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div  >


          <div className="row">
            <div className={`container ${errors.name?.type == 'required' ? 'error' : ''}`}>
              <label  >
                {t('_name')}
              </label>
              <TextInput {...register('name')} />
              <p className="error-message">{errors.name?.type == 'required' ? errors.name?.message : ''}</p>

            </div>
            <div className={`container ${errors.erp_brand_id?.type == 'required' ? 'error' : ''}`}>
              <label  >
                {t('_erp_brand_id')}
              </label>
              <TextInput {...register('erp_brand_id')} />
              <p className="error-message">{errors.erp_brand_id?.type == 'required' ? errors.erp_brand_id?.message : ''}</p>

            </div>
            <div className={`container ${errors.image?.type == 'required' ? 'error' : ''}`}>
              <UploadImage errors={errors} clearErrors={clearErrors} setValue={setValue} />

            </div>

          </div>
          <div className="row">
            <div className="container is-partner-checkbox-container">
              <label htmlFor="is_partner">{t('_partner_brand')}</label>
              <input type="checkbox" id="is_partner" {...register('is_partner')} />
            </div>
          </div>
        </div>
        <div className="btn-save">
          <button type="submit" className={loading ? 'disabled' : ''}> {loading ? <Spinner class="extra-small" /> : null} <div className="title-btn-save">{t('_create_article')}</div></button>
        </div>
      </form>


    </div>

  );

};

BrandCreate.displayName = 'BrandCreate';

export default BrandCreate; 