import React from 'react';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import CatalogOils from './CatalogOils';
import CatalogOilsMobile from './CatalogOilsMobile/CatalogOilsMobile';


const CatalogOilsView:React.FC = () => {
  const {isMobile} = useDeviceDetect();

  return (
    isMobile ? <CatalogOilsMobile /> : <CatalogOils/>
  );
};

export default CatalogOilsView;