import { IOil } from 'interfaces/oil';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './OilDetails.scss';

type Props = {
  oil: IOil;
}

const OilDetails: React.FC<Props> = (props) => {
  const oil = props.oil;
  const { t } = useTranslation();

  return (

    <div className="oil-details-wrapper">
      <div className="oil__header">
        <div className="oil__header__article">
          <span>{t('_reference')}</span>:
          {` ${oil.data_supplier_reference_original}`}
        </div>
        <h5 className="oil__header__title">{`${oil.data_supplier.name} ${oil.data_supplier_reference_original}`}</h5>
        <div className="oil__header__category">
          {`${oil.description ? oil.description : '-'}`}
        </div>
      </div>
      <div className="oil__content">
        {

          Object.entries(oil.tags).map((characteristic, index) => (
            characteristic[1] && characteristic[1] != ' ' ?
              <div className="content-item" key={index}>
                <p className="label">{t(`_oil_${characteristic[0]}`)}: </p>
                <p className="value">{`${characteristic[1]}`}</p>
              </div>
              : null
          ))
        }
      </div>
    </div >
  );
};

OilDetails.displayName = 'OilDetails';


export default OilDetails;