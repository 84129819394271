import React from 'react';
import { Route } from 'react-router-dom';
import Adjust from 'pages/CatalogVehicle/TechnicalDocumentation/components/Adjust/Adjust';
import Actia from 'pages/CatalogVehicle/TechnicalDocumentation/components/Actia/Actia';
import ComponentsPosition from 'pages/CatalogVehicle/TechnicalDocumentation/components/ComponentsPosition/ComponentsPosition';
import Consumables from 'pages/CatalogVehicle/TechnicalDocumentation/components/Consumables/Consumables';
import Diagnostic from 'pages/CatalogVehicle/TechnicalDocumentation/components/Diagnostic/Diagnostic';
import ErrorCodes from 'pages/CatalogVehicle/TechnicalDocumentation/components/ErrorCodes/ErrorCodes';
import Graphics from 'pages/CatalogVehicle/TechnicalDocumentation/components/Graphics/Graphics';
import RelaysFuses from 'pages/CatalogVehicle/TechnicalDocumentation/components/RelaysFuses/RelaysFuses';
import RepairItems from 'pages/CatalogVehicle/TechnicalDocumentation/components/RepairItems/RepairItems';
import ServiceNewsletter from 'pages/CatalogVehicle/TechnicalDocumentation/components/ServiceNewsletter/ServiceNewsletter';
import TechnicalManuals from 'pages/CatalogVehicle/TechnicalDocumentation/components/TechnicalManuals/TechnicalManuals';
import Wiring from 'pages/CatalogVehicle/TechnicalDocumentation/components/Wiring/Wiring';
import Tyres from '../components/Tyres/Tyres';
import MaintenanceLayout from 'applications/amerigo/layouts/maintenance-layout';
import ManufacturerInformation from '../components/Maintenance/Content/ManufacturerInformation/ManufacturerInformation';
import SuggestedMaintenance from '../components/Maintenance/Content/SuggestedMaintenance/SuggestedMaintenance';
import MaintenanceWorks from '../components/Maintenance/Content/MaintenanceWorks/MaintenanceWorks';
import ServicePlan from '../components/Maintenance/Content/ServicePlan/ServicePlan';
import TecrmiModulesLayout from 'applications/amerigo/layouts/tecrmi-modules-layout/tecrmi-modules-layout';
import ErrorCodesSidebarLayout from 'applications/public/layouts/CatalogVehicleLayout/ErrorCodesSidebarLayout/ErrorCodesSidebarLayout';
import TechnicalDocumentation from '../components/ErrorCodes/TechnicalDocumentation/TechnicalDocumentation';
import Solutions from '../components/ErrorCodes/Solutions/Solutions';
import Catalogs from '../components/ErrorCodes/Catalogs/Catalogs';

function useTecRMIModules(): JSX.Element {
  return (
    // <React.Fragment>
    <Route path="" element={<TecrmiModulesLayout />}>
      <Route path="adjust" element={<Adjust />} />
      <Route path="components-position" element={<ComponentsPosition />} />
      <Route path="consumables" element={<Consumables />} />
      <Route path="diagnostics" element={<Diagnostic />} />
      <Route path="error-codes" element={<ErrorCodesSidebarLayout />}>
        <Route path="manuals" element={<TechnicalDocumentation />} />
        <Route path="solutions" element={<Solutions />} />
        <Route path="catalogs" element={<Catalogs />} />
      </Route>
      <Route path="graphics" element={<Graphics />} />
      <Route path="maintenance" element={<MaintenanceLayout />}>
        <Route path="info" element={<ManufacturerInformation />} />
        <Route path="suggested" element={<SuggestedMaintenance />} />
        <Route path="repair" element={<MaintenanceWorks />} />
        <Route path="service-plan" element={<ServicePlan />} />
      </Route>
      <Route path="relays-fuses" element={<RelaysFuses />} />
      <Route path="repair-items" element={<RepairItems />} />
      <Route path="service-newsletter" element={<ServiceNewsletter />} />
      <Route path="technical-manuals" element={<TechnicalManuals />} />
      <Route path="tyres" element={<Tyres />} />
      <Route path="wiring" element={<Wiring />} />
    </Route>
    // </React.Fragment>
  );
}

export default useTecRMIModules;
