import React, { useEffect, useState } from 'react';

import './SelectClientErpId.scss';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import { useTranslation } from 'react-i18next';
import Modal from 'components/modal/Modal';
import { notify } from 'utils/marketplace';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { useSearchParams } from 'react-router-dom';

type Props = {
  openModalClientErp
  setOpenModalClientErp
  clients
};

const SelectClientErpId: React.FC<Props> = (props) => {
  const { isMobile, isTablet } = useDeviceDetect();
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();

  const [client, setClient] = useState<{ label: string; value: string; erpClientId: string; } | null>();

  const {
    getValues,
    handleSubmit,
    control,
  } = useForm();

  useEffect(() => {
    if (localStorage.getItem('infoSelectClientErp')) {
      const client = JSON.parse(localStorage.getItem('infoSelectClientErp') as string);

      setClient(client);
    }
  }, []);


  const onSubmit = () => {
    const values = getValues();

    if(!values?.client?.erpClientId){
      searchParams.delete('erp_client_id');
      setSearchParams(searchParams);
    } else {
      setSearchParams({erp_client_id: values?.client?.erpClientId, page: '1'});
    }
    
    if(localStorage.getItem('infoSelectClientErp')){
      localStorage.removeItem('infoSelectClientErp');
    }
   
    if(values.client){
      localStorage.setItem('infoSelectClientErp', JSON.stringify(values.client));
    }

    notify(t('_success'), 'success');
    props.setOpenModalClientErp(false);
  };

  return (

    <Modal
      openModal={props.openModalClientErp}
      setOpenModal={props.setOpenModalClientErp}
      childrenComponent={
        <div className="wrapper-select-client-erpid">
          <div className="modal-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="close-modal" onClick={() => { props.setOpenModalClientErp(false); }}><FontAwesomeIcon icon={faClose} /></div>
              <div className="title">
                <h3>{t('_select_client')}</h3>
              </div>
              <div className="input-container">
                <Controller
                  control={control}
                  name="client"
                  render={({ field: { onChange } }) => (
                    <Select
                      className="select-dropdown"
                      placeholder={t('_select_client')}
                      value={client}
                      onChange={(val) => {
                        onChange(val);
                        setClient(val);

                      }}
                      options={props.clients}
                      isSearchable={!isMobile && !isTablet}
                      isClearable
                    />
                  )}
                />
              </div>

              <div className="btn-save">
                <DefaultButton text={t('_save')} type="submit" />
              </div>

            </form>
          </div>
        </div>

      }
    />


  );
};

SelectClientErpId.displayName = 'SelectClientErpId';

export default SelectClientErpId;
