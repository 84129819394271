import React from 'react';
import './EspaceClient.scss';
import SearchReference from '../../components/espace/client/search-reference/SearchReference';
import Catalog from '../../components/espace/client/catalog/Catalog';
import Orders from '../../components/espace/client/orders/Orders';

type Props = {};

const EspaceClientView: React.FC<Props> = () => {

  return (
    <div className="espace-client-container">
      
      <div className="catalog-container">
        <Catalog />
      </div>
      <div className="search-reference-container">
        <SearchReference />
      </div>
      <div className="orders-container">
        <Orders />
      </div>
    </div>
  );
};

EspaceClientView.displayName = 'EspaceClientView';

export default EspaceClientView;
