import React from 'react';
import { faAdd, faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import TextInput from 'components/inputs/TextInput/TextInput';

type Props = {
  setOpenModal: (e: boolean) => void;
  setParams;
  setForcePage: (forcePage: number) => void;
  setIsActive: (e: boolean) => void;
  providers
}

const PartsReplacementFilter: React.FC<Props> = (props) => {
  const { register, getValues, handleSubmit, control, setValue } = useForm();
  const { t } = useTranslation();

  const onSubmit = () => {
    const values = getValues();

    Object.keys(values).forEach((key) => {
      if (values[key] == undefined || values[key] == '') {
        delete values[key];
      }
    });

    if (Object.keys(values).length == 0) {
      return;
    }

    const params = {
      ...values,
      codfou_old: getValues('codfou_old.value'),
      codfou_new: getValues('codfou_new.value'),
      page: 1
    };

    props.setParams(params);
    props.setForcePage(1);
    props.setIsActive(false);
  };

  const resetFilter = () => {
    const values = getValues();

    Object.keys(values).forEach((key) => {
      if (values[key] == undefined || values[key] == '') {
        delete values[key];
      }
    });

    setValue('codfou_old', null);
    setValue('codfou_new', null);
    setValue('ref_old', '');
    setValue('ref_new', '');
    
    props.setParams({ page: 1 });
    props.setForcePage(1);
    props.setIsActive(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSubmit(onSubmit)();
    }
  };

  return (
    <div className="filter">
      <div className="filter-header">
        <div className="filter-title">{t('_advanced_search')}</div>
        <div className="filter-close-btn" onClick={() => props.setIsActive(false)}>
          <FontAwesomeIcon className="icon" icon={faClose} />
        </div>

      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="filter-body">
          <div className="filter-input-item">
            <Controller
              control={control}
              name="codfou_old"
              render={({ field: { onChange, value } }) => {
                return (
                  <Select
                    value={value}
                    className="select-dropdown"
                    onChange={(val) => {
                      onChange(val);
                      onSubmit();
                    }}
                    options={props.providers}
                    placeholder={t('_codfou_old')}
                  />
                );
              }}
            />
          </div>
          <div className="filter-input-item">
            <Controller
              control={control}
              name="codfou_new"
              render={({ field: { onChange, value } }) => {
                return (
                  <Select
                    value={value}
                    className="select-dropdown"
                    onChange={(val) => {
                      onChange(val);
                      onSubmit();
                    }}
                    options={props.providers}
                    placeholder={t('_codfou_new')}
                  />
                );
              }}
            />
          </div>
          <div className="filter-input-item">
            <TextInput {...register('ref_old')} placeholder={t('_ref_old')} onKeyDown={handleKeyDown} />
          </div>
          <div className="filter-input-item">
            <TextInput {...register('ref_new')} placeholder={t('_ref_new')} onKeyDown={handleKeyDown} />
          </div>
          <DefaultButton text={t('_filter')} type="submit" />
          <DefaultButton text={t('_reset')} red onClick={resetFilter} type="button" />
        </div>
      </form>
    </div >
  );
};

export default PartsReplacementFilter;
