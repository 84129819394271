import { faClose, faRedo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import LinkButton from 'components/buttons/LinkButton/LinkButton';
import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import Spinner from 'components/spinner/Spinner';
import './StockFilter.scss';
import { IParamsConsumable } from 'interfaces/consumable';
import { useOnClickOutside } from 'utils/hooks/useOnClickOutside';
import TextInput from 'components/inputs/TextInput/TextInput';
import { useDeviceDetect } from '../../../utils/hooks/deviceDetect';
import { IDataSuppliersValue, IStockFilter } from 'models/stock';

type Props = {
  setOpenFilter?: (boolean) => void;
  openFilter?: boolean;
  filter: IStockFilter | undefined;
  loadingFilter: boolean;
};

const StockFilter: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const { control, getValues, setValue, reset, register } = useForm();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const filterRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const { isMobile, isTablet } = useDeviceDetect();
  const [suppliers, setSuppliers] = useState<IDataSuppliersValue[]>([]);

  // useOnClickOutside(filterRef, () => {
  //   if (props.openFilter) {
  //     handleClick();
  //   }
  // });

  useEffect(() => {

    if (props.filter) {
      setSuppliers(props.filter.suppliers);

      if (Array.from(searchParams).length) {

        searchParams.forEach((value, key) => {
          if (key == 'data_supplier') {

            const activeDataSupplier = props.filter?.suppliers.find((item) => item.value == Number(searchParams.get('data_supplier')));

            setValue('data_supplier', activeDataSupplier);
          }

          if (key == 'reference') {
            setValue('reference', searchParams.get('reference'));
          }

        });
      }
    }


  }, [props.filter, searchParams.get('data_supplier'), searchParams.get('reference')]);

  useEffect(() => {

    handleReset();

  }, [location.pathname.substring(location.pathname.lastIndexOf('/') + 1)]);


  function handleClick() {
    const value = getValues();


    const params = {
      data_supplier: value.data_supplier && value.data_supplier.value || '',
      reference: value.reference && value.reference || '',
      type_supplier: location.pathname.substring(location.pathname.lastIndexOf('/') + 1)
    };

    const validParams = Object.entries(params).reduce((acc, [key, value]) => {

      if (value === undefined || value === '') {
        return acc;
      }

      acc[key] = value;
      acc['page'] = String(1);

      return acc;
    }, {});


    const searchParams = createSearchParams(validParams);

    navigate({
      search: `?${searchParams}`,
    });

    props.setOpenFilter!(false);
  }

  function handleReset() {

    reset({ data_supplier: '', reference: '' });
    navigate({ search: '?' });
    props.setOpenFilter!(false);

  }

  return (
    <div ref={filterRef} className={`consumable-filter ${props.openFilter ? 'active' : ''}`}>
      <div className="header">
        <div className="buttons-container">
          <LinkButton title={t('_reset_filters')} icon={faRedo} onClick={handleReset} />
        </div>
        <div onClick={() => props.setOpenFilter!(false)}>
          <FontAwesomeIcon className="icon" icon={faClose} />
        </div>
      </div>
      <div className="title">
        <Trans
          i18nKey="_filter_results"
          components={{ b: <strong /> }}
        />

      </div>

      {props.loadingFilter ? <Spinner class="small" /> :
        <div className="filters">
          <div className="categories-filter">
            <div className="dropdown-item">
              <label className="dropdown-label">{t('_stock_supplier')}</label>
              <Controller
                control={control}
                name="data_supplier"
                render={({ field: { onChange, value } }) => (
                  <Select
                    value={value}
                    className="tyre-filter-select-dropdown"
                    onChange={(val) => {
                      onChange(val);
                    }}
                    options={suppliers}
                    placeholder={`${t('_select')}  ${t('_stock_supplier')}`}
                    menuPlacement="auto"
                    isSearchable={!isMobile && !isTablet}
                    menuPosition="fixed"
                    styles={{ menuPortal: base => ({ ...base, zIndex: 50 }) }}
                  />
                )}
              />

            </div>
            <div className="dropdown-item">
              <label className="dropdown-label">{t('_stock_reference')}</label>
              <TextInput {...register('reference')} />
            </div>

          </div>

        </div>
      }
      <div className="apply-btn">
        <DefaultButton text={t('_apply')} onClick={() => handleClick()} />
      </div>
    </div>
  );
};

StockFilter.displayName = 'StockFilter';

export default StockFilter;
