import React, { MutableRefObject, ReactElement, useEffect, useRef, useState } from 'react';
import { faCar, faHeart, faHome } from '@fortawesome/free-solid-svg-icons';

import './CatalogSupplierNavbar.scss';
import RoundedIcon from '../../buttons/RoundedIcon/RoundedIcon';
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { getBackendAssetsBasePath } from '../../../utils/marketplace';
import Spinner from '../../spinner/Spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import VehicleInfo from '../../VehicleInfo/VehicleInfo';
import { useTranslation } from 'react-i18next';
import { setCarInfo, setCarSelected } from '../../../store/reducers/carSelectedSlices';
import useHttp from '../../../utils/hooks/useHttp';
import { useAppDispatch } from '../../../utils/hooks/redux';
import { useOnClickOutside } from '../../../utils/hooks/useOnClickOutside';
import { CAR_IDENTIFIER, SOURCE_TECDOC } from '../../../utils/constants/defaultConstants';
import { getVehicleDescription } from 'utils/getVehicleDescription';

type Props = {
  title?: string;
  option?: ReactElement;
  infoCar?: boolean

}
const CatalogSupplierNavbar: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const { currentCatalog, isLoadingData, isLoadingInfo } = useSelector((state: RootState) => state.catalogs);
  const vehicleInfoPopupRef = useRef() as MutableRefObject<HTMLDivElement>;
  const [vehicleInfoIsOpen, setVehicleInfoIsOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const http = useHttp();
  const dispatch = useAppDispatch();
  const { car, carInfo } = useSelector((state: RootState) => state.carSelected);
  const vin = searchParams.get('vin') ?? '';
  const plateNumber = searchParams.get('plate_number') ?? '';
  const registrationPlate = searchParams.get('registration_plate') ?? '';
  const vehicleId = searchParams.get('ktypnr') ?? '';

  const isVehicleInfoShowed = vin !== '' || plateNumber !== '' || registrationPlate !== '';

  useOnClickOutside(vehicleInfoPopupRef, () => setVehicleInfoIsOpen(false));

  const resetVehicleInfoData = () => {
    localStorage.setItem('carSelected', '');
    dispatch(setCarSelected(null));
    dispatch(setCarInfo(null));
  };

  useEffect(() => {
    if (vin || plateNumber || registrationPlate || props.infoCar) {
      fetchVehicleInfo();

    } else {
      resetVehicleInfoData();
    }


  }, [vin, plateNumber, registrationPlate, vehicleId, props.infoCar]);

  const fetchVehicleInfo = () => {
    const commonParams: { [key: string]: string } = {};

    commonParams.source = SOURCE_TECDOC;
    commonParams.vehicle_identifier = String(searchParams.get('vehicle_identifier'));
    commonParams.vin = vin;
    commonParams.plate_number = plateNumber;
    commonParams.registration_plate = registrationPlate;
    setLoading(true);
    http.cart
      .fetchVehicleData(String(vehicleId), commonParams)
      .then((res) => {
        localStorage.setItem('carSelected', JSON.stringify(res.vehicle.car != null ? { car: res.vehicle.car, car_information: res.car_information } : { car: res.vehicle.truck, car_information: res.car_information }));
        dispatch(setCarSelected(res.vehicle.car != null ? res.vehicle.car : res.vehicle.truck));
        dispatch(setCarInfo(res.car_information));
      })
      .catch((err) => {
        resetVehicleInfoData();
      })
      .finally(() => setLoading(false));
  };


  return (
    <div className="catalog-supplier-navbar-container">
      {
        isLoadingData || isLoadingInfo ? (
          <div className="spiner-info">

            <Spinner class="small" />
          </div>
        ) : (
          <React.Fragment>
            <div className="breadcrumbs">
              <NavLink to={`/catalog/${currentCatalog?.id}`}>
                <img src={`${getBackendAssetsBasePath()}${currentCatalog?.image}`} alt={currentCatalog?.name} />
              </NavLink>
              <div className="icon" onClick={() => navigate('/catalog/' + currentCatalog?.id)}>
                <RoundedIcon icon={faHome} />
              </div>
            </div>
            {
              isVehicleInfoShowed && (
                <div className="vehicle-details">
                  <div ref={vehicleInfoPopupRef} className="info" onClick={() => setVehicleInfoIsOpen(true)}>
                    <FontAwesomeIcon icon={faCar} /> <div className="label"> {t('_vehicle_details')}</div>

                  </div>
                  {vehicleInfoIsOpen && <VehicleInfo loading={loading} setVehicleInfoIsOpen={setVehicleInfoIsOpen} />}
                </div>
              )
            }
            <div className="title">
              {props.title && !props.infoCar ? <h1>{props.title} {currentCatalog?.name}</h1> : null}
              {props.infoCar ?
                !carInfo ? (
                  <Spinner />
                ) : (
                  <div className="vehicle-container">
                    <div className="vehicle-name">
                      {carInfo?.manufacturer_name} {carInfo?.model_name ? carInfo?.model_name : carInfo?.model}
                    </div>
                    <div className="vehicle-description">
                      <span>{carInfo && car ? getVehicleDescription(carInfo, car) : ''}</span>
                    </div>
                  </div>
                )
                : ''}
            </div>
            <div className="options">{props.option}</div>
          </React.Fragment >
        )
      }
    </div >
  );
};

CatalogSupplierNavbar.displayName = 'CatalogSupplierNavbar';

export default CatalogSupplierNavbar;