import SkeletonSpinner from 'components/skeleton-spinner/SkeletonSpinner';
import React, { MutableRefObject, PropsWithChildren, useRef } from 'react';
import { useOnClickOutside } from 'utils/hooks/useOnClickOutside';
import { useTranslation } from 'react-i18next';
import './DropdownContainer.scss';

type Props = {
  setIsOpen: (isOpen: boolean) => void;
  setRefreshResults?: (bool) => void;
  loading: boolean;
  refreshResults?: boolean;
};

const DropdownContainer: React.FC<PropsWithChildren<Props>> = (props: PropsWithChildren<Props>) => {
  const referenceDropdownRef = useRef() as MutableRefObject<HTMLDivElement>;
  const { t } = useTranslation();

  useOnClickOutside(referenceDropdownRef, () => props.setIsOpen(false));

  return (
    <div ref={referenceDropdownRef} className="universal-dropdown-content">
      <div className="universal-dropdown-header">
        <div className="universal-dropdown-title">{t('_search_result')}</div>
      </div>
      <div className="universal-search-result-content">{props.loading ? <SkeletonSpinner /> : props.children}</div>
      {!props.loading && props.refreshResults ? (
        <div className="refresh-vin-results">
          <span
            onClick={() => {
              props.setRefreshResults!(() => true);
            }}
          >
            {t('_refresh_vin_results')}
          </span>
        </div>
      ) : null}
    </div>
  );
};

DropdownContainer.displayName = 'DropdownContainer';

export default DropdownContainer;
