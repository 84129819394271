import React, { useState } from 'react';
import { ReactComponent as FileIcon } from 'assets/Icons/file.svg';
import { IMessageFile } from '../../../interfaces/messaging';
import './MessageFile.scss';
import useHttp from 'utils/hooks/useHttp';
import Spinner from '../../spinner/Spinner';
import { notify } from '../../../utils/marketplace';
import { useTranslation } from 'react-i18next';
import MessagingHttp from '../../../utils/hooks/http/messaging';
import PlatformAdminMessaging from '../../../utils/hooks/http/platformAdminMessaging';


type Props = {
  messageFile: IMessageFile;
  isPlatformAdmin?: boolean
}

const MessageFile: React.FC<Props> = (props) => {
  const { messaging, platformAdminMessaging } = useHttp();
  let messagingRequest: MessagingHttp | PlatformAdminMessaging = messaging;

  if (props.isPlatformAdmin) {
    messagingRequest = platformAdminMessaging;
  }

  const { t } = useTranslation();
  const [isDownloading, setIsDownloading] = useState<boolean>(false);

  const handleDownloadMessage = async () => {
    downloadMessage();
  };

  const downloadMessage = async () => {
    setIsDownloading(true);

    messagingRequest.downloadMessage(String(props.messageFile.id))
      .then(({ data }) => {
        const element = document.createElement('a');

        if (data) {
          const file = new Blob(
            [data],
            { type: data.type },
          );

          element.href = URL.createObjectURL(file);
          element.download = props.messageFile.name;
          element.click();
        }

      })
      .catch((e) => {
        console.log(e);

        switch (e.status) {
          case 500:
            notify(t(e.detail));

            break;
          case 404:
            notify(t(e.detail));

            break;
          default:
            console.log(e);
        }
      })
      .finally(() => {
        setIsDownloading(false);
      });

  };


  return (
    <div className="message-file" onClick={handleDownloadMessage}>
      <div className="icon">
        {
          isDownloading ? (
            <Spinner class="extra-small" />
          ) : (
            < FileIcon />
          )
        }

      </div>
      <div className="name">
        <p>{props.messageFile.name}</p>
      </div>
    </div>

  );
};

MessageFile.displayName = 'MessageFile';

export default MessageFile;

