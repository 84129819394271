import React from 'react';
import './MaintenanceMobileTab.scss';

type Props = {
  title: string;
  index: string;
  selectedTab: string;
  setSelectedTab: (index: string) => void;
  icon?
  onClick?: ()=> void
  disabled?: boolean
  activeParam?: string
};

const MaintenanceMobileTabTitle: React.FC<Props> = (props) => {
  return (
    <div className={`tab-item ${props.selectedTab?.includes(props.index) ? 'active' : ''} ${props.disabled ? 'disabled-tab' : ''}`} onClick={() => {props.setSelectedTab(props.index); props.onClick!();}}>
      <div className="tab-icon">{props.icon}</div>
      <div className="tab-title">{props.title}</div>
    </div>
  );
};


export default MaintenanceMobileTabTitle;
