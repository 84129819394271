import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useHttp from 'utils/hooks/useHttp';
import { notify } from 'utils/marketplace';
import { AxiosError } from 'axios';
import Spinner from 'components/spinner/Spinner';
import { IConsumable } from 'interfaces/consumable';
import ConsumablePhotoGallery from '../ConsumablePhotoGallery/ConsumablePhotoGallery';
import ConsumableDetails from '../ConsumableDetails/ConsumableDetails';
import ConsumableOptions from '../ConsumableOptions/ConsumableOptions';
import './ConsumablesViewMobile.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const ConsumablesViewMobile: React.FC = () => {
  const http = useHttp();
  const { id } = useParams<'id'>();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [consumables, setConsumables] = useState<IConsumable>();
  const [loading, isLoading] = useState(false);

  useEffect(() => {
    document.body.classList.add('footer-padding');
    getConsumables();

    return () => {
      document.body.classList.remove('footer-padding');
    };
  }, []);

  const getConsumables = async () => {
    isLoading(true);

    try {
      await http.consumableCatalogHttp.getConsumable(String(id)).then((res) => setConsumables(res.data));
    } catch (e: unknown) {
      if (e instanceof AxiosError) {
        const message = e?.response?.data?.detail;

        notify(message);
      }
    } finally {
      isLoading(false);
    }
  };

  return (
    <div className="consumable-view item-view-wrapper">
      {loading ? (
        <Spinner class="small" />
      ) : (
        consumables && (
          <React.Fragment>
            <div className="item-view-header">
              <div className="mobile-consumable-reference">
                <span>Ref</span>: {` ${consumables.data_supplier_reference_original}`}
              </div>
              <div className="mobile-consumable-brand">
                {`${consumables.data_supplier.name} ${consumables.data_supplier_reference_original}`}
              </div>
              <div className="return-button">
                <span
                  className="returnButton"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <FontAwesomeIcon icon={faCircleArrowLeft} className="icon" />
                  {t('_return')}
                </span>
              </div>
            </div>
            <div className="item-view-content">
              <div className="gallery">
                <ConsumablePhotoGallery consumable={consumables} />
              </div>
              <div className="details">
                <ConsumableDetails consumable={consumables} />
              </div>
              <div className="options">
                <ConsumableOptions consumable={consumables} />
              </div>
            </div>
            {/*<div className="item-view-footer"></div>*/}
          </React.Fragment>
        )
      )}
    </div>
  );
};

export default ConsumablesViewMobile;
