import React, { useContext, useEffect } from 'react';
import Accordion from '../../../../../../../../components/accordion/Accordion';
import ProformaRowHeader from './ProformaRowHeader/ProformaRowHeader';
import ProformaRowData from './ProformaRowData/ProformaRowData';
import { AccordionData } from '../../../../../../../../interfaces/accordion';
import {
  IUploadProformaContext,
  UploadProformaAmerigoContext
} from 'context/UploadProformaAmerigoContext';
import './ProformaRows.scss';
import { useTranslation } from 'react-i18next';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';

const ProformaRows: React.FC = () => {
  const [accordionItems, setAccordionItems] = React.useState<AccordionData[] | []>([]);
  const { uploadFileResponse } = useContext(UploadProformaAmerigoContext) as IUploadProformaContext;
  const { t } = useTranslation();
  const { isMobile } = useDeviceDetect();

  useEffect(() => {
    initAccordionItems();
  }, [uploadFileResponse]);

  const initAccordionItems = () => {
    if (uploadFileResponse) {
      const items = Object.keys(uploadFileResponse.rows).map((item) => {
        const objectItem = uploadFileResponse.rows[item];

        return {
          title: <ProformaRowHeader title={t(item)} />,
          content: <ProformaRowData header={uploadFileResponse.header} rowKey={item} data={objectItem} />
        };
      });

      setAccordionItems(items);
    }
  };

  return (
    <div className="proforma-rows">
      {!isMobile ? <div className="header">
        {
          uploadFileResponse?.header.map((item, idx) => {
            return (
              <div className="header-item" key={idx}>
                <p>{item}</p>
              </div>
            );
          })

        }
        <div className="header-item actions">
          <p></p>
        </div>
      </div> : null}

      <Accordion items={accordionItems} />
    </div>
  );
};

ProformaRows.displayName = 'ProformaRows';

export default ProformaRows;