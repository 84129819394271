import Spinner from 'components/spinner/Spinner';
import Modal from 'components/modal/Modal';
import i18n from 'i18n';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { generatePath, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { RootState } from 'store/store';
import { CONSUMABLES_GROUP_ID } from 'utils/constants/defaultConstants';
import useHttp from 'utils/hooks/useHttp';

import { IAdjustListSubGroup } from 'utils/rmi/interface/adjust.interface';
import { getCurrentCountryRMI, getCurrentLanguageRMI } from 'utils/rmi/rmi-utils/rmi-utils';
import SidebarDetails from '../../SidebarDetails/SidebarDetails';
import VehicleIframe from '../../VehicleIframe/VehicleIframe';
import ConsumablesMobileCard from './ConsumablesMobileCard/ConsumablesMobileCard';
import IframeModal from './ConsumablesMobileCard/IframeModal/IframeModal';
import './Consumables.scss';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import { converObjectToParams } from 'utils/marketplace';
import useReliableCatalogCarQueryParams from 'utils/hooks/useReliableCatalogCarQueryParams';

const Consumables: React.FC = () => {
  const http = useHttp();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { typeId } = useSelector((state: RootState) => state.tecrmi);
  const { isMobile } = useDeviceDetect();

  const [itemMps, setItemMps] = useState<IAdjustListSubGroup>();
  const [subGroup, setSubGroup] = useState<IAdjustListSubGroup[]>();

  const [loadingList, setLoadingList] = useState(false);
  const [loadingHTML, setLoadingHTML] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const { vehicle_id } = useParams();

  const [iframeData, setIframeData] = useState<string>();
  const [sidebarDetails, setSidebarDetails] = useState(Number(searchParams.get('level')) || 1);

  const carParams = useReliableCatalogCarQueryParams();

  const [activeItem, setActiveItem] = useState<number>();
  const [showModal, setShowModal] = useState(false);

  function fetchConsumablesList() {
    if (typeId) {
      setLoadingList(true);
      http.adjustHttp
        .fetchRMIVehicleMainGroupData({
          typeId: typeId,
          countryCode: getCurrentCountryRMI(i18n.language),
          languageCode: getCurrentLanguageRMI(i18n.language),
          mainGroupId: CONSUMABLES_GROUP_ID,
        })
        .then((res) => setSubGroup(res.data))
        .finally(() => {
          setLoadingList(false);
        });
    }
  }

  function fetchConsumablesMainGroupHtml(id) {
    if (typeId) {
      setLoadingHTML(true);
      http.adjustHttp
        .fetchRMIVehicleAdjustMainGroupHtml({
          typeId: typeId,
          countryCode: getCurrentCountryRMI(i18n.language),
          languageCode: getCurrentLanguageRMI(i18n.language),
          mainGroupId: id,
          printView: true,
        })
        .then((res) => setIframeData(res.data))
        .finally(() => {
          setLoadingHTML(false);
        });
    }
  }

  function fetchConsumablesSubGroupHtml(id) {
    setLoadingHTML(true);
    http.adjustHttp
      .fetchRMIVehicleAdjustSubGroupHtml({
        typeId: typeId,
        countryCode: getCurrentCountryRMI(i18n.language),
        languageCode: getCurrentLanguageRMI(i18n.language),
        subGroupId: id,
        printView: true,
      })
      .then((res) => setIframeData(res.data))
      .finally(() => {
        setLoadingHTML(false);
      });
  }

  function fetchConsumablesItemMpsHtml(id) {
    setLoadingHTML(true);
    http.adjustHttp
      .fetchRMIVehicleAdjustItemHtml({
        typeId: typeId,
        countryCode: getCurrentCountryRMI(i18n.language),
        languageCode: getCurrentLanguageRMI(i18n.language),
        itemMpId: id,
        printView: true,
      })
      .then((res) => setIframeData(res.data))
      .finally(() => {
        setLoadingHTML(false);
      });
  }

  function onSubGroupClick(item) {
    setItemMps(item);
    setSidebarDetails(2);
    searchParams.set('subgroup_id', item.SubGroupId);
    searchParams.set('level', '2');
    setSearchParams(searchParams);
    isMobile && fetchConsumablesSubGroupHtml(Number(searchParams.get('subgroup_id')));
    iframeData && setShowModal(true);
  }

  function onItemMpClick(item) {
    setActiveItem(item.ItemMpId);
    searchParams.set('item_id', item.ItemMpId);
    setSearchParams(searchParams);
    isMobile && fetchConsumablesItemMpsHtml(Number(searchParams.get('item_id')));
    iframeData && setShowModal(true);
  }

  function onReturnClick() {
    if (sidebarDetails === 1) {
      navigate({
        pathname: generatePath('/reliable/rmi/:vehicle_id/technical-documentation', {
          vehicle_id: String(vehicle_id),
        }),
        search: converObjectToParams(carParams),
      });

      return;
    }

    if (searchParams.get('level') === '2') {
      searchParams.delete('item_id');
      setSearchParams(searchParams);
    }

    navigate(-1);
    setSidebarDetails((prev) => prev - 1);
  }

  useEffect(() => {
    fetchConsumablesList();
  }, [typeId, i18n.language]);

  useEffect(() => {
    if (!subGroup) {
      return;
    }

    if (Number(searchParams.get('subgroup_id'))) {
      setItemMps(subGroup?.find((item) => item.SubGroupId === Number(searchParams.get('subgroup_id'))));
    }

    if (Number(searchParams.get('item_id'))) {
      setActiveItem(Number(searchParams.get('item_id')));
    }
  }, [subGroup]);

  useEffect(() => {
    if (!searchParams.get('subgroup_id') && !searchParams.get('item_id')) {
      fetchConsumablesMainGroupHtml(CONSUMABLES_GROUP_ID);
    }

    if (searchParams.get('subgroup_id') && typeId && !searchParams.get('item_id')) {
      fetchConsumablesSubGroupHtml(Number(searchParams.get('subgroup_id')));
    }

    if (searchParams.get('item_id') && typeId) {
      fetchConsumablesItemMpsHtml(Number(searchParams.get('item_id')));
    }
  }, [searchParams.get('group_id'), searchParams.get('subgroup_id'), searchParams.get('item_id'), typeId, i18n.language]);

  return (
    <div className="technical-documentation-consumables">
      {loadingList ? (
        <Spinner class="extra-small" />
      ) : !isMobile ? (
        <React.Fragment>
          <div className="tdoc-sidebar">
            {sidebarDetails === 1 && (
              <SidebarDetails
                title={`<span>${t('_consumables')}</span>`}
                itemName="SubGroupName"
                onClick={onSubGroupClick}
                data={subGroup}
                onReturnClick={onReturnClick}
              />
            )}
            {sidebarDetails === 2 && (
              <SidebarDetails
                title={`${t('_consumables')} / <span>${itemMps?.SubGroupName}</span> `}
                itemId="ItemMpId"
                itemName="ItemMpText"
                onClick={onItemMpClick}
                data={itemMps?.ItemMps}
                onReturnClick={onReturnClick}
                activeItem={activeItem}
              />
            )}
          </div>
          <div className="tdoc-content">{iframeData && <VehicleIframe loading={loadingHTML} vehicleIframe={iframeData!} />}</div>
        </React.Fragment>
      ) : (
        <div className="tdoc-content">
          <ConsumablesMobileCard
            title={t('_consumables')}
            data={subGroup}
            onSubGroupClick={onSubGroupClick}
            onItemMpClick={onItemMpClick}
          />
          <Modal
            openModal={showModal}
            setOpenModal={setShowModal}
            childrenComponent={<IframeModal loading={loadingHTML} setOpenModal={setShowModal} vehicleIframe={iframeData!} />}
          />
        </div>
      )}
    </div>
  );
};

export default Consumables;
