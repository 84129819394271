
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import { IUsers } from 'models/automotor-tariffs.model';
import React, { PropsWithChildren, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import useHttp from 'utils/hooks/useHttp';
import { notify } from 'utils/marketplace';
import './SendEmailToSellerModal.scss';

type Props = {
  setOpenModal?: (boolean) => void
  users: IUsers[]
};

const SendEmailModal: React.FC<Props> = (props: PropsWithChildren<Props>) => {

  const { register, handleSubmit, getValues, formState: { errors } } = useForm();
  const http = useHttp();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  function submit() {
    setLoading(true);
    const params = {
      emails: props.users.map(user => user.email),
      subject: getValues().subject,
      message: getValues().message
    };

    http.automotorTariff.sendEmail(params).then(() => {
      props.setOpenModal!(false);
      notify('Email sent successfully', 'success');
    }).catch(() => {
      notify('Error');
    }).finally(() => {
      setLoading(false);
    });
  }


  return (
    <div className="modal-select-sendemail">
      <button onClick={() => {
        props.setOpenModal!(false);
      }
      } className="close"
      >
        &times;
      </button>
      <div className="sendemail-modal-dialog">
        <div className="checkout-modal-title">{t('_new_message')}</div>
        <div className="modal-container">
          <form onSubmit={handleSubmit(submit)} className="modal-body">
            <div className="sendemail-input-container">
              <input className={`sendemail-input ${errors.subject && 'sendemail-error'}`} {...register('subject', { required: true })} type="text" placeholder={t('_subject')} />
              {errors.subject && <div className="sendemail-modal-err">
                <Trans
                  i18nKey="_validate_field"
                />
              </div>}
            </div>
            <div className="sendemail-input-container">
              <textarea className={`sendemail-textarea ${errors.message && 'sendemail-error'}`} {...register('message', { required: true })} placeholder={t('_message')} />
              {errors.message && <div className="sendemail-modal-err">
                <Trans
                  i18nKey="_validate_field"
                />
              </div>
              }
            </div>

            <div className="sendemail-button">
              <DefaultButton isloading={loading} type="submit" text={t('_send')} />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SendEmailModal;
