import React, { useState } from 'react';
import { faCar, faTruck, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tabs from '../Tabs/Tabs';
import Tab from '../Tabs/Tab';
import CarSelector from '../Contents/CarSelector';
import TruckSelector from '../Contents/TruckSelector';
import './VehicleCardMobile.scss';
import { useTranslation } from 'react-i18next';

type Props = {
  setOpenModal?: (e: boolean) => void;
  link?: string;
};

const VehicleCardMobileView: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const [isActive, setIsActive] = useState(false);

  return (
    <div className="search-card">
      <div className="card-title" onClick={() => (props.setOpenModal ? null : setIsActive(!isActive))}>
        {t('_you_know_vehicle')}
        {!props.setOpenModal && (isActive ? <FontAwesomeIcon icon={faMinus} /> : <FontAwesomeIcon icon={faPlus} />)}
      </div>
      {(isActive || props.setOpenModal) && (
        <div className="card-content">
          <div className="search-inputs-container">
            <Tabs>
              <Tab title={<FontAwesomeIcon icon={faCar} />}>
                <CarSelector setOpenModal={props.setOpenModal} link={props.link} />
              </Tab>
              <Tab title={<FontAwesomeIcon icon={faTruck} />}>
                <TruckSelector setOpenModal={props.setOpenModal} link={props.link} />
              </Tab>
            </Tabs>
          </div>
        </div>
      )}
    </div>
  );
};

VehicleCardMobileView.displayName = 'VehicleCardMobileView';

export default VehicleCardMobileView;
