import React, { Dispatch, ChangeEvent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faXmark, faCheck } from '@fortawesome/free-solid-svg-icons';
import { IClientContact, IClient, IClientUserSettings } from 'models/client.model';
import Spinner from 'components/spinner/Spinner';
import Icon from 'components/Icon/Icon';
import Modal from 'components/modal/Modal';
import EditClient from './EditClient/EditClient';
import EditContact from './EditContact/EditContact';
import { useTranslation } from 'react-i18next';
import { UseFormRegister, UseFormGetValues } from 'react-hook-form';
import CustomDatePicker from 'components/custom-date-picker/CustomDatePicker';
import './ModifyClient.scss';
import { IDateOption } from 'models/date-picker';

type Props = {
  showClientEdit: boolean;
  showContactEdit: boolean;
  newContactMode: boolean;
  contactDetails: IClientContact[];
  contractToEdit: IClientContact | null;
  loading: boolean;
  clientDetails: IClient | null;
  openConfirmModal: boolean;
  refreshData: () => void;
  handleReturnClick: () => void;
  onClientEdit: () => void;
  handleContactEdit: (contract: IClientContact | null) => () => void;
  handleNewContact: () => void;
  handleConfirmModal: () => void;
  setConfirmModal: Dispatch<React.SetStateAction<boolean>>;
  handleSubmit: () => void;
  register: UseFormRegister<IClientUserSettings>;
  getValues: UseFormGetValues<IClientUserSettings>;
  rmiModules: boolean;
  handleRmiModulesCheck: (e: ChangeEvent<HTMLInputElement> | string) => void;
  diagnosticCheck: boolean;
  handleDiagnosticCheck: (e: ChangeEvent<HTMLInputElement>) => void;
  setPlateValueDate: (date: IDateOption) => void
  setRmiValueDate: (date: IDateOption) => void
  setSubscriptionValueDate: (date: IDateOption) => void
  subscriptionValueDate?: IDateOption
  watch?
};

const ModifyClientView: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();
  const parentSettings = JSON.parse(localStorage.getItem('userData')!);

  return (
    <div className="client-view-content-wrapper">
      <div className="top-content">
        <div className="return-button-wrapper">
          <Icon name="circleLeftRegular" className="button" onClick={props.handleReturnClick}></Icon>
          <h4 className="title-back">{t('_back_to_client_list')}</h4>
          <div></div>
        </div>
        <h1 className="title">{props.clientDetails?.name}</h1>
      </div>
      <hr className="separator"></hr>
      <div className="middle-content">
        <div className="client-wrapper">
          <h4 className="client-title">{t('_client').toLocaleUpperCase()}</h4>
          <div className="client-box">
            <FontAwesomeIcon icon={faPen} className="edit-icon" onClick={props.onClientEdit} />
            {props.showClientEdit && (
              <EditClient
                showEditClient={props.showClientEdit}
                client={props.clientDetails}
                setSubscriptionValueDate={props.setSubscriptionValueDate}
                subscriptionValueDate={props.subscriptionValueDate}
                handleEditClientModal={props.onClientEdit}
                refreshData={props.refreshData}
              ></EditClient>
            )}
            <div className="name-wrapper">
              <h4 className="client-name">{t('_name')}:</h4>
              <h6 className="client-name-value">{props.clientDetails?.name}&#00;</h6>
            </div>
            <div className="name-wrapper--m">
              <h4 className="client-name">{t('_client_address')} :</h4>
              <h6 className="client-name-value">
                {(props.clientDetails?.addresses[0]?.addressL1 ? props.clientDetails?.addresses[0]?.addressL1 != 'null' ? props.clientDetails?.addresses[0]?.addressL1 : '' : '') + ' ' + (props.clientDetails?.addresses[0]?.addressL6 ? props.clientDetails?.addresses[0]?.addressL6 != 'null' ? props.clientDetails?.addresses[0]?.addressL6 : '' : '')}
                &#00;
              </h6>
            </div>
          </div>
        </div>
        {props.loading && <Spinner />}
        <div className="button-container">
          <div className="new-contact" onClick={props.handleNewContact}>
            + {t('_new_contact')}
          </div>
        </div>
        {props.showContactEdit && (
          <EditContact
            showContactEdit={props.showContactEdit}
            contactDetails={props.contractToEdit}
            handleNewClientModal={props.handleContactEdit(null)}
            newContactMode={props.newContactMode}
            refreshData={props.refreshData}
          ></EditContact>
        )}
        <div className="contact-wrapper">
          <h4 className="contact-title">{t('_contact').toLocaleUpperCase()}</h4>
        </div>
        {props.contactDetails.map((contact: IClientContact) => (
          <div key={contact.id} className="contact-wrapper">
            <div className="client-box">
              <FontAwesomeIcon icon={faPen} className="edit-icon" onClick={props.handleContactEdit(contact)} />
              <div className="name-wrapper">
                <h4 className="client-name">{t('_lastname')}:</h4>
                <h6 className="client-name-value">{contact.last_name}&#00;</h6>
              </div>
              <div className="name-wrapper">
                <h4 className="client-name">{t('_firstname')}:</h4>
                <h6 className="client-name-value">{contact.first_name}&#00;</h6>
              </div>
              <div className="name-wrapper">
                <h4 className="client-name">{t('_work_position')}:</h4>
                <h6 className="client-name-value">{contact.roles.reduce((roles, role) => `${roles} ${role}`, '')}&#00;</h6>
              </div>
              <div className="name-wrapper">
                <h4 className="client-name">{t('_email')}:</h4>
                <h6 className="client-name-value">{contact.email}&#00;</h6>
              </div>
              <div className="name-wrapper">
                <h4 className="client-name">{t('_mobile')}:</h4>
                <h6 className="client-name-value">{contact.mobile != 'null' ? contact.mobile : ''}&#00;</h6>
              </div>
              <div className="name-wrapper">
                <h4 className="client-name">{t('_phone')}:</h4>
                <h6 className="client-name-value">{contact.phone != 'null' ? contact.phone : ''}&#00;</h6>
              </div>
            </div>
          </div>
        ))}
      </div>
      <h4 className="parameters-title">{t('_access_settings')}</h4>
      <hr className="separator"></hr>
      <div className="parameters-wrapper">
        <div className="parameters">
          <h3 className="subtitle">{t('_product_research')}</h3>
          <div className="list-wrapper">
            <div className="item-wrapper">
              <input type="checkbox" id="catalogues" checked readOnly />
              <label htmlFor="catalogues">{t('_in_catalogs')}</label>
            </div>
            <div className="item-wrapper">
              <input type="checkbox" id="reference" checked readOnly />
              <label htmlFor="reference">{t('_by_reference')}</label>
            </div>
            <div className={`item-wrapper registration ${parentSettings?.client_settings?.plate_number_search ? '' : 'input-disabled'}`}>
              <div>
                <input type="checkbox" id="registration" {...props.register('plate_number_search')} />
                <label htmlFor="registration">{t('_by_registration')}</label>
              </div>
              {props.watch('plate_number_search') && (
                <div className="item-date-picker">
                  <CustomDatePicker setValue={props.setPlateValueDate} defaultValues={props.clientDetails?.client_settings.plate_number_subscription_period} />
                </div>
              )}
            </div>
            <div className="item-wrapper">
              <input type="checkbox" id="model" checked readOnly />
              <label htmlFor="model">{t('_by_vehicle_model')}</label>
            </div>
          </div>
          {/*    
          <div className="item-date-picker subscription-date-picker">
            <label>{t('_subscription')}</label>
            <CustomDatePicker setValue={props.setSubscriptionValueDate} defaultValues={props.clientDetails?.client_subscription} />
          </div> */}

        </div>
        <div className="parameters">
          <h3 className="subtitle">{t('_access_services')}</h3>
          <div className="list-wrapper">
            <div
              className={`${'item-wrapper'} ${parentSettings?.client_settings?.eina_subscription ? '' : 'input-disabled'}`}
            >
              <input
                type="checkbox"
                value="true"
                defaultChecked={props.getValues().eina_subscription}
                id="eina_subscription"
                {...props.register('eina_subscription')}
              />
              <label htmlFor="eina_subscription">{t('_step_by_step')}</label>
            </div>
            <div className={`item-wrapper rmi_modules ${parentSettings?.client_rmi_modules_type === 'none' ? 'input-disabled' : ''}`}>
              <div>
                <input
                  className={`${parentSettings?.client_rmi_modules_type === 'none' ? 'disabled' : ''}`}
                  type="checkbox"
                  id="catalogues"
                  checked={props.rmiModules}
                  onChange={props.handleRmiModulesCheck}
                />
                <label htmlFor="catalogues">{t('_technical_documentation')}</label>
                <div className="rmi-settings-select">
                  <div className={`settings-select  ${props.rmiModules ? 'rmi-settings' : ''}`}>
                    <div className={`${'radio'} ${parentSettings?.client_rmi_modules_type === 'none' ? 'input-disabled' : ''}`}>
                      <input
                        className="check"
                        type="radio"
                        value="CAR_Tech"
                        checked={props.getValues().rmi_modules_type === 'CAR_Tech'}
                        onChange={() => props.handleRmiModulesCheck('CAR_Tech')}
                      />
                      <label htmlFor="is-active-true">CAR_Tech</label>
                      <FontAwesomeIcon icon={faCheck} className="check-icon" />
                    </div>
                    <div
                      className={`${'radio'} ${parentSettings?.client_rmi_modules_type !== 'CAR_Pro' ? 'input-disabled' : ''}`}
                    >
                      <input
                        className="check"
                        type="radio"
                        value="CAR_Pro"
                        checked={props.getValues().rmi_modules_type === 'CAR_Pro'}
                        onChange={() => props.handleRmiModulesCheck('CAR_Pro')}
                      />
                      <label htmlFor="is-active-true">CAR_Pro</label>
                      <FontAwesomeIcon icon={faCheck} className="check-icon" />
                    </div>
                  </div>
                  {(props.watch('rmi_modules_type') === 'CAR_Pro' || props.watch('rmi_modules_type') === 'CAR_Tech') && (
                    <div className="item-date-picker rmi-date-picker">
                      <CustomDatePicker setValue={props.setRmiValueDate} defaultValues={props.clientDetails?.client_settings.rmi_modules_subscription_period} />
                    </div>
                  )}
                </div>
              </div>

            </div>
            <div className={`${'item-wrapper'} ${parentSettings?.client_settings?.actia ? '' : 'input-disabled'}`}>
              <input type="checkbox" id="registration" checked={props.diagnosticCheck} onChange={props.handleDiagnosticCheck} />
              <label htmlFor="registration">{t('_diagnostic')}</label>
            </div>
            <div className="settings-select">
              <div className={`${'radio'} ${parentSettings?.client_settings?.actia ? '' : 'input-disabled'}`}>
                <input
                  className="check"
                  type="checkbox"
                  checked={props.diagnosticCheck}
                  onChange={props.handleDiagnosticCheck}
                />
                <label htmlFor="is-active-true">{t('_actia')}</label>
                <FontAwesomeIcon icon={faCheck} className="check-icon" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="actions-wrapper">
        <div className="button-wrapper" onClick={() => props.setConfirmModal(true)}>
          <FontAwesomeIcon className="discard-icon" icon={faXmark} />
          <h5 className="discard-title">{t('_delete_client')}</h5>
        </div>
        <div className="button-wrapper--apply" onClick={() => props.handleSubmit()}>
          {t('_save_changes')}
        </div>
      </div>
      <Modal
        openModal={props.openConfirmModal}
        setOpenModal={() => props.setConfirmModal(true)}
        onRequestClose={() => props.setConfirmModal(false)}
        childrenComponent={
          <div className="modal-success-proforma">
            {props.loading && <Spinner />}
            <h1>{t('_delete_client_confirmation_message')}</h1>
            <div className="modal-footer">
              <button onClick={props.handleConfirmModal} className="btn-close">
                {t('_yes')}
              </button>
              <button onClick={() => props.setConfirmModal(false)} className="btn-submit">
                {t('_no')}
              </button>
            </div>
          </div>
        }
      />
    </div>
  );
};

ModifyClientView.displayName = 'ModifyClientView';

export default ModifyClientView;
