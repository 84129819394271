import React from 'react';
import { useTranslation } from 'react-i18next';
import './OrderFinancial.scss';

import { NavLink } from 'react-router-dom';

import ORDER_IN_PROGRESS_IMG from 'assets/images/order_in_progress.svg';

type Props = {};

const OrderFinancial: React.FC<Props> = () => {
  const { t } = useTranslation();

  return (
    <div className="order-in-progress">
      <NavLink to="/order/financial">
        <div className="title">{t('_financial')}</div>
        <div className="upload-btn">
          <div className="icon">
            <img src={ORDER_IN_PROGRESS_IMG} alt="" />
          </div>
          <div className="text">{t('_overall_turnover_disponible')}</div>
        </div>
      </NavLink>
    </div>
  );
};

OrderFinancial.displayName = 'OrderFinancial';

export default OrderFinancial;
