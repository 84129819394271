import React from 'react';
import { CellProps } from 'react-table';
import { CartTableItem } from '../../CartTable.model';
import PriceCellView from './PriceCell.view';

type Props = CellProps<CartTableItem> & {
  unspecified?: boolean
};

const PriceCell: React.FC<Props> = (props: Props) => {
  return <PriceCellView unspecified={props.unspecified} value={props.value}></PriceCellView>;
};

PriceCell.displayName = 'PriceCell';

export default PriceCell;
