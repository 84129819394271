import { faCircleArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import SubGroup from './CollapseList/SubGroup';
import './ConsumablesMobileCard.scss';

type Props = {
  onSubGroupClick?: (item) => void;
  onItemMpClick?: (item) => void;
  data;
  title: string;
};

const ConsumablesMobileCard: React.FC<Props> = (props: Props) => {
  const navigate = useNavigate();

  function onReturnClick() {
    navigate(-1);
  }

  return (
    <div className="consumable-mobile-card">
      <div className="header">
        {/*<span className="return-button" onClick={onReturnClick}>*/}
        {/*  <FontAwesomeIcon icon={faCircleArrowLeft} className="icon" />*/}
        {/*</span>*/}
        <div className="title">{props.title}</div>
      </div>
      <div className="content">
        <div className="items">
          {props.data?.map((item, key) => {
            return <SubGroup key={key} data={item} onSubGroupClick={props.onSubGroupClick} onItemMpClick={props.onItemMpClick} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default ConsumablesMobileCard;
