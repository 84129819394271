import React, {useEffect, useState} from 'react';
import ErrorCodesView from './ErrorCodes.view';
import {IActiaReportData, IErrorCodesOrganizes} from '../../../../components/DiagnosticItems/Actia/actia';
import useHttp from 'utils/hooks/useHttp';
import {useParams} from 'react-router-dom';
import {getURLParams} from '../../../../utils/marketplace';

interface IUrlParams {
  code?: string;
}
type Props = {}

const ErrorCodes: React.FC<Props> = (props) => {


  const [errorCodesOrganized, setErrorCodesOrganized] = useState<Array<IActiaReportData>[] | []>([]);
  const {actiaHttp} = useHttp();
  const {session_id} = useParams();
  const [fileName, setFileName] = useState<string | null>(null);
  const {code}: IUrlParams = getURLParams(location.search);
  const [loading, setLoading] = useState(code !== undefined);


  const [selectedCode, setSelectedCode] = useState<string>(String(code));

  useEffect(() => {
    if(!errorCodesOrganized.length) {
      getErrorCodes();
    }
  }, []);

  const getErrorCodesOrganized = (errorCodesData: IActiaReportData[]): Array<IActiaReportData>[] | [] => {
    const errors: Array<IActiaReportData>[] = [];

    errorCodesData.map((item,index) => {
      const itemNameKey: string = item.name.replace(' ', '_');

      if(errors[itemNameKey] === undefined) {
        errors[itemNameKey] = [item];
      } else {
        errors[itemNameKey].push(item);
      }
    });

    const errorsArray =  Object.values(errors);

    errorsArray.sort((a, b) => {
      return a.length > b.length ? -1 : 1;
    });

    return errorsArray;

  };

  const getErrorCodes = () => {

    if(!session_id) {
      return;
    }

    setLoading(true);
    actiaHttp.getErrorCodesBySessionId(session_id)
      .then((response) => {
        setFileName(response.file);

        if(Array.isArray(response.data)) {
          setErrorCodesOrganized(getErrorCodesOrganized(response.data));
        }
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <ErrorCodesView setSelectedCode={setSelectedCode} selectedCode={selectedCode} fileName={fileName} loading={loading} errorCodesOrganized={errorCodesOrganized}/>
  );
};

ErrorCodes.displayName = 'ErrorCodes';

export default ErrorCodes;