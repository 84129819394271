import React, { useEffect, useState } from 'react';

import './EquivalenceOEMItemV2.scss';
import { IOem } from 'models/car-parts.model';
import { useTranslation } from 'react-i18next';
import { Link, NavLink, useSearchParams } from 'react-router-dom';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';

type Props = {
  item: IOem;
};

const EquivalenceOEMItemV2: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const [link, setLink] = useState('');
  const { isMobile } = useDeviceDetect();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    let url = `/car-parts?q=${props.item.short_name}&source=${props.item.source}&subcategory_id=${props.item.category_id}&man_sup_id=${props.item?.manufacturer_group_id}&type=${props.item.type}`;

    if (searchParams.get('is_platform_admin')) {
      url = url + '&is_platform_admin=true';
    }

    setLink(
      url
    );
  });


  return (
    <div className="equivalence-oem-item">
      <NavLink className="image" to={link}>
        <img alt="image" src={props.item.manufacturer_logo ? props.item.manufacturer_logo : '/images/car-part-placeholder.png'} onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src = '/images/car-part-placeholder.png';
        }}
        ></img>
      </NavLink>
      <div className="content">
        <Link className="title" to={link}>
          {props.item.short_name}
        </Link>
        <div className="category text-ellipsis one-line">{props.item?.category_name}</div>
        <div className="subtitle">
          {isMobile ? <span>Ref</span> : <span>{t('_reference')}</span>}: {props.item.short_name}
        </div>
      </div>
    </div>
  );
};

EquivalenceOEMItemV2.displayName = 'EquivalenceOEMItemV2';

export default EquivalenceOEMItemV2;
