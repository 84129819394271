import React, {createContext, useState} from 'react';
import {IProformaAmerigoResponse} from '../utils/hooks/http/proforma.http';
import {SingleValue} from 'react-select';
import {IOption} from '../interfaces/select';

type UploadProformaAmerigoContextProviderProps = {
  children: React.ReactNode;
}

export type IUploadProformaContext = {
  modalContentType: string;
  setModalContentType: (string) => void;
  uploadFileResponse: IProformaAmerigoResponse | null;
  setUploadFileResponse: (response: IProformaAmerigoResponse) => void;
  selectedSupplier: SingleValue<IOption> | null;
  setSelectedSupplier: (supplier: SingleValue<IOption> | null) => void;
  setFileName: (fileName: string) => void;
  fileName: string;
  supplierOptions: IOption[];
  setSupplierOptions: (supplierOptions: IOption[]) => void;

}

export const UploadProformaAmerigoContext = createContext<IUploadProformaContext | null>(null);

export const UploadProformaAmerigoContextProvider:React.FC<UploadProformaAmerigoContextProviderProps> = (props) => {
  const [modalContentType, setModalContentType] = useState('small');
  const [uploadFileResponse, setUploadFileResponse] = useState<IProformaAmerigoResponse | null>(null);
  const [selectedSupplier, setSelectedSupplier] = React.useState<SingleValue<IOption> | null>(null);
  const [fileName, setFileName] = useState<string>('');
  const [supplierOptions, setSupplierOptions] = useState<IOption[] | []>([]);


  return (
    <UploadProformaAmerigoContext.Provider value={{ supplierOptions, setSupplierOptions, setFileName,fileName, selectedSupplier, setSelectedSupplier, modalContentType, setModalContentType, uploadFileResponse, setUploadFileResponse}}>
      {props.children}
    </UploadProformaAmerigoContext.Provider>
  );
};

