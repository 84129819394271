import { IManufacturers, IFuelType, IVersion, IParams, ICatalogModels } from 'interfaces/vehicleSelector';
import { IHttpResponse, serverAxios } from '../../http';

class VehicleSelectorCatalogHttp {
  private static carManufacturers = 'car-manufacturers';
  private static carModels = 'car-models';
  private static carFuel = 'fuel-type';
  private static carVersion = 'car-version';
  
  public async getCarManufacturers(catalog_id: string): Promise<IHttpResponse<IManufacturers[]>> {
    return serverAxios.get(VehicleSelectorCatalogHttp.carManufacturers, { params: { catalog_id } });
  }
  public async getCarModels(params: IParams, catalog_id: string): Promise<IHttpResponse<ICatalogModels[]>> {
    return serverAxios.get(VehicleSelectorCatalogHttp.carModels, { params: { manufacturer_id: params.value, catalog_id } });
  }
  public async getCarFuel(params: IParams, catalog_id: string): Promise<IHttpResponse<IFuelType[]>> {
    return serverAxios.get(VehicleSelectorCatalogHttp.carFuel, { params: { model_id: params.value, catalog_id } });
  }
  public async getCarVersion(params: IParams, catalog_id: string): Promise<IHttpResponse<IVersion[]>> {
    return serverAxios.get(VehicleSelectorCatalogHttp.carVersion, { params: { model_id: params.value, catalog_id } });
  }
}

export default VehicleSelectorCatalogHttp;
