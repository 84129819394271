import React, { useEffect, useState } from 'react';
import { createSearchParams, useSearchParams, useNavigate, URLSearchParamsInit } from 'react-router-dom';
import { IClientInvitationResponse, IInvitationsFilterParams, IInvitationFilters } from 'models/client.model';
import ClientsInvitationsView from './ClientsInvitations.view';
import useHttp from 'utils/hooks/useHttp';

type Props = {};

const ClientsInvitations: React.FC<Props> = () => {
  const [invitations, setInvitations] = useState<IClientInvitationResponse>();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [invitationsCount, setInvitationsCount] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [isFilterActive, setFilterActive] = useState<boolean>(false);
  const [searchInputValue, setSearchInputValue] = useState<string>('');
  const [searchFilterType, setSearchFilterType] = useState<IInvitationFilters>(IInvitationFilters.NAME);
  const [searchParams] = useSearchParams();
  const http = useHttp();
  const navigate = useNavigate();

  useEffect(() => {
    getInvitations();
  }, [page]);

  const getInvitations = (params?) => {
    setLoading(true);

    const paramQ: URLSearchParamsInit = [
      ['page', page.toString()],
      ['per_page', String(invitations?.per_page || 20)],
    ];

    if (params?.filterBy != undefined) {
      paramQ.push([params?.filterBy, params?.filterValue]);
    }

    const searchParams = createSearchParams(paramQ);

    Promise.all([http.clients.getClientsInvitations(searchParams), http.clients.getClientsInvitationsCount()])
      .then((res) => {
        setInvitations(res[0]);
        setInvitationsCount(res[1].data);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const navigateBack = () => {
    navigate('/espace-clients/my-clients');
  };

  const changeSearchHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInputValue(event.target.value);
  };

  const changeRadioHandler = (value: string) => {
    setSearchInputValue(value);
  };

  const changeFilterHandler = (newFilterType: IInvitationFilters) => {
    if (searchFilterType === IInvitationFilters.SEEN) {
      setSearchInputValue('');
    }

    setSearchFilterType(newFilterType);
  };

  const onFilterSubmit = (pageN?: number) => {
    const params: IInvitationsFilterParams = {
      filterBy: searchFilterType,
      filterValue: searchInputValue,
      page: Number(pageN || searchParams.get('page') || 1),
      perPage: invitations?.per_page || 20,
    };

    if (searchFilterType === IInvitationFilters.SEEN) {
      if (!searchInputValue) {
        params.filterValue = 'true';
      } else {
        params.filterValue = searchInputValue === 'yes' ? 'true' : 'false';
      }
    }

    if (params.filterValue) {
      setFilterActive(true);
    } else {
      setFilterActive(false);
      getInvitations(Number(searchParams.get('page') || 1));

      return;
    }

    getInvitations(params);
  };

  const handlePageClick = (pageN: number) => {
    if (isFilterActive) {
      onFilterSubmit(pageN);
    } else {
      setPage(pageN + 1);
    }
  };

  return (
    <ClientsInvitationsView
      invitations={invitations}
      fetchData={getInvitations}
      handlePageClick={handlePageClick}
      isLoading={isLoading}
      invitationsCount={invitationsCount}
      navigateBack={navigateBack}
      searchInputValue={searchInputValue}
      changeRadioHandler={changeRadioHandler}
      changeSearchHandler={changeSearchHandler}
      onFilterSubmit={onFilterSubmit}
      onChangeFilterType={changeFilterHandler}
      searchFilterType={searchFilterType}
    ></ClientsInvitationsView>
  );
};

ClientsInvitations.displayName = 'ClientsInvitations';

export default ClientsInvitations;
