import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './UploadCsv.scss';
import UPLOAD_IMG from 'assets/images/upload.svg';
import { useDropzone } from 'react-dropzone';
import { useForm } from 'react-hook-form';
import useHttp from 'utils/hooks/useHttp';
import Spinner from 'components/spinner/Spinner';
import { IUploadFileProformaResponse } from 'utils/hooks/http/proforma.http';
import { IHttpResponse } from 'utils/http';
import { useNavigate } from 'react-router-dom';
import Modal from 'components/modal/Modal';
import ConfirmationModal from './ConfirmationModal/ConfirmationModal';

type ICsvForm = {
  files: File;
};

type Props = {
  setShowCongratulationMessage: React.Dispatch<React.SetStateAction<boolean>>;
  setShowMessageType: React.Dispatch<React.SetStateAction<string>>;
};

const UploadCsv: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  const navigator = useNavigate();

  const http = useHttp();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState(false);
  const [sendFile, setSendFile] = useState(false);

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    onDrop: (file) => {
      setValue('files', file[0]);
    },
    multiple: false,
    accept: {
      'text/csv': ['.csv', '.xls', '.xlsx'],
      'application/vnd.ms-excel': ['.csv', '.xls', '.xlsx'],
    },
  });

  const {
    setValue,
    watch,
    getValues,
    formState: { errors },
  } = useForm<ICsvForm>();

  useEffect(() => {
    const file = getValues('files');

    if (!file || !file.size) {
      return;
    }

    if (file.name && !sendFile) {
      setOpenModal(true);
    }

    if (sendFile) {
      const formData: FormData = new FormData();

      formData.append('csv', file);
      setIsLoading(true);
      http.proforma
        .uploadProforma(formData)
        .then((res: IHttpResponse<IUploadFileProformaResponse>) => {
          localStorage.setItem('proformaFileInProgress', JSON.stringify(res.data));
          navigator('summary');
        })
        .catch((e) => {
          if (e.message && e.message[0] == 'MAPPING_DOES_NOT_EXIST') {
            props.setShowMessageType('MAPPING_DOES_NOT_EXIST');
            props.setShowCongratulationMessage(true);
          } else {
            //Display another message
            props.setShowMessageType('INVALID_FILE');
            props.setShowCongratulationMessage(true);
          }

          setSendFile(false);
        })
        .finally(() => {
          setIsLoading(false);
          setSendFile(false);
        });
    }
  }, [watch('files'), sendFile]);

  return (
    <div className="proforma-upload-file">
      <div className="title">{t('_upload_your_proforma_file')}</div>
      <form>
        <div {...getRootProps({ className: `${errors.files ? 'content errors' : 'content'}` })} className="upload-btn">
          <div className="icon">
            {isLoading ? <Spinner class="small" /> : <img src={UPLOAD_IMG} alt="" />}
            <input {...getInputProps()} />
          </div>
          <div>{fileRejections.length > 0 ? <div className="error">{t('WRONG_PROFORMA_FILE')}</div> : ''}</div>
          <div className="text">{t('_browse_your_proforma_file')}</div>
        </div>
      </form>

      <Modal
        openModal={openModal}
        setOpenModal={setOpenModal}
        shouldCloseOnOverlayClick={false}
        childrenComponent={<ConfirmationModal setSendFile={setSendFile} file={watch('files')} setOpenModal={setOpenModal} />}
      />
    </div>
  );
};

UploadCsv.displayName = 'UploadCsv';

export default UploadCsv;
