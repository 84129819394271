import React, { useState } from 'react';
import { FieldValues, UseFormRegister } from 'react-hook-form';

import PasswordInputView from './PasswordInput.view';

import { ILoginForm } from 'models/ILoginForm.model';

type Props = {
  placeholder?: string;
  register: UseFormRegister<FieldValues >;
  name: keyof ILoginForm;
  id: string;
  required?: boolean;
  autoComplete?: string
};

const PasswordInput: React.FC<Props> = (props: Props) => {
  const [showPasswordState, setShowPasswordState] = useState<boolean>(false);

  const handleShowPasswordClick = () => {
    setShowPasswordState((prev: boolean) => !prev);
  };

  return (
    <PasswordInputView
      name={props.name}
      register={props.register}
      required={props.required}
      placeholder={props.placeholder}
      showPassword={showPasswordState}
      onShowPasswordClick={handleShowPasswordClick}
      autoComplete={props.autoComplete}
    ></PasswordInputView>
  );
};

PasswordInput.displayName = 'PasswordInput';

export default PasswordInput;
