import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'utils/hooks/redux';
import { useSelector } from 'react-redux';
import { getCatalogData, getIsLoadingCatalogData } from 'store/selectors/catalogs.selector';
import { fetchCatalogData } from 'store/reducers/catalog/catalogs.actions';
import { resetCurrentCatalog } from '../../../../store/slices/catalogs.slice';
import EspaceSupplierView from './EspaceSupplier.view';

const EspaceSupplier: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const loadingCatalogs = useSelector(getIsLoadingCatalogData);
  const catalogsData = useSelector(getCatalogData);

  useEffect(() => {
    dispatch(resetCurrentCatalog());

    if (!Array.isArray(catalogsData) || !catalogsData.length) {
      fetchCatalogs();
    }
  }, [catalogsData]);

  const fetchCatalogs = () => {
    dispatch(fetchCatalogData());
  };

  function handleCatalogClick(id, enabled) {
    if (enabled) {
      navigate(`/catalog/${id}`);
    }

  }

  return <EspaceSupplierView handleCatalogClick={handleCatalogClick} loadingCatalogs={loadingCatalogs} catalogsData={catalogsData} />;
};

EspaceSupplier.displayName = 'EspaceSupplier';

export default EspaceSupplier;
