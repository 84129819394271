import React from 'react';

import { useTranslation } from 'react-i18next';
import './ModalCars.scss';
import VehicleCard from 'components/general-search/vehicle-card/VehicleCard';
import VinPlateCard from 'components/general-search/vin-plate-card/VinPlateCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';

type Props = {
  setOpenModal: (e: boolean) => void;
  link?: string;
};

const ModalCars: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  return (
    <div className="modal-select-car">
      <div className="close-modal" onClick={() => { props.setOpenModal(false); }}><FontAwesomeIcon icon={faClose} /></div>
      <div className="modal-select-title">
        {t('_identification_vehicle')}
      </div>
      <div className="modal-select-content">
        <div className="general-search-car">
          <VinPlateCard setOpenModal={props.setOpenModal} link={props.link} />
          <VehicleCard setOpenModal={props.setOpenModal} link={props.link} />
        </div>
      </div>
    </div>
  );
};

ModalCars.displayName = 'ModalCars';

export default ModalCars;
