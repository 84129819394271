import { IBrand } from 'interfaces/oilBrand';
import React from 'react';
import ItemEshopOilBrandView from './ItemEshopOilBrandView';


type Props = {
  data: IBrand[],
  loading: boolean
  openModalOilBrand: (id: number) => void;
  deleteItem: (id: number) => void

  handleOnChangeIsPartner: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const ItemEshopOilBrand: React.FC<Props> = (props) => {
  return <ItemEshopOilBrandView data={props.data} loading={props.loading} openModalOilBrand={props.openModalOilBrand} deleteItem={props.deleteItem} handleOnChangeIsPartner={props.handleOnChangeIsPartner} />;
};

ItemEshopOilBrand.displayName = ' itemEshop';

export default ItemEshopOilBrand;
