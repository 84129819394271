import React, {PropsWithChildren} from 'react';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import './VideoPlayer.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import ReactPlayer from 'react-player';
/*
React Player docs
https://www.npmjs.com/package/react-player
*/

type Props = {
  url: string;
  previewImage?: string | boolean;
  width?: string;
  height?: string;
  controls?: boolean;
  playing?: boolean;
  volume?: number;
  playIcon?: JSX.Element;
};

const VideoPlayerView: React.FC<PropsWithChildren<Props>> = (props: PropsWithChildren<Props>) => {

  return (
    <div className="video-player-wrapper">
      <ReactPlayer
        width={props.width}
        height={props.height}
        controls={props.controls}
        playing={props.playing}
        volume={props.volume}
        url={props.url}
        light={props.previewImage}
        playIcon={props.playIcon}
        onContextMenu={e => e.preventDefault()}
        config={{
          file: {
            attributes: { controlsList: 'nodownload'},
          }
        }}
      />
    </div>
  );
};

VideoPlayerView.displayName = 'VideoPlayerView';

VideoPlayerView.defaultProps = {
  previewImage: false,
  width: '100%',
  height: '100%',
  controls: true,
  playing: true,
  volume: 0.6, // min 0 - 1 max
  playIcon: <FontAwesomeIcon className="button-play" icon={faPlay}></FontAwesomeIcon>
};

export default VideoPlayerView;
