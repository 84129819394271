import React, { useMemo } from 'react';
import { Column, useTable } from 'react-table';
import Pagination from 'components/pagination/Pagination';
import Spinner from 'components/spinner/Spinner';
import { useTranslation } from 'react-i18next';
import Head from 'components/tables/Cells/Head/Head';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import { IDataStock } from 'models/stock';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './StockManagementTableNew.scss';


type Props = {
  data: IDataStock[]
  loading: boolean
  handlePageClick?: (data) => Promise<void>
  totalPage?: number
  currentPage?: number
  openModalEditItem: (item: IDataStock) => void;
  openModalDeleteItem: (item: IDataStock) => void;
};


const StockManagementTableNew: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { isMobile, isTablet, isDesktop } = useDeviceDetect();

  let hiddenColumns: string[] = [];

  if (isTablet) {
    hiddenColumns = ['id'];
  }

  if (isMobile) {
    hiddenColumns = ['id', 'data_supplier'];
  }

  const columns = useMemo(
    (): Column<IDataStock>[] => [

      {
        Header: <Head text={t('_stock_id').toLocaleUpperCase()}></Head>,
        accessor: 'id',
      },
      {
        Header: t('_stock_supplier').toString().toLocaleUpperCase(),
        accessor: 'data_supplier',
        Cell: (cell) => <span>{cell.row.original.data_supplier.name}</span>,
      },
      {
        Header: <Head text={t('_stock_reference').toLocaleUpperCase()}></Head>,
        accessor: 'reference',
      },
      {
        Header: <Head text={t('_stock_quantity').toLocaleUpperCase()}></Head>,
        accessor: 'quantity',
      },
      {
        Header: <Head text={`${t('_price').toLocaleUpperCase()} €`}></Head>,
        accessor: 'priceEur',
      },
      {
        Header: <Head text={`${t('_price').toLocaleUpperCase()} £`}></Head>,
        accessor: 'priceGbp',
      },
      {
        Header: t('_action').toString().toLocaleUpperCase(),
        Cell: (cell) =>
          < div className="action">
            <span><FontAwesomeIcon icon={faEdit} onClick={() => props.openModalEditItem(cell.row.original)} /></span>
            <span><FontAwesomeIcon icon={faTrash} onClick={() => props.openModalDeleteItem(cell.row.original)} /></span>
          </div>
      },
    ],
    [isMobile, isTablet, isDesktop],
  );


  const initialState = hiddenColumns ? {
    'hiddenColumns': hiddenColumns
  } : {};

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data: props.data,
    initialState: initialState
  });

  return (
    <div className="table-wrapper stock-table-wrapper">

      <table {...getTableProps()}>
        <thead className="headings">
          {headerGroups.map((headerGroup, index) => {
            return (
              <tr key={headerGroup.headers[index].id} className="row">
                {headerGroup.headers.map((column) => {
                  const { key, ...restColumn } = column.getHeaderProps();

                  return (
                    <th key={key} {...restColumn} className="heading">
                      {column.render('Header')}
                    </th>
                  );
                })}
              </tr>
            );
          })}
        </thead>

        {
          props.loading ?
            <tbody className="body">
              <tr className="no-data">
                <td colSpan={9}>
                  <Spinner class="extra-small" />
                </td>
              </tr>
            </tbody> :
            props.data.length ?
              <tbody {...getTableBodyProps} className="body">
                {rows.map((row) => {
                  prepareRow(row);
                  const { key, ...restRowProps } = row.getRowProps();

                  return (

                    <tr key={key} {...restRowProps} className="row-stock">
                      {row.cells.map((cell) => {
                        const { key, ...restCellProps } = cell.getCellProps();

                        return (
                          <td key={key} {...restCellProps} className="cell">
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>

                  );
                })}
              </tbody>
              :
              <tbody className="body">
                <tr className="no-data">
                  <td colSpan={9}>{t('_no_data')}</td>
                </tr>
              </tbody>
        }
      </table>
      <div>
        {props.totalPage! > 1 && (
          <Pagination pageCount={props.totalPage!} handlePageClick={props.handlePageClick!} forcePage={props.currentPage!} />
        )}
      </div>
    </div>

  );
};

StockManagementTableNew.defaultProps = {
  currentPage: 1
};


StockManagementTableNew.displayName = 'StockManagementTableNew';

export default StockManagementTableNew;
