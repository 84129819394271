import React, { useEffect } from 'react';
import './TabStyle.scss';
import { useNavigate } from 'react-router-dom';

type Props = {
  title: string;
  link: string;
  selectedTab: string;

  setSelectedIndex: (index: number) => void;
  index: number;
};

const TabTitle: React.FC<Props> = (props) => {

  const navigate = useNavigate();

  function handleClick() {
    props.setSelectedIndex(props.index);
    navigate(props.selectedTab);

  }

  useEffect(() => {
    if (props.link.includes(props.selectedTab)) {
      props.setSelectedIndex(props.index);

    }

  }, [props.link]);

  return (
    <div className={`tab-item ${props.link.includes(props.selectedTab) ? 'active' : ''}`} onClick={handleClick} >
      {props.title}
    </div>
  );
};

TabTitle.displayName = 'TabTitle';

export default TabTitle;
