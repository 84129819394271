import React, { useEffect, useState } from 'react';
import { IOption } from '../../../../interfaces/select';
import { IEquivalencesV2Response, IOem } from '../../../../models/car-parts.model';
import Select, { SingleValue } from 'react-select';
import Spinner from '../../../spinner/Spinner';
import './EquivalenceOEMV2.scss';
import { useTranslation } from 'react-i18next';
import SwiperSlider from 'components/SwiperSlider/SwiperSlider';
import { SwiperSlide } from 'swiper/react';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import EquivalenceOEMItemV2 from './EquivalenceOEMItemV2/EquivalenceOEMItemV2';

type Props = {
  loading: boolean;
  equivalencesResponse?: IEquivalencesV2Response;
  isLoading?: boolean;
};

const EquivalenceOEMV2: React.FC<Props> = (props) => {
  const [options, setOptions] = useState<IOption[]>([]);
  const [selectedOption, setSelectedOption] = useState<SingleValue<IOption>>();
  const [equivalances, setEquivalances] = useState<IOem[]>([]);
  const [equivalancesFiltered, setEquivalancesFiltered] = useState<IOem[]>([]);
  const { t } = useTranslation();
  const { isMobile, isTablet } = useDeviceDetect();

  useEffect(() => {
    getEquivalences();
  }, [props.equivalencesResponse]);


  const getEquivalences = async () => {
    if (!props.equivalencesResponse) {
      return;
    }


    const optionsFromResponse: IOem[] = props.equivalencesResponse.oem;

    if (optionsFromResponse) {
      const optionFromResponseTransform = transformOptions(optionsFromResponse);

      setOptions(optionFromResponseTransform);
    }

    setEquivalances(props.equivalencesResponse.oem);
    setEquivalancesFiltered(props.equivalencesResponse.oem);
  };

  const transformOptions = (optionsFromResponse: IOem[]) => {
    if (!optionsFromResponse) {
      return [];
    }

    const uniqueOptions = optionsFromResponse
      .map((item) => ({
        label: item.manufacturer_name_group,
        value: String(item.manufacturer_group_id),
      }))
      .filter((item, index, self) => (
        self.findIndex((i) => i.label === item.label && i.value === item.value) === index
      ));

    return uniqueOptions;
  };


  useEffect(() => {

    if (!props.equivalencesResponse) {
      return;
    }

    if (selectedOption == null) {
      setEquivalancesFiltered(props.equivalencesResponse.oem);
    } else {
      const filteredEquivalences = equivalances.filter((e) => String(e.manufacturer_group_id) === String(selectedOption?.value));

      setEquivalancesFiltered(filteredEquivalences);
    }


  }, [selectedOption]);


  return (
    <div className="equivalance-oem-container">
      {props.loading ? (
        <Spinner />
      ) : options.length ? (
        <div className="content">
          <div className="filters">
            <div className="manufacturers">
              <p className="select-title">{t('_ref_contructeur_oe')}</p>
              <Select
                className="select-element"
                placeholder={t('_ref_contructeur_oe')}
                value={selectedOption}
                isClearable
                onChange={(val) => {
                  setSelectedOption(val);
                }}
                options={options}
                isSearchable={false}
              />
            </div>
          </div>
          <div className="list">
            {isMobile || isTablet ? (
              <SwiperSlider slidesPerView="auto" isLoading={props.isLoading} centeredSlides={false}>
                <div>
                  {equivalancesFiltered.map((item) => {
                    return (
                      <SwiperSlide className="swiper-slide parts-view-equivalence" key={item.short_name + item.manufacturer_group_id}>
                        <EquivalenceOEMItemV2 item={item} key={item.short_name + item.manufacturer_group_id} />
                      </SwiperSlide>
                    );
                  })}
                </div>
              </SwiperSlider>
            ) : (
              <div className="list-item">
                {equivalancesFiltered.map((item) => {
                  return <EquivalenceOEMItemV2 item={item} key={item.short_name + item.manufacturer_group_id} />;
                })}
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="no-data">
          <p>{t('_no_data')}</p>
        </div>
      )}
    </div>
  );
};

EquivalenceOEMV2.displayName = 'EquivalenceOEMV2';

export default EquivalenceOEMV2;
