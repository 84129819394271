import React from 'react';
import './AccountLayout.scss';
import { Outlet } from 'react-router-dom';
import MenuSidebar from '../../../../components/MenuSidebar/MenuSidebar';

const AccountLayout: React.FC = () => {
  return (
    <div className="my-account-wrapper">
      <MenuSidebar />
      <div className="main-container">
        <Outlet />
      </div>
    </div>
  );
};

AccountLayout.displayName = 'AccountLayout';

export default AccountLayout;
