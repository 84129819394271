import React from 'react';
import { useTranslation } from 'react-i18next';
import './OrderRemaining.scss';

import { NavLink } from 'react-router-dom';

import ORDER_IN_PROGRESS_IMG from 'assets/images/order_in_progress.svg';

type Props = {};

const OrderRemaining: React.FC<Props> = () => {
  const { t } = useTranslation();

  return (
    <div className="order-in-progress">
      <NavLink to="/order/remaining">
        <div className="title">{t('_order_availability')}</div>
        <div className="upload-btn">
          <div className="icon">
            <img src={ORDER_IN_PROGRESS_IMG} alt="" />
          </div>
          <div className="text">{t('_overall_turnover_disponible')}</div>
        </div>
      </NavLink>
    </div>
  );
};

OrderRemaining.displayName = 'OrderRemaining';

export default OrderRemaining;
