import React, { useEffect } from 'react';

import useLocalStorage from 'use-local-storage';
import { RootState } from 'store/store';
import { useSelector } from 'react-redux';
import './App.scss';

interface Props {
  routes: JSX.Element;
}

const AppView: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  const { user } = useSelector((state: RootState) => state.userData);

  return (
    <React.Suspense
      fallback={
        <div className="loading">
          <div className="sk-spinner sk-spinner-pulse"></div>
        </div>
      }
    >
      <div className={user?.ui_theme ? user.ui_theme : 'theme-default'}>
        <div className="app-style">
          {props.routes}
        </div>
      </div>
    </React.Suspense>
  );
};

AppView.displayName = 'AppView';
AppView.defaultProps = {};

export default React.memo(AppView);
