import React from 'react';

import './RetailerMessagesPage.scss';
import DefaultMessages from 'components/messages/AutomotorMessages/DefaultMessages';
import {MessagesContextProvider} from 'context/MessagesContext';
import MessagesAutomotorNavbar
  from 'applications/automotor/AutomotorMessagesPage/messages-automotor-navbar/MessagesAutomotorNavbar';
import {useTranslation} from 'react-i18next';
import MessageRecipientInfo from '../../../../components/messages/MessageRecipientInfo/MessageRecipientInfo';
import MessagesSupplierInfo from '../../components/messages/MessagesSupplierInfo/MessagesSupplierInfo';

const RetailerMessagesPage: React.FC= () => {
  const {t} = useTranslation();

  return (
    <div className="retailer-messages-page">
      <MessagesAutomotorNavbar title={t('_my_supplier')}/>
      <MessagesSupplierInfo/>
      <MessageRecipientInfo loading={false} recipientName={t('_my_supplier')}/>
      <MessagesContextProvider>
        <DefaultMessages/>
      </MessagesContextProvider>
    </div>
  );
};

RetailerMessagesPage.displayName = 'RetailerMessagesPage';

export default RetailerMessagesPage;