
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import TextInput from 'components/inputs/TextInput/TextInput';
import Spinner from 'components/spinner/Spinner';
import i18n from 'i18n';
import { IDetailsListFinancial } from 'interfaces/financial';
import moment from 'moment';
import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import useHttp from 'utils/hooks/useHttp';
import { useLocaleDatePicker } from 'utils/localeDatePicker';
import { notify } from '../../../../../utils/marketplace';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';


type Props = {
  setLoading: (loading: boolean) => void;
  setData: (data: IDetailsListFinancial[]) => void;
  setPageCount: (pageCount: number) => void;
  setLoadingContent: (loadingContent: boolean) => void;
  setFiter,
  setForcePage: (forcePage: number) => void;
  loadingExportMobile: boolean;
  setLoadingExportMobile: (e: boolean) => void;
  setIsActive: (e: boolean) => void;
}

const Filter: React.FC<Props> = (props) => {

  const { reset, getValues, register, handleSubmit } = useForm();
  const [openDate, setOpenDate] = useState(false);
  const [loadingExport, setLoadingExport] = useState<boolean>(false);
  const http = useHttp();
  const { t } = useTranslation();
  const dateRef = useRef() as MutableRefObject<HTMLDivElement>;
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [filterStartDate, setFilterStartDate] = useState<string | null>(null);
  const [filterEndDate, setFilterEndDate] = useState<string | null>(null);
  const [valueDate, setValueDate] = useState<string | null>(null);
  const { isMobile } = useDeviceDetect();
  const { user } = useSelector((state: RootState) => state.userData);

  const showM3User = user?.is_m3_connected;


  const handleExportFile = () => {

    setLoadingExport(true);
    const data = getValues();


    if (data.id_folder === '') {
      delete data.id_folder;
    }


    const param = {
      ...data, ...{
        page: 1,
        per_page: 10
      }
    };


    if (filterStartDate) {
      param['start_date'] = filterStartDate;
    }

    if (filterEndDate) {
      param['end_date'] = filterEndDate;
    }


    http.financialHttp.export(param).then((data) => {
      const element = document.createElement('a');

      const file = new Blob(
        [data.data],
        { type: data.type },
      );

      element.href = URL.createObjectURL(file);
      element.download = `${t('_financial_export')} - ${moment().format('DD.MM.YYYY')}.xlsx`;
      element.click();
    }).
      catch(() => {
        notify(t('_file_could_not_be_downloaded'));
      })
      .finally(() => {
        setLoadingExport(false);

        if (props.loadingExportMobile) {
          props.setLoadingExportMobile(false);
        }
      });


  };

  const resetFilter = () => {
    props.setFiter({});
    props.setForcePage(1);
    setFilterStartDate(null);
    setFilterEndDate(null);
    setValueDate(null);
    setStartDate(new Date());
    setEndDate(null);
    reset({ id_folder: '' });

    props.setLoading(true);
    props.setForcePage(1);
    const params = {
      page: 1,
      per_page: 10
    };

    http.financialHttp.getList(params).then((res) => {

      props.setData(res.data.data);
      props.setPageCount(res.data.total_pages);
    }).finally(() => {
      props.setLoading(false);
      props.setLoadingContent(false);

    });

  };


  function onDateChange(dates) {

    const [start, end] = dates;

    setStartDate(start);
    setEndDate(end);

    if (start && end) {
      setValueDate(`${moment(start).format('DD/MM/YYYY')} - ${moment(end).format('DD/MM/YYYY')}`);
      setFilterStartDate(`${moment(start).format('DD/MM/YYYY')}`);
      setFilterEndDate(`${moment(end).format('DD/MM/YYYY')}`);
    }
    else {

      if (start) {
        setFilterStartDate(`${moment(start).format('DD/MM/YYYY')}`);
        setValueDate(`${moment(start).format('DD/MM/YYYY')}`);

      }

      if (end) {
        setFilterEndDate(`${moment(end).format('DD/MM/YYYY')}`);
        setValueDate(`${moment(end).format('DD/MM/YYYY')}`);

      }
    }
  }

  const onSubmit = async () => {
    props.setLoading(true);
    props.setForcePage(1);
    props.setIsActive(false);
    const data = getValues();


    if (data.id_folder === '') {
      delete data.id_folder;
    }


    const param = {
      ...data, ...{
        page: 1,
        per_page: 10
      }
    };


    if (filterStartDate) {
      param['start_date'] = filterStartDate;
    }

    if (filterEndDate) {
      param['end_date'] = filterEndDate;
    }


    await http.financialHttp.getList(param).then((res) => {

      props.setData(res.data.data);
      props.setPageCount(res.data.total_pages);
      props.setFiter(param);
    }).finally(() => {
      props.setLoading(false);
      props.setLoadingContent(false);

    });
  };

  useEffect(() => {

    if (props.loadingExportMobile) {
      handleExportFile();
    }

  }, [props.loadingExportMobile]);
  useLocaleDatePicker(i18n.language);

  return (
    <div className="filter">
      <div className="filter-header">
        <div className="filter-link-btn">
          <button className="export-btn" onClick={() => handleExportFile()} disabled={loadingExport}> {loadingExport ? <Spinner class="extra-small" /> : <FontAwesomeIcon icon={faFileDownload} className="icon" />} {t('_export')} </button>
        </div>
        <div className="filter-title">{t('_advanced_search')}</div>
        <div className="filter-reliquats">

        </div>
      </div>
      <form onSubmit={handleSubmit(() => onSubmit())}>
        <div className="filter-body">

          <div className="filter-input-item">
            <div className={`react-picker-dropdown ${openDate ? 'disable' : ''}`} onClick={() => setOpenDate(true)}>
              <TextInput placeholder={t('_select_date')} defaultValue={valueDate ? valueDate : ''} readOnly />
            </div>
            {openDate && (
              <div className="date-picker" ref={dateRef}>
                <ReactDatePicker
                  dateFormat="YYYY-MM-DD"
                  selected={startDate}
                  onChange={onDateChange}
                  startDate={startDate}
                  endDate={endDate}
                  monthsShown={isMobile ? 1 : 2}
                  selectsRange
                  inline
                  onClickOutside={() => setOpenDate(false)}
                  locale={i18n.language.split('-')[0]}
                />
              </div>
            )}
          </div>

          <div className="filter-input-item">
            <TextInput {...register('id_folder')} placeholder={t('_id_folder')} disabled={showM3User} />
          </div>

          <DefaultButton text={t('_filter')} type="submit" />
          <DefaultButton text={t('_reset')} red onClick={() => { resetFilter(); props.setIsActive(false); }} type="button" />
        </div>
      </form>
    </div>
  );
};

Filter.displayName = 'Filter';

export default Filter;
