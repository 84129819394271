import React from 'react';

import { IGraphicsLinkedSpareParts, IPartSetQualCols, ISpareParts } from 'utils/rmi/interface/graphics.interface';

import Spinner from 'components/spinner/Spinner';
import { useTranslation } from 'react-i18next';


type Props = {
  
  linkedSpareParts: IGraphicsLinkedSpareParts[] | undefined;
};

const SpareParts: React.FC<Props> = (props) => {
 
  const {t} = useTranslation();


  return (
    
    <div>
      {
        !props.linkedSpareParts ? <Spinner class="extra-small"/> : 

          props.linkedSpareParts.length ?
            <ul>
              {
                props.linkedSpareParts.map((value) => {
                  return (
                    value.PartSetQualCols.map((item: IPartSetQualCols) => {
                      return  (
                        item.Parts.map((i: ISpareParts, index) => {
                          return <div key={index}>
                            <li key={index} >
                              <p> {`${i.ItemMpText}`}</p>
                              <p> {`${i.PartQualColText}`}</p>
                              <span>{`${i.ItemMpText}; [${t('PART_NO')} : ${i.PartNo}]`}</span>
                        
                            </li>
                          </div>;
                        })
                      );
                    })
                
                  );
                })
              }
            </ul>
            : 
            <div className="no-data"> 
              {t('_no_data')}
            </div>
      }

    </div>
 
  );

};

SpareParts.displayName = 'SpareParts';

export default SpareParts;