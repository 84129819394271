import i18n from 'i18n';
import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { RootState } from 'store/store';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import { getCurrentLanguageRMI } from 'utils/rmi/rmi-utils/rmi-utils';

type Props = {
  control?;
  setValue?
};
type ISelect = {
  value: number;
  label: string;
};

const RegistrationDateSelect: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();
  const formatter = new Intl.DateTimeFormat(getCurrentLanguageRMI(i18n.language), { month: 'short' });
  const [years, setYears] = useState<ISelect[]>();
  const { car } = useSelector((state: RootState) => state.carSelected);
  const { isMobile, isTablet } = useDeviceDetect();

  const fillYears = (startYear: number, endYear: number): void => {
    const years: Array<ISelect> = [];

    for (let i = endYear; i >= startYear; i -= 1) {
      years.push({ value: i, label: String(i) });
    }

    setYears(years);
  };

  const fillMonths = (): ISelect[] => {
    const months: Array<ISelect> = [];

    for (let i = 0; i <= 11; i += 1) {
      months.push({
        value: i,
        label: formatter.format(new Date(currentYear, i)), // , isDisabled: new Date(selectedYear, i).getTime() > Date.now(),
      });
    }

    return months;
  };

  useEffect(() => {
    fillYears(Number(car?.startYear) || Number(currentYear) - 20, Number(car?.endYear) || Number(currentYear));
    props.setValue('year', { label: car?.startYear, value: Number(car?.startYear) });
    props.setValue('month', { label: car?.startMonth, value: Number(car?.startMonth) });
  }, [i18n.language, car, currentYear]);

  return (
    <div className="maintenance-dropdown-date">
      <Controller
        control={props.control}
        name="month"
        render={({ field: { onChange, value } }) => (
          <Select
            value={value}
            className="configuration-dropdown"
            onChange={(val) => {
              onChange(val);
            }}
            options={fillMonths()}
            placeholder={t('_month')}
            menuPlacement="auto"
            styles={{
              menu: provided => ({ ...provided, zIndex: 50 })
            }}
            isSearchable={!isMobile && !isTablet}
          />
        )}
      />
      <Controller
        control={props.control}
        name="year"
        render={({ field: { onChange, value } }) => (
          <Select
            value={value}
            className="configuration-dropdown"
            onChange={(val) => {
              onChange(val);
            }}
            options={years}
            placeholder={t('_year')}
            menuPlacement="auto"
            menuPosition="fixed"
            styles={{ menuPortal: base => ({ ...base, zIndex: 50 }) }}
            isSearchable={!isMobile && !isTablet}
          />
        )}
      />
    </div>
  );
};

export default RegistrationDateSelect;
