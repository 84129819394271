
import React, { ReactElement, SVGProps, useState } from 'react';
import { setLoadingPriceCheckedStatus } from 'store/slices/buttonPriceCheckedStatus.slice';
import './HidePrice.scss';
import { useAppDispatch } from 'utils/hooks/redux';
import RoundedIcon from 'components/buttons/RoundedIcon/RoundedIcon';
import { faEuro } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const HidePrice: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [priceCheckedStatus, setPriceCheckedStatus] = useState<boolean>(
    localStorage.getItem('priceCheckedStatus') != null ? (localStorage.getItem('priceCheckedStatus') == 'true' ? true : false) : false,
  );

  // function renderIcon(icon: React.ComponentType<SVGProps<SVGSVGElement>> | ReactElement) {
  //   if (React.isValidElement(icon)) {
  //     return icon;
  //   }

  //   const Icon = icon as React.ComponentType<SVGProps<SVGSVGElement>>;

  //   return <Icon className="automotor-nav-icon" width={20} height={20} />;
  // }

  return (
    <div className="switch-hide">
      <div className="title">
        <FontAwesomeIcon icon={faEuro} />   {t('_hide_price')}
      </div>

      <label className="switch">
        <input
          type="checkbox"
          checked={priceCheckedStatus}
          onChange={(e) => {
            localStorage.setItem('priceCheckedStatus', String(e.target.checked)), setPriceCheckedStatus(e.target.checked);
            dispatch(setLoadingPriceCheckedStatus(e.target.checked));
          }}
        />
        <span className="switch_slider round"></span>
      </label>
    </div>
  );
};

export default HidePrice;

