import React from 'react';
import Head from 'components/tables/Cells/Head/Head';
import { IDetailsList } from 'interfaces/remaining';

import { t } from 'i18next';
import { Column } from 'react-table';
import ExpandButton from 'components/tables/TableMoreInformation/ExpandButton/ExpandButton';
import CURRENCY from 'utils/constants/currency';


export function columReceived(): Column<IDetailsList>[] {
  const user = JSON.parse(localStorage.getItem('userData')!);

  return [
    {
      id: 'expander',
      // eslint-disable-next-line react/destructuring-assignment
      Header: '',
      width: 15,
      // eslint-disable-next-line react/destructuring-assignment
      Cell: ({ row }) => {
        return (
          //Applying the toggle expander props i.e onClick, style and title
          <ExpandButton row={row} />
        );
      }
    },
    // {
    //   Header: <Head text={t('_code')}></Head>,
    //   accessor: 'code',


    // },
    // {
    //   Header: <Head text={t('_id_folder')}></Head>,
    //   accessor: 'id_folder',
    // },


    {
      Header: <Head text={t('_reference')}></Head>,
      accessor: 'reference',
      Footer: <Head text={t('_work_total')}></Head>

    },

    {
      Header: <Head text={t('_reference_dem')}></Head>,
      accessor: 'reference_dem',
    },
    {
      Header: <Head text={t('_date')}></Head>,
      accessor: 'date'

    },
    {
      Header: <Head text={t('_id_folder')}></Head>,
      accessor: 'id_folder',

    },
    // {
    //   Header:<Head text={t('_date')}></Head> ,
    //   accessor: 'date',

    // },
    {
      Header: <Head text={t('_code_fou')}></Head>,
      accessor: 'code_fou',

    },

    // {
    //   Header: <Head text={t('_description_dem')}></Head> ,
    //   accessor: 'description_dem',

    // },
    {
      Header: <Head text={t('_price')}></Head>,
      accessor: 'price',
      Cell: (cell) => (<div className="row-aling-right">{cell.row.original.price ? `${CURRENCY[user!.currency]}${cell.row.original.price}` : ''}</div>),
      minWidth: 50

    },
    {
      Header: <Head text={t('_quantity')}></Head>,
      accessor: 'quantity',

    },
    {
      Header: <Head text={t('_total_price')}></Head>,
      accessor: 'total_price',
      Cell: (cell) => (<div className="row-aling-right">{cell.row.original.total_price ? `${CURRENCY[user!.currency]}${cell.row.original.total_price}` : ''}</div>),
      Footer: cell => {

        return <React.Fragment> <span>{cell.sum[0]['total_price'] ? `${CURRENCY[user!.currency]}${cell.sum[0]['total_price']}` : ''}</span></React.Fragment>;
      },
    },
    {
      Header: <Head text={t('_quantity_invoiced')}></Head>,
      accessor: 'quantity_invoiced',

    },
    {
      Header: <Head text={t('_total_price_invoiced')}></Head>,
      accessor: 'total_price_invoiced',
      Cell: (cell) => (<div className="row-aling-right">{cell.row.original.total_price_invoiced ? `${CURRENCY[user!.currency]}${cell.row.original.total_price_invoiced}` : ''}</div>),
      Footer: cell => {

        return <React.Fragment> <span>{cell.sum[0]['total_price_invoiced'] ? `${CURRENCY[user!.currency]}${cell.sum[0]['total_price_invoiced']}` : ''}</span> </React.Fragment>;
      },
    },


    {
      Header: <Head text={t('_quantity_to_invoiced')}></Head>,
      accessor: 'quantity_to_invoiced',

    },
    {
      Header: <Head text={t('_total_price_to_invoiced')}></Head>,
      accessor: 'total_price_to_invoiced',
      Cell: (cell) => (<div className="row-aling-right">{cell.row.original.total_price_to_invoiced ? `${CURRENCY[user!.currency]}${cell.row.original.total_price_to_invoiced}` : ''}</div>),
      Footer: cell => {

        return <React.Fragment> <span>{cell.sum[0]['total_price_to_invoiced'] ? `${CURRENCY[user!.currency]}${cell.sum[0]['total_price_to_invoiced']}` : ''}</span></React.Fragment>;
      },
    },
    {
      Header: <Head text={t('_quantity_not_received')}></Head>,
      accessor: 'quantity_not_received',

    },
    {
      Header: <Head text={t('_total_price_not_received')}></Head>,
      accessor: 'total_price_not_received',
      Cell: (cell) => (<div className="row-aling-right">{cell.row.original.total_price_not_received ? `${CURRENCY[user!.currency]}${cell.row.original.total_price_not_received}` : ''}</div>),
      Footer: cell => {

        return <React.Fragment> <span>{cell.sum[0]['total_price_not_received'] ? `${CURRENCY[user!.currency]}${cell.sum[0]['total_price_not_received']}` : ''}</span></React.Fragment>;
      },
    }


  ];

}


export function columNotReceived(): Column<IDetailsList>[] {
  const user = JSON.parse(localStorage.getItem('userData')!);

  return [
    // {
    //   Header: <Head text={t('_code')}></Head>,
    //   accessor: 'code',

    // },

    // {
    //   Header: <Head text={t('_id_folder')}></Head>,
    //   accessor: 'id_folder',
    // },

    {
      Header: <Head text={t('_reference')}></Head>,
      accessor: 'reference',

    },

    {
      Header: <Head text={t('_reference_dem')}></Head>,
      accessor: 'reference_dem',

    },
    {
      Header: <Head text={t('_code_fou')}></Head>,
      accessor: 'code_fou',

    },
    // {
    //   Header: <Head text={t('_description_dem')}></Head> ,
    //   accessor: 'description_dem',

    // },
    {
      Header: <Head text={t('_price')}></Head>,
      accessor: 'price',
      Cell: (cell) => (<div className="row-aling-right">{cell.row.original.price ? `${CURRENCY[user!.currency]}${cell.row.original.price}` : ''}</div>),
      minWidth: 30

    },
    {
      Header: <Head text={t('_quantity')}></Head>,
      accessor: 'quantity',

    },
    {
      Header: <Head text={t('_total_price')}></Head>,
      accessor: 'total_price',
      Cell: (cell) => (<div className="row-aling-right">{cell.row.original.total_price ? `${CURRENCY[user!.currency]}${cell.row.original.total_price} ` : ''}</div>),

    },
    {
      Header: <Head text={t('_quantity_invoiced')}></Head>,
      accessor: 'quantity_invoiced',

    },
    {
      Header: <Head text={t('_total_price_invoiced')}></Head>,
      accessor: 'total_price_invoiced',
      Cell: (cell) => (<div className="row-aling-right">{cell.row.original.total_price_invoiced ? `${CURRENCY[user!.currency]}${cell.row.original.total_price_invoiced}` : ''}</div>),

    },

    {
      Header: <Head text={t('_quantity_to_invoiced')}></Head>,
      accessor: 'quantity_to_invoiced',
      Cell: (cell) => (<div className="row-aling-right">{cell.row.original.quantity_to_invoiced + cell.row.original.quantity_not_received}</div>),

    },
    {
      Header: <Head text={t('_total_price_to_invoiced')}></Head>,
      accessor: 'total_price_to_invoiced',
      Cell: (cell) => (<div className="row-aling-right">{
        cell.row.original.total_price_to_invoiced || cell.row.original.total_price_not_received ? `${CURRENCY[user!.currency]}${cell.row.original.total_price_to_invoiced + cell.row.original.total_price_not_received}` : ''}</div>),

    },

    // {
    //   Header:<Head text={t('_date')}></Head> ,
    //   accessor: 'date',

    // },

  ];

}
