import React, { useState } from 'react';
import OrderInfo from './OrderInfo/OrderInfo';
import OrderTable from './OrderTable/OrderTable';
import './OrderView.scss';
import OrderMessages from '../../components/messages/OrderMessages/OrderMessages';
import { MessagesContextProvider } from '../../context/MessagesContext';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import { faCircleArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


type Props = {}

const OrderView: React.FC<Props> = () => {
  const [supplierName, setSupplierName] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const { isMobile } = useDeviceDetect();
  const hiddenColumns = isMobile ? { hiddenColumns: ['name'] } : {};
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className="order-view-page">
      <div className="order-client-back" onClick={() => navigate(-1)}>
        <FontAwesomeIcon icon={faCircleArrowLeft} className="icon" />
        {t('_return_order')}
      </div>

      <OrderInfo />
      <div className="order-view-body">
        <div className="order-table">
          <OrderTable loading={loading} setLoading={setLoading} setSupplierName={setSupplierName} hiddenColumns={hiddenColumns} />
        </div>
        <div className="chat">
          <MessagesContextProvider>
            <OrderMessages loadingDataFromOrderList={loading} supplierName={supplierName} />
          </MessagesContextProvider>
        </div>
      </div>
    </div>
  );
};

export default OrderView;