import React from 'react';
import SupplierCartTable from '../SupplierCartTable/SupplierCartTable';

import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import Modal from 'components/modal/Modal';
import { contentStyle } from './CheckoutModal/ContentStyleModal';
import './Content.scss';
import CheckoutModal from './CheckoutModal/CheckoutModal';
import { useTranslation } from 'react-i18next';

type Props = {
  dlnr?: string,
  handleCheckout?: () => void,
  loading?: boolean
  openModal?: boolean
  setOpenModal?: (openModal) => void
  setTabIndex?: (number) => void
  getSuppliers?
  supplierName?: string;
  orderId: null | number;
};

const ContentView: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  return (
    <div className="sellers-content">
      <div className="table">
        <SupplierCartTable getSuppliers={props.getSuppliers} dlnr={props.dlnr} ></SupplierCartTable>
        <div className="row">
          <p className="title">{t('_internal_cmd')}</p>
          <input type="text" className="input" />
          <div className="checkout-button">
            <DefaultButton isloading={props.loading} text={t('_confirm_order')} onClick={props.handleCheckout} red />
          </div>
        </div>
      </div>
      {/*<div className={'chat']}>*/}
      {/*  <CartMessages dlnr={props.dlnr} supplierName={props.supplierName}/>*/}
      {/*</div>*/}
      <Modal
        setTabIndex={props.setTabIndex}
        getSuppliers={props.getSuppliers}
        openModal={props.openModal!}
        setOpenModal={props.setOpenModal!}
        childrenComponent={<CheckoutModal orderId={props.orderId} supplierName={props.supplierName} getSuppliers={props.getSuppliers} setTabIndex={props.setTabIndex} setOpenModal={props.setOpenModal} />}
        contentStyle={contentStyle}
      />
    </div >
  );
};

ContentView.displayName = 'ContentView';

export default ContentView;
