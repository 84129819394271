import React, { useEffect, useRef, useState } from 'react';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import { AccordionData } from '../../../interfaces/accordion';
import { getRefValue } from '../../../utils/utils';
import './AccordionItem.scss';

type Props = {
  data: AccordionData;
  isOpen: boolean;
  btnOnClick: () => void;
}

const AccordionItem: React.FC<Props> = (props) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);
  const { isMobile } = useDeviceDetect();

  useEffect(() => {
    if (props.isOpen) {
      const contentEl = getRefValue(contentRef);

      setHeight(contentEl.scrollHeight);
    } else {
      setHeight(0);
    }
  }, [props.isOpen, props.data, isMobile]);

  return (
    <li className={`accordion-item ${props.isOpen ? 'active' : ''}`}>
      <h2 className="accordion-item-title">
        <button className="accordion-item-btn" onClick={props.btnOnClick}>
          {props.data.title}
        </button>
      </h2>
      {/*<div className="accordion-item-container" style={isMobile ? { height } : {}}>*/}
      <div className="accordion-item-container" style={{ height }}>

        <div ref={contentRef} className="accordion-item-content">
          {props.data.content}
        </div>
      </div>
    </li >
  );
};

AccordionItem.displayName = 'AccordionItem';

export default AccordionItem;
