import React, { useEffect, useRef } from 'react';
import { faClose, faRedo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import LinkButton from 'components/buttons/LinkButton/LinkButton';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { Trans, useTranslation } from 'react-i18next';
import { useOnClickOutside } from 'utils/hooks/useOnClickOutside';
import { useSearchParams } from 'react-router-dom';
import './CatalogGenericFilter.scss';

type Props = {
  setOpenFilter?: (boolean) => void;
  openFilter?: boolean;
  filterData?;
  getData?;
  filterParams: { name: string; label?: string }[];
};

const CatalogGenericFilter: React.FC<Props> = (props) => {
  const { control, getValues, reset, setValue } = useForm();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const filterRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  // useOnClickOutside(filterRef, () => {
  //   props.setOpenFilter?.(false);
  // });

  const getOptions = (key) => {
    if (props.filterData && key in props.filterData) {
      return (
        props.filterData[key]?.map((item) => ({
          label: item.key,
          value: item.value,
        })) || []
      );
    }
  };

  function handleClick() {
    if (!getValues()) {
      return;
    }

    const params = props.filterParams.reduce((params, tyreParam) => {
      return {
        ...params,
        [tyreParam.name]: Array.isArray(getValues()[tyreParam.name])
          ? getValues()[tyreParam.name]?.map(({ value }) => value)
          : getValues()[tyreParam.name]?.value && [getValues()[tyreParam.name]?.value],
      };
    }, {});

    const validParams = Object.entries(params).reduce((acc, [key, value]) => {
      if (!value) {
        return acc;
      }

      acc[key] = value;

      return acc;
    }, {});

    props.getData(validParams);
    props.setOpenFilter!(false);
  }

  function handleReset() {
    reset(
      props.filterParams.reduce((reset, tyreParam) => {
        return { ...reset, [tyreParam.name]: '' };
      }, {}),
    );

    props.getData({});
    props.setOpenFilter!(false);
  }

  useEffect(() => {
    if (!props.filterData) {
      return;
    }

    props.filterParams.forEach((tyreParam) => {
      props.filterData[tyreParam.name]?.forEach((item) => {
        if (searchParams.getAll(tyreParam.name).includes(item.value)) {
          setValue(tyreParam.name, { label: item.key, value: item.value });
        } else {
          setValue(tyreParam.name, '');
        }
      });
    });
  }, [props.filterData]);

  return (
    <div ref={filterRef} className={`generic-parts-filter ${props.openFilter ? 'active' : ''}`}>
      <div className="header">
        <div className="buttons-container">
          <LinkButton title={t('_reset')} icon={faRedo} onClick={handleReset} />
        </div>
        <div onClick={() => props.setOpenFilter!(false)}>
          <FontAwesomeIcon className="icon" icon={faClose} />
        </div>
      </div>
      <div className="title">
        <Trans i18nKey="_filter_results" components={{ b: <strong /> }} />
      </div>
      <div className="filters">
        <div className="dropdowns-container">
          {props.filterParams.map((filter) => (
            <div key={filter.name} className="dropdown-item">
              <label className="dropdown-label">{t(`_${filter.label || filter.name}`)}</label>
              <Controller
                control={control}
                name={filter.name}
                render={({ field: { onChange, value } }) => (
                  <Select
                    value={value}
                    isMulti
                    className="parts-filter-select-dropdown"
                    onChange={(val) => {
                      onChange(val);
                    }}
                    isDisabled={value?.is_amerigo}
                    options={getOptions(filter.name)}
                    placeholder={t(`_${filter.label || filter.name}`)}
                    isClearable={false}
                    menuPlacement="auto"
                    menuPosition="fixed"
                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 150 }) }}
                  />
                )}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="apply-btn">
        <DefaultButton text={t('_apply')} onClick={() => handleClick()} />
      </div>
    </div>
  );
};

export default CatalogGenericFilter;
