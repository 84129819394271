import React, { useEffect, useState } from 'react';
import './CartAvailability.scss';

type Props = {
  limit: number;
  isAvailable: boolean;
  unspecified?: boolean;
  available?
  value?
};
const CartAvailabilityView: React.FC<Props> = (props: Props) => {
  const [stockColor, setStockColor] = useState<string>();

  useEffect(() => {
    if(props.available?.warehouse_main){
      const warehouses = {...props.available};
 
      let totalQuantity = 0;

      Object.keys(warehouses).forEach(key => {
        if (key !== 'uv' && warehouses.hasOwnProperty!(key)) {
          totalQuantity += Number(warehouses[key].quantity);
        }
      });

      if (props.value > 0 && props.value <= Number(warehouses.warehouse_main?.quantity)) {
        setStockColor('green');
      } else if (props.value > Number(warehouses.warehouse_main?.quantity) && props.value <= totalQuantity) {
        setStockColor('blue');
      }  else {
        setStockColor('');
      }

    }

    if(props.available?.quantity){
      if(props.available.quantity >= props.value){
        setStockColor('green');
      } else {
        setStockColor('');
      }
    }

    if(typeof props.available === 'number'){
      if(props.available >= props.value){
        setStockColor('green');
      } else {
        setStockColor('');
      }
    }
  }, [props.limit, props.value]);

  return (
    <div className="cart-availability-wrapper">
      {
        props.unspecified ? (
          <span>-</span>
        ):(
          <div className="value">
            <div className={`item ${stockColor ? stockColor : ''}`}></div>
            <div>{props.limit > 0 ? props.limit : undefined}</div>
          </div>
        )
      }

    </div>
  );
};

CartAvailabilityView.displayName = 'CartAvailabilityView';

export default CartAvailabilityView;