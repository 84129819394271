import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import './LinkButton.scss';

type Props = {
  title: string;
  icon?: IconDefinition;
  onClick?
};

const LinkButtonView: React.FC<Props> = (props) => {
  return (
    <div onClick={props.onClick} className="link-button">
      <div className="title-link">{props.title}</div>
      {props.icon && <FontAwesomeIcon icon={props.icon} className="icon-link" />}
    </div>
  );
};

LinkButtonView.displayName = 'LinkButtonView';

export default LinkButtonView;
