import React, { useState } from 'react';
import AccordionItem from './accordion-item/AccordionItem';
import { AccordionData } from '../../interfaces/accordion';
import './Accordion.scss';

type Props = {
  items: Array<AccordionData>
}

const Accordion: React.FC<Props> = (props) => {
  const [currentIdx, setCurrentIdx] = useState(-1);

  const btnOnClick = (idx: number) => {
    setCurrentIdx((currentValue) => (currentValue !== idx ? idx : -1));
  };

  return (
    <ul className="accordion">
      {props.items.map((item, idx) => (
        <AccordionItem
          key={idx}
          data={item}
          isOpen={idx === currentIdx}
          btnOnClick={() => btnOnClick(idx)}
        />
      ))}
    </ul>
  );
};

Accordion.displayName = 'Accordion';

export default Accordion;

