import { IHttpResponse, serverAxios } from '../../http';
import {
  IGetCountNewMessagesParams,
  IGetMessagesParams, IGetMessageSupplierParams,
  IMessage,
  IMessageAll,
  IMessageSuppliersAll
} from '../../../interfaces/messaging';

class MessagingHttp {
  private static getMessages = 'messages';
  private static getOrderMessages = 'messages/list-order';
  private static getCartMessages = 'messages/list-cart';
  private static getNewMessages = 'messages/new';
  private static getOrderNewMessages = 'messages/new-order';
  private static getCartNewMessages = 'messages/new-cart';
  private static getLastMessages = 'messages/last';
  private static createMessage = 'messages/message';
  private static createOrderMessage = 'messages/message-order';
  private static createCartMessage = 'messages/message-cart';
  private static countNewMessages = 'messages/count-new';
  private static countNewMessagesSeller = '/messages/amerigo/count-new';
  private static downloadFile = 'messages/download-file/{{id}}';
  private static deleteMessage = 'messages/message/{{id}}';
  static PER_PAGE = 10;
  static SUPPLIER_MESSAGES_PER_PAGE = 12;

  public async getMessages(params: IGetMessagesParams): Promise<IHttpResponse<IMessageAll>> {

    return serverAxios.get(MessagingHttp.getMessages, { params });
  }

  public async getOrderMessages(params: IGetMessagesParams): Promise<IHttpResponse<IMessageAll>> {

    return serverAxios.get(MessagingHttp.getOrderMessages, { params });
  }

  public async getCartMessages(params: IGetMessagesParams): Promise<IHttpResponse<IMessageAll>> {

    return serverAxios.get(MessagingHttp.getCartMessages, { params });
  }

  public async getNewMessages(last_message_id: number | null, supplier_mapping_group_id?: number ): Promise<IHttpResponse<IMessage[]>> {

    return serverAxios.get(MessagingHttp.getNewMessages, { params: { last_message_id, supplier_mapping_group_id } });
  }

  public async getOrderNewMessages(last_message_id: number | null, order_id: string): Promise<IHttpResponse<IMessage[]>> {

    return serverAxios.get(MessagingHttp.getOrderNewMessages, { params: { last_message_id, order_id } });
  }

  public async getCartNewMessages(last_message_id: number | null, dlnr: string): Promise<IHttpResponse<IMessage[]>> {

    return serverAxios.get(MessagingHttp.getCartNewMessages, { params: { last_message_id, dlnr } });
  }

  public async getLastMessages(params: IGetMessageSupplierParams): Promise<IHttpResponse<IMessageSuppliersAll>> {

    return serverAxios.get(MessagingHttp.getLastMessages, { params });
  }

  public async countNewMessages(params: IGetCountNewMessagesParams): Promise<IHttpResponse<number>> {

    return serverAxios.get(MessagingHttp.countNewMessages, { params });
  }

  public async countNewMessagesSeller(): Promise<IHttpResponse<number>> {

    return serverAxios.get(MessagingHttp.countNewMessagesSeller);
  }

  public async createMessage(data: FormData): Promise<IHttpResponse<IMessage>> {
    return serverAxios.post(MessagingHttp.createMessage, data, { headers: { 'content-type': 'multipart/form-data' } });
  }

  public async createOrderMessage(data: FormData): Promise<IHttpResponse<IMessage>> {
    return serverAxios.post(MessagingHttp.createOrderMessage, data, { headers: { 'content-type': 'multipart/form-data' } });
  }


  public async createCartMessage(data: FormData): Promise<IHttpResponse<IMessage>> {
    return serverAxios.post(MessagingHttp.createCartMessage, data, { headers: { 'content-type': 'multipart/form-data' } });
  }


  public async downloadMessage(id: string): Promise<IHttpResponse<Blob>> {
    const url = MessagingHttp.downloadFile.replace('{{id}}', id);

    return serverAxios.get(url, { responseType: 'blob' });
  }

  public async deleteMessage(id: string): Promise<IHttpResponse<null>> {
    const url = MessagingHttp.deleteMessage.replace('{{id}}', id);

    return serverAxios.delete(url);
  }
}

export default MessagingHttp;