import React, {useEffect} from 'react';
import {fetchItemIdsByCategory} from '../../../store/reducers/car-parts-categories/car-parts-categories.actions';
import {useSelector} from 'react-redux';
import {RootState} from '../../../store/store';
import _ from 'underscore';
import {useAppDispatch} from '../redux';

const useFetchItemIdsByCategory = (): void => {
  const {partsCategories} = useSelector((state: RootState) => state.carPartsCategories);
  const { user } = useSelector((state: RootState) => state.userData);
  const dispatch = useAppDispatch();
   
  const hasNoSubscription = user?.client_rmi_modules_type === 'none';

  useEffect(() => {

    if (partsCategories.length && !hasNoSubscription) {
      const updatedCategories = updateVehicleCategories(partsCategories);
      const categoriesId = [];

      updatedCategories.forEach((category) => {
        addCategoryIdsIdToArray(category, categoriesId);
      });

      dispatch(fetchItemIdsByCategory(categoriesId));
    }
  }, [partsCategories]);

  const addCategoryIdsIdToArray = (category, array) => {
    if (category.subcategories) {
      category.subcategories.forEach((subcategory) => {
        addCategoryIdsIdToArray(subcategory, array);
      });

      return;
    }

    array.push({
      code_repere: category.code_repere,
      code_groupe: category.code_groupe,
      code_ssgroupe: category.code_ssgroupe,
    });
  };


  const updateVehicleCategories = (vehicleCategories) => vehicleCategories.filter((category) => !_.isEmpty(category.subcategories));

};

export default useFetchItemIdsByCategory;