import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import useCollapse from 'react-collapsed';
import { useSearchParams } from 'react-router-dom';
import './CollapseList.scss';
import SubGroup from './SubGroup';

type Props = {
  data?
  onMainGroupClick?: (item) => void;
  onSubGroupClick?: (item) => void;
  onItemMpClick?: (item) => void;
  onItemMpManualClick?: (item) => void;
  setOpenModal?: (value: boolean) => void;
  activeItem?: string | number
}

const MainGroup: React.FC<Props> = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();

  function handleExpand() {
    console.log('main_group_id', String(props.data.MainGroupId), searchParams.get('main_group_id'));
    searchParams.set('main_group_id', String(props.data.MainGroupId));
    setSearchParams(searchParams);
  }

  const config = {
    defaultExpanded: String(props.data.MainGroupId) === searchParams.get('main_group_id'),
    duration: 0,
    expandStyles: {
      height: '100%'
    },
    collapseStyles: {},
    onExpandStart: handleExpand
  };

  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse(config);


  return (
    <div className="collapse-list-diagnostic">
      <div className="item">
        <div className="collapse-item" {...getToggleProps()}>
          <FontAwesomeIcon icon={isExpanded ? faChevronDown : faChevronRight} />
          <div>{props.data.MainGroupName}    </div>
        </div>
      </div>
      {props.data.SubGroups.map((subGroup, index) => {
        return (
          <div {...getCollapseProps()} key={index}>
            <SubGroup data={subGroup}
              onSubGroupClick={props.onSubGroupClick}
              onItemMpClick={props.onItemMpClick}
              onItemMpManualClick={props.onItemMpManualClick}
              activeItem={props.activeItem}
            />
          </div>
        );
      })}
    </div>
  );
};

export default MainGroup;