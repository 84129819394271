import Modal from 'components/modal/Modal';
import React, { useMemo, useState } from 'react';
import { Column, useTable } from 'react-table';
import Head from '../Cells/Head/Head';
import './ClientsInvitationsTable.scss';
import { useTranslation } from 'react-i18next';
import { IClientInvitation } from 'models/client.model';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import { setNewClientModal } from 'store/slices/clients.slice';
import { useAppDispatch } from 'utils/hooks/redux';
import { setPendingInvitations } from 'store/slices/pending-invitations.slice';
import ClientRejectModal from './ClientRejectModal/ClientRejectModal';


type Props = {
  invitations: IClientInvitation[];
  handleClientClick: (key: React.Key) => void;
  fetchData?
};

const MyClientTableView: React.FC<Props> = (props: Props) => {
  const data = useMemo((): IClientInvitation[] => props.invitations, [props.invitations]);
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const { isMobile, isTablet } = useDeviceDetect();
  const [clientId, setClientId] = useState<string>('');
  const [openModal, setOpenModal] = useState<boolean>(false);

  const columns = useMemo(
    (): Column<IClientInvitation>[] => [
      {
        Header: <Head text={t('_id')}></Head>,
        accessor: 'id',
      },
      {
        Header: <Head text={t('_name')}></Head>,
        accessor: 'full_name',
      },
      {
        Header: <Head text={t('_address')}></Head>,
        accessor: 'address',
      },
      {
        Header: <Head text={t('_email')}></Head>,
        accessor: 'email',
      },
      {
        Header: <Head text={t('_creation_date')}></Head>,
        accessor: 'created_at',
      },
      // {
      //   Header: <Head text={t('_seen')}></Head>,
      //   accessor: 'seen',
      //   Cell: (cell) => {
      //     return (
      //       <div className={'email'}>
      //         {cell.row.values.seen ? (
      //           <FontAwesomeIcon className={'relicats-green'} icon={faCheck} />
      //         ) : (
      //           <FontAwesomeIcon className={'relicats-red'} icon={faXmark} />
      //         )}
      //       </div>
      //     );
      //   },
      // },
      {
        Header: <Head center text={t('_approval')}></Head>,
        accessor: 'approval',
        Cell: (cell) => {
          return (
            <div className="approval">
              <button className="approve" onClick={() => {
                dispatch(setNewClientModal(true));
                dispatch(setPendingInvitations(cell.row.original));
              }}
              >{isMobile ? <FontAwesomeIcon icon={faCheck} /> : t('_approve')}</button>
              <button className="reject" onClick={() => {
                setOpenModal(true);
                setClientId(String(cell.row.original.id));
              }}
              >{isMobile ? <FontAwesomeIcon icon={faXmark} /> : t('_reject')}</button>
            </div >
          );
        },
      },
    ],
    [isMobile, isTablet, i18n.language],
  );

  let hiddenColumns = isTablet ? ['id'] : undefined;

  if (isMobile) {
    hiddenColumns = ['id', 'address', 'created_at'];
  }

  const initialState = hiddenColumns ? {
    'hiddenColumns': hiddenColumns
  } : {};

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data, initialState });

  return (
    <div>
      <table className="wrapper-client-invitation" {...getTableProps()}>
        <thead className="headings">
          {headerGroups.map((headerGroup, index) => {
            return (
              <tr key={headerGroup.headers[index].id} className="row">
                {headerGroup.headers.map((column) => {
                  const { key, ...restColumn } = column.getHeaderProps();

                  return (
                    <th key={key} {...restColumn} className="heading">
                      {column.render('Header')}
                    </th>
                  );
                })}
              </tr >
            );
          })}
        </thead >
        <tbody {...getTableBodyProps} className="body">
          {rows.length ? (
            rows.map((row) => {
              prepareRow(row);
              const { key, ...restRowProps } = row.getRowProps();

              return (
                <React.Fragment key={key}>
                  <tr key={key} {...restRowProps} className="row">
                    {row.cells.map((cell) => {
                      const { key, ...restCellProps } = cell.getCellProps();

                      return (
                        <td key={key} {...restCellProps} className="cell">
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                </React.Fragment >
              );
            })
          ) : (
            <tr>
              <td colSpan={20} className="notFond">{`${t('_no_data')}`}</td>
            </tr >
          )}
        </tbody >
      </table >
      <Modal
        openModal={openModal}
        setOpenModal={setOpenModal}
        childrenComponent={<ClientRejectModal invitationId={clientId} fetchData={props.fetchData} setOpenModal={setOpenModal} />}
      />
    </div >
  );
};

MyClientTableView.displayName = 'MyClientTableView';

export default MyClientTableView;
