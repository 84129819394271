import i18next from 'i18n';
import {i18n, t} from 'i18next';
import { countries, DEFAULT_COUNTRY, DEFAULT_LANGUAGE, languages } from './constants/locales';
import { IDropdownModel } from '../components/dropdowns/Dropdown.model';

export const getLanguageFromLocalStorage = (): IDropdownModel | undefined => {
  const locale = String(localStorage.getItem('locale')) ||  i18next.language;
  const localeArray = locale.split('-');

  const language = Array.isArray(localeArray) && localeArray.length > 0 ? localeArray[0] : DEFAULT_LANGUAGE;

  return languages.find((item: IDropdownModel) => {
    return item.id === language;
  });
};

export const getCountryFromLocalStorage = (): IDropdownModel | undefined => {
  const locale = String(localStorage.getItem('locale'));
  const localeArray = locale.split('-');

  const country = Array.isArray(localeArray) && localeArray.length > 0 ? localeArray[1] : DEFAULT_COUNTRY;

  return countries.find((item: IDropdownModel) => {
    return item.id === country;
  });
};

export const getLanguageFromLocale = (i18n: i18n): IDropdownModel | undefined => {
  const locale = String(i18n.language);
  const localeArray = locale.split('-');

  const language = Array.isArray(localeArray) && localeArray.length > 0 ? localeArray[0] : DEFAULT_LANGUAGE;

  return languages.find((item: IDropdownModel) => {
    return item.id === language;
  });
};

export const getCountryFromLocale = (i18n: i18n): IDropdownModel | undefined => {
  const locale = String(i18n.language);
  const localeArray = locale.split('-');

  const country = Array.isArray(localeArray) && localeArray.length > 0 ? localeArray[1] : DEFAULT_COUNTRY;

  return countries.find((item: IDropdownModel) => {
    return item.id === country;
  });
};

export const getTranslationName = (name?: string): string => {
  return t('_' + name?.toLowerCase());
};