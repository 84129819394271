import {IClientSettings, IUserSettings} from '../../../models/IUser';
import {IHttpResponse, serverAxios} from '../../http';
import {ProductsViewModeTypes} from 'components/ProductsViewMode/ProductsViewMode.constants';

class ProductsViewModeHttp {
  private static route = '/products-view-mode';

  public async updateProductsViewMode(products_view_mode: keyof typeof ProductsViewModeTypes): Promise<IHttpResponse<IUserSettings>> {

    return serverAxios.patch(ProductsViewModeHttp.route, {products_view_mode});
  }
}

export default ProductsViewModeHttp;
