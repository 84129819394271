import React from 'react';
import { useTranslation } from 'react-i18next';
import './TyreDetails.scss';
import { ITyre } from 'interfaces/tyre';

type Props = {
  tyre: ITyre;
};

const TyreDetails: React.FC<Props> = (props) => {
  const tyre = props.tyre;
  const { t } = useTranslation();

  return (
    <div className="tyre-details-wrapper">
      <div className="tyre__header">
        <div className="tyre__header__article">
          <span>{t('_reference')}</span>:{` ${tyre.data_supplier_reference_original}`}
        </div>
        <h5 className="tyre__header__title">{`${tyre.data_supplier.name} ${tyre.data_supplier_reference_original}`}</h5>
        <div className="tyre__header__category">{`${tyre.description ? tyre.description : '-'}`}</div>
      </div>
      <div className="tyre__content">
        {Object.entries(tyre.tags).map((characteristic, index) => (
          <div className="content-item" key={index}>
            <p className="label">{t(`_${characteristic[0]}`)}: </p>
            <p className="value">{`${characteristic[1]}`}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

TyreDetails.displayName = 'TyreDetails';

export default TyreDetails;
