import Catalog from 'applications/automotor/components/espace/client/catalog/Catalog';
import SearchReference from 'applications/automotor/components/espace/client/search-reference/SearchReference';
import React from 'react';


import './EspaceClientView.scss';

type Props = {};

const EspaceClientView: React.FC<Props> = () => {

  return (
    <div className="espace-client-container-content">
      <div className="catalog-container">
        <Catalog />
      </div>
      <div className="search-reference-container">
        <SearchReference />
      </div>
    </div>
  );
};

EspaceClientView.displayName = 'EspaceClientView';

export default EspaceClientView;


