import React, { useEffect, useState } from 'react';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import TextInput from 'components/inputs/TextInput/TextInput';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import useHttp from 'utils/hooks/useHttp';
import { useForm } from 'react-hook-form';
import { notify } from 'utils/marketplace';
import Spinner from 'components/spinner/Spinner';
import './SupplierSettingsModal.scss';

type Props = {
    setOpenModal: (openModal: boolean) => void
    supplier
}
const c = 'supplier-settings-modal';

const SupplierSettingsModal: React.FC<Props> = (props: Props) => {
  const {t} = useTranslation();
  const [loadingData, setLoadingData] = useState(true);
  const [loadingSave, setLoadingSave] = useState(false);
  const {register, setValue, getValues} = useForm();
  const http = useHttp();

  function getErpSuppliers(){
    setLoadingData(true);
    http.filterSuppliers.getErpSuppliers(props.supplier.id)
      .then((res) => setValue('erp_id', res))
      .finally(() => setLoadingData(false));
  }

  function saveSupplierErp(){
    setLoadingSave(true);
    http.filterSuppliers.saveErpSupplier(props.supplier.id, {erpId: getValues('erp_id')})
      .then(() => {
        notify(t('_success'), 'success');
        props.setOpenModal(false);
      })
      .finally(() => {
        setLoadingSave(false);
      });
  }

  useEffect(() => {
    getErpSuppliers();
  },[]);

  return (
    <div className={c}>
      <div className={`${c}__header`}>
        <div className={`${c}__header-title`}>{t('_supplier_erp_id')}</div>
        <div className={`${c}__header-close`}><FontAwesomeIcon icon={faClose} onClick={(()=> props.setOpenModal(false))} /></div>
      </div>
      <div className={`${c}__body`}>
        {loadingData ? <Spinner class="extra-small"/> : 
          <TextInput {...register('erp_id')} placeholder={t('_supplier_erp_id')}/>
        }
      </div>
      <div className={`${c}__footer`}>
        <div className={`${c}__footer-save`}>
          <DefaultButton text={t('_save')} onClick={saveSupplierErp} isloading={loadingSave} />
        </div>
      </div>
    </div>
  );
};

export default SupplierSettingsModal;