import React, { ChangeEvent } from 'react';
import './CheckboxCellTab.scss';


type Props = {
  register;
  handleOnChange: (e: ChangeEvent<HTMLInputElement>, code_groupe?: string | number, code_ssgroupe?: string | number, code_repere?: string | number) => void;
  id: number | string;
  code_groupe?: string | number;
  code_ssgroupe?: string | number;
  code_repere?: string | number;
  inputName?: string;
};

const CheckboxCellTabView: React.FC<Props> = (props: Props) => {

  const checkboxCartForm = props.inputName != undefined ? props.register(props.inputName) : props.register('checkbox-cart');

  return (
    <div className="checkbox-cell-wrapper-tab">
      <input type="checkbox"
        {...checkboxCartForm} value={props.id}
        onChange={e => {
          checkboxCartForm.onChange(e);
          props.handleOnChange(e, props.code_groupe, props.code_ssgroupe, props.code_repere);
        }}
      />
    </div>
  );
};

CheckboxCellTabView.displayName = 'CheckboxCellTabView';

export default CheckboxCellTabView;
