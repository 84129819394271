import React from 'react';
import QuantityInput from 'components/inputs/QuantityInput/QuantityInput';
import CartAvailability from '../../../CartAvailability/CartAvailability';
import './QuantityAvailability.scss';
import Spinner from 'components/spinner/Spinner';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import CirrusCartAvailability from '../../../CirrusCartAvailability/CirrusCartAvailability';

type Props = {
  availability: number;
  quantity: number;
  setQuantity: (quantity: number) => void;
  onAdd?: () => void;
  onSubstract?: () => void;
  quantityLoading: boolean;
  availabilityUnspecified?: boolean;
  readonly?: boolean;
  workRow?: boolean;
  available?
  disableAddBtn?: boolean
  disableSubBtn?: boolean
};

const QuantityAvailabilityView: React.FC<Props> = (props: Props) => {
  const {user} = useSelector((state:RootState) => state.userData);

  return <div className="quantity-availability">

    {props.quantityLoading ? (
      <Spinner class="extra-small" />
    ) : (
      <QuantityInput disableSubBtn={props.disableSubBtn} disableAddBtn={props.disableAddBtn} disabled={props.workRow} readonly={props.readonly} value={props.quantity} setValue={props.setQuantity} onAdd={props.onAdd} onSubstract={props.onSubstract}></QuantityInput>
    )}
    {
      props.availabilityUnspecified ? (
        <span>-</span>
      ) : (
        !props.workRow && (user?.numcli === Number(process.env.REACT_APP_CIRRUS_NUMCLI) ? <CirrusCartAvailability available={props.available} value={props.quantity} limit={props.availability} /> : <CartAvailability available={props.available} value={props.quantity} limit={props.availability} />)
      )
    }
  </div>;
};

QuantityAvailabilityView.displayName = 'NumberCellView';

export default QuantityAvailabilityView;
