import React from 'react';
import { SwiperSlide } from 'swiper/react';
import SwiperSlider from 'components/SwiperSlider/SwiperSlider';

import ProductCategoryItem from 'components/Item/ProductCategoryItem/ProductCategoryItem';
import './CategorySlider.scss';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';

type Props = {
  isLoading?: boolean;
  data?
  type?: string
  onCategoryClick: (item) => void;
};

const CategorySlider: React.FC<Props> = (props) => {
  const { isMobile } = useDeviceDetect();

  return (
    <SwiperSlider
      isLoading={props.isLoading}
      slidesPerView={isMobile ? 2 : 3}
      navigation={!isMobile}
      autoplay
    // loop
    >
      {
        props.data?.map((item, index: number) => {
          return (
            <SwiperSlide className="swiper-slide" key={index}>
              <ProductCategoryItem
                text={item.name}
                image={item.image}
                onClick={() => props.onCategoryClick(item.name)}
              ></ProductCategoryItem>
            </SwiperSlide>
          );
        })
      }
    </SwiperSlider>
  );
};


export default CategorySlider;
