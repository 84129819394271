import React from 'react';
import {useDrag} from 'react-dnd';
import './DragDrop.scss';

interface IDropComponentProps{
number:number,
name:string,
disableMapping:boolean,
}

const DragComponent = (props:IDropComponentProps): JSX.Element => {
  const type = 'any';
  
  const [{opacity, display}, drag] = useDrag(
    () => ({
      type,
      item: {number: props.number, name: props.name, type: type},
      collect: monitor => {

        return {
          opacity: monitor.isDragging() ? 0.4 : 1,
          display: monitor.isDragging() ? 'none' : 'table-row',
        };
      },
    }),
    [props.name, type],
  );

  return (
    <div ref={drag} className="proforma-file-items" style={{opacity, display: props.name ? display : 'none'}}>
      <div className="proforma-file-item">
        {!props.disableMapping ? <i ref={drag} className="fas fa-bars" /> : <i className="fas fa-bars drag-disabled" />}
        <div className={`proforma-item-name ${props.disableMapping ? 'proforma-disabled-text' : ''}`}>
          {props.number}
          {'. '}
          {props.name}
        </div>
        <div className={`proforma-item-name ${props.disableMapping ? 'proforma-disabled-text' : ''}`} />
      </div>
    </div>
  );
};

export default DragComponent;
