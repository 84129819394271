import {  ITariff } from 'models/catalog.model';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useHttp from 'utils/hooks/useHttp';
import EspaceTariffsView from './EspaceTariffs.view';

type Prop = {};

const EspaceTariffs: React.FC<Prop> = () => {
  const http = useHttp();
  const [catalogsData, setCatalogsData] = useState<ITariff[]>([]);
  const [loadingCatalogs, setLoadingCatalogs] = useState(false);
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState('');
  const [initialCatalogData, setInitialCatalogData] = useState<ITariff[]>([]);

  useEffect(() => {
    fetchCatalogs();
  }, []);

  function fetchCatalogs() {
    setLoadingCatalogs(true);
    http.tariffCatalogs
      .getTariffCatalogs()
      .then((res) => {
        setCatalogsData(res);
        setInitialCatalogData(res);
      })
      .finally(() => setLoadingCatalogs(false));
  }


  function handleCatalogClick(id) {
    console.log(id);
    navigate(`/catalog/${id}`);
  }

  useEffect(() => {
    if (inputValue === '') {
      setCatalogsData(initialCatalogData);
    } else {
      const lowerValue = inputValue.toLowerCase();
      const filteredCatalogs = initialCatalogData.filter((catalog) => catalog.catalog_id?.toLowerCase().includes(lowerValue));

      setCatalogsData(filteredCatalogs);
    }
  }, [inputValue]);

  return (
    <EspaceTariffsView
      inputValue={inputValue}
      setInputValue={setInputValue}
      handleCatalogClick={handleCatalogClick}
      loadingCatalogs={loadingCatalogs}
      catalogsData={catalogsData}
    />
  );
};

EspaceTariffs.displayName = 'EspaceTariffs';

export default EspaceTariffs;
