import { countries } from 'utils/constants/locales';
import {
  TECRMI_ANCHOR_HREF,
  TECRMI_DOC_LOCATION_HREF,
  TECRMI_FOOTER_REGEX, TECRMI_FOOTER_REGEX_ADJUST,
  TECRMI_HEADER_REGEX
} from 'utils/constants/defaultConstants';
import moment from 'moment';

interface IValues {
  HeaderValue: string;
  KindOfHeaderValue: number;
}

interface ServicePlaInputs {
  first_name?: string;
  last_name?: string;
  phone?: string;
  plate_number?: string;
  milage?: string;
  order_number?: string;
}

export function getCurrentLanguageRMI(data: string): string {
  const [language] = data.split('-');

  let newLanguage;
  const excludedLanguage = ['ar', 'ko', 'qa'];

  if (excludedLanguage.includes(language)) {
    newLanguage = 'en';
  } else {
    newLanguage = language;
  }

  return newLanguage;
}

export function getCurrentCountryRMI(data: string): string {
  const [, countryCode] = data.split('-');
  const excludedCountriesCodes = ['US', 'DZ', 'CO', 'RU', 'CN', 'KR', 'EG', 'TR', 'IR', 'KW', 'SA', 'AE'];

  if (excludedCountriesCodes.includes(countryCode)) {
    return 'FR';
  }

  const country = countries.find(c => c.id === countryCode);

  return country?.id ?? 'FR';
}

export const langDefaultEina = ['es', 'en', 'fr', 'pt', 'it', 'ro', 'de', 'nl', 'cs'];

export const EquipmentFilter: { [index: string]: string } = {
  1001: '_longlife',
  1002: '_particle_filter',
  1003: '_start_stop_system',
  1004: '_air_condition',
  1005: '_tow_coupling',
  1006: '_fuel_additive_add_blue',
  1007: '_sun_roof',
  1008: '_xenon_headlamps',
};

export const GearBoxFilter: { [index: string]: string } = {
  65: '_automatic_gear_box',
  77: '_manual_gear_box',
  81: '_automated_gear_box',
};

export const EuroExhaustNorm: { [index: string]: string } = {
  1: 'EURO1',
  2: 'EURO2',
  3: 'EURO3',
  4: 'EURO4',
  5: 'EURO5',
  6: 'EURO6',
};

export const EuroExhaustNormOptions = [
  { value: 1, label: EuroExhaustNorm[1] },
  { value: 2, label: EuroExhaustNorm[2] },
  { value: 3, label: EuroExhaustNorm[3] },
  { value: 4, label: EuroExhaustNorm[4] },
  { value: 5, label: EuroExhaustNorm[5] },
  { value: 6, label: EuroExhaustNorm[6] },
];

export const maintenanceSuggestionSelectStyles = {
  control: (base): void => ({
    ...base,
    boxShadow: 'none',
    '&:focus': {
      borderColor: '#80bdff',
    },
  }),
  menuList: (styles): void => ({
    ...styles,
    maxHeight: 250,
  }),
};

export const NUMBER_REGEX = /\d+/;

export const replaceTecRmiIframeBrandingInfo = (html: string): string => {
  return html.replace(TECRMI_HEADER_REGEX, '')
    .replace(TECRMI_FOOTER_REGEX, '')
    .replace(TECRMI_FOOTER_REGEX_ADJUST, '')
    .replace(TECRMI_DOC_LOCATION_HREF, '')
    .replace(TECRMI_ANCHOR_HREF, '<a data-href=');
};

export function getOptionalHeaderValues(data: ServicePlaInputs): IValues[] {
  const {
    first_name,
    last_name,
    phone,
    plate_number,
    milage,
    order_number,
  } = data;

  const values: IValues[] = [{
    HeaderValue: moment().format('DD/MM/YYYY, h:mm:ss a'),
    KindOfHeaderValue: 1,
  }];

  if (first_name || last_name) {
    values.push({
      HeaderValue: `${first_name} ${last_name}`,
      KindOfHeaderValue: 2,
    });
  }

  if (phone) {
    values.push({
      HeaderValue: phone,
      KindOfHeaderValue: 3,
    });
  }

  if (plate_number) {
    values.push({
      HeaderValue: plate_number,
      KindOfHeaderValue: 5,
    });
  }

  if (milage) {
    values.push({
      HeaderValue: milage,
      KindOfHeaderValue: 6,
    });
  }

  if (order_number) {
    values.push({
      HeaderValue: order_number,
      KindOfHeaderValue: 4,
    });
  }

  return values;
}