import CarPartPrice from 'components/CarPartPrice/CarPartPrice';
import FavoriteButton from 'components/PartItem/PartItemOptions/FavoriteButton/FavoriteButton';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, createSearchParams, generatePath, useParams, useSearchParams } from 'react-router-dom';
import { getCurrentMarketplace, getMarketplaceBaseUrl, notify } from 'utils/marketplace';
import CartListButton from '../buttons/CartListButton/CartListButton';
import FileButton from '../buttons/FileButton/FileButton';
import Disponibility from '../disponibility/Disponibility';
import QuantityInput from '../inputs/QuantityInput/QuantityInput';
import { IAddToCartParams } from 'models/car-parts.model';
import { CATALOG_REL, Marketplaces } from 'utils/constants/applications';
import { addToCartAction } from 'store/actions/cart.actions';
import { useAppDispatch } from 'utils/hooks/redux';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { setQuantityToolbar } from 'store/slices/cart.slice';
import { checkIfImageExists } from 'utils/utils';
import Placeholder from 'assets/images/car-part-placeholder.png';
import './ListPartOEMMecadepot.scss';
import CirrusDisponibility from 'components/CirrusDisponibility/CirrusDisponibility';
import CURRENCY from 'utils/constants/currency';
import useHttp from 'utils/hooks/useHttp';
import Spinner from 'components/spinner/Spinner';
import Modal from 'components/modal/Modal';
import ReplacementsModal from 'components/ReplacementsModal/ReplacementsModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
// import {
//   MagnifierContainer,
//   MagnifierPreview,
//   MagnifierZoom,
// } from '@datobs/react-image-magnifiers';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import { faFileClipboard } from '@fortawesome/free-regular-svg-icons';
import CarPartView from 'components/partsView/CarPartView';
import { isRetailerMecadepotChild } from 'utils/isRetailerMecadepotChild';

type Props = {
  data?;
  inCatalog?: boolean;
  updateData?
};

const ListPartOEMMecadepot: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { id, vehicle_id } = useParams<'id' | 'vehicle_id'>();
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const marketplace = getCurrentMarketplace();
  const { typeId } = useSelector((state: RootState) => state.tecrmi);
  const { quantityToolbar } = useSelector((state: RootState) => state.cart);
  const user = JSON.parse(localStorage.getItem('userData')!);
  const http = useHttp();
  const [image, setImage] = useState('');
  const [cartLoading, setCartLoading] = useState(false);
  const [workLoading, setWorkLoading] = useState(false);
  const [disableCartBtn, setDisableCartBtn] = useState(false);
  const [contactLoading, setContactLoading] = useState(false);
  const [oeModalOpen, setOEModalOpen] = useState(false);
  const [quantityValue, setQuantityValue] = useState(props.data.available.uv || 1);
  const [showQuantityAndCart, setShowQuantityAndCart] = useState<boolean>(false);
  const isIND = props.data.source === 'IND';
  const { loadingPriceCheckedStatus } = useSelector((state: RootState) => state.buttonPriceCheckedStatus);
  const [showPrice, setShowPrice] = useState(false);
  const [openModalView, setOpenModalView] = useState(false);
  const [paramsModalView, setParamsModalView] = useState({});
  const { isDesktop } = useDeviceDetect();
  const isMecadepotChild = isRetailerMecadepotChild();

  const handleAddToCart = () => {
    if (!quantityValue) {
      return;
    }

    setCartLoading(true);

    const params: IAddToCartParams[] = [];


    let param: IAddToCartParams = {
      supplier_id: props.data?.data_supplier?.id,
      supplier_reference: props.data?.data_supplier_reference_original,
      price: props.data?.promotion_price ? props.data.promotion_price :
        (props.data.price?.price ? props.data.price?.price : (typeof props.data?.price === 'string' || typeof props.data?.price === 'number' ? props.data?.price : null)),
      quantity: quantityValue,
      catalog_id: CATALOG_REL,
      is_oe_part: false,
      oe_reference: props.data?.name,
      cod_fou: props.data?.cod_fou,
      manufacturer_id: props.data?.manufacturer_id,
    };

    if (props.data.source === 'ERP') {
      param = {
        ...param,
        ...{ source: props.data.source }
      };
    }

    if (localStorage.getItem('external_cart_id')) {
      param = {
        ...param,
        ...{ external_cart_id: localStorage.getItem('external_cart_id') },
      };
    }

    params.push(param);

    dispatch(addToCartAction(params))
      .then(() => {
        dispatch(setQuantityToolbar(+quantityValue + +quantityToolbar));
      })
      .finally(() => {
        setCartLoading(false);
      });
  };

  const handleAddWorkAndCart = () => {
    if (!quantityValue) {
      return;
    }

    dispatch(setQuantityToolbar(+quantityValue * 2 + +quantityToolbar));

    setWorkLoading(true);

    let partObj = {
      supplier_id: props.data?.data_supplier?.id,
      supplier_reference: props.data?.data_supplier_reference_original,
      price: props.data?.promotion_price ? props.data.promotion_price : props.data.price?.price,
      quantity: quantityValue,
      catalog_id: CATALOG_REL,
      is_oe_part: false,
      oe_reference: props.data?.name,
      cod_fou: props.data?.cod_fou,
      manufacturer_id: props.data?.manufacturer_id,
    };

    if (props.data.source === 'ERP') {
      partObj = {
        ...partObj,
        ...{ source: props.data.source }
      };
    }

    if (localStorage.getItem('external_cart_id')) {
      partObj = {
        ...partObj,
        ...{ external_cart_id: localStorage.getItem('external_cart_id') },
      };
    }

    const params: IAddToCartParams[] = [partObj];

    let workObj = {
      reference: props.data?.work_difficulty,
      work_id: props.data?.work_difficulty,
      quantity: quantityValue,
      description: props.data?.WorkText,
      is_oe_part: false,
      work_time: props.data.WorkTime
    };

    if (localStorage.getItem('external_cart_id')) {
      workObj = {
        ...workObj,
        ...{ external_cart_id: localStorage.getItem('external_cart_id') },
      };
    }

    params.push(workObj);

    dispatch(addToCartAction(params))
      .catch(() => {
        dispatch(setQuantityToolbar(+quantityValue * 2 - +quantityToolbar));
      })
      .finally(() => {
        setWorkLoading(false);
      });
  };

  function getPrice() {
    if (props.data.price?.price) {
      return props.data.price.price;
    }

    return props.data.price;
  }

  const handleCatalogRefClick = () => {
    let params;

    if (!props.inCatalog || !id) {
      return generatePath(`/catalog/${props.data.catalog_id}/view/${props.data.data_supplier_reference}?${createSearchParams(params)}`);
    } else {
      params = [
        ['data_supplier_id', props.data.data_supplier.id],
        ['provider_id', props.data.cod_fou],
        ['catalog_id', id],
        ['data_supplier_reference', props.data.data_supplier_reference],
      ];
    }

    return generatePath(`/catalog/${id}/view/${props.data.data_supplier_reference}?${createSearchParams(params)}`);
  };

  const handleRefClick = () => {
    let params;

    if (
      (props.data?.catalog_id && props.data.catalog_id !== CATALOG_REL) ||
      (props.data?.source && props.data?.source !== 'T' && props.data?.source !== 'P')
    ) {
      params = [
        ['data_supplier_id', props.data.data_supplier.id],
        ['provider_id', props.data.cod_fou],
        ['catalog_id', props.data?.source],
        ['reference', props.data.data_supplier_reference],
      ];
    } else {
      params = [
        ['data_supplier_id', props.data.data_supplier.id],
        ['provider_id', props.data.cod_fou],
      ];
    }

    if (searchParams.get('is_platform_admin')) {
      params = [...params, ['is_platform_admin', searchParams.get('is_platform_admin')]];
    }

    if (searchParams.get('vehicle_identifier')) {
      params = [...params, ['vehicle_identifier', searchParams.get('vehicle_identifier')]];
    }

    if (searchParams.get('source')) {
      params = [...params, ['source', searchParams.get('source')]];
    }

    if (vehicle_id) {

      return generatePath(`/reliable/rmi/${vehicle_id}/catalogs/car-parts/view/${props.data.data_supplier_reference}?${createSearchParams(params)}`);
    } else {

      return generatePath(`/car-parts/view/${props.data.data_supplier_reference}?${createSearchParams(params)}`);
    }
  };

  useEffect(() => {
    checkIfImageExists(props.data.image, (exists) => (exists ? setImage(props.data.image) : setImage(Placeholder)));

    displayCartBlock();
  }, []);

  function displayCartBlock() {
    setShowQuantityAndCart(
      ![Marketplaces.PUBLIC, Marketplaces.PUBLIC_TR, Marketplaces.AMERIGO].includes(marketplace) ||
      (marketplace == Marketplaces.AMERIGO && searchParams.get('is_platform_admin') !== 'true'),
    );
  }


  useEffect(() => {
    setShowPrice(!loadingPriceCheckedStatus);
  }, [loadingPriceCheckedStatus]);

  function calcLimit() {
    if (props.data.available && typeof props.data.available === 'object') {
      const newLimit: { uv?: string; warehouse_main } = { ...props.data.available };

      delete newLimit.uv;

      return Object.values(newLimit).reduce((acc, item) => Number(acc) + Number(item?.quantity), 0);
    }

    return props.data.available;
  }


  const updateFavorite = (isChecked) => {
    if (props.updateData) {
      props.updateData(props.data, isChecked);
    }

  };

  function getAvailablityTitle() {
    if (props.data?.available?.warehouse_main) {
      const warehouses = { ...props.data.available };

      let totalQuantity = 0;
      let deliveryTime = 0;

      Object.keys(warehouses).forEach(key => {
        if (key !== 'uv' && warehouses.hasOwnProperty!(key)) {
          totalQuantity += Number(warehouses[key].quantity);

          if (warehouses[key].warehouse_delivery_time) {
            deliveryTime += Number(warehouses[key].warehouse_delivery_time);
          }
        }
      });

      if (quantityValue > Number(warehouses.warehouse_main?.quantity) && quantityValue <= totalQuantity) {
        return `Disponible dans un délai de ${deliveryTime} jours:`;
      }

      if (quantityValue <= Number(warehouses.warehouse_main?.quantity)) {
        return 'Disponible:';
      }


      if (quantityValue <= Number(warehouses.warehouse_main?.quantity)) {
        return 'Disponible:';
      }


      if (props.data.available?.quantity) {
        if (props.data.available.quantity >= quantityValue) {
          return 'Disponible: ';
        }
      }

      if (!getPrice()) {
        return '';
      }

      return '';
    }
  }


  function cirrusContactUs() {
    setContactLoading(true);

    const params = {
      source: props.data?.source,
      reference: props.data?.data_supplier_reference_original,
      supplier_id: props.data?.data_supplier?.id,
      product_type: 'part'
    };

    http.cirrus.contactUs(params)
      .then(() => notify(t('_distributor_contact_soon'), 'success'))
      .catch((err) => notify(err.message))
      .finally(() => setContactLoading(false));
  }

  function contactUsBtn() {
    if (props.data.available && typeof props.data.available.warehouse_main === 'object') {
      if ((props.data.available?.warehouse_main.quantity === 0 && props.data.available?.warehouse_all.quantity > 0) || (props.data.available?.warehouse_main.quantity === 0 && props.data.available?.warehouse_all.quantity === 0) || props.data.available?.warehouse_main.quantity === 0) {
        return <div className="cirrus-contact-us" onClick={cirrusContactUs}>{contactLoading ? <Spinner class="extra-small"></Spinner> : t('_contact_us')}</div>;
      }

      return '';
    }

    return <div className="cirrus-contact-us" onClick={cirrusContactUs}>{contactLoading ? <Spinner class="extra-small"></Spinner> : t('_contact_us')}</div>;
  }


  useEffect(() => {
    if (!calcLimit() && isMecadepotChild) {
      setDisableCartBtn(true);
    }
  }, [props.data]);

  function copyToClipboard() {
    navigator.clipboard.writeText(props.data.data_supplier_reference_original);
    notify(t('_reference_copied'), 'success');
  }


  const modalViewClick = () => {

    let params;

    if (
      (props.data?.catalog_id && props.data.catalog_id !== CATALOG_REL) ||
      (props.data?.source && props.data?.source !== 'T' && props.data?.source !== 'P')
    ) {
      params =
      {
        'data_supplier_id': props.data.data_supplier.id,
        'provider_id': props.data.cod_fou,
        'catalog_id': props.data?.source,
        'reference': props.data.data_supplier_reference,
      };
    } else {
      params = {

        'data_supplier_id': props.data.data_supplier.id,
        'provider_id': props.data.cod_fou
      };

    }


    if (searchParams.get('source')) {

      params.source = searchParams.get('source');
    }

    params.reference = props.data.data_supplier_reference;


    setParamsModalView(params);
    setOpenModalView(true);
  };

  const closeOpenModalView = () => {
    searchParams.delete('car_model_id');
    setSearchParams(searchParams);
    setOpenModalView(false);
  };

  return (
    <div className={`part-item-oem-list-mecadepot ${isIND ? 'best-price' : ''}`}>
      {isIND && (
        <div className="best-price-label">
          <div className="best-price-text">best price</div>
        </div>
      )}
      <div className="oem-header">
        {/* <MagnifierContainer className="img-zoom-container" autoInPlace>
          <div className="img">
            <MagnifierPreview
              imageSrc={image}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = '/images/car-part-placeholder-big.png';
              }}
            />
          </div>
          <MagnifierZoom className="img-zoom" style={{ height: '200px', width: '200px' }} imageSrc={image} />
        </MagnifierContainer> */}
        <div className="img">
          <img
            src={props.data.image || props.data.data_supplier_logo || '/images/car-part-placeholder.png'}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = '/images/car-part-placeholder-big.png';
            }}
            alt={props.data.subcategory?.name}
          />
        </div>
        {/* <div className="img">
          <img src={image} alt={props.data.subcategory?.name} />
        </div> */}
        <div className="content">
          {props.data.source === 'ERP'
            ? (
              <div className="reference">
                {props.data.data_supplier.name} {props.data.data_supplier_reference_original}   <FontAwesomeIcon icon={faFileClipboard} onClick={copyToClipboard} />
              </div>
            )
            :
            isDesktop ?
              <div className="reference" >
                <span onClick={modalViewClick}>    {t('_reference_short')}:    {props.data.data_supplier.name} {props.data.data_supplier_reference_original}</span>
                <FontAwesomeIcon icon={faFileClipboard} onClick={copyToClipboard} />
              </div>
              : (
                <Link className="reference" to={props.inCatalog ? handleCatalogRefClick() : handleRefClick()}>
                  {props.data.data_supplier.name} {props.data.data_supplier_reference_original}   <FontAwesomeIcon icon={faFileClipboard} onClick={copyToClipboard} />
                </Link>
              )}


          <div className="title" onClick={modalViewClick}>{props.data.subcategory?.name}</div>

        </div>
      </div>
      <div className="oem-actions-side">
        <div className="oem-actions">
          {showQuantityAndCart
            // && marketplace !== Marketplaces.PUBLIC && marketplace !== Marketplaces.PUBLIC_TR
            && (
              <React.Fragment>
                <div className="cart-price">
                  <CarPartPrice currency={CURRENCY[user.currency]} price={getPrice()} quantityValue={quantityValue} priceVAT={Number(props.data?.price?.price_with_vat)} part />
                </div>
                <div className="cart">
                  <div className="quantity">
                    {user?.numcli === Number(process.env.REACT_APP_CIRRUS_NUMCLI) ? (
                      <CirrusDisponibility setDisableCartBtn={setDisableCartBtn} value={quantityValue} data={props.data} />
                    ) : (
                      <Disponibility
                        value={quantityValue}
                        limit={props.data.available}
                        title={getAvailablityTitle()}
                        price={props.data.price}
                      />
                    )}
                    {marketplace == Marketplaces.RETAILER ? (
                      calcLimit() ? (
                        props.data.add_to_cart && (
                          <QuantityInput uv={Number(props.data?.available?.uv)} setValue={setQuantityValue} value={quantityValue} />
                        )
                      ) : !calcLimit() && props.data.add_to_cart ? (
                        <QuantityInput uv={Number(props.data?.available?.uv)} setValue={setQuantityValue} value={quantityValue} />
                      ) : null
                    ) : (
                      props.data.add_to_cart && <QuantityInput setValue={setQuantityValue} value={quantityValue} />
                    )}
                  </div>
                  {user?.numcli === Number(process.env.REACT_APP_CIRRUS_NUMCLI) && contactUsBtn() ? contactUsBtn() : (
                    props.data.add_to_cart && (
                      <div className="cart-button">
                        {vehicle_id &&
                          typeId &&
                          props.data.WorkTime &&
                          user?.client_settings.rmi_modules_type !== 'none' &&
                          user?.client_settings.work_tab &&
                          (
                            <div className="button">
                              <FileButton disabled={disableCartBtn} onClick={handleAddWorkAndCart} loading={workLoading} value={quantityValue} limit={calcLimit()} addToCart={props.data.add_to_cart} price={getPrice()} />
                            </div>
                          )}
                        <div className="button">
                          <CartListButton
                            disabled={disableCartBtn}
                            onClick={handleAddToCart}
                            cartLoading={cartLoading}
                            value={quantityValue}
                            limit={calcLimit()}
                            addToCart={props.data?.add_to_cart}
                            price={getPrice()}
                          />
                        </div>
                      </div>
                    )
                  )}
                </div>
              </React.Fragment>
            )}
          {showPrice ? quantityValue > 1 && getPrice() ? <div className="oem-price_per_unit">P/U: {CURRENCY[user.currency]}{getPrice()}</div> : '' : ''}
          {marketplace == Marketplaces.PUNCHOUT && props.data?.price ?
            <div className="cirrus-prices">
              <div className="cirrus-price">
                <div className="cirrus-price-label">{t('_public_rate')}:</div>
                <div className="cirrus-price-value">{props.data?.price?.initial_price ? `€${Number(props.data?.price?.initial_price).toFixed(2)}` : '-'}</div>
              </div>
              <div className="price-separator" />
              <div className="cirrus-price">
                <div className="cirrus-price-label">{t('_your_rate')}:</div>
                <div className="cirrus-price-value">{props.data?.price?.price_simple ? `€${props.data?.price?.price_simple}` : '-'} </div>
              </div>
              <div className="price-separator" />
              <div className="cirrus-price">
                <div className="cirrus-price-label">{t('_discount')}:</div>
                <div className="cirrus-price-value">{(props.data?.price?.discount && props.data?.price?.discount != 0) ? `${props.data?.price?.discount}%` : '-'} </div>
              </div>
            </div>
            : null}
        </div>
        <div className="barcode">
          {props.data.barcode && props.data.barcode.code && (
            <React.Fragment>
              <img src={`${getMarketplaceBaseUrl()}/${props.data.barcode.image}`} alt="barcode" />
              <div className="barcode-number">{props.data.barcode.code}</div>
            </React.Fragment>
          )}
        </div>
        {(marketplace !== Marketplaces.PUBLIC_TR && marketplace !== Marketplaces.PUBLIC) && (props.data.source !== 'CNT') && !props.data?.supplier?.id && (
          <div className="part-list-item-favorite">
            <FavoriteButton carPart={props.data} updateFavorite={updateFavorite} />
          </div>
        )}
      </div>
      <Modal
        openModal={oeModalOpen}
        setOpenModal={setOEModalOpen}
        childrenComponent={<ReplacementsModal setOpenModal={setOEModalOpen} data={props.data.replacements_oe} />}
      />
      < Modal
        openModal={openModalView}
        childrenComponent={
          <div className="car-part-view-modal">
            <div className="header-close">
              <FontAwesomeIcon icon={faTimes} onClick={() => closeOpenModalView()} />
            </div>
            <div className="car-part-view-modal-body">
              <CarPartView paramsModal={paramsModalView} />
            </div>
          </div>
        }
      />
    </div>
  );
};

export default ListPartOEMMecadepot;
