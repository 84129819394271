import React, { PropsWithChildren, useState } from 'react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination, SwiperOptions } from 'swiper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleArrowLeft, faCircleArrowRight } from '@fortawesome/free-solid-svg-icons';
import Spinner from '../spinner/Spinner';
import './SwiperSlider.scss';
import { SwiperModule } from 'swiper/types';

type Props = {
  onClick?: () => void;
  isLoading?: boolean;
  slidesPerView?: number | 'auto';
  spaceBetween?: number;
  slidesOffsetBefore?: number;
  slidesOffsetAfter?: number;
  slideToClickedSlide?: boolean;
  initialSlide?: number;
  loop?: boolean;
  centeredSlides?: boolean;
  navigation?: boolean;
  pagination?: boolean;
  autoplay?: boolean;
  handleNextClick?: () => void;
  handlePrevClick?: () => void;
  allowTouchMove?: boolean;
  slideToIndex?: number | 'auto';
  getActiveSlideFromDom?: () => number;
  breakpoints?: SwiperOptions['breakpoints'];
  iconsArrowRight?
  iconsArrowLeft?
  speed?: number
  delay?: number
};

type ISwiper = {
  slidePrev: () => void;
  slideNext: () => void;
  slideTo: (index: number) => void;
  activeIndex: number;
  realIndex: number;
  destroyed: boolean;
  update: () => void;
};

const SwiperSliderView: React.FC<PropsWithChildren<Props>> = (props: PropsWithChildren<Props>) => {
  const [swiper, setSwiper] = useState<ISwiper | null>(null);

  const modules: SwiperModule[] = [];

  const handleNextClick = () => {
    swiper?.slideNext();

    if (props.handleNextClick) {
      props.handleNextClick();
    }
  };

  const handlePrevClick = () => {
    swiper?.slidePrev();

    if (props.handlePrevClick) {
      props.handlePrevClick();
    }
  };

  const handleSlideTo = (swiper: ISwiper, index?: number | string) => {
    if (!swiper || swiper?.destroyed) {
      return;
    }

    if (index === 'auto') {
      index = props.getActiveSlideFromDom?.();
    }

    if (Number(index) > 0) {
      swiper.slideTo(Number(index));
    }
  };

  if (props.autoplay) {
    modules.push(Autoplay);
  }

  if (props.pagination) {
    modules.push(Pagination);
  }

  if (props.navigation) {
    modules.push(Navigation);
  }


  return (
    <div className={`swiper-wrapper${props.pagination ? ' with-pagination' : ''}${props.navigation ? ' with-navigation' : ''}`}>
      <div className="swiper-container items">
        {props.navigation && (
          <div className="navigation swiper-prev">
            <FontAwesomeIcon className="arrow" icon={props.iconsArrowLeft ? props.iconsArrowLeft : faCircleArrowLeft} onClick={handlePrevClick} />
          </div>

        )}
        <Swiper
          onAfterInit={(swiper) => props.slideToIndex && handleSlideTo(swiper, props.slideToIndex)}
          updateOnWindowResize
          className="mySwiper"
          modules={modules}
          onSwiper={(s) => {
            setSwiper(s as unknown as ISwiper);
          }}
          pagination={props.pagination && {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true
          }}
          autoplay={props.autoplay && {
            delay: 2500,
            disableOnInteraction: false,
          }}
          speed={1200}
          allowTouchMove={props.allowTouchMove ?? true}
          loop={props.loop ?? false}
          spaceBetween={props.spaceBetween ?? 12}
          slidesOffsetBefore={props.slidesOffsetBefore ?? 0}
          slidesOffsetAfter={props.slidesOffsetAfter ?? 0}
          slidesPerView={props.slidesPerView ?? 'auto'}
          centeredSlides={props.centeredSlides ?? props.slidesPerView === 'auto'}
          slideToClickedSlide={props.slideToClickedSlide ?? true}
          initialSlide={props.initialSlide ?? 0}
          // watchSlidesProgress
          breakpoints={{
            // default settings for breakpoints + custom (props.breakpoints)
            // when window width is >= 990px
            990: {
              // slidesPerView: 2,
            }, ...props.breakpoints
          }}
        >
          {props.isLoading ? (
            <SwiperSlide className="swiper-slide swiper-loading">
              <Spinner class="extra-small"></Spinner>
            </SwiperSlide>
          ) :
            props.children
          }
        </Swiper>
        {props.navigation && (
          <div className="navigation swiper-next">
            <FontAwesomeIcon className="arrow" icon={props.iconsArrowRight ? props.iconsArrowRight : faCircleArrowRight} onClick={handleNextClick} />
          </div>
        )}
      </div>
      {props.pagination && (
        <div className="swiper-pagination"></div>
      )}
    </div>
  );
};

SwiperSliderView.displayName = 'SwiperSliderView';

export default SwiperSliderView;
