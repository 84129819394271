import { createAsyncThunk } from '@reduxjs/toolkit';
import { IGetCarPartDataParams } from 'models/car-parts.model';
import useHttp from 'utils/hooks/useHttp';

export const fetchPartsList = createAsyncThunk('car-parts/fetchPartsList', async (params: {vehicle_id: string, data: IGetCarPartDataParams}, { rejectWithValue }) => {
  try {
    const http = useHttp();
    const response = await http.carParts.getPartsList(params);

    return response;
  } catch (e) {

    return rejectWithValue('fetch parts error');
  }
});

export const fetchPartsListFilter = createAsyncThunk(
  'car-parts/fetchPartsListFilter',
  async (params: {vehicle_id: string, data: IGetCarPartDataParams}, { rejectWithValue }) => {
    try {
      const http = useHttp();
      const response = await http.carParts.getPartsListFilter(params);


      return response;
    } catch (e) {
      return rejectWithValue('fetch parts error');
    }
  },
);

export const fetchPartsListOE = createAsyncThunk(
  'car-parts/fetchPartsListOE',
  async (params: {vehicle_id: string, data: IGetCarPartDataParams}, { rejectWithValue }) => {
    try {
      const http = useHttp();
      const response = await http.carParts.getPartsListOE(params);


      return response;
    } catch (e) {
      return rejectWithValue('fetch parts error');
    }
  },
);
