import React, {createContext, useState} from 'react';
import {IFavoriteList, IFavoritePagination} from '../models/favorite';
import {ICarPartData} from '../models/car-parts.model';
import {notify} from 'utils/marketplace';
import useHttp from 'utils/hooks/useHttp';
import {useTranslation} from 'react-i18next';

type FavoriteContextProviderProps = {
  children: React.ReactNode;
}

export type IFavoriteContext = {
  list: IFavoriteList[] | [];
  setList: (lists: IFavoriteList[]) => void;
  selectedList: IFavoriteList | undefined;
  setSelectedList: (list: IFavoriteList | undefined) => void;
  favoriteItemsLoading: boolean;
  setFavoriteItemsLoading: (favoriteItemsLoading: boolean) => void;
  favoriteListLoading: boolean;
  setFavoriteListLoading: (favoriteListLoading: boolean) => void;
  loadingCreateList: boolean;
  setLoadingCreateList: (loadingCreateList: boolean) => void;
  favoriteItems: ICarPartData[];
  setFavoriteItems: (favoriteItems: ICarPartData[]) => void;
  pagination: IFavoritePagination;
  setPagination: (pagination: IFavoritePagination) => void;
  handlePageClick: (page: number) => void;
  getFavoriteItems: (favorite_category_id: string) => void;
  newListActive: boolean;
  setNewListActive: (newListActive: boolean) => void;
  newListValue: string;
  setNewListValue: (newListValue: string) => void;
}

export const FavoriteContext = createContext<IFavoriteContext | null>(null);

export const FavoriteContextProvider:React.FC<FavoriteContextProviderProps> = (props) => {
  const [favoriteItemsLoading, setFavoriteItemsLoading] = useState<boolean>(true);
  const [selectedList, setSelectedList] = useState<IFavoriteList>();
  const [list, setList] = useState<IFavoriteList[]>([]);
  const [favoriteItems, setFavoriteItems] = useState<ICarPartData[]>([]);
  const [favoriteListLoading, setFavoriteListLoading] = useState<boolean>(false);
  const [loadingCreateList, setLoadingCreateList] = useState<boolean>(false);

  const [newListActive, setNewListActive] = useState<boolean>(false);
  const [newListValue, setNewListValue] = useState<string>('');

  const [pagination, setPagination] = useState<IFavoritePagination>({
    page: 1,
    per_page: 10,
    total_count: 0,
    total_pages: 0,
  });

  const http = useHttp();
  const {t} = useTranslation();

  const getFavoriteItems = async (favorite_category_id: string) => {
    try {

      setFavoriteItemsLoading(true);
      const response = await http.favorite.getFavoriteListItems(favorite_category_id, String(pagination.page), String(pagination.per_page));

      setPagination({
        page: response.page,
        per_page: response.per_page,
        total_count:response.total_count,
        total_pages: response.total_pages,
      });
      setFavoriteItems(response.data);

    } catch (e) {
      console.log(e);
      notify(t('_error'));
    } finally {
      setFavoriteItemsLoading(false);
    }
  };

  const handlePageClick = (page) => {
    const paginationCopy = pagination;

    paginationCopy.page = page;
    setPagination(paginationCopy);
    getFavoriteItems(String(selectedList?.id));
  };


  return (
    <FavoriteContext.Provider value={{
      getFavoriteItems,
      handlePageClick,
      pagination,
      setPagination,
      favoriteItemsLoading,
      setFavoriteItemsLoading,
      selectedList,
      setSelectedList,
      list,
      setList,
      favoriteItems,
      setFavoriteItems,
      favoriteListLoading,
      setFavoriteListLoading,
      loadingCreateList,
      setLoadingCreateList,
      newListActive,
      setNewListActive,
      newListValue,
      setNewListValue
    }}
    >
      {props.children}
    </FavoriteContext.Provider>
  );
};

