import {
  GetAllCartPartsResponse, ICarPartData,
  ICarPartItemViewParams, IEquivalenceCarsResponse, IEquivalencesResponse, IEquivalenceTrucksResponse,
  IGetCarPartByRefParams,
  IGetCarPartDataParams, IReliableCarParts, ITeccomDisponibility, ITeccomParams, CarPartsResponse, CarPartsFilterResponse,
  IPartsFiltersResponse,
  INewCarPartsResponse,
  INewCarPartsFilter,
  INewCarPartsInfo,
  ICarPart,
  IEquivalencesV2Response
} from 'models/car-parts.model';
import { addRouteParam, serverAxios } from '../../http';
import { HttpResponse } from './history.http';

class CarPartsHttp {
  private static route = '/car-parts';
  private static work_difficulty = 'reliable/car-parts/work-difficulty';
  private static teccom = '/teccom/disponibility';
  private static carPartView = '/car-parts/view/';
  private static carPartEquivalences = 'reliable/car-parts/equivalences/v2/';
  private static carPartCars = '/car-parts/cars/';
  private static carPartCarsFilter = 'reliable/car-parts/cars/filters/';
  private static carPartTrucks = '/car-parts/trucks/';
  private static reliableCarParts = 'reliable/car-parts/searchV2';
  private static catalogCarParts = 'catalog/:catalog_id/car-parts/search';
  private static reliableFilter = 'reliable/car-parts/filtersV2';
  private static reliableFilterV3automotor = 'reliable/car-parts/filtersV3';
  private static catalogFilter = 'catalog/:catalog_id/car-parts/filters';
  private static reliableCarPartsView = 'reliable/car-parts/view/:id';
  private static reliableCarPartEquivalences = 'reliable/car-parts/equivalences/v2/:id';
  private static retailerCarPartEquivalencesV3 = 'reliable/car-parts/retailer/equivalences/v3/:id';
  private static reliableCarPartEquivalencesV3 = 'reliable/car-parts/equivalences/v3/:id';
  private static reliableCarPartCars = 'reliable/car-parts/cars/:id';
  private static reliableCarPartTrucks = 'reliable/car-parts/trucks/:id';

  private static reliableCarPartCatalog = 'reliable/:vehicle_id/all-oe-list/car-parts?';
  private static oeCarPartsV3 = 'reliable/car-parts/retailer/searchV3/OEInfo';
  private static oemCarPartsV3 = 'reliable/car-parts/retailer/searchV3';
  private static newReliableCarPartCatalog = 'reliable/:vehicle_id/oem-parts';
  private static reliableCarPartFilter = 'reliable/:vehicle_id/all-oe-filter/car-parts?';
  private static newReliableCarPartFilter = 'reliable/:vehicle_id/oem-parts/filter';
  private static genArtCarParts = 'reliable/:vehicle_id/car-parts-by-genart';

  private static oeCarPartsV3automotor = 'reliable/car-parts/searchV3/OEInfo';
  private static oemCarPartsV3automotor = 'reliable/car-parts/searchV3';

  private static newReliableCarPartOEFilter = 'reliable/:vehicle_id/oem/info';

  public async getCarPartsData(params: IGetCarPartDataParams): Promise<GetAllCartPartsResponse> {
    const { data } = await serverAxios.get(CarPartsHttp.route, { params });

    return data;
  }
  public async getWorkDifficulty(params: { genArtNr: string, itemMpId: number }): Promise<{ work_difficulty: string }> {
    const { data } = await serverAxios.get(CarPartsHttp.work_difficulty, { params });

    return data;
  }

  public async getReliableCarParts(params: IReliableCarParts): Promise<GetAllCartPartsResponse> {
    const { data } = await serverAxios.get(CarPartsHttp.reliableCarParts, { params });

    return data;
  }

  public async getCatalogCarParts(catalog_id: string, params: IReliableCarParts): Promise<GetAllCartPartsResponse> {
    // const { data } = await serverAxios.get(CarPartsHttp.reliableCarParts, { params });
    const { data } = await serverAxios.get(addRouteParam(CarPartsHttp.catalogCarParts, { catalog_id }), { params });

    return data;
  }

  public async getReliableFilters(params: IReliableCarParts): Promise<IPartsFiltersResponse> {
    const { data } = await serverAxios.get(CarPartsHttp.reliableFilter, { params });

    return data;
  }

  public async getCatalogFilters(catalog_id: string, params: IReliableCarParts): Promise<IPartsFiltersResponse> {
    const { data } = await serverAxios.get(addRouteParam(CarPartsHttp.catalogFilter, { catalog_id }), { params });

    return data;
  }
  public async getCarPartsByRef(params: IGetCarPartByRefParams): Promise<GetAllCartPartsResponse> {
    const { data } = await serverAxios.get(CarPartsHttp.route, { params });

    return data;
  }
  public async getTeccomDisponibility(params: ITeccomParams): Promise<ITeccomDisponibility> {
    const { data } = await serverAxios.post(CarPartsHttp.teccom, { ...params });

    return data;
  }

  public async getCarPartView(reference: string, params: ICarPartItemViewParams): Promise<ICarPartData> {

    const { data } = await serverAxios.get(CarPartsHttp.carPartView + reference, { params });

    return data;
  }
  public async getReliableCarPartView(id: string, params: ICarPartItemViewParams): Promise<ICarPartData> {
    const { data } = await serverAxios.get(addRouteParam(CarPartsHttp.reliableCarPartsView, { id }), { params });


    return data;
  }

  public async getCarPartEquivalences(reference: string, params: ICarPartItemViewParams): Promise<IEquivalencesResponse> {

    const { data } = await serverAxios.get(CarPartsHttp.carPartEquivalences + reference, { params });

    return data;
  }


  public async getCarPartEquivalencesFilter(reference: string, params: ICarPartItemViewParams): Promise<IEquivalenceCarsResponse> {

    const { data } = await serverAxios.get(CarPartsHttp.carPartCarsFilter + reference, { params });

    return data;
  }


  public async getCarPartCarsEquivalences(reference: string, params: ICarPartItemViewParams): Promise<IEquivalenceCarsResponse> {

    const { data } = await serverAxios.get(CarPartsHttp.carPartCars + reference, { params });

    return data;
  }

  public async getCarPartTrucksEquivalences(reference: string, params: ICarPartItemViewParams): Promise<IEquivalenceTrucksResponse> {

    const { data } = await serverAxios.get(CarPartsHttp.carPartTrucks + reference, { params });

    return data;
  }
  public async getReliableCarPartEquivalences(id: string, params: ICarPartItemViewParams): Promise<IEquivalencesResponse> {

    const { data } = await serverAxios.get(addRouteParam(CarPartsHttp.reliableCarPartEquivalences, { id }), { params });

    return data;
  }

  public async getRetailerCarPartEquivalences(id: string, params: ICarPartItemViewParams): Promise<IEquivalencesV2Response> {

    const { data } = await serverAxios.get(addRouteParam(CarPartsHttp.retailerCarPartEquivalencesV3, { id }), { params });

    return data;
  }
  public async getCarPartEquivalencesV3(id: string, params: ICarPartItemViewParams): Promise<IEquivalencesV2Response> {

    const { data } = await serverAxios.get(addRouteParam(CarPartsHttp.reliableCarPartEquivalencesV3, { id }), { params });

    return data;
  }


  public async getReliableCarPartCarsEquivalences(id: string, params: ICarPartItemViewParams): Promise<IEquivalenceCarsResponse> {

    const { data } = await serverAxios.get(addRouteParam(CarPartsHttp.reliableCarPartCars, { id }), { params });

    return data;
  }

  public async getReliableCarPartTrucksEquivalences(id: string, params: ICarPartItemViewParams): Promise<IEquivalenceTrucksResponse> {

    const { data } = await serverAxios.get(addRouteParam(CarPartsHttp.reliableCarPartTrucks, { id }), { params });

    return data;
  }

  public async getReliableCarPartCatalog(vehicle_id: string, params: IGetCarPartDataParams): Promise<CarPartsResponse> {
    const { data } = await serverAxios.get(addRouteParam(CarPartsHttp.reliableCarPartCatalog, { vehicle_id }), { params });

    return data;
  }

  public async getOEMCarPartsV3(params: IGetCarPartDataParams): Promise<ICarPartData[]> {
    const { data } = await serverAxios.get(CarPartsHttp.oemCarPartsV3, { params });

    return data;
  }
  public async getOECarPartsV3(params: IGetCarPartDataParams): Promise<ICarPartData> {
    const { data } = await serverAxios.get(CarPartsHttp.oeCarPartsV3, { params });

    return data;
  }


  public async getOEMCarPartsV3automotor(params: IGetCarPartDataParams): Promise<ICarPart> {
    const { data } = await serverAxios.get(CarPartsHttp.oemCarPartsV3automotor, { params });

    return data;
  }
  public async getOECarPartsV3automotor(params: IGetCarPartDataParams): Promise<ICarPartData> {
    const { data } = await serverAxios.get(CarPartsHttp.oeCarPartsV3automotor, { params });

    return data;
  }

  public async getReliableFiltersV3automotor(params: IReliableCarParts): Promise<IPartsFiltersResponse> {
    const { data } = await serverAxios.get(CarPartsHttp.reliableFilterV3automotor, { params });

    return data;
  }

  public async getNewReliableCarPartCatalog(vehicle_id?: string, params?: IGetCarPartDataParams): Promise<INewCarPartsResponse> {
    const { data } = await serverAxios.get(addRouteParam(CarPartsHttp.newReliableCarPartCatalog, { vehicle_id }), { params });

    return data;
  }
  public async getPartsList(params: {vehicle_id: string, data: IGetCarPartDataParams} ): Promise<INewCarPartsResponse> {
    const { data } = await serverAxios.get(addRouteParam(CarPartsHttp.newReliableCarPartCatalog, { vehicle_id: params.vehicle_id }), { params: params.data });

    return data;
  }

  public async getPartsListFilter(params: {vehicle_id: string, data: IGetCarPartDataParams} ): Promise<INewCarPartsFilter> {
    const { data } = await serverAxios.get(addRouteParam(CarPartsHttp.newReliableCarPartFilter, { vehicle_id: params.vehicle_id }), { params: params.data });

    return data;
  }

  public async getPartsListOE(params: {vehicle_id: string, data: IGetCarPartDataParams}): Promise<INewCarPartsInfo> {
    const { data } = await serverAxios.get(addRouteParam(CarPartsHttp.newReliableCarPartOEFilter, { vehicle_id: params.vehicle_id }), { params: params.data });

    return data;
  }

  public async getReliableCarPartFilter(vehicle_id: string, params: IGetCarPartDataParams): Promise<CarPartsFilterResponse> {
    const { data } = await serverAxios.get(addRouteParam(CarPartsHttp.reliableCarPartFilter, { vehicle_id }), { params });

    return data;
  }
  public async getNewReliableCarPartFilter(vehicle_id: string, params: IGetCarPartDataParams): Promise<INewCarPartsFilter> {
    const { data } = await serverAxios.get(addRouteParam(CarPartsHttp.newReliableCarPartFilter, { vehicle_id }), { params });

    return data;
  }

  public async getGenArtCarPart(vehicle_id: string, params: IGetCarPartDataParams): Promise<HttpResponse<CarPartsResponse>> {
    return await serverAxios.get(addRouteParam(CarPartsHttp.genArtCarParts, { vehicle_id }), { params });
  }

  public async getNewReliableCarPartOEInfo(vehicle_id: string, params: IGetCarPartDataParams): Promise<INewCarPartsInfo> {
    const { data } = await serverAxios.get(addRouteParam(CarPartsHttp.newReliableCarPartOEFilter, { vehicle_id }), { params });

    return data;
  }
}

export default CarPartsHttp;
