import { HttpResponse } from 'utils/hooks/http/history.http';

import { tecRMIAxios, serverAxios, addRouteParam } from '../../http';
import { ITyres, ITyresCategorie, ITyreFilter } from '../interface/tyres.interface';
import { IBodyResponseTyre, ITyre } from 'interfaces/tyre';

class TyresHttpTecRmi {
  private static VehicleHasTyres = '/Tyres/AllTyresForType';

  private static vehicleTyresCategories = '/tyres/vehicle-categories';
  private static vehicleTyresProducts = '/tyres/products';
  private static vehicleTyresFilters = '/tyres/filters';
  private static vehicleTyreById = '/tyres/products/:id';

  public async fetchRMIVehicleHasTyres(params: {}): Promise<HttpResponse<ITyres[]>> {
    return tecRMIAxios.get(TyresHttpTecRmi.VehicleHasTyres, { params });
  }

  public async fetchVehicleTyresCategories(params: {}): Promise<HttpResponse<ITyresCategorie[]>> {
    return serverAxios.get(TyresHttpTecRmi.vehicleTyresCategories, { params });
  }

  public async fetchVehicleTyresProducts(params: {}): Promise<HttpResponse<IBodyResponseTyre>> {
    return serverAxios.get(TyresHttpTecRmi.vehicleTyresProducts, { params });
  }

  public async fetchVehicleTyresFilters(params: {}): Promise<HttpResponse<ITyreFilter>> {
    return serverAxios.get(TyresHttpTecRmi.vehicleTyresFilters, { params });
  }

  public async getTyreById(id: string): Promise<HttpResponse<ITyre>> {
    return serverAxios.get(addRouteParam(TyresHttpTecRmi.vehicleTyreById, {id}));
  }
}

export default TyresHttpTecRmi;
