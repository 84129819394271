import React, {createContext, useRef, useState} from 'react';
import { IMessage, IMessagePaginate} from '../interfaces/messaging';
import MessagingHttp from '../utils/hooks/http/messaging';


type MessagesProviderProps = {
  children: React.ReactNode;
};

export type IMessagesContext = {
  pagination: IMessagePaginate;
  setPagination: (pagination: IMessagePaginate) => void;
  messages: IMessage[] | [];
  setMessages: (messages: IMessage[] | []) => void;
  files: (File[] | []);
  setFiles: (files: File[]| []) => void;
  loadingMessages: boolean;
  isLoadingMessages: (loading: boolean) => void;
  messageIsCreating: boolean;
  setMessageIsCreating: (loading: boolean) => void;
  newMessagesLoading: boolean;
  isNewMessagesLoading: (loading: boolean) => void;
  lastMessageId: number | null;
  setLastMessageId: (lastMessageId: number | null) => void;
  ref:React.MutableRefObject<HTMLDivElement>;
  bottom: React.MutableRefObject<HTMLDivElement>;
  scrollOnTop: boolean;
  isScrollOnTop: (scrollOnTop: boolean) => void;
  loadingOldMessages: boolean;
  isLoadingOldMessages: (loadingOldMessages: boolean) => void;
};

export const MessagesContext = createContext<IMessagesContext | null>(null);

export const MessagesContextProvider: React.FC<MessagesProviderProps> = (props) => {
  const [pagination, setPagination] = useState<IMessagePaginate>({
    page: 1,
    per_page: MessagingHttp.PER_PAGE,
    total_count: 0,
    unseen_messages: 0,
  });

  const [messages, setMessages] = useState<IMessage[] | []>([]);
  const [files, setFiles] = useState<File[] | []>([]);
  const [loadingMessages, isLoadingMessages] = useState<boolean>(false);
  const [messageIsCreating, setMessageIsCreating] = useState<boolean>(false);
  const [newMessagesLoading, isNewMessagesLoading] = useState<boolean>(false);
  const [lastMessageId, setLastMessageId] = useState<number | null>(null);

  const ref = useRef() as React.MutableRefObject<HTMLDivElement>;
  const bottom = useRef() as React.MutableRefObject<HTMLDivElement>;
  const [scrollOnTop, isScrollOnTop] = useState(false);
  const [loadingOldMessages, isLoadingOldMessages] = useState<boolean>(false);

  return <MessagesContext.Provider value={{ref, bottom, scrollOnTop,isScrollOnTop, loadingOldMessages,isLoadingOldMessages, pagination, setPagination, messages, setMessages, files, setFiles, loadingMessages, isLoadingMessages, messageIsCreating, setMessageIsCreating, newMessagesLoading, isNewMessagesLoading, lastMessageId, setLastMessageId}}>
    {props.children}
  </MessagesContext.Provider>;
};
