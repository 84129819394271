import React, { useEffect, useState } from 'react';

import './PlatformAdminClientMessagesPage.scss';
import PlatformAdminClientMessagesNavbar
  from './messages-platform-admin-client-navbar/PlatformAdminClientMessagesNavbar';
import PlatformAdminClientMessages
  from 'components/messages/PlatformAdminClientMessages/PlatformAdminClientMessages';
import { MessagesContextProvider } from '../../context/MessagesContext';
import { IClient } from '../../models/client.model';
import useHttp from '../../utils/hooks/useHttp';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const PlatformAdminClientMessagesPage: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [clientDetails, setClientDetails] = useState<IClient | null>(null);
  const { client_id } = useParams();

  const { clients } = useHttp();
  const { t } = useTranslation();

  useEffect(() => {
    getClientDetails();
  }, []);

  const getClientDetails = () => {
    setLoading(true);
    clients.getClientDetail(Number(client_id)).then((client: IClient) => {
      setClientDetails(client);
      setLoading(false);
    });
  };

  return (
    <div className="catalog-supplier-layout-container">
      <PlatformAdminClientMessagesNavbar title={t('_discussions_client')} />
      <MessagesContextProvider>
        <PlatformAdminClientMessages loading={loading} clientDetails={clientDetails} />
      </MessagesContextProvider>
    </div>
  );
};

PlatformAdminClientMessagesPage.displayName = 'PlatformAdminClientMessagesPage';

export default PlatformAdminClientMessagesPage;