import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useHttp from 'utils/hooks/useHttp';
import { ICarPartData } from 'models/car-parts.model';
import { useSearchParams, useLocation, useParams } from 'react-router-dom';
import UniversalPartsNavbar from 'components/navbar/universal-parts-navbar/UniversalPartsNavbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import { useOnClickOutside } from 'utils/hooks/useOnClickOutside';
import PopupInfo from '../PopupInfo/PopupInfo';
import { getCurrentCountryRMI, getCurrentLanguageRMI } from 'utils/rmi/rmi-utils/rmi-utils';
import i18n from 'i18n';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { IRepairItemsWorkItemMps, IVehicleRepairItemWork } from 'utils/rmi/interface/repair-items.interface';
import { CAR_PRO, CAR_TECH } from 'utils/rmi/reliableTabs';
import ProductsViewMode from '../ProductsViewMode/ProductsViewMode';
import { DEFAULT_VIEW } from 'components/ProductsViewMode/ProductsViewMode.constants';
import { CATALOG_PART_OEM_V2 } from 'components/ProductsViewModeChanger/products-view-mode-changer-utils';
import ProductsViewModeChanger from 'components/ProductsViewModeChanger/ProductsViewModeChanger';
import SortBy, { SortByParams } from '../SortBy/SortBy';
import { sortByOptions } from '../SortBy/SortBy.map';
import './PartsListV2.scss';
import PartsFilterV2 from './PartsFilter/PartsFilterV2';
import { ReactComponent as PartsNotFoundImage } from 'assets/images/parts-not-found.svg';
import GlobalSearchInput from 'components/inputs/GlobalSearchInput/GlobalSearchInput';
import { isRetailerMecadepotChild } from 'utils/isRetailerMecadepotChild';

type Props = {
  inCatalog?: boolean;
};

const PartsListV2: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const http = useHttp();
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [oeData, setOEData] = useState<ICarPartData>();
  const [oemData, setOEMData] = useState<ICarPartData[]>();
  const [filteredRefData, setFilteredRefData] = useState<ICarPartData[] | undefined>();
  const [openFilter, setOpenFilter] = useState(false);
  const [page, setPage] = useState(Number(searchParams.get('page')));
  const { pathname } = useLocation();
  const isVehiclePartCatalog = pathname.includes('part-catalog');
  const { vehicle_id } = useParams<'vehicle_id'>();
  const { typeId } = useSelector((state: RootState) => state.tecrmi);
  const [popupInfoIsOpen, setPopupInfoIsOpen] = useState<boolean>(false);
  const popupInfoRef = useRef() as MutableRefObject<HTMLDivElement>;
  const [popupInfoLoading, setPopupInfoLoading] = useState(false);
  const [selectedWork, setSelectedWork] = useState<IVehicleRepairItemWork>();
  const { user } = useSelector((state: RootState) => state.userData);
  const [viewMode, setViewMode] = useState(user?.user_settings.products_view_mode || DEFAULT_VIEW);

  useOnClickOutside(popupInfoRef, () => setPopupInfoIsOpen(false));

  useEffect(() => {
    getData({ page });
  }, [
    i18n.language,
    searchParams.get('page'),
    searchParams.get('q'),
    searchParams.get('subcategory_id'),
    searchParams.get('source'),
    searchParams.get('type'),

  ]);

  function getData(filterParams?) {
    getOEMParts(filterParams);
    getOEParts(filterParams);
  }

  function getOEMParts(filterParams) {
    setLoading(true);

    const sortBySelectedOption = sortByOptions.filter((item) => String(item.value) == String(searchParams.get('sort')))[0] ?? [];

    const sortParams: SortByParams = {
      sort_by_column: String(searchParams.get('sort') ?? ''),
      sort_by_asc: String(searchParams.get('asc') ?? sortBySelectedOption?.default_sort_asc ?? ''),
    };

    const params = {
      source: searchParams.get('source'),
      man_sup_id: searchParams.get('man_sup_id'),
      subcategory_id: searchParams.get('subcategory_id'),
      q: searchParams.get('q'),
      page: searchParams.get('page'),
      ...filterParams,
      ...sortParams,
      ...(searchParams.get('is_platform_admin') && { is_platform_admin: true }),
      ...(searchParams.get('type') && { type: searchParams.get('type') }),
    };

    const validParams = validateParams(params);


    http.carParts
      .getOEMCarPartsV3(validParams)
      .then((res) => {
        setOEMData(res);
      })
      .catch(() => setLoading(false));
  }

  function getOEParts(filterParams) {

    const sortBySelectedOption = sortByOptions.filter((item) => String(item.value) == String(searchParams.get('sort')))[0] ?? [];

    const sortParams: SortByParams = {
      sort_by_column: String(searchParams.get('sort') ?? ''),
      sort_by_asc: String(searchParams.get('asc') ?? sortBySelectedOption?.default_sort_asc ?? ''),
    };

    const params = {
      source: searchParams.get('source'),
      man_sup_id: searchParams.get('man_sup_id'),
      subcategory_id: searchParams.get('subcategory_id'),
      q: searchParams.get('q'),
      page: searchParams.get('page'),
      ...filterParams,
      ...sortParams,
      ...(searchParams.get('is_platform_admin') && { is_platform_admin: true }),
      ...(searchParams.get('type') && { type: searchParams.get('type') }),
    };

    const validParams = validateParams(params);

    setOEData(undefined);

    http.carParts
      .getOECarPartsV3(validParams)
      .then((res) => {
        setOEData(res);
      });
  }

  const validateParams = (data) => {
    return Object.entries(data).reduce((acc, [key, value]) => {
      if (!value) {
        return acc;
      }

      acc[key] = value;

      return acc;
    }, {});
  };

  function handleOpenPopup() {
    setPopupInfoIsOpen(true);
  }


  const getWorkTimeInfo = async () => {
    setPopupInfoLoading(true);

    if (oeData) {
      const genArtNr = oeData?.genartnr;

      const { data: itemIds } = await http.pricesHttp.fetchRMIItemMpIdForGenArt({ genArtNo: genArtNr ?? null });

      const { data: vehicleRepairItems } = await http.repairItemsHttp.fetchWorkListList({
        typeId: typeId,
        countryCode: getCurrentCountryRMI(i18n.language),
        languageCode: getCurrentLanguageRMI(i18n.language),
      });

      const vehicleItems: IRepairItemsWorkItemMps[] = [];

      vehicleRepairItems.forEach((group) => {
        group.SubGroups.forEach((subGroup) => {
          subGroup.ItemMps.forEach((item) => {
            vehicleItems.push(item);
          });
        });
      });

      const itemMps = vehicleItems.filter(
        (v) =>
          v.ItemMpId ===
          (() => {
            return itemIds.find((itemId) => vehicleItems.find((v) => v.ItemMpId === itemId) !== undefined);
          })(),
      );

      const { data: vehicleRepairItem } = await http.repairItemsHttp.fetchWorkStepsList({
        typeId,
        itemMpId: itemMps[0].ItemMpId,
        korId: itemMps[0].KorId,
        countryCode: getCurrentCountryRMI(i18n.language),
        languageCode: getCurrentLanguageRMI(i18n.language),
      });

      setSelectedWork(vehicleRepairItem[0]);
    }

    setPopupInfoLoading(false);
  };

  function countResults() {
    const displayedData = filteredRefData || oemData;

    if (displayedData && displayedData?.length) {
      return displayedData?.length;
    }

    return 0;
  }

  function getAvailability(item) {
    if (item.available?.warehouse_main?.quantity) {
      return item.available?.warehouse_main?.quantity;
    }

    if (item.available?.warehouse_all?.quantity) {
      return item.available?.warehouse_all?.quantity;
    }

    Object.keys(item.available).map(key => {
      if (key.startsWith('warehouse') && key !== 'warehouse_main') {
        return item.available?.[key]?.quantity;
      }
    });

    if (item.available?.quantity) {
      return item.available?.quantity;
    }

    return -1;
  }

  function getPrice(item) {
    if (item.price?.price) {
      return item.price.price;
    }

    return -1;
  }

  useEffect(() => {
    if (vehicle_id) {
      getWorkTimeInfo();
    }
  }, [oemData, vehicle_id]);

  useEffect(() => {
    setFilteredRefData(() => {
      if (!searchParams.get('sup')) {
        return oemData;
      } else {
        const filteredItems = oemData?.filter((part) => part.data_supplier.id === searchParams.get('sup'));

        return filteredItems;
      }
    });

    if (oemData) {
      setLoading(false);
    }
  }, [oemData, searchParams.get('sup')]);

  useEffect(() => {
    setFilteredRefData((prev) => {

      const data = prev || oemData;

      if (data?.length && searchParams.get('sort') === 'name') {
        const sortedData = [...data];

        if (searchParams.get('asc') === 'true') {
          sortedData.sort((a, b) => {
            const nameA = a.data_supplier.name || 'Z';
            const nameB = b.data_supplier.name || 'Z';

            return nameA.localeCompare(nameB);
          });

          return sortedData;
        }

        if (searchParams.get('asc') === 'false') {
          sortedData.sort((a, b) => {
            const nameA = a.data_supplier.name || 'Z';
            const nameB = b.data_supplier.name || 'Z';

            return nameB.localeCompare(nameA);
          });

          return sortedData;
        }
      }

      if (data?.length && searchParams.get('sort') === 'reference_original') {
        const sortedData = [...data];

        if (searchParams.get('asc') === 'true') {
          sortedData.sort((a, b) => a.data_supplier_reference_original.localeCompare(b.data_supplier_reference_original));

          return sortedData;
        }

        if (searchParams.get('asc') === 'false') {
          sortedData.sort((a, b) => b.data_supplier_reference_original.localeCompare(a.data_supplier_reference_original));

          return sortedData;
        }
      }

      if (data?.length && searchParams.get('sort') === 'price') {
        const sortedData = [...data];

        if (searchParams.get('asc') === 'true') {
          sortedData.sort((a, b) => getPrice(a) - getPrice(b));

          return sortedData;
        }

        if (searchParams.get('asc') === 'false') {
          sortedData.sort((a, b) => getPrice(b) - getPrice(a));

          return sortedData;
        }
      }

      if (data?.length && searchParams.get('sort') === 'available') {
        const sortedData = [...data];

        if (searchParams.get('asc') === 'true') {
          sortedData.sort((a, b) => getAvailability(a) - getAvailability(b));

          return sortedData;
        }

        if (searchParams.get('asc') === 'false') {
          sortedData.sort((a, b) => getAvailability(b) - getAvailability(a));

          return sortedData;
        }
      }

      if (!searchParams.get('asc') && !searchParams.get('sort')) {
        return searchParams.get('sup') ? oemData?.filter((part) => part.data_supplier.id === searchParams.get('sup')) : oemData;
      }

      return data;
    });

  }, [oemData, searchParams.get('asc'), searchParams.get('sort')]);

  function filterOptionsElement() {
    return (
      <div className="catalog-parts-filter">
        <button onClick={() => setOpenFilter(true)} className={`catalog-parts-button ${openFilter ? 'active' : ''}`}>
          {t('_filter_results_btn')}
        </button>
      </div>
    );
  }


  console.log(filteredRefData);

  return (
    <div className={`parts-wrapper ${isVehiclePartCatalog ? 'part-catalog-wrapper' : ''}    ${isRetailerMecadepotChild() ? 'wrapper-mecadepot' : ''}`}>
      <UniversalPartsNavbar option={filterOptionsElement()} title="_find_neccessary_parts" showSortBy={isRetailerMecadepotChild()} />
      <div className="separator"></div>
      <div className="filterOptions">
        <div className="parts-general-input">
          <div className="parts-input">
            <GlobalSearchInput
              placeholder={t('_free_search')}
              size="m"
              withIcon
              noValidation
            />
          </div>
        </div>
        {(filteredRefData && filteredRefData?.length > 0 && !isRetailerMecadepotChild()) && <SortBy />}
        {!loading &&
          filteredRefData &&
          filteredRefData?.length > 0 &&
          typeId &&
          vehicle_id &&
          filteredRefData &&
          user && [CAR_PRO, CAR_TECH.includes(user?.client_settings?.rmi_modules_type)] &&
          (
            <div ref={popupInfoRef} className="work-time" onClick={handleOpenPopup}>
              {t('_work_time')}
              <FontAwesomeIcon icon={faClock} className="clock-icon" />
              {popupInfoIsOpen && <PopupInfo loading={popupInfoLoading} data={selectedWork} />}
            </div>
          )}
        <div className="filter-body-part">
          <div className="results">
            {countResults()} {t('_results')}
          </div>
          <ProductsViewMode viewMode={viewMode} setViewMode={setViewMode} />
        </div>
      </div>

      {
        (filteredRefData?.length == 0 && oeData?.name == 'IAM EQUIVALENCE') || (filteredRefData?.length == 0 && isRetailerMecadepotChild()) ?
          (
            <div className="no-parts-data">
              <div className="notFoundSvg">
                <PartsNotFoundImage />
              </div>
              <h1>{t('_parts_not_found')}</h1>
            </div>
          ) :
          <ProductsViewModeChanger
            key={filteredRefData?.length}
            inCatalog={props.inCatalog}
            catalog={CATALOG_PART_OEM_V2}
            viewMode={viewMode}
            data={filteredRefData}
            oemLoading={loading}
            oeData={oeData}
          />
      }
      <PartsFilterV2
        getData={getData}
        data={oemData}
        setData={setFilteredRefData}
        setPage={setPage}
        openFilter={openFilter}
        setOpenFilter={setOpenFilter}
      />
    </div>
  );
};

export default PartsListV2;
