import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useHttp from 'utils/hooks/useHttp';
import './CreateCountryRestriction.scss';
import Spinner from 'components/spinner/Spinner';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import 'react-datepicker/dist/react-datepicker.css';
import { notify } from 'utils/marketplace';
import i18n from 'i18n';
import { useLocaleDatePicker } from 'utils/localeDatePicker';
import { IOption } from 'interfaces/select';
import Select from 'react-select';
import { IFilterValue } from 'interfaces/tyre';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';

type Props = {
  setOpenModal: (e: boolean) => void;
  setSubmit: (e: boolean) => void;
  clients;
  countries;
  loading: boolean;
};

const CreateCountryRestriction: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const http = useHttp();

  const [loading, setLoading] = useState<boolean>(props.loading);
  const [loadingSave, setLoadingSave] = useState<boolean>(false);
  const { isMobile } = useDeviceDetect();
  const [country, setCountry] = useState<IOption[]>([]);
  const [showRow, setShowRow] = useState<Number>(1);
  const [clients, setClients] = useState<IOption | null>();
  const [selectClient, setSelectClient] = useState<IOption | null>();

  const { user } = useSelector((state: RootState) => state.userData);

  const validate = () => {
    if (showRow == 1) {
      return Yup.object().shape({
        // client_id: Yup.object().shape({
        //   label: Yup.string().required(t('_fild_required')),
        //   value: Yup.string().required(t('_fild_required')),
        // }),
        origin_country: Yup.object().shape({
          label: Yup.string().required(t('_fild_required')),
          value: Yup.string().required(t('_fild_required')),
        }),
      });
    } else {
      return Yup.object().shape({
        origin_country_2: Yup.object().shape({
          label: Yup.string().required(t('_fild_required')),
          value: Yup.string().required(t('_fild_required')),
        }),
        delivery_country: Yup.object().shape({
          label: Yup.string().required(t('_fild_required')),
          value: Yup.string().required(t('_fild_required')),
        }),
      });
    }
  };

  const {
    getValues,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<{
    type_form: IFilterValue;
    origin_country: IFilterValue;
    delivery_country: IFilterValue;
    client_id: IFilterValue;
    origin_country_2: IFilterValue;
  }>({ resolver: yupResolver(validate()) });

  useLocaleDatePicker(i18n.language);

  useEffect(() => {
    if (!props.loading) {
      setCountry(
        props.countries.map((item) => ({
          label: String(item.name),
          value: String(item.iso_code),
        })),
      );
      setClients({ value: '1', label: String(t('_client')) });
      setSelectClient({ value: String(user?.client_id), label: String(user?.client_name) });
      setLoading(false);
    }
  }, [props.loading]);

  const onSubmit = async () => {
    const values = getValues();

    setLoadingSave(true);
    const formData: FormData = new FormData();

    if (showRow == 1) {
      formData.append('client_id', String(selectClient?.value));
      formData.append('origin_country', String(values?.origin_country.value));
    } else {
      const ids: Array<string> = [];

      props.clients.map((client) => {
        if (client.country == values?.delivery_country.label) {
          console.log(values?.delivery_country.label, client);
          ids.push(client.value);
        }
      });

      if (ids.length == 0) {
        notify(t('_error'), 'error', t('_not_have_client_for') + ' ' + values?.delivery_country.label);
        setLoadingSave(false);

        return;
      }

      ids.forEach((id) => {
        formData.append('client_id[]', id);
      });

      formData.append('origin_country', String(values?.origin_country_2.value));
    }

    http.countryRestriction
      .saveCountryRestriction(formData)
      .then(() => {
        notify(t('_created_successfully'), 'success', t('_success'));
        props.setOpenModal(false);
        props.setSubmit(true);
        setLoadingSave(false);
      })
      .catch((error) => {
        let erorrs = '';

        Object.entries(error).forEach(([key, value]) => {
          erorrs += `${t('_' + String(value))}`;
        });
        notify(erorrs, 'error', t('_error'));
        setLoadingSave(false);
      });
  };

  const customStyles = (hasError) => ({
    control: (styles) => ({
      ...styles,
      ...(hasError ? { borderColor: 'red' } : { border: 'none' }),
    }),
  });

  function handleChange(item) {
    setShowRow(Number(item.value));
  }

  return (
    <div className="create-country-restriction-container">
      <div
        className="close-modal"
        onClick={() => {
          props.setOpenModal(false);
        }}
      >
        <FontAwesomeIcon icon={faClose} />
      </div>
      <div className="title">{t('_create_country_restriction')}</div>
      {loading ? (
        <div className="spinner-info">
          {' '}
          <Spinner />{' '}
        </div>
      ) : (
        <React.Fragment>
          <div className="row">
            <div className="container">
              <label>{t('_type_restriction')}</label>
              <div className="input-item">
                <Controller
                  control={control}
                  name="type_form"
                  render={({ field: { onChange } }) => {
                    return (
                      <Select
                        value={clients}
                        className="select-dropdown"
                        onChange={(val) => {
                          onChange(val);
                          handleChange(val);
                          setClients(val);
                        }}
                        options={[
                          {
                            value: '1',
                            label: String(t('_client')),
                          },
                          {
                            value: '2',
                            label: String(t('_country')),
                          },
                        ]}
                        styles={customStyles(errors.client_id?.label?.type)}
                        isSearchable={!isMobile}
                      />
                    );
                  }}
                />
              </div>
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="modal-info">
              <div className={`row ${showRow == 1 ? '' : 'hide'} `}>
                <div className="container">
                  <label>{t('_client')}</label>
                  <div className="input-item">
                    <Controller
                      control={control}
                      name="client_id"
                      render={({ field: { onChange } }) => {
                        return (
                          <Select
                            value={selectClient}
                            className="select-dropdown"
                            onChange={(val) => {
                              onChange(val);
                              setSelectClient(val);
                            }}
                            options={props.clients}
                            placeholder={t('_client')}
                            styles={customStyles(errors.client_id?.label?.type)}
                            isSearchable={!isMobile}
                          />
                        );
                      }}
                    />
                    <p className="error">{errors.client_id?.label?.type == 'required' ? errors.client_id?.label.message : ''}</p>
                  </div>
                </div>
                <div className="container">
                  <label>{t('_origin_country')}</label>
                  <div className="input-item">
                    <Controller
                      control={control}
                      name="origin_country"
                      render={({ field: { onChange, value } }) => {
                        return (
                          <Select
                            value={value}
                            className="select-dropdown"
                            onChange={(val) => {
                              onChange(val);
                            }}
                            options={country}
                            placeholder={t('_origin_country')}
                            styles={customStyles(errors.origin_country?.label?.type)}
                            isSearchable={!isMobile}
                          />
                        );
                      }}
                    />
                    <p className="error">{errors.origin_country?.label?.type == 'required' ? errors.origin_country?.label.message : ''}</p>
                  </div>
                </div>
              </div>
              <div className={`row ${showRow == 2 ? '' : 'hide'} `}>
                <div className="container">
                  <label>{t('_clients_country')}</label>
                  <div className="input-item">
                    <Controller
                      control={control}
                      name="delivery_country"
                      render={({ field: { onChange, value } }) => {
                        return (
                          <Select
                            value={value}
                            className="select-dropdown"
                            onChange={(val) => {
                              onChange(val);
                            }}
                            options={country}
                            placeholder={t('_clients_country')}
                            styles={customStyles(errors.delivery_country?.label?.type)}
                            isSearchable={!isMobile}
                          />
                        );
                      }}
                    />
                    <p className="error">
                      {errors.delivery_country?.label?.type == 'required' ? errors.delivery_country?.label.message : ''}
                    </p>
                  </div>
                </div>
                <div className="container">
                  <label>{t('_origin_country')}</label>
                  <div className="input-item">
                    <Controller
                      control={control}
                      name="origin_country_2"
                      render={({ field: { onChange, value } }) => {
                        return (
                          <Select
                            value={value}
                            className="select-dropdown"
                            onChange={(val) => {
                              onChange(val);
                            }}
                            options={country}
                            placeholder={t('_origin_country')}
                            styles={customStyles(errors.origin_country_2?.label?.type)}
                            isSearchable={!isMobile}
                          />
                        );
                      }}
                    />
                    <p className="error">
                      {errors.origin_country_2?.label?.type == 'required' ? errors.origin_country_2?.label.message : ''}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="btn-save">
              <button type="submit">
                {' '}
                {loadingSave ? <Spinner class="extra-small" /> : null} <div className="title-btn-save">{t('_save')}</div>
              </button>
            </div>
          </form>
        </React.Fragment>
      )}
    </div>
  );
};

CreateCountryRestriction.displayName = 'CreateCountryRestriction';

export default CreateCountryRestriction;
