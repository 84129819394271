import React, { PropsWithChildren} from 'react';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import { useTranslation } from 'react-i18next';
import './DeleteConfirmationTariffModal.scss';

type Props = {
  setOpenModal: (openModal: boolean) => void
  deleteTariff: (id: number) => void
};

const DeleteConfirmationTariffModal: React.FC<Props> = (props: PropsWithChildren<Props>) => {
  const { t } = useTranslation();

  return (
    <div className="delete-confirmation-modal-tariffs">
      <div className="modal-dialog">
        <div className="modal-container">
          <div className="modal-body">
            <div className="content">
              <div className="message">
                <p>{t('_delete_confirmation_message')}</p>
              </div>
              <div className="actions">
                <div className="no">
                  <DefaultButton text={t('_no')} onClick={() => props.setOpenModal(false)} />
                </div>
                <div className="yes">
                  <DefaultButton text={t('_yes')} onClick={props.deleteTariff} blue />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteConfirmationTariffModal;
