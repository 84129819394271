import React from 'react';
import './CatalogMessageHeader.scss';

type Props = {
  headerOne: string
  headerTwo: string
  headerThree: string
}

const CatalogMessageHeader: React.FC<Props> = (props) => {

  return (
    <div className="message-supplier-header-container">
      <div className="message-supplier">
        <div className="supplier">
          <p>{props.headerOne}</p>
        </div>
        <div className="message">
          <p>{props.headerTwo}</p>
        </div>
        <div className="created-time">
          <p>{props.headerThree}</p>
        </div>
      </div>

    </div>
  );
};

CatalogMessageHeader.displayName = 'CatalogMessageHeader';

export default CatalogMessageHeader;