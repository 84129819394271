import React, { useMemo } from 'react';
import { Column, useTable } from 'react-table';
import { useTranslation } from 'react-i18next';
import Spinner from 'components/spinner/Spinner';
import { IOilSearch } from 'interfaces/oil';
import './ReferenceTable.scss';

type Props = {
    data?
    columns
    loading?
}

const ReferenceTable: React.FC<Props> = (props) => {
  const {t} = useTranslation();
  const data = props.data.map(item => item.parts.filter(part => part.replace_status)).flat();
  const tableData: Column<IOilSearch>[] = props.columns;


  const columns = useMemo(
    (): Column<IOilSearch>[] => tableData,
    [],
  );
    
    
  const { getTableProps, getTableBodyProps, rows, headerGroups, prepareRow } = useTable({
    columns,
    data
  });


  return (
    <div className="table-wrapper reference-table-wrapper">
      <table {...getTableProps()}>
        {data.length ? 
          <thead className="headings">
            {headerGroups.map((headerGroup, index) => {
              return (
                <tr key={headerGroup.headers[index].id} className="row">
                  {headerGroup.headers.map((column) => {
                    const { key, ...restColumn } = column.getHeaderProps();
    
                    return (
                      <th key={key} {...restColumn} className="heading">
                        {column.render('Header')}
                      </th>
                    );
                  })}
                </tr>
              );
            })}
          </thead>
          : null}
        {
          props.loading ?
            <tbody className="body">
              <tr className="no-data">
                <td colSpan={8}>
                  <Spinner class="extra-small" />
                </td>
              </tr>
            </tbody> :
            data.length ?
              <tbody {...getTableBodyProps} className="body">
                {rows.map((row) => {
                  prepareRow(row);
                  const { key, ...restRowProps } = row.getRowProps();
    
                  return (
                    <tr key={key} {...restRowProps} className="row">
                      {row.cells.map((cell) => {
                        const { key, ...restCellProps } = cell.getCellProps();

                        return (
                          <td
                            style={!('image' in cell.row.values) ? {padding: '1.65rem 0.4rem 1.75rem 1.4rem'} : {}}
                            key={key}
                            {...restCellProps}
                            className="cell"
                          >
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
              :
              <tbody className="body">
                <tr className="no-data">
                  <td colSpan={8}>{t('_no_data')}</td>
                </tr>
              </tbody>
        }
      </table>
    </div>
    
  );
};

export default ReferenceTable;