import React from 'react';
import EspaceClientView from './EspaceClientView.view';

type Props = {};

const EspaceClient: React.FC<Props> = () => {
  return (
    <div className="container">
      <EspaceClientView />
    </div>
  );
};

EspaceClient.displayName = 'EspaceClient';

export default EspaceClient;
