import React, { useEffect, useState } from 'react';
import { IData, IDataResponse, IStatus } from 'models/order';
import { getURLParams } from 'utils/marketplace';
import { useForm } from 'react-hook-form';
import useHttp from 'utils/hooks/useHttp';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import './RetailerOrders.scss';
import { useTranslation } from 'react-i18next';
import RetailerOrderTable from '../../components/RetailerOrderTable/RetailerOrderTable';

import RetailrOrdersFilter from './RetailerOrderFilter/RetailrOrdersFilter';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';

type Props = {
  catalogId?: string;
};
interface IURLParams {
  page?: number;
}
const RetailerOrders: React.FC<Props> = (props) => {
  const { getValues, register, handleSubmit, reset } = useForm();
  const http = useHttp();
  const urlParams: IURLParams = getURLParams(location.search);
  const [data, setData] = useState<IData[]>([]);
  const [paginationData, setPaginationData] = useState<IDataResponse>();
  const [status, setStatus] = useState<IStatus>();
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [startDate, setStartDate] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const { isMobile } = useDeviceDetect();
  const { t, i18n } = useTranslation();

  function getOrderList(params) {
    setLoading(true);
    http.order
      .getOrders(params)
      .then((res) => {
        const { data, page, total_pages } = res.data as IDataResponse;

        setData(data);
        setPaginationData({ data, page, total_pages });
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function getStatus() {
    http.order
      .getOrdersStatus()
      .then((res) => setStatus(res.data));

  }


  useEffect(() => {
    getStatus();
    getOrderList({ page: urlParams.page ?? 1, catalog_id: props.catalogId });

  }, [i18n.language]);

  useEffect(() => {
    if (paginationData) {
      setCurrentPage(paginationData.page);
      setTotalPage(paginationData.total_pages);
    }
  }, [paginationData]);

  const onSubmit = async () => {

    if (isMobile) {
      setIsActive(false);
    }

    setLoading(true);
    const data = getValues();

    if (data.status) {
      data.status = data.status.label;
    }

    const start_date = moment(startDate).format('YYYY-MM-DD');


    if (moment(start_date).isValid()) {
      data.date = moment(startDate).format('YYYY-MM-DD');
    }


    const validParams = Object.entries(data).reduce((acc, [key, value]) => {
      if (!value) {
        return acc;
      }

      acc[key] = value;

      return acc;
    }, {});

    await getOrderList(validParams);
  };

  const handlePageClick = async (data: number) => {
    setCurrentPage(data);
    setLoading(true);
    const selectPage = data;
    const params = getValues();

    if (params.status) {
      params.status = params.status.label;
    }

    await getOrderList({ ...params, page: selectPage });
  };

  const handleClick = () => {
    setIsActive(true);
  };

  return (
    <div className="orders-page-client-retailer">
      <div className="orders-page-title">
        {t('_orders')}
      </div>
      <RetailrOrdersFilter
        register={register}
        onSubmit={onSubmit}
        handleSubmit={handleSubmit}
        handleClick={handleClick}
        isActive={isActive}
        setIsActive={setIsActive}
        reset={reset}
        setStartDate={setStartDate}
        startDate={startDate}
      />
      <div className="orders-table">
        <RetailerOrderTable data={data} handlePageClick={handlePageClick} currentPage={currentPage} totalPage={totalPage} loading={loading} />
      </div>
    </div>
  );
};

export default RetailerOrders;
