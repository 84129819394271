import React from 'react';
import {IMessageSupplier} from '../../../interfaces/messaging';
import './CatalogMessageItem.scss';
import ReactTimeAgo from 'react-time-ago';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {RootState} from '../../../store/store';

type Props = {
  messageItem: IMessageSupplier
}

const generateDate = (date: Date): Date => {
  const timeZoneOffset = date.getTimezoneOffset();

  date.setMinutes(date.getMinutes() - timeZoneOffset);

  return date;
};

const CatalogMessageItem: React.FC<Props> = (props) => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  const handleMessageClick = () => {
    navigate(`/espace-clients/messages/${props.messageItem.catalog_id}`);
  };

  const {user} = useSelector((state: RootState) => state.userData);


  const isSeen= (): boolean  => {
    if(props.messageItem.message_seen) {
      return true;
    }

    return  props.messageItem.message_user_id === user?.id;
  };

  return (
    <div className="message-supplier-container">
      <div onClick={handleMessageClick} className={`message-supplier ${isSeen() ? '' : 'unseen'}`}>
        <div className="supplier">
          <p>{props.messageItem.catalog_name}</p>
        </div>
        <div className="message">
          <p>{props.messageItem.message}</p>
        </div>
        <div className="created-time">
          <ReactTimeAgo timeStyle="round-minute" date={generateDate(new Date(props.messageItem.created_at))} locale={i18n.language}/>
          <span></span>
        </div>
      </div>

    </div>
  );
};

CatalogMessageItem.displayName = 'CatalogMessageItem';

export default CatalogMessageItem;