import React from 'react';
import {useTranslation} from 'react-i18next';
import Spinner from 'components/spinner/Spinner';
import CarPartCard from '../car-part-card/CarPartCard';
import './CarPartsStatic.scss';

type Props = {
  isLoading?: boolean;
  data?
  type?: string
  handleClick: (item)=> void
};

const CarPartsStatic: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  return (
    <div className="car-parts-static">
      {!props.isLoading ? 
        props.data?.length ?
          props.data?.map((card, index: number) => {
            return (
              <div className="car-parts-static-item" key={index}>
                <CarPartCard name={card.data_supplier_reference_original} image={card.image} handleClick={()=> props.handleClick(card)}/>
              </div>
            );
          })
          : <div className="no-data">{t('_no_data')}</div>
        : <Spinner class="small car-parts-static-spinner"></Spinner>}
    </div>
  );
};


export default CarPartsStatic;
