import { faCircleArrowLeft, faClose, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ButtonLink from 'components/buttons/ButtonLink/ButtonLink';
import List from 'components/List/List';
import { ICatalogInfo } from 'interfaces/selectClient';
import { IGetCarPartDataParams } from 'models/car-parts.model';
import { IItemClick } from 'models/catalog.model';
import React, { forwardRef, LegacyRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ICategorieGroup } from '../Navbar.model';

import './CategoriesSidebar.scss';

type Props = {
  showSidebar?: boolean;
  showDetails?: boolean;
  activeCategory: ICategorieGroup | null;
  categories: ICategorieGroup[];
  onCloseButtonClick?: () => void;
  onSidebarSubCategoryClick: (params: Omit<IGetCarPartDataParams, 'catalog_id' | 'page' | 'per_page'>) => void;
  onItemClick?: (item: IItemClick) => void;
  onReturnButtonClick?: () => void;
  currentCatalog?: ICatalogInfo | null;
};

const CategoriesSidebarView = forwardRef((props: Props, ref: LegacyRef<HTMLDivElement>): JSX.Element | null => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      {props.showDetails && (
        <div ref={ref} className="detailsWrapper">
          <div className="header">
            {props.onReturnButtonClick != null && (
              <span className="returnButton" onClick={props.onReturnButtonClick}>
                <FontAwesomeIcon icon={faCircleArrowLeft} className="icon" />
                {t('_return')}
              </span>
            )}
            <ButtonLink image={props.activeCategory?.image} className="buttonLink" text={props.activeCategory?.name || ''} showPdf={false}></ButtonLink>
            <FontAwesomeIcon icon={faXmark} onClick={props.onCloseButtonClick} className="icon" />
          </div>
          <div className="content">
            <div className="items">
              {props.activeCategory?.subcategories.map((items, key) => {
                return (
                  <div key={key + items.name} className="item">
                    <div className="title">{items.name}</div>
                    {items.subcategories.map((item, key) => {
                      return (
                        <div
                          onClick={() =>
                            props.onSidebarSubCategoryClick({
                              code_groupe: item.code_groupe,
                              code_repere: item.code_repere,
                              code_ssgroupe: item.code_ssgroupe,
                            })
                          }
                          className="item"
                          key={key + item.name}
                        >
                          {item.name}
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}

      {props.showSidebar && (
        <div ref={ref} className="sidebarWrapper">
          <div className="closeSidebarWrapper"> <FontAwesomeIcon icon={faClose} onClick={props.onCloseButtonClick} /></div>
          <List
            content={props.categories.map((item: ICategorieGroup) => item.name)}
            images={props.categories.map((item: ICategorieGroup) => item.image)}
            onItemClick={props.onItemClick}
            className="title"
            itemClassName="listItem"
            title={<Trans i18nKey="_catalog_detached_pieces" values={{ catalogName: props.currentCatalog?.name || '' }} />}
          ></List>

        </div>
      )}
    </React.Fragment >
  );

});

CategoriesSidebarView.displayName = 'CategoriesSidebarView';

export default CategoriesSidebarView;
