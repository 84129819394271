
import TextInput from 'components/inputs/TextInput/TextInput';
import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useHttp from 'utils/hooks/useHttp';
import './EditPromotions.scss';
import Spinner from 'components/spinner/Spinner';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faClose } from '@fortawesome/free-solid-svg-icons';
import UploadImage from 'components/UploadImage/UploadImage';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { languages } from 'utils/constants/locales';
import { ICatalogInfoTranslate } from 'models/catalog.model';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import DatePicker from 'react-datepicker';
import { useOnClickOutside } from 'utils/hooks/useOnClickOutside';
import UploadPdf from 'components/UploadPdf/UploadPdf';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import { getMarketplaceBaseUrl, notify } from 'utils/marketplace';
import { IPromotionDetails } from 'utils/hooks/http/promotions.http';
import { useLocaleDatePicker } from 'utils/localeDatePicker';
import i18n from 'i18n';

type Props = {
  setOpenModal: (e: boolean) => void;
  setSubmit: (e: boolean) => void;
  editId: number | undefined;
};


const EditPromotions: React.FC<Props> = (props) => {


  const { t } = useTranslation();
  const http = useHttp();

  useLocaleDatePicker(i18n.language);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingInfo, setLoadingInfo] = useState<boolean>(false);
  const [tabColor, setTabColor] = useState<string[]>([]);
  const { isMobile, isTablet } = useDeviceDetect();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const dateRef = useRef() as MutableRefObject<HTMLDivElement>;
  const [openStartDate, setOpenStartDate] = useState(false);
  const [openEndDate, setOpenEndDate] = useState(false);
  const [errorTitle, setErrorTitle] = useState(false);
  const [valueStartDate, setValueStartDate] = useState('');
  const [valueEndDate, setValueEndDate] = useState('');
  const [curentDate] = useState(new Date());
  let componentMounted = true;
  const [info, setInfo] = useState<IPromotionDetails>();

  useOnClickOutside(dateRef, () => { setOpenStartDate(false); setOpenEndDate(false); });
  const validate = Yup.object().shape({

    // start_date: Yup.string().required(t('_fild_required')),
    // end_date: Yup.string().required(t('_fild_required'))

  });

  const { getValues, register, handleSubmit, clearErrors, setValue, formState: { errors }, } = useForm({ resolver: yupResolver(validate) });

  useEffect(() => {
    if (componentMounted) {
      setLoadingInfo(true);
      http.promotions.infoPromotion(props.editId!).then((res) => {
        setInfo(res.data);
        const lang: string[] = [];

        res.data?.translations.map((trans) => {
          if (trans.locale && trans.title && trans.title != '') {
            lang.push(trans.locale);
            setValue(`title[${trans.locale}]`, trans.title);
            setValue(`description[${trans.locale}]`, trans.description);
          }

        });
        setTabColor([...tabColor, ...lang]);

        if(res.data.start_date){
          setValueStartDate(moment(res.data.start_date).format('DD/MM/YYYY'));
          setValue('start_date', `${moment(res.data.start_date).format('YYYY-MM-DD')}`);
        }

        if(res.data.end_date){
          setValueEndDate(`${moment(res.data.end_date).format('DD/MM/YYYY')}`);
          setValue('end_date', `${moment(res.data.end_date).format('YYYY-MM-DD')}`);
        }

      }).finally(() => {
        setLoadingInfo(false);
      });
    }


    return () => {
      componentMounted = false;
    };
  }, []);


  const onError = () => {

    const values = getValues();

    Object.keys(values.title).forEach(key => {
      if (values.title[key] == undefined || values.title[key] == '') {
        delete values.title[key];
      }
    });

    if (Object.keys(values.title).length == 0) {
      setErrorTitle(true);


    } else {
      setErrorTitle(false);
    }
  };

  const onSubmit = async () => {
    const values = getValues();
    const formData: FormData = new FormData();

    Object.keys(values.title).forEach(key => {
      if (values.title[key] == undefined) {
        delete values.title[key];
      } else {
        formData.append(`translations[${key}][title]`, String(values.title[key]));

        if (!(values.description[key] == undefined)) {
          formData.append(`translations[${key}][description]`, String(values.description[key]));
        }

      }

    });
    Object.keys(values.title).forEach(key => {
      if (values.title[key] == undefined || values.title[key] == '') {
        delete values.title[key];
      }
    });

    if (Object.keys(values.title).length == 0) {
      setErrorTitle(true);

      return;
    }

    setLoading(true);


    if (values.image) {
      formData.append('image', values.image);
    }

    if (values.pdf) {
      formData.append('pdf', values.pdf);
    }


    formData.append('start_date', values.start_date);
    formData.append('end_date', values.end_date);
    formData.append('enabled', values.enabled);

    http.promotions.editPromotion(formData, props.editId!).then(() => {
      props.setSubmit(true);
      notify(t('_updated_successfully'), 'success', t('_success'));
      props.setOpenModal(false);

    }).catch((error) => {
      let erorrs = '';

      Object.entries(error).forEach(([key, value]) => {
        erorrs += `${t(key)} : ${value} `;
      });
      notify(erorrs, 'error', t('_error'));
      setLoading(false);
    });

  };

  const changeTitle = (key: string, value: string) => {
    if (value != '') {
      if (!tabColor.includes(String(key))) {
        const lang: string[] = [key];

        setTabColor([...tabColor, ...lang]);
      }

      setErrorTitle(false);
    } else {
      if (tabColor.includes(String(key))) {
        tabColor.splice(tabColor.indexOf(key), 1);
        setTabColor([...tabColor]);
      }
    }
  };


  function onDateChangeStart(date) {

    setValueStartDate(`${moment(date).format('DD/MM/YYYY')}`);
    setValue('start_date', `${moment(date).format('YYYY-MM-DD')}`);
    setStartDate(date);


    if (moment(date, 'DD-MM-YYYY') > moment(valueEndDate, 'DD-MM-YYYY')) {
      setValue('end_date', '');
      setEndDate(null);
      setValueEndDate('');
    }

  }

  function onDateChangeEnd(date) {
    setValueEndDate(`${moment(date).format('DD/MM/YYYY')}`);
    setValue('end_date', `${moment(date).format('YYYY-MM-DD')}`);
    setEndDate(date);
  }

  return (
    <div className="edit-promotion-container" >
      <div className="close-modal" onClick={() => { props.setOpenModal(false); }}><FontAwesomeIcon icon={faClose} /></div>
      <div className="title">
        {t('_edit_promotions')}
      </div>
      {
        loadingInfo && info == undefined ? <div className="spinner-info"> <Spinner /> </div> : <form onSubmit={handleSubmit(onSubmit, onError)}>
          <div className="modal-info">
            <div className="row">
              <div className={`container  file ${errors.image?.type == 'required' ? 'error' : ''}`}>
                <UploadImage errors={errors} clearErrors={clearErrors} setValue={setValue}
                  image={isMobile ? info?.images_path.mobile : info?.images_path.desktop}
                />
              </div>
              <div className={`container  pdf ${errors.image?.type == 'required' ? 'error' : ''}`}>
                <UploadPdf errors={errors} clearErrors={clearErrors} setValue={setValue} pdf={info?.pdf_path} />
              </div>
            </div>
            <div className="row">
              <div className={`container ${errors.start_date?.type == 'required' ? 'error' : ''}`}>
                <label  >
                  {t('_promotions_start_date')}
                </label>
                <div className="date-input-item">
                  <div className="orders-date-picker" onClick={() => setOpenStartDate(true)}>
                    <TextInput placeholder={t('_select_date')} readOnly value={valueStartDate} />
                    <p className="error-message">{errors.start_date?.type == 'required' ? t('_fild_required') : ''}</p>
                  </div>
                  {openStartDate && (
                    <div className="date-picker" ref={dateRef}>
                      <DatePicker
                        {...register('start_date')}
                        onChange={onDateChangeStart}
                        inline
                        locale={i18n.language.split('-')[0]}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className={`container ${errors.end_date?.type == 'required' ? 'error' : ''}`}>
                <label  >
                  {t('_promotions_end_date')}
                </label>
                <div className="date-input-item">
                  <div className="promotions-date-picker" onClick={() => setOpenEndDate(true)}>
                    <TextInput placeholder={t('_select_date')} value={valueEndDate} readOnly />
                    <p className="error-message">{errors.end_date?.type == 'required' ? t('_fild_required') : ''}</p>
                  </div>
                  {openEndDate && (
                    <div className="date-picker" ref={dateRef}>
                      <DatePicker
                        {...register('end_date')}
                        onChange={onDateChangeEnd}
                        inline
                        minDate={startDate}
                        locale={i18n.language.split('-')[0]}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="container">

                <div className="promotions-active-btn">
                  <div className="radio">
                    <input className="check" type="radio" defaultChecked={info?.enabled == true ? true : false} value="true" {...register('enabled')} />
                    <label htmlFor="is-active-true">{t('_active')}</label>
                    <FontAwesomeIcon icon={faCheck} className="check-icon" />
                  </div>
                  <div className="radio">
                    <input className="check" type="radio" defaultChecked={info?.enabled == false ? true : false} value="false" {...register('enabled')} />
                    <label htmlFor="is-active-false">{t('_inactive')}</label>
                    <FontAwesomeIcon icon={faCheck} className="check-icon" />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className={`container ${errorTitle ? 'error' : ''}`}>
                {
                  errorTitle ? <p className="error-message"> {t('_fild_required_title')} </p> : ''
                }
                <div className="tabs-container">
                  <Tabs>
                    <TabList >
                      {
                        languages.map((item, index) => {
                          let classActive = 'empty_text';

                          tabColor.map((trans) => {
                            if (trans == item.id) {
                              classActive = 'active_text';
                            }
                          });

                          return <Tab key={index} className={classActive} >{isMobile || isTablet ? String(item.id).toUpperCase() : item.text}</Tab>;
                        })
                      }
                    </TabList>
                    {languages.map((item, index) => {
                      return (<TabPanel key={index} >
                        <label  >
                          {t('_promotions_title')}
                        </label>
                        <textarea {...register(`title[${item.id}]`)} onChange={(event) => { changeTitle(String(item.id), event?.target.value); }} rows={2} />
                        <label  >
                          {t('_description')}
                        </label>
                        <textarea {...register(`description[${item.id}]`)} rows={4} />

                      </TabPanel>
                      );
                    })
                    }
                  </Tabs>
                </div>
              </div>
            </div>

          </div>
          <div className="btn-save">
            <button type="submit"> {loading ? <Spinner class="extra-small" /> : null} <div className="title-btn-save">{t('_update_article')}</div></button>
          </div>
        </form >
      }


    </div>

  );

};

EditPromotions.displayName = 'EditPromotions';

export default EditPromotions; 