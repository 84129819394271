
import axios from 'axios';
import ModalDelete from 'components/CatalogNaf/ModalDelete/ModalDelete';
import Modal from 'components/modal/Modal';
import Pagination from 'components/pagination/Pagination';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import useHttp from 'utils/hooks/useHttp';
import './BatteryCategory.scss';
import { ICategory } from 'interfaces/batteryCategory';
import CategoryCreate from './ModalCreate/CategoryCreate';
import CategoryEdit from './ModalEdit/CategoryEdit';
import BatteryCategoryFilter from 'components/filter/BatteryCategoryFilter/BatteryCategoryFilter';
import ItemEshopBatteryCategory from 'components/CatalogNaf/itemEshopBatteryCategory/ItemEshopBatteryCategory';

const BatteryCategory: React.FC = () => {

  const http = useHttp();
  const [searchParams] = useSearchParams();
  const [data, setData] = useState<ICategory[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [forcePage] = useState<number>(searchParams.get('page') ? Number(searchParams.get('page')) : 1);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openModalCreate, setOpenModalCreate] = useState<boolean>(false);
  const [submit, setSubmit] = useState<boolean>(false);
  const [brandId, setBrandId] = useState<number>();
  const [openFilter, setOpenFilter] = useState(false);
  const cancelToken = axios.CancelToken.source();
  const [openModalDelete, setOpenModalDelete] = useState<boolean>(false);
  let isMounted = true;
  const { i18n } = useTranslation();

  const { t } = useTranslation();

  function feach(page) {
    setLoading(true);

    if (Array.from(searchParams).length) {

      let param = {};

      searchParams.forEach((value, key) => {

        param = { ...param, [key]: String(value) };

      });

      http.batteryCategoryHttp.getList({ ...{ page: page }, ...param }, cancelToken.token).then((resp) => {
        setData(resp.data.data);
        setTotalPage(resp.data.total_pages);
        setLoading(false);
      }).catch(() => {
        console.log('cancelToken.token!!');
        setLoading(false);
      });
    } else {
      http.batteryCategoryHttp.getList({ page: page }, cancelToken.token).then((resp) => {
        setData(resp.data.data);
        setTotalPage(resp.data.total_pages);
        setLoading(false);
      }).catch(() => {
        setLoading(false);
        console.log('cancelToken.token!!');
      });
    }
  }


  useEffect(() => {
    if (isMounted) {
      setLoading(true);
      feach(searchParams.get('page') ? Number(searchParams.get('page')) : 1);
      setSubmit(false);
    }

    return () => {
      isMounted = false;
    };
  }, [i18n.language, submit, searchParams.get('category_ids[]')]);


  const handlePageClick = (page) => {
    setLoading(true);
    feach(page);
  };


  const openModalBatteryCategory = (id: number) => {
    setOpenModal(true);
    setBrandId(id);

  };

  const deleteItem = (id: number) => {
    setOpenModalDelete(true);
    setBrandId(id);
  };


  return (
    <div className="battery-category-container" >
      <div className="body">
        <div className="battery-category-header">
          <div className="title">
            {t('_customer_catalog')}
          </div>
          <div className="btn-new">
            <button onClick={() => setOpenModalCreate(true)}>  {t('_create_oil')}</button>
          </div>
          <div className="filter">
            <div className="title-filter">
              {t('_filter_category')}
            </div>
            <div className="btn-filter">
              <button onClick={() => setOpenFilter(true)}>{t('_filter_result')}</button>
            </div>
          </div>

        </div>

        <hr className="hr" />
        <ItemEshopBatteryCategory data={data} loading={loading} openModalBatteryCategory={openModalBatteryCategory} deleteItem={deleteItem} />

        {totalPage > 1 && (
          <Pagination pageCount={totalPage} handlePageClick={handlePageClick} forcePage={forcePage} />
        )}
      </div>

      <BatteryCategoryFilter
        openFilter={openFilter}
        setOpenFilter={setOpenFilter}
        setData={setData}
        setTotalPage={setTotalPage}
        setLoading={setLoading}

      />

      <Modal
        openModal={openModal}
        setOpenModal={setOpenModal}
        childrenComponent={<CategoryEdit setOpenModal={setOpenModal} id={brandId} setSubmit={setSubmit} />}
      />

      <Modal
        openModal={openModalCreate}
        setOpenModal={setOpenModalCreate}
        childrenComponent={<CategoryCreate setOpenModalCreate={setOpenModalCreate} setSubmit={setSubmit} />}
      />

      <Modal
        openModal={openModalDelete}
        setOpenModal={setOpenModalDelete}
        childrenComponent={<ModalDelete setOpenModalDelete={setOpenModalDelete} setSubmit={setSubmit} id={brandId} link={http.batteryCategoryHttp.delete} />}
      />

    </div>

  );

};

BatteryCategory.displayName = 'BatteryCategory';

export default BatteryCategory; 