import { HttpResponse } from 'utils/hooks/http/history.http';
import { tecRMIAxios } from '../../http';
import { IRepairItemsWorkList, IVehicleRepairItemWork } from '../interface/repair-items.interface';

class RepairItemsHttp {

  private static WorkList = '/Times/WorkList';
  private static WorkSteps = '/Times/WorkSteps';

  public async fetchWorkListList(params: IVehicleRepairFetchBase): Promise<HttpResponse<IRepairItemsWorkList[]>> {
    return tecRMIAxios.get(RepairItemsHttp.WorkList, { params });
  }
    
  public async fetchWorkStepsList(params: {}): Promise<HttpResponse<IVehicleRepairItemWork[]>> {
    return tecRMIAxios.get(RepairItemsHttp.WorkSteps, { params });
  }
}

export default RepairItemsHttp;

export interface IVehicleRepairFetchBase {
  typeId: number | null;
  countryCode: string;
  languageCode?: string;
}