import React from 'react';
import './CatalogMessages.scss';


import Messages from '../../../components/messages/MessagesComponent';
import CatalogSupplierNavbar from '../../../components/navbar/catalog-supplier-navbar/CatalogSupplierNavbar';
import { useTranslation } from 'react-i18next';
import {MessagesContextProvider} from '../../../context/MessagesContext';

const CatalogMessages: React.FC= () => {
  const {t} = useTranslation();

  return (
    <div className="catalog-supplier-layout-container">
      <CatalogSupplierNavbar title={`${t('_messages')} /`}/>
      <MessagesContextProvider>
        <Messages/>
      </MessagesContextProvider>
    </div>
  );
};

CatalogMessages.displayName = 'CatalogMessages';

export type IMessageCreate = {
  message: string;
  files: File[];
};


export default CatalogMessages;