import { combineReducers, configureStore, EnhancedStore } from '@reduxjs/toolkit';
import useReducer from './reducers/useSlices';
import carSelectedReducer from './reducers/carSelectedSlices';
import catalogSlice from './slices/catalogs.slice';
import categoriesSlice from './slices/categories.slice';
import carPartsCategoriesSlice from './slices/car-parts-categories.slice';
import carPartsSlice from './slices/car-parts.slice';
import ebooksSlice from './slices/ebooks.slice';
import clientsSlice from './slices/clients.slice';
import timerSlice from './slices/timer.slice';
import messengerSlice from './slices/messenger.slice';
import cartSlice from './slices/cart.slice';
import importReferenceSlice from './reducers/importReferenceSlice';
import automotorTariffSlice from './slices/automotor-tariff.slice';
import proformaSlice from './slices/proforma.slice';
import tecrmiSlice from './slices/tecrmi.slice';
import pendingInvitationsSlice from './slices/pending-invitations.slice';
import partsListSlice from './slices/parts-list.slice';
import buttonSubmitProformaSlice from './slices/buttonSubmitProforma.slice';
import cartMecadepotSlice from './slices/cart-mecadepot.slice';
import buttonPriceCheckedStatusSlice from './slices/buttonPriceCheckedStatus.slice';
import cartEspaceAutomotorSlice from './slices/cartEspaceAutomotor.slice';
import proformaAutomotorSlice from './slices/proformaAutomotor.slice';

const rootReducer = combineReducers({
  userData: useReducer,
  carSelected: carSelectedReducer,
  catalogs: catalogSlice,
  categories: categoriesSlice,
  carPartsCategories: carPartsCategoriesSlice,
  partsList: partsListSlice,
  carParts: carPartsSlice,
  ebooks: ebooksSlice,
  clients: clientsSlice,
  timer: timerSlice,
  messenger: messengerSlice,
  cart: cartSlice,
  importReferenceData: importReferenceSlice,
  automotorTariff: automotorTariffSlice,
  proforma: proformaSlice,
  proformaAutomotor: proformaAutomotorSlice,
  tecrmi: tecrmiSlice,
  pendingInvitations: pendingInvitationsSlice,
  buttonSubmitProforma: buttonSubmitProformaSlice,
  cartMecadepotSlice: cartMecadepotSlice,
  buttonPriceCheckedStatus: buttonPriceCheckedStatusSlice,
  cartEspaceAutomotor: cartEspaceAutomotorSlice
});

export const setupStore = (): EnhancedStore => {
  return configureStore({
    reducer: rootReducer,
  });
};

export type RootState = ReturnType<typeof rootReducer>;

export type AppStore = ReturnType<typeof setupStore>;

export type AppDispatch = AppStore['dispatch'];
