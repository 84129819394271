
import TextInput from 'components/inputs/TextInput/TextInput';
import Select from 'react-select';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useHttp from 'utils/hooks/useHttp';
import './StockCreatePrice.scss';

import Spinner from 'components/spinner/Spinner';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { notify } from 'utils/marketplace';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';

import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import { IDataSuppliersValuePrice, IValidateStockPrice } from 'models/stockPrice';


type Props = {
  setOpenModalCreate: (e: boolean) => void;
  setSubmit: (e: boolean) => void;
};


const StockCreatePrice: React.FC<Props> = (props) => {

  let isMounted = true;
  const { t } = useTranslation();
  const http = useHttp();
  const { isMobile, isTablet } = useDeviceDetect();
  const [suppliers, setSuppliers] = useState<IDataSuppliersValuePrice[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingModal, setLoadingModal] = useState<boolean>(true);

  const validate = Yup.object().shape({
    reference: Yup.string().trim().required(t('_fild_required')).matches(/^[a-zA-Z0-9]+$/, 'No special characters'),
    priceGbp: Yup.number().required(t('_fild_required')).moreThan(0).transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value)).typeError(t('_must_number')),
    priceEur: Yup.number().required(t('_fild_required')).moreThan(0).transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value)).typeError(t('_must_number')),
    supplier: Yup.object().shape({
      label: Yup.string().required(t('_fild_required')),
      value: Yup.string().required(t('_fild_required')),
    }),

  });

  const { getValues, register, handleSubmit, control, formState: { errors }, } = useForm<IValidateStockPrice>({ resolver: yupResolver(validate) });

  useEffect(() => {

    if (isMounted) {
      setLoadingModal(true);

      http.stockHttp.getSuppliers()
        .then((res) => {

          const setSuppliersData = res.data.map((item) => {
            return { value: item.id, label: item.name };
          });

          setSuppliers(setSuppliersData);
          setLoadingModal(false);
        }).catch(() => {
          setLoadingModal(false);
        });
    }


    return () => {
      isMounted = false;
    };

  }, []);

  const customStyles = (hasError) => ({
    control: (styles) => ({
      ...styles,
      ...(hasError ? { borderColor: 'red' } : { border: 'none' }),
    }),
  });

  const onSubmit = async () => {
    setLoading(true);
    const data = getValues();


    const params = {
      priceGbp: data.priceGbp,
      priceEur: data.priceEur,
      reference: data.reference,
      supplier: data.supplier.value
    };


    http.stockPriceHttp.createStock(params).then(() => {
      notify(t('_stock_price_created'), 'success', t('_success'));
      props.setOpenModalCreate(false);
      props.setSubmit(true);
    }).catch(() => {
      notify('error', t('_error'));
      setLoading(false);
    });

  };


  return (
    <div className="stock-price-create-container" >
      <div className="close-modal" onClick={() => { props.setOpenModalCreate(false); }}><FontAwesomeIcon icon={faClose} /></div>

      <div className="title">
        {t('_create_stock_price')}

      </div>


      {
        loadingModal ?
          <div className="spinner-info"> <Spinner />  </div> :
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="stock-create-body">
              <div className="row">
                <div className="container ">
                  <label  >
                    {t('_stock_price_supplier')}
                  </label>
                  <Controller
                    control={control}
                    name="supplier"
                    render={({ field: { onChange, value } }) => (
                      <Select
                        value={value}
                        className="select-dropdown "
                        styles={customStyles(errors.supplier?.label?.type)}
                        onChange={(val) => {
                          onChange(val);

                        }}
                        options={suppliers}
                        placeholder={`${t('_select')}  ${t('_stock_price_supplier')}`}
                        isSearchable={!isMobile && !isTablet}
                      />
                    )}
                  />
                  <p className="error">{errors.supplier?.label?.type == 'required' ? errors.supplier?.label?.message : ''}</p>
                </div>

                <div className={`container ${errors.reference?.type == 'required' || errors.reference?.type == 'matches' ? 'error' : ''}`}>
                  <label  >
                    {t('_stock_price_reference')}
                  </label>
                  <TextInput {...register('reference')} />
                  <p className="error-message">{errors.reference?.type == 'required' || errors.reference?.type == 'matches' ? errors.reference?.message : ''}</p>

                </div>
                <div className={`container ${errors.priceEur?.type ? 'error' : ''}`}>
                  <label  >
                    {t('_stock_price_euro')}
                  </label>
                  <TextInput {...register('priceEur')} />
                  <p className="error-message">{errors.priceEur?.type ? errors.priceEur?.message : ''}</p>
                </div>
                <div className={`container ${errors.priceGbp?.type ? 'error' : ''}`}>
                  <label  >
                    {t('_stock_price_euro_gbp')}
                  </label>
                  <TextInput {...register('priceGbp')} />
                  <p className="error-message">{errors.priceGbp?.type ? errors.priceGbp?.message : ''}</p>
                </div>
              </div>

            </div>
            <div className="btn-save">
              <button type="submit"> {loading ? <Spinner class="extra-small" /> : null} <div className="title-btn-save">{t('_stock_price_create_article')}</div></button>
            </div>
          </form>
      }

    </div>

  );

};

StockCreatePrice.displayName = 'StockCreatePrice';

export default StockCreatePrice; 