import React, {useEffect} from 'react';
import './MessagesCatalog.scss';


import Messages from '../../../components/messages/MessagesComponent';
import MessagesNavbar from './messages-navbar/MessagesNavbar';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {useAppDispatch} from '../../../utils/hooks/redux';
import {useTranslation} from 'react-i18next';
import {fetchCatalogInfo} from '../../../store/reducers/catalog/catalogs.actions';
import {RootState} from '../../../store/store';
import Spinner from '../../../components/spinner/Spinner';
import {resetNewMessages} from '../../../store/slices/messenger.slice';
import useMessageText from '../../../utils/hooks/useMessageText';
import {MessagesContextProvider} from '../../../context/MessagesContext';
import MessagesSupplierComponent from '../../../components/messages/MessagesSupplierComponent';

const MessagesCatalog: React.FC= () => {
  const params = useParams<'id'>();
  const dispatch = useAppDispatch();
  const { currentCatalog, isLoadingInfo } = useSelector((state: RootState) => state.catalogs);
  const { i18n } = useTranslation();
  const messageText = useMessageText();

  // useEffect(() => {
  //   if (params.id && (!currentCatalog?.id || currentCatalog.id !== params.id)) {
  //     dispatch(resetNewMessages());
  //     dispatch(fetchCatalogInfo(params.id));
  //   }
  // }, [params.id, i18n.language, currentCatalog]);

  return (

    <div className="catalog-supplier-layout-container">
      <MessagesContextProvider>
        {
          isLoadingInfo ? (
            <Spinner />
          ) : (
            <React.Fragment>
              <MessagesNavbar title={`${messageText}`}/>
              <MessagesContextProvider>
                <MessagesSupplierComponent/>
              </MessagesContextProvider>
            </React.Fragment>
          )
        }

      </MessagesContextProvider>


    </div>
  );
};

MessagesCatalog.displayName = 'MessagesCatalog';

export type IMessageCreate = {
              message: string;
              files: File[];
            };


export default MessagesCatalog;