import React from 'react';
import './PriceCell.scss';
import CURRENCY from 'utils/constants/currency';

type Props = {
  value: number
  unspecified?: boolean
};

const PriceCellView: React.FC<Props> = (props: Props) => {
  const user = JSON.parse(localStorage.getItem('userData')!);

  return <div className="price-cell-wrapper">
    {
      props.unspecified ? (
        '-'
      ) : (
        <span>{CURRENCY[user!.currency]}{Number(props.value).toFixed(2)}  TTC</span>
      )
    }

  </div>;
};

PriceCellView.displayName = 'PriceCellView';

export default PriceCellView;
