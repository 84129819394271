import React from 'react';
import { faCar, faTruck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tabs from './Tabs/Tabs';
import Tab from './Tabs/Tab';
import CarSelector from './Contents/CarSelector';
import TruckSelector from './Contents/TruckSelector';
import './VehicleCard.scss';
import { useTranslation } from 'react-i18next';

type Props = {
  setOpenModal?: (e: boolean) => void;
  link?: string;
};

const VehicleCardView: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  return (
    <div className="vehicle-selector-search-card">
      <div className="card-title">{t('_you_know_vehicle')}</div>
      <Tabs>
        <Tab title={<FontAwesomeIcon icon={faCar} />}>
          <CarSelector setOpenModal={props.setOpenModal} link={props.link} />
        </Tab>
        <Tab title={<FontAwesomeIcon icon={faTruck} />}>
          <TruckSelector setOpenModal={props.setOpenModal} link={props.link} />
        </Tab>
      </Tabs>
    </div>
  );
};

VehicleCardView.displayName = 'VehicleCardView';

export default VehicleCardView;
