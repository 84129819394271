import React, { useState } from 'react';
import { ReactComponent as PartsNotFoundImage } from 'assets/images/parts-not-found.svg';
import Pagination from 'components/pagination/Pagination';
import './CatalogCarParts.scss';
import { useSelector } from 'react-redux';
import { getIsLoadingParts, getParts } from 'store/selectors/car-parts.selector';
import Spinner from 'components/spinner/Spinner';
import CatalogSupplierNavbar from 'components/navbar/catalog-supplier-navbar/CatalogSupplierNavbar';
import { useTranslation } from 'react-i18next';
import ProductsViewMode from '../../components/ProductsViewMode/ProductsViewMode';
import { DEFAULT_VIEW } from '../../components/ProductsViewMode/ProductsViewMode.constants';
import ProductsViewModeChanger from 'components/ProductsViewModeChanger/ProductsViewModeChanger';
import { CATALOG_CAR_PARTS } from 'components/ProductsViewModeChanger/products-view-mode-changer-utils';
import PartsFilterNew from 'components/filter/PartsFilterNew/PartsFilterNew';
import { useLocation, useSearchParams } from 'react-router-dom';
import { MARKETPLACE_AMERIGO } from 'utils/constants/defaultConstants';
import { getCurrentMarketplace } from 'utils/marketplace';


type Props = {
  onNextPageClick: () => void;
  onPrevPageClick: () => void;
  onPageClick: (page: number) => void;
  page: number;
  totalPages: number;
  logo?: string;
  title?: string;
  totalCount?: number;
  loading?: boolean;
  filterData?
};

const CatalogCarPartsView: React.FC<Props> = (props: Props) => {
  const [openFilter, setOpenFilter] = useState(false);
  const parts = useSelector(getParts);
  const isLoading = useSelector(getIsLoadingParts);
  const { t } = useTranslation();
  const user = JSON.parse(localStorage.getItem('userData')!);
  const [viewMode, setViewMode] = useState(user?.user_settings.products_view_mode || DEFAULT_VIEW);
  const [searchParamUrl] = useSearchParams();
  const marketplace = getCurrentMarketplace();
  const { pathname } = useLocation();

  function filterOptionsElement() {
    if (!props.filterData?.car_manufacturers.length && !props.filterData?.data_suppliers.length && pathname.includes('catalog') && marketplace == MARKETPLACE_AMERIGO) {
      return <div className="catalog-parts-filter"> </div>;
    } else {
      return (
        <div className="catalog-parts-filter">
          <button onClick={() => setOpenFilter(true)} className="catalog-parts-button">
            {t('_filter_results_btn')}
          </button>
        </div>
      );
    }
  }


  return (
    <div className="wrapper-catalog-parts">
      <div className="wrapper-content">
        <CatalogSupplierNavbar option={filterOptionsElement()} title={`${t('_parts')} / `} infoCar={searchParamUrl.get('car_info') == '1' ? true : false} />
        <div className="separator"></div>
        {!props.loading && (
          <div className="filterOptions">
            <div className="rezults">
              {parts && props.totalCount} {t('_results')}
            </div>
            <ProductsViewMode viewMode={viewMode} setViewMode={setViewMode} />
          </div>
        )}
        <div className="wrapperItems">

          {isLoading ? (
            <Spinner class="car-parts-spinner" />
          ) : (
            <React.Fragment>
              {parts.length ? (
                <ProductsViewModeChanger catalog={CATALOG_CAR_PARTS} viewMode={viewMode} />
              ) : (
                <div className="no-parts-data">
                  <div className="notFoundSvg">
                    <PartsNotFoundImage />
                  </div>
                  <h1>{t('_parts_not_found')}</h1>
                </div>
              )}
              { }
            </React.Fragment>
          )}
        </div>
        {props.totalPages > 0 && parts.length && !isLoading && props.totalPages > 1 ? (
          <Pagination pageCount={props.totalPages} handlePageClick={props.onPageClick} forcePage={props.page} />
        ) : (
          ''
        )}
        {/* <CarPartsFilter openFilter={openFilter} setOpenFilter={setOpenFilter} /> */}
        <PartsFilterNew filterData={props.filterData} openFilter={openFilter} setOpenFilter={setOpenFilter} />
      </div>
    </div>
  );
};

CatalogCarPartsView.displayName = 'CatalogCarPartsView';

export default CatalogCarPartsView;
