import React, { PropsWithChildren } from 'react';
import './ToastifyNotification.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

type Props = {
  icon?: IconProp;
  title?: string;
  message?: string;
};

const ToastifyNotificationView: React.FC<Props> = (props: PropsWithChildren<Props>) => {
  return (
    <div className="toast-notification">
      <div className="toast-icon">{props.icon ? <FontAwesomeIcon icon={props.icon} className="icon" /> : ''}</div>
      <div className="toast-text">
        {props.title ? <div className="title">{props.title}</div> : ''}
        <div className="message">{props.message}</div>
      </div>
    </div>
  );
};

ToastifyNotificationView.displayName = 'ToastifyNotificationView';

export default ToastifyNotificationView;
