import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactElement, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './MaintenanceMobileTab.scss';
import MaintenanceMobileTabTitle from './MaintenanceMobileTabTitle';

type Props = {
  children: ReactElement[];
  activeTabs: string[]
};

const MaintenanceMobileTabs: React.FC<Props> = (props) => {
  const {pathname, search} = useLocation();
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState(pathname);

  useEffect(()=> {
    setSelectedTab(pathname);
  }, [pathname]);

  function handlePrev(activeItem){
    const index = props.activeTabs.indexOf(activeItem);

    if(index > 0){
      navigate({pathname: props.activeTabs[index - 1], search: search.toString()});
    }
  }

  function handleNext(activeItem){
    const index = props.activeTabs.indexOf(activeItem);

    if(index < props.activeTabs.length - 1){
      navigate({pathname: props.activeTabs[index + 1], search: search.toString()});
    }
  }

  return (
    <div className="maintenance-tabs-container" >
      {props.children.map((item, index) => {
        if(!pathname?.includes(item.props.activeParam)) {
          return; 
        }
       
        return (
          <div key={index} className="mobile-maintenance-tab-container">
            {props.activeTabs![props.activeTabs!.indexOf(item.props.activeParam) - 1] && <div onClick={()=> handlePrev(item.props.activeParam)} className="maintenance-nav-btn"><FontAwesomeIcon icon={faChevronLeft} /></div>}
            <div className="maintenance-tabs">
              <div className="tabs">
                <MaintenanceMobileTabTitle disabled={item.props.disabled} onClick={item.props.onClick} icon={item.props.icon} title={item.props.title} index={item.props.activeParam} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
              </div>
            </div>
            {props.activeTabs![props.activeTabs!.indexOf(item.props.activeParam) + 1] && <div onClick={()=>handleNext(item.props.activeParam)} className="maintenance-nav-btn"><FontAwesomeIcon icon={faChevronRight} /></div>}
          </div>
        );
      })}
    </div>
  );
};

export default MaintenanceMobileTabs;
