import React from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import {ReactComponent as FolderIcon} from 'assets/images/folder.svg';
import './FavCategoryTabCard.scss';

type Props = {
  card?;
};

const FavCategoryTabCard: React.FC<Props> = (props: Props) => {
  const navigate = useNavigate();

  const generateLinkPart = (item) => {

    navigate({
      pathname: '/favorite',
      search: createSearchParams({
        category: item.id
      }).toString(),
    });
  };

  return (
    <div className="tabcard-fav-category tabcard-container" onClick={() => generateLinkPart(props.card)}>
      <div className="image-wrapper">
        <FolderIcon />
      </div>
      <div className="wrapper">
        {props.card?.name}
      </div>
    </div>
  );
};

export default FavCategoryTabCard;
