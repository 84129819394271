import React, { useEffect, useState } from 'react';
import { ReactComponent as PartsNotFoundImage } from 'assets/images/parts-not-found.svg';
import Pagination from 'components/pagination/Pagination';
import Spinner from 'components/spinner/Spinner';
import { useTranslation } from 'react-i18next';
import useHttp from 'utils/hooks/useHttp';
import { useSearchParams } from 'react-router-dom';
import { IBatteryCatalogFilter, IBodyResponseBattery } from 'interfaces/battery';
import CatalogNavbar from 'components/navbar/catalog-navbar/CatalogNavbar';
import ProductsViewMode from 'components/ProductsViewMode/ProductsViewMode';
import { DEFAULT_VIEW } from 'components/ProductsViewMode/ProductsViewMode.constants';
import ProductsViewModeChanger from 'components/ProductsViewModeChanger/ProductsViewModeChanger';
import { CATALOG_BATTERY } from 'components/ProductsViewModeChanger/products-view-mode-changer-utils';
import List from 'components/List/List';
import { ICategory } from 'views/Sidebar/Sidebar.model';
import CatalogBatteryFilter from '../CatalogBatteryFilter/CatalogBatteryFilter';
import axios from 'axios';
import './CatalogBatteriesMobile.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleArrowLeft } from '@fortawesome/free-solid-svg-icons';
import SortBy, { SortByParams } from '../../../../../components/SortBy/SortBy';
import { sortByOptions } from '../../../../../components/SortBy/SortBy.map';

const CatalogBatteriesMobile: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const http = useHttp();
  const [loading, setLoading] = useState(true);
  const [refData, setRefData] = useState<IBodyResponseBattery>();
  const [filterData, setFilterData] = useState<IBatteryCatalogFilter>();
  const [openFilter, setOpenFilter] = useState(false);
  const [page, setPage] = useState(Number(searchParams.get('page')));

  const [batteryCategories, setBatteryCategories] = useState<ICategory[]>([]);
  const [loadingCategories, setLoadingCategories] = useState(true);
  const [activeCategory, setActiveCategory] = useState<{ id: number }>({ id: searchParams.get('category_id') ? Number(searchParams.get('category_id')) : batteryCategories[0]?.id });
  const [isCategorySelected, setIsCategorySelected] = useState(false);

  const user = JSON.parse(localStorage.getItem('userData')!);
  const [viewMode, setViewMode] = useState(user?.user_settings.products_view_mode || DEFAULT_VIEW);

  function getBatteryCategories() {
    setLoadingCategories(true);
    http.catalogs.getCarBatteriesAllCategories().then((res) => {
      setBatteryCategories(res);
    }).finally(() => setLoadingCategories(false));
  }

  function getBatteriesData(data) {
    setLoading(true);

    const sortBySelectedOption = sortByOptions.filter((item) => String(item.value) == String(searchParams.get('sort')))[0] ?? [];

    const sortParams: SortByParams = {
      sort_by_column: String(searchParams.get('sort') ?? ''),
      sort_by_asc: String(searchParams.get('asc') ?? sortBySelectedOption?.default_sort_asc ?? ''),
    };

    const params = {
      ...data,
      ...sortParams,
      page,
    };

    const validParams = validateParams(params);

    http.batteriesHttp
      .getBatteries(validParams)
      .then((res) => {
        setRefData(res.data);
      })
      .finally(() => setLoading(false));
  }

  function getBatteriesFilter(params) {
    const filterParams = {
      ...params,
      page,
    };

    const validFilterParams = validateParams(filterParams);

    http.batteriesHttp.getBatteryFilters(validFilterParams).then((res) => {
      setFilterData(res.data);
    });
  }

  const validateParams = (data) => {
    return Object.entries(data).reduce((acc, [key, value]) => {
      if (!value) {
        return acc;
      }

      acc[key] = value;

      return acc;
    }, {});
  };

  const handlePageClick = (pageN: number) => {
    setPage(pageN);
  };

  const onCategoryClick = (category) => {
    setIsCategorySelected(true);
    setActiveCategory({ id: category.id });
    setSearchParams({ category_id: [category.id] });
    setPage(1);
  };

  useEffect(() => {
    const params = {
      category_ids: searchParams.getAll('category_id'),
      brand_ids: searchParams.getAll('brand_ids'),
      ah: searchParams.getAll('ah'),
      amp: searchParams.getAll('amp'),
      battery_id: searchParams.get('battery_id'),
      q: searchParams.get('q')
    };

    if (searchParams.get('category_id')) {
      getBatteriesData(params);
      getBatteriesFilter(params);
    }
  }, [
    page,
    searchParams.get('category_id'),
    searchParams.get('brand_ids'),
    searchParams.get('q'),
    searchParams.get('ah'),
    searchParams.get('amp'),
    searchParams.get('battery_id'),
    searchParams.get('sort'),
    searchParams.get('asc'),
    activeCategory.id
  ]);

  useEffect(() => {
    getBatteryCategories();
  }, []);

  useEffect(() => {
    if (searchParams.get('category_id')) {
      setActiveCategory({ id: Number(searchParams.get('category_id')) });
    }

    if (!searchParams.get('category_id')) {
      setActiveCategory({ id: Number(batteryCategories[0]?.id) });
    }

    if (!searchParams.get('category_id') && batteryCategories[0]?.id) {
      setSearchParams({ category_id: [String(batteryCategories[0]?.id)] });
    }
  }, [batteryCategories, searchParams.get('category_id')]);

  function filterOptionsElement() {
    return (
      <div className="catalog-parts-filter">
        <button onClick={() => setOpenFilter(true)} className={`catalog-parts-button ${openFilter ? 'active' : ''}`}>
          {t('_filter_results_btn')}
        </button>
      </div>
    );
  }

  const currentCategory = batteryCategories.find((category) => category.id === activeCategory.id);


  return (
    <div className="batteries-wrapper-mobile">
      <CatalogNavbar option={filterOptionsElement()} title="_battery_catalog" />
      <div className="separator"></div>

      <div className="filterOptions">
        {refData?.data?.length && refData?.data?.length > 0 ? <SortBy /> : null}
        <div className="results">
          {refData?.total_count} {t('_results')}
        </div>
        <ProductsViewMode viewMode={viewMode} setViewMode={setViewMode} />
      </div>

      {isCategorySelected ? (
        <div className="batteries-catalog-heading">
          <div className="batteries-category-return" onClick={() => setIsCategorySelected(false)}><FontAwesomeIcon icon={faCircleArrowLeft} />{t('_return')}</div>
          <div className="batteries-category-title">{currentCategory?.name}</div>
        </div>
      ) : null}

      <div className="retailer-catalog-batteries-container-mobile">
        {!isCategorySelected ? (
          <div className={`catalog-batteries-sidebar-mobile ${loadingCategories ? 'loading' : null}`}>
            {loadingCategories ? <Spinner class="small" /> : (
              batteryCategories && batteryCategories.length ? (
                <List
                  content={batteryCategories?.map((item) => {
                    return { ...item, id: item.id, name: item.name };
                  })}
                  images={batteryCategories?.map((item) => item.image)}
                  itemClassName="parts-list-catalog-item"
                  itemId="id"
                  onItemClick={onCategoryClick}
                  activeCategory={activeCategory}
                />
              ) : null
            )}
          </div>
        ) : (
          <div className="wrapperItems">
            {loading ? (
              <Spinner class="car-parts-spinner" />
            ) : refData?.data?.length ? (
              <ProductsViewModeChanger catalog={CATALOG_BATTERY} viewMode={viewMode} data={refData?.data} />
            ) : (
              <div className="no-parts-data">
                <div className="notFoundSvg">
                  <PartsNotFoundImage />
                </div>
                <h1>{t('_batteries_not_found')}</h1>
              </div>
            )}
          </div>
        )}
      </div>
      {(refData && refData?.total_pages && refData?.page && refData?.total_pages > 1) && isCategorySelected ? (
        <Pagination pageCount={refData.total_pages} handlePageClick={handlePageClick} forcePage={refData.page} />
      ) : (
        null
      )}
      <CatalogBatteryFilter getData={getBatteriesData} filterData={filterData} openFilter={openFilter} setOpenFilter={setOpenFilter} />
    </div>
  );
};

export default CatalogBatteriesMobile;
