import React, { useEffect, useState } from 'react';
import { createSearchParams, generatePath, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getCurrentCountryRMI, getCurrentLanguageRMI } from 'utils/rmi/rmi-utils/rmi-utils';
import i18n from 'i18n';
import useHttp from 'utils/hooks/useHttp';
import { RootState } from 'store/store';
import { useSelector } from 'react-redux';
import { IManuals } from 'utils/rmi/interface/manuals.interface';
import { faFile, faForward, faList } from '@fortawesome/free-solid-svg-icons';
import { ERROR_CODE_GROUP_ID } from 'utils/constants/defaultConstants';
import {
  IVehicleManual,
  IVehicleManualItemMps,
  IVehicleManualList,
  IVehicleManualSubGroups
} from 'interfaces/vehicle-manuals.interface';

import useReliableCatalogCarQueryParams from 'utils/hooks/useReliableCatalogCarQueryParams';
import ErrorCodesView from './ErrorCodes.view';
import ErrorCodesMobileView from './Mobile/ErrorCodesMobile.view';
import './ErrorCodes.scss';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import { ISearchLinks } from './Solutions/Solutions';
import { ROLE_CLIENT_PLATFORM } from 'utils/constants/roles';

const ErrorCodes: React.FC = () => {
  const { isMobile } = useDeviceDetect();
  const navigate = useNavigate();
  const http = useHttp();
  const { typeId } = useSelector((state: RootState) => state.tecrmi);
  const [loadingList, setLoadingList] = useState(false);
  const [searchParams] = useSearchParams();
  const { vehicle_id } = useParams<'vehicle_id'>();
  const [sidebarDetails, setSidebarDetails] = useState(Number(searchParams.get('level')) || 1);
  const reliableCatalogCarQueryParams = useReliableCatalogCarQueryParams();
  const { user } = useSelector((state: RootState) => state.userData);
  const { t } = useTranslation();
  const [itemMps, setItemMps] = useState<IVehicleManualItemMps>();
  const [manuals, setManuals] = useState<IVehicleManual[]>();
  const [activManual, setActivManual] = useState<IManuals>();
  const checkPermission = user?.roles.includes(ROLE_CLIENT_PLATFORM);

  const searchLinks: ISearchLinks = {
    code: generatePath('/reliable/rmi/:vehicle_id/technical-documentation/error-codes/manuals', { vehicle_id: String(vehicle_id) }),
    keyword: generatePath('/reliable/rmi/:vehicle_id/technical-documentation/error-codes/solutions', { vehicle_id: String(vehicle_id) })
  };

  const navLinks = [
    {
      icon: faFile,
      text: t('Docs technique'),
      disabled: user?.client_rmi_modules_type === 'none',
      link: generatePath('/reliable/rmi/:vehicle_id/technical-documentation/error-codes/manuals', { vehicle_id: String(vehicle_id) })
    },
    {
      icon: faForward,
      text: t('Solutions'),
      disabled: user?.client_settings.eina_subscription === false,
      link: generatePath('/reliable/rmi/:vehicle_id/technical-documentation/error-codes/solutions', { vehicle_id: String(vehicle_id) })
    },
    {
      icon: faList,
      text: t('Catalogues'),
      disabled: true,
      link: generatePath('/reliable/rmi/:vehicle_id/technical-documentation/error-codes/catalogs', { vehicle_id: String(vehicle_id) })
    },
  ];

  const getList = (): void => {
    if (typeId) {
      setLoadingList(true);

      http.manualsHttp.fetchRMIVehicleManualsList({
        typeId: typeId,
        countryCode: getCurrentCountryRMI(i18n.language),
        languageCode: getCurrentLanguageRMI(i18n.language)
      }).then(res => {
        const items: IVehicleManualItemMps[] = [];

        res.data.forEach((mainGroup: IVehicleManualList) => {
          mainGroup.SubGroups.forEach((subGroup: IVehicleManualSubGroups) => {
            if (subGroup.SubGroupId == ERROR_CODE_GROUP_ID) {
              subGroup.ItemMps.forEach((item: IVehicleManualItemMps) => {
                items.push(item);
              });
            }
          });
        });

        if (items.length > 0) {
          setItemMps(items[0]);
          setManuals(items[0]?.Manuals);
        }

      }).finally(() => {
        setLoadingList(false);
      });
    }
  };

  useEffect(() => {
    getList();
  }, [typeId, i18n.language]);

  function onManualClick(manual) {


    let paramsObject: { [key: string]: string } = {};

    paramsObject = { ...paramsObject, ...reliableCatalogCarQueryParams };
    paramsObject['manual_id'] = String(manual.ManualId);

    if (checkPermission) {
      http.statistics.saveStatistics({ ...paramsObject, ...{ module_name: 'Error Codes' } });
    }

    setActivManual(manual);
    navigate({
      pathname: searchLinks.code,
      search: `?${createSearchParams(paramsObject)}`,
    });
  }

  function onReturnClick() {
    if (sidebarDetails === 1) {
      const paramsObject = { ...reliableCatalogCarQueryParams };

      navigate({
        pathname: generatePath('/reliable/rmi/:vehicle_id/technical-documentation', {
          vehicle_id: String(vehicle_id),
        }),
        search: `?${createSearchParams(paramsObject)}`,
      });

      return;
    }

    navigate(-1);
    setSidebarDetails((prev) => prev - 1);
  }

  return (
    isMobile ?
      <ErrorCodesMobileView
        searchLinks={searchLinks}
        navLinks={navLinks}
        manuals={manuals}
        onManualClick={onManualClick}
      />
      :
      <ErrorCodesView
        loadingList={loadingList}
        sidebarDetails={sidebarDetails}
        manuals={manuals}
        onManualClick={onManualClick}
        onReturnClick={onReturnClick}
        activManual={activManual}
        searchLinks={searchLinks}
        navLinks={navLinks}
      />
  );
};

export default ErrorCodes;