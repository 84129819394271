import React, { useEffect, useState } from 'react';
import i18n from 'i18n';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import useHttp from 'utils/hooks/useHttp';
import { getCurrentCountryRMI, getCurrentLanguageRMI } from 'utils/rmi/rmi-utils/rmi-utils';
import './TechnicalManuals.scss';
import Spinner from 'components/spinner/Spinner';
import { generatePath, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import TechnicalManualsMobileView from './Mobile/TechnicalManualsMobile.view';
import TechnicalManualsView from './TechnicalManuals.view';
import { IManualListMainGroup, IManualListSubGroup, IManualsListItemMps } from 'utils/rmi/interface/manuals.interface';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import useReliableCatalogCarQueryParams from 'utils/hooks/useReliableCatalogCarQueryParams';
import { converObjectToParams } from 'utils/marketplace';

const TechnicalManuals: React.FC = () => {

  const { isMobile } = useDeviceDetect();
  const http = useHttp();
  const navigate = useNavigate();
  const { vehicle_id } = useParams();
  const { typeId } = useSelector((state: RootState) => state.tecrmi);
  const [searchParams, setSearchParams] = useSearchParams();

  const [sidebarDetails, setSidebarDetails] = useState(Number(searchParams.get('level')) || 1);
  const [loadingList, setLoadingList] = useState(false);

  const [manualList, setManualList] = useState<IManualListMainGroup[]>([]);
  const [mainGroup, setMainGroup] = useState<IManualListMainGroup>();
  const [subGroups, setSubGroups] = useState<IManualListSubGroup>();
  const [itemMpsManual, setItemMpsManual] = useState<IManualsListItemMps>();
  const [activeItem, setActiveItem] = useState<number | string | undefined>();

  // const [mainGroup, setMainGroup] = useState<IManualListMainGroup[]>();
  // const [subGroup, setSubGroup] = useState<IAdjustListMainGroup>();
  // const [itemMps, setItemMps] = useState<IAdjustListSubGroup>();
  // const [manuals, setManuals] = useState<IManualsListItemMps>();
  // const [activManual, setActivManual] = useState<IManuals>();

  const [showModal, setShowModal] = useState(false);
  const [loadingHTML, setLoadingHTML] = useState(false);

  const [iframeData, setIframeData] = useState<string>();
  const carParams = useReliableCatalogCarQueryParams();

  // function fetchRMIVehicleManualsList() {
  //   if (typeId) {
  //     setLoadingList(true);
  //     http.manualsHttp.fetchRMIVehicleManualsList({
  //       typeId: typeId,
  //       countryCode: getCurrentCountryRMI(i18n.language),
  //       languageCode: getCurrentLanguageRMI(i18n.language)
  //     }).then(res => {
  //       setMainGroup(res.data);
  //     }
  //     ).finally(() => {
  //       setLoadingList(false);
  //     });
  //   }
  // }

  const getList = (): void => {
    setLoadingList(true);
    http.manualsHttp
      .fetchRMIVehicleManualsList({
        typeId: typeId,
        countryCode: getCurrentCountryRMI(i18n.language),
        languageCode: getCurrentLanguageRMI(i18n.language)
      })
      .then(({ data }) => {
        // data.forEach((groups) => {
        //   groups.SubGroups.forEach((subgroups) => {
        //     subgroups.ItemMps.forEach((itemps) => {
        //       itemps.Manuals.forEach((element, index) => {
        //         element.ManualId = itemps.ItemMpId + index;
        //       });
        //     });
        //   });
        // });


        setManualList(data);
      })
      .finally(() => {
        setLoadingList(false);
      });
  };

  useEffect(() => {
    getList();
  }, [typeId, i18n.language]);

  function onMainGroupClick(item) {
    setMainGroup(item);
    setSidebarDetails(2);
    searchParams.set('main_group_id', item.MainGroupId);
    searchParams.set('level', '2');
    setSearchParams(searchParams);
  }

  function onSubGroupClick(item) {
    setSubGroups(item);
    setSidebarDetails(3);
    searchParams.set('sub_group_id', item.SubGroupId);
    searchParams.set('level', '3');
    setSearchParams(searchParams);
  }

  function onItemMpClick(item) {
    setItemMpsManual(item);
    setSidebarDetails(4);
    searchParams.set('level', '4');
    searchParams.set('item_id', item.ItemMpId);
    setSearchParams(searchParams);
  }

  function onItemMpManualClick(item) {
    setActiveItem(item.ManualId);
    searchParams.set('manual_id', item.ManualId);
    setSearchParams(searchParams);

    if (typeId && isMobile) {
      getHTML(item.ManualId);
    }
  }

  useEffect(() => {
    if (Number(searchParams.get('main_group_id')) && manualList) {
      setMainGroup(manualList.find((item) => item.MainGroupId === Number(searchParams.get('main_group_id'))));
    }

    if (Number(searchParams.get('sub_group_id')) && mainGroup) {
      setSubGroups(mainGroup?.SubGroups.find((item) => item.SubGroupId === Number(searchParams.get('sub_group_id'))));
    }

    if (Number(searchParams.get('item_id')) && subGroups) {
      const item = subGroups?.ItemMps.find((item) => item.ItemMpId === Number(searchParams.get('item_id')));

      setItemMpsManual(item);
    }

    if (searchParams.get('manual_id') && itemMpsManual) {
      const item = itemMpsManual?.Manuals.find((item) => item.ManualId === Number(searchParams.get('manual_id')));

      setActiveItem(item?.ManualId);
    }


    if (searchParams.get('manual_id') && !searchParams.get('sub_group_id') && !searchParams.get('main_group_id') && manualList) {
      let isManualFound = false;

      for (const groups of manualList) {

        for (const subgroups of groups.SubGroups) {
          for (const itemps of subgroups.ItemMps) {
            for (const manual of itemps.Manuals) {
              if (manual.ManualId == +searchParams.get('manual_id')!) {
                isManualFound = true;
                setSidebarDetails(4);

                break;
              }

              if (isManualFound) {
                setActiveItem(manual?.ManualId);

                break;
              }
            }

            if (isManualFound) {
              setItemMpsManual(itemps);

              break;
            }
          }

          if (isManualFound) {
            setSubGroups(subgroups);

            break;
          }
        }

        if (isManualFound) {
          setMainGroup(groups);

          break;
        }
      }
    }


    if (searchParams.get('item_id') && !searchParams.get('manual_id') && !searchParams.get('sub_group_id') && !searchParams.get('main_group_id') && manualList) {
      for (const groups of manualList) {
        for (const subgroups of groups.SubGroups) {
          for (const itemps of subgroups.ItemMps) {
            if (itemps.ItemMpId == +searchParams.get('item_id')!) {
              searchParams.set('manual_id', String(itemps.Manuals[0].ManualId));
              setSearchParams(searchParams, {replace: true});
            }
          }

        }
      }
    }
  }, [manualList, subGroups, mainGroup, itemMpsManual, searchParams.get('manual_id')]);


  useEffect(() => {
    const manualIdUrl = Number(searchParams.get('manual_id'));

    if (manualIdUrl && typeId) {
      getHTML(manualIdUrl);
    }


  }, [searchParams.get('manual_id'), typeId]);

  function onReturnClick() {
    if (sidebarDetails === 1) {
      navigate({
        pathname: generatePath('/reliable/rmi/:vehicle_id/technical-documentation', {
          vehicle_id: String(vehicle_id),
        }),
        search: converObjectToParams(carParams),
      });

      return;
    }

    //  navigate(-1);
    setSidebarDetails((prev) => prev - 1);
  }


  const getHTML = (manualId: number) => {
    setLoadingHTML(true);
    const param = {
      typeId,
      countryCode: getCurrentCountryRMI(i18n.language),
      languageCode: getCurrentLanguageRMI(i18n.language),
      printView: true,
      manualId: manualId ?? activeItem,
    };

    http.manualsHttp.fetchRMIVehicleManualHtml(param)
      .then(({ data }) => {
        setIframeData(data);

      })
      .catch((e: Error) => console.log(e))
      .finally(() => {
        if (isMobile) {
          setShowModal(true);
        }

        setLoadingHTML(false);
      });
  };

  return (

    <div className="technical-documentation-manuals">
      {loadingList ? (
        <Spinner class="extra-small" />
      ) : (
        isMobile ?
          <TechnicalManualsMobileView
            data={manualList}
            onMainGroupClick={onMainGroupClick}
            onSubGroupClick={onSubGroupClick}
            onItemMpClick={onItemMpClick}
            onItemMpManualClick={onItemMpManualClick}
            setOpenModal={setShowModal}
            activeItem={activeItem}
            showModal={showModal}
            loadingHTML={loadingHTML}
            iframeData={iframeData}
          />
          :
          <TechnicalManualsView
            loadingList={loadingList}
            sidebarDetails={sidebarDetails}
            loadingHTML={loadingHTML}
            iframeData={iframeData}
            onMainGroupClick={onMainGroupClick}
            onSubGroupClick={onSubGroupClick}
            onItemMpClick={onItemMpClick}
            onManualClick={onItemMpManualClick}
            mainGroup={manualList}
            subGroup={mainGroup}
            itemMps={subGroups}
            itemMpsManual={itemMpsManual}
            activeItem={activeItem}
            onReturnClick={onReturnClick}
          />
      )}
    </div>

  // <div className="technical-documentation-adjust">
  //   {loadingList ? <Spinner class="small" /> : (
  //     <React.Fragment>
  //       <div className="adjust-sidebar">
  //         {sidebarDetails === 1 && <SidebarDetails title={t('_technical_manuals')} itemName="MainGroupName" onClick={onMainGroupClick} data={mainGroup} onReturnClick={onReturnClick} />}
  //         {sidebarDetails === 2 && <SidebarDetails title={`${t('_technical_manuals')} / ${subGroup?.MainGroupName}`} itemName="SubGroupName" onClick={onSubGroupClick} data={subGroup?.SubGroups} onReturnClick={onReturnClick} />}
  //         {sidebarDetails === 3 && <SidebarDetails title={`${t('_technical_manuals')} / ${subGroup?.MainGroupName} / ${itemMps?.SubGroupName}`} itemId="ItemMpId" itemName="ItemMpText" onClick={onItemMpClick} data={itemMps?.ItemMps} onReturnClick={onReturnClick} />}
  //         {sidebarDetails === 4 && <SidebarDetails title={`${t('_technical_manuals')} / ${subGroup?.MainGroupName} / ${itemMps?.SubGroupName} / ${manuals?.ItemMpText}`} itemId="ManualId" itemName="QualColText" onClick={onManualClick} data={manuals?.Manuals} onReturnClick={onReturnClick} activeItem={activManual?.ManualId} />}
  //       </div>
  //       <div className="adjust-content">
  //         {iframeData &&
  //           <VehicleIframe
  //             loading={loadingHTML}
  //             vehicleIframe={iframeData!}
  //           />
  //         }
  //       </div>
  //     </React.Fragment>
  //   )}
  // </div>

  );
};

export default TechnicalManuals;