import { faCircleArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import MainGroup from './CollapseList/MainGroup';
import './ModulesMobileCard.scss';

type Props = {
  onMainGroupClick?: (item) => void;
  onSubGroupClick?: (item) => void;
  onItemMpClick?: (item) => void;
  data
  title: string
  setOpenModal?: (value: boolean) => void
  iframeInfo?: boolean;

};

const ModulesMobileCard: React.FC<Props> = (props: Props) => {
  const navigate = useNavigate();

  function onReturnClick() {
    navigate(-1);
  }

  return (
    <div className="modules-mobile-card">
      <div className="header">
        {/*<span className="return-button" onClick={onReturnClick}>*/}
        {/*  <FontAwesomeIcon icon={faCircleArrowLeft} className="icon" />*/}
        {/*</span>*/}
        <div className="title">{props.title}</div>
      </div>
      <div className="content">
        <div className="items">
          {props.data?.map((item, key) => {
            return (
              <MainGroup iframeInfo={props.iframeInfo} key={key} data={item} onMainGroupClick={props.onMainGroupClick} onSubGroupClick={props.onSubGroupClick} onItemMpClick={props.onItemMpClick} setOpenModal={props.setOpenModal} />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ModulesMobileCard;
