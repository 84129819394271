import { IStats } from 'applications/automotor/models/statistics';
import moment from 'moment';
import i18n from 'i18n';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useHttp from 'utils/hooks/useHttp';
import { notify } from 'utils/marketplace';
import StatisticsChart from './statistics-chart/StatisticsChart';
import StatisticsFilter from './statistics-filter/StatisticsFilter';
import StatisticsTable from './statistics-table/StatisticsTable';
import './Statistics.scss';

type Props = {}

const Statistics: React.FC<Props> = () => {
  const http = useHttp();
  const { t } = useTranslation();
  const { control, register, getValues, setValue, watch } = useForm();

  const currentDate = new Date();
  const pastDate = new Date(currentDate.getTime() - (7 * 24 * 60 * 60 * 1000));
  const [valueDate, setValueDate] = useState('');

  const [data, setData] = useState<IStats[]>([]);
  const [chartData, setChartData] = useState<IStats[]>([]);

  const [resetFlag, setResetFlag] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [chartLoading, setChartLoading] = useState<boolean>(false);
  const [exportLoading, setExportLoading] = useState<boolean>(false);
  const [exportFileLoading, setExportFileLoading] = useState<boolean>(false);


  function handleClick() {
    getStatistics();
    getChartData();
  }

  function handleReset() {
    setValue('module_name', []);
    setValue('client', []);
    setValueDate('');
    setValue('chart', '');
    setResetFlag(true);
  }


  function getStatistics() {
    const params = {
      modul_service_id: getValues()?.module_name?.map(module => module.value),
      client_id: getValues()?.client?.map(client => client.value),
      actual_delivery_time_start: valueDate.split(' ')[0],
      actual_delivery_time_end: valueDate.split(' ')[2]
    };

    setLoading(true);

    http.statistics.getStatistics(params).then((res) => {
      setData(res.data);
    }).finally(() => {
      setLoading(false);
    });
  }

  function getChartData() {
    const params = {
      modul_service_id: getValues()?.module_name?.map(module => module.value),
      client_id: getValues()?.client?.map(client => client.value),
      actual_delivery_time_start: valueDate.split(' ')[0],
      actual_delivery_time_end: valueDate.split(' ')[2]
    };

    setChartLoading(true);

    if (watch()?.chart?.value === 1) {
      http.statistics.getStatisticsByDate(params).then((res) => {
        setChartData(res.data);
      }).finally(() => {
        setChartLoading(false);
      }).catch((err) => {
        if (err.date_interval) {
          notify(t('_limit_14_days'));
        }
      });
    } else {
      http.statistics.getStatistics(params).then((res) => {
        setChartData(res.data);
      }).finally(() => {
        setChartLoading(false);
      });
    }
  }

  function exportStats() {
    const params = {
      modul_service_id: getValues()?.module_name?.map(module => module.value),
      client_id: getValues()?.client?.map(client => client.value),
      actual_delivery_time_start: valueDate.split(' ')[0],
      actual_delivery_time_end: valueDate.split(' ')[2]
    };

    setExportLoading(true);
    http.statistics.exportStatistics(params).then((res) => {
      const element = document.createElement('a');

      const file = new Blob([res.data], { type: res.data.type });

      element.href = URL.createObjectURL(file);
      element.download = 'statistics';
      element.click();
    }).finally(() => setExportLoading(false));
  }

  function exportFile() {
    const params = {
      modul_service_id: [2],
      // client_id: getValues()?.client?.map(client => client.value),
      actual_delivery_time_start: valueDate.split(' ')[0],
      actual_delivery_time_end: valueDate.split(' ')[2]
    };

    const validParams = Object.entries(params).reduce((acc, [key, value]) => {
      if (!value) {
        return acc;
      }

      acc[key] = value;

      return acc;
    }, {});

    setExportFileLoading(true);
    http.statistics.exportFile(validParams).then((res) => {
      const element = document.createElement('a');

      const file = new Blob([res.data], { type: res.data.type });

      element.href = URL.createObjectURL(file);
      element.download = 'Statistics_' + new Date().toJSON().slice(0, 10).replace(/-/g, '-');
      element.click();
    }).finally(() => setExportFileLoading(false)).catch((res) => {
      notify(t('_no_data'));

    });
  }

  useEffect(() => {
    if (resetFlag) {
      getStatistics();
      getChartData();
    }

    return () => {
      setResetFlag(false);
    };
  }, [resetFlag]);

  useEffect(() => {
    getStatistics();
    getChartData();
  }, [i18n.language]);

  useEffect(() => {
    if (watch()?.chart?.value === 1) {
      setValueDate(`${moment(pastDate).format('YYYY-MM-DD')} - ${moment(currentDate).format('YYYY-MM-DD')}`);
    }
  }, [watch()?.chart?.value]);

  return (
    <div className="statistics-page">
      <div className="statistics-title">
        {t('_statistics')}
      </div>
      <div className="statistics-filters">
        <StatisticsFilter
          control={control}
          register={register}
          getValues={getValues}
          handleClick={handleClick}
          handleReset={handleReset}
          setValueDate={setValueDate}
          valueDate={valueDate}
          exportStats={exportStats}
          loadingExport={exportLoading}
          exportFileLoading={exportFileLoading}
          exportFile={exportFile}
        />
      </div>
      <div className="statistics-data">
        <div className={`statistics-table ${loading ? 'loading' : ''}`}>
          <StatisticsTable data={data} loading={loading} />
        </div>
        <div className={`statistics-chart ${chartLoading ? 'loading' : ''}`}>
          <StatisticsChart data={chartData} loading={chartLoading} />
        </div>
      </div>
    </div>
  );
};

export default Statistics;
