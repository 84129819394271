import React, { useRef, useEffect, useState } from 'react';
import { faClose, faRedo, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import LinkButton from 'components/buttons/LinkButton/LinkButton';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { Trans, useTranslation } from 'react-i18next';
import './NewPartsFilter.scss';
import { useOnClickOutside } from 'utils/hooks/useOnClickOutside';
import { useSearchParams } from 'react-router-dom';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import { ICharacteristicsFilters } from 'models/car-parts.model';
import Spinner from 'components/spinner/Spinner';

type Props = {
  setOpenFilter?: (boolean) => void;
  openFilter?: boolean;
  filterData?;
  getData?;
  getFilters?: (data) => void;
  getOEInfo?: (data) => void;
  loading?: boolean;
};

const importantFiltersIds = ['0048', '0532', '0497', '0622'];

const PartsFilter: React.FC<Props> = (props) => {
  const { control, getValues, setValue, reset, register, watch } = useForm();
  const { t } = useTranslation();
  const filterRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const [searchParams, setSearchParams] = useSearchParams();
  const { isMobile, isTablet } = useDeviceDetect();
  const [characteristics, setCharacteristics] = useState<ICharacteristicsFilters[]>([]);
  const [showMore, setShowMore] = useState<boolean>(false);

  function handleClose() {
    // if all selects are empty, refetch data
    // Object.values(getValues()).every(item => {

    //   if(Array.isArray(item) && item.length === 0){
    //     handleClick();
    //   }
    // });

    props.setOpenFilter!(false);
  }

  const mapCharacteristics = (characteristics): ICharacteristicsFilters[] => {
    if (!characteristics) {
      return [];
    }

    return Object.keys(characteristics).map((key) => {
      return importantFiltersIds.reduce((previousValue, filterId) => {
        let important = false;

        if (characteristics[key][0]?.id.startsWith(filterId)) {
          important = true;
        }

        return {
          id: characteristics[key][0].id,
          label: key,
          options: characteristics[key],
          important: previousValue.important || important,
        };
      }, {} as ICharacteristicsFilters);
    });
  };

  useEffect(() => {
    const mapedCaracteristics = mapCharacteristics(props.filterData?.characteristics);

    const sortedCaracteristics = mapedCaracteristics.sort((a, b) => {
      if (a.important && !b.important) {
        return -1;
      }

      if (!a.important && b.important) {
        return 1;
      }

      return 0;
    });

    setCharacteristics(sortedCaracteristics);
  }, [props.filterData?.characteristics]);

  useEffect(() => {
    if (props.filterData) {
      if (Array.from(searchParams).length) {
        searchParams.forEach((value, key) => {
          if (key == 'dlnr[]') {
            const activeBrand = searchParams.getAll('dlnr[]').map((id) => {
              return getSupplierOptions().find((item) => item.value === id);
            });

            setValue('dlnr', activeBrand);
          }

          if (key == 'oe_reference[]') {
            const activeSeason = searchParams.getAll('oe_reference[]').map((id) => {
              return getOEReferenceOptions().find((item) => item.value === id);
            });

            setValue('oe_reference', activeSeason);
          }
        });
      }
    }
  }, [props.filterData, searchParams.get('dlnr[]'), searchParams.get('oe_reference')]);

  // useOnClickOutside(filterRef, (event) => {
  //   if (
  //     props.openFilter &&
  //     (event?.target as HTMLElement).tagName !== 'svg' &&
  //     (event?.target as HTMLElement).tagName !== 'parh' &&
  //     !(event?.target as HTMLElement).className.includes('indicatorContainer')
  //   ) {
  //     props.setOpenFilter?.(false);
  //   }
  // });

  const getSupplierOptions = () => {
    if (!props.filterData?.suppliers?.length) {
      return;
    }

    return (
      props.filterData?.suppliers?.map((item) => ({
        label: item.name,
        value: item.id,
      })) || []
    );
  };

  const getOEReferenceOptions = () => {
    if (!props.filterData?.oe_references?.length) {
      return;
    }

    return (
      props.filterData?.oe_references?.map((item) => ({
        label: item.name,
        value: item.id,
      })) || []
    );
  };

  const buildAttributesValue = () => {
    if (!props.filterData?.characteristics) {
      return [];
    }

    return Object.keys(props.filterData?.characteristics).reduce((attributesArray: string[], key) => {
      getValues()[key.replace('[', '').replace(']', '')] && getValues()[key.replace('[', '').replace(']', '')].length
        ? getValues()[key.replace('[', '').replace(']', '')].map((attr) => {
          return attributesArray.push(attr.value);
        })
        : [];

      return attributesArray;
    }, []);
  };

  const getAtributesOptions = (key) => {
    if (!props.filterData?.characteristics[key]?.length) {
      return;
    }

    const nonNullOptions = props.filterData?.characteristics[key]?.filter((option) => option.name !== '');

    return (
      nonNullOptions.map((item) => ({
        label: item.name,
        value: item.id,
      })) || []
    );
  };

  function handleClick() {
    const params = {
      dlnr: getValues().dlnr && getValues().dlnr.length ? getValues().dlnr.map(({ value }) => value) : [],
      oe_reference: getValues().oe_reference && getValues().oe_reference.length ? getValues().oe_reference.map(({ value }) => value) : [],
      characteristics: buildAttributesValue(),
      page: 1,
    };

    const validParams = Object.entries(params).reduce((acc, [key, value]) => {
      if (value === undefined || value === '' || value.length === 0) {
        searchParams.delete(key);

        return acc;
      }

      acc[key] = value;

      return acc;
    }, {});

    searchParams.set('page', '1');
    setSearchParams(searchParams);
    props.getData(validParams);
    props.getOEInfo!(validParams);
    props.setOpenFilter!(false);
  }

  function handleReset() {
    reset({ dlnr: [], oe_reference: [] });
    props.getData({});
    props.getOEInfo!({});
    props.setOpenFilter!(false);
    searchParams.delete('dlnr[]');
    searchParams.delete('oe_reference[]');
    setSearchParams(searchParams);
    setCharacteristics([]);
  }

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      const params = {
        dlnr: getValues().dlnr && getValues().dlnr.length ? getValues().dlnr.map(({ value }) => value) : [],
        oe_reference: getValues().oe_reference && getValues().oe_reference.length ? getValues().oe_reference.map(({ value }) => value) : [],
        characteristics: buildAttributesValue(),
      };

      const validParams = Object.entries(params).reduce((acc, [key, value]) => {
        if (value === undefined || value === '' || value.length === 0) {
          searchParams.delete(key);

          return acc;
        }

        acc[key] = value;

        return acc;
      }, {});

      props.getFilters!(validParams);
    });

    return () => subscription.unsubscribe();
  }, [watch, props.filterData?.characteristics]);

  return (
    <div ref={filterRef} className={`new-parts-filter ${props.openFilter ? 'active' : ''}`}>
      <div className="header-filters">
        <div className="buttons-container">
          <LinkButton title={t('_reset')} icon={faRedo} onClick={handleReset} />
        </div>
        <div onClick={handleClose}>
          <FontAwesomeIcon className="icon" icon={faClose} />
        </div>
      </div>
      <div className="title">
        <Trans i18nKey="_filter_results" components={{ b: <strong /> }} />
      </div>
      <div className="filters">
        <div className="dropdowns-container">
          {props.loading ? <Spinner class="catalogs-spinner" /> : ''}
          <div className="dropdown-item">
            {props.filterData?.attributes && (
              <div className="checkbox-select">
                <div className="checkbox-item">
                  <input className="check" type="checkbox" value="true" {...register('isAmerigo')} />
                  <label htmlFor="is-amerigo">{t('_is_amerigo')}</label>
                  <FontAwesomeIcon icon={faCheck} className="check-icon" />
                </div>
              </div>
            )}
            <label className="dropdown-label">{t('_supplier')}</label>
            <Controller
              control={control}
              name="dlnr"
              render={({ field: { onChange, value } }) => (
                <Select
                  value={value}
                  isMulti
                  className="parts-filter-select-dropdown"
                  onChange={(val, { action }) => {
                    onChange(val);

                    // if(action === 'clear' || action === 'remove-value'){
                    //   handleClick();
                    // }
                  }}
                  isDisabled={value?.is_amerigo}
                  options={getSupplierOptions()}
                  placeholder={t('_supplier')}
                  menuPlacement="auto"
                  menuPosition="fixed"
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 50 }) }}
                  isSearchable={!isMobile && !isTablet}
                />
              )}
            />
          </div>
          <div className="dropdown-item">
            <label className="dropdown-label">{t('_oe_references')}</label>
            <Controller
              control={control}
              name="oe_reference"
              render={({ field: { onChange, value } }) => (
                <Select
                  value={value}
                  isMulti
                  className="parts-filter-select-dropdown"
                  onChange={(val) => {
                    onChange(val);
                  }}
                  options={getOEReferenceOptions()}
                  placeholder={t('_oe_references')}
                  menuPlacement="auto"
                  menuPosition="fixed"
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 50 }) }}
                  isSearchable={!isMobile && !isTablet}
                />
              )}
            />
          </div>
        </div>
        {props.filterData && props.filterData?.characteristics ? (
          <div className="container-criteria">
            <div className="dropdown-label">{t('_aim_criteria')}</div>
            {characteristics.length > 3 && (
              <span>
                {showMore ? (
                  <a onClick={() => setShowMore(false)} className="hide btn" id="hide">
                    {t('_hide_filters')}
                  </a>
                ) : (
                  <a onClick={() => setShowMore(true)} className="show btn" id="show">
                    {t('_show_more_filters')}
                  </a>
                )}
              </span>
            )}
            <div
              className={`dropdowns-container-criteria panel criteria-length-${Object.keys(props.filterData?.characteristics).length % 3}`}
            >
              {(showMore ? characteristics : characteristics.slice(0, 4)).map((item) => {
                return (
                  <div key={item.id} className="dropdown-item characteristic ">
                    <label className="dropdown-second-label">{item.label}</label>
                    <Controller
                      control={control}
                      name={item.label.replace('[', '').replace(']', '')}
                      render={({ field: { onChange, value } }) => (
                        <Select
                          value={value}
                          isMulti
                          className="parts-filter-select-dropdown"
                          onChange={(val) => {
                            onChange(val);
                          }}
                          options={getAtributesOptions(item.label)}
                          menuPlacement="auto"
                          placeholder={item.label}
                          styles={{ menuPortal: (base) => ({ ...base, zIndex: 99 }) }}
                          isSearchable={!isMobile && !isTablet}
                        />
                      )}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
      <div className="apply-btn">
        <DefaultButton text={t('_apply')} onClick={() => handleClick()} />
      </div>
    </div>
  );
};

export default PartsFilter;
