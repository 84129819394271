import React from 'react';
import './CatalogsTabsLayout.scss';
import { Outlet } from 'react-router-dom';
import CatalogsTabs from 'components/CatalogVehicle/CatalogsTabs/CatalogsTabs';

const CatalogsTabsLayout: React.FC = () => {

  return (
    <div className="catalog-vehicle-page container-fluid-large">
      <div className="catalog-vehicle-header-wrapper">
        <CatalogsTabs />
      </div>
      <div className="catalog-vehicle-content-wrapper">
        <Outlet />
      </div>
    </div>
  );
};

export default CatalogsTabsLayout;
