import { IHttpResponse, serverAxios } from '../../http';


class CirrusHttp {
  private static route = '/retailer/cirrus/contact-us';

  public async contactUs(params: Params): Promise<IHttpResponse<{ message: string }>> {
    return serverAxios.get(CirrusHttp.route, { params });
  }
}

export type Params = {
  source: string;
  supplier_id: string;
  reference: string
};

export default CirrusHttp;
