import { IOil } from 'interfaces/oil';
import React from 'react';
import ItemEshopView from './ItemEshop.view';


type Props = {
  data: IOil[],
  loading: boolean
  openModalOil: (id: number) => void;
  deleteItem: (id: number) => void;
  register;
  handleOnChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleOnChangeTopOffer: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleOnChangeTopSales: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const ItemEshop: React.FC<Props> = (props) => {
  return (
    <ItemEshopView
      data={props.data}
      loading={props.loading}
      openModalOil={props.openModalOil}
      deleteItem={props.deleteItem}
      register={props.register}
      handleOnChange={props.handleOnChange}
      handleOnChangeTopOffer={props.handleOnChangeTopOffer}
      handleOnChangeTopSales={props.handleOnChangeTopSales}
    />);
};

ItemEshop.displayName = ' itemEshop';

export default ItemEshop;
