import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getEbookSidebarShow, getEbooks } from 'store/selectors/ebooks.selector';
import { setShowEbookSidebar } from 'store/slices/ebooks.slice';
import { useOnClickOutside } from 'utils/hooks/useOnClickOutside';
import EbooksSidebarView from './EbooksSidebar.view';

type Props = {
  showEbooks?: boolean;
  showDetails?: boolean;
  handleCloseEbook?: () => void;
  onReturnButtonClick?: () => void;
};

const EbooksSidebar: React.FC<Props> = (props: Props) => {
  const showSidebar = useSelector(getEbookSidebarShow);
  const ebooks = useSelector(getEbooks);
  const dispatch = useDispatch();
  const ref = useRef() as React.MutableRefObject<HTMLDivElement>;

  const handleReturnItemClick = () => {
    dispatch(setShowEbookSidebar(true));
    props.onReturnButtonClick?.();
  };

  const handleCloseButtonClick = () => {
    dispatch(setShowEbookSidebar(false));
    props.handleCloseEbook?.();
  };

  useOnClickOutside(ref, () => {
    dispatch(setShowEbookSidebar(false));
  });

  const onItemClick = (item) => {
    window.open('/flipbook?pdf_name=' + item, '_blank');
  };

  return (
    <EbooksSidebarView
      ebooks={ebooks}
      ref={ref}
      onCloseButtonClick={handleCloseButtonClick}
      onReturnButtonClick={handleReturnItemClick}
      showSidebar={showSidebar}
      onItemClick={onItemClick}
    ></EbooksSidebarView>
  );
};

export default EbooksSidebar;
