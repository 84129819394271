import React, { useEffect } from 'react';
import ErrorCodesSearchView, { IErrorSearchForm } from './ErrorCodesSearch.view';
import { useForm } from 'react-hook-form';
import {getURLParams} from 'utils/marketplace';
import {createSearchParams, useNavigate, useParams} from 'react-router-dom';
import useReliableCatalogCarQueryParams from 'utils/hooks/useReliableCatalogCarQueryParams';
import useHttp from 'utils/hooks/useHttp';
import useEinaLanguage from '../../../utils/hooks/useEinaLanguage';
import {useSelector} from 'react-redux';
import {RootState} from '../../../store/store';

interface IUrlParams {
  code?: string;
  keyword?: string;
}
interface IEinaUrlParams {
  source?: string;
  vehicle_id?: number;
  criteria?:string;
  error_code?:string;
  brand?:string;
  model?:string;
  system?:string;
  subsystem?:string;
}

type Props = {
  searchLinks: IUrlParams;
}

const ErrorCodesSearch: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const http = useHttp();
  const { setValue, getValues, register, handleSubmit } = useForm<IErrorSearchForm>();
  const urlParams: IUrlParams = getURLParams(location.search);
  const einaUrlParams: IEinaUrlParams = getURLParams(location.search);
  const reliableCatalogCarQueryParams = useReliableCatalogCarQueryParams();
  const [einaVtsLink, setEinaVtsLink] = React.useState('');
  const { vehicle_id } = useParams<'vehicle_id'>();
  const einaLanguage = useEinaLanguage();
  // const user = useSelector((state: RootState) => state.userData.user);
  const user = JSON.parse(localStorage.getItem('userData')!);

  useEffect(() => {
    setValue('code', urlParams.code ?? '');
    setValue('keyword', urlParams.keyword ?? '');

    fetchEinaVtsLink();
  }, [vehicle_id, urlParams.keyword, urlParams.code]);


  function handleSearchByErrorCode(searchValue: string) {
    setValue('keyword', '');

    let paramsObject: { [key: string]: string } = {};

    paramsObject = {...paramsObject, ...reliableCatalogCarQueryParams};
    paramsObject['code'] = String(searchValue);

    navigate({
      pathname: props.searchLinks.code,
      search: `?${createSearchParams(paramsObject)}`
    });
  }

  const handleSearchByKeyword = (searchValue: string) => {
    setValue('code', '');

    let paramsObject: { [key: string]: string } = {};

    paramsObject = {...paramsObject, ...reliableCatalogCarQueryParams};
    paramsObject['keyword'] = String(searchValue);

    navigate({
      pathname: props.searchLinks.keyword,
      search: `?${createSearchParams(paramsObject)}`,
    });
  };

  const onSubmit = () => {
    // console.log('submit');
  };

  const fetchEinaVtsLink = async () => {
    if(!user?.client_settings?.eina_subscription) {
      return;
    }

    http.eina.autologinToVTS().then( async (res) => {
      let autologinUrl: string = res.data?.autologin_url;

      //replace 'en' with einaLanguage in autologinUrl
      autologinUrl = autologinUrl.replace('.com/en', `.com/${einaLanguage}`);

      einaUrlParams.vehicle_id = Number(vehicle_id);

      if (einaUrlParams.vehicle_id && einaUrlParams.source === 'P') {
        const { data } = await http.authTecRmi.fetchTcdIdByPlkId({ vehicle_id });

        einaUrlParams.vehicle_id = Number(data);
      }

      const apiUrl = `${String(process.env.REACT_APP_URL_EINA)}/${einaLanguage}/api`;
      const vehicle = (einaUrlParams.vehicle_id ? `/versions/${einaUrlParams.vehicle_id}` : '');
      const vehicleUrl = `${vehicle}`;

      const customParams = createSearchParams([
        ['criteria', (einaUrlParams.criteria ? String(einaUrlParams.criteria) : '')],
        ['dtcs', (einaUrlParams.error_code ? String(einaUrlParams.error_code) : '')],
        ['url', (vehicleUrl ? String(`${apiUrl}${vehicleUrl}`): '')],
      ]);

      setEinaVtsLink(`${autologinUrl}&${customParams}`);
    })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <ErrorCodesSearchView
      onSubmit={onSubmit}
      getValues={getValues}
      register={register}
      handleSubmit={handleSubmit}
      handleSearchByErrorCode={handleSearchByErrorCode}
      handleSearchByKeyword={handleSearchByKeyword}
      einaVtsLink={einaVtsLink}
    />
  );
};

ErrorCodesSearch.displayName = 'ErrorCodesSearch';

export default ErrorCodesSearch;
