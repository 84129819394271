import { IOil } from 'interfaces/oil';
import React, {useRef, useState} from 'react';
import ImageGallery, {ReactImageGalleryItem} from 'react-image-gallery';
import {LazyLoadImage} from 'react-lazy-load-image-component';
import _ from 'underscore';
import './OilPhotoGallery.scss';


type Props = {
  oil: IOil;
}

const OilPhotoGallery: React.FC<Props> = (props) => {
  const [, setIsFullscreen] = useState<boolean>(false);
  const imageRef = useRef(null);


  const renderImage = item => {

    return <div className="image-gallery-image">
      <img
        alt="oil"
        src={!item.original ? '/images/car-part-placeholder-big.png' : item.original}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src = '/images/car-part-placeholder-big.png';
        }}
      />
    </div>;
  };

  const renderThumb = item => (
    <div className="image-gallery-thumbnail-inner">
      <LazyLoadImage
        alt="oil"
        src={!item.original ? '/images/car-part-placeholder.png' : item.original}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src = '/images/car-part-placeholder-big.png';
        }}
      />
    </div>
  );

  const getImages = () => {
    const {image, data_supplier_logo} = props.oil;

    const imageList: ReactImageGalleryItem[] = [];


    if (_.isEmpty(imageList)) {

      imageList.push({original: image || data_supplier_logo, thumbnail: image});
    }

    return (
      <ImageGallery
        ref={imageRef}
        items={imageList}
        showPlayButton={false}
        showNav
        showFullscreenButton
        renderFullscreenButton={(triggerFullScreen, isFullscreen) => (
          <button
            type="button"
            className={`${!isFullscreen ? 'image-gallery-fullscreen-button' : 'image-gallery-fullscreen-button width'}`}
            onClick={(event) => !isFullscreen && triggerFullScreen(event)}
          >
            {isFullscreen && <i className="fas fa-times" onClick={triggerFullScreen} />}
          </button>
        )}
        showBullets
        onScreenChange={() => setIsFullscreen(prev => !prev)}
        renderItem={renderImage}
        renderThumbInner={renderThumb}
      />
    );
  };


  return (
    <div className={`oil-thumbnail ${!props.oil.image ? 'empty' : ''}`}>
      {getImages()}
    </div>
  );
};

OilPhotoGallery.displayName = 'OilPhotoGallery';

export default OilPhotoGallery;