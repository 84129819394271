import React, { useEffect, useState } from 'react';
import './CarPartCard.scss';
import Placeholder from 'assets/images/car-part-placeholder.png';
import { checkIfImageExists } from 'utils/utils';

type Props = {
    name?: string
    image: string
    handleClick?: () => void
}

const CarPartCard:React.FC<Props> = (props: Props) => {
  const [image, setImage] = useState<string>(String(props.image));

  useEffect(()=>{
    checkIfImageExists(props.image, (exists)=> exists ? setImage(props.image) : setImage(Placeholder));
  },[]);

  return (
    <div className="car-part-card" onClick={props.handleClick}>
      <div className="image-holder">
        <img className="car-part-card-img" src={image}/>
      </div>
      <div className="car-part-name" >
        {props.name}
      </div>
    </div>
  );
};

export default CarPartCard;