import Spinner from 'components/spinner/Spinner';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { INews } from 'utils/hooks/http/news.http';
import useHttp from 'utils/hooks/useHttp';
import { getBackendAssetsBasePath } from 'utils/marketplace';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleArrowLeft } from '@fortawesome/free-solid-svg-icons';
import SideNews from '../side-news/SideNews';
import './NewsView.scss';
import VideoPlayer from '../../VideoPlayer/VideoPlayer';
import { SwiperSlide } from 'swiper/react';
import SwiperSlider from 'components/SwiperSlider/SwiperSlider';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';

type Props = {}

const NewsView: React.FC<Props> = () => {
  const http = useHttp();
  const { id } = useParams();
  const [data, setData] = useState<INews>();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingNews, setLoadingNews] = useState<boolean>(false);
  const [news, setNews] = useState<INews[]>([]);
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const { isDesktop } = useDeviceDetect();

  function getNews() {
    setLoadingNews(true);
    http.news.getNews({ 'per_page': 10 }).then((res) => {
      const result = res.data.data;

      setNews(result.filter((item) => String(item.id) !== String(id)));
    }
    ).finally(() => setLoadingNews(false));
  }

  function getNewsById() {
    setLoading(true);
    http.news.getNewsById(id!).then((res) => {
      setData(res.data);
    }).finally(() => setLoading(false));
  }


  useEffect(() => {
    getNewsById();
    getNews();
  }, [i18n.language, id]);


  return (
    <div className="news-view">
      {loading ? <Spinner class="small news-view-spinner" /> : (
        <React.Fragment>
          <div className="news-view-header">
            <span className="return-btn" onClick={() => navigate('/news')}>
              <FontAwesomeIcon icon={faCircleArrowLeft} className="icon" />
              {`${t('_return')}`}
            </span>
            <div className="news-view-header-title">
              <div className="title">{data?.title}</div>
              <div className="date">{data?.created_at.substring(0, data.created_at.indexOf(' '))}</div>
            </div>
            <div className="news-view-header-contacts"></div>
          </div>
          <div className="news-view-body">
            <div className="news-view-body-media">
              {data?.video ? (
                <div className="player">
                  <VideoPlayer previewImage={`${getBackendAssetsBasePath()}/${data?.image}`} url={data.video} />
                </div>
              ) : (
                <img src={`${getBackendAssetsBasePath()}/${data?.image}`} />
              )}
            </div>
            <div className="news-view-body-content" dangerouslySetInnerHTML={{ __html: String(data?.content) }}>
            </div>
          </div>
          <div className="news-view-other">
            {loadingNews ? <Spinner class="small news-view-spinner" /> : (
              news.length ? (
                <React.Fragment>
                  <div className="divder" />
                  <div className={`relevant-news ${loadingNews ? 'loading' : ''}`}>
                    <div className="relevant-news-title">{t('_relevant_news')}</div>
                    <div className="relevant-news-items">
                      <SwiperSlider
                        slidesPerView="auto"
                        spaceBetween={0}
                        navigation={isDesktop}
                        pagination={!isDesktop}
                        loop
                      >
                        {news.map((item, index: number) => {
                          return (
                            <SwiperSlide className="swiper-slide" key={index}>
                              <SideNews key={item.id} data={item} />
                            </SwiperSlide>
                          );
                        }
                        )}
                      </SwiperSlider>
                    </div>
                  </div>
                </React.Fragment>
              ) : null
            )}
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default NewsView;