import React, { useRef} from 'react';

import {useOnClickOutside} from '../../../../../utils/hooks/useOnClickOutside';
import './UploadProformaModal.scss';
import {useDispatch} from 'react-redux';
import {setProformaActive} from '../../../../../store/slices/proforma.slice';
import IconItem from '../../../../../components/Item/IconItem/IconItem';
import {faClose} from '@fortawesome/free-solid-svg-icons';
import {
  UploadProformaAmerigoContextProvider
} from '../../../../../context/UploadProformaAmerigoContext';
import UploadProformaModalContent from './UploadProformaModalContent/UploadProformaModalContent';


type Props = {
  setOpenModal: (e: boolean) => void;
};

const UploadProformaModal: React.FC<Props> = (props) => {

  const ref = useRef() as React.MutableRefObject<HTMLDivElement>;
  const dispatch = useDispatch();

  useOnClickOutside(ref, () => {
    dispatch(setProformaActive(false));
  });

  return (
    <UploadProformaAmerigoContextProvider>
      <div className="upload-proforma-amerigo" ref={ref}>
        <div className="modal-dialog">
          <div className="close">
            <IconItem icon={faClose} onClick={() => props.setOpenModal(false)}/>
          </div>
          <UploadProformaModalContent/>
        </div>
      </div>

    </UploadProformaAmerigoContextProvider>

  );
};

UploadProformaModal.displayName = 'UploadProformaModal';

export default UploadProformaModal;