import React, { useContext, useEffect, useState } from 'react';
import { ICarPartData } from 'models/car-parts.model';
import Pagination from 'components/pagination/Pagination';
import FavoriteItem from './FavoriteItem/FavoriteItem';
import './FavoriteItems.scss';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import { contentStyle } from 'components/modal/ContentStyleModal';
import Modal from 'components/modal/Modal';
import { useTranslation } from 'react-i18next';
import { FavoriteContext, IFavoriteContext } from 'context/FavoriteContext';
import DeleteConfirmationItemsModal from './DeleteConfirmationItemsModal/DeleteConfirmationItemsModal';
import { useAppDispatch } from 'utils/hooks/redux';
import { setQuantityToolbar } from 'store/slices/cart.slice';
import { addToCartAction } from 'store/actions/cart.actions';
import { CATALOG_MANUFACTURER } from 'utils/constants/defaultConstants';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { ReactComponent as CheckedImage } from 'assets/images/checked.svg';
import { ReactComponent as UncheckedImage } from 'assets/images/unchecked.svg';

type Props = {

}

const FavoriteItems: React.FC<Props> = () => {

  const { selectedList, handlePageClick, favoriteItemsLoading, favoriteItems, pagination, getFavoriteItems } = useContext(FavoriteContext) as IFavoriteContext;

  const [openModal, setOpenModal] = useState<boolean>(false);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [cartLoading, setCartLoading] = useState<boolean>(false);
  const [checkedList, setCheckedList] = useState<ICarPartData[]>([]);
  const [allChecked, isAllChecked] = useState<boolean>(false);
  const { quantityToolbar } = useSelector((state: RootState) => state.cart);

  const onDone = () => {
    // setFavoriteItems([]);
    getFavoriteItems(String(selectedList?.id));
    setCheckedList([]);
    isAllChecked(false);
  };


  useEffect(() => {
    if (checkedList.length === favoriteItems.length) {
      isAllChecked(true);
    } else {
      isAllChecked(false);
    }
  }, [checkedList]);

  const handleSelectAll = () => {
    const allCheckedCopy = !allChecked;

    if (allCheckedCopy) {
      setCheckedList(favoriteItems);
    } else {
      setCheckedList([]);
    }

    isAllChecked(allCheckedCopy);
  };

  /*   function calcAvailability(item) {
    if (item.available && typeof item.available === 'object') {
      const newLimit = { ...item.available };

      delete newLimit.uv;

      return Object.values(newLimit).reduce((acc, item: typeof newLimit) => {
        if (item?.quantity === undefined) {
          return Number(acc) + Number(item);
        }

        return Number(acc) + Number(item.quantity);
      }, 0);
    }

    return item.available;
  } */

  const handleAddToCart = async () => {
    setCartLoading(true);
    const filteredList = checkedList.filter(item => item.add_to_cart 
      // && calcAvailability(item)
      && item.available?.warehouse_main?.quantity !== 0
    );

    const params = filteredList.map((item) => {
      let param = {
        catalog_id: item.catalog_id,
        cod_fou: item.cod_fou,
        price: item.price?.price !== undefined ? item.price?.price : item.price,
        is_oe_part: item.catalog_type === CATALOG_MANUFACTURER,
        quantity: 1, //item.quantity
        supplier_id: item.data_supplier.id,
        supplier_reference: item.data_supplier_reference,
      };

      if (localStorage.getItem('external_cart_id')) {
        param = { ...param, external_cart_id: localStorage.getItem('external_cart_id') } as typeof param;
      }

      return param;
    });


    dispatch(addToCartAction(params)).finally(() => {
      setCartLoading(false);
    });
    dispatch(setQuantityToolbar(+filteredList.length + +quantityToolbar));
  };

  return (
    <div className="favorite-items-container">

      <div className="items-options">
        {
          favoriteItems.length > 0 && (
            <div className="select-all" onClick={handleSelectAll}>
              <div className="status">
                {
                  allChecked ?
                    (

                      <CheckedImage />
                    ) : (
                      <UncheckedImage />

                    )
                }
              </div>
              <p>{t('_select_all')}</p>
            </div>
          )
        }
        {
          checkedList.length > 0 && (
            <div className="delete">
              {
                checkedList.length > 0 && (
                  <DefaultButton red text={t('_delete')} onClick={() => setOpenModal(true)} />
                )
              }
            </div>
          )
        }
        {
          checkedList.length > 0 && (
            <div className="favourite-add-to-cart">
              {
                checkedList.length > 0 && (
                  <DefaultButton text={t('_add_to_cart')} onClick={() => handleAddToCart()} isloading={cartLoading} />
                )
              }
            </div>
          )
        }
      </div>


      <div className="list">
        {
          favoriteItems.length > 0 ? (
            favoriteItems.map((item, index) => {
              return <FavoriteItem checkedList={checkedList} setCheckedList={setCheckedList} key={index} car={item} />;
            })
          ) : (
            !favoriteItemsLoading && (
              <div className="no-items">
                {t('_no_data')}
              </div>
            )
          )
        }
        {
        }
      </div>
      <div className="pagination">
        {
          pagination.total_count > 0 && pagination.total_pages > 1 && (
            <Pagination handlePageClick={handlePageClick} pageCount={pagination.total_pages} forcePage={pagination.page} />
          )
        }
      </div>
      <Modal
        openModal={openModal}
        setOpenModal={setOpenModal}
        childrenComponent={<DeleteConfirmationItemsModal checkedList={checkedList} favoriteList={selectedList} onDone={onDone} setOpenModal={setOpenModal} />}
        contentStyle={contentStyle}
      />
    </div>

  );
};

FavoriteItems.displayName = 'FavoriteItems';

export default FavoriteItems;