import { CancelToken } from 'axios';
import {
  IBodyResponseBatteryBrands,
  IBodyResponseBatteryCategories,
  IBatteryInfo,
  IParamsBattery,
  IParamsPagination,
  IBodyResponseBattery,
} from 'interfaces/battery';
import { addRouteParam, IHttpResponse, serverAxios } from '../../http';

class BatteryAdminHttp {
  private static list = '/battery/products';
  private static listAdmin = '/battery/list_admin';
  private static brands = '/battery/brand/list';
  private static category = '/battery/category/list';
  private static info = '/retailer/catalog_battery/';
  private static update = '/retailer/catalog_battery/update/';
  private static updateTopOffer = '/retailer/catalog_battery/update/:id/top_offer';
  private static updateTopSales = '/retailer/catalog_battery/update/:id/top_sales';
  private static create = '/retailer/catalog_battery/register';
  private static delete = '/retailer/catalog_battery/';
  private static deleteALL = '/retailer/catalog_battery/delete_all';
  private static deleteSelected = '/retailer/catalog_battery/delete_all';
  public getBattery(urlParams: IParamsBattery | {}, cancelToken: CancelToken): Promise<IHttpResponse<IBodyResponseBattery>> {
    return serverAxios.get(BatteryAdminHttp.list, { params: urlParams, cancelToken: cancelToken });
  }

  public getBatteryAdmin(urlParams: IParamsBattery | {}, cancelToken: CancelToken): Promise<IHttpResponse<IBodyResponseBattery>> {
    return serverAxios.get(BatteryAdminHttp.listAdmin, { params: urlParams, cancelToken: cancelToken });
  }
  public getBatteryBrand(urlParams: IParamsPagination): Promise<IHttpResponse<IBodyResponseBatteryBrands>> {
    return serverAxios.get(BatteryAdminHttp.brands, { params: urlParams });
  }
  public getBatteryCategory(urlParams: IParamsPagination): Promise<IHttpResponse<IBodyResponseBatteryCategories>> {
    return serverAxios.get(BatteryAdminHttp.category, { params: urlParams });
  }

  public getBatteryInfo(id: number): Promise<IHttpResponse<IBatteryInfo>> {
    return serverAxios.get(BatteryAdminHttp.info + id);
  }

  public postBattery(id: number, data: FormData): Promise<IHttpResponse<IBatteryInfo>> {
    return serverAxios.post(BatteryAdminHttp.update + id, data);
  }

  public postBatteryTopOffer(id: number, data: {}): Promise<IHttpResponse<[]>> {
    return serverAxios.post(addRouteParam(BatteryAdminHttp.updateTopOffer, { id }), data);
  }

  public postBatteryTopSales(id: number, data: {}): Promise<IHttpResponse<[]>> {
    return serverAxios.post(addRouteParam(BatteryAdminHttp.updateTopSales, { id }), data);
  }

  public createBattery(data: FormData): Promise<IHttpResponse<IBatteryInfo>> {
    return serverAxios.post(BatteryAdminHttp.create, data);
  }
  public deleteBattery(id: number): Promise<IHttpResponse<null>> {
    return serverAxios.delete(BatteryAdminHttp.delete + id);
  }
  public deleteBatteryAll(): Promise<IHttpResponse<null>> {
    return serverAxios.delete(BatteryAdminHttp.deleteALL);
  }
  public deleteBatterySelected(ids: Array<string>): Promise<IHttpResponse<null>> {
    return serverAxios.delete(BatteryAdminHttp.deleteSelected, { params: { ids: ids } });
  }
}

export default BatteryAdminHttp;
