import React, {PropsWithChildren} from 'react';
import VideoPlayerView from './VideoPlayer.view';

type Props = {
  url: string;
  previewImage?: string | boolean;
  width?: string;
  height?: string;
  controls?: boolean;
  playing?: boolean;
  volume?: number;
  playIcon?: JSX.Element;
};

const VideoPlayer: React.FC<PropsWithChildren<Props>> = (props: PropsWithChildren<Props>) => {

  return <VideoPlayerView {...props}>
    {
      props.children
    }
  </VideoPlayerView>;
};

VideoPlayer.displayName = 'VideoPlayer';

export default VideoPlayer;