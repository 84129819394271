import React from 'react';

type Props = { value: string };

const ReferenceCellView: React.FC<Props> = (props: Props) => {
  return <div className="reference-cell-wrapper">{props.value}</div>;
};

ReferenceCellView.displayName = 'ReferenceCellView';

export default ReferenceCellView;
