import React, { useCallback, useContext } from 'react';
import UploadedMessageFile from '../../message/uploaded-message-file/UploadedMessageFile';
import { IMessageCreate, removeFile } from '../messages-utils';
import DefaultButton from '../../buttons/DefaultButton/DefaultButton';
import { useForm } from 'react-hook-form';
import { IMessagesContext, MessagesContext } from '../../../context/MessagesContext';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import './ReplyMessage.scss';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import { ReactComponent as FileUpload } from '../../../assets/Icons/file-upload.svg';

type Props = {
  onSubmit: (values) => Promise<void>;
}

const ReplyMessage: React.FC<Props> = (props) => {
  const { files, setFiles, messageIsCreating } = useContext(MessagesContext) as IMessagesContext;
  const { t } = useTranslation();
  const { isMobile } = useDeviceDetect();

  const {
    getValues,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IMessageCreate>();

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setFiles([...acceptedFiles, ...files]);
  }, [files]);

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    onDrop,
    multiple: true,
  });

  const onSubmitHandler = () => {
    if (messageIsCreating) {
      return;
    }

    const values = getValues();

    props.onSubmit(values).then(() => {
      reset({ 'message': '' });
    });
  };

  return (
    <div className="reply-container">
      {
        files.length ? (
          <div className="files-data">
            {
              files.map((file, fileIndex) => {
                return (
                  <UploadedMessageFile key={fileIndex} messageFile={file} removeFile={() => removeFile(files, file, setFiles)} />
                );
              })
            }
          </div>
        ) : null
      }
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <div className="reply-content">
          <div className={`left-wrapper ${errors.message?.type == 'required' ? 'error' : ''}`}>

            <textarea
              {...register('message', {
                required: true,
              })}
            />

          </div>
          <div className="right-wrapper">
            <div {...getRootProps({ className: 'content' })}>
              <input {...getInputProps()} />
              <FileUpload />
              <p>{t('_upload')}</p>
            </div>
          </div>
        </div>
        <div className="reply-content">
          <div className="left-wrapper">
            <div className="submit-wrapper">
              <DefaultButton isloading={messageIsCreating} text={t('_send')} type="submit" />
            </div>
          </div>
          {
            isMobile ? null : <div className="right-wrapper"> </div>
          }

        </div>
        {
          fileRejections.length > 0
            ? <div className="modal-message-dropzone__error">{t('FILE_RESTRICT')}</div>
            : ''
        }
      </form>
    </div>

  );
};

ReplyMessage.displayName = 'ReplyMessage';


export default ReplyMessage;