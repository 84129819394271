import React from 'react';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';

import CatalogVehicleHeaderView from './CatalogVehicleHeader.view';
import CatalogVehicleHeaderMobileView from './Mobile/CatalogVehicleHeaderMobile.view';

type Props = {};

const CatalogVehicleHeader: React.FC<Props> = () => {
  const { isMobile } = useDeviceDetect();

  return (
    isMobile ?
      <CatalogVehicleHeaderMobileView />
      :
      <CatalogVehicleHeaderView />
  );
};

CatalogVehicleHeader.displayName = 'CatalogVehicleHeader';

export default CatalogVehicleHeader;
