import React, { useMemo } from 'react';
import { Column, useTable } from 'react-table';
import Head from '../Cells/Head/Head';
import { useNavigate, useParams } from 'react-router-dom';
import { IData } from 'models/order';
import Pagination from 'components/pagination/Pagination';
import Spinner from 'components/spinner/Spinner';
import { useTranslation } from 'react-i18next';
import './SellersTable.scss';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { IUser } from '../../../models/IUser';


type Props = {
  data: IData[]
  loading: boolean
  handlePageClick?: (data) => Promise<void>
  totalPage?: number
  currentPage?: number
};


const SellersTable: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id: catalogId } = useParams();
  const { isMobile } = useDeviceDetect();
  const user: IUser = JSON.parse(localStorage.getItem('userData') || '');


  const tableData: Column<IData>[] = [
    {
      Header: <Head text={t('_amount').toLocaleUpperCase()}></Head>,
      accessor: 'total_price',
    },
    {
      Header: <Head text={t('_id').toLocaleUpperCase()}></Head>,
      accessor: 'id',
    },
    {
      Header: <Head text={t('_internal_cmd').toLocaleUpperCase()}></Head>,
      accessor: 'ebay_order_id',
    },
    {
      Header: <Head text={t('_name').toLocaleUpperCase()}></Head>,
      accessor: 'user_full_name',
    },
    {
      Header: <Head text={t('_email').toLocaleUpperCase()}></Head>,
      accessor: 'user_email',
    },
    {
      Header: <Head text={t('_teccom_id').toLocaleUpperCase()}></Head>,
      accessor: 'proforma_id',
    },
    {
      Header: <Head text={t('_date').toLocaleUpperCase()}></Head>,
      accessor: 'time',
    }
  ];

  // add new item after item with id
  const addAfter = (id: string, item: Column<IData>) => {
    const index = tableData.findIndex((item) => item.accessor === id);

    tableData.splice(index + 1, 0, item);
  };


  addAfter('time', {
    Header: <Head text={t('_status').toLocaleUpperCase()}></Head>,
    accessor: 'status',
    // Cell: t('_sent')
  });


  const columns = useMemo(
    (): Column<IData>[] => tableData,
    [],
  );

  const hiddenColumns = isMobile ? { hiddenColumns: ['time', 'proforma_id', 'ebay_order_id', 'user_email'] } : {};

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data: props.data,
    initialState: hiddenColumns
  });

  const handleOrderView = (orderId) => {

    if (catalogId) {
      navigate(`/catalog/${catalogId}/orders/${orderId}`);
    } else {
      navigate(`/orders/${orderId}`);
    }

  };


  return (
    <div className="table-wrapper sellers-table-wrapper">
      <table {...getTableProps()}>
        <thead className="headings">
          {headerGroups.map((headerGroup, index) => {
            return (
              <tr key={headerGroup.headers[index].id} className="row">
                {headerGroup.headers.map((column) => {
                  const { key, ...restColumn } = column.getHeaderProps();

                  return (
                    <th key={key} {...restColumn} className="heading">
                      {column.render('Header')}
                    </th>
                  );
                })}
              </tr>
            );
          })}
        </thead>

        {
          props.loading ?
            <tbody className="body">
              <tr className="no-data">
                <td colSpan={8}>
                  <Spinner class="extra-small" />
                </td>
              </tr>
            </tbody> :
            props.data.length ?
              <tbody {...getTableBodyProps} className="body">
                {rows.map((row) => {
                  prepareRow(row);
                  const { key, ...restRowProps } = row.getRowProps();

                  return (

                    <tr key={key} {...restRowProps} onClick={() => handleOrderView(row.original.id)} className="cell-row">
                      {row.cells.map((cell) => {
                        const { key, ...restCellProps } = cell.getCellProps();

                        return (
                          <td key={key} {...restCellProps} className="cell">
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
              :
              <tbody className="body">
                <tr className="no-data">
                  <td colSpan={8}>{t('_no_data')}</td>
                </tr>
              </tbody>
        }
      </table>
      {props.totalPage! > 1 && (
        <div>
          <Pagination pageCount={props.totalPage!} handlePageClick={props.handlePageClick!} forcePage={props.currentPage!} />
          {/* <Pagination pageCount={props.totalPage!} handlePageClick={props.handlePageClick!} forcePage={props.currentPage! - 1} /> */}
        </div>
      )}
    </div>

  );
};

SellersTable.defaultProps = {
  currentPage: 1
};


SellersTable.displayName = 'SellersTable';

export default SellersTable;
