import { faWarning } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Footer from 'components/footer/Footer';
import Modal from 'components/modal/Modal';
import Pagination from 'components/pagination/Pagination';
import { ICatalogInfo, IData, IFilterForm } from 'interfaces/selectClient';
import React, { PropsWithChildren } from 'react';
import InfoClient from './InfoClient';
import { contentStyle } from './ModalClient/ContentStyleModal';
import ModalClient from './ModalClient/ModalClient';
import Spinner from 'components/spinner/Spinner';
import Select from 'react-select';
import { Control, Controller, FieldValues, UseFormHandleSubmit, UseFormRegister } from 'react-hook-form';
import './style.scss';
import { useTranslation } from 'react-i18next';

interface IOption {
  label: string;
  value: string;
}

type Props = {
  openModal: boolean;
  handlePageClick: (page: number) => void;
  setOpenModal: (e: boolean) => void;
  infoClients: IData[] | undefined;
  curentPage: number;
  totalPage: number;
  loading: boolean;
  optionsCountries: IOption[];
  onSubmit: () => void;
  handleSubmit: UseFormHandleSubmit<IFilterForm>;
  register: UseFormRegister<IFilterForm>;
  resetFilter: () => void;
  control: Control<FieldValues>;
  valueCountries: IOption | null;
  setValueCountries: (e: IOption | null) => void;
  handleSelectClient: (e: number) => void;
  loadingSaveClient: boolean;
  clientIdForSpinner: number;
  logout: () => void;
  openModalClientId: number;
  setOpenModalClientId: (e: number) => void;
  catalogInfo: ICatalogInfo | null;
};

const SelectClientView: React.FC<Props> = (props: PropsWithChildren<Props>) => {
  const { t } = useTranslation();

  return (
    <div className="container-client-amerigo">
      <div className="container-items">
        <div className="container-top-header">
          <div className="container-logo">
            {/*<img key={props.catalogInfo?.id} src={`${getMarketplaceBaseUrl()}/${props.catalogInfo?.image[1] ?? ''}`} width="108" height="48"/>*/}
          </div>
          <div className="header-body">
            <div className="header-icon">
              <FontAwesomeIcon icon={faWarning} className="icon" />
            </div>
            {t('_select_client_to_continue')}
          </div>
          <div className="header-btn">
            <button className="header-btn-logout" type="button" onClick={props.logout}>
              {t('_logout')}
            </button>
          </div>
        </div>
        <div className="container-top">
          <div className="container-top-title">{t('_select_client')}</div>
          <form onSubmit={props.handleSubmit(props.onSubmit)}>
            <div className="container-top-filter">
              <div className="filter-input">
                <div className="filter-item">
                  <input
                    className="select-client-name"
                    placeholder="Par nom de client"
                    {...props.register('name')}
                  />
                </div>
                <div className="filter-item">
                  <Controller
                    control={props.control}
                    name="country"
                    render={({ field: { onChange } }) => (
                      <Select
                        value={props.valueCountries}
                        className="select-dropdown "
                        onChange={(val) => {
                          onChange(val?.value);
                          props.setValueCountries(val);
                        }}
                        options={props.optionsCountries}
                        placeholder={t('_select_country')}
                        menuPosition="fixed"
                        styles={{ menuPortal: base => ({ ...base, zIndex: 50 }) }}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="filter-button">
                <div className="filter-item">
                  <button className="filter-btn-search" type="button" onClick={props.resetFilter}>
                    {t('_reset')}
                  </button>
                  <button className="filter-btn-reset" type="submit">
                    {t('_filter')}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="container-middle">
          {props.loading ? (
            <Spinner class="spinner-client" />
          ) : (
            <React.Fragment>
              {props.infoClients?.length ? (
                props.infoClients?.map((item) => {
                  return (
                    <InfoClient
                      key={item.id}
                      setOpenModalClientId={props.setOpenModalClientId}
                      setOpenModal={props.setOpenModal}
                      info={item}
                      handleSelectClient={props.handleSelectClient}
                      loadingSaveClient={props.loadingSaveClient}
                      clientIdForSpinner={props.clientIdForSpinner}
                    />
                  );
                })
              ) : (
                <div className="select-client-no-data">{t('_no_data').toLocaleUpperCase()}</div>
              )}
              { }
            </React.Fragment>
          )}
        </div>

        <div>
          {props.totalPage > 1 && (
            <Pagination pageCount={props.totalPage} handlePageClick={props.handlePageClick} forcePage={props.curentPage} />
          )}
        </div>

        <div className="container-bootom">
          <Footer />
        </div>
      </div>
      <Modal
        openModal={props.openModal}
        setOpenModal={props.setOpenModal}
        childrenComponent={<ModalClient setOpenModal={props.setOpenModal} openModalClientId={props.openModalClientId} />}
        contentStyle={contentStyle}
      />
    </div>
  );
};

SelectClientView.displayName = 'SelectClientView';

export default SelectClientView;
