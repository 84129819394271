import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping } from '@fortawesome/free-solid-svg-icons';

import './CartButton.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import CartSlidingV2 from 'applications/retailer/components/cartSlidingV2/CartSlidingV2';
import { useOnHoverOutside } from 'utils/hooks/useOnHoverOutside';

type Props = {
  quantityToolbar?: number;
};

const CartButton: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState<boolean>(false);
  const ref = useRef() as React.MutableRefObject<HTMLDivElement>;
  const location = useLocation();


  useOnHoverOutside(ref, () => {
    setIsActive(false);
  });


  const handleCartButtonClick = () => {
    navigate('/cart');
  };

  useEffect(() => {
    if (location.pathname.includes('/cart')) {
      setIsActive(false);
    }

  }, [location.pathname]);

  return (
    <div className="cart-retailer-body" onMouseMove={() => { !location.pathname.includes('/cart') ? setIsActive(true) : setIsActive(false); }} ref={ref}>
      <div className="CartButton cart-button" onClick={handleCartButtonClick}  >
        {props.quantityToolbar ?
          <div className="quantity">{props.quantityToolbar > 99 ? '+99' : props.quantityToolbar}</div>
          : ''}
        <FontAwesomeIcon icon={faCartShopping} className="cart-icon" />
      </div>
      <CartSlidingV2 isActive={isActive} />
    </div>
  );
};

CartButton.displayName = 'CartButton';

export default CartButton;
