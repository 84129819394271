import React, {useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import { getCurrentCatalog } from 'store/selectors/catalogs.selector';
import CatalogTariffsView from './CatalogTariffsView';
import useHttp from 'utils/hooks/useHttp';
import {IOption} from 'interfaces/select';
import { RootState } from 'store/store';
import { ROLE_SUPPLIER_AMERIGO } from 'utils/constants/roles';

const CatalogTariffs: React.FC = () => {

  const {selectClient} = useHttp();
  const currentCatalog = useSelector(getCurrentCatalog);
  const clientId = useSelector((state: RootState) => state.userData.user?.client_id);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [clients, setClients] = useState<IOption[] | []>([]);
  const [preselectedClient, setPreselectedClient] = useState<IOption>();
  const [loading, setLoading] = useState(false);

  const userData = useSelector((state: RootState) => state.userData.user);
  const roles = userData?.roles;

  useEffect(() => {

    if(roles?.includes(ROLE_SUPPLIER_AMERIGO)){
      getClients();
    }
   
  }, []);

  const getClients = async() => {
    setLoading(true);
    await selectClient.getSellerClients().then((res)=>{
      setClients(res);
    }).finally(()=>{
      setLoading(false);
    });
  };

  useEffect(()=>{
    const [defaultClient] = clients.filter(item => +item.value === clientId).map(item => ({ label: item.label, value: item.value}));
      
    setPreselectedClient(defaultClient);
  },[clients]);

  return (
    <CatalogTariffsView
      loading={loading}
      catalogInfo={currentCatalog}
      clients={clients}
      openModal={openModal}
      setOpenModal={setOpenModal}
      preselectedClient={preselectedClient}
    />
  );
};


CatalogTariffs.displayName = 'CatalogTariff';


export default CatalogTariffs;