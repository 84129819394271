import React, { useEffect, useState } from 'react';
import TextInput from 'components/inputs/TextInput/TextInput';
import { useTranslation } from 'react-i18next';
import ServiceItem from './ServiceItem';
import Spinner from 'components/spinner/Spinner';
import { IMaintenanceWorksService } from 'utils/rmi/interface/maintenance.interface';
import './ServiceCard.scss';

type Props = {
data?
loading?: boolean,
title: string
}
const ServiceCard:React.FC<Props> = (props) => {
  const {t} = useTranslation();
  const [filteredData, setFilteredData] =useState<IMaintenanceWorksService[]>([]);

  function handleSearch(e){
    setFilteredData(props.data.filter(item => item.ItemMpText.toLowerCase().includes(e.target.value.toLowerCase())));
  }

  useEffect(()=>{
    if(!props.data?.length){return;}

    setFilteredData(props.data);
  },[props.data]);

  return (
    <div className="service-card-container">
      <form className="header">
        <div className="title">
          {t(props.title)}
        </div>
        <TextInput placeholder={t('_search')} onChange={handleSearch} />
      </form>
      {props.loading ? <Spinner class="small service-spinner"/> :
        filteredData?.map((item, index)=> {
          return <ServiceItem key={index + item.ItemMpText} data={item}/>;
        })}
    </div>
  );
};

export default ServiceCard;