import TextInput from 'components/inputs/TextInput/TextInput';
import { IClientInfo, IUpdateForm } from 'interfaces/selectClient';
import React, { PropsWithChildren } from 'react';

type Props = {
  infoClient: IClientInfo;
  teccomMappingData: IUpdateForm[];
  setTeccomMappingData: (teccomMappingData: IUpdateForm[]) => void;
};

const ConfigurationTab: React.FC<Props> = (props: PropsWithChildren<Props>) => {
  const handleTeccomMappingChange = (propertyName: string, teccomMappingIndex: number, value: string) => {
    const teccomMappingDataCopy = [...props.teccomMappingData];
    const teccomMapping = { ...teccomMappingDataCopy[teccomMappingIndex] };

    teccomMapping[propertyName] = value;
    teccomMappingDataCopy[teccomMappingIndex] = teccomMapping;
    props.setTeccomMappingData(teccomMappingDataCopy);
  };

  return (
    <div className="configuration-tab">
      <div className="tab-row configuration-tab">
        <div className="tab-label">Client ID :</div>
        <div className="tab-span">{props.infoClient.client_id}</div>
      </div>
      <div className="tab-row">
        <div className="tab-label">Country</div>
        <div className="tab-span">{props.infoClient.country}</div>
      </div>
      <div className="tab-row">
        <div className="tab-label">Language:</div>
        <div className="tab-span">{props.infoClient.language}</div>
      </div>
      <div className="tab-row">
        <div className="tab-label">Name:</div>
        <div className="tab-span">{props.infoClient.name}</div>
      </div>
      <div className="tab-row">
        <div className="tab-label">Repere:</div>
        <div className="tab-span">PLATEFORME AMERIGO</div>
      </div>

      {props.teccomMappingData.map((item, index) => {
        return (
          <div className="tab-row" key={index}>
            <div className="tab-label">{item.name}:</div>
            <form className="tab-span">
              <TextInput
                placeholder="Seller ID"
                value={item.buyer_id}
                onChange={(e) => handleTeccomMappingChange('buyer_id', index, e.target.value)}
              />
              <TextInput
                placeholder="Buyer ID"
                value={item.seller_id}
                onChange={(e) => handleTeccomMappingChange('seller_id', index, e.target.value)}
              />
            </form>
          </div>
        );
      })}
    </div>
  );
};

ConfigurationTab.displayName = 'ConfigurationTab';

export default ConfigurationTab;
