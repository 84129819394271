import React from 'react';

import './ModalFileNotFond.scss';
import { useTranslation } from 'react-i18next';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';

type Props = {
  setModalNotFile: (e: boolean) => void;
};
const ModalFileNotFond: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  return (
    <div className="modal_file_not_fond">
      <div className="text">
        <span className="file_not_fond">{t('_file_not_fond')}</span>
        <br></br>
        <span>{t('_file_not_fond_message')}</span>
      </div>
      <div className="button">
        <DefaultButton type="button" text={t('_close')} onClick={() => { props.setModalNotFile(false); }} />
      </div>
    </div>
  );
};

ModalFileNotFond.displayName = 'ModalFileNotFond';

export default ModalFileNotFond;
