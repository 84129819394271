import React, { useState, MutableRefObject, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCar, faHeart, faList, faForward, faWaveSquare, faFile } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import './CatalogVehicleHeaderMobile.scss';
import { NavLink, createSearchParams } from 'react-router-dom';
import { useOnClickOutside } from 'utils/hooks/useOnClickOutside';
import VehicleInfo from 'components/VehicleInfo/VehicleInfo';
import { getVehicleDescription } from 'utils/getVehicleDescription';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import Spinner from 'components/spinner/Spinner';
import useReliableCatalogCarQueryParams from 'utils/hooks/useReliableCatalogCarQueryParams';
import { SwiperSlide } from 'swiper/react';
import SwiperSlider from 'components/SwiperSlider/SwiperSlider';

type Props = {};

const CatalogVehicleHeaderMobileView: React.FC<Props> = () => {
  const { t } = useTranslation();
  const [loading] = useState(false);
  const [vehicleInfoIsOpen, setVehicleInfoIsOpen] = useState<boolean>(false);
  const { user } = useSelector((state: RootState) => state.userData);
  const { car, carInfo } = useSelector((state: RootState) => state.carSelected);
  const vehicleInfoPopupRef = useRef() as MutableRefObject<HTMLDivElement>;
  const reliableCatalogCarQueryParams = useReliableCatalogCarQueryParams();
  const queryParams = createSearchParams(reliableCatalogCarQueryParams);

  useOnClickOutside(vehicleInfoPopupRef, () => setVehicleInfoIsOpen(false));

  const getActiveSlideFromDom = () => {
    const activeNavItem = document.querySelector('.vehicle-page-navbar .swiper-container.items .navbar-item .active');
    const activeIndex = activeNavItem?.parentElement?.parentElement?.getAttribute('data-swiper-slide-index');

    return Number(activeIndex);
  };


  return (
    <div className="vehicle-header-wrapper">
      <div className="vehicle-header-container">

        <div className="vehicle-details">
          <div className="info" onClick={() => { setVehicleInfoIsOpen(true); }}>
            <FontAwesomeIcon icon={faCar} /> <div className="label"> {t('_vehicle_details')}</div>

          </div>
          {vehicleInfoIsOpen && <VehicleInfo loading={loading} setVehicleInfoIsOpen={setVehicleInfoIsOpen} />}
        </div>
        {!carInfo ? (
          <Spinner class="extra-small" />
        ) : (
          <React.Fragment>
            <div className="vehicle-name">
              {carInfo?.manufacturer_name} {carInfo?.model_name ? carInfo?.model_name : carInfo?.model}
            </div>
            <div className="vehicle-description">
              <span>{carInfo && car ? getVehicleDescription(carInfo, car) : ''}</span>
            </div>
          </React.Fragment>
        )}
      </div>

      <div className="vehicle-page-navbar">
        <SwiperSlider
          slidesPerView="auto"
          isLoading={loading}
          spaceBetween={0}
          slideToIndex="auto"
          loop
          getActiveSlideFromDom={getActiveSlideFromDom}
        >
          <SwiperSlide className="swiper-slide swiper-navbar-item">
            <div className="navbar-item">
              <NavLink to={`catalogs?${queryParams}`}>
                <React.Fragment>
                  <FontAwesomeIcon icon={faList} /> {t('_catalogs')}
                </React.Fragment>
              </NavLink>
            </div>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide">
            <div className="navbar-item">
              <NavLink to={`technical-documentation?${queryParams}`} className={user?.client_rmi_modules_type === 'none' ? 'disabled' : ''}>
                <React.Fragment>
                  <FontAwesomeIcon icon={faFile} /> {t('_technical_documentation')}
                </React.Fragment>
              </NavLink>
            </div>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide">
            <div className="navbar-item">
              <NavLink
                to={`error-codes?${queryParams}`}
                className={user?.client_rmi_modules_type === 'none' && user?.client_settings.eina_subscription === false ? 'disabled' : ''}
              >
                <React.Fragment>
                  <FontAwesomeIcon icon={faForward} /> {t('_step_by_step_method')}
                </React.Fragment>
              </NavLink>
            </div>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide">
            <div className="navbar-item">
              <NavLink to={`diagnostic?${queryParams}`} className={user?.client_settings.actia === false ? 'disabled' : ''}>
                <React.Fragment>
                  <FontAwesomeIcon icon={faWaveSquare} /> {t('_diagnostic')}
                </React.Fragment>
              </NavLink>
            </div>
          </SwiperSlide>
        </SwiperSlider>
      </div>
    </div>
  );
};

CatalogVehicleHeaderMobileView.displayName = 'CatalogVehicleHeaderMobileView';

export default CatalogVehicleHeaderMobileView;
