import React from 'react';
import { useTranslation } from 'react-i18next';
import SupplierCard from './supplier-card/SupplierCard';
import AutomotorFrance from 'assets/images/automotor-france.png';
import FactoryRange from 'assets/images/factory-range.png';
import { createSearchParams, useNavigate } from 'react-router-dom';
import './PresentationSuppliers.scss';

type Props = {}

const PresentationSuppliers:React.FC<Props> = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  function handleClick(catalog_id){
    navigate({
      pathname: '/public/categories',
      search: createSearchParams({catalog_id}).toString()
    });
  }


  return (
    <div className="presentation-suppliers">
      <div className="suppliers-title">{t('_select_a_supplier')}</div>
      <div className="suppliers-container">
        <SupplierCard name="AF" image={AutomotorFrance} handleClick={()=> handleClick('AUT')}/>
        <SupplierCard name="Factory" image={FactoryRange} handleClick={()=> handleClick('IND')}/>
      </div>
    </div>
  );
};

export default PresentationSuppliers;