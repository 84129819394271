import React from 'react';

import AppView from './App.view';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en.json';
import fr from 'javascript-time-ago/locale/fr.json';
import ru from 'javascript-time-ago/locale/ru.json';
import useRoutes from './utils/hooks/use-routes';
// import UnderMaintenancePage from 'applications/retailer/views/UnderMaintenancePage/UnderMaintenancePage';

const App: React.FC = () => {
  const applicationMode = useRoutes();

  TimeAgo.addLocale(en);
  TimeAgo.addLocale(ru);
  TimeAgo.addLocale(fr);

  // return <UnderMaintenancePage/>;

  return <AppView routes={applicationMode} />;
};

App.displayName = 'App';

export default React.memo(App);
