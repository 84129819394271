import React, {useEffect, useState} from 'react';
import ActiaPageView from './ActiaPage.view';
import {
  ActiaListRequest,
  IActiaData,
  IActiaPagination,
  IActiaTableData, ISessionModal
} from '../../../../components/DiagnosticItems/Actia/actia';
import useHttp from 'utils/hooks/useHttp';
import {useSelector} from 'react-redux';
import {RootState} from '../../../../store/store';
import {getURLParams, notify} from '../../../../utils/marketplace';
import {useParams} from 'react-router-dom';

interface IUrlParams {
  source?: string;
  vehicle_identifier?: string;
}

const ActiaPage: React.FC = () => {
  const {car, carInfo} = useSelector((state: RootState) => state.carSelected);
  const [actiaItems, setActiaItems] = useState<IActiaData[]>([]);
  const {actiaHttp} = useHttp();
  const [loading, setLoading] = useState(false);
  const [actiaTableData, setActiaTableData] = useState<IActiaTableData[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const {vehicle_id} = useParams();
  const urlParams: IUrlParams = getURLParams(location.search);


  const [modalValues, setModalValues] = useState<ISessionModal>({
    first_name: '',
    last_name: '',
    email: '',
    phone: ''
  });


  const [actiaPagination, setActiaPagination] = useState<IActiaPagination>({
    page: 0,
    per_page: 10,
    total_count: 0,
    total_pages: 0
  });

  useEffect(() => {
    getActiaListItems();
  }, []);

  useEffect(() => {
    if(actiaItems) {
      const actiaData:IActiaTableData[] = actiaItems.map((item, index) => {
        return {
          id: item.id,
          name: `${item.actia_user.first_name} ${item.actia_user.last_name}`,
          first_name: item.actia_user.first_name ,
          last_name: item.actia_user.last_name,
          email: item.actia_user.email,
          phone: item.actia_user.phone,
          vin: item.actia_user.vin && item.actia_user.vin !== '' ? item.actia_user.vin : '-',
          plate: item.actia_user.registration_plate ? String(item.actia_user.registration_plate) : '-',
          operation_type: item.actia_operation.operationTypeLabel,
          session_id: item.uid,
          date:`${new Date(item.actia_operation.createdDate).toLocaleDateString()} ${new Date(item.actia_operation.createdDate).toLocaleTimeString()}`,
        };
      });

      setActiaTableData(actiaData);
    }
  },[actiaItems]);

  const getActiaListItems = async () => {
    const params: ActiaListRequest  = {
      ktypnr: String(vehicle_id),
      vehicle_identifier: String(urlParams?.vehicle_identifier)

      // ktypnr: '270113',
      // vehicle_identifier: '002',
    };

    if(actiaPagination.page > 0) {
      params.page = actiaPagination.page;
    }

    if(actiaPagination.per_page > 0) {
      params.per_page = actiaPagination.per_page;
    }

    setLoading(true);

    await actiaHttp.getActiaList(params)
      .then((data) => {
        setActiaItems(data.data);
        setActiaPagination({
          page: data.page,
          per_page: data.per_page,
          total_count: data.total_count,
          total_pages: data.total_pages
        });
      })
      .catch((e) => {
        console.log(e);
        notify('_error');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handlePageClick = (page: number) => {
    const paginationCopy = actiaPagination;

    paginationCopy.page = page;
    setActiaPagination(paginationCopy);
    getActiaListItems();
  };

  const handleOpenModal = () => {

    setModalValues({
      first_name:'',
      last_name: '',
      email: '',
      phone:''
    });
    setOpenModal(true);
  };

  return <ActiaPageView getActiaListItems={getActiaListItems} handleOpenModal={handleOpenModal} modalValues={modalValues} setModalValues={setModalValues} openModal={openModal} setOpenModal={setOpenModal} actiaPagination={actiaPagination} handlePageClick={handlePageClick} loading={loading} data={actiaTableData}  />;
};

ActiaPage.displayName = 'DiagnosticPage';

export default ActiaPage;
