import React, { PropsWithChildren } from 'react';
import './ProductCategoryItem.scss';
import { IconType } from 'react-icons/lib';

type Props = {
  image: string | IconType;
  text: string;
  onClick: () => void;
};

const ProductCategoryItem: React.FC<Props> = (props: PropsWithChildren<Props>) => {

  return (
    <div className="product-category-item" onClick={props.onClick}>
      <div className="item-icon">
        {typeof props.image === 'string' ? (
          <img className="icon" src={props.image} width={55} height={55} alt="image" />
        ) : (
          props.image && <span> {props.image({})} </span>
        )}
      </div>
      <div className="item-text">
        <p>{props.text}</p>
      </div>
    </div>
  );
};

ProductCategoryItem.displayName = 'ProductCategoryItem';

export default ProductCategoryItem;
