
import { IBattery } from 'interfaces/battery';

import React from 'react';
import ItemEshopBatteryView from './ItemEshopBattery.view';


type Props = {
  data: IBattery[],
  loading: boolean
  openModalBattery: (id: number) => void;
  deleteItem: (id: number) => void;
  register;
  handleOnChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleOnChangeTopOffer: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleOnChangeTopSales: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const ItemEshopBattery: React.FC<Props> = (props) => {
  return (
    <ItemEshopBatteryView
      data={props.data}
      loading={props.loading}
      openModalBattery={props.openModalBattery}
      deleteItem={props.deleteItem}
      register={props.register}
      handleOnChange={props.handleOnChange}
      handleOnChangeTopOffer={props.handleOnChangeTopOffer}
      handleOnChangeTopSales={props.handleOnChangeTopSales}
    />);
};

ItemEshopBattery.displayName = ' ItemEshopBattery';

export default ItemEshopBattery;
