import { createSlice } from '@reduxjs/toolkit';
import { fetchPendingInvitation } from 'store/reducers/pending-invitations/pending-invitations.actions';


type AutomotorTariffSlice = {
pendingInvitations?;

};

const initialState: AutomotorTariffSlice = {
  pendingInvitations: null,

};

const pendingInvitations = createSlice({
  name: 'pending_invitations',
  initialState,
  reducers: {
    setPendingInvitations(state, { payload }) {
      state.pendingInvitations = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPendingInvitation.fulfilled, (state, { payload }) => {
      state.pendingInvitations = payload;
    });
  },
});

export const {setPendingInvitations} = pendingInvitations.actions;

export default pendingInvitations.reducer;
