import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import TYRE_HELP from '../../../assets/images/tyres-help.png';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import './TyresHelpModal.scss';

interface ITyresHelpProps {
  isModalOpen: boolean;
  setIsModalOpen: (isModalOpen: boolean) => void;
  dimensions?: string;
}

const TyresHelpModal: React.FC<ITyresHelpProps> = (props) => {
  const { t } = useTranslation();
  const { isMobile } = useDeviceDetect();

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
      height: 'auto',
      border: 'none',
      padding: '0',
      overflow: 'hidden',
    },
  };

  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={props.isModalOpen}
      style={customStyles}
      onRequestClose={() => props.setIsModalOpen(false)}
      overlayClassName="tyres-modal-overlay"
    >
      <div className={`tyres-modal ${isMobile ? 'mobile' : ''}`}>
        <div className="tyres-modal-header">
          <h3 className="tyres-modal-title">{t('_how_to_read_a_tyre')}</h3>
          <button type="button" className="tyres-modal-close" onClick={() => props.setIsModalOpen(false)}>
            <FontAwesomeIcon className="icon" icon={faClose} />
          </button>
        </div>
        <div className="tyres-right-container">
          <img className="tyres-help-image" src={TYRE_HELP} alt="tyre" />

          <div className="tyres-stats-arrow-1">
            <p className="tyre-stat-description">{t('_speed_rating')}</p>
            <span className="arrow-right " />
          </div>
          <div className="tyres-stats-arrow-2">
            <p className="tyre-stat-description">{t('_load_index')}</p>
            <span className="arrow-right " />
          </div>
          <div className="tyres-stats-arrow-3">
            <p className="tyre-stat-description">{t('_diameter')}</p>
            <span className="arrow-right " />
          </div>
          <div className="tyres-stats-arrow-4">
            <p className="tyre-stat-description">{t('_height')}</p>
            <span className="arrow-right " />
          </div>
          <div className="tyres-stats-arrow-5">
            <p className="tyre-stat-description">{t('_width')}</p>
            <span className="arrow-right " />
          </div>
          <svg viewBox="0 0 385 385" className="tyres-curved-stats">
            <path id="curve" d="M73.2,148.6c4-6.1,65.5-96.8,178.6-95.6c111.3,1.2,170.8,90.3,175.1,97" />
            <text width="500">
              <textPath xlinkHref="#curve">{props.dimensions !== '' ? props.dimensions : '205 / 55 R18'} 19 W</textPath>
            </text>
          </svg>
        </div>
      </div>
    </ReactModal>
  );
};

export default TyresHelpModal;
