
import React from 'react';
import './TableMoreInformationDefault.scss';

import { t } from 'i18next';


type Props = {
  row;
  hiddenColumns?
  extraFieldsNumber?: number;
}

const TableMoreInformationDefault: React.FC<Props> = (props) => {
  const extraFieldsNumber = props.extraFieldsNumber || 1;

  const moreInfo = props.hiddenColumns?.hiddenColumns.reduce((o, key) => Object.assign(o, { [key]: props.row.original[key] }), {}) ?? {};
  const colSpanLength = props.hiddenColumns ? (Object.values(props.row.original).length + extraFieldsNumber) - props.hiddenColumns.hiddenColumns?.length : 0;


  return (
    <tr className="more-information">
      <td colSpan={colSpanLength}>
        {
          Object.keys(moreInfo)?.map((key, index) => {

            return (
              <p key={index}>
                <span>{`${t('_' + key)}`}</span>: {moreInfo[key] ?? '-'}
              </p>

            );

          })

        }

      </td>

    </tr >
  );
};

export default TableMoreInformationDefault;

