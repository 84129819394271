import React from 'react';
import {ReactComponent as Oil} from 'assets/Icons/oil.svg';
import {ReactComponent as Parts} from 'assets/Icons/parts.svg';
import {ReactComponent as Tyre} from 'assets/Icons/tyre.svg';
import { ReactComponent as Battery } from 'assets/Icons/battery.svg';
import { GoTools } from 'react-icons/go';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation, createSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import useReliableCatalogCarQueryParams from '../../../utils/hooks/useReliableCatalogCarQueryParams';
import { getCurrentMarketplace } from 'utils/marketplace';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import './CatalogsTabs.scss';

type Props = {};

const CatalogsTabs: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const isDefaultRoute = pathname.endsWith('catalogs');
  const { user } = useSelector((state: RootState) => state.userData);
  const reliableCatalogCarQueryParams = useReliableCatalogCarQueryParams();
  const queryParams = createSearchParams(reliableCatalogCarQueryParams);

  const currentMarketplace = getCurrentMarketplace();
  const { isMobile, isTablet } = useDeviceDetect();

  const showPartsTab =
    (!isMobile && !isTablet) ||
    (user?.hasTyre && currentMarketplace === 'retailer') ||
    (user?.hasBattery && currentMarketplace === 'retailer') ||
    (user?.hasOil && currentMarketplace === 'retailer') ||
    (user?.hasConsumable && currentMarketplace === 'retailer');


  return (
    <div className="catalogs-tab-container">
      <div className="vehicle-page-navbar">
        {showPartsTab ? (
          <div className="navbar-item">
            {isDefaultRoute ? (
              <NavLink to={`?${queryParams}`}>
                <Parts/> <label>{t('_parts')}</label>
              </NavLink>
            ) : (
              <NavLink to={`parts?${queryParams}`} className={pathname.includes('car-parts/view') ? 'active' : ''}>
                <Parts/> <label>{t('_parts')}</label>
              </NavLink>
            )}
          </div>
        ) : null}
        {user?.hasTyre && currentMarketplace === 'retailer' && (
          <div className="navbar-item">
            <NavLink to={`tires?${queryParams}`} className={pathname.includes('tires/view') || pathname.includes('tyres/view') ? 'active' : ''}>
              <React.Fragment>
                <Tyre/>
                <label> {t('_tyre')} </label>
              </React.Fragment>
            </NavLink>
          </div>
        )}
        {user?.hasOil && currentMarketplace === 'retailer' && (
          <div className="navbar-item">
            <NavLink to={`oils?${queryParams}`} className={pathname.includes('oils/view') ? 'active' : ''}>
              <React.Fragment>
                <Oil/>
                <label> {t('_oil')}</label>
              </React.Fragment>
            </NavLink>
          </div>
        )}
        {user?.hasConsumable && currentMarketplace === 'retailer' && (
          <div className="navbar-item">
            <NavLink to={`consumables?${queryParams}`} className={pathname.includes('consumables/view') ? 'active' : ''}>
              <React.Fragment>
                <GoTools /> <label> {t('_consumables')}</label>
              </React.Fragment>
            </NavLink>
          </div>
        )}
        {user?.hasBattery && currentMarketplace === 'retailer' && (
          <div className="navbar-item">
            <NavLink to={`batteries?${queryParams}`} className={pathname.includes('batteries/view') ? 'active' : ''}>
              <React.Fragment>
                <Battery /> <label>{t('_batteries')}</label>
              </React.Fragment>
            </NavLink>
          </div>
        )}
      </div>
    </div>
  );
};

CatalogsTabs.displayName = 'CatalogsTabs';

export default CatalogsTabs;
