import { CancelToken } from 'axios';
import {
  IBodyResponseTyre,
  IBodyResponseTyreBrand,
  ITyreInfo,
  IParamsTyre,
  IParamsTyreBrand,
  ISpeedRating,
  ISeason,
  IBodyResponseTyreLoadIndex,
  ITyreFilter,
  IRefTyreSearchData,
  ITyreProductsResponse,
} from 'interfaces/tyre';

import { addRouteParam, IHttpResponse, serverAxios } from '../../http';

class TyreHttp {
  private static list = '/tyres/list/platform_admin';
  private static load_index = '/tyres/load_index';
  private static speed_rating = '/tyres/speed_rating';

  private static tyresProducts = '/tyres/products';
  private static brands = '/tyres/brand';
  private static season = '/tyres/season';
  private static info = '/tyres/';
  private static update = '/tyres/update/';
  private static updateTopOffer = '/tyres/update/:id/top_offer';
  private static updateTopSales = '/tyres/update/:id/top_sales';
  private static creat = '/tyres/register';
  private static filter = '/tyres/filters/admin-platform';
  private static tyresfilter = '/tyres/filters';
  private static delete = '/tyres/';
  private static search = '/tyres/search-dimension';
  private static deleteAll = '/tyres/delete_all';
  private static deleteSelected = '/tyres/delete_all';

  public getTyre(urlParams: IParamsTyre | {}, cancelToken: CancelToken): Promise<IHttpResponse<IBodyResponseTyre>> {
    return serverAxios.get(TyreHttp.list, { params: urlParams, cancelToken: cancelToken });
  }

  public getLoadIndex(): Promise<IHttpResponse<IBodyResponseTyreLoadIndex[]>> {
    return serverAxios.get(TyreHttp.load_index);
  }
  public getBrands(urlParams: IParamsTyreBrand): Promise<IHttpResponse<IBodyResponseTyreBrand>> {
    return serverAxios.get(TyreHttp.brands, { params: urlParams });
  }

  public getSeason(): Promise<IHttpResponse<ISeason[]>> {
    return serverAxios.get(TyreHttp.season);
  }

  public getTyreInfo(id: number | undefined): Promise<IHttpResponse<ITyreInfo>> {
    return serverAxios.get(TyreHttp.info + id);
  }

  public getTyresProducts(urlParams: IParamsTyre | {}, cancelToken?: CancelToken): Promise<IHttpResponse<ITyreProductsResponse>> {
    return serverAxios.get(TyreHttp.tyresProducts, { params: urlParams, cancelToken: cancelToken });
  }

  public getTyresFilters(urlParams: IParamsTyre | {}): Promise<IHttpResponse<ITyreFilter>> {
    return serverAxios.get(TyreHttp.tyresfilter, { params: urlParams });
  }

  public getSpeedRating(): Promise<IHttpResponse<ISpeedRating[]>> {
    return serverAxios.get(TyreHttp.speed_rating);
  }

  public postTyre(id: number, data: FormData): Promise<IHttpResponse<ITyreInfo>> {
    return serverAxios.post(TyreHttp.update + id, data);
  }

  public postTyreTopOffer(id: number, data: {}): Promise<IHttpResponse<[]>> {
    return serverAxios.post(addRouteParam(TyreHttp.updateTopOffer, { id }), data);
  }

  public postTyreTopSales(id: number, data: {}): Promise<IHttpResponse<[]>> {
    return serverAxios.post(addRouteParam(TyreHttp.updateTopSales, { id }), data);
  }

  public createTyre(data: FormData): Promise<IHttpResponse<ITyreInfo>> {
    return serverAxios.post(TyreHttp.creat, data);
  }

  public getFilter(): Promise<IHttpResponse<ITyreFilter>> {
    return serverAxios.get(TyreHttp.filter);
  }
  public deleteTyre(id: number): Promise<IHttpResponse<null>> {
    return serverAxios.delete(TyreHttp.delete + id);
  }

  public async searchTyreByRef(params: { q: string }): Promise<IRefTyreSearchData> {
    return serverAxios.get(TyreHttp.search, { params });
  }
  public deleteTyreAll(): Promise<IHttpResponse<null>> {
    return serverAxios.delete(TyreHttp.deleteAll);
  }
  public deleteTyreSelected(ids: Array<string>): Promise<IHttpResponse<null>> {
    return serverAxios.delete(TyreHttp.deleteSelected, { params: { ids: ids } });
  }
}

export default TyreHttp;
