import React, { PropsWithChildren } from 'react';
import ReactPaginate from 'react-paginate';
import './style.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';

type Props = {
  handlePageClick: (selectedItem: { selected: number }) => void;
  pageCount: number;
  forcePage?: number;
};

const PaginationView: React.FC<Props> = (props: PropsWithChildren<Props>) => {
  const { isMobile } = useDeviceDetect();

  return (
    <div className="pagination">
      <ReactPaginate
        previousLabel={isMobile ? <button type="button" className="button"> <FontAwesomeIcon icon={faChevronLeft} /></button> : '<<'}
        nextLabel={isMobile ? <button type="button" className="button"> <FontAwesomeIcon icon={faChevronRight} /></button> : '>>'}
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        pageCount={props.pageCount}
        pageRangeDisplayed={isMobile ? 1 : 4}
        marginPagesDisplayed={isMobile ? 1 : 2}
        onPageChange={props.handlePageClick}
        containerClassName="pagination-container"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        activeClassName="active"
        hrefBuilder={(page, pageCount) => (page >= 1 && page <= pageCount ? `/page/${page}` : '#')}
        hrefAllControls
        forcePage={props.forcePage}
      // onClick={(clickEvent) => {
      //   console.log('onClick', clickEvent);
      // // Return false to prevent standard page change,
      // // return false; // --> Will do nothing.
      // // return a number to choose the next page,
      // // return 4; --> Will go to page 5 (index 4)
      // // return nothing (undefined) to let standard behavior take place.
      // }}
      />
    </div>
  );
};

PaginationView.displayName = 'PaginationView';

export default PaginationView;
