import React, { ReactElement } from 'react';
import './MessagesAutomotorNavbar.scss';


type Props = {
  title: string;
  option?: ReactElement
}
const MessagesAutomotorNavbar: React.FC<Props> = (props) => {

  return (
    <div className="catalog-supplier-navbar-container-messages">
      <div></div>
      <div className="title">
        <h1>{props.title}</h1>
      </div>
      <div></div>
    </div>
  );
};

MessagesAutomotorNavbar.displayName = 'MessagesAutomotorNavbar';

export default MessagesAutomotorNavbar;