import React from 'react';
import { useTranslation } from 'react-i18next';
import './SessionExpired.scss';

type Props = {}

const SessionExpired: React.FC<Props> = () => {
  const {t} = useTranslation();
  const callback_url = sessionStorage.getItem('callback_url');

  return (
    <div className="session-expired-page">
      <div className="session-expired">{t('_session_expired')}</div>
      {callback_url && (
        <div className="home-btn">
          <a href={callback_url} >{t('_go_home')}</a>
        </div>
      )}
    </div>
  );
};

export default SessionExpired;