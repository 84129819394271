import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import useHttp from 'utils/hooks/useHttp';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import Spinner from 'components/spinner/Spinner';
import PasswordInput from 'components/inputs/PasswordInput/PasswordInput';
import '../ForgotPassword.scss';
import { useTranslation } from 'react-i18next';
import { notify } from 'utils/marketplace';
import DefaultToastContainer from 'components/toast-container/DefaultToastContainer';

type Props = {};

const RestorePassword: React.FC<Props> = () => {
  const [loading, setLoading] = useState(false);
  const http = useHttp();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [token] = useState(location.pathname.split('/')?.[2]);
  const { t } = useTranslation();

  const submitHandler = (data) => {
    setLoading(true);

    http.forgotPassword
      .restorePassword(token, { first: data.password, second: data.password_repeat })
      .then(() => {
        navigate('/login');
      })
      .finally(() => {
        setLoading(false);
      })
      .catch((err) => {
        notify(err.password.first || 'Error');
      });
  };

  return (
    <React.Fragment>
      <DefaultToastContainer />

      <div className="forgot-password-wrapper">
        <div className="content">
          <p className="description">{t('_reset_password')}</p>
          <div className="formWrapper">
            <form onSubmit={handleSubmit(submitHandler)} className="inputs">
              <label htmlFor="#password" className={`${errors.password && 'error'}`}>
                {t('_password')}
                <PasswordInput placeholder="* * * * * *" name="password" id="password" register={register} required />
                {errors.password?.type === 'required' && <p className="error-message">{t('_password_required')}</p>}
              </label>
              <label htmlFor="#password" className={`${errors.password && 'error'}`}>
                {t('_repeat_password')}
                <PasswordInput placeholder="* * * * * *" name="password_repeat" id="password" register={register} required />
                {errors.password?.type === 'required' && <p className="error-message">{t('_password_required')}</p>}
              </label>
              <DefaultButton
                text={
                  <span>
                    {loading && <Spinner class="Spinner"></Spinner>}
                    {t('_reset')}
                  </span>
                }
                disabled={loading}
                type="submit"
              ></DefaultButton>
            </form>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default RestorePassword;
