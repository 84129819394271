
import { ITBrand } from 'interfaces/tyresBrand';
import React from 'react';
import ItemEshopTyreBrandView from './ItemEshopTyreBrandView';


type Props = {
  data: ITBrand[],
  loading: boolean
  openModalTyreBrand: (id: number) => void;
  deleteItem: (id: number) => void;
  handleOnChangeIsPartner: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const ItemEshopTyreBrand: React.FC<Props> = (props) => {
  return <ItemEshopTyreBrandView data={props.data} loading={props.loading} openModalTyreBrand={props.openModalTyreBrand} deleteItem={props.deleteItem} handleOnChangeIsPartner={props.handleOnChangeIsPartner} />;
};

ItemEshopTyreBrand.displayName = ' ItemEshopTyreBrand';

export default ItemEshopTyreBrand;
