import React, { PropsWithChildren } from 'react';
import './EspaceReliableNavbarCatalogNaf.scss';
import IconTextItem from '../../../../../../../components/Item/IconTextItem/IconTextItem';
import { INavbarItem } from '../../Navbar.model';
import { useNavigate } from 'react-router-dom';
import { SwiperSlide } from 'swiper/react';
import SwiperSlider from 'components/SwiperSlider/SwiperSlider';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';

type Props = {
  items: INavbarItem[];
};

const EspaceReliableNavbarCatalogNafView: React.FC<Props> = (props: PropsWithChildren<Props>) => {
  const navigate = useNavigate();
  const { isMobile, isTablet } = useDeviceDetect(768, 1368);

  return props.items.length > 0 ? (
    <div className="navbar-catalog-naf-container">
      <div className="navbar-below">
        <div className="items">
          {(isMobile || isTablet) && props.items.length > 5 ? (
            <SwiperSlider slidesPerView={isTablet ? 4 : 'auto'} loop>
              {props.items.map((item: INavbarItem, index: number) => {
                if (item.hidden != undefined && item.hidden) {
                  return null;
                }

                return (
                  <SwiperSlide key={index} className="swiper-slide swiper-navbar-below-item">
                    <IconTextItem
                      disabled={item.disabled}
                      active={item.link === location.pathname}
                      text={item.text}
                      icon={item.icon}
                      onClick={() => {
                        navigate(item.link);
                      }}
                      key={index}
                    />
                  </SwiperSlide>
                );
              })}
            </SwiperSlider>
          ) : (
            props.items.map((item: INavbarItem, index: number) => {
              if (item.hidden != undefined && item.hidden) {
                return null;
              }

              return (
                <IconTextItem
                  disabled={item.disabled}
                  active={item.link === location.pathname}
                  text={item.text}
                  icon={item.icon}
                  onClick={() => {
                    navigate(item.link);
                  }}
                  key={index}
                />
              );
            })
          )}
        </div>
      </div>
    </div>
  ) : null;
};

EspaceReliableNavbarCatalogNafView.displayName = 'EspaceReliableNavbarCatalogNafView';

export default EspaceReliableNavbarCatalogNafView;
