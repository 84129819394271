import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { INotificationResponse } from 'utils/hooks/http/notifications.http';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBox, faDownload, faFileDownload, faTags, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { getMarketplaceBaseUrl, notify } from 'utils/marketplace';
import './NotificationItem.scss';
import useHttp from 'utils/hooks/useHttp';
import moment from 'moment';
import Spinner from 'components/spinner/Spinner';

type Props = {
    data: INotificationResponse;
    markAsRead: (id: number) => void
    handleClick
}

const NotificationItem:React.FC<Props> = (props: Props) => {
  const [loading, setLoading] = useState(false);
  const {t} = useTranslation();
  const http = useHttp();

  function download(id?){
    if(props.data.notification.toLocaleLowerCase() === 'tariff download'){
      setLoading(true);
  
      http.automotorTariff
        .getFile(id, {isNotification: true})
        .then((res) => {
          const element = document.createElement('a');
  
          const file = new Blob([res.data], { type: res.data.type });
  
          element.href = URL.createObjectURL(file);
          element.download = props.data.notification + `_${moment().format('MM-DD-YY')}`;
          element.click();
        })
        .catch(() => {
          window.scrollTo({ top: 0, behavior: 'smooth' });
          notify(t('_error'));
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      const url =  getMarketplaceBaseUrl() + props.data.payload.file;
      const link = document.createElement('a');

      link.href = url;
      link.setAttribute('download', props.data.payload.original_file);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  return (
    <div className={`notification-item-container ${!props.data.seen ? 'unread' : ''}`} >
      <div className="notification-icon-container">
        <div className="notification-info-icon"> 
          {props.data.notification.toLocaleLowerCase() === 'order' && <FontAwesomeIcon className="notification-icon" icon={faBox}/>}
          {props.data.notification.toLocaleLowerCase() === 'wrong mapping' && <FontAwesomeIcon className="notification-icon" icon={faXmarkCircle}/>}
          {props.data.notification.toLocaleLowerCase() === 'tariff' && <FontAwesomeIcon className="notification-icon" icon={faTags}/>}
          {props.data.notification.toLocaleLowerCase() === 'tariff download' && <FontAwesomeIcon className="notification-icon" icon={faFileDownload}/>}
        </div>
      </div>
      <div className="notification-info">
        <div className="notification-info-text">
          <div className="notification-message">{props.data.message}</div>
          <div className="notification-date">{props.data.created_at}</div>
        </div>
        <div className="notification-actions">
          {props.data.payload.file && <div className="notification-file" >
            <span className="notification-download-icon">{loading ? <Spinner class="extra-small download-notification-spinner"/> :<FontAwesomeIcon icon={faDownload} onClick={() => props.data.notification.toLocaleLowerCase() === 'tariff download' ? download(props.data.payload.file) : download()}/>}</span>
            <span className="notification-filename">{props.data.payload.original_file || t('_file')}</span>
          </div>}
          <div className="notifications-btns">
            <div className="notification-action" onClick={() => props.handleClick(props.data)}>{t('_read')}</div>
            {!props.data.seen && (
              <div className="notification-action" onClick={() => props.markAsRead(props.data.id)}>{t('_mark_as_read')}</div>
            )}
          </div>
        </div>
      </div>
  
    </div>
  );
};

export default NotificationItem;