import { useEffect, useState } from 'react';

export const useMobileDetect = (defaultWidth = 768): boolean => {
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth <= defaultWidth,
  });

  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        width: window.innerWidth <= defaultWidth,
      });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return dimensions.width;
};