import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useHttp from 'utils/hooks/useHttp';
import { notify } from 'utils/marketplace';
import { AxiosError } from 'axios';
import Spinner from 'components/spinner/Spinner';
import TyrePhotoGallery from './TyresPhotoGallery/TyrePhotoGallery';
import TyreDetails from './TyresDetails/TyreDetails';
import TyreOptions from './TyresOptions/TyresOptions';
import './TyreView.scss';
import { ITyre } from 'interfaces/tyre';
import { useTranslation } from 'react-i18next';

const TyreView: React.FC = () => {
  const http = useHttp();
  const { id } = useParams<'id'>();
  const { i18n } = useTranslation();

  const [tyre, setTyre] = useState<ITyre>();
  const [loading, isLoading] = useState(false);

  useEffect(() => {
    getTyre();
  }, [i18n.language]);

  const getTyre = async () => {
    isLoading(true);

    try {
      await http.rmiTyresHttp.getTyreById(String(id)).then((res) => setTyre(res.data));
    } catch (e: unknown) {
      if (e instanceof AxiosError) {
        const message = e?.response?.data?.detail;

        notify(message);
      }
    } finally {
      isLoading(false);
    }
  };

  return (
    <div className="tyre-wrapper">
      {loading ? (
        <Spinner class="small" />
      ) : (
        <div>
          {tyre && (
            <div>
              <div className="tyre">
                <div className="gallery">
                  <TyrePhotoGallery tyre={tyre} />
                </div>
                <div className="details">
                  <TyreDetails tyre={tyre} />
                </div>
                <div className="options">
                  <TyreOptions tyre={tyre} />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default TyreView;
