import React from 'react';
import './ErrorCodes.scss';
import Spinner from 'components/spinner/Spinner';
import SidebarDetails from '../../SidebarDetails/SidebarDetails';
import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IManuals } from 'utils/rmi/interface/manuals.interface';
import { IVehicleManual } from 'interfaces/vehicle-manuals.interface';
import { IErrorCodesNavLinks } from '../../../ErrorCodes/ErrorCodesNavLinks/errorCodes';
import ErrorCodesSearch from '../../../ErrorCodes/ErrorCodesSearch';
import ErrorCodesNavLinks from '../../../ErrorCodes/ErrorCodesNavLinks/ErrorCodesNavLinks';
import {ISearchLinks} from './Solutions/Solutions';

type Props = {
  loadingList: boolean;
  sidebarDetails: number;
  manuals?: IVehicleManual[];
  onManualClick: (manual: IVehicleManual) => void;
  onReturnClick: () => void;
  activManual?: IManuals;
  searchLinks: ISearchLinks;
  navLinks: IErrorCodesNavLinks[];
};

const ErrorCodesView: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();
  const searchLinks = props.searchLinks;

  return (
    <div className="error-codes-layout">
      {props.loadingList ? <Spinner class="extra-small" /> : (
        <React.Fragment>
          <div className="tdoc-sidebar error-codes-sidebar">
            {props.sidebarDetails === 1 &&
              <SidebarDetails
                title={`<span>${t('_error_codes')}</span>`}
                itemId="ManualId"
                itemName="QualColText"
                onClick={props.onManualClick}
                data={props.manuals}
                onReturnClick={props.onReturnClick}
                activeItem={props.activManual?.ManualId}
              />}
          </div>
          <div className="tdoc-content container-fluid-medium">
            <ErrorCodesSearch searchLinks={props.searchLinks} />
            <ErrorCodesNavLinks navLinks={props.navLinks} />
            <Outlet context={{searchLinks}} />
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

ErrorCodesView.displayName = 'ErrorCodesView';

export default ErrorCodesView;