import React, {useEffect, useState} from 'react';
import { Outlet } from 'react-router-dom';
import { useHasTecrmiTabs } from 'utils/hooks/useHasTecrmiTabs';
import { INITIAL_TABS_STATE } from 'utils/rmi/reliableTabs';
import {useSelector} from 'react-redux';
import {RootState} from 'store/store';

const TechnicalDocumentationLayout = (): JSX.Element => {
  
  const { typeId } = useSelector((state: RootState) => state.tecrmi);
  const [hasTab, setHasTab] = useState(INITIAL_TABS_STATE);
  const {hasTecRMITabs} = useHasTecrmiTabs();

  const fetchRMI = async (): Promise<void> => {
    if (typeId) {
      const result = await hasTecRMITabs(typeId);

      setHasTab(prev => ({
        ...prev,
        ...result,
      }));
    }
  };

  useEffect(()=>{
    fetchRMI();
  },[typeId]);

  return (
    <Outlet context={[hasTab]} />
  );
};

export default TechnicalDocumentationLayout;