
import { CancelToken } from 'axios';
import { IParamsCategory, IBodyResponseCategory, ICategory } from 'interfaces/consumableCategory';


import {IHttpResponse, serverAxios } from '../../http';

class ConsumableCategoryHttp {

  private static list = '/consumable/category';
  private static info = '/consumable/category/';
  private static update = '/consumable/update/category/';
  private static create = '/consumable/register/category';
  private static delete = '/consumable/category/';

  public  getList(urlParams:IParamsCategory , cancelToken: CancelToken): Promise<IHttpResponse<IBodyResponseCategory>> {
    return  serverAxios.get(ConsumableCategoryHttp.list ,{ params: urlParams , cancelToken:cancelToken } );
  }


  public  getCategoryInfo(id:number|undefined): Promise<IHttpResponse<ICategory>> {
    return   serverAxios.get(ConsumableCategoryHttp.info+ id);
  }

  public  edit(id:number ,data:FormData): Promise<IHttpResponse<ICategory>> {
    return   serverAxios.post(ConsumableCategoryHttp.update+id, data);
  }

  public  create(data:FormData): Promise<IHttpResponse<ICategory>> {
    return   serverAxios.post(ConsumableCategoryHttp.create, data);
  }

  public  delete(id:number): Promise<IHttpResponse<null>> {
    return   serverAxios.delete(ConsumableCategoryHttp.delete+ id);
  }
}

export default ConsumableCategoryHttp;
