import { HttpResponse } from 'utils/hooks/http/history.http';
import { tecRMIAxios } from '../../http';
import { IBoxDetailList, IComponentsForType, ILinkedWiringDiagrams } from '../interface/relays-fuses';

class RelaysFusesHttp {

  private static ComponentsForType = '/RelaysFuses/ComponentsForType';
  private static BoxOverviewHtml = '/RelaysFuses/BoxOverviewHtml';
  private static BoxDetailList = '/RelaysFuses/BoxDetailList';
  private static BoxDetailHtml = '/RelaysFuses/BoxDetailHtml';
  private static LinkedWiringDiagrams = '/RelaysFuses/LinkedWiringDiagrams';


  public async fetchRMIComponentsForType(params: {}): Promise<HttpResponse<IComponentsForType[]>> {
    return tecRMIAxios.get(RelaysFusesHttp.ComponentsForType, { params });
  }

  public async fetchRMIBoxOverviewHtml(params: {}): Promise<HttpResponse<string>> {
    return tecRMIAxios.get(RelaysFusesHttp.BoxOverviewHtml, { params });
  }

  public async fetchRMIBoxDetailList(params: {}): Promise<HttpResponse<IBoxDetailList>> {
    return tecRMIAxios.get(RelaysFusesHttp.BoxDetailList, { params });
  }

  public async fetchRMIBoxDetailHtml(params: {}): Promise<HttpResponse<string>> {
    return tecRMIAxios.get(RelaysFusesHttp.BoxDetailHtml, { params });
  }


  public async fetchRMILinkedWiringDiagrams(params: {}): Promise<HttpResponse<ILinkedWiringDiagrams>> {
    return tecRMIAxios.get(RelaysFusesHttp.LinkedWiringDiagrams, { params });
  }


}

export default RelaysFusesHttp;