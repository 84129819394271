import { GetAllServerResponse } from 'interfaces/common';
import { IHttpResponse, serverAxios } from '../../http';


class NotificationsHttp {
  private static route = '/notification';
  private static seen = '/seenUpdate';

  public async getNotifications(params?: Params): Promise<INotificationsData> {
    const { data } = await serverAxios.get(NotificationsHttp.route, { params });

    return data;
  }
  public async markAsRead(params?: IMarkAsReadParams): Promise<{}> {
    const { data } = await serverAxios.post(NotificationsHttp.seen, { ...params });

    return data;
  }
}

export type INotificationsData = GetAllServerResponse<INotificationResponse>

export type INotificationResponse = {
 id: number;
 notification: string;
 message: string;
 payload: {
  order: string;
  email: string | null;
  proforma: string;
  estimationId: string | null;
  proformaId: string;
  original_file: string;
  file: string;
 }
 seen: boolean;
 created_at: string;
};

export type Params = {
  icon_notification?: boolean;
  per_page: number;
  page: number;
};

export type IMarkAsReadParams = {
  seen_ids: Array<number>
};

export default NotificationsHttp;
