import { IItemClick } from 'models/catalog.model';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { IReliableRoute } from 'utils/rmi/reliableTabs';

import './List.scss';
import ButtonLink from './SidebarItem/ButtonLink/ButtonLink';

type Props = {
  title?: string;
  content?;
  images?: string[];
  className?: string;
  itemClassName?: string;
  itemLink?: (item: IItemClick) => string;
  showPdf?: boolean;
  hasTab: { [key: string]: boolean };
};

const List: React.FC<Props> = (props: Props) => {
  const { user } = useSelector((state: RootState) => state.userData);
  const { t } = useTranslation();

  const renderIcon = (item: IReliableRoute): JSX.Element => {
    const Icon = item.icon;

    return <Icon />;
  };

  return (
    <div className="sidebar-list-container">
      {props.title && <h1 className={`list-heading ${props.className}`}>{props.title}</h1>}
      {props.content &&
        props.content.map((item, index: number) => {
          if (!item.subscription_type.includes(user?.client_rmi_modules_type)) {
            return;
          }

          return (
            <ButtonLink
              disabled={!props.hasTab[item.tab_name]}
              text={t(item.title)}
              key={index}
              itemLink={props.itemLink?.(item)}
              className={`check ${props.itemClassName}`}
              icon={renderIcon(item)}
            ></ButtonLink>
          );
        })}
    </div>
  );
};

export default List;
