import icons from 'assets/Icons';
import Icon from 'components/Icon/Icon';
import React from 'react';

import './MenuItem.scss';

type Props = {
  icon: keyof typeof icons;
  text: string;
  className?: string;
};

const MenuItemView: React.FC<Props> = (props: Props) => {
  return (
    <div className={`menu-item-wrapper ${props.className || ''}`.trim()}>
      <div className="iconWrapper">
        <Icon name={props.icon} className="icon" />
      </div>
      <div className="text">{props.text}</div>
    </div>
  );
};

MenuItemView.displayName = 'MenuItemView';

export default MenuItemView;
