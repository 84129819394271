import SkeletonSpinner from 'components/skeleton-spinner/SkeletonSpinner';
import React, { MutableRefObject, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { createSearchParams, generatePath, useNavigate } from 'react-router-dom';
import { useOnClickOutside } from 'utils/hooks/useOnClickOutside';
import { getBackendAssetsBasePath } from 'utils/marketplace';
import './ConsumableDropdownContent.scss';
import { IConsumableSearch } from 'interfaces/consumable';
import Head from 'components/tables/Cells/Head/Head';
import { Column } from 'react-table';
import DropdownContentTable from 'components/DropdownContentTable/DropdownContentTable';
import { isRetailerMecadepotChild } from 'utils/isRetailerMecadepotChild';

type Props = {
  data: IConsumableSearch[] | undefined;
  setIsOpen: (isOpen: boolean) => void;
  loading: boolean;
  consumableValue?: string;
};

const ConsumableDropdownContent: React.FC<Props> = (props) => {
  const consumableDropdownRef = useRef() as MutableRefObject<HTMLDivElement>;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMecadepotChild = isRetailerMecadepotChild();

  useOnClickOutside(consumableDropdownRef, () => props.setIsOpen(false));

  const tableData: Column<IConsumableSearch>[] =
    isMecadepotChild ?
      [
        {
          Header: <Head text=""></Head>,
          Cell: (cell) => (
            <img src={cell.cell.row.original.image ? `${getBackendAssetsBasePath()}/${cell.cell.row.original.image}` : '/images/car-part-placeholder.png'} width={30} height={30} alt="oil" onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = '/images/car-part-placeholder.png';
            }}
            />)
          ,
          accessor: 'image',
        },
        {
          Header: <Head text={t('_description').toLocaleUpperCase()}></Head>,
          accessor: 'description',
        },
      ] :
      [
        {
          Header: <Head text=""></Head>,
          Cell: (cell) => (
            <img src={cell.cell.row.original.image ? `${getBackendAssetsBasePath()}/${cell.cell.row.original.image}` : '/images/car-part-placeholder.png'} width={30} height={30} alt="oil" onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = '/images/car-part-placeholder.png';
            }}
            />)
          ,
          accessor: 'image',
        },
        {
          Header: <Head text={t('_conditioning').toLocaleUpperCase()}></Head>,
          accessor: 'conditioning',
        },
        {
          Header: <Head text={t('_reference').toLocaleUpperCase()}></Head>,
          accessor: 'reference',
        },
        {
          Header: <Head text={t('_category').toLocaleUpperCase()}></Head>,
          accessor: 'category_name',
        },
      ];

  function handleConsumableClick(item) {
    const params = {
      page: '1',
      category_ids: item.category_id,
      brand_id: item.brand_id,
      consumable_id: item.consumable_id,
      q: props.consumableValue
    };

    const validParams = Object.entries(params).reduce((acc, [key, value]) => {
      if (!value) {
        return acc;
      }

      acc[key] = value;

      return acc;
    }, {});

    navigate({
      pathname: generatePath('/consumables'),
      search: `?${createSearchParams(validParams)}`,
    });
    props.setIsOpen(false);
  }

  // function handleConsumableCategoryClick(e, item) {
  //   e.stopPropagation();
  //   const params ={
  //     page: '1',
  //     category_ids: item.category_id,
  //     q: props.consumableValue
  //   };

  //   const validParams = Object.entries(params).reduce((acc, [key, value]) => {
  //     if (!value) {
  //       return acc;
  //     }

  //     acc[key] = value;

  //     return acc;
  //   }, {});

  //   navigate({
  //     pathname: generatePath('/consumables'),
  //     search: `?${createSearchParams(validParams)}`,
  //   });
  //   props.setIsOpen(false);
  // }

  return (
    <div ref={consumableDropdownRef} className="consumable-dropdown-content">
      <div className="consumable-dropdown-header">
        <div className="consumable-dropdown-title">{t('_search_result')}</div>
      </div>
      <div className="consumable-search-result-content">
        {props.loading ? (
          <SkeletonSpinner />
        ) : (
          <React.Fragment>
            {props.data?.length ? (
              <DropdownContentTable columns={tableData} itemClick={handleConsumableClick} data={props.data} loading={props.loading} />

              // props.data?.map((item, index) => {
              //   if (!item.consumable_id) {
              //     return;
              //   }

              //   return (
              //     <li key={index} className="suggestion-list-item-consumable">
              //       <div className="suggestion-item-title">
              //         <div className="suggestion-item-ref" onClick={() => handleConsumableClick(item)}>
              //           <img src={`${getBackendAssetsBasePath()}/${item.image}`} alt="consumable"></img>
              //           <div>
              //             <span>{item.conditioning}</span>
              //             <span>{item.reference}</span>
              //           </div>
              //         </div>
              //         <span onClick={() => handleConsumableCategoryClick(item)}>{item.category_name}</span>
              //       </div>
              //     </li>
              //   );
              // })
            ) : (
              <div className="no-data">{t('_no_data').toLocaleUpperCase()}</div>
            )}
            { }
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default ConsumableDropdownContent;
