import { ICarInformation, ICarVehicle } from 'models/cart.model';

export function getVehicleDescription(data: ICarInformation, car: ICarVehicle): string {

  const { manufacturer_name, kw_power, horse_power } = data;
  const { engineCapacity, capacityQualifier, startMonth, startYear, endYear, endMonth, engine_capacity, power_kw_from, power_hp_from, start_month, start_year, end_month, end_year } = car;
  let vehicleDescription = '';

  if (manufacturer_name) {
    vehicleDescription += manufacturer_name + ' ';
  }

  if (engineCapacity && capacityQualifier) {
    vehicleDescription += engineCapacity + ' ' + capacityQualifier + ' cm3 ';
  } else if (capacityQualifier) {
    vehicleDescription += capacityQualifier + ' cm3 ';
  } else if (engineCapacity) {
    vehicleDescription += engineCapacity + ' cm3 ';
  }

  if (engine_capacity) {
    vehicleDescription += engine_capacity + ' cm3 ';
  }

  if (kw_power) {
    vehicleDescription += kw_power + ' KW ';
  }

  if (power_kw_from) {
    vehicleDescription += power_kw_from + ' KW ';
  }

  if (horse_power) {
    vehicleDescription += horse_power + ' CH DIN ';
  }

  if (power_hp_from) {
    vehicleDescription += power_hp_from + ' CH DIN ';
  }

  if (startMonth && startYear && endMonth && endYear) {
    // if(start_month.toString.length > 2 ){
    // start_month.toString.slice(0, 1);}

    // if( end_month.toString.length > 2){
    // end_month.toString.slice(0, 1);}

    vehicleDescription += `(${startMonth}/${startYear} - ${endMonth}/${endYear})`;
  } else if (startMonth && startYear) {
    vehicleDescription += `(${startMonth}/${startYear})`;
  }

  if (start_month && start_year && end_month && end_year) {

    vehicleDescription += `(${start_month}/${start_year} - ${end_month}/${end_year})`;
  } else if (start_month && start_year) {
    vehicleDescription += `(${start_month}/${start_year})`;
  }

  return vehicleDescription;
}
