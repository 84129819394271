import React, { PropsWithChildren } from 'react';
import DefaultButtonView from './DefaultButton.view';

type Props = {
  text: string | JSX.Element;
  onClick?: (e) => void;
  type?: 'button' | 'submit' | 'reset' | undefined;
  isloading?: boolean
  disabled?: boolean;
  red?: boolean
  blue?: boolean
  className?: string
};

const DefaultButton: React.FC<PropsWithChildren<Props>> = (props: PropsWithChildren<Props>) => {
  return <DefaultButtonView {...props}>{props.children}</DefaultButtonView>;
};

DefaultButton.displayName = 'DefaultButton';

export default DefaultButton;
