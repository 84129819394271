import React, { ReactElement, useEffect, useState } from 'react';
import TabTitle from './TabTitle';
import './TabStyle.scss';
import { FieldValues, UseFormResetField } from 'react-hook-form';

type Props = {
  children: Array<ReactElement | null>;
  resetField?: UseFormResetField<FieldValues>;
};

const Tabs: React.FC<Props> = (props) => {
  const [selectedTab, setSelectedTab] = useState(0);


  useEffect(() => {
    if (props.resetField) {
      props.resetField('oil');
      props.resetField('consumable');
      props.resetField('tyre');
      props.resetField('battery');
    }

  }, [selectedTab]);


  return (
    <div className="vehicle-selector-tabs-container">
      <div className="tabs">
        {props.children.map(
          (item, index) =>
            item && (
              <TabTitle key={index} title={item.props.title} index={index} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
            ),
        )}
      </div>
      {props.children[selectedTab]}
    </div>
  );
};

Tabs.displayName = 'Tabs';

export default Tabs;
