import React, { PropsWithChildren } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useTranslation } from 'react-i18next';
import './IconTextItem.scss';

type Props = {
  icon: IconProp;
  text: string;
  onClick: () => void;
  active?: boolean
  disabled?: boolean
  number?: number
};

const IconTextItem: React.FC<Props> = (props: PropsWithChildren<Props>) => {
  const { t } = useTranslation();

  return (
    <div className={`navbar-item-container ${props.disabled ? 'disabled' : ''}`}>
      <div className={`icon-item ${props.active ? 'active' : ''}`} onClick={props.onClick}>
        <div className="item-icon">
          {
            props.number && props.number > 0 ? (
              <span className="notification">{props.number}</span>
            ) : null
          }
          <FontAwesomeIcon icon={props.icon} className="icon" />
        </div>
        <div className="item-text">
          <div className="item-label">{t(props.text)}</div>
        </div>
      </div>
    </div>
  );
};

IconTextItem.displayName = 'IconTextItem';

export default IconTextItem;
