import { AxiosInstance, AxiosRequestConfig } from 'axios';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { CONSUMABLES_GROUP_ID } from 'utils/constants/defaultConstants';
import { ROLE_CLIENT_PLATFORM } from 'utils/constants/roles';


const useModuleRequestStatistics = (request: AxiosRequestConfig, serverAxios: AxiosInstance): void => {
  const user = JSON.parse(localStorage.getItem('userData')!);
  const checkPermission = user?.roles.includes(ROLE_CLIENT_PLATFORM);

  if (checkPermission) {
    if (!request.url!.includes('Has')) {
      switch (request.url!.split('/')[1]) {
        case 'Times':
          serverAxios.post('/retailer/statistics', { ...request.params, ...{ module_name: 'Repair Items' } });

          break;

        case 'Maintenance':
          serverAxios.post('/retailer/statistics', { ...request.params, ...{ module_name: 'Maintenance' } });

          break;
        case 'Adjust':
          if (request.params.mainGroupId == CONSUMABLES_GROUP_ID) {
            serverAxios.post('/retailer/statistics', { ...request.params, ...{ module_name: 'Consumables' } });
          } else {
            serverAxios.post('/retailer/statistics', { ...request.params, ...{ module_name: 'Adjust' } });
          }

          break;
        case 'Manuals':
          serverAxios.post('/retailer/statistics', { ...request.params, ...{ module_name: 'Technical Manuals' } });

          break;
        case 'Tyres':
          serverAxios.post('/retailer/statistics', { ...request.params, ...{ module_name: 'Tyres' } });

          break;
        case 'Bulletins':
          serverAxios.post('/retailer/statistics', { ...request.params, ...{ module_name: 'Service Newsletter (SOS)' } });

          break;
        case 'RelaysFuses':
          serverAxios.post('/retailer/statistics', { ...request.params, ...{ module_name: 'Relays/Fuses' } });

          break;
        case 'Components':
          serverAxios.post('/retailer/statistics', { ...request.params, ...{ module_name: 'Components Position' } });

          break;
        case 'Diagnostics':
          serverAxios.post('/retailer/statistics', { ...request.params, ...{ module_name: 'Diagnostics' } });

          break;
        case 'Wiring':
          serverAxios.post('/retailer/statistics', { ...request.params, ...{ module_name: 'Wiring Diagrams' } });

          break;
        case 'Graphics':
          serverAxios.post('/retailer/statistics', { ...request.params, ...{ module_name: 'Graphical Selection' } });

          break;
      }

    }
  }


};

export default useModuleRequestStatistics;