import React, { PropsWithChildren, ReactElement, useEffect } from 'react';
import ReactModal from 'react-modal';
import ModalView from './Modal.view';
import { contentStyle } from './ContentStyleModal';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import useLocalStorage from 'use-local-storage';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const { $ } = global;

type Props = {
  openModal?: boolean;
  childrenComponent: ReactElement | null;
  setOpenModal?: (e: boolean) => void;
  onRequestClose?: () => void,
  contentStyle?: object;
  customCssClass?: string;
  setTabIndex?: (number) => void
  getSuppliers?
  shouldCloseOnOverlayClick?: boolean | undefined
};

ReactModal.setAppElement(document.getElementById('root') || '');

const Modal: React.FC<Props> = (props: PropsWithChildren<Props>) => {
  const customStyles = props.contentStyle ? props.contentStyle : contentStyle;
  const shouldCloseOnOverlayClick = props.shouldCloseOnOverlayClick != undefined ? props.shouldCloseOnOverlayClick : true;
  const { isMobile, isTablet } = useDeviceDetect();
  const { user } = useSelector((state: RootState) => state.userData);


  function closeModal() {
    // props.setOpenModal!(false);
    props.setOpenModal && props.setOpenModal(false);
    props.onRequestClose && props.onRequestClose();
    props.setTabIndex && props.setTabIndex!(0);
    props.getSuppliers && props.getSuppliers();
  }


  useEffect(() => {
    const newTheme = user?.ui_theme ? user.ui_theme : 'theme-default';

    $('.ReactModalPortal').addClass(newTheme);

    if (newTheme === 'theme-default') {
      $('.ReactModalPortal').removeClass('theme-mecadepot');
    }

    if (newTheme === 'theme-mecadepot') {
      $('.ReactModalPortal').removeClass('theme-default');
    }
  }, [user]);


  return (

    <ReactModal
      isOpen={props.openModal!}
      onRequestClose={closeModal}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      style={customStyles}
      className={` ${props.customCssClass} ${isMobile ? 'ReactModal__Mobile' : isTablet ? 'ReactModal__Tablet' : 'ReactModal__Desktop'}`}
    >
      <ModalView childrenComponent={props.childrenComponent} isMobile={isMobile} isTablet={isTablet} />
    </ReactModal>

  );
};

Modal.displayName = 'Modal';

export default Modal;
