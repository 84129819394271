import React, { ChangeEvent, useContext } from 'react';
import Spinner from 'components/spinner/Spinner';
import { useTranslation } from 'react-i18next';
import { HeaderGroup, Row, TableBodyPropGetter, TableBodyProps, TablePropGetter, TableProps } from 'react-table';

import './CartTable.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store/store';
import TableMoreInformation from 'components/tables/TableMoreInformation/TableMoreInformation';
import { CartTableItem } from './CartTable.model';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import { CartTableContext, ICartTableContext } from '../../../../../context/CartTableContext';
import CURRENCY from 'utils/constants/currency';


type Props = {
  hiddenColumns: string[];
  getTableProps: (propGetter?: TablePropGetter<CartTableItem>) => TableProps;
  headerGroups: HeaderGroup<CartTableItem>[];
  prepareRow: (row: Row<CartTableItem>) => void;
  rows: Row<CartTableItem>[];
  getTableBodyProps: (propGetter?: TableBodyPropGetter<CartTableItem>) => TableBodyProps;
  loading: boolean;
  handleCheckout?: () => void;
  handleSelectAll: (event: ChangeEvent<HTMLInputElement>) => void;
  handleDeleteAll?: () => void;
  totalPartsPrice: number;
  quantityToolbar: number;
};

const CartTableView: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();
  const user = JSON.parse(localStorage.getItem('userData')!);
  const { quantityToolbar } = useSelector((state: RootState) => state.cart);
  const { isMobile, isTablet } = useDeviceDetect();
  const { selectAll, selectedData } = useContext(CartTableContext) as ICartTableContext;

  return (
    <div className={`cart-table-container-automotor  ${!isMobile && !isTablet ? 'container-fluid-large' : ''}`}>
      {props.loading ? (<Spinner class="Spinner"></Spinner>)
        : (
          props.rows.length >= 0 ? (
            <React.Fragment>
              <div className="cart-actions-wrapper">
                <label className="checkbox-container">
                  <input type="checkbox" checked={selectAll} onChange={(event) => props.handleSelectAll(event)} />
                  <span className="link">{t('_select_all')}</span>
                </label>
                {
                  selectedData.length > 0 && (
                    <div className="cart-actions-buttons">
                      <span className="link" onClick={props.handleDeleteAll}>{t('_delete_selected')}</span>
                    </div>
                  )
                }
              </div>
              <div className="cart-table-wrapper">
                <table {...props.getTableProps()}>
                  <thead className="headings">
                    {props.headerGroups.map((headerGroup, index) => {
                      return (
                        <tr key={headerGroup.headers[index].id} className="row">
                          {headerGroup.headers.map((column) => {
                            const { key, ...restColumn } = column.getHeaderProps({
                              style: { minWidth: column.minWidth, width: column.width, maxWidth: column.maxWidth },
                            });

                            return (
                              <th key={key} {...restColumn} className="heading">
                                {column.render('Header')}
                              </th>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </thead>
                  <tbody {...props.getTableBodyProps()} className="body">
                    {props.rows.map((row) => {
                      props.prepareRow(row);
                      const { key, ...restRowProps } = row.getRowProps();


                      return (
                        <React.Fragment key={key + row.original.reference}>
                          <tr key={key + row.original.reference} {...restRowProps} className="row">
                            {row.cells.map((cell) => {
                              const { key, ...restCellProps } = cell.getCellProps();


                              return (
                                <td key={key + row.original.reference} {...restCellProps} className="cell">
                                  {cell.render('Cell')}
                                </td>
                              );
                            })}
                          </tr>
                          {row.isExpanded ? TableMoreInformation({ row, hiddenColumns: props.hiddenColumns, extraFieldsNumber: 2 }) : null}
                        </React.Fragment>
                      );
                    })}
                  </tbody>
                  <tfoot className="footer">
                    <tr className="row">
                      <td className="cell" colSpan={isMobile ? 3 : 4}></td>
                      <td className="cell"><span className="total-qty">{quantityToolbar}</span></td>
                      <td className="cell"><span>{CURRENCY[user!.currency]}{Number(props.totalPartsPrice).toFixed(2)} TTC</span></td>
                      <td className="cell" colSpan={1}></td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </React.Fragment>
          ) : (t('_no_items_in_cart'))
        )}
    </div>

  );
};

CartTableView.displayName = 'CartTableView';

export default CartTableView;
