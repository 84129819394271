
import React from 'react';

import { ICategory } from 'interfaces/consumableCategory';
import ItemEshopBatteryCategoryView from './ItemEshopConsumableCategoryView';

type Props = {
  data: ICategory[],
  loading: boolean
  openModalBatteryCategory: (id: number) => void;
  deleteItem: (id: number) => void
};

const ItemEshopBatteryCategory: React.FC<Props> = (props) => {
  return <ItemEshopBatteryCategoryView data={props.data} loading={props.loading} openModalBatteryCategory={props.openModalBatteryCategory} deleteItem={props.deleteItem} />;
};

ItemEshopBatteryCategory.displayName = ' ItemEshopConsumableCategory';

export default ItemEshopBatteryCategory;
