import React from 'react';
import { useTranslation } from 'react-i18next';
import '../Solutions.scss';
import { IEinaTechnotes } from 'utils/eina/interface/eina.interface';
import Spinner from 'components/spinner/Spinner';

type Props = {
  loading: boolean;
  technotes: IEinaTechnotes[];
  handleFetchTechnote: (id: number) => void;
};

const SolutionsList: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();

  return (
    props.loading ? <Spinner class="extra-small" /> : (
      <ul className="technotes-list">
        {props.technotes.length > 0 ?
          props.technotes.map((technote, index) => (
            <li className="technotes-item" key={index} onClick={() => props.handleFetchTechnote(technote['id'])}>
              <div className="error-code">
                {technote['dtc error codes'] && String(technote['dtc error codes']?.join(', '))}
              </div>
              <div className="error-description">
                {technote['symptom']}
              </div>
            </li>
          )) : <div className="no-data">{t('_no_data')}</div>}
      </ul>
    )
  );
};

SolutionsList.displayName = 'SolutionsList';

export default SolutionsList;
