import React from 'react';
import './PriceQuantityCell.scss';
import CURRENCY from 'utils/constants/currency';
import { useTranslation } from 'react-i18next';
import { isRetailerMecadepotChild } from 'utils/isRetailerMecadepotChild';

type Props = {
  value: number;
  priceVAT?: number;
};

const PriceQuantityCellView: React.FC<Props> = (props: Props) => {
  const user = JSON.parse(localStorage.getItem('userData')!);
  const { t } = useTranslation();
  const isMecadepotChild = isRetailerMecadepotChild();

  return (
    <div className="price-cell-wrapper">
      <div className="price-cell">
        {isMecadepotChild ?
          <span >
            HT  {props.value}
            {`  ${CURRENCY[user!.currency]}`}
          </span>
          :
          <span>
            {CURRENCY[user!.currency]}
            HT  {props.value}
          </span>
        }
        {props.priceVAT && Number(props?.priceVAT) ? (
          <React.Fragment>
            <div className="divider" />
            <div className="vat-price">
              {Number(props?.priceVAT).toFixed(2)} {props?.priceVAT ? CURRENCY[user!.currency] : ''}
              <div className="vat-label">{t('_price_with_vat')}</div>
            </div>
          </React.Fragment>
        ) : null}
      </div>
    </div >
  );
};

PriceQuantityCellView.displayName = 'PriceCellView';

export default PriceQuantityCellView;
