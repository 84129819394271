import React from 'react';
import { ReactComponent as Error500 } from 'assets/images/500.svg';
import './ErrorPage500.scss';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

type Prop = {};

const ErrorPage500: React.FC<Prop> = () => {
  const navigate = useNavigate();
  const {t} = useTranslation();

  return (
    <div className="error-page-500">
      <div className="error-page-text">{t('_internal_server_error')}</div>

      <Error500 className="error500" />
      <div className="go-home-button">
        <DefaultButton text={t('_go_back')} onClick={() => navigate('/universal-catalog')} />
      </div>
    </div>
  );
};

export default ErrorPage500;
