import React, { useRef } from 'react';

import { AiOutlineAppstore } from 'react-icons/ai';
import { GiCarWheel } from 'react-icons/gi';
import { FaCarBattery, FaOilCan, FaSprayCan } from 'react-icons/fa';
import './Sidebar.scss';
import { ICategoryTab, TabCategories, IConsumableBrands, IConsumableCategories } from './Sidebar.model';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { IItemClick } from 'models/catalog.model';
import Spinner from 'components/spinner/Spinner';
import { getMarketplaceBaseUrl } from 'utils/marketplace';
import { useOnClickOutside } from 'utils/hooks/useOnClickOutside';
import { IPartsCategory } from 'models/vehicle-catalogs';
import List from 'components/List/List';
import { IFilterResponse, IOilCatalogFilter } from 'utils/hooks/http/oils-catalog.http';
import { useTranslation } from 'react-i18next';
import {
  IBatteryBrands, IBatteryCategory,
  IVehicleTyresBrand,
  IVehicleTyresFilterResponce,
  IVehicleTyresSeason
} from 'views/Sidebar/Sidebar.model';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import { createSearchParams, useNavigate } from 'react-router-dom';


type Props = {
  sideBarTab: TabCategories | null;
  setSidebarTab: (tab: TabCategories | null) => void;
  closeSidebar: () => void;
  loading: boolean;
  oilCategories: IOilCatalogFilter | undefined;
  consumableBrands: IConsumableBrands[];
  consumableCategories: IConsumableCategories[];
  tyresCategories: IVehicleTyresFilterResponce[];
  categories: IPartsCategory[];
  onItemClick?: (item: IItemClick) => void;
  batteryBrands: IBatteryBrands[];
  batteryCategories: IBatteryCategory[];
};


const RetailerSidebarView: React.FC<Props> = (props) => {
  const { user } = useSelector((state: RootState) => state.userData);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const tabs: ICategoryTab[] = [
    { id: TabCategories.Pieces, status: true, header: t('_pieces'), icon: <AiOutlineAppstore className="icon" /> },
    { id: TabCategories.Pneus, status: user?.hasTyre, header: t('_tyre'), icon: <GiCarWheel className="icon" /> },
    { id: TabCategories.Huiles, status: user?.hasOil, header: t('_oil'), icon: <FaOilCan className="icon" /> },
    { id: TabCategories.Consumables, status: user?.hasConsumable, header: t('_consumables'), icon: <FaSprayCan className="icon" /> },
    { id: TabCategories.Battery, status: user?.hasBattery, header: t('_battery'), icon: <FaCarBattery className="icon" /> },
  ];

  const marketplace = getMarketplaceBaseUrl();
  const sidebarRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const vehicleSelected = localStorage.getItem('carSelected') ? JSON.parse(localStorage.getItem('carSelected') as string) : null;

  const params = {
    source: vehicleSelected.car.source,
    vehicle_identifier: vehicleSelected.car.vehicle_identifier,
    ...(vehicleSelected.car_information.vin && { vin: vehicleSelected.car_information.vin }),
  };

  useOnClickOutside(sidebarRef, props.closeSidebar);

  const activeTabs = tabs.filter((tab) => tab.status);

  return (
    <div ref={sidebarRef} className="container-sidebar-retailer">
      <FontAwesomeIcon icon={faXmark} onClick={props.closeSidebar} className="close-btn" />
      <div className="tabs">
        {activeTabs.map((tab: ICategoryTab) => {
          return (
            <div
              key={tab.id}
              onClick={() => props.setSidebarTab(tab.id)}
              className={`tab ${tab.id == props.sideBarTab ? 'active' : ''}`}
            >
              {tab.icon}
              <div className="tab-name">{tab.header}</div>
            </div>
          );
        })}
      </div>
      <div className="content-list">
        {props.sideBarTab === 'pieces' && (
          <div className="content">
            <List title={t('_spare_parts_catalog')}
              content={props.categories.map((item: IPartsCategory, index) => {
                return { id: index, name: item.name, type: 'pieces', params: 'pieces', item };
              })}
              images={props.categories.map((item: IPartsCategory) => item.image)}
              onItemClick={props.onItemClick}
              itemClassName="listItem"
            ></List>
          </div>
        )}
        {props.loading ? (
          <div className="content">
            <Spinner class="small" />
          </div>
        ) : (
          <div>
            {props.sideBarTab === 'pneus' && (
              <div className="content">
                <h1>{t('_tire_catalog')}</h1>
                <div className="search">
                  <button className="btn-catalog" type="button"
                    onClick={() => {
                      navigate({
                        pathname: `/reliable/rmi/${vehicleSelected.car.id}/catalogs/tires`,
                        search: `?${createSearchParams(params)}`,
                      }), props.closeSidebar();
                    }}
                  >
                    <GiCarWheel />  {t('_view_catalog')}
                  </button>
                </div>
                {
                  props.tyresCategories ? Object.keys(props.tyresCategories).map((key, index) => {
                    return (
                      <List
                        key={index}
                        title={t('_' + key)}
                        content={props.tyresCategories?.[key].map((item: IVehicleTyresSeason | IVehicleTyresBrand) => {
                          return { id: item.id, name: item.name, type: 'tyre', params: key };
                        })}
                        images={key == 'brand' ? props.tyresCategories[key].map((item: IVehicleTyresBrand) => marketplace + item.image) : []}
                        itemClassName="listItem"
                        onItemClick={props.onItemClick}
                      />
                    );
                  }) : null
                }
              </div>
            )}

            {props.sideBarTab === 'huiles' && (
              <div className="content">
                <h1>{t('_oils_catalog')}</h1>
                <div className="search">
                  <button className="btn-catalog" type="button"
                    onClick={() => {
                      navigate({
                        pathname: `/reliable/rmi/${vehicleSelected.car.id}/catalogs/oils`,
                        search: `?${createSearchParams(params)}`,
                      });
                      props.closeSidebar();
                    }}
                  >
                    <GiCarWheel />  {t('_view_catalog')}
                  </button>
                </div>
                {
                  props.oilCategories ? Object.keys(props.oilCategories).map((key, index) => {
                    return (
                      <List
                        key={index}
                        title={t('_oil_' + key)}
                        content={props.oilCategories?.[key].map((item: IFilterResponse) => {
                          return { id: item.value, name: item.key, type: 'oil', params: key };
                        })}
                        itemClassName="listItem"
                        onItemClick={props.onItemClick}
                      />
                    );
                  }) : null
                }
              </div>
            )}

            {props.sideBarTab === 'consumables' && (
              <div className="content">
                <h1>{t('_consumable_catalog')}</h1>
                <div className="search">
                  <button className="btn-catalog" type="button"
                    onClick={() => {
                      navigate({
                        pathname: `/reliable/rmi/${vehicleSelected.car.id}/catalogs/consumables`,
                        search: `?${createSearchParams(params)}`,
                      });
                      props.closeSidebar();
                    }}
                  >
                    <GiCarWheel />  {t('_view_catalog')}
                  </button>
                </div>
                <List
                  title={t('_brand')}
                  content={props.consumableBrands.map((item: IConsumableBrands) => {
                    return { id: item.id, name: item.name, type: 'consumables', params: 'brand_ids' };
                  })}
                  images={props.consumableBrands.map((item: IConsumableBrands) => marketplace + item.image)}
                  itemClassName="listItem"
                  onItemClick={props.onItemClick}
                />
                <List
                  title={t('_category')}
                  content={props.consumableCategories.map((item: IConsumableCategories) => {
                    return { id: item.id, name: item.name, type: 'consumables', params: 'category_ids' };
                  })}
                  images={props.consumableCategories.map((item: IConsumableCategories) => marketplace + item.image)}
                  itemClassName="listItem"
                  onItemClick={props.onItemClick}
                />
              </div>
            )}

            {props.sideBarTab === 'battery' && (
              <div className="content">
                <h1>{t('_catalog_battery')}</h1>
                <div className="search">
                  <button className="btn-catalog" type="button"
                    onClick={() => {
                      navigate({
                        pathname: `/reliable/rmi/${vehicleSelected.car.id}/catalogs/batteries`,
                        search: `?${createSearchParams(params)}`,
                      });
                      props.closeSidebar();
                    }}
                  >
                    <GiCarWheel />  {t('_view_catalog')}
                  </button>
                </div>
                <List
                  title={t('_brand')}
                  content={props.batteryBrands.map((item: IBatteryBrands) => {
                    return { id: item.id, name: item.name, type: 'battery', params: 'brand_ids' };
                  })}
                  images={props.batteryBrands.map((item: IBatteryBrands) => marketplace + item.image)}
                  itemClassName="listItem"
                  onItemClick={props.onItemClick}
                />
                <List
                  title={t('_category')}
                  content={props.batteryCategories.map((item: IBatteryCategory) => {
                    return { id: item.id, name: item.name, type: 'battery', params: 'category_ids' };
                  })}
                  images={props.batteryCategories.map((item: IBatteryCategory) => marketplace + item.image)}
                  itemClassName="listItem"
                  onItemClick={props.onItemClick}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div >
  );
};


RetailerSidebarView.displayName = 'RetailerSidebarView';

export default RetailerSidebarView;
