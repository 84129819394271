import React, { useEffect, useState } from 'react';
import './CirrusCartAvailability.scss';

type Props = {
  limit: number;
  isAvailable?: boolean;
  unspecified?: boolean;
  available?
  value?
};
const CirrusCartAvailability: React.FC<Props> = (props: Props) => {
  const [stockColor, setStockColor] = useState<string>();

  useEffect(() => {
    if(props.available && typeof props.available.warehouse_main === 'object'){
      if(props.available?.warehouse_main.quantity > 0){
        setStockColor('green');
      }
      
      if(props.available?.warehouse_main.quantity === 0){
        setStockColor('');
      }
      
      if(props.available?.warehouse_main.quantity === 0 && props.available?.warehouse_all.quantity > 0){
        setStockColor('orange');
      }

      if(props.available?.warehouse_main.quantity === 0 && props.available?.warehouse_all.quantity === 0){
        setStockColor('');
      }
    }
  }, [props.available, props.value]);

  return (
    <div className="cirrus-cart-availability-wrapper">
      {
        props.unspecified ? (
          <span>-</span>
        ):(
          <div className="value">
            <div className={`item ${stockColor ? stockColor : ''}`}></div>
            <div>{props.limit > 0 ? props.limit : undefined}</div>
          </div>
        )
      }

    </div>
  );
};

export default CirrusCartAvailability;