import React, { useCallback } from 'react';
import { faCheck, faClose, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './NewClient.scss';
import { ICreateClientForm } from 'models/client.model';
import { UseFormHandleSubmit, UseFormRegister, Controller } from 'react-hook-form';
import { IOption } from 'interfaces/select';
import { useTranslation } from 'react-i18next';
import fileUpload from 'assets/Icons/file-upload.svg';
import { useDropzone } from 'react-dropzone';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import Select from 'react-select';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import CustomDatePicker from 'components/custom-date-picker/CustomDatePicker';
import { IDateOption } from 'models/date-picker';
import PasswordStrengthInput from '../../../components/inputs/PasswordStrengthInput/PasswordStrengthInput';

type Props = {
  handleNewClientModal?: () => void;
  handleSubmit: UseFormHandleSubmit<ICreateClientForm>;
  businessTypeOptions: IOption[];
  languagesOptions: IOption[];
  countriesOptions: IOption[];
  onSubmit: () => void;
  register: UseFormRegister<ICreateClientForm>;
  loading: boolean;
  errors?
  passwordMarchError: boolean;
  files?
  setSelectedFile: (file: File) => void
  setSubscriptionValueDate: (date: IDateOption) => void
  setFiles: (files) => void
  control?
};

const NewClientView: React.FC<Props> = (props: Props) => {

  const { t } = useTranslation();
  const { isMobile, isTablet } = useDeviceDetect();

  const onDrop = useCallback((acceptedFiles: File[]) => {
    props.setSelectedFile(acceptedFiles[0]);
    props.setFiles(acceptedFiles.map(file => Object.assign(file, {
      preview: URL.createObjectURL(file)
    })));
  }, [props.files]);

  const removeFile = (file) => {
    const newFiles = [...props.files];

    newFiles.splice(newFiles.indexOf(file), 1);
    props.setFiles(newFiles);
  };

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    onDrop,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpg', '.jpeg'],
    },
  });


  const customStyles = (hasError) => ({
    control: (styles) => ({
      ...styles,
      ...(hasError ? { borderColor: 'red' } : { border: 'none' }),
    }),
  });

  return (<div className="add-client-container">
    <form className="popup-wrapper" onSubmit={props.handleSubmit(props.onSubmit)} noValidate>
      <FontAwesomeIcon icon={faXmark} onClick={props.handleNewClientModal} className="close-btn" />
      <h1 className="popup-title">
        <span>{t('_new_client')}</span>
      </h1>
      <div className="form">
        <h4 className="form-title">{t('_contact').toLocaleUpperCase()}</h4>
        <div className="client-select">
          <div className="radio">
            <input className="check" type="radio" defaultChecked value="m" {...props.register('gender')} />
            <label htmlFor="gender-male">{t('_male')}</label>
            <FontAwesomeIcon icon={faCheck} className="check-icon" />
          </div>
          <div className="radio">
            <input className="check" type="radio" value="f" {...props.register('gender')} />
            <label htmlFor="gender-female">{t('_female')}</label>
            <FontAwesomeIcon icon={faCheck} className="check-icon" />
          </div>
        </div >
        <div className="client-select">
          <div className="radio">
            <input className="check" type="radio" defaultChecked value="true" {...props.register('is_active')} />
            <label htmlFor="is-active-true">{t('_active')}</label>
            <FontAwesomeIcon icon={faCheck} className="check-icon" />
          </div>
          <div className="radio">
            <input className="check" type="radio" value="false" {...props.register('is_active')} />
            <label htmlFor="is-active-false">{t('_inactive')}</label>
            <FontAwesomeIcon icon={faCheck} className="check-icon" />
          </div>
        </div >
        <div className="row">
          <div className={`create-container ${props.errors.last_name?.type == 'required' ? 'error' : ''}`}>
            <label htmlFor="#client.name">{t('_name')}</label>
            <div className="text-input-container">
              <input
                className="text-input"
                type="text"
                placeholder="Cesarelli"
                {...props.register('last_name', { required: true })}
              ></input>
              {props.errors?.last_name?.type === 'required' && <p className="error-message"> {t('_name_required')}</p >}
            </div >
          </div >
          <div className={`create-container ${props.errors.first_name?.type == 'required' ? 'error' : ''}`}>
            <label htmlFor="#client.surname">{t('_firstname')}</label>
            <div className="text-input-container">
              <input
                className="text-input"
                type="text"
                placeholder="Sylvain"
                {...props.register('first_name', { required: true })}
              ></input>
              {props.errors?.first_name?.type === 'required' && <p className="error-message"> {t('_firstname_required')}</p >}
            </div >
          </div >
          <div className="create-container">
            <label>{t('_language')}</label>
            <Controller
              control={props.control}
              name="language"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <Select
                  value={props.languagesOptions?.find((c) => c.value === value)}
                  className="select-dropdown"
                  styles={customStyles(props.errors?.language?.type)}
                  onChange={(val) => {
                    onChange(val?.value);
                  }}
                  options={props.languagesOptions}
                  isSearchable={!isMobile && !isTablet}
                  placeholder={t('_language')}
                />
              )}
            />
            {props.errors?.language?.type === 'required' && <p className="error-message">{t('_language_required')}</p>}
          </div >
        </div >
        <div className="row">
          <div className={`create-container ${props.errors.email?.type == 'required' ? 'error' : ''}`}>
            <label htmlFor="#client.mail">{t('_email')}</label>
            <div className="text-input-container">
              <input
                className="text-input"
                type="email"
                placeholder="cesarelli.s@gmail.com"
                {...props.register('email', { required: true })}
              ></input>
              {props.errors?.email?.type === 'required' && <p className="error-message"> {t('_email_required')}</p >}
            </div >
          </div >
          <div className="create-container">
            <label htmlFor="#client.telephone.mobile">{t('_mobile')}</label>
            <div className="text-input-container">
              <input className="text-input" type="tel" placeholder="06 55 11 99 55" {...props.register('mobile')}></input>
            </div >
          </div >
          <div className="create-container">
            <label htmlFor="#client.telephone.fix">{t('_phone')}</label>
            <div className="text-input-container">
              <input className="text-input" type="tel" placeholder="06 55 11 99 55" {...props.register('phone')}></input>
            </div >
          </div >
        </div >
        <div className="row">
          <div className={`create-container ${props.errors.password?.first?.type == 'required' ? 'error' : ''}`}>
            <label htmlFor="#client.password.first">{t('_password')}</label>
            <div className="text-input-container">
              <PasswordStrengthInput placeholder="* * * * * *" {...props.register('password.first')} required></PasswordStrengthInput>
              {props.errors.password?.first?.type === 'required' && <p className="error-message"> {t('_password_required')}</p >}
            </div >
          </div >
          <div className={`create-container ${props.errors.password?.second?.type == 'required' ? 'error' : ''}`}>
            <label htmlFor="#client.telephone.fix">{t('_password_confirm')}</label>
            <div className="text-input-container">
              <PasswordStrengthInput placeholder="* * * * * *" {...props.register('password.second')} required></PasswordStrengthInput>
              {props.errors.password?.second?.type === 'required' && <p className="error-message"> {t('_password_required')}</p >}
              {props.passwordMarchError && <p className="error-message"> Passwords do not match</p >}
            </div >
          </div >
          <div className="create-container">
            <label htmlFor="#client.commentary">{t('_comment')}</label>
            <div className="text-input-container">
              <textarea className="text-input" placeholder="" {...props.register('commentary')}></textarea>
            </div >
          </div >
        </div >
      </div >
      <hr className="separator"></hr>
      <div className="form">
        <h4 className="form-title">{t('_client').toLocaleUpperCase()}</h4>
        <div className="row">
          <div className="client-select">
            <div className="radio">
              <input className="check" type="radio" defaultChecked value="true" {...props.register('client_is_active')} />
              <label htmlFor="is-active-true">{t('_active')}</label>
              <FontAwesomeIcon icon={faCheck} className="check-icon" />
            </div>
            <div className="radio">
              <input className="check" type="radio" value="false" {...props.register('client_is_active')} />
              <label htmlFor="is-active-false">{t('_inactive')}</label>
              <FontAwesomeIcon icon={faCheck} className="check-icon" />
            </div>
          </div >
        </div >
        <div className="row">
          <div className={`create-container ${props.errors?.client_name?.type == 'required' ? 'error' : ''}`}>
            <label htmlFor="#garage.name">{t('_name')}</label>
            <div className="text-input-container">
              <input
                className="text-input"
                type="text"
                placeholder="Cesarelli Garage"
                {...props.register('client_name', { required: true })}
              ></input>
              {props.errors?.client_name?.type === 'required' && <p className="error-message"> {t('_name_required')}</p >}
            </div >
          </div >
          <div className="create-container">
            <label>{t('_business_type')}</label>
            <Controller
              control={props.control}
              name="client_business_type_id"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <Select
                  value={props.businessTypeOptions?.find((c) => c.value === value)}
                  className="select-dropdown"
                  styles={customStyles(props.errors?.client_business_type_id?.type)}
                  onChange={(val) => {
                    onChange(val?.value);
                  }}
                  options={props.businessTypeOptions}
                  isSearchable={!isMobile && !isTablet}
                  placeholder={t('_business_type')}
                />
              )}
            />
            {props.errors?.client_business_type_id?.type === 'required' && (
              <p className="error-message">{t('_business_type_required')}</p>
            )}
          </div >
          <div className="create-container ">
            <label>{t('_language')}</label>
            <Controller
              control={props.control}
              name="client_language"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <Select
                  value={props.languagesOptions?.find((c) => c.value === value)}
                  className="select-dropdown"
                  styles={customStyles(props.errors?.client_language?.type)}
                  onChange={(val) => {
                    onChange(val?.value);
                  }}
                  options={props.languagesOptions}
                  isSearchable={!isMobile && !isTablet}
                  placeholder={t('_language')}
                />
              )}
            />
            {props.errors?.client_language?.type === 'required' && <p className="error-message">{t('_language_required')}</p>}
          </div >
        </div >
        <div className="row">
          <div className="create-container">
            <label htmlFor="#garage.name">{t('_garage_address')}</label>
            <div className="text-input-container">
              <input
                className="text-input"
                type="text"
                placeholder="12 avenue Auguste Renoir"
                {...props.register('client_address')}
              ></input>
            </div >
          </div >
          <div className="create-container ">
            <label>{t('_country')}</label>
            <Controller
              control={props.control}
              name="client_country_id"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <Select
                  value={props.countriesOptions?.find((c) => c.value === value)}
                  className="select-dropdown"
                  styles={customStyles(props.errors?.client_country_id?.type)}
                  onChange={(val) => {
                    onChange(val?.value);
                  }}
                  options={props.countriesOptions}
                  isSearchable={!isMobile && !isTablet}
                  placeholder={t('_country')}
                />
              )}
            />


            {props.errors?.client_country_id?.type === 'required' && <p className="error-message">{t('_country_required')}</p>}
          </div >
          <div className="create-container">
            <label htmlFor="#garage.client_zipcode">{t('_code_postal')}</label>
            <div className="text-input-container">
              <input className="text-input" type="text" placeholder="78500" {...props.register('client_zipcode')}></input>
            </div >
          </div >
        </div >
        <div className="row">
          <div className="create-container">
            <label htmlFor="#garage.client_phone">{t('_mobile')}</label>
            <div className="text-input-container">
              <input className="text-input" type="tel" placeholder="06 55 11 99 55" {...props.register('client_phone')}></input>
            </div >
          </div >
          <div className="create-container">
            <label htmlFor="#garage.client_commentary">{t('_comment')}</label>
            <div className="text-input-container">
              <textarea className="text-input" placeholder="" {...props.register('client_commentary')}></textarea>
            </div >
          </div >
          <div className="create-container">
            <label htmlFor="#garage.image">{t('_logo')}</label>
            <div className="logo-upload-container">
              <div className="logo-upload">
                {props.files.length ?
                  <div className="uploaded-message-file">
                    <div className="icon">
                      <img alt="icon" src={props.files[0].preview} />
                    </div>

                    <div className="remove-file" onClick={() => removeFile(props.files[0])}>
                      <FontAwesomeIcon className="remove-client-img" icon={faClose} />
                    </div>
                  </div >
                  : <div className="select-logo-title">{t('_please_select_a_logo')}</div>}
              </div >
              <div {...getRootProps({ className: 'content' })}>
                <div className="logo-upload-button">
                  <input {...getInputProps()} />
                  <img src={fileUpload} className="upload-image-btn" alt="file-upload" />
                </div>
              </div >
            </div >
          </div >

        </div >
        <div className="row subscription">
          <div className={`create-container ${props.errors?.client_erp_id?.type == 'required' ? 'error' : ''}`}>
            <label htmlFor="#garage.client_phone">{t('_client_erp_id')}</label>
            <div className="text-input-container">
              <input className="text-input" placeholder={t('_client_erp_id')} {...props.register('client_erp_id', { required: true })}></input>
              {props.errors?.client_erp_id?.type === 'required' && <p className="error-message">{t('_fild_required')}</p>}
            </div >
          </div >
          <div className="item-date-picker subscription-date-picker">
            <label>{t('_subscription')}</label>
            <CustomDatePicker setValue={props.setSubscriptionValueDate} />
          </div>
        </div >
      </div >
      <div className="btns">
        <div className="save">
          <DefaultButton type="submit" text={t('_save')} isloading={props.loading} />
        </div>
      </div >
    </form >
  </div >);
};

NewClientView.displayName = 'NewClientView';

export default NewClientView;
