import axios, { AxiosRequestConfig } from 'axios';
import { getPresentationApi } from './marketplace';

const presentationApi = axios.create({
  baseURL: getPresentationApi(),
});

presentationApi.interceptors.request.use((config: AxiosRequestConfig) => {
  config.baseURL = getPresentationApi();

  return config;
});


export const presentationAxios = presentationApi;
