
import { CancelToken } from 'axios';
import { IBodyResponseBrand, IBrand, IParamsBrand } from 'interfaces/consumableBrand';

import { addRouteParam, IHttpResponse, serverAxios } from '../../http';

class ConsumableBrandHttp {

  private static list = '/consumable/brand';
  private static info = '/consumable/brand/';
  private static update = '/consumable/update/brand/';
  private static create = '/consumable/register/brand';
  private static delete = '/consumable/brand/';
  private static isPartner = '/consumable/update/brand/:id/is_partner';

  public getList(urlParams: IParamsBrand, cancelToken: CancelToken): Promise<IHttpResponse<IBodyResponseBrand>> {
    return serverAxios.get(ConsumableBrandHttp.list, { params: urlParams, cancelToken: cancelToken });
  }


  public getBrandInfo(id: number | undefined): Promise<IHttpResponse<IBrand>> {
    return serverAxios.get(ConsumableBrandHttp.info + id);
  }

  public edit(id: number, data: FormData): Promise<IHttpResponse<IBrand>> {
    return serverAxios.post(ConsumableBrandHttp.update + id, data);
  }

  public create(data: FormData): Promise<IHttpResponse<IBrand>> {
    return serverAxios.post(ConsumableBrandHttp.create, data);
  }

  public delete(id: number): Promise<IHttpResponse<null>> {
    return serverAxios.delete(ConsumableBrandHttp.delete + id);
  }

  public updateIsPartner(id: number, data: {}): Promise<IHttpResponse<[]>> {
    return serverAxios.post(addRouteParam(ConsumableBrandHttp.isPartner, { id }), data);
  }
}

export default ConsumableBrandHttp;
