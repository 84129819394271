
import React, { ChangeEvent, PropsWithChildren, useEffect, useState } from 'react';

import './FilterSuppliersCategoryMobile.scss';
import useHttp from 'utils/hooks/useHttp';
import { ICategorieGroup } from '../../../navbar/Navbar.model';
import { useTranslation } from 'react-i18next';
import Spinner from 'components/spinner/Spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft, faClose } from '@fortawesome/free-solid-svg-icons';
import Masonry from 'react-masonry-css';
import CheckboxCellTab from 'components/CheckboxCellTab/CheckboxCellTab';
import { useForm } from 'react-hook-form';
import ListSupplier from 'components/ListSupplier/ListSupplier';
import { notify } from 'utils/marketplace';
import { ISupplier } from 'utils/hooks/http/filter-suppliers.http';


type Props = {
  setOpenModal: (e: boolean) => void;
  supplier: ISupplier | undefined;
  getData;

};

const FilterSuppliersCategoryMobile: React.FC<Props> = (props: PropsWithChildren<Props>) => {
  const http = useHttp();
  const [loading, setLoading] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);

  const [data, setData] = useState<ICategorieGroup[]>([]);
  const [activeCategory, setActiveCategory] = useState<ICategorieGroup>();
  const { getValues, reset, setValue, register, } = useForm();
  const { t } = useTranslation();
  const [showCategory, setShowCategory] = useState(false);

  useEffect(() => {

    setLoading(true);
    http.filterSuppliers
      .addSupplierCategory(String(props.supplier?.id))
      .then((res) => {
        setData(res.data);
        setActiveCategory(res.data[0]);
        res.data?.map((items) => {
          let genartnrAll: Array<string> = [];
          let genartnrAllSelected: Array<string> = [];

          items.subcategories?.map((item) => {
            const genartnrNew: Array<string> = [];

            item.subcategories?.map((cat) => {
              if (cat.is_category_enabled) {
                genartnrNew.push(String(cat.genartnr));
                setValue(`checkbox-cart[${String(items.code_groupe)}][${String(item.code_ssgroupe)}][${String(cat.code_repere)}]`, [cat.genartnr]);
              }

            });
            genartnrAllSelected = genartnrAllSelected.concat(genartnrNew);
            genartnrAll = genartnrAll.concat(item.subcategories.map(e => e['genartnr']));

            if (item.subcategories.map(e => e['genartnr']).length == genartnrNew.length) {
              setValue(`checkbox-code_ssgroupe[${String(items.code_groupe) + String(item.code_ssgroupe)}]`, [genartnrNew.length]);
            }


          });

          if (genartnrAll.length == genartnrAllSelected.length) {
            setValue(`checkbox-parent[${items.code_groupe}]`, [items.code_groupe]);
          }


        });


      })
      .finally(() => {
        setLoading(false);
      });

  }, []);


  const onCategoryClick = (category) => {
    setActiveCategory(category);
    setShowCategory(true);
  };


  const handleOnChangeCategory = (event, activeCategoryCheck) => {
    const isChecked = event.target.checked;


    if (isChecked) {
      let genartnrNew: Array<string> = [];

      activeCategoryCheck?.subcategories.map((items) => {
        const array_genartnr = items.subcategories.map(e => e['genartnr']);

        items.subcategories.map(item => {
          setValue(`checkbox-cart[${String(item.code_groupe)}][${String(item.code_ssgroupe)}][${String(item.code_repere)}]`, [item.genartnr]);
        });

        genartnrNew = genartnrNew.concat(array_genartnr);
        setValue(`checkbox-code_ssgroupe[${String(activeCategoryCheck.code_groupe) + String(items.code_ssgroupe)}]`, [array_genartnr.length]);

      });

      setValue(`checkbox-parent[${activeCategoryCheck.code_groupe}]`, [activeCategoryCheck.code_groupe]);
    } else {
      setValue(`checkbox-parent[${activeCategoryCheck.code_groupe}]`, '');
      activeCategoryCheck?.subcategories.map((items) => {
        setValue(`checkbox-code_ssgroupe[${String(activeCategoryCheck.code_groupe) + String(items.code_ssgroupe)}]`, '');
        items.subcategories.map(item => {
          setValue(`checkbox-cart[${String(item.code_groupe)}][${String(item.code_ssgroupe)}][${String(item.code_repere)}]`, '');
        });
      });
    }

  };


  const handleSelectAllchildren = (event, items, code_groupe, code_ssgroupe) => {
    const isChecked = event.target.checked;

    if (isChecked) {
      items.map((item) => {
        setValue(`checkbox-cart[${String(item.code_groupe)}][${String(item.code_ssgroupe)}][${String(item.code_repere)}]`, [item.genartnr]);
      });
    } else {
      items.map((item) => {
        setValue(`checkbox-cart[${String(item.code_groupe)}][${String(item.code_ssgroupe)}][${String(item.code_repere)}]`, '');
      });
    }


    let allGenartnr: Array<string> = [];
    let allGenartSelected: Array<string> = [];

    activeCategory?.subcategories.map((items) => {
      allGenartnr = allGenartnr.concat(items.subcategories.map(e => e['genartnr']));
      allGenartSelected = allGenartSelected.concat(getValues()['checkbox-cart'][String(code_groupe)][String(items.code_ssgroupe)]?.filter((item) => Array.isArray(item)));
    });

    if (allGenartnr.length == allGenartSelected.length) {
      setValue(`checkbox-parent[${activeCategory?.code_groupe}]`, [activeCategory?.code_groupe]);
    } else {
      setValue(`checkbox-parent[${activeCategory?.code_groupe}]`, '');
    }
  };

  const handleOnChangeGenartnr = (e: ChangeEvent<HTMLInputElement>, code_groupe, code_ssgroupe, code_repere) => {

    const isChecked = e.target.checked;
    const value = e.target.value;

    let allGenartnr: Array<string> = [];
    const allGenartnrSelected: Array<string> = [];

    if (isChecked) {


      setValue(`checkbox-cart[${String(code_groupe)}][${String(code_ssgroupe)}][${String(code_repere)}]`, [String(value)]);
    } else {
      setValue(`checkbox-cart[${String(code_groupe)}][${String(code_ssgroupe)}][${String(code_repere)}]`, '');
    }

    activeCategory?.subcategories.map((items) => {
      allGenartnr = allGenartnr.concat(items.subcategories.map(e => e['genartnr']));
      items.subcategories.map(e => {
        if (getValues()['checkbox-cart'][String(e.code_groupe)][String(e.code_ssgroupe)][String(e.code_repere)]) {
          allGenartnrSelected.push(String(getValues()['checkbox-cart'][String(e.code_groupe)][String(e.code_ssgroupe)][String(e.code_repere)]));
        }
      });

      if (items.code_ssgroupe == code_ssgroupe) {
        const array_genartnr = items.subcategories.map(e => e['genartnr']);
        const intersection = array_genartnr.every(elem => getValues()['checkbox-cart'][String(code_groupe)][String(code_ssgroupe)]?.map(String).includes(elem));

        if (intersection) {
          setValue(`checkbox-code_ssgroupe[${String(code_groupe) + String(code_ssgroupe)}]`, [array_genartnr.length]);
        } else {
          setValue(`checkbox-code_ssgroupe[${String(code_groupe) + String(code_ssgroupe)}]`, '');
        }

      }
    });

    if (allGenartnr.length == allGenartnrSelected.length) {
      setValue(`checkbox-parent[${activeCategory?.code_groupe}]`, [activeCategory?.code_groupe]);
    } else {
      setValue(`checkbox-parent[${activeCategory?.code_groupe}]`, '');
    }

  };

  const onSubmit = async () => {
    setLoadingSave(true);
    const values = getValues();
    const data: Array<{}> = [];


    values?.['checkbox-cart'].forEach((items, code_groupe) => {
      if (Array.isArray(items) && items.length) {
        items.forEach((item, code_ssgroupe) => {
          if (Array.isArray(item) && items.length) {
            item.forEach((genartnr, code_repere) => {

              if (Array.isArray(genartnr) && genartnr) {
                genartnr.forEach((i) => {
                  data.push(
                    {
                      code_groupe: code_groupe,
                      code_ssgroupe: code_ssgroupe,
                      code_repere: code_repere,
                      genartnr: i
                    }
                  );
                });
              }

              if (!Array.isArray(genartnr) && genartnr) {
                data.push(
                  {
                    code_groupe: code_groupe,
                    code_ssgroupe: code_ssgroupe,
                    code_repere: code_repere,
                    genartnr: genartnr
                  }
                );

              }

            });
          }
        });
      }
    });

    http.filterSuppliers
      .addSupplierCategorySave(String(props.supplier?.id), data).then(() => {
        notify(t('_supplier_category'), 'success', t('_success'));
        setLoadingSave(false);
        props.setOpenModal(false);
        props.getData();
      }).catch(() => {
        setLoadingSave(false);
      });


  };


  return (
    <div className="filter-supplier-category-mobile">
      <div className="close-modal" onClick={() => { props.setOpenModal(false); }}><FontAwesomeIcon icon={faClose} /></div>
      <div className="modal-title">
        {t('_supplier_category')} -  {props.supplier?.name}
      </div>
      {
        loading ? <Spinner /> :


          data.length > 0 ?
            <React.Fragment>
              <div className="supplier-categories">
                <div className={`categories-list  ${showCategory ? 'hide' : 'show'}`}>
                  <ListSupplier
                    content={data.map((item) => {
                      return { ...item, id: item.code_groupe, name: item.name };
                    })}
                    images={data.map((item) => item.image)}
                    onItemClick={onCategoryClick}
                    itemId="code_groupe"
                    itemClassName="supplier-list"
                    activeCategory={activeCategory}
                    handleOnChangeCategory={handleOnChangeCategory}
                    register={register}
                  />

                </div>


                <div className={`supplier-subcategories-list ${showCategory ? 'show' : 'hide'}`}>
                  <div className="return" >
                    <span onClick={() => setShowCategory(false)}>
                      <FontAwesomeIcon icon={faArrowCircleLeft} /> {t('_return')}
                    </span>

                  </div>
                  <Masonry breakpointCols={3} className="my-masonry-grid" columnClassName="my-masonry-grid_column">
                    {activeCategory?.subcategories.map((items, key) => {


                      return (
                        <div key={key + items.name} className="item">
                          <div className="title">

                            {/* <input type="checkbox" checked={selectAll} onChange={(event) => handleSelectAll(event)} /> */}
                            <input type="checkbox" {...register(`checkbox-code_ssgroupe[${String(activeCategory.code_groupe) + String(items.code_ssgroupe)}]`)}
                              value={String(items.subcategories?.map((item) => String(item.genartnr)).length)}
                              onChange={(event) => {
                                handleSelectAllchildren(event, items.subcategories, String(activeCategory.code_groupe), String(items.code_ssgroupe));
                              }}
                            />
                            {items.name}
                          </div>
                          {items.subcategories.map((item, key) => {
                            return (
                              <div className={`subcategory ${item.disponible === 0 ? 'disabled' : ''}`} key={key + item.name}>
                                <CheckboxCellTab handleOnChange={handleOnChangeGenartnr}
                                  id={String(item.genartnr)}
                                  code_groupe={activeCategory.code_groupe}
                                  code_ssgroupe={items.code_ssgroupe}
                                  code_repere={item.code_repere}
                                  inputName={`checkbox-cart[${String(activeCategory.code_groupe)}][${String(items.code_ssgroupe)}][${String(item.code_repere)}]`}
                                  register={register}
                                ></CheckboxCellTab>  {item.name}
                              </div>
                            );
                          })}
                        </div>
                      );
                    })}
                  </Masonry>
                </div >

              </div >
              <div className="btn-save">
                <button onClick={onSubmit}> {loadingSave ? <Spinner class="extra-small" /> : null} <div className="title-btn-save">{t('_update_article_supplier')}</div></button>
              </div>
            </React.Fragment >
            :
            <div className="categories-no-data">{t('_no_data')}</div>
      }


    </div >
  );
};

export default FilterSuppliersCategoryMobile;
