import React, { useEffect, useState } from 'react';

import './PartOENew.scss';
import { useTranslation } from 'react-i18next';
import Modal from 'components/modal/Modal';
import PartInfoModal from 'components/PartInfo/PartInfoModal';

type Props = {
  data?;
};

const PartOENew: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const [showButton, setShowButton] = useState(false);
  const [characteristics, setCharacteristics] = useState('');
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [modalData, setModalData] = useState();
  const [showButtonOE, setShowButtonOE] = useState(false);
  const [line, setLine] = useState(false);

  useEffect(() => {
    let charact = '';

    props.data.characteristics &&
      Object.keys(props.data.characteristics).map((key) => {
        props.data.characteristics[key]?.length > 0 ? (charact = charact + ` ${key}: ${props.data.characteristics[key].toString()} ;`) : '';
      });

    setCharacteristics(charact);

    if (charact.length > 130) {
      setShowButton(true);
    }

    if (props.data?.name?.length > 60) {
      setShowButtonOE(true);
    }
  }, []);

  return (
    <div className="part-list-item-oe-new">
      <div className="part-header">
        <div className="img">
          <img
            src={props.data.image ? props.data.image : '/images/car-part-placeholder-big.png'}
            alt="img"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = '/images/car-part-placeholder-big.png';
            }}
          />
        </div>
        <div className="title">{props.data.manufacturer_name}</div>
        <div className="product-name">{props.data.product_name}</div>
        <div className="reference">
          <div className="info">{props.data.name}</div>
          {showButtonOE ? (
            <div className="show-more">
              <button
                className="btn-more"
                onClick={() => {
                  setOpenModal(true), setModalData(props.data.name);
                  setLine(true);
                }}
              >
                {t('_show_more')}
              </button>
            </div>
          ) : null}
        </div>
        <div className="description">
          <div className="info">{characteristics}</div>

          {showButton ? (
            <div className="show-more">
              <button
                className="btn-more"
                onClick={() => {
                  setOpenModal(true), setModalData(props.data.characteristics);
                  setLine(false);
                }}
              >
                {t('_show_more')}
              </button>
            </div>
          ) : null}
        </div>
      </div>
      <div className="part-footer"></div>
      <Modal
        openModal={openModal}
        setOpenModal={setOpenModal}
        childrenComponent={<PartInfoModal setOpenModal={setOpenModal} data={modalData} line={line} />}
      />
    </div>
  );
};

export default PartOENew;
