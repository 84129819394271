import React, { PropsWithChildren } from 'react';

import './MenuSidebar.scss';
import './MenuItem/MenuItem.scss';
import MenuItem from './MenuItem/MenuItem';
import { NavLink } from 'react-router-dom';
import useLogOut from 'utils/hooks/useLogOut';
import { useTranslation } from 'react-i18next';
import useMessageText from '../../utils/hooks/useMessageText';
import { getCurrentMarketplace } from '../../utils/marketplace';
import { Marketplaces } from 'utils/constants/applications';

type Props = {
  setSelected: (selected) => void;
  selected: string;
};

const MenuSidebarView: React.FC<Props> = (props: PropsWithChildren<Props>) => {
  const { t } = useTranslation();
  const marketplace = getCurrentMarketplace();

  const messageText = useMessageText();
  const logout = useLogOut();

  return (
    <div className={`sidebar-container ${marketplace}`}>
      <div>
        <div className="title">{t('_my_account')}</div>

        <div className="sidebar-navbar">
          <NavLink
            onClick={() => props.setSelected('my-profile')}
            className={`nav-link ${props.selected == 'my-profile' ? 'active' : ''}`}
            to="my-profile"
          >
            <MenuItem icon="info" text={t('_my_info')} />
          </NavLink>

          <NavLink
            onClick={() => props.setSelected('messages')}
            className={`nav-link ${props.selected == 'messages' ? 'active' : ''}`}
            to="messages"
          >
            <MenuItem icon="comment" text={messageText} />
          </NavLink>

          <NavLink
            onClick={() => props.setSelected('settings')}
            className={`nav-link ${props.selected == 'settings' ? 'active' : ''}`}
            to="settings"
          >
            <MenuItem icon="gear" text={t('_settings')} />
          </NavLink>

          <NavLink
            onClick={() => props.setSelected('history')}
            className={`nav-link ${props.selected == 'history' ? 'active' : ''}`}
            to="history"
          >
            <MenuItem icon="search" text={t('_history')} />
          </NavLink>
          <NavLink
            onClick={() => props.setSelected('favorite')}
            className={`nav-link ${props.selected == 'favorite' ? 'active' : ''}`}
            to="favorite"
          >
            <MenuItem icon="heart" text={t('_favorite')} />
          </NavLink>
          {/*<NavLink className={`nav-link`} to="options">*/}
          {/*  <MenuItem*/}
          {/*    icon="options"*/}
          {/*    text="Options impression devis"*/}
          {/*  />*/}
          {/*</NavLink>*/}
          {getCurrentMarketplace() !== Marketplaces.PUNCHOUT && (
            <div
              className="nav-link"
              onClick={logout}
            >
              <MenuItem icon="power" text={t('_logout')} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

MenuSidebarView.displayName = 'MenuSidebarView';

export default MenuSidebarView;
