

export interface IRow {
  ID: string,
  name: string,
  number: number | null,
  required: boolean,
  title:string,
  order:number
}

export const INITIAL_DATA = {
  columns: {
    'column-mapping': {
      id: 'column-mapping',
      title: 'Mapping',
      fieldIds: [],
    },
    'column-file': {
      id: 'column-file',
      title: 'File',
      fieldIds: [],
    },
  },
  columnOrder: ['column-mapping', 'column-file'],
};

export const PROFORMA_MAPPING_ROWS : IRow[] = [
  {
    ID: 'item',
    number: 0,
    title: '_item',
    name: 'Item',
    required: false,
    order: 0,
  },
  {
    ID: 'code_fou_internal',
    number: 1,
    title: '_internal_code',
    name: 'Internal code',
    required: false,
    order: 1,
  },
  {
    ID: 'reference_internal',
    number: 2,
    title: '_our_reference_item_no',
    name: 'Our Reference (Item No.)',
    required: false,
    order: 2,
  },
  {
    ID: 'code_fou_amf',
    number: 3,
    title: '_amf_code_item_no',
    name: 'AMF code (Item No.)',
    required: false,
    order: 3,
  },
  {
    ID: 'reference_amf',
    number: 4,
    title: '_amf_reference_item_no',
    name: 'AMF  Reference (Item No.)',
    required: true,
    order: 4,
  },
  {
    ID: 'description',
    number: 5,
    title: '_description',
    name: 'Description',
    required: false,
    order: 5,
  },
  {
    ID: 'quantity',
    number: 6,
    title: '_quantity_mapping',
    name: 'Quantity',
    required: true,
    order: 6,
  },
  {
    ID: 'price',
    number: 7,
    title: '_price_pr_unit',
    name: 'Price Pr. Unit',
    required: false,
    order: 7,
  },
];

export const FILE_HEADER = [
  'ITEM',
  'INTERNAL CODE',
  'OUR REFERENCE (ITEM NO.)',
  'AMF CODE (ITEM NO.)',
  'AMF  REFERENCE (ITEM NO.)',
  'DESCRIPTION',
  'QUANTITY',
  'E-Reliable Price',
];
