import React, { useEffect, useState } from 'react';
import Icon from '../Icon/Icon';
import { DEFAULT_VIEW, GRID_VIEW, LIST_VIEW, ProductsViewModeTypes } from './ProductsViewMode.constants';
import './ProductsViewMode.scss';
import { useDispatch } from 'react-redux';
import useHttp from '../../utils/hooks/useHttp';
import { setUser } from '../../store/reducers/useSlices';
import { useMobileDetect } from '../../utils/hooks/use-mobile-detect';

type Props = {
  setViewMode: (viewMode) => void
  viewMode: keyof typeof ProductsViewModeTypes
}

const ProductsViewMode: React.FC<Props> = (props) => {

  const user = JSON.parse(localStorage.getItem('userData')!);
  const http = useHttp();
  const dispatch = useDispatch();
  const isMobile = useMobileDetect();

  useEffect(() => {
    if (isMobile) {
      props.setViewMode(GRID_VIEW);
    } else {
      props.setViewMode(user?.user_settings.products_view_mode || DEFAULT_VIEW);
    }
  }, [isMobile]);

  const updateViewMode = (viewMode: keyof typeof ProductsViewModeTypes) => {
    if (isMobile) {
      return;
    }

    props.setViewMode(viewMode);

    if (user && user.user_settings) {
      // if(user.user_settings.products_view_mode === viewMode) {
      //   return;
      // }

      user.user_settings.products_view_mode = viewMode;
      localStorage.setItem('userData', JSON.stringify(user));

    }

    http.productsViewModeHttp.updateProductsViewMode(viewMode)

      .then(({ data }) => {
        if (!user) {
          return;
        }

        const userInfo = { ...user };

        userInfo.user_settings = data;
        dispatch(setUser(userInfo));

      });

  };

  if (isMobile) {
    return null;
  }

  return (
    <div className="products-view-mode">
      <div onClick={() => updateViewMode(GRID_VIEW)} className={`icon ${props.viewMode === GRID_VIEW ? 'selected' : ''}`}>
        <Icon ios name={GRID_VIEW}></Icon>

      </div>
      <div onClick={() => updateViewMode(LIST_VIEW)} className={`icon ${props.viewMode === LIST_VIEW ? 'selected' : ''}`}>
        <Icon ios name={LIST_VIEW}></Icon>

      </div>
    </div>
  );
};

ProductsViewMode.displayName = 'ProductsViewMode';

export default ProductsViewMode;