import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { IDropdownModel } from '../Dropdown.model';
import { useOnClickOutside } from '../../../utils/hooks/useOnClickOutside';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import './StandardDropdown.scss';

type Props = {
  selectedItem: IDropdownModel;
  data?: IDropdownModel[];
  onClick?: (item: IDropdownModel) => void;
  isFlagDimension?: boolean;
};

const StandardDropdown: React.FC<Props> = (props: PropsWithChildren<Props>) => {
  const [show, setShow] = useState<boolean>(false);
  const dropdownRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  const [selectedItem, setSelectedItem] = useState<IDropdownModel>(props.selectedItem);

  useOnClickOutside(dropdownRef, () => setShow(false));

  const handleOnClick = (item: IDropdownModel) => {
    setSelectedItem(item);

    if (props.onClick) {
      props.onClick(item);
    }
  };

  useEffect(() => {
    setSelectedItem(props.selectedItem);
  }, [props.selectedItem]);

  return (
    <div ref={dropdownRef} className="standard-dropdown" onClick={() => setShow(!show)}>
      <div className="selected-data">
        <div className="info">
          {selectedItem.image ? <img className="selected-image" src={selectedItem.image} alt={selectedItem.text} width={props.isFlagDimension ? '14' : ''} height={props.isFlagDimension ? '9' : ''} /> : null}
          {selectedItem.text ? <div className="selected-value">{selectedItem.text} </div> : null}
        </div>
        <FontAwesomeIcon icon={show ? faCaretUp : faCaretDown} className="caret-icon" />
      </div>
      <div className={show ? 'show' : 'hide'}>
        <div className="content-data">
          {props.data
            ? props.data.map((item: IDropdownModel, index) => {
              return (
                <div className="item" onClick={() => handleOnClick(item)} key={index}>
                  {item.icon ? <FontAwesomeIcon icon={item.icon} className="icon" /> : null}
                  {item.image ? <img className="image" src={item.image} alt={item.text} width={props.isFlagDimension ? '14' : ''} height={props.isFlagDimension ? '9' : ''} /> : null}
                  {item.text ? <p className="text">{item.text}</p> : null}
                </div>
              );
            })
            : null}
        </div>
      </div>
    </div>
  );
};

StandardDropdown.displayName = 'MiniDropdown';

export default StandardDropdown;
