import { IHttpResponse, serverAxios } from '../../http';

class SearchByVinHttp {
  private static route = 'reliable/vin';
  private static routev2 = 'reliable/vin/v2';
  private static cache = 'reliable/vin/v2/update-cache';
  public async search(data: { vin: string, request_tecalliance?: boolean, refresh_search?: boolean }): Promise<IHttpResponse<IVinData>> {
    return serverAxios.get(SearchByVinHttp.routev2, { params: data });
  }
  public async searchByVinCatalog(data: { vin: string }): Promise<IHttpResponse<IVinData>> {
    return serverAxios.get(SearchByVinHttp.routev2, { params: data });
  }

  public async updateVinCar(data: { vin: string, ktypnr: string }): Promise<IHttpResponse<null>> {
    return serverAxios.patch(SearchByVinHttp.route, { ...data });
  }
  public async updateVinCache(data: { vin: string, ktypnr: string, cv_manufacturer: number }): Promise<IHttpResponse<null>> {
    return serverAxios.post(SearchByVinHttp.cache, { ...data });
  }

}

export interface IVinData {
  cars: null | {
    trucks: null | Array<ITrucks>;
    cars: null | Array<ICars>;
  };
  is_information_correct: boolean;
  manufacturers: null | Object;
  models: null | Array<IModelsVin>;
  search_from_tecalliance: boolean;
}

export interface IPlateForm {
  registration_plate: string
  registration_country: string
}

export interface ICars {
  body_description: string;
  capacity_qualifier: string;
  end_month: number;
  end_year: number;
  engine_capacity: string;
  fap_description: string;
  fuel: string;
  gearbox_description: string;
  horse_power?: number;
  id: number;
  is_selected: boolean;
  kw_power: number;
  manufacturer_name: string;
  manufacturer_id: string;
  model_id: string;
  model_name: string;
  model?: string;
  name: string;
  nb_of_cylinder: number;
  source: string;
  start_month: number;
  start_year: number;
  vehicle_identifier: string;
  vin: string;
  short_name: string;
  manufacturerName: string;
  valves_description: string;
  power_kw_from: number;
  power_hp_from?: number;
}

export interface ITrucks {
  body_description: string;
  country_code: string;
  end_month: string;
  end_year: string;
  engine_capacity: string;
  first_reg_date?: string;
  fuel?: string;
  id: number;
  is_selected: boolean;
  manufacturer_name: string;
  model_name: string;
  name: string;
  power_hp_from?: number;
  power_hp_to?: number;
  power_kw_from?: number;
  power_kw_to?: number;
  source: string;
  start_month: string;
  start_year: string;
  vehicle_identifier: string;
  vin: string;
  weight: number;
}


export interface IModelsVin {
  id: number;
  model_name: string;
  manufacturer_name: string;
  manufacturer_id: string;
  source: string;
  vehicle_identifier: string;
}

export default SearchByVinHttp;
