import React, { useRef, useState } from 'react';

import { IVehicleRepairItemWorkInfo } from 'utils/rmi/interface/graphics.interface';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import ReactTooltip from 'react-tooltip';

import { useOnClickOutside } from 'utils/hooks/useOnClickOutside';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';

type Props = {

  item;
  i: number;
};

const TooltipRepairItem: React.FC<Props> = (props) => {

  const [hideTooltip, setHideTooltip] = useState(true);
  const { isMobile } = useDeviceDetect();

  const ref = useRef() as React.MutableRefObject<HTMLDivElement>;

  useOnClickOutside(ref, () => {
    setHideTooltip(false);
  });


  return (

    <span ref={ref} onClick={() => setHideTooltip(true)}>
      {props.item.ExclusiveWorkPositions.length ?

        <FontAwesomeIcon icon={faCircleInfo} onMouseEnter={() => setHideTooltip(true)}
          onMouseLeave={() => {
            setHideTooltip(false);
            setTimeout(() => setHideTooltip(true), 100);
          }}
          data-tip
          data-for={props.i + props.item.WorkText}
        /> : null
      }

      {hideTooltip && (
        <ReactTooltip
          event={isMobile ? 'click' : undefined}
          id={String(props.i) + props.item.WorkText}
          className="tooltip-class" place="top" effect="solid"
          clickable

        >
          <ul>
            {
              props.item.ExclusiveWorkPositions.map((item: IVehicleRepairItemWorkInfo, index: number) => (
                <li key={index}>
                  {`${item.WorkText} [${item.QualColText}]`}
                </li>
              ))
            }
          </ul>
        </ReactTooltip>
      )}
    </span>
  );

};

TooltipRepairItem.displayName = 'TooltipRepairItem';

export default TooltipRepairItem;