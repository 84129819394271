import React, { PropsWithChildren } from 'react';

import './Discutions.scss';
import { ICatalogSupplierMappingGroup } from '../../models/catalog.model';
import { IMessageSupplier, IMessageSupplierPaginate } from '../../interfaces/messaging';
import CatalogMessageItem from './CatalogMessageItem/CatalogMessageItem';
import Spinner from '../../components/spinner/Spinner';
import Select from 'react-select';
import CatalogMessageHeader from './CatalogMessageHeader/CatalogMessageHeader';
import Pagination from '../../components/pagination/Pagination';
import Modal from '../../components/modal/Modal';
import { contentStyle } from '../../applications/amerigo/views/select-client/ModalClient/ContentStyleModal';
import NewMessageModal from './NewMessageModal/NewMessageModal';
import { useTranslation } from 'react-i18next';
import useMessageText from 'utils/hooks/useMessageText';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';

type Props = {
  suppliers: ICatalogSupplierMappingGroup[]
  messages: IMessageSupplier[] | []
  pagination: IMessageSupplierPaginate;
  loading: boolean;
  handleChangeSupplier: (item) => void;
  selectedSupplier: ICatalogSupplierMappingGroup | null
  handlePageClick: (page: number) => void;
  openModal: boolean;
  setOpenModal: (e: boolean) => void;
  getMessages: () => void;

};


const DiscutionsView: React.FC<Props> = (props: PropsWithChildren<Props>) => {
  const { t } = useTranslation();
  const messageText = useMessageText();
  const { isMobile, isTablet } = useDeviceDetect();

  const selectOptions = () => {
    return (
      props.suppliers.map((item) => ({
        label: item.supplier_mapping_group_name,
        value: item.supplier_mapping_group_id,
      })) || []
    );
  };

  const selectedOption = () => {
    if (!props.selectedSupplier) {
      return null;
    }

    return {
      label: props.selectedSupplier?.supplier_mapping_group_name,
      value: props.selectedSupplier?.supplier_mapping_group_id,
    };
  };

  const contentStyleModal = contentStyle;

  contentStyleModal.content.backgroundColor = '#F1F5FC';
  contentStyleModal.content.maxWidth = '100rem';

  contentStyleModal.content.maxWidth = '100rem';

  return (
    <div className="container-discutions">
      <div className="wrapper">
        <Modal
          openModal={props.openModal}
          setOpenModal={props.setOpenModal}
          childrenComponent={<NewMessageModal suppliers={props.suppliers} getMessages={props.getMessages} options={selectOptions()} setOpenModal={props.setOpenModal} />}
          contentStyle={contentStyleModal}
        />
        <div className="row">
          <div className="search">
            <Select
              className="select-supplier"
              placeholder={t('_find_supplier')}
              value={selectedOption()}
              onChange={(val) => {
                props.handleChangeSupplier(val);
              }}
              options={selectOptions()}
              isSearchable={!isMobile && !isTablet}
              isClearable
            />
          </div>
          <h1>{messageText}</h1>
          <button onClick={() => props.setOpenModal(true)}>{t('_new_message')}</button>
        </div>
        <div className="messages">
          <div className="header">
            <div className="supplier"></div>
            <div className="message"></div>
            <div className="created-time"></div>
          </div>
          <div className="content">
            {props.loading ? (
              <Spinner />
            ) : props.messages?.length ? (
              <div>
                <CatalogMessageHeader headerOne={t('_supplier')} headerTwo={t('_contents')} headerThree={t('_last_message')} />
                {props.messages.map((item, index) => {
                  return <CatalogMessageItem key={index} messageItem={item} />;
                })}
              </div>
            ) : (
              <div className="no-messages">
                <p>{t('_no_messages')}</p>
              </div>
            )}
          </div>
        </div>
        {props.pagination.total_pages ? (
          <Pagination handlePageClick={props.handlePageClick} forcePage={props.pagination.page} pageCount={props.pagination.total_pages}></Pagination>
        ) : null}
      </div>
    </div>
  );
};

DiscutionsView.displayName = 'DiscutionsView';

export default DiscutionsView;
