import React, { useEffect, useState } from 'react';
import {
  IEquivalenceModel,
  IEquivalenceTruck,
  IEquivalenceTrucksResponse,
  IEquivalenceTruckVersion,
  INameId,
} from '../../../../../models/car-parts.model';
import { IOption } from '../../../../../interfaces/select';
import Select, { SingleValue } from 'react-select';
import Spinner from '../../../../spinner/Spinner';
import '../EquivalenceCar/EquivalenceCar.scss';
import TruckEquivalenceItem from './VehicleEquivalenceItem/TruckEquivalenceItem';
import { useTranslation } from 'react-i18next';
import SwiperSlider from 'components/SwiperSlider/SwiperSlider';
import { SwiperSlide } from 'swiper/react';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';

type Props = {
  loading: boolean;
  data?: IEquivalenceTrucksResponse;
};

const EquivalenceTruck: React.FC<Props> = (props) => {
  const [allModels, setAllModels] = useState<IEquivalenceModel[]>([]);
  const { t } = useTranslation();

  const [modelsOptions, setModelsOptions] = useState<IOption[]>([]);
  const [selectedModel, setSelectedModel] = useState<SingleValue<IOption>>();

  const [selectedManufacturer, setSelectedManufacturer] = useState<SingleValue<IOption>>();
  const [manufacturersOptions, setManufacturersOptions] = useState<IOption[]>([]);

  const [dataEquivalenceCarVersions, setDataEquivalenceCarVersions] = useState<IEquivalenceTruckVersion[]>([]);
  const [dataFiltered, setDataFiltered] = useState<IEquivalenceTruck[]>([]);

  useEffect(() => {
    getEquivalences();
  }, []);


  useEffect(() => {
    setModelsOptions([]);
    setSelectedModel(null);
    const filteredEquivalences = allModels.filter((model) => {
      return String(model.manufacturer_id) === String(selectedManufacturer?.value);
    });

    if (filteredEquivalences.length > 0) {
      const filteredEquivalencesOptions = transformModelOptions(filteredEquivalences);

      setModelsOptions(filteredEquivalencesOptions);
      setSelectedModel(filteredEquivalencesOptions[0]);
    }
  }, [selectedManufacturer]);

  useEffect(() => {
    const dataEquivalenceCarManufacturerFiltered = dataEquivalenceCarVersions.filter((model) => {
      return String(model.manufacturer_id) === String(selectedManufacturer?.value);
    });

    if (dataEquivalenceCarManufacturerFiltered.length) {
      const dataEquivalenceCarVersionsFiltered = dataEquivalenceCarManufacturerFiltered[0].trucks.filter((version) => {
        return version.model_id === selectedModel?.value;
      });

      setDataFiltered(dataEquivalenceCarVersionsFiltered);
    }
  }, [selectedModel]);

  const getEquivalences = async () => {
    if (!props.data) {
      return;
    }

    try {
      setAllModels(props.data.models);
      setDataEquivalenceCarVersions(props.data.truck_versions);
      const optionsManufacturerFromResponse: INameId[] = props.data.filters;

      if (optionsManufacturerFromResponse) {
        const optionFromResponseTransform = transformOptions(optionsManufacturerFromResponse);

        setManufacturersOptions(optionFromResponseTransform);
        setSelectedManufacturer(optionFromResponseTransform[0]);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const transformOptions = (optionsFromResponse: INameId[]) => {
    return optionsFromResponse?.map((item) => ({
      label: item.name,
      value: String(item.id),
    }));
  };

  const transformModelOptions = (optionsFromResponse: IEquivalenceModel[]) => {
    return optionsFromResponse?.map((item) => ({
      label: item.name,
      value: String(item.id),
    }));
  };

  const { isMobile, isTablet } = useDeviceDetect();

  return (
    <div className="equivalence-vehicle eq-truck-parts-view">
      {props.loading ? (
        <Spinner />
      ) : manufacturersOptions.length ? (
        <div className="content">
          <div className="filters">
            <div className="manufacturers">
              <p className="select-title">{t('_manufacturer')}</p>
              <Select
                className="select-element"
                placeholder={t('_manufacturer')}
                value={selectedManufacturer}
                onChange={(val) => {
                  setSelectedManufacturer(val);
                }}
                options={manufacturersOptions}
              />
            </div>

            <div className="manufacturers">
              <p className="select-title">{t('_model')}</p>
              <Select
                className="select-element"
                placeholder={t('_model')}
                value={selectedModel}
                onChange={(val) => {
                  setSelectedModel(val);
                }}
                options={modelsOptions}
              />
            </div>
          </div>
          <div className="list">
            {dataFiltered.length ? (
              isMobile || isTablet ? (
                <SwiperSlider slidesPerView="auto" isLoading={props.loading} navigation centeredSlides={false}>
                  <div>
                    {dataFiltered.map((item, index) => {
                      return (
                        <SwiperSlide className="swiper-slide" key={index}>
                          <TruckEquivalenceItem key={index} selectedManufacturer={selectedManufacturer} version={item} />
                        </SwiperSlide>
                      );
                    })}
                  </div>
                </SwiperSlider>
              ) : (
                dataFiltered.map((item, index) => {
                  return <TruckEquivalenceItem key={index} selectedManufacturer={selectedManufacturer} version={item} />;
                })
              )
            ) : (
              <div className="items-no-data">
                <p>{t('_no_data')}</p>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="no-data">
          <p>{t('_no_data')}</p>
        </div>
      )}
    </div>
  );
};

EquivalenceTruck.displayName = 'EquivalenceTruck';

export default EquivalenceTruck;
