import React from 'react';
import { useNavigate } from 'react-router-dom';
import { clearAuthTokens } from 'axios-jwt';
import { logout } from 'store/reducers/useSlices';
import { useAppDispatch, useAppSelector } from 'utils/hooks/redux';
import HomeMecadepotView from './HomeMecadepotView.view';

const HomeMecadepot: React.FC = () => {
  const navigate = useNavigate();

  const user = useAppSelector((state) => state.userData.user);

  const dispatch = useAppDispatch();

  const handleLogout = () => {
    dispatch(logout());
    clearAuthTokens();
    navigate('/login');
  };

  return <HomeMecadepotView handleLogout={handleLogout} user={user}></HomeMecadepotView>;
};

HomeMecadepot.displayName = 'HomeMecadepot';

export default HomeMecadepot;
