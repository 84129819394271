import React, { useEffect, useState } from 'react';
import { getURLParams } from 'utils/marketplace';

import useHttp from 'utils/hooks/useHttp';
import 'react-datepicker/dist/react-datepicker.css';
import './StockPriceManagementAdmin.scss';
import { useTranslation } from 'react-i18next';
import { useLocaleDatePicker } from 'utils/localeDatePicker';
import i18n from 'i18n';
import Modal from 'components/modal/Modal';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import ModalDelete from 'components/CatalogNaf/ModalDelete/ModalDelete';
import StockPriceManagementTable from './Table/StockPriceManagementTable';
import { IDataResponseStockPrice, IDataStockPrice, IStockFilterPrice } from 'models/stockPrice';
import StockCreatePrice from './ModalCreate/StockCreatePrice';
import StockEditPrice from './ModalEdit/StockEditPrice';
import StockPriceFilter from 'components/filter/StockPriceFilter/StockPriceFilter';


interface IURLParams {
  page?: number;
}
const StockPriceManagementAdmin: React.FC = () => {
  const http = useHttp();
  const urlParams: IURLParams = getURLParams(location.search);
  const [data, setData] = useState<IDataStockPrice[]>([]);
  const [paginationData, setPaginationData] = useState<IDataResponseStockPrice>();
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [openFilter, setOpenFilter] = useState(false);
  const [openModalCreate, setOpenModalCreate] = useState<boolean>(false);
  const [submit, setSubmit] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [filter, setFilter] = useState<IStockFilterPrice>();
  const [loadingFilter, setLoadingFilter] = useState<boolean>(false);
  const cancelToken = axios.CancelToken.source();
  const [searchParams] = useSearchParams();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openModalDelete, setOpenModalDelete] = useState<boolean>(false);
  const [editItem, setEditItem] = useState<IDataStockPrice>();
  const [deleteId, setDeleteId] = useState<number>();

  useLocaleDatePicker(i18n.language);

  function getStockList(params) {
    setLoading(true);
    http.stockPriceHttp
      .getStockPriceList(params, cancelToken.token)
      .then((res) => {
        const { data, page, total_pages } = res.data as IDataResponseStockPrice;

        setData(data);
        setPaginationData({ data, page, total_pages });
      })
      .finally(() => {
        setLoading(false);
      });
  }


  useEffect(() => {

    let param = {};

    searchParams.forEach((value, key) => {

      param = { ...param, [key]: String(value) };
    });
    getStockList(Object.keys(param).length ? param : { page: urlParams.page ?? 1 });
    setSubmit(false);

  }, [submit, searchParams.get('data_supplier'), searchParams.get('reference')]);


  useEffect(() => {
    http.stockPriceHttp.getSupplierFilter()
      .then((res) => {

        const setSuppliersData = res.data.map((item) => {
          return { value: item.id, label: item.name };
        });

        setFilter({ suppliers: setSuppliersData });
        setLoadingFilter(false);
      }).catch(() => {
        setLoadingFilter(false);
      });


  }, []);


  useEffect(() => {
    if (paginationData) {
      setCurrentPage(paginationData.page);
      setTotalPage(paginationData.total_pages);
    }
  }, [paginationData]);

  const handlePageClick = async (data: number) => {
    setCurrentPage(data);
    setLoading(true);
    const selectPage = data;

    await getStockList({ page: selectPage });
  };

  const openModalEditItem = (item: IDataStockPrice) => {
    setOpenModal(true);
    setEditItem(item);

  };

  const openModalDeleteItem = (item: IDataStockPrice) => {
    setOpenModalDelete(true);
    setDeleteId(item.id);

  };

  return (
    <div className="stock-price">
      <div className="stock-header">
        <div className="title">
          {t('_customer_stock_price')}
        </div>
        <div className="btn-new">
          <button onClick={() => setOpenModalCreate(true)}>  {t('_create_stock_price')}</button>
        </div>
        <div className="filter">
          <div className="title-filter">
            {t('_filter_desc_stock_price')}
          </div>
          <div className={`btn-filter ${openFilter ? 'active' : ''}`}>
            <button onClick={() => setOpenFilter(true)}>{t('_filter_result')}</button>
          </div>
        </div>

      </div>
      <div className="stock-table">
        <StockPriceManagementTable data={data} handlePageClick={handlePageClick} openModalDeleteItem={openModalDeleteItem} openModalEditItem={openModalEditItem} currentPage={currentPage} totalPage={totalPage} loading={loading} />
      </div>

      <Modal
        openModal={openModalCreate}
        setOpenModal={setOpenModalCreate}
        childrenComponent={<StockCreatePrice setOpenModalCreate={setOpenModalCreate} setSubmit={setSubmit} />}
      />

      <Modal
        openModal={openModal}
        setOpenModal={setOpenModal}
        childrenComponent={<StockEditPrice setOpenModal={setOpenModal} item={editItem} setSubmit={setSubmit} />}
      />
      <StockPriceFilter
        openFilter={openFilter}
        setOpenFilter={setOpenFilter}
        filter={filter}
        loadingFilter={loadingFilter}
      />

      <Modal
        openModal={openModalDelete}
        setOpenModal={setOpenModalDelete}
        childrenComponent={
          <ModalDelete setOpenModalDelete={setOpenModalDelete} setSubmit={setSubmit} id={deleteId} link={http.stockPriceHttp.deleteStock} />}
      />

    </div >
  );
};

export default StockPriceManagementAdmin;
