import React from 'react';
import './CartAvailability.scss';

type Props = {
  limit: number;
  isAvailable: boolean;
  unspecified?: boolean;
};
const CartAvailabilityView: React.FC<Props> = (props: Props) => {

  return (
    <div className="cart-availability-wrapper">
      {
        props.unspecified ? (
          <span>-</span>
        ):(
          <div className="value">
            <div className={`item ${props.isAvailable ? 'green' : 'red'}`}></div>
            <div>{props.limit}</div>
          </div>
        )
      }

    </div>
  );
};

CartAvailabilityView.displayName = 'CartAvailabilityView';

export default CartAvailabilityView;