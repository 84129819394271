import Modal from 'components/modal/Modal';
import ComponentsForTypeModal from 'components/ModalTCRMI/ComponentsForTypeModal';
import i18n from 'i18n';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { RootState } from 'store/store';
import useHttp from 'utils/hooks/useHttp';
import useReliableCatalogCarQueryParams from 'utils/hooks/useReliableCatalogCarQueryParams';
import { getQueryParamsEntryLikeObject } from 'utils/http';
import { ISelectModalDiagnostic } from 'utils/rmi/interface/diagnostic.interface';
import { IRelaysFuses } from 'utils/rmi/interface/relays-fuses';
import { IRMIModulesLinkChildren, IRMIModulesLinkModal, RELIABLE_TAB_NAMES } from 'utils/rmi/reliableTabs';
import { getCurrentCountryRMI, getCurrentLanguageRMI } from 'utils/rmi/rmi-utils/rmi-utils';
import VehicleIframe from '../../VehicleIframe/VehicleIframe';
import ComponentsLinkModal from '../ComponentsLinkModal/ComponentsLinkModal';
import './RelaysFuses.scss';

const RelaysFuses: React.FC = () => {

  const { t } = useTranslation();
  const { typeId } = useSelector((state: RootState) => state.tecrmi);
  const { vehicle_id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const reliableCatalogCarQueryParams = useReliableCatalogCarQueryParams();


  const [iframeHTMLloading, setIframeHTMLloading] = useState<boolean>(false);
  const [iframeHTML, setIframeHTML] = useState<string>('');
  const navigate = useNavigate();
  const http = useHttp();
  const [componentTypeId, setComponentTypeId] = useState<number | null>();

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openModalComponentType, setOpenModalComponentType] = useState<boolean>(false);
  const [modalOptions, setModalOptions] = useState<ISelectModalDiagnostic[]>([]);

  const [openModalLink, setOpenModalLink] = useState<boolean>(false);
  const [rmiModulesLinkModal, setRmiModulesLinkModal] = useState<Array<IRMIModulesLinkModal>>([]);


  let params: IRelaysFuses = {

    typeId,
    countryCode: getCurrentCountryRMI(i18n.language),
    languageCode: getCurrentLanguageRMI(i18n.language),
  };

  useEffect(() => {
    if (!typeId) {
      return;
    }

    params = {
      ...params,
      typeId
    };

    http.relaysFusesHttp.fetchRMIComponentsForType(params).then(({ data }) => {

      if (data.length > 1) {
        setModalOptions(data.map(type => {
          return {
            value: type.ComponentTypeId,
            label: type.ComponentTypeName
          };
        }));

      } else {
        setComponentTypeId(data[0].ComponentTypeId);
      }
    });


  }, [typeId]);

  useEffect(() => {

    if (!typeId && componentTypeId == null) {
      return;
    }

    if (componentTypeId !== null && searchParams.get('buildPeriodQualColId') !== null && searchParams.get('boxConfigurationMappingId') !== null) {

      params = {
        ...params,
        componentTypeId,
        buildPeriodQualColId: +searchParams.get('buildPeriodQualColId')!,
        boxConfigurationMappingId: +searchParams.get('boxConfigurationMappingId')!,
      };

      fetchBoxDetailHtml();
    } else {

      setIframeHTMLloading(true);
      http.relaysFusesHttp.fetchRMIBoxOverviewHtml({
        typeId,
        componentTypeId,
        countryCode: getCurrentCountryRMI(i18n.language),
        languageCode: getCurrentLanguageRMI(i18n.language),
      }).then(({ data }) => {
        setIframeHTML(data);

      }).finally(() => { setIframeHTMLloading(false); });
    }

  }, [typeId, componentTypeId, searchParams.get('buildPeriodQualColId'), searchParams.get('boxConfigurationMappingId'), i18n.language]);

  const fetchBoxDetailHtml = (): void => {
    setIframeHTMLloading(true);

    http.relaysFusesHttp.fetchRMIBoxDetailHtml(params).then(({ data }) => {
      if (data && data.length) {
        setIframeHTML(data);
      } else {
        // Notification({
        //   container: 'top-center',
        //   message: t('_no_data'),
        //   type: 'warning',
        //   title: t('INFORMATION'),
        // });
      }
    })
      .catch((e: Error) => console.error(e))
      .finally(() => setIframeHTMLloading(false));
  };


  const onFuseClickHandler = (event): void => {
    const itemMpId = event.target?.parentElement?.parentElement?.id;

    if (Number(itemMpId) && componentTypeId !== null) {
      const params = {
        typeId,
        componentTypeId,
        itemMpId,
        countryCode: getCurrentCountryRMI(i18n.language),
        languageCode: getCurrentLanguageRMI(i18n.language),
      };

      http.relaysFusesHttp.fetchRMIBoxDetailList(params).then(({ data }) => {

        const { BoxConfigurations } = data;


        if (BoxConfigurations.length > 1) {
          const options: ISelectModalDiagnostic[] = [];

          BoxConfigurations.forEach(box => {
            const group: ISelectModalDiagnostic = {
              label: box.QualColText.length ? box.QualColText : box.BuildPeriodQualColText,
              options: []
            };

            box.BoxBuildPeriodQualCols.forEach(boxConfiguration => {
              group.options?.push(
                {
                  value: `${boxConfiguration.QualColId}/${box.BoxConfigurationId}`,
                  label: boxConfiguration.QualColText
                }
              );
            });

            options.push(group);
          });

          setModalOptions(options);
          setOpenModal(true);

        } else {

          const [group] = BoxConfigurations;

          const singleGroup = {
            value: group.BuildPeriodQualColId,
            label: group.BuildPeriodQualColText,
            boxConfigurationMappingId: group.BoxConfigurationId,
          };

          modalVersionSelectHandler(singleGroup);
        }
      }).catch((e: Error) => console.error(e));
    }
  };


  const modalVersionSelectHandler = (selectedDetailList): void => {

    delete params.itemMpId;
    params = {
      ...params,
      buildPeriodQualColId: selectedDetailList.value,
      boxConfigurationMappingId: selectedDetailList.boxConfigurationMappingId,
    };

    const query = (getQueryParamsEntryLikeObject(searchParams));

    setSearchParams({ ...query, ...searchParams, ...params });

  };

  const selectModalHandler = (option: ISelectModalDiagnostic | null) => {
    const value = String(option?.value)?.split('/');

    if (value.length) {
      const [qualColId, BoxConfigurationId] = value;

      const singleGroup = {
        value: qualColId,
        label: '',
        boxConfigurationMappingId: BoxConfigurationId,
      };


      searchParams.set('buildPeriodQualColId', qualColId);
      searchParams.set('boxConfigurationMappingId', BoxConfigurationId);

      modalVersionSelectHandler(singleGroup);
    }
  };

  const selectModalComponentHandler = (option: ISelectModalDiagnostic | null) => {
    const value = (option?.value);

    value && setComponentTypeId(+value);
  };


  const fetchLinkedWiringDiagram = (hrefId: string): void => {
    const itemMpId = Number(hrefId?.match(/\d+/)?.join(''));

    http.relaysFusesHttp.fetchRMILinkedWiringDiagrams({
      typeId: params.typeId,
      countryCode: params.countryCode,
      languageCode: params.languageCode,
      itemMpId,
    }).then(({ data }) => {
      const { Manuals, ItemMpText } = data[0];

      if (Manuals) {
        if (Manuals?.length > 1) {

          const arrayToSend: Array<IRMIModulesLinkModal> = [];
          const paramsObject = { ...reliableCatalogCarQueryParams };
          const childrenTree: Array<IRMIModulesLinkChildren> = [];


          Manuals && Manuals.forEach((manual) => {
            paramsObject['manual_id'] = manual.ManualId.toString();
            // paramsObject['source'] = searchParams.get('source');
            // paramsObject['vehicle_identifier'] = searchParams.get('vehicle_identifier');

            childrenTree.push(
              {
                label: manual.QualColText,
                link: `/reliable/rmi/${vehicle_id}/technical-documentation/${RELIABLE_TAB_NAMES.WIRING}?${(new URLSearchParams(paramsObject)).toString()}`,
                children: null
              }
            );
          });

          if (childrenTree.length) {
            if (ItemMpText) {
              arrayToSend.push(
                {
                  tabName: t('_wiring_diagrams'),
                  children: [{
                    label: ItemMpText,
                    link: null,
                    children: childrenTree
                  }
                  ]
                });
            } else {
              childrenTree.length && arrayToSend.push(
                {
                  tabName: t('_wiring_diagrams'),
                  children: childrenTree
                }
              );
            }

          }

          setRmiModulesLinkModal(arrayToSend);
          setOpenModalLink(true);
        } else {

          const [manual] = Manuals;
          const paramsObject = { manual_id: manual.ManualId, ...reliableCatalogCarQueryParams };
          const link = `/reliable/rmi/${vehicle_id}/technical-documentation/${RELIABLE_TAB_NAMES.WIRING}?${(new URLSearchParams(paramsObject)).toString()}`;

          navigate(link);

        }
      }
    }).catch((e: Error) => console.error(e));
  };

  return (
    <div className="technical-documentation-relays-fuses">
      <div className="tdoc-content">
        <Modal
          openModal={openModalLink}
          setOpenModal={setOpenModalLink}
          childrenComponent={<ComponentsLinkModal setOpenModal={setOpenModalLink} rmiModulesLink={rmiModulesLinkModal} />}
        />
        <Modal
          openModal={openModal}
          setOpenModal={setOpenModal}
          shouldCloseOnOverlayClick={false}
          childrenComponent={<ComponentsForTypeModal setOpenModal={setOpenModal} boxDetailOptions={modalOptions} setSelectHandler={selectModalHandler} />}
        />
        <Modal
          openModal={openModalComponentType}
          setOpenModal={setOpenModalComponentType}
          shouldCloseOnOverlayClick={false}
          childrenComponent={<ComponentsForTypeModal setOpenModal={setOpenModal} boxDetailOptions={modalOptions} setSelectHandler={selectModalComponentHandler} />}
        />
        {iframeHTML &&
          <VehicleIframe
            onClickHandler={onFuseClickHandler}
            onIframeAnchorClick={fetchLinkedWiringDiagram}
            loading={iframeHTMLloading}
            vehicleIframe={iframeHTML!}
          />
        }
      </div>
    </div>
  );
};

export default RelaysFuses;