import React from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as PartsNotFoundImage } from 'assets/images/parts-not-found.svg';
import CatalogPartsItemListView from './CatalogPartsItemList.view';
import { getIsLoadingParts } from 'store/selectors/car-parts.selector';
import Spinner from 'components/spinner/Spinner';
import './CatalogPartsItemList.scss';
import { useTranslation } from 'react-i18next';

type Props = {
  parts;
  updateData?;
};

const CatalogPartsItemList: React.FC<Props> = (props: Props) => {
  const isLoading = useSelector(getIsLoadingParts);

  const { t } = useTranslation();

  const partsData = () => {
    return Array.isArray(props.parts) && props.parts.length ? (
      <React.Fragment>
        {props.parts.map((part, index) => (
          <CatalogPartsItemListView
            isLoading={isLoading}
            key={index}
            isFavorite={part.is_favorite}
            image={part.image}
            logo={part.data_supplier_logo}
            title={part.data_supplier_reference_original}
            description={part.description}
            tags={part.tags}
            info={part.package_components}
            barcode={part.barcode}
            available={part.available}
            data={part}
            price={part.price}
            codeNumber=""
            updateData={props.updateData}
          />
        ))}
      </React.Fragment>
    ) : (
      <div className="no-parts-data">
        <div className="notFoundSvg">
          <PartsNotFoundImage />
        </div>
        <h1>{t('_parts_not_found')}</h1>
      </div>
    );
  };

  return !isLoading ? partsData() : <Spinner></Spinner>;
};

CatalogPartsItemList.displayName = 'CatalogItem';

export default CatalogPartsItemList;
