import { createAsyncThunk } from '@reduxjs/toolkit';
import { ICategoriesParams } from 'utils/hooks/http/categories.http';
import useHttp from 'utils/hooks/useHttp';

export const fetchCategoriesVin = createAsyncThunk('fetchCategoriesVin', async (params: ICategoriesParams, { rejectWithValue }) => {
  try {
    const http = useHttp();
    const response = await http.categories.getCategoriesVin(params);

    return response;
  } catch (e) {

    console.log('fetch catalogs error');

    return [];
  }
});
