import React, { useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useAppDispatch } from './redux';
import useHttp from './useHttp';
import { SOURCE_POLK } from '../constants/defaultConstants';
import { fetchVehicleTreeAdcTypeIdByTcdTypeAndClass } from 'store/actions/tecrmi.actions';
import { setTecRMITypeId } from 'store/slices/tecrmi.slice';

const useTecrmiTypeId = (): void => {

  const http = useHttp();
  const { vehicle_id } = useParams();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();

  const fetchRMI = async (): Promise<void> => {

    try {
      dispatch(setTecRMITypeId(null));

      if (searchParams.get('source') === SOURCE_POLK) {
        http.authTecRmi.fetchTcdIdByPlkId({ vehicle_id }).then(async ({ data }) => {
          const { payload } = await dispatch(fetchVehicleTreeAdcTypeIdByTcdTypeAndClass(Number(data)));

          if (payload) {
            dispatch(setTecRMITypeId(payload));
          }

        });
      } else {
        const { payload } = await dispatch(fetchVehicleTreeAdcTypeIdByTcdTypeAndClass(Number(vehicle_id)));

        if (payload) {
          dispatch(setTecRMITypeId(payload));
        }
      }


    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    fetchRMI();
  }, []);
};

export default useTecrmiTypeId;