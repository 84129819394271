import React, { useEffect, useState } from 'react';
import Modal from 'components/modal/Modal';
import { ICreateClientForm } from 'models/client.model';
import { useForm } from 'react-hook-form';
import { useAppDispatch } from 'utils/hooks/redux';
import { notify } from 'utils/marketplace';
import useHttp from 'utils/hooks/useHttp';
import NewClientView from './NewClient.view';
import { IClientOption, IOption } from 'interfaces/select';
import { getLanguagesAsClientsOption } from 'utils/constants/locales';
import { fetchClients } from 'store/reducers/clients/clients.actions';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { IDateOption } from 'models/date-picker';

type Props = {
  showNewClient?: boolean;
  handleNewClientModal?: () => void;
};

const NewClient: React.FC<Props> = (props: Props) => {
  const {
    control,
    getValues,
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ICreateClientForm>();

  const http = useHttp();
  const [loading, setLoading] = useState(false);
  const [businessTypeOptions, setBusinessTypeOptions] = useState<IOption[]>([{ label: '', value: '' }]);
  const [countriesOptions, setCountriesOptions] = useState<IOption[]>([{ label: '', value: '' }]);
  const [componentMounted, setComponentMounted] = useState<boolean>(true);
  const [passwordMarchError, setPasswordMatchError] = useState<boolean>(false);
  const [languagesOptions, setLanguagesOptions] = useState<IOption[]>([{ label: '', value: '' }]);
  const [subscriptionValueDate, setSubscriptionValueDate] = useState<IDateOption>();
  const [files, setFiles] = useState<File[] | []>([]);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {pendingInvitations} = useSelector((state: RootState) => state.pendingInvitations);

  useEffect(()=>{
    if(pendingInvitations){
      const {email, full_name, phone, address, store} = pendingInvitations;

      setValue('email', email);
      setValue('client_name', full_name);
      setValue('phone', phone);
      setValue('client_address', address);

      if(store){
        setValue('client_business_type_id', store.id);
      }
    }

  },[pendingInvitations]);

  useEffect(()=>{
    if(businessTypeOptions.length){
      setValue('client_business_type_id', businessTypeOptions[0].value);
    }

    if(countriesOptions.length){
      setValue('client_country_id', countriesOptions[0].value);
    }
  },[businessTypeOptions, countriesOptions]);

  useEffect(() => {
    if (componentMounted) {
      fetchRequests();
    }
  }, []);

  const getOption = (option) => {
    return option.map((item) => ({
      label: item.name || '',
      value: item.id || '',
    })) || [];
  };

  const fetchRequests = () => {
    Promise.all([http.clients.getBusinessTypeOptions(), http.clients.getCountriesOptions()])
      .then((res) => {
        setBusinessTypeOptions([...getOption(res[0].data)]);
        setCountriesOptions([...getOption(res[1].data)]);
        setLanguagesOptions([...getLanguagesAsClientsOption()]);
      })
      .finally(() => {
        setLoading(false);
        setComponentMounted(false);
      });
  };

  const onSubmit = () => {
    const client = getValues();

    if (client.password?.first !== client.password?.second) {
      setPasswordMatchError(true);

      return;
    }


    setLoading(true);
    setPasswordMatchError(false);
    
    
    const formData = new FormData();

    formData.append('client_image', selectedFile ? selectedFile : 'null');
    formData.append('password', JSON.stringify(client.password));
    formData.append('subscription_start_date', String(subscriptionValueDate?.start_date));
    formData.append('subscription_end_date', String(subscriptionValueDate?.end_date));

    if(pendingInvitations?.id){
      formData.append('invitation_id', pendingInvitations.id);
    }

    delete client.password;
    delete client.client_image;
    
    for (const key in client) {
      formData.append(key, client[key]);
    }

    http.clients
      .createClient(formData)
      .then(() => {
        dispatch(fetchClients(1));
        setLoading(false);
        props.handleNewClientModal!();
        navigate('/espace-clients/my-clients');
      })
      .catch((error) => {
        setLoading(false);
        notify(error?.message ? error?.message : 'Error creating the user');
      });
  };


  return (
    <Modal
      openModal={props.showNewClient}
      setOpenModal={props.handleNewClientModal}
      childrenComponent={
        <NewClientView
          setFiles={setFiles}
          setSelectedFile={setSelectedFile}
          files={files}
          onSubmit={onSubmit}
          handleSubmit={handleSubmit}
          register={register}
          handleNewClientModal={props.handleNewClientModal}
          businessTypeOptions={businessTypeOptions}
          countriesOptions={countriesOptions}
          languagesOptions={languagesOptions}
          loading={loading}
          errors={errors}
          passwordMarchError={passwordMarchError}
          control={control}
          setSubscriptionValueDate={setSubscriptionValueDate}
        ></NewClientView>
      }
    ></Modal>
  );
};

NewClient.displayName = 'NewClient';

export default NewClient;
