import SkeletonSpinner from 'components/skeleton-spinner/SkeletonSpinner';
import React, { MutableRefObject, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { createSearchParams, generatePath, useNavigate } from 'react-router-dom';
import { useOnClickOutside } from 'utils/hooks/useOnClickOutside';
import { getBackendAssetsBasePath } from 'utils/marketplace';
import { IBatterySearch } from 'interfaces/battery';
import './BatteryDropdownContent.scss';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import DropdownContentTable from 'components/DropdownContentTable/DropdownContentTable';
import Head from 'components/tables/Cells/Head/Head';
import { Column } from 'react-table';
import { isRetailerMecadepotChild } from 'utils/isRetailerMecadepotChild';

type Props = {
  data: IBatterySearch[] | undefined;
  setIsOpen: (isOpen: boolean) => void;
  loading: boolean;
  value?: string;
};

const BatteryDropdownContent: React.FC<Props> = (props) => {
  const batteryDropdownRef = useRef() as MutableRefObject<HTMLDivElement>;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { isMobile } = useDeviceDetect();
  const isMecadepotChild = isRetailerMecadepotChild();

  useOnClickOutside(batteryDropdownRef, () => props.setIsOpen(false));

  function handleBatteryClick(item) {
    const params = {
      page: '1',
      category_ids: item.category_id,
      brand_ids: item.brand_id,
      battery_id: item.battery_id,
      ah: item.ah,
      amp: item.amp,
      q: props.value
    };

    const validParams = Object.entries(params).reduce((acc, [key, value]) => {
      if (!value) {
        return acc;
      }

      acc[key] = value;

      return acc;
    }, {});

    navigate({
      pathname: generatePath('/batteries'),
      search: `?${createSearchParams(validParams)}`,
    });
    props.setIsOpen(false);
  }

  // function handleBatteryCategoryClick(e, item) {
  //   e.stopPropagation();
  //   const params ={
  //     page: '1',
  //     category_ids: item.category_id,
  //     q: props.value
  //   };

  //   const validParams = Object.entries(params).reduce((acc, [key, value]) => {
  //     if (!value) {
  //       return acc;
  //     }

  //     acc[key] = value;

  //     return acc;
  //   }, {});

  //   navigate({
  //     pathname: generatePath('/batteries'),
  //     search: `?${createSearchParams(validParams)}`,
  //   });
  //   props.setIsOpen(false);

  // }

  const tableData: Column<IBatterySearch>[] =
    isMecadepotChild ?
      [
        {
          Header: <Head text=""></Head>,
          Cell: (cell) => (
            <img src={cell.cell.row.original.image ? `${getBackendAssetsBasePath()}/${cell.cell.row.original.image}` : '/images/car-part-placeholder.png'} width={30} height={30} alt="battery" onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = '/images/car-part-placeholder.png';
            }}
            />)
          ,
          accessor: 'image',
        },
        {
          Header: <Head text={t('_description111').toLocaleUpperCase()}></Head>,
          accessor: 'description',
        }
      ] :
      [
        {
          Header: <Head text=""></Head>,
          Cell: (cell) => (
            <img src={cell.cell.row.original.image ? `${getBackendAssetsBasePath()}/${cell.cell.row.original.image}` : '/images/car-part-placeholder.png'} width={30} height={30} alt="battery" onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = '/images/car-part-placeholder.png';
            }}
            />)
          ,
          accessor: 'image',
        },
        {
          Header: <Head text={t('_conditioning').toLocaleUpperCase()}></Head>,
          Cell: (cell) =>
            <div className="item-ref">
              <span>{cell.cell.row.original.brand_name}</span>
              {' '}
              <span>{cell.cell.row.original.reference}</span>
              {' '}
              <span>{cell.cell.row.original.ah}AH</span>
              {' '}
              <span>{cell.cell.row.original.amp}AMP</span>
              {' '}
              {!isMobile ? <span>{cell.cell.row.original.size}</span> : null}
            </div>,
          accessor: 'brand_name',
        },
        {
          Header: <Head text={t('_category').toLocaleUpperCase()}></Head>,
          accessor: 'category_name',
        },
      ];

  return (
    <div ref={batteryDropdownRef} className="battery-dropdown-content">
      <div className="battery-dropdown-header">
        <div className="battery-dropdown-title">{t('_search_result')}</div>
      </div>
      <div className="battery-search-result-content">
        {props.loading ? (
          <SkeletonSpinner />
        ) : (

          props.data?.length ? (
            <DropdownContentTable columns={tableData} itemClick={handleBatteryClick} data={props.data} loading={props.loading} />
            // props.data?.map((item, index) => {
            //   if (!item.battery_id) {
            //     return;
            //   }

            //   return (

            // <li key={index} className="suggestion-list-item-battery">
            //   <div className="suggestion-item-title">
            //     <div onClick={() => handleBatteryClick(item)} className="suggestion-item-ref">
            //       <img src={`${getBackendAssetsBasePath()}/${item.image}`} alt="battery"></img>
            //       <div className="item-ref">
            //         <span>{item.brand_name}</span>
            //         <span>{item.reference}</span>
            //         <span>{item.ah}AH</span>
            //         <span>{item.amp}AMP</span>
            //         {!isMobile ? <span>{item.size}</span> : null}
            //       </div>
            //     </div>
            //     <span onClick={() => handleBatteryCategoryClick(item)}>{item.category_name}</span>
            //   </div>
            // </li>
            //   );
            // })
          ) : (
            <div className="no-data">{t('_no_data').toLocaleUpperCase()}</div>
          )
        )}
      </div>
    </div>
  );
};

export default BatteryDropdownContent;
