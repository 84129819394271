import { createSlice } from '@reduxjs/toolkit';
import { addToCartAction } from '../actions/cart.actions';

type ICartState = {
  checkoutErrorStatus: number | null;
  checkoutRequestStatus: number | null;
  quantityToolbar: number;
  addToCartLoading: boolean;
};

const initialState: ICartState = {
  checkoutErrorStatus: null,
  checkoutRequestStatus: null,
  quantityToolbar: 0,
  addToCartLoading: false,
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setQuantityToolbar(state, { payload }) {
      state.quantityToolbar = payload;
    },
    setCheckoutErrorStatus(state, { payload }) {
      state.checkoutErrorStatus = payload;
    },
    setCheckoutRequestStatus(state, { payload }) {
      state.checkoutErrorStatus = payload;
    },

  },
  extraReducers: (builder) => {
    builder.addCase(addToCartAction.pending, (state) => {
      state.addToCartLoading = true;
    });
    builder.addCase(addToCartAction.rejected, (state) => {
      state.addToCartLoading = false;
    });
    builder.addCase(addToCartAction.fulfilled, (state) => {
      state.addToCartLoading = false;
    });
  }
});

export const { setQuantityToolbar, setCheckoutErrorStatus, setCheckoutRequestStatus } = cartSlice.actions;

export default cartSlice.reducer;
