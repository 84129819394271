import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import UPLOAD_IMG from 'assets/images/upload.svg';
import './UploadPdf.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRemove } from '@fortawesome/free-solid-svg-icons';
import { getMarketplaceBaseUrl } from 'utils/marketplace';

type Props = {
  setFile?: (file: File[]) => void;
  pdf?: string;
  errors?;
  clearErrors?;
  setValue?;
  title?: string
};
const UploadPdf: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const [paths, setPaths] = useState([]);
  const [uploadedFile, setUploadedFile] = useState<{ path?}>();


  const onDrop = useCallback(acceptedFiles => {
    setPaths(acceptedFiles.map(file => URL.createObjectURL(file)));
    setUploadedFile(acceptedFiles[0]);

    if (props.setFile) {
      props.setFile(acceptedFiles);
    }

    if (props.setValue) {
      props.setValue('pdf', acceptedFiles[0]);
    }


  }, [setPaths]);


  const { getRootProps, getInputProps, } = useDropzone({
    multiple: false,
    onDrop,
    accept: {
      'application/pdf': ['.pdf'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
      'application/vnd.ms-excel': ['.xls'],
    },
  });

  const remove = file => {
    const newFiles = [...paths];     // make a var for the new array

    if (props.setValue) {
      props.setValue('pdf', '');
    }


    newFiles.splice(file, 1);        // remove the file from the array 

    if (props.setFile) {
      props.setFile([]);
    }


    setPaths(newFiles);              // update the state
  };


  return (
    <div className="upload-pdf-container">

      {paths.length ?
        paths.map((path, index) => {
          return (
            <div key={index} className="file-preview">
              <img src={`${uploadedFile?.path.split('.').pop() === 'pdf' ? '/images/pdf_file.png' : '/images/xlsx_file.png'}`} width="100px" />
              <button onClick={() => remove(path)} className="remove-file"><FontAwesomeIcon icon={faRemove} /></button>
            </div>
          );
        })
        : props.pdf ?
          <div className="preview">
            <img src={`${props.pdf.split('.').pop() === 'pdf' ? '/images/pdf_file.png' : '/images/xlsx_file.png'}`} width="100px" />
            <a href={getMarketplaceBaseUrl() + props.pdf} target="_blank" rel="noreferrer" className="preview_pdf">{props.pdf.split('.').pop() === 'pdf' ? t('_preview') : t('_download')}</a>
          </div>
          :
          <div className="icon">  <img src={UPLOAD_IMG} alt="" />   </div>
      }
      <div className="button">
        <div {...getRootProps({ className: 'content' })}>
          <input {...getInputProps()} />
          <DefaultButton type="button" text={props.title ? t(props.title) : t('_select')} />
        </div>
      </div>

      <p className="error-message">{props.errors && props.errors.pdf?.type == 'required' ? props.errors.pdf?.message : ''}</p>
    </div>
  );
};

UploadPdf.displayName = 'UploadPdf';

export default UploadPdf; 