import React, { useMemo } from 'react';
import ActiaTableView from './ActiaTable.view';
import { IActiaTableData, ISessionModal } from '../../DiagnosticItems/Actia/actia';
import { Column } from 'react-table';
import { useTranslation } from 'react-i18next';
import ActiaTableOptions from './ActiaTableOptions/ActiaTableOptions';

import ExpandButton from '../TableMoreInformation/ExpandButton/ExpandButton';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';

type Props = {
  data: IActiaTableData[];
  loading: boolean;
  setOpenModal: (boolean) => void;
  setModalValues: (values: ISessionModal) => void;
  getActiaListItems: () => void;
}

const ActiaTable: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { isMobile } = useDeviceDetect();

  const columns = useMemo(
    (): Column<IActiaTableData>[] => [
      {
        id: 'expander',
        // eslint-disable-next-line react/destructuring-assignment
        Header: '',
        width: 10,
        // eslint-disable-next-line react/destructuring-assignment
        Cell: ({ row }) => {
          return (
            //Applying the toggle expander props i.e onClick, style and title
            <ExpandButton row={row} />

          );
        }
      },
      {
        Header: t('id'),
        accessor: 'id',
      },
      {
        Header: t('_name').toLocaleUpperCase(),
        accessor: 'name',
      },
      {
        Header: t('_email').toLocaleUpperCase(),
        accessor: 'email',
      },
      {
        Header: t('_phone').toLocaleUpperCase(),
        accessor: 'phone',
      },
      {
        Header: t('_vin').toLocaleUpperCase(),
        accessor: 'vin',
      },
      {
        Header: t('_plate').toLocaleUpperCase(),
        accessor: 'plate',
      },
      {
        Header: t('_operation_type').toLocaleUpperCase(),
        accessor: 'operation_type',
      },
      {
        Header: t('_date').toLocaleUpperCase(),
        accessor: 'date',
      },
      {
        Header: t('_actions').toLocaleUpperCase(),
        Cell: (cell) => <span>
          <ActiaTableOptions getActiaListItems={props.getActiaListItems} setModalValues={props.setModalValues} setOpenModal={props.setOpenModal} data={cell.row.original} /></span>,
      }
    ],
    [],
  );

  if (!isMobile) {
    columns.shift();
  }

  const hiddenColumns = isMobile ? ['id', 'email', 'vin', 'plate', 'operation_type', 'date'] : undefined;


  return (
    <ActiaTableView hiddenColumns={hiddenColumns} loading={props.loading} data={props.data} columns={columns} />
  );
};

ActiaTable.displayName = 'ActiaTable';

export default ActiaTable;
