import React, { useEffect, useRef, useState } from 'react';

import { useOnClickOutside } from 'utils/hooks/useOnClickOutside';
import { useDispatch } from 'react-redux';
import { faClose, faFileExport } from '@fortawesome/free-solid-svg-icons';
import { useDropzone } from 'react-dropzone';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { removeImportReferenceData, setImportReferenceData } from 'store/reducers/importReferenceSlice';
import { notify } from 'utils/marketplace';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Spinner from 'components/spinner/Spinner';
import FILE_XLSX from 'assets/images/file-xlsx.svg';
import useHttp from 'utils/hooks/useHttp';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import './SubmitQuotationModal.scss';

type ICsvForm = {
  files: File;
};
type Props = {
  setOpenModal: (e: boolean) => void;
};

const SubmitQuotationModal: React.FC<Props> = (props) => {
  const http = useHttp();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ref = useRef() as React.MutableRefObject<HTMLDivElement>;
  const [sendFile, setSendFile] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [exportLoading, setExportLoading] = useState<boolean>(false);
  const { setValue, watch, getValues } = useForm<ICsvForm>();

  useOnClickOutside(ref, () => {
    props.setOpenModal(false);
  });

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    onDrop: (file) => {
      setValue('files', file[0]);
    },
    multiple: false,
    accept: {
      'text/csv': ['.csv', '.xls', '.xlsx'],
      'application/vnd.ms-excel': ['.csv', '.xls', '.xlsx'],
    },
  });

  function close() {
    props.setOpenModal(false);
  }

  useEffect(() => {
    const file = getValues('files');

    if (!file || !file.size) {
      return;
    }

    if (sendFile) {
      setIsLoading(true);
      const formData: FormData = new FormData();

      formData.append('file', file);
      http.price.import(formData).then((res) => {
        localStorage.removeItem('importReference');
        localStorage.setItem('importReference', JSON.stringify(res.data));
        dispatch(removeImportReferenceData());
        dispatch(setImportReferenceData(res.data));

        setIsLoading(false);
        navigate({
          pathname: '/automotor-online/offer/import?file=true'
        });
        props.setOpenModal(false);
      }

      ).catch((err) => {
        setIsLoading(false);

        const erorr = err.csv[0];

        notify(t('_' + erorr.toLowerCase()), 'default', t('_error'));
      });
    }
  }, [watch('files'), sendFile]);

  const exportDefault = () => {

    setExportLoading(true);
    http.price.exportDefault().then(res => {
      const element = document.createElement('a');

      const file = new Blob(
        [res.data],
        { type: res.type },
      );

      element.href = URL.createObjectURL(file);
      element.download = `${t('_import_file_example')}.xlsx`;
      element.click();

    }).finally(() => {
      setExportLoading(false);
    });

  };

  return (
    <div className="submit-quotation-modal" ref={ref}>
      <div className="close">
        <FontAwesomeIcon icon={faClose} onClick={close}/>
      </div>
      <div className="modal-dialog">
        <form>
          <div className="upload-btn" >
            <div {...getRootProps({ className: 'icon' })} className="icon">
              {isLoading ? <Spinner class="small" /> : <img src={FILE_XLSX} alt="" />}
              <input {...getInputProps()} />
            </div>

            <div className="text">
              <div>{fileRejections.length > 0 ? <div className="error">{t('WRONG_PROFORMA_FILE')}</div> : ''}</div>
              <p className="title">
                {t('_browse_your_proforma_file')}
              </p>
              <p className="subtitle">
                <span>{t('_upload_proforma_supports')} </span>
              </p>
              <div onClick={exportDefault} className="quotation-export_file_example"> {exportLoading ? <Spinner class="extra-small" /> : <FontAwesomeIcon icon={faFileExport} />} {t('_import_file_example')}</div>
              {watch('files') ? 
                <p className="subtitle">
                  <span>{t('_file')}: {watch('files').name} </span>
                </p>
                : null}
            </div>
          </div>
        </form>
        {
          watch('files') ?
            <div className="actions">
              <div className="no">
                <DefaultButton text={t('_no')} onClick={() => {setSendFile(false); props.setOpenModal(false);}} red />
              </div>
              <div className="yes">
                <DefaultButton text={t('_yes')} onClick={() => setSendFile(true)} blue />
              </div>
            </div>
            : null
        }
      </div>
    </div >
  );
};

export default SubmitQuotationModal;