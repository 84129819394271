import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICarInformation, ICarVehicle } from 'models/cart.model';


type CarSelectedState = {
  car: ICarVehicle | null;
  carInfo: ICarInformation | null;
};

const initialState: CarSelectedState = {
  car: null,
  carInfo: null,
};

export const carSelectedSlice = createSlice({
  name: 'carSelected',
  initialState,
  reducers: {
    setCarSelected: (state, action: PayloadAction<ICarVehicle | null>) => {
      state.car = action.payload;
    },
    removeCarSelected: (state) => {
      state.car = null;
    },
    setCarInfo: (state, action: PayloadAction< ICarInformation | null>) => {
      state.carInfo = action.payload;
    },
    removeCarInfo: (state) => {
      state.carInfo = null;
    },
  },
});

export const { setCarSelected, removeCarSelected, setCarInfo, removeCarInfo } = carSelectedSlice.actions;

export default carSelectedSlice.reducer;
