import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import { ReactComponent as PartsNotFoundImage } from 'assets/images/parts-not-found.svg';
import useHttp from 'utils/hooks/useHttp';
import PartOEM from 'components/partOEM/PartOEM';
import PartOE from 'components/partOE/PartOE';
import Spinner from 'components/spinner/Spinner';
import UniversalPartsNavbar from 'components/navbar/universal-parts-navbar/UniversalPartsNavbar';
import { CarPartsResponse } from 'models/car-parts.model';
import GenartPartsFilter from './GenartPartsFilter/GenartPartsFilter';
import './GenartCarParts.scss';
import ProductsViewMode from '../../components/ProductsViewMode/ProductsViewMode';
import { DEFAULT_VIEW } from '../../components/ProductsViewMode/ProductsViewMode.constants';

type Props = {}

const GenartCarParts: React.FC<Props> = () => {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const http = useHttp();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<CarPartsResponse>();
  const [openFilter, setOpenFilter] = useState(false);
  const { vehicle_id } = useParams<'vehicle_id'>();
  const [grabFilterData, setGrabFilterData] = useState();
  const user = JSON.parse(localStorage.getItem('userData')!);
  const [viewMode, setViewMode] = useState(user?.user_settings.products_view_mode || DEFAULT_VIEW);

  useEffect(() => {
    getPartsByCar();
  }, []);


  function getPartsByCar() {
    setLoading(true);
    const params = {
      source: searchParams.get('source'),
      vehicle_identifier: searchParams.get('vehicle_identifier') || '002',
      genartnrs: searchParams.getAll('genartnrs[]'),
    };

    const validParams = validateParams(params);

    http.carParts
      .getGenArtCarPart(String(vehicle_id), validParams)
      .then((res) => {
        setData(res.data);
      })
      .finally(() => setLoading(false));

  }

  const validateParams = (data) => {
    return Object.entries(data).reduce((acc, [key, value]) => {
      if (!value) {
        return acc;
      }

      acc[key] = value;

      return acc;
    }, {});
  };

  function filterCarParts(items, filters) {

    const filterItems = items.filter(item => {
      const filterAttributes = filters?.attributes && filters?.attributes.length ? item.attributes?.some(car => filters?.attributes?.includes(car.value.trim())) : true;
      const filterSuppliers = filters?.supplier && filters?.supplier.length ? filters?.supplier?.some(supplier => String(supplier.id) === String(item.data_supplier.id)) : true;
      const filterSubcategories = filters?.subcategory && filters?.subcategory.length ? filters?.subcategory?.some(subcat => String(subcat) === String(item.genartnr)) : true;
      const filterManufacturers = filters?.manufacturer && filters?.manufacturer.length ? item.car_manufacturers?.some(manufacturer => filters?.manufacturer?.includes(Number(manufacturer?.id))) : true;
      const filterIsAmerigo = filters?.isAmerigo ? item.is_amerigo === !!filters?.isAmerigo : true;

      if (!filters?.isAmerigo) {
        return filterSuppliers && filterManufacturers && filterAttributes && filterSubcategories;
      }

      return filterSuppliers && filterManufacturers && filterAttributes && filterIsAmerigo && filterSubcategories;
    });


    return filterItems;
  }

  function filterOptionsElement() {
    return (
      <div className="catalog-parts-filter">
        <button onClick={() => setOpenFilter(true)} className={`catalog-parts-button ${openFilter ? 'active' : ''}`}>
          {t('_filter_results_btn')}
        </button>
      </div>
    );
  }

  return (
    <div className="genart-car-parts-wrapper">
      <UniversalPartsNavbar option={filterOptionsElement()} title="_find_neccessary_parts" />
      {
        !loading && (
          <div className="filterOptions">
            <ProductsViewMode viewMode={viewMode} setViewMode={setViewMode} />

          </div>
        )
      }

      <div className="wrapperItems">
        {loading ? (
          <Spinner class="car-parts-spinner" />
        ) : (
          data?.oe_references.map((item, index) => {
            return (
              <React.Fragment key={index}>
                <PartOE data={item} />
                {item.car_parts &&
                  filterCarParts(item.car_parts, grabFilterData).map((part, i) => {
                    return <PartOEM data={part} key={i} />;
                  })}
              </React.Fragment>
            );
          })

        )}
      </div>
      {!loading && !data && (
        <div className="no-parts-data">
          <div className="notFoundSvg">
            <PartsNotFoundImage />
          </div>
          <h1>{t('_parts_not_found')}</h1>
        </div>
      )}
      <GenartPartsFilter setGrabFilterData={setGrabFilterData} getData={getPartsByCar} filterData={data?.filters} openFilter={openFilter} setOpenFilter={setOpenFilter} />
    </div>
  );
};

export default GenartCarParts;