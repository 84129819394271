import React, { PropsWithChildren } from 'react';
import EspaceNavbar from 'applications/automotor/components/espace/navbar/espace-navbar/EspaceNavbar';
import EspaceReliableCatalog from 'applications/automotor/components/espace/espace-reliable-catalog/EspaceReliableCatalog';
import './Espace.scss';
import EspaceTab from 'applications/automotor/components/espace/espace-tab/EspaceTab';
import { IEspaceTabItem } from './Espace.model';
import { useTranslation } from 'react-i18next';
import { IUser } from '../../../../models/IUser';
import { ROLE_CLIENT_PLATFORM_ADMIN } from '../../../../utils/constants/roles';

type Props = {
  clientTabItems: IEspaceTabItem[];
  supplierTabItems: IEspaceTabItem[];
  user: IUser | null;
  messagesNumber: number;
  loading?: boolean;
};

const EspaceView: React.FC<Props> = (props: PropsWithChildren<Props>) => {
  const { t } = useTranslation();

  const isEnabled = props.user?.roles.includes(ROLE_CLIENT_PLATFORM_ADMIN);

  return (
    <div className="amerigo-espace espace-container">
      <EspaceNavbar />
      <EspaceReliableCatalog />
      <div className="tabs">
        <div className="tab">
          <EspaceTab
            messagesNumber={props.messagesNumber}
            warningsNumber={0}
            type={t('_my_suppliers')}
            onClick={() => null}
            description={t('_espace_supplier_description')}
            buttonText={t('_espace_supplier_button')}
            buttonLink="espace-suppliers"
            items={props.supplierTabItems}
          />
        </div>
        <div className="tab">
          <EspaceTab
            messagesNumber={0}
            warningsNumber={0}
            enabled={isEnabled}
            type={t('_my_clients')}
            onClick={() => null}
            description={t('_espace_clients_description')}
            buttonText={t('_espace_clients_button')}
            buttonLink="espace-clients"
            items={props.clientTabItems}
            loading={props.loading}
            from="clients"
          />
        </div>
      </div>
    </div>
  );
};

EspaceView.displayName = 'EspaceView';

export default EspaceView;
