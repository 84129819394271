import React, { useState } from 'react';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import LogoItem from 'components/Item/LogoItem/LogoItem';
import SwiperSlider from 'components/SwiperSlider/SwiperSlider';
import { useTranslation } from 'react-i18next';
import { SwiperSlide } from 'swiper/react';
import Modal from 'components/modal/Modal';
import ModalFile from '../../espace-automotor-offer/Card/ModalFile/ModalFile';
import useHttp from 'utils/hooks/useHttp';
import moment from 'moment';
import { notify } from 'utils/marketplace';
import Spinner from 'components/spinner/Spinner';
import ModalFileNotFond from '../../espace-automotor-offer/Card/ModalFileNotFond/ModalFileNotFond';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';

type Props = {
  data
  title
  length
};
const TariffsAutomotorSwiper: React.FC<Props> = (props) => {

  const { t } = useTranslation();
  const http = useHttp();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const userInfo = JSON.parse(localStorage.getItem('userData')!);
  const [loading, setLoading] = useState(true);
  const [supplierName, setSupplierName] = useState('');
  const [openModalNotFile, setOpenModalNotFile] = useState(false);
  const { isMobile, isTablet } = useDeviceDetect();
  const minVisibleSlides = 15;

  function download(item, type) {

    if (userInfo?.is_using_tariffs_tables_v2) {
      if (!item.has_price) {
        setOpenModalNotFile(true);

        return;
      }

      setLoading(true);
      setSupplierName(item.supplier_name);

      setOpenModal(true);
      http.automotorTariff.getFileV2({ codfou: item.codfou, type: type }).then((res) => {
        const element = document.createElement('a');

        const file = new Blob([res.data], { type: res.data.type });

        element.href = URL.createObjectURL(file);
        element.download = item.supplier_name + `_${moment().format('MM-DD-YY')}`;
        element.click();
      })
        .catch(() => {
          window.scrollTo({ top: 0, behavior: 'smooth' });
          notify(t('_please_contact_seller'));
          setOpenModal(false);
        })
        .finally(() => {
          setLoading(false);
          setOpenModal(false);
        });

      return;
    }

    setLoading(true);
    setSupplierName(item.supplier_name);
    http.automotorTariff.getFile(item.file_id, { name: item.supplier_name }).then((res) => {
      const element = document.createElement('a');
      const file = new Blob([res.data], { type: res.data.type });

      element.href = URL.createObjectURL(file);
      element.download = item.supplier_name + `_${moment().format('MM-DD-YY')}`;
      element.click();
    }).catch(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      notify(t('_please_contact_seller'));
    }).finally(() => {
      setLoading(false);
    });

  }

  return <div className="items">
    <div className="item-title">
      {props.title == 'suppliers' ? t('_equipment_manufacturers').toUpperCase() : t('_manufacturers').toUpperCase()}
    </div>
    {props.data.length ?
      <SwiperSlider
        navigation={!(isMobile || isTablet) && props.length > minVisibleSlides}
        pagination={isMobile || isTablet}
        autoplay={props.length > minVisibleSlides}
        loop={props.length > minVisibleSlides}
        iconsArrowRight={faChevronRight}
        iconsArrowLeft={faChevronLeft}
        slidesPerView={props.length < minVisibleSlides ? props.length : 'auto'}
      >
        {props.data.map((item, index: number) => {
          return (
            <SwiperSlide className="swiper-slide" key={index} >
              <div className="item-body">
                {loading && item.supplier_name === supplierName ? <Spinner class="small automotor-tariff-spinner" /> :
                  <LogoItem image={item.supplier_logo} name={item.supplier_name} onClick={() => download(item, item.type)} />
                }
              </div>
            </SwiperSlide>
          );
        })
        }

      </SwiperSlider>
      : <div className="no-data"> {t('_no_data')} </div>
    }
    <Modal
      openModal={openModal}
      setOpenModal={setOpenModal}
      shouldCloseOnOverlayClick={false}
      childrenComponent={
        <ModalFile />
      }
    />
    <Modal
      openModal={openModalNotFile}
      setOpenModal={setOpenModalNotFile}
      shouldCloseOnOverlayClick={false}
      childrenComponent={
        <ModalFileNotFond setModalNotFile={setOpenModalNotFile} />
      }
    />
  </div>;

};

export default TariffsAutomotorSwiper;


