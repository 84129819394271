import React, { PropsWithChildren } from 'react';
import './Profil.scss';
import { IUser, IUserProfile } from '../../models/IUser';
import { Controller, FieldErrors, UseFormHandleSubmit, UseFormRegister } from 'react-hook-form';
import TextInput from '../../components/inputs/TextInput/TextInput';
import { useTranslation } from 'react-i18next';
import DefaultButton from '../../components/buttons/DefaultButton/DefaultButton';
import Select from 'react-select';
import { getLanguagesAsClientsOption } from 'utils/constants/locales';
import { IOption } from 'interfaces/select';
import { getCurrentMarketplace } from 'utils/marketplace';
import { Marketplaces } from 'utils/constants/applications';
import PasswordStrengthInput from 'components/inputs/PasswordStrengthInput/PasswordStrengthInput';


type Props = {
  onSubmit: () => void;
  handleSubmit: UseFormHandleSubmit<IUserProfile>;
  register: UseFormRegister<IUserProfile>;
  user: IUser | null;
  errors: FieldErrors<IUserProfile>;
  loading: boolean;
  countries?: IOption[];
  watch?
  getValues?
  control?
};

const ProfilView: React.FC<Props> = (props: PropsWithChildren<Props>) => {
  const { t } = useTranslation();

  const m3User = props.user?.m3User;

  return (
    <div className="my-info form-container">
      <h1 className="title">{t('_my_info')}</h1>

      {m3User && (
        <div className="m3User">
          {/* <h2 className="title">{t('_my_info_m3')}</h2> */}
          <div className="m3-form">
            <div className="m3">
              {Object.keys(m3User).map((key, val) => {
                return <div key={val} className="m3-item">
                  <span className="m3-key">{t(`_${key}`)}</span> - {m3User[key]}
                </div>;
              })}
            </div>
          </div>
        </div>
      )}

      <form onSubmit={props.handleSubmit(props.onSubmit)} className="inputs">
        {getCurrentMarketplace() === Marketplaces.RETAILER && <div className="form">
          <h4>{t('_destribution_company').toLocaleUpperCase()}</h4>
          <div className="row">
            <div className={`container ${props.errors.company?.name && 'error-input'}`}>
              <label htmlFor="#company.name" className={`${props.errors.company?.name && 'error-input'}`}>
                {t('_company_name')}
              </label>
              <TextInput defaultValue="" placeholder={t('_auto_destribution')} {...props.register('company.name')} />
              {props.errors.company?.name?.type === 'required' && <span className="error-message">{t('_company_name_required')}</span>}
            </div>
            <div className="container form-group">
              <div className={`container input-long ${props.errors.company?.address && 'error-input'}`}>
                <label htmlFor="#company.address" className={`${props.errors.company?.address && 'error-input'}`}>
                  {t('_garage_address')}
                </label>
                <TextInput
                  defaultValue=""
                  placeholder={t('12 avenue du Maréchal Foch')}
                  {...props.register('company.address')}
                />
                {props.errors.company?.address?.type === 'required' && (
                  <span className="error-message">{t('address_required')}</span>
                )}
              </div>
              <div className={`container input-short ${props.errors.company?.code_postal && 'error-input'}`}>
                <label htmlFor="#company.code_postal" className={`${props.errors.company?.code_postal && 'error-input'}`}>
                  {t('_code_postal')}
                </label>
                <TextInput defaultValue="" placeholder={t('78 600')} {...props.register('company.code_postal')} />
                {props.errors.company?.code_postal?.type === 'required' && (
                  <span className="error-message">{t('_code_postal_required')}</span>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="container ">
              <label htmlFor="#company.country">
                {t('_country')}
              </label>
              <Controller
                control={props.control}
                name="company.country"
                render={({ field: { onChange, value } }) => (
                  <Select
                    value={props.countries?.find((c) => c.value === value)}
                    className="select-dropdown"
                    onChange={(val) => {
                      onChange(val?.value);
                    }}
                    options={props.countries}
                    placeholder={t('_country')}
                    menuPosition="fixed"
                    styles={{
                      menuPortal: base => ({ ...base, zIndex: 50, })
                    }}

                  />
                )}
              />
            </div>
            <div className="container">
              <label htmlFor="#company.language">
                {t('_language')}
              </label>
              <Controller
                control={props.control}
                name="company.language"
                render={({ field: { onChange, value } }) => (
                  <Select
                    value={value}
                    className="select-dropdown"
                    onChange={(val) => {
                      onChange(val);
                    }}

                    options={getLanguagesAsClientsOption()}
                    placeholder={t('_language')}
                    menuPosition="fixed"
                    styles={{ menuPortal: base => ({ ...base, zIndex: 50 }) }}
                  />
                )}
              />
            </div>
            <div className={`container ${props.errors.company?.phone && 'error-input'}`}>
              <label htmlFor="#company.phone" className={`${props.errors.company?.phone && 'error-input'}`}>
                {t('_phone')}
              </label>
              <TextInput defaultValue="" placeholder={t('06 55 11 99 55')} {...props.register('company.phone')} />
              {props.errors.company?.phone?.type === 'required' && <span className="error-message">{t('_phone_required')}</span>}
            </div>
          </div>
        </div>
        }
        <div className="form">
          <h4>{t('_main_contact').toLocaleUpperCase()}</h4>
          <div className="line">
            <div className={`radio-group ${props.errors.gender ? 'error-input' : ''}`}>
              <div className="radio">
                <input type="radio" value="m" {...props.register('gender', { required: true })} />
                <label htmlFor="#gender" className={`${props.errors.gender ? 'error-input' : ''}`}>
                  {t('_male')}
                </label>
              </div>
              <div className="radio">
                <input type="radio" value="f" {...props.register('gender', { required: true })} />
                <label htmlFor="#gender" className={`${props.errors.gender ? 'error-input' : ''}`}>
                  {t('_female')}
                </label>
              </div>
              {props.errors.gender?.type === 'required' && <span className="error-message">{t('_gender_required')}</span>}
            </div>
          </div>
          <div className="row">
            <div className={`container ${props.errors.last_name && 'error-input'}`}>
              <label htmlFor="#last_name" className={`${props.errors.last_name && 'error-input'}`}>
                {t('_name')}
              </label>
              <TextInput
                placeholder={t('Cesarelli')}
                {...props.register('last_name', { required: true })}
              />
              {props.errors.last_name?.type === 'required' && <span className="error-message">{t('_lastname_required')}</span>}
            </div>
            <div className={`container ${props.errors.first_name && 'error-input'}`}>
              <label htmlFor="#first_name" className={`${props.errors.first_name && 'error-input'}`}>
                {t('_firstname')}
              </label>
              <TextInput
                placeholder={t('Sylvain')}
                {...props.register('first_name', { required: true })}
              />
              {props.errors.first_name?.type === 'required' && <span className="error-message">{t('_firstname_required')}</span>}
            </div>
            <div className={`container select ${props.errors.position && 'error-input'}`}>
              <label htmlFor="#position" className={`${props.errors.position && 'error-input'}`}>
                {t('_work_position')}
              </label>
              <Select
                className="select-dropdown"
                value={{ label: 'Gérant', value: 'Gérant' }}
                options={[{label: 'Gérant', value: 'Gérant'}]}
              />
              {props.errors.position?.type === 'required' && <span className="error-message">{t('_work_position_required')}</span>}
            </div>
          </div>
          <div className="row">
            <div className={`container ${props.errors.email && 'error-input'}`}>
              <label htmlFor="#email" className={`${props.errors.email && 'error-input'}`}>
                {t('_email')}
              </label>
              <TextInput
                placeholder={t('cesarelli@gmail.com')}
                {...props.register('email', {
                  required: true,
                  pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: t('_email_invalid') },
                })}
              ></TextInput>
              {props.errors.email?.type === 'pattern' && <span className="error-message">{t('_email_invalid')}</span>}
              {props.errors.email?.type === 'required' && <span className="error-message">{t('_email_required')}</span>}
            </div>
            <div className={`container ${props.errors.mobile && 'error-input'}`}>
              <label htmlFor="#mobile" className={`${props.errors.mobile && 'error-input'}`}>
                {t('_mobile')}
              </label>
              <TextInput
                placeholder={t('06 55 11 99 55')}
                {...props.register('mobile', { required: true })}
              />
              {props.errors.mobile?.type === 'required' && <span className="error-message">{t('_phone_required')}</span>}
            </div>
            <div className={`container ${props.errors.phone && 'error-input'}`}>
              <label htmlFor="#phone" className={`${props.errors.phone && 'error-input'}`}>
                {t('_phone')}
              </label>
              <TextInput
                placeholder={t('06 55 11 99 55')}
                {...props.register('phone', { required: true })}
              />
              {props.errors.phone?.type === 'required' && <span className="error-message">{t('_phone_required')}</span>}
            </div>
          </div>
        </div>
        <div className="form">
          <h4>{t('_your_password')}</h4>
          <div className="row">
            <div className={`container ${props.errors?.old_password
              || (props.watch('password.second') && props.watch('old_password') == '')
              || (props.watch('password.first') && props.watch('old_password') == '') ? 'error-input' : ''}`}
            >
              <label className={`${props.errors?.old_password && 'error-input'}`}>{t('_old_password')}</label>
              <TextInput placeholder="* * * * * *" type="password" {...props.register('old_password')}></TextInput>
              {props.errors.password?.type === 'required' ||
                (props.watch('password.second') && props.watch('old_password') == '') ||
                (props.watch('password.first') && props.watch('old_password') == '')

                ? <span className="error-message">{t('_password_required')}</span> : ''}
            </div>
            <div className={`container ${props.errors.password?.first
              || (props.watch('old_password') != '' && props.watch('password.first') == '')
              || (props.watch('password.second') && props.watch('password.first') == '') ? 'error-input' : ''}`}
            >
              <label className={`${props.errors.password?.first && 'error-input'}`}>{t('_password')}</label>
              <PasswordStrengthInput placeholder="* * * * * *" {...props.register('password.first')} />
              {props.errors.password?.first?.type === 'required' || (props.watch('old_password') != '' && props.watch('password.first') == '')
                || (props.watch('password.second') && props.watch('password.first') == '') ? <span className="error-message">{t('_password_required')}</span> : null}

            </div>
            <div className={`container ${props.errors.password?.second || props.watch('password.first') !== props.watch('password.second') && props.getValues('password.first') ? 'error-input' : ''}`}>
              <label className={`${props.errors.password?.second && 'error-input'}`}>{t('_repeat_password')}</label>
              <PasswordStrengthInput placeholder="* * * * * *" {...props.register('password.second')} />
              {props.errors.password?.second?.type === 'required' && <span className="error-message">{t('_password_required')}</span>}
              {props.watch('password.first') !== props.watch('password.second') && props.getValues('password.first') ? (<span className="error-message">{t('_password_not_match')}</span>) : null}
            </div>
          </div>
        </div>
        <div className="form">
          <DefaultButton isloading={props.loading} text={t('_save_changes')} type="submit" />
        </div>
      </form>
    </div>
  );
};

ProfilView.displayName = 'ProfilView';

export default ProfilView;
