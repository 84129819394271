
import React from 'react';

import { ICategory } from 'interfaces/consumableCategory';
import ItemEshopOilCategoryView from './ItemEshopOilCategoryView';


type Props = {
  data: ICategory[],
  loading: boolean
  openModalBatteryCategory: (id: number) => void;
  deleteItem: (id: number) => void
};

const ItemEshopOilCategory: React.FC<Props> = (props) => {
  return <ItemEshopOilCategoryView data={props.data} loading={props.loading} openModalBatteryCategory={props.openModalBatteryCategory} deleteItem={props.deleteItem} />;
};

ItemEshopOilCategory.displayName = 'ItemEshopOilCategory';

export default ItemEshopOilCategory;
