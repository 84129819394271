import { faCalendar, faCar, faCheck, faGears } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import Spinner from 'components/spinner/Spinner';
import i18n from 'i18n';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { RootState } from 'store/store';
import useHttp from 'utils/hooks/useHttp';
import { ICooGroupFilter } from 'utils/rmi/interface/maintenance.interface';
import { EquipmentFilter, GearBoxFilter, getCurrentCountryRMI } from 'utils/rmi/rmi-utils/rmi-utils';
import EuroExhaustEmissionSelect from '../StepOneComponents/EuroExhaustEmissionSelect';
import GearBoxSelect from '../StepOneComponents/GearBoxSelect';
import RegistrationDateSelect from '../StepOneComponents/RegistrationDateSelect';
import './Step.scss';
import useReliableCatalogCarQueryParams from 'utils/hooks/useReliableCatalogCarQueryParams';

type Props = {
  setStep?: (string) => void
}
const StepOne: React.FC<Props> = (props) => {
  const { control, getValues, register, handleSubmit, setValue } = useForm();
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const http = useHttp();
  const { typeId } = useSelector((state: RootState) => state.tecrmi);
  const [cooGroupFilters, setCooGroupFilters] = useState<ICooGroupFilter>();
  const [gearboxCodes, setGearboxCodes] = useState<number[]>([]);
  const reliableSearchParams = useReliableCatalogCarQueryParams();
  const [loading, setLoading] = useState(false);
  const [milageErr, setMilageErr] = useState(false);

  function getPossibleCooGroupFilters() {
    setLoading(true);

    if (typeId) {
      http.maintenanceHttp.fetchCooGroupFilterParams(
        {
          TypeId: typeId,
          CountryCode: getCurrentCountryRMI(i18n.language),
          KindOfVehicleTreeId: 3
        }
      ).then(res => setCooGroupFilters(res.data)).finally(() => setLoading(false));
    }
  }

  function getPossibleGearboxCodes() {
    if (typeId) {
      http.maintenanceHttp.fetchPossibleGearboxCodes(
        {
          TypeId: typeId,
          CountryCode: getCurrentCountryRMI(i18n.language),
          KindOfVehicleTreeId: 3
        }
      ).then(res => setGearboxCodes(res.data));
    }
  }

  useEffect(() => {
    getPossibleCooGroupFilters();
    getPossibleGearboxCodes();
  }, [typeId, i18n.language]);

  function submit() {
    if (getValues()?.milage == 0) {
      setMilageErr(true);

      return;
    } else {
      setMilageErr(false);
    }

    const params = {
      source: 'T',
      vehicle_identifier: '002',
      milage: getValues().milage,
      equipmentFilter: Array.isArray(getValues().equipmentFilter) ? getValues().equipmentFilter : [getValues().equipmentFilter],
      kindOfGearBox: getValues().kindOfGearBox ? getValues().kindOfGearBox?.value : null,
      registration_date_month: getValues()?.month ? moment(new Date(getValues()?.year?.value, getValues()?.month?.value - 1)).format('MMM') : 'Jan',
      registration_date_year: getValues()?.year ? moment(new Date(getValues()?.year?.value, getValues()?.month?.value - 1)).format('YYYY') : 1998,
      step: '12'
    };

    if (!getValues().equipmentFilter) { delete params.equipmentFilter; }


    const validParams = Object.entries(params).reduce((acc, [key, value]) => {
      if (!value) {
        return acc;
      }

      acc[key] = value;

      return acc;
    }, {});

    setSearchParams({...reliableSearchParams, ...validParams});
    props.setStep!('12');
  }

  useEffect(() => {
    searchParams.get('milage') && setValue('milage', searchParams.get('milage'));
    searchParams.get('kindOfGearBox') && setValue('kindOfGearBox', { value: Number(searchParams.get('kindOfGearBox')), label: t(GearBoxFilter[Number(searchParams.get('kindOfGearBox'))]) });
    searchParams.get('equipmentFilter') && setValue('equipmentFilter', searchParams.getAll('equipmentFilter'));
    searchParams.get('registration_date_month') && setValue('month', { value: moment().month(String(searchParams.get('registration_date_month'))).format('M'), label: searchParams.get('registration_date_month') });
    searchParams.get('registration_date_year') && setValue('year', { value: Number(searchParams.get('registration_date_year')), label: searchParams.get('registration_date_year') });
  }, []);

  return (
    <form onSubmit={handleSubmit(submit)} className="maintenance-step">
      {loading ? <Spinner class="small" /> :
        <React.Fragment>
          {searchParams.get('step')?.includes('2') ?
            <div className="preview-maintenance-step">
              <div className="preview-item">
                <div className="preview-icon">
                  <FontAwesomeIcon icon={faCar} />
                </div>
                <div className="preview-text">
                  {searchParams.get('milage')} KM
                </div>
              </div>
              {searchParams.get('registration_date_month') &&
                <div className="preview-item">
                  <div className="preview-icon">
                    <FontAwesomeIcon icon={faCalendar} />
                  </div>
                  <div className="preview-text">
                    {searchParams.get('registration_date_month')}. {searchParams.get('registration_date_year')}
                  </div>
                </div>
              }
              {searchParams.get('kindOfGearBox') &&
                <div className="preview-item">
                  <div className="preview-icon">
                    <FontAwesomeIcon icon={faGears} />
                  </div>
                  <div className="preview-text">
                    {t(GearBoxFilter[searchParams.get('kindOfGearBox')!])}
                  </div>
                </div>
              }
              {searchParams.get('equipmentFilter') && searchParams.getAll('equipmentFilter')?.map(filter => {
                return (
                  <div className="preview-item" key={filter}>
                    <div className="preview-icon">
                      <FontAwesomeIcon icon={faCheck} />
                    </div>
                    <div className="preview-text">
                      {t(EquipmentFilter[filter])}
                    </div>
                  </div>
                );
              })}
            </div> :
            (<React.Fragment>
              <div className={`milage-container ${milageErr ? 'error' : ''}`}>
                <input
                  {...register('milage')}
                  onChange={() => setMilageErr(false)}
                  type="number"
                  min={0}
                  max={1000000}
                  step="any"
                  pattern="[0-9]*"
                  inputMode="numeric"
                  className="numeric-input"
                  placeholder={t('_milage')}
                />
                {milageErr && <div className="milage-error-text">{t('_milage_required')}</div>}
              </div>
              {cooGroupFilters?.IsFilterByGearBoxCodePossible && <GearBoxSelect control={control} gearboxCodes={gearboxCodes} />}
              {cooGroupFilters?.IsFilterByEuroExhaustNormPossible && <EuroExhaustEmissionSelect control={control} />}
              {cooGroupFilters?.IsFilterByRegistrationDatePossible && <RegistrationDateSelect setValue={setValue} control={control} />}
              <div className="checkbox-container">
                {cooGroupFilters?.PossibleEquipmentFilter?.map(item => {
                  return (
                    <div className="checkbox" key={item}>
                      <input type="checkbox" value={item} {...register('equipmentFilter')} />
                      {t(EquipmentFilter[item])}
                    </div>
                  );
                })}
              </div>
              <div className="maintenance-button">
                <DefaultButton text={t('_confirm')} />
              </div>

            </React.Fragment>
            )}
          { }
        </React.Fragment>}
    </form>
  );
};

export default StepOne;