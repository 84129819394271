import { createSlice } from '@reduxjs/toolkit';
import { ICarPartData } from 'models/car-parts.model';
import { fetchCarPartsByRef, fetchCarPartsData } from 'store/reducers/car-parts/car-parts.actions';

type CarPartsSlice = {
  parts: ICarPartData[];

  isLoading: boolean;
  page: number;
  total_pages: number;
  total_count: number
};

const initialState: CarPartsSlice = {
  parts: [],
  isLoading: false,
  page: 1,
  total_pages: 1,
  total_count: 0
};

const CarPartsSlice = createSlice({
  name: 'carParts',
  initialState,
  reducers: {
    setIsLoading(state, { payload }) {
      state.isLoading = payload;
    },
    nextPage(state) {
      state.page = +state.page + 1;
    },
    setPage(state, { payload }) {
      state.page = +payload;
    },
    previousPage(state) {
      if (state.page > 1) {
        state.page = +state.page - 1;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCarPartsData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchCarPartsData.fulfilled, (state, { payload }) => {
      state.parts = payload.data;
      state.total_pages = payload.total_pages;
      state.total_count = payload.total_count;
      state.isLoading = false;
    });
    builder.addCase(fetchCarPartsByRef.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchCarPartsByRef.fulfilled, (state, { payload }) => {
      state.parts = payload.data;
      state.total_pages = payload.total_pages;
      state.total_count = payload.total_count;
      state.isLoading = false;
    });
  },
});

export const { nextPage, previousPage, setPage } = CarPartsSlice.actions;

export default CarPartsSlice.reducer;
