import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { ReactComponent as LoadingProvidersPrice } from 'assets/images/price-list-icon.svg';

type Props = {};

const ProvidersPrice: React.FC<Props> = () => {
  const { t } = useTranslation();

  return (
    <div className="item-price">
      <NavLink to="/price/loading-providers">
        <div className="title">{t('_loading_providers_price')}</div>
        <div className="icon-wrapper">
          <div className="icon">
            <LoadingProvidersPrice />
          </div>
          <div className="text">{t('_loading_providers_price')}</div>
        </div>
      </NavLink>
    </div>
  );
};

ProvidersPrice.displayName = 'ProvidersPrice';

export default ProvidersPrice;
