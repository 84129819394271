import React, { useEffect } from 'react';
import {resetCarPartsCategoriesSlice} from '../../../store/slices/car-parts-categories.slice';
import {
  fetchCarPartsCategoriesData,
  ICarPartsCategoriesState
} from '../../../store/reducers/car-parts-categories/car-parts-categories.actions';
import {useAppDispatch} from '../redux';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import useReliableCatalogCarQueryParams from '../useReliableCatalogCarQueryParams';

const useFetchPartsCategories = (): void => {
  const dispatch = useAppDispatch();
  const { i18n } = useTranslation();
  const { vehicle_id } = useParams<'vehicle_id'>();
  const reliableCatalogCarQueryParams = useReliableCatalogCarQueryParams();
    
  useEffect(() => {
    dispatch(resetCarPartsCategoriesSlice());

    const fetchCarPartsCategories: ICarPartsCategoriesState = {
      vehicleId: vehicle_id?.toString() || '',
      params: reliableCatalogCarQueryParams
    };

    dispatch(fetchCarPartsCategoriesData(fetchCarPartsCategories));

  }, [i18n.language]);

};

export default useFetchPartsCategories;