import React, { PropsWithChildren } from 'react';

import './PlatformAdminMessages.scss';
import { IMessageSupplier, IMessageSupplierPaginate } from '../../interfaces/messaging';
import CatalogMessageItem from './CatalogMessageItem/CatalogMessageItem';
import Spinner from '../../components/spinner/Spinner';
import Select from 'react-select';
import CatalogMessageHeader from './CatalogMessageHeader/CatalogMessageHeader';
import Pagination from '../../components/pagination/Pagination';
import Modal from 'components/modal/Modal';
import { contentStyle } from 'applications/amerigo/views/select-client/ModalClient/ContentStyleModal';
import NewMessageModal from './NewMessageModal/NewMessageModal';
import { IIdName } from '../../models/car-parts.model';
import { useTranslation } from 'react-i18next';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';


type Props = {
  clientList: IIdName[]
  messages: IMessageSupplier[] | []
  pagination: IMessageSupplierPaginate;
  loading: boolean;
  handleChangeSupplier: (item) => void;
  selectedClient: IIdName | null
  handlePageClick: (page: number) => void;
  openModal: boolean;
  setOpenModal: (e: boolean) => void;
  getMessages: () => void;

};


const PlatformAdminMessagesView: React.FC<Props> = (props: PropsWithChildren<Props>) => {
  const { t } = useTranslation();
  const { isMobile, isTablet } = useDeviceDetect();

  const selectOptions = () => {
    return (
      props.clientList.map((item) => ({
        label: item.name,
        value: item.id,
      })) || []
    );
  };

  const selectedOption = () => {
    if (!props.selectedClient) {
      return null;
    }

    return {
      label: props.selectedClient?.name,
      value: props.selectedClient?.id,
    };
  };

  const contentStyleModal = contentStyle;

  contentStyleModal.content.backgroundColor = '#F1F5FC';
  contentStyleModal.content.maxWidth = '100rem';

  return (
    <div className="container_platform_admin_message">
      <div className="wrapper_meessage">
        <Modal
          openModal={props.openModal}
          setOpenModal={props.setOpenModal}
          childrenComponent={<NewMessageModal clientList={props.clientList} getMessages={props.getMessages} options={selectOptions()} setOpenModal={props.setOpenModal} />}
          contentStyle={contentStyleModal}
        />
        {isMobile ?
          <div className="row-mobile">
            <h1>{t('_messages_clients')}</h1>
            <div className="search">
              <Select
                className="select-supplier"
                placeholder={t('_find_a_customer')}
                value={selectedOption()}
                onChange={(val) => {
                  props.handleChangeSupplier(val);
                }}
                options={selectOptions()}
                isSearchable={!isMobile && !isTablet}
                isClearable
              />
              <button onClick={() => props.setOpenModal(true)}>{t('_new_message')}</button>
            </div>
          </div>
          :
          <div className="header-row">
            <div className="search">
              <Select
                className="select-supplier"
                placeholder={t('_find_a_customer')}
                value={selectedOption()}
                onChange={(val) => {
                  props.handleChangeSupplier(val);
                }}
                options={selectOptions()}
                isSearchable={!isMobile && !isTablet}
                isClearable
              />
            </div>
            <h1>{t('_messages_clients')}</h1>
            <button onClick={() => props.setOpenModal(true)}>{t('_new_message')}</button>
          </div>
        }
        <div className="messages">
          <div className="header">
            <div className="supplier"></div>
            <div className="message"></div>
            <div className="created-time"></div>
          </div>
          <div className="content">
            {props.loading ? (
              <Spinner />
            ) : props.messages?.length ? (
              <div>
                <CatalogMessageHeader headerOne={t('_client')} headerTwo={t('_contents')} headerThree={t('_last_message')} />
                {props.messages.map((item, index) => {
                  return <CatalogMessageItem key={index} messageItem={item} />;
                })}
              </div>
            ) : (
              <div className="no-messages">
                <p>No messages</p>
              </div>
            )}
          </div>
        </div>
        {props.pagination.total_pages > 1 ? (
          <Pagination handlePageClick={props.handlePageClick} forcePage={0} pageCount={props.pagination.total_pages}></Pagination>
        ) : null}
      </div>
    </div>
  );
};

PlatformAdminMessagesView.displayName = 'PlatformAdminMPlatformAdminMessagesViewessages';

export default PlatformAdminMessagesView;
