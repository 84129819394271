

import { CancelToken } from 'axios';
import {
  IParamsPagination,
} from 'interfaces/battery';
import { IHttpResponse, addRouteParam, serverAxios } from '../../http';
import { IBodyResponseNonTecdocParts, INonTecdocPart, INonTecdocPartSupplier, INonTecdocPartSupplierFilter, IParamsNonTecdocParts } from 'interfaces/nonTecdocCatalog';


class NonTecdocCatalogHttp {
  private static list = '/retailer/catalog_non_tec_dock/parts';
  private static supplier = '/retailer/catalog_non_tec_dock/supplier/';
  private static create = '/retailer/catalog_non_tec_dock/supplier/register';
  private static supplierDelete = '/retailer/catalog_non_tec_dock/supplier/';
  private static edit = '/retailer/catalog_non_tec_dock/supplier/update/';
  private static suppliers = '/retailer/catalog_non_tec_dock/supplier/list';
  private static partInfo = '/retailer/catalog_non_tec_dock/part/';
  private static partUpdate = '/retailer/catalog_non_tec_dock/part/:id/update';
  private static partCreate = '/retailer/catalog_non_tec_dock/part/create';
  private static partDelete = '/retailer/catalog_non_tec_dock/part/:id';
  private static partDeleteAll = '/retailer/catalog_non_tec_dock/part/delete_all';

  public getNonTecdocParts(urlParams: IParamsNonTecdocParts | {}, cancelToken: CancelToken): Promise<IHttpResponse<IBodyResponseNonTecdocParts>> {
    return serverAxios.get(NonTecdocCatalogHttp.list, { params: urlParams, cancelToken: cancelToken });
  }

  public getNonTecdocSuppliers(urlParams: IParamsPagination): Promise<IHttpResponse<INonTecdocPartSupplierFilter>> {
    return serverAxios.get(NonTecdocCatalogHttp.suppliers, { params: urlParams });
  }

  public getNonTecdocSupplierById(id: number | undefined): Promise<IHttpResponse<INonTecdocPartSupplier>> {
    return serverAxios.get(NonTecdocCatalogHttp.supplier + id);
  }

  public editSupplier(id: number, data: FormData): Promise<IHttpResponse<INonTecdocPartSupplier>> {
    return serverAxios.post(NonTecdocCatalogHttp.edit + id, data);
  }

  public partInfo(id: number): Promise<IHttpResponse<INonTecdocPart>> {
    return serverAxios.get(NonTecdocCatalogHttp.partInfo + id);
  }

  public createSupplier(data: FormData): Promise<IHttpResponse<INonTecdocPartSupplier>> {
    return serverAxios.post(NonTecdocCatalogHttp.create, data);
  }
  public partUpdate(id: number, data: FormData): Promise<IHttpResponse<INonTecdocPart>> {
    return serverAxios.post(addRouteParam(NonTecdocCatalogHttp.partUpdate, { id }), data);
  }
  public partCreate(data: FormData): Promise<IHttpResponse<INonTecdocPart>> {
    return serverAxios.post(NonTecdocCatalogHttp.partCreate, data);
  }
  public partDelete(id: number): Promise<IHttpResponse<INonTecdocPart>> {
    return serverAxios.delete(addRouteParam(NonTecdocCatalogHttp.partDelete, { id }));
  }
  public partDeleteAll(): Promise<IHttpResponse<INonTecdocPart>> {
    return serverAxios.delete(NonTecdocCatalogHttp.partDeleteAll);
  }
  public partDeleteSelected(ids: Array<string>): Promise<IHttpResponse<INonTecdocPart>> {
    return serverAxios.delete(NonTecdocCatalogHttp.partDeleteAll, { params: { ids: ids } });
  }

  public supplierDelete(id: number): Promise<IHttpResponse<null>> {
    return serverAxios.delete(NonTecdocCatalogHttp.supplierDelete + id);
  }
}

export default NonTecdocCatalogHttp;
