import React, {useState,useRef} from 'react';
import DefaultIcon from 'components/buttons/DefaultIcon/DefaultIcon';
import './DefaultRetailerFloatableButtons.scss';
import {
  faComment,
  faHeart,
  faFileContract,
  faMagnifyingGlass,
  faXmark,
  faGear
} from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {setShowEbookSidebar} from 'store/slices/ebooks.slice';
import {RootState} from 'store/store';
import useTimer from 'utils/hooks/useTimer';
import useCountNewMessages from 'utils/hooks/useCountNewMessages';
import {useDeviceDetect} from 'utils/hooks/deviceDetect';
import { useOnClickOutside } from 'utils/hooks/useOnClickOutside';


type Props = {};

const DefaultRetailerFloatableButtons: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {isMobile} =useDeviceDetect();
  const [buttonsAreaExpanded,setButtonsAreaExpanded] = useState<boolean>(false);
  const clickRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const {newMessagesNumber} = useSelector((state: RootState) => state.messenger);
  const totalNotificationsNumber = newMessagesNumber + 0;


  useTimer();
  useCountNewMessages();
  useOnClickOutside(clickRef, () => {
    setButtonsAreaExpanded(false);
  });


  const changeAreaExpanded = () => {
    setButtonsAreaExpanded((prevState) => !prevState);
  };

  const goToCatalogMessages = () => {
    navigate('/messages');
  };

  const goToHistory = () => {
    navigate('/history');
  };

  const goToFavorite = () => {
    navigate('/favorite');
  };

  const { t } = useTranslation();

  const handleEbooksClick = () => {
    dispatch(setShowEbookSidebar(true));
  };

  return (

    isMobile ? (
      <div className="floatable-buttons-wrapper" ref={clickRef}>
        <DefaultIcon onClick={changeAreaExpanded} number={buttonsAreaExpanded ? 0 : totalNotificationsNumber} icon={buttonsAreaExpanded ? faXmark : faGear} />
        <div className={`buttons ${buttonsAreaExpanded ? 'show':'hidden'}`}>
          <DefaultIcon onClick={goToCatalogMessages} number={newMessagesNumber} icon={faComment}  />
          <DefaultIcon onClick={goToFavorite} icon={faHeart}  />
          <DefaultIcon onClick={goToHistory} icon={faMagnifyingGlass}  />
          <DefaultIcon onClick={handleEbooksClick} icon={faFileContract}  />
        </div>
      </div>
    ) : (
      <React.Fragment>
        <DefaultIcon onClick={goToCatalogMessages} number={newMessagesNumber} icon={faComment} text={t('_messages')} />
        <DefaultIcon onClick={goToFavorite} icon={faHeart} text={t('_favorite')} />
        <DefaultIcon onClick={goToHistory} icon={faMagnifyingGlass} text={t('_history')} />
        <DefaultIcon onClick={handleEbooksClick} icon={faFileContract} text={t('_catalog_ebooks')} />
      </React.Fragment>
    )

      
  );
};

DefaultRetailerFloatableButtons.displayName = 'DefaultRetailerFloatableButtons';

export default DefaultRetailerFloatableButtons;
