import React, { PropsWithChildren, ReactElement } from 'react';

type Props = {
  childrenComponent: ReactElement | null;
  isMobile: boolean;
  isTablet: boolean;
};

const ModalView: React.FC<PropsWithChildren<Props>> = (props: PropsWithChildren<Props>) => {
  return <div className={props.isMobile ? 'app-layout-mobile' : props.isTablet ? 'app-layout-tablet' : 'app-layout-desktop'}> {props.childrenComponent} </div>;
};

ModalView.displayName = 'ModalView';

export default ModalView;
