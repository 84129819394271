import React, { useEffect, useState } from 'react';
import CatalogSupplierNavbar from '../navbar/catalog-supplier-navbar/CatalogSupplierNavbar';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import './PartItem.scss';
import { ICarPartData } from 'models/car-parts.model';
import useHttp from 'utils/hooks/useHttp';
import PartItemPhotoGallery from './PartItemPhotoGallery/PartItemPhotoGallery';
import PartItemDetails from './PartItemDetails/PartItemDetails';
import PartItemOptions from './PartItemOptions/PartItemOptions';
import Spinner from '../spinner/Spinner';
import { notify } from 'utils/marketplace';
import { AxiosError } from 'axios';
import Equivalence from './Equivalence/Equivalence';
import { useTranslation } from 'react-i18next';
import EquivalenceV2 from 'components/partsView/EquivalenceV2/EquivalenceV2';

const PartItem: React.FC = () => {
  const http = useHttp();
  const { reference } = useParams<'reference'>();
  const { t, i18n } = useTranslation();
  const [searchParams] = useSearchParams();

  const [carPart, setCarPart] = useState<ICarPartData | null>(null);
  const [loading, isLoading] = useState(false);
  const navigate = useNavigate();


  useEffect(() => {
    getCarPart();

  }, [i18n.language]);


  const getCarPart = async () => {
    isLoading(true);

    try {
      const params = {
        data_supplier_id: String(searchParams.get('data_supplier_id')),
        provider_id: String(searchParams.get('provider_id')),
        catalog_id: String(searchParams.get('catalog_id')),
      };

      if (!params.catalog_id) {
        notify('Params not valid');
        navigate('/');
      }

      const response = await http.carParts.getCarPartView(String(reference), params);

      setCarPart(response);
    } catch (e: unknown) {

      if (e instanceof AxiosError) {
        const message = e?.response?.data?.detail;

        notify(message);
      }


    } finally {
      isLoading(false);
    }
  };

  const [has360, setHas360] = useState(false);
  const [checked, setChecked] = useState(true);

  useEffect(() => {

    if (checked && carPart != null && carPart.image_360 && carPart.image_360.endsWith('.html')) {
      http.ebooks.checkImage({ url: carPart.image_360 }).then(reps => {
        setChecked(false);

        if (reps.data.status) {
          setHas360(true);
        }
      });
    }
  }, [carPart]);

  return (

    <div className="part-item-wrapper">
      {
        loading ? (
          <Spinner />
        ) : (
          <div>
            <div className="nav-bar-container">
              <CatalogSupplierNavbar title={t('_catalog')} />
            </div>
            {
              carPart && (
                <div>
                  <div className="part-item">
                    <div className="gallery">
                      <PartItemPhotoGallery carPart={carPart} has360={has360} />
                    </div>
                    <div className="details">
                      <PartItemDetails carPart={carPart} />
                    </div>
                    <div className="options">
                      <PartItemOptions carPart={carPart} />
                    </div>
                  </div>
                  <div className="equivalence">
                    {/* <Equivalence /> */}
                    <EquivalenceV2 carPart={carPart} />
                    {/* // modificare dupa noua cerinta */}
                  </div>
                </div>
              )
            }
          </div>
        )
      }
    </div>
  );
};

PartItem.displayName = 'PartItem';

export default PartItem;