import React from 'react';
import './CodeCell.scss';

type Props = { src: string; code: string };

const CodeCellView: React.FC<Props> = (props: Props) => {


  return (
    <div className="wrapper-code-cell">
      <div className="barCode">
        {props.code && <img className="img" src={props.src} />}
        <div className="code">{props.code}</div>
      </div>
    </div>
  );
};

CodeCellView.displayName = 'CodeCellView';

export default CodeCellView;
