import React, { useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useAppDispatch } from 'utils/hooks/redux';
import { fetchCatalogInfo } from 'store/reducers/catalog/catalogs.actions';
import { useSelector } from 'react-redux';
import { getCurrentCatalog, getCurrentCatalogLanguage } from 'store/selectors/catalogs.selector';
import { fetchCategoriesData } from 'store/reducers/catalog/categories.actions';

import { useTranslation } from 'react-i18next';
import { setCatalogLangauge } from 'store/slices/catalogs.slice';
import { notify } from 'utils/marketplace';
import { resetNewMessages } from '../../store/slices/messenger.slice';


type Props = {};

const CatalogLayout: React.FC<Props> = () => {
  const params = useParams<'id'>();
  const dispatch = useAppDispatch();
  const currentCatalog = useSelector(getCurrentCatalog);
  const currentCatalogLanguage = useSelector(getCurrentCatalogLanguage);
  const { i18n } = useTranslation();

  useEffect(() => {
    dispatch(setCatalogLangauge(i18n.language));

    if (params.id && (!currentCatalog?.id || currentCatalog.id !== params.id || currentCatalogLanguage !== i18n.language)) {
      dispatch(resetNewMessages());
      dispatch(fetchCatalogInfo(params.id));

      dispatch(fetchCategoriesData({ catalog_id: params.id }));
    }
  }, [params.id, i18n.language, currentCatalog]);

  useEffect(() => {

    if (currentCatalog?.unseen_orders?.length) {
      currentCatalog?.unseen_orders.forEach((order) => {
        notify(`Commande #${order.order_id} expédiée à ${(new Date(order.created_at.date)).toLocaleString()}`);
      });

    }


  }, [currentCatalog]);

  return <Outlet />;
};

CatalogLayout.displayName = 'CatalogLayout';

export default CatalogLayout;
