import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import useHttp from 'utils/hooks/useHttp';
import {notify} from 'utils/marketplace';
import {AxiosError} from 'axios';
import Spinner from 'components/spinner/Spinner';
import { IOil } from 'interfaces/oil';
import OilPhotoGallery from './OilPhotoGallery/OilPhotoGallery';
import OilDetails from './OilDetails/OilDetails';
import OilOptions from './OilOptions/OilOptions';
import './OilsView.scss';
import {useTranslation} from 'react-i18next';

const OilsView: React.FC = () => {
  const http = useHttp();
  const { id } = useParams<'id'>();
  const { i18n } = useTranslation();

  const [oils, setOils] = useState<IOil>();
  const [loading, isLoading] = useState(false);


  useEffect(() => {
    getOils();
  }, [i18n.language]);


  const getOils = async () => {
    isLoading(true);

    try {

      await http.oilCatalogHttp.getOil(String(id)).then(res=> setOils(res.data));

      
    } catch (e: unknown) {

      if(e instanceof AxiosError) {
        const message = e?.response?.data?.detail;

        notify(message);
      }


    } finally {
      isLoading(false);
    }
  };

  return (

    <div className="oil-wrapper">
      {
        loading ? (
          <Spinner class="small"/>
        ) : (
          <div>
            {
              oils && (
                <div>
                  <div className="oil">
                    <div className="gallery">
                      <OilPhotoGallery oil={oils}/>
                    </div>
                    <div className="details">
                      <OilDetails oil={oils}/>
                    </div>
                    <div className="options">
                      <OilOptions oil={oils}/>
                    </div>
                  </div>
                </div>
              )
            }
          </div>
        )
      }
    </div>
  );
};


export default OilsView;