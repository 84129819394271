import { HttpResponse } from 'utils/hooks/http/history.http';
import { tecRMIAxios } from '../../http';
import { IAdjustListMainGroup, IAdjustListSubGroup } from '../interface/adjust.interface';

class AdjustHttp {
  private static AdjustList = '/Adjust/List';
  private static MainGroupData = '/Adjust/MainGroupData';
  private static ItemHtml = '/Adjust/ItemHtml';
  private static SubGroupHtml = '/Adjust/SubGroupHtml';
  private static MainGroupHtml = '/Adjust/MainGroupHtml';

  public async fetchRMIVehicleAdjustList(params: {}): Promise<HttpResponse<IAdjustListMainGroup[]>> {
    return tecRMIAxios.get(AdjustHttp.AdjustList, {params});
  }
  public async fetchRMIVehicleMainGroupData(params: {}): Promise<HttpResponse<IAdjustListSubGroup[]>> {
    return tecRMIAxios.get(AdjustHttp.MainGroupData, {params});
  }
  public async fetchRMIVehicleAdjustMainGroupHtml(params: {}): Promise<HttpResponse<string>> {
    return tecRMIAxios.get(AdjustHttp.MainGroupHtml, {params});
  }
  public async fetchRMIVehicleAdjustSubGroupHtml(params: {}): Promise<HttpResponse<string>> {
    return tecRMIAxios.get(AdjustHttp.SubGroupHtml, {params});
  }
  public async fetchRMIVehicleAdjustItemHtml(params: {}): Promise<HttpResponse<string>> {
    return tecRMIAxios.get(AdjustHttp.ItemHtml, {params});
  }
}


export default AdjustHttp;
