import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import IconItem from 'components/Item/IconItem/IconItem';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import UploadedMessageFile from 'components/message/uploaded-message-file/UploadedMessageFile';
import fileUpload from 'assets/Icons/file-upload.svg';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import { notify } from 'utils/marketplace';
import { Controller, useForm } from 'react-hook-form';
import useHttp from 'utils/hooks/useHttp';
import { IOption } from 'interfaces/select';
import Select from 'react-select';
import { fetchCatalogInfo } from 'store/reducers/catalog/catalogs.actions';

import { useSelector } from 'react-redux';

import './NewTariff.scss';
import { useAppDispatch } from 'utils/hooks/redux';
import { getCurrentCatalog } from 'store/selectors/catalogs.selector';

type Props = {
  setOpenModal: (e: boolean) => void;
  options: IOption[];
  preselectedClient

};

const NewTariff: React.FC<Props> = (props: PropsWithChildren<Props>) => {

  const { t } = useTranslation();
  const { selectClient } = useHttp();
  const [files, setFiles] = useState<File[] | []>([]);
  const [loading, setLoading] = useState(false);
  const currentCatalog = useSelector(getCurrentCatalog);


  const dispatch = useAppDispatch();


  const {
    getValues,
    register,
    handleSubmit,
    control,
    setValue,
    setError,
    formState: { errors },
  } = useForm<INewTariff>();

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setFiles([acceptedFiles[0]]);
    setValue('files', acceptedFiles[0]);
  }, [files]);

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    onDrop,
    multiple: false,
  });

  const removeFile = (file: File) => {
    const newFiles = [...files];

    newFiles.splice(newFiles.indexOf(file), 1);
    setFiles(newFiles);
  };

  const onSubmit = async () => {

    const values = getValues();

    const formData: FormData = new FormData();

    if (files.length) {
      formData.append('file', files[0]);
    }


    for (let i = 0; i < values.clients.length; i++) {
      formData.append('clients[]', String(values.clients[i].value));
    }

    formData.append('message', values.message.trim());

    if (files[0].type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      setError('files', {
        type: 'filetype',
        message: 'Only Excel files are valid.'
      });

      return;
    }

    try {

      setLoading(true);
      const response = await selectClient.uploadSellerFiles(formData).finally(() => {
        setLoading(false);
      });

      currentCatalog?.id && dispatch(fetchCatalogInfo(currentCatalog?.id));

      if (response) {
        notify('SUCCESS', 'success');
      }


      setFiles([]);
    } catch (e) {
      notify('NO_TARIFFS_TO_LOAD');
    } finally {
      props.setOpenModal(false);
    }
  };

  useEffect(() => {
    if (props.preselectedClient) {
      setValue('clients', [props.preselectedClient]);
    }
  }, [props.options]);

  return (
    <div className="modal-new-tariff">
      <div className="close" onClick={() => props.setOpenModal(false)}>
        <IconItem icon={faClose} onClick={() => null} />
      </div>
      <div className="modal-dialog">
        <div className="modal-container">
          <div className="modal-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="title">Importez un fichier</div>
              <div className="tariff-modal-container">
                <div className="tariff-upload tariff-content">
                  <div {...getRootProps({ className: `${errors.files ? 'content errors' : 'content'}` })}>

                    <input {...getInputProps()} />

                    <img src={fileUpload} alt="file-upload tariff-content" />
                    <p>Importez</p>
                  </div>
                  {errors.files && <div className="file-error">{errors.files.message}</div>}
                </div>
                {
                  files.length ? (
                    <div className="tariff-files tariff-content">
                      {
                        files.map((file, fileIndex) => {
                          return (
                            <UploadedMessageFile key={fileIndex} messageFile={file} removeFile={() => removeFile(file)} />
                          );
                        })
                      }
                    </div>
                  ) : null
                }
                <div className="tariff-clients tariff-content">
                  <Controller
                    control={control}
                    {...register('clients', { required: true })}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        className={`select-client ${errors.clients?.type == 'required' ? 'error' : ''}`}
                        placeholder="Clients"
                        isMulti
                        value={value}
                        onChange={(val) => {
                          onChange(val);
                        }}
                        options={props.options}
                        isClearable
                      />
                    )}
                  />
                </div>
                <div className="tariff-message tariff-content">
                  <textarea
                    {...register('message', {
                      required: true,
                    })}
                  />
                </div>
                <div className="tariff-submit tariff-content">
                  <DefaultButton isloading={loading} text="Submit" type="submit" />
                </div>
                {
                  fileRejections.length > 0
                    ? <div className="modal-message-dropzone__error">{t('FILE_RESTRICT')}</div>
                    : ''
                }
              </div>
            </form>

          </div>
        </div>
      </div>
    </div>
  );
};


NewTariff.displayName = 'CatalogTariff';

export type INewTariff = {
  clients: IOption[];
  message: string;
  files: File;
  supplier_mapping_group?: number | null;
};

export default NewTariff;