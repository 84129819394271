import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { IProformaResponse } from 'utils/hooks/http/proforma.http';
import './OrderModal.scss';

type Props = {
    data?: IProformaResponse;
    handleCloseModal: () => void
    isDirectorCommercial?: boolean
}

const OrderModal: React.FC<Props> = (props) => {
  const {t} = useTranslation();
  const {user} = useSelector((state: RootState) => state.userData);

  return (
    <div className="modal-success-proforma">
      {/* <h1>{t('_upload_proforma_successfully')}</h1> */}
      <h1>{t('_submitted_succesfully')}</h1>
      {props.isDirectorCommercial ?
        <div>
          {props.data?.order && (
            props.data?.is_m3_connected ? 
              <h2>
                {t('_m3_order')}: {props.data?.order}
              </h2>
              : 
              <h2>
                {t('_apl_order')}: {props.data?.order}
              </h2>
          )}
          {(props.data?.aol_order) && (
            <h2>
              {t('_aol_order')}: {props.data?.aol_order}
            </h2>
          )}
        </div>
        : (
          user?.client_settings.m3_erp_connection ? 
            <div>
              {props.data?.m3_order && (
                <h2>
                  {t('_m3_order')}: {props.data?.m3_order}
                </h2>
              )}
              {(props.data?.aol_order) && (
                <h2>
                  {t('_aol_order')}: {props.data?.aol_order}
                </h2>
              )}
              {(props.data?.waiting_offer) && (
                <h2>
                  {t('_waiting_offer')}: {props.data.waiting_offer}
                </h2>
              )}
            </div>
            : (
              <React.Fragment>
                {(props.data?.order || props.data?.proformaId) && (
                  <h2>
                    {t('_order_proforma_id')} {props.data?.order ?? props.data?.proformaId}
                  </h2>
                )}
                {props.data?.estimationId && (
                  <h2>
                    {t('_order_estimation_id')}: {props.data.estimationId}
                  </h2>
                )}
                {props.data?.proformaId && <h2>Proforma ID: {props.data?.proformaId}</h2>}
              </React.Fragment>
            )
        )}
      {props.data?.status === 'PENDING' && (
        <h2 className="order-modal-pending">{t('_order_being_processed')}</h2>
      )}
      <DefaultButton onClick={props.handleCloseModal} text={t('_close')} />
    </div>
  );
};

export default OrderModal;