import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import VinSearch from '../../vin-search/VinSearch';
import PlateSearch from '../../plate-search/PlateSearch';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faMinus, faPlus} from '@fortawesome/free-solid-svg-icons';
import Tabs from '../../vehicle-card/Tabs/Tabs';
import Tab from '../../vehicle-card/Tabs/Tab';
import {ReactComponent as VinIcon} from 'assets/Icons/vin-icon.svg';
import {ReactComponent as PlateNumberIcon} from 'assets/Icons/plate-number-icon.svg';
import './VinPlateMobileCard.scss';


type Props = {
  setOpenModal?: (e: boolean) => void;
  link?:string;
  };

const VinPlateCardMobileView: React.FC<Props> = (props) => {
  const {t}= useTranslation();
  const {user} = useSelector((state: RootState) => state.userData);
  const [isActive, setIsActive] = useState(false);

  return (
    <div className="search-card">
      <div className="card-title" onClick={() => (props.setOpenModal ? null : setIsActive(!isActive))}>
        {t('_you_know_vehicle_in_front')}
        {!props.setOpenModal && (isActive ? <FontAwesomeIcon icon={faMinus} /> : <FontAwesomeIcon icon={faPlus} />)}
      </div>
      {(isActive || props.setOpenModal) && (
        <div className="card-content">
          <Tabs>
            <Tab title={<div className="title"><VinIcon /></div>}>
              <div className="search-inputs-container search-vin">
                <VinSearch setOpenModal={props.setOpenModal} link={props.link} />
              </div>
            </Tab>
            {user?.client_settings.plate_number_search ? (
              <Tab
                title={
                  <div className="title">
                    <PlateNumberIcon />
                    <span>{t('_plate')}</span>
                  </div>
                }
              >
                <div className="search-inputs-container search-plate">
                  <PlateSearch setOpenModal={props.setOpenModal} link={props.link} />
                </div>
              </Tab>
            ) : null}
          </Tabs>
        </div>
      )}
    </div>
  );
};

VinPlateCardMobileView.displayName = 'VinPlateCardMobileView';

export default VinPlateCardMobileView;
