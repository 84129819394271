import React, { useMemo, useState } from 'react';
import { Column, useExpanded, useTable } from 'react-table';

import { useNavigate, useSearchParams } from 'react-router-dom';
import { IData } from 'models/order';
import Spinner from 'components/spinner/Spinner';
import Head from 'components/tables/Cells/Head/Head';
import { useTranslation } from 'react-i18next';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import i18n from 'i18n';
import Pagination from 'components/pagination/Pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faClose } from '@fortawesome/free-solid-svg-icons';
import useHttp from 'utils/hooks/useHttp';
import Modal from 'components/modal/Modal';
import OrderConfirmationModal from '../OrderConfirmationModal/OrderConfirmationModal';
import { notify } from 'utils/marketplace';
import OrderModal from 'applications/automotor/components/OrderModal/OrderModal';
import './ClientOrderTable.scss';
import { IProformaResponse } from 'utils/hooks/http/proforma.http';
import ExpandButton from 'components/tables/TableMoreInformation/ExpandButton/ExpandButton';
import TableMoreInformation from 'components/tables/TableMoreInformation/TableMoreInformation';


type Props = {
  data: IData[]
  loading: boolean
  handlePageClick?: (data) => void
  totalPage?: number
  currentPage?: number
  setOpenDetails?
  hiddenColumns?
  lastItemRef?
  getClientOrderList?
  orderAction: string;
  setOrderAction?
};


const ClientOrderTable: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const http = useHttp();
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [orderId, setOrderId] = useState<number>();

  const [resData, setResData] = useState<IProformaResponse>();

  const [searchParams, setSearchParams] = useSearchParams();
  const { isMobile, isTablet, isDesktop } = useDeviceDetect();

  function onConfirmOrder(){
    setLoading(true);
    http.order.orderAction(String(orderId), {action: props.orderAction})
      .then((res) => {
        notify(t('_success'), 'success');

        if(props.orderAction == 'confirmed'){
          setResData(res.data);
          setOpenConfirmModal(true);
        }

        props.getClientOrderList();
      })
      .catch(() => notify(t('_error')))
      .finally(() => {
        setLoading(false);
        setOpenModal(false);
      });
  }

  function onOpenModal(e){
    e.stopPropagation();
    setOpenModal(true);
  }


  const handleOnCloseModal = () => {
    setOpenConfirmModal(false);
  };


  const columns = useMemo(
    (): Column<IData>[] => [
      {
        id: 'expander',
        Header: '',
        width: 15,
        Cell: (row) => {
          return <ExpandButton row={row.row} />;
        },
      },
      {
        Header: <Head text={t('_id').toLocaleUpperCase()}></Head>,
        accessor: 'id',
      },
      {
        Header: <Head text={t('_price').toLocaleUpperCase()}></Head>,
        accessor: 'total_price',
        Cell: (props) => { return (props.row.values.total_price ? props.row.values.total_price.toFixed(2) : 0); },
      },
      {
        Header: <Head text={t('_client').toLocaleUpperCase()}></Head>,
        accessor: 'client_full_name',
      },
      {
        Header: <Head text={t('_name').toLocaleUpperCase()}></Head>,
        accessor: 'user_full_name',
      },
      {
        Header: <Head text={t('_email').toLocaleUpperCase()}></Head>,
        accessor: 'user_email',
      },
      {
        Header: <Head text={t('_proforma_id').toLocaleUpperCase()}></Head>,
        accessor: 'proforma_id',
        Cell: (props) => <span>{props.row.original.m3_order_id ? props.row.original.m3_order_id : props.row.original.proforma_id}</span>
      },
      {
        Header: <Head text={t('_date').toLocaleUpperCase()}></Head>,
        accessor: 'time',
      },
      {
        Header: <Head text={t('_status').toLocaleUpperCase()}></Head>,
        accessor: 'status',
        Cell: (props) => <span>{t(props.row.original?.status)}</span>
      },
      {
        Header: <Head text={t('_actions').toLocaleUpperCase()}></Head>,
        accessor: 'actions',
        Cell: (prop) => (
          <span>{isMobile || isTablet ? 
            <React.Fragment>
              <FontAwesomeIcon onClick={(e) => {onOpenModal(e); setOrderId(prop.row.original.id); props.setOrderAction('confirmed');}} icon={faCheck} className="confirm-order" size="lg"/>
              {t(prop.row.original?.status) !== 'Rejected' && <FontAwesomeIcon onClick={(e) => {onOpenModal(e); setOrderId(prop.row.original.id); props.setOrderAction('rejected');}} icon={faClose} className="reject-order" size="lg" />}
            </React.Fragment>
            :
            <React.Fragment>
              <div className="confirm-order-btn" onClick={(e) => {onOpenModal(e); setOrderId(prop.row.original.id); props.setOrderAction('confirmed');}}>
                {t('_confirm')}
              </div>
              {t(prop.row.original?.status) !== 'Rejected' &&
              <div className="reject-order-btn" onClick={(e) => {onOpenModal(e); setOrderId(prop.row.original.id); props.setOrderAction('rejected');}}>
                {t('_reject')}
              </div>
              }
            </React.Fragment>
          }
          </span>
        )
      },
    ],
    [i18n.language, isMobile, isTablet, isDesktop],
  );

  if (!isMobile) {
    columns.shift();
  }

  const hiddenColumns = isMobile ? ['id', 'total_price', 'user_email', 'proforma_id', 'time' ] : [];

  const initialState = hiddenColumns ? { hiddenColumns } : {};

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data: props.data,
    initialState: initialState
  },
    useExpanded
  );

  function handleRowClick(id , key){
    if(!(key.includes('cell') && key.includes('expander'))) {
    props.setOpenDetails(true);

    setSearchParams({...searchParams, id});
    }
  } 

  return (
    <div className="client-order-table">
      <div className="client-order-table-container">
        {props.loading ? <Spinner class="sellers-table-spinner" /> :
          props.data.length ? (
            <table {...getTableProps()}>

              <thead className="headings">
                {headerGroups.map((headerGroup, index) => {
                  return (
                    <tr key={headerGroup.headers[index].id} className="row">
                      {headerGroup.headers.map((column) => {
                        const { key, ...restColumn } = column.getHeaderProps();

                        return (
                          <th key={key} {...restColumn} className="heading">
                            {column.render('Header')}
                          </th>
                        );
                      })}
                    </tr>
                  );
                })}
              </thead>
              <tbody {...getTableBodyProps} className="body">
                {rows.map((row, index) => {
                  prepareRow(row);
                  const { key, ...restRowProps } = row.getRowProps();

                  if (index === rows.length - 1) {
                    return (
                      <tr key={key} {...restRowProps} className="row" >
                        {row.cells.map((cell) => {
                          const { key, ...restCellProps } = cell.getCellProps();

                          return (
                            <td key={key} {...restCellProps} className="cell" onClick={() => handleRowClick(row.original.id ,key)}>
                              {cell.render('Cell')}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  }

                  return (
                    <React.Fragment key={key}>
                    <tr key={key} {...restRowProps} className="row" >
                      {row.cells.map((cell) => {
                        const { key, ...restCellProps } = cell.getCellProps();

                        return (
                          <td key={key} {...restCellProps} className="cell" onClick={() => handleRowClick(row.original.id,key)}>
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>
                    {row.isExpanded ? TableMoreInformation({ row, hiddenColumns: hiddenColumns, extraFieldsNumber: 2 }) : null}
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
          ) :
            <div className="order-no-data">{t('_no_data')}</div>
        }
        {/* {props.loading && <Spinner class={`extra-small order-table-small-spinner ${props.data.length ? 'has-data' : ''} `} />} */}
      </div>

      <div>
        {props.totalPage! > 1 && (
          <Pagination pageCount={props.totalPage!} handlePageClick={props.handlePageClick!} forcePage={props.currentPage!} />
        )}
      </div>
      <Modal
        openModal={openModal}
        setOpenModal={setOpenModal}
        childrenComponent={<OrderConfirmationModal setOpenModal={setOpenModal} loading={loading} onConfirmOrder={onConfirmOrder} orderAction={props.orderAction} />}
      />
      <Modal
        openModal={openConfirmModal}
        setOpenModal={setOpenConfirmModal}
        onRequestClose={handleOnCloseModal}
        childrenComponent={<OrderModal data={resData} handleCloseModal={handleOnCloseModal} isDirectorCommercial />}
      />
    </div>
  );
};

ClientOrderTable.defaultProps = {
  currentPage: 1
};

export default ClientOrderTable;
