import {
  CirrusPunchoutResponse,
  GetCartResponse,
  ICartQuantityResponse,
  ISuppliersAmerigo,
  IVehicleParam,
  IVehicleResponce,
  GetCart,
} from 'models/cart.model';
import { addRouteParam, IHttpResponse, serverAxios } from '../../http';
import { CancelToken } from 'axios';

class CartHttp {
  private static route = '/cart';
  private static checkout = '/checkout';
  private static amerigoCartQuantity = '/cart/quantity';
  private static amerigoOrder = '/amerigo-order/suppliers';
  private static amerigoCartOrder = '/amerigo-order/cart-by-supplier';
  private static amerigoCartCheckout = '/amerigo-order/checkout?dlnr=:dlnr';
  private static amerigoVehicleData = '/reliable/breadcrumb/:vehicle_id/categories';

  public async fetchVehicleData(vehicle_id: string, params: IVehicleParam): Promise<IVehicleResponce> {
    const { data } = await serverAxios.get(addRouteParam(CartHttp.amerigoVehicleData, { vehicle_id }), { params });

    return data;
  }

  public async getCartData(page = 1, per_page = 20, cancelToken?: CancelToken): Promise<GetCart> {
    if (localStorage.getItem('external_cart_id')) {
      const { data } = await serverAxios.get(CartHttp.route, {
        params: { page, external_cart_id: localStorage.getItem('external_cart_id') },
        cancelToken,
      });

      return data;
    } else {
      const { data } = await serverAxios.get(CartHttp.route, { params: { page, per_page } });

      return data;
    }
  }

  public async getSupplierCartData(dlnr: string): Promise<GetCartResponse[]> {
    const { data } = await serverAxios.get(CartHttp.amerigoCartOrder, { params: { dlnr } });

    return data.data;
  }

  public async getAmerigoOrderSuppliers(): Promise<ISuppliersAmerigo[]> {
    const { data } = await serverAxios.get(CartHttp.amerigoOrder);

    return data;
  }

  public async getCartQuantity(): Promise<ICartQuantityResponse> {
    if (localStorage.getItem('external_cart_id')) {
      const { data } = await serverAxios.get(CartHttp.amerigoCartQuantity, {
        params: { external_cart_id: localStorage.getItem('external_cart_id') },
      });

      return data;
    } else {
      const { data } = await serverAxios.get(CartHttp.amerigoCartQuantity);

      return data;
    }
  }

  public async supplierCartCheckout(dlnr: string): Promise<IHttpResponse<null | number>> {
    return serverAxios.post(addRouteParam(CartHttp.amerigoCartCheckout, { dlnr }));
  }

  public async checkout(data: { customer_id?: string }): Promise<IHttpResponse<null | number>> {
    return serverAxios.post(CartHttp.checkout, { ...data });
  }

  public async checkoutCirrus(data: { customer_id?: string; external_cart_id?: string }): Promise<IHttpResponse<CirrusPunchoutResponse>> {
    return serverAxios.post(CartHttp.checkout, { ...data });
  }

  public async deleteCartData(data: string[]): Promise<IHttpResponse<null | number>> {
    if (localStorage.getItem('external_cart_id')) {
      return serverAxios.delete(CartHttp.route, { params: { external_cart_id: localStorage.getItem('external_cart_id') }, data: data });
    } else {
      return serverAxios.delete(CartHttp.route, { data });
    }
  }
}

export default CartHttp;
