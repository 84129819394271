import React, { useState, useEffect } from 'react';
import { IClientInvitation } from 'models/client.model';
import useHttp from 'utils/hooks/useHttp';
import ModifyClientView from './InvitationView.view';
import { useNavigate, useParams } from 'react-router-dom';
import { notify } from 'utils/marketplace';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'utils/hooks/redux';
import { fetchPendingInvitation } from 'store/reducers/pending-invitations/pending-invitations.actions';
import { setPendingInvitations } from 'store/slices/pending-invitations.slice';

type Props = {};

const InvitationView: React.FC<Props> = () => {
  const { id } = useParams<'id'>();
  const navigate = useNavigate();
  const [clientInvitation, setClientInvitation] = useState<IClientInvitation | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const http = useHttp();

  useEffect(() => {
    refreshData();
  }, []);

  const refreshData = () => {
    setLoading(true);
    dispatch(fetchPendingInvitation(id?.toString() || ''))
      .then((invitation) => {
        dispatch(setPendingInvitations(invitation.payload));
        setClientInvitation(invitation.payload);
      })
      .finally(() => {
        setLoading(false);
      })
      .catch(() => {
        notify(t('_invitation_no_access'));
        navigate('/espace-clients/my-clients/invitations');
      });
  };

  const handleReturnClick = () => {
    navigate('/espace-clients/my-clients/invitations');
  };

  return (
    <ModifyClientView
      handleReturnClick={handleReturnClick}
      refreshData={refreshData}
      loading={loading}
      clientInvitation={clientInvitation}
    ></ModifyClientView>
  );
};

InvitationView.displayName = 'InvitationView';

export default InvitationView;
