import { generatePath } from 'react-router-dom';
import { addRouteParam, commercialAxios, IHttpResponse, serverAxios } from '../../http';

class PriceListHttp {
  private static tariffs = '/client-notification/tariffs';
  private static fileTypes = '/client-notification/file-types';
  private static supplierMapping = '/client-notification/supplier-mapping-list';
  private static manufacturerMapping = '/client-notification/manufacturer-mapping-list';
  private static deleteTariff = '/client-notification/upload/:id';
  private static downloadFile = '/client-notification/file/:id';
  private static sellerClients = '/seller/clients/all';
  private static uploadTariff = '/seller/upload-notification-tariff';
  private static updateTariff = '/seller/notification-file/:id/update-load-file-response';
  private static loadFile = '/loadFile/:zone_commercial/:id';
  private static uploadPriceProvider = '/loading-price/save';
  private static loadingPrice = '/loading-price/list';
  private static priceInfo = '/loading-price/:id';
  private static priceInfoRetry = '/loading-price/import/:id';
  private static getGroup = '/loading-price/get-group/:id';
  private static getHistory = '/loading-price/list/history/:id';


  public async getPriceList(params: { page: number }): Promise<IHttpResponse<IPaginationData>> {
    return serverAxios.get(PriceListHttp.tariffs, { params });
  }

  public async getFileTypes(): Promise<IHttpResponse<IFileTypes[]>> {
    return serverAxios.get(PriceListHttp.fileTypes);
  }

  public async getSupplierMappingList(): Promise<IHttpResponse<IMappingList[]>> {
    return serverAxios.get(PriceListHttp.supplierMapping);
  }
  public async getManufacturerMappingList(): Promise<IHttpResponse<IMappingList[]>> {
    return serverAxios.get(PriceListHttp.manufacturerMapping);
  }
  public async getSellers(): Promise<IHttpResponse<ISellers[]>> {
    return serverAxios.get(PriceListHttp.sellerClients);
  }
  public async deleteTariff(id: number): Promise<IHttpResponse<ISellers[]>> {
    return serverAxios.delete(addRouteParam(PriceListHttp.deleteTariff, { id }));
  }
  public async downloadFile(id: number): Promise<{ data?}> {
    return serverAxios.get(addRouteParam(PriceListHttp.downloadFile, { id }), { responseType: 'blob' });
  }
  public async loadFile(id: string, zone_commercial: string): Promise<{ data?}> {
    return commercialAxios.get(generatePath(PriceListHttp.loadFile, { id, zone_commercial }));
  }
  public async uploadTariff(form: FormData): Promise<{ data?}> {
    return serverAxios.post(PriceListHttp.uploadTariff, form, { headers: { 'content-type': 'multipart/form-data' } });
  }
  public async updateTariff(data: { status_code: number; message: string }, id: string): Promise<{ data?}> {
    return serverAxios.patch(generatePath(PriceListHttp.updateTariff, { id }), data);
  }

  public async uploadPriceProvider(form: FormData): Promise<{ data?}> {
    return serverAxios.post(PriceListHttp.uploadPriceProvider, form, { headers: { 'content-type': 'multipart/form-data' }, timeout: 30000 });
  }

  public async getLoadingPrice(params: { page: number }): Promise<IHttpResponse<ILoadingPriceData>> {
    return serverAxios.get(PriceListHttp.loadingPrice, { params });
  }
  public async getLoadingPriceInfo(id: string): Promise<IHttpResponse<ILoadingPriceList>> {
    return serverAxios.get(generatePath(PriceListHttp.priceInfo, { id }));
  }
  public async priceInfoRetry(id: string): Promise<IHttpResponse<ILoadingPriceList>> {
    return serverAxios.post(generatePath(PriceListHttp.priceInfoRetry, { id }));
  }
  public async priceGetGroup(id: string): Promise<IHttpResponse<[]>> {
    return serverAxios.get(generatePath(PriceListHttp.getGroup, { id }));
  }
  public async getLoadingPriceHistory(id: string, params: { page: number }): Promise<IHttpResponse<ILoadingPriceData>> {
    return serverAxios.get(generatePath(PriceListHttp.getHistory, { id }), { params });
  }


}

export interface IPaginationData {
  data: IPriceList[];
  page: number;
  per_page: number;
  total_count: number;
  total_pages: number;
}


export interface ILoadingPriceData {
  data: ILoadingPriceList[];
  page: number;
  per_page: number;
  total_count: number;
  total_pages: number;
}


export interface ILoadingPriceList {
  id: number;
  created_at: string;
  created_by: string;
  file: string;
  codfou: string;
  file_name: string;
  comments: string;
  status: string;
  started_at: string;
  finished_at: string;
  progress: number;
  logs: string;
}


export interface IPriceList {
  id: number;
  message: string;
  client: {
    id: number;
    name: string;
  };
  seen: boolean;
  created_at: string;
  created_by: {
    id: number;
    full_name: string;
    roles: string[];
  };
  updated_by: string;
  file: {
    id: number;
    filepath: string;
    name: string;
    file_type: string;
    file_translation: string;
    code_fou: string;
    zone_commercial: string;
    load_file: {
      status_code: number;
      message: string;
    };
    is_special_price: boolean;
    price_strategy: string;
  };
}

export interface IFileTypes {
  id: number;
  translation: string;
  type: string;
}

export interface IMappingList {
  code_fou: string;
  name: string;
}

export interface ISellers {
  value: number;
  label: string;
  country: string;
  zone_commercial: string;
}

export default PriceListHttp;
