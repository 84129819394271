import React from 'react';
import UploadProformaAmerigoFile from '../UploadProformaAmerigoFile/UploadProformaAmerigoFile';
import './LoadFileModalContent.scss';
import ProformaSupplierList from '../ProformaSupplierList/ProformaSupplierList';

const LoadFileModalContent: React.FC = () => {

  return (
    <div id="load-file-modal-content" className="modal-container">
      <div className="modal-body">

        <UploadProformaAmerigoFile/>
        <ProformaSupplierList />
      </div>
      <div className="modal-footer" >
      </div>
    </div>

  );
};

LoadFileModalContent.displayName = 'LoadFileModalContent';

export default LoadFileModalContent;