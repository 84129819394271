import SkeletonSpinner from 'components/skeleton-spinner/SkeletonSpinner';
import { IOilSearch } from 'interfaces/oil';
import React, { MutableRefObject, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { createSearchParams, generatePath, useNavigate } from 'react-router-dom';
import { useOnClickOutside } from 'utils/hooks/useOnClickOutside';
import DropdownContentTable from 'components/DropdownContentTable/DropdownContentTable';
import './OilDropdownContent.scss';
import Head from 'components/tables/Cells/Head/Head';
import { getBackendAssetsBasePath } from 'utils/marketplace';
import { Column } from 'react-table';
import { isRetailerMecadepotChild } from 'utils/isRetailerMecadepotChild';

type Props = {
  data: IOilSearch[] | undefined;
  setIsOpen: (isOpen: boolean) => void;
  loading: boolean;
  oilValue?: string;
};

const ConsumableDropdownContent: React.FC<Props> = (props) => {
  const oilDropdownRef = useRef() as MutableRefObject<HTMLDivElement>;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMecadepotChild = isRetailerMecadepotChild();


  useOnClickOutside(oilDropdownRef, () => props.setIsOpen(false));

  const tableData: Column<IOilSearch>[] =
    isMecadepotChild ?
      [
        {
          Header: <Head text=""></Head>,
          Cell: (cell) => (
            <img src={cell.cell.row.original.image ? `${getBackendAssetsBasePath()}/${cell.cell.row.original.image}` : '/images/car-part-placeholder.png'} width={30} height={30} alt="oil" onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = '/images/car-part-placeholder.png';
            }}
            />)
          ,
          accessor: 'image',
        },
        {
          Header: <Head text={t('_description').toLocaleUpperCase()}></Head>,
          accessor: 'description',
        },
      ] :
      [
        {
          Header: <Head text=""></Head>,
          Cell: (cell) => (
            <img src={cell.cell.row.original.image ? `${getBackendAssetsBasePath()}/${cell.cell.row.original.image}` : '/images/car-part-placeholder.png'} width={30} height={30} alt="oil" onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = '/images/car-part-placeholder.png';
            }}
            />)
          ,
          accessor: 'image',
        },
        {
          Header: <Head text={t('_brand').toLocaleUpperCase()}></Head>,
          accessor: 'brand_name',
        },
        {
          Header: <Head text={t('_reference').toLocaleUpperCase()}></Head>,
          accessor: 'reference',
        },
        {
          Header: <Head text={t('_category').toLocaleUpperCase()}></Head>,
          accessor: 'category_name',
        },
      ];

  function handleOilClick(item) {
    const params = {
      page: '1',
      category_ids: item.category_id,
      brand_id: item.brand_id,
      family: item.family,
      quantity: item.quantity,
      viscosity: item.viscosity,
      oil_id: item.oil_id,
      q: props.oilValue
    };

    const validParams = Object.entries(params).reduce((acc, [key, value]) => {
      if (!value) {
        return acc;
      }

      acc[key] = value;

      return acc;
    }, {});

    navigate({
      pathname: generatePath('/oils'),
      search: `?${createSearchParams(validParams)}`,
    });
    props.setIsOpen(false);
  }

  // function handleOilCategoryClick(e, item) {
  //   e.stopPropagation();
  //   const params = {
  //     page: '1',
  //     category_ids: item.category_id,
  //     q: props.oilValue
  //   };

  //   const validParams = Object.entries(params).reduce((acc, [key, value]) => {
  //     if (!value) {
  //       return acc;
  //     }

  //     acc[key] = value;

  //     return acc;
  //   }, {});

  //   navigate({
  //     pathname: generatePath('/oils'),
  //     search: `?${createSearchParams(validParams)}`,
  //   });
  //   props.setIsOpen(false);
  // }

  return (
    <div ref={oilDropdownRef} className="oil-dropdown-content">
      <div className="oil-dropdown-header">
        <div className="oil-dropdown-title">{t('_search_result')}</div>
      </div>
      <div className="oil-search-result-content">
        {props.loading ? (
          <SkeletonSpinner />
        ) : (
          props.data?.length ? (
            <DropdownContentTable columns={tableData} itemClick={handleOilClick} data={props.data} loading={props.loading} />
          ) : (
            <div className="no-data">{t('_no_data').toLocaleUpperCase()}</div>
          )
        )}
      </div>
    </div>
  );
};

export default ConsumableDropdownContent;
