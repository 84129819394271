import React, {useEffect, useState} from 'react';
import PlatformAdminMessagesView from './PlatformAdminMessages.view';
import {
  IGetRetailerParentMessagesParams,
  IMessageSupplier,
  IMessageSupplierPaginate
} from '../../interfaces/messaging';
import useHttp from '../../utils/hooks/useHttp';
import MessagingHttp from '../../utils/hooks/http/messaging';
import {notify} from '../../utils/marketplace';

import {IIdName} from '../../models/car-parts.model';

type Props = {};

const PlatformAdminMessages: React.FC<Props> = () => {
  const { platformAdminMessaging, clients} = useHttp();
  const [loading, isLoading] = useState<boolean>(false);
  const [messages, setMessages] = useState<IMessageSupplier[] | []>([]);
  const [selectedClient, setSelectedClient] = useState<IIdName | null>(null);
  const [clientList, setClientList] = useState<IIdName[] | []>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);

  const [pagination, setPagination] = useState<IMessageSupplierPaginate>({
    page: 1,
    per_page: MessagingHttp.SUPPLIER_MESSAGES_PER_PAGE,
    total_pages: 0,
    total_count: 0
  });

  useEffect(() => {
    getMessages();
    getClients();
  }, []);

  useEffect(() => {
    getMessages();
  }, [selectedClient]);

  const getClients = async() => {

    try {
      const response = await clients.getClientsAll();

      setClientList(response);

    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      notify(e.message);
    }

  };

  const getMessages = async () => {
    isLoading(true);

    try {
      const params: IGetRetailerParentMessagesParams = {
        page: pagination.page,
        per_page: pagination.per_page,
      };

      if(selectedClient) {
        params.client_id = selectedClient?.id;
      }

      const {data} = await platformAdminMessaging.getLastMessages(params);

      setMessages(data.data);
      setPagination({
        page: data.page,
        per_page: data.per_page,
        total_count: data.total_count,
        total_pages: data.total_pages
      });

    }catch (e: unknown) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      notify(e.message);
    } finally {
      isLoading(false);
    }
  };

  const handleChangeSupplier = (val ) => {
    if(!val){
      setSelectedClient(null);

      return;
    }

    const supplier = clientList.find((item) => item.id == val.value );

    if(supplier) {
      setSelectedClient(supplier);
    }
  };

  const handlePageClick = (page: number) => {
    if(!pagination) {
      return;
    }

    const paginationCopy = pagination;

    paginationCopy.page = page + 1;

    setPagination(paginationCopy);
    getMessages();
  };

  return <PlatformAdminMessagesView getMessages={getMessages} openModal={openModal} setOpenModal={setOpenModal} handlePageClick={handlePageClick} selectedClient={selectedClient} handleChangeSupplier={handleChangeSupplier} loading={loading} pagination={pagination} messages={messages} clientList={clientList}></PlatformAdminMessagesView>;
};

PlatformAdminMessages.displayName = 'PlatformAdminMessages';

export default PlatformAdminMessages;
