import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './EspaceSupplier.scss';
import LogoItem from '../../../../components/Item/LogoItem/LogoItem';
import SellersTable from '../../../../components/tables/SellersTable/SellersTable';
import DefaultButton from '../../../../components/buttons/DefaultButton/DefaultButton';
import { ICatalogs } from 'models/catalog.model';
import Spinner from 'components/spinner/Spinner';
import SupplierPromo from '../../../../components/Promotions/Supplier/SupplierPromo';
import {supplierPromoItems} from '../../../../components/Promotions/Supplier/SupplierPromo.map';
import { useNavigate } from 'react-router-dom';
import { IData } from 'models/order';
import useHttp from 'utils/hooks/useHttp';
import { getBackendAssetsBasePath } from 'utils/marketplace';

type Props = {
  catalogsData: ICatalogs[]
  loadingCatalogs: boolean
  handleCatalogClick: (id)=> void
}

const EspaceSupplierView: React.FC<Props> = (props) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const http = useHttp();
  const [data, setData] = useState<IData[]>([]);
  const [loading, setLoading] = useState(false);


  function getOrderList(params){
    setLoading(true);
    http.order.getOrders(params).then(res=> setData(res.data.data)).finally(()=>{
      setLoading(false);
    });

  }

  useEffect(()=>{
    getOrderList({per_page: 5});
  },[]);

  return (
    <div className="espace-supplier-container">
      <div className="espace-supplier-top-container">
        <div className="title">
          {t('_espace')}
          <span>&nbsp;Automotor</span>
        </div>
        <div className="subtitle">{t('_espace_supplier_subtitle')}</div>

        <div className="items-container">
          {props.loadingCatalogs ? <Spinner class="catalogs-spinner" /> : (
            <div className="items">
              {
                props.catalogsData.map(({images, id }) => {
                  return (
                    <LogoItem image={`${getBackendAssetsBasePath()}${images[1]}`} onClick={()=>props.handleCatalogClick(id)} key={id}/>
                  );
                })
              }
            </div>
          )}
        </div>
      </div>
      <div className="espace-supplier-main-container container-fluid-large">
        <div className="promotions-slider">
          <SupplierPromo items={supplierPromoItems} />
        </div>
        <div className="order-container">
          <div className="title">{t('_espace_order_title')}</div>
          <div className="order-list-container">
            <SellersTable data={data} loading={loading} />
            <div className="button">
              <DefaultButton text={t('_all_orders')} onClick={() => navigate('/orders')} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

EspaceSupplierView.displayName = 'EspaceSupplierView';

export default EspaceSupplierView;
