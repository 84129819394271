import {serverAxios} from '../../http';
import { IUserProfile, IUserPutResponse} from '../../../models/IUser';
import { IPromiseInfoUser } from 'interfaces/user';

class UserHttp {
  private static route = 'user';
  private static info = '/user/info';

  public async putUser(user:IUserPut | IUserProfile | {}): Promise<IUserPutResponse> {
    return serverAxios.put(UserHttp.route, user);
  }
  public async postUser(formData:FormData | {}): Promise<IUserPutResponse> {
    return serverAxios.post(UserHttp.route, formData, { headers: { 'content-type': 'multipart/form-data' },});
  }
  public async infoUser(): Promise<IPromiseInfoUser> {
    return serverAxios.get(UserHttp.info);
  }
}

export type IUserPut = {
  language?: string;
  adr?: string
  cde?: boolean
  created_by?: number
  created_date?: string
  dlnr?: string
  email?: string
  first_name?: string
  gender?: string
  last_connection?: string
  last_name?: string
  mail?: boolean
  mobile?: string
  phone?: string
  position?: string
  supplier_group_id?: string
  updated_by?: number
  updated_date?: string
  visa_apl?: string
  logo?: object
};

export default UserHttp;