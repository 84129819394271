import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import './ButtonLink.scss';

type Props = {
  text: string | string[];
  className?: string;
  pdf_name?: string;
  icon?;
  itemLink?: string;
  disabled?: boolean;
  active?: string;
};

const ButtonLink: React.FC<Props> = (props: Props) => {
  const [hideTooltip, setHideTooltip] = useState(true);

  return (
    <NavLink className={`button-link-container ${props.disabled ? 'disabled' : ''}`} tabIndex={1} to={String(props.itemLink)}>
      <div
        className="image"
        onMouseEnter={() => setHideTooltip(true)}
        onMouseLeave={() => {
          setHideTooltip(false);
          setTimeout(() => setHideTooltip(true), 50);
        }}
        data-tip
        data-for={props.text}
      >
        {/* {if(search.includes(props.text){

        })}; */}
        {props.icon}
      </div>
      {hideTooltip && (
        <ReactTooltip id={typeof props.text === 'string' ? props.text : ''} className="tooltip-class" place="top" effect="solid">
          <span>{props.text}</span>
        </ReactTooltip>
      )}
      <p className="text">{props.text}</p>
    </NavLink>
  );
};

export default ButtonLink;
