import React, { ReactElement, useEffect, useState } from 'react';
import TabTitle from './TabTitle';
import './TabStyle.scss';

type Props = {
  children: ReactElement[];
  disabled?: boolean
  selectedTab?: number;
};

const Tabs: React.FC<Props> = (props) => {
  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {

    if (props.selectedTab) {
      setSelectedTab(props.selectedTab);
    }

  }, [props.selectedTab]);

  return (
    <div className="vehicle-selector-tabs-container">
      <div className="vehicle-tabs">
        {props.children.map((item, index) => (
          <TabTitle disabled={props.disabled} key={index} title={item.props.title} index={index} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
        ))}
      </div>
      {props.children[selectedTab]}
    </div>
  );
};

export default Tabs;
