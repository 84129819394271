export enum TabCategories {
  Pieces = 'pieces',
  Pneus = 'pneus',
  Huiles = 'huiles',
  Consumables = 'consumables',
  Battery = 'battery',
  Parts = 'parts'
}

export type ICategoryContentTab = {
  title: string;
  items: string[];
};

export type ICategoryTab = {
  id: TabCategories;
  icon: JSX.Element;
  header: string;
  status?: boolean;
};


export type ITyreBrand = {
  brand_id: number;
  brand_image: string;
  brand_name: string;
}

export type ITyreSeason = {
  id: number;
  image?: string;
  name: string;
} 

export interface IOilCategory {
  category_id: number;
  category_image: string;
  category_name: string;
}

export interface ITyreCategory {
  qualities: {
    BUDGET: ITyreBrand[],
    PREMIUM: ITyreBrand[],
    QUALITY: ITyreBrand[],
  }
  seasons: ITyreSeason[]
}

export interface IConsumableBrands {
  id: number;
  name: string;
  image:string;
  erp_brand_id: string;
}

export interface IConsumableCategories {
  id: number;
  name: string;
  image:string;
 
}


