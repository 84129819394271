import React from 'react';
import {IMessage} from '../../interfaces/messaging';

export enum MessagesTypes {
  automotor= 'automotor',
  amerigo = 'amerigo',
  platformAdminClients = 'platformAdminClients',
  platformAdminOrders = 'platformAdminOrders',
  order = 'order'
}

export type IMessageCreate = {
  message: string;
  files: File[];
  supplier_mapping_group?: number | null;
  order_id?: string;
};

export const removeFile = (files:File[], file: File, setFiles: (files:File[]) => void): void => {
  const newFiles = [...files];

  newFiles.splice(newFiles.indexOf(file), 1);
  setFiles(newFiles);
};

export const handleScroll = (loadingMessages: boolean, loadingOldMessages: boolean, ref: React.MutableRefObject<HTMLDivElement>, isScrollOnTop: (value: boolean) => void): void => {
  if (!loadingMessages && !loadingOldMessages) {

    if (!ref.current.scrollTop) {
      isScrollOnTop(true);
    }
  }
};

export const scrollBottom = (ref:React.MutableRefObject<HTMLDivElement>):void => {
  setTimeout(() => {
    if(ref.current) {
      ref.current.scrollTo({ top: ref.current.scrollHeight });
    }

  });
};

export const deleteMessageFromList = (messages:IMessage[] | [], messageItem: IMessage, setMessages: (messages: IMessage[] | []) => void): void => {
  const messagesFilters = messages.filter((message) =>  messageItem.id !== message.id);

  setMessages(messagesFilters);
};

export const AutomotorMessagesTypes = [MessagesTypes.automotor, MessagesTypes.amerigo, MessagesTypes.order];

export const PlatformAdminMessagesTypes = [MessagesTypes.platformAdminClients, MessagesTypes.platformAdminOrders];