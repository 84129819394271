import { faCheck, faExclamation, faEllipsis, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import SendEmailToSeller from 'applications/automotor/components/espace-automotor/send-email-to-seller/SendEmailToSeller';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import Modal from 'components/modal/Modal';
import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getAutomotorTariff } from 'store/selectors/automotor-tariff.selector';
import { IProformaResponse, IUploadFileProformaResponse } from 'utils/hooks/http/proforma.http';
import useHttp from 'utils/hooks/useHttp';
import { notify } from 'utils/marketplace';
import { IFileRow } from '../automotor-order.mode';
import './OrderSummaryV3.scss';

import TableSectionDetails from '../order-summary/table-section-details/TableSectionDetails';
import TextInput from 'components/inputs/TextInput/TextInput';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import { useOnClickOutside } from 'utils/hooks/useOnClickOutside';
import { useLocaleDatePicker } from 'utils/localeDatePicker';
import i18n from 'i18n';
import { IImportFileNew, IParts } from 'interfaces/price';

import Head from 'components/tables/Cells/Head/Head';
import { Column } from 'react-table';
import { useAppDispatch } from 'utils/hooks/redux';
import { setLoadingButtonSubmitProforma, setRederTable } from 'store/slices/buttonSubmitProforma.slice';
import ReferenceTable from '../order-summary/reference-table/ReferenceTable';
import PartsSection, { ISectionType, SectionConfirmed, SectionNOTConfirmed, SectionNOTFound, SectionNOTValidate } from '../order-summary/parts-section/PartsSection';
import ModalRestriction from './modalRestriction/ModalRestriction';
import OrderModal from 'applications/automotor/components/OrderModal/OrderModal';
import CURRENCY from 'utils/constants/currency';

type IRowTable = {
  // total: { totalPrice: string, index: number},
  [key: string]: string | number | null;
};
const OrderSummaryV3: React.FC = () => {
  const SECTION_CONFIRMED = SectionConfirmed;
  const SECTION_NOT_CONFIRMED = SectionNOTConfirmed;
  const SECTION_NOT_FOUND = SectionNOTFound;
  const SECTION_NOT_VALIDATE = SectionNOTValidate;

  const SEND_IN_QUOTATION = 'send_in_quotation';
  const CONFORM_TARIFFS = 'conform_tariffs';

  const { t } = useTranslation();

  const navigator = useNavigate();

  const user = JSON.parse(localStorage.getItem('userData')!);

  const [, setProformaFileInProgress] = useState<IUploadFileProformaResponse>();
  const http = useHttp();

  const [referencesConfirmed, setReferencesConfirmed] = useState<Array<IImportFileNew>>([]);
  const [referencesNotConfirmed, setReferencesNotConfirmed] = useState<Array<IFileRow>>([]);
  const [referencesNotFound, setReferencesNotFound] = useState<Array<IFileRow>>([]);
  const [showSectionDetails, setShowSectionDetails] = useState<boolean>(false);
  const [isLoadingSendProforma, setIsLoadingSendProforma] = useState<boolean>(false);
  const [showSectionDetailsTypeElement, setShowSectionDetailsTypeElement] = useState<JSX.Element>();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [resData, setResData] = useState<IProformaResponse>();
  const [startDate, setStartDate] = useState(null);
  const [valueDate, setValueDate] = useState('');
  const [openDate, setOpenDate] = useState(false);
  const dateRef = useRef() as MutableRefObject<HTMLDivElement>;
  const [openModalValidate, setOpenModalValidate] = useState<boolean>(false);
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [orderSubmitButton, setOrderSubmitButton] = useState<boolean>(false);
  const [openModalCountryRestriction, setOpenModalCountryRestriction] = useState<boolean>(false);
  const [submitCountryRestriction, setSubmitCountryRestriction] = useState<boolean>(false);
  const [exportLoad, setExportLoad] = useState<boolean>(false);
  const [submitFromChild, setSubmitFromChild] = useState<boolean>(false);
  const [totalOrderValidate, setTotalOrderValidate] = useState<Number | null>(null);
  const [dataCountryRestriction, setDataCountryRestriction] = useState<{ [key: string]: [string] }>();

  const dispatch = useAppDispatch();

  useOnClickOutside(dateRef, () => setOpenDate(false));

  const handleOnCloseModal = () => {
    navigator('/automotor-online');
  };

  const sellersData = useSelector(getAutomotorTariff);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (localStorage.getItem('proformaFileInProgress')) {
      const proformaFile = JSON.parse(localStorage.getItem('proformaFileInProgress') as string);

      if (proformaFile.min_order_value) {
        setTotalOrderValidate(proformaFile.min_order_value);
      }

      setProformaFileInProgress(proformaFile);

      setReferencesConfirmed(proformaFile.partsWithReplacements);

      const notConfirmedRefs: Array<IFileRow> = [];
      const notFoundRefs: Array<IFileRow> = [];

      proformaFile.rows.forEach((row) => {
        switch (row.section) {
          case 'PRICE_UPDATE':

            notFoundRefs.push(row);

            break;
          case 'REFERENCE_NOT_FOUND':
            notFoundRefs.push(row);

            break;
          case 'REFERENCE_NOT_ALLOWED':
            notFoundRefs.push(row);

            break;
        }
      });

      setReferencesNotConfirmed(notConfirmedRefs);
      setReferencesNotFound(notFoundRefs);

      checkRestrictionParts(proformaFile.partsWithReplacements);

    } else {
      navigator('/automotor-online');
      notify('Order summery does\'t exist');
    }
  }, []);


  const checkRestrictionParts = async (data) => {
    const country_restriction = {};

    data.map((items) => {
      items.parts.map((item) => {
        if (item.country_restriction) {
          if (typeof country_restriction[item.country_restriction.name] !== 'undefined') {
            country_restriction[item.country_restriction.name].push(item.reference);
          } else {
            country_restriction[item.country_restriction.name] = [];
            country_restriction[item.country_restriction.name].push(item.reference);
          }
        }
      });
    });


    if (Object.keys(country_restriction).length) {
      setDataCountryRestriction(country_restriction);
      setOpenModalCountryRestriction(true);
    } else {
      setSubmitCountryRestriction(true);
    }

  };

  const removeRestrictionParts = () => {


    const newData = referencesConfirmed.map((items) => {

      const result_parts = items.parts.filter((item) => {
        if (item.country_restriction && dataCountryRestriction != undefined) {
          return !dataCountryRestriction[item.country_restriction.name].includes(String(item.reference));
        } else {
          return item;
        }
      });

      items.parts = result_parts;

      return items;
    });


    if (newData.length) {
      setReferencesConfirmed(newData);
      setLocalStorage(newData);
      setDataCountryRestriction({});

      if (submitFromChild) {
        dispatch(setRederTable(true));
      }
    }

  };


  const setLocalStorage = (data) => {
    if (localStorage.getItem('proformaFileInProgress')) {
      const proformaFile = JSON.parse(localStorage.getItem('proformaFileInProgress') as string);

      localStorage.setItem('proformaFileInProgress', JSON.stringify({ ...proformaFile, partsWithReplacements: data }));
    }
  };

  const checkOpenValidateModal = () => {
    const validParts = validateParts(referencesConfirmed).validateProforma;

    const hasReplacedReferences = referencesConfirmed.map((item) => item.parts.filter((part) => part.replace)).flat().length;

    if (validParts) {
      if (hasReplacedReferences) {
        setOpenConfirmModal(true);
      } else {
        onOrderSubmit();
      }
    } else {
      setOpenModalValidate(true);
    }
  };

  const filterParts = async () => {

    const newData = referencesConfirmed.map((items) => {
      const result_parts = items.parts.filter((item) => {
        if (item.ind_equivalence && item.ind_equivalence_select) {
          return (item.ind_equivalence.quantity! >= item.ind_equivalence.min_quantity && item.ind_equivalence.quantity! % item.ind_equivalence.uv! == 0)
            && ((item.ind_equivalence.max_quantity != null && (item.ind_equivalence.quantity! > item.ind_equivalence.max_quantity)) || item.ind_equivalence.max_quantity == null);


        } else {
          return (item.quantity! >= item.min_quantity && item.quantity! % item.uv! == 0)
            && ((item.max_quantity != null && (item.quantity! < item.max_quantity)) || item.max_quantity == null);
        }


      });

      const objCopy = { ...items };

      objCopy.parts = result_parts;

      return objCopy;
    });

    //delete supplier if it has no parts
    const newDataSupplier = newData.filter((item) => {
      return item.parts.length !== 0;
    });

    //delete supplier if franco is lower

    const dataValidate = newDataSupplier.map((item) => {

      let total_provider = 0;
      const validate_ind_franco = {};

      item.parts.map((i) => {

        if (i.ind_equivalence && i.ind_equivalence_select) {
          if (!Object.keys(validate_ind_franco).some((key) => key === i.ind_equivalence.company)) {
            validate_ind_franco[i.ind_equivalence.company] = { total: i.ind_equivalence.total, franco: i.ind_equivalence.franco };
          } else {
            validate_ind_franco[i.ind_equivalence.company].total += i.ind_equivalence.total;
          }
        } else {
          total_provider = Number(total_provider) + Number(i.total);
        }
      });


      const result_parts = item.parts.filter((i) => {
        if (i.ind_equivalence && i.ind_equivalence_select) {
          return Object.keys(validate_ind_franco).includes(i.ind_equivalence.company) && (validate_ind_franco[i.ind_equivalence.company].franco <= validate_ind_franco[i.ind_equivalence.company].total);
        } else {
          return total_provider >= item.franco!;
        }
      });

      const objCopy = { ...item };

      objCopy.parts = result_parts;

      return objCopy;
    });

    //delete supplier if it has no parts
    const newDataValidate = dataValidate.filter((item) => {
      return item.parts.length !== 0;
    });

    const dataParts: IParts[] = [];

    newDataValidate.map((item) => {
      item.parts.map((part) => {
        dataParts.push(part);
      });
    });

    const PRICE_OK = dataParts.filter((item) => item.price !== null || item.price !== 0);
    let proformaBody;

    if (PRICE_OK.length) {
      proformaBody = PRICE_OK.map((item, index) => {
        if (item.ind_equivalence && item.ind_equivalence_select) {

          return {
            data: [index, '', (item.ind_equivalence?.reference_internal ? item.ind_equivalence?.reference_internal : ''), item.ind_equivalence.codfou, item.ind_equivalence.reference_full, '', item.ind_equivalence.quantity, item.ind_equivalence.price],
            info: { is_manufacturer: item.ind_equivalence.source === 'sup' ? 0 : 1, supplier_id: item.ind_equivalence.supplier_id, country_restriction: (item.ind_equivalence.country_restriction != null ? true : false) },
            section: 'PRICE_OK',
          };
        } else {
          return {
            data: [index, '', item.reference_internal, item.codfou, item.reference_full, '', item.quantity, item.price],
            info: { is_manufacturer: item.source === 'sup' ? 0 : 1, supplier_id: item.supplier_id, country_restriction: (item.country_restriction != null ? true : false) },
            section: 'PRICE_OK',
          };
        }

      });
    } else {
      proformaBody = [];
    }

    return proformaBody;
  };


  const partNotOkFromReferencesConfirmed = async (validateTotalOrder: boolean) => {
    const partNotOK: { minimum_quantity_not_respect: IParts[]; franco_not_respect: IParts[]; minimum_total_order: IParts[], maximum_quantity_not_respect: IParts[]; item_unavailable: IParts[]; } = { franco_not_respect: [], minimum_quantity_not_respect: [], minimum_total_order: [], maximum_quantity_not_respect: [], item_unavailable: [] };

    referencesConfirmed.map((items) => {

      let total_provider = 0;
      const validate_ind_franco = {};

      items.parts.map((i) => {

        if (i.ind_equivalence && i.ind_equivalence_select) {
          if (!Object.keys(validate_ind_franco).some((key) => key === i.ind_equivalence.company)) {
            validate_ind_franco[i.ind_equivalence.company] = { total: i.ind_equivalence.total, franco: i.ind_equivalence.franco };
          } else {
            validate_ind_franco[i.ind_equivalence.company].total += i.ind_equivalence.total;
          }
        } else {
          total_provider = Number(total_provider) + Number(i.total);
        }
      });


      items.parts.map((item) => {

        if (validateTotalOrder) {
          if (item.ind_equivalence && item.ind_equivalence_select) {
            if (item.ind_equivalence.quantity! < item.ind_equivalence.min_quantity && item.ind_equivalence.quantity! % item.ind_equivalence.uv! == 0) {
              partNotOK['minimum_quantity_not_respect'].push(item);
            }

            if (item.ind_equivalence.max_quantity && (item.ind_equivalence.quantity! > item.ind_equivalence.max_quantity)) {

              if (item.ind_equivalence.max_quantity <= 0) {
                partNotOK['item_unavailable'].push(item);
              } else {
                partNotOK['maximum_quantity_not_respect'].push(item);
              }

            }

            if (Object.keys(validate_ind_franco).includes(item.ind_equivalence.company) && (validate_ind_franco[item.ind_equivalence.company].franco > validate_ind_franco[item.ind_equivalence.company].total)) {
              partNotOK['franco_not_respect'].push(item);
            }
          } else {
            if (item.quantity! < item.min_quantity && item.quantity! % item.uv! == 0) {
              partNotOK['minimum_quantity_not_respect'].push(item);
            }

            if (item.max_quantity != null && (item.quantity! > item.max_quantity)) {

              if (item.max_quantity <= 0) {
                partNotOK['item_unavailable'].push(item);
              } else {
                partNotOK['maximum_quantity_not_respect'].push(item);
              }
            }

            if (total_provider < item.franco!) {
              partNotOK['franco_not_respect'].push(item);
            }
          }


        } else {
          partNotOK['minimum_total_order'].push(item);
        }

      });

    });


    if (referencesNotFound.length) {

      const partNotOKnew: Array<IFileRow> = [];

      Object.keys(partNotOK).map((sectionKey) => {
        partNotOK[sectionKey].map((item, k) => {

          if (item.ind_equivalence && item.ind_equivalence_select) {
            partNotOKnew.push({
              data: [referencesNotFound.length + k, '', (item.ind_equivalence?.reference_internal ? item.ind_equivalence?.reference_internal : ''), item.ind_equivalence.codfou, item.ind_equivalence.reference_full, '', item.ind_equivalence.quantity, item.ind_equivalence.price],
              info: {
                codfou: item.ind_equivalence.codfou,
                current_price: item.ind_equivalence.price,
                description: item.ind_equivalence.description,
                is_manufacturer: Number(0),
                price_from_file: Number(item.price_from_file),
                reference: item.ind_equivalence.reference,
                supplier_id: item.ind_equivalence.supplier_id,
                supplier_name: item.ind_equivalence.supplier_cod_fou,
              },
              section: sectionKey.toUpperCase(),
              isRemovedByUser: false
            });
          } else {
            partNotOKnew.push({
              data: [referencesNotFound.length + k, '', item.reference_internal, item.codfou, item.reference_full, '', item.quantity, item.price],
              info: {
                codfou: item.codfou,
                current_price: item.current_price,
                description: item.description,
                is_manufacturer: Number(item.is_manufacturer),
                price_from_file: Number(item.price_from_file),
                reference: item.reference,
                supplier_id: item.supplier_id,
                supplier_name: item.supplier_name,
              },
              section: sectionKey.toUpperCase(),
              isRemovedByUser: false
            });
          }


        });
      });

      return [...referencesNotFound, ...partNotOKnew];

    } else {
      return referencesNotFound;
    }

  };

  const onOrderSubmit = async () => {

    if (isLoadingSendProforma) {
      return;
    }


    const proformaFile = JSON.parse(localStorage.getItem('proformaFileInProgress') as string);
    const validParts = validateParts(referencesConfirmed);


    if (validParts.validateTotalOrder) {

      const proformaBody = await filterParts();

      proformaFile.rows = proformaBody;
      proformaFile.estimation = await partNotOkFromReferencesConfirmed(validParts.validateTotalOrder);

    } else {

      proformaFile.rows = [];
      proformaFile.estimation = await partNotOkFromReferencesConfirmed(validParts.validateTotalOrder);

    }

    setIsLoadingSendProforma(true);
    dispatch(setLoadingButtonSubmitProforma(true));

    if (user?.client_settings.show_proforma_delivery_date) {
      const start_date = moment(startDate).format('YYYY-MM-DD');

      if (moment(start_date).isValid()) {
        proformaFile.delivery_date = moment(startDate).format('YYYY-MM-DD');
      }
    }

    http.proforma
      .sendproformaAndEstimation(proformaFile)
      .then((res) => {
        notify(t('_order_submitted_successfully'), 'success');
        setResData(res.data);
        setOpenModal(true);
        localStorage.removeItem('proformaFileInProgress');
      })
      .catch(() => {
        notify(t('_something_went_wrong'));
      })
      .finally(() => {
        setIsLoadingSendProforma(false);
        dispatch(setLoadingButtonSubmitProforma(false));
      });
  };


  const redirect = () => {

    if (referencesNotFound.length) {
      setShowSectionDetails(false);
      setReferencesConfirmed([]);
      setLocalStorage([]);
    } else {
      localStorage.removeItem('proformaFileInProgress');
      navigator('/automotor-online');
    }
  };


  useEffect(() => {
    if (orderSubmitButton) {
      if (!submitCountryRestriction) {
        checkRestrictionParts(referencesConfirmed);
        setSubmitCountryRestriction(true);
        setOrderSubmitButton(false);
      } else {
        checkOpenValidateModal();
        setOrderSubmitButton(false);
      }
    }

  }, [orderSubmitButton]);

  const onClickExport = async () => {
    setExportLoad(true);

    const proformaFile = JSON.parse(localStorage.getItem('proformaFileInProgress') as string);
    const validParts = validateParts(referencesConfirmed);

    const references = await partNotOkFromReferencesConfirmed(validParts.validateTotalOrder);

    const notFound = references.reduce((rows: Array<IRowTable>, row) => {

      console.log(row);


      if (row.section != 'PRICE_OK') {
        const qte = +(row.data[6] as number);

        if (row.isRemovedByUser) {
          return rows;
        }

        const data = {
          reference_amf: row.data[4],
          price: row.data[7] ? Number(row.data[7]) : '',
          quantity: qte,
          rejected_reason: (qte < 0 || qte > 100000) ? 'OUT_OF_QUANTITY' : row.section

        };

        rows.push(data);


      }

      return rows;

    }, []);


    const data: { rows: Array<IRowTable>; type: string; }[] = [{
      rows: notFound,
      type: proformaFile.original_file.split('.').pop()
    }];


    http.proforma.exportPartNotFond(data).then(res => {
      const element = document.createElement('a');

      const file = new Blob(
        [res.data],
        { type: res.type },
      );

      element.href = URL.createObjectURL(file);
      element.download = `${proformaFile.filename}.${proformaFile.original_file.split('.').pop()}`;
      element.click();

    }).finally(() => {
      setExportLoad(false);
    });

  };

  const viewDetailedSection = (section: ISectionType) => {
    setShowSectionDetails(true);


    switch (section) {
      case SectionConfirmed:

        setShowSectionDetailsTypeElement(
          <TableSectionDetails
            register={register}
            onClose={() => {
              setShowSectionDetails(false);
            }}
            partsWithReplacements={referencesConfirmed}
            setReferencesConfirmed={setReferencesConfirmed}
            // checkboxValues={[VALIDATE_PRICES, SEND_IN_QUOTATION]}
            checkboxValues={[]}
            type={SECTION_CONFIRMED}
            icon={validateParts(referencesConfirmed).validateProforma ? faCheck : faExclamationCircle}
            sectionTitle={t('_section_confirmed')}
            typeColor={validateParts(referencesConfirmed).validateProforma ? SECTION_CONFIRMED : SECTION_NOT_VALIDATE}
            setOrderSubmitButton={setOrderSubmitButton}
            showOrderSubmitButton
            setLocalStorage={setLocalStorage}
            setSubmitCountryRestriction={setSubmitCountryRestriction}
            setSubmitFromChild={setSubmitFromChild}
            redirect={redirect}
          />,
        );

        break;
      case SectionNOTConfirmed:
        setShowSectionDetailsTypeElement(
          <TableSectionDetails
            register={register}
            onClose={() => {
              setShowSectionDetails(false);
            }}
            references={referencesNotConfirmed}
            icon={faExclamation}
            sectionTitle={t('_section_not_confirmed')}
            setReferences={setReferencesNotConfirmed}
            checkboxValues={[CONFORM_TARIFFS, SEND_IN_QUOTATION]}
            type={SECTION_NOT_CONFIRMED}
            redirect={redirect}
          />,
        );

        break;

      case SectionNOTFound:
        setShowSectionDetailsTypeElement(
          <TableSectionDetails
            register={register}
            onClose={() => {
              setShowSectionDetails(false);
            }}
            references={referencesNotFound}
            setReferences={setReferencesNotFound}
            // checkboxValues={[SEND_IN_QUOTATION]}
            checkboxValues={[]}
            type={SECTION_NOT_FOUND}
            icon={faEllipsis}
            sectionTitle={t('_section_not_found')}
            setOrderSubmitButton={setOrderSubmitButton}
            showOrderSubmitButton
            setLocalStorage={setLocalStorage}
            setSubmitCountryRestriction={setSubmitCountryRestriction}
            redirect={redirect}

          />,
        );
    }
  };

  useLocaleDatePicker(i18n.language);

  const getTotalPrice = (references: IFileRow[]) => {
    return references.reduce((total, currentReference) => {
      return total + (currentReference.data[7] as number) * (currentReference.data[6] as number);
    }, 0);
  };

  const getTotalPriceConfirmeted = (references: IImportFileNew[]) => {
    let total_price = 0;

    references.map((item) => {
      item.parts.map((i) => {
        if (i.ind_equivalence && i.ind_equivalence_select) {
          if (
            Number(i.ind_equivalence.quantity) >= i.ind_equivalence.min_quantity &&
            Number(i.ind_equivalence.quantity) % i.ind_equivalence.uv == 0
          ) {
            total_price += Number(i.ind_equivalence.total);
          }
        } else {
          if (Number(i.quantity) >= i.min_quantity && Number(i.quantity) % i.uv == 0) {
            total_price += Number(i.total);
          }
        }
      });
    });

    return total_price;
  };

  function onDateChange(date) {
    setStartDate(date);

    setValueDate(`${moment(date).format('DD/MM/YYYY')}`);
  }

  const validateParts = (references: IImportFileNew[]) => {

    let validateProforma = true;
    let validateTotalOrder = true;
    const errornotValid: Array<string> = [];

    const validate_ind_franco = {};


    references.map((items) => {
      let total_provider_franco = 0;
      let total_provider = 0;


      items.parts.map((i) => {
        if (i.ind_equivalence && i.ind_equivalence_select) {
          if (!Object.keys(validate_ind_franco).some((key) => key === i.ind_equivalence?.company)) {
            validate_ind_franco[i.ind_equivalence.company] = { total: i.ind_equivalence.total, franco: i.ind_equivalence.franco };
          } else {
            validate_ind_franco[i.ind_equivalence.company].total += i.ind_equivalence.total;
          }
        } else {
          if (Number(i.quantity) >= i.min_quantity && Number(i.quantity) % i.uv == 0) {
            total_provider += 1;
            total_provider_franco += Number(i.total);
          }
        }
      });

      if (total_provider_franco < Number(items.franco) && total_provider != 0 && items.name != 'NOT_SUPPLIED') {
        validateProforma = false;
        errornotValid.push(t('_min_franco'));
      }

      items.parts.map((item) => {


        if (item.ind_equivalence && item.ind_equivalence_select) {
          if (Number(item.ind_equivalence.quantity) < item.ind_equivalence.min_quantity && items.name != 'NOT_SUPPLIED') {
            validateProforma = false;
            errornotValid.push(t('_min_quantity'));
          }

          if (item.max_quantity != null && (Number(item.ind_equivalence.quantity) > Number(item.ind_equivalence.max_quantity)) && items.name != 'NOT_SUPPLIED') {
            validateProforma = false;
            errornotValid.push((item.ind_equivalence.max_quantity <= 0 ? t('_validate_max_quantity_negative') : t('_validate_max_quantity')));

          }

          if (Number(item.ind_equivalence.quantity) % item.ind_equivalence.uv != 0 && items.name != 'NOT_SUPPLIED') {
            validateProforma = false;
            errornotValid.push(t('_validate_uv'));
          }
        } else {
          if (Number(item.quantity) < item.min_quantity && items.name != 'NOT_SUPPLIED') {

            validateProforma = false;
            errornotValid.push(t('_min_quantity'));
          }

          if (item.max_quantity != null && (Number(item.quantity) > Number(item.max_quantity)) && items.name != 'NOT_SUPPLIED') {

            validateProforma = false;
            errornotValid.push((item.max_quantity <= 0 ? t('_validate_max_quantity_negative') : t('_validate_max_quantity')));
          }

          if (Number(item.quantity) % item.uv != 0 && items.name != 'NOT_SUPPLIED') {
            validateProforma = false;
            errornotValid.push(t('_validate_uv'));
          }
        }
      });

      Object.keys(validate_ind_franco).forEach((key) => {
        if (validate_ind_franco[key].total < validate_ind_franco[key].franco) {
          validateProforma = false;
          errornotValid.push(t('_min_franco') + key);
        }
      });
    });

    const total_price = getTotalPriceConfirmeted(referencesConfirmed);

    if (totalOrderValidate && total_price < Number(totalOrderValidate)) {
      errornotValid.push(t('_min_total_order') + ' ' + `${CURRENCY[user!.currency]}${Number(totalOrderValidate).toFixed(2)}`);
      validateProforma = false;
      validateTotalOrder = false;
    }


    return { validateProforma: validateProforma, errornotValid: errornotValid.filter((x, i, a) => a.indexOf(x) == i), validateTotalOrder: validateTotalOrder };
  };

  const tableData: Column<IParts>[] = [
    {
      Header: <Head text={t('_new_reference').toLocaleUpperCase()}></Head>,
      Cell: (newProps) => (
        <div>
          {newProps.cell.row.original.codfou}
          {newProps.cell.row.original.reference}
        </div>
      ),
      accessor: 'reference',
    },
    {
      Header: <Head text={t('_old_reference').toLocaleUpperCase()}></Head>,
      Cell: (newProps) => (
        <div>
          {newProps.cell.row.original.codfou}
          {newProps.cell.row.original.replace?.reference}
        </div>
      ),
      accessor: 'replace',
    },
  ];


  return (
    <div className="summary-order-container">
      <div className="title">{t('_summary_of_your_order')}</div>
      <div className="order-status">
        <div className="id">
          {t('_order')} <span>#XXXX</span>
        </div>
        <div className="status">
          {t('_status')} - <span>PROFORMA</span>
        </div>
      </div>
      {referencesConfirmed.length || referencesNotFound.length ? (
        !showSectionDetails ? (
          <form onSubmit={handleSubmit(onOrderSubmit)}>
            <div className="sections-container">
              {!!referencesConfirmed.length && (
                <PartsSection
                  register={register}
                  errors={errors}
                  errorsValidate={validateParts(referencesConfirmed).errornotValid}
                  // checkboxValues={[VALIDATE_PRICES, SEND_IN_QUOTATION]}
                  checkboxValues={[]}
                  type={validateParts(referencesConfirmed).validateProforma ? SECTION_CONFIRMED : SECTION_NOT_VALIDATE}
                  icon={validateParts(referencesConfirmed).validateProforma ? faCheck : faExclamationCircle}
                  sectionTitle={t('_section_confirmed')}
                  // sectionDescription={t('_description_section_confirmed', {
                  //   price: Number(getTotalPriceConfirmeted(referencesConfirmed)).toFixed(2),
                  //   reference_count: totalPaartsConfirmed,
                  //   currency: CURRENCY[user!.currency],
                  // })}
                  sectionDescription={t('_description_section_confirmed_table')}
                  totalConfirmedLabel={t('_total_confirmed_parts')}
                  totalConfirmedLabelPrice={getTotalPriceConfirmeted(referencesConfirmed)}
                  onClickViewDetails={() => viewDetailedSection(SECTION_CONFIRMED)}
                />
              )}
              {!!referencesNotConfirmed.length && (
                <PartsSection
                  register={register}
                  errors={errors}
                  checkboxValues={[CONFORM_TARIFFS, SEND_IN_QUOTATION]}
                  type={SECTION_NOT_CONFIRMED}
                  icon={faExclamation}
                  sectionTitle={t('_section_not_confirmed')}
                  sectionDescription={t('_description_section_not_confirmed', { reference_count: referencesNotConfirmed.length })}
                  totalConfirmedLabel={t('_total_non_confirmed_parts')}
                  totalConfirmedLabelPrice={getTotalPrice(referencesNotConfirmed)}
                  onClickViewDetails={() => viewDetailedSection(SECTION_NOT_CONFIRMED)}
                />
              )}
              {!!referencesNotFound.length && (
                <PartsSection
                  register={register}
                  errors={errors}
                  // checkboxValues={[SEND_IN_QUOTATION]}
                  checkboxValues={[]}
                  type={SECTION_NOT_FOUND}
                  icon={faEllipsis}
                  sectionTitle={t('_section_not_found')}
                  sectionDescription={t('_description_section_not_found', { seller: sellersData && sellersData[0].name })}
                  onClickViewDetails={() => viewDetailedSection(SECTION_NOT_FOUND)}
                  export
                  onClickExport={onClickExport}
                  exportLoad={exportLoad}
                />
              )}
            </div>
            <div className="submit-container">
              {user?.client_settings.show_proforma_delivery_date ? (
                <div className="form-date-input-item">
                  <div className="label">{t('_delivery_date')}</div>
                  <div className={`form-react-picker-dropdown ${openDate ? 'disable' : ''}`} onClick={() => setOpenDate(true)}>
                    <TextInput readOnly placeholder={t('_select_date')} defaultValue={valueDate ? valueDate : ''} />
                  </div>
                  {openDate && (
                    <div className="form-date-picker" ref={dateRef}>
                      <DatePicker
                        {...register('date')}
                        onChange={onDateChange}
                        minDate={moment().toDate()}
                        inline
                        locale={i18n.language.split('-')[0]}
                      />
                    </div>
                  )}
                </div>
              ) : (
                ''
              )}
              <div className="submit-button">
                {/* <DefaultButton isloading={isLoadingSendProforma} onClick={ handleSubmit(onOrderSubmit); } text={t('_submit_my_order')} /> */}
                <DefaultButton
                  // disabled={!validateParts(referencesConfirmed).validateProforma}
                  isloading={isLoadingSendProforma}
                  type="button"
                  onClick={() => {
                    setOrderSubmitButton(true);
                  }}
                  text={t('_submit_my_order')}
                />
              </div>
            </div>
          </form>
        ) : (
          <div className="table-section">{showSectionDetailsTypeElement}</div>
        )
      ) : (
        <div className="file-invalid-error-message">{t('_please_check_the_file_information')}</div>
      )}

      <div className="send-email">
        <SendEmailToSeller />
      </div>
      <Modal
        openModal={openModal}
        setOpenModal={setOpenModal}
        onRequestClose={handleOnCloseModal}
        childrenComponent={<OrderModal data={resData} handleCloseModal={handleOnCloseModal} />}
      />

      <Modal
        openModal={openModalValidate}
        setOpenModal={setOpenModalValidate}
        shouldCloseOnOverlayClick={false}
        childrenComponent={
          <div className="modal-proforma-validate">
            <h1>
              <Trans i18nKey="_upload_proforma_validate" components={{ span: <span className="color-red-h1" /> }} />
            </h1>
            <div className="btn-validate">
              <DefaultButton
                onClick={() => {
                  setOpenModalValidate(false);
                }}
                red
                text={t('_cancel')}
              />
              <DefaultButton onClick={() => { onOrderSubmit(); setOpenModalValidate(false); }} text={t('_continue_order')} />
            </div>
          </div>
        }
      />
      <Modal
        openModal={openConfirmModal}
        setOpenModal={setOpenConfirmModal}
        shouldCloseOnOverlayClick={false}
        childrenComponent={
          <div className="modal-proforma-validate">
            <ReferenceTable columns={tableData} data={referencesConfirmed} loading={false} />
            <div className="btn-validate">
              <DefaultButton
                onClick={() => {
                  setOpenConfirmModal(false);
                  setIsLoadingSendProforma(false);
                  dispatch(setLoadingButtonSubmitProforma(false));
                }}
                red
                text={t('_cancel')}
              />
              <DefaultButton
                onClick={() => {
                  onOrderSubmit();
                  setOpenConfirmModal(false);

                }}
                text={t('_continue_order')}
              />
            </div>
          </div>
        }
      />


      <Modal
        openModal={openModalCountryRestriction}
        shouldCloseOnOverlayClick={false}
        setOpenModal={setOpenModalCountryRestriction}
        childrenComponent={
          <ModalRestriction
            dataCountryRestriction={dataCountryRestriction}
            setOpenModalCountryRestriction={setOpenModalCountryRestriction}
            removeRestrictionParts={removeRestrictionParts}
            setSubmitCountryRestriction={setSubmitCountryRestriction}

          />
        }
      />
    </div>
  );
};

OrderSummaryV3.displayName = 'OrderSummaryV3';

export default OrderSummaryV3;
