import React, { ReactElement, SVGProps, useEffect, useState } from 'react';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink, useNavigate } from 'react-router-dom';
import RELIABLE_LOGO from 'assets/images/reliable-logo.svg';
import FOOTER_LOGO from 'assets/images/e-reliable.png';
import './SidenavMenu.scss';
import { automotorMenuList } from 'applications/automotor/layouts/espace-automotor-layout/automotor-menu-navbar/menu-automotor-data';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';

type Props = {
  isActive: boolean;
  setIsActive: (e: boolean) => void;
};
const c = 'sidenav-espace';

const SidenavMenu: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user } = useSelector((state: RootState) => state.userData);
  const [activeTab, setActiveTab] = useState(0);

  function renderIcon(icon: React.ComponentType<SVGProps<SVGSVGElement>> | ReactElement) {
    if (React.isValidElement(icon)) {
      return icon;
    }

    const Icon = icon as React.ComponentType<SVGProps<SVGSVGElement>>;

    return <Icon className="automotor-nav-icon" width={20} height={20} />;
  }

  useEffect(() => {
    props.setIsActive(false);
  }, [window.location.pathname]);

  return (
    <div className={`${c} ${props.isActive ? 'show-sidenav' : 'hidden-sidenav'}`}>
      <div className="header-sidenav-espace">
        <div
          className="logo"
          onClick={() => {
            navigate('/'), props.setIsActive(false);
          }}
        >
          <img src={RELIABLE_LOGO} width="87" height="10" alt="espace-logo" />
        </div>
        <div className="close-btn">
          <FontAwesomeIcon
            icon={faXmark}
            onClick={() => {
              props.setIsActive(false);
            }}
            className="close-btn-icon"
          />
        </div>
      </div>
      <div className={`${c}__sidenav`}>
        <div className={`${c}__tabs`}>
          {automotorMenuList.map((item, index) => {
            if (item.role.length && !item.role.some((role) => user?.roles.includes(role))) {
              return null;
            }

            return (
              <div
                key={index}
                className={`${c}__tab ${activeTab === index ? 'active' : ''}`}
                onClick={() => setActiveTab(index)}
              >
                {renderIcon(item.image)}
                <span>{t(item.name)}</span>
              </div>
            );
          })}
        </div>

        <div className={`${c}__content`}>
          {automotorMenuList[activeTab]?.children.map((child, childIndex) => {
            if (child?.separator) {
              return <div key={childIndex} className={`${c}__separator`} />;
            }

            if (child.role.length && !child.role.some((role) => user?.roles.includes(role))) {
              return null;
            }

            if (child.custom) {
              return (
                <div key={childIndex} className={`${c}__content-item`}>
                  {child.custom}
                </div>
              );
            }

            return (
              <div key={childIndex} className={`${c}__content-item`}>
                <NavLink to={child.url || ''} onClick={child.callback}>
                  {renderIcon(child.image)}
                  <span>{t(child.name)}</span>
                </NavLink>
              </div>
            );
          })}
        </div>
      </div>

      <div className="footer">
        <img src={FOOTER_LOGO} width="121" alt="e-reliable" />
      </div>
    </div>
  );
};

export default SidenavMenu;
