import { Marketplaces } from './constants/applications';
import { IUrlParams } from '../interfaces/marketplace';
import { IUser } from '../models/IUser';
import { ROLE_CLIENT_AUTOMOTOR } from './constants/roles';
import ToastifyNotification from '../components/notification/ToastifyNotification/ToastifyNotification';

const BASE_URLS = {
  automotor: process.env.REACT_APP_API_AUTOMOTOR,
  retailer: process.env.REACT_APP_API_RETAILER,
  public_tr: process.env.REACT_APP_API_PUBLIC_TR,
  public_ma: process.env.REACT_APP_API_PUBLIC_MA,
  public_tn: process.env.REACT_APP_API_PUBLIC_TN,
  public_dz: process.env.REACT_APP_API_PUBLIC_DZ,
  mra: process.env.REACT_APP_API_MRA,
  fournisseur: process.env.REACT_APP_API_FOURNISSEUR,
  distributeur: process.env.REACT_APP_API_DISTRIBUTEUR,
  amerigo: process.env.REACT_APP_API_AMERIGO,
  af: process.env.REACT_APP_API_AF,
  punchout: process.env.REACT_APP_API_PUNCHOUT,
};

export const getMarketplaceBaseUrl = (): string | undefined => {
  const hostname = window.location.hostname;

  let currentDomain = `${location.origin}/api`;

  if (hostname.includes('localhost')) {
    Object.keys(BASE_URLS).forEach((key) => {
      if (hostname.includes(key)) {
        currentDomain = BASE_URLS[key];
      }
    });
  }

  return currentDomain;
};

export const getCurrentLanguage = (): string => {
  return `${localStorage.getItem('locale') ? localStorage.getItem('locale')?.replace('-', '_') : 'fr_FR'}`;
};

export const getApi = (): string | undefined => {
  return `${getMarketplaceBaseUrl()}/${getCurrentLanguage()}/api`;
};

export const getPresentationApi = (): string | undefined => {
  return `${getMarketplaceBaseUrl()}/${getCurrentLanguage()}`;
};

export function getLandingApi(): string {
  return `${process.env.REACT_APP_LANDING_API}/${getCurrentLanguage()}/api/landing`;
}

export const getAuthApi = (): string | undefined => {
  return `${getMarketplaceBaseUrl()}/api`;
};

export const getCurrentMarketplace = (): string => {
  const hostname = window.location.hostname;

  if (hostname == String(process.env.REACT_APP_MARKETPLACE_MECADEPOT)) {
    return Marketplaces.RETAILER;
  }

  return Object.values(Marketplaces).find((marketplace) => hostname.includes(String(marketplace))) || Marketplaces.AUTOMOTOR;
};

export const notify = (message?: string, type?: 'info' | 'warning' | 'error' | 'success' | 'default', title?: string): void => {
  ToastifyNotification({ message, type, title });
};

export const getURLParams = (location: string): {} => {
  const data = {};
  const params = new URLSearchParams(location);

  params.forEach((paramValue, paramName) => {
    data[paramName] = paramValue;
  });

  return data;
};

export const converObjectToParams = (object: IUrlParams): string => {
  const str = [];

  for (const p in object) {
    // eslint-disable-next-line no-prototype-builtins
    if (object.hasOwnProperty(p)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      str.push(encodeURIComponent(p) + '=' + encodeURIComponent(object[p]));
    }
  }

  return str.join('&');
};

export const getBackendAssetsBasePath = (): string | undefined => {
  return `${getMarketplaceBaseUrl()}`;
};

export const isRole = (role: string): boolean => {
  const userInfo: IUser = JSON.parse(localStorage.getItem('userData')!);
  const roles = userInfo?.roles || [];

  return roles.includes(role);
};

export const isM3Client = (): boolean => {
  const userInfo: IUser = JSON.parse(localStorage.getItem('userData')!);
  const hasErpConnection = userInfo?.client_settings?.m3_erp_connection ?? false;

  return hasErpConnection && isRole(ROLE_CLIENT_AUTOMOTOR);
};

export const isHostPunchOut = (): boolean => {
  const punchOutSubdomains = process.env.REACT_APP_SUBDOMAIN_PUNCHOUT;

  const subdomains: string[] | [] = punchOutSubdomains?.length ? punchOutSubdomains.split('|') : [];

  return subdomains.some((subdomain: string): boolean => window.location.host.includes(subdomain));
};
