import React, { PropsWithChildren, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import PaginationView from './Pagination.view';

type Props = {
  handlePageClick: (page: number) => void;
  pageCount: number;
  forcePage: number;
  hiddenParamPage?: boolean;  
};

const Pagination: React.FC<Props> = (props: PropsWithChildren<Props>) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const handlePageClick = (selectedItem: { selected: number }) => {
    const pageselected = selectedItem.selected + 1;

    if(props.hiddenParamPage === undefined) {
      searchParams.set('page', pageselected.toString());
      setSearchParams(searchParams);
    }

    props.handlePageClick(selectedItem.selected + 1);
  };

  // useEffect(() => {
  //   setSearchParams({page: String(props.forcePage)});
  // }, [props.forcePage]);

  return <PaginationView pageCount={props.pageCount} handlePageClick={handlePageClick} forcePage={props.forcePage - 1} />;
};

Pagination.displayName = 'Pagination';

export default Pagination;