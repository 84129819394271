import { IStats } from 'applications/automotor/models/statistics';
import { IClientOption } from 'interfaces/select';
import { IHttpResponse, serverAxios } from '../../http';

class StatisticsHttp {
  private static statistics = 'retailer/statistics';
  private static modules = 'retailer/statistics/modules';
  private static clients = 'retailer/statistics/clients';
  private static chart = 'retailer/statistics/date';
  private static export = 'retailer/statistics/export';
  private static export_file = 'retailer/statistics/export_module';

  public async getStatistics(params: {}): Promise<IHttpResponse<IStats[]>> {
    return serverAxios.get(StatisticsHttp.statistics, {params});
  }
  public async exportStatistics(params: {}): Promise<IHttpResponse<Blob>> {
    return serverAxios.get(StatisticsHttp.export, {params, responseType: 'blob'});
  }
  public async exportFile(params: {}): Promise<IHttpResponse<Blob>> {
    return serverAxios.get(StatisticsHttp.export_file, {params, responseType: 'blob'});
  }
  public async getStatisticsByDate(params: {}): Promise<IHttpResponse<IStats[]>> {
    return serverAxios.get(StatisticsHttp.chart, {params});
  }
  public async getModules(): Promise<IHttpResponse<IClientOption[]>> {
    return serverAxios.get(StatisticsHttp.modules);
  }
  public async getClients(): Promise<IHttpResponse<IClientOption[]>> {
    return serverAxios.get(StatisticsHttp.clients);
  }

  public async saveStatistics(params: {}): Promise<IHttpResponse<boolean | null>> {
    return serverAxios.post(StatisticsHttp.statistics, params);
  }
}

export default StatisticsHttp;
