import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faForward, faScrewdriverWrench } from '@fortawesome/free-solid-svg-icons';
import React, { useRef, useState } from 'react';
import './TechnicalHelp.scss';
import { useOnClickOutside } from '../../../utils/hooks/useOnClickOutside';
import RoundedIcon from '../../buttons/RoundedIcon/RoundedIcon';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';

type Props = {
  setOpenModal: (e: boolean) => void;
  setLink: (e: string) => void;
};

const TechnicalHelp: React.FC<Props> = (props) => {
  const [show, setShow] = useState<boolean>(false);
  const dropdownRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const { t } = useTranslation();
  const { user } = useSelector((state: RootState) => state.userData);

  const vehicleSelected = localStorage.getItem('carSelected') ? JSON.parse(localStorage.getItem('carSelected') as string) : null;

  useOnClickOutside(dropdownRef, () => setShow(false));

  const technicalDocumentationIsDisabled = () => user?.client_rmi_modules_type === 'none';

  const methodStepByStepIsDisabled = () => user?.client_rmi_modules_type === 'none' && user?.client_settings.eina_subscription === false;

  const technicalDocumentationOnClick = () => {
    if (technicalDocumentationIsDisabled()) {
      return;
    }

    props.setOpenModal(true);
    props.setLink('/technical-documentation');
  };

  const methodStepByStepOnClick = () => {
    if (methodStepByStepIsDisabled()) {
      return;
    }

    props.setOpenModal(true);
    props.setLink('/error-codes');
  };

  return (
    <div ref={dropdownRef} className="account-dropdown" onClick={() => setShow(!show)}>
      <FontAwesomeIcon className="tab-icon" icon={faScrewdriverWrench} width="15" height="15" />
      <div className="text">{t('_technical_help')}</div>
      <div className={show ? 'show' : 'hide'}>
        <div className="content-data">
          {vehicleSelected != null ? (
            <React.Fragment>
              <NavLink
                to={
                  technicalDocumentationIsDisabled()
                    ? ''
                    : `reliable/rmi/${vehicleSelected.car.id.toString()}/technical-documentation?${new URLSearchParams({
                      source: vehicleSelected.car.source,
                      vehicle_identifier: vehicleSelected.car.vehicle_identifier,
                      ...(vehicleSelected.car.vin && { vin: vehicleSelected.car.vin }),
                    }).toString()}`
                }
                className={`item ${technicalDocumentationIsDisabled() ? 'disabled' : ''}`}
              >
                <RoundedIcon icon={faFile} text={t('_technical_documentation')} />
              </NavLink>
              <NavLink
                to={
                  methodStepByStepIsDisabled()
                    ? ''
                    : `reliable/rmi/${vehicleSelected.car.id.toString()}/error-codes?${new URLSearchParams({
                      source: vehicleSelected.car.source,
                      vehicle_identifier: vehicleSelected.car.vehicle_identifier,
                      ...(vehicleSelected.car.vin && { vin: vehicleSelected.car.vin }),
                    }).toString()}`
                }
                className={`item ${methodStepByStepIsDisabled() ? 'disabled' : ''}`}
              >
                <RoundedIcon icon={faForward} text={t('_step_by_step_method')} />
              </NavLink>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <NavLink
                to="#"
                className={`item ${technicalDocumentationIsDisabled() ? 'disabled' : ''}`}
                onClick={() => {
                  technicalDocumentationOnClick();
                }}
              >
                <RoundedIcon icon={faFile} text={t('_technical_documentation')} />
              </NavLink>
              <NavLink
                to="#"
                className={`item ${methodStepByStepIsDisabled() ? 'disabled' : ''}`}
                onClick={() => {
                  methodStepByStepOnClick();
                }}
              >
                <RoundedIcon icon={faForward} text={t('_step_by_step_method')} />
              </NavLink>
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
};


export default TechnicalHelp;
