import { ITariff } from 'models/catalog.model';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useHttp from 'utils/hooks/useHttp';
import TariffFileView from './TariffFile.view';
import { notify } from '../../utils/marketplace';

type Props = {
  file: ITariff;
};

const TariffFile: React.FC<Props> = (props) => {
  const http = useHttp();
  const { t } = useTranslation();

  const [downloadIsLoading, setDownloadIsLoading] = useState(false);
  const [downloadFromPreview, setDownloadFromPreview] = useState(false);

  const handleDownloadClick = (id: number, name: string) => {
    setDownloadIsLoading(true);
    http.catalogs
      .downloadTariff(id)
      .then((data) => {
        const element = document.createElement('a');

        const file = new Blob([data.data], { type: data.data.type });

        element.href = URL.createObjectURL(file);
        element.download = name;
        element.click();
      })
      .catch(() => {
        notify(t('_tariff_file_could_not_be_downloaded'));
      })
      .finally(() => {
        setDownloadIsLoading(false);
      });
  };

  const handleDownloadPreviewClick = (id: number, name: string) => {
    setDownloadFromPreview(true);
    http.catalogs
      .downloadTariff(id)
      .then((data) => {
        const element = document.createElement('a');

        const file = new Blob([data.data], { type: data.type });

        element.href = URL.createObjectURL(file);
        element.download = name;
        element.click();
      })
      .catch(() => {
        notify(t('_tariff_file_could_not_be_downloaded'));
      })
      .finally(() => {
        setDownloadFromPreview(false);
      });
  };

  return (
    <TariffFileView
      {...props}
      handleDownloadClick={handleDownloadClick}
      handleDownloadPreviewClick={handleDownloadPreviewClick}
      downloadIsLoading={downloadIsLoading}
      downloadFromPreview={downloadFromPreview}
    />
  );
};

TariffFile.displayName = 'TariffFile';

export default TariffFile;
