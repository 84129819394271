import React from 'react';
import { Navigate } from 'react-router-dom';

type Props = {
  roles?: Array<String>;
  redirectPath?: string;
  isAllowed?: boolean
};

const ProtectedRoute: React.FC<React.PropsWithChildren<Props>> = (props: React.PropsWithChildren<Props>) => {
  const userData = JSON.parse(localStorage.getItem('userData')!);

  if (!props.isAllowed) {
    <Navigate to={props.redirectPath ? props.redirectPath : '/404'} replace />;
  }

  const checkRole = () => {
    if (!props.roles || (userData?.roles && props.roles?.some((role: String) => userData.roles.includes(role)))) {
      return props.children;
    } else {
      return <Navigate to={props.redirectPath ?? '/404'} replace />;
    }
  };

  return <React.Fragment>{checkRole()}</React.Fragment>;
};

ProtectedRoute.displayName = 'ProtectedRoute';

export default ProtectedRoute;
