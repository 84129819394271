import React, { useEffect, useState } from 'react';
import { ReactComponent as PartsNotFoundImage } from 'assets/images/parts-not-found.svg';
import Pagination from 'components/pagination/Pagination';
import Spinner from 'components/spinner/Spinner';
import { useTranslation } from 'react-i18next';
import useHttp from 'utils/hooks/useHttp';
import { useSearchParams } from 'react-router-dom';
import { IBodyResponseOil, IOilSearch } from 'interfaces/oil';
import { IOilCatalogFilter } from 'utils/hooks/http/oils-catalog.http';
import CatalogOilFilter from 'pages/CatalogVehicle/Catalogs/CatalogsOilsPage/CatalogOilFilter/CatalogOilFilter';
import CatalogOilItem from 'pages/CatalogVehicle/Catalogs/CatalogsOilsPage/CatalogOilItem/CatalogOilItem';
import './Oils.scss';
import TextInput from 'components/inputs/TextInput/TextInput';
import OilDropdownContent from 'components/OilDropdownContent/OilDropdownContent';
import { useForm } from 'react-hook-form';
import OilNavbar from 'components/navbar/oil-navbar/OilNavbar';
import ProductsViewMode from '../../../../components/ProductsViewMode/ProductsViewMode';
import { DEFAULT_VIEW } from '../../../../components/ProductsViewMode/ProductsViewMode.constants';
import ProductsViewModeChanger from 'components/ProductsViewModeChanger/ProductsViewModeChanger';
import {
  CATALOG_CONSUMABLE,
  CATALOG_OIL
} from '../../../../components/ProductsViewModeChanger/products-view-mode-changer-utils';
import axios from 'axios';

const Oils: React.FC = () => {
  const [searchParams] = useSearchParams();
  const { register, getValues, setValue } = useForm();
  const { t, i18n } = useTranslation();
  const http = useHttp();

  const [loading, setLoading] = useState(true);
  const [refData, setRefData] = useState<IBodyResponseOil>();
  const [filterData, setFilterData] = useState<IOilCatalogFilter>();
  const [openFilter, setOpenFilter] = useState(false);

  const [openOilDropdown, setOpenOilDropdown] = useState(false);
  const [oilData, setOilData] = useState<IOilSearch[]>([]);
  const [loadingOil, setLoadingOil] = useState(false);

  const [page, setPage] = useState(Number(searchParams.get('page')));

  const user = JSON.parse(localStorage.getItem('userData')!);
  const [viewMode, setViewMode] = useState(user?.user_settings.products_view_mode || DEFAULT_VIEW);
  const cancelToken = axios.CancelToken.source();


  function getOilData(data) {
    setLoading(true);
    const params = {
      page,
      ...data,
    };

    const validParams = validateParams(params);

    http.oilCatalogHttp
      .getOils(validParams, cancelToken.token)
      .then((res) => {
        setRefData(res.data);
      })
      .finally(() => setLoading(false));
  }

  function getOilFilter(data) {

    const validFilterParams = validateParams(data);

    http.oilCatalogHttp.getOilFilters(validFilterParams).then((res) => {
      setFilterData(res.data);
    });
  }

  const validateParams = (data) => {
    return Object.entries(data).reduce((acc, [key, value]) => {
      if (!value) {
        return acc;
      }

      acc[key] = value;

      return acc;
    }, {});
  };


  const handlePageClick = (pageN: number) => {
    setPage(pageN);
  };

  function oilSearch() {
    setLoadingOil(true);
    http.oilHttp.oilSearch({ q: getValues()?.oil }).then((res) => {
      setOilData(res.data);
    }).finally(() => setLoadingOil(false));
  }

  useEffect(() => {
    const params = {
      q: searchParams.get('q'),
      category_ids: searchParams.getAll('category_ids'),
      oil_ids: searchParams.getAll('oil_id'),
      brand_id: searchParams.getAll('brand_id'),
      viscosity: searchParams.getAll('viscosity'),
      quantity: searchParams.getAll('quantity'),
      family: searchParams.getAll('family')
    };

    getOilData(params);
    getOilFilter(params);
    setValue('oil', searchParams.get('q'));
  }, [i18n.language, page, searchParams.get('category_ids'), searchParams.get('brand_id'), searchParams.get('viscosity'), searchParams.get('quantity'), searchParams.get('family')]);

  function handleSearch() {
    setOpenOilDropdown(true);
    oilSearch();
  }

  function filterOptionsElement() {
    return (
      <div className="catalog-parts-filter">
        <button onClick={() => setOpenFilter(true)} className={`catalog-parts-button ${openFilter ? 'active' : ''}`}>
          {t('_filter_results_btn')}
        </button>
      </div>
    );
  }

  function filterInputElement() {
    return (
      <div className="catalog-parts-filter">
        <div className="oil-search-input-container">
          <TextInput
            placeholder="Ex: 1003001F"
            {...register('oil')}
            onClick={handleSearch}
            withIcon
          />
          {openOilDropdown && <OilDropdownContent loading={loadingOil} setIsOpen={setOpenOilDropdown} data={oilData} oilValue={getValues()?.oil} />}
        </div>
      </div>
    );
  }

  return (
    <div className="oils-wrapper">
      <OilNavbar option={filterOptionsElement()} oilInput={filterInputElement()} title="_oil_catalog" />
      <div className="separator"></div>
      {
        !loading && (
          <div className="filterOptions">
            <div className="results">
              {refData?.total_count} {t('_results')}
            </div>
            <ProductsViewMode viewMode={viewMode} setViewMode={setViewMode} />

          </div>
        )
      }

      <div className="wrapperItems">
        {loading ? (
          <Spinner class="car-parts-spinner" />
        ) : refData?.data?.length ? (
          <ProductsViewModeChanger catalog={CATALOG_OIL} viewMode={viewMode} data={refData?.data} />
        ) : (
          <div className="no-parts-data">
            <div className="notFoundSvg">
              <PartsNotFoundImage />
            </div>
            <h1>{t('_oils_not_found')}</h1>
          </div>
        )}
      </div>

      {refData && refData?.total_pages && refData?.page && refData?.total_pages > 1 ? (
        <Pagination pageCount={refData.total_pages} handlePageClick={handlePageClick} forcePage={refData.page} />
      ) : (
        ''
      )}
      <CatalogOilFilter getData={getOilData} filterData={filterData} openFilter={openFilter} setOpenFilter={setOpenFilter} />
    </div>
  );
};

export default Oils;
