import React, { useState } from 'react';

import { Trans, useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment } from '@fortawesome/free-solid-svg-icons';
import Modal from 'components/modal/Modal';
import { contentStyle } from './SendEmailToSellerModal/SendEmailToSellerModal';
import SendEmailModal from './SendEmailToSellerModal/SendEmailModal';
import { useSelector } from 'react-redux';
import { getAutomotorTariff } from 'store/selectors/automotor-tariff.selector';
import './SendEmailToSeller.scss';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';


type Props = {

};

const SendEmailToSeller: React.FC<Props> = () => {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const data = useSelector(getAutomotorTariff);
  const { isTablet } = useDeviceDetect();

  return (
    <div className="send-mail-container">
      <div onClick={() => setOpenModal(true)} className="send-email-to-seller">
        <div className="header">{t('_a_question')}</div>
        <div className="subject">
          <Trans
            i18nKey="_email_description_explication"
            values={{ NomDuCommercial: data && data[0]?.name }}
            components={{ bold: <strong /> }}
          />
        </div>
        <div className="icon">
          <FontAwesomeIcon icon={faComment} className="" />
        </div>
      </div>
      {data &&
        <Modal
          openModal={openModal}
          setOpenModal={setOpenModal}
          childrenComponent={<SendEmailModal users={data} setOpenModal={setOpenModal} />}
          contentStyle={isTablet ? { overlay: { ...contentStyle.overlay }, content: { ...contentStyle.content, maxWidth: '100%' } } : contentStyle}
        />}
    </div>
  );
};

SendEmailToSeller.displayName = 'SendEmailToSeller';

export default SendEmailToSeller;
