import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import './SearchCatalog.scss';
import { IUser } from 'models/IUser';
import MainSearchInput from 'components/inputs/MainSearchInput/MainSearchInput';
import { Trans, useTranslation } from 'react-i18next';
import GlobalSearchInput from '../../../../../components/inputs/GlobalSearchInput/GlobalSearchInput';

type Props = {
  handleLogout: () => void;
  user: IUser | null;
};

const SearchCatalogView: React.FC<Props> = () => {
  const { t } = useTranslation();

  return (
    <div className="header-search-espace-catalog-sidebar">
      <div className="title-sidebar">
        <Trans
          i18nKey="_universal_catalog_header"
          components={{ b: <b /> }}
        />
      </div>
      {/* <div className="subtitle">
        {' '}
        {t('_run_search_with_saved_filters')} <FontAwesomeIcon icon={faArrowRight} />
      </div> */}
      <div className="header-search-input">
        <GlobalSearchInput
          placeholder={t('_free_search')}
          size="l"
          withIcon
        />
      </div>
    </div>
  );
};

SearchCatalogView.displayName = 'SearchCatalogView';

export default SearchCatalogView;
