
import TextInput from 'components/inputs/TextInput/TextInput';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useHttp from 'utils/hooks/useHttp';
import './CategoryEdit.scss';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';

import Spinner from 'components/spinner/Spinner';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { notify } from 'utils/marketplace';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import UploadImage from 'components/UploadImage/UploadImage';
import { ICategory, ICategoryValidate } from 'interfaces/batteryCategory';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { languages } from '../../../../utils/constants/locales';


type Props = {
  setOpenModal: (e: boolean) => void;
  setSubmit: (e: boolean) => void;
  id: number | undefined
};


const CategoryEdit: React.FC<Props> = (props) => {

  let isMounted = true;
  const [loadingInfo, setLoadingInfo] = useState<boolean>(false);
  const [tabColor, setTabColor] = useState<string[]>([]);
  const { t } = useTranslation();
  const http = useHttp();
  const [file, setFile] = useState<File[]>([]);
  const [brandInfo, setBrandInfo] = useState<ICategory>();
  const [loading, setLoading] = useState<boolean>(false);
  const [errorName, setErrorName] = useState(false);
  const { isMobile, isTablet } = useDeviceDetect();

  const validate = Yup.object().shape({
  });


  const { clearErrors, setValue, getValues, register, handleSubmit, formState: { errors } } = useForm({ resolver: yupResolver(validate) });


  useEffect(() => {

    if (props.id && isMounted) {
      setLoadingInfo(true);
      http.batteryCategoryHttp.getCategoryInfo(props.id)
        .then((res) => {
          setBrandInfo(res.data);
          setValue('name', res.data?.name);
          const lang: string[] = [];

          res.data?.translations?.map((trans) => {
            if (trans.locale && trans.name && trans.name != '') {
              lang.push(trans.locale);
              setValue(`translations[${trans.locale}]`, trans.name);
            }
          });
          setTabColor([...tabColor, ...lang]);
        }).finally(() => {
          setLoadingInfo(false);
        });
    }

    return () => {
      isMounted = false;
    };

  }, []);

  const onError = () => {

    const values = getValues();

    Object.keys(values.name).forEach(key => {
      if (values.name[key] == undefined || values.name[key] == '') {
        delete values.name[key];
      }
    });

    if (Object.keys(values.name).length == 0) {
      setErrorName(true);


    } else {
      setErrorName(false);
    }
  };

  const onSubmit = async () => {

    if (loading) {
      return;
    }

    const values = getValues();

    setLoading(true);
    const formData: FormData = new FormData();

    if (values.image) {
      formData.append('image', values.image);
    }

    Object.keys(values.translations).forEach(key => {
      if (values.translations[key] == undefined) {
        delete values.translations[key];
      } else {
        formData.append(`translations[${key}][name]`, String(values.translations[key]));
      }


    });
    Object.keys(values.translations).forEach(key => {
      if (values.translations[key] == undefined || values.translations[key] == '') {
        delete values.translations[key];
      }
    });

    if (Object.keys(values.translations).length == 0) {
      setErrorName(true);

      return;
    }

    setLoading(true);

    if (file.length) {
      formData.append('image', file[0]);
    }

    http.batteryCategoryHttp.edit(Number(props?.id), formData).then(() => {
      notify(t('_battery_category_updated'), 'success', t('_success'));
      props.setOpenModal(false);
      props.setSubmit(true);
    }).catch((error) => {
      let erorrs = '';

      Object.entries(error).forEach(([key, value]) => {
        erorrs += `${t('_' + key)} : ${value} `;
      });
      notify(erorrs, 'error', t('_error'));
      setLoading(false);
    });

  };

  const changeName = (key: string, value: string) => {
    if (value != '') {
      if (!tabColor.includes(String(key))) {
        const lang: string[] = [key];

        setTabColor([...tabColor, ...lang]);
      }

      setErrorName(false);
    } else {
      if (tabColor.includes(String(key))) {
        tabColor.splice(tabColor.indexOf(key), 1);
        setTabColor([...tabColor]);
      }
    }
  };

  return (
    <div className="battery-edit-container-category" >
      <div className="close-modal" onClick={() => { props.setOpenModal(false); }}><FontAwesomeIcon icon={faClose} /></div>

      <div className="title">
        {t('_battery_title_edit')}
        <br />
        <span>{t('_battery_brand_title_edit')}</span>
      </div>


      {
        !brandInfo ?
          <div className="spinner-info"> <Spinner />  </div> :
          <React.Fragment >
            <div className="info-item">
              {t('_battery_item_count')}
              <br />
              <span>{brandInfo?.name}</span>
            </div>

            <form onSubmit={handleSubmit(onSubmit, onError)}>

              <div className="row">
                <div className={`container  file ${errors.image?.type == 'required' ? 'error' : ''}`}>
                  <UploadImage
                    errors={errors}
                    clearErrors={clearErrors}
                    setValue={setValue}
                    image={brandInfo?.image}
                  />
                </div>

              </div>

              <div className="row">
                <div className={`container ${errorName ? 'error' : ''}`}>
                  {
                    errorName ? <p className="error-message"> {t('_fild_required_title')} </p> : ''
                  }
                  <div className="tabs-container">
                    <Tabs>
                      <TabList >
                        {
                          languages.map((item, index) => {
                            let classActive = 'empty_text';

                            tabColor.map((trans) => {
                              if (trans == item.id) {
                                classActive = 'active_text';
                              }
                            });

                            return <Tab key={index} className={classActive} >{isMobile || isTablet ? String(item.id).toUpperCase() : item.text}</Tab>;
                          })
                        }
                      </TabList>
                      {languages.map((item, index) => {
                        return (<TabPanel key={index} >
                          <label  >
                            {t('_name')}
                          </label>
                          <textarea {...register(`translations[${item.id}]`)} onChange={(event) => { changeName(String(item.id), event?.target.value); }} rows={2} />

                        </TabPanel>
                        );
                      })
                      }
                    </Tabs>
                  </div>
                </div>
              </div>
              <div className="btn-save">
                <button type="submit" className={loading ? 'disabled' : ''}> {loading ? <Spinner class="extra-small" /> : null} <div className="title-btn-save">{t('_battery_update_article')}</div></button>
              </div>
            </form>
          </React.Fragment>
      }

    </div>

  );

};

CategoryEdit.displayName = 'CategoryEdit';

export default CategoryEdit; 