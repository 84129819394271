import React from 'react';
import { useNavigate } from 'react-router-dom';
import { clearAuthTokens } from 'axios-jwt';

import { logout } from 'store/reducers/useSlices';
import { useAppDispatch, useAppSelector } from 'utils/hooks/redux';
import SearchCatalogView from './SearchCatalog.view';

const SearchCatalog: React.FC = () => {
  const navigate = useNavigate();

  const user = useAppSelector((state) => state.userData.user);

  const dispatch = useAppDispatch();

  const handleLogout = () => {
    dispatch(logout());
    clearAuthTokens();
    navigate('/login');
  };

  return <SearchCatalogView handleLogout={handleLogout} user={user} />;
};

SearchCatalog.displayName = 'SearchCatalog';

export default SearchCatalog;
