import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ItemPunchout } from '../../../../models/cart.model';
import { RootState } from '../../../../store/store';

interface ICartPunchOutForm {
  currentOrderItems: ItemPunchout[];
}

const CartPunchOutForm: React.FC<ICartPunchOutForm> = (props): JSX.Element => {
  const currency = useSelector((state: RootState) => state.userData.user?.currency);
  const [items, setItems] = useState(props.currentOrderItems);

  useEffect(() => {
    if (props.currentOrderItems.length) {
      setItems(props.currentOrderItems);
    }
  }, [props.currentOrderItems]);

  useEffect(() => {
    const updatedArray = props.currentOrderItems.map(obj => {

      if (obj.category === 'Work Time') {
        const matchingPart = props.currentOrderItems.find(part => (part.reference === obj.referenceParent) && (part.supplier_id === obj.referenceDataSupplierId));


        if (matchingPart) {
          if (matchingPart.work_time) {
            matchingPart.work_time.quantity += obj.quantity;
          } else {
            matchingPart.work_time = obj;
          }
        }

        return;
      }

      return obj;
    }).filter(Boolean);

    setItems(updatedArray as ItemPunchout[]);
  }, [props.currentOrderItems]);

  useEffect(() => {
    if (items.length) {
      document.forms['addBasket'].submit();
    }
  }, [items]);

  return (
    <form method="POST" name="addBasket" action={sessionStorage.getItem('callback_order_url') || 'https://cirrus-pro.re/panier'} hidden >
      <input type="text" readOnly name="username" />
      <input type="text" readOnly name="password" />
      <input type="text" readOnly name="client_erp_id" />
      <input type="text" readOnly name="cart_id" />
      <input type="text" readOnly name="default_warehouse" />
      {/* // token de la cirrius */}
      <input type="text" readOnly name="token" value={process.env.REACT_APP_CIRRUS_API} />
      <input type="text" readOnly value={currency || ''} name="currency" />
      {
        items?.map((part: ItemPunchout, index: number) => (
          <Fragment key={index}>
            <input type="text" readOnly value={part.reference || ''} name={`items[${index}][reference]`} />
            <input type="text" readOnly value={part.supplier_id || ''} name={`items[${index}][supplier_id]`} />
            <input type="text" readOnly value={part.supplier_logo || ''} name={`items[${index}][supplier_logo]`} />
            <input type="text" readOnly value={part.price || ''} name={`items[${index}][price]`} />
            <input type="text" readOnly value={part.category || ''} name={`items[${index}][category]`} />
            <input type="text" readOnly value={part.quantity || ''} name={`items[${index}][quantity]`} />
            {
              part.images && part.images.map((value, key) => {
                return (
                  <input
                    key={key}
                    type="text"
                    readOnly
                    value={value || ''}
                    name={`items[${index}][image][${key}][link]`}
                  />
                );
              })
            }
            {
              part.commercial_references && part.commercial_references.map((value, key) => {
                return (
                  <input
                    key={key}
                    type="text"
                    readOnly
                    value={value || ''}
                    name={`items[${index}][commercial_references][${key}]`}
                  />
                );
              })
            }

            <input
              type="text"
              readOnly
              value={part.characteristics.map((value, key) => {
                let finalString = '';
                let partialString = '';

                Object.keys(value).forEach((key) => {
                  partialString += `${key}: ${value[key]}; `;
                });

                finalString += `${partialString}`;

                return finalString;
              }).join('; ') || ''}
              name={`items[${index}][description]`}
            />
            {part.work_time && (
              <React.Fragment>
                <input type="text" readOnly value={part.work_time.quantity || ''} name={`items[${index}][work_time][quantity]`} />
                <input type="text" readOnly value={part.work_time.reference || ''} name={`items[${index}][work_time][reference]`} />
                <input type="text" readOnly value={part.work_time.supplier_id || ''} name={`items[${index}][work_time][supplier_id]`} />
                <input type="text" readOnly value={part.work_time.supplier_logo || ''} name={`items[${index}][work_time][supplier_logo]`} />
                <input type="text" readOnly value={part.work_time.price || ''} name={`items[${index}][work_time][price]`} />
                <input type="text" readOnly value={part.work_time.category || ''} name={`items[${index}][work_time][category]`} />
                {
                  part.work_time.images && part.work_time.images.map((value, key) => {
                    return (
                      <input
                        key={key}
                        type="text"
                        readOnly
                        value={value || ''}
                        name={`items[${index}][work_time][image][${key}][link]`}
                      />
                    );
                  })
                }

                <input
                  type="text"
                  readOnly
                  value={part.work_time.characteristics.map((value, key) => {
                    let finalString = '';
                    let partialString = '';

                    Object.keys(value).forEach((key) => {
                      partialString += `${key}: ${value[key]}; `;
                    });

                    finalString += `${partialString}`;

                    return finalString;
                  }).join('; ') || ''}
                  name={`items[${index}][work_time][description]`}
                />
              </React.Fragment>
            )}
          </Fragment>

        ))
      }
    </form>
  );
};

export default CartPunchOutForm;
