import { faClose, faRedo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import LinkButton from 'components/buttons/LinkButton/LinkButton';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import './BatteryBrandFilter.scss';
import useHttp from 'utils/hooks/useHttp';
import axios from 'axios';
import TextInput from 'components/inputs/TextInput/TextInput';
import { IBrand, IParamsBrand } from 'interfaces/batteryBrand';

type Props = {
  setOpenFilter?: (boolean) => void;
  openFilter?: boolean;
  setData: Dispatch<SetStateAction<IBrand[]>>;
  setTotalPage: Dispatch<SetStateAction<number>>;
  setLoading: Dispatch<SetStateAction<boolean>>;
};

const BatteryBrandFilter: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const { getValues, setValue, reset, register } = useForm();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const http = useHttp();
  const cancelToken = axios.CancelToken.source();
  const [searchFilter, setSearchFilter] = useState<IParamsBrand | {}>({});

  useEffect(() => {


    if (Array.from(searchParams).length) {

      searchParams.forEach((value, key) => {


        if (key == 'erp_brand_id') {
          setValue('erp_brand_id', searchParams.get('erp_brand_id'));
        }

        if (key == 'name') {
          setValue('name', searchParams.get('name'));
        }

      });
    }


  }, [searchParams.get('name'), searchParams.get('erp_brand_id')]);


  function handleClick() {
    const value = getValues();


    const params = {
      name: value.name && value.name || '',
      erp_brand_id: value.erp_brand_id && value.erp_brand_id || '',
      page: 1
    };

    const validParams = Object.entries(params).reduce((acc, [key, value]) => {
      if (value === undefined || value === '') {
        return acc;
      }

      acc[key] = value;

      return acc;
    }, {});

    const searchParams = createSearchParams(validParams);

    navigate({
      search: `?${searchParams}`,
    });
    props.setLoading(true);
    props.setOpenFilter!(false);
    setSearchFilter(params);
  }


  useEffect(() => {
    if (Object.keys(searchFilter).length) {
      http.batteryBrandHttp.getList(searchFilter, cancelToken.token).then((resp) => {
        props.setData(resp.data.data);
        props.setTotalPage(resp.data.total_pages);
        props.setLoading(false);
      }).catch(() => {
        console.log('cancelToken.token!!');
      });

      return () => {

        cancelToken.cancel();
      };
    }

  }, [searchFilter]);

  function handleReset() {

    reset({ name: '', erp_brand_id: '' });
    navigate({ search: '?' });
    props.setOpenFilter!(false);
    props.setLoading(true);
    setSearchFilter({ page: 1 });
  }

  return (
    <div className={`battery-filter-brand ${props.openFilter ? 'active' : ''}`}>
      <div className="header">
        <div className="buttons-container">
          <LinkButton title={t('_reset_filters')} icon={faRedo} onClick={handleReset} />
        </div>
        <div onClick={() => props.setOpenFilter!(false)}>
          <FontAwesomeIcon className="icon" icon={faClose} />
        </div>
      </div>
      <div className="title">
        <Trans
          i18nKey="_filter_results"
          components={{ b: <strong /> }}
        />

      </div>


      <div className="filters">
        <div className="categories-filter">
          <div className="dropdown-item">
            <label className="dropdown-label">{t('_name')}</label>
            <TextInput {...register('name')} />
          </div>
          <div className="dropdown-item">
            <label className="dropdown-label">{t('_erp_brand_id')}</label>
            <TextInput {...register('erp_brand_id')} />
          </div>
        </div>

      </div>

      <div className="apply-btn">
        <DefaultButton text={t('_apply')} onClick={() => handleClick()} />
      </div>
    </div>
  );
};

BatteryBrandFilter.displayName = 'BatteryBrandFilter';

export default BatteryBrandFilter;
