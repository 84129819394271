import React, { lazy } from 'react';
import { retryPromise as retry } from 'utils/retry_promise';
import { ReactComponent as IconManuals } from './icons/manuals.svg';
import { ReactComponent as IconMaintenance } from './icons/maintenance.svg';
import { ReactComponent as IconRelaysFuses } from './icons/relays-fuses.svg';
import { ReactComponent as IconWiring } from './icons/wiring.svg';
import { ReactComponent as IconServiceNewsletter } from './icons/service-newsletter.svg';
import { ReactComponent as IconRepairItems } from './icons/repair-items.svg';
import { ReactComponent as IconAdjust } from './icons/adjust.svg';
import { ReactComponent as IconDiagnostics } from './icons/diagnostics.svg';
import { ReactComponent as IconComponentsPosition } from './icons/components_position.svg';
import { ReactComponent as IconGraphicalSelection } from './icons/graphics.svg';
import { ReactComponent as IconTyres } from './icons/tyres.svg';
import { ReactComponent as IconErrorCodes } from './icons/error-codes.svg';
import { ReactComponent as IconOil } from './icons/car-oil.svg';

const VehicleAdjust = lazy(() => retry(() => import('pages/CatalogVehicle/TechnicalDocumentation/components/Adjust/Adjust')));
const VehicleServiceNewsletter = lazy(() => retry(() => import('pages/CatalogVehicle/TechnicalDocumentation/components/Adjust/Adjust')));
const VehicleTecCompPosition = lazy(() => retry(() => import('pages/CatalogVehicle/TechnicalDocumentation/components/Adjust/Adjust')));
const VehicleDiagnostic = lazy(() => retry(() => import('pages/CatalogVehicle/TechnicalDocumentation/components/Adjust/Adjust')));
const VehicleManuals = lazy(() => retry(() => import('pages/CatalogVehicle/TechnicalDocumentation/components/Adjust/Adjust')));
const VehicleRepairItems = lazy(() => retry(() => import('pages/CatalogVehicle/TechnicalDocumentation/components/Adjust/Adjust')));
const VehicleMaintenance = lazy(() => retry(() => import('pages/CatalogVehicle/TechnicalDocumentation/components/Adjust/Adjust')));
const VehicleRelaysFuses = lazy(() => retry(() => import('pages/CatalogVehicle/TechnicalDocumentation/components/Adjust/Adjust')));
const VehicleWiring = lazy(() => retry(() => import('pages/CatalogVehicle/TechnicalDocumentation/components/Adjust/Adjust')));
const VehicleGraphicalSelection = lazy(() => retry(() => import('pages/CatalogVehicle/TechnicalDocumentation/components/Adjust/Adjust')));
const VehicleTyres = lazy(() => retry(() => import('pages/CatalogVehicle/TechnicalDocumentation/components/Adjust/Adjust')));
const VehicleErrorCodes = lazy(() => retry(() => import('pages/CatalogVehicle/TechnicalDocumentation/components/Adjust/Adjust')));
const VehicleConsumables = lazy(() => retry(() => import('pages/CatalogVehicle/TechnicalDocumentation/components/Adjust/Adjust')));

export interface IReliableRoute {
  tab_name: string;
  title: string;
  icon: React.FC;
  component?: React.FC;
  subscription_type: Array<string>;
}

export interface IReliableComponentProps {
  [index: string]: boolean,
}

export const RELIABLE_TAB_NAMES: { [index: string]: string } = {
  // CATEGORIES: 'categories',
  MANUALS: 'technical-manuals',
  MAINTENANCE: 'maintenance',
  RELAYSFUSES: 'relays-fuses',
  WIRING: 'wiring',
  SERVICE_NEWSLETTER: 'service-newsletter',
  REPAIR_ITEMS: 'repair-items',
  DIAGNOSTICS: 'diagnostics',
  ADJUST: 'adjust',
  TEC_COMP_POSITION: 'components-position',
  GRAPHICS: 'graphics',
  TYRES: 'tyres',
  ERROR_CODES: 'error-codes',
  CONSUMABLES: 'consumables',
  ACTIA: 'actia',
};

export const INITIAL_TABS_STATE: { [key: string]: boolean; } = {
  // [RELIABLE_TAB_NAMES.CATEGORIES]: true,
  [RELIABLE_TAB_NAMES.MANUALS]: false,
  [RELIABLE_TAB_NAMES.ERROR_CODES]: false,
  [RELIABLE_TAB_NAMES.MAINTENANCE]: false,
  [RELIABLE_TAB_NAMES.RELAYSFUSES]: false,
  [RELIABLE_TAB_NAMES.WIRING]: false,
  [RELIABLE_TAB_NAMES.SERVICE_NEWSLETTER]: false,
  [RELIABLE_TAB_NAMES.REPAIR_ITEMS]: false,
  [RELIABLE_TAB_NAMES.DIAGNOSTICS]: false,
  [RELIABLE_TAB_NAMES.ADJUST]: false,
  [RELIABLE_TAB_NAMES.GRAPHICS]: false,
  [RELIABLE_TAB_NAMES.TYRES]: false,
  [RELIABLE_TAB_NAMES.CONSUMABLES]: false,
  [RELIABLE_TAB_NAMES.ACTIA]: false,

};

export const CAR_TECH = 'CAR_Tech';

export const CAR_PRO = 'CAR_Pro';

export const NONE_SUBSCRIPTION = 'none';

export const RELIABLE_CONTENT_TABS: IReliableRoute[] = [
  {
    tab_name: RELIABLE_TAB_NAMES.REPAIR_ITEMS,
    title: '_repair_items',
    icon: IconRepairItems,
    component: VehicleRepairItems,
    subscription_type: [CAR_TECH, CAR_PRO],
  },
  {
    tab_name: RELIABLE_TAB_NAMES.MAINTENANCE,
    title: '_maintenance',
    icon: IconMaintenance,
    component: VehicleMaintenance,
    subscription_type: [CAR_TECH, CAR_PRO],
  },
  {
    tab_name: RELIABLE_TAB_NAMES.ADJUST,
    title: '_adjust',
    icon: IconAdjust,
    component: VehicleAdjust,
    subscription_type: [CAR_TECH, CAR_PRO],
  },
  {
    tab_name: RELIABLE_TAB_NAMES.MANUALS,
    title: '_technical_manuals',
    icon: IconManuals,
    component: VehicleManuals,
    subscription_type: [CAR_TECH, CAR_PRO],
  },
  {
    tab_name: RELIABLE_TAB_NAMES.TYRES,
    title: '_tyres',
    icon: IconTyres,
    component: VehicleTyres,
    subscription_type: [CAR_TECH, CAR_PRO],
  },
  {
    tab_name: RELIABLE_TAB_NAMES.SERVICE_NEWSLETTER,
    title: '_service_newsletter',
    icon: IconServiceNewsletter,
    component: VehicleServiceNewsletter,
    subscription_type: [CAR_TECH, CAR_PRO],
  },
  {
    tab_name: RELIABLE_TAB_NAMES.RELAYSFUSES,
    title: '_relays_fuses',
    icon: IconRelaysFuses,
    component: VehicleRelaysFuses,
    subscription_type: [CAR_PRO],
  },
  {
    tab_name: RELIABLE_TAB_NAMES.TEC_COMP_POSITION,
    title: '_tec_comp_position',
    icon: IconComponentsPosition,
    component: VehicleTecCompPosition,
    subscription_type: [CAR_PRO],
  },
  {
    tab_name: RELIABLE_TAB_NAMES.DIAGNOSTICS,
    title: '_diagnostic',
    icon: IconDiagnostics,
    component: VehicleDiagnostic,
    subscription_type: [CAR_PRO],
  },
  {
    tab_name: RELIABLE_TAB_NAMES.WIRING,
    title: '_wiring_diagrams',
    icon: IconWiring,
    component: VehicleWiring,
    subscription_type: [CAR_PRO],
  },
  {
    tab_name: RELIABLE_TAB_NAMES.GRAPHICS,
    title: '_graphics',
    icon: IconGraphicalSelection,
    component: VehicleGraphicalSelection,
    subscription_type: [CAR_PRO],
  },

  {
    tab_name: RELIABLE_TAB_NAMES.ERROR_CODES,
    title: '_error_codes',
    icon: IconErrorCodes,
    component: VehicleErrorCodes,
    subscription_type: [CAR_TECH, CAR_PRO],
  },
  {
    tab_name: RELIABLE_TAB_NAMES.CONSUMABLES,
    title: '_consumables',
    icon: IconOil,
    component: VehicleConsumables,
    subscription_type: [CAR_TECH, CAR_PRO],
  },
  // {
  //   tab_name: RELIABLE_TAB_NAMES.ACTIA,
  //   title: '_actia',
  //   icon: IconActia,
  //   component: VehicleActia,
  //   subscription_type: [CAR_PRO],
  // },
];

export interface IRMIModulesLinkModal {
  tabName: string,
  children: Array<IRMIModulesLinkChildren>
}

export interface IRMIModulesLinkChildren {
  label: string,
  link: string | null,
  children: Array<IRMIModulesLinkChildren> | null;
}