import React from 'react';
import {useTranslation} from 'react-i18next';
import { IRow } from '../schema';
import DropComponent from './DragAndDropComponents/DropComponent';
import './ProformaMappingTable.scss';

interface IProformaTable{
  rows: IRow[],
  disabled,
  setRows:(newRows)=> void,
  setDisableBtn: (disableBtn:boolean)=>void,
  fileHeader,
  setFileHeader: (header) => void,
  disableMapping: boolean,
  setDisableMapping:(checked:boolean)=>void
}


const ProformaMappingTable = (props:IProformaTable): JSX.Element => {
  const {t} = useTranslation();

  return (
    <React.Fragment>
      <table className="tg proforma-mapping-table" style={{tableLayout: 'fixed'}}>
        <colgroup>
          <col style={{width: '66px'}} />
          <col style={{width: '236px'}} />
          <col style={{width: '74px'}} />
          <col style={{width: '199px'}} />
        </colgroup>
        <thead>
          <tr>
            <th className={`tg-0pky ${!props.disableMapping ? 'disabled' : ''}`} colSpan={2}>
              {t('_standard')}
            </th>
            <th className="tg-0pky custom" colSpan={2}>
              <div className="proforma-enable-custom">
                <label className="enable-mapping">
                  <input
                    type="checkbox"
                    checked={!props.disableMapping}
                    onChange={e => props.setDisableMapping(!e.target.checked)}
                  />
                  <span className="slider round" />
                </label>
                <div className={`enable-mapping-title ${props.disableMapping ? 'disabled' : ''}`}>{t('_custom')}</div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {props.rows.map(({ID, title, required}, index) => {
            return (
              <tr key={ID}>
                <td className={`tg-0pky ${!props.disableMapping ? 'disabled' : ''}`}>{index}</td>
                <td className={`tg-0pky ${!props.disableMapping ? 'disabled' : ''} title`}>
                  {t(String(title))}
                  {required ? <span className="required">*</span> : ''}
                </td>
                <DropComponent rows={props.rows} setRows={props.setRows} index={index} setFileHeader={props.setFileHeader} fileHeader={props.fileHeader ?? []} disableMapping={props.disableMapping} />
              </tr>
            );
          })}
        </tbody>
      </table>
      <div />
    </React.Fragment>
  );
};


export default ProformaMappingTable;
