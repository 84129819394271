import React, { useCallback, useEffect, useState } from 'react';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import { useTranslation } from 'react-i18next';
import { FileWithPath, useDropzone } from 'react-dropzone';
import { faFileExcel } from '@fortawesome/free-regular-svg-icons';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import useHttp from 'utils/hooks/useHttp';
import { notify } from 'utils/marketplace';
import { useSearchParams } from 'react-router-dom';
import { useDeviceDetect } from '../../../../../utils/hooks/deviceDetect';
import { IOption } from 'interfaces/select';
import './UploadPriceModal.scss';


type Props = {
  setOpenModal: (openModal: boolean) => void
  providers?: IOption[]
  getLoadingPrice: (page) => void
}


const UploadPriceModal: React.FC<Props> = (props: Props) => {
  const http = useHttp();
  const { t } = useTranslation();

  const [invalidFile, setInvalidFile] = useState(false);
  const [file, setFile] = useState<FileWithPath | null>(null);
  const [loadingPrice, setLoadingPrice] = useState(false);
  const { control, register, getValues, handleSubmit, formState: { errors } } = useForm();
  const [searchParams, setSearchParams] = useSearchParams();
  const { isMobile, isTablet } = useDeviceDetect();
  // const [loadingPriceGroup, setLoadingPriceGroup] = useState(false);
  // const [groups, setGroups] = useState<IOption[]>([]);
  // const [selectAll, setSelectAll] = useState(false);

  const [deleteBeforeUpload, setDeleteBeforeUpload] = useState(false);

  const handleCheckboxChange = (e) => {
    setDeleteBeforeUpload(e.target.checked);
  };

  const onDrop = useCallback((acceptedFiles) => {
    setFile(acceptedFiles[0]);
    setInvalidFile(false);
  }, [file]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    accept: {
      'text/csv': ['.xls', '.xlsx'],
      'application/vnd.ms-excel': ['.xls', '.xlsx'],
    },
  });


  function addPrice() {
    if (!file) {
      setInvalidFile(true);

      return;
    } else {
      setInvalidFile(false);
    }

    setLoadingPrice(true);
    const formData = new FormData();

    formData.append('file', file!);
    formData.append('codfou', getValues().provider?.value);


    // if (getValues().group?.length) {
    //   formData.append('group', JSON.stringify(getValues().group.map(({ value }) => value)));
    // }

    formData.append('comments', getValues().message);
    formData.append('delete_before_upload', String(deleteBeforeUpload));

    http.priceListHttp.uploadPriceProvider(formData).then(() => {
      setLoadingPrice(false);
      props.setOpenModal(false);
      props.getLoadingPrice({ page: 1 });
      searchParams.delete('page');
      setSearchParams(searchParams);

    }).catch(() => {

      notify('Error');
      props.setOpenModal(false);
      props.getLoadingPrice({ page: 1 });
      setLoadingPrice(false);
      searchParams.delete('page');
      setSearchParams(searchParams);
    });

  }

  // useEffect(() => {

  //   if (watch('provider')) {
  //     setLoadingPriceGroup(true);
  //     setGroups([]);
  //     setValue('group', []);
  //     setSelectAll(false);
  //     http.priceListHttp.priceGetGroup(watch('provider').value).then(res => {

  //       setGroups(res.data.map((item) => ({ value: String(item), label: String(item) })));
  //     }).finally(() => {

  //       setLoadingPriceGroup(false);

  //     });

  //   }

  // }, [watch('provider')]);

  // function handleSelectAll() {
  //   setSelectAll(!selectAll);
  // }

  // useEffect(() => {
  //   if (selectAll) {
  //     setValue('group', groups);
  //   } else {
  //     setValue('group', []);
  //   }
  // }, [selectAll]);

  return (
    <form onSubmit={handleSubmit(addPrice)} className="upload-price-modal">
      <div className="modal-header">
        <div className="modal-title">{t('_upload_providers_price')}</div>
        <div className="close-modal" onClick={() => props.setOpenModal(false)}><FontAwesomeIcon icon={faClose} /></div>
      </div>
      <div className="mobile-scroll">
        <div className="modal-body">
          <div className={`price-file-upload-container ${invalidFile && 'invalid'}`}>
            <i className="fas fa-folder-open price-folder-upload" />
            <div {...getRootProps()} className="price-title-upload ">
              {t('_drag_drop_here')}
              <br />
              <strong className="browse-files">{t('_csv_browse')}</strong>
            </div>
            <input
              {...getInputProps()}
              accept="text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              type="file"
              style={{ display: 'none' }}
            />
          </div>

          <div className="price-details">
            {
              file &&
              <div className="uploaded-file">
                <FontAwesomeIcon className="file-icon" icon={faFileExcel} />
                <div className="file-data">
                  <div className="file-name">{file.path}</div>
                  <FontAwesomeIcon className="remove-file" icon={faClose} onClick={() => setFile(null)} />
                </div>
              </div>
            }
            <div className="price-setting-row">
              <div className="row-label">{t('_provider')}<span>*</span></div>
              <div className="price-dropdown-item">
                <Controller
                  control={control}
                  name="provider"
                  rules={{ required: true }}
                  render={({ field: { onChange } }) => (
                    <Select
                      className={`select-dropdown ${errors.provider && 'required'}`}
                      onChange={(val) => {
                        onChange(val);
                      }}
                      options={props.providers}
                      isSearchable={!isMobile && !isTablet}
                      placeholder={t('_provider')}
                      styles={{
                        menu: provided => ({ ...provided, zIndex: 50 })
                      }}
                    />
                  )}
                />
              </div>
            </div>
            {/* {
              watch('provider') ?

                <div className="price-setting-row">
                  <div className="row-label">{t('_group')}</div>
                  <div className="price-dropdown-item">
                    <Controller
                      control={control}
                      name="group"
                      // rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <Select
                          className="select-dropdown"
                          value={value}
                          onChange={(val) => {
                            onChange(val);
                          }}
                          options={groups}
                          isLoading={loadingPriceGroup}
                          isMulti
                          isSearchable={!isMobile && !isTablet}
                          placeholder={t('_select') + ' ' + t('_group')}
                          styles={{
                            menu: provided => ({ ...provided, zIndex: 50 })
                          }}
                        />
                      )}
                    />
                    <div className="select-all-container">
                      <input type="checkbox" checked={selectAll} onChange={handleSelectAll} />
                      <span>{t('_select_all')}</span>
                    </div>
                  </div>

                </div> : null
            } */}
          </div>
          <div className="price-setting-row">
            <div className="select_adelete_before_upload">
              <input
                type="checkbox"
                name="delete_before_upload"
                checked={deleteBeforeUpload}
                onChange={handleCheckboxChange}
              />
              <span>{t('_delete_all_parts_before_upload')}</span>
            </div>
          </div>
        </div>

        <div className="price-message ">
          <textarea className={`price-textarea ${errors.message && 'error'}`} {...register('message', { required: true })} rows={4} placeholder="Message" />
        </div>
      </div>
      <div className="modal-footer">
        <div className="upload-price-btn">
          <DefaultButton isloading={loadingPrice} text={t('_save')} />
        </div>
      </div>
    </form>
  );
};

export default UploadPriceModal;