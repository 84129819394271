
import TextInput from 'components/inputs/TextInput/TextInput';
import Select from 'react-select';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useHttp from 'utils/hooks/useHttp';
import './OilEdit.scss';
import { IOilBrand, IOilCategory, IOilInfo } from 'interfaces/oil';

import Spinner from 'components/spinner/Spinner';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { notify } from 'utils/marketplace';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import UploadImage from 'components/UploadImage/UploadImage';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import UploadPdf from 'components/UploadPdf/UploadPdf';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';

type Props = {
  setOpenModal: (e: boolean) => void;
  setSubmit: (e: boolean) => void;
  oilId: number | undefined
};


const OilEdit: React.FC<Props> = (props) => {

  let isMounted = true;
  const { t } = useTranslation();
  const http = useHttp();
  const user = JSON.parse(localStorage.getItem('userData')!);

  const [categories, setCategories] = useState<IOilCategory[]>([]);
  const [category, setCategory] = useState<IOilCategory | null>();
  const [brands, setBrands] = useState<IOilBrand[]>([]);
  const [brand, setBrand] = useState<IOilBrand | null>();
  const [file, setFile] = useState<File[]>([]);
  const [oilInfo, setOilInfo] = useState<IOilInfo>();
  const [loading, setLoading] = useState<boolean>(false);
  const { isMobile, isTablet } = useDeviceDetect();
  const [loadingErpPartInfo, setLoadingErpPartInfo] = useState<boolean>(false);
  const [image, setImage] = useState<File[]>([]);

  const validate = Yup.object().shape({
    reference: Yup.string().trim().required(t('_fild_required')),
    // category: Yup.string().required(t('_fild_required')),
    // brand: Yup.string().required(t('_fild_required')),
    // family: Yup.string().trim().required(t('_fild_required')),
    viscosity: Yup.string().trim().required(t('_fild_required')),
    quantity: Yup.string().required().typeError(t('_fild_required')),
    unity: Yup.string().trim().required(t('_fild_required')),
    short_description: Yup.string().trim().required(t('_fild_required')),
    // description: Yup.string().trim().required(t('_fild_required')),
    // gencode: Yup.string().trim().required(t('_fild_required')),

  });

  const { getValues, resetField, register, handleSubmit, clearErrors, setValue, control, formState: { errors }, } = useForm<IOilInfo>({ resolver: yupResolver(validate) });

  useEffect(() => {

    if (props.oilId && isMounted) {
      Promise.all([http.oilHttp.getCategories(), http.oilHttp.getBrands({ per_page: 1000 }), http.oilHttp.getOilInfo(props.oilId)])
        .then((res) => {
          const dataCategories = res[0].data.map((item) => {
            return { value: item.category_id, label: item.category_name, image: item.category_image };
          });

          setCategories(dataCategories);


          const dataBrands = res[1].data.data.map((item) => {
            return { value: item.id, label: item.name, image: item.image };
          });

          setBrands(dataBrands);
          setOilInfo(res[2].data);
          setCategory({ value: res[2]?.data?.category.id, label: res[2]?.data?.category.name });
          setBrand({ value: res[2]?.data?.brand.id, label: res[2]?.data?.brand.name });
          setValue('image', res[2].data?.image ? res[2].data?.image : '');
        });
    }

    return () => {
      isMounted = false;
    };

  }, []);

  const getErpPart = () => {
    let erpClientId = null;
    const values = getValues();
    const reference = values?.reference.trim();
    const brand_id = brand?.value;

    if (localStorage.getItem('infoSelectClientErp')) {
      const info = JSON.parse(localStorage.getItem('infoSelectClientErp') as string);

      erpClientId = info.erpClientId;
    }

    if (erpClientId == null || reference == '' || brand_id == undefined) {
      if (erpClientId == null) {
        notify(t('_select_client'), 'error', t('_error'));
      }

      if (reference == '') {
        notify(t('_reference'), 'error', t('_error'));
      }

      if (brand_id == undefined) {
        notify(t('_brand_id'), 'error', t('_error'));
      }

      return;
    } else {

      const param = {
        erp_id: erpClientId,
        reference: reference,
        brand_id: brand_id,
        type: 'oil'
      };

      setLoadingErpPartInfo(true);

      if (values.short_description) {
        resetField('short_description');
      }

      http.retailerHttp.getErpPart(param).then((data) => {

        if (data.data?.description == undefined) {
          notify(t('_not_found_description'), 'error', t('_error'));
        } else {
          setValue('short_description', data.data?.description);
        }

      }).finally(() => {
        setLoadingErpPartInfo(false);
      });
    }
  };


  const onSubmit = async () => {
    setLoading(true);
    const values = getValues();
    const formData: FormData = new FormData();

    formData.append('reference', values.reference.trim());
    formData.append('family', values.family.trim());
    formData.append('quantity', String(values.quantity).trim());
    formData.append('unity', values.unity.trim());
    formData.append('description', values.description.trim());
    formData.append('short_description', values.short_description.trim());
    formData.append('viscosity', values.viscosity.trim());
    formData.append('category', String(category?.value));
    formData.append('brand', String(brand?.value));
    formData.append('top_offer', String(values.top_offer));
    formData.append('gencode', String(values.gencode).trim());

    if(user?.hasTopSales){
      formData.append('top_sales', String(values.top_sales));
    }


    if (file.length) {
      formData.append('file', file[0]);
    }

    if (image.length) {
      formData.append('image', image[0]);
    }

    http.oilHttp.postOil(Number(props?.oilId), formData).then(() => {
      notify(t('_oil_updated'), 'success', t('_success'));
      props.setOpenModal(false);
      props.setSubmit(true);
    }).catch((error) => {
      let erorrs = '';

      Object.entries(error).forEach(([key, value]) => {
        erorrs += `${t('_' + key)} : ${value} `;
      });
      notify(erorrs, 'error', t('_error'));
      setLoading(false);
    });
  };


  return (
    <div className="oil-edit-container" >
      <div className="close-modal" onClick={() => { props.setOpenModal(false); }}><FontAwesomeIcon icon={faClose} /></div>

      <div className="title">
        {t('_oil_title_edit')}
        <br />
        <span>{t('_oil_title_edit_catalog')}</span>
      </div>

      {
        !oilInfo ?
          <div className="spinner-info"> <Spinner />  </div> :
          <React.Fragment >
            <div className="info-item">
              {t('_oil_item_count')}
              <br />
              <span>{oilInfo?.brand.name} {oilInfo?.reference}</span>
            </div>

            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="oil-edit-body">

                <div className="row">
                  <div className="container">
                    <label>
                      {t('_oil_category')} <span className="placeholderRed"> * </span>
                    </label>
                    <Controller
                      control={control}
                      name="category"
                      render={({ field: { onChange } }) => (
                        <Select
                          value={category}
                          className="select-dropdown "
                          onChange={(val) => {
                            onChange(val?.value);
                            setCategory(val);
                          }}
                          options={categories}
                          placeholder={t('_oil_select_category')}
                          isSearchable={!isMobile && !isTablet}
                        />
                      )}
                    />
                  </div>

                  <div className="container">
                    <label>
                      {t('_oil_brand')} <span className="placeholderRed"> * </span>
                    </label>
                    <Controller
                      control={control}
                      name="brand"
                      render={({ field: { onChange } }) => (
                        <Select
                          value={brand}
                          className="select-dropdown "
                          onChange={(val) => {
                            onChange(val?.value);
                            setBrand(val);
                          }}
                          options={brands}
                          placeholder={t('_oil_select_brand')}
                          isSearchable={!isMobile && !isTablet}
                        />
                      )}
                    />
                  </div>
                  <div className={`container ${errors.reference?.type == 'required' ? 'error' : ''}`}>
                    <label>
                      {t('_oil_reference')} <span className="placeholderRed"> * </span>
                    </label>
                    <TextInput defaultValue={oilInfo?.reference} {...register('reference')} />
                    <p className="error-message">{errors.reference?.type == 'required' ? errors.reference?.message : ''}</p>
                  </div>
                </div>

                <div className="row">
                  <div className="container">
                    <label>
                      {t('_oil_family')}
                    </label>
                    <TextInput defaultValue={oilInfo?.family} {...register('family')} />
                    <p className="error-message">{errors.family?.type == 'required' ? errors.family?.message : ''}</p>
                  </div>

                  <div className={`container ${errors.viscosity?.type == 'required' ? 'error' : ''}`}>
                    <label>
                      {t('_oil_viscosity')} <span className="placeholderRed"> * </span>
                    </label>
                    <TextInput defaultValue={oilInfo?.viscosity} {...register('viscosity')} />
                    <p className="error-message">{errors.viscosity?.type == 'required' ? errors.viscosity?.message : ''}</p>
                  </div>

                  <div className={`container ${errors.quantity?.type ? 'error' : ''}`}>
                    <label>
                      {t('_oil_quantity')} <span className="placeholderRed"> * </span>
                    </label>
                    <TextInput defaultValue={oilInfo?.quantity} {...register('quantity')} />
                    <p className="error-message">{errors.quantity?.type ? errors.quantity?.message : ''}</p>
                  </div>


                </div>
                <div className="row">
                  <div className={`container ${errors.unity?.type == 'required' ? 'error' : ''}`}>
                    <label>
                      {t('_oil_unity')} <span className="placeholderRed"> * </span>
                    </label>
                    <TextInput defaultValue={oilInfo?.unity} {...register('unity')} />
                    <p className="error-message">{errors.unity?.type == 'required' ? errors.unity?.message : ''}</p>
                  </div>
                  <div className="container">
                    <label>
                      {t('_gencode')}
                    </label>
                    <TextInput defaultValue={oilInfo?.gencode} {...register('gencode')} />
                    <p className="error-message">{errors.gencode?.type == 'required' ? errors.gencode?.message : ''}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="container top-offer-checkbox-container">
                    <label htmlFor="top_offer">{t('_top_offer')}</label>
                    <input type="checkbox" id="top_offer" defaultChecked={oilInfo?.top_offer} {...register('top_offer')} />
                  </div>
                  {user?.hasTopSales ? 
                    <div className="container top-offer-checkbox-container">
                      <label htmlFor="top_sales">{t('_top_sales')}</label>
                      <input type="checkbox" id="top_sales" defaultChecked={oilInfo?.top_sales} {...register('top_sales')} />
                    </div>
                    : null
                  }
                </div>

                <div className="row">


                  <div className={`container ${errors.image?.type == 'required' ? 'error' : ''}`}>
                    <UploadImage setFile={setImage} image={oilInfo?.image} errors={errors} setValue={setValue} clearErrors={clearErrors} />
                    <span className="upload_image_info"> {t('_upload_image_info')}</span>
                  </div>

                  <div className={`container ${errors.file?.type == 'required' ? 'error' : ''}`}>
                    <UploadPdf setFile={setFile} errors={errors} pdf={oilInfo?.file} clearErrors={clearErrors} setValue={setValue} title="_technical_sheet" />
                  </div>
                </div>

                <div className="row">
                  <div className={`container ${errors.short_description?.type == 'required' ? 'error' : ''}`}>
                    <label>
                      {t('_short_description')} <span className="placeholderRed"> * </span>
                    </label>
                    <TextInput defaultValue={oilInfo?.short_description} {...register('short_description')} />
                    <p className="error-message">{errors.short_description?.type == 'required' ? errors.short_description?.message : ''}</p>
                  </div>
                </div>
                <div className="row file-container">
                  <div className={`container ${errors.description?.type == 'required' ? 'error' : ''}`}>

                    <div className="description_info">
                      <label>
                        {t('_oil_description')}
                      </label>
                      <DefaultButton type="button" red text={t('_get_erp_description')} onClick={() => getErpPart()} isloading={loadingErpPartInfo} />
                    </div>
                    <textarea rows={7} defaultValue={oilInfo?.description} {...register('description', {})} />
                    <p className="error-message">{errors.description?.type == 'required' ? errors.description?.message : ''}</p>
                  </div>
                </div>
              </div>

              <div className="btn-save">
                <button type="submit" className={loading ? 'disabled' : ''}> {loading ? <Spinner class="extra-small" /> : null}
                  <div className="title-btn-save">{t('_oil_update_article')}</div>
                </button>
              </div>
            </form>
          </React.Fragment>
      }

    </div>

  );

};

OilEdit.displayName = 'OilEdit';

export default OilEdit; 