import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import useCollapse from 'react-collapsed';
import { useSearchParams } from 'react-router-dom';
import './CollapseList.scss';
import ItemGroup from './ItemGroup';

type Props = {
  data?
  onSubGroupClick?: (item) => void;
  onItemMpClick?: (item) => void;
  onItemMpManualClick?: (value: boolean) => void;
  activeItem?: string | number
}

const SubGroupItem: React.FC<Props> = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();


  function handleExpand() {
    console.log('item_id', String(props.data.ItemMpId), searchParams.get('item_id'));
    searchParams.set('item_id', String(props.data.ItemMpId));
    setSearchParams(searchParams);
  }


  const config = {
    defaultExpanded: String(props.data.ItemMpId) === searchParams.get('item_id'),
    duration: 0,
    expandStyles: {
      height: '100%'
    },
    collapseStyles: {},
    onExpandStart: handleExpand
  };


  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse(config);

  return (
    <div className="collapse-list-diagnostic">
      <div className="item level-3">
        <div className="collapse-item" {...getToggleProps()}>
          <FontAwesomeIcon icon={isExpanded ? faChevronDown : faChevronRight} />
          <div >{props.data.ItemMpText} </div>
        </div>
      </div>
      {props.data.Manuals.map((itemMp, index) => {

        return (
          <div {...getCollapseProps()} key={index}>
            <ItemGroup data={itemMp} onItemMpManualClick={props.onItemMpManualClick} activeItem={props.activeItem} />
          </div>
        );
      })}
    </div>
  );
};

export default SubGroupItem;