
import React from 'react';
import ItemEshopConsumableCategoryView from './ItemEshopConsumableCategoryView';
import { ICategory } from 'interfaces/consumableCategory';

type Props = {
  data: ICategory[],
  loading:boolean
  openModalConsumableCategory:(id:number)=>void;
  deleteItem:(id:number)=>void
};

const ItemEshopConsumableCategory: React.FC<Props> = (props) => {
  return <ItemEshopConsumableCategoryView data={props.data} loading={props.loading} openModalConsumableCategory={props.openModalConsumableCategory} deleteItem={props.deleteItem}/>;
};

ItemEshopConsumableCategory.displayName = ' ItemEshopConsumableCategory';

export default ItemEshopConsumableCategory;
