import React from 'react';
import { useNavigate } from 'react-router-dom';
import { clearAuthTokens } from 'axios-jwt';
import HomeView from './Home.view';
import { logout } from 'store/reducers/useSlices';
import { useAppDispatch, useAppSelector } from 'utils/hooks/redux';

const Home: React.FC = () => {
  const navigate = useNavigate();

  const user = useAppSelector((state) => state.userData.user);

  const dispatch = useAppDispatch();

  const handleLogout = () => {
    dispatch(logout());
    clearAuthTokens();
    navigate('/login');
  };

  return <HomeView handleLogout={handleLogout} user={user}></HomeView>;
};

Home.displayName = 'Home';

export default Home;
