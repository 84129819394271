import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import './OilNavbar.scss';
import SortBy from 'components/SortBy/SortBy';


type Props = {
  title: string;
  option?: ReactElement
  oilInput?: ReactElement,
  showSortBy?: boolean
}
const OilNavbar: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  return (
    <div className="oil-navbar-container">
      <div className="breadcrumbs">
        {t(props.title)}
      </div>
      {props.showSortBy ? <SortBy /> : null}
      <div className="input">{props.oilInput}</div>
      <div className="options">{props.option}</div>
    </div>
  );
};


export default OilNavbar;