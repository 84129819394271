import React, {useContext, useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import './UploadProformaAmerigoFile.scss';
import FILE_XLSX from 'assets/images/file-xlsx.svg';
import { useDropzone } from 'react-dropzone';
import {  useForm } from 'react-hook-form';
import useHttp from 'utils/hooks/useHttp';
import Spinner from 'components/spinner/Spinner';
import {IProformaAmerigoResponse} from 'utils/hooks/http/proforma.http';
import { IHttpResponse } from 'utils/http';
import {
  IUploadProformaContext,
  UploadProformaAmerigoContext
} from 'context/UploadProformaAmerigoContext';
import {notify} from '../../../../../../../utils/marketplace';

type ICsvForm = {
  files: File;
};

type Props = {

}

const UploadProformaAmerigoFile: React.FC<Props> = () => {
  const { t } = useTranslation();
  const {selectedSupplier, setUploadFileResponse, setFileName} = useContext(UploadProformaAmerigoContext) as IUploadProformaContext;

  const http = useHttp();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { setModalContentType} = useContext(UploadProformaAmerigoContext) as IUploadProformaContext;

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    onDrop: file => {
      setValue('files', file[0]);
    },
    multiple: false,
    accept: {
      'text/csv': ['.csv', '.xls', '.xlsx'],
      'application/vnd.ms-excel': ['.csv', '.xls', '.xlsx'],
    },
  });

  const {
    setValue,
    watch,
    getValues
  } = useForm<ICsvForm>();

  useEffect(() => {
    const file = getValues('files');

    if (!file || !file.size) {
      return;
    }

    if(!selectedSupplier) {
      return;
    }

    const formData: FormData = new FormData();

    formData.append('csv', file);
    formData.append('supplier_mapping_dlnr', selectedSupplier?.value);
    setIsLoading(true);
    http.proformaAmerigo.uploadProformaAmerigo(formData).then((res: IHttpResponse<IProformaAmerigoResponse>) => {
      setUploadFileResponse(res.data);
    }).catch((e) => {
      if(e.message) {
        notify(t(e.message));
      }
    }).finally(() => {
      setIsLoading(false);
      setModalContentType('big');
      setFileName(file.name);
    });

  }, [watch('files')]);

  const checkSelectedSupplier = (e) => {
    if(!selectedSupplier) {
      e.stopPropagation();
      e.preventDefault();
    }
  };


  return (
    <div className="proforma-upload-file-amerigo">
      <form >
        <div  >
          <div className="upload-btn" >
            { selectedSupplier ?
              (
                <div {...getRootProps({ className: 'icon' })} className="icon">
                  {isLoading ? <Spinner class="small" /> : <img src={FILE_XLSX} alt="" />}
                  <input {...getInputProps()} />
                </div>
              ) : (
                <div className="icon">
                  <img src={FILE_XLSX} alt="" />
                </div>
              )
            }

            <div onClick={checkSelectedSupplier} className="text">
              <p className="title">
                {t('_browse_your_proforma_file')}
              </p>
              <p className="subtitle">
                <span>{t('_upload_proforma_supports')} <a onClick={(e) => e.stopPropagation()} href="/csv-template/proforma/template.csv" download>{t('_download_template')}</a></span>
              </p>
              {
                fileRejections.length > 0
                  ? <div className="error">{t('WRONG_PROFORMA_FILE')}</div>
                  : ''
              }
            </div>
          </div>
        </div>
      </form >

    </div >
  );
};

UploadProformaAmerigoFile.displayName = 'UploadProformaAmerigoFile';

export default UploadProformaAmerigoFile;
