
import { faAdd } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { IOption } from 'interfaces/select';


type Props = {
  setOpenModal: (e: boolean) => void;
  setParams;
  setForcePage: (forcePage: number) => void;
  setIsActive: (e: boolean) => void;
  clients
  countries
}

const FilterCountryRestriction: React.FC<Props> = (props) => {

  const { reset, getValues, setValue, register, handleSubmit, control } = useForm();
  const { t } = useTranslation();
  const [country, setCountry] = useState<IOption[]>([]);

  const [selectClient, setSelectClient] = useState<IOption | null>();
  const [selectCountry, setSelectCountry] = useState<IOption | null>();


  useEffect(() => {
    setCountry(props.countries.map((item) => ({
      label: String(item.name),
      value: String(item.iso_code),
    })));

  }, [props.countries]);

  const onSubmit = () => {

    const values = getValues();

    Object.keys(values).forEach((key) => {
      if (values[key] == undefined || values[key] == '') {
        delete values[key];
      }
    });

    if (Object.keys(values).length == 0) {
      return;
    }

    props.setParams({ ...getValues(), ...{ page: 1 } });
    props.setForcePage(1);
    props.setIsActive(false);
  };

  const resetFilter = () => {
    const values = getValues();

    Object.keys(values).forEach((key) => {
      if (values[key] == undefined || values[key] == '') {
        delete values[key];
      }
    });

    setSelectClient(null);
    setSelectCountry(null);
    props.setParams({ page: 1 });
    props.setForcePage(1);
    props.setIsActive(false);

  };

  return (
    <div className="filter">
      <div className="filter-header">

        <div className="filter-title">{t('_advanced_search')}</div>

        <div className="filter-link-btn">
          <button className="add-btn" onClick={() => props.setOpenModal(true)}> <FontAwesomeIcon icon={faAdd} className="icon" /> {t('_create_country_restriction')} </button>
        </div>
      </div>
      <form onSubmit={handleSubmit(() => onSubmit())}>
        <div className="filter-body">
          <div className="filter-input-item">
            <Controller
              control={control}
              name="client_id"
              render={({ field: { onChange } }) => {
                return (
                  <Select
                    value={selectClient}
                    className="select-dropdown"
                    onChange={(val) => {
                      onChange(val?.value);
                      setSelectClient(val);

                    }}
                    options={props.clients}
                    placeholder={t('_client')}

                  />
                );
              }}
            />
          </div>

          <div className="filter-input-item">
            <Controller
              control={control}
              name="origin_country"
              render={({ field: { onChange } }) => {
                return (
                  <Select
                    value={selectCountry}
                    className="select-dropdown"
                    onChange={(val) => {
                      onChange(val?.value);
                      setSelectCountry(val);

                    }}
                    options={country}
                    placeholder={t('_origin_country')}

                  />
                );
              }}
            />
          </div>
          <DefaultButton text={t('_filter')} type="submit" />
          <DefaultButton text={t('_reset')} red onClick={() => { resetFilter(); }} type="button" />
        </div>
      </form>
    </div >
  );
};

FilterCountryRestriction.displayName = 'FilterCountryRestriction';

export default FilterCountryRestriction;
