
import Select from 'react-select';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import './ComponentsForTypeModal.scss';

import Spinner from 'components/spinner/Spinner';
import { ISelectModalDiagnostic, IValidateDiagnostic } from 'utils/rmi/interface/diagnostic.interface';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

type Props = {
  setOpenModal: (e: boolean) => void;
  boxDetailOptions: ISelectModalDiagnostic[];
  setSelectHandler: (e: ISelectModalDiagnostic) => void;
};


const ComponentsForType: React.FC<Props> = (props) => {

  const { t } = useTranslation();

  const validate = Yup.object().shape({

    component_type: Yup.object().shape({
      label: Yup.string().required(t('_fild_required')),
      value: Yup.string().required(t('_fild_required')),
    })
  });


  const { getValues, handleSubmit, control, formState: { errors }, } = useForm<IValidateDiagnostic>({ resolver: yupResolver(validate) });

  const onSubmit = () => {

    const values = getValues();

    props.setSelectHandler(values.component_type);
    props.setOpenModal(false);
  };

  const customStyles = (hasError) => ({
    control: (styles) => ({
      ...styles,
      ...(hasError ? { borderColor: 'red' } : { border: 'none' }),
    }),
  });


  return (
    <div className="diagnostic-container-modal" >


      {
        !props.boxDetailOptions.length ?
          <div className="spinner-info"> <Spinner />  </div> :
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="title">
              {t('_title_modal_type_component')}
            </div>
            <div className="container ">

              <Controller
                control={control}
                name="component_type"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Select
                    value={value}
                    className="select-dropdown "
                    styles={customStyles(errors.component_type?.label?.type)}
                    onChange={(value) => {
                      onChange(value);

                    }}
                    options={props.boxDetailOptions}
                    placeholder={t('_select')}

                  />
                )}
              />
              <p className="error">{errors.component_type?.label?.type ? errors.component_type?.label.message : ''}</p>
            </div>
            <div className="btn-save">
              <button type="submit"> <FontAwesomeIcon icon={faCheck} /><div className="title-btn-save">{t('_type_select')}</div></button>
            </div>
          </form>
      }

    </div>

  );

};

ComponentsForType.displayName = 'ComponentsForType';

export default ComponentsForType; 