import { faPen, faRemove } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Spinner from 'components/spinner/Spinner';
import { IBrand } from 'interfaces/batteryBrand';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getMarketplaceBaseUrl } from 'utils/marketplace';
import './ItemEshopBatteryBrand.scss';

type Props = {
  data: IBrand[],
  loading: boolean,
  openModalBatteryBrand: (id: number) => void;
  deleteItem: (id: number) => void;
  handleOnChangeIsPartner: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const ItemEshopBatteryBrandView: React.FC<Props> = (props) => {

  const { t } = useTranslation();

  return (
    <div className="item-container-shop-battery-brand">
      {
        props.loading ? <Spinner /> :

          props.data.length ?
            props.data.map((item, index) => {
              return (
                <div className="item-shop" key={index}>

                  <div className="header">

                    <div className="content">
                      <div className="title">{item.name}</div>
                      <div className="reference">{item.erp_brand_id} </div>
                    </div>
                  </div>
                  <div className="body">
                    <div className="img">
                      <img src={`${getMarketplaceBaseUrl()}${item.image}`} />
                    </div>
                    <div className="is-partner-checkbox-container">
                      <label htmlFor={`partner_brand_${item.id}`}>{t('_partner_brand')}</label>
                      <input
                        type="checkbox"
                        id={`partner_brand_${item.id}`}
                        defaultChecked={item.is_partner}
                        value={item.id}
                        onChange={props.handleOnChangeIsPartner}
                      />
                    </div>
                  </div>
                  <div className="footer">
                    <div className="cart">
                      <div className="cart-button">
                        <button className="button-edit" onClick={() => props.openModalBatteryBrand(item.id)}>
                          <FontAwesomeIcon icon={faPen} className="icon-edit" />
                        </button>
                        <button className="button-delete" onClick={() => props.deleteItem(item.id)}>
                          <FontAwesomeIcon icon={faRemove} className="icon-delete" />
                        </button>

                      </div>
                    </div>
                  </div>

                </div>
              );
            }) :
            <div className="no-data">
              {t('_no_data')}
            </div>

      }
    </div>
  );
};

ItemEshopBatteryBrandView.displayName = 'ItemEshopBatteryBrandView';

export default ItemEshopBatteryBrandView;
