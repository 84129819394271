import { ICarPartData } from 'models/car-parts.model';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './CarPartDetails.scss';

type Props = {
  carPart: ICarPartData;
};

const CarPartDetails: React.FC<Props> = (props) => {
  const carPart = props.carPart;
  const { t } = useTranslation();

  return (
    <div className="car-part-details-wrapper">
      <div className="car-part__header">
        <div className="car-part__header__article">
          <span>{t('_reference')}</span>:{` ${carPart.data_supplier_reference_original}`}
        </div>
        <h5 className="car-part__header__title">{`${carPart.data_supplier.name} ${carPart.data_supplier_reference_original}`}</h5>
        <div className="car-part__header__category">{`${carPart.subcategory?.name ? carPart.subcategory?.name : '-'}`}</div>
      </div>
      <div className="car-part__content">
        {carPart.characteristics.map((characteristic, index) => (
          <div className="content-item" key={index}>
            <p className="label">{characteristic.name}: </p>
            <p className="value">{`${characteristic.value} ${characteristic.unit ? characteristic.unit : ''}`}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

CarPartDetails.displayName = 'CarPartDetails';

export default CarPartDetails;
