
import { IClinetInfo, IFinancialBodyRequest, IFinancialResponse } from 'interfaces/financial';
import {  IHttpResponse, serverAxios } from '../../http';

class FinancialHttp {
  private static route = 'financial_client_deadline/list';
  private static export = 'financial_client_deadline/export';
  private static client_info  = 'financial_history/client_info';
  public async getList(param: IFinancialBodyRequest): Promise<IHttpResponse<IFinancialResponse>> {
    return serverAxios.get(FinancialHttp.route, { params: param });
  }

  public async getInfoClient(): Promise<IHttpResponse<IClinetInfo>> {
    return serverAxios.get(FinancialHttp.client_info);
  }
  public async export(param: IFinancialBodyRequest): Promise<IHttpResponse<Blob>> {
    return serverAxios.get(FinancialHttp.export, { params: param , responseType: 'blob' });
  }

}

export default FinancialHttp;
