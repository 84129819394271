import Spinner from 'components/spinner/Spinner';
import React from 'react';
import { HeaderGroup, Row, TableBodyPropGetter, TableBodyProps, TablePropGetter, TableProps } from 'react-table';
import { CartTableItem } from './SupplierCartTable.model';
import './SupplierCartTable.scss';
import TableMoreInformation from '../../../components/tables/TableMoreInformation/TableMoreInformation';

type Props = {
  hiddenColumns?: string[];
  getTableProps: (propGetter?: TablePropGetter<CartTableItem> | undefined) => TableProps;
  headerGroups: HeaderGroup<CartTableItem>[];
  prepareRow: (row: Row<CartTableItem>) => void;
  rows: Row<CartTableItem>[];
  getTableBodyProps: (propGetter?: TableBodyPropGetter<CartTableItem> | undefined) => TableBodyProps;
  loading?: boolean;
};

const SupplierCartTableView: React.FC<Props> = (props: Props) => {

  return (

    <div className="supplier-table-container">
      {props.loading ? (<Spinner class="Spinner" />) : (
        <table className="wrapper" {...props.getTableProps()}>
          <thead className="headings">
            {props.headerGroups.map((headerGroup, index) => {
              return (
                <tr key={headerGroup.headers[index].id} className="supplier-row">
                  {headerGroup.headers.map((column) => {
                    const { key, ...restColumn } = column.getHeaderProps({
                      style: { minWidth: column.minWidth, width: column.width, maxWidth: column.maxWidth },
                    });

                    return (
                      <th key={key} {...restColumn} className="heading">
                        {column.render('Header')}
                      </th>
                    );
                  })}
                </tr>
              );
            })}
          </thead>
          <tbody {...props.getTableBodyProps()} className="body">
            {props.rows.map((row) => {
              props.prepareRow(row);
              const { key, ...restRowProps } = row.getRowProps();

              return (
                <React.Fragment key={key}>
                  <tr {...restRowProps} className="supplier-row">
                    {row.cells.map((cell) => {
                      const { key, ...restCellProps } = cell.getCellProps();

                      return (
                        <td key={key} {...restCellProps} className="cell">
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                  {row.isExpanded ? TableMoreInformation({ row, hiddenColumns: props.hiddenColumns, extraFieldsNumber: 2 }) : null}
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};

SupplierCartTableView.displayName = 'SupplierCartTableView';

export default SupplierCartTableView;
