import React, { useState } from 'react';
import { IMessage } from '../../interfaces/messaging';
import ReactTimeAgo from 'react-time-ago';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import MessageFile from './message-file/MessageFile';

import Spinner from '../spinner/Spinner';
import { notify } from '../../utils/marketplace';
import useHttp from 'utils/hooks/useHttp';
import MessagingHttp from '../../utils/hooks/http/messaging';
import PlatformAdminMessaging from '../../utils/hooks/http/platformAdminMessaging';
import './Message.scss';
import { ReactComponent as CloseImage } from 'assets/images/x.svg';


type Props = {
  message: IMessage;
  deleteMessageFromList: (message: IMessage) => void;
  isPlatformAdmin?: boolean
}

const Message: React.FC<Props> = (props) => {
  const { i18n, t } = useTranslation();
  const { user } = useSelector((state: RootState) => state.userData);
  const [messageIsDeleting, setMessageIsDeleting] = useState<boolean>(false);
  const { messaging, platformAdminMessaging } = useHttp();
  let messagingRequest: MessagingHttp | PlatformAdminMessaging = messaging;

  if (props.isPlatformAdmin) {
    messagingRequest = platformAdminMessaging;
  }

  const handleDeleteMessage = async () => {
    if (messageIsDeleting) {
      return false;
    }

    setMessageIsDeleting(true);

    messagingRequest.deleteMessage(String(props.message.id))
      .then(() => {
        props.deleteMessageFromList(props.message);
      })
      .catch((e) => {
        switch (e.status) {
          case 500:
            notify(t(e.detail));

            break;
          case 404:
            notify(t(e.detail));

            break;
          default:
            console.log(e);
        }
      })
      .finally(() => {
        setMessageIsDeleting(false);
      });
  };

  const isYourMessage = (message: IMessage) => {
    return message?.created_by?.id === Number(user?.id);
  };

  function generateDate(date: string) {
    return new Date(date + ' GMT+0000');
  }

  const isSeen = (): boolean => {
    if (props.message.seen) {
      return true;
    }

    return props.message.created_by?.id === user?.id;
  };

  return (
    <div className={`message-data ${isYourMessage(props.message) ? 'sent' : 'received'}`}>
      <div className="info">

        {props.message?.created_at ? 
          <p className={`date ${isSeen() ? 'seen' : 'unseen'}`}>
            <ReactTimeAgo timeStyle="round-minute" date={generateDate(props.message.created_at)} locale={i18n.language} />
            <span> - {isYourMessage(props.message) ? t('_pronoun_messages') + ` (${props.message?.created_by?.full_name}) : ` : props.message?.created_by?.full_name}</span>
          </p>
          : null}
        {
          isYourMessage(props.message) && (
            <a href="#" onClick={handleDeleteMessage} className="delete-message">
              {
                messageIsDeleting ? (
                  <Spinner class="extra-small"></Spinner>
                ) : (
                  <CloseImage />
                )
              }
            </a>
          )
        }
      </div>

      <div className="message">
        <p className="message-content">{props.message?.message}</p>
        {
          props.message.files?.length ? (
            <div className="files-data">
              <p className="title-message">{t('_attached_files')}</p>
              <div className="files">
                {
                  props.message.files.map((file, fileIndex) => {
                    return (
                      <MessageFile isPlatformAdmin={props.isPlatformAdmin} key={fileIndex} messageFile={file} />
                    );
                  })
                }
              </div>
            </div>

          ) : null
        }
      </div>

    </div>

  );
};

Message.displayName = 'Message';

export default Message;

