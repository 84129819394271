import React, { PropsWithChildren } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './RoundedIcon.scss';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

type Props = {
  icon: IconProp;
  text?: string;
};

const RoundedIcon: React.FC<Props> = (props: PropsWithChildren<Props>) => {
  return (
    <div className="rounded-file-button">
      <FontAwesomeIcon icon={props.icon} className="rounded-file-icon" />
      {props.text ? <p className="rounded-file-text">{props.text}</p> : null}
    </div>
  );
};

RoundedIcon.displayName = 'RoundedIcon';

export default RoundedIcon;
