import React, { useEffect, useMemo, useState } from 'react';
import './TableSectionDetails.scss';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleArrowLeft, faPlus, faRemove } from '@fortawesome/free-solid-svg-icons';
import DefaultTable from 'components/tables/DefaultTable/DefaultTable';
import RadioboxInput from 'components/inputs/RadioboxInput/RadioboxInput';
import { FieldValues, UseFormRegister } from 'react-hook-form';
import { Column } from 'react-table';
import { ISectionType, SectionConfirmed, SectionNOTConfirmed, SectionNOTFound } from '../parts-section/PartsSection';
import { IFileRow } from '../../automotor-order.mode';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { IImportFileNew } from 'interfaces/price';
import { IValueItems } from 'applications/automotor/views/espace-automotor-offer/TariffImport/TariffImport';
import TariffTable from 'applications/automotor/views/espace-automotor-offer/TariffTable/TariffTable';
import CURRENCY from 'utils/constants/currency';
import Modal from 'components/modal/Modal';
import AddReferenceModal from './add-reference-modal/AddReferenceModal';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { useAppDispatch } from 'utils/hooks/redux';
import { setRederTable } from 'store/slices/buttonSubmitProforma.slice';


type Props = {
  onClose: () => void;
  register: UseFormRegister<FieldValues>;
  setReferences?: React.Dispatch<React.SetStateAction<IFileRow[]>>;
  references?: Array<IFileRow>;
  checkboxValues: Array<string>;
  type: ISectionType;
  icon: IconProp;
  sectionTitle: string;
  partsWithReplacements?: IImportFileNew[];
  setReferencesConfirmed?: React.Dispatch<React.SetStateAction<IImportFileNew[]>>;
  typeColor?;
  setOrderSubmitButton?: (e: boolean) => void;
  showOrderSubmitButton?: boolean;
  setLocalStorage?;
  setSubmitCountryRestriction?;
  setSubmitFromChild?: (e: boolean) => void;
  redirect?
};

interface IRef {
  reference: string;
  brand: string;
  file_price: string;
  sell_price: string;
  qte: string;
  price?: number;
  description: string;
  total: {
    totalPrice: string;
    index: number;
  };

  file_type: string;
  filepath: string;
  id: string;

  name: string;
  price_strategy: string;
  zone_commercial: string;
}

type IRowTable = {
  // total: { totalPrice: string, index: number},
  [key: string]: string | number | null | { totalPrice: string; index: number };
};

const TableSectionDetails: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const user = JSON.parse(localStorage.getItem('userData')!);
  const proformaFileData = JSON.parse(localStorage.getItem('proformaFileInProgress')!);
  const [data, setData] = useState<Array<IRowTable>>([]);
  const [newRefData, setNewRefData] = useState<IImportFileNew[]>([]);
  const [dataConfirmedColumns, setDataConfirmedColumns] = useState<Array<IImportFileNew>>([]);
  const [total] = useState<number>(0);
  const [removeReferenceKey, setRemoveReferenceKey] = useState<number | null>(null);
  const [valueItems, setValueItems] = useState<IValueItems>();
  const [loadingContent, setLoadingContent] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const { loadingButtonSubmitProforma, rederTable } = useSelector((state: RootState) => state.buttonSubmitProforma);
  const dispatch = useAppDispatch();

  const referncesNotConfirmedColumns = useMemo(
    (): Column<IRef>[] => [
      {
        Header: `${t('_info_submited')}`,
        columns: [
          {
            Header: 'Référence SOUMISE',
            accessor: 'reference',
          },
          {
            Header: 'Marque',
            accessor: 'brand',
          },
        ],
      },
      {
        Header: `${t('_information')}`,
        columns: [
          {
            Header: 'Prix Soumis',
            accessor: 'file_price',
          },
          {
            Header: 'Prix Vente',
            accessor: 'sell_price',
          },
          {
            Header: 'Qte',
            accessor: 'qte',
          },
          {
            Header: 'Description',
            accessor: 'description',
          },
        ],
      },
      {
        Header: 'Total H.T',
        columns: [
          {
            Header: '',
            accessor: 'total',
            Cell: (cell) => {
              return (
                <div className="section-not-confirmed-cell-action">
                  <div>{cell.cell.value?.totalPrice}</div>
                  <DefaultButton onClick={() => removeReference(cell.cell.value.index)} text="">
                    <FontAwesomeIcon icon={faRemove} className="" />
                  </DefaultButton>
                </div>
              );
            },
          },
        ],
      },
    ],
    [],
  );

  const referncesNotFoundColumns = useMemo(
    (): Column<IRef>[] => [
      {
        Header: `${t('_info_submited')}`,
        columns: [
          {
            Header: `${t('_reference_submited')}`,
            accessor: 'reference',
          },
        ],
      },
      {
        Header: `${t('_information')}`,
        columns: [
          {
            Header: `${t('_price_submited')}`,
            accessor: 'file_price',
          },
          {
            Header: `${t('_current_price')}`,
            accessor: 'price',
          },

          {
            Header: `${t('_qte')}`,
            accessor: 'qte',
          },
        ],
      },
    ],
    [],
  );

  const [columns, setColumns] = useState(referncesNotFoundColumns);

  const removeReference = (index) => {
    if (props.references) {
      props.references[index].isRemovedByUser = true;

      if (props.setReferences) {
        props.setReferences([...props.references]);
      }
    }

    renderTable();
  };

  useEffect(() => {
    renderTable();
  }, [props.references]);


  useEffect(() => {
    if (newRefData?.length) {
      const newData = dataConfirmedColumns.map((item) => {
        const matchingRefData = newRefData.find((i) => item.code_fou === i?.code_fou);

        if (matchingRefData) {
          return { ...item, parts: [...matchingRefData.parts, ...item.parts] };
        }

        return item;
      });

      const isNewRefDataAdded = newRefData.some((item) => newData.some((i) => item.code_fou === i?.code_fou));

      const uniqueData = Array.from(
        new Set(
          [...(isNewRefDataAdded ? [] : newRefData), ...newData].filter((item) => item !== undefined)
        )
      );


      props.setReferencesConfirmed?.(uniqueData);
      setDataConfirmedColumns?.(uniqueData);
      props.setLocalStorage?.(uniqueData);
      props.setSubmitCountryRestriction?.(false);
    }
  }, [newRefData]);

  useEffect(() => {
    if (rederTable) {
      const proformaFile = JSON.parse(localStorage.getItem('proformaFileInProgress') as string);

      setDataConfirmedColumns(proformaFile.partsWithReplacements);
      dispatch(setRederTable(false));
    }


  }, [rederTable]);

  const renderTable = () => {
    switch (props.type) {
      case SectionConfirmed:
        setLoadingContent(true);

        if (props.partsWithReplacements?.length && dataConfirmedColumns.length == 0) {

          setDataConfirmedColumns(props.partsWithReplacements);

        } else {
          setDataConfirmedColumns(dataConfirmedColumns);
        }

        setLoadingContent(false);

        break;
      case SectionNOTConfirmed:
        if (props.references) {
          setLoadingContent(true);
          setData(
            props.references.reduce((rows: Array<IRowTable>, row, currentIndex) => {
              const qte = +(row.data[6] as number);

              if (row.isRemovedByUser) {
                return rows;
              }

              const data = {
                reference: row.data[4],
                brand: 'brand1',
                file_price: row.info?.price_from_file ? `${CURRENCY[user!.currency]}${row.info?.price_from_file}` : (row.data[7] ? `${CURRENCY[user!.currency]}${row.data[7]}` : '-'),
                sell_price: row.info?.current_price ? `${CURRENCY[user!.currency]}${row.info?.current_price}` : '-',
                qte: qte,
                description: 'description1',
                total: {
                  totalPrice: `${CURRENCY[user!.currency]}${row.info?.current_price * qte}`,
                  index: currentIndex,
                },
              };

              rows.push(data);

              return rows;
            }, []),
          );
          setColumns(referncesNotConfirmedColumns);
          setLoadingContent(false);
        }

        break;
      case SectionNOTFound:

        if (props.references) {

          setLoadingContent(true);
          setData(
            props.references.reduce((rows: Array<IRowTable>, row) => {
              const qte = +(row.data[6] as number);

              if (row.isRemovedByUser) {
                return rows;
              }

              const data = {
                reference: row.info?.codfou ? row.info?.codfou + row.info?.reference : row.data[4],
                file_price: row.info?.price_from_file ? `${CURRENCY[user!.currency]}${row.info?.price_from_file}` : (row.data[7] ? `${CURRENCY[user!.currency]}${row.data[7]}` : '-'),
                qte: qte,
                price: row.info?.current_price ? `${CURRENCY[user!.currency]}${row.info?.current_price}` : '-',
              };

              rows.push(data);

              return rows;
            }, []),
          );

          setColumns(referncesNotFoundColumns);
          setLoadingContent(false);
        }

        break;
    }
  };

  const removeRow = (index: number) => {
    setRemoveReferenceKey(index);
  };

  useEffect(() => {
    if (removeReferenceKey !== null) {
      const updatedAdditionalParts = [...JSON.parse(JSON.stringify(proformaFileData.additionalParts || []))];
      const updatedPartsWithReplacements = [...JSON.parse(JSON.stringify(dataConfirmedColumns))];

      updatedAdditionalParts.forEach((item) => {
        item.parts = item.parts.filter((part) => part.index !== removeReferenceKey);
      });

      updatedPartsWithReplacements.forEach((item) => {
        item.parts = item.parts.filter((part) => part.index !== removeReferenceKey);
      });

      const updatedProformaFileData = {
        ...proformaFileData,
        additionalParts: updatedAdditionalParts.filter((item) => item.parts.length !== 0),
        partsWithReplacements: updatedPartsWithReplacements.filter((item) => item.parts.length !== 0),
      };

      if (updatedProformaFileData.partsWithReplacements.length) {
        localStorage.setItem('proformaFileInProgress', JSON.stringify(updatedProformaFileData));
        setDataConfirmedColumns(updatedProformaFileData.partsWithReplacements);

        if (props.setReferencesConfirmed) {
          props.setReferencesConfirmed(updatedProformaFileData.partsWithReplacements);
        }
      }
    }
  }, [removeReferenceKey]);


  useEffect(() => {
    if (valueItems !== undefined) {


      const newData = [...JSON.parse(JSON.stringify(dataConfirmedColumns))];
      const updatedAdditionalParts = [...JSON.parse(JSON.stringify(proformaFileData.additionalParts || []))];

      updatedAdditionalParts.forEach((item) => {
        item.parts.forEach((part) => {
          if (part.index === valueItems.index) {
            part.quantity = valueItems.quantity;
            part.total = Number(parseFloat(String(valueItems.quantity * Number(part.price))).toFixed(2));

            if (part.ind_equivalence) {
              part.ind_equivalence.quantity = valueItems.quantity;
              part.ind_equivalence.total = Number(parseFloat(String(valueItems.quantity * Number(part.ind_equivalence.price))).toFixed(2));
            }
          }
        });
      });

      newData.forEach((items: IImportFileNew) => {
        items.parts.forEach((item) => {
          if (item.index === valueItems.index) {
            item.quantity = valueItems.quantity;
            item.total = Number(parseFloat(String(valueItems.quantity * Number(item.price))).toFixed(2));

            if (item.ind_equivalence) {
              item.ind_equivalence.quantity = valueItems.quantity;
              item.ind_equivalence.total = Number(parseFloat(String(valueItems.quantity * Number(item.ind_equivalence.price))).toFixed(2));
            }

          }
        });
      });

      const updatedProformaFileData = {
        ...proformaFileData,
        additionalParts: updatedAdditionalParts,
        // partsWithReplacements: newData.filter((newItem) => {
        //   const matchingAdditionalPart = updatedAdditionalParts.find((ap) => ap.code_fou === newItem.code_fou);

        //   return !matchingAdditionalPart;
        // })
        partsWithReplacements: newData
      };

      localStorage.setItem('proformaFileInProgress', JSON.stringify(updatedProformaFileData));

      if (newData.length) {
        setDataConfirmedColumns(newData);

        if (props.setReferencesConfirmed) {
          props.setReferencesConfirmed(newData);
        }


      }
    }
  }, [valueItems]);

  const valueChangeRepaced = (index: number) => {
    const obj = [...JSON.parse(JSON.stringify(dataConfirmedColumns))];

    const newData = obj.map((items) => {
      const result_parts = items.parts.map((item) => {
        if (item.index === index) {
          const replace = item.replace;
          const itemDefault = { replace_status: !item.replace_status, index: Number(item.index), quantity: item.quantity };

          delete item.replace_status;
          delete item.index;
          delete item.replace;
          delete item.quantity;
          const itemNew = { ...itemDefault, ...replace };

          return { ...itemNew, ...{ replace: item } };
        }

        return item;
      });

      const objCopy = { ...items };

      objCopy.parts = result_parts;

      return objCopy;
    });

    if (newData.length) {
      setDataConfirmedColumns(newData);

      if (props.setReferencesConfirmed) {
        props.setReferencesConfirmed(newData);
      }
    }
  };

  const changePartInd = (status, index: number) => {
    const obj = [...JSON.parse(JSON.stringify(dataConfirmedColumns))];

    const newData = obj.map((items) => {
      const result_parts = items.parts.map((item) => {
        if (item.index === index) {
          if (status) {
            item.ind_equivalence_select = true;
          } else {
            item.ind_equivalence_select = false;
          }
        }

        return item;
      });

      const objCopy = { ...items };

      objCopy.parts = result_parts;

      return objCopy;
    });

    if (newData.length) {
      setDataConfirmedColumns(newData);

      if (props.setReferencesConfirmed) {
        props.setReferencesConfirmed(newData);
        props.setLocalStorage?.(newData);
      }
    }
  };


  return (
    <div className="table-section-details">
      <div className="section-info">
        <div className="return-btn" onClick={props.onClose}>
          <FontAwesomeIcon icon={faCircleArrowLeft} className="" /> {t('_return')}
        </div>
        <div className="title">
          {/* <div className={`icon-confirmed ${props.typeColor ? validateParts(dataConfirmedColumns) : props.type}`}>
            <FontAwesomeIcon icon={props.icon} className="" />
          </div>
          {t(props.sectionTitle)} */}
        </div>
        {props.type == SectionConfirmed ? (
          <div className="add-ref-btn">
            <DefaultButton
              text={
                <div>
                  <FontAwesomeIcon icon={faPlus} /> {t('_add_reference')}
                </div>
              }
              onClick={() => setOpenModal(true)}
            />
          </div>
        )
          : null}
      </div>
      <div className="automotor-order-table-container">
        {props.type == SectionConfirmed ? (

          <TariffTable
            loadingContent={loadingContent}
            changePartInd={changePartInd}
            data={dataConfirmedColumns}
            setValueItems={setValueItems}
            removeRow={removeRow}
            total={total}
            redirect={props.redirect}
            valueChangeRepaced={valueChangeRepaced}
            noValidateOrderTotal
          />


        ) : (


          <DefaultTable columns={columns} data={data} currentPage={1} totalPage={1} loading={loadingContent} />

        )}
        {
          props.showOrderSubmitButton ? <div className="button-submit">
            <DefaultButton
              //disabled={!validateParts(dataConfirmedColumns).validateProforma}
              isloading={loadingButtonSubmitProforma}
              type="button"
              onClick={() => {
                props.setOrderSubmitButton ? props.setOrderSubmitButton(true) : undefined;
                props.setSubmitFromChild ? props.setSubmitFromChild(true) : undefined;
              }}
              text={t('_submit_my_order')}
            />
          </div> : null
        }

        {!!props.checkboxValues.length && (
          <div className="automotor-order-radiobox-container">
            {props.checkboxValues.map((checkboxValue, index) => (
              <RadioboxInput
                key={index}
                value={checkboxValue}
                {...props.register(props.type, { required: true })}
                label={t(`_${checkboxValue}`)}
              />
            ))}
          </div>
        )}
      </div>
      <Modal
        openModal={openModal}
        shouldCloseOnOverlayClick={false}
        setOpenModal={setOpenModal}
        childrenComponent={
          <AddReferenceModal tableData={dataConfirmedColumns} setNewRefData={setNewRefData} setOpenModal={setOpenModal} proformaFile />
        }
      />
    </div>
  );
};

export default TableSectionDetails;
