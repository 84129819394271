import React from 'react';

import './AutomotorMessagesPage.scss';
import {MessagesContextProvider} from 'context/MessagesContext';
import MessagesAutomotorNavbar
  from 'applications/automotor/AutomotorMessagesPage/messages-automotor-navbar/MessagesAutomotorNavbar';
import DefaultMessages from 'components/messages/AutomotorMessages/DefaultMessages';

const AutomotorMessagesPage: React.FC= () => {

  return (
    <div className="catalog-supplier-layout-container">
      <MessagesAutomotorNavbar title="Discussions "/>
      <MessagesContextProvider>
        <DefaultMessages/>
      </MessagesContextProvider>
    </div>
  );
};

AutomotorMessagesPage.displayName = 'AutomotorMessagesPage';

export default AutomotorMessagesPage;