import React from 'react';
import './ErrorCodesSidebarLayout.scss';
import ErrorCodes from 'pages/CatalogVehicle/TechnicalDocumentation/components/ErrorCodes/ErrorCodes';

const ErrorCodesSidebarLayout:React.FC = () => {

  return (
    <div className="technical-documentation-error-codes">
      <ErrorCodes />
    </div>
  );
};

ErrorCodesSidebarLayout.displayName = 'ErrorCodesSidebarLayout';

export default ErrorCodesSidebarLayout;
