import React, { PropsWithChildren } from 'react';
import RoundedButtonView from './RoundedButton.view';

type Props = {
  text: string | JSX.Element;
  onClick: () => void;
};

const RoundedButton: React.FC<Props> = (props: PropsWithChildren<Props>) => {
  return <RoundedButtonView onClick={props.onClick} text={props.text} />;
};

RoundedButton.displayName = 'RoundedButton';

export default RoundedButton;
