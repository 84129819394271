import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { IVinData } from 'utils/hooks/http/search-by-vin.http';
import useHttp from 'utils/hooks/useHttp';
import VinSearchView, { IVinForm } from './VinSearch.view';
import { IHttpResponse } from 'utils/http';

type Props = {
  setOpenModal?: (e: boolean) => void;
  link?: string;
  catalog?: boolean;
};

const VinSearch: React.FC<Props> = (props) => {
  const http = useHttp();
  const [vinData, setVinData] = useState<IVinData>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [refreshResults, setRefreshResults] = useState(false);

  const {
    getValues,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IVinForm>();

  const onSubmit = () => {
    setIsLoading(true);
    setIsOpen(true);

    const params = {
      ...getValues(),
    };

    if (refreshResults) {
      params.refresh_search = refreshResults;
    }

    http.searchByVin
      .search(params)
      .then((res: IHttpResponse<IVinData>) => {
        setVinData(res.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const viewAllModelsTecalliance = () => {
    setIsLoading(true);
    setIsOpen(true);
    http.searchByVin
      .search({ vin: getValues('vin'), request_tecalliance: true })
      .then((res: IHttpResponse<IVinData>) => {
        setVinData(res.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (refreshResults) {
      onSubmit();
    }
  }, [refreshResults]);

  useEffect(() => {
    if (isOpen === false) {
      setRefreshResults(() => false);
    }
  }, [isOpen]);

  return (
    <VinSearchView
      errors={errors}
      vin={getValues('vin')}
      vinData={vinData}
      onSubmit={onSubmit}
      isLoading={isLoading}
      handleSubmit={handleSubmit}
      register={register}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      setOpenModal={props.setOpenModal}
      link={props.link}
      viewAllModelsTecalliance={viewAllModelsTecalliance}
      catalog={props.catalog}
      setRefreshResults={setRefreshResults}
    />
  );
};

VinSearch.displayName = 'Vinsearch';

export default VinSearch;
