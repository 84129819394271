import CatalogSupplierNavbar from 'components/navbar/catalog-supplier-navbar/CatalogSupplierNavbar';
import { t } from 'i18next';
import Orders from 'pages/Orders/Orders';
import React from 'react';
import { useParams } from 'react-router-dom';
import './CatalogOrders.scss';

type Props = {}

const CatalogOrders: React.FC<Props> = () => {
  const {id} = useParams();

  return (
    <div className="catalog-orders">
      <CatalogSupplierNavbar title={`${t('_orders')} / `} />
      <Orders catalogId={id} />
    </div>
  );
};

export default CatalogOrders;