import { useNavigate } from 'react-router-dom';
import { removeCarInfo, removeCarSelected } from 'store/reducers/carSelectedSlices';
import { login } from 'store/reducers/useSlices';
import { useAppDispatch } from './redux';
import { setLoadingPriceCheckedStatus } from 'store/slices/buttonPriceCheckedStatus.slice';
import { removeCartMecadepot } from 'store/slices/cart-mecadepot.slice';

const useLogOut = (): (() => void) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  return () => {
    localStorage.clear();
    dispatch(login(null));
    dispatch(removeCarInfo());
    dispatch(removeCarSelected());
    dispatch(setLoadingPriceCheckedStatus(false));
    dispatch(removeCartMecadepot());

    navigate('/login');
  };
};

export default useLogOut;
