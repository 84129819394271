import Spinner from 'components/spinner/Spinner';
import React from 'react';

import { useExpanded, useTable } from 'react-table';

import './DefaultTable.scss';
import { useTranslation } from 'react-i18next';
import CURRENCY from 'utils/constants/currency';
import TableMoreInformationDefault from './TableMoreInformationDefault/TableMoreInformationDefault';


type Props = {
  data;
  loading?: boolean;
  handlePageClick?: (data) => Promise<void>;
  totalPage?: number;
  currentPage?: number;
  columns;
  total?: number;
  tableRef?: React.RefObject<HTMLTableElement>;
  loadingContent?: boolean;
  header?: boolean;
  onRowClick?: (row) => void;
  hiddenColumns?
  lastItemRef?
};

const DefaultTable: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const isClickable = props.onRowClick ? true : false;
  const initialState = props.hiddenColumns ? props.hiddenColumns : {};
  const user = JSON.parse(localStorage.getItem('userData')!);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns: props.columns,
    data: props.data,
    initialState
  }, useExpanded);

  /* this piece of code is used to make the last heading in the table have an attribute of rowspan="2" MAYBE needs some refactoring */

  const first = document.querySelector('.row');

  const lastHeading = first?.querySelectorAll('.heading')[2];
  const attr = document.createAttribute('rowspan');

  attr.value = '2';

  lastHeading?.setAttributeNode(attr);


  /*************************************/

  return (
    <div className="table-wrapper-tariff-default">
      {/* <button className="btn-download-excel" onClick={onDownload}></button> */}
      <div className="order-default-table-container">
        {props.data.length ? <table {...getTableProps()} ref={props.tableRef}>
          {props.header && (
            <thead>
              {headerGroups.map((headerGroup, key) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={key} className="row">
                  {headerGroup.headers.map((column, index) => (
                    <th  {...column.getHeaderProps({
                      style: { minWidth: column.minWidth, width: column.width, maxWidth: column.maxWidth },
                    })} key={index} className="heading"
                    >
                      <div className="table-title">{column.render('Header')}</div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
          )}
          {props.loadingContent ? (
            <tbody>
              <tr>
                <td colSpan={Object.keys(rows[0].original).length}>
                  <Spinner class="extra-small" />
                </td>
              </tr>
            </tbody>
          ) : (
            <tbody {...getTableBodyProps} className="body">
              {props.data.length ? (
                rows.map((row, index) => {
                  prepareRow(row);
                  const { key, ...restRowProps } = row.getRowProps();

                  const isLastRow = index === props.data.length - 1;
                  const rowRef = isLastRow ? props.lastItemRef : null;

                  return (
                    <React.Fragment key={key}>
                      <tr
                        key={key}
                        {...restRowProps}
                        className={`row ${isClickable ? 'is-clickable' : ''}`}
                        onClick={() => props.onRowClick && props.onRowClick(row.original)}
                        ref={rowRef}
                      >
                        {row.cells.map((cell) => {
                          const { key, ...restCellProps } = cell.getCellProps();

                          return (
                            <td key={key} {...restCellProps} className="cell">
                              {cell.render('Cell')}
                            </td>
                          );
                        })}
                      </tr>
                      {row.isExpanded ? TableMoreInformationDefault({ row, hiddenColumns: props.hiddenColumns, extraFieldsNumber: 3 }) : null}
                    </React.Fragment>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={20} className="notFond">{`${t('_no_data_found')}`}</td>
                </tr>
              )}
            </tbody>
          )}
        </table>
          : !props.loading && <div className="no-data"> {t('_no_data_found')}</div>}
        {props.loading && <Spinner class={`extra-small default-table-small-spinner ${props.data.length ? 'has-data' : ''} `} />}
        {!!props.data.length && <div className="tfoot">
          {props.total ? (
            <React.Fragment>
              <div className="tfoot-td-1">{t('_total_h_t')}</div>
              <div className="tfoot-td-2">{CURRENCY[user!.currency]}{Number(props.total).toFixed(2)}</div>
            </React.Fragment>
          ) : null}
        </div>}
      </div>
    </div>
  );
};

DefaultTable.defaultProps = {
  header: true,
};

DefaultTable.displayName = 'DefaultTable';

export default DefaultTable;
