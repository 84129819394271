import React from 'react';
import { CellProps } from 'react-table';
import { CartTableItem } from '../../CartTable.model';
import CheckboxCellView from './CheckboxCell.view';

type Props = CellProps<CartTableItem> & {
  register;
  handleOnChange: (e: React.ChangeEvent<HTMLInputElement>) => void;

};

const CheckboxCell: React.FC<Props> = (props: Props) => {

  return <CheckboxCellView {...props} />;
};

CheckboxCell.displayName = 'CheckboxCell';

export default CheckboxCell;
