import React from 'react';
import { Link } from 'react-router-dom';
import { INews } from 'utils/hooks/http/news.http';
import { getBackendAssetsBasePath } from 'utils/marketplace';
import './MainNews.scss';

type Props = {
  data?: INews
};

const MainNewsView: React.FC<Props> = (props) => {

  return (
    <div className="main-news-item">
      <img src={props.data?.image ? `${getBackendAssetsBasePath()}/${props.data?.image}` : '/images/car-part-placeholder-big.png'}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src = '/images/car-part-placeholder-big.png';
        }}
      />
      <div className="news-date">{props.data?.created_at.substring(0, props.data?.created_at.indexOf(' '))}</div>
      <Link className="news-title" to={`/news/${props.data?.id}`}>
        <b>{props.data?.title}</b>
      </Link>
      <div className="news-description">
        {props.data?.content}
      </div>
    </div>
  );
};

MainNewsView.displayName = 'MainNewsView';

export default MainNewsView;
