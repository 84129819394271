import React, {ChangeEvent, useContext, useEffect} from 'react';
import './CheckboxCell.scss';
import {CellProps} from 'react-table';
import {CartTableItem} from '../../CartTable.model';

type Props = CellProps<CartTableItem> & {
  register;
  handleOnChange: (e: ChangeEvent<HTMLInputElement>) => void;
};

const CheckboxCellView: React.FC<Props> = (props: Props) => {
  const checkboxCartForm = props.register('checkbox-cart');

  return (
    <div className="checkbox-cell-wrapper">
      <input type="checkbox"
        {...checkboxCartForm} value={props.row.original.id}
        onChange={e => {
          checkboxCartForm.onChange(e);
          props.handleOnChange(e);
        }}
      />
    </div>
  );
};

CheckboxCellView.displayName = 'CheckboxCellView';

export default CheckboxCellView;
