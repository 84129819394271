
import React, { PropsWithChildren, useCallback, useState } from 'react';
import './NewMessageModal.scss';
import Select from 'react-select';
import { IOption } from '../../../interfaces/select';
import { Controller, useForm } from 'react-hook-form';
import { useDropzone } from 'react-dropzone';
import { notify } from '../../../utils/marketplace';
import useHttp from 'utils/hooks/useHttp';
import fileUpload from '../../../assets/Icons/file-upload.svg';
import DefaultButton from '../../../components/buttons/DefaultButton/DefaultButton';
import { useTranslation } from 'react-i18next';
import UploadedMessageFile from '../../../components/message/uploaded-message-file/UploadedMessageFile';
import IconItem from '../../../components/Item/IconItem/IconItem';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { IIdName } from '../../../models/car-parts.model';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';

type Props = {
  setOpenModal: (e: boolean) => void;
  options: IOption[]
  clientList: IIdName[];
  getMessages: () => void;
};

const NewMessageModal: React.FC<Props> = (props: PropsWithChildren<Props>) => {
  const [messageIsCreating, setMessageIsCreating] = useState<boolean>(false);
  const [files, setFiles] = useState<File[] | []>([]);
  const { platformAdminMessaging } = useHttp();
  const { isMobile, isTablet } = useDeviceDetect();
  const { t } = useTranslation();
  const [selectedClient, setSelectedClient] = useState<IOption | null>(null);

  const {
    getValues,
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<IPlatformAdminMessageCreate>();

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setFiles([...acceptedFiles, ...files]);
  }, [files]);

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    onDrop,
    multiple: true,
  });

  const removeFile = (file: File) => {
    const newFiles = [...files];

    newFiles.splice(newFiles.indexOf(file), 1);
    setFiles(newFiles);
  };

  const handleChangeSupplier = (val) => {
    if (!val) {
      setSelectedClient(null);

      return;
    }

    const client = props.options.find((item) => item.value == val.value);

    if (client) {
      setSelectedClient(client);
    }
  };


  const onSubmit = async () => {
    if (messageIsCreating) {
      return;
    }

    const values = getValues();
    const formData: FormData = new FormData();

    if (files.length) {
      for (let i = 0; i < files.length; i++) {
        formData.append('files[]', files[i]);
      }
    }

    formData.append('message', values.message.trim());
    const client = props.clientList.find((item) => item.id == selectedClient?.value);

    if (client) {
      formData.append('client_id', String(client?.id));
    }

    createMessage(formData);
  };

  const createMessage = async (dataMessage: FormData) => {
    setMessageIsCreating(true);

    try {
      await platformAdminMessaging.createMessage(dataMessage);

      reset({ 'message': '' });
      setFiles([]);
    } catch (e) {
      notify('error');
      console.log('ERROR');
    } finally {
      setMessageIsCreating(false);
      props.setOpenModal(false);
      props.getMessages();

    }
  };


  return (
    <div className="modal-new-message">
      <div className="close" onClick={() => props.setOpenModal(false)}>
        <IconItem icon={faClose} onClick={() => null} />
      </div>
      <div className="modal-dialog">
        <div className="modal-container">
          <div className="modal-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="title">
                <h3>{t('_new_message')}</h3>
              </div>
              <div className="supplier-list">
                <Controller
                  control={control}
                  {...register('client_id', { required: true })}
                  render={({ field: { onChange } }) => (
                    <Select
                      className={`select-supplier ${errors.client_id?.type == 'required' ? 'error' : ''}`}
                      placeholder={t('_find_a_customer')}
                      value={selectedClient}
                      onChange={(val) => {
                        onChange(val);
                        handleChangeSupplier(val);
                      }}
                      options={props.options}
                      isSearchable={!isMobile && !isTablet}
                      isClearable
                    />
                  )}
                />
              </div>
              <div className="reply-container">
                {
                  files.length ? (
                    <div className="files-data">
                      {
                        files.map((file, fileIndex) => {
                          return (
                            <UploadedMessageFile key={fileIndex} messageFile={file} removeFile={() => removeFile(file)} />
                          );
                        })
                      }
                    </div>
                  ) : null
                }
                <div className="reply-content">
                  <div className={`left-wrapper ${errors.message?.type == 'required' ? 'error' : ''}`}>

                    <textarea
                      {...register('message', {
                        required: true,
                      })}
                    />

                  </div>
                  <div className="right-wrapper">
                    <div {...getRootProps({ className: 'content' })}>
                      <input {...getInputProps()} />
                      <img src={fileUpload} alt="file-upload" />
                      <p>{t('_upload')}</p>
                    </div>
                  </div>
                </div>
                <div className="reply-content">
                  <div className="left-wrapper">
                    <div className="submit-wrapper">
                      <DefaultButton isloading={messageIsCreating} text={t('_send')} type="submit" />
                    </div>
                  </div>
                  <div className="right-wrapper">
                  </div>
                </div>
                {
                  fileRejections.length > 0
                    ? <div className="modal-message-dropzone__error">{t('FILE_RESTRICT')}</div>
                    : ''
                }
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

NewMessageModal.displayName = 'NewMessageModal';

type IPlatformAdminMessageCreate = {
  message: string;
  files: File[];
  client_id?: number | null;
};

export default NewMessageModal;
