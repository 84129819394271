import React, { useEffect, useState } from 'react';
import Modal from '../../../../components/modal/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import { setProformaActive } from '../../../../store/slices/proforma.slice';
import UploadProformaModal from './UploadProformaModal/UploadProformaModal';

type Props = {};

const UploadProforma: React.FC<Props> = () => {
  const { proformaActive } = useSelector((state: RootState) => state.proforma);
  const [openModal, setOpenModal] = useState<boolean>(proformaActive);
  const dispatch = useDispatch();

  useEffect(() => {
    setOpenModal(proformaActive);
  }, [proformaActive]);

  useEffect(() => {
    if (!openModal) {
      dispatch(setProformaActive(false));
    }
  }, [openModal]);

  return (
    <div>
      <Modal
        openModal={openModal}
        setOpenModal={setOpenModal}
        childrenComponent={<UploadProformaModal setOpenModal={setOpenModal} />}
      />

    </div>
  );
};

UploadProforma.displayName = 'UploadProforma';

export default UploadProforma;