import { useEffect } from 'react';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { ROLE_SUPPLIER_AMERIGO } from 'utils/constants/roles';

const useForceSupplierAmerigoSelectClient = (): void => {
  const location = useLocation();
  const navigate = useNavigate();
  const userData = useSelector((state: RootState) => state.userData.user);
  const roles = userData?.roles;

  useEffect(() => {
    if (roles && roles.includes(ROLE_SUPPLIER_AMERIGO) && !localStorage.getItem('clientId')) {
      const searchParams = createSearchParams([
        ['page', '1'],
      ]);

      navigate({
        pathname: '/select-client',
        search: `?${searchParams}`,
      });
    }
  }, [location.pathname, userData]);
};

export default useForceSupplierAmerigoSelectClient;
