import { createSlice } from '@reduxjs/toolkit';
import { CartTableItem } from 'applications/retailer/views/Cart/CartTable/CartTable.model';


type ICartState = {
  cart: CartTableItem[];
  quantityToolbarMecadepot: number;
  totalPriceMecadepot: number;

};

const initialState: ICartState = {
  cart: [],
  quantityToolbarMecadepot: 0,
  totalPriceMecadepot: 0,
};

const cartMecadepotSlice = createSlice({
  name: 'cart-mecadepot',
  initialState,
  reducers: {
    setCartMecadepotQuantityToolbar(state, { payload }) {
      state.quantityToolbarMecadepot = payload;
    },
    setCartMecadepot(state, { payload }) {
      state.cart = payload;
    },
    setCartMecadepotTotalPrice(state, { payload }) {
      state.totalPriceMecadepot = payload;
    },
    removeCartMecadepot: (state) => {
      state.cart = [];
      state.quantityToolbarMecadepot = 0;
      state.totalPriceMecadepot = 0;
    },

  }
});

export const { setCartMecadepot, setCartMecadepotQuantityToolbar, setCartMecadepotTotalPrice, removeCartMecadepot } = cartMecadepotSlice.actions;

export default cartMecadepotSlice.reducer;
