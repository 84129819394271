import { ICategiryByItem, IPartsCategory } from '../../models/vehicle-catalogs';
import { createSlice } from '@reduxjs/toolkit';
import {
  fetchCarPartsCategoriesData,
  fetchItemIdsByCategory, fetchRMIVehicleManualsList
} from '../reducers/car-parts-categories/car-parts-categories.actions';
import { IManualsListItemMps } from '../../utils/rmi/interface/manuals.interface';
import { fetchCategoriesVin } from 'store/reducers/catalog/categoriesVin.actions';
import { ICategorieGroup } from 'applications/automotor/components/espace/navbar/Navbar.model';

type CarPartsCategoriesSlice = {
  partsCategories: IPartsCategory[] | ICategorieGroup[];
  loadingCategories: boolean;
  loadingItemMpIds: boolean;
  loadingVehicleManualsList: boolean;
  activePartCategory: IPartsCategory | null;
  itemMpIds: ICategiryByItem[] | null;
  vehicleManualsList: IManualsListItemMps[] | null;
};

const initialState: CarPartsCategoriesSlice = {
  partsCategories: [],
  loadingCategories: false,
  loadingItemMpIds: false,
  activePartCategory: null,
  itemMpIds: null,
  vehicleManualsList: null,
  loadingVehicleManualsList: false,
};

const carPartsCategoriesSlice = createSlice({
  name: 'carPartsCategories',
  initialState,
  reducers: {
    setCategories(state, { payload }) {
      state.partsCategories = payload;
    },
    setIsLoading(state, { payload }) {
      state.loadingCategories = payload;
    },
    setActivePartCategory(state, { payload }) {
      state.activePartCategory = payload;
    },
    setItemMpIds(state, { payload }) {
      state.itemMpIds = payload;
    },
    setLoadingItemMpIds(state, { payload }) {
      state.loadingItemMpIds = payload;
    },
    setVehicleManualsList(state, { payload }) {
      state.vehicleManualsList = payload;
    },
    setLoadingVehicleManualsList(state, { payload }) {
      state.loadingVehicleManualsList = payload;
    },
    resetCarPartsCategoriesSlice(state) {
      state.partsCategories = [];
      state.loadingCategories = false;
      state.loadingItemMpIds = false;
      state.activePartCategory = null;
      state.itemMpIds = null;
      state.vehicleManualsList = null;
      state.loadingVehicleManualsList = false;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCarPartsCategoriesData.pending, (state) => {
      state.loadingCategories = true;
    });
    builder.addCase(fetchCarPartsCategoriesData.fulfilled, (state, { payload }) => {
      state.partsCategories = payload;
      state.loadingCategories = false;
    });

    builder.addCase(fetchCategoriesVin.pending, (state) => {
      state.loadingCategories = true;
    });
    builder.addCase(fetchCategoriesVin.fulfilled, (state, { payload }) => {
      state.partsCategories = payload;
      state.loadingCategories = false;
    });

    builder.addCase(fetchItemIdsByCategory.pending, (state) => {
      state.loadingItemMpIds = true;
    });
    builder.addCase(fetchItemIdsByCategory.fulfilled, (state, { payload }) => {
      state.itemMpIds = payload;
      state.loadingItemMpIds = false;
    });

    builder.addCase(fetchRMIVehicleManualsList.pending, (state) => {
      state.loadingVehicleManualsList = true;
    });
    builder.addCase(fetchRMIVehicleManualsList.fulfilled, (state, { payload }) => {
      state.vehicleManualsList = payload;
      state.loadingVehicleManualsList = false;
    });
  },
});

export const { setCategories, setIsLoading, setActivePartCategory, resetCarPartsCategoriesSlice } = carPartsCategoriesSlice.actions;

export default carPartsCategoriesSlice.reducer;