import React from 'react';
import CheckboxCellTabView from './CheckboxCellTab.view';


type Props = {
  id: number | string;
  register;
  handleOnChange: (e: React.ChangeEvent<HTMLInputElement>, code_groupe?: string | number, code_ssgroupe?: string | number, code_repere?: string | number) => void;
  code_ssgroupe?: string | number;
  code_repere?: string | number;
  inputName?: string;
  code_groupe?: string | number;
};

const CheckboxCellTab: React.FC<Props> = (props: Props) => {

  return <CheckboxCellTabView id={props.id} register={props.register} inputName={props.inputName} code_repere={props.code_repere} handleOnChange={props.handleOnChange} code_groupe={props.code_groupe} code_ssgroupe={props.code_ssgroupe} />;
};

CheckboxCellTab.displayName = 'CheckboxCell';

export default CheckboxCellTab;
