import React, { PropsWithChildren, useEffect, useState } from 'react';
import TextInput from 'components/inputs/TextInput/TextInput';
import Select from 'react-select';
import { IOption, ITestForm, ITestResponce } from 'interfaces/selectClient';
import { Controller, useForm } from 'react-hook-form';
import useHttp from 'utils/hooks/useHttp';
import Spinner from 'components/spinner/Spinner';
import { notify } from 'utils/marketplace';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';

type Props = {
  optionsSupplierMapping: IOption[];
  openModalClientId: number;
  setReference: (string) => void;
  reference?: string;
  setEdisabledBtn
};

const TestTab: React.FC<Props> = (props: PropsWithChildren<Props>) => {
  const { register, handleSubmit, control } = useForm<ITestForm>();
  const [supplierMapping, setSupplierMapping] = useState<IOption | null>(null);
  const { t } = useTranslation();
  const [responseTest, setResponseTest] = useState<ITestResponce>({} as ITestResponce);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorTest, setErrorTest] = useState<boolean>(false);
  const http = useHttp();
  const currency = useSelector((state: RootState) => state.userData.user?.currency);

  function handleChange(event) {
    props.setReference(event.target.value);
  }

  useEffect(() => {
    if (supplierMapping && (supplierMapping.seller_id == '' || supplierMapping.buyer_id == '')) {
      setErrorTest(true);
      props.setEdisabledBtn(true);
    } else {
      setErrorTest(false);
      props.setEdisabledBtn(false);
    }

  }, [supplierMapping]);


  const clickTestBtn = () => {
    setLoading(true);
    http.selectClient
      .supplierTest(props.openModalClientId, {
        reference: String(props.reference),
        buyer_id: String(supplierMapping?.buyer_id),
        seller_id: String(supplierMapping?.seller_id),
      })
      .then((res) => {
        setResponseTest(res.data);
      })
      .catch((res) => {
        if (res.message && res.message[0]) {
          notify(t(`_${res.message[0].toLocaleLowerCase()}`));
        }

        if (res.Mapping && res.Mapping[0]) {
          notify(res.Mapping);
        }

        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="test-tab">
      <form onSubmit={handleSubmit(clickTestBtn)}>
        <div className="tab-row input-row">
          <div className="tab-label">
            <TextInput placeholder="Reference" value={props.reference} {...register('reference')} onChange={handleChange} />
          </div>
          <div className="tab-span btn-test-container">
            <Controller
              control={control}
              name="seller_id"
              render={({ field: { onChange } }) => (
                <Select
                  className="select-dropdown"
                  value={supplierMapping}
                  onChange={(val) => {
                    onChange(val?.value);
                    setSupplierMapping(val);
                  }}
                  options={props.optionsSupplierMapping}
                  placeholder="Supplier"
                  isClearable
                  styles={{
                    menu: (provided) => ({ ...provided, zIndex: 50 }),
                  }}
                />
              )}
            />
            <button className="btn-test" type="submit">
              <div className="container-spiner">{loading ? <Spinner class="extra-small" /> : null}</div> Test
            </button>
          </div>
        </div>
      </form>
      <div className="tab-row">
        <div className="row-info">
          <div className="tab-label">Seller ID:</div>
          <div className="tab-span">{supplierMapping?.buyer_id}</div>
        </div>
        <div className="row-info">
          <div className="tab-label">Reference:</div>
          <div className="tab-span">{props.reference}</div>
        </div>
      </div>
      <div className="tab-row">
        <div className="row-info">
          <div className="tab-label">Buyer ID:</div>
          <div className="tab-span">{supplierMapping?.seller_id}</div>
        </div>
        <div className="row-info">
          <div className="tab-label">Quantity:</div>
          <div className="tab-span">{responseTest.quantity}</div>
        </div>
      </div>
      <div className="tab-row">
        <div className="row-info">
          <div className="tab-label">Price:</div>
          <div className="tab-span">{responseTest.price} {responseTest.price ? currency : ''}</div>
        </div>
      </div>
      {errorTest ?
        <div className="tab-row red">
          {t('_not_connected_to_tecom')}
        </div>
        :
        null
      }


    </div>
  );
};

TestTab.displayName = 'TestTab';

export default TestTab;
