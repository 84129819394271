import React from 'react';
import {ICarPartData} from 'models/car-parts.model';
import {getMarketplaceBaseUrl} from '../../../../utils/marketplace';
import './BarCode.scss';

type Props =  {
  // item: ICarPartData;
  code: string;
  image: string;
  // className?: string;
}

const BarCode:React.FC<Props> = (props): JSX.Element => {
  // const item = props.item;
  // eslint-disable-next-line react/destructuring-assignment
  const {code, image} = props;


  if (!image) {
    return (
      <div className="item-option-barcode"> </div>

    );
  }

  return (
    <div className="item-option-barcode">
      <img src={`${getMarketplaceBaseUrl() ?? ''}/${image}`} alt="barcode" />
      <span>{code}</span>
    </div>
  );
};

BarCode.displayName = 'BarCode';

export default BarCode;
