import React from 'react';
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faNewspaper } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';
import { usePermission } from 'utils/usePermission';
import { ROLE_CLIENT_PLATFORM_ADMIN } from 'utils/constants/roles';


const c = 'automotor-menu-navbar';

const MenuEstore: React.FC = () => {
  const { t } = useTranslation();
  const hasPermission = usePermission([ROLE_CLIENT_PLATFORM_ADMIN]);

  return (
    <NavLink className={`${c}__item--parent__children__item--title ${!hasPermission ? 'disabled' : ''}`} to="/espace-clients">
      <FontAwesomeIcon icon={faNewspaper} />
      <span>{t('_my_eshop')}</span>
    </NavLink>
  );
};

export default MenuEstore;
