import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import './Solutions.scss';
import useHttp from 'utils/hooks/useHttp';
import {getURLParams} from 'utils/marketplace';
import {RootState} from 'store/store';
import {useSelector} from 'react-redux';
import i18n from 'i18next';
import {IEina, IEinaTechnotes} from 'utils/eina/interface/eina.interface';
import SolutionsList from './partial/SolutionsList';
import SolutionsItem from './partial/SolutionsItem';
import {isEmpty} from 'lodash';
import {useOutletContext, useParams, useSearchParams} from 'react-router-dom';
import useEinaLanguage from '../../../../../../utils/hooks/useEinaLanguage';

type Props = {};

interface IUrlParams {
  code?: string;
  keyword?: string;
  source?: string;
  vehicle_identifier?: string;
  manual_id?: string;
}

const Solutions: React.FC<Props> = () => {
  const { t }= useTranslation();

  const [loading, setLoading] = useState<boolean>(false);
  const { searchLinks } = useOutletContext<{ searchLinks: ISearchLinks }>();

  const http = useHttp();
  const urlParams: IUrlParams = getURLParams(location.search);
  const { typeId } = useSelector((state: RootState) => state.tecrmi);
  const [technote, setTechnote] = useState<IEinaTechnotes | null>(null);
  const [technotes, setTechnotes] = useState<IEinaTechnotes[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const { vehicle_id } = useParams<'vehicle_id'>();
  const carSelected = JSON.parse(localStorage.getItem('carSelected')!);
  const paramsLangEina = useEinaLanguage();

  useEffect(() => {
    setTechnote(null);

    const manual_id = searchParams.get('manual_id');

    if (!isEmpty(manual_id)) {
      fetchTechnote(Number(manual_id));
    } else {
      if (isEmpty(technotes) || urlParams.keyword) {
        fetchTechnotes();
      }
    }
  }, [typeId, urlParams.manual_id, urlParams.keyword, i18n.language]);

  const fetchTechnotes = () => {
    setLoading(true);

    const params: IEina = {
      source: String(urlParams.source ?? carSelected.source),
      vehicle_identifier: String(urlParams.vehicle_identifier ?? carSelected.vehicle_identifier),
      // error_code: '',
      lang: paramsLangEina
    };

    if(urlParams.keyword) {
      params.criteria =  String(urlParams.keyword);
    }

    http.eina.fetchVersionTechnotes(
      Number(vehicle_id),
      params
    ).then((res) => {
      setTechnotes(res.data);
    })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  };

  const fetchTechnote = (id: number) => {
    setLoading(true);

    http.eina.fetchTechnoteId(id).then((res) => {
      setTechnote(res.data[0]);
    }).finally(() => setLoading(false));
  };

  const handleFetchTechnote = (id: number) => {
    searchParams.set('manual_id', String(id));
    setSearchParams(searchParams);
  };

  return (
    <div className="solutions-container">
      {technote ?
        <SolutionsItem loading={loading} technote={technote} solutionListLink={searchLinks.keyword} queryParams={searchLinks.keywordQueryParams} />
        : <SolutionsList loading={loading} technotes={technotes} handleFetchTechnote={handleFetchTechnote} />
      }


    </div>
  );
};

Solutions.displayName = 'Solutions';

export default Solutions;

export interface ISearchLinks {
  code: string;
  keyword: string;
  keywordQueryParams?: object
}