import React, { PropsWithChildren } from 'react';
import ToastifyNotificationView from './ToastifyNotification.view';
import 'react-toastify/dist/ReactToastify.css';
import { toast, TypeOptions, ToastOptions } from 'react-toastify';
import { faWarning, faCircleCheck, faCircleInfo, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import './ToastifyNotification.scss';

type Props = {
  title?: string;
  message?: string;
  type?: TypeOptions | string | undefined;
  toastProps?: ToastOptions | undefined;
};

const ToastifyNotification = (props: PropsWithChildren<Props>): void => {
  const type = props.type ?? toast.TYPE.DEFAULT;

  const defaultOptions = {
    position: toast.POSITION.TOP_LEFT,
    autoClose: 5000,
    closeButton: false,
    rtl: false,
    hideProgressBar: true,
    pauseOnHover: false,
    newestOnTop: true,
    icon: false,
    draggable: false,
  };

  // const fadeInOut = cssTransition({
  //   enter: 'toastifyFadeIn',
  //   exit: 'toastifyFadeOut',
  //   appendPosition: false,
  //   collapse: true,
  //   collapseDuration: 600,
  // });

  const options = { ...defaultOptions, ...props.toastProps };

  switch (type) {
    case 'success':
      toast.success(<ToastifyNotificationView icon={faCircleCheck} title={props.title} message={props.message} />, options);

      break;
    case 'warning':
      toast.warning(<ToastifyNotificationView icon={faWarning} title={props.title} message={props.message} />, options);

      break;
    case 'info':
      toast.info(<ToastifyNotificationView icon={faCircleInfo} title={props.title} message={props.message} />, options);

      break;
    case 'none':
      break;
    case 'error':
      toast.error(<ToastifyNotificationView icon={faTriangleExclamation} title={props.title} message={props.message} />, options);

      break;
    case 'default':
    default:
      toast(<ToastifyNotificationView icon={faWarning} title={props.title} message={props.message} />, options);

      break;
  }

  // return <ToastContainer limit={maxLimit} theme={theme} transition={fadeInOut} />;
};

ToastifyNotification.displayName = 'ToastifyNotification';

export default ToastifyNotification;
