import React, { useCallback, useEffect, useRef, useState } from 'react';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import { useTranslation } from 'react-i18next';
import { FileWithPath, useDropzone } from 'react-dropzone';
import { faFileExcel } from '@fortawesome/free-regular-svg-icons';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import './UploadTariffModal.scss';
import { IFileTypes, IMappingList, ISellers } from 'utils/hooks/http/price-list.http';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import useHttp from 'utils/hooks/useHttp';
import { notify } from 'utils/marketplace';
import { useSearchParams } from 'react-router-dom';
import { useDeviceDetect } from '../../../../../utils/hooks/deviceDetect';
import { IOption } from 'interfaces/select';

type Props = {
  setOpenModal: (openModal: boolean) => void
  fileTypes?: IFileTypes[]
  manufacturers?: IMappingList[]
  suppliers?: IMappingList[]
  clients?: ISellers[]
  countries?: IOption[]
  commercialZones?: IOption[]
  getTariffs: (page) => void
}


const styles = {
  multiValue: (base, state) => {
    return state.data.isFixed ? { ...base, backgroundColor: '#D5E2FC' } : base;
  },
  multiValueLabel: (base, state) => {
    return state.data.isFixed
      ? { ...base, fontWeight: 'bold', color: '#27509f', paddingRight: 6 }
      : base;
  },
  multiValueRemove: (base, state) => {
    return state.data.isFixed ? { ...base, display: 'none' } : base;
  },
};

const UploadTariffModal: React.FC<Props> = (props: Props) => {
  const http = useHttp();
  const { t } = useTranslation();
  const uploadFileRef = useRef<HTMLInputElement>(null);
  const { user } = useSelector((state: RootState) => state.userData);

  const activeClient = props.clients && props.clients?.filter(client => client.value === user?.client_id).map(item => {
    return {
      value: String(item.value),
      label: item.label,
      isFixed: true
    };
  });

  const [invalidFile, setInvalidFile] = useState(false);
  const [file, setFile] = useState<FileWithPath | null>(null);
  const [selectAll, setSelectAll] = useState(false);
  const [loadingTariff, setLoadingTariff] = useState(false);
  const [clientValue, setClientValue] = useState<IOption[] | null>(activeClient || []);
  const { control, watch, register, getValues, handleSubmit, formState: { errors } } = useForm();
  const [searchParams] = useSearchParams();
  const { isMobile, isTablet } = useDeviceDetect();

  const onClientsChange = (
    newValue,
    actionMeta
  ) => {

    switch (actionMeta.action) {
      case 'remove-value':
      case 'pop-value':
        if (actionMeta.removedValue.isFixed) {
          return;
        }

        break;
      case 'clear':
        newValue = clientValue && clientValue.filter((v) => v.isFixed);
        setSelectAll(false);

        break;
    }

    setClientValue(newValue);
  };

  const onDrop = useCallback((acceptedFiles) => {
    setFile(acceptedFiles[0]);
    setInvalidFile(false);
  }, [file]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    accept: {
      'text/csv': ['.xls', '.xlsx'],
      'application/vnd.ms-excel': ['.xls', '.xlsx'],
    },
  });

  function getTypesOptions() {
    if (props.fileTypes) {
      return props.fileTypes.map(type => {
        return {
          label: t(`_${type.translation.toLocaleLowerCase()}`),
          value: type.id
        };
      });
    }
  }

  function getManAndSupOptions(type) {
    if (props[type]) {
      return props[type].map(item => {
        return {
          label: `${item.name} (${item.code_fou})`,
          value: item.code_fou
        };
      });
    }
  }

  function getClientOption() {
    if (props.commercialZones?.length && watch().form_type === '3') {
      return props.commercialZones;
    }

    if (props.countries?.length && watch().form_type === '2') {
      return props.countries;
    }

    if (props.clients) {
      return props.clients.map(client => {
        return {
          value: String(client.value),
          label: client.label,
          isFixed: client.value === user?.client_id
        };
      });
    }
  }

  function addTariff() {
    if (!file) {
      setInvalidFile(true);

      return;
    } else {
      setInvalidFile(false);
    }

    setLoadingTariff(true);
    const formData = new FormData();

    formData.append('file', file!);
    formData.append('file_type_id', getValues().type?.value);
    watch().type.value === 1 && formData.append('code_fou', getValues().supplier?.value);
    watch().type.value === 2 && formData.append('code_fou', getValues().manufacturer?.value);

    if (clientValue?.length) {
      if (watch().form_type === '1') {
        clientValue.forEach((item) => {
          formData.append('clients[]', String(item.value));
        });
      }

      if (watch().form_type === '2') {
        clientValue.forEach((item) => {
          props.clients?.forEach((client) => {
            if (item.label === client.country) {
              formData.append('clients[]', String(client.value));
            }
          });
        });
      }

      if (watch().form_type === '3') {
        clientValue.forEach((item) => {
          props.clients?.forEach((client) => {
            if (item.value === client.zone_commercial) {
              formData.append('clients[]', String(client.value));
            }
          });
        });
      }
    }

    formData.append('message', getValues().message);
    formData.append('is_special_price', getValues().special_price);
    http.priceListHttp.uploadTariff(formData).then(res => {
      http.priceListHttp.loadFile(res.data[0].file.id, res.data[0].file.zone_commercial).then(response => {
        notify(response.data.message, 'success');
        http.priceListHttp.updateTariff({ status_code: response.data.code, message: response.data.message }, res.data[0].file.id).then(() => {
          props.setOpenModal(false);
          props.getTariffs(+searchParams.get('page')! || 1);
        }).finally(() => setLoadingTariff(false));
      }).catch(response => {
        if (response.response?.data) {
          notify(response.response.data.message);
          http.priceListHttp.updateTariff({ status_code: response.response.data.code, message: response.response.data.message }, res.data[0].file.id).then(() => {
            props.setOpenModal(false);
            props.getTariffs(+searchParams.get('page')! || 1);
          }).finally(() => setLoadingTariff(false));
        } else {
          notify('Error mapping file!');
          props.setOpenModal(false);
          props.getTariffs(+searchParams.get('page')! || 1);
          setLoadingTariff(false);
        }
      });
    });
  }

  function handleSelectAll() {
    setSelectAll(!selectAll);
  }

  useEffect(() => {
    if (selectAll) {
      setClientValue(getClientOption() || []);
    } else {
      setClientValue(activeClient || []);
    }
  }, [selectAll]);


  function handleRadioCheck() {
    if (watch().form_type === '1') {
      setClientValue(activeClient || []);

      return;
    }

    setClientValue(null);
  }

  return (
    <form onSubmit={handleSubmit(addTariff)} className="upload-tariff-modal">
      <div className="modal-header">
        <div className="modal-title">{t('_upload_tariff')}</div>
        <div className="close-modal" onClick={() => props.setOpenModal(false)}><FontAwesomeIcon icon={faClose} /></div>
      </div>
      <div className="mobile-scroll">
        <div className="modal-body">
          <div className={`tariff-file-upload-container ${invalidFile && 'invalid'}`}>
            <i className="fas fa-folder-open tariff-folder-upload" />
            <div {...getRootProps()} className="tariff-title-upload ">
              {t('_drag_drop_here')}
              <br />
              <strong className="browse-files">{t('_csv_browse')}</strong>
            </div>
            <input
              {...getInputProps()}
              accept="text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              type="file"
              style={{ display: 'none' }}
            />
          </div>
          {
            file &&
            <div className="tariff-details">
              <div className="uploaded-file">
                <FontAwesomeIcon className="file-icon" icon={faFileExcel} />
                <div className="file-data">
                  <div className="file-name">{file.path}</div>
                  <FontAwesomeIcon className="remove-file" icon={faClose} onClick={() => setFile(null)} />
                </div>
              </div>
              <div className="tariff-setting-row">
                <div className="row-label">{t('_type')}<span>*</span></div>
                <div className="tariff-dropdown-item">
                  <Controller
                    control={control}
                    name="type"
                    rules={{ required: true }}
                    render={({ field: { onChange } }) => (
                      <Select
                        className={`select-dropdown ${errors.type && 'required'}`}
                        onChange={(val) => {
                          onChange(val);
                        }}
                        options={getTypesOptions()}
                        isSearchable={!isMobile && !isTablet}
                        placeholder={t('_type')}
                        styles={{
                          menu: provided => ({ ...provided, zIndex: 50 })
                        }}
                      />
                    )}
                  />
                </div>
              </div>
              {watch('type')?.value === 1 && <div className="tariff-setting-row">
                <div className="row-label">{t('_supplier')}<span>*</span></div>
                <div className="tariff-dropdown-item">
                  <Controller
                    control={control}
                    name="supplier"
                    rules={{ required: true }}
                    render={({ field: { onChange } }) => (
                      <Select
                        className={`select-dropdown ${errors.supplier && 'required'}`}
                        onChange={(val) => {
                          onChange(val);
                        }}
                        options={getManAndSupOptions('suppliers')}
                        isSearchable={!isMobile && !isTablet}
                        placeholder={t('_supplier')}
                        styles={{
                          menu: provided => ({ ...provided, zIndex: 50 })
                        }}
                      />
                    )}
                  />
                </div>
              </div>
              }
              {watch('type')?.value === 2 &&
                <div className="tariff-setting-row">
                  <div className="row-label">{t('_manufacturer')}<span>*</span></div>
                  <div className="tariff-dropdown-item">
                    <Controller
                      control={control}
                      name="manufacturer"
                      rules={{ required: true }}
                      render={({ field: { onChange } }) => (
                        <Select
                          className={`select-dropdown ${errors.manufacturer && 'required'}`}
                          onChange={(val) => {
                            onChange(val);
                          }}
                          options={getManAndSupOptions('manufacturers')}
                          isSearchable={!isMobile && !isTablet}
                          placeholder={t('_manufacturer')}
                          styles={{
                            menu: provided => ({ ...provided, zIndex: 50 })
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
              }
              <div className="tariff-setting-row tariff-clients">
                <div className="row-label">{t('_clients')}<span>*</span></div>
                <div className="tariff-dropdown-item">
                  <div className="tariff-client-container">
                    <div className="tariff-client-radio">
                      <div className="tariff-client-radio-btn">
                        <input type="radio" {...register('form_type', { onChange: handleRadioCheck })} value="1" defaultChecked />
                      </div>
                      <div className="tariff-client-radio-label">{t('_by_client')}</div>
                    </div>
                    <div className="tariff-client-radio">
                      <div className="tariff-client-radio-btn">
                        <input type="radio" {...register('form_type', { onChange: handleRadioCheck })} value="2" />
                      </div>
                      <div className="tariff-client-radio-label">{t('_by_country')}</div>
                    </div>
                    <div className="tariff-client-radio">
                      <div className="tariff-client-radio-btn">
                        <input type="radio" {...register('form_type', { onChange: handleRadioCheck })} value="3" />
                      </div>
                      <div className="tariff-client-radio-label">{t('_by_zone_commercial')}</div>
                    </div>
                  </div>
                  <div className="tariff-select-container clients">
                    <Select
                      className="select-dropdown"
                      value={clientValue}
                      styles={styles}
                      onChange={onClientsChange}
                      options={getClientOption()}
                      isSearchable={!isMobile && !isTablet}
                      placeholder={watch('form_type') === '1' ? t('_clients') : watch('form_type') === '2' ? t('_clients_country') : t('_zone_commercial')}
                      isClearable={clientValue?.some((v) => !v.isFixed)}
                      isMulti
                      menuPlacement="auto"
                    />
                    <div className="select-all-container">
                      <input type="checkbox" checked={selectAll} onChange={handleSelectAll} />
                      <span>{t('_select_all')}</span>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="tariff-setting-row">
                <div className="container">
                  <label>{t('_select_by')}</label>
                  <div className="input-item">
                    <Controller
                      control={control}
                      name="type_form"
                      render={({ field: { onChange, value } }) => {
                        return (
                          <Select
                            value={value}
                            className="select-dropdown"
                            onChange={(val) => {
                              onChange(val);
                              setValue('numcli', null);
                            }}
                            options={[
                              {
                                value: '1',
                                label: String(t('_client')),
                              },
                              {
                                value: '2',
                                label: String(t('_country')),
                              },
                              {
                                value: '3',
                                label: String(t('_zone_commercial')),
                              },
                            ]}
                            isSearchable={!isMobile}
                          />
                        );
                      }}
                    />
                  </div>
                </div>
                <div className={`container ${watch()?.type_form?.value === '2' ? '' : 'hide'}`}>
                  <label>{t('_clients_country')}</label>
                  <div className="input-item">
                    <Controller
                      control={control}
                      name="numcli"
                      render={({ field: { onChange, value } }) => {
                        return (
                          <Select
                            value={value}
                            className="select-dropdown"
                            onChange={(val) => {
                              onChange(val);
                            }}
                            options={props.countries}
                            placeholder={t('_clients_country')}
                            isSearchable={!isMobile}
                            isMulti
                          />
                        );
                      }}
                    />
                  </div>
                </div>

                <div className={`container ${watch()?.type_form?.value === '1' ? '' : 'hide'}`}>
                  <label>{t('_client')}</label>
                  <div className="input-item">
                    <Controller
                      control={control}
                      name="numcli"
                      render={({ field: { onChange, value } }) => {
                        return (
                          <Select
                            value={value}
                            className="select-dropdown"
                            onChange={(val) => {
                              onChange(val);
                            }}
                            options={props.clients}
                            placeholder={t('_client')}
                            isSearchable={!isMobile}
                            isMulti
                          />
                        );
                      }}
                    />
                  </div>
                </div>
                <div className={`container ${watch()?.type_form?.value === '3' ? '' : 'hide'}`}>
                  <label>{t('_zone_commercial')}</label>
                  <div className="input-item">
                    <Controller
                      control={control}
                      name="numcli"
                      render={({ field: { onChange, value } }) => {
                        return (
                          <Select
                            value={value}
                            className="select-dropdown"
                            onChange={(val) => {
                              onChange(val);
                            }}
                            options={props.commercialZones}
                            placeholder={t('_zone_commercial')}
                            isSearchable={!isMobile}
                            isMulti
                          />
                        );
                      }}
                    />
                  </div>
                </div>
              </div> */}
              <div className="special-price">
                <span>{t('_special_price')}</span>
                <input type="checkbox" {...register('special_price')} />
              </div>
            </div>
          }
        </div>

        <div className="tariff-message ">
          <textarea className={`tariff-textarea ${errors.message && 'error'}`} {...register('message', { required: true })} rows={4} placeholder="Message" />
        </div>
      </div>
      <div className="modal-footer">
        <div className="upload-tariff-btn">
          <DefaultButton isloading={loadingTariff} text={t('_upload_tariff')} />
        </div>
      </div>
    </form>
  );
};

export default UploadTariffModal;