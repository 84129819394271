import React from 'react';
import VinPlateCardView from './VinPlateCard.view';
import VinPlateCardMobileView from './Mobile/VinPlateCardMobile.view';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';

type Props = {
  setOpenModal?: (e: boolean) => void;
  link?: string;
};

const VinPlateCard: React.FC<Props> = (props) => {
  const { isMobile, isTablet } = useDeviceDetect();


  return (
    isMobile || isTablet ?
      <VinPlateCardMobileView setOpenModal={props.setOpenModal} link={props.link} />
      :
      <VinPlateCardView setOpenModal={props.setOpenModal} link={props.link} />
  );
};

VinPlateCard.displayName = 'VinPlateCard';

export default VinPlateCard;
