import React, { ReactElement, useState } from 'react';
import './TabStyle.scss';
import { FieldValues, UseFormResetField } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import TabTitle from './TabTitle';

type Props = {
  children: Array<ReactElement | null>;
  resetField?: UseFormResetField<FieldValues>;
};

const Tabs: React.FC<Props> = (props) => {
  const location = useLocation();

  const [selectedTabIndex, setSelectedIndex] = useState(0);

  return (
    <div className="select-client-tabs-container">
      <div className="tabs">
        {props.children.map(
          (item, index) =>
            item && (

              < TabTitle key={index} title={item.props.title} index={index} link={location.pathname} selectedTab={item.props.activeTab} setSelectedIndex={setSelectedIndex} />
            )
        )}
      </div>
      {props.children[selectedTabIndex]}
    </div>
  );
};

Tabs.displayName = 'Tabs';

export default Tabs;
