import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import UPLOAD_IMG from 'assets/images/upload.svg';
import './UploadFile.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRemove } from '@fortawesome/free-solid-svg-icons';
import { getMarketplaceBaseUrl } from 'utils/marketplace';

type Props = {
  setFile?: (file: File[]) => void;
  image?: string;
  errors?;
  clearErrors?;
  setValue?;
};
const UploadImage: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const [paths, setPaths] = useState([]);


  const onDrop = useCallback(acceptedFiles => {
    setPaths(acceptedFiles.map(file => URL.createObjectURL(file)));

    if (props.setFile) {
      props.setFile(acceptedFiles);
    }

    if (props.setValue) {
      props.setValue('image', acceptedFiles[0]);
    }


  }, [setPaths]);


  const { getRootProps, getInputProps, } = useDropzone({
    multiple: false,
    onDrop,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpg', '.jpeg'],
    },

  });

  const remove = file => {
    const newFiles = [...paths];     // make a var for the new array

    if (props.setValue) {
      props.setValue('image', props.image ? props.image : '');
    }


    newFiles.splice(file, 1);        // remove the file from the array 

    if (props.setFile) {
      props.setFile([]);
    }


    setPaths(newFiles);              // update the state
  };


  return (
    <div className="upload-file-container">

      {paths.length ?
        paths.map((path, index) => {
          return (
            <div key={index} className="file-preview">
              <img key={path} src={path} width="100px" />
              <button onClick={() => remove(path)} className="remove-file"><FontAwesomeIcon icon={faRemove} /></button>
            </div>
          );
        })
        : props.image ?
          <img src={getMarketplaceBaseUrl() + props.image} width="100px" />
          :
          <div className="icon">  <img src={UPLOAD_IMG} alt="" />   </div>
      }
      <div className="button">
        <div {...getRootProps({ className: 'content' })}>
          <input {...getInputProps()} />
          <DefaultButton type="button" text={t('_select_image')} />
        </div>
      </div>
      <p className="error-message">{props.errors && props.errors.image?.type == 'required' ? props.errors.image?.message : ''}</p>
    </div>
  );
};

UploadImage.displayName = 'UploadImage';

export default UploadImage; 