import { useEffect } from 'react';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { ROLE_COMMERCIAL_DIRECTOR, ROLE_SELLER } from 'utils/constants/roles';
import { usePermission } from 'utils/usePermission';
import { selectClientsRoutes } from '../public-routes';

const useForceSellerSelectClient = (): void => {
  const location = useLocation();
  const navigate = useNavigate();
  const userData = useSelector((state: RootState) => state.userData.user);
  const roles = userData?.roles;
  const isCommercial = usePermission([ROLE_COMMERCIAL_DIRECTOR]);


  useEffect(() => {
    if (roles && (roles.includes(ROLE_SELLER) || roles.includes(ROLE_COMMERCIAL_DIRECTOR)) && !localStorage.getItem('clientId') && !selectClientsRoutes.includes(location.pathname.split('/')?.[1])) {

      const searchParams = createSearchParams([
        ['page', '1'],
      ]);

      if (isCommercial) {
        navigate({
          pathname: '/waiting-confirmation'
        });

      } else {
        navigate({
          pathname: '/select-client',
          search: `?${searchParams}`,
        });
      }
    }
  }, [location.pathname, userData]);
};

export default useForceSellerSelectClient;
