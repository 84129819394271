import { createAsyncThunk } from '@reduxjs/toolkit';
import useHttp from 'utils/hooks/useHttp';

export const fetchPendingInvitation = createAsyncThunk('pendingInvitation/fetchPendingInvitation', async (params: string) => {
  try {
    const http = useHttp();

    return http.clients.getClientsInvitationById(params);

  
  } catch (e) {
    return 'fetch seller info error';
  }
});