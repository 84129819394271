import { RootState } from 'store/store';
import { createDeepEqualSelector } from './index';

export const ebooksSelector = (state: RootState): RootState['ebooks'] => state.ebooks;

export const getEbooks = createDeepEqualSelector(ebooksSelector, ({ebooks}) => ebooks);

export const getEbookSidebarShow = createDeepEqualSelector(ebooksSelector, (ebooks) => ebooks.showEbookSidebar);

export const getIsLoadingEbooks = createDeepEqualSelector(ebooksSelector, (ebooks) => ebooks.isLoading);
