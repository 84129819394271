import React from 'react';

import IconButtonView from './IconButton.view';

import './IconButton.scss';

type Props = {
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  text?: string;
  disabled?: boolean;
  className?: string;
  icon: JSX.Element;
};

const IconButton: React.FC<Props> = (props: Props) => {
  const classesContainer = ['button-content'];

  if (props.className) {
    classesContainer.push(props.className);
  }

  return (
    <IconButtonView disabled={props.disabled} icon={props.icon} className={classesContainer.join(' ')} text={props.text} onClick={props.onClick}></IconButtonView>
  );
};

IconButton.displayName = 'IconButton';

export default IconButton;
