import React from 'react';

import { IGraphicsLinkedTechnicalManuals, IGraphicsManuals } from 'utils/rmi/interface/graphics.interface';

import Spinner from 'components/spinner/Spinner';
import { NavLink, useParams, useSearchParams } from 'react-router-dom';
import { RELIABLE_TAB_NAMES } from 'utils/rmi/reliableTabs';
import { useTranslation } from 'react-i18next';
import useReliableCatalogCarQueryParams from 'utils/hooks/useReliableCatalogCarQueryParams';


type Props = {
  
  linkedTechnicalManuals: IGraphicsLinkedTechnicalManuals[] | undefined;
  setOpenModalGrafics : (e:boolean)=> void
};

const TechnicalManuals: React.FC<Props> = (props) => {
  const [searchParams] = useSearchParams();
  const { vehicle_id } = useParams();
  const {t} = useTranslation();
  const reliableCatalogCarQueryParams = useReliableCatalogCarQueryParams();

  const manualLink = (manualId: number) => {
    let paramsObject: { [key: string]: string } = {};

    paramsObject = {...paramsObject, ...reliableCatalogCarQueryParams};
    paramsObject['manual_id'] = String(manualId);
    const link  =  `/reliable/rmi/${vehicle_id}/technical-documentation/${RELIABLE_TAB_NAMES.MANUALS}?${new URLSearchParams(paramsObject).toString()}`;

    return link;
  };

  return (
    
    <div>
      {
        !props.linkedTechnicalManuals ? <Spinner class="extra-small"/> : 
          props.linkedTechnicalManuals.length ?
            <ul>
              {
                props.linkedTechnicalManuals.map((value) => {
                  return (
                    value.Manuals.map((item: IGraphicsManuals, index) => {
                      return   <div key={index}>
                        <li key={index} >
                          <p> {`${value.ItemMpText}`}</p>
                          <NavLink onClick={() => { props.setOpenModalGrafics(false); }} to={manualLink(item.ManualId)}>
                            {`${item.QualColText}`}
                          </NavLink>
                        
                        </li>
                      </div>;
                    })
                
                  );
                })
              }
            </ul>
            : 
            <div className="no-data"> 
              {t('_no_data')}
            </div>
      }

    </div>
 
  );

};

TechnicalManuals.displayName = 'TechnicalManuals';

export default TechnicalManuals;