import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ReactComponent as PartsNotFoundImage } from 'assets/images/parts-not-found.svg';
import Pagination from 'components/pagination/Pagination';
import Spinner from 'components/spinner/Spinner';
import { useTranslation } from 'react-i18next';
import useHttp from 'utils/hooks/useHttp';
import { useSearchParams, useParams } from 'react-router-dom';
import UniversalPartsNavbar from 'components/navbar/universal-parts-navbar/UniversalPartsNavbar';
import { IBodyResponseConsumable, IConsumableCatalogFilter } from 'interfaces/consumable';
import CatalogConsumableFilter from '../CatalogConsumableFilter/CatalogConsumableFilter';
import ProductsViewMode from 'components/ProductsViewMode/ProductsViewMode';
import { DEFAULT_VIEW } from 'components/ProductsViewMode/ProductsViewMode.constants';
import ProductsViewModeChanger from 'components/ProductsViewModeChanger/ProductsViewModeChanger';
import { CATALOG_CONSUMABLE } from 'components/ProductsViewModeChanger/products-view-mode-changer-utils';
import { ICategory } from 'views/Sidebar/Sidebar.model';
import List from 'components/List/List';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleArrowLeft } from '@fortawesome/free-solid-svg-icons';
import './CatalogsConsumablesPageMobile.scss';
import { sortByOptions } from 'components/SortBy/SortBy.map';
import SortBy, { SortByParams } from 'components/SortBy/SortBy';

const CatalogsConsumablesPageMobile: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const { vehicle_id } = useParams<'vehicle_id'>();
  const user = JSON.parse(localStorage.getItem('userData')!);
  const http = useHttp();

  const [loading, setLoading] = useState(true);
  const [refData, setRefData] = useState<IBodyResponseConsumable>();
  const [filterData, setFilterData] = useState<IConsumableCatalogFilter>();

  const [consumableCategories, setConsumableCategories] = useState<ICategory[]>([]);
  const [loadingCategories, setLoadingCategories] = useState(true);
  const [activeCategory, setActiveCategory] = useState<{ id: number }>({ id: searchParams.get('category_id') ? Number(searchParams.get('category_id')) : consumableCategories[0]?.id });
  const [isCategorySelected, setIsCategorySelected] = useState(false);

  const [openFilter, setOpenFilter] = useState(false);
  const [page, setPage] = useState(Number(searchParams.get('page')) ? Number(searchParams.get('page')) : 1);
  const [viewMode, setViewMode] = useState(user?.user_settings.products_view_mode || DEFAULT_VIEW);
  const cancelToken = axios.CancelToken.source();

  function getConsumableData(data) {
    setLoading(true);

    const sortBySelectedOption = sortByOptions.filter((item) => String(item.value) == String(searchParams.get('sort')))[0] ?? [];

    const sortParams: SortByParams = {
      sort_by_column: String(searchParams.get('sort') ?? ''),
      sort_by_asc: String(searchParams.get('asc') ?? sortBySelectedOption?.default_sort_asc ?? ''),
    };

    const params = {
      ...data,
      ...sortParams,
      source: searchParams.get('source'),
      vehicle_identifier: searchParams.get('vehicle_identifier'),
      vin: searchParams.get('vin'),
      vehicle_id,
      page,
    };

    const validParams = validateParams(params);

    http.consumableCatalogHttp
      .getConsumables(validParams, cancelToken.token)
      .then((res) => {
        setRefData(res.data);
      })
      .finally(() => setLoading(false));
  }

  function getConsumableCategories() {
    setLoadingCategories(true);
    http.catalogs.getCarConsumableAllCategories().then((res) => {
      setConsumableCategories(res);
    }).finally(() => setLoadingCategories(false));
  }

  function getConsumableFilter() {
    const filterParams = {
      source: searchParams.get('source'),
      vehicle_identifier: searchParams.get('vehicle_identifier'),
      vin: searchParams.get('vin'),
      vehicle_id,
      page,
    };

    const validFilterParams = validateParams(filterParams);

    http.consumableCatalogHttp.getConsumableFilters(validFilterParams).then((res) => {
      setFilterData(res.data);
    });
  }

  const validateParams = (data) => {
    return Object.entries(data).reduce((acc, [key, value]) => {
      if (!value) {
        return acc;
      }

      acc[key] = value;

      return acc;
    }, {});
  };

  const handlePageClick = (pageN: number) => {
    setPage(pageN);
  };


  const onCategoryClick = (category) => {
    setActiveCategory({ id: category.id });
    setIsCategorySelected(true);
    searchParams.set('category_id', String([category.id]));
    setSearchParams(searchParams);
    setPage(1);
  };

  useEffect(() => {

    const params = {
      category_ids: searchParams.getAll('category_id'),
      brand_ids: searchParams.getAll('brand_id'),
    };

    if (searchParams.get('category_id')) {
      getConsumableData(params);
      getConsumableFilter();
    }

    return () => {
      cancelToken.cancel();
    };

  }, [
    page,
    searchParams.get('category_id'),
    searchParams.get('brand_id'),
    searchParams.get('sort'),
    searchParams.get('asc'),
    activeCategory.id
  ]);

  useEffect(() => {
    getConsumableCategories();
  }, []);


  useEffect(() => {
    if (searchParams.get('category_id')) {
      setActiveCategory({ id: Number(searchParams.get('category_id')) });
    }

    if (!searchParams.get('category_id') && consumableCategories.length) {
      const firstValidCategory = consumableCategories.find((category) => category.number_of_products !== 0);

      searchParams.set('category_id', String(firstValidCategory?.id));
      setSearchParams(searchParams);

    }
  }, [consumableCategories, searchParams.get('category_id')]);
  const currentCategory = consumableCategories.find((category) => category.id === activeCategory.id);

  function filterOptionsElement() {
    return (
      <div className="catalog-parts-filter">
        <button onClick={() => setOpenFilter(true)} className={`catalog-parts-button ${openFilter ? 'active' : ''}`}>
          {t('_filter_results_btn')}
        </button>
      </div>
    );
  }

  return (
    <div className="consumables-catalog-wrapper-mobile">
      <UniversalPartsNavbar option={filterOptionsElement()} title="_find_neccessary_parts" />
      <div className="separator"></div>

      <div className="filterOptions">
        {refData?.data?.length && refData?.data?.length > 0 ? <SortBy /> : null}
        <div className="results">
          {refData?.data?.length ? refData?.data?.length : 0} {t('_results')}
        </div>
        <ProductsViewMode viewMode={viewMode} setViewMode={setViewMode} />
      </div>
      {isCategorySelected ? (
        <div className="consumables-catalog-heading">
          <div className="consumables-category-return" onClick={() => setIsCategorySelected(false)}><FontAwesomeIcon icon={faCircleArrowLeft} />{t('_return')}</div>
          <div className="consumables-category-title">{currentCategory?.name}</div>
        </div>
      ) : null}
      <div className="catalog-consumables-items-container-mobile">
        {!isCategorySelected ? (
          <div className={`catalog-consumables-sidebar-mobile ${loadingCategories ? 'loading' : null}`}>
            {loadingCategories ? <Spinner class="small" /> : (
              consumableCategories && consumableCategories.length ? (
                <List
                  content={consumableCategories?.map((item) => {
                    return { ...item, id: item.id, name: item.name };
                  })}
                  images={consumableCategories?.map((item) => item.image)}
                  itemClassName="parts-list-catalog"
                  itemId="id"
                  onItemClick={onCategoryClick}
                  activeCategory={activeCategory}
                />
              ) : null
            )}
          </div>
        ) : (
          <div className="wrapperItems">
            {loading ? (
              <Spinner class="car-parts-spinner" />
            ) : refData?.data?.length ? (
              <ProductsViewModeChanger catalog={CATALOG_CONSUMABLE} viewMode={viewMode} data={refData?.data} />
            ) : (
              <div className="no-parts-data">
                <div className="notFoundSvg">
                  <PartsNotFoundImage />
                </div>
                <h1>{t('_consumables_not_found')}</h1>
              </div>
            )}
          </div>
        )}
      </div>
      {(refData && refData?.total_pages && refData?.page && refData?.total_pages > 1) && isCategorySelected ? (
        <Pagination pageCount={refData.total_pages} handlePageClick={handlePageClick} forcePage={page} />
      ) : (
        ''
      )}
      <CatalogConsumableFilter getData={getConsumableData} filterData={filterData} openFilter={openFilter} setOpenFilter={setOpenFilter} />
    </div>
  );
};

export default CatalogsConsumablesPageMobile;
