import Spinner from 'components/spinner/Spinner';
import React, { useEffect } from 'react';
import { IIndustrialCarPartsResponse } from 'utils/hooks/http/industrial.http';
import IndustrialTable from '../IndustrialTable/IndustrialTable';
import { useTranslation } from 'react-i18next';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import TextInput from 'components/inputs/TextInput/TextInput';
import { useForm } from 'react-hook-form';
import NewPagination from 'components/NewPagination/NewPagination';
import './IndustrialCarParts.scss';

type Props = {
  data?: IIndustrialCarPartsResponse[];
  paginationData?;
  page: number;
  setPage;
  loading: boolean;
  columns;
  fetchData: (params) => void;
};
const c = 'industrial-car-parts';

const IndustrialCarParts: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();
  const { handleSubmit, register, getValues, setValue } = useForm();

  const validateParams = (data) => {
    return Object.entries(data).reduce((acc, [key, value]) => {
      if (!value) {
        return acc;
      }

      acc[key] = value;

      return acc;
    }, {});
  };

  function onSubmit() {
    const params = {
      page: 1,
      artnr: String(getValues('artnr')).toLocaleUpperCase(),
      codfou: String(getValues('codfou')).toLocaleUpperCase(),
    };

    props.fetchData(validateParams(params));
  }

  function handleReset() {
    setValue('artnr', '');
    setValue('codfou', '');
    props.fetchData({});
  }

  function handlePageClick(page: number) {
    if (page < 1) {
      return;
    }

    if (props.paginationData?.data.length < props.paginationData?.per_page) {
      return;
    }

    props.setPage(page);
    props.fetchData({ page });
  }

  useEffect(() => {
    if (props.page > 1 && props.paginationData?.data.length === 0) {
      props.setPage((prev) => prev - 1);
    }
  }, [props.paginationData?.data.length]);

  return (
    <div className={c}>
      <div className={`${c}__filter`}>
        <div className={`${c}__filter-header`}>
          <div className={`${c}__filter-header-title`}>{t('_order_search')}</div>
        </div>
        <form onSubmit={handleSubmit(() => onSubmit())}>
          <div className={`${c}__filter-body`}>
            <div className={`${c}__filter-body-item`}>
              <TextInput {...register('codfou')} placeholder={t('codfou')} />
            </div>
            <div className={`${c}__filter-body-item`}>
              <TextInput {...register('artnr')} placeholder={t('artnr')} />
            </div>

            <DefaultButton text={t('_filter')} type="submit" />
            <DefaultButton red text={t('_reset')} onClick={handleReset} />
          </div>
        </form>
      </div>
      <div className={`${c}__content ${props.loading ? 'loading' : ''}`}>
        {props.loading ? (
          <Spinner class="small" />
        ) : props.data?.length ? (
          <IndustrialTable data={props.data} columnsTable={props.columns} />
        ) : (
          <div className={`${c}__content-no-data`}>{t('_no_data')}</div>
        )}
      </div>
      <NewPagination handlePageClick={handlePageClick} page={props.page} data={props.paginationData} />
    </div>
  );
};

export default IndustrialCarParts;
