import { createSlice } from '@reduxjs/toolkit';
import { fetchVehicleTreeAdcTypeIdByTcdTypeAndClass } from 'store/actions/tecrmi.actions';

type TecRMISlice = {
    typeId: number | null;
};

const initialState: TecRMISlice = {
  typeId: null,
};

const tecRMISlice = createSlice({
  name: 'tecrmi',
  initialState,
  reducers: {
    setTecRMITypeId(state, { payload }) {
      state.typeId = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchVehicleTreeAdcTypeIdByTcdTypeAndClass.fulfilled, (state, { payload }) => {
      state.typeId = payload!;
    });
  },
});

export const { setTecRMITypeId } = tecRMISlice.actions;

export default tecRMISlice.reducer;
