import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping } from '@fortawesome/free-solid-svg-icons';

import './CartButtonEspaceAutomotor.scss';
import { useNavigate } from 'react-router-dom';

type Props = {
  quantityToolbar?: number;
};

const CartButtonEspaceAutomotor: React.FC<Props> = (props) => {
  const navigate = useNavigate();

  const handleCartButtonClick = () => {
    navigate('offer/cart');
  };


  return (
    <div className="CartButtonEspaceAutomotor cart-button" onClick={handleCartButtonClick}>
      {
        props.quantityToolbar ?
          <div className="quantity">{props.quantityToolbar > 99 ? '+99' : props.quantityToolbar}</div>
          : ''
      }
      <FontAwesomeIcon icon={faCartShopping} className="cart-icon" />
    </div>
  );
};

CartButtonEspaceAutomotor.displayName = 'CartButtonEspaceAutomotor';

export default CartButtonEspaceAutomotor;
