import React from 'react';
import { CellProps } from 'react-table';
import { CartTableItem } from '../../CartTable.model';
import ImageCellView from './ImageCell.view';

type Props = CellProps<CartTableItem> & {};

const ImageCell: React.FC<Props> = (props: Props) => {

  return <ImageCellView src={props.value} />;
};

ImageCell.displayName = 'ImageCell';

export default ImageCell;
