import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { INavbarItem } from '../../Navbar.model';
import { navbarEspaceCatalogNafBattery, navbarEspaceCatalogNafConsumable, navbarEspaceCatalogNafOil, navbarEspaceCatalogNafTyre, navbarEspaceCatalogNonTecdoc, navbarEspaceStockManagement } from '../../Navbar.map';
import EspaceReliableNavbarCatalogNafView from './EspaceReliableNavbarCatalogNaf.view';

type Props = {};

const EspaceReliableNavbarCatalogNaf: React.FC<Props> = () => {
  const location = useLocation();
  const [items, setItems] = useState<INavbarItem[]>([]);
  const user = JSON.parse(localStorage.getItem('userData')!);

  useEffect(() => {
    switch (true) {
      case location.pathname.includes('/catalog_oil'):
        setItems(navbarEspaceCatalogNafOil);

        break;
      case location.pathname.includes('/catalog_tyres'):
        setItems(navbarEspaceCatalogNafTyre);

        break;
      case location.pathname.includes('/catalog_consumable'):
        setItems(navbarEspaceCatalogNafConsumable);

        break;

      case location.pathname.includes('/catalog_battery'):
        setItems(navbarEspaceCatalogNafBattery);

        break;
      case location.pathname.includes('/catalog_non_tecdoc'):
        setItems(navbarEspaceCatalogNonTecdoc);

        break;
      case location.pathname.includes('/stock-management'):


        navbarEspaceStockManagement.map((item: INavbarItem) => {

          return item.disabled = user != null && item.disabled_type ? !user[item.disabled_type!] : false;
        });

        setItems(navbarEspaceStockManagement);

        break;
      default:
        setItems([]);
    }
  }, [location]);

  return <EspaceReliableNavbarCatalogNafView items={items} />;
};

export default EspaceReliableNavbarCatalogNaf;
