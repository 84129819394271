import React, { useEffect, useState } from 'react';
import CatalogsTiresPageView from './CatalogsTiresPage.view';
import useHttp from 'utils/hooks/useHttp';
import { useSearchParams, useParams } from 'react-router-dom';
import { ITyresCategorie, ITyreFilter } from 'utils/rmi/interface/tyres.interface';
import { IBodyResponseTyre } from 'interfaces/tyre';
import { sortByOptions } from 'components/SortBy/SortBy.map';
import { SortByParams } from 'components/SortBy/SortBy';
import {useTranslation} from 'react-i18next';

const CatalogsTiresPage: React.FC = () => {
  const http = useHttp();
  const [searchParams, setSearchParams] = useSearchParams();
  const { vehicle_id } = useParams<'vehicle_id'>();

  const [tyreCategories, setTyresCategories] = useState<ITyresCategorie[]>([]);
  const [tyreProducts, setTyresProducts] = useState<IBodyResponseTyre>();
  const [tyreFilters, setTyresFilters] = useState<ITyreFilter>();
  const [loading, setLoading] = useState<boolean>(false);
  const { t, i18n } = useTranslation();

  const defaultParams = {
    vehicle_id,
    source: searchParams.get('source'),
  };

  useEffect(() => {

    if (!searchParams.get('tab') || searchParams.get('tab') === 'category') {
      setSearchParams(searchParams);
      setTyresProducts(undefined);
      getTyresSizes();
    }
  }, [i18n.language, searchParams.get('tab')]);

  useEffect(() => {
    if (searchParams.get('tab') === 'tyresCatalog') {
      getTyresAndFilters();
    }
  }, [i18n.language, searchParams.get('sort'), searchParams.get('asc'), searchParams.get('tab')]);

  const getTyresSizes = () => {
    setLoading(true);
    http.rmiTyresHttp
      .fetchVehicleTyresCategories(defaultParams)
      .then((res) => {
        setTyresCategories(res.data.filter((tyre) => tyre.status > 0));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getTyresAndFilters = () => {
    const params = getParamsFromUrl();

    const sortBySelectedOption = sortByOptions.filter((item) => String(item.value) == String(searchParams.get('sort')))[0] ?? [];

    const sortParams: SortByParams = {
      sort_by_column: String(searchParams.get('sort') ?? ''),
      sort_by_asc: String(searchParams.get('asc') ?? sortBySelectedOption?.default_sort_asc ?? ''),
    };

    setLoading(true);
    Promise.all([
      http.rmiTyresHttp.fetchVehicleTyresProducts({ ...params, ...sortParams, page: 1 }),
      http.rmiTyresHttp.fetchVehicleTyresFilters({}),
    ])
      .then((res) => {
        setTyresProducts(res[0].data);
        setTyresFilters(formatFilters(res[1].data));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getParamsFromUrl = () => {
    return {
      'widths[]': searchParams.get('widths[]'),
      'heights[]': searchParams.get('heights[]'),
      'diameters[]': searchParams.get('diameters[]'),
      'load_indexes[]': searchParams.get('load_indexes[]'),
      'speed_ratings[]': searchParams.get('speed_ratings[]'),
      'season_ids[]': searchParams.get('season_ids[]'),
      'brand_ids[]': searchParams.get('brand_ids[]'),
    };
  };

  const handleSelectTyreSize = (row: ITyresCategorie) => {
    setLoading(true);
    searchParams.set('tab', 'tyresCatalog');
    searchParams.set('widths[]', row.filters.width.toString());
    searchParams.set('heights[]', row.filters.height.toString());
    searchParams.set('diameters[]', row.filters.diameter.toString());
    searchParams.set('load_indexes[]', row.filters.loadIndexId.toString());
    searchParams.set('speed_ratings[]', row.filters.speedRatingId.toString());
    searchParams.set('page', '1');
    setSearchParams(searchParams);

    getTyresAndFilters();
  };

  const formatFilters = (filters) => {
    Object.keys(filters).map((key) => {
      filters[key].forEach((filter) => {
        filter.label = filter.key;
      });
    });

    return filters;
  };

  const tyresRequest = (params) => {
    setLoading(true);
    http.rmiTyresHttp
      .fetchVehicleTyresProducts(params)
      .then((res) => {
        setTyresProducts(res.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handlePageClick = (page) => {
    searchParams.set('page', page);
    setSearchParams(searchParams);
    tyresRequest({ page, ...getParamsFromUrl() });
  };

  const onReturnClick = () => {
    searchParams.set('tab', 'category');
    searchParams.delete('widths[]');
    searchParams.delete('heights[]');
    searchParams.delete('diameters[]');
    searchParams.delete('diameters[]');
    searchParams.delete('load_indexes[]');
    searchParams.delete('speed_ratings[]');
    searchParams.delete('season_ids[]');
    searchParams.delete('page');
    setSearchParams(searchParams);
  };

  return (
    <CatalogsTiresPageView
      tyreCategories={tyreCategories}
      onRowClick={handleSelectTyreSize}
      tyreProducts={tyreProducts}
      tyreFilters={tyreFilters}
      loading={loading}
      setLoading={setLoading}
      onFilterClick={tyresRequest}
      handlePageClick={handlePageClick}
      onReturnClick={onReturnClick}
    />
  );
};

CatalogsTiresPage.displayName = 'CatalogsTiresPage';

export default CatalogsTiresPage;
