import React, { useState } from 'react';
import { IFavoriteList } from '../../../../../../models/favorite';
import { ReactComponent as FolderImage } from 'assets/images/folder.svg';

import './FavoriteListItem.scss';
import { ReactComponent as CheckedImage } from 'assets/images/checked.svg';
import { ReactComponent as UncheckedImage } from 'assets/images/unchecked.svg';

type Props = {
  listItem: IFavoriteList;
  isChecked: boolean;
  setCheckedList: (list: IFavoriteList[]) => void;
  checkedList: IFavoriteList[]
}

const FavoriteListItem: React.FC<Props> = (props) => {

  const [checked, setChecked] = useState<boolean>(props.isChecked);

  const handleChecked = (e) => {
    const isChecked = e.target.checked;

    setChecked(isChecked);

    if (isChecked) {
      addItemInList();
    } else {
      removeItemFromList();
    }
  };

  const addItemInList = () => {

    if (isChecked()) {
      return;
    }

    const newList = [...props.checkedList, props.listItem];

    props.setCheckedList(newList);
  };

  const removeItemFromList = () => {
    const newList = props.checkedList.filter(item => item.id !== props.listItem.id);

    props.setCheckedList(newList);
  };

  const isChecked = () => {
    return props.checkedList.some(list => list.id === props.listItem.id);
  };

  return (
    <div className="favorite-list-item">
      <div className="icon">
        <FolderImage />
      </div>
      <div className="title">
        <p>{props.listItem.name}</p>
      </div>
      <div className="status">
        <label htmlFor={'checkbox' + String(props.listItem.id)}>
          <input id={'checkbox' + String(props.listItem.id)} type="checkbox" className="checkboxField" checked={checked} onChange={(e) => handleChecked(e)} />
          {
            checked ?
              (
                <CheckedImage />

              ) : (

                <UncheckedImage />

              )
          }
        </label>
      </div>
    </div>
  );
};

FavoriteListItem.displayName = 'FavoriteListItem';

export default FavoriteListItem;