import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import { fetchCarPartsByRef, fetchCarPartsData } from 'store/reducers/car-parts/car-parts.actions';
import { fetchCatalogInfo } from 'store/reducers/catalog/catalogs.actions';
import { getPage, getTotalCount, getTotalPages } from 'store/selectors/car-parts.selector';
import { getCurrentCatalog } from 'store/selectors/catalogs.selector';
import { nextPage, previousPage, setPage } from 'store/slices/car-parts.slice';
import { useAppDispatch } from 'utils/hooks/redux';
import { getMarketplaceBaseUrl } from 'utils/marketplace';
import CatalogCarPartsView from './CatalogCarParts.view';
import { DEFAULT_VIEW } from '../../components/ProductsViewMode/ProductsViewMode.constants';
import { INewCarPartsFilter } from 'models/car-parts.model';
import useHttp from 'utils/hooks/useHttp';
import { number } from 'yup';

const CatalogCarParts: React.FC = () => {
  const dispatch = useAppDispatch();
  const page = useSelector(getPage);
  const totalPages = useSelector(getTotalPages);
  const totalCount = useSelector(getTotalCount);
  const currentCatalog = useSelector(getCurrentCatalog);
  const { id } = useParams<'id'>();
  const [searchParams] = useSearchParams();
  const query = searchParams.get('q');
  const plateNumber = searchParams.get('platenumber');
  const vinNumber = searchParams.get('vin');
  const carId = searchParams.get('ktypnr');
  const per_page = 12;
  // const per_page = Number(searchParams.get('per_page'));
  const [filterData, setFilterData] = useState<INewCarPartsFilter>();
  const http = useHttp();

  useEffect(() => {
    dispatch(setPage(searchParams.get('page') || 1));
    // getFilters({});
  }, []);


  // function getFilters(data) {

  //   const params = {
  //     ...data,
  //     ...(searchParams.get('vehicle_identifier') && { vehicle_identifier: searchParams.get('vehicle_identifier') }),
  //     ...(searchParams.get('code_groupe') && { code_groupe: searchParams.get('code_groupe') }),
  //     ...(searchParams.get('code_ssgroupe') && { code_ssgroupe: searchParams.get('code_ssgroupe') }),
  //     ...(searchParams.get('code_repere') && { code_repere: searchParams.get('code_repere') }),
  //     genartnrs: searchParams.getAll('genartnrs[]'),
  //   };


  //   http.carParts
  //     .getNewReliableCarPartFilter(String(searchParams.get('ktypnr')), params)
  //     .then((res) => {
  //       setFilterData(res);
  //     });
  // }

  const fetchCarParts = (page: number) => {
    const code_groupe = Number(searchParams.get('code_groupe'));
    const code_repere = Number(searchParams.get('code_repere'));
    const code_ssgroupe = Number(searchParams.get('code_ssgroupe'));
    const manufacturer_id = searchParams.get('manufacturer_id');
    const ktypnr = searchParams.get('ktypnr');
    const model_id = searchParams.getAll('model_id');


    const params = {
      catalog_id: id,
      page,
      per_page,
      code_groupe,
      code_repere,
      code_ssgroupe,
      manufacturer_id,
      ktypnr,
      model_id,
    };


    const validParams = Object.entries(params).reduce((acc, [key, value]) => {
      if (!value) {
        return acc;
      }

      acc[key] = value;

      return acc;
    }, {});

    dispatch(fetchCarPartsData(validParams));
    dispatch(fetchCatalogInfo(id!));

  };

  const handleFetchCarPartsByRef = (page: number) => {
    if (id && query) {
      const params = {
        catalog_id: id,
        page,
        per_page,
        q: query,
      };

      dispatch(fetchCarPartsByRef(params));
    }
  };

  const fetchCarPartsByPlateNr = (page: number) => {
    if (id && carId && plateNumber) {
      const params = {
        catalog_id: id,
        page,
        per_page,
        platenumber: plateNumber,
        ktypnr: carId,
        ...(searchParams.get('code_groupe') && { code_groupe: Number(searchParams.get('code_groupe')) }),
        ...(searchParams.get('code_ssgroupe') && { code_ssgroupe: Number(searchParams.get('code_ssgroupe')) }),
        ...(searchParams.get('code_repere') && { code_repere: Number(searchParams.get('code_repere')) })
      };

      dispatch(fetchCarPartsData(params));
    }
  };

  const fetchCarPartsByVin = (page: number) => {
    if (id && carId && vinNumber) {
      const params = {
        catalog_id: id,
        page,
        per_page,
        vin: vinNumber,
        ktypnr: carId,
        vehicle_identifier: String(searchParams.get('vehicle_identifier')),
        source: String(searchParams.get('source')),
        ...(searchParams.get('code_groupe') && { code_groupe: Number(searchParams.get('code_groupe')) }),
        ...(searchParams.get('code_ssgroupe') && { code_ssgroupe: Number(searchParams.get('code_ssgroupe')) }),
        ...(searchParams.get('code_repere') && { code_repere: Number(searchParams.get('code_repere')) }),
        ...(searchParams.get('reference_count') && { reference_count: Number(searchParams.get('reference_count')) }),

      };

      dispatch(fetchCarPartsData(params));
    }
  };

  const handlePrevPageClick = () => {
    dispatch(previousPage());
  };

  const handleNextPageClick = () => {
    dispatch(nextPage());
  };

  const handlePageClick = (pageN: number) => {
    dispatch(setPage(pageN));
  };

  useEffect(() => {
    if (query) {
      handleFetchCarPartsByRef(page);
    } else if (plateNumber) {
      fetchCarPartsByPlateNr(page);
    } else if (vinNumber) {
      fetchCarPartsByVin(page);
    } else {
      fetchCarParts(page);
    }

  }, [page, query, plateNumber, vinNumber, searchParams.get('code_groupe'), searchParams.get('code_ssgroupe'), searchParams.get('code_repere')]);

  return (
    <CatalogCarPartsView
      totalCount={totalCount}
      onPrevPageClick={handlePrevPageClick}
      onNextPageClick={handleNextPageClick}
      onPageClick={handlePageClick}
      page={page}
      totalPages={totalPages}
      logo={`${getMarketplaceBaseUrl()}/${currentCatalog?.image}`}
      title={currentCatalog?.name}
      filterData={filterData}
    />
  );
};

CatalogCarParts.displayName = 'CatalogCarParts';

export default CatalogCarParts;
