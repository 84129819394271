import React from 'react';
import { Marketplaces } from 'utils/constants/applications';

import { getCurrentMarketplace } from 'utils/marketplace';
import './ListPartOE.scss';
import { useTranslation } from 'react-i18next';
import {isEmpty} from 'lodash';
import { Link, createSearchParams, generatePath } from 'react-router-dom';

type Props = {
  data?;
  v2?: boolean;
};

const ListPartOE: React.FC<Props> = (props) => {
  const marketplace = getCurrentMarketplace();
  const { t } = useTranslation();

  if (props.data.name === 'ORPHANE') {
    return null;
  }

  function generateLink(item, reference){
    const params = {
      page: '1',
      subcategory_id: props.data.genartnr,
      man_sup_id: item.manufacturer_group_id,
      type: 'T',
      source: 'man',
      q: reference
    };

    return generatePath(`?${createSearchParams(params)}`);
  }

  return (
    <div className="part-item-oe-list">
      { !isEmpty(props.data.manufacturer_group_info) &&
        Object.keys(props.data.manufacturer_group_info).length > 1 ? (
          <div className="part-header oe-header">
            <div className="img">
              <img src="/images/car-part-placeholder-big.png" alt="img" />
            </div>
            <div className="content">
              <div className="product-man-group">
                {props.v2 ? (
                  Object.keys(props.data.manufacturer_group_info_v2).map((man, index) => {
                    return (
                      <div key={index} className="product-man-group-info">
                        <span className="title">{man}: </span>
                        <span className="reference">{props.data.manufacturer_group_info_v2[man]?.references?.map(item => <Link key={item} className="oe-item-link" to={generateLink(props.data.manufacturer_group_info_v2[man], item)}>{item}, </Link>)}</span>
                      </div>
                    );
                  })
                ) : (
                  Object.keys(props.data.manufacturer_group_info).map((man, index) => {
                    return (
                      <div key={index} className="product-man-group-info">
                        <span className="title">{man}: </span>
                        <span className="reference">{String(props.data.manufacturer_group_info[man].join(', '))}</span>
                      </div>
                    );
                  })
                )}
              </div>
              <div className="product-name">{props.data.product_name}</div>
              <div className="description">
                {Array.isArray(props.data.tags) ? props.data.tags.map((tag) => `${tag.name}: ${tag.value}; `) : props.data.tags && Object.keys(props.data.tags).map((characteristic: string) => `${String(t(`${characteristic}`))}: ${String(props.data.tags[characteristic])}; `)}
              </div>
            </div>
          </div>
        ) : (
          <div className="oe-header">
            <div className="img">
              <img src={props.data.image ? props.data.image : '/images/car-part-placeholder-big.png'} alt={props.data.subcategory?.name}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = '/images/car-part-placeholder-big.png';
                }}

              />
            </div>
            <div className="content">

              <div className="title">{props.data.manufacturer_name}</div>
              <div className="reference">{props.data.name}</div>
              <div className="product-name">{props.data.product_name}</div>
              <div className="description">
              {/* {Array.isArray(props.data.tags) ? props.data.tags.map((tag) => `${tag.name}: ${tag.value}; `) : props.data.tags && Object.keys(props.data.tags).map((characteristic: string) => `${String(t(`_${characteristic}`))}: ${String(props.data.tags[characteristic])}; `)} */}
                {Array.isArray(props.data.tags) ? props.data.tags.map((tag) => `${tag.name}: ${tag.value}; `) : props.data.tags && Object.keys(props.data.tags).map((characteristic: string) => `${String(t(`${characteristic}`))}: ${String(props.data.tags[characteristic])}; `)}
              </div>
            </div>
          </div>
        )}
      <div className="oe-actions-side">
        <div className="oe-actions">
          {(marketplace !== Marketplaces.PUBLIC && marketplace !== Marketplaces.PUBLIC_TR) && (
            <React.Fragment>
              {/* <div className="quantity">
            <Disponibility value={quantityValue} limit={props.data.available} />
            <QuantityInput setValue={setQuantityValue} value={quantityValue} />
          </div> */}
              <div className="cart">
                {/* <div className="cart-price">
              {' '}
              <CarPartPrice currency={currencies.EUR} price={getPrice()} part />
            </div>
            <div className="cart-button"> */}
                {/* <div className="button">
                  <FileButton />
                </div> */}
                {/* <div className="button">
                <CartListButton onClick={handleAddToCart} cartLoading={cartLoading} />
              </div> */}
              </div>
              {/* </div> */}
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
    // <div className="part-list-item-oe">
    //   <div className="part-header">
    //     <div className="img">
    //       <img src={props.data.image} alt="img" />
    //     </div>
    //     <div className="title">{props.data.manufacturer_name}</div>
    //     <div className="reference">{props.data.name}</div>
    //     <div className="product-name">{props.data.product_name}</div>
    //   </div>
    //   <div className="part-footer">
    //     <div className="barcode">
    //       {props.data.barcode && (
    //         <React.Fragment>
    //           <img src={`${getMarketplaceBaseUrl()}/${props.data.barcode.image}`} alt="barcode" />
    //           <div className="barcode-number">{props.data.barcode.code}</div>
    //         </React.Fragment>
    //       )}
    //     </div>
    //     {marketplace !== Marketplaces.PUBLIC && (
    //       <React.Fragment>
    //         {/* <div className="quantity">
    //         <Disponibility value={quantityValue} limit={props.data.available} />
    //         <QuantityInput setValue={setQuantityValue} value={quantityValue} />
    //       </div> */}
    //         <div className="cart">
    //           {/* <div className="cart-price">
    //           {' '}
    //           <CarPartPrice currency={currencies.EUR} price={getPrice()} part />
    //         </div>
    //         <div className="cart-button"> */}
    //           <div className="button">
    //             <FileButton />
    //           </div>
    //           {/* <div className="button">
    //             <CartListButton onClick={handleAddToCart} cartLoading={cartLoading} />
    //           </div> */}
    //         </div>
    //         {/* </div> */}
    //       </React.Fragment>
    //     )}
    //   </div>
    // </div>
  );
};

export default ListPartOE;