import React, { useEffect, useState } from 'react';
import { createSearchParams, Outlet, useLocation, useSearchParams } from 'react-router-dom';
import { useHasTecrmiTabs } from 'utils/hooks/useHasTecrmiTabs';
import { INITIAL_TABS_STATE, IReliableRoute, RELIABLE_CONTENT_TABS } from 'utils/rmi/reliableTabs';
import ListIcon from 'pages/CatalogVehicle/TechnicalDocumentation/Sidebar/HorizontalBar/ListIcon';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { getCurrentCountryRMI } from 'utils/rmi/rmi-utils/rmi-utils';
import i18n from 'i18n';
import useHttp from 'utils/hooks/useHttp';

import Modal from 'components/modal/Modal';
import ModulesModal from 'pages/CatalogVehicle/TechnicalDocumentation/ModulesModal/ModulesModal';
import { useTranslation } from 'react-i18next';
import './tecrmi-modules-layout.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import useReliableCatalogCarQueryParams from 'utils/hooks/useReliableCatalogCarQueryParams';

const TecrmiModulesLayout = (): JSX.Element => {
  const { typeId } = useSelector((state: RootState) => state.tecrmi);
  const http = useHttp();
  const { t } = useTranslation();
  const [hasServiceProposal, setHasServiceProposal] = useState(true);
  const [hasTab, setHasTab] = useState(INITIAL_TABS_STATE);
  const [showModal, setShowModal] = useState(false);
  const [DEFAULT_TAB] = RELIABLE_CONTENT_TABS;
  const { pathname } = useLocation();
  const [activeModule, setActiveModule] = useState(RELIABLE_CONTENT_TABS.find((module) => pathname.includes(module.tab_name)) || DEFAULT_TAB);
  const { hasTecRMITabs } = useHasTecrmiTabs();
  const carParams = useReliableCatalogCarQueryParams();
  const { isMobile } = useDeviceDetect();

  useEffect(() => {
    if (isMobile) {
      setActiveModule(RELIABLE_CONTENT_TABS.find((module) => pathname.includes(module.tab_name)) || DEFAULT_TAB);
    }
  }, [pathname]);

  const fetchRMI = async (): Promise<void> => {
    if (typeId) {
      const result = await hasTecRMITabs(typeId);

      setHasTab((prev) => ({
        ...prev,
        ...result,
      }));
    }
  };

  function serviceProposal() {
    if (typeId) {
      http.maintenanceHttp
        .hasServiceProposal({
          typeId,
          countryCode: getCurrentCountryRMI(i18n.language),
        })
        .then((res) => setHasServiceProposal(res.data));
    }
  }

  useEffect(() => {
    serviceProposal();
    fetchRMI();
  }, [typeId]);

  useEffect(() => {
    if (!isMobile) {
      setShowModal(false);
    }
  }, [isMobile]);

  function itemLink(item) {
    const queryParams = createSearchParams({ ...carParams, source: 'T', vehicle_identifier: '002', level: '1' });

    if (item.tab_name === 'maintenance') {
      if (hasServiceProposal) {
        return `maintenance/suggested?${queryParams}`;
      } else {
        return `maintenance/repair?${queryParams}`;
      }
    }

    return `${item.tab_name}?${queryParams}`;
  }

  const renderIcon = (item: IReliableRoute): JSX.Element => {
    const Icon = item.icon;

    return <Icon />;
  };

  return (
    <div className="tecrmi-modules">
      {isMobile ? (
        <div className="tecrmi-modules-modal-btn" onClick={() => setShowModal(true)}>
          <div className="modules-modal-btn">
            <div className="modules-modal-btn-icon">{renderIcon(activeModule)}</div>
            <div className="modules-modal-btn-text">{t(activeModule.title)}</div>
          </div>
          <FontAwesomeIcon icon={faChevronRight} />
        </div>
      ) : (
        <div className="tcrmi-icons-bar">
          <ListIcon
            content={RELIABLE_CONTENT_TABS}
            className="sidebar-title"
            itemClassName="sidebar-list-item"
            itemLink={itemLink}
            hasTab={hasTab}
          />
        </div>
      )}
      <Modal
        openModal={showModal}
        setOpenModal={setShowModal}
        childrenComponent={
          <ModulesModal
            setActiveModule={setActiveModule}
            content={RELIABLE_CONTENT_TABS}
            hasModule={hasTab}
            link={itemLink}
            setOpenModal={setShowModal}
            title={t('_technical_documentation')}
          />
        }
      />
      <Outlet context={{ hasServiceProposal }} />
    </div>
  );
};

export default TecrmiModulesLayout;
