import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import './MessagesSupplierInfo.scss';
import Spinner from '../../../../../components/spinner/Spinner';
import {IRetailerInfo} from 'interfaces/messaging';
import useHttp from '../../../../../utils/hooks/useHttp';
import MessageSupplierInfoItem from './MessagesSupplierInfoItem/MessagesSupplierInfoItem';

type Props = {}


const MessageSupplierInfo: React.FC<Props> = (props) => {
  const {t} = useTranslation();
  const [loading, setLoading] = useState<boolean>(true);
  const [supplierInfo, setSupplierInfo] = useState<IRetailerInfo | null>(null);
  const [contactInfo, setContactInfo] = useState<IRetailerInfo | null>(null);
  const {retailerHttp} = useHttp();
  

  useEffect(() => {
    getRetailerSupplierInfo();
  }, []);

  const getRetailerSupplierInfo = async () => {
    setLoading(true);
    retailerHttp.getSupplierInfo()
      .then(({data}) => {
        setSupplierInfo(data.parent_supplier);
        setContactInfo(data.parent_contact);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="message-supplier-info">
      {
        loading ? (
          <Spinner class="extra-small"></Spinner>
        ) : (
          <div className="info-items">
            <MessageSupplierInfoItem title={t('_contact')} data={contactInfo}/>
            <MessageSupplierInfoItem title={t('_supplier')} data={supplierInfo}/>
          </div>
        )
      }
    </div>
  );
};

MessageSupplierInfo.displayName = 'MessageSupplierInfo';

export default MessageSupplierInfo;
