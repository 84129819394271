import SkeletonSpinner from 'components/skeleton-spinner/SkeletonSpinner';
import { ITyreSearchData } from 'interfaces/tyre';
import React, { MutableRefObject, useState, useRef, useEffect } from 'react';
import { faCircleDot, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { createSearchParams, generatePath, useNavigate, useSearchParams } from 'react-router-dom';
import { useOnClickOutside } from 'utils/hooks/useOnClickOutside';
import TextInput from '../inputs/TextInput/TextInput';
import './TyreDropdownContent.scss';
import { useForm } from 'react-hook-form';
import useHttp from 'utils/hooks/useHttp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TyresHelpModal from 'components/general-search/TyresHelpModal/TyresHelpModal';
import axios from 'axios';
import { ICustomValidation } from '../../utils/hooks/http/search-by-reference.http';
import DropdownContentTable from 'components/DropdownContentTable/DropdownContentTable';
import Head from 'components/tables/Cells/Head/Head';
import { Column } from 'react-table';
import { getBackendAssetsBasePath } from 'utils/marketplace';

type Props = {
  customValidations?: ICustomValidation[]
  setIsOpen?: (isOpen: boolean) => void;
  isOpen?: boolean;
}

const TyreDropdownContent: React.FC<Props> = (props) => {
  const [openTyreDropdown, setOpenTyreDropdown] = useState(false);
  const [tyreData, setTyreData] = useState<ITyreSearchData[]>([]);
  const [loadingTyre, setLoadingTyre] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const tyreDropdownRef = useRef() as MutableRefObject<HTMLDivElement>;
  const http = useHttp();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { register, getValues, reset } = useForm();
  const [searchParams] = useSearchParams();
  const cancelToken = axios.CancelToken.source();

  useOnClickOutside(tyreDropdownRef, () => setOpenTyreDropdown(false));

  useEffect(() => {
    if (!props.isOpen) {
      setOpenTyreDropdown(false);
    }
  }, [props.isOpen]);

  useEffect(() => {
    props.setIsOpen && props.setIsOpen(openTyreDropdown);
  }, [openTyreDropdown]);

  useEffect(() => {
    if (getValues('tyre').length < 3) { return; }

    if (searchParams.get('widths') && searchParams.get('heights') && searchParams.get('diameters')) {
      const fillInputFromURL = `${searchParams.get('widths')} / ${searchParams.get('heights')} R${searchParams.get('diameters')}`;

      if (fillInputFromURL !== '') {
        reset({ tyre: fillInputFromURL });
      }
    }
  }, [
    searchParams.get('widths'),
    searchParams.get('heights'),
    searchParams.get('diameters'),
    searchParams.get('load_index_id'),
    searchParams.get('speed_rating_id'),
    searchParams.get('q')
  ]);

  function handleTyreClick(item: ITyreSearchData) {
    const params = {
      page: '1',
      widths: item.width,
      heights: item.height,
      diameters: item.diameter,
      load_index_id: item.load_index_id,
      speed_rating_id: item.speed_rating_id,
      brand: item.brand_id,
      tyre_id: item.tyre_id,
      q: inputValue
    };

    const validParams = Object.entries(params).reduce((acc, [key, value]) => {
      if (!value) {
        return acc;
      }

      acc[key] = value;

      return acc;
    }, {});

    navigate({
      pathname: generatePath('/tyres'),
      search: `?${createSearchParams(validParams)}`,
    });
    setOpenTyreDropdown(false);
  }

  function tyreSearch() {
    if (inputValue.length < 3) {
      return;
    }

    setOpenTyreDropdown(true);
    setLoadingTyre(true);
    http.tyreHttp
      .searchTyreByRef({ q: inputValue })
      .then((res) => {
        setTyreData(res.data);
      })
      .finally(() => setLoadingTyre(false));
  }

  function tyreChange(event) {
    const inputData = event.target.value;

    setInputValue(inputData);

    if (inputData.length === 0) {
      setTyreData([]);
      setOpenTyreDropdown(false);
    }

  }

  useEffect(() => {
    if (inputValue.length >= 3) {
      setOpenTyreDropdown(true);
      setLoadingTyre(true);
      http.tyreHttp
        .searchTyreByRef({ q: inputValue })
        .then((res) => {
          setTyreData(res.data);
        })
        .finally(() => setLoadingTyre(false));

      return () => {
        cancelToken.cancel();
      };
    }

  }, [inputValue]);

  const tableData: Column<ITyreSearchData>[] = [
    {
      Header: <Head text=""></Head>,
      Cell: (cell) => (
        <img src={cell.cell.row.original.image ? `${getBackendAssetsBasePath()}/${cell.cell.row.original.image}` : '/images/car-part-placeholder.png'} width={30} height={30} alt="oil" onError={({ currentTarget }) => {
          currentTarget.onerror = null;
          currentTarget.src = '/images/car-part-placeholder.png';
        }}
        />)
      ,
      accessor: 'image',
    },
    {
      Header: <Head text={t('_reference').toLocaleUpperCase()}></Head>,
      accessor: 'reference',
    },
    {
      Header: <Head text={t('_brand').toLocaleUpperCase()}></Head>,
      accessor: 'brand_name',
    },
  ];

  return (
    <div className="catalog-search-input-container search-by-ref tyre-dropdown tyre-ref-search">
      <label className="reference-label">
        <FontAwesomeIcon className="reference-icon" icon={faCircleDot} /> {t('_ref_tyre')}
      </label>
      <TextInput
        defaultValue={searchParams.get('q') || ''}
        setIsOpen={props.setIsOpen}
        customValidations={props.customValidations}
        placeholder="Ex : 205 55 16 91"
        {...register('tyre')}
        onClick={() => {
          tyreSearch();
        }}
        withIcon
        onChange={tyreChange}
      />
      {openTyreDropdown && (
        <div ref={tyreDropdownRef} className="tyre-dropdown-content">
          <div className="tyre-dropdown-header">
            <div className="tyre-dropdown-title">{t('_search_result')}</div>
          </div>
          <div className="tyre-search-result-content">
            {loadingTyre ? (
              <SkeletonSpinner />
            ) : (
              tyreData?.length ? (
                <DropdownContentTable columns={tableData} itemClick={handleTyreClick} data={tyreData} loading={loadingTyre} />
              ) : (
                <div className="no-data">{t('_no_data').toLocaleUpperCase()}</div>
              )
            )}
          </div>
        </div>
      )}
      <div className="tyre-help-container">
        <p className="help-container" onClick={() => setIsModalOpen(true)}>
          <FontAwesomeIcon className="tyre-help-icon" icon={faQuestionCircle} /> <span className="help-text">{t('_how_to_read_a_tyre')}</span>
        </p>
        <div className="tyres-help-modal">
          <TyresHelpModal setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen} dimensions={getValues()?.tyre} />
        </div>
      </div>
    </div>
  );
};

export default TyreDropdownContent;
