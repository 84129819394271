import Modal from 'components/modal/Modal';
import ComponentsForTypeModal from 'components/ModalTCRMI/ComponentsForTypeModal';
import Spinner from 'components/spinner/Spinner';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { generatePath, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { RootState } from 'store/store';

import useHttp from 'utils/hooks/useHttp';

import {
  IBoxConfigurationsDiagnostic,
  IComponentsForType,
  ISelectModalDiagnostic,
  IVehicleManualItemMps,
  IVehicleManualList,
  IVehicleManualSubGroups,
} from 'utils/rmi/interface/diagnostic.interface';
import { getCurrentCountryRMI, getCurrentLanguageRMI } from 'utils/rmi/rmi-utils/rmi-utils';
import SidebarDetails from '../../SidebarDetails/SidebarDetails';
import VehicleIframe from '../../VehicleIframe/VehicleIframe';
import './Diagnostic.scss';
import ModulesMobileCardLevel4 from '../../ModulesMobileCardLevel4/ModulesMobileCardLevel4';
import IframeModalMobileCardLevel4 from '../../ModulesMobileCardLevel4/IframeModal/IframeModalMobileCardLevel4';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import { converObjectToParams } from 'utils/marketplace';
import useReliableCatalogCarQueryParams from 'utils/hooks/useReliableCatalogCarQueryParams';

const Diagnostic: React.FC = () => {
  const http = useHttp();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const { isMobile } = useDeviceDetect();
  let componentMounted = true;
  const [searchParams, setSearchParams] = useSearchParams();
  const [loadingList, setLoadingList] = useState(false);
  const [loadingHTML, setLoadingHTML] = useState(false);
  const { typeId } = useSelector((state: RootState) => state.tecrmi);
  const [iframeData, setIframeData] = useState<string>();
  const [sidebarDetails, setSidebarDetails] = useState(Number(searchParams.get('level')) || 1);
  const [componentType, setcomponentType] = useState<IComponentsForType[]>([]);
  const [componentTypeId, setComponentTypeId] = useState<number>(Number(searchParams.get('component_type_id')) || 0);
  const [manualList, setManualList] = useState<IVehicleManualList[]>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectHandler, setSelectHandler] = useState<ISelectModalDiagnostic | null>(null);
  const [mainGroup, setMainGroup] = useState<IVehicleManualList>();
  const [subGroups, setSubGroups] = useState<IVehicleManualSubGroups>();
  const [itemMpsManual, setItemMpsManual] = useState<IVehicleManualItemMps>();
  const [activeItem, setActiveItem] = useState<number | string | undefined>();
  const { vehicle_id } = useParams();
  const [boxDetailOptions, setBoxDetailOptions] = useState<ISelectModalDiagnostic[]>([]);
  const [showModal, setShowModal] = useState(false);
  const carParams = useReliableCatalogCarQueryParams();

  useEffect(() => {
    if (typeId && componentMounted) {
      http.diagnostictHttp
        .fetchRMIVehicleComponentsForType({
          typeId,
          countryCode: getCurrentCountryRMI(i18n.language),
          languageCode: getCurrentLanguageRMI(i18n.language),
        })
        .then((res) => {
          if (componentTypeId) {
            setComponentTypeId(componentTypeId);
            getList(componentTypeId);
          } else if (res.data?.length > 1) {
            setcomponentType(res.data);
          } else {
            setComponentTypeId(res.data[0].ComponentTypeId);
            searchParams.set('component_type_id', String(res.data[0].ComponentTypeId));
            setSearchParams(searchParams);
            getList(res.data[0].ComponentTypeId);
          }
        });
    }

    return () => {
      componentMounted = false;
    };
  }, [typeId, i18n.language]);

  const getList = (componentTypeId: number): void => {
    setLoadingList(true);
    http.diagnostictHttp
      .fetchRMIVehicleList({
        typeId,
        countryCode: getCurrentCountryRMI(i18n.language),
        languageCode: getCurrentLanguageRMI(i18n.language),
        componentTypeId,
      })
      .then(({ data }) => {
        data.forEach((groups) => {
          groups.SubGroups.forEach((subgroups) => {
            subgroups.ItemMps.forEach((itemps) => {
              itemps.Manuals.forEach((element, index) => {
                element.activeId = itemps.ItemMpId + index;
              });
            });
          });
        });


        setManualList(data);
      })
      .finally(() => {
        setLoadingList(false);
      });
  };

  useEffect(() => {
    if (selectHandler) {
      setComponentTypeId(Number(selectHandler?.value));
      searchParams.set('component_type_id', String(selectHandler?.value));
      setSearchParams(searchParams);
      getList(Number(selectHandler?.value));
    }
  }, [selectHandler]);

  useEffect(() => {
    if (componentType?.length > 1) {
      setOpenModal(true);
      fillProposalBoxDetailList(componentType);
    }
  }, [componentType]);

  function onMainGroupClick(item) {
    setMainGroup(item);
    setSidebarDetails(2);
    searchParams.set('main_group_id', item.MainGroupId);
    searchParams.set('level', '2');
    setSearchParams(searchParams);

  }

  function onSubGroupClick(item) {
    setSubGroups(item);
    setSidebarDetails(3);
    searchParams.set('sub_group_id', item.SubGroupId);
    searchParams.set('level', '3');
    setSearchParams(searchParams);
  }

  function onItemMpClick(item) {
    setItemMpsManual(item);
    setSidebarDetails(4);
    searchParams.set('level', '4');
    searchParams.set('item_id', item.ItemMpId);
    setSearchParams(searchParams);
  }

  function onItemMpManualClick(item) {
    setActiveItem(item.activeId);
    searchParams.set('manual_id', item.activeId);
    searchParams.set('qual_col_id', item.QualColId);
    searchParams.set('system_qual_col_id', item.SystemQualColId);
    setSearchParams(searchParams);

    // if (typeId && isMobile) {
    //   getHTML();
    // }
  }

  const getHTML = () => {
    setLoadingHTML(true);
    const param = {
      typeId,
      countryCode: getCurrentCountryRMI(i18n.language),
      languageCode: getCurrentLanguageRMI(i18n.language),
      componentTypeId,
      ItemMpId: Number(searchParams.get('item_id')),
      QualColId: Number(searchParams.get('qual_col_id')) || null,
      SystemQualColId: Number(searchParams.get('system_qual_col_id')) || null,
      SubGroupId: Number(searchParams.get('sub_group_id')),
      printView: true,
    };

    http.diagnostictHttp
      .fetchRMIVehicleManual(param)
      .then((res) => {
        setIframeData(res.data);


      })
      .finally(() => {
        if (isMobile) {
          setShowModal(true);
        }

        setLoadingHTML(false);
      });
  };

  function onReturnClick() {
    if (sidebarDetails === 1) {
      navigate({
        pathname: generatePath('/reliable/rmi/:vehicle_id/technical-documentation', {
          vehicle_id: String(vehicle_id),
        }),
        search: converObjectToParams(carParams),
      });

      return;
    }

    //  navigate(-1);
    setSidebarDetails((prev) => prev - 1);
  }

  useEffect(() => {
    if (Number(searchParams.get('main_group_id')) && manualList) {
      setMainGroup(manualList?.find((item) => item.MainGroupId === Number(searchParams.get('main_group_id'))));
    }

    if (Number(searchParams.get('sub_group_id')) && mainGroup) {
      setSubGroups(mainGroup?.SubGroups.find((item) => item.SubGroupId === Number(searchParams.get('sub_group_id'))));
    }

    if (Number(searchParams.get('item_id')) && subGroups) {
      const item = subGroups?.ItemMps.find((item) => item.ItemMpId === Number(searchParams.get('item_id')));

      setItemMpsManual(item);
    }

    if (searchParams.get('manual_id') && itemMpsManual) {
      const item = itemMpsManual?.Manuals.find((item) => item.activeId === Number(searchParams.get('manual_id')));

      setActiveItem(item?.activeId);
    }

    if (searchParams.get('manual_id') && !searchParams.get('sub_group_id') && !searchParams.get('main_group_id') && manualList) {
      let isManualFound = false;

      for (const groups of manualList) {
        for (const subgroups of groups.SubGroups) {
          for (const itemps of subgroups.ItemMps) {
            for (const manual of itemps.Manuals) {
              if (manual.activeId == +searchParams.get('manual_id')!) {
                console.log(manual.activeId, searchParams.get('manual_id'));
                isManualFound = true;
                setSidebarDetails(4);

                break;
              }

              if (isManualFound) {
                setActiveItem(manual?.activeId);

                break;
              }
            }

            if (isManualFound) {
              setItemMpsManual(itemps);

              break;
            }
          }

          if (isManualFound) {
            setSubGroups(subgroups);

            break;
          }
        }

        if (isManualFound) {
          setMainGroup(groups);

          break;
        }
      }
    }
  }, [manualList, subGroups, mainGroup, itemMpsManual]);

  useEffect(() => {

    if (Number(searchParams.get('manual_id')) && typeId) {
      getHTML();
    }


  }, [searchParams.get('manual_id'), typeId]);

  const fillProposalBoxDetailList = (optionsList: IBoxConfigurationsDiagnostic[]): void => {
    const boxDetailListOptions: Array<ISelectModalDiagnostic> = [];

    optionsList.forEach((opt: IBoxConfigurationsDiagnostic): void => {
      boxDetailListOptions.push({
        value: opt.ComponentTypeId,
        label: opt.ComponentTypeName,
      });
    });

    setBoxDetailOptions(boxDetailListOptions);
  };

  return (
    <div className="technical-documentation-diagnostic">
      {loadingList ? (
        <Spinner class="extra-small" />
      ) : (
        !isMobile ?
          <React.Fragment>
            <div className="tdoc-sidebar">
              {sidebarDetails === 1 && (
                <SidebarDetails
                  title={`<span>${t('_diagnostic')}</span>`}
                  itemName="MainGroupName"
                  onClick={onMainGroupClick}
                  data={manualList}
                  onReturnClick={onReturnClick}
                />
              )}
              {sidebarDetails === 2 && (
                <SidebarDetails
                  title={`${t('_diagnostic')} / <span>${mainGroup?.MainGroupName}</span>`}
                  itemName="SubGroupName"
                  onClick={onSubGroupClick}
                  data={mainGroup?.SubGroups}
                  onReturnClick={onReturnClick}
                />
              )}
              {sidebarDetails === 3 && (
                <SidebarDetails
                  title={`${t('_diagnostic')} / ${mainGroup?.MainGroupName} / <span>${subGroups?.SubGroupName}</span>`}
                  itemId="ItemMpId"
                  itemName="ItemMpText"
                  onClick={onItemMpClick}
                  data={subGroups?.ItemMps}
                  onReturnClick={onReturnClick}
                />
              )}
              {sidebarDetails === 4 && (
                <SidebarDetails
                  title={`${t('_diagnostic')} / ${mainGroup?.MainGroupName} / ${subGroups?.SubGroupName} / <span>${itemMpsManual?.ItemMpText}</span>`}
                  itemId="activeId"
                  itemName="QualColText"
                  onClick={onItemMpManualClick}
                  data={itemMpsManual?.Manuals}
                  onReturnClick={onReturnClick}
                  activeItem={activeItem}
                />
              )}
            </div>
            <div className="tdoc-content">{iframeData && <VehicleIframe loading={loadingHTML} vehicleIframe={iframeData!} />}</div>
          </React.Fragment>
          :
          <div className="tdoc-content">
            <ModulesMobileCardLevel4
              title={t('_diagnostic')}
              data={manualList}
              onMainGroupClick={onMainGroupClick}
              onSubGroupClick={onSubGroupClick}
              onItemMpClick={onItemMpClick}
              onItemMpManualClick={onItemMpManualClick}
              setOpenModal={setShowModal}
              activeItem={activeItem}
            />
            <Modal
              openModal={showModal}
              setOpenModal={setShowModal}
              childrenComponent={<IframeModalMobileCardLevel4 title={t('_diagnostic')} loading={loadingHTML} setOpenModal={setShowModal} vehicleIframe={iframeData!} />}
            />
          </div>
      )}
      <Modal
        openModal={openModal}
        setOpenModal={setOpenModal}
        shouldCloseOnOverlayClick={false}
        childrenComponent={
          <ComponentsForTypeModal setOpenModal={setOpenModal} boxDetailOptions={boxDetailOptions} setSelectHandler={setSelectHandler} />
        }
      />
    </div>
  );
};

export default Diagnostic;
