import React, { useEffect, useState } from 'react';
import ProfilView from './Profil.view';
import { RootState } from '../../store/store';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { IUserProfile } from '../../models/IUser';
import useHttp from '../../utils/hooks/useHttp';
import { notify } from '../../utils/marketplace';
import { useTranslation } from 'react-i18next';
import { IOption } from 'interfaces/select';
import { getLanguagesAsClientsOption } from 'utils/constants/locales';
import { useAppDispatch } from 'utils/hooks/redux';
import { login } from 'store/reducers/useSlices';

type Props = {};

const Profil: React.FC<Props> = () => {
  const {
    control,
    getValues,
    handleSubmit,
    register,
    formState: { errors },
    watch,
    setValue
  } = useForm<IUserProfile>();

  const { user } = useSelector((state: RootState) => state.userData);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [countries, setCountries] = useState<IOption[]>([]);
  // const [passwordRequired, setPasswordRequired] = useState<boolean>(false);
  const http = useHttp();
  const { t } = useTranslation();

  //--------- Set Default Values For Inputs --------//
  useEffect(() => {
    if (user) {
      // Distributor info
      if(countries.length){
        const country = countries?.find((country) => String(country.value) === String(user?.distributor_info?.info?.country.id));

        if (country) {
          setValue('company.country', country.value);
        }
      }

      const language = getLanguagesAsClientsOption()?.find((language) => String(language.value) === String(user?.distributor_info?.info?.language?.code));

      if (language) {
        setValue('company.language', language);
      }
      

      setValue('company.code_postal', user?.distributor_info?.info?.addresses[0]?.addressL6);
      setValue('company.address', user?.distributor_info?.info?.addresses[0]?.addressL1);
      setValue('company.phone', user?.distributor_info?.info?.addresses[0]?.tel);
      setValue('company.name', user?.distributor_info?.info?.name);

      setValue('gender', user!.gender);
      setValue('first_name', user!.full_name.split(' ')[0]);
      setValue('last_name', user!.full_name.split(' ')[1]);
      setValue('email', user!.email);
      setValue('mobile', user?.mobile);
      setValue('phone', user?.phone);
      setValue('language', user?.language);
    }
  }, [user, countries]);
  //------------------------------------------------//

  // const handleOnChangePassword = (e) => {
  //   setPasswordRequired(false);

  //   if (e.target.value.length >= 5) {
  //     setPasswordRequired(true);
  //   }
  // };

  // useEffect(() => {
  //
  // }, []);

  function getCountries(){
    http.clients.getCountriesOptions().then((res)=>{
      setCountries(
        res.data.map((item) => ({
          label: item.name || '',
          value: item.id || '',
        })) || []);

    });
  }

  const onSubmit = async () => {
    if (watch('password.first') !== watch('password.second') && getValues('password.first')) {
      return;
    }

    if (getValues('old_password') != '' && watch('password.first') == '') {
      return;
    }


    if (getValues('old_password') == '' && watch('password.second') != '') {
      return;
    }

    if ((watch('password.second') != '' && watch('password.first') != '') && getValues('old_password') == '') {
      return;
    }

    setLoading(true);
    const data = {
      email: getValues().email,
      first_name: getValues().first_name,
      gender: getValues().gender,
      language: getValues().language,
      last_name: getValues().last_name,
      mobile: getValues().mobile,
      phone: getValues().phone,
      old_password: getValues().old_password,
      ...(getValues().old_password && {
        password: { first: getValues().password.first, second: getValues().password.second }
      }),
      distributor_info: {
        id: user?.distributor_info?.info?.client_id,
        ...getValues()?.company,
        language: getValues()?.company?.language?.value}
    };

    const validData = Object.entries(data).reduce((acc, [key, value]) => {

      if (!value) {
        return acc;
      }

      acc[key] = value;

      return acc;
    }, {});


    http.user.putUser(validData).then(async () => {
      const userInfo = await http.user.infoUser();
      const user = { ...userInfo, ...validData, full_name: validData['first_name'] + ' ' + validData['last_name'] };

      localStorage.setItem('userData', JSON.stringify(user.data));
      dispatch(login(user.data));

      notify(t('_updated_successfully'), 'success');
    }).finally(() => {
      setLoading(false);
    }).catch((error) => {
      let erorrs = '';

      Object.entries(error).forEach(([, value]) => {
        erorrs += value;
      });
      notify(erorrs, 'error', t('_error'));

    });

  };

  useEffect(()=>{
    getCountries();
  },[]);

  return (
    <ProfilView
      getValues={getValues}
      handleSubmit={handleSubmit}
      watch={watch}
      onSubmit={onSubmit}
      user={user}
      control={control}
      register={register}
      errors={errors}
      loading={loading}
      countries={countries}
    />
  );
};

Profil.displayName = 'Profil';

export default Profil;
