import React from 'react';
import SupplierPromoView from './SupplierPromo.view';
import {ISupplierPromoModel} from './SupplierPromo.model';

type Props = {
  items: ISupplierPromoModel[];
};

const SupplierPromo: React.FC<Props> = (props) => {
  return <SupplierPromoView items={props.items} />;
};

SupplierPromo.displayName = 'SupplierPromo';

export default SupplierPromo;
