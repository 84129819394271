import React, { ChangeEventHandler, KeyboardEventHandler, PropsWithChildren } from 'react';
import './RadioboxInput.scss';

type Props = {

  placeholder?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onBlur?: ChangeEventHandler<HTMLInputElement>;
  name?: string;
  value?: string;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
  withIcon?: boolean;
  onClick?: () => void;
  label: string
};

const RadioboxInput = React.forwardRef<HTMLInputElement, Props>((props: PropsWithChildren<Props>, ref) => {

  return (
    <label className="checkbox-label">
      <input type="radio" className="option-input radio" {...props} ref={ref} />
      {props.label}
    </label >
  );

});

RadioboxInput.displayName = 'RadioboxInput';

export default RadioboxInput;
