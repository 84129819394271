import React, { useEffect, useState } from 'react';
import './CatalogSearchCategories.scss';
import { useSelector } from 'react-redux';
import CatalogSupplierNavbar from 'components/navbar/catalog-supplier-navbar/CatalogSupplierNavbar';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'utils/hooks/redux';
import { createSearchParams, generatePath, useNavigate, useSearchParams } from 'react-router-dom';
import useReliableCatalogCarQueryParams from 'utils/hooks/useReliableCatalogCarQueryParams';
import { resetCarPartsCategoriesSlice, setActivePartCategory } from 'store/slices/car-parts-categories.slice';
import { IGetCarPartDataParams } from 'models/car-parts.model';
import CatalogsPartsPageView from 'pages/CatalogVehicle/Catalogs/CatalogsPartsPage/CatalogsPartsPage.view';
import { RootState } from 'store/store';
import { fetchCategoriesVin } from 'store/reducers/catalog/categoriesVin.actions';
import { getCurrentCatalog } from 'store/selectors/catalogs.selector';
import i18n from 'i18n';
import useSelectFirstActiveCategory from 'utils/hooks/parts-categories/use-set-first-active-category';
import useSetStateVehicle from 'utils/hooks/useSetStateVehicle';

type Props = {

};

const CatalogSearchCategoriesView: React.FC<Props> = () => {
  const { t } = useTranslation();
  const [searchParamUrl] = useSearchParams();
  const currentCatalog = useSelector(getCurrentCatalog);
  let componentMounted = true;
  const { itemMpIds, vehicleManualsList, loadingCategories, activePartCategory } = useSelector((state: RootState) => state.carPartsCategories);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const reliableCatalogCarQueryParams = useReliableCatalogCarQueryParams();

  const onCategoryClick = (category) => {
    dispatch(setActivePartCategory(category));
  };

  const onClickHide = () => {
    dispatch(setActivePartCategory(null));
  };

  const handleSubcategoryClick = (params: Omit<IGetCarPartDataParams, 'catalog_id' | 'page' | 'per_page'>) => {
    const searchParams = createSearchParams({
      ...reliableCatalogCarQueryParams,
      code_groupe: String(params.code_groupe),
      code_ssgroupe: String(params.code_ssgroupe),
      code_repere: String(params.code_repere),
      reference_count: String(params.code_repere),
      source: String(searchParamUrl.get('source')),
      vehicle_identifier: String(searchParamUrl.get('vehicle_identifier')),
      ktypnr: String(searchParamUrl.get('ktypnr')),
      plate_number: String(searchParamUrl.get('plate_number')),
      car_info: '1',
      page: '1',
    });

    // navigate({
    //   pathname: generatePath('/catalog/:id/parts', {
    //     id: String(currentCatalog?.id),
    //   }),
    //   search: `?${searchParams}`,
    // });

    const newLink = ({
      pathname: generatePath('/catalog/:id/parts', {
        id: String(currentCatalog?.id),
      }),
      search: `?${searchParams}`,
    });

    return newLink.pathname + newLink.search;
  };

  const redirectToManual = (event, manualId) => {
    event.stopPropagation();
    navigate({
      pathname: `/reliable/rmi/${String(searchParamUrl.get('vehicle_id'))}/technical-documentation/technical-manuals`, //`/reliable/rmi/${manualId}`,
      search: `?${createSearchParams({ ...reliableCatalogCarQueryParams, manual_id: manualId })}`,
    });
  };

  useEffect(() => {
    if (currentCatalog?.id && componentMounted) {
      dispatch(resetCarPartsCategoriesSlice());
      const param = {
        ...{ catalog_id: String(currentCatalog?.id) },
        ...(searchParamUrl.get('ktypnr') && { vehicle_id: String(searchParamUrl.get('ktypnr')) }),
        ...(searchParamUrl.get('source') && { source: String(searchParamUrl.get('source')) }),
        ...(searchParamUrl.get('vehicle_identifier') && { vehicle_identifier: String(searchParamUrl.get('vehicle_identifier')) }),
        ...(searchParamUrl.get('vin') && { vin: String(searchParamUrl.get('vin')) }),
        ...(searchParamUrl.get('plate_number') && { plate_number: String(searchParamUrl.get('plate_number')) })

      };

      const filteredPartsCategories = fetchCategoriesVin(param);

      dispatch(filteredPartsCategories);

      return () => {
        componentMounted = false;
      };
    }

  }, [currentCatalog, i18n.language]);


  useSelectFirstActiveCategory();

  return (
    <div className="wrapper-parts-categories">

      <div className="wrapper-content">
        <CatalogSupplierNavbar infoCar />
        <div className="separator"></div>
        <div className="wrapperItems">
          <CatalogsPartsPageView
            loading={loadingCategories}
            activeCategory={activePartCategory}
            handleSubcategoryClick={handleSubcategoryClick}
            onCategoryClick={onCategoryClick}
            onClickHide={onClickHide}
            itemMpIds={itemMpIds}
            vehicleManuals={vehicleManualsList}
            redirectToManual={redirectToManual}


          />
        </div>
      </div>
    </div>
  );
};

CatalogSearchCategoriesView.displayName = 'CatalogSearchCategoriesView';

export default CatalogSearchCategoriesView;
