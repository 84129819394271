
import { faAdd } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import TextInput from 'components/inputs/TextInput/TextInput';
import Select from 'react-select';

import React from 'react';


import { Controller, useForm } from 'react-hook-form';

import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';


type Props = {
  setOpenModal: (e: boolean) => void;
  setFiter;
  setForcePage: (forcePage: number) => void;
  setBtnFilter: (e: boolean) => void;
}

const FilterPrice: React.FC<Props> = (props) => {

  const { reset, getValues, register, control, handleSubmit } = useForm();
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();

  const TYPE_OPTIONS = [
    { label: t('_PENDING'), value: 'PENDING' },
    { label: t('_IN_PROGRESS'), value: 'IN_PROGRESS' },
    { label: t('_FINISHED'), value: 'FINISHED' },
    { label: t('_FAILED'), value: 'FAILED' },
  ];


  const onSubmit = () => {


    const values = getValues();

    Object.keys(values).forEach((key) => {
      if (values[key] == undefined || values[key] == '') {
        delete values[key];
      }
    });

    if (values?.status) {
      values.status = values.status.value;
    }

    if (Object.keys(values).length == 0) {
      return;
    }

    props.setFiter({ ...values, page: 1 });
    props.setForcePage(1);
    props.setBtnFilter(true);
    searchParams.delete('page');
    setSearchParams(searchParams);

  };

  const resetFilter = () => {
    const values = getValues();

    Object.keys(values).forEach((key) => {
      if (values[key] == undefined || values[key] == '') {
        delete values[key];
      }
    });


    reset({ codfou: '', status: '' });

    props.setFiter({ page: 1 });
    props.setForcePage(1);
    searchParams.delete('page');
    setSearchParams(searchParams);

  };


  return (
    <div className="filter">
      <div className="filter-header">

        <div className="filter-title">{t('_advanced_search')}</div>

        <div className="filter-link-btn">
          <button className="add-btn" onClick={() => props.setOpenModal(true)}> <FontAwesomeIcon icon={faAdd} className="icon" /> {t('_add_new_price')} </button>
        </div>
      </div>
      <form onSubmit={handleSubmit(() => onSubmit())}>
        <div className="filter-body">


          <div className="filter-input-item">
            <TextInput {...register('codfou')} placeholder={t('_codfou')} />
          </div>
          <div className="filter-input-item">
            <Controller
              control={control}
              name="status"
              render={({ field: { onChange, value } }) => (
                <Select
                  value={value}
                  className="select-dropdown"
                  onChange={(val) => {
                    onChange(val);
                  }}
                  options={TYPE_OPTIONS}
                  placeholder={t('_status')}
                  menuPlacement="auto"
                  isClearable={false}
                  isSearchable={false}
                  menuPosition="fixed"
                  styles={{ menuPortal: base => ({ ...base, zIndex: 150 }) }}
                />
              )}
            />
          </div>

          <DefaultButton text={t('_filter')} type="submit" />
          <DefaultButton text={t('_reset')} red onClick={() => { resetFilter(); props.setBtnFilter(true); }} type="button" />
        </div>
      </form>
    </div >
  );
};

FilterPrice.displayName = 'FilterPrice';

export default FilterPrice;
