import React, { useState, useEffect } from 'react';
import Modal from 'components/modal/Modal';
import { useForm } from 'react-hook-form';
import { IClientEdit, IClient } from 'models/client.model';
import EditClientView from './EditClient.view';
import { IOption } from 'interfaces/select';
import { getLanguagesAsClientsOption } from 'utils/constants/locales';
import useHttp from 'utils/hooks/useHttp';
import { useParams } from 'react-router-dom';
import { notify } from 'utils/marketplace';
import { IDateOption } from 'models/date-picker';

type Props = {
  showEditClient: boolean;
  client: IClient | null;
  handleEditClientModal: () => void;
  setSubscriptionValueDate: (date: IDateOption) => void
  subscriptionValueDate?: IDateOption
  refreshData: () => void;
};

const EditClient: React.FC<Props> = (props: Props) => {
  const validDefaultParams = Object.entries({
    is_active: props.client?.is_active ? 'true' : 'false',
    name: props.client?.name,
    image: props.client?.image,
    commentary: props.client?.commentary,
    country: props.client?.country.id,
    language: props.client?.language.code,
    business_type_id: props.client?.client_business.id,
    phone: props.client?.addresses[0]?.tel != 'null' ? props.client?.addresses[0]?.tel : '',
    address: props.client?.addresses[0]?.addressL1 != 'null' ? props.client?.addresses[0]?.addressL1 : '',
    zipcode: props.client?.addresses[0]?.addressL6 != 'null' ? props.client?.addresses[0]?.addressL6 : '',
    client_erp_id: props.client?.client_erp_id
  }).reduce((acc, [key, value]) => {
    if (!value) {
      return acc;
    }

    acc[key] = value;

    return acc;
  }, {});

  const {
    getValues,
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IClientEdit>({
    defaultValues: validDefaultParams,
  });

  const [loading, setLoading] = useState(false);
  const [businessTypeOptions, setBusinessTypeOptions] = useState<IOption[]>([{ label: '', value: '' }]);
  const [countriesOptions, setCountriesOptions] = useState<IOption[]>([{ label: '', value: '' }]);
  const [languagesOptions, setLanguagesOptions] = useState<IOption[]>([{ label: '', value: '' }]);
  const [files, setFiles] = useState<File[] | []>([]);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const http = useHttp();
  const { id } = useParams<'id'>();


  const [country, setCountry] = useState<IOption>();
  const [lang, setLang] = useState<IOption>();
  const [businessType, setBusinessType] = useState<IOption>();

  useEffect(() => {
    fetchRequests();


  }, []);

  // useEffect(() => {
  //   reset();
  // }, [businessTypeOptions, countriesOptions, languagesOptions]);

  const getOption = (option) => {
    return option.map((item) => ({
      label: item.name || '',
      value: item.id || '',
    })) || [];
  };

  const fetchRequests = () => {
    setLoading(true);
    Promise.all([http.clients.getBusinessTypeOptions(), http.clients.getCountriesOptions()])
      .then((res) => {
        setBusinessTypeOptions([...getOption(res[0].data)]);
        setCountriesOptions([...getOption(res[1].data)]);
        setLanguagesOptions(getLanguagesAsClientsOption());
        setLoading(false);

        if (props.client?.country.id) {


          const country = [...getOption(res[1].data)]?.find((item) => item.value === props.client?.country.id);

          if (country) {
            setCountry(country);
          }
        }

        if (props.client?.language.code) {
          const lang = getLanguagesAsClientsOption()?.find((item) => item.value === props.client?.language.code);

          if (lang) {
            setLang(lang);
          }

        }

        if (props.client?.client_business.id) {
          const client_business = [...getOption(res[0].data)]?.find((item) => item.value === props.client?.client_business.id);

          if (client_business) {
            setBusinessType(client_business);
          }

        }

      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleOnCloseModal = () => {
    console.log('CLOSE MODAL');
  };

  const onSubmit = () => {
    setLoading(true);
    const client = getValues();


    console.log(client);


    const formData = new FormData();

    formData.append('image', selectedFile ? selectedFile : 'null');

    formData.append('address', client.address);
    formData.append('business_type_id', client.business_type_id);
    formData.append('commentary', client.commentary);
    formData.append('is_active', client.is_active);
    formData.append('language', client.language);
    formData.append('country', client.country);
    formData.append('name', client.name);
    formData.append('phone', client.phone);
    formData.append('zipcode', client.zipcode);
    formData.append('client_erp_id', client.client_erp_id);

    formData.append('subscription_start_date', String(props.subscriptionValueDate?.start_date));
    formData.append('subscription_end_date', String(props.subscriptionValueDate?.end_date));

    http.clients
      .editClient(Number(id), formData)
      .then(() => {
        setLoading(false);
        props.refreshData();
        props.handleEditClientModal();
      })
      .catch((error) => {
        setLoading(false);
        notify(error?.message ? error?.message : 'Error creating the user');
      });
  };


  return (
    <Modal
      openModal={props.showEditClient}
      setOpenModal={props.handleEditClientModal}
      onRequestClose={handleOnCloseModal}
      childrenComponent={
        <EditClientView
          setSubscriptionValueDate={props.setSubscriptionValueDate}
          setFiles={setFiles}
          setSelectedFile={setSelectedFile}
          onSubmit={onSubmit}
          handleSubmit={handleSubmit}
          register={register}
          control={control}
          handleEditClientModal={props.handleEditClientModal}
          businessTypeOptions={businessTypeOptions}
          countriesOptions={countriesOptions}
          languagesOptions={languagesOptions}
          loading={loading}
          files={files}
          errors={errors}
          clientData={props.client}
          country={country}
          lang={lang}
          businessType={businessType}
          setCountry={setCountry}
          setLang={setLang}
          setBusinessType={setBusinessType}

        ></EditClientView>
      }
    ></Modal>
  );
};

EditClient.displayName = 'EditClient';

export default EditClient;
