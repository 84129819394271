import React from 'react';
import { CellProps } from 'react-table';
import { CartTableItem } from '../../SupplierCartTable.model';

import DistributorCellView from './DistributorCell.view';

type Props = CellProps<CartTableItem>;

const DistributorCell: React.FC<Props> = (props: Props) => {
  return <DistributorCellView value={props.value}></DistributorCellView>;
};

DistributorCell.displayName = 'DistributorCell';

export default DistributorCell;
