import DefaultToastContainer from 'components/toast-container/DefaultToastContainer';
import React from 'react';
import { Outlet } from 'react-router-dom';

const SelectClientLayout: React.FC = () => {
  return <React.Fragment>
    <DefaultToastContainer />
    <Outlet />
  </React.Fragment>;
};

export default SelectClientLayout;
