import React from 'react';
import {ReactComponent as FileIcon} from 'assets/Icons/file.svg';
import {IMessageFile} from '../../../interfaces/messaging';
import './UploadedMessageFile.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faClose} from '@fortawesome/free-solid-svg-icons';
import {ReactComponent} from '*.svg';

type Props = {
  messageFile: IMessageFile;
  removeFile: (file:IMessageFile) => void;
}

const UploadedMessageFile: React.FC<Props> = (props) => {

  return (
    <div className="uploaded-message-file">
      <div className="file-info">
        <div className="icon">
          <FileIcon />
        </div>
        <div className="name">
          <p>{props.messageFile.name}</p>
        </div>
      </div>
      <div className="remove-file" onClick={() => props.removeFile(props.messageFile)}>
        <FontAwesomeIcon icon={faClose}/>
      </div>
    </div>
  );
};

UploadedMessageFile.displayName ='MessageFile';

export default UploadedMessageFile;

