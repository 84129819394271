import { createAsyncThunk } from '@reduxjs/toolkit';
import { ICarPartData, IGetCarPartByRefParams, IGetCarPartDataParams, IGetCarPartDataResponse } from 'models/car-parts.model';
import useHttp from 'utils/hooks/useHttp';
import { getMarketplaceBaseUrl } from 'utils/marketplace';

export const fetchCarPartsData = createAsyncThunk('car-parts/fetchCarParts', async (params: IGetCarPartDataParams, { rejectWithValue }) => {
  try {
    const http = useHttp();
    const response = await http.carParts.getCarPartsData(params);

    const data: ICarPartData[] = response.data.map((part: IGetCarPartDataResponse) => {

      if (part.price) {
        const promotion_price = part.promotion_price && part.promotion_price.toString().substring(0, 2) + ',' + part.promotion_price.toString().substring(2);

        return { ...part, promotion_price: promotion_price && promotion_price, barcode: part.barcode && { ...part.barcode, image: `${getMarketplaceBaseUrl()}/${part.barcode.image}` } };
      }

      return {
        ...part,
        price: '',
        promotion_price: '',
        barcode: part.barcode && { ...part.barcode, image: `${getMarketplaceBaseUrl()}/${part.barcode.image}` },
      };
    });


    return { ...response, data };
  } catch (e) {

    return rejectWithValue('fetch parts error');
  }
});

export const fetchCarPartsByRef = createAsyncThunk(
  'car-parts/fetchCarPartsByRef',
  async (params: IGetCarPartByRefParams, { rejectWithValue }) => {
    try {
      const http = useHttp();
      const response = await http.carParts.getCarPartsByRef(params);

      const data: ICarPartData[] = response.data.map((part: IGetCarPartDataResponse) => {
        
        const barcode = part.barcode && { ...part.barcode, image: `${getMarketplaceBaseUrl()}/${part.barcode.image}` };

        // let promotion_price = '';
        // if (part?.promotion_price) {
        //   promotion_price = `${part.promotion_price.toString().substring(0, 2)}${part.promotion_price.toString().substring(2) || '00'}`;
        // }
        function getPrice(){
          if (part?.price?.price) {
            return `${part.price.price.toString().substring(0, 2)}${part.price.price.toString().substring(2) || '00'}`;
          }

          return part.price;
        }


        return { ...part, barcode, price: getPrice() };
      });

      return { ...response, data };
    } catch (e) {
      return rejectWithValue('fetch parts error');
    }
  },
);
