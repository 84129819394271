import { IconDefinition } from '@fortawesome/free-solid-svg-icons';

import React from 'react';
import LinkButtonView from './LinkButton.view';

type Props = {
  title: string;
  icon?: IconDefinition;
  onClick?
};

const LinkButton: React.FC<Props> = (props) => {
  return <LinkButtonView onClick={props.onClick} icon={props.icon} title={props.title} />;
};

LinkButton.displayName = 'LinkButton';

export default LinkButton;
