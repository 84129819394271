import React, { useMemo } from 'react';
import { Column, useTable } from 'react-table';
import { useNavigate } from 'react-router-dom';
import { IData } from 'models/order';
import Pagination from 'components/pagination/Pagination';
import Spinner from 'components/spinner/Spinner';
import { useTranslation } from 'react-i18next';
import './OrdersClientsTable.scss';
import Head from 'components/tables/Cells/Head/Head';
import IconNotification from 'components/notification/IconNotification/IconNotification';
import { faComment } from '@fortawesome/free-solid-svg-icons';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import CURRENCY from 'utils/constants/currency';


type Props = {
  data: IData[]
  loading: boolean
  handlePageClick?: (data) => Promise<void>
  totalPage?: number
  currentPage?: number
};


const OrderClientsTable: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { isMobile, isTablet, isDesktop } = useDeviceDetect();
  const user = JSON.parse(localStorage.getItem('userData')!);


  let hiddenColumns: string[] = [];

  if (isTablet) {
    hiddenColumns = ['status', 'customer_id'];
  }

  if (isMobile) {
    hiddenColumns = ['id', 'time', 'proforma_id', 'customer_id', 'user_email', 'status'];
  }

  const columns = useMemo(
    (): Column<IData>[] => [

      {
        Header: <Head text={t('_date').toLocaleUpperCase()}></Head>,
        accessor: 'time',
      },
      {
        Header: <Head text={t('_order_number').toLocaleUpperCase()}></Head>,
        accessor: 'id',
      },
      {
        Header: <Head text={t('_client').toLocaleUpperCase()}></Head>,
        accessor: 'client_name',
      },
      {
        Header: <Head text={t('_order_ref').toLocaleUpperCase()}></Head>,
        accessor: 'customer_id',
      },
      {
        Header: <Head text={t('_proforma_id').toLocaleUpperCase()}></Head>,
        accessor: 'proforma_id',
      },
      {
        Header: <Head text={t('_email').toLocaleUpperCase()}></Head>,
        accessor: 'user_email',
      },

      {
        Header: <Head text={t('_price').toLocaleUpperCase()}></Head>,
        accessor: 'total_price',
        Cell: (cell) => <span>{cell.row.original.total_price ? `${CURRENCY[user.currency]}${Number(cell.row.original.total_price).toFixed(2)}` : ''} </span>,
      },

      {
        id: 'comment',
        Header: t('_comment').toString().toLocaleUpperCase(),
        Cell: (cell) => <span><IconNotification icon={faComment} number={cell.row.original?.unseen_messages_count} onClick={() => null} /></span>,
      },

      {
        Header: <Head text={t('_status').toLocaleUpperCase()}></Head>,
        accessor: 'status',
        Cell: t('_sent')
      }

    ],
    [isMobile, isTablet, isDesktop, i18n.language],
  );


  const initialState = hiddenColumns ? {
    'hiddenColumns': hiddenColumns
  } : {};

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data: props.data,
    initialState: initialState
  });

  const handleOrderView = (orderId) => {
    navigate(`/espace-clients/orders-clients/${orderId}`);
  };

  return (
    <div className="table-wrapper-orders-clients sellers-table-wrapper order">

      <table {...getTableProps()}>
        <thead className="headings">
          {headerGroups.map((headerGroup, index) => {
            return (
              <tr key={headerGroup.headers[index].id} className="row">
                {headerGroup.headers.map((column) => {
                  const { key, ...restColumn } = column.getHeaderProps();

                  return (
                    <th key={key} {...restColumn} className="heading">
                      {column.render('Header')}
                    </th>
                  );
                })}
              </tr>
            );
          })}
        </thead>

        {
          props.loading ?
            <tbody className="body">
              <tr className="no-data">
                <td colSpan={9}>
                  <Spinner class="extra-small" />
                </td>
              </tr>
            </tbody> :
            props.data.length ?
              <tbody {...getTableBodyProps} className="body">
                {rows.map((row) => {
                  prepareRow(row);
                  const { key, ...restRowProps } = row.getRowProps();

                  return (

                    <tr key={key} {...restRowProps} onClick={() => handleOrderView(row.original.id)} className="cell-row">
                      {row.cells.map((cell) => {
                        const { key, ...restCellProps } = cell.getCellProps();

                        return (
                          <td key={key} {...restCellProps} className="cell">
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>

                  );
                })}
              </tbody>
              :
              <tbody className="body">
                <tr className="no-data">
                  <td colSpan={9}>{t('_no_data')}</td>
                </tr>
              </tbody>
        }
      </table>
      <div>
        {props.totalPage! > 1 && (
          <Pagination pageCount={props.totalPage!} handlePageClick={props.handlePageClick!} forcePage={props.currentPage!} />
          // <Pagination pageCount={props.totalPage!} handlePageClick={props.handlePageClick!} forcePage={props.currentPage! - 1} />
        )}
      </div>
    </div>

  );
};

OrderClientsTable.defaultProps = {
  currentPage: 1
};


OrderClientsTable.displayName = 'OrderClientsTable';

export default OrderClientsTable;
