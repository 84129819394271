import { faCircleArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import './SidebarDetails.scss';

type Props = {
  onReturnButtonClick?: (item) => void;
  onClick: (item) => void;
  data?
  title: string
  itemName: string
  onReturnClick?: () => void
  activeItem?: string | number
  itemId?: string | number
  noPointerEvents?: boolean
};

const SidebarDetails: React.FC<Props> = (props: Props) => {

  return (
    <div className="sidebar-details">
      <div className="header">
        <span className="return-button" onClick={props.onReturnClick}>
          <FontAwesomeIcon icon={faCircleArrowLeft} className="icon" />
        </span>
        <div className="title" dangerouslySetInnerHTML={{ __html: String(props.title) }}></div>
      </div>
      <div className="content">
        <div className="items">
          {props.data?.map((item, key) => {
            return (
              <div onClick={() => props.onClick(item)} key={key + item[props.itemName]} className={`item ${props.activeItem && props.activeItem == item[props.itemId!] ? 'active' : ''} ${props.noPointerEvents ? 'no-pointer-events' : ''}`}>
                {item[props.itemName]}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SidebarDetails;
