import React from 'react';
import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IErrorCodesNavLinks } from 'pages/CatalogVehicle/ErrorCodes/ErrorCodesNavLinks/errorCodes';
import ErrorCodesSearch from 'pages/CatalogVehicle/ErrorCodes/ErrorCodesSearch';
import ErrorCodesNavLinks from 'pages/CatalogVehicle/ErrorCodes/ErrorCodesNavLinks/ErrorCodesNavLinks';
import './ErrorCodesMobile.scss';
import ErrorCodesListMobile from './ErrorCodesList/ErrorCodesListMobile';
import {IVehicleManual} from 'interfaces/vehicle-manuals.interface';
import {ISearchLinks} from '../Solutions/Solutions';

type Props = {
  searchLinks: ISearchLinks;
  navLinks: IErrorCodesNavLinks[];
  keywordQueryParams?: string;
  manuals?: IVehicleManual[];
  onManualClick: (manual: IVehicleManual) => void;
};

const ErrorCodesMobileView:React.FC<Props> = (props: Props) => {
  const searchLinks = props.searchLinks;

  return (
    <div className="error-codes-layout">
      <div className="content container-fluid-medium">
        <ErrorCodesListMobile manuals={props.manuals} onClick={props.onManualClick} />
        <ErrorCodesSearch searchLinks={props.searchLinks} />
        <ErrorCodesNavLinks navLinks={props.navLinks} />
        <Outlet context={{searchLinks}} />
      </div>
    </div>
  );
};

ErrorCodesMobileView.displayName = 'ErrorCodesView';

export default ErrorCodesMobileView;