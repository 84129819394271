import React from 'react';
import ErrorCodeItemView from './ErrorCodeItem.view';
import {IActiaReportData} from '../../../../../components/DiagnosticItems/Actia/actia';
import {createSearchParams, generatePath, useNavigate, useParams} from 'react-router-dom';
import {getURLParams} from '../../../../../utils/marketplace';
import useReliableCatalogCarQueryParams from '../../../../../utils/hooks/useReliableCatalogCarQueryParams';
import {RELIABLE_TAB_NAMES} from '../../../../../utils/rmi/reliableTabs';

type Props = {
    items: IActiaReportData[];
  selectedCode: string;
  setSelectedCode: (code:string) => void
}

interface IUrlParams {
  source?: string;
  vehicle_identifier?: string;
}

const ErrorCodeItem: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const { vehicle_id, session_id } = useParams();
  const reliableCatalogCarQueryParams = useReliableCatalogCarQueryParams();

  const handleOnClick = (code:string) => {
    props.setSelectedCode(code);
    const paramsObject = {...reliableCatalogCarQueryParams, code};
    const link = `/reliable/rmi/${vehicle_id}/diagnostic/${session_id}/error-codes/manuals?${(new URLSearchParams(paramsObject)).toString()}`;

    navigate({
      pathname:link
    });
  };

  const classType  = (): string => {
    switch (true) {
      case props.items.length >= 4:
        return 'big';
      case props.items.length >= 2 && props.items.length < 4:
        return 'medium';
      default:
        return 'small';
    }
  };

  return (
    <ErrorCodeItemView onClick={handleOnClick} selectedCode={props.selectedCode} name={String(props.items?.[0].name)} classType={classType} items={props.items}/>
  );
};

ErrorCodeItem.displayName = 'ErrorCodeItem';

export default ErrorCodeItem;
