import React, { useContext, useEffect } from 'react';
import { IOption } from 'interfaces/select';
import Select, { SingleValue } from 'react-select';
import useHttp from 'utils/hooks/useHttp';
import Spinner from 'components/spinner/Spinner';
import {
  IUploadProformaContext,
  UploadProformaAmerigoContext
} from '../../../../../../../context/UploadProformaAmerigoContext';
import './ProformaSupplierList.scss';
import { notify } from '../../../../../../../utils/marketplace';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getCurrentCatalog } from 'store/selectors/catalogs.selector';

type Props = {
};

const ProformaSupplierList: React.FC<Props> = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState<boolean>(false);
  const { proformaAmerigo } = useHttp();
  const { selectedSupplier, setSelectedSupplier, supplierOptions, setSupplierOptions } = useContext(UploadProformaAmerigoContext) as IUploadProformaContext;

  const currentCatalog = useSelector(getCurrentCatalog);

  useEffect(() => {
    if (!supplierOptions.length) {
      getSuppliers();
    }
  }, []);

  const getSuppliers = async () => {

    try {
      setLoading(true);
      const { data } = await proformaAmerigo.getSupplierMappingOptions({ catalog_id: currentCatalog?.id });

      setSupplierOptions(data);

      if (data.length === 1) {
        setSelectedSupplier(data[0]);
      }
    } catch (e) {
      notify(t('_error'));
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const handleChangeSupplier = (selectedSupplier: SingleValue<IOption>) => {
    setSelectedSupplier(selectedSupplier);
  };

  return (
    <div className="amerigo-supplier-mapping-options">
      {
        loading ? (
          <Spinner />
        ) : (
          supplierOptions.length ?
            <Select
              className={`select-supplier ${!selectedSupplier ? 'required' : ''}`}
              placeholder={t('_find_supplier')}
              value={selectedSupplier}
              onChange={(val) => {
                handleChangeSupplier(val);
              }}
              options={supplierOptions}
            /> :
            <div className="upload-file-no-suppliers">
              {currentCatalog ?
                <p>
                  <Trans
                    i18nKey="_supplier_did_not_define_teccom"
                    values={{
                      catalog_name: currentCatalog.name,

                    }}
                  />
                </p> :
                <p> {t('_suppliers_did_not_define_teccom')} </p>
              }

            </div>

        )
      }

    </div >
  );
};

ProformaSupplierList.displayName = 'ProformaSupplierList';

export default ProformaSupplierList;