import React from 'react';
import DiagnosticItemsView from './DiagnosticItems.view';

type Props = {
}

const DiagnosticsItems: React.FC<Props> = () => {

  return (
    <DiagnosticItemsView/>
  );
};

DiagnosticsItems.displayName = 'DiagnosticItems';

export default DiagnosticsItems;