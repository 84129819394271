import React from 'react';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import CatalogConsumables from './CatalogConsumables';
import CatalogConsumablesMobile from './CatalogConsumablesMobile/CatalogConsumablesMobile';

const CatalogConsumablesView:React.FC = () => {
  const {isMobile} = useDeviceDetect();

  return (
    isMobile ? <CatalogConsumablesMobile /> : <CatalogConsumables/>
  );
};

export default CatalogConsumablesView;