import { createAsyncThunk } from '@reduxjs/toolkit';
import { IClientsFilterParams } from 'models/client.model';
import useHttp from 'utils/hooks/useHttp';

export const fetchClients = createAsyncThunk('clients/fetchClients', async (page: number, { rejectWithValue }) => {
  try {
    const http = useHttp();
    const response = await http.clients.getClients({page});

    return response;
  } catch (e) {
    return rejectWithValue('fetch clients error');
  }
});

export const fetchClientContacts = createAsyncThunk('clients/fetchClientContacts', async (id: number, { rejectWithValue }) => {
  try {
    const http = useHttp();
    const response = await http.clients.getClientContacts(id);

    return response;
  } catch (e) {
    return rejectWithValue('fetch clients error');
  }
});

export const fetchFilteredClients = createAsyncThunk('clients/fetchFilteredClient', async(params: IClientsFilterParams,  { rejectWithValue }) => {
  try {
    const http = useHttp();
    const response = await http.clients.getFilteredClients(params);

    return response;
  } catch (e) {
    return rejectWithValue('fetch clients error');
  }
});
