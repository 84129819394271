import Catalog from 'applications/automotor/components/espace/client/catalog/Catalog';
import SearchReference from 'applications/automotor/components/espace/client/search-reference/SearchReference';
import OrdersClientsEspace from 'pages/OrdersClientsEspace/OrdersClients';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import './MyEshopView.scss';

type Props = {};

const MyEshopView: React.FC<Props> = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();


  return (
    <div className="my-eshop-container">
      <div className="catalog-container">
        <Catalog />
      </div>
      <div className="search-reference-container">
        <SearchReference />
      </div>
      <div className="order-clients">
        <div className="order-clients-title">{t('_new_orders')}</div>
        <div className="order-clients-table">
          <OrdersClientsEspace />
        </div>
        <div className="order-clients-btn-order-client">
          <button className="btn" onClick={() => navigate('/espace-clients/orders-clients')}>{t('_all_orders')}</button>
        </div>
      </div>
    </div>
  );
};

MyEshopView.displayName = 'MyEshopView';

export default MyEshopView;


