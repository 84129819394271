import React from 'react';
import TabFilterView from './TabFilter.view';

type Props = {};

const TabFilter: React.FC<Props> = () => {
  return <TabFilterView />;
};

TabFilter.displayName = 'TabFilter';

export default TabFilter;
