import { ITruckManufacturers, ITruckModels, ITruckFuel, ITruckVersion } from 'interfaces/truckSelector';
import { addRouteParam, IHttpResponse, serverAxios } from '../../http';

class TruckSelectorHttp {
  private static truckManufacturers = 'reliable/truck-selector/manufacturers';
  private static truckModels = 'reliable/truck-selector/:id/models';
  private static truckFuel = 'reliable/truck-selector/:id/fuel-type';
  private static truckVersion = 'reliable/truck-selector/:id/variant';

  public async getTruckManufacturers(): Promise<IHttpResponse<ITruckManufacturers[]>> {
    return serverAxios.get(TruckSelectorHttp.truckManufacturers);
  }
  public async getTruckModels(id: string): Promise<IHttpResponse<ITruckModels[]>> {
    return serverAxios.get(addRouteParam(TruckSelectorHttp.truckModels, { id: id }));
  }
  public async getTruckFuel(id: string): Promise<IHttpResponse<ITruckFuel[]>> {
    return serverAxios.get(addRouteParam(TruckSelectorHttp.truckFuel, { id: id }));
  }
  public async getTruckVersion(id: string): Promise<IHttpResponse<ITruckVersion[]>> {
    return serverAxios.get(addRouteParam(TruckSelectorHttp.truckVersion, { id: id }));
  }
  public async getCatalogTruckModels(id: string, catalog_id:string): Promise<IHttpResponse<ITruckModels[]>> {
    return serverAxios.get(addRouteParam(TruckSelectorHttp.truckModels, { id: id }), {params: { catalog_id: catalog_id }});
  }
  public async getCatalogTruckFuel(id: string, catalog_id:string): Promise<IHttpResponse<ITruckFuel[]>> {
    return serverAxios.get(addRouteParam(TruckSelectorHttp.truckFuel, { id: id }), {params: { catalog_id: catalog_id }});
  }
  public async getCatalogTruckVersion(id: string, catalog_id:string): Promise<IHttpResponse<ITruckVersion[]>> {
    return serverAxios.get(addRouteParam(TruckSelectorHttp.truckVersion, { id: id }), {params: { catalog_id: catalog_id }});
  }
}

export default TruckSelectorHttp;
