
import axios from 'axios';

import ModalDelete from 'components/CatalogNaf/ModalDelete/ModalDelete';
import Modal from 'components/modal/Modal';
import Pagination from 'components/pagination/Pagination';

import { IBrand } from 'interfaces/consumableBrand';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import useHttp from 'utils/hooks/useHttp';
import BrandCreate from './ModalCreate/BrandCreate';
import BrandEdit from './ModalEdit/BrandEdit';
import './ConsumableBrand.scss';
import ItemEshopConsumableBrand from 'components/CatalogNaf/itemEshopConsumableBrand/ItemEshopConsumableBrand';
import ConsumableBrandFilter from 'components/filter/ConsumableBrandFilter/ConsumableBrandFilter';

const ConsumableBrand: React.FC = () => {

  const http = useHttp();
  const [searchParams] = useSearchParams();
  const [data, setData] = useState<IBrand[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [forcePage] = useState<number>(searchParams.get('page') ? Number(searchParams.get('page')) : 1);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openModalCreate, setOpenModalCreate] = useState<boolean>(false);
  const [submit, setSubmit] = useState<boolean>(false);
  const [brandId, setBrandId] = useState<number>();
  const [openFilter, setOpenFilter] = useState(false);
  const cancelToken = axios.CancelToken.source();
  const [openModalDelete, setOpenModalDelete] = useState<boolean>(false);
  let isMounted = true;

  const { t } = useTranslation();

  function feach(page) {
    setLoading(true);

    if (Array.from(searchParams).length) {

      let param = {};

      searchParams.forEach((value, key) => {

        param = { ...param, [key]: String(value) };

      });

      http.consumableBrandHttp.getList({ ...{ page: page }, ...param }, cancelToken.token).then((resp) => {
        setData(resp.data.data);
        setTotalPage(resp.data.total_pages);
        setLoading(false);
      }).catch(() => {
        console.log('cancelToken.token!!');
        setLoading(false);
      });
    } else {
      http.consumableBrandHttp.getList({ page: page }, cancelToken.token).then((resp) => {
        setData(resp.data.data);
        setTotalPage(resp.data.total_pages);
        setLoading(false);
      }).catch(() => {
        setLoading(false);
        console.log('cancelToken.token!!');
      });
    }
  }


  useEffect(() => {
    if (isMounted) {
      setLoading(true);
      feach(searchParams.get('page') ? Number(searchParams.get('page')) : 1);
      setSubmit(false);
    }

    return () => {
      isMounted = false;
    };
  }, [submit, searchParams.get('category_ids[]')]);


  const handlePageClick = (page) => {
    setLoading(true);
    feach(page);
  };


  const openModalConsumableBrand = (id: number) => {
    setOpenModal(true);
    setBrandId(id);

  };

  const deleteItem = (id: number) => {
    setOpenModalDelete(true);
    setBrandId(id);
  };

  const handleOnChangeIsPartner = async (e: ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    const id = Number(e.target.value);

    if (!data) {
      return;
    }

    // find the item in the data array by id
    const item = data.find((item) => item.id === id);

    if (!item) {
      return;
    }

    await http.consumableBrandHttp.updateIsPartner(id, { 'is_partner': isChecked });
  };


  return (
    <div className="consumable-brand-container" >
      <div className="body">
        <div className="consumable-brand-header">
          <div className="title">
            {t('_customer_catalog')}
          </div>
          <div className="btn-new">
            <button onClick={() => setOpenModalCreate(true)}>  {t('_create_oil')}</button>
          </div>
          <div className="filter">
            <div className="title-filter">
              {t('_filter_brand')}
            </div>
            <div className="btn-filter">
              <button onClick={() => setOpenFilter(true)}>{t('_filter_result')}</button>
            </div>
          </div>

        </div>

        <hr className="hr" />
        <ItemEshopConsumableBrand data={data} loading={loading} openModalConsumableBrand={openModalConsumableBrand} deleteItem={deleteItem} handleOnChangeIsPartner={handleOnChangeIsPartner} />

        {totalPage > 1 && (
          <Pagination pageCount={totalPage} handlePageClick={handlePageClick} forcePage={forcePage} />
        )}
      </div>

      <ConsumableBrandFilter
        openFilter={openFilter}
        setOpenFilter={setOpenFilter}
        setData={setData}
        setTotalPage={setTotalPage}
        setLoading={setLoading}

      />

      <Modal
        openModal={openModal}
        setOpenModal={setOpenModal}
        childrenComponent={<BrandEdit setOpenModal={setOpenModal} id={brandId} setSubmit={setSubmit} />}
      />

      <Modal
        openModal={openModalCreate}
        setOpenModal={setOpenModalCreate}
        childrenComponent={<BrandCreate setOpenModalCreate={setOpenModalCreate} setSubmit={setSubmit} />}
      />

      <Modal
        openModal={openModalDelete}
        setOpenModal={setOpenModalDelete}
        childrenComponent={<ModalDelete setOpenModalDelete={setOpenModalDelete} setSubmit={setSubmit} id={brandId} link={http.consumableBrandHttp.delete} />}
      />

    </div>

  );

};

ConsumableBrand.displayName = 'ConsumableBrand';

export default ConsumableBrand; 