import React, { useState } from 'react';
import './RetailerOrderView.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { faCircleArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import { MessagesContextProvider } from 'context/MessagesContext';
import RetailerOrderItemsTable from '../../../components/RetailerOrderItemsTable/RetailerOrderItemsTable';
import RetailerOrderInfo from './RetailerOrderInfo/RetailerOrderInfo';
import RetailerOrderMessages from '../../../../../components/messages/RetailerOrderMessages/RetailerOrderMessages';

type Props = {}

const RetailerOrderView: React.FC<Props> = () => {
  const { order_id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);

  return (
    <div className="order-clients-view-page-retailer">
      <div className="order-clients-back" onClick={() => navigate('/orders')}>
        <FontAwesomeIcon icon={faCircleArrowLeft} className="icon" />
        {t('_return_order')}
      </div>
      <div className="order-clients-title-view">

        {t('_order')}  <span style={{ fontWeight: 'bold', marginLeft: '0.5rem' }} > N° {order_id}</span>
      </div>
      <div className="order-info-retailer" >

        <RetailerOrderInfo loading={loading} setLoading={setLoading} />
      </div>

      <div className="order-view-body">
        <div className="order-table">
          <RetailerOrderItemsTable loading={loading} setLoading={setLoading} />
        </div>
        <div className="chat">
          <MessagesContextProvider>
            <RetailerOrderMessages loadingDataFromOrderList={false} supplierName={t('_my_supplier').toLowerCase()} />
          </MessagesContextProvider>
        </div>
      </div>
    </div>
  );
};

export default RetailerOrderView;