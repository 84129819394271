import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Head from 'components/tables/Cells/Head/Head';
import DefaultTable from 'components/tables/DefaultTable/DefaultTable';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Column } from 'react-table';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import { useMobileDetect } from 'utils/hooks/use-mobile-detect';
import { ITyres } from 'utils/rmi/interface/tyres.interface';
import './Tyres.scss';

type Props = {
  data: ITyres[];
  loading: boolean;
  activeTab: string;
  onRowClick?: (row) => void;
};

export const TyresView: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { isMobile } = useDeviceDetect();


  const columns = useMemo(
    (): Column<ITyres>[] => [
      {
        Header: <Head text={t('_front_rim_size').toLocaleUpperCase()}></Head>,
        accessor: 'FrontRimSize',
      },
      {
        Header: <Head text={t('_front_tyre_size').toLocaleUpperCase()}></Head>,
        accessor: 'FrontTyreSize',
      },
      {
        Header: <Head text={t('_rear_rim_size').toLocaleUpperCase()}></Head>,
        accessor: 'RearRimSize',
      },
      {
        Header: <Head text={t('_rear_tyre_size').toLocaleUpperCase()}></Head>,
        accessor: 'RearTyreSize',
      },
      {
        Header: <Head text={t('_application_text').toLocaleUpperCase()}></Head>,
        accessor: 'ApplicationText',
      },
      {
        Header: <Head text={t('_info_text').toLocaleUpperCase()}></Head>,
        accessor: 'InfoText',
      },
      {
        Header: <Head text={t('_hsn_tsn_vsn_selection_required').toLocaleUpperCase()}></Head>,
        accessor: 'HsnTsnVsnSelectionRequired',
        Cell: (item) => item.cell.value && <FontAwesomeIcon icon={faCheck} />,
      },
    ],
    [],
  );


  const hiddenColumns = isMobile ? { hiddenColumns: ['FrontRimSize', 'RearRimSize', 'InfoText', 'HsnTsnVsnSelectionRequired'] } : {};

  return (
    <div className="tyres-table-container">
      {/* {props.loading ? (
        <Spinner class="sellers-table-spinner" />
      ) : (
        
      )} */}
      <DefaultTable columns={columns} data={props.data} loading={false} onRowClick={props.onRowClick ? props.onRowClick : undefined} hiddenColumns={hiddenColumns} />
    </div>
  );
};
