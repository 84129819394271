import React, { useEffect, useState } from 'react';
import { CellProps } from 'react-table';
import { useAppDispatch } from 'utils/hooks/redux';
import useHttp from 'utils/hooks/useHttp';
import { CartTableItem } from '../../CartTable.model';
import DeleteCellView from './DeleteCell.view';
import { setQuantityToolbar } from 'store/slices/cart.slice';


type Props = CellProps<CartTableItem> & {
  setData?
  data?
  rowLoading?: boolean
  setRowLoading?: (state: boolean) => void;
  setTotalPartsPrice?: (totalPartsPrice: number) => void
  idsDelete?
  setIdsDelete?
};

const DeleteCell: React.FC<Props> = (props: Props) => {
  const [loading, setLoading] = useState(false);
  const [loadingDisabled, setLoadingDisabled] = useState(false);
  const http = useHttp();
  const dispatch = useAppDispatch();

  const removeId = (idToRemove) => {
    props.setIdsDelete((prevIds) => prevIds.filter(id => id !== idToRemove));
  };

  const handleDeleteClick = async () => {


    console.log('delete', props.row.original.id);


    const params = {
      is_work_time: !!props.row.original?.work_id
    };

    if (props.setIdsDelete) {
      props.setIdsDelete([...props.idsDelete, props.row.original.id]);
    }

    await http.order.delete(props.row.original.id, params).then(async (res) => {

      props.setData((prevData) => {
        const objects = prevData.filter((obj) => res.data.data.find((item) => item.cart_id == obj.id) != undefined);

        return objects.map((element) => {
          const newItem = res.data.data.find((i) => i.cart_id == element.id);

          element.quantity_availability = { quantity: newItem?.cart_quantity || 0, availability: typeof newItem?.available === 'number' ? newItem?.available : newItem?.available?.quantity };

          return element;
        });
      });

      dispatch(setQuantityToolbar(res.data.total_quantity));

      props.setTotalPartsPrice?.(res.data.total_price ? Number(res.data.total_price) : 0);


    }).finally(() => {
      if (props.setIdsDelete) {
        removeId(props.row.original.id);
      }

    });

  };


  useEffect(() => {


    props.idsDelete.forEach(idToDelete => {


      if (props.row.original?.work_item_id === idToDelete) {

        setLoadingDisabled(true);
      }

      if (props.row.original.id === idToDelete) {

        setLoading(true);
      }

    });

  }, [props.idsDelete]);

  return <DeleteCellView {...props} disabled={props.rowLoading || loadingDisabled} loading={loading} onDelete={handleDeleteClick} />;
};

DeleteCell.displayName = 'DeleteCell';

export default DeleteCell;
