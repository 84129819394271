import React, { useEffect, useState } from 'react';
import CartTable from './CartTable/CartTable';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import TextInput from 'components/inputs/TextInput/TextInput';
import { useTranslation } from 'react-i18next';
import Modal from 'components/modal/Modal';
import Pagination from 'components/pagination/Pagination';
import './Cart.scss';
import CheckoutModal from 'pages/Cart/Content/CheckoutModal/CheckoutModal';
import { getMarketplaceBaseUrl, notify } from 'utils/marketplace';
import useHttp from 'utils/hooks/useHttp';
import { contentStyle } from 'pages/Cart/Content/CheckoutModal/ContentStyleModal';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { CartTableItem } from './CartTable/CartTable.model';
import { GetCartResponse } from 'models/cart.model';
import Spinner from 'components/spinner/Spinner';
import { CartTableContextProvider } from '../../../../context/CartTableContext';
import { setQuantityToolbar } from '../../../../store/slices/cart.slice';
import { useDispatch } from 'react-redux';
import axios from 'axios';

type Props = {};

const Cart: React.FC<Props> = () => {
  const { t } = useTranslation();
  const http = useHttp();
  const [searchParams] = useSearchParams();
  const { getValues, register } = useForm();
  const [loading, setLoading] = useState(false);
  const [rowLoading, setRowLoading] = useState(false);
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState(false);
  const [data, setData] = useState<CartTableItem[]>([]);
  const [curentPage, setCurentPage] = useState<number>(0);
  const [totalPage, setTotalPage] = useState<number>(0);
  const dispatch = useDispatch();
  const [totalPartsPrice, setTotalPartsPrice] = useState<number>(0);
  const cancelToken = axios.CancelToken.source();

  useEffect(() => {
    fetchData(Number(searchParams.get('page')) || 1);
  }, [searchParams.get('page')]);

  function handleCheckout() {
    setLoading(true);
    http.cart
      .checkout(getValues().order_id ? { customer_id: getValues().order_id } : {})
      .then(() => {
        setOpenModal(true);
        fetchData();
        updateCartQuantityToolbar();
      })
      .catch(() => {
        notify('Error');
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const updateCartQuantityToolbar = () => {
    http.cart.getCartQuantity().then((res) => {
      dispatch(setQuantityToolbar(res.quantity));
    });
  };

  const fetchData = async (page = 1) => {
    try {
      setLoadingData(true);
      const response = await http.cart.getCartData(page).finally(() => {
        setLoadingData(false);
      });

      setTotalPage(response.total_pages);
      setCurentPage(response.page);

      const data = response.data.map((item: GetCartResponse): CartTableItem => {
        return {
          id: item.cart_id,
          image: item?.images ? item?.images[0] : item?.image ? item?.image : item?.data_supplier_logo,
          barCode: { image: `${getMarketplaceBaseUrl()}/${item?.barcode?.image}`, code: item?.barcode?.code },
          subcategory: item?.subcategory?.name,
          quantity_availability: {
            quantity: item?.cart_quantity || 0,
            availability: typeof item?.available === 'number' ? item?.available : item?.available?.quantity,
          },
          price: item?.price?.price || 0,
          reference: `${item.data_supplier?.name} ${item?.data_supplier_reference}`,
        };
      });

      setData!(() => data);

      setTotalPartsPrice(Number(response.total_price));
      dispatch(setQuantityToolbar(response.total_quantity));
    } catch (error) {
      setLoadingData(false);
      notify('Error');
      setData!(() => []);
    }
  };

  const handlePageClick = async (page: number) => {
    setLoading(true);

    await fetchData(page);
    setLoading(false);
  };

  return (
    <div className="cart-view-container">
      <div className={`cart-view-body ${loadingData ? 'loading' : null}`}>
        <Modal
          openModal={openModal!}
          setOpenModal={setOpenModal!}
          childrenComponent={<CheckoutModal orderId={getValues().order_id} setOpenModal={setOpenModal} />}
          contentStyle={contentStyle}
        />
        {loadingData ? (
          <Spinner class="small" />
        ) : data && data.length > 0 ? (
          <CartTableContextProvider>
            <p className="cart-title">{t('_your_shopping_carts')}</p>
            <CartTable
              setRowLoading={setRowLoading}
              rowLoading={rowLoading}
              setDataState={setData}
              data={data}
              fetchData={fetchData}
              totalPartsPrice={totalPartsPrice}
              setTotalPartsPrice={setTotalPartsPrice}
              loading={loadingData}
              setLoading={setLoadingData}
              handleCheckout={handleCheckout}
            />
            <div>{totalPage > 1 && <Pagination pageCount={totalPage} handlePageClick={handlePageClick} forcePage={curentPage} />}</div>
            <div className="cart-checkout">
              <p className="title">{t('_internal_cmd')}</p>

              <TextInput type="text" {...register('order_id')} />

              <div className="checkout-button">
                <DefaultButton disabled={rowLoading} isloading={loading} text={t('_confirm_order')} onClick={handleCheckout} red />
              </div>
            </div>
          </CartTableContextProvider>
        ) : (
          <div className="empty_cart">
            <div className="empty_cart_image">
              <img src={require('assets/images/empty_cart.png')} alt="empty_cart" />
            </div>
            <div className="empty_cart_title">{t('_your_shopping_carts_empty')}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Cart;
