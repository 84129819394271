import { AxiosResponse } from 'axios';
import { createSearchParams } from 'react-router-dom';
import { IClientContact, IClientResponse, IClientsFilterParams, IClientContactsForm, IClient, IClientUserSettings, IClientCountResponse, IClientInvitationResponse, IClientInvitation, IClientErpID } from 'models/client.model';
import { addRouteParam, IHttpResponse, serverAxios } from '../../http';
import { IEspaceTabItem } from 'applications/automotor/views/espace/Espace.model';
import { IIdName } from '../../../models/car-parts.model';


class ClientsHttp {
  private static adminApiUri = 'http://admin-api.e-reliable.pro/en_FR/admin'; //TODO add dynamic routing

  private static getClientsRoute = '/retailer/clients';
  private static getClientsAllRoute = '/retailer/clients-all';
  private static getContactsRoute = '/retailer/client/contacts/:id';
  private static clientRoute = '/retailer/client/:id';

  private static createClientRoute = '/retailer/client/register-contact';
  private static filterClientsRoute = '/retailer/clients';

  private static businessTypeOptionsRoute = '/retailer/clients/business-types';
  private static countriesOptionsRoute = '/retailer/clients/countries';

  private static editClientRoute = '/retailer/client/:id/edit';
  private static editContactRoute = '/retailer/client/:client_id/users/:user_id';
  private static addContactRoute = '/retailer/client/:client_id/users';

  private static getLogo = 'clients/logo';
  private static saveClientLogo = '/retailer/client/logo/save';
  // private static deleteClientLogo = '/retailer/client/logo/delete';
  private static deleteClientLogo = '/retailer/client/:id/logo';
  private static deleteWebClientLogo = '/client/:id/logo';

  private static clientUserSettings = '/retailer/client/:id/settings';

  private static clientsInvitationsCount = '/distributor-invitation/count';
  private static clientsInvitations = '/distributor-invitation/all';
  private static clientsInvitationsById = '/distributor-invitation/:id';
  private static clientsInvitationsStatus = '/distributor-invitation/status/:id';
  private static rejectClient = '/retailer/client/decline-invitation';
  private static clients = '/retailer/clients-with-erpid';

  public async getClients(params: { page: number }): Promise<IClientResponse> {
    const { data } = await serverAxios.get(ClientsHttp.getClientsRoute, { params });

    return data;
  }

  public async getClientsAll(): Promise<IIdName[]> {
    const { data } = await serverAxios.get(ClientsHttp.getClientsAllRoute);

    return data;
  }

  public async getClientContacts(id: number): Promise<IClientContact[]> {
    const { data } = await serverAxios.get(addRouteParam(ClientsHttp.getContactsRoute, { id }));

    return data;
  }

  public async getClientDetail(id: number): Promise<IClient> {
    const { data } = await serverAxios.get(addRouteParam(ClientsHttp.clientRoute, { id }));

    return data;
  }

  public async getFilteredClients({ filterBy, filterValue, page, perPage }: IClientsFilterParams): Promise<IClientResponse> {
    const searchParams = createSearchParams([['page', page.toString()], ['per_page', perPage.toString()], [filterBy, filterValue]]);
    const { data } = await serverAxios.get(ClientsHttp.filterClientsRoute + '?' + searchParams);

    return data;
  }

  public async createClient(formData: FormData): Promise<AxiosResponse> {
    return await serverAxios.post(ClientsHttp.createClientRoute, formData, { headers: { 'content-type': 'multipart/form-data' }, });
  }

  public async editClient(id: number, formData: FormData): Promise<AxiosResponse> {
    return await serverAxios.post(addRouteParam(ClientsHttp.editClientRoute, { id }), formData, { headers: { 'content-type': 'multipart/form-data' }, });
  }

  public async getBusinessTypeOptions(): Promise<AxiosResponse> {
    return await serverAxios.get(ClientsHttp.businessTypeOptionsRoute);
  }

  public async deleteClient(id: number): Promise<AxiosResponse> {
    const deleteUrl = ClientsHttp.clientRoute.replace(':id', id.toString());

    return await serverAxios.delete(deleteUrl);
  }

  public async getCountriesOptions(): Promise<AxiosResponse> {
    return await serverAxios.get(ClientsHttp.countriesOptionsRoute);
  }

  public async patchContact(client_id: number, user_id: number, contact: IClientContactsForm): Promise<AxiosResponse> {
    // removed empty fields
    Object.keys(contact).forEach(key => {
      if (typeof contact[key] === 'string') {
        if (contact[key] === '') {
          delete contact[key];
        }
      } else {


        // password {first: string, second: string}
        if (!contact[key]?.first || !contact[key]?.second) {
          delete contact[key];
        } else {
          contact.has_password = true;
        }
      }
    });

    const response = await serverAxios.patch(
      addRouteParam(ClientsHttp.editContactRoute, { client_id, user_id }),
      contact,
    );

    return response;
  }

  public async createContact(client_id: number, contact: IClientContactsForm): Promise<AxiosResponse> {
    const response = await serverAxios.post(
      addRouteParam(ClientsHttp.addContactRoute, { client_id }),
      contact,
    );

    return response;
  }


  public async saveLogo(data: FormData): Promise<IHttpResponse<[]>> {
    return serverAxios.post(ClientsHttp.saveClientLogo, data, { headers: { 'content-type': 'multipart/form-data' } });
  }

  public async deleteLogo(id: string): Promise<IHttpResponse<[]>> {
    return serverAxios.delete(addRouteParam(ClientsHttp.deleteClientLogo, { id }));
  }
  public async deleteLogoWeb(id: string): Promise<IHttpResponse<[]>> {
    return serverAxios.delete(addRouteParam(ClientsHttp.deleteWebClientLogo, { id }));
  }

  public async getLogo(): Promise<AxiosResponse<IEspaceTabItem[]>> {
    return await serverAxios.get(ClientsHttp.getLogo);
  }

  public async patchClientSettings(id: number, values: IClientUserSettings): Promise<IClientUserSettings> {
    const { data } = await serverAxios.patch(addRouteParam(ClientsHttp.clientUserSettings, { id }), values);

    return data;
  }

  public async getClientsInvitationsCount(): Promise<IClientCountResponse> {
    return await serverAxios.get(ClientsHttp.clientsInvitationsCount);
  }

  public async getClientsInvitations(params: URLSearchParams): Promise<IClientInvitationResponse> {
    const { data } = await serverAxios.get(ClientsHttp.clientsInvitations + '?' + params);

    return data;
  }

  public async getClientsInvitationById(id: string): Promise<IClientInvitation> {
    const { data } = await serverAxios.get(addRouteParam(ClientsHttp.clientsInvitationsById, { id }));

    return data;
  }

  public async patchClientInvitation(id: string, status: string): Promise<IClientInvitation> {
    const { data } = await serverAxios.patch(addRouteParam(ClientsHttp.clientUserSettings, { id }), { status });

    return data;
  }
  public async postRejectClient(invitation_id: number, message: string): Promise<IHttpResponse<string>> {
    return serverAxios.post(addRouteParam(ClientsHttp.rejectClient), { invitation_id, message });
  }
  public async listClientErpId(): Promise<IHttpResponse<IClientErpID[]>> {
    return serverAxios.get(ClientsHttp.clients);
  }
}

export default ClientsHttp;
