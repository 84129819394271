import { IAutomotorTariffs, IFileStatus, ISendEmail, IUsers } from 'models/automotor-tariffs.model';
import { serverAxios, commercialAxios, addRouteParam, IHttpResponse } from '../../http';

class AutomotorTariffsHttp {
  private static route = '/automotor-price';
  private static fileStatus = '/loadFile/ZCP/:id';
  private static file = '/client-notification/file/:id';
  private static filev2 = '/price-matrix/file';
  private static automotorSellerEmail = '/automotor-seller-email';

  public async getTariffs(): Promise<IAutomotorTariffs> {
    const { data } = await serverAxios.get(AutomotorTariffsHttp.route);

    return data;
  }
  public async checkFileStatus(id: number): Promise<IFileStatus> {
    const { data } = await commercialAxios.get(addRouteParam(AutomotorTariffsHttp.fileStatus, { id }));

    return data;
  }
  public getFile(id: number, params?: { isNotification?: boolean; name?: string }): Promise<{ data }> {
    return serverAxios.get(addRouteParam(AutomotorTariffsHttp.file, { id }), { params, responseType: 'blob' });
  }

  public getFileV2(params?: { type?: string; codfou?: string }): Promise<{ data }> {
    return serverAxios.get(AutomotorTariffsHttp.filev2, { params, responseType: 'blob' });
  }

  public getUsers(): Promise<IHttpResponse<IUsers[]>> {
    return serverAxios.get(AutomotorTariffsHttp.automotorSellerEmail);
  }
  public sendEmail(params: ISendEmail): Promise<ISendEmail> {
    return serverAxios.post(AutomotorTariffsHttp.automotorSellerEmail, { ...params });
  }
}

export default AutomotorTariffsHttp;
