import Modal from 'components/modal/Modal';
import ComponentsForTypeModal from 'components/ModalTCRMI/ComponentsForTypeModal';
import Spinner from 'components/spinner/Spinner';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import { RootState } from 'store/store';
import useHttp from 'utils/hooks/useHttp';
import { ISelectModalDiagnostic } from 'utils/rmi/interface/diagnostic.interface';
import { IDataModalGraphics, IGraphicsResponce, ISelectModal } from 'utils/rmi/interface/graphics.interface';

import { getCurrentCountryRMI, getCurrentLanguageRMI } from 'utils/rmi/rmi-utils/rmi-utils';
import { NUMBER_REGEX } from 'utils/utils';
import VehicleIframe from '../../VehicleIframe/VehicleIframe';
import ComponentsModal from './ComponentsModal/ComponentsModal';
import './Graphics.scss';
import { useFetchBodiesForGraphics, useFetchComponentLinkedData, useFetchGraphicsHtml, useFetchGraphicsMainGroupHtml, useFetchGraphicsSubGroupHtml } from './Request/TcrmiRequest';

const Graphics: React.FC = () => {

  const http = useHttp();
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [iframeData, setIframeData] = useState<string>();
  const [loadingHTML, setLoadingHTML] = useState(false);
  const [loading, setLoading] = useState(false);
  const { typeId } = useSelector((state: RootState) => state.tecrmi);
  const [searchParams, setSearchParams] = useSearchParams();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [boxDetailOptions, setBoxDetailOptions] = useState<ISelectModal[]>([]);
  const [paramType, setParamType] = useState<string>('');
  const [openModalGrafics, setOpenModalGrafics] = useState<boolean>(false);
  const [boxDetailOptionsGrafics, setBoxDetailOptionsGrafics] = useState<IDataModalGraphics[]>([]);
  const navigate = useNavigate();

  useFetchBodiesForGraphics({ typeId, setLoading, setOpenModal, setBoxDetailOptions, setParamType });
  useFetchGraphicsHtml({ setLoadingHTML, typeId, setIframeData });
  useFetchGraphicsMainGroupHtml({ typeId, setLoadingHTML, setIframeData });
  useFetchGraphicsSubGroupHtml({ setLoadingHTML, typeId, setIframeData });
  useFetchComponentLinkedData({ typeId, setOpenModalGrafics, setBoxDetailOptionsGrafics });

  const onMainGroupIdClickHandler = (id: string): void => {
    const mainGroupId = Number((id?.match(NUMBER_REGEX) ?? []).join(''));

    searchParams.set('main_group_id', String(mainGroupId));

    setSearchParams(searchParams);

  };


  //click 3 step
  const onSubGroupIdClickHandler = (id: string): void => {

    const subgroupId = Number((id?.match(NUMBER_REGEX) ?? []).join(''));


    setLoadingHTML(true);
    http.graphicstHttp.fetchRMIGraphicsSubGroupQuals({
      typeId,
      countryCode: getCurrentCountryRMI(i18n.language),
      languageCode: getCurrentLanguageRMI(i18n.language),
      bodyQualColId: Number(searchParams.get('body_qualcol_id')),
      mainGroupId: Number(searchParams.get('main_group_id')),
      subGroupId: Number(subgroupId)
    })
      .then((res) => {

        if (res.data.length == 1) {
          if (subgroupId) {
            searchParams.set('sub_group_qualcol', String(res.data[0].QualColId));
            searchParams.set('sub_group_id', String(subgroupId));
            setSearchParams(searchParams);
          }
        } else {
          const boxDetailListOptions: Array<ISelectModal> = [];

          res.data.forEach((opt: IGraphicsResponce): void => {

            boxDetailListOptions.push(
              {
                value: opt.QualColId,
                label: opt.QualColText,
              },
            );


          });
          setParamType('sub_group_qualcol');
          setBoxDetailOptions(boxDetailListOptions);
          setOpenModal(true);
          searchParams.set('sub_group_id', String(subgroupId));
          setSearchParams(searchParams);
        }


      });
  };


  const onItemGroupIdClickHandler = (id: string): void => {
    const itemGroupId = Number((id?.match(NUMBER_REGEX) ?? []).join(''));

    searchParams.set('item_group_id', String(itemGroupId));
    setSearchParams(searchParams);
  };

  const setSelectHandler = (item: ISelectModalDiagnostic) => {
    searchParams.set(paramType, String(item.value));
    setSearchParams(searchParams);
  };

  return (
    <div className="technical-documentation-graphics">
      {loading ? <Spinner class="extra-small" /> : (


        <div className="diagnostic-content">
          {
            searchParams.get('body_qualcol_id') ? (
              <button
                type="button"
                className={`btn-link ${(searchParams.get('body_qualcol_id') && !searchParams.get('main_group_id')) ? 'active' : ''}`}
                disabled={Boolean(searchParams.get('body_qualcol_id') && !searchParams.get('main_group_id'))}
                onClick={() => {
                  const params = createSearchParams([
                    ['source', String(searchParams.get('source'))],
                    ['vehicle_identifier', String(searchParams.get('vehicle_identifier'))],
                    ['body_qualcol_id', String(searchParams.get('body_qualcol_id'))],

                  ]);

                  navigate({
                    search: `?${params}`,
                  });
                }
                }
              >
                {t('_main_group')}
              </button>
            ) : null
          }

          {
            searchParams.get('body_qualcol_id') && searchParams.get('main_group_id') ? (
              <button
                type="button"
                className={`btn-link ${(searchParams.get('main_group_id') && !searchParams.get('sub_group_id')) ? 'active' : ''}`}
                disabled={Boolean(searchParams.get('main_group_id') && !searchParams.get('sub_group_id'))}
                onClick={() => {
                  const params = createSearchParams([
                    ['source', String(searchParams.get('source'))],
                    ['vehicle_identifier', String(searchParams.get('vehicle_identifier'))],
                    ['body_qualcol_id', String(searchParams.get('body_qualcol_id'))],
                    ['main_group_id', String(searchParams.get('main_group_id'))],
                  ]);

                  navigate({
                    search: `?${params}`,
                  });
                }
                }
              >
                {t('_sub_group')}
              </button>
            ) : null
          }

          {
            searchParams.get('body_qualcol_id') && searchParams.get('main_group_id') && searchParams.get('sub_group_id') && searchParams.get('sub_group_qualcol') ? (
              <button type="button" className="btn btn-link active" disabled>
                {
                  t('_component')
                }
              </button>
            ) : null
          }
          {iframeData &&
            <VehicleIframe
              loading={loadingHTML}
              vehicleIframe={iframeData!}
              onIframeAnchorClick={(!searchParams.get('sub_group_id') && !searchParams.get('main_group_id') && searchParams.get('body_qualcol_id')) ? onMainGroupIdClickHandler
                : ((!searchParams.get('sub_group_id') && searchParams.get('main_group_id') && searchParams.get('body_qualcol_id')) ? onSubGroupIdClickHandler : onItemGroupIdClickHandler)}
            />
          }
        </div>
      )}
      <Modal
        openModal={openModal}
        setOpenModal={setOpenModal}
        shouldCloseOnOverlayClick={false}
        childrenComponent={<ComponentsForTypeModal setOpenModal={setOpenModal} boxDetailOptions={boxDetailOptions} setSelectHandler={setSelectHandler} />}
      />
      <Modal
        openModal={openModalGrafics}
        setOpenModal={setOpenModalGrafics}
        childrenComponent={<ComponentsModal setOpenModalGrafics={setOpenModalGrafics} openModalGrafics={openModalGrafics} boxDetailOptionsGrafics={boxDetailOptionsGrafics} />}
      />
    </div>


  );
};

export default Graphics;