import React, { useEffect, useState } from 'react';
import { useNavigate, createSearchParams, generatePath } from 'react-router-dom';
import { getCarDescription, getTruckDescription } from 'utils/getCarDescription';
import { ICars, IModelsVin, ITrucks, IVinData } from 'utils/hooks/http/search-by-vin.http';
import { getCurrentCatalog } from 'store/selectors/catalogs.selector';
import './DropDownContent.scss';
import { useTranslation } from 'react-i18next';
import useHttp from 'utils/hooks/useHttp';
import { useSelector } from 'react-redux';

type Props = {
  vin: string;
  vinData: IVinData | undefined;
  setIsOpen: (boolean) => void;
  setOpenModal?: (e: boolean) => void;
  link?: string;
  viewAllModelsTecalliance: () => void;
  catalog?: boolean;
  submit?;
};

const DropDownContent: React.FC<Props> = (Props) => {
  const { vinData } = Props;
  const { searchByVin } = useHttp();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [sugestion, setSugestion] = useState<ICars[] | ITrucks[] | IModelsVin[] | null>();
  const currentCatalog = useSelector(getCurrentCatalog);

  const checkCarSelected = (): null | ICars | ITrucks => {
    if (!Props.vinData) {
      return null;
    }

    let selectedCars: [] | ICars[] | ITrucks[] = [];
    let selectedCar: null | ICars | ITrucks = null;

    if (Props.vinData.cars?.cars) {
      selectedCars = Props.vinData.cars.cars.filter((car) => car.is_selected);
    }

    if (Props.vinData.cars?.trucks) {
      selectedCars = Props.vinData.cars.trucks.filter((car) => car.is_selected);
    }

    if (selectedCars && selectedCars.length > 0) {
      selectedCar = selectedCars[0];
    }

    return selectedCar;
  };

  const [selectedSuggestion, setSelectedSuggestion] = useState<ICars | ITrucks | null>(checkCarSelected());
  const [viewAllModels, setViewAllModels] = useState(selectedSuggestion === null);

  const [vehicleType, setVehicleType] = useState<string>('');

  const vinHandler = (car: ICars | ITrucks) => {
    if (Props.link) {
      {
        navigate({
          pathname: `/reliable/rmi/${car.id}${Props.link}`,
          search: createSearchParams({
            source: car.source,
            vehicle_identifier: car.vehicle_identifier,
            vin: Props.vin,
          }).toString(),
        });
      }
    } else if (Props?.catalog) {
      navigate({
        pathname: generatePath('/catalog/:id/categories', {
          id: String(currentCatalog?.id),
        }),
        search: `?${createSearchParams([
          ['source', car.source],
          ['vehicle_identifier', car.vehicle_identifier],
          ['vin', Props.vin],
          ['ktypnr', String(car.id)],
        ])}`,
      });
    } else {
      {
        navigate({
          pathname: `/reliable/rmi/${car.id}/catalogs/parts`,
          search: createSearchParams({
            source: car.source,
            vehicle_identifier: car.vehicle_identifier,
            vin: Props.vin,
          }).toString(),
        });
      }
    }

    if (Props.setOpenModal != undefined) {
      Props.setOpenModal(false);
    }
  };

  const vinUpdateHandler = (car: ICars) => {
    if (car !== selectedSuggestion) {
      searchByVin.updateVinCar({ vin: Props.vin, ktypnr: String(car.id) }).catch((e) => {
        console.log(e);
      });
    }

    searchByVin.updateVinCache({ vin: Props.vin, ktypnr: String(car.id), cv_manufacturer: vehicleType === 'cars' ? 0 : 1 }).catch((e) => {
      console.log(e);
    });

    setSelectedSuggestion(car);

    vinHandler(car);
  };

  useEffect(() => {
    if (vinData?.cars?.cars) {
      setVehicleType('cars');
      setSugestion(vinData?.cars?.cars);
    } else if (vinData?.cars?.trucks) {
      setVehicleType('trucks');
      setSugestion(vinData?.cars?.trucks);
    } else if (vinData?.models) {
      setVehicleType('models');
      setSugestion(vinData?.models);
    } else {
      setSugestion(null);
    }
  }, [vinData]);

  return (
    <div className="suggestion-list-container">
      {selectedSuggestion && (
        <li className="suggestion-list-item selected" onClick={() => vinHandler(selectedSuggestion)}>
          <div className="suggestion-item-title">
            <span>
              {vehicleType === 'cars' ? getCarDescription(selectedSuggestion as ICars) : getTruckDescription(selectedSuggestion as ITrucks)}
            </span>
          </div>
        </li>
      )}
      {viewAllModels &&
        (sugestion && sugestion.length ? (
          sugestion.map((item, index) => {
            if (item !== selectedSuggestion) {
              return (
                <li key={index} className="suggestion-list-item" onClick={() => vehicleType != 'models' && vinUpdateHandler(item)}>
                  <div className="suggestion-item-title">
                    <span>{vehicleType === 'cars' || vehicleType === 'models' ? getCarDescription(item) : getTruckDescription(item)}</span>
                  </div>
                </li>
              );
            }
          })
        ) : (
          <div className="no-data">{t('_no_data').toLocaleUpperCase()}</div>
        ))}
      {/* {!viewAllModels && sugestion && sugestion.length > 1 && (
        <button className="change-model-btn" type="button" onClick={() => setViewAllModels(true)}>
          Select another car variant
        </button>
      )} */}

      {Props.vinData?.search_from_tecalliance && (
        <button className="change-model-btn" type="button" onClick={() => Props.viewAllModelsTecalliance()}>
          {t('_select_cars')}
        </button>
      )}
    </div>
  );
};

DropDownContent.displayName = 'DropDownContent';

export default DropDownContent;
