import FR from '../../assets/images/flags/FR.svg';
import AE from '../../assets/images/flags/AE.svg';
import CN from '../../assets/images/flags/CN.svg';
import CO from '../../assets/images/flags/CO.svg';
import DZ from '../../assets/images/flags/DZ.svg';
import EG from '../../assets/images/flags/EG.svg';
import IE from '../../assets/images/flags/IE.svg';
import IR from '../../assets/images/flags/IR.svg';
import IT from '../../assets/images/flags/IT.svg';
import KR from '../../assets/images/flags/KR.svg';
import KW from '../../assets/images/flags/KW.svg';
import RU from '../../assets/images/flags/RU.svg';
import SA from '../../assets/images/flags/SA.svg';
import TR from '../../assets/images/flags/TR.svg';
import US from '../../assets/images/flags/US.svg';
import UK from '../../assets/images/flags/UK.svg';
import ES from '../../assets/images/flags/ES.svg';
import KSA from '../../assets/images/flags/KSA.svg';
import { IDropdownModel } from '../../components/dropdowns/Dropdown.model';
import { IClientOption, IOption } from 'interfaces/select';

export const LOCALE = 'locale';

export const DEFAULT_COUNTRY_IMAGE = FR;

export const DEFAULT_LANGUAGE = 'fr';

export const DEFAULT_COUNTRY = 'FR';

export const DEFAULT_LOCALE = `${DEFAULT_LANGUAGE}-${DEFAULT_COUNTRY}`;

export const languages: IDropdownModel[] = [
  {
    id: 'en',
    text: 'English',
    image: UK,
  },
  {
    id: 'fr',
    text: 'Français',
    image: FR,
  },
  {
    id: 'ru',
    text: 'Русский',
    image: RU,
  },
  {
    id: 'es',
    text: 'Español',
    image: ES,
  },
  {
    id: 'en',
    text: 'English (USA)',
    image: US,
  },
  {
    id: 'it',
    text: 'Italiano',
    image: IT,
  },
  {
    id: 'tr',
    text: 'Türkçe',
    image: TR,
  },
  {
    id: 'ar',
    text: 'العربية',
    image: KSA,
  },
  {
    id: 'zh',
    text: '中文',
    image: CN,
  },
  {
    id: 'ko',
    text: '한국어',
    image: KR,
  },
];

export const countries: IDropdownModel[] = [
  {
    id: 'FR',
    text: 'France',
    image: FR,
  },
  {
    id: 'IT',
    text: 'Italy',
    image: IT,
  },
  {
    id: 'IE',
    text: 'Ireland',
    image: IE,
  },
  {
    id: 'DZ',
    text: 'Algeria',
    image: DZ,
  },
  {
    id: 'CO',
    text: 'Colombia',
    image: CO,
  },
  {
    id: 'RU',
    text: 'Russia',
    image: RU,
  },
  {
    id: 'CN',
    text: 'China',
    image: CN,
  },
  {
    id: 'KR',
    text: 'Korea',
    image: KR,
  },
  {
    id: 'EG',
    text: 'Egypt',
    image: EG,
  },
  {
    id: 'TR',
    text: 'Turkey',
    image: TR,
  },
  {
    id: 'IR',
    text: 'Iran',
    image: IR,
  },
  {
    id: 'KW',
    text: 'Kuwait',
    image: KW,
  },
  {
    id: 'SA',
    text: 'Saudi Arabia',
    image: SA,
  },
  {
    id: 'AE',
    text: 'United Arab Emirates',
    image: AE,
  },
  {
    id: 'US',
    text: 'United States of America',
    image: US,
  },
];

export const immatCountry = [
  {
    text: 'F',
    value: 'fr',
  },
  {
    text: 'E',
    value: 'es',
  },
  {
    text: 'D',
    value: 'de',
  },
  {
    text: 'P',
    value: 'pl',
  },
  {
    text: 'I',
    value: 'it',
  },
  {
    text: 'N',
    value: 'nl',
  },
];

export const getLanguagesAsClientsOption: () => IOption[] = () => {
  return languages.map((item) => ({
    label: item.text || '',
    value: item.id || '',
  })) || [];
};
