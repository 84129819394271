import React from 'react';
import EspaceClientView from './EspaceCatalog.view';

const EspaceCatalog: React.FC = () => {
  return <EspaceClientView />;
};

EspaceCatalog.displayName = 'EspaceCatalog';

export default EspaceCatalog;
