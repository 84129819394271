
import { CancelToken } from 'axios';
import { IHttpResponse, serverAxios } from '../../http';
import { IBodyResponseCategory, ICategory, IParamsCategory } from 'interfaces/oilCategory';

class OilCategoryHttp {

  private static list = 'oil/category/list';
  private static info = '/oil/category/';
  private static update = '/oil/category/';
  private static create = '/oil/category';
  private static delete = '/oil/category/';

  public getList(urlParams: IParamsCategory, cancelToken: CancelToken): Promise<IHttpResponse<IBodyResponseCategory>> {
    return serverAxios.get(OilCategoryHttp.list, { params: urlParams, cancelToken: cancelToken });
  }


  public getCategoryInfo(id: number | undefined): Promise<IHttpResponse<ICategory>> {
    return serverAxios.get(OilCategoryHttp.info + id);
  }

  public edit(id: number, data: FormData): Promise<IHttpResponse<ICategory>> {
    return serverAxios.post(OilCategoryHttp.update + id, data);
  }

  public create(data: FormData): Promise<IHttpResponse<ICategory>> {
    return serverAxios.post(OilCategoryHttp.create, data);
  }

  public delete(id: number): Promise<IHttpResponse<null>> {
    return serverAxios.delete(OilCategoryHttp.delete + id);
  }
}

export default OilCategoryHttp;
