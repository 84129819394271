import DropdownContainer from 'components/dropdown-container/DropdownContainer';
import React from 'react';
import { FieldErrors, UseFormHandleSubmit, UseFormRegister } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IVinData } from 'utils/hooks/http/search-by-vin.http';
import TextInput from '../../inputs/TextInput/TextInput';
import DropDownContent from './parts/DropDownContent/DropDownContent';
import './VinSearch.scss';

type Props = {
  errors: FieldErrors<IVinForm>;
  vin: string;
  register: UseFormRegister<IVinForm>;
  handleSubmit: UseFormHandleSubmit<IVinForm>;
  onSubmit: (data: object) => void;
  vinData: IVinData | undefined;
  isLoading: boolean;
  isOpen: boolean;
  setIsOpen: (data: boolean) => void;
  setOpenModal?: (e: boolean) => void;
  link?: string;
  viewAllModelsTecalliance: () => void;
  catalog?: boolean;
  setRefreshResults?: (bool) => void;
};

const VinSearchView: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  return (
    <div className="vin">
      <form className="form" onSubmit={props.handleSubmit(props.onSubmit)}>
        <label className="reference-label">{t('_vin')}</label>
        <TextInput
          placeholder={t('_vin')}
          {...props.register('vin', {
            required: true,
            pattern: { value: /^(?=.*[0-9])(?=.*[A-z])[0-9A-z-]{11,17}$/, message: t('_invalid_vin') },
            minLength: {
              value: 17,
              message: t('_invalid_vin_length'),
            },
            maxLength: {
              value: 17,
              message: t('_invalid_vin_length'),
            },
          })}
          withIcon
          onClick={props.handleSubmit(props.onSubmit)}
        />
        {props.errors?.vin?.message && <p className="error-message">{t(props.errors?.vin?.message)}</p>}

        {props.isOpen && (
          <DropdownContainer
            setIsOpen={props.setIsOpen}
            loading={props.isLoading}
            setRefreshResults={props.setRefreshResults}
            refreshResults
          >
            <DropDownContent
              catalog={props.catalog}
              vin={props.vin}
              vinData={props.vinData}
              setIsOpen={props.setIsOpen}
              setOpenModal={props.setOpenModal}
              link={props.link}
              viewAllModelsTecalliance={props.viewAllModelsTecalliance}
            />
          </DropdownContainer>
        )}
      </form>
    </div>
  );
};

VinSearchView.displayName = 'VinSearchView';

export interface IVinForm {
  vin: string;
  refresh_search?: boolean;
}

export default VinSearchView;
