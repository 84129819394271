import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { fetchClients, fetchFilteredClients } from 'store/reducers/clients/clients.actions';
import { getClients, getClientsPage, getFilteredClients, getIsLoadingClients } from 'store/selectors/clients.selector';
import { setPage } from 'store/slices/clients.slice';
import { IClientsFilterParams, IClientFilters } from 'models/client.model';
import { useAppDispatch } from 'utils/hooks/redux';
import MyClientsView from './MyClients.view';
import useHttp from 'utils/hooks/useHttp';
import {notify} from '../../utils/marketplace';
import {useTranslation} from 'react-i18next';

type Props = {};

const MyClients: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const clients = useSelector(getClients);
  const clientsPage = useSelector(getClientsPage);
  const filteredClients = useSelector(getFilteredClients);
  const [searchParams] = useSearchParams();
  const [showNewClient, setShowNewClient] = useState<boolean>(false);
  const [isFilterActive, setFilterActive] = useState<boolean>(false);
  const [searchInputValue, setSearchInputValue] = useState<string>('');
  const [searchFilterType, setSearchFilterType] = useState<IClientFilters>(IClientFilters.NAME);
  const isLoading = useSelector(getIsLoadingClients);
  const [invitationsCount, setInvitationsCount] = useState<number>(0);
  const navigate = useNavigate();
  const http = useHttp();
  const {t} = useTranslation();

  const handlePageClick = (pageN: number) => {
    if (isFilterActive) {
      onFilterSubmit(pageN);
    } else {
      dispatch(setPage(pageN + 1));
    }
  };

  const handleNewClientModal = () => {
    setShowNewClient((prevState: boolean) => !prevState);
  };

  const changeSearchHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInputValue(event.target.value);
  };

  const changeRadioHandler = (value: string) => {
    setSearchInputValue(value);
  };

  const changeFilterHandler = (newFilterType: IClientFilters) => {
    if (searchFilterType === IClientFilters.ACTIVE) {
      setSearchInputValue('');
    }

    setSearchFilterType(newFilterType);
  };

  const getInvitationsCount = () => {
    http.clients.getClientsInvitationsCount().then((res) => {
      setInvitationsCount(res.data);
    });
  };

  useEffect(() => {
    dispatch(fetchClients(Number(searchParams.get('page') || 1)));
    getInvitationsCount();
  }, [clientsPage]);

  const onFilterSubmit = (pageN?: number) => {
    const params: IClientsFilterParams = {
      filterBy: searchFilterType,
      filterValue: searchInputValue,
      page: Number(pageN || searchParams.get('page') || 1),
      perPage: clients?.per_page || 20,
    };

    if (searchFilterType === IClientFilters.ID) {
      if(isNaN(Number(searchInputValue))) {
        notify(t('_invalid_number_validation'), 'error');

        return;
      }

      if(searchInputValue.length > 9) {
        notify(t('_length_to_big_validation'), 'error');

        return;
      }
    }

    if (searchFilterType === IClientFilters.ACTIVE) {
      if (!searchInputValue) {
        params.filterValue = 'true';
      } else {
        params.filterValue = searchInputValue === 'yes' ? 'true' : 'false';
      }
    }

    if (params.filterValue) {
      setFilterActive(true);
    } else {
      setFilterActive(false);
      dispatch(fetchClients(Number(searchParams.get('page') || 1)));

      return;
    }

    dispatch(fetchFilteredClients(params));
  };

  const navigatePendingInvitations = () => {
    navigate('/espace-clients/my-clients/invitations');
  };

  return (
    <MyClientsView
      clients={filteredClients || clients}
      clientsPage={clientsPage}
      showNewClient={showNewClient}
      searchInputValue={searchInputValue}
      handleNewClientModal={handleNewClientModal}
      changeRadioHandler={changeRadioHandler}
      handlePageClick={handlePageClick}
      changeSearchHandler={changeSearchHandler}
      onFilterSubmit={onFilterSubmit}
      onChangeFilterType={changeFilterHandler}
      navigatePendingInvitations={navigatePendingInvitations}
      searchFilterType={searchFilterType}
      isLoading={isLoading}
      invitationsCount={invitationsCount}
    ></MyClientsView>
  );
};

MyClients.displayName = 'MyClients';

export default MyClients;
