import React from 'react';
import './CategoriesCell.scss';

type Props = {
  value: string;
};

const CategoriesCellView: React.FC<Props> = (props: Props) => {
  return <div className="category-cell-wrapper">{props.value}</div>;
};

CategoriesCellView.displayName = 'CategoriesCellView';

export default CategoriesCellView;
