import React from 'react';
import {IProformaAmerigoRowItem} from '../../../../../../../../../utils/hooks/http/proforma.http';
import ProformaDataItem from './ProformaDataItem/ProformaDataItem';

type Props = {
  data: IProformaAmerigoRowItem[]
  header: string[];
  rowKey: string;
}

const ProformaRowData: React.FC<Props> = (props) => {

  return (
    <div>
      {
        props.data.map((item, index) => {
          return (
            <ProformaDataItem index={index} header={props.header} rowKey={props.rowKey} key={index} data={item}/>
          );
        }
        )}
    </div>
  );
};

ProformaRowData.displayName = 'ProformaRowData';

export default ProformaRowData;