import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
// import {translations} from './translations';
import LazyImportPlugin from './languages/LazyimportPlugin';
import { DEFAULT_LOCALE, LOCALE } from './utils/constants/locales';


if (!localStorage.getItem(LOCALE) && localStorage.getItem('token')) {
  localStorage.setItem(LOCALE, DEFAULT_LOCALE);
}

i18n
  .use(LazyImportPlugin)
  .use(initReactI18next)
  .init({
    lng: window.localStorage.getItem(LOCALE) || DEFAULT_LOCALE,
    fallbackLng: DEFAULT_LOCALE,
    interpolation: {
      escapeValue: false,
    },
    ns: ['Common'],
    saveMissing: true, // for missing key handler to fire
    missingKeyHandler: function () {
      // console.log(key);
    },
  });

export default i18n;
