import React from 'react';
import { getMarketplaceBaseUrl } from 'utils/marketplace';
import './BarCode.scss';

type Props =  {
  item,
  className?: string;
}

const BarCode:React.FC<Props> = (props): JSX.Element => {
  const item = props.item;

  if (!item?.barcode?.image) {
    return (
      <div className="item-option-barcode"> </div>

    );
  }

  return (
    <div className="item-option-barcode">
      <img src={`${getMarketplaceBaseUrl() ?? ''}/${item.barcode.image}`} alt="barcode" />
      <span>{item.barcode.code}</span>
    </div>
  );
};

BarCode.displayName = 'BarCode';

export default BarCode;
