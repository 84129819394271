
import React from 'react';
import './CollapseList.scss';

type Props = {
  data?
  onItemMpManualClick?: (value: boolean) => void;
  activeItem?: string | number
}

const ItemGroup: React.FC<Props> = (props) => {

  return (
    <div className="collapse-list-diagnostic">
      <div className="item last-level">
        <div onClick={() => props.onItemMpManualClick!(props.data)}>{props.data.QualColText} </div>
      </div>
    </div>
  );
};

export default ItemGroup;