import React, { useState } from 'react';
import Spinner from 'components/spinner/Spinner';
import { useTranslation } from 'react-i18next';
import { createSearchParams, generatePath } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRepeat } from '@fortawesome/free-solid-svg-icons';
import useHttp from 'utils/hooks/useHttp';
import { notify } from 'utils/marketplace';

type Props = {
  item
  setReferenceSearch
  setRefDataAutocomplete
  setShowModal: (boolean) => void
};

const BtnEquivalence: React.FC<Props> = (props) => {

  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const http = useHttp();

  function showEquivalence(id: string, type) {
    document.getElementById(String(id))!.style.display = type;
  }


  function getData(item) {

    setIsLoading(true);

    http.searchByRef.routeGlobalSearchSimple(
      {
        q: (item.ind_equivalence && item.ind_equivalence_select ? item.ind_equivalence.reference : item.reference),
        codfou: (item.ind_equivalence && item.ind_equivalence_select ? item.ind_equivalence.codfou : item.codfou)
      }
    )
      .then((res) => {
        if (res.data?.parts?.length) {
          if (res.data?.parts?.length == 1) {
            const param = {
              page: String(1),
              ...(res.data?.parts[0].source && { source: res.data?.parts[0].source }),
              ...(res.data?.parts[0].subcategory_id && { subcategory_id: String(res.data?.parts[0].subcategory_id) }),
              ...(res.data?.parts[0].man_sup_id && { man_sup_id: String(res.data?.parts[0].man_sup_id) }),
              ...(res.data?.parts[0].reference && { q: res.data?.parts[0].reference }),
              ...(res.data?.parts[0].type && { type: res.data?.parts[0].type }),
            };

            const url = `${generatePath('/car-parts')}?${createSearchParams(param)}`;

            // Deschide URL-ul într-un tab nou
            window.open(url, '_blank');

          } else {
            props.setReferenceSearch(item);
            props.setRefDataAutocomplete(res.data?.parts);
            props.setShowModal(true);
          }

        } else {
          props.setReferenceSearch(item);
          props.setRefDataAutocomplete([]);
          props.setShowModal(true);
        }

        setIsLoading(false);
      }).catch((err) => {
        if (err) {
          notify(t('_error'));
          setIsLoading(false);
        }
      });

  }

  return (
    isLoading ? <Spinner class="extra-small" /> :
      < div className="action_btn_info" >

        <FontAwesomeIcon
          icon={faRepeat}
          className="action_btn_icons"
          onMouseEnter={() =>
            showEquivalence(String('ref_equivalence' + String(props.item.reference_full + props.item.name)), 'block')
          }
          onMouseLeave={() =>
            setTimeout(() => {
              showEquivalence(String('ref_equivalence' + String(props.item.reference_full + props.item.name)), 'none');
            }, 100)
          }
          onClick={() => getData(props.item)}
        />
        <div
          id={String('ref_equivalence' + String(props.item.reference_full + props.item.name))}
          className="ref_equivalence"
        >
          {t('_equivalence')}
        </div>
      </div >

  );
};

BtnEquivalence.displayName = 'ModalReferenceEquivalences';

export default BtnEquivalence;
