import React from 'react';
import VideoSection from 'components/VideoSection/VideoSection';
import { useSelector } from 'react-redux';
import { getCurrentCatalog } from 'store/selectors/catalogs.selector';
import './CatalogVideo.scss';
import CatalogSupplierNavbar from 'components/navbar/catalog-supplier-navbar/CatalogSupplierNavbar';
import { useTranslation } from 'react-i18next';

type Props = {};

const CatalogVideo: React.FC<Props> = () => {
  const currentCatalog = useSelector(getCurrentCatalog);
  const { t } = useTranslation();

  return (
    <div className="catalog-supplier-layout-container">
      <CatalogSupplierNavbar title={`${t('Vidéos de montage')} /`} />
      <div className="tariff-page-container">
        <div className="main-container">
          <VideoSection previewImage={currentCatalog?.image} perPage={5} showPagination />
        </div>
      </div>
    </div>
  );
};

export default CatalogVideo;
