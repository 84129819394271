import React, { useState } from 'react';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import CatalogView from './Catalog.view';
import CatalogMobileView from './Mobile/CatalogMobile.view';


const Catalog: React.FC = () => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openMessageModal, setOpenMessageModal] = useState<boolean>(false);
  const [openCatalogModal, setOpenCatalogModal] = useState<boolean>(false);
  const [loading] = useState(false);

  const { isMobile } = useDeviceDetect(950);

  return isMobile ? (
    <CatalogMobileView
      loading={loading}
      openModal={openModal}
      setOpenModal={setOpenModal}
      openCatalogModal={openCatalogModal}
      setOpenCatalogModal={setOpenCatalogModal}
      setOpenMessageModal={setOpenMessageModal}
      openMessageModal={openMessageModal}
    />
  ) : (
    <CatalogView
      loading={loading}
      openModal={openModal}
      setOpenModal={setOpenModal}
      openCatalogModal={openCatalogModal}
      setOpenCatalogModal={setOpenCatalogModal}
      setOpenMessageModal={setOpenMessageModal}
      openMessageModal={openMessageModal}
    />
  );
};

Catalog.displayName = 'Catalog';

export default Catalog;
