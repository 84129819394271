import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { ReactComponent as PartsNotFoundImage } from 'assets/images/parts-not-found.svg';
import Pagination from 'components/pagination/Pagination';
import './PartsList.scss';
import Spinner from 'components/spinner/Spinner';
import { useTranslation } from 'react-i18next';
import useHttp from 'utils/hooks/useHttp';
import { ICarPart, ICarPartData, IPartsFiltersResponse, ICarFilteredPart } from 'models/car-parts.model';
import { useSearchParams, useLocation, useParams } from 'react-router-dom';
import UniversalPartsNavbar from 'components/navbar/universal-parts-navbar/UniversalPartsNavbar';
import PartsFilter from './PartsFilter/PartsFilter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import { useOnClickOutside } from 'utils/hooks/useOnClickOutside';
import PopupInfo from '../PopupInfo/PopupInfo';
import { getCurrentCountryRMI, getCurrentLanguageRMI } from 'utils/rmi/rmi-utils/rmi-utils';
import i18n from 'i18n';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { IRepairItemsWorkItemMps, IVehicleRepairItemWork } from 'utils/rmi/interface/repair-items.interface';
import { CAR_PRO, CAR_TECH } from 'utils/rmi/reliableTabs';
import ProductsViewMode from '../ProductsViewMode/ProductsViewMode';
import { DEFAULT_VIEW } from 'components/ProductsViewMode/ProductsViewMode.constants';
import { CATALOG_PART_OEM } from 'components/ProductsViewModeChanger/products-view-mode-changer-utils';
import ProductsViewModeChanger from 'components/ProductsViewModeChanger/ProductsViewModeChanger';
import SortBy, { SortByParams } from '../SortBy/SortBy';
import { sortByOptions } from '../SortBy/SortBy.map';
import { MARKETPLACE_AMERIGO } from 'utils/constants/defaultConstants';
import { getCurrentMarketplace } from 'utils/marketplace';

type Props = {
  inCatalog?: boolean
}

const PartsList: React.FC<Props> = (props) => {

  const { t } = useTranslation();
  const http = useHttp();
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [refData, setRefData] = useState<ICarPart>();
  const [filterData, setFilterData] = useState<IPartsFiltersResponse>();
  const [localyFilteredData, setLocalyFilteredData] = useState<ICarFilteredPart>();
  const [openFilter, setOpenFilter] = useState(false);
  const [page, setPage] = useState(Number(searchParams.get('page')));
  const { pathname } = useLocation();
  const isVehiclePartCatalog = pathname.includes('part-catalog');
  const { vehicle_id } = useParams<'vehicle_id'>();
  const { typeId } = useSelector((state: RootState) => state.tecrmi);
  const [popupInfoIsOpen, setPopupInfoIsOpen] = useState<boolean>(false);
  const popupInfoRef = useRef() as MutableRefObject<HTMLDivElement>;
  const [popupInfoLoading, setPopupInfoLoading] = useState(false);
  const [selectedWork, setSelectedWork] = useState<IVehicleRepairItemWork>();
  const { user } = useSelector((state: RootState) => state.userData);
  const [viewMode, setViewMode] = useState(user?.user_settings.products_view_mode || DEFAULT_VIEW);
  const marketplace = getCurrentMarketplace();

  const { id } = useParams<'id'>();

  useOnClickOutside(popupInfoRef, () => setPopupInfoIsOpen(false));

  useEffect(() => {
    if (searchParams.getAll('sup').length || searchParams.getAll('man').length) {
      if (!isVehiclePartCatalog) {
        const params = {
          sup: searchParams.getAll('sup'),
          man: searchParams.getAll('man'),
          page,
        };

        return getData(params);
      } else {
        searchParams.delete('sup');
        searchParams.delete('man');
        setSearchParams(searchParams);
      }
    }

    getData();
  }, [
    i18n.language,
    searchParams.get('page'),
    searchParams.get('code_groupe'),
    searchParams.get('code_ssgroupe'),
    searchParams.get('code_repere'),
    searchParams.get('is_platform_admin'),
    searchParams.get('sort'),
    searchParams.get('asc')
  ]);

  function getData(filterParams?) {
    if (isVehiclePartCatalog) {
      // local filter
      if (filterParams) {
        setLocalyFilteredData({
          data: filterCarParts(refData?.data || [], filterParams),
        });
      } else {
        setLocalyFilteredData(undefined);
        getPartsByCar();
      }
    } else {
      // request filtered parts
      getPartsByCode(filterParams);
    }
  }

  function getPartsByCar() {
    setLoading(true);
    const params = {
      source: searchParams.get('source'),
      vehicle_identifier: searchParams.get('vehicle_identifier'),
      code_groupe: searchParams.get('code_groupe'),
      code_ssgroupe: searchParams.get('code_ssgroupe'),
      code_repere: searchParams.get('code_repere'),
      reference_count: searchParams.get('reference_count'),
      page,
    };

    const validParams = validateParams(params);

    http.carParts
      .getReliableCarPartCatalog(String(vehicle_id), validParams)
      .then((res) => {
        setRefData({
          data: res.oe_references,
        });
      })
      .finally(() => setLoading(false));
    http.carParts.getReliableCarPartFilter(String(vehicle_id), validParams).then((res) => {
      setFilterData(res.filters);
    });
  }

  function getPartsByCode(filterParams) {
    setLoading(true);

    const sortBySelectedOption = sortByOptions.filter((item) => String(item.value) == String(searchParams.get('sort')))[0] ?? [];

    const sortParams: SortByParams = {
      sort_by_column: String(searchParams.get('sort') ?? ''),
      sort_by_asc: String(searchParams.get('asc') ?? sortBySelectedOption?.default_sort_asc ?? '')
    };

    const params = {
      source: searchParams.get('source'),
      man_sup_id: searchParams.get('man_sup_id'),
      subcategory_id: searchParams.get('subcategory_id'),
      q: searchParams.get('q'),
      page: searchParams.get('page'),
      ...filterParams,
      ...sortParams,
      ...(searchParams.get('is_platform_admin') && { is_platform_admin: true }),
      ...(searchParams.get('type') && { type: searchParams.get('type') }),
    };

    const validParams = validateParams(params);

    if (id && props.inCatalog) {
      http.carParts
        .getCatalogCarParts(id, validParams).then((res) => {
          setRefData(res);
        })
        .finally(() => setLoading(false));

      http.carParts.getCatalogFilters(id, validParams).then((res) => {
        setFilterData(res);
      });
    } else {
      http.carParts
        .getReliableCarParts(validParams)
        .then((res) => {
          setRefData(res);
        })
        .finally(() => setLoading(false));

      http.carParts.getReliableFilters(validParams).then((res) => {
        setFilterData(res);
      });
    }


  }

  const validateParams = (data) => {
    return Object.entries(data).reduce((acc, [key, value]) => {
      if (!value) {
        return acc;
      }

      acc[key] = value;

      return acc;
    }, {});
  };

  const handlePageClick = (pageN: number) => {
    setPage(pageN);
  };

  function handleOpenPopup() {
    setPopupInfoIsOpen(true);
  }

  const getWorkTimeInfo = async () => {
    setPopupInfoLoading(true);

    if (refData?.data.length) {
      const genArtNr = refData?.data[0]?.genartnr ?? refData?.data[0]?.car_parts[0]?.genartnr;

      const { data: itemIds } = await http.pricesHttp.fetchRMIItemMpIdForGenArt({ genArtNo: genArtNr ?? null });

      const { data: vehicleRepairItems } = await http.repairItemsHttp.fetchWorkListList({
        typeId: typeId,
        countryCode: getCurrentCountryRMI(i18n.language),
        languageCode: getCurrentLanguageRMI(i18n.language),
      });

      const vehicleItems: IRepairItemsWorkItemMps[] = [];

      vehicleRepairItems.forEach((group) => {
        group.SubGroups.forEach((subGroup) => {
          subGroup.ItemMps.forEach((item) => {
            vehicleItems.push(item);
          });
        });
      });

      const itemMps = vehicleItems.filter(
        (v) =>
          v.ItemMpId ===
          (() => {
            return itemIds.find((itemId) => vehicleItems.find((v) => v.ItemMpId === itemId) !== undefined);
          })(),
      );

      const { data: vehicleRepairItem } = await http.repairItemsHttp.fetchWorkStepsList({
        typeId,
        itemMpId: itemMps[0].ItemMpId,
        korId: itemMps[0].KorId,
        countryCode: getCurrentCountryRMI(i18n.language),
        languageCode: getCurrentLanguageRMI(i18n.language),
      });

      setSelectedWork(vehicleRepairItem[0]);
    }

    setPopupInfoLoading(false);
  };


  function countResults() {
    const displayedData = localyFilteredData || refData;

    if (
      !isNaN(displayedData?.data && displayedData?.data?.length && displayedData?.data[0].car_parts?.length) &&
      displayedData?.data?.length
    ) {
      return displayedData.data.length + displayedData.data[0]?.car_parts.length;
    }

    return 0;
  }

  const isArrayPopulated = (array) => (!array || (array && array.length !== 0) ? true : false);

  const filterCarParts = (items: ICarPartData[], filterParams) => {
    if (!filterParams) {
      return;
    }

    const { man = [], sup = [], attributes = [] } = filterParams;
    const isAmerigo = !!filterParams.isAmerigo;

    const filterItems = JSON.parse(JSON.stringify(items)).map((oe_reference) => {
      oe_reference['car_parts'] = oe_reference?.car_parts.filter((item) => {
        const attributesStatus = isArrayPopulated(attributes)
          ? isArrayPopulated(item.attributes)
            ? item.attributes?.some((car) => attributes.includes(car.value.trim()))
            : false
          : true;

        const manufacturerStatus = isArrayPopulated(man) ? item.car_manufacturers.some((carId) => man.includes(carId)) : true;
        const supplierStatus = isArrayPopulated(sup) ? sup.some((supplierId) => supplierId === item.data_supplier.id) : true;

        const isAmerigoFilter = isAmerigo === true && !isArrayPopulated(sup) ? item.is_amerigo === isAmerigo : true;

        if (sup && isAmerigo && sup.some((el) => el.is_amerigo === false) && !sup.some((el) => el.is_amerigo === true)) {
          if (item.is_amerigo) {
            return true;
          }
        }

        if (isAmerigo === false) {
          return manufacturerStatus && supplierStatus && attributesStatus;
        }

        return manufacturerStatus && supplierStatus && attributesStatus && isAmerigoFilter;
      });

      if (isAmerigo) {
        oe_reference.car_parts.sort((a, b) => (a.is_amerigo === b.is_amerigo ? 0 : a.is_amerigo ? -1 : 1));
      }

      return oe_reference;
    });

    return filterItems;
  };

  const dataToShow = localyFilteredData || refData;

  useEffect(() => {
    if (vehicle_id) {
      getWorkTimeInfo();
    }
  }, [refData, vehicle_id]);

  function filterOptionsElement() {

    if (!filterData?.car_manufacturers.length && !filterData?.data_suppliers.length && marketplace == MARKETPLACE_AMERIGO) {
      return <div className="catalog-parts-filter"> </div>;
    } else {
      return (
        <div className="catalog-parts-filter">
          <button onClick={() => setOpenFilter(true)} className={`catalog-parts-button ${openFilter ? 'active' : ''}`}>
            {t('_filter_results_btn')}
          </button>
        </div>
      );
    }


  }

  return (
    <div className={`parts-wrapper ${isVehiclePartCatalog ? 'part-catalog-wrapper' : ''}`}>
      <UniversalPartsNavbar option={filterOptionsElement()} title="_find_neccessary_parts" />
      <div className="separator"></div>
      <div className="filterOptions">
        {dataToShow?.data && dataToShow?.data?.length > 0 &&
          <SortBy />
        }
        {!loading && (dataToShow?.data && dataToShow?.data?.length > 0) && typeId && vehicle_id && dataToShow && user && [CAR_PRO, CAR_TECH.includes(user?.client_settings?.rmi_modules_type)] && (
          <div ref={popupInfoRef} className="work-time" onClick={handleOpenPopup}>
            {t('_work_time')}
            <FontAwesomeIcon icon={faClock} className="clock-icon" />
            {popupInfoIsOpen && <PopupInfo loading={popupInfoLoading} data={selectedWork} />}
          </div>
        )}
        <div className="filter-body-part">
          {dataToShow?.data &&
            <React.Fragment>
              <div className="results">
                {countResults()} {t('_results')}
              </div>
              <ProductsViewMode viewMode={viewMode} setViewMode={setViewMode} />
            </React.Fragment>
          }
        </div>
      </div>
      {loading ? (
        <Spinner class="car-parts-spinner" />
      ) : dataToShow?.data?.length ? (
        <ProductsViewModeChanger inCatalog={props.inCatalog} catalog={CATALOG_PART_OEM} viewMode={viewMode} data={dataToShow.data} />
      ) : (
        <div className="no-parts-data">
          <div className="notFoundSvg">
            <PartsNotFoundImage />
          </div>
          <h1>{t('_parts_not_found')}</h1>
        </div>
      )}
      {
        refData && refData?.total_pages && refData?.page && refData?.total_pages > 1 ? (
          <Pagination pageCount={refData.total_pages} handlePageClick={handlePageClick} forcePage={refData.page} />
        ) : (
          ''
        )
      }
      <PartsFilter getData={getData} setPage={setPage} filterData={filterData} openFilter={openFilter} setOpenFilter={setOpenFilter} />
    </div >
  );
};

export default PartsList;
