import React from 'react';
import Spinner from 'components/spinner/Spinner';
import {useTranslation} from 'react-i18next';
import ProformaMappingTable from '../ProformaMappingTable/ProformaMappingTable';
import { IRow } from '../schema';
import './ProformaMappingTableContainer.scss';

interface IProformaTableContainer{
  loadingData:boolean,
  rows:IRow[],
  setRows: (rows: IRow[])=>void,
  disabled: boolean,
  disableMapping:boolean,
  setDisableMapping:(checked:boolean)=>void,
  setDisableBtn:(disableBtn:boolean)=>void,
  fileColumn?:boolean,
  fileHeader
  setFileHeader: (header) => void,
  uploadingFile:boolean,
}

const ProformaMappingTableContainer = (props:IProformaTableContainer): JSX.Element => {
  const c = 'proforma-mapping-column';
  const {t} = useTranslation();

  return (

    <div className="proforma-mapping-column-container">
      <div className={`${c}`}>
        <div className="spinner-blur">
          {props.loadingData
            ? (<Spinner class="spinner-table-data" />)
            : ''}
          {props.fileColumn && props.uploadingFile
            ? (<Spinner class="spinner-table-data" />)
            : ''}
        </div>
        <div className="proforma-custom-mapping">
          {!props.fileColumn ? <div className={`${c}-title`}>{t('_mapping')}</div> : <div className={`${c}-title`}>{t('_file')}</div>}

        </div>
        <div className={`${c}-item-list`}>
          <ProformaMappingTable
            disableMapping={props.disableMapping}
            setDisableMapping={props.setDisableMapping}
            fileHeader={props.fileHeader}
            setFileHeader={props.setFileHeader}
            setDisableBtn={props.setDisableBtn}
            disabled={props.disabled}
            rows={props.rows}
            setRows={props.setRows}
          />
        </div>
      </div>
    </div>

  );
};

export default ProformaMappingTableContainer;
