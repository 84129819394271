import React, {PropsWithChildren, useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Control,
  Controller,
  FieldErrors,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue, UseFormWatch
} from 'react-hook-form';
import useHttp from 'utils/hooks/useHttp';
import { IOption } from 'interfaces/select';
import Select from 'react-select';
import './ImportCatalog.scss';
import {isEmpty} from 'lodash';
import { ICatalogItem } from 'interfaces/selectClient';

type Props = {
  previewCatalog: boolean;
  setPreviewCatalog: (e: boolean) => void;
  previewCatalogData: ICatalogItem[];
  setPreviewCatalogData: (e: []) => void;
  setValue: UseFormSetValue<IImportCatalog>;
  getValues: UseFormGetValues<IImportCatalog>;
  register: UseFormRegister<IImportCatalog>;
  control: Control<IImportCatalog>;
  errors: FieldErrors<IImportCatalog>;
  watch: UseFormWatch<IImportCatalog>;
};

const ImportCatalogPreview: React.FC<Props> = (props: PropsWithChildren<Props>) => {
  const { t } = useTranslation();
  const { selectClient } = useHttp();
  const [manLoading, setManLoading] = useState(false);
  const [supLoading, setSupLoading] = useState(false);
  const [manOptions, setManOptions] = useState<IOption[]>([]);
  const [supOptions, setSupOptions] = useState<IOption[]>([]);

  useEffect(() => {
    if (!isEmpty(props.previewCatalogData)) {
      props.previewCatalogData.forEach((item, index) => {
        props.setValue(`catalog_non_tecdoc.row_${index}.supplier`, props.getValues().selected_supplier);
        props.setValue(`catalog_non_tecdoc.row_${index}.manufacturer`, props.getValues().selected_manufacturer);
      });
    }
  }, [props.previewCatalogData]);

  useEffect(() => {
    props.setPreviewCatalog(false);

    if (props.watch()?.catalog_type?.value == 'non_tec_doc_catalog') {
      props.setPreviewCatalog(true);
      props.setPreviewCatalogData([]);
      fetchNonTecdocManufacturers();
      fetchNonTecdocSuppliers();
    }

    // reset({catalog_type: value});
  }, [props.getValues().catalog_type]);

  const fetchNonTecdocManufacturers = async () => {
    setManLoading(true);
    await selectClient.manufacturerMappingList().then((res) => {
      setManOptions(res.map((item)  => ({label: item?.name, value: item?.id})) || [] );
    });
    setManLoading(false);
  };

  const fetchNonTecdocSuppliers = async () => {
    setSupLoading(true);
    await selectClient.supplierMappingList().then((res) => {
      setSupOptions( res.map((item) : IOption => ({label: item.name, value: item.id})) || [] );
    });
    setSupLoading(false);
  };

  return (
    (isEmpty(props.previewCatalogData) ?
      <React.Fragment>
        <div className="import-manufacturer import-content">
          <label>{t('_manufacturer')}</label>
          <Controller
            control={props.control}
            name="selected_manufacturer"
            render={({ field: { onChange, value } }) => (
              <Select
                className={`select-catalog-manufacturer ${props.errors.selected_manufacturer?.type == 'required' ? 'error' : ''}`}
                value={value}
                onChange={(val) => {onChange(val);}}
                options={manOptions}
                placeholder={t('_select')}
                isLoading={manLoading}
                menuPlacement="auto"
                styles={{
                  menu: provided => ({ ...provided, zIndex: 9999 })
                }}
              />
            )}
          />
        </div>
        <div className="import-supplier import-content">
          <label>{t('_supplier')}</label>
          <Controller
            control={props.control}
            name="selected_supplier"
            render={({ field: { onChange, value } }) => (
              <Select
                className={`select-catalog-supplier ${props.errors.selected_supplier?.type == 'required' ? 'error' : ''}`}
                value={value || ''}
                onChange={(val) => {onChange(val);}}
                options={supOptions}
                placeholder={t('_select')}
                isLoading={supLoading}
                menuPlacement="auto"
                styles={{
                  menu: provided => ({ ...provided, zIndex: 9999 })
                }}
              />
            )}
          />
        </div>
      </React.Fragment>
      :
      <div className={`import-preview-wrapper force-scroll ${props.previewCatalogData.length <= 8 ? 'no-scroll' : ''}`}>
        <table>
          <thead>
            <tr>
              <td>{t('#')}</td>
              <td>{t('_description')}</td>
              <td>{t('_reference')}</td>
              <td className="text-center">{t('_manufacturer')}</td>
              <td>{t('_equivalence_oem')}</td>
              <td className="text-center">{t('_supplier')}</td>
              <td>{t('_equivalence_iam')}</td>
              <td>{t('_oil_description')}</td>
            </tr>
          </thead>
          <tbody>
            {
              props.previewCatalogData.map((item : ICatalogItem, index) => {
                return (
                  <tr key={index}>
                    <td>{index+1}</td>
                    <td>{item.supplier_name}<input type="hidden" {...props.register(`catalog_non_tecdoc.row_${index}.supplier_name`)} value={item.supplier_name} /></td>
                    <td>{item.reference}<input type="hidden" {...props.register(`catalog_non_tecdoc.row_${index}.reference`)} value={item.reference} /></td>
                    <td>
                      {item.refnr &&
                      <Controller
                        control={props.control}
                        name={`catalog_non_tecdoc.row_${index}.manufacturer`}
                        rules={{ required: true }}
                        render={({ field: { onChange, value , ref} }) => (
                          <Select
                            className="select-catalog-type"
                            ref={ref}
                            value={value}
                            onChange={(val) => {onChange(val);}}
                            options={manOptions}
                            placeholder={t('_select')}
                            menuPlacement="auto"
                            styles={{
                              menu: provided => ({ ...provided, zIndex: 9999 })
                            }}
                          />
                        )}
                      />}
                    </td>
                    <td>{item.refnr}<input type="hidden" {...props.register(`catalog_non_tecdoc.row_${index}.refnr`)} value={item.refnr} /></td>
                    <td>
                      {
                        item.artnr && 
                         <Controller
                           control={props.control}
                           name={`catalog_non_tecdoc.row_${index}.supplier`}
                           rules={{ required: true }}
                           render={({ field: { onChange, value , ref} }) => (
                             <Select
                               className="select-catalog-type"
                               ref={ref}
                               value={value}
                               onChange={(val) => {onChange(val);}}
                               options={supOptions}
                               placeholder={t('_select')}
                               menuPlacement="auto"
                               styles={{
                                 menu: provided => ({ ...provided, zIndex: 9999 })
                               }}
                             />
                           )}
                         />
                      }
                    </td>
                    <td>{item.artnr}<input type="hidden" {...props.register(`catalog_non_tecdoc.row_${index}.artnr`)} value={item.artnr} /></td>
                    <td><textarea {...props.register(`catalog_non_tecdoc.row_${index}.description`)} cols={20} rows={3} style={{resize: 'none'}}>{item.description}</textarea></td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      </div>
    )
  );
};

ImportCatalogPreview.displayName = 'ImportCatalogPreview';

export type IImportCatalog = {
  catalog_type: IOption;
  files: File;
  catalog_non_tecdoc?;
  selected_manufacturer?: IOption;
  selected_supplier?: IOption;
};

export default ImportCatalogPreview;
