import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useHttp from 'utils/hooks/useHttp';
import { notify } from 'utils/marketplace';
import { AxiosError } from 'axios';
import Spinner from 'components/spinner/Spinner';
import TyrePhotoGallery from '../TyresPhotoGallery/TyrePhotoGallery';
import TyreDetails from '../TyresDetails/TyreDetails';
import TyreOptions from '../TyresOptions/TyresOptions';
import './TyreViewMobile.scss';
import { ITyre } from 'interfaces/tyre';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const TyreViewMobile: React.FC = () => {
  const http = useHttp();
  const { id } = useParams<'id'>();

  const [tyre, setTyre] = useState<ITyre>();
  const [loading, isLoading] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  
  useEffect(() => {
    document.body.classList.add('footer-padding');
    getTyre();

    return () => {
      document.body.classList.remove('footer-padding');
    };
  }, []);

  const getTyre = async () => {
    isLoading(true);

    try {
      await http.rmiTyresHttp.getTyreById(String(id)).then((res) => setTyre(res.data));
    } catch (e: unknown) {
      if (e instanceof AxiosError) {
        const message = e?.response?.data?.detail;

        notify(message);
      }
    } finally {
      isLoading(false);
    }
  };

  return (
    <div className="tyre-view item-view-wrapper">
      {loading ? (
        <Spinner class="small" />
      ) : (
        tyre && (
          <React.Fragment>
            <div className="item-view-header">
              <div className="mobile-tyre-reference">
                <span>Ref</span>: {` ${tyre.data_supplier_reference_original}`}
              </div>
              <div className="mobile-tyre-brand">
                {`${tyre.data_supplier.name} ${tyre.data_supplier_reference_original}`}
              </div>
              <div className="return-button">
                <span
                  className="returnButton"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <FontAwesomeIcon icon={faCircleArrowLeft} className="icon" />
                  {t('_return')}
                </span>
              </div>
            </div>
            <div className="item-view-content">
              <div className="gallery">
                <TyrePhotoGallery tyre={tyre} />
              </div>
              <div className="details">
                <TyreDetails tyre={tyre} />
              </div>
              <div className="options">
                <TyreOptions tyre={tyre} />
              </div>
            </div>
            {/*<div className="item-view-footer"></div>*/}
          </React.Fragment>
        )
      )}
    </div>
  );
};

export default TyreViewMobile;
