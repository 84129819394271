import React, { ChangeEventHandler, FormEvent, PropsWithChildren, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import './GlobalSearchInput.scss';
import useHttp from '../../../utils/hooks/useHttp';
import axios from 'axios';
import { IGlobalSearchData } from 'interfaces/globalSearch';
import GlobalSearchContent from './GlobalSearchContent/GlobalSearchContent';
import { useTranslation } from 'react-i18next';
import { notify } from '../../../utils/marketplace';
import { ICustomValidation } from '../../../utils/hooks/http/search-by-reference.http';

type Props = {
  placeholder: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  value?: string;
  withIcon: boolean;
  size?: 's' | 'm' | 'l';
  onSubmit?: () => void;
  noValidation?: boolean;
};

const GlobalSearchInput: React.FC<Props> = (props: PropsWithChildren<Props>) => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const [loading, setLoading] = useState(false);
  const http = useHttp();
  const cancelToken = axios.CancelToken.source();
  const [data, setData] = useState<IGlobalSearchData>();
  const [inputValue, setInputValue] = useState('');
  const [validationError, setValidationError] = useState(false);
  const [validationErrorMessages, setValidationErrorMessages] = useState<string[]>([]);
  const { t } = useTranslation();

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();

    getData();
  };

  function getData() {
    if (inputValue.length < 3) { return; }

    setLoading(true);
    setOpenDropdown(true);

    http.globalSearch.search(inputValue, cancelToken.token)
      .then((res) => {
        setData(res.data);
        setLoading(false);
      }).catch((err) => {
        if (err) {
          notify(t('_error'));
          setLoading(false);
        }
      });

  }

  const customValidations: ICustomValidation[] = [
    {
      regex: new RegExp(/^.{3,}$/),
      message: t('_ref_search_min_length'),
    }
  ];

  function isValid(value) {
    if(props.noValidation) {
      return true;
    }

    const errors = customValidations.filter((validation) => !validation.regex.test(value));

    setValidationErrorMessages(errors.map((error) => error.message));
    setValidationError(errors.length > 0);

    return errors.length === 0;
  }

  function onChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (!isValid(e.target.value)) {
      setLoading(false);
      setOpenDropdown(false);

      return;
    }

    setInputValue(e.target.value);
    props.onChange?.(e);

    if (e?.target.value === '') {
      setLoading(false);
    }


  }

  function keyDownHandler(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      getData();
    }
  }

  useEffect(() => {
    getData();

    return () => {
      cancelToken.cancel();
    };
  }, [inputValue]);

  return (
    <form className="GlobalSearchInput text-input-container" onSubmit={(event) => onSubmit(event)}>
      <div className={`query-input ${validationError ? 'error' : ''}`}>
        <input
          className={`text-input  ${props.size && `text-input--${props.size}`}`}
          type="text"
          onKeyDown={(event) => keyDownHandler(event)}
          onChange={onChange}
          placeholder={props.placeholder}
          value={props.value}
        />

        {props.withIcon &&
          <button
            type={props.onSubmit && 'submit' || undefined}
            className={`input-search-icon ${props.onSubmit && 'cursor-pointer'}`}
          >
            <FontAwesomeIcon icon={faSearch} />
          </button>
        }
      </div>
      {validationError &&
        <div className="error-message">
          {validationErrorMessages.map((message, index) => <div key={index}>{message}</div>)}
        </div>
      }

      {openDropdown && <GlobalSearchContent data={data} setIsOpen={setOpenDropdown} loading={loading} queryValue={inputValue} size={props.size} />}

    </form>
  );
};

export default GlobalSearchInput;
