import EspaceClientLayout from 'applications/amerigo/layouts/espace-client-layout';
import MyClientsLayout from 'applications/amerigo/layouts/espace-my-clients-layout';
import ModifyClient from 'pages/MyClients/ModifyClient/ModifyClient';
import React from 'react';
import MyClients from 'pages/MyClients/MyClients';
import ClientsInvitations from 'pages/MyClients/ClientsInvitations/ClientsInvitations';
import InvitationView from 'pages/MyClients/InvitationView/InvitationView';
import OrdersClients from 'pages/OrdersClients/OrdersClients';

import { Route } from 'react-router-dom';
import EspaceClient from 'views/EspaceClientView/EspaceClientView';
import OrderClientsView from 'pages/OrderClientsView/OrderClientsView';
import OrdersClientsLayout from 'applications/amerigo/layouts/espace-order-clients-layout';
import EspaceMessagesLayout from '../../applications/amerigo/layouts/espace-messages-layout';
import PlatformAdminMessages from '../../pages/PlatformAdminMessages/PlatformAdminMessages';
import PlatformAdminClientMessagesPage from 'pages/PlatformAdminClientMessagesPage/PlatformAdminClientMessagesPage';

import MyEshop from 'pages/MyEshop/MyEshop';

import Consumable from 'pages/CatalogNaf/Consumable/Consumable';
import Tyre from 'pages/CatalogNaf/Tyre/Tyre';
import Oil from 'pages/CatalogNaf/Oil/Oil';
import OilBrand from 'pages/CatalogNaf/OilBrand/OilBrand';
import TyreBrand from 'pages/CatalogNaf/TyreBrand/TyreBrand';
import ConsumableBrand from 'pages/CatalogNaf/ConsumableBrand/ConsumableBrand';
import ConsumableCategory from 'pages/CatalogNaf/ConsumableCategory/ConsumableCategory';
import BatteryBrand from 'pages/CatalogNaf/BatteryBrand/BatteryBrand';
import BatteryCategory from 'pages/CatalogNaf/BatteryCategory/BatteryCategory';
import Battery from 'pages/CatalogNaf/Battery/Battery';
import PromotionsLayout from 'applications/amerigo/layouts/espace-promotions-layout';
import Promotions from 'pages/Promotions/Promotions';
import FilterSuppliers from 'applications/automotor/components/espace/client/FilterSuppliers/FilterSuppliers';
import ProtectedRoute from 'components/high-order-components/HasRoles/ProtectedRoute';
import { ROLE_CLIENT_PLATFORM_ADMIN } from 'utils/constants/roles';
import Statistics from 'applications/automotor/views/statistics/Statistics';

import StockPriceManagementAdmin from 'pages/StockPriceManagementAdmin/StockPriceManagementAdmin';
import NonTecdocParts from 'pages/CatalogNaf/NonTecdocParts/NonTecdocParts';
import NonTecdocPartsSuppliers from 'pages/CatalogNaf/NonTecdocPartsSuppliers/NonTecdocPartsSuppliers';
import StockManagementAdminNew from 'pages/StockManagementAdminNew/StockManagementAdminNew';
import MecadepotInfo from 'applications/automotor/views/mecadepot-info/MecadepotInfo';
import OilCategory from 'pages/CatalogNaf/OilCategory/OilCategory';

const useDistributorClientsRoutes = (): JSX.Element => {
  return (
    <Route
      path="/espace-clients"
      element={
        <ProtectedRoute roles={[ROLE_CLIENT_PLATFORM_ADMIN]}>
          <EspaceClientLayout />
        </ProtectedRoute>
      }
    >
      <Route path="statistics" element={<Statistics />} />
      <Route path="mecadepot_info" element={<MecadepotInfo />} />
      <Route path="filter-suppliers" element={<FilterSuppliers />} />
      <Route path="stock-management" element={<EspaceClientLayout />}>
        <Route path="parts" element={<StockManagementAdminNew />} />
        <Route path="oil" element={<StockManagementAdminNew />} />
        <Route path="non_tecdoc_parts" element={<StockManagementAdminNew />} />
        <Route path="battery" element={<StockManagementAdminNew />} />
        <Route path="tyre" element={<StockManagementAdminNew />} />
        <Route path="consumable" element={<StockManagementAdminNew />} />
        <Route path="price" element={<StockPriceManagementAdmin />} />
      </Route>
      <Route path="" element={<EspaceClient />} />
      <Route path="my-eshop" element={<EspaceClientLayout />}>
        <Route path="" element={<MyEshop />} />
        <Route path="catalog_oil" element={<EspaceClientLayout />}>
          <Route path="" element={<Oil />} />
          <Route path="oil_brand" element={<OilBrand />} />
          <Route path="oil_category" element={<OilCategory />} />
        </Route>
        <Route path="catalog_tyres" element={<EspaceClientLayout />}>
          <Route path="" element={<Tyre />} />
          <Route path="tyres_brand" element={<TyreBrand />} />
        </Route>
        <Route path="catalog_consumable" element={<EspaceClientLayout />}>
          <Route path="" element={<Consumable />} />
          <Route path="consumable_brand" element={<ConsumableBrand />} />
          <Route path="consumable_category" element={<ConsumableCategory />} />
        </Route>
        <Route path="catalog_battery" element={<EspaceClientLayout />}>
          <Route path="" element={<Battery />} />
          <Route path="battery_brand" element={<BatteryBrand />} />
          <Route path="battery_category" element={<BatteryCategory />} />
        </Route>
        <Route path="catalog_non_tecdoc" element={<EspaceClientLayout />}>
          <Route path="" element={<NonTecdocParts />} />
          <Route path="suppliers" element={<NonTecdocPartsSuppliers />} />
        </Route>
      </Route>
      <Route path="my-clients" element={<MyClientsLayout />}>
        <Route path="" element={<MyClients />} />
        <Route path=":id" element={<ModifyClient />}></Route>
        <Route path="invitations" element={<ClientsInvitations />} />
        <Route path="invitations/:id" element={<InvitationView />} />
      </Route>
      <Route path="orders-clients" element={<OrdersClientsLayout />}>
        <Route path="" element={<OrdersClients />} />
        <Route path=":order_id" element={<OrderClientsView />} />
      </Route>
      <Route path="messages" element={<EspaceMessagesLayout />}>
        <Route path="" element={<PlatformAdminMessages />} />
        <Route path=":client_id" element={<PlatformAdminClientMessagesPage />} />
      </Route>

      <Route path="promotions" element={<PromotionsLayout />}>
        <Route path="" element={<Promotions />} />
      </Route>
    </Route>
  );
};

export default useDistributorClientsRoutes;
