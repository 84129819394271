import React, { useEffect, useState } from 'react';
import CartView from './Cart.view';
import useHttp from 'utils/hooks/useHttp';
import { ISuppliersAmerigo } from 'models/cart.model';

type Props = {};

const Cart: React.FC<Props> = () => {
  const http = useHttp();
  const [tabs, setTabs]= useState<ISuppliersAmerigo[]>([]);
  const [loading, setLoading] = useState(false);

  function getSuppliers(){
    setLoading(true);
    http.cart.getAmerigoOrderSuppliers().then(setTabs).finally(()=>{
      setLoading(false);
    });
  }

  useEffect(()=>{
    getSuppliers();
  },[]);

  return <CartView getSuppliers={getSuppliers} loading={loading} tabs={tabs} />;
};

Cart.displayName = 'Cart';

export default Cart;
