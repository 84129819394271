import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import { ITruckFuel, ITruckManufacturers, ITruckModels, ITruckVersion } from 'interfaces/truckSelector';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { createSearchParams, generatePath, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import useHttp from 'utils/hooks/useHttp';
import '../VehicleCard.scss';

type Props = {
  setOpenModal?: (e: boolean) => void;
  link?: string;
};


const TruckSelector: React.FC<Props> = (props) => {
  const { reset, control, getValues, handleSubmit } = useForm();
  const http = useHttp();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [activeButton, setActiveButton] = useState(false);
  const { isMobile, isTablet } = useDeviceDetect();
  const [manufacturers, setManufacturers] = useState<ITruckManufacturers[]>([]);
  const [manufacturersLoading, setManufacturersLoading] = useState(false);

  const [models, setModels] = useState<ITruckModels[]>([]);
  const [modelsLoading, setModelsLoading] = useState(false);

  const [fuelType, setFuelType] = useState<ITruckFuel[]>([]);
  const [fuelTypeLoading, setFuelTypeLoading] = useState(false);

  const [versions, setVersions] = useState<ITruckVersion[]>([]);
  const [versionsLoading, setVersionsLoading] = useState(false);


  function getData(request, setLoading) {
    setLoading(true);

    return request
      .then((res) => res.data)
      .finally(() => {
        setLoading(false);
      });
  }

  const manufacturerOptions = () => {
    return (
      manufacturers?.map((item) => ({
        label: item.description,
        value: item.id,
      })) || []
    );
  };

  const modelOptions = () => {
    return (
      models?.map((item) => ({
        label: item.description,
        value: item.id,
      })) || []
    );
  };

  const fuelOptions = () => {
    return (
      fuelType?.map((item) => ({
        label: item.description,
        value: item.id,
      })) || []
    );
  };

  const versionOptions = () => {
    return (
      versions?.map((item) => ({
        label: `${item.model_name} ${item.name} ${item.engine_capacity} ${item.weight} ${item.start_year}/${item.start_month} - ${item.end_year}/${item.end_month} ${item.power_hp_from} ${!item.power_hp_to ? '' : ` - ${item.power_hp_to}`} HP ${item.power_kw_from} - ${!item.power_kw_to ? '' : ` - ${item.power_kw_to}`} KW`,
        value: item.id,
      })) || []
    );
  };

  function submit() {
    if (props.link) {
      navigate({
        pathname: `/reliable/rmi/${getValues()?.version.value}${props.link}`,
        search: `${createSearchParams({ source: 'T', vehicle_identifier: '016' })}`
      });
    } else {

      navigate({
        pathname: generatePath('/reliable/rmi/:vehicle_id/catalogs', { vehicle_id: getValues()?.version.value }),
        search: `${createSearchParams({ source: 'T', vehicle_identifier: '016' })}`
      });
    }

    if (props.setOpenModal) {
      props.setOpenModal(false);
    }
  }

  const handleManufacturerChange = (id) => {
    getData(http.truckSelector.getTruckModels(id), setModelsLoading).then((models) => setModels(models));
    reset({ model: '', fueltype: '', version: '' });
    setModels([]);
    setFuelType([]);
    setVersions([]);
    setActiveButton(false);
  };

  const handleModelChange = (val) => {
    getData(http.truckSelector.getTruckFuel(val.value), setFuelTypeLoading).then((fuel) => setFuelType(fuel));
    getData(http.truckSelector.getTruckVersion(val.value), setVersionsLoading).then((versions) => setVersions(versions));
    reset({ fueltype: '', version: '' });
    setFuelType([]);
    setVersions([]);
    setActiveButton(false);
  };

  useEffect(() => {
    getData(http.truckSelector.getTruckManufacturers(), setManufacturersLoading).then((manufacturers) => {
      setManufacturers(manufacturers);
    });
  }, []);

  return (
    <form onSubmit={handleSubmit(submit)} className="dropdowns-container">
      <div className="dropdown-item">
        <label className="dropdown-label">{t('_manufacturer')}</label>
        <Controller
          control={control}
          name="manufacturer"
          render={({ field: { onChange, value } }) => (
            <Select
              value={value}
              maxMenuHeight={200}
              className={`select-dropdown ${!manufacturers.length ? 'disabled' : ''}`}
              onChange={(val) => {
                handleManufacturerChange(val?.value);
                onChange(val);
              }}
              options={manufacturerOptions()}
              placeholder={manufacturersLoading ? t('_loading') : t('_manufacturer')}
              styles={{
                menu: provided => ({ ...provided, zIndex: 9999 })
              }}
              isSearchable={!isMobile && !isTablet}
            />
          )}
        />
      </div>
      <div className="dropdown-item">
        <label className="dropdown-label">{t('_model')}</label>
        <Controller
          control={control}
          name="model"
          render={({ field: { onChange, value } }) => (
            <Select
              value={value}
              maxMenuHeight={180}
              className={`select-dropdown ${!models.length ? 'disabled' : ''}`}
              onChange={(val) => {
                onChange(val);
                handleModelChange(val);
              }}
              options={modelOptions()}
              placeholder={modelsLoading ? t('_loading') : t('_model')}
              styles={{
                menu: provided => ({ ...provided, zIndex: 9999 })
              }}
              isSearchable={!isMobile && !isTablet}
            />
          )}
        />
      </div>
      <div className="dropdown-item">
        <label className="dropdown-label">{t('_fuel_type')}</label>
        <Controller
          control={control}
          name="fueltype"
          render={({ field: { onChange, value } }) => (
            <Select
              value={value}
              maxMenuHeight={100}
              className={`select-dropdown ${!fuelType.length ? 'disabled' : ''}`}
              onChange={(val) => {
                onChange(val);
                setActiveButton(false);
              }}
              options={fuelOptions()}
              placeholder={fuelTypeLoading ? t('_loading') : t('_fuel_type')}
              styles={{
                menu: provided => ({ ...provided, zIndex: 9999 })
              }}
              isSearchable={!isMobile && !isTablet}
            />
          )}
        />
      </div>
      <div className="dropdown-item">
        <label className="dropdown-label">{t('_version')}</label>
        <Controller
          control={control}
          name="version"
          render={({ field: { onChange, value } }) => (
            <Select
              value={value}
              menuPlacement="auto"
              menuPosition="fixed"
              minMenuHeight={150}
              maxMenuHeight={200}
              className={`select-dropdown ${!versions.length ? 'disabled' : ''}`}
              onChange={(val) => {
                onChange(val);
                setActiveButton(true);
              }}
              options={versionOptions()}
              placeholder={versionsLoading ? t('_loading') : t('version')}
              styles={{
                menu: provided => ({ ...provided, zIndex: 9999 })
              }}
              isSearchable={!isMobile && !isTablet}
            />
          )}
        />
      </div>
      <div className="truck-selector-submit">
        <DefaultButton
          text={t('_send')}
          disabled={!activeButton}
          onClick={() => {
            submit();
          }}
        />
      </div>
    </form>
  );
};

TruckSelector.displayName = 'TruckSelector';

export default TruckSelector;
