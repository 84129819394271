import React, { useEffect, useState } from 'react';
import { IOption } from '../../../../interfaces/select';
import {
  IAim,
  IEquivalencesV2Response,
} from '../../../../models/car-parts.model';
import Select, { SingleValue } from 'react-select';
import Spinner from '../../../spinner/Spinner';
import './EquivalenceIAMV2.scss';
import { useTranslation } from 'react-i18next';

import SwiperSlider from 'components/SwiperSlider/SwiperSlider';
import { SwiperSlide } from 'swiper/react';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import EquivalenceIAMItemV2 from './EquivalenceIAMItemV2/EquivalenceIAMItemV2';


type Props = {
  loading: boolean;
  equivalencesResponse?: IEquivalencesV2Response;
  isLoading?: boolean;
};

const EquivalenceIAMV2: React.FC<Props> = (props) => {
  const [options, setOptions] = useState<IOption[]>([]);
  const [selectedOption, setSelectedOption] = useState<SingleValue<IOption>>();
  const [equivalances, setEquivalances] = useState<IAim[]>([]);
  const [equivalancesFiltered, setEquivalancesFiltered] = useState<IAim[]>([]);
  const { t } = useTranslation();
  const { isMobile, isTablet } = useDeviceDetect();


  useEffect(() => {

    getEquivalences();
  }, [props.equivalencesResponse]);


  const getEquivalences = async () => {


    if (!props.equivalencesResponse) {
      return;
    }


    const optionsFromResponse: IAim[] = props.equivalencesResponse.iam;

    if (optionsFromResponse) {
      const optionFromResponseTransform = transformOptions(optionsFromResponse);

      setOptions(optionFromResponseTransform);
    }

    setEquivalances(props.equivalencesResponse.iam);
    setEquivalancesFiltered(props.equivalencesResponse.iam);
  };

  const transformOptions = (optionsFromResponse: IAim[]) => {
    if (!optionsFromResponse) {
      return [];
    }

    const uniqueOptions = optionsFromResponse
      .map((item) => ({
        label: item.data_supplier_name,
        value: String(item.supplier_id),
      }))
      .filter((item, index, self) => (
        self.findIndex((i) => i.label === item.label && i.value === item.value) === index
      ));

    return uniqueOptions;
  };


  useEffect(() => {

    if (!props.equivalencesResponse) {
      return;
    }

    if (selectedOption == null) {
      setEquivalancesFiltered(props.equivalencesResponse.iam);
    } else {
      const filteredEquivalences = equivalances.filter((e) => String(e.supplier_id) === String(selectedOption?.value));

      setEquivalancesFiltered(filteredEquivalences);
    }


  }, [selectedOption]);


  return (
    <div className="equivalance-oem-container">
      {props.loading ? (
        <Spinner />
      ) : options.length ? (
        <div className="content">
          <div className="filters">
            <div className="manufacturers">
              <p className="select-title">{t('_ref_contructeur_iam')}</p>
              <Select
                className="select-element"
                placeholder={t('_ref_contructeur_iam')}
                value={selectedOption}
                onChange={(val) => {
                  setSelectedOption(val);
                }}
                options={options}
                isSearchable={false}
                isClearable
              />
            </div>
          </div>

          <div className="list">
            {isMobile || isTablet ? (
              <SwiperSlider slidesPerView="auto" isLoading={props.isLoading} centeredSlides={false}>
                <div>
                  {equivalancesFiltered.map((item) => {
                    return (
                      <SwiperSlide className="swiper-slide parts-view-equivalence" key={item.car_part_reference + item.supplier_id}>
                        <EquivalenceIAMItemV2 item={item} key={item.car_part_reference + item.supplier_id} />
                      </SwiperSlide>
                    );
                  })}
                </div>
              </SwiperSlider>
            ) : (
              <div className="list-item">
                {equivalancesFiltered.map((item) => {
                  return <EquivalenceIAMItemV2 item={item} key={item.car_part_reference + item.supplier_id} />;
                })}
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="no-data">
          <p>{t('_no_data')}</p>
        </div>
      )}
    </div>
  );
};

EquivalenceIAMV2.displayName = 'EquivalenceIAMV2';

export default EquivalenceIAMV2;
