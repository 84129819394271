import { createSlice } from '@reduxjs/toolkit';
import { fetchPartsList, fetchPartsListFilter, fetchPartsListOE } from 'store/reducers/car-parts-catalog/car-parts.actions';

type CarPartsSlice = {
  parts
  filters
  oeInfo
};

const initialState: CarPartsSlice = {
  parts: null,
  oeInfo: null,
  filters: [],
};

const PartsListSlice = createSlice({
  name: 'parts-list',
  initialState,
  reducers: {
    resetPartsList: (state) => {
      state.parts = null;
      state.oeInfo = null;
      state.filters = [];
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPartsList.fulfilled, (state, { payload }) => {
      state.parts = payload;
    });
    builder.addCase(fetchPartsListFilter.fulfilled, (state, { payload }) => {
      state.filters = payload;
    });
    builder.addCase(fetchPartsListOE.fulfilled, (state, { payload }) => {
      state.oeInfo = payload;
    });
  },
});

export const {resetPartsList} = PartsListSlice.actions;

export default PartsListSlice.reducer;
