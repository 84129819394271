import React, { useMemo } from 'react';
import Spinner from 'components/spinner/Spinner';
import TableMoreInformation from 'components/tables/TableMoreInformation/TableMoreInformation';
import { useTranslation } from 'react-i18next';
import { Column, useExpanded, useTable } from 'react-table';
import { IStats } from 'applications/automotor/models/statistics';
import './StatisticsTable.scss';
import i18n from 'i18n';

type Props = {
  data: IStats[];
  loading?: boolean
};

const StatisticsTable: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  const columns = useMemo(
    (): Column<IStats>[] => [
      {
        Header: t('_name'),
        accessor: 'name',
      },
      {
        Header: t('_access_nr'),
        accessor: 'count',
      },
    ],
    [i18n.language],
  );


  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data: props.data
  }, useExpanded);


  return (
    <div className={`table-wrapper statistics-table ${!rows.length && !props.loading ? 'centered' : ''}`}>
      {props.loading ? <Spinner class="statistics-table-spinner" /> : (

        !rows.length ? <div className="no-data">{t('_no_data').toLocaleUpperCase()}</div> : (
          <table {...getTableProps()}>
            <thead className="headings">
              {headerGroups.map((headerGroup, index) => {
                return (
                  <tr key={headerGroup.headers[index].id} className="row">
                    {headerGroup.headers.map((column) => {
                      const { key, ...restColumn } = column.getHeaderProps({
                        style: { minWidth: column.minWidth, width: column.width, maxWidth: column.maxWidth },
                      });

                      return (
                        <th key={key} {...restColumn} className="heading">
                          {column.render('Header')}
                        </th>
                      );
                    })}
                  </tr>
                );
              })}
            </thead>
            <tbody {...getTableBodyProps} className="body">
              {rows.map((row) => {
                prepareRow(row);
                const { key, ...restRowProps } = row.getRowProps();

                return (
                  <React.Fragment key={key}>
                    <tr key={key} {...restRowProps} className="cell-row">
                      {row.cells.map((cell) => {
                        const { key, ...restCellProps } = cell.getCellProps();

                        return (
                          <td key={key} {...restCellProps} className="cell">
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>
                    {row.isExpanded ? TableMoreInformation({ row, extraFieldsNumber: 2 }) : null}
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
        )
      )}
    </div>
  );
};

export default StatisticsTable;
