import { createSlice } from '@reduxjs/toolkit';

type ITimerState = {
  count: number;
};

const initialState: ITimerState = {
  count: 0,
};

const timerSlice = createSlice({
  name: 'timer',
  initialState,
  reducers: {
    updateCount(state) {
      state.count = state.count + 1 ;
    },
  },

});

export const { updateCount } = timerSlice.actions;

export default timerSlice.reducer;
