import { serverAxios } from '../../http';
import { IHistory } from '../../../models/IHistory';
import { CancelToken } from 'axios';

class HistoryHttp {
  private static ROUTE = 'reliable/history';
  static PER_PAGE = 10;

  public async getHistory(urlParams: IHistoryGet, cancelToken?: CancelToken): Promise<HttpResponse<IHistory>> {
    return serverAxios.get(HistoryHttp.ROUTE, { params: urlParams, cancelToken });
  }
}

export type IHistoryGet = {
  page_parts?: number;
  page_cars?: number;
  per_page?: number;
  q_cars?: string;
  q_parts?: string;
};

export type HttpResponse<P> = {
  data: P;
  status: number;
};

export default HistoryHttp;
