import AccountLayout from 'applications/automotor/layouts/account-layout/AccountLayout';
import MainLayoutNoHeader from 'applications/amerigo/layouts/main-layout-no-header/MainLayoutNoHeader';
import MainLayout from 'applications/amerigo/layouts/main-layout/MainLayout';
import EspaceCatalog from 'applications/automotor/views/espace-catalog/EspaceCatalog';
import EspaceClient from 'applications/automotor/views/espace-client/EspaceClient';
import EspaceSupplier from 'applications/amerigo/views/espace-supplier/EspaceSupplier';
import Espace from 'applications/amerigo/views/espace/Espace';
import Discutions from 'pages/Discutions/Discutions';
import Favorite from 'pages/Favorite/Favorite';
import History from 'pages/History/History';
import MonoFournisseur from 'pages/Cart/Cart';
import Parametres from 'pages/Parametres/Parametres';
import Profil from 'pages/Profil/Profil';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { SUPPLIER_AMERIGO, USER_AMERIGO } from 'utils/constants/roles';
import useForceSupplierAmerigoSelectClient from 'utils/hooks/Amerigo/useForceSupplierSelectClient';
import useAuthCheck from 'utils/hooks/useAuthCheck';
import NewLogin from 'views/NewLogin/NewLogin';
import SelectClient from './views/select-client/SelectClient';
import CatalogPage from 'pages/CatalogPage/CatalogPage';
import MySuppliers from 'pages/MySuppliers/MySuppliers';
import EspaceTariffs from 'pages/Tariffs/EspaceTariffs';
import CatalogTariffs from 'pages/CatalogPage/partial/catalog-tariff/CatalogTariffs';
import CatalogOrders from 'pages/CatalogPage/catalog-orders/CatalogOrders';
import CatalogLayout from 'pages/CatalogPage/CatalogLayout';
import Ebook from 'components/Ebook';
import CatalogCarParts from 'pages/CatalogKawe/CatalogCarParts';
import CatalogMessages from 'pages/CatalogPage/catalog-messages/CatalogMessages';
import MessagesCatalog from '../../pages/Discutions/messages-catalog/MessagesCatalog';
import CatalogVideo from 'pages/CatalogPage/catalog-video/CatalogVideo';
import useTimer from '../../utils/hooks/useTimer';
import PartItem from 'components/PartItem/PartItem';
import useCommonRoutes from 'utils/hooks/use-common-routes';
import Orders from 'pages/Orders/Orders';
import OrderView from 'pages/OrderView/OrderView';
import CatalogOrdersView from 'pages/CatalogPage/catalog-orders-view/CatalogOrdersView';
import { usePermission } from 'utils/usePermission';
import useDistributorClientsRoutes from 'utils/hooks/use-distributor-clients-routes';
import EspaceSupplierLayout from './layouts/espace-supplier-layout';
import PartsList from 'components/partsList/PartsList';
import CarPartView from 'components/partsView/CarPartView';
import useCatalogVehicleRoutes from 'utils/hooks/use-catalog-vehicle-routes';
import GenartCarParts from 'pages/GenartCarParts/GenartCarParts';
import NewsPage from 'pages/NewsPage/NewsPage';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import CarPartViewMobile from 'components/partsView/Mobile/CarPartViewMobile';
import NewsView from 'components/news/news-view/NewsView';
import Promotions from '../../pages/Promotions/Promotions';
import SelectClientLayout from './layouts/select-client-layout';
import CatalogSearchCategories from 'pages/VinCategories/CatalogSearchCategories';
import useCountNewMessages from '../../utils/hooks/useCountNewMessages';
import ProtectedRoute from 'components/high-order-components/HasRoles/ProtectedRoute';
import PartsListV3 from 'applications/automotor/components/PartsListV3/PartsListV3';
import PartsListV2 from 'components/PartsListV2/PartsListV2';


const Amerigo: React.FC = () => {
  useAuthCheck();
  useForceSupplierAmerigoSelectClient();
  useTimer();
  useCountNewMessages();
  const commonRoutes = useCommonRoutes();
  const distributorClientsRoutes = useDistributorClientsRoutes();
  const catalogVehicleRoutes = useCatalogVehicleRoutes();
  const supplierAmerigo = usePermission(SUPPLIER_AMERIGO);
  const userAmerigo = usePermission(USER_AMERIGO) && !supplierAmerigo;
  const { isMobile, isTablet } = useDeviceDetect();
  const catalog = JSON.parse(localStorage.getItem('catalog')!);

  return (
    <Routes>
      <Route path="/flipbook" element={<Ebook />} />
      <Route element={<MainLayout />}>
        {distributorClientsRoutes}
        {catalogVehicleRoutes}
        <Route path="/reliable/:vehicle_id/car-parts-by-genart" element={<GenartCarParts />} />
        <Route path="/universal-catalog" element={<EspaceCatalog />} />
        <Route path="/news" element={<NewsPage />} />
        <Route path="/news/:id" element={<NewsView />} />
        {/* <Route path="/car-parts" element={<PartsList />} /> */}
        <Route path="/car-parts" element={<PartsListV3 />} />
        <Route path="/retailer/car-parts" element={<PartsListV2 />} />
        <Route path="/car-parts/view/:id" element={isMobile || isTablet ? <CarPartViewMobile /> : <CarPartView />} />

        <Route path="/espace-suppliers" element={<ProtectedRoute roles={USER_AMERIGO}><EspaceSupplierLayout /></ProtectedRoute>} >
          <Route path="" element={<EspaceSupplier />}></Route>
          <Route path="my-suppliers" element={<MySuppliers />} />
          <Route path="espace-tariffs" element={<EspaceTariffs />} />
          <Route path="orders" element={<Orders />} />
        </Route>
        <Route path="/catalog/:id" element={<CatalogLayout />}>
          <Route path="car-parts" element={<PartsList inCatalog />}></Route>
          <Route path="parts" element={<CatalogCarParts />}></Route>
          <Route path="categories" element={<CatalogSearchCategories />}></Route>
          <Route path="" element={<CatalogPage />}></Route>
          <Route path="messages" element={<CatalogMessages />} />
          <Route path="orders/:order_id" element={<CatalogOrdersView />} />
          <Route path="orders" element={<CatalogOrders />} />
          <Route path="tariffs" element={<CatalogTariffs />} />
          <Route path="view/:reference" element={isMobile || isTablet ? <CarPartViewMobile /> : <PartItem />} />
          <Route path="video" element={<ProtectedRoute roles={SUPPLIER_AMERIGO}><CatalogVideo /></ProtectedRoute>} />
          <Route path="promotions" element={<ProtectedRoute roles={SUPPLIER_AMERIGO}><Promotions /></ProtectedRoute>} />
        </Route>
        <Route path="/reliable" element={<EspaceCatalog />} />
        <Route path="/client" element={<EspaceClient />} />
        <Route path="/supplier" element={<EspaceSupplier />} />
        <Route element={<AccountLayout />}>
          <Route path="/my-profile" element={<Profil />} />
          <Route path="/messages" element={<Discutions />} />
          <Route path="/settings" element={<Parametres />} />
          <Route path="/history" element={<History />} />
          <Route path="/favorite" element={<Favorite />} />
          <Route path="messages/supplier/:id" element={<MessagesCatalog />} />
        </Route>
        <Route path="/cart" element={<MonoFournisseur />}></Route>
        <Route path="/orders" element={<Orders />} />
        <Route path="/orders/:order_id" element={<ProtectedRoute roles={USER_AMERIGO}><OrderView /></ProtectedRoute>} />
        {supplierAmerigo && (
          <Route path="/" element={<ProtectedRoute roles={SUPPLIER_AMERIGO}><Navigate to={catalog ? `catalog/${catalog.id}` : 'select-client?page=1'} /></ProtectedRoute>}></Route>
        )}
      </Route>
      <Route element={<ProtectedRoute roles={USER_AMERIGO}><MainLayoutNoHeader /></ProtectedRoute>}>
        <Route path="/" element={<Espace />} />
      </Route>
      <Route element={<ProtectedRoute roles={SUPPLIER_AMERIGO}><SelectClientLayout /></ProtectedRoute>}>
        <Route path="/select-client" element={<SelectClient />} />
        <Route path="/waiting-offers" element={<SelectClient />} />
        <Route path="/waiting-confirmation" element={<SelectClient />} />
        <Route path="/waiting-confirmation/history-orders" element={<SelectClient />} />
        <Route path="/waiting-confirmation/history-orders/:id" element={<SelectClient />} />
      </Route>
      <Route path="/login" element={<NewLogin />} />
      {commonRoutes}

    </Routes>
  );
};

Amerigo.displayName = 'Amerigo';

export default Amerigo;
