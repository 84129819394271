export const publicRoutes = [
  'forgot-password',
  'restore-password',
  'login'
];


export const selectClientsRoutes = [
  'select-client',
  'waiting-confirmation',
  'waiting-offers',
  'waiting-confirmation/history-orders'
];