import { createSlice } from '@reduxjs/toolkit';

type proformaAutomotorSlice = {
  proformaAutomotorActive: boolean;
  isLoading: boolean;
};

const initialState: proformaAutomotorSlice = {
  proformaAutomotorActive: false,
  isLoading: false,
};

const proformaAutomotorSlice = createSlice({
  name: 'proformaAutomotor',
  initialState,
  reducers: {
    setProformaAutomotorActive(state, { payload }) {
      state.proformaAutomotorActive = payload;
    },
    setIsProformaAutomotorLoading(state, { payload }) {
      state.isLoading = payload;
    },

  },

});

export const { setProformaAutomotorActive, setIsProformaAutomotorLoading } = proformaAutomotorSlice.actions;

export default proformaAutomotorSlice.reducer;
