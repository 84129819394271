import React from 'react';


type Props = {
  title: string;
}


const ProformaRowHeader: React.FC<Props> = (props) => {

  return (
    <div>
      {props.title}
    </div>
  );
};

ProformaRowHeader.displayName = 'ProformaRowHeader';

export default ProformaRowHeader;