import { faClose, faRedo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import LinkButton from 'components/buttons/LinkButton/LinkButton';

import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import Select from 'react-select';

import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { IFilterValue, IParamsTyre, ITyre, ITyreFilter } from 'interfaces/tyre';
import Spinner from 'components/spinner/Spinner';

import './TyreFilter.scss';
import useHttp from 'utils/hooks/useHttp';
import axios from 'axios';
import { useOnClickOutside } from 'utils/hooks/useOnClickOutside';
import useReliableCatalogCarQueryParams from 'utils/hooks/useReliableCatalogCarQueryParams';
import TextInput from 'components/inputs/TextInput/TextInput';
import { isMobile } from 'react-device-detect';
import { useDeviceDetect } from '../../../utils/hooks/deviceDetect';
import {getTranslationName} from '../../../utils/locale';

type Props = {
  setOpenFilter?: (boolean) => void;
  openFilter?: boolean;
  filter: ITyreFilter | undefined;
  loadingFilter?: boolean;
  setData?: Dispatch<SetStateAction<ITyre[]>>;
  setTotalPage?: Dispatch<SetStateAction<number>>;
  setLoading: Dispatch<SetStateAction<boolean>>;
  onFilterClick?: (params) => void;
  setSelectAll?;
  setSelectedData?;
};

const TyreFilter: React.FC<Props> = (props) => {
  const { isMobile, isTablet } = useDeviceDetect();
  const navigate = useNavigate();
  const { control, getValues, setValue, reset, register } = useForm();
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const http = useHttp();
  const cancelToken = axios.CancelToken.source();
  const [brands, setBrands] = useState<IFilterValue[]>([]);
  const [seasons, setSeasons] = useState<IFilterValue[]>([]);
  const [speedRatings, setSpeedRatings] = useState<IFilterValue[]>([]);
  const [diameters, setDiameters] = useState<IFilterValue[]>([]);
  const [loadIndexs, setLoadIndexs] = useState<IFilterValue[]>([]);
  const [widths, setWidths] = useState<IFilterValue[]>([]);
  const [heights, setHeights] = useState<IFilterValue[]>([]);
  const [searchFilter, setSearchFilter] = useState<IParamsTyre | {}>({});
  const filterRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const reliableCatalogCarQueryParams = useReliableCatalogCarQueryParams();

  // useOnClickOutside(filterRef, (event: Event) => {
  //   if (
  //     props.openFilter &&
  //     (event?.target as HTMLElement).tagName !== 'svg' &&
  //     (event?.target as HTMLElement).tagName !== 'parh' &&
  //     !(event?.target as HTMLElement).className?.includes('indicatorContainer')
  //   ) {
  //     handleClick();
  //   }
  // });

  useEffect(() => {
    if (props.filter) {
      setBrands(props.filter.brand);
      const translatedSeasons = props.filter.season.map((item) => {
        return {
          value: item.value,
          label: getTranslationName(item.label),
        };
      });

      setSeasons(translatedSeasons);
      setSpeedRatings(props.filter.speed_rating);

      if (props.filter.diameter) {
        setDiameters(props.filter.diameter);
      }


      if (props.filter.load_index) {
        setLoadIndexs(props.filter.load_index);
      }

      if (props.filter.width) {
        setWidths(props.filter.width);
      }

      if (props.filter.height) {
        setHeights(props.filter.height);
      }

      if (Array.from(searchParams).length) {
        searchParams.forEach((value, key) => {
          if (key == 'brand_ids[]') {
            const activeBrand = searchParams.getAll('brand_ids[]').map((id) => {
              return props.filter?.brand.find((item) => item.value === id);
            });

            setValue('brand', activeBrand);
          }

          if (key == 'season_ids[]') {
            const activeSeason = searchParams.getAll('season_ids[]').map((id) => {
              return props.filter?.season.find((item) => item.value === id);
            });

            setValue('season', activeSeason);
          }

          if (key == 'load_indexes[]') {
            const activeloadIndexes = searchParams.getAll('load_indexes[]').map((id) => {
              return props.filter?.load_index?.find((item) => item.value === id);
            });

            setValue('load_index', activeloadIndexes);
          }

          if (key == 'speed_ratings[]') {
            const activespeedRatings = searchParams.getAll('speed_ratings[]').map((id) => {
              return props.filter?.speed_rating.find((item) => item.value === id);
            });

            setValue('speed_rating', activespeedRatings);
          }

          if (key == 'diameters[]') {
            const activesDiameters = searchParams.getAll('diameters[]').map((id) => {
              return props.filter?.diameter?.find((item) => item.value === id);
            });

            setValue('diameter', activesDiameters);
          }

          if (key == 'widths[]') {
            const activesWidths = searchParams.getAll('widths[]').map((id) => {
              return props.filter?.width?.find((item) => item.value === id);
            });

            setValue('width', activesWidths);
          }

          if (key == 'heights[]') {
            const activesHeights = searchParams.getAll('heights[]').map((id) => {
              return props.filter?.height?.find((item) => item.value === id);
            });

            setValue('height', activesHeights);
          }

          if (key == 'reference') {
            setValue('reference', searchParams.get('reference'));
          }
        });
      }
    }
  }, [props.filter, searchParams.get('season_ids[]'), searchParams.get('brand_ids[]'), searchParams.get('reference')]);


  function handleClick() {
    const value = getValues();

    const tyreTypeParams = {
      brand_ids: (value.brand && value.brand.map((item) => item.value)) || [],
      speed_ratings: (value.speed_rating && value.speed_rating.map((item) => item.value)) || [],
      season_ids: (value.season && value.season.map((item) => item.value)) || [],
      widths: (value.width && value.width.map((item) => item.value)) || [],
      heights: (value.height && value.height.map((item) => item.value)) || [],
      load_indexes: (value.load_index && value.load_index.map((item) => item.value)) || [],
      diameters: (value.diameter && value.diameter.map((item) => item.value)) || [],
      reference: value.reference && value.reference || ''
    };

    const validParams = Object.entries(tyreTypeParams).reduce((acc, [key, value]) => {
      if (value === undefined || value === '') {
        return acc;
      }

      if (key == 'reference') {
        acc[key] = value;
      } else {
        acc[key + '[]'] = value;


      }

      acc['page'] = String(1);

      return acc;
    }, {});

    props.setLoading(true);


    if (props.onFilterClick) {
      props.setOpenFilter!(false);
      setSearchParams({ ...validParams, ...reliableCatalogCarQueryParams, tab: 'tyresCatalog' });

      return props.onFilterClick(tyreTypeParams);
    }

    const searchURLParams = createSearchParams(validParams);

    navigate({
      search: `?${searchURLParams}`,
    });
    setSearchFilter(tyreTypeParams);
    props.setOpenFilter!(false);
  }

  function handleReset() {
    reset({ height: '', width: '', load_index: '', diameter: '', brand: '', speed_rating: '', season: '', reference: '' });

    if (props.onFilterClick) {
      handleClick();
    } else {
      navigate({ search: '?' });
      props.setLoading(true);
      setSearchFilter({ page: 1 });
    }

    props.setOpenFilter!(false);
  }

  useEffect(() => {
    if (!props.onFilterClick) {


      if (Object.keys(searchFilter).length) {

        http.tyreHttp
          .getTyre(searchFilter, cancelToken.token)
          .then((resp) => {
            props.setData?.(resp.data.data);
            props.setTotalPage?.(resp.data.total_pages);
            props.setLoading(false);
            props.setSelectAll(false);
            props.setSelectedData([]);
          })
          .catch(() => {
            console.log('cancelToken.token!!');
            props.setSelectAll(false);
            props.setSelectedData([]);
          });

        return () => {
          cancelToken.cancel();
        };
      }
    }
  }, [searchFilter]);

  return (
    <div ref={filterRef} className={`tyre-filter ${props.openFilter ? 'active' : ''}`}>
      <div className="header">
        <div className="buttons-container">
          <LinkButton title={t('_reset')} icon={faRedo} onClick={handleReset} />
        </div>
        <div onClick={() => props.setOpenFilter!(false)}>
          <FontAwesomeIcon className="icon" icon={faClose} />
        </div>
      </div>
      <div className="title">
        <Trans i18nKey="_filter_results" components={{ b: <strong /> }} />
      </div>

      {props.loadingFilter ? (
        <Spinner class="small" />
      ) : (
        <div className="filters">
          <div className="categories-filter">

            <div className="dropdown-item">
              <label className="dropdown-label">{t('_reference')}</label>
              <TextInput {...register('reference')} />
            </div>
            <div className="dropdown-item">
              <label className="dropdown-label">{t('_brand')}</label>
              <Controller
                control={control}
                name="brand"
                render={({ field: { onChange, value } }) => (
                  <Select
                    value={value}
                    className="tyre-filter-select-dropdown"
                    onChange={(val) => {
                      onChange(val);
                    }}
                    options={brands}
                    placeholder={`${t('_select')}  ${t('_brand')}`}
                    menuPlacement="auto"
                    isSearchable={!isMobile && !isTablet}
                    isMulti
                    menuPosition="fixed"
                    styles={{ menuPortal: base => ({ ...base, zIndex: 50 }) }}
                  />
                )}
              />
            </div>
            <div className="dropdown-item">
              <label className="dropdown-label">{t('_season')}</label>
              <Controller
                control={control}
                name="season"
                render={({ field: { onChange, value } }) => (
                  <Select
                    value={value}
                    className="tyre-filter-select-dropdown"
                    onChange={(val) => {
                      onChange(val);
                    }}
                    options={seasons}
                    placeholder={`${t('_select')}  ${t('_season')}`}
                    menuPlacement="auto"
                    isSearchable={!isMobile && !isTablet}
                    isMulti
                    menuPosition="fixed"
                    styles={{ menuPortal: base => ({ ...base, zIndex: 50 }) }}
                  />
                )}
              />
            </div>
            <div className="dropdown-item">
              <label className="dropdown-label">{t('_speed_rating')}</label>
              <Controller
                control={control}
                name="speed_rating"
                render={({ field: { onChange, value } }) => (
                  <Select
                    value={value}
                    className="tyre-filter-select-dropdown"
                    onChange={(val) => {
                      onChange(val);
                    }}
                    options={speedRatings}
                    placeholder={`${t('_select')}  ${t('_speed_rating')}`}
                    menuPlacement="auto"
                    isSearchable={!isMobile && !isTablet}
                    isMulti
                    menuPosition="fixed"
                    styles={{ menuPortal: base => ({ ...base, zIndex: 50 }) }}
                  />
                )}
              />
            </div>


            {diameters.length > 0 && (
              <div className="dropdown-item">
                <label className="dropdown-label">{t('_diameter')}</label>
                <Controller
                  control={control}
                  name="diameter"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      value={value}
                      className="tyre-filter-select-dropdown"
                      onChange={(val) => {
                        onChange(val);
                      }}
                      options={diameters}
                      placeholder={`${t('_select')}  ${t('_diameter')}`}
                      menuPlacement="auto"
                      isSearchable={!isMobile && !isTablet}
                      isMulti
                      menuPosition="fixed"
                      styles={{ menuPortal: base => ({ ...base, zIndex: 50 }) }}
                    />
                  )}
                />
              </div>
            )}
            {loadIndexs.length > 0 && (
              <div className="dropdown-item">
                <label className="dropdown-label">{t('_load_index')}</label>
                <Controller
                  control={control}
                  name="load_index"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      value={value}
                      className="tyre-filter-select-dropdown"
                      onChange={(val) => {
                        onChange(val);
                      }}
                      options={loadIndexs}
                      placeholder={`${t('_select')}  ${t('_load_index')}`}
                      menuPlacement="auto"
                      isSearchable={!isMobile && !isTablet}
                      isMulti
                      menuPosition="fixed"
                      styles={{ menuPortal: base => ({ ...base, zIndex: 50 }) }}
                    />
                  )}
                />
              </div>
            )}
            {widths.length > 0 && (
              <div className="dropdown-item">
                <label className="dropdown-label">{t('_width')}</label>
                <Controller
                  control={control}
                  name="width"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      value={value}
                      className="tyre-filter-select-dropdown"
                      onChange={(val) => {
                        onChange(val);
                      }}
                      options={widths}
                      placeholder={`${t('_select')}  ${t('_width')}`}
                      menuPlacement="auto"
                      isSearchable={!isMobile && !isTablet}
                      isMulti
                      menuPosition="fixed"
                      styles={{ menuPortal: base => ({ ...base, zIndex: 50 }) }}
                    />
                  )}
                />
              </div>
            )}
            {heights.length > 0 && (
              <div className="dropdown-item">
                <label className="dropdown-label">{t('_height')}</label>
                <Controller
                  control={control}
                  name="height"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      value={value}
                      className="tyre-filter-select-dropdown"
                      onChange={(val) => {
                        onChange(val);
                      }}
                      options={heights}
                      placeholder={`${t('_select')}  ${t('_height')}`}
                      menuPlacement="auto"
                      isSearchable={!isMobile && !isTablet}
                      isMulti
                      menuPosition="fixed"
                      styles={{ menuPortal: base => ({ ...base, zIndex: 50 }) }}
                    />
                  )}
                />
              </div>
            )}
          </div>
        </div>
      )}
      <div className="apply-btn">
        <DefaultButton text={t('_apply')} onClick={() => handleClick()} />
      </div>
    </div>
  );
};

TyreFilter.displayName = 'TyreFilter';

export default TyreFilter;
