import { CancelToken } from 'axios';
import { IHttpResponse, serverAxios } from '../../http';

class PublicAppHttp {
  private static distributors = 'public/clients-drop-down';
  private static stores = 'public/stores-drop-down';
  private static invitation = 'public/distributor-invitation';
  private static accessToken = 'public/distributor-invitation/access';
  private static progresUpload = 'progres-upload';

  public async getDistributors(): Promise<IHttpResponse<IDistributor[]>> {
    return serverAxios.get(PublicAppHttp.distributors);
  }

  public async getStores(): Promise<IHttpResponse<IStore[]>> {
    return serverAxios.get(PublicAppHttp.stores);
  }
  public async accessToken(token: string | null): Promise<IHttpResponse<string | null>> {
    return serverAxios.get(PublicAppHttp.accessToken, {params: {token}});
  }

  public async postInvitation(params: {}): Promise<IHttpResponse<{}>> {
    return serverAxios.post(PublicAppHttp.invitation, { ...params });
  }

  public progresUpload(key_upload: string, cancelToken: CancelToken): Promise<IHttpResponse<{ total_number: number; current_number: number; progress: number }>> {
    return serverAxios.get(PublicAppHttp.progresUpload, { params: { key_upload }, cancelToken: cancelToken });
  }
}

export interface IDistributor {
  numcli: number;
  name: string;
  phone?: string;
  email?: string
}

export interface IStore {
  id: number;
  name: string;
}

export default PublicAppHttp;
