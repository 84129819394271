
import { IConsumable } from 'interfaces/consumable';
import React from 'react';
import ItemEshopView from './ItemEshopConsumable.view';


type Props = {
  data: IConsumable[],
  loading: boolean
  openModalConsumable: (id: number) => void;
  deleteItem: (id: number) => void;
  register;
  handleOnChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleOnChangeTopOffer: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleOnChangeTopSales: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const ItemEshop: React.FC<Props> = (props) => {
  return (
    <ItemEshopView
      data={props.data}
      loading={props.loading}
      openModalConsumable={props.openModalConsumable}
      deleteItem={props.deleteItem}
      register={props.register}
      handleOnChange={props.handleOnChange}
      handleOnChangeTopOffer={props.handleOnChangeTopOffer}
      handleOnChangeTopSales={props.handleOnChangeTopSales}
    />
  );
};

ItemEshop.displayName = ' itemEshop';

export default ItemEshop;
