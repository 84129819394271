import React, { ChangeEvent } from 'react';
import { IClientInvitationResponse, IInvitationFilters } from 'models/client.model';
import { Trans, useTranslation } from 'react-i18next';
import { faCheck, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MainSearchInput from 'components/inputs/MainSearchInput/MainSearchInput';
import ClientsInvitationsTable from 'components/tables/ClientsInvitationsTable/ClientsInvitationsTable';
import Icon from 'components/Icon/Icon';
import Spinner from 'components/spinner/Spinner';
import Pagination from 'components/pagination/Pagination';
import Select from 'react-select';
import './ClientsInvitations.scss';

type Props = {
  invitations: IClientInvitationResponse | undefined;
  handlePageClick: (pageN: number) => void;
  isLoading: boolean;
  invitationsCount: number;
  navigateBack: () => void;
  searchFilterType: IInvitationFilters;
  changeSearchHandler: (event: ChangeEvent<HTMLInputElement>) => void;
  searchInputValue: string;
  onFilterSubmit: () => void;
  changeRadioHandler: (value: string) => void;
  onChangeFilterType: (value: IInvitationFilters) => void;
  fetchData?
};

const MyClientsView: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();

  const filterOptionsText = [
    {
      value: 'email',
      label: String(t('_email')),
    },
    {
      value: 'seen',
      label: String(t('_seen')),
    },
    {
      value: 'full_name',
      label: String(t('_name')),
    },
  ];

  return (
    <div className="wrapper-client-invitation-page">
      <div className="top-content">
        <div className="return-button-wrapper">
          <Icon name="circleLeftRegular" className="button" onClick={props.navigateBack}></Icon>
          <h4 className="title-back">{t('_back_to_client_list')}</h4>
          <div></div>
        </div>
        <h1 className="title">
          {props.invitationsCount > 0 ? (
            <Trans i18nKey="_pending_invitations" values={{ pendingInvitations: props.invitationsCount.toString() }} />
          ) : (
            t('_all_invitations')
          )}
        </h1>
      </div>
      <div className="search-wrapper">
        <div className="wrapper-title">
          <h1 className="search">{t('_search_for_invitation').toLocaleUpperCase()}</h1>
        </div>
        <div className="inside-content">
          <div className="client-search">
            <div className="test">
              {props.searchFilterType !== IInvitationFilters.SEEN ? (
                <MainSearchInput
                  size="m"
                  placeholder={t('_search_for_invitation')}
                  onChange={props.changeSearchHandler}
                  value={props.searchInputValue}
                  withIcon
                  onSubmit={props.onFilterSubmit}
                ></MainSearchInput>
              ) : (
                <div className="radio-container">
                  <div className="radio">
                    <input
                      className="check"
                      type="radio"
                      value="yes"
                      defaultChecked
                      name="active-radio"
                      onChange={() => props.changeRadioHandler('yes')}
                    />
                    <label htmlFor="active-yes">{t('_yes')}</label>
                    <FontAwesomeIcon icon={faCheck} className="check-icon" />
                  </div>
                  <div className="radio">
                    <input
                      className="check"
                      type="radio"
                      value="no"
                      name="active-radio"
                      onChange={() => props.changeRadioHandler('no')}
                    />
                    <label htmlFor="active-no">{t('_no')}</label>
                    <FontAwesomeIcon icon={faCheck} className="check-icon" />
                  </div>
                  <button className="input-search-icon" onClick={() => props.onFilterSubmit()}>
                    <FontAwesomeIcon icon={faSearch} />
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className="sort-wrapper">
            <Select
              className="select-dropdown"
              onChange={(val) => {
                props.onChangeFilterType(val?.value as IInvitationFilters);
              }}
              options={filterOptionsText}
            />
            {/* <select className="sort" onChange={(e) => props.onChangeFilterType(e.target.value as IInvitationFilters)}>
              {Object.keys(IInvitationFilters).map((key) => (
                <option key={key} value={IInvitationFilters[key]}>
                  {filterOptionsText[key]}
                </option>
              ))}
            </select> */}
          </div>
        </div>
      </div>
      {!props.isLoading ? (
        <div>
          <ClientsInvitationsTable invitations={props.invitations?.data || []} fetchData={props.fetchData} />
          <Pagination
            handlePageClick={props.handlePageClick}
            pageCount={props.invitations?.total_pages || 0}
            forcePage={props.invitations?.page || 0}
          ></Pagination>
        </div>
      ) : (
        <Spinner class="content-spinner" />
      )}
    </div >
  );
};

MyClientsView.displayName = 'MyClientsView';

export default MyClientsView;
