import ListPartOEM from 'components/ListPartOEM/ListPartOEM';
import PartOEM from 'components/partOEM/PartOEM';
import React, { useState } from 'react';
import { ReactComponent as PartsNotFoundImage } from 'assets/images/parts-not-found.svg';
import {
  CATALOG_BATTERY,
  CATALOG_CONSUMABLE,
  CATALOG_OIL,
  CATALOG_PART_OEM,
  CATALOG_TYRE,
  NEW_CATALOG_PART_OEM,
  PRODUCT_VIEW_MODE_CHANGER_CATALOG_TYPES,
  CATALOG_CAR_PARTS,
  CATALOG_PART_OEM_V2
} from './products-view-mode-changer-utils';
import CatalogConsumableItem from 'pages/CatalogVehicle/Catalogs/CatalogsConsumablesPage/CatalogConsumableItem/CatalogConsumableItem';
import CatalogConsumableItemList from 'pages/CatalogVehicle/Catalogs/CatalogsConsumablesPage/CatalogConsumableItemList/CatalogConsumableItemList';
import CatalogBatteryItem from 'pages/CatalogVehicle/Catalogs/CatalogsBatteriesPage/CatalogBatteryItem/CatalogBatteryItem';
import CatalogBatteryItemList from 'pages/CatalogVehicle/Catalogs/CatalogsBatteriesPage/CatalogBatteryItemList/CatalogBatteryItemList';
import CatalogOilItem from 'pages/CatalogVehicle/Catalogs/CatalogsOilsPage/CatalogOilItem/CatalogOilItem';
import CatalogOilItemList from 'pages/CatalogVehicle/Catalogs/CatalogsOilsPage/CatalogOilItemList/CatalogOilItemList';
import TyreCard from '../TyreCard/TyreCard';
import TyreCardList from '../TyreCardList/TyreCardList';
import PartOE from 'components/partOE/PartOE';

import CatalogPartsItem from 'components/CatalogItem/CatalogPartsItem';
import CatalogPartsItemList from 'components/CatalogItemList/CatalogPartsItemList';
import PartOENew from 'components/partOENew/PartOENew';
import ListPartOENew from 'components/ListPartOENew/ListPartOENew';
import ListPartOE from 'components/ListPartOE/ListPartOE';
import { useSelector } from 'react-redux';

import { getParts } from 'store/selectors/car-parts.selector';
import Spinner from 'components/spinner/Spinner';
import { useTranslation } from 'react-i18next';
import MecadepotCatalogItem from 'pages/CatalogVehicle/Catalogs/MecadepotCatalogItem/MecadepotCatalogItem';
import './ProductsViewModeChanger.scss';
import MecadepotCatalogItemList from 'pages/CatalogVehicle/Catalogs/MecadepotCatalogItemList/MecadepotCatalogItemList';
import PartOEMMecadepot from 'components/partOEMMecadepot/PartOEMMecadepot';
import { isRetailerMecadepotChild } from 'utils/isRetailerMecadepotChild';
import ListPartOEMMecadepot from 'components/ListPartOEMMecadepot/ListPartOEMMecadepot';

type Props = {
  viewMode: string;
  catalog: keyof typeof PRODUCT_VIEW_MODE_CHANGER_CATALOG_TYPES;
  data?;
  oeData?;
  oemLoading?;
  inCatalog?: boolean;
  dataOE?,
  workTimeInfo?
};


const ProductsViewModeChanger: React.FC<Props> = (props) => {
  const [data, setData] = useState(props.data);
  const [parts, setParts] = useState(useSelector(getParts));
  const { t } = useTranslation();
  const user = JSON.parse(localStorage.getItem('userData')!);
  const isMecadepotChild = isRetailerMecadepotChild();

  const updateData = (itemOld, isChecked) => {
    if (props.catalog === NEW_CATALOG_PART_OEM) {

      const newArray = data.map(item => {

        if (item.cod_fou + item.data_supplier_reference === itemOld.cod_fou + itemOld.data_supplier_reference) {

          return { ...item, is_favorite: isChecked };
        }

        return item;

      });


      setData(newArray);
    }

    if (props.catalog === CATALOG_PART_OEM) {

      const newArray = data.map(item => {


        const newCarParts = item.car_parts.map(part => {
          if (part.cod_fou + part.data_supplier_reference === itemOld.cod_fou + itemOld.data_supplier_reference) {

            return { ...part, is_favorite: isChecked };
          }

          return part;
        });

        return { ...item, car_parts: newCarParts };


      });


      setData(newArray);
    }


    if (props.catalog === CATALOG_CAR_PARTS) {
      const newArray = parts.map(item => {
        console.log(parts);

        if (item.cod_fou + item.data_supplier_reference === itemOld.cod_fou + itemOld.data_supplier_reference) {
          return { ...item, is_favorite: isChecked };
        }

        return item;
      });

      setParts(newArray);
    }


  };


  return (
    <div className={`catalog-view-mode ${props.viewMode}`}>
      {props.catalog === NEW_CATALOG_PART_OEM && (
        <React.Fragment>

          {props.viewMode === 'LIST' &&
            <React.Fragment>
              {(!isMecadepotChild && [props.dataOE].flat(1).length) ? < ListPartOENew data={props.dataOE} /> : null}
              {data.map((item, index) => {
                return isMecadepotChild ? <ListPartOEMMecadepot data={{ ...item, ...props.workTimeInfo }} key={index} updateData={updateData} /> :
                  <ListPartOEM data={{ ...item, ...props.workTimeInfo }} key={index} updateData={updateData} />;
              })}

            </React.Fragment>

          }
          {props.viewMode === 'GRID' &&
            <React.Fragment>
              {(!isMecadepotChild && !Array.isArray(props.dataOE)) && <PartOENew data={props.dataOE} />}
              {data.map((item, index) => {
                return isMecadepotChild ?
                  <PartOEMMecadepot inCatalog={props.inCatalog} data={{ ...item, ...props.workTimeInfo }} key={index} updateData={updateData} />
                  :
                  <PartOEM inCatalog={props.inCatalog} data={{ ...item, ...props.workTimeInfo }} key={index} updateData={updateData} />;
              })}

            </React.Fragment>
          }
        </React.Fragment>
      )}
      {props.catalog === CATALOG_PART_OEM && (
        <React.Fragment>
          {props.viewMode === 'LIST' &&
            data.map((item, index) => {


              return (
                <React.Fragment key={index}>
                  {!isMecadepotChild && <ListPartOE data={item} />}
                  {item.car_parts &&
                    item.car_parts.map((part, i) => {
                      return isMecadepotChild ? <ListPartOEMMecadepot inCatalog={props.inCatalog} data={part} key={part.cod_fou + part.data_supplier_reference + i} updateData={updateData} /> :
                        <ListPartOEM inCatalog={props.inCatalog} data={part} key={part.cod_fou + part.data_supplier_reference + i} updateData={updateData} />;
                    })}
                </React.Fragment>
              );
            })}
          {props.viewMode === 'GRID' &&
            data.map((item, index) => {

              return (
                <React.Fragment key={index}>
                  {!isMecadepotChild && <PartOE data={item} />}
                  {item.car_parts &&
                    item.car_parts.map((part, i) => {
                      return isMecadepotChild ?
                        <PartOEMMecadepot inCatalog={props.inCatalog} data={part} key={part.cod_fou + part.data_supplier_reference + i} updateData={updateData} />
                        :
                        <PartOEM inCatalog={props.inCatalog} data={part} key={part.cod_fou + part.data_supplier_reference + i} updateData={updateData} />;


                    })}
                </React.Fragment>
              );
            })}
        </React.Fragment>
      )}

      {props.catalog === CATALOG_PART_OEM_V2 && (
        <React.Fragment>
          {props.viewMode === 'LIST' &&
            (
              <React.Fragment>
                {(!isMecadepotChild && props.oeData) && <ListPartOE data={props.oeData} v2 />}
                {props.oemLoading ? <Spinner class="car-parts-spinner-info" /> :
                  props.data ?
                    props.data.map((part, i) => {

                      return isMecadepotChild ? <ListPartOEMMecadepot inCatalog={props.inCatalog} data={part} key={part.cod_fou + part.data_supplier_reference + i} updateData={updateData} /> :
                        <ListPartOEM inCatalog={props.inCatalog} data={part} key={part.cod_fou + part.data_supplier_reference + i} updateData={updateData} />;
                    }) : !props.oeData && <div className="no-parts-data">
                      <div className="notFoundSvg">
                        <PartsNotFoundImage />
                      </div>
                      <h1>{t('_parts_not_found')}</h1>
                    </div>}
              </React.Fragment>
            )}
          {props.viewMode === 'GRID' &&
            (<React.Fragment>
              {(!isMecadepotChild && props.oeData) && <PartOE data={props.oeData} v2 />}
              {props.oemLoading ? <Spinner class="car-parts-spinner-info" /> :
                props.data ?
                  props.data.map((part, i) => {
                    return isMecadepotChild ?
                      <PartOEMMecadepot inCatalog={props.inCatalog} data={part} key={part.cod_fou + part.data_supplier_reference + i} updateData={updateData} />
                      :
                      <PartOEM inCatalog={props.inCatalog} data={part} key={part.cod_fou + part.data_supplier_reference + i} updateData={updateData} />;


                  }) : !props.oeData && <div className="no-parts-data">
                    <div className="notFoundSvg">
                      <PartsNotFoundImage />
                    </div>
                    <h1>{t('_parts_not_found')}</h1>
                  </div>}
            </React.Fragment>
            )}
        </React.Fragment>
      )}
      {props.catalog === CATALOG_CONSUMABLE && (
        <React.Fragment>
          {props.viewMode === 'LIST' &&
            data.map((item, index) => {
              return isMecadepotChild ? <MecadepotCatalogItemList data={item} key={index} /> : <CatalogConsumableItemList data={item} key={index} />;
            })}
          {props.viewMode === 'GRID' &&
            data.map((item, index) => {
              return isMecadepotChild ? <MecadepotCatalogItem data={item} key={index} /> : <CatalogConsumableItem data={item} key={index} />;
            })}
        </React.Fragment>
      )}
      {props.catalog === CATALOG_BATTERY && (
        <React.Fragment>
          {props.viewMode === 'LIST' &&
            data.map((item, index) => {
              return isMecadepotChild ? <MecadepotCatalogItemList data={item} key={index} /> : <CatalogBatteryItemList data={item} key={index} />;
            })}
          {props.viewMode === 'GRID' &&
            data.map((item, index) => {
              return isMecadepotChild ? <MecadepotCatalogItem data={item} key={index} /> : <CatalogBatteryItem data={item} key={index} />;
            })}
        </React.Fragment>
      )}
      {props.catalog === CATALOG_OIL && (
        <React.Fragment>
          {props.viewMode === 'LIST' &&
            data.map((item, index) => {
              return isMecadepotChild ? <MecadepotCatalogItemList data={item} key={index} /> : <CatalogOilItemList data={item} key={index} />;
            })}
          {props.viewMode === 'GRID' &&
            data.map((item, index) => {
              return isMecadepotChild ? <MecadepotCatalogItem data={item} key={index} /> : <CatalogOilItem data={item} key={index} />;
            })}
        </React.Fragment>
      )}
      {props.catalog === CATALOG_TYRE && (
        <React.Fragment>
          {props.viewMode === 'LIST' &&
            data.map((item, index) => {
              return isMecadepotChild ? <MecadepotCatalogItemList data={item} key={index} /> : <TyreCardList key={index} tyreData={item} />;
            })}
          {props.viewMode === 'GRID' &&
            data.map((item, index) => {
              return isMecadepotChild ? <MecadepotCatalogItem data={item} key={index} /> : <TyreCard key={index} tyreData={item} />;
            })}
        </React.Fragment>
      )}
      {props.catalog === CATALOG_CAR_PARTS && (
        <React.Fragment>
          {props.viewMode === 'LIST' && <CatalogPartsItemList parts={parts} updateData={updateData} />}
          {props.viewMode === 'GRID' && <CatalogPartsItem parts={parts} updateData={updateData} />}
        </React.Fragment>
      )}
    </div>
  );
};

export default ProductsViewModeChanger;
