import React, { useState, useEffect, useMemo } from 'react';
import { IClient, IClientContact, IClientUserSettings } from 'models/client.model';
import useHttp from 'utils/hooks/useHttp';
import ModifyClientView from './ModifyClient.view';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getClientContacts, getIsLoadingContacts } from 'store/selectors/clients.selector';
import { useAppDispatch } from 'utils/hooks/redux';
import { fetchClientContacts } from 'store/reducers/clients/clients.actions';
import { notify } from 'utils/marketplace';
import { useForm } from 'react-hook-form';
import { IDateOption } from 'models/date-picker';
import { useTranslation } from 'react-i18next';

type Props = {};

const ModifyClient: React.FC<Props> = () => {
  const { id } = useParams<'id'>();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {t} = useTranslation();
  const [showClientEdit, setShowClientEdit] = useState<boolean>(false);
  const [showContactEdit, setShowContactEdit] = useState<boolean>(false);
  const [clientDetails, setClientDetails] = useState<IClient | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [newContactMode, setNewContactMode] = useState<boolean>(false);
  const [contractToEdit, setContractToEdit] = useState<IClientContact | null>(null);
  const [openConfirmModal, setConfirmModal] = useState<boolean>(false);
  const [rmiModules, setRmiModules] = useState<boolean>(false);
  const [diagnosticCheck, setDiagnosticCheck] = useState<boolean>(false);

  const [plateValueDate, setPlateValueDate] = useState<IDateOption>();
  const [rmiValueDate, setRmiValueDate] = useState<IDateOption>();
  const [subscriptionValueDate, setSubscriptionValueDate] = useState<IDateOption>();

  const { register, getValues, setValue, reset, watch } = useForm<IClientUserSettings>({
    defaultValues: useMemo(() => {
      return clientDetails?.client_settings;
    }, [clientDetails]),
  });

  const contactDetails = useSelector(getClientContacts);
  const loadingContacts = useSelector(getIsLoadingContacts);
  const http = useHttp();
  const { search } = useLocation();

  useEffect(() => {
    refreshData();
  }, []);

  const refreshData = () => {
    setLoading(true);
    dispatch(fetchClientContacts(Number(id)));
    http.clients.getClientDetail(Number(id)).then((client: IClient) => {
      setClientDetails(client);
      setRmiModules(client.client_settings.rmi_modules_type !== null && client.client_settings.rmi_modules_type !== 'none');
      setDiagnosticCheck(client.client_settings.actia);

      delete client.client_settings.work_cost;
      reset(client.client_settings);

      setLoading(false);
    });
  };

  const handleConfirmModal = () => {
    setLoading(true);
    http.clients
      .deleteClient(Number(id))
      .then(() => {
        setLoading(false);
        notify('User deleted', 'success');
        navigate('/espace-clients/my-clients');
      })
      .catch(() => {
        setLoading(false);
        notify('Error deleting user');
      });
  };

  const handleSubmit = () => {
    const values = getValues();

    if(values.plate_number_search && plateValueDate){
      values.plate_number_start_date = plateValueDate?.start_date,
      values.plate_number_end_date = plateValueDate?.end_date;
    }

    if((values.rmi_modules_type === 'CAR_Pro' || values.rmi_modules_type === 'CAR_Tech') && rmiValueDate){
      values.rmi_modules_start_date = rmiValueDate.start_date,
      values.rmi_modules_end_date = rmiValueDate.end_date;
    }

    if(subscriptionValueDate){
      values.subscription_start_date = subscriptionValueDate.start_date,
      values.subscription_end_date = subscriptionValueDate.end_date;
    }

    values.diagnostic = {
      actia: values.actia,
    };
    
    delete values.plate_number_subscription_period;
    delete values.rmi_modules_subscription_period;

    setLoading(true);

    http.clients
      .patchClientSettings(Number(id), values)
      .then((data) => {
        if(data?.eina_subscription === true) {
          http.einaInternal.create(Number(id))
            .then(() => {

              return http.einaInternal.activate(Number(id))
                .then(() => {
                  setLoading(false);

                  notify('User settings updated', 'success');
                });
            })
            .finally(() => {
              setLoading(false);
            });
        }

        if(data?.eina_subscription === false) {
          http.einaInternal.deactivate(Number(id))
            .then(() => {
              setLoading(false);
              notify('User settings updated', 'success');
            })
            .finally(() => {
              setLoading(false);
            });
        }

      })
      .catch((error) => {
        setLoading(false);
        notify(error?.client_settings_form[0] || t('_error'));
      });
  };

  const handleReturnClick = () => {
    navigate('/espace-clients/my-clients');
    navigate({
      pathname: '/espace-clients/my-clients',
      search: `${search}`,
    });
  };

  const handleClientEdit = () => {
    setShowClientEdit((prevState) => !prevState);
  };

  const handleContactEdit = (contract: IClientContact | null) => () => {
    setNewContactMode(false);
    setContractToEdit(contract);
    setShowContactEdit((prevState) => !prevState);
  };

  const handleNewContact = () => {
    setNewContactMode(true);
    setShowContactEdit((prevState) => !prevState);
  };

  const handleRmiModulesCheck = (e) => {
    if (typeof e === 'string') {
      setRmiModules(true);
      setValue('rmi_modules_type', e);

      return;
    }

    setRmiModules(e.target.checked);

    if (e.target.checked) {
      setValue('rmi_modules_type', 'CAR_Tech');
    } else {
      setValue('rmi_modules_type', 'none');
    }
  };

  const handleDiagnosticCheck = (e) => {
    setDiagnosticCheck(e.target.checked);
    setValue('actia', e.target.checked);
  };


  return (
    <ModifyClientView
      showClientEdit={showClientEdit}
      showContactEdit={showContactEdit}
      newContactMode={newContactMode}
      handleContactEdit={handleContactEdit}
      handleNewContact={handleNewContact}
      onClientEdit={handleClientEdit}
      handleReturnClick={handleReturnClick}
      refreshData={refreshData}
      register={register}
      watch={watch}
      handleSubmit={handleSubmit}
      contactDetails={contactDetails}
      contractToEdit={contractToEdit}
      clientDetails={clientDetails}
      loading={loadingContacts || loading}
      openConfirmModal={openConfirmModal}
      handleConfirmModal={handleConfirmModal}
      setConfirmModal={setConfirmModal}
      getValues={getValues}
      rmiModules={rmiModules}
      handleRmiModulesCheck={handleRmiModulesCheck}
      diagnosticCheck={diagnosticCheck}
      handleDiagnosticCheck={handleDiagnosticCheck}
      setPlateValueDate={setPlateValueDate}
      setRmiValueDate={setRmiValueDate}
      setSubscriptionValueDate={setSubscriptionValueDate}
      subscriptionValueDate={subscriptionValueDate}
    />
  );
};

ModifyClient.displayName = 'ModifyClient';

export default ModifyClient;
