import React, { useRef, useState } from 'react';
import { faCheck, faClose, faRedo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import LinkButton from 'components/buttons/LinkButton/LinkButton';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { Trans, useTranslation } from 'react-i18next';
import './GenartPartsFilter.scss';
import { useOnClickOutside } from 'utils/hooks/useOnClickOutside';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';

type Props = {
  setOpenFilter?: (boolean) => void;
  openFilter?: boolean;
  filterData?;
  getData?;
  setGrabFilterData?: (data) => void
};

const GenartPartsFilter: React.FC<Props> = (props) => {
  const { control, getValues, reset, register } = useForm();
  const { t } = useTranslation();
  const [disableSuppliers, setDisableSuppliers] = useState(false);
  const filterRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const { isMobile, isTablet } = useDeviceDetect();

  // useOnClickOutside(filterRef, () => {
  //   if (props.openFilter) {
  //     handleClick();
  //   }
  // });

  const getSupplierOptions = () => {
    if (!props.filterData?.data_suppliers?.length) {
      return;
    }

    return (
      props.filterData?.data_suppliers?.map((item) => ({
        label: item.name,
        value: item.id,
        is_amerigo: item.is_amerigo,
        isDisabled: item.is_amerigo && item.is_amerigo === disableSuppliers,
      })) || []
    );
  };

  const getBrandOptions = () => {
    if (!props.filterData?.car_manufacturers?.length) {
      return;
    }

    return (
      props.filterData?.car_manufacturers?.map((item) => ({
        label: item.name,
        value: item.id,
      })) || []
    );
  };

  const getSubcategoryOptions = () => {
    if (!props.filterData?.subcategories?.length) {
      return;
    }

    return (
      props.filterData?.subcategories?.map((item) => ({
        label: item.name,
        value: item.id,
      })) || []
    );
  };


  const getAtributesOptions = (key) => {
    if (!props.filterData?.attributes[key]?.length) {
      return;
    }

    return (
      props.filterData?.attributes[key]?.map((item) => ({
        label: item,
        value: item,
      })) || []
    );
  };

  function handleClick() {
    const attributeValues = getValues();

    delete attributeValues.supplier;
    delete attributeValues.brand;
    delete attributeValues.subcategory;
    delete attributeValues.isAmerigo;

    const attributeLabels = Object.keys(attributeValues).map(attribute => {
      if (getValues()[attribute] && getValues()[attribute][Object.keys(getValues()[attribute])[0]]) {
        return getValues()[attribute].map(item => item.label)[0];
      }
    });

    const validAttributes = attributeLabels.filter(attribute => attribute);

    const params = {
      manufacturer: getValues().brand && [
        ...getValues().brand.map(item => item.value),
      ],
      supplier: getValues().supplier && [
        ...getValues().supplier.map(item => { return { name: item.label, id: item.value, is_amerigo: item.is_amerigo }; })
      ],
      subcategory: getValues().subcategory && [
        ...getValues().subcategory.map(item => item.value)
      ],
      attributes: validAttributes as Array<string>,
      isAmerigo: getValues().isAmerigo,
    };

    const validParams = Object.entries(params).reduce((acc, [key, value]) => {
      if (value === undefined || value === '') {
        return acc;
      }

      acc[key] = value;

      return acc;
    }, {});

    props.setGrabFilterData!(validParams);
    props.setOpenFilter!(false);
  }

  function handleReset() {
    reset({ brand: [], supplier: [], isAmerigo: false, construction_year_to: '', engine_code: '', manufacturer_restriction: '', tecdoc_engine_number: '' });
    props.setGrabFilterData!({});
    setDisableSuppliers(false);
    props.setOpenFilter!(false);
  }

  function handleSupplierChange(val) {
    if (val.some((item) => item.is_amerigo)) {
      setDisableSuppliers(true);
    } else {
      setDisableSuppliers(false);
    }
  }


  return (
    <div ref={filterRef} className={`parts-filter-genard ${props.openFilter ? 'active' : ''}`}>
      <div className="header">
        <div className="buttons-container">
          <LinkButton title={t('_reset')} icon={faRedo} onClick={handleReset} />
        </div>
        <div onClick={() => props.setOpenFilter!(false)}>
          <FontAwesomeIcon className="icon" icon={faClose} />
        </div>
      </div>
      <div className="title">
        <Trans i18nKey="_filter_results" components={{ b: <strong /> }} />
      </div>
      <div className="filters">

        <div className="dropdowns-container">
          <div className="dropdown-item">
            {props.filterData?.attributes && (
              <div className="checkbox-select-container">
                <div className="checkbox-item">
                  <input className="check" type="checkbox" value="true" {...register('isAmerigo')} />
                  <label htmlFor="is-amerigo">{t('_is_amerigo')}</label>
                  <FontAwesomeIcon icon={faCheck} className="check-icon" />
                </div>
              </div>
            )}
            <label className="dropdown-label">{t('_supplier')}</label>
            <Controller
              control={control}
              name="supplier"
              render={({ field: { onChange, value } }) => (
                <Select
                  value={value}
                  isMulti
                  className="parts-filter-select-dropdown"
                  onChange={(val) => {
                    onChange(val);
                    handleSupplierChange(val);
                  }}
                  isDisabled={value?.is_amerigo}
                  options={getSupplierOptions()}
                  placeholder={t('_supplier')}
                  isClearable={false}
                  menuPlacement="auto"
                  menuPosition="fixed"
                  styles={{ menuPortal: base => ({ ...base, zIndex: 50 }) }}
                  isSearchable={!isMobile && !isTablet}
                />
              )}
            />
          </div>
          <div className="dropdown-item">
            <label className="dropdown-label">{t('_oil_brand')}</label>
            <Controller
              control={control}
              name="brand"
              render={({ field: { onChange, value } }) => (
                <Select
                  value={value}
                  isMulti
                  className="parts-filter-select-dropdown"
                  onChange={(val) => {
                    onChange(val);
                  }}
                  options={getBrandOptions()}
                  placeholder={t('_oil_brand')}
                  isClearable={false}
                  menuPlacement="top"
                  menuPosition="fixed"
                  styles={{ menuPortal: base => ({ ...base, zIndex: 50 }) }}
                  isSearchable={!isMobile && !isTablet}
                />
              )}
            />
          </div>
          <div className="dropdown-item">
            <label className="dropdown-label">{t('_subcategory')}</label>
            <Controller
              control={control}
              name="subcategory"
              render={({ field: { onChange, value } }) => (
                <Select
                  value={value}
                  isMulti
                  className="parts-filter-select-dropdown"
                  onChange={(val) => {
                    onChange(val);
                  }}
                  options={getSubcategoryOptions()}
                  placeholder={t('_subcategory')}
                  isClearable={false}
                  menuPlacement="top"
                  menuPosition="fixed"
                  styles={{ menuPortal: base => ({ ...base, zIndex: 50 }) }}
                  isSearchable={!isMobile && !isTablet}
                />
              )}
            />
          </div>

        </div>

        {props.filterData?.attributes && (
          <React.Fragment>
            <div className="container-label">{t('_aim_criteria')}</div>
            <div className="dropdowns-container-attributes-criteria">

              {Object.keys(props.filterData?.attributes).map((key) => {
                const filteredAttributes = props.filterData?.attributes[key].filter(attr => attr);

                if (!filteredAttributes.length) {
                  return;
                }

                return (
                  <div key={key} className="dropdown-item">
                    <label className="dropdown-second-label">{key}</label>
                    <Controller
                      control={control}
                      name={key.replaceAll(' ', '_').toLocaleLowerCase()}
                      render={({ field: { onChange, value } }) => (
                        <Select
                          value={value}
                          isMulti
                          className="parts-filter-select-dropdown"
                          onChange={(val) => {
                            onChange(val);
                          }}
                          options={getAtributesOptions(key)}
                          menuPlacement="auto"
                          isClearable={false}
                          placeholder={key}
                          menuPosition="fixed"
                          styles={{ menuPortal: base => ({ ...base, zIndex: 50 }) }}
                          isSearchable={!isMobile && !isTablet}
                        />
                      )}
                    />
                  </div>
                );
              })}
            </div>
          </React.Fragment>
        )}
      </div>
      <div className="apply-btn">
        <DefaultButton text={t('_apply')} onClick={() => handleClick()} />
      </div>
    </div>
  );
};

export default GenartPartsFilter;
