import React, { useEffect, useState } from 'react';
import './VehicleEquivalenceItem.scss';
import { SingleValue } from 'react-select';
import { IOption } from '../../../../../../interfaces/select';
import { IEquivalenceVersion } from '../../../../../../models/car-parts.model';
import { createSearchParams, Link } from 'react-router-dom';

type Props = {
  version: IEquivalenceVersion;
  selectedManufacturer: SingleValue<IOption> | undefined;
};

const VehicleEquivalenceItem: React.FC<Props> = (props) => {
  const [link, setLink] = useState('');

  const carPartName = (data) => {
    const {
      manufacturerName,
      shortName,
      modelName,
      name,
      engineCapacity,
      capacityQualifier,
      vehicle_trim_code,
      startYear,
      startMonth,
      endYear,
      endMonth,
      fapDescription,
      otherDescription,
      valvesDescription,
      nbOfCylinder,
      kwPower,
      horsePower,
      engine_code,
      lhd,
      sub_model,
      first_reg_date,
    } = data;

    let carName = '';

    if (manufacturerName) {
      carName = manufacturerName;
    }

    if (shortName || modelName) {
      carName = `${carName} ${shortName || modelName}`;
    }

    if (name) {
      carName = `${carName} ${name}`;
    }

    if (engineCapacity) {
      carName = `${carName} ${engineCapacity}`;
    }

    if (sub_model) {
      carName = `${carName} (${sub_model})`;
    }

    if (capacityQualifier) {
      carName = `${carName} ${capacityQualifier}`;
    }

    if (vehicle_trim_code) {
      carName = `${carName} ${vehicle_trim_code}`;
    }

    if (valvesDescription) {
      carName = `${carName} ${valvesDescription}`;
    }

    if (otherDescription) {
      carName = `${carName} ${otherDescription}`;
    }

    if (startMonth && startYear) {
      carName = `${carName} ${startMonth}/${startYear}`;
    }

    if (endMonth && endYear) {
      carName = `${carName} - ${endMonth}/${endYear}`;
    }

    if (fapDescription) {
      carName = `${carName} ${fapDescription}`;
    }

    if (nbOfCylinder) {
      carName = `${carName} ${nbOfCylinder}`;
    }

    if (kwPower) {
      carName = `${carName} - ${kwPower} KW`;
    }

    if (horsePower) {
      carName = `${carName} - ${horsePower} CH DIN`;
    }

    if (engine_code) {
      carName = `${carName} ${engine_code}`;
    }

    if (lhd) {
      carName = `${carName} ${lhd}`;
    }

    if (first_reg_date) {
      carName = `${carName} DATE_1ER_MEC: ${first_reg_date}`;
    }

    return carName;
  };

  useEffect(() => {
    const newLink = {
      pathname: `/reliable/rmi/${props.version.id}/catalogs`,
      search: createSearchParams({
        source: props.version.source,
        vehicle_identifier: '002',
      }).toString(),
    };

    setLink(newLink.pathname + '?' + newLink.search);
  }, [props.version]);

  return (
    <div className="vehicle-equivalence-item-list">
      <Link className="vehicle-equivalence-item" to={link}>
        <div className="content">
          <div className="title">
            <p>
              {props.selectedManufacturer?.label} {props.version.modelName}
            </p>
          </div>
          <div className="description">
            <p>{carPartName(props.version)}</p>
          </div>
        </div>
      </Link>
    </div>
  );
};

VehicleEquivalenceItem.displayName = 'VehicleEquivalenceItem';

export default VehicleEquivalenceItem;
