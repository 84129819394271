
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getTariffs, loadingTariffs } from 'store/selectors/automotor-tariff.selector';
import { RootState } from 'store/store';
import { ROLE_COMMERCIAL_DIRECTOR, ROLE_SELLER } from 'utils/constants/roles';

import Card from './Card/Card';
import './EspaceAutomotorOffer.scss';


const EspaceAutomotorOffer: React.FC = () => {
  const { t } = useTranslation();
  const data = useSelector(getTariffs);
  const loading = useSelector(loadingTariffs);
  const navigate = useNavigate();
  const { user } = useSelector((state: RootState) => state.userData);

  return (
    <div className="automotor-tariff-cards">
      <div className="automotor-offer-cards">
        <Card title={t('_manufacturers').toLocaleUpperCase()} data={data && data.manufaturers} loadingData={loading} type="man" />
        <Card title={t('_equipment_manufacturers').toLocaleUpperCase()} data={data && data.suppliers} loadingData={loading} type="sup" />
      </div>
      {(user?.roles.includes(ROLE_SELLER) || user?.roles.includes(ROLE_COMMERCIAL_DIRECTOR)) &&
        <div className="automotor-tariff-link">
          <div className="tariff-link" onClick={() => navigate('/automotor-online/price/tariffs')}> {t('_upload_tariff_files')}</div>
        </div>
      }
    </div>
  );
};

EspaceAutomotorOffer.displayName = 'EspaceAutomotorOffer';

export default EspaceAutomotorOffer;
