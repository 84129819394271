import React, { useContext, useEffect, useState } from 'react';
import { IFavoriteList } from 'models/favorite';
import Spinner from 'components/spinner/Spinner';

import useHttp from 'utils/hooks/useHttp';
import { notify } from 'utils/marketplace';
import './FavoriteSidebar.scss';
import Modal from 'components/modal/Modal';
import { contentStyle } from 'components/modal/ContentStyleModal';
import { ReactComponent as CloseButton } from 'assets/images/x.svg';
import { useTranslation } from 'react-i18next';
import { FavoriteContext, IFavoriteContext } from 'context/FavoriteContext';
import DeleteConfirmationListModal from './DeleteConfirmationListModal/DeleteConfirmationListModal';
import FavoriteListSidebarItem from './FavoriteListSidebarItem/FavoriteListSidebarItem';
import { useSearchParams } from 'react-router-dom';
import { ReactComponent as PlusImage } from 'assets/images/plus.svg';
import { ReactComponent as CheckedSave } from 'assets/images/checked-save.svg';

type Props = {}
const FavoriteSidebar: React.FC<Props> = () => {
  const { getFavoriteItems, list, setList, setSelectedList, setFavoriteItems, setFavoriteItemsLoading, loadingCreateList, setLoadingCreateList, newListActive, setNewListActive, newListValue, setNewListValue } = useContext(FavoriteContext) as IFavoriteContext;

  const [openModal, setOpenModal] = useState<boolean>(false);
  const { t } = useTranslation();
  const http = useHttp();
  const [searchParams, setSearchParams] = useSearchParams();
  const [favoriteListLoading, setFavoriteListLoading] = useState<boolean>(false);
  const [isInvalid, setIsInvalid] = useState<boolean>(false);
  const [editListItem, setEditListItem] = useState<IFavoriteList | null>(null);

  useEffect(() => {
    getFavoriteList();
  }, []);

  useEffect(() => {
    if (newListValue.length) {
      if (list.find(favCat => favCat.name === newListValue)) {
        setIsInvalid(true);
      } else if (isInvalid) {
        setIsInvalid(false);
      }
    }

    if (!newListValue.length && !isInvalid) {
      setIsInvalid(true);
    }

  }, [newListValue]);

  const getFavoriteList = async () => {
    try {

      setFavoriteListLoading(true);
      const response = await http.favorite.getFavoriteLists();

      setList(response);

      if (response.length) {
        const name = response.filter(item => item.id === Number(searchParams.get('category')));

        if (searchParams.get('category') && name.length) {
          setSelectedList({ name: name[0].name, id: Number(searchParams.get('category')) });
          setSearchParams({ category: String(name[0].id) });
        } else {
          setSelectedList(response[0]);
          setSearchParams({ category: String(response[0].id) });

        }

        getFavoriteItems(searchParams.get('category') ? String(searchParams.get('category')) : String(response[0].id));
      } else {
        setFavoriteItemsLoading(false);
      }


    } catch (e) {
      console.log(e);
      notify(t('_error'));

    } finally {
      setFavoriteListLoading(false);
    }
  };

  const createList = async () => {
    if (isInvalid || loadingCreateList) {
      return;
    }

    setLoadingCreateList(true);

    http.favorite.postFavoriteList(newListValue)
      .then((data) => {
        setNewListActive(false);
        setNewListValue('');
        setIsInvalid(false);
        setList([data, ...list]);
      })
      .catch(() => {
        // console.log(e.response.data);
        notify(t('_error'));

      })
      .finally(() => {
        setLoadingCreateList(false);
      });
  };

  const editList = async () => {
    if (isInvalid || loadingCreateList) {
      return;
    }

    if (!editListItem) {
      return;
    }

    setLoadingCreateList(true);

    http.favorite.patchFavoriteList(newListValue, editListItem.id)
      .then(async (data) => {
        setNewListActive(false);
        setNewListValue('');
        setIsInvalid(false);
        const listWithoutItem = await removeItemFromList(data);

        setList([data, ...listWithoutItem]);
        setEditListItem(null);

      })
      .catch(() => {
        // console.log(e.response.data);
        notify(t('_error'));

      })
      .finally(() => {
        setLoadingCreateList(false);
      });
  };

  const handleSave = () => {
    if (editListItem?.id) {
      editList();
    } else {
      createList();
    }
  };

  const handleClose = () => {
    setNewListActive(false);
    setNewListValue('');
    setIsInvalid(false);
    setEditListItem(null);
  };

  const removeItemFromList = async (item: IFavoriteList) => {
    return list.filter(list => list.id !== item.id);
  };

  const onDone = () => {
    setEditListItem(null);
    getFavoriteList();
  };


  return (
    <div className="favorite-sidebar">

      <Modal
        openModal={openModal}
        setOpenModal={setOpenModal}
        childrenComponent={<DeleteConfirmationListModal setFavoriteItems={setFavoriteItems} favoriteList={editListItem} onDone={onDone} setOpenModal={setOpenModal} />}
        contentStyle={contentStyle}
      />
      <div className="new-list-container">
        {
          newListActive ? (
            <div className="new-list-input">
              <div className="input-content">
                <input type="text" className={isInvalid ? 'error' : ''} value={newListValue}
                  onChange={(e) => setNewListValue(e.target.value)}
                />
                <div className="actions-content">
                  <div className="cancel" onClick={handleClose}>
                    <CloseButton />
                  </div>
                  <div className="save" onClick={handleSave}>
                    {
                      loadingCreateList ? (
                        <Spinner class="extra-small"></Spinner>
                      ) : (
                        <CheckedSave />
                      )
                    }
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="new-list-button" onClick={() => setNewListActive(!newListActive)}>
              {t('_new_list')}
              <div className="img-wrapper">
                <PlusImage />
              </div>
            </div>
          )
        }
      </div>
      <div className="list-container">
        {
          favoriteListLoading ?
            (
              <Spinner />
            ) : (
              list.map((item: IFavoriteList) => {
                return (
                  <FavoriteListSidebarItem setEditListItem={setEditListItem} setNewListActive={setNewListActive} setNewListValue={setNewListValue} setOpenModal={setOpenModal} getFavoriteItems={getFavoriteItems} key={item.id} listItem={item} />
                );
              })
            )
        }
      </div>

    </div>

  );
};

FavoriteSidebar.displayName = 'FavoriteSidebar';

export default FavoriteSidebar;