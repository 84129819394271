import React, { CSSProperties } from 'react';
import './SkeletonSpinner.scss';

interface SpinnerProps {
  type?: string;
  style?: CSSProperties;
  class?: string;
}

const SkeletonSpinner: React.FC<SpinnerProps> = (props) => {
  return (
    <div className={`wrapper-skeleton ${props.class ? props.class : ''}`}>
      <div className="skeleton-line" />
    </div>
  );
};

SkeletonSpinner.displayName = 'SkeletonSpinner';

export default SkeletonSpinner;
