import { IEbook } from 'applications/automotor/components/espace/navbar/Navbar.model';
import React from 'react';

import EbookListView from './EbookList.view';

type Props = {
  title: string;
  ebooks: IEbook[];
  className?: string;
  itemClassName?: string;
  onItemClick?: (item: string) => void;
  image?: string
};

const EbookList: React.FC<Props> = (props: Props) => {
  return (
    <EbookListView
      image={props.image}
      itemClassName={props.itemClassName}
      onItemClick={props.onItemClick}
      className={props.className}
      title={props.title}
      ebooks={props.ebooks}
    ></EbookListView>
  );
};

EbookList.displayName = 'EbookList';

export default EbookList;
