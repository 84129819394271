import React, { useState, useContext, useEffect } from 'react';

import { SidebarContext, ISidebarContext } from 'context/SidebarContext';
import useHttp from 'utils/hooks/useHttp';
import { IItemClick } from 'models/catalog.model';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { IBatteryBrands, IConsumableBrands, IConsumableCategories, IOilCategory, IPartSupplier, ITyreCategory, TabCategories } from './Sidebar.model';
import SidebarView from './Sidebar.view';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { IPartsCategory } from 'models/vehicle-catalogs';
import CategoriesSidebarView from './CategoriesSidebar/CategoriesSidebar.view';
import { IGetCarPartDataParams } from 'models/car-parts.model';
import useReliableCatalogCarQueryParams from 'utils/hooks/useReliableCatalogCarQueryParams';
import { ROLE_CLIENT_PLATFORM_ADMIN } from 'utils/constants/roles';
import { IBodyResponseBatteryCategory } from 'interfaces/battery';
import {useTranslation} from 'react-i18next';

type Props = {};

const Sidebar: React.FC<Props> = () => {
  const { sideBarTab, setSidebarTab } = useContext(SidebarContext) as ISidebarContext;
  const http = useHttp();
  const {i18n} = useTranslation();
  const [loading, setLoading] = useState(false);
  const [mounted, setMounted] = useState(false);
  const navigate = useNavigate();
  const [tyresCategories, setTyresCategories] = useState<ITyreCategory>();
  const [oilCategories, setOilCategories] = useState<IOilCategory[]>([]);
  const [consumableBrands, setConsumableBrands] = useState<IConsumableBrands[]>([]);
  const [consumableCategories, setConsumableCategories] = useState<IConsumableCategories[]>([]);
  const [batteryBrands, setBatteryBrands] = useState<IBatteryBrands[]>([]);
  const [batteryCategory, setBatteryCategory] = useState<IBodyResponseBatteryCategory[]>([]);
  const [partsSuppliers, setPartsSuppliers] = useState<IPartSupplier[]>([]);
  const vehicleSelected = localStorage.getItem('carSelected') ? JSON.parse(localStorage.getItem('carSelected') as string) : null;
  const [categories, setCategories] = useState<IPartsCategory[]>([]);
  const [showDetails, setShowDetails] = useState(false);
  const [activeCategory, setActiveCategory] = useState<IPartsCategory>();
  const reliableCatalogCarQueryParams = useReliableCatalogCarQueryParams();
  const { user } = useSelector((state: RootState) => state.userData);
  const isEnabled = user?.roles.includes(ROLE_CLIENT_PLATFORM_ADMIN);

  useEffect(() => {
    setMounted(false);
  }, [i18n.language]);

  useEffect(() => {
    if (!mounted && sideBarTab) {
      if (isEnabled) {
        fetchRequestsPlatformAdmin();
      } else {
        fetchRequests();
      }
    }
  }, [sideBarTab]);

  const fetchRequestsPlatformAdmin = () => {
    setLoading(true);
    let resp;

    if (vehicleSelected != null) {

      let params;

      if (Object.keys(reliableCatalogCarQueryParams).length > 2) {
        params = reliableCatalogCarQueryParams;
      } else {
        params = {
          source: vehicleSelected.car.source,
          vehicle_identifier: vehicleSelected.car.vehicle_identifier,
          ...(vehicleSelected.car_information.vin && { vin: vehicleSelected.car_information.vin }),
        };
      }


      resp = Promise.all([
        http.vehicleCatalogs.getCategories(String(vehicleSelected.car.id), params),
        http.catalogs.getOilCategories(),
        http.catalogs.getTyresCategories(),
        http.catalogs.getConsumableBrands({ per_page: 10000 }),
        http.catalogs.getConsumableCategories({ per_page: 10000 }),
        http.catalogs.getBrandsBattery({ per_page: 10000 }),
        http.catalogs.getCategory({ per_page: 10000 }),
        http.catalogs.getPartsSuppliers({ per_page: 10000 })
      ]);
    } else {
      resp = Promise.all([
        http.catalogs.getOilCategories(),
        http.catalogs.getTyresCategories(),
        http.catalogs.getConsumableBrands({ per_page: 10000 }),
        http.catalogs.getConsumableCategories({ per_page: 10000 }),
        http.catalogs.getBrandsBattery({ per_page: 10000 }),
        http.catalogs.getCategory({ per_page: 10000 }),
        http.catalogs.getPartsSuppliers({ per_page: 10000 })


      ]);

    }

    resp.then((res) => {
      setMounted(true);

      if (vehicleSelected != null) {
        setCategories(res[0]);
        setOilCategories(res[1]);
        setTyresCategories(res[2]);
        setConsumableBrands(res[3].data);
        setConsumableCategories(res[4].data);
        setBatteryBrands(res[5].data);
        setBatteryCategory(res[6].data);
        setPartsSuppliers(res[7].data);
      } else {
        setOilCategories(res[0]);
        setTyresCategories(res[1]);
        setConsumableBrands(res[2].data);
        setConsumableCategories(res[3].data);
        setBatteryBrands(res[4].data);
        setBatteryCategory(res[5].data);
        setPartsSuppliers(res[6].data);

      }
    })
      .finally(() => {
        setLoading(false);
      });


  };

  const fetchRequests = () => {
    setLoading(true);

    if (vehicleSelected != null) {
      let params;

      if (Object.keys(reliableCatalogCarQueryParams).length) {
        params = reliableCatalogCarQueryParams;
      } else {
        params = {
          source: vehicleSelected.car.source,
          vehicle_identifier: vehicleSelected.car.vehicle_identifier,
          ...(vehicleSelected.car_information.vin && { vin: vehicleSelected.car_information.vin }),
        };
      }

      http.vehicleCatalogs.getCategories(String(vehicleSelected.car.id), params).then((res) => {
        setMounted(true);
        setCategories(res);
      }).finally(() => {
        setLoading(false);
      });

    }
  };

  const closeSidebar = () => {
    setSidebarTab(null);
  };

  const onItemClick = (item: IItemClick) => {

    switch (item.type) {
      case 'oil': {
        const searchParams = createSearchParams([['category_ids[]', String(item.id)]]);

        navigate({
          pathname: '/espace-clients/my-eshop/catalog_oil',
          search: `?${searchParams}`,
        });

        break;
      }

      case 'tyre': {
        const searchParams = createSearchParams([[String(item.params), String(item.id)]]);

        navigate({
          pathname: '/espace-clients/my-eshop/catalog_tyres',
          search: `?${searchParams}`,
        });

        break;
      }
      case 'consumables': {

        const searchParams = createSearchParams([[String(item.params), String(item.id)]]);

        navigate({
          pathname: '/espace-clients/my-eshop/catalog_consumable',
          search: `?${searchParams}`,
        });

        break;
      }

      case 'battery': {

        const searchParams = createSearchParams([[String(item.params), String(item.id)]]);

        navigate({
          pathname: '/espace-clients/my-eshop/catalog_battery',
          search: `?${searchParams}`,
        });

        break;
      }

      case 'pieces': {
        setSidebarTab(null);
        setShowDetails(true);
        setActiveCategory(item.item);

        break;
      }
      case 'parts': {
        const searchParams = createSearchParams([[String(item.params), String(item.id)]]);

        navigate({
          pathname: '/espace-clients/my-eshop/catalog_non_tecdoc',
          search: `?${searchParams}`,
        });

        break;
      }
      default: {
        return;
      }
    }

    closeSidebar();
  };

  const onSidebarSubCategoryClick = async (params: Omit<IGetCarPartDataParams, 'catalog_id' | 'page' | 'per_page'>) => {


    let paramsObject: { [key: string]: string } = {};

    if (Object.keys(reliableCatalogCarQueryParams).length) {
      paramsObject = reliableCatalogCarQueryParams;
    } else {
      paramsObject = {
        source: vehicleSelected.car.source,
        vehicle_identifier: vehicleSelected.car.vehicle_identifier,
        ...(vehicleSelected.car.vin && { vin: vehicleSelected.car.vin }),
      };
    }

    paramsObject['code_groupe'] = String(params.code_groupe);
    paramsObject['code_ssgroupe'] = String(params.code_ssgroupe);
    paramsObject['code_repere'] = String(params.code_repere);
    paramsObject['page'] = String(1);

    navigate({
      pathname: `reliable/rmi/${vehicleSelected.car.id.toString()}/catalogs/parts/part-catalog`,
      search: `?${new URLSearchParams(paramsObject).toString()}`,
    });
    setShowDetails(false);
  };

  const onReturnButtonClick = () => {
    setShowDetails(false);
    setSidebarTab(TabCategories.Pieces);
  };

  const onCloseButtonClick = () => {
    setShowDetails(false);

  };

  return (

    <React.Fragment >
      {
        sideBarTab && (
          <SidebarView
            sideBarTab={sideBarTab}
            loading={loading}
            oilCategories={oilCategories}
            tyresCategories={tyresCategories}
            consumableBrands={consumableBrands}
            consumableCategories={consumableCategories}
            categories={categories}
            setSidebarTab={setSidebarTab}
            closeSidebar={closeSidebar}
            onItemClick={onItemClick}
            isEnabled={isEnabled}
            batteryBrands={batteryBrands}
            batteryCategory={batteryCategory}
            partsSuppliers={partsSuppliers}
          />
        )
      }
      {showDetails && <CategoriesSidebarView activeCategory={activeCategory} onCloseButtonClick={onCloseButtonClick} onSidebarSubCategoryClick={onSidebarSubCategoryClick} onReturnButtonClick={onReturnButtonClick} />}
    </React.Fragment>
  );
};

Sidebar.displayName = 'Sidebar';

export default Sidebar;
