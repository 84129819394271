import React, { useEffect, useState } from 'react';

import { IGraphicsLinkedWorkPositions, IVehicleRepairItemWork } from 'utils/rmi/interface/graphics.interface';
import i18n from 'i18n';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { getCurrentCountryRMI, getCurrentLanguageRMI } from 'utils/rmi/rmi-utils/rmi-utils';
import useHttp from 'utils/hooks/useHttp';
import Spinner from 'components/spinner/Spinner';

import { useTranslation } from 'react-i18next';

import TooltipRepairItem from './TooltipRepairItem';

type Props = {

  linkedWorkPositions: IGraphicsLinkedWorkPositions[] | undefined;
};

const RepairItems: React.FC<Props> = (props) => {

  const [loading, setLoading] = useState(false);
  const { typeId } = useSelector((state: RootState) => state.tecrmi);
  const [repairItemsWorks, setRepairItemsWorks] = useState<IVehicleRepairItemWork[][]>([]);
  const http = useHttp();

  const { t } = useTranslation();
  let componentMounted = true;

  useEffect(() => {

    if (props.linkedWorkPositions?.length && componentMounted) {

      setLoading(true);

      const fetchData = async (linkedWorkPositions) => {
        const data = linkedWorkPositions.map(async (childe) => {
          return await http.graphicstHttp.fetchRepairItemsWorks({
            typeId,
            itemMpId: childe.ItemMpId,
            korId: childe.KorId,
            countryCode: getCurrentCountryRMI(i18n.language),
            languageCode: getCurrentLanguageRMI(i18n.language),
          });

        });

        const res: IVehicleRepairItemWork[][] = [];

        await Promise.all(data).then((values) => {

          for (let index = 0; index < values.length; index++) {
            res.push(values[index].data);
          }

        });
        setRepairItemsWorks(res);
      };

      fetchData(props.linkedWorkPositions).then(() => {
        setLoading(false);

        return () => {
          componentMounted = false;
        };
      });


    }
  }, [props.linkedWorkPositions]);


  return (
    <div>
      {
        loading ?

          <Spinner class="extra-small" />
          :
          <ul>
            {
              repairItemsWorks.length ?
                repairItemsWorks.map((value) => {
                  return (
                    value.map((item: IVehicleRepairItemWork, i) => {
                      return <li key={i}>

                        <p>{item.ItemMpText}</p>
                        <p> <img src={`${item.KorImage}`} alt="" /> {item.KorText}</p>
                        <span> {`${item.WorkText} [${item.QualColText}]`}  </span>
                        <TooltipRepairItem item={item} i={i} />

                      </li>;
                    })

                  );
                })
                :
                <div className="no-data">
                  {t('_no_data')}
                </div>
            }
          </ul>
      }
    </div >
  );

};

RepairItems.displayName = 'RepairItems';

export default RepairItems;