import React, { ReactElement, useEffect, useState } from 'react';
import TabTitle from './MaintenanceTabTitle';
import { useLocation } from 'react-router-dom';
import './MaintenanceTab.scss';

type Props = {
  children: ReactElement[];
};

const MaintenanceTabs: React.FC<Props> = (props) => {
  const {pathname} = useLocation();
  const [selectedTab, setSelectedTab] = useState(pathname);

  useEffect(()=> {
    setSelectedTab(pathname);
  }, [pathname]);

  return (
    <div className="maintenance-tabs-container">
      <div className="maintenance-tabs">
        <div className="tabs">
          {props.children.map((item, index) => (
            <TabTitle disabled={item.props.disabled} onClick={item.props.onClick} icon={item.props.icon} key={index} title={item.props.title} index={item.props.activeParam} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
          ))}
        </div>
      </div>
      <div>
        {props.children[selectedTab]}
      </div>
    </div>
  );
};

export default MaintenanceTabs;
