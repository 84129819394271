
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import './ReplacementsModal.scss';

type Props = {
  data?;
  setOpenModal: (e: boolean) => void;
};

const ReplacementsModal: React.FC<Props> = (props) => {

  return (
    <div className="part-oem-info">
      <div className="part-oem-header">
        <div className="part-oem-title">OE</div>
        <div className="close-modal" onClick={() => props.setOpenModal(false)}><FontAwesomeIcon icon={faClose} /></div>
      </div>
      <div className="description">
        {props.data}
      </div>
    </div>
  );
};

export default ReplacementsModal;
