import React, { useState } from 'react';
import './EspaceAutomotorClient.scss';
import ClientSelect from './client-select/ClientSelect';
import WaitingOffers from './waiting-offers/WaitingOffers';
import { usePermission } from 'utils/usePermission';
import { ROLE_COMMERCIAL_DIRECTOR } from 'utils/constants/roles';
import WaitingConfirmation from './waiting-confirmation/WaitingConfirmation';

const EspaceAutomotorClient: React.FC = () => {

  const Commercial = usePermission([ROLE_COMMERCIAL_DIRECTOR]);

  return (
    <div className="espace-automotor-client-container">
      <div className="actions">
        <ClientSelect />
        <WaitingOffers />
        {Commercial ? <WaitingConfirmation /> : null
        }
      </div>
    </div >
  );
};

EspaceAutomotorClient.displayName = 'EspaceAutomotorClient';

export default EspaceAutomotorClient;
