import { faClose, faEdit, faGear, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import SkeletonSpinner from 'components/skeleton-spinner/SkeletonSpinner';
import Spinner from 'components/spinner/Spinner';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { IFilterSuppliers, ISupplier } from 'utils/hooks/http/filter-suppliers.http';
import useHttp from 'utils/hooks/useHttp';
import { notify } from 'utils/marketplace';
import { useDispatch } from 'react-redux';
import { setUser } from 'store/reducers/useSlices';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import './FilterSuppliers.scss';
import { useOnClickOutside } from 'utils/hooks/useOnClickOutside';
import Modal from 'components/modal/Modal';
import FilterSuppliersCategory from './ModalCategory/FilterSuppliersCategory';
import FilterSuppliersCategoryMobile from './ModalCategoryMobile/FilterSuppliersCategoryMobile';
import SwiperSlider from 'components/SwiperSlider/SwiperSlider';
import { SwiperSlide } from 'swiper/react';
import SupplierSettingsModal from './SupplierSettingsModal/SupplierSettingsModal';

type Props = {};

const FilterSuppliers: React.FC<Props> = () => {
  const http = useHttp();
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem('userData')!);

  const [filterSuppliersData, setFilterSuppliersData] = useState<IFilterSuppliers[]>([]);
  const [filterSuppliersDataNew, setFilterSuppliersDataNew] = useState<IFilterSuppliers[]>([]);
  const [allSuppliers, setAllSuppliers] = useState<ISupplier[]>([]);
  const [isOpen, setIsOpen] = useState(false);

  const [loading, setLoading] = useState(false);
  const [dropdownLoading, setDropdownLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [showSupplierDelete, setShowSupplierDelete] = useState(false);
  const [showSupplierEdit, setShowSupplierEdit] = useState(false);
  const [showSupplierSettings, setShowSupplierSettings] = useState(false);
  const [loadingCatalog, setLoadingCatalog] = useState(false);

  const [activeTyre, setActiveTyre] = useState(user?.hasTyre);
  const [activeOil, setActiveOil] = useState(user?.hasOil);
  const [activeConsumable, setActiveConsumable] = useState(user?.hasConsumable);
  const [activeBattery, setActiveBattery] = useState(user?.hasBattery);
  const [activeTecDocCatalog, setActiveTecDocCatalog] = useState(user?.has_nonTecDocCatalog);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openSettingsModal, setOpenSettingsModal] = useState<boolean>(false);
  const [supId, setSupId] = useState('');
  const [catalogId, setCatalogId] = useState('');
  const [supCategory, setSupCategory] = useState<ISupplier>();
  const [checkletter, setCheckLetter] = useState('');
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const { t } = useTranslation();
  const ref = useRef() as React.MutableRefObject<HTMLDivElement>;
  const { isMobile, isTablet } = useDeviceDetect();

  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem('userData')!);

    dispatch(setUser(userInfo));
  }, [activeTyre, activeOil, activeConsumable, activeBattery, activeTecDocCatalog]);

  useOnClickOutside(ref, () => {
    setIsOpen(false);
  });

  function getData() {
    setLoading(true);
    http.filterSuppliers
      .getFilterSuppliersV2()
      .then((res) => {
        setFilterSuppliersData(res.data);

        if (checkletter != '' && checkletter != 'all') {
          setFilterSuppliersDataNew(res.data.filter((sup) => sup.name.toLowerCase().startsWith(checkletter)));
        } else {
          setFilterSuppliersDataNew(res.data);
        }

      })
      .finally(() => {
        setLoading(false);
      });
  }

  function getAllSuppliers() {
    setIsOpen(true);
    setDropdownLoading(true);
    http.filterSuppliers
      .getAllSuppliers()
      .then((res) => {
        setAllSuppliers(res);
      })
      .finally(() => {
        setDropdownLoading(false);
      });
  }

  function addSupplier(id) {
    setLoading(true);
    http.filterSuppliers
      .addSupplier(id)
      .then(() => {
        getData();
      })
      .then(() => {
        setIsOpen(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function deleteSupplier(id) {
    setLoading(true);
    http.filterSuppliers
      .deleteSupplier(id)
      .then(() => {
        getData();
      })
      .then(() => {
        setIsOpen(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function handleCheckSupplier(supplierId) {
    const suppliersCopy = [...filterSuppliersData];
    const supplierIndex = suppliersCopy.findIndex((supplier) => supplier.id === supplierId);

    if (supplierIndex === -1) {
      return;
    }

    const supplier = suppliersCopy[supplierIndex];

    suppliersCopy[supplierIndex] = { ...supplier, status: !supplier.status };
    setFilterSuppliersData(suppliersCopy);

    ///////

    const suppliersCopyNew = [...filterSuppliersDataNew];
    const supplierIndexNew = suppliersCopyNew.findIndex((supplier) => supplier.id === supplierId);

    if (supplierIndexNew === -1) {
      return;
    }

    const supplierNew = suppliersCopyNew[supplierIndexNew];

    suppliersCopyNew[supplierIndexNew] = { ...supplierNew, status: !supplierNew.status };

    setFilterSuppliersDataNew(suppliersCopyNew);
  }

  function handleSubmitClick() {
    setLoadingSubmit(true);
    const data = filterSuppliersData.map(({ id, status }) => ({ dlnr: id, status }));

    http.filterSuppliers
      .submitSuppliers(data)
      .then((res) => {
        notify(res.data[0], 'success');
      })
      .finally(() => {
        setLoadingSubmit(false);
      });
  }

  function handleCheckTyre(e) {
    setLoadingCatalog(true);
    http.filterSuppliers
      .checkCatalogTyre({ status: e.target.checked ? 1 : 0 })
      .then(() => {
        const userInfo = JSON.parse(localStorage.getItem('userData')!);
        const user = { ...userInfo, hasTyre: e.target.checked };

        localStorage.setItem('userData', JSON.stringify(user));
        setActiveTyre(e.target.checked);
        notify('Enabled Catalog Successfully', 'success');
      })
      .finally(() => {
        setLoadingCatalog(false);
      });
  }

  function handleCheckOil(e) {
    setLoadingCatalog(true);
    http.filterSuppliers
      .checkCatalogOil({ status: e.target.checked ? 1 : 0 })
      .then(() => {
        const userInfo = JSON.parse(localStorage.getItem('userData')!);
        const user = { ...userInfo, hasOil: e.target.checked };

        localStorage.setItem('userData', JSON.stringify(user));
        setActiveOil(e.target.checked);
        notify('Enabled Catalog Successfully', 'success');
      })
      .finally(() => {
        setLoadingCatalog(false);
      });
  }

  function handleCheckConsumable(e) {
    setLoadingCatalog(true);
    http.filterSuppliers
      .checkCatalogConsumable({ status: e.target.checked ? 1 : 0 })
      .then(() => {
        const userInfo = JSON.parse(localStorage.getItem('userData')!);
        const user = { ...userInfo, hasConsumable: e.target.checked };

        localStorage.setItem('userData', JSON.stringify(user));
        setActiveConsumable(e.target.checked);
        notify('Enabled Catalog Successfully', 'success');
      })
      .finally(() => {
        setLoadingCatalog(false);
      });
  }

  function handleCheckBattery(e) {
    setLoadingCatalog(true);
    http.filterSuppliers
      .checkCatalogBattery({ status: e.target.checked ? 1 : 0 })
      .then(() => {
        const userInfo = JSON.parse(localStorage.getItem('userData')!);
        const user = { ...userInfo, hasBattery: e.target.checked };

        localStorage.setItem('userData', JSON.stringify(user));
        setActiveBattery(e.target.checked);
        notify('Enabled Catalog Successfully', 'success');
      })
      .finally(() => {
        setLoadingCatalog(false);
      });
  }

  function handleCheckNonTecDocCatalog(e) {
    setLoadingCatalog(true);
    http.filterSuppliers
      .checkNotTecDocCatalog({ status: e.target.checked ? 1 : 0 })
      .then(() => {
        const userInfo = JSON.parse(localStorage.getItem('userData')!);
        const user = { ...userInfo, has_nonTecDocCatalog: e.target.checked };

        localStorage.setItem('userData', JSON.stringify(user));
        setActiveTecDocCatalog(e.target.checked);
        notify('Enabled Catalog Successfully', 'success');
      })
      .finally(() => {
        setLoadingCatalog(false);
      });
  }

  useEffect(() => {
    getData();
  }, []);

  const nonCatalog = [
    {
      id: '1',
      name: '_tyre',
      onChange: handleCheckTyre,
      active: activeTyre,
    },
    {
      id: '2',
      name: '_oil',
      onChange: handleCheckOil,
      active: activeOil,
    },
    {
      id: '3',
      name: '_consumables',
      onChange: handleCheckConsumable,
      active: activeConsumable,
    },
    {
      id: '4',
      name: '_battery',
      onChange: handleCheckBattery,
      active: activeBattery,
    },
  ];

  const nonTecdoc = [
    {
      id: '5',
      name: '_parts',
      onChange: handleCheckNonTecDocCatalog,
      active: activeTecDocCatalog,
    },
  ];

  const handleCheckSupplierCategories = (suplier) => {
    setOpenModal(true);
    setSupCategory(suplier);
  };

  const handleOpenSettings = (supplier) => {
    setOpenSettingsModal(true);
    setSupCategory(supplier);
  };

  const alphabet = 'abcdefghijklmnopqrstuvwxyz'.split('');

  const filterAlphabet = (letter: string) => {
    if (checkletter == letter || letter == 'all') {
      setCheckLetter('all');
      setFilterSuppliersDataNew(filterSuppliersData);
    } else {
      setCheckLetter(letter);
      setFilterSuppliersDataNew(filterSuppliersData.filter((sup) => sup.name.toLowerCase().startsWith(letter)));
    }
  };

  const handleSelectAll = (event) => {
    const isChecked = event.target.checked;

    setSelectAll(isChecked);

    const suppliersCopy = filterSuppliersData.map((supplier) => {
      const sup = { ...supplier };

      sup.status = isChecked;

      return sup;
    });

    setFilterSuppliersData(suppliersCopy);

    /////////

    const suppliersCopyNew = filterSuppliersDataNew.map((supplier) => {
      const supNew = { ...supplier };

      supNew.status = isChecked;

      return supNew;
    });

    setFilterSuppliersDataNew(suppliersCopyNew);
  };

  return (
    <div className={`filter-supplier ${isMobile ? 'mobile' : ''} ${isTablet ? 'tablet' : ''}`}>
      <div className="filter-supplier-title">{t('_access_catalog')}</div>
      <div className="filter-supplier-header">
        <div className="checkbox-container-div">
          <label className="checkbox-container">
            <input type="checkbox" checked={selectAll} onChange={(event) => handleSelectAll(event)} />
            <span className="link">{selectAll ? t('_deselect_all') : t('_select_all')}</span>
          </label>
        </div>

      </div>
      <div className="suppliers-container">
        <div className="suppliers-subtitle">
          <Trans i18nKey="_ereliable_suppliers" components={{ span: <span translate="no" /> }} />
        </div>
        {(isMobile || isTablet) && (
          <SwiperSlider
            containerClass="alphabet-filter-container"
            spaceBetween={0}
            slideToIndex="auto"
            slidesPerView={isTablet ? 15 : 8}
            loop
          >
            <SwiperSlide className="swiper-slide" key="all">
              <div className="filter-alphabet">
                <div className={`alphabet ${checkletter == 'all' || checkletter == '' ? 'check' : ''}`} key="all" onClick={() => filterAlphabet('all')}>
                  {t('_all').toUpperCase()}
                </div>
              </div>
            </SwiperSlide>
            {alphabet.map((item) => {
              return (
                <SwiperSlide className="swiper-slide" key={item}>
                  <div className="filter-alphabet">

                    <div className={`alphabet ${checkletter == item ? 'check' : ''}`} key={item} onClick={() => filterAlphabet(item)}>
                      {item.toUpperCase()}
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </SwiperSlider>
        )}
        <div className={`filter-suppliers-container ${loading ? 'loading' : ''}`}>
          {loading ? (
            <Spinner class="small" />
          ) : (
            <div className="filter-suppliers-body">
              {!(isMobile || isTablet) && (
                <div className="filter-alphabet">
                  <div className={`alphabet ${checkletter == 'all' || checkletter == '' ? 'check' : ''}`} key="all" onClick={() => filterAlphabet('all')}>
                    {t('_all').toUpperCase()}
                  </div>
                  {alphabet.map((item) => {
                    return (
                      <div className={`alphabet ${checkletter == item ? 'check' : ''}`} key={item} onClick={() => filterAlphabet(item)}>
                        {item.toUpperCase()}
                      </div>
                    );
                  })}
                </div>
              )}

              <div className="filter-suppliers-alphabet">
                {filterSuppliersDataNew.length ? (
                  filterSuppliersDataNew.map((supplier) => {
                    return isMobile ? (
                      <div className="supplier" key={supplier.id}>
                        <div>
                          <input type="checkbox" checked={supplier.status} onChange={() => handleCheckSupplier(supplier.id)} />
                          {supplier.name}
                        </div>
                        <div className="supplier-actions">
                          <FontAwesomeIcon
                            className="supplier-action"
                            onClick={() => {
                              handleOpenSettings(supplier);
                            }}
                            icon={faGear}
                          />
                          <FontAwesomeIcon
                            className="supplier-action"
                            onClick={() => {
                              handleCheckSupplierCategories(supplier);
                            }}
                            icon={faEdit}
                          />
                          {/* <FontAwesomeIcon className="supplier-action" onClick={() => deleteSupplier(supplier.id)} icon={faClose} /> */}
                        </div>
                      </div>
                    ) : (
                      <div
                        className="supplier"
                        key={supplier.id}
                        onMouseEnter={() => {
                          setShowSupplierDelete(true);
                          setShowSupplierSettings(true);
                          setShowSupplierEdit(true);
                          setSupId(supplier.id);
                        }}
                        onMouseLeave={() => {
                          setShowSupplierDelete(false);
                          setShowSupplierSettings(false);
                          setShowSupplierEdit(false);
                        }}
                      >
                        <div>
                          <input type="checkbox" checked={supplier.status} onChange={() => handleCheckSupplier(supplier.id)} />
                          {supplier.name}
                        </div>
                        <div className="supplier-actions">
                          {showSupplierSettings && supplier.id === supId && (
                            <FontAwesomeIcon
                              className="supplier-action"
                              onClick={() => {
                                handleOpenSettings(supplier);
                              }}
                              icon={faGear}
                            />
                          )}
                          {showSupplierEdit && supplier.id === supId && (
                            <FontAwesomeIcon
                              className="supplier-action"
                              onClick={() => {
                                handleCheckSupplierCategories(supplier);
                              }}
                              icon={faEdit}
                            />
                          )}
                          {/* {showSupplierDelete && supplier.id === supId && (
                            <FontAwesomeIcon className="supplier-action" onClick={() => deleteSupplier(supplier.id)} icon={faClose} />
                          )} */}
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <React.Fragment>
                    <div />
                    <div className="no-data">{t('_no_suppliers')}</div>
                  </React.Fragment>
                )}
              </div>
            </div>
          )}
        </div>
        <Modal
          openModal={openModal}
          setOpenModal={setOpenModal}
          shouldCloseOnOverlayClick={false}
          childrenComponent={
            isMobile ? (
              <FilterSuppliersCategoryMobile setOpenModal={setOpenModal} supplier={supCategory} getData={getData} />
            ) : (
              <FilterSuppliersCategory setOpenModal={setOpenModal} supplier={supCategory} getData={getData} />
            )
          }
        />
        <Modal
          openModal={openSettingsModal}
          setOpenModal={setOpenSettingsModal}
          shouldCloseOnOverlayClick
          childrenComponent={
            <SupplierSettingsModal setOpenModal={setOpenSettingsModal} supplier={supCategory} />
          }
        />
      </div>
      <div className="suppliers-container">
        <div className="suppliers-subtitle">{t('_non_catalog_suppliers')}</div>
        <div className="filter-suppliers-container catalogs">
          <div className="filter-suppliers">
            {nonCatalog.map((item) => {
              return (
                <div key={item.id} onClick={() => setCatalogId(item.id)} className="supplier catalog">
                  {loadingCatalog && catalogId === item.id ? (
                    <Spinner class="small" />
                  ) : (
                    <div>
                      <input type="checkbox" checked={item.active} onChange={item.onChange} />
                      {t(item.name)}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="suppliers-container">
        <div className="suppliers-subtitle">{t('_non_tec_doc_catalog')}</div>
        <div className="filter-suppliers-container catalogs">
          <div className="filter-suppliers non-tecdoc-catalog">
            {nonTecdoc.map((item) => {
              return (
                <div key={item.id} onClick={() => setCatalogId(item.id)} className="supplier non-tecdoc catalog">
                  {loadingCatalog && catalogId === item.id ? (
                    <Spinner class="small" />
                  ) : (
                    <div>
                      <input type="checkbox" checked={item.active} onChange={item.onChange} />
                      {t(item.name)}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="filter-supplier-submit">
        <DefaultButton isloading={loadingSubmit} text={t('_save')} onClick={handleSubmitClick} />
      </div>
    </div>
  );
};

export default FilterSuppliers;
