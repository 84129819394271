import React, { useState } from 'react';
import './CatalogsTiresPage.scss';
import Tyres from 'pages/CatalogVehicle/TechnicalDocumentation/components/Tyres/Tyres';
import TyreFilter from 'components/filter/TyreFilter/TyreFilter';
import Pagination from 'components/pagination/Pagination';
import TyreCard from 'components/TyreCard/TyreCard';
import { useTranslation } from 'react-i18next';
import { ITyresCategorie, ITyreFilter } from 'utils/rmi/interface/tyres.interface';
import { IBodyResponseTyre } from 'interfaces/tyre';
import { useSearchParams } from 'react-router-dom';
import Spinner from 'components/spinner/Spinner';
import { faCircleArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactComponent as PartsNotFoundImage } from 'assets/images/parts-not-found.svg';
import { DEFAULT_VIEW } from 'components/ProductsViewMode/ProductsViewMode.constants';
import ProductsViewMode from 'components/ProductsViewMode/ProductsViewMode';
import ProductsViewModeChanger from 'components/ProductsViewModeChanger/ProductsViewModeChanger';
import {
  CATALOG_BATTERY,
  CATALOG_TYRE
} from 'components/ProductsViewModeChanger/products-view-mode-changer-utils';
import SortBy from 'components/SortBy/SortBy';

type Props = {
  tyreCategories: ITyresCategorie[];
  onRowClick: (row) => void;
  tyreProducts: IBodyResponseTyre | undefined;
  loading: boolean;
  tyreFilters: ITyreFilter | undefined;
  setLoading: (value) => void;
  onFilterClick: (param) => void;
  handlePageClick: (page) => void;
  onReturnClick: (page) => void;
};

const CatalogsTiresPageView: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const [openFilter, setOpenFilter] = useState(false);
  const [searchParams] = useSearchParams();
  const user = JSON.parse(localStorage.getItem('userData')!);
  const [viewMode, setViewMode] = useState(user?.user_settings.products_view_mode || DEFAULT_VIEW);

  return (
    <div className="catalog-vehicle-tyre-page">
      {props.loading ? (
        <Spinner class="small" />
      ) : !props.tyreProducts && searchParams.get('tab') != 'tyresCatalog' ? (
        <div className="tyre-sizes-container">
          <Tyres tyreCategories={props.tyreCategories} onRowClick={props.onRowClick} />
        </div>
      ) : (
        <div className="tyre-container">
          <div className="body tyre-catalog">
            <div className="tyre-header">
              <div className="title">
                {t('_find_neccessary_parts')}
              </div>
              <div className="filter">
                <div className={`btn-filter ${openFilter ? 'active' : ''}`}>
                  <button onClick={() => setOpenFilter(true)}>{t('_filter_result')}</button>
                </div>
              </div>
            </div>

            <hr className="hr" />
            <span className="return-button" onClick={props.onReturnClick}>
              <FontAwesomeIcon icon={faCircleArrowLeft} className="icon" />
            </span>
            {
              props?.tyreProducts?.data ?
                <div className="filterOptions">
                  <SortBy />
                  <div className="results">
                    {props?.tyreProducts?.total_count} {t('_results')}
                  </div>
                  <ProductsViewMode viewMode={viewMode} setViewMode={setViewMode} />
                </div> : ''
            }
            <div className="wrapper-tyres">
              {props.tyreProducts?.data?.length && (
                <ProductsViewModeChanger catalog={CATALOG_TYRE} viewMode={viewMode} data={props.tyreProducts?.data} />
              )}

            </div>

            {props.tyreProducts?.total_pages && props.tyreProducts?.total_pages > 1 && (
              <Pagination
                pageCount={props.tyreProducts?.total_pages || 0}
                handlePageClick={props.handlePageClick}
                forcePage={searchParams.get('page') ? Number(searchParams.get('page')) : 1}
              />
            )}

          </div>
          <TyreFilter
            openFilter={openFilter}
            setOpenFilter={setOpenFilter}
            filter={props.tyreFilters}
            setLoading={props.setLoading}
            onFilterClick={props.onFilterClick}
          />
        </div>
      )}
      {!props.loading && props.tyreProducts && !props.tyreProducts?.data?.length ? (
        <div className="no-parts-data">
          <div className="notFoundSvg">
            <PartsNotFoundImage />
          </div>
          <h1>{t('_tyres_not_found')}</h1>
        </div>
      ) : null}
    </div>
  );
};

CatalogsTiresPageView.displayName = 'CatalogCarPageView';

export default CatalogsTiresPageView;
