
import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import useAuthCheck from 'utils/hooks/useAuthCheck';
import useHttp from 'utils/hooks/useHttp';
import { useAppDispatch } from 'utils/hooks/redux';
import { login } from 'store/reducers/useSlices';
import { IUser } from 'models/IUser';
import Spinner from 'components/spinner/Spinner';
import DefaultRouteRetailer from './retailerRoute/DefaultRouteRetailer';
import DefaultRouteRetailerMecadepot from './retailerRoute/DefaultRouteRetailerMecadepot';
import useCountNewMessages from 'utils/hooks/useCountNewMessages';

const Retailer: React.FC = () => {
  useAuthCheck();
  useCountNewMessages();
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const http = useHttp();


  useEffect(() => {
    if (searchParams.get('authorization-token')) {
      http.retailerHttp.retailerAutoLogin(searchParams.get('authorization-token')!).then((res) => {
        localStorage.setItem('token', res.data.token);
        localStorage.setItem('userData', JSON.stringify(res.data.user));

        if (localStorage.getItem('userData')) {
          const userData: IUser = JSON.parse(localStorage.getItem('userData') || '');

          dispatch(login(userData));
        }
      }).finally(() => {
        searchParams.delete('authorization-token');
        setSearchParams(searchParams);
      });
    }
  }, []);

  if (searchParams.get('authorization-token') && !localStorage.getItem('token')) {
    return <Spinner />;
  }

  const isRetailerMecadepot = () => {
    const userData = JSON.parse(localStorage.getItem('userData')!);

    if (userData?.numcli === Number(process.env.REACT_APP_MECADEPOT_NUMCLI)) {
      return <DefaultRouteRetailerMecadepot />;
    } else {
      return <DefaultRouteRetailer />;
    }
  };


  return isRetailerMecadepot();
};

Retailer.displayName = 'Retailer';

export default Retailer;
