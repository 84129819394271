
import {createAsyncThunk} from '@reduxjs/toolkit';
import useHttp from 'utils/hooks/useHttp';
import {IAddToCartParams} from '../../models/car-parts.model';
import {notify} from '../../utils/marketplace';
// import {setQuantityToolbar} from '../slices/cart.slice';
// import {RootState} from '../store';


export const addToCartAction = createAsyncThunk('orders', async (params: IAddToCartParams[], { rejectWithValue }) => {
  try {

    const http = useHttp();
    // const dispatch = useDispatch();
    // const {quantityToolbar} = useSelector((state: RootState) => state.cart);


    const { data } = await http.order.addToCart(params);

    // const sum = params.reduce((accumulator, object) => {
    //   return accumulator + object.quantity;
    // }, 0);

    // dispatch(setQuantityToolbar(sum + quantityToolbar));

    notify('SUCCESS', 'success');

    return data;
  } catch (e) {
    console.log(e);
    notify('ERROR');

    return rejectWithValue('add to cart error');
  }
});