import { GRID_VIEW, LIST_VIEW } from '../ProductsViewMode/ProductsViewMode.constants';

export const CATALOG_PART_OE = 'CATALOG_PART_OE';

export const CATALOG_PART_OEM = 'CATALOG_PART_OEM';

export const CATALOG_PART_OEM_V2 = 'CATALOG_PART_OEM_V2';


export const NEW_CATALOG_PART_OEM = 'NEW_CATALOG_PART_OEM';

export const CATALOG_OIL = 'CATALOG_OIL';

export const CATALOG_TYRE = 'CATALOG_TYRE';

export const CATALOG_BATTERY = 'CATALOG_BATTERY';

export const CATALOG_CONSUMABLE = 'CATALOG_CONSUMABLE';

export const CATALOG_CAR_PARTS = 'CATALOG_CAR_PARTS';

export const PRODUCT_VIEW_MODE_CHANGER_CATALOG_TYPES = {
  CATALOG_PART_OEM,
  NEW_CATALOG_PART_OEM,
  CATALOG_PART_OE,
  CATALOG_OIL,
  CATALOG_TYRE,
  CATALOG_BATTERY,
  CATALOG_CONSUMABLE,
  CATALOG_CAR_PARTS,
  CATALOG_PART_OEM_V2
};
