import React from 'react';
import './TechnicalManualsMobile.scss';
import { useTranslation } from 'react-i18next';
import Modal from 'components/modal/Modal';
import ModulesMobileCardLevel4 from 'pages/CatalogVehicle/TechnicalDocumentation/ModulesMobileCardLevel4/ModulesMobileCardLevel4';
import IframeModalMobileCardLevel4 from 'pages/CatalogVehicle/TechnicalDocumentation/ModulesMobileCardLevel4/IframeModal/IframeModalMobileCardLevel4';

type Props = {
  data
  onMainGroupClick?: (item) => void;
  onSubGroupClick?: (item) => void;
  onItemMpClick?: (item) => void;
  onItemMpManualClick?: (item) => void;
  activeItem?: number | string;
  setOpenModal?: (value: boolean) => void
  showModal: boolean;
  loadingHTML: boolean;
  iframeData?: string;
}

const TechnicalManualsMobileView: React.FC<Props> = (props) => {

  const { t } = useTranslation();

  return (
      <div className="tdoc-module">
        <ModulesMobileCardLevel4
          title={t('_technical_manuals')}
          data={props.data}
          onMainGroupClick={props.onMainGroupClick}
          onSubGroupClick={props.onSubGroupClick}
          onItemMpClick={props.onItemMpClick}
          onItemMpManualClick={props.onItemMpManualClick}
          activeItem={props.activeItem}
          setOpenModal={props.setOpenModal}
        />
        <Modal
          openModal={props.showModal}
          setOpenModal={props.setOpenModal}
          childrenComponent={
            <IframeModalMobileCardLevel4
              title={t('_technical_manuals')}
              loading={props.loadingHTML}
              setOpenModal={props.setOpenModal}
              vehicleIframe={props.iframeData!}
            />
          }
        />
      </div>
  );
};

TechnicalManualsMobileView.displayName = 'TechnicalManualsMobileView';

export default TechnicalManualsMobileView;