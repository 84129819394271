import React, { useEffect, useMemo, useState } from 'react';
import Tabs from './Tabs/Tabs';
import Tab from './Tabs/Tab';
import { useTranslation } from 'react-i18next';
import useHttp from 'utils/hooks/useHttp';
import { IIndustrialCarPartsResponse, IIndustrialEquivalencesResponse, IIndustrialParams } from 'utils/hooks/http/industrial.http';
import Head from 'components/tables/Cells/Head/Head';
import { Column } from 'react-table';
import i18n from 'i18n';
import IndustrialEquivalances from './IndustrialEquivalances/IndustrialEquivalances';
import IndustrialCarParts from './IndustrialCarParts/IndustrialCarParts';
import { IPagination } from 'models/IHistory';
import { useSearchParams } from 'react-router-dom';
import './Industrial.scss';

const c = 'industrial';

const Industrial: React.FC = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const http = useHttp();

  const [equivalancesLoading, setEquivalancesLoading] = useState(true);
  const [equivalancesData, setEquivalancesData] = useState<IIndustrialEquivalencesResponse[]>();
  const [equivalancesPaginationData, setEquivalancesPaginationData] = useState<IPagination>();
  const [equivalancesPage, setEquivalancesPage] = useState(1);

  const [carPartsLoading, setCarPartslLoading] = useState(true);
  const [carPartsData, setCarPartsData] = useState<IIndustrialCarPartsResponse[]>();
  const [carPartsPaginationData, setCarPartsPaginationData] = useState<IPagination>();
  const [carPartsPage, setCarPartsPage] = useState(1);

  function getIndustrialCarParts(params?: IIndustrialParams) {
    setCarPartslLoading(true);
    http.industrial
      .industrialCarParts(params)
      .then((res) => {
        setCarPartsData(res.data);
        setCarPartsPaginationData(res);
      })
      .finally(() => setCarPartslLoading(false));
  }

  function getIndustrialEquivalances(params?: IIndustrialParams) {
    setEquivalancesLoading(true);
    http.industrial
      .industrialEquivalences(params)
      .then((res) => {
        setEquivalancesData(res.data);
        setEquivalancesPaginationData(res);
      })
      .finally(() => setEquivalancesLoading(false));
  }

  useEffect(() => {
    getIndustrialCarParts();
    getIndustrialEquivalances();
  }, []);

  const equivalancesColumns = useMemo((): Column<IIndustrialEquivalencesResponse>[] => {
    return [
      {
        Header: <Head text={t('codfou')}></Head>,
        accessor: 'codfou',
      },
      {
        Header: <Head text={t('artnr')}></Head>,
        accessor: 'artnr',
      },
      {
        Header: <Head text={t('hernr')}></Head>,
        accessor: 'hernr',
      },
      {
        Header: <Head text={t('refnr')}></Head>,
        accessor: 'refnr',
      },
    ];
  }, [i18n.language, equivalancesData, searchParams.get('page')]);

  const carPartsColumns = useMemo((): Column<IIndustrialEquivalencesResponse>[] => {
    return [
      {
        Header: <Head text={t('codfou')}></Head>,
        accessor: 'codfou',
      },
      {
        Header: <Head text={t('artnr')}></Head>,
        accessor: 'artnr',
      },
    ];
  }, [i18n.language, carPartsData, searchParams.get('page')]);

  return (
    <div className={c}>
      <Tabs>
        <Tab title={t('_car_parts')}>
          <IndustrialCarParts
            data={carPartsData}
            page={carPartsPage}
            setPage={setCarPartsPage}
            paginationData={carPartsPaginationData}
            loading={carPartsLoading}
            columns={carPartsColumns}
            fetchData={getIndustrialCarParts}
          />
        </Tab>
        <Tab title={t('_equivalances')}>
          <IndustrialEquivalances
            data={equivalancesData}
            page={equivalancesPage}
            setPage={setEquivalancesPage}
            paginationData={equivalancesPaginationData}
            loading={equivalancesLoading}
            columns={equivalancesColumns}
            fetchData={getIndustrialEquivalances}
          />
        </Tab>
      </Tabs>
    </div>
  );
};

export default Industrial;
