import React from 'react';
import '../DefaultCssV2.scss';

const Af: React.FC = () => {
  return (
    <div className="container-wrapper-v2">
      <div className="container">Af</div>
    </div>
  );
};

Af.displayName = 'Af';

export default Af;