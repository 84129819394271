import { faClose, faRedo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import LinkButton from 'components/buttons/LinkButton/LinkButton';
import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { IFilterValue } from 'interfaces/tyre';
import Spinner from 'components/spinner/Spinner';
import './BatteryFilter.scss';
import useHttp from 'utils/hooks/useHttp';
import axios from 'axios';
import { useOnClickOutside } from 'utils/hooks/useOnClickOutside';
import { IBattery, IBatteryFilter, IParamsBattery } from 'interfaces/battery';
import TextInput from 'components/inputs/TextInput/TextInput';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';


type Props = {
  setOpenFilter?: (boolean) => void;
  openFilter?: boolean;
  filter: IBatteryFilter | undefined;
  loadingFilter: boolean;
  setData: Dispatch<SetStateAction<IBattery[]>>;
  setTotalPage: Dispatch<SetStateAction<number>>;
  setLoading: Dispatch<SetStateAction<boolean>>;
  setSelectAll?;
  setSelectedData?;
};

const BatteryFilter: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const { control, getValues, setValue, reset, register } = useForm();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const http = useHttp();
  const cancelToken = axios.CancelToken.source();
  const [brands, setBrands] = useState<IFilterValue[]>([]);
  const [searchFilter, setSearchFilter] = useState<IParamsBattery | {}>({});
  const [category, setCategory] = useState<IFilterValue[]>([]);
  const filterRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const { isMobile, isTablet } = useDeviceDetect();

  // useOnClickOutside(filterRef, (event) => {
  //   if (
  //     props.openFilter &&
  //     (event?.target as HTMLElement).tagName !== 'svg' &&
  //     (event?.target as HTMLElement).tagName !== 'parh' &&
  //     !(event?.target as HTMLElement).className.includes('indicatorContainer')
  //   ) {
  //     handleClick();
  //   }
  // });

  useEffect(() => {
    if (props.filter) {
      setBrands(props.filter.brand);
      setCategory(props.filter.category);

      if (Array.from(searchParams).length) {
        searchParams.forEach((value, key) => {
          if (key == 'brand_ids[]') {
            const activeBrand = searchParams.getAll('brand_ids[]').map((id) => {
              return props.filter?.brand.find((item) => item.value == Number(id));
            });

            setValue('brand', activeBrand);
          }

          if (key == 'category_ids[]') {
            const activeCategory = searchParams.getAll('category_ids[]').map((id) => {
              return props.filter?.category.find((item) => item.value == Number(id));
            });

            setValue('category', activeCategory);
          }

          if (key == 'reference') {
            setValue('reference', searchParams.get('reference'));
          }
        });
      }
    }
  }, [props.filter, searchParams.get('category_ids[]'), searchParams.get('brand_ids[]'), searchParams.get('reference')]);

  function handleClick() {
    const value = getValues();

    const params = {
      brand_ids: (value.brand && value.brand.map((item) => item.value)) || [],
      category_ids: (value.category && value.category.map((item) => item.value)) || [],
      reference: (value.reference && value.reference) || '',
    };

    const validParams = Object.entries(params).reduce((acc, [key, value]) => {
      if (value === undefined || value === '') {
        return acc;
      }

      if (key == 'reference') {
        acc[key] = value;
      } else {
        acc[key + '[]'] = value;
      }

      acc['page'] = String(1);

      return acc;
    }, {});

    const searchParams = createSearchParams(validParams);

    navigate({
      search: `?${searchParams}`,
    });
    props.setLoading(true);

    setSearchFilter(params);
    props.setOpenFilter!(false);
  }

  function handleReset() {
    reset({ brand: '', category: '', reference: '' });
    navigate({ search: '?' });
    props.setOpenFilter!(false);
    props.setLoading(true);

    setSearchFilter({ page: 1 });
  }

  useEffect(() => {
    if (Object.keys(searchFilter).length) {
      http.batteryAdminHttp
        .getBatteryAdmin(searchFilter, cancelToken.token)
        .then((resp) => {
          props.setSelectAll(false);
          props.setSelectedData([]);
          props.setData(resp.data.data);
          props.setTotalPage(resp.data.total_pages);
          props.setLoading(false);
        })
        .catch(() => {
          console.log('cancelToken.token!!');
          props.setSelectAll(false);
          props.setSelectedData([]);
        });

      return () => {
        cancelToken.cancel();
      };
    }
  }, [searchFilter]);

  return (
    <div ref={filterRef} className={`battery-filter ${props.openFilter ? 'active' : ''}`}>
      <div className="header">
        <div className="buttons-container">
          <LinkButton title={t('_reset_filters')} icon={faRedo} onClick={handleReset} />
        </div>
        <div onClick={() => props.setOpenFilter!(false)}>
          <FontAwesomeIcon className="icon" icon={faClose} />
        </div>
      </div>
      <div className="title">
        <Trans i18nKey="_filter_results" components={{ b: <strong /> }} />
      </div>

      {props.loadingFilter ? (
        <Spinner class="small" />
      ) : (
        <div className="filters">
          <div className="categories-filter">
            <div className="dropdown-item">
              <label className="dropdown-label">{t('_reference')}</label>
              <TextInput {...register('reference')} />
            </div>
            <div className="dropdown-item">
              <label className="dropdown-label">{t('_brand')}</label>
              <Controller
                control={control}
                name="brand"
                render={({ field: { onChange, value } }) => (
                  <Select
                    value={value}
                    className="tyre-filter-select-dropdown"
                    onChange={(val) => {
                      onChange(val);
                    }}
                    options={brands}
                    placeholder={`${t('_select')}  ${t('_brand')}`}
                    menuPlacement="auto"
                    isMulti
                    menuPosition="fixed"
                    styles={{ menuPortal: base => ({ ...base, zIndex: 50 }) }}
                    isSearchable={!isMobile && !isTablet}
                  />
                )}
              />
            </div>
            <div className="dropdown-item">
              <label className="dropdown-label">{t('_category')}</label>
              <Controller
                control={control}
                name="category"
                render={({ field: { onChange, value } }) => (
                  <Select
                    value={value}
                    className="tyre-filter-select-dropdown"
                    onChange={(val) => {
                      onChange(val);
                    }}
                    options={category}
                    placeholder={`${t('_select')}  ${t('_category')}`}
                    menuPlacement="auto"
                    isMulti
                    menuPosition="fixed"
                    styles={{ menuPortal: base => ({ ...base, zIndex: 50 }) }}
                    isSearchable={!isMobile && !isTablet}
                  />
                )}
              />
            </div>
          </div>
        </div>
      )}
      <div className="apply-btn">
        <DefaultButton text={t('_apply')} onClick={() => handleClick()} />
      </div>
    </div>
  );
};

BatteryFilter.displayName = 'BatteryFilter';

export default BatteryFilter;
