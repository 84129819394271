import { IPartsCategory } from 'models/vehicle-catalogs';
import { serverAxios, tecRMIAxios } from '../../http';
import { HttpResponse } from 'utils/hooks/http/history.http';
import { IBodiesForGraphicsParam, IBodiesForMaingroupParam, IBodiesForManulParam, IBodiesForSubGroupQualsParam, ICategoryGenArt, IGraphicsLinkedAdjustmentData, IGraphicsLinkedGenArt, IGraphicsLinkedSpareParts, IGraphicsLinkedTechnicalManuals, IGraphicsLinkedWorkPositions, IGraphicsResponce, ISubgroupHtml, ITechnicalManuals, IVehicleRepairFetchWorks, IVehicleRepairItemWork } from '../interface/graphics.interface';


class GraphicstHttp {
  private static ComponentsForType = '/Graphics/BodiesForGraphics';
  private static Manual = 'Graphics/VehicleHtml';
  private static ManualMaingroupHtm = 'Graphics/MaingroupHtml';
  private static SubGroupQuals = 'Graphics/SubGroupQuals';
  private static SubgroupHtml = 'Graphics/SubgroupHtml';
  private static LinkedTechnicalManuals = 'Graphics/LinkedTechnicalManuals';
  private static LinkedAdjustmentData = 'Graphics/LinkedAdjustmentData';
  private static LinkedWorkPositions = 'Graphics/LinkedWorkPositions';
  private static LinkedSpareParts = 'Graphics/LinkedSpareParts';
  private static LinkedGenArt = 'Graphics/LinkedGenArt';
  private static RepairItemsWorks = 'Times/WorkSteps';


  public async fetchRMIVehicleBodiesForGraphics(params: IBodiesForGraphicsParam): Promise<HttpResponse<IGraphicsResponce[]>> {
    return tecRMIAxios.get(GraphicstHttp.ComponentsForType, { params });
  }
  public async fetchRMIVehicleManualGraphics(params: IBodiesForManulParam): Promise<HttpResponse<string>> {
    return tecRMIAxios.get(GraphicstHttp.Manual, { params });
  }

  public async fetchRMIGraphicsManualMaingroupHtml(params: IBodiesForMaingroupParam): Promise<HttpResponse<string>> {
    return tecRMIAxios.get(GraphicstHttp.ManualMaingroupHtm, { params });
  }


  public async fetchRMIGraphicsSubGroupQuals(params: IBodiesForSubGroupQualsParam): Promise<HttpResponse<IGraphicsResponce[]>> {
    return tecRMIAxios.get(GraphicstHttp.SubGroupQuals, { params });
  }
  public async fetchRMIGraphicsSubgroupHtml(params: ISubgroupHtml): Promise<HttpResponse<string>> {
    return tecRMIAxios.get(GraphicstHttp.SubgroupHtml, { params });
  }

  public async fetchRMIGraphicsLinkedTechnicalManuals(params: ITechnicalManuals): Promise<HttpResponse<IGraphicsLinkedTechnicalManuals[]>> {
    return tecRMIAxios.get(GraphicstHttp.LinkedTechnicalManuals, { params });
  }
  public async fetchRMIGraphicsLinkedAdjustmentData(params: ITechnicalManuals): Promise<HttpResponse<IGraphicsLinkedAdjustmentData[]>> {
    return tecRMIAxios.get(GraphicstHttp.LinkedAdjustmentData, { params });
  }

  public async fetchRMIGraphicsLinkedWorkPositions(params: ITechnicalManuals): Promise<HttpResponse<IGraphicsLinkedWorkPositions[]>> {
    return tecRMIAxios.get(GraphicstHttp.LinkedWorkPositions, { params });
  }

  public async fetchRMIGraphicsLinkedSpareParts(params: ITechnicalManuals): Promise<HttpResponse<IGraphicsLinkedSpareParts[]>> {
    return tecRMIAxios.get(GraphicstHttp.LinkedSpareParts, { params });
  }
  public async fetchRMIGraphicsLinkedGenArt(params: ITechnicalManuals): Promise<HttpResponse<IGraphicsLinkedGenArt[]>> {
    return tecRMIAxios.get(GraphicstHttp.LinkedGenArt, { params });
  }

  public async fetchRepairItemsWorks(params: IVehicleRepairFetchWorks): Promise<HttpResponse<IVehicleRepairItemWork>> {
    return tecRMIAxios.get(GraphicstHttp.RepairItemsWorks, { params });
  }

  public async fetchRMIGraphicsCategoryGenArt(vehicleId: number, params: ICategoryGenArt): Promise<HttpResponse<IPartsCategory[]>> {
    return serverAxios.get(`/reliable/${vehicleId}/categories/genartnrV2`, { params });
  }


}


export default GraphicstHttp;