import { createAsyncThunk } from '@reduxjs/toolkit';
import useHttp from 'utils/hooks/useHttp';

export const fetchEbooksData = createAsyncThunk('ebooks/fetchEbooks', async (catalog_id: string, { rejectWithValue }) => {
  try {
    const http = useHttp();
    const response = await http.ebooks.getEbooks(catalog_id);

    return response;
  } catch (e) {
    return rejectWithValue('fetch catalogs error');
  }
});
