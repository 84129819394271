import { CancelToken } from 'axios';
import { IBattery, IBatteryCatalogFilter, IBatterySearch, IBodyResponseBattery } from 'interfaces/battery';
import { addRouteParam, IHttpResponse, serverAxios } from '../../http';

class BatteriesCatalogHttp {
  private static ROUTE = 'battery/products';
  private static VIEW_ROUTE = 'battery/products/:id';
  private static FILTER_ROUTE = 'battery/filters';
  private static SEARCH_ROUTE = '/battery/search';

  public async getBatteries(params: IBatteryCatalogParams, cancelToken?: CancelToken): Promise<IHttpResponse<IBodyResponseBattery>> {
    return serverAxios.get(BatteriesCatalogHttp.ROUTE, { params, cancelToken });
  }
  public async getBattery(id: string): Promise<IHttpResponse<IBattery>> {
    return serverAxios.get(addRouteParam(BatteriesCatalogHttp.VIEW_ROUTE, { id }));
  }
  public async batterySearch(params: { q: string }): Promise<IHttpResponse<IBatterySearch[]>> {
    return serverAxios.get(BatteriesCatalogHttp.SEARCH_ROUTE, { params });
  }
  public async getBatteryFilters(params: IBatteryCatalogParams): Promise<IHttpResponse<IBatteryCatalogFilter>> {
    return serverAxios.get(BatteriesCatalogHttp.FILTER_ROUTE, { params });
  }
}

export interface IBatteryCatalogParams {
  vin?: string;
  source?: string;
  vehicle_identifier?: string;
  vehicle_id?: string;
  page?: number;
}

export default BatteriesCatalogHttp;
