import {usePermission} from 'utils/usePermission';
import {getCurrentMarketplace} from '../marketplace';
import {MARKETPLACE_AMERIGO} from '../constants/defaultConstants';


const useTeccomButtonDisponibility = (): boolean => {
  const marketplace = getCurrentMarketplace();
  const isAmerigo = marketplace === MARKETPLACE_AMERIGO;
  const hasRole =  usePermission(['ROLE_CLIENT_AMERIGO', 'ROLE_SUPPLIER_AMERIGO']);

  return isAmerigo && hasRole;

};

export default useTeccomButtonDisponibility;