import { IItemClick } from 'models/catalog.model';
import React from 'react';
import ListSupplierView from './ListSupplier.view';


type Props = {
  title?;
  content;
  images?: string[];
  className?: string;
  itemClassName?: string;
  onItemClick?: (item: IItemClick) => void;
  showPdf?: boolean;
  activeCategory?
  itemId?: string;
  handleOnChangeCategory: (e: React.ChangeEvent<HTMLInputElement>, activeCategoryCheck) => void;
  register?;
};

const ListSupplier: React.FC<Props> = (props: Props) => {
  return (
    <ListSupplierView
      itemClassName={props.itemClassName}
      onItemClick={props.onItemClick}
      className={props.className}
      title={props.title}
      images={props.images}
      content={props.content}
      showPdf={props.showPdf}
      activeCategory={props.activeCategory}
      itemId={props.itemId}
      handleOnChangeCategory={props.handleOnChangeCategory}
      register={props.register}
    ></ListSupplierView>
  );
};

ListSupplier.displayName = 'ListSupplier';

export default ListSupplier;
