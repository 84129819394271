import React from 'react';
import QuantityInput from 'components/inputs/QuantityInput/QuantityInput';
import CartAvailability from '../../../CartAvailability/CartAvailability';
import './QuantityAvailability.scss';
import Spinner from 'components/spinner/Spinner';

type Props = {
  availability: number;
  quantity: number;
  setQuantity: (quantity: number) => void;
  onAdd?: () => void;
  onSubstract?: () => void;
  quantityLoading: boolean;
  availabilityUnspecified?: boolean;
};

const QuantityAvailabilityView: React.FC<Props> = (props: Props) => {
  return <div className="quantity-availability">

    {props.quantityLoading ? (
      <Spinner class="extra-small" />
    ) : (
      <QuantityInput value={props.quantity} setValue={props.setQuantity} onAdd={props.onAdd} onSubstract={props.onSubstract}></QuantityInput>
    )
    }
    {
      props.availabilityUnspecified ? (
        <span>-</span>
      ) : (
        <CartAvailability value={props.quantity} limit={props.availability}></CartAvailability>
      )
    }
  </div>;
};

QuantityAvailabilityView.displayName = 'NumberCellView';

export default QuantityAvailabilityView;
