import React from 'react';

import { IGraphicsLinkedAdjustmentData } from 'utils/rmi/interface/graphics.interface';


import Spinner from 'components/spinner/Spinner';
import { NavLink, useParams } from 'react-router-dom';
import { RELIABLE_TAB_NAMES } from 'utils/rmi/reliableTabs';
import { useTranslation } from 'react-i18next';
import useReliableCatalogCarQueryParams from 'utils/hooks/useReliableCatalogCarQueryParams';


type Props = {
  
  linkedAdjustment: IGraphicsLinkedAdjustmentData[] | undefined;
  setOpenModalGrafics : (e:boolean)=> void
};

const Adjust: React.FC<Props> = (props) => {
  const { vehicle_id } = useParams();
  const { t } = useTranslation();
  const reliableCatalogCarQueryParams = useReliableCatalogCarQueryParams();

  const manualLink = (ItemMpId: number) => {
    let paramsObject: { [key: string]: string } = {};

    paramsObject = {...paramsObject, ...reliableCatalogCarQueryParams};
    paramsObject['item_id'] = String(ItemMpId);
    const link  =  `/reliable/rmi/${vehicle_id}/technical-documentation/${RELIABLE_TAB_NAMES.ADJUST}?${new URLSearchParams(paramsObject).toString()}`;

    return link;
  };

  return (
    
    <div>
      {
        !props.linkedAdjustment ? <Spinner class="extra-small"/> : 

          props.linkedAdjustment.length ?      <ul>
            {
              props.linkedAdjustment.map((item ,index) => {
                return   <div key={index}>
                  <li key={index} >
                    <NavLink onClick={() => { props.setOpenModalGrafics(false); }} to={manualLink(item.ItemMpId)}>
                      {`${item.ItemMpText}`}
                    </NavLink>
                  </li>
                </div>;
              })
  
            }
          </ul> 
            : 
            <div className="no-data"> 
              {t('_no_data')}
            </div>
    
      }

    </div>
 
  );

};

Adjust.displayName = 'Adjust';

export default Adjust;