import React from 'react';
import { Outlet, } from 'react-router-dom';
import CatalogVehicleHeader from 'components/CatalogVehicle/CatalogVehicleHeader/CatalogVehicleHeader';
import './CatalogVehicleLayout.scss';
import useSetStateVehicle from 'utils/hooks/useSetStateVehicle';
import useTecrmiTypeId from 'utils/hooks/useTecrmiTypeId';
import _ from 'underscore';
import useFetchPartsCategories from '../../../../utils/hooks/parts-categories/use-fetch-parts-categories';
import useFetchItemIdsByCategory from '../../../../utils/hooks/parts-categories/use-fetch-item-ids-by-category';
import useFetchRmiVehicleManualsList from '../../../../utils/hooks/parts-categories/use-fetch-rmi-vehicle-manuals-list';
import useSelectFirstActiveCategory from '../../../../utils/hooks/parts-categories/use-set-first-active-category';

const CatalogVehicleLayout: React.FC = () => {

  useTecrmiTypeId();
  useSetStateVehicle();
  useFetchPartsCategories();
  useSelectFirstActiveCategory();
  useFetchItemIdsByCategory();
  useFetchRmiVehicleManualsList();

  return (
    <div className="catalog-vehicle-layout container-fluid-large">
      <div className="catalog-vehicle-header-wrapper">
        <CatalogVehicleHeader />
      </div>
      <div className="catalog-vehicle-content-wrapper">
        <Outlet />
      </div>
    </div>
  );
};

export default CatalogVehicleLayout;
