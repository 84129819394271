import { HttpResponse } from 'utils/hooks/http/history.http';
import { tecRMIAxios } from '../../http';
import { IAdjustListMainGroup } from '../interface/adjust.interface';

class ServiceNewsletterHttp {

  private static ManualList = '/Bulletins/ManualList';
  private static ManualHtml = '/Bulletins/ManualHtml';


  public async fetchRMIVehicleManualsList(params: {}): Promise<HttpResponse<IAdjustListMainGroup[]>> {
    return tecRMIAxios.get(ServiceNewsletterHttp.ManualList, { params });
  }
  public async fetchRMIVehicleManualHtml(params: {}): Promise<HttpResponse<string>> {
    return tecRMIAxios.get(ServiceNewsletterHttp.ManualHtml, { params });
  }

}

export default ServiceNewsletterHttp;
