import React, { createContext, useState } from 'react';
import { CartTableItem } from '../applications/automotor/views/Cart/CartTable/CartTable.model';

type CartTableProps = {
  children: React.ReactNode;
};

export type ICartTableContext = {
  selectAll: boolean;
  setSelectAll: (value: boolean) => void;
  selectedData: CartTableItem[] | [];
  setSelectedData: (data: CartTableItem[] | []) => void;


};

export const CartTableContext = createContext<ICartTableContext | null>(null);

export const CartTableContextProvider: React.FC<CartTableProps> = (props) => {
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [selectedData, setSelectedData] = useState<CartTableItem[]>([]);

  return <CartTableContext.Provider value={{ selectAll, setSelectAll, selectedData, setSelectedData }}>{props.children}</CartTableContext.Provider>;
};
