import React from 'react';
import './ErrorCodeItem.scss';
import {IActiaReportData} from '../../../../../components/DiagnosticItems/Actia/actia';

type Props = {
    name: string;
    items: IActiaReportData[]
    onClick : (code: string) => void;
    classType: () => string;
    selectedCode: string;

}

const ErrorCodeItemView: React.FC<Props> = (props) => {

  return (
    <div className={`diagnostic-error-code-item ${props.classType()}`}>
      <div className="name">
        <p>{props.name.toUpperCase()}</p>
      </div>
      <div className="error-codes">
        {
          props.items.map((item, index) => {
            return (
              <span className={`${props.selectedCode === item.code ? 'selected' : ''}`} key={index} onClick={() => props.onClick(item.code)}>{item.code}</span>
            );
          })
        }
      </div>
    </div>
  );
};

ErrorCodeItemView.displayName = 'ErrorCodeItemView';

export default ErrorCodeItemView;
