import React from 'react';
import {useTranslation} from 'react-i18next';
import './MessagesSupplierInfoItem.scss';
import {IRetailerInfo} from 'interfaces/messaging';

type Props = {
    data: IRetailerInfo | null;
    title: string;
}

const MessageSupplierInfoItem: React.FC<Props> = (props) => {
  const {t} = useTranslation();

  return (
    <div className="message-supplier-info-item">
      <p className="title">{props.title}</p>
      {props.data !== null ? (
        Object.keys(props.data).map((key, index) => {
          return (
            <div className="item" key={index}>
              <span className="label">{t(`_${key}`)} :</span>
              <span className="value">{props.data ? props.data[key] : '-'}</span>
            </div>
          );
        })
      ) : (
        <p>{t('_no_data')}</p>
      )}
    </div>
  );
};

MessageSupplierInfoItem.displayName = 'MessageSupplierInfoItem';

export default MessageSupplierInfoItem;
