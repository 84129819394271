import { faArrowAltCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import i18n from 'i18n';
import { ICarPartData } from 'models/car-parts.model';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createSearchParams, generatePath, useNavigate, useSearchParams } from 'react-router-dom';
import useHttp from 'utils/hooks/useHttp';
import CarPartsSlider from './car-part-slider/CarPartsSlider';
import CarPartsStatic from './car-part-static/CarPartsStatic';
import './PresentationCarParts.scss';

type Props = {}

const PresentationCarParts:React.FC<Props> = () => {
  const http = useHttp();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const {t} = useTranslation();
  const [data, setData] = useState<ICarPartData[]>();
  const [loading, setLoading] = useState(false);
  const [mainGroup, setMainGroup] = useState('');
  const [subGroup, setSubGroup] = useState('');
  const [subSubGroup, setSubSubGroup] = useState('');

  function getData(){
    setLoading(true);
    http.presentation.getCarParts({
      catalog_id: String(searchParams.get('catalog_id')),
      code_groupe: Number(searchParams.get('code_groupe')),
      code_ssgroupe: Number(searchParams.get('code_ssgroupe')),
      code_repere: Number(searchParams.get('code_repere'))
    }).then(res => setData(res.data)).finally(()=> {setLoading(false);});
  }

  function getBreadcrumbData(){

    http.presentation.getBreadCrumb({
      catalog_id: String(searchParams.get('catalog_id')),
      code_groupe: Number(searchParams.get('code_groupe')),
      code_ssgroupe: Number(searchParams.get('code_ssgroupe')),
      code_repere: Number(searchParams.get('code_repere'))
    }).then(res => {
      setMainGroup(res.data.name + ' /');
      setSubGroup(res.data.subcategories.name.replace(':', '') + ' /');
      setSubSubGroup(res.data.subcategories.subcategories.name);
    });
  }

  function goBack(){
    navigate(-1);
  }

  function handleClick(item){
    navigate({
      pathname: generatePath('/public/car-parts/view/:id', {
        id: item.data_supplier_reference
      }),
      search: createSearchParams({
        code_groupe: String(searchParams.get('code_groupe')),
        code_ssgroupe: String(searchParams.get('code_ssgroupe')),
        code_repere: String(searchParams.get('code_repere')),
        provider_id: item.cod_fou,
        catalog_id: String(searchParams.get('catalog_id')),
        data_supplier_id: item.data_supplier.id
      }).toString()
    });
  }

  useEffect(()=>{
    getData();
    getBreadcrumbData();
  },[i18n.language]);

  return (
    <div className="presentation-car-parts">
      <div className="car-parts-header">
        <div className="car-parts-return" onClick={goBack}><FontAwesomeIcon icon={faArrowAltCircleLeft}/> {t('_return')}</div>
        <div className="car-parts-breadcrumb"><span className="main-group">{mainGroup}</span> <span className="sub-group">{subGroup}</span> <span className="sub-sub-group">{subSubGroup}</span></div>
      </div>
      {data && data.length > 5 ?
        <CarPartsSlider data={data} isLoading={loading} handleClick={handleClick} slidesPerView={5} /> : 
        <CarPartsStatic data={data} isLoading={loading} handleClick={handleClick} />
      }
    </div>
  );
};

export default PresentationCarParts;