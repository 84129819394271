import React, { useState } from 'react';
import { CellProps } from 'react-table';
import { useAppDispatch } from 'utils/hooks/redux';
import useHttp from 'utils/hooks/useHttp';
import { CartTableItem } from '../../CartTable.model';
import QuantityAvailabilityView from './QuantityAvailability.view';
import { setQuantityToolbar } from 'store/slices/cart.slice';

type Props = CellProps<CartTableItem> & {
  refetch: () => Promise<void>;
  setLoading: (state: boolean) => void;
  availabilityUnspecified?: boolean;
};

const QuantityAvailability: React.FC<Props> = (props: Props) => {
  const http = useHttp();
  const [quantity, setQuantity] = useState<number>(props.value.quantity);
  const dispatch = useAppDispatch();

  const handleAddClick = async () => {
    props.setLoading(true);
    await http.order.update(props.row.original.id, { quantity: quantity + 1 }).then((res) => {
      dispatch(setQuantityToolbar(res.data.total_quantity));
    });
    await props.refetch();
    props.setLoading(false);
  };

  const handleSubstractClick = async () => {
    props.setLoading(true);
    await http.order.update(props.row.original.id, { quantity: quantity - 1 }).then((res) => {
      dispatch(setQuantityToolbar(res.data.total_quantity));
    });
    await props.refetch();
    props.setLoading(false);
  };

  return (
    <QuantityAvailabilityView
      availabilityUnspecified={props.availabilityUnspecified}
      availability={props.value.availability}
      quantity={quantity}
      setQuantity={setQuantity}
      onAdd={handleAddClick}
      onSubstract={handleSubstractClick}
    ></QuantityAvailabilityView>
  );
};

QuantityAvailability.displayName = 'QuantityAvailability';

export default QuantityAvailability;
