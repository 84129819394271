import React, { useEffect, useState } from 'react';
import { ReactComponent as PartsNotFoundImage } from 'assets/images/parts-not-found.svg';
import Pagination from 'components/pagination/Pagination';
import Spinner from 'components/spinner/Spinner';
import { useTranslation } from 'react-i18next';
import useHttp from 'utils/hooks/useHttp';
import { useSearchParams } from 'react-router-dom';
import './Consumables.scss';
import TextInput from 'components/inputs/TextInput/TextInput';
import ConsumableDropdownContent from 'components/ConsumableDropdownContent/ConsumableDropdownContent';
import { useForm } from 'react-hook-form';
import ConsumableNavbar from 'components/navbar/consumable-navbar/ConsumableNavbar';
import { IBodyResponseConsumable, IConsumableCatalogFilter, IConsumableSearch } from '../../../../interfaces/consumable';

import CatalogConsumableFilter
  from 'pages/CatalogVehicle/Catalogs/CatalogsConsumablesPage/CatalogConsumableFilter/CatalogConsumableFilter';
import ProductsViewMode from '../../../../components/ProductsViewMode/ProductsViewMode';
import { DEFAULT_VIEW } from '../../../../components/ProductsViewMode/ProductsViewMode.constants';
import ProductsViewModeChanger from 'components/ProductsViewModeChanger/ProductsViewModeChanger';
import { CATALOG_CONSUMABLE } from '../../../../components/ProductsViewModeChanger/products-view-mode-changer-utils';
import axios from 'axios';

const Consumables: React.FC = () => {
  const [searchParams] = useSearchParams();
  const { register, getValues, setValue } = useForm();
  const { t, i18n } = useTranslation();
  const http = useHttp();


  const [loading, setLoading] = useState(true);
  const [refData, setRefData] = useState<IBodyResponseConsumable>();
  const [filterData, setFilterData] = useState<IConsumableCatalogFilter>();
  const [openFilter, setOpenFilter] = useState(false);

  const [openConsumableDropdown, setOpenConsumableDropdown] = useState(false);
  const [consumableData, setConsumableData] = useState<IConsumableSearch[]>([]);
  const [loadingConsumable, setLoadingConsumable] = useState(false);

  const [page, setPage] = useState(Number(searchParams.get('page')));
  const user = JSON.parse(localStorage.getItem('userData')!);
  const [viewMode, setViewMode] = useState(user?.user_settings.products_view_mode || DEFAULT_VIEW);
  const cancelToken = axios.CancelToken.source();


  function getConsumableData(data) {
    setLoading(true);
    const params = {
      page,
      ...data,
    };

    const validParams = validateParams(params);

    http.consumableCatalogHttp
      .getConsumables(validParams, cancelToken.token)
      .then((res) => {
        setRefData(res.data);
      })
      .finally(() => setLoading(false));
  }

  function getConsumableFilter(data) {

    const validFilterParams = validateParams(data);

    http.consumableCatalogHttp.getConsumableFilters(validFilterParams).then((res) => {
      setFilterData(res.data);
    });
  }

  const validateParams = (data) => {
    return Object.entries(data).reduce((acc, [key, value]) => {
      if (!value) {
        return acc;
      }

      acc[key] = value;

      return acc;
    }, {});
  };


  const handlePageClick = (pageN: number) => {
    setPage(pageN);
  };

  function consumableSearch() {
    setLoadingConsumable(true);
    http.consumableHttp.consumableSearch({ q: getValues()?.consumable }).then((res) => {
      setConsumableData(res.data);
    }).finally(() => setLoadingConsumable(false));
  }

  useEffect(() => {
    const params = {
      q: searchParams.get('q'),
      category_ids: searchParams.getAll('category_ids'),
      brand_ids: searchParams.getAll('brand_id'),
      viscosity: searchParams.getAll('viscosity'),
      quantity: searchParams.getAll('quantity'),
      family: searchParams.getAll('family'),
      consumable_id: searchParams.get('consumable_id')
    };

    getConsumableData(params);
    getConsumableFilter({});
    setValue('consumable', searchParams.get('q'));
  }, [i18n.language, page, searchParams.get('category_ids'), searchParams.get('brand_id'), searchParams.get('q'), searchParams.get('consumable_id')]);

  function handleSearch() {
    setOpenConsumableDropdown(true);
    consumableSearch();
  }

  function filterOptionsElement() {
    return (
      <div className="catalog-parts-filter">
        <button onClick={() => setOpenFilter(true)} className={`catalog-parts-button ${openFilter ? 'active' : ''}`}>
          {t('_filter_results_btn')}
        </button>
      </div>
    );
  }

  function filterInputElement() {
    return (
      <div className="catalog-parts-filter">
        <div className="consumable-search-input-container">
          <TextInput
            placeholder="Ex: 1003001F"
            {...register('consumable')}
            onClick={handleSearch}
            withIcon
          />
          {openConsumableDropdown && <ConsumableDropdownContent loading={loadingConsumable} setIsOpen={setOpenConsumableDropdown} data={consumableData} consumableValue={getValues()?.consumable} />}
        </div>
      </div>
    );
  }

  return (
    <div className="consumables-wrapper">
      <ConsumableNavbar option={filterOptionsElement()} consumableInput={filterInputElement()} title="_consumable_catalog" />
      <div className="separator"></div>
      {
        !loading && (
          <div className="filterOptions">
            <div className="results">
              {refData?.total_count} {t('_results')}
            </div>
            <ProductsViewMode viewMode={viewMode} setViewMode={setViewMode} />

          </div>
        )
      }
      <div className="wrapperItems">
        {loading ? (
          <Spinner class="car-parts-spinner" />
        ) : refData?.data?.length ? (
          <ProductsViewModeChanger catalog={CATALOG_CONSUMABLE} viewMode={viewMode} data={refData?.data} />
        ) : (
          <div className="no-parts-data">
            <div className="notFoundSvg">
              <PartsNotFoundImage />
            </div>
            <h1>{t('_consumables_not_found')}</h1>
          </div>
        )}
      </div>
      {refData && refData?.total_pages && refData?.page && refData?.total_pages > 1 ? (
        <Pagination pageCount={refData.total_pages} handlePageClick={handlePageClick} forcePage={refData.page} />
      ) : (
        ''
      )}
      <CatalogConsumableFilter getData={getConsumableData} filterData={filterData} openFilter={openFilter} setOpenFilter={setOpenFilter} />
    </div>
  );
};

export default Consumables;
