
import { CancelToken } from 'axios';
import { IBodyResponseCategory, ICategory, IParamsCategory } from 'interfaces/batteryCategory';

import { IHttpResponse, serverAxios } from '../../http';

class BatteryCategoryHttp {

  private static list = '/battery/category/list';
  private static info = '/battery/category/info/';
  private static update = '/battery/category/update/';
  private static create = '/battery/category/register';
  private static delete = '/battery/category/remove/';

  public getList(urlParams: IParamsCategory, cancelToken: CancelToken): Promise<IHttpResponse<IBodyResponseCategory>> {
    return serverAxios.get(BatteryCategoryHttp.list, { params: urlParams, cancelToken: cancelToken });
  }


  public getCategoryInfo(id: number | undefined): Promise<IHttpResponse<ICategory>> {
    return serverAxios.get(BatteryCategoryHttp.info + id);
  }

  public edit(id: number, data: FormData): Promise<IHttpResponse<ICategory>> {
    return serverAxios.post(BatteryCategoryHttp.update + id, data);
  }

  public create(data: FormData): Promise<IHttpResponse<ICategory>> {
    return serverAxios.post(BatteryCategoryHttp.create, data);
  }

  public delete(id: number): Promise<IHttpResponse<null>> {
    return serverAxios.delete(BatteryCategoryHttp.delete + id);
  }
}

export default BatteryCategoryHttp;
