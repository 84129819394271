import React, { useMemo } from 'react';
import { Column, useTable } from 'react-table';

import { useNavigate } from 'react-router-dom';
import { IData } from 'models/order';
import Spinner from 'components/spinner/Spinner';
import './OrderTable.scss';
import Head from 'components/tables/Cells/Head/Head';
import { useTranslation } from 'react-i18next';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import i18n from 'i18n';
import Pagination from 'components/pagination/Pagination';


type Props = {
  data: IData[]
  loading: boolean
  handlePageClick?: (data) => void
  totalPage?: number
  currentPage?: number
  hiddenColumns?
  lastItemRef?
};


const OrderTable: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isMobile, isTablet, isDesktop } = useDeviceDetect();

  const columns = useMemo(
    (): Column<IData>[] => [
      {
        Header: <Head text={t('_id').toLocaleUpperCase()}></Head>,
        accessor: 'id',
      },
      {
        Header: <Head text={t('_price').toLocaleUpperCase()}></Head>,
        accessor: 'total_price',
        Cell: (props) => { return (props.row.values.total_price ? props.row.values.total_price.toFixed(2) : 0); },
      },
      // {
      //   Header: <Head text="INTERNAL CMD"></Head>,
      //   accessor: 'ebay_order_id',
      // },
      {
        Header: <Head text={t('_name').toLocaleUpperCase()}></Head>,
        accessor: 'user_full_name',
      },
      {
        Header: <Head text={t('_email').toLocaleUpperCase()}></Head>,
        accessor: 'user_email',
      },
      {
        Header: <Head text={t('_proforma_id').toLocaleUpperCase()}></Head>,
        accessor: 'proforma_id',
        Cell: (props) => <span>{props.row.original.m3_order_id ? props.row.original.m3_order_id : props.row.original.proforma_id}</span>
      },
      {
        Header: <Head text={t('_date').toLocaleUpperCase()}></Head>,
        accessor: 'time',
      },
      {
        Header: <Head text={t('_status').toLocaleUpperCase()}></Head>,
        accessor: 'status',
        Cell: (props) => <span>{t(props.row.original?.status)}</span>
        // Cell: t('_sent')
      },
    ],
    [i18n.language, isMobile, isTablet, isDesktop],
  );


  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data: props.data,
    initialState: props.hiddenColumns
  });


  return (
    <div className="orderTable">
      <div className="orderTable-container">
        {props.loading ? <Spinner class="sellers-table-spinner" /> :
          props.data.length ? (
            <table {...getTableProps()}>

              <thead className="headings">
                {headerGroups.map((headerGroup, index) => {
                  return (
                    <tr key={headerGroup.headers[index].id} className="row">
                      {headerGroup.headers.map((column) => {
                        const { key, ...restColumn } = column.getHeaderProps();

                        return (
                          <th key={key} {...restColumn} className="heading">
                            {column.render('Header')}
                          </th>
                        );
                      })}
                    </tr>
                  );
                })}
              </thead>
              <tbody {...getTableBodyProps} className="body">
                {rows.map((row, index) => {
                  prepareRow(row);
                  const { key, ...restRowProps } = row.getRowProps();

                  if (index === rows.length - 1) {
                    return (
                      <tr ref={props.lastItemRef} key={key} {...restRowProps} onClick={() => navigate(`/automotor-online/order/details/${row.original.id}`)} className="body-cell-row">
                        {row.cells.map((cell) => {
                          const { key, ...restCellProps } = cell.getCellProps();

                          return (
                            <td key={key} {...restCellProps} className="cell">
                              {cell.render('Cell')}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  }

                  return (
                    <tr key={key} {...restRowProps} onClick={() => navigate(`/automotor-online/order/details/${row.original.id}`)} className="body-cell-row">
                      {row.cells.map((cell) => {
                        const { key, ...restCellProps } = cell.getCellProps();

                        return (
                          <td key={key} {...restCellProps} className="cell">
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) :
            <div className="order-no-data">{t('_no_data')}</div>
        }
        {/* {props.loading && <Spinner class={`extra-small order-table-small-spinner ${props.data.length ? 'has-data' : ''} `} />} */}
      </div>

      <div>
        {props.totalPage! > 1 && (
          <Pagination pageCount={props.totalPage!} handlePageClick={props.handlePageClick!} forcePage={props.currentPage!} />
        )}
      </div>
    </div>

  );
};

OrderTable.defaultProps = {
  currentPage: 1
};


OrderTable.displayName = 'SellersTable';

export default OrderTable;
