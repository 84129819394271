import React, { useEffect, useState } from 'react';
import EspaceReliableNavbarBelowView from './EspaceReliableNavbarBelow.view';
import { useLocation, useParams } from 'react-router-dom';
import { INavbarItem } from '../../Navbar.model';
import { navbarEspaceClientElements, navbarEspaceSupplierElements } from '../../Navbar.map';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../../store/store';
import { faHeart, faList, faNewspaper, faPercent, faScrewdriverWrench, faSearch } from '@fortawesome/free-solid-svg-icons';
import TechnicalHelpAutomotor
  from 'components/dropdowns/TechnicalHelpAutomotor/TechnicalHelpAutomotor';
import { usePermission } from 'utils/usePermission';
import {
  ROLE_SELLER,
  ROLE_COMMERCIAL_DIRECTOR,
  ROLE_SUPPLIER_AMERIGO
} from 'utils/constants/roles';
import { getCurrentMarketplace } from 'utils/marketplace';
import { Marketplaces } from 'utils/constants/applications';


type Props = {};

const EspaceReliableNavbarBelow: React.FC<Props> = () => {
  const location = useLocation();
  const [items, setItems] = useState<INavbarItem[]>([]);
  const { user } = useSelector((state: RootState) => state.userData);
  const permission = usePermission([ROLE_SELLER, ROLE_COMMERCIAL_DIRECTOR, ROLE_SUPPLIER_AMERIGO]);
  const permissionSeller = usePermission([ROLE_SELLER, ROLE_COMMERCIAL_DIRECTOR]);
  const isSupplierAmerigo = usePermission([ROLE_SUPPLIER_AMERIGO]);
  const currentCatalog = JSON.parse(localStorage.getItem('catalog')!);
  const isAutomotor = getCurrentMarketplace() === Marketplaces.AUTOMOTOR;

  const technicalDocumentationIsDisabled = () => {
    return user?.client_rmi_modules_type === 'none' && !user.client_settings.eina_subscription;
  };

  function promotionLink() {
    if (permissionSeller && !isSupplierAmerigo) {
      return '/promotions';
    } else {
      return `catalog/${currentCatalog?.id}/promotions`;
    }
  }

  const navbarEspaceCatalogElements: INavbarItem[] = [
    {
      id: 5,
      text: '_rapid_search',
      icon: faSearch,
      link: '/universal-catalog',
    },
    {
      id: 6,
      text: '_catalog',
      icon: faList,
      link: 'catalogs',
      localStorage: true

    },
    {
      id: 7,
      text: '_technical_help',
      icon: faScrewdriverWrench,
      link: null,
      localStorage: true,
      component: TechnicalHelpAutomotor,
      disabled: technicalDocumentationIsDisabled()
    },
    {
      id: 8,
      text: '_news',
      icon: faNewspaper,
      link: '/news',
      hidden: isAutomotor
    },
    {
      id: 24,
      text: '_promotions',
      icon: faPercent,
      link: promotionLink(),
      hidden: !permission || isAutomotor
    },
    {
      id: 26,
      text: '_history',
      icon: faSearch,
      link: '/page/history',
      hidden: !isAutomotor
    },
    {
      id: 27,
      text: '_favorite',
      icon: faHeart,
      link: '/page/favorite',
      hidden: !isAutomotor
    },
  ];

  useEffect(() => {
    switch (true) {
      case location.pathname.includes('/espace-clients'):
        setItems(navbarEspaceClientElements);

        break;
      case location.pathname.includes('/my-eshop'):
        setItems(navbarEspaceClientElements);

        break;

      case location.pathname.includes('/espace-tariffs'):
        setItems(navbarEspaceSupplierElements);

        break;

      case location.pathname.includes('/espace-suppliers'):
        setItems(navbarEspaceSupplierElements);

        break;

      case location.pathname.includes('/my-clients'):
        setItems(navbarEspaceClientElements);

        break;

      case location.pathname.includes('orders-clients'):
        setItems(navbarEspaceClientElements);

        break;
      case location.pathname.includes('/espace-clients/promotions'):
        setItems(navbarEspaceClientElements);

        break;
      case (
        location.pathname.includes('/universal-catalog')
        || location.pathname == '/catalog'
        || location.pathname.includes('/technical-documentation')
        || location.pathname.includes('/catalogs')
        || location.pathname.includes('/error-codes')
        || location.pathname.includes('/diagnostic')
        || location.pathname.includes('/news')
        || location.pathname.includes('/promotions')
        || location.pathname.includes('/page/history')
        || location.pathname.includes('/page/favorite')

      ):
        setItems(navbarEspaceCatalogElements);

        break;
      default:
        setItems([]);
    }
  }, [location]);

  return <EspaceReliableNavbarBelowView items={items} />;
};

EspaceReliableNavbarBelow.displayName = 'EspaceReliableNavbarBelow';

export default EspaceReliableNavbarBelow;
