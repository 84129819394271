import React, { ReactNode, useCallback, useState } from 'react';
import { faAdd } from '@fortawesome/free-solid-svg-icons';
import Select, {components, ValueContainerProps} from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import TextInput from 'components/inputs/TextInput/TextInput';


type Props = {
  setOpenModal: (e: boolean) => void;
  setParams;
  setForcePage: (forcePage: number) => void;
  setIsActive: (e: boolean) => void;
  clients
  providers
}

const AllowedListFilter: React.FC<Props> = (props) => {
  const { register, getValues, handleSubmit, control, setValue } = useForm();
  const [clientIds, setClientIds] = useState<string[]>([]);
  const { t } = useTranslation();

  const onSubmit = () => {
    const values = getValues();

    Object.keys(values).forEach((key) => {
      if (values[key] == undefined || values[key] == '') {
        delete values[key];
      }
    });

    if (Object.keys(values).length == 0) {
      return;
    }

    const params = {
      ...values,
      numcli: clientIds,
      codfou: getValues('codfou.value'),
      page: 1
    };

    props.setParams(params);
    props.setForcePage(1);
    props.setIsActive(false);
  };

  const resetFilter = () => {
    const values = getValues();

    Object.keys(values).forEach((key) => {
      if (values[key] == undefined || values[key] == '') {
        delete values[key];
      }
    });

    setValue('codfou', null);
    setValue('title', '');
    setValue('numcli', null);
    
    props.setParams({ page: 1 });
    props.setForcePage(1);
    props.setIsActive(false);
  };


  const CheckboxOption = (props) => {
    return (
      <div className="allowed-checkbox-option">
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => props.selectOption(props.data)}
        />
        <label>{props.label}</label>
      </div>
    );
  };

  const MultiValue = (props) => {
    return (
      <components.MultiValue className="multi-value" {...props}>
        {props.children}
      </components.MultiValue>
    );
  };

  const ValueContainer = useCallback((props: ValueContainerProps) => {
    const length = props.children![0]?.length;
    const tmpChildren = [...props.children as Array<ReactNode>];

    if(length >= 3){
      tmpChildren[0] = `${length} selected`;
    }

    return <components.ValueContainer {...props}>{tmpChildren}</components.ValueContainer>;
  }, []);
  
  const customComponents = {
    Option: CheckboxOption,
    ValueContainer,
    MultiValue,
    MultiValueRemove: () => null
  };

  const customStyles = {
    multiValue: (provided) => ({
      ...provided,
      background: '#D5E2FC',
      color: 'black',
    }),
    valueContainer: (provided) =>({
      ...provided,
      flexWrap: 'nowrap'
    }),
    placeholder: (provided)=>({
      ...provided,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    }),
    menuPortal: base => ({ ...base, zIndex: 150 }) 
  };

  return (
    <div className="filter">
      <div className="filter-header">
        <div className="filter-title">{t('_advanced_search')}</div>

        <div className="filter-link-btn">
          <button className="add-btn" onClick={() => props.setOpenModal(true)}> <FontAwesomeIcon icon={faAdd} className="icon" /> {t('_create_allowed_list')} </button>
        </div>
      </div>
      <form onSubmit={handleSubmit(() => onSubmit())}>
        <div className="filter-body">
          <div className="filter-input-item">
            <TextInput {...register('title')} placeholder={t('_title')} />
          </div>
          <div className="filter-input-item">
            <Controller
              control={control}
              name="codfou"
              render={({ field: { onChange, value } }) => {
                return (
                  <Select
                    value={value}
                    className="select-dropdown"
                    onChange={(val) => {
                      onChange(val);
                    }}
                    options={props.providers}
                    placeholder={t('_provider')}
                  />
                );
              }}
            />
          </div>
          <div className="filter-input-item">
            <Controller
              control={control}
              name="numcli"
              render={({ field: { onChange, value } }) => {
                return (
                  <Select
                    value={value}
                    className="select-dropdown"
                    onChange={(val) => {
                      const values: string[] = val ? val.map(option => option.value) : [];

                      setClientIds(values);
                      onChange(val);
                    }}
                    options={props.clients}
                    placeholder={t('_client')}
                    isMulti
                    menuPlacement="auto"
                    isClearable={false}
                    isSearchable
                    menuPosition="fixed"
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    components={customComponents}
                    styles={customStyles}
                  />
                );
              }}
            />
          </div>
          <DefaultButton text={t('_filter')} type="submit" />
          <DefaultButton text={t('_reset')} red onClick={() => { resetFilter(); }} type="button" />
        </div>
      </form>
    </div >
  );
};

export default AllowedListFilter;
