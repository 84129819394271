import React from 'react';
import './ImageCell.scss';

type Props = { src: string };

const ImageCellView: React.FC<Props> = (props: Props) => {

  return (
    <div className="image-cell-wrapper">
      <div className="image-container">
        <img className="img" src={props.src || '/images/car-part-placeholder.png'} alt="cart-part" onError={({ currentTarget }) => {
          currentTarget.onerror = null;

          currentTarget.src = '/images/car-part-placeholder.png';
        }}
        />
      </div>
    </div>
  );
};

ImageCellView.displayName = 'ImageCellView';

export default ImageCellView;
