import { RootState } from 'store/store';
import { createDeepEqualSelector } from './index';

export const clientsSelector = (state: RootState): RootState['clients'] => state.clients;

export const getClients = createDeepEqualSelector(clientsSelector, (clients) => clients.clients);

export const getClientsData = createDeepEqualSelector(clientsSelector, (clients) => clients.clientsData);

export const getFilteredClients = createDeepEqualSelector(clientsSelector, (clients) => clients.filteredClients);

export const getClientContacts = createDeepEqualSelector(clientsSelector, (clients) => clients.clientContacts);

export const getIsLoadingClients = createDeepEqualSelector(clientsSelector, (clients) => clients.isLoadingClients);

export const getIsLoadingContacts = createDeepEqualSelector(clientsSelector, (clients) => clients.isLoadingContacts);

export const getClientsPage = createDeepEqualSelector(clientsSelector, (clients) => clients.page);
