import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faSearch } from '@fortawesome/free-solid-svg-icons';
import GeneralSearch from 'components/general-search/GeneralSearch';
import { Trans, useTranslation } from 'react-i18next';
import './RapidSearch.scss';
import RapidSlider from 'applications/retailer/components/RapidSlider/RapidSlider';
import useHttp from 'utils/hooks/useHttp';
import { IHistory } from 'models/IHistory';
import HistoryHttp from 'utils/hooks/http/history.http';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { useNavigate } from 'react-router-dom';
import { IFavoriteList } from 'models/favorite';
import RapidAccess from 'applications/retailer/components/RapidAccess/RapidAccess';
import GlobalSearchInput from '../../../../components/inputs/GlobalSearchInput/GlobalSearchInput';
import { retailerCatalogItems } from 'applications/automotor/components/espace/client/catalog/Catalog.map';
import { ICatalogModel } from 'applications/automotor/components/espace/client/catalog/catalog.model';
import SwiperSlider from 'components/SwiperSlider/SwiperSlider';
import { SwiperSlide } from 'swiper/react';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import ProductCategoryItemNew from 'components/Item/ProductCategoryItemNew/ProductCategoryItemNew';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { IUser } from 'models/IUser';
import { Marketplaces } from 'utils/constants/applications';
import { getCurrentMarketplace } from 'utils/marketplace';

type Props = {};

const RapidSearch: React.FC<Props> = () => {
  const { t } = useTranslation();
  const http = useHttp();
  const navigate = useNavigate();
  const [historyData, setHistoryData] = useState<IHistory>();
  const [favoriteData, setFavoriteData] = useState<IFavoriteList[]>([]);
  const [loadingHistory, setLoadingHistory] = useState(false);
  const [loadingFavourite, setLoadingFavourite] = useState(false);
  const { isMobile } = useDeviceDetect();
  const { user } = useSelector((state: RootState) => state.userData);
  const [userData, setUserData] = useState<IUser>(user as IUser);

  function getHistoryData() {
    setLoadingHistory(true);
    http.history
      .getHistory({ page_parts: 1, page_cars: 1, per_page: HistoryHttp.PER_PAGE })
      .then((res) => setHistoryData(res.data))
      .finally(() => setLoadingHistory(false));
  }

  function getFavoriteData() {
    setLoadingFavourite(true);
    http.favorite
      .getFavoriteLists()
      .then((res) => setFavoriteData(res))
      .finally(() => setLoadingFavourite(false));
  }

  useEffect(() => {
    getHistoryData();
    getFavoriteData();
  }, []);

  useEffect(() => {
    if (user) {
      setUserData(user);
    }
  }, [user]);

  const filteredCatalogItems = getCurrentMarketplace() ===  Marketplaces.RETAILER || getCurrentMarketplace() === Marketplaces.PUNCHOUT ? retailerCatalogItems(userData).filter((item) => item.disabled === false) : [];

  return (
    <div className="home retailer-rapid-search">
      <div className="retailer-catalog-search">
        <div className="header-search">
          <div className="title">
            <Trans i18nKey="_home_title" components={{ b: <b /> }} />
          </div>
          {/* <div className="subtitle">
            {t('_run_search_with_saved_filters')} <FontAwesomeIcon icon={faArrowRight} />
          </div> */}
          <div className="header-search-input">
            <GlobalSearchInput placeholder={t('_free_search')} withIcon size="l" />
          </div>
        </div>
        {!isMobile ? (
          filteredCatalogItems.length > 0 &&
          <div className="rapid-search-catalog-items">
            {filteredCatalogItems.map((item: ICatalogModel, index: number) => {
              return (<ProductCategoryItemNew text={t(item.text)} image={item.image} onClick={() => navigate(item.link)} key={index} />);
            })}
          </div>
        ) : (
          filteredCatalogItems.length > 0 &&
          <div className="rapid-search-catalog-items">
            <SwiperSlider spaceBetween={0} slideToIndex="auto" slidesPerView={2} loop>
              {filteredCatalogItems.map((item: ICatalogModel, index: number) => {
                return (
                  <SwiperSlide key={index} className="swiper-slide swiper-navbar-item">
                    <ProductCategoryItemNew text={t(item.text)} image={item.image} onClick={() => navigate(item.link)} />
                  </SwiperSlide>
                );
              })}
            </SwiperSlider>
          </div>
        )}
        <div className="general-search">
          <GeneralSearch />
        </div>
      </div>

      <div className="vehicle-quick-sliders">
        <div className="quick-sliders">
          <div className="slider-title">
            <FontAwesomeIcon icon={faSearch} /> {t('_my_last_searches')}
          </div>
          <Tabs className="tabs">
            <TabList className="tab-list">
              <div className="tab-container">
                <Tab className="tab">{t('_vehicles')}</Tab>
                <Tab className="tab">{t('_parts')}</Tab>
              </div>
              <div className="view-all" onClick={() => navigate('/history')}>
                {t('_view_history')}
              </div>
            </TabList>
            <TabPanel className="tab-body">
              {historyData && historyData?.cars?.data?.length > 3 ? (
                <RapidSlider isLoading={loadingHistory} data={historyData?.cars.data} type="history-vehicles" />
              ) : (
                <RapidAccess isLoading={loadingHistory} data={historyData?.cars.data} type="history-vehicles" />
              )}
            </TabPanel>
            <TabPanel className="tab-body">
              {historyData && historyData?.parts?.data?.length > 3 ? (
                <RapidSlider isLoading={loadingHistory} data={historyData?.parts.data} type="history-parts" />
              ) : (
                <RapidAccess isLoading={loadingHistory} data={historyData?.parts.data} type="history-parts" />
              )}
            </TabPanel>
          </Tabs>
        </div>
        <div className="quick-sliders">
          <div className="slider-title">
            <FontAwesomeIcon icon={faHeart} /> {t('_my_favorite')}
          </div>
          <Tabs className="tabs" defaultIndex={1}>
            <TabList className="tab-list">
              <div className="tab-container">
                <Tab className="tab" disabled>
                  {t('_vehicles')}
                </Tab>
                <Tab className="tab">{t('_parts')}</Tab>
              </div>
              <div className="view-all" onClick={() => navigate('/favorite')}>
                {t('_view_favorites')}
              </div>
            </TabList>
            <TabPanel className="tab-body">
              {favoriteData?.length > 3 ? (
                <RapidSlider isLoading={loadingFavourite} data={favoriteData} type="favourite-parts" />
              ) : (
                <RapidAccess isLoading={loadingFavourite} data={favoriteData} type="favourite-parts" />
              )}
            </TabPanel>
            <TabPanel className="tab-body">
              {favoriteData?.length > 3 ? (
                <RapidSlider isLoading={loadingFavourite} data={favoriteData} type="favourite-parts" />
              ) : (
                <RapidAccess isLoading={loadingFavourite} data={favoriteData} type="favourite-parts" />
              )}
            </TabPanel>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default RapidSearch;
