import React, { PropsWithChildren } from 'react';
import './RoundedButton.scss';

type Props = {
  text: string | JSX.Element;
  onClick: () => void;
};

const RoundedButtonView: React.FC<Props> = (props: PropsWithChildren<Props>) => {
  return (
    <button className="rounded-button" onClick={props.onClick} type="button">
      {props.text}
    </button>
  );
};

RoundedButtonView.displayName = 'RoundedButtonView';

export default RoundedButtonView;
