import React from 'react';

import './DistributorCell.scss';

type Props = { value: string };

const DistributorCellView: React.FC<Props> = (props: Props) => {
  return <div className="wrapper-cart-supplier"> {props.value}</div >;
};

DistributorCellView.displayName = 'DistributorCellView';

export default DistributorCellView;
