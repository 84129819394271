import React from 'react';
import TariffsAutomotorSwiper from './TariffsAutomotorSwiper';
import Spinner from 'components/spinner/Spinner';

type Props = { tariffs };

const TariffsAutomotor: React.FC<Props> = (props) => {

  return <div className="tariffs">
    {props.tariffs !== null && typeof props.tariffs !== 'string' && Object.keys(props.tariffs).length ?


      Object.keys(props.tariffs).map((item) => {

        return <TariffsAutomotorSwiper data={props.tariffs[item]} key={item} title={item} length={props.tariffs[item].length} />;

      })
      :
      <Spinner class="small" />
    }
  </div>;
};


export default TariffsAutomotor;


