import React, { useEffect, useState } from 'react';
import './SearchReference.scss';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleDot, faOilCan, faTag, faTools, faCarBattery } from '@fortawesome/free-solid-svg-icons';
import TextInput from '../../../../../../components/inputs/TextInput/TextInput';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { IData } from 'utils/hooks/http/search-by-reference.http';

import useHttp from 'utils/hooks/useHttp';
import axios from 'axios';
import RefDropdownContentPartsAdmin from './dropdown-content-parts/RefDropdownContentPartsAdmin';


const SearchReferenceView: React.FC = () => {
  const { t } = useTranslation();
  const { user } = useSelector((state: RootState) => state.userData);
  const [tiresReferance, setTireReferance] = useState<string>('');
  const [oilsReferance, setOilsReferance] = useState<string>('');
  const [consumablesReferance, setConsumablesReferance] = useState<string>('');
  const [batteryReferance, setBatteryReferance] = useState<string>('');
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [refData, setRefData] = useState<IData[]>([]);
  const http = useHttp();
  const cancelToken = axios.CancelToken.source();

  const handleSearch = (searchType: string) => {
    switch (searchType) {
      case 'parts':
        break;
      case 'tires':
        navigate({
          pathname: '/espace-clients/my-eshop/catalog_tyres',
          search: `?${createSearchParams([['reference', tiresReferance]])}`,
        });

        break;
      case 'oils':
        navigate({
          pathname: '/espace-clients/my-eshop/catalog_oil',
          search: `?${createSearchParams([['reference', oilsReferance]])}`,
        });

        break;
      case 'consumables':
        navigate({
          pathname: '/espace-clients/my-eshop/catalog_consumable',
          search: `?${createSearchParams([['reference', consumablesReferance]])}`,
        });

        break;
      case 'battery':
        navigate({
          pathname: '/espace-clients/my-eshop/catalog_battery',
          search: `?${createSearchParams([['reference', batteryReferance]])}`,
        });

        break;
      default:
        break;
    }
  };


  function refInputChange(event) {
    const inputData = event.target.value.replace(/\s/g, '').length > 0 ? event.target.value : event.target.value.trim();

    setInputValue(inputData);


    if (inputData.length === 0) {
      setRefData([]);
      setIsOpen(false);
    }

  }

  function getRefData(q: string) {
    setLoading(true);
    setIsOpen(true);

    http.searchByRef.SearchByRefAdmin({ q: q, is_platform_admin: true }, cancelToken.token)
      .then((res) => {
        setRefData(res.data);
        setLoading(false);
      });
  }

  function submitData() {
    if (inputValue.length < 3) { return; }

    getRefData(inputValue);
    setIsOpen(true);
  }

  useEffect(() => {
    if (inputValue.length >= 3) {
      getRefData(inputValue);
      setLoading(true);

      return () => {
        cancelToken.cancel();
      };
    }

  }, [inputValue]);

  return (
    <div className="search-reference-content">
      <div className="title">
        <p>{t('_search_reference_promotions')}</p>
      </div>
      <div className="search">
        <div className="car-parts-reference">
          <label className="reference-label">
            {' '}
            <FontAwesomeIcon className="reference-icon" icon={faTag} />
            {t('_ref_parts')}
          </label>
          <TextInput
            placeholder="Ex: PDC2015"
            withIcon
            onChange={refInputChange}
            onClick={submitData}
          />
          {isOpen && (
            <RefDropdownContentPartsAdmin refValue={inputValue} loading={loading} setIsOpen={setIsOpen} refData={refData} />
          )}
        </div>
        {user?.hasTyre && (
          <div className="tyre-reference">
            <label className="reference-label">
              {' '}
              <FontAwesomeIcon className="reference-icon" icon={faCircleDot} />
              {t('_ref_tyre')}
            </label>
            <TextInput
              placeholder="Ex : 205 55 16 91"
              value={tiresReferance}
              onChange={(e) => setTireReferance(e.target.value)}
              withIcon
              onClick={() => handleSearch('tires')}
            />
          </div>
        )}
        {user?.hasOil && (
          <div className="oil-reference">
            <label className="reference-label">
              {' '}
              <FontAwesomeIcon className="reference-icon" icon={faOilCan} />
              {t('_ref_oil')}
            </label>
            <TextInput
              placeholder="Ex : 1003001F"
              value={oilsReferance}
              onChange={(e) => setOilsReferance(e.target.value)}
              withIcon
              onClick={() => handleSearch('oils')}
            />
          </div>
        )}
        {user?.hasConsumable && (
          <div className="consumable-reference">
            <label className="reference-label">
              {' '}
              <FontAwesomeIcon className="reference-icon" icon={faTools} />
              {t('_ref_consumable')}
            </label>
            <TextInput
              placeholder="Ex: 2002"
              value={consumablesReferance}
              onChange={(e) => setConsumablesReferance(e.target.value)}
              withIcon
              onClick={() => handleSearch('consumables')}
            />
          </div>
        )}
        {user?.hasBattery && (
          <div className="battery-reference">
            <label className="reference-label">
              {' '}
              <FontAwesomeIcon className="reference-icon" icon={faCarBattery} />
              {t('_ref_battery')}
            </label>
            <TextInput
              placeholder="Ex: T170"
              value={batteryReferance}
              onChange={(e) => setBatteryReferance(e.target.value)}
              withIcon
              onClick={() => handleSearch('battery')}
            />
          </div>
        )}
      </div>
    </div>
  );

};

SearchReferenceView.displayName = 'SearchReferenceView';

export default SearchReferenceView;
