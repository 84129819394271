
import { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import ru from 'date-fns/locale/ru';
import it from 'date-fns/locale/it';
import zhCN from 'date-fns/locale/zh-CN';
import fr from 'date-fns/locale/fr';
import tr from 'date-fns/locale/tr';
import ar from 'date-fns/locale/ar';
import ko from 'date-fns/locale/ko';

export const useLocaleDatePicker = (lang: string): string => {

  lang = lang.split('-')[0];

  if (lang == 'fr') {
    registerLocale(lang, fr);
  }

  if (lang == 'es') {
    registerLocale(lang, es);
  }

  if (lang == 'ru') {
    registerLocale(lang, ru);
  }

  if (lang == 'it') {
    registerLocale(lang, it);
  }

  if (lang == 'zh') {
    registerLocale(lang, zhCN);
  }

  if (lang == 'tr') {
    registerLocale(lang, tr);
  }

  if (lang == 'ar') {
    registerLocale(lang, ar);
  }

  if (lang == 'ar') {
    registerLocale(lang, ar);
  }

  if (lang == 'ko') {
    registerLocale(lang, ko);
  }


  return lang;


};