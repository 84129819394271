import React, { useState } from 'react';
import {FieldErrors, UseFormHandleSubmit, UseFormRegister} from 'react-hook-form';
import {IPlateForm} from '../../../utils/hooks/http/search-by-vin.http';
import {IPlateNumberData} from '../../../utils/hooks/http/search-by-plate.http';
import './PlateSearch.scss';
import TextInput from '../../inputs/TextInput/TextInput';
import {useTranslation} from 'react-i18next';
import classes from '../../../pages/Retailer/Login/Login.module.scss';
import {ILoginForm} from '../../../models/ILoginForm.model';
import DropdownContainer from '../../dropdown-container/DropdownContainer';
import DropDownContent from './DropDownContent/DropDownContent';

type Props = {
    errors: FieldErrors<IPlateForm>;
    registrationPlate: string;
    register: UseFormRegister<IPlateForm>;
    handleSubmit: UseFormHandleSubmit<IPlateForm>;
    onSubmit: (data: object) => void;
    plateData: IPlateNumberData | null;
    isLoading: boolean;
    isOpen: boolean;
    setIsOpen: (data: boolean) => void;
    setOpenModal?: (e: boolean) => void;
    link?:string;
};


const PlateSearchView: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  return (
    <div className="plate-number">
      <form className="form" onSubmit={props.handleSubmit(props.onSubmit)}>
            
        <label className="reference-label">{t('_plate')}</label>
        <TextInput
          placeholder={t('_plate')}
          {...props.register('registration_plate', {
            required: true,
            pattern: { value: /^.{5,10}$/, message: t('_plate_number_invalid') },
          })}
          onClick={props.handleSubmit(props.onSubmit)}
          withIcon
        />

        {props.errors.registration_plate?.type == 'pattern' && <p className="error-message">{t('_plate_number_invalid')}</p>}

        {props.isOpen && (
          <DropdownContainer setIsOpen={props.setIsOpen} loading={props.isLoading}>
            <DropDownContent registration_plate={props.registrationPlate} plateData={props.plateData} setIsOpen={props.setIsOpen} setOpenModal={props.setOpenModal} link={props.link}/>
          </DropdownContainer>
        )}
      </form>
    </div>

  );
};

PlateSearchView.displayName = 'PlateSearchView';

export default PlateSearchView;