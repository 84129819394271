import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { IData, IDataResponse } from 'models/order';
import { Controller, useForm } from 'react-hook-form';
import useHttp from 'utils/hooks/useHttp';
import TextInput from 'components/inputs/TextInput/TextInput';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import DatePicker from 'react-datepicker';
import { useOnClickOutside } from 'utils/hooks/useOnClickOutside';
import moment from 'moment';

import 'react-datepicker/dist/react-datepicker.css';
import OrderTable from './ClientOfferTable/ClientOfferTable';

import { useTranslation } from 'react-i18next';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import { useLocaleDatePicker } from 'utils/localeDatePicker';
import { useSearchParams } from 'react-router-dom';
import i18n from 'i18n';
import Select from 'react-select';
import ClientOfferDetails from './ClientOfferDetails/ClientOfferDetails';
import { IOption } from 'interfaces/select';
import './ClientOfferList.scss';

type Props = {
  catalogId?: string;
  seller?: boolean
};

const ClientOfferList: React.FC<Props> = (props) => {
  const { getValues, register, handleSubmit, reset, control } = useForm();
  const http = useHttp();
  const [urlParams, setSearchParams] = useSearchParams();

  const [data, setData] = useState<IData[]>([]);
  const [clients, setClients] = useState<IOption[]>([]);
  const [openDetails, setOpenDetails] = useState(false);
  const [orderAction, setOrderAction] = useState<string>('');

  const [, setPaginationData] = useState<IDataResponse>();
  const [currentPage, setCurrentPage] = useState<number>(urlParams.get('page') ? Number(urlParams.get('page')) : 1);
  const [totalPage, setTotalPage] = useState<number>(0);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [valueDate, setValueDate] = useState('');
  const [openDate, setOpenDate] = useState(false);
  const [loading, setLoading] = useState(false);
  const dateRef = useRef() as MutableRefObject<HTMLDivElement>;

  const { t } = useTranslation();
  const { isMobile } = useDeviceDetect();

  useOnClickOutside(dateRef, () => setOpenDate(false));
  useLocaleDatePicker(i18n.language);

  function getClientOfferList(params) {
    setLoading(true);
    http.order
      .getOrders({ ...params, status: 'NEED_OFFER' })
      .then((res) => {
        const { data, page, total_pages } = res.data as IDataResponse;

        setTotalPage(total_pages);
        setData(data);
        setPaginationData({ data, page, total_pages });

      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {

    getClientOfferList({ page: urlParams.get('page') ?? 1 });

  }, [urlParams.get('page')]);

  const onSubmit = async () => {
    setLoading(true);
    const data = getValues();

    if (data?.status) {
      data.status = data.status?.value;
    }

    if (data?.customer_id) {
      data.customer_id = data.customer_id?.value;
    }

    const start_date = moment(startDate).format('YYYY-MM-DD');
    const end_date = moment(endDate).format('YYYY-MM-DD');

    if (moment(start_date).isValid()) {
      data.start_date = moment(startDate).format('YYYY-MM-DD');
    }

    if (moment(end_date).isValid()) {
      data.end_date = moment(endDate).format('YYYY-MM-DD');
    }


    const validParams = Object.entries(data).reduce((acc, [key, value]) => {
      if (!value) {
        return acc;
      }

      acc[key] = value;

      return acc;
    }, {});


    urlParams.delete('page');
    setSearchParams(urlParams);
    setCurrentPage(1);
    await getClientOfferList({ ...validParams, page: 1 });
  };

  const handlePageClick = (data: number) => {
    setCurrentPage(data);
  };

  function onDateChange(dates) {
    const [start, end] = dates;

    setStartDate(start);
    setEndDate(end);

    if (start && end) {
      setValueDate(`${moment(start).format('DD/MM/YYYY')} - ${moment(end).format('DD/MM/YYYY')}`);
    }
    else {

      if (start) {
        setValueDate(`${moment(start).format('DD/MM/YYYY')}`);

      }

      if (end) {
        setValueDate(`${moment(end).format('DD/MM/YYYY')}`);

      }
    }
  }

  function getClientOptions() {
    const params = {
      status: props.seller ? 'NEED_OFFER' : null
    };

    const validParams = Object.entries(params).reduce((acc, [key, value]) => {
      if (!value) {
        return acc;
      }

      acc[key] = value;

      return acc;
    }, {});


    http.order.getClients(validParams).then(res => {
      setClients(res.data);
    });
  }

  useEffect(() => {
    getClientOptions();
  }, []);

  const handleResetFilter = () => {
    reset({ date: '', id: '', customer_id: '' });
    setCurrentPage(1);
    setSearchParams({ page: '1' });
    setStartDate(null);
    setEndDate(null);
    setValueDate('');
  };

  const hiddenColumns = isMobile ? { hiddenColumns: ['time', 'proforma_id', 'user_email'] } : {};

  return (
    !openDetails ?
      <div className="automotor-offer-list">
        <div className="orders-filter">
          <div className="orders-filter-header">
            <div className="filter-title">{t('_order_search')}</div>
          </div>
          <form onSubmit={handleSubmit(() => onSubmit())}>
            <div className="orders-filter-body">
              <div className="orders-filter-input-item">
                <Controller
                  control={control}
                  name="customer_id"
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Select
                        value={value}
                        className="select-dropdown"
                        onChange={(value) => onChange(value)}
                        options={clients}
                        placeholder={t('_client')}
                        styles={{
                          menu: provided => ({ ...provided, zIndex: 50 })
                        }}
                        isSearchable={!isMobile}
                      />
                    );
                  }}
                />
              </div>
              <div className="orders-filter-input-item">
                <div className={`orders-react-picker-dropdown ${openDate ? 'disable' : ''}`} onClick={() => setOpenDate(true)}>
                  <TextInput disabled={openDate} placeholder={t('_select_date')} defaultValue={valueDate ? valueDate : ''} />
                </div>
                {openDate && (
                  <div className="orders-date-picker" ref={dateRef}>
                    <DatePicker
                      {...register('date')}
                      dateFormat="YYYY-MM-DD"
                      selected={startDate}
                      onChange={onDateChange}
                      startDate={startDate}
                      endDate={endDate}
                      monthsShown={isMobile ? 1 : 2}
                      selectsRange
                      inline
                      locale={i18n.language.split('-')[0]}
                    />
                  </div>
                )}
              </div>
              <div className="orders-filter-input-item">
                <TextInput {...register('id')} placeholder={t('_by_id')} />
              </div>
              <DefaultButton text={t('_filter')} type="submit" />
              <DefaultButton red text={t('_reset')} onClick={handleResetFilter} />
            </div>
          </form>
        </div>
        <div className="orders-table">
          <OrderTable data={data} getClientOfferList={getClientOfferList} handlePageClick={handlePageClick} setOpenDetails={setOpenDetails} currentPage={currentPage} totalPage={totalPage} loading={loading} hiddenColumns={hiddenColumns} orderAction={orderAction} setOrderAction={setOrderAction} />
        </div>
      </div>
      : <ClientOfferDetails setOpenDetails={setOpenDetails} getClientOfferList={getClientOfferList} orderAction={orderAction} setOrderAction={setOrderAction} />
  );
};

export default ClientOfferList;
