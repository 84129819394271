import React, { useEffect, useState } from 'react';
import { faFileInvoice, faGear, faInfo, faTools } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Outlet, useLocation, useNavigate, useOutletContext, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import MaintenanceTabs from 'pages/CatalogVehicle/TechnicalDocumentation/components/Maintenance/Tabs/MaintenanceTabs';
import MaintenanceTab from 'pages/CatalogVehicle/TechnicalDocumentation/components/Maintenance/Tabs/MaintenanceTab';

import MaintenanceMobileTabs from 'pages/CatalogVehicle/TechnicalDocumentation/components/Maintenance/MobileTabs/MaintenanceMobileTabs';
import MaintenanceMobileTab from 'pages/CatalogVehicle/TechnicalDocumentation/components/Maintenance/MobileTabs/MaintenanceMobileTab';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';


const MaintenanceLayout = (): JSX.Element => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const location = useLocation();
  const { hasServiceProposal } = useOutletContext<{ hasServiceProposal: boolean }>();
  const [hasMaintenanceWorks, setHasMaintenanceWorks] = useState(false);
  const [hasServicePlan, setHasServicePlan] = useState(false);
  const [activeTabs, setActiveTabs] = useState<string[]>(['info']);
  const { search } = useLocation();
  const { isMobile } = useDeviceDetect();
  const [searchParams] = useSearchParams();


  function handleClick(path) {
    navigate({
      pathname: path,
      search: `${search}`,
    });
  }

  useEffect(() => {
    if (location.pathname.includes('service-plan')) {
      setHasMaintenanceWorks(true);
      setHasServicePlan(true);
    } else if (location.pathname.includes('repair')) {
      setHasMaintenanceWorks(true);
    } else if (searchParams.get('workIds')) {
      setHasMaintenanceWorks(true);
      setHasServicePlan(true);

    }

    if (!hasServiceProposal) {
      setHasMaintenanceWorks(true);
    }
  }, [location.pathname, hasServiceProposal]);

  useEffect(() => {
    if (searchParams.get('workIds')) {
      setHasServicePlan(true);
    } else {
      setHasServicePlan(false);
    }
  }, [searchParams.get('workIds'), location.search]);

  useEffect(() => {
    if (hasServiceProposal) {
      setActiveTabs(prev => [...prev, 'suggested']);
    }

    if (hasMaintenanceWorks) {
      setActiveTabs(prev => [...prev, 'repair']);
    }

    if (hasServicePlan) {
      setActiveTabs(prev => [...prev, 'service-plan']);
    }

    return () => {
      setActiveTabs(['info']);
    };
  }, [hasServiceProposal, hasMaintenanceWorks, hasServicePlan]);

  return (
    <div>
      {isMobile ?
        <MaintenanceMobileTabs activeTabs={activeTabs}>
          <MaintenanceMobileTab icon={<FontAwesomeIcon icon={faInfo} />} title={t('_manufacturer_information')} onClick={() => handleClick('info')} activeParam="info" />
          <MaintenanceTab disabled={!hasServiceProposal} icon={<FontAwesomeIcon icon={faGear} />} title={t('_suggested_maintenance')} onClick={() => handleClick('suggested')} activeParam="suggested" />
          <MaintenanceTab disabled={!hasMaintenanceWorks} icon={<FontAwesomeIcon icon={faTools} />} title={t('_maintenance_works')} onClick={() => handleClick('repair')} activeParam="repair" />
          <MaintenanceTab disabled={!hasServicePlan} icon={<FontAwesomeIcon icon={faFileInvoice} />} title={t('_service_plan')} onClick={() => handleClick('service-plan')} activeParam="service-plan" />
        </MaintenanceMobileTabs>
        :
        <MaintenanceTabs>
          <MaintenanceTab icon={<FontAwesomeIcon icon={faInfo} />} title={t('_manufacturer_information')} onClick={() => handleClick('info')} activeParam="info" />
          <MaintenanceTab disabled={!hasServiceProposal} icon={<FontAwesomeIcon icon={faGear} />} title={t('_suggested_maintenance')} onClick={() => handleClick('suggested')} activeParam="suggested" />
          <MaintenanceTab disabled={!hasMaintenanceWorks && hasServiceProposal} icon={<FontAwesomeIcon icon={faTools} />} title={t('_maintenance_works')} onClick={() => handleClick('repair')} activeParam="repair" />
          <MaintenanceTab disabled={!hasServicePlan} icon={<FontAwesomeIcon icon={faFileInvoice} />} title={t('_service_plan')} onClick={() => handleClick('service-plan')} activeParam="service-plan" />
        </MaintenanceTabs>
      }
      <Outlet context={{ setHasMaintenanceWorks, setHasServicePlan }} />
    </div>
  );
};

export default MaintenanceLayout;