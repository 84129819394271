
import { CancelToken } from 'axios';
import { IParamsBrand, IBodyResponseBrand, IBrand } from 'interfaces/batteryBrand';
import { addRouteParam, IHttpResponse, serverAxios } from '../../http';


class BatteryBrandHttp {

  private static list = '/battery/brand/list';
  private static info = '/battery/brand/info/';
  private static update = '/battery/brand/update/';
  private static create = '/battery/brand/register';
  private static delete = '/battery/brand/delete/';
  private static isPartner = '/battery/brand/update/:id/is_partner';

  public getList(urlParams: IParamsBrand, cancelToken: CancelToken): Promise<IHttpResponse<IBodyResponseBrand>> {
    return serverAxios.get(BatteryBrandHttp.list, { params: urlParams, cancelToken: cancelToken });
  }


  public getBrandInfo(id: number | undefined): Promise<IHttpResponse<IBrand>> {
    return serverAxios.get(BatteryBrandHttp.info + id);
  }

  public edit(id: number, data: FormData): Promise<IHttpResponse<IBrand>> {
    return serverAxios.post(BatteryBrandHttp.update + id, data);
  }

  public create(data: FormData): Promise<IHttpResponse<IBrand>> {
    return serverAxios.post(BatteryBrandHttp.create, data);
  }

  public delete(id: number): Promise<IHttpResponse<null>> {
    return serverAxios.delete(BatteryBrandHttp.delete + id);
  }

  public updateIsPartner(id: number, data: {}): Promise<IHttpResponse<[]>> {
    return serverAxios.post(addRouteParam(BatteryBrandHttp.isPartner, { id }), data);
  }
}

export default BatteryBrandHttp;
