import axios from 'axios';
import TextInput from 'components/inputs/TextInput/TextInput';
import React, { useEffect, useRef, useState } from 'react';
import { IDataRefAll } from 'utils/hooks/http/search-by-reference-all.http';
import useHttp from 'utils/hooks/useHttp';
import NewReferenceDropdown from './dropdown-content/NewReferenceDropdown';
import { useTranslation } from 'react-i18next';
import './NewReferenceSearch.scss';

type Props = {
    setParams
}

const NewReferenceSearch: React.FC<Props> = (props) => {
  const http = useHttp();
  const ref = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [refData, setRefData] = useState<IDataRefAll[]>([]);
  const [clearable, setClearable] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>('');
  const cancelToken = axios.CancelToken.source();
  const [upEnter, setUpEnter] = useState<boolean>(false);
  const {t} = useTranslation();
  
  
  function refInputChange(event) {
    setClearable(true);
  
    const inputData = event.target.value.replace(/\s/g, '').length > 0 ? event.target.value : event.target.value.trim();
    
    if (inputData.length >= 3) {
      setInputValue(inputData);
        
    }
  
    if(inputData.length === 0) {
      setClearable(false);
      setRefData([]);
      setIsOpen(false);
    }
      
  }
  
  const onKeyDown = (event) => {
    if (event.key === 'Enter') {
      const inputData = event.target.value.replace(/\s/g, '').length > 0 ? event.target.value : event.target.value.trim();
  
      setInputValue(inputData); 
      setUpEnter(true);
    }
  };
  
  const onClickReset = () => {
     
    setClearable(false);
      ref.current!.value = '';
     
      setRefData([]);
  
  };
  
  
  useEffect(()=>{
  
    if(inputValue !== '') {
       
      setLoading(true);
      setIsOpen(true);
           
      http.searchByRefAll.SearchByRefAll(inputValue.trim(), cancelToken.token)
        .then((res) => {
          setRefData(res.data);
          setLoading(false);
        });
      setUpEnter(false);
  
      return () => {
        cancelToken.cancel();
      };
    } 
  
  }, [inputValue ,upEnter]);
  
  
  return (
    <div className="automotor-new-reference">
      <div className="input">
        <div className="label">{t('_reference')}</div>
        <TextInput
          placeholder="PBPXXX" 
          ref={ref} 
          onChange={refInputChange} 
          clearable={clearable}
          onClickReset={onClickReset}
          onKeyDown={onKeyDown}
        />
      </div>
      {isOpen && <NewReferenceDropdown setParams={props.setParams} refData={refData} setIsOpen={setIsOpen} loading={loading} />}
    </div>
  );
};

export default NewReferenceSearch;