import React, {useContext} from 'react';
import {
  IProformaAmerigoRowItem,
} from 'utils/hooks/http/proforma.http';
import DELETE from 'assets/images/delete.svg';
import {
  IUploadProformaContext,
  UploadProformaAmerigoContext
} from 'context/UploadProformaAmerigoContext';
import './DeleteItem.scss';

type Props = {
  data: IProformaAmerigoRowItem;
  rowKey: string
}

const DeleteItem: React.FC<Props> = (props) => {

  const {
    uploadFileResponse,
    setUploadFileResponse
  } = useContext(UploadProformaAmerigoContext) as IUploadProformaContext;


  const handleDelete = () => {
    if (!uploadFileResponse) {
      return false;
    }

    const uploadFileResponseCopy = {...uploadFileResponse};

    uploadFileResponseCopy.rows[props.rowKey].filter((item, index) => {
      if (item.reference === props.data.reference) {
        uploadFileResponseCopy.rows[props.rowKey].splice(index, 1);
      }
    });
    setUploadFileResponse(uploadFileResponseCopy);
  };

  return (
    <div className="delete-proforma-item" onClick={handleDelete}>
      <img src={DELETE} alt="delete"/>
    </div>
  );
};

DeleteItem.displayName = 'DeleteItem';

export default DeleteItem;