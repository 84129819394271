import React, { useEffect, useMemo, useState } from 'react';
import Modal from 'components/modal/Modal';
import { useTranslation } from 'react-i18next';
import { Column, useExpanded, useTable } from 'react-table';
import useHttp from 'utils/hooks/useHttp';
import Spinner from 'components/spinner/Spinner';
import i18n from 'i18n';
import ModalDelete from 'components/CatalogNaf/ModalDelete/ModalDelete';
import Head from 'components/tables/Cells/Head/Head';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faEdit, faFilter, faTrash, faXmark } from '@fortawesome/free-solid-svg-icons';
import Pagination from 'components/pagination/Pagination';

import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import ExpandButton from 'components/tables/TableMoreInformation/ExpandButton/ExpandButton';
import TableMoreInformation from 'components/tables/TableMoreInformation/TableMoreInformation';
import { IData } from 'interfaces/selectClient';
import { IOption } from 'interfaces/select';
import { IAllowedListDetails } from 'utils/hooks/http/allowed-list.http';
import AllowedListFilter from './Filter/AllowedListFilter';
import CreateAllowedList from './ModalCreate/CreateAllowedList';
import { generatePath, useNavigate, useSearchParams } from 'react-router-dom';
import './AllowedList.scss';

type Props = {};

const AllowedList: React.FC<Props> = () => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [submit, setSubmit] = useState<boolean>(false);
  const { t } = useTranslation();
  const http = useHttp();
  const [loading, setLoading] = useState(false);
  const [data, setData] = React.useState<IAllowedListDetails[]>([]);
  const [params, setParams] = React.useState({ page: Number(searchParams.get('page') || 1) });
  const [openModalDelete, setOpenModalDelete] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<number | undefined>();
  const [pageCount, setPageCount] = useState(0);
  const [forcePage, setForcePage] = useState<number>(Number(searchParams.get('page') || 1));
  const { isMobile, isTablet, isDesktop } = useDeviceDetect();
  const [isActive, setIsActive] = useState(false);
  const [providers, setProviders] = useState<IOption[]>([]);
  const [clients, setClients] = useState<IOption[]>([]);
  const [countries, setCountries] = useState<IOption[]>([]);
  const [commercialZones, setCommercialZones] = useState<IOption[]>([]);

  const request = (params) => {
    http.allowedList
      .getAllowedList(params)
      .then((res) => {
        setData(res.data.data);
        setPageCount(res.data.total_pages);
      })
      .finally(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    Promise.all([
      http.allowedList.getAllowedList(params),
      http.allowedList.getClients(),
      http.allowedList.getProviders(),
      http.selectClient.countries(),
      http.selectClient.zoneCommercials(),
    ])
      .then((res) => {
        setData(res[0].data.data);
        setPageCount(res[0].data.total_pages);
        setClients(transformOptions(res[1].data));
        setProviders(res[2].data.map((item) => ({ value: item.code, label: `${item.company} (${item.code})` })));
        setCountries(res[3].data.map((item) => ({ value: item.id, label: item.name })));
        setCommercialZones(res[4].data.map((item) => ({ value: item.id, label: `${item.id} (${item.name})` })));
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const transformOptions = (optionsFromResponse: IData[]) => {
    if (!optionsFromResponse) {
      return [];
    }

    const uniqueOptions = optionsFromResponse
      .map((item) => ({
        label: item.name,
        value: String(item.id),
        country: item.country,
        zone_commercial: item.zone_commercial
      }))
      .filter((item, index, self) => self.findIndex((i) => i.label === item.label && i.value === item.value) === index);

    return uniqueOptions;
  };

  useEffect(() => {
    setLoading(true);
    request(params);
    setSubmit(false);
  }, [params]);

  useEffect(() => {
    if (submit) {
      setLoading(true);
      request(params);
      setSubmit(false);
    }
  }, [submit]);

  const columnsTable = useMemo((): Column<IAllowedListDetails>[] => columns(), [i18n.language, data, isMobile, isTablet, isDesktop]);

  function columns(): Column<IAllowedListDetails>[] {
    return [
      {
        id: 'expander',
        Header: '',
        width: 15,
        Cell: (row) => {
          return <ExpandButton row={row.row} />;
        },
      },
      {
        Header: <Head text={t('_title')}></Head>,
        accessor: 'title',
      },
      {
        Header: <Head text={t('_comments')}></Head>,
        accessor: 'comments',
      },
      {
        Header: <Head text={t('_created_at')}></Head>,
        accessor: 'created_at',
      },
      {
        Header: <Head text={t('_created_by')}></Head>,
        accessor: 'created_by',
      },
      {
        Header: <Head text={t('Codfou')}></Head>,
        accessor: 'provider',
        Cell: (cell) => <span>{cell.row.original?.provider?.company} ({cell.row.original?.provider?.codfou})</span>
      },
      {
        Header: <Head text={t('_actions')}></Head>,
        accessor: 'id',
        width: isDesktop ? 30 : 60,
        maxWidth: isDesktop ? 30 : 60,
        Cell: (cell) => (
          <div className="row-align-right">
            <FontAwesomeIcon
              icon={faEdit}
              onClick={() => {
                navigate({
                  pathname: generatePath(':id', {
                    id: String(cell.row.original.id),
                  }),
                });
              }}
            />
            <FontAwesomeIcon
              icon={faTrash}
              onClick={() => {
                setDeleteId(Number(cell.row.original.id));
                setOpenModalDelete(true);
              }}
            />
          </div>
        ),
      },
    ];
  }

  if (!isMobile) {
    columnsTable.shift();
  }

  const hiddenColumns = isMobile ? ['comments', 'created_at', 'created_by'] : [];

  const initialState = hiddenColumns ? { hiddenColumns: hiddenColumns } : {};

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns: columnsTable,
      data: data,
      initialState,
    },
    useExpanded,
  );

  const handlePageClick = (page: number) => {
    setForcePage(page);
    setSearchParams({ page: String(page) });
    setParams({ ...params, ...{ page } });
  };

  return (
    <div className="allowed_list_header ">
      <div className="allowed_list_title">{t('_allowed_list')}</div>

      {isMobile ? (
        <div className="filter-btns">
          <button className="button-filter-allowed" onClick={() => setIsActive(true)}>
            <FontAwesomeIcon icon={faFilter} /> {t('_filter')}
          </button>

          <div className="filter-link-btn">
            <button className="add-btn" onClick={() => setOpenModal(true)}>
              <FontAwesomeIcon icon={faAdd} className="icon" /> {t('_create_allowed_list')}
            </button>
          </div>
        </div>
      ) : null}
      <div className={`allowed-filter ${isMobile && !isActive ? 'hidden-filter-allowed' : 'show-filter-allowed'}`}>
        {isMobile ? (
          <div className="close-btn">
            <FontAwesomeIcon
              icon={faXmark}
              onClick={() => {
                setIsActive(false);
              }}
              className="close-btn-icon"
            />
          </div>
        ) : null}
        <AllowedListFilter
          setOpenModal={setOpenModal}
          clients={clients}
          setParams={setParams}
          setForcePage={setForcePage}
          setIsActive={setIsActive}
          providers={providers}
        />
      </div>

      <div className="table-wrapper">
        {loading ? (
          <Spinner />
        ) : (
          <table {...getTableProps()}>
            <thead className="thead">
              {headerGroups.map((headerGroup, key) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={key} className="row">
                  {headerGroup.headers.map((column, index) => (
                    <th
                      {...column.getHeaderProps({
                        style: { minWidth: column.minWidth, width: column.width, maxWidth: column.maxWidth },
                      })}
                      key={index}
                      className="heading"
                    >
                      <div className="table-title">{column.render('Header')}</div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            {loading && data.length ? (
              <tbody>
                <tr>
                  <td colSpan={Object.keys(rows[0].original).length}>
                    <Spinner class="extra-small" />
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody {...getTableBodyProps} className="body">
                {data.length ? (
                  rows.map((row) => {
                    prepareRow(row);
                    const { key, ...restRowProps } = row.getRowProps();

                    return (
                      <React.Fragment key={key}>
                        <tr key={key} {...restRowProps} className="cell-row">
                          {row.cells.map((cell) => {
                            const { key, ...restCellProps } = cell.getCellProps();

                            return (
                              <td key={key} {...restCellProps} className="cell">
                                {cell.render('Cell')}
                              </td>
                            );
                          })}
                        </tr>
                        {row.isExpanded ? TableMoreInformation({ row, hiddenColumns: hiddenColumns, extraFieldsNumber: 2 }) : null}
                      </React.Fragment>
                    );
                  })
                ) : (
                  <tr className="notFond">
                    <td colSpan={5}>{`${t('_no_data_found')}`}</td>
                  </tr>
                )}
              </tbody>
            )}
          </table>
        )}
        {pageCount > 1 && !loading ? (
          <Pagination pageCount={pageCount} forcePage={forcePage} handlePageClick={handlePageClick} hiddenParamPage />
        ) : null}
      </div>
      <Modal
        shouldCloseOnOverlayClick={false}
        openModal={openModal}
        setOpenModal={setOpenModal}
        childrenComponent={
          <CreateAllowedList
            setOpenModal={setOpenModal}
            setSubmit={setSubmit}
            clients={clients}
            providers={providers}
            loading={loading}
            countries={countries}
            commercialZones={commercialZones}
          />
        }
      />
      <Modal
        openModal={openModalDelete}
        setOpenModal={setOpenModalDelete}
        childrenComponent={
          <ModalDelete
            setOpenModalDelete={setOpenModalDelete}
            setSubmit={setSubmit}
            id={deleteId}
            link={http.allowedList.deleteAllowedPart}
          />
        }
      />
    </div>
  );
};

export default AllowedList;
