import React from 'react';

import { IClientInvitation } from 'models/client.model';
import ClientsInvitationsTableView from './ClientsInvitationsTable.view';
import { useNavigate } from 'react-router-dom';

type Props = {
  invitations: IClientInvitation[];
  fetchData?
};

const ClientsInvitationsTable: React.FC<Props> = (props) => {
  const navigate = useNavigate();

  const handleClientClick = (key: React.Key) => {
    const invitationId = props.invitations[key.toString().replace('row_', '')].id;

    navigate(`/espace-clients/my-clients/invitations/${invitationId}`);
  };

  return <ClientsInvitationsTableView invitations={props.invitations} handleClientClick={handleClientClick} fetchData={props.fetchData}></ClientsInvitationsTableView>;
};

ClientsInvitationsTable.displayName = 'ClientsInvitationsTable';

export default ClientsInvitationsTable;
