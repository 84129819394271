import React from 'react';
import DiagnosticItemView from './DiagnosticItem.view';

type Props = {
    selected?: boolean | undefined;
    name: string;
    buttonName: string;
    onClick : () => void;
    disabled: boolean;
}

const DiagnosticItem: React.FC<Props> = (props) => {

  return (
    <DiagnosticItemView disabled={props.disabled} selected={props.selected} name={props.name} buttonName={props.buttonName} onClick={props.onClick}/>
  );
};


DiagnosticItem.displayName = 'DiagnosticItem';

export default DiagnosticItem;