import { faCircleArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import MainGroup from './CollapseList/MainGroup';
import './ModulesMobileCardLevel4.scss';

type Props = {
  onMainGroupClick?: (item) => void;
  onSubGroupClick?: (item) => void;
  onItemMpClick?: (item) => void;
  data
  title: string
  setOpenModal?: (value: boolean) => void;
  onItemMpManualClick?: (value: boolean) => void;
  activeItem?: string | number
};

const ModulesMobileCardLevel4: React.FC<Props> = (props: Props) => {
  const navigate = useNavigate();

  function onReturnClick() {
    navigate(-1);
  }

  return (
    <div className="modules-mobile-card">
      <div className="header">
        {/*<span className="return-button" onClick={onReturnClick}>*/}
        {/*  <FontAwesomeIcon icon={faCircleArrowLeft} className="icon" />*/}
        {/*</span>*/}
        <div className="title">{props.title}</div>
      </div>
      <div className="content">
        <div className="items">
          {props.data?.map((item, key) => {
            return (
              <MainGroup key={key} data={item}
                onMainGroupClick={props.onMainGroupClick}
                onSubGroupClick={props.onSubGroupClick}
                onItemMpClick={props.onItemMpClick}
                setOpenModal={props.setOpenModal}
                onItemMpManualClick={props.onItemMpManualClick}
                activeItem={props.activeItem}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ModulesMobileCardLevel4;
