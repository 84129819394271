import React, { MutableRefObject, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import Select, {components, ValueContainerProps} from 'react-select';
import { Control, Controller, FieldValues, UseFormRegister } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import './StatisticsFilter.scss';
import ReactDatePicker from 'react-datepicker';
import i18n from 'i18n';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import moment from 'moment';
import TextInput from 'components/inputs/TextInput/TextInput';
import { useOnClickOutside } from 'utils/hooks/useOnClickOutside';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faFileExport, faFileUpload, faRefresh, faSearch } from '@fortawesome/free-solid-svg-icons';
import useHttp from 'utils/hooks/useHttp';
import { IClientOption } from 'interfaces/select';

type Props = {
    control?: Control
    register: UseFormRegister<FieldValues>
    getValues?
    handleClick: () => void
    handleReset: () => void
    setValueDate: (date) => void
    valueDate: string
    loadingExport: boolean
    exportStats: () => void
    exportFileLoading: boolean
    exportFile: () => void
}

const StatisticsFilter: React.FC<Props> = (props) => {
  const {t} = useTranslation();
  const {isMobile} = useDeviceDetect();
  const http = useHttp();

  const currentDate = new Date();
  const pastDate = new Date(currentDate.getTime() - (7 * 24 * 60 * 60 * 1000));
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [openDate, setOpenDate] = useState(false);

  const [modules, setModules] = useState<IClientOption[]>([]);
  const [clients, setClients] = useState<IClientOption[]>([]);

  const dateRef = useRef() as MutableRefObject<HTMLDivElement>;

  const CHART_OPTIONS = [
    {label: t('_chart_by_module'), value: 0},
    {label: t('_chart_by_date'), value: 1},
  ];

  useOnClickOutside(dateRef, () => setOpenDate(false));

  function getModulesOptions(){
    http.statistics.getModules().then((res)=>{
      setModules(res.data);
    });
  }

  function getClientsOptions(){
    http.statistics.getClients().then((res)=>{
      setClients(res.data);
    });
  }

  function onDateChange(dates) {
    const [start, end] = dates;

    setStartDate(start);
    setEndDate(end);

    if (start && end) {
      props.setValueDate(`${moment(start).format('YYYY-MM-DD')} - ${moment(end).format('YYYY-MM-DD')}`);
    }
    else {

      if (start) {
        props.setValueDate(`${moment(start).format('YYYY-MM-DD')}`);

      }

      if (end) {
        props.setValueDate(`${moment(end).format('YYYY-MM-DD')}`);

      }
    }
  }

  useEffect(()=>{
    getModulesOptions();
    getClientsOptions();
  },[]);

  useEffect(()=>{
    if(props.getValues()?.chart?.value === 1){
      setStartDate(pastDate);
      setEndDate(currentDate);
    } else {
      setStartDate(null);
      setEndDate(null);
      props.setValueDate('');
    }
  },[props.getValues()?.chart?.value]);

  const getOptions = (arr) => {
    return (
      arr?.map((item) => ({
        label: item.name,
        value: item.id,
      })) || []
    );
    
  };

  const CheckboxOption = (props) => {
    return (
      <div className="stats-checkbox-option">
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => props.selectOption(props.data)}
        />
        <label>{props.label}</label>
      </div>
    );
  };

  const MultiValue = (props) => {
    return (
      <components.MultiValue className="multi-value" {...props}>
        {props.children}
      </components.MultiValue>
    );
  };

  const ValueContainer = useCallback((props: ValueContainerProps) => {
    const length = props.children![0]?.length;
    const tmpChildren = [...props.children as Array<ReactNode>];

    if(length >= 3){
      tmpChildren[0] = `${length} selected`;
    }

    return <components.ValueContainer {...props}>{tmpChildren}</components.ValueContainer>;
  }, []);
  
  const customComponents = {
    Option: CheckboxOption,
    ValueContainer,
    MultiValue,
    MultiValueRemove: () => null
  };

  const customStyles = {
    multiValue: (provided) => ({
      ...provided,
      background: '#D5E2FC',
      color: 'black',
    }),
    valueContainer: (provided) =>({
      ...provided,
      flexWrap: 'nowrap'
    }),
    placeholder: (provided)=>({
      ...provided,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    }),
    menuPortal: base => ({ ...base, zIndex: 150 }) 
  };

  return (
    <div className="stats-filter">
      <div className="stats-filter-item">
        <label className="stats-filter-label">{t('_module_service_name')}</label>
        <Controller
          control={props.control}
          name="module_name"
          render={({ field: { onChange, value } }) => (
            <Select
              value={value}
              isMulti
              className="stats-select-dropdown"
              onChange={(val) => {
                onChange(val);
              }}
              options={getOptions(modules)}
              placeholder={t('_module_service_name')}
              menuPlacement="auto"
              isClearable={false}
              isSearchable
              menuPosition="fixed"
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              components={customComponents}
              styles={customStyles}
            />
          )}
        />
      </div>
      <div className="stats-filter-item">
        <label className="stats-filter-label">{t('_client')}</label>
        <Controller
          control={props.control}
          name="client"
          render={({ field: { onChange, value } }) => (
            <Select
              value={value}
              isMulti
              className="stats-select-dropdown"
              onChange={(val) => {
                onChange(val);
              }}
              options={getOptions(clients)}
              placeholder={t('_client')}
              menuPlacement="auto"
              isClearable={false}
              isSearchable
              menuPosition="fixed"
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              components={customComponents}
              styles={customStyles}
            />
          )}
        />
      </div>
      <div className="stats-filter-item">
        <label className="stats-filter-label">{t('_date')}</label>
        <div className={`stats-react-picker-dropdown ${openDate ? 'disable' : ''}`} onClick={() => setOpenDate(true)}>
          <TextInput disabled={openDate} placeholder={t('_select_date')} value={props.valueDate || ''} />
        </div>
        {openDate && (
          <div className="stats-date-picker" ref={dateRef}>
            <ReactDatePicker
              {...props.register('date')}
              dateFormat="YYYY-MM-DD"
              selected={startDate}
              onChange={onDateChange}
              startDate={startDate}
              endDate={endDate}
              monthsShown={isMobile ? 1 : 2}
              maxDate={new Date()}
              selectsRange
              inline
              locale={i18n.language.split('-')[0]}
            />
          </div>
        )}
      </div>
      <div className="stats-filter-item">
        <label className="stats-filter-label">{t('_filter_chart')}</label>
        <Controller
          control={props.control}
          name="chart"
          render={({ field: { onChange, value } }) => (
            <Select
              value={value}
              className="stats-select-dropdown"
              onChange={(val) => {
                onChange(val);
              }}
              options={CHART_OPTIONS}
              placeholder={t('_filter_chart')}
              menuPlacement="auto"
              isClearable={false}
              isSearchable={false}
              menuPosition="fixed"
              styles={{ menuPortal: base => ({ ...base, zIndex: 150 }) }}
            />
          )}
        />
      </div>
      <div className="stats-btns">
        <div className="stats-btn">
          <DefaultButton
            text={<FontAwesomeIcon className="button-text" icon={faRefresh} />}
            red
            onClick={()=> {
              props.handleReset();
              setStartDate(null);
              setEndDate(null);
            }}
          />
        </div>
        <div className="stats-btn">
          <DefaultButton text={<FontAwesomeIcon className="button-text" icon={faSearch} />} onClick={()=> props.handleClick()} />
        </div>
        <div className="stats-btn">
          <DefaultButton text={<FontAwesomeIcon className="button-text" icon={faDownload} />} onClick={props.exportStats} isloading={props.loadingExport}  />
        </div>
        <div className="stats-btn">
          <DefaultButton text={<div className="button-text"><FontAwesomeIcon icon={faFileExport} /> {t('_plate_statistics')} </div>} onClick={props.exportFile} isloading={props.exportFileLoading}  /> 
        </div>
      </div>
    </div>
  );
};

export default StatisticsFilter;