import { IItemClick } from 'models/catalog.model';
import React from 'react';

import ListView from './List.view';

type Props = {
  title?;
  content;
  images?: string[];
  className?: string;
  itemClassName?: string;
  onItemClick?: (item: IItemClick) => void;
  showPdf?: boolean;
  activeCategory?
  itemId?: string;
};

const List: React.FC<Props> = (props: Props) => {
  return (
    <ListView
      itemClassName={props.itemClassName}
      onItemClick={props.onItemClick}
      className={props.className}
      title={props.title}
      images={props.images}
      content={props.content}
      showPdf={props.showPdf}
      activeCategory={props.activeCategory}
      itemId={props.itemId}
    ></ListView>
  );
};

List.displayName = 'List';

export default List;
