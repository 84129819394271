import React from 'react';

import './FavoritePartTab.scss';

import {FavoriteContextProvider} from '../../../../context/FavoriteContext';
import FavoritePartTabContent from './FavoritePartTabContent/FavoritePartTabContent';

type Props = {

}

const FavoritePartTab: React.FC<Props> = () => {

  return (
    <FavoriteContextProvider>
      <FavoritePartTabContent/>
    </FavoriteContextProvider>
  );

};

FavoritePartTab.displayName = 'FavoritePartTab';

export default FavoritePartTab;
