import React, { useEffect, useState } from 'react';
import { ISuppliersAmerigo } from 'models/cart.model';
import './Cart.scss';
import { SwiperSlide } from 'swiper/react';
import SwiperSlider from '../../components/SwiperSlider/SwiperSlider';
import Spinner from 'components/spinner/Spinner';
import { useTranslation } from 'react-i18next';
import Content from './Content/Content';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import CartTable from './CartTable/CartTable';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';

type Props = {
  tabs: ISuppliersAmerigo[];
  loading?: boolean
  getSuppliers?
};


const CartView: React.FC<Props> = (props: Props) => {
  const [tabIndex, setTabIndex] = useState(0);
  const { t } = useTranslation();
  const { isMobile } = useDeviceDetect();

  const handleNextClick = () => {
    if (tabIndex + 1 <= props.tabs.length) {
      setTabIndex(tabIndex + 1);
    }
  };

  const handlePrevClick = () => {
    if (tabIndex - 1 >= 0) {
      setTabIndex(tabIndex - 1);
    }
  };

  useEffect(()=>{
    if(tabIndex > props.tabs.length){
      setTabIndex(0);
    }
  },[tabIndex, props.tabs.length]);

  return (
    <div className="cart-view-container">
      {props.loading ? <Spinner class="tabs-spinner" /> : (
        <React.Fragment>
          {props.tabs.length ? <p className="cart-title">{t('_your_shopping_carts')}</p> : null}
          {props.tabs.length !== 0 ? (
            <Tabs className="tabs" selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
              <TabList className="tab-list">
                {
                  isMobile ? (
                    <SwiperSlider
                      allowTouchMove={false}
                      slidesPerView="auto"
                      spaceBetween={0}
                      navigation
                      handleNextClick={handleNextClick}
                      handlePrevClick={handlePrevClick}
                    >
                      <SwiperSlide className="swiper-slide">
                        <Tab className="tab">{t('_multi_suppliers')}</Tab>
                      </SwiperSlide>

                      {
                        props.tabs.map((item, index: number) => {
                          return (
                            <SwiperSlide className="swiper-slide" key={index}>
                              <Tab key={item.value} className="tab">{item.label}</Tab>
                            </SwiperSlide>
                          );
                        }
                        )}
                    </SwiperSlider>
                  ) : (
                    <React.Fragment>
                      <Tab className="tab">{t('_multi_suppliers')}</Tab>
                      {props.tabs.length && props.tabs.map(tab => (
                        <Tab key={tab.value} className="tab">{tab.label}</Tab>
                      ))}
                    </React.Fragment>
                  )
                }
              </TabList>
              <TabPanel><CartTable getSuppliers={props.getSuppliers}></CartTable></TabPanel>
              {props.tabs.length && props.tabs.map(tab => (
                <TabPanel key={tab.value}><Content getSuppliers={props.getSuppliers} setTabIndex={setTabIndex} dlnr={tab.value} supplierName={tab.label} /></TabPanel>
              ))}
            </Tabs>
          ) : (
            <div className="empty_cart">
              <div className="empty_cart_image">
                <img src={require('assets/images/empty_cart.png')} alt="empty_cart" />
              </div>
              <div className="empty_cart_title">{t('_your_shopping_carts_empty')}</div>
            </div>
          )}
        </React.Fragment>
      )}
    </div>

  );
};

CartView.displayName = 'Cartiew';

export default CartView;
