import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import { ICatalogModels, IFuelType, IManufacturers, IVersion } from 'interfaces/vehicleSelector';

import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { createSearchParams, generatePath, useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import { getCurrentCatalog } from 'store/selectors/catalogs.selector';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import useHttp from 'utils/hooks/useHttp';
import '../VehicleContent.scss';
import { CAR_IDENTIFIER } from 'utils/constants/defaultConstants';

type Props = {};

const CatalogCarSelector: React.FC<Props> = () => {
  const { control, getValues, handleSubmit, reset, setValue } = useForm();
  const http = useHttp();
  const navigate = useNavigate();
  const { id } = useParams();
  const { isMobile, isTablet } = useDeviceDetect();
  const currentCatalog = useSelector(getCurrentCatalog);
  const [submitDisabled, setSubmitDisabled] = useState(true);

  const [manufacturers, setManufacturers] = useState<IManufacturers[]>([]);
  const [manufacturersLoading, setManufacturersLoading] = useState(false);

  const [fuelType, setFuelType] = useState<IFuelType[]>([]);
  const [fuelTypeLoading, setFuelTypeLoading] = useState(false);

  const [versions, setVersions] = useState<IVersion[]>([]);
  const [versionsLoading, setVersionsLoading] = useState(false);

  const [models, setModels] = useState<ICatalogModels[]>([]);
  const [modelsLoading, setModelsLoading] = useState(false);
  const { t } = useTranslation();

  function getData(request, setLoading) {
    setLoading(true);

    return request
      .then((res) => res.data)
      .finally(() => {
        setLoading(false);
      });
  }

  const manufacturerOptions = () => {
    return (
      manufacturers?.map((item) => ({
        label: item.description,
        value: item.id,
        quantity: item.quantity,
      })) || []
    );
  };

  const modelOptions = () => {
    return (
      models?.map((item) => ({
        label: item.description,
        value: item.id,
        year_from: item.year_from,
        year_to: item.year_to,
      })) || []
    );
  };

  const fuelOptions = () => {
    return (
      fuelType?.map((item) => ({
        label: item.description,
        value: item.id,
      })) || []
    );
  };

  const versionOptions = () => {
    return (
      versions?.map((item) => ({
        label: `${item.shortName} ${item.name} ${item.engineCapacity} ${item.capacityQualifier} ${item.startMonth}/${item.startYear} - ${item.endMonth}/${item.endYear} ${item.fapDescription} ${item.nbOfCylinder} - ${item.kwPower} KW - ${item.horsePower} CH DIN`,
        value: item.id,
      })) || []
    );
  };

  const handleManufacturerChange = (val) => {
    getData(http.vehicleSelectorCatalog.getCarModels(val, id!), setModelsLoading).then((models) => setModels(models));
    setValue('model', '');
    setValue('fuel', '');
    setValue('version', '');
    setModels([]);
    setFuelType([]);
    setVersions([]);
    setSubmitDisabled(true);
  };

  const handleModelChange = (val) => {
    if (currentCatalog?.catalog_type === 'TECDOC_SUPPLIER') {
      getData(http.vehicleSelectorCatalog.getCarFuel(val, id!), setFuelTypeLoading).then((fuels) => setFuelType(fuels));
      getData(http.vehicleSelectorCatalog.getCarVersion(val, id!), setVersionsLoading).then((versions) => setVersions(versions));
      // reset({ fuel: '', version: '' });
      // reset({ fuel: '', version: '' });
      setValue('fuel', '');
      setValue('version', '');
      setVersions([]);
      setSubmitDisabled(true);
    }
  };

  useEffect(() => {
    getData(http.vehicleSelectorCatalog.getCarManufacturers(id!), setManufacturersLoading).then((manufacturers) => {
      setManufacturers(manufacturers);
    });
  }, []);

  const submit = () => {
    if (!getValues().manufacturer) { return; }

    const searchParams = createSearchParams([
      ['manufacturer_id', String(getValues().manufacturer.value)],
      ['model_id', String(getValues().model.value)],
      ['ktypnr', String(getValues().version?.value)],
      ['vehicle_identifier', String(CAR_IDENTIFIER)],
      ['source', 'T'],
      ['page', '1'],
    ]);

    if (currentCatalog?.catalog_type === 'TECDOC_SUPPLIER') {
      String(getValues().fuel?.value) ? searchParams.append('fuel_id', String(getValues().fuel?.value)) : null;
      //searchParams.append('ktypnr', String(getValues().version?.value));

    }

    navigate({
      pathname: generatePath('/catalog/:id/categories', {
        id: String(currentCatalog?.id),
      }),
      search: `?${searchParams}`,
    });
  };

  return (
    <form className="dropdowns-container" onSubmit={handleSubmit(submit)}>
      <div className="dropdown-item">
        <label className="dropdown-label">{t('_manufacturer')}</label>
        <Controller
          control={control}
          name="manufacturer"
          render={({ field: { onChange, value } }) => (
            <Select
              value={value}
              className={`select-dropdown ${!manufacturers.length ? 'disabled' : ''}`}
              onChange={(val) => {
                handleManufacturerChange(val);
                onChange(val);
              }}
              options={manufacturerOptions()}
              isSearchable={!isMobile && !isTablet}
              placeholder={manufacturersLoading ? t('_loading') : t('_manufacturer')}
              styles={{
                menu: provided => ({ ...provided, zIndex: 9999 })
              }}
            />
          )}
        />
      </div>
      <div className="dropdown-item">
        <label className="dropdown-label">{t('_model')}</label>
        <Controller
          control={control}
          name="model"
          render={({ field: { onChange, value } }) => (
            <Select
              value={value}
              className={`select-dropdown ${!models.length ? 'disabled' : ''}`}
              onChange={(val) => {
                onChange(val);
                handleModelChange(val);
              }}
              options={modelOptions()}
              isSearchable={!isMobile && !isTablet}
              placeholder={modelsLoading ? t('_loading') : t('_model')}
              styles={{
                menu: provided => ({ ...provided, zIndex: 9999 })
              }}
            />
          )}
        />
      </div>
      {currentCatalog?.catalog_type === 'TECDOC_SUPPLIER' ? (
        <React.Fragment>
          <div className="dropdown-item">
            <label className="dropdown-label">{t('_fuel_type')}</label>
            <Controller
              control={control}
              name="fuel"
              render={({ field: { onChange, value } }) => (
                <Select
                  value={value}
                  className={`select-dropdown ${!fuelType.length ? 'disabled' : ''}`}
                  onChange={(val) => {
                    onChange(val);
                  }}
                  options={fuelOptions()}
                  isSearchable={!isMobile && !isTablet}
                  placeholder={fuelTypeLoading ? t('_loading') : t('_fuel_type')}
                  styles={{
                    menu: provided => ({ ...provided, zIndex: 9999 })
                  }}
                />
              )}
            />
          </div>
          <div className="dropdown-item">
            <label className="dropdown-label">{t('_version')}</label>
            <Controller
              control={control}
              name="version"
              render={({ field: { onChange, value } }) => (
                <Select
                  value={value}
                  className={`select-dropdown ${!versions.length ? 'disabled' : ''}`}
                  onChange={(val) => {
                    onChange(val);
                    setSubmitDisabled(false);
                  }}
                  options={versionOptions()}
                  isSearchable={!isMobile && !isTablet}
                  placeholder={versionsLoading ? t('_loading') : t('_version')}
                  styles={{
                    menu: provided => ({ ...provided, zIndex: 9999 })
                  }}
                />
              )}
            />
          </div>
        </React.Fragment>
      ) : null}

      <div className="catalog-car-selector-btn ">
        <DefaultButton disabled={submitDisabled} text={t('_send')} type="submit" />
      </div>
    </form>
  );
};

export default CatalogCarSelector;