import { BackendModule } from 'i18next';

const LazyImportPlugin: BackendModule = {
  type: 'backend',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  init: function () {},
  read: function (language, namespace, callback) {
    if (language == 'dev') {
      return;
    }

    const languageArray = language.split('-');

    import(/* webpackChunkName: "i18n/[request]" */ `./${languageArray[0]}.json`).then((obj) => {
      callback(null, obj);
    });
  },

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  // save: function (language, namespace, data) {},

  // create: function (languages, namespace, key, fallbackValue) {
  /* save the missing translation */
  // },
};

export default LazyImportPlugin;
