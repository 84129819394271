import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { IData, IDataResponse, IStatus } from 'models/order';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { getURLParams } from 'utils/marketplace';
import { Controller, useForm } from 'react-hook-form';
import useHttp from 'utils/hooks/useHttp';
import SellersTable from 'components/tables/SellersTable/SellersTable';
import TextInput from 'components/inputs/TextInput/TextInput';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import LinkButton from 'components/buttons/LinkButton/LinkButton';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import { useOnClickOutside } from 'utils/hooks/useOnClickOutside';
import moment from 'moment';

import 'react-datepicker/dist/react-datepicker.css';
import './Orders.scss';
import { useTranslation } from 'react-i18next';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import { useLocaleDatePicker } from 'utils/localeDatePicker';
import i18n from 'i18n';

type Props = {
  catalogId?: string;
};
interface IURLParams {
  page?: number;
}
const Orders: React.FC<Props> = (props) => {
  const { control, getValues, register, handleSubmit, reset } = useForm();
  const http = useHttp();
  const urlParams: IURLParams = getURLParams(location.search);

  const [data, setData] = useState<IData[]>([]);
  const [paginationData, setPaginationData] = useState<IDataResponse>();
  const [status, setStatus] = useState<IStatus>();

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalPage, setTotalPage] = useState<number>(0);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [valueDate, setValueDate] = useState('');

  const [openDate, setOpenDate] = useState(false);

  const [loading, setLoading] = useState(false);
  const dateRef = useRef() as MutableRefObject<HTMLDivElement>;
  const { t } = useTranslation();
  const { isMobile, isTablet } = useDeviceDetect();

  useOnClickOutside(dateRef, () => setOpenDate(false));
  useLocaleDatePicker(i18n.language);

  function getOrderList(params) {
    setLoading(true);

    let newParams;

    if (params.page) {
      newParams = { ...params, catalog_id: props.catalogId };
    } else {
      newParams = { ...params, catalog_id: props.catalogId, page: urlParams.page ?? 1 };
    }

    http.order
      .getOrders(newParams)
      .then((res) => {
        const { data, page, total_pages } = res.data as IDataResponse;

        setData(data);
        setPaginationData({ data, page, total_pages });
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function getStatus() {
    setLoading(true);
    http.order
      .getOrdersStatus()
      .then((res) => setStatus(res.data))
      .finally(() => {
        setLoading(false);
      });
  }

  function getStatusOptions() {
    return status ? Object.entries(status).map(([value, label]) => ({ value, label })) : [];
  }

  useEffect(() => {
    getOrderList({ page: urlParams.page ?? 1, catalog_id: props.catalogId });
    getStatus();
  }, []);

  useEffect(() => {
    if (paginationData) {
      setCurrentPage(paginationData.page);
      setTotalPage(paginationData.total_pages);
    }
  }, [paginationData]);

  const onSubmit = async () => {
    setLoading(true);
    const data = getValues();

    if (data.status) {
      data.status = data.status.label;
    }

    const start_date = moment(startDate).format('YYYY-MM-DD');
    const end_date = moment(endDate).format('YYYY-MM-DD');

    if (moment(start_date).isValid()) {
      data.start_date = moment(startDate).format('YYYY-MM-DD');
    }

    if (moment(end_date).isValid()) {
      data.end_date = moment(endDate).format('YYYY-MM-DD');
    }


    const validParams = Object.entries(data).reduce((acc, [key, value]) => {
      if (!value) {
        return acc;
      }

      acc[key] = value;

      return acc;
    }, {});

    await getOrderList(validParams);
  };

  const handlePageClick = async (data: number) => {
    setCurrentPage(data);
    setLoading(true);
    const selectPage = data;
    const params = getValues();

    if (params.status) {
      params.status = params.status.label;
    }

    await getOrderList({ ...params, page: selectPage });
  };

  function onDateChange(dates) {
    const [start, end] = dates;

    setStartDate(start);
    setEndDate(end);

    if (start && end) {
      setValueDate(`${moment(start).format('DD/MM/YYYY')} - ${moment(end).format('DD/MM/YYYY')}`);
    }
    else {

      if (start) {
        setValueDate(`${moment(start).format('DD/MM/YYYY')}`);

      }

      if (end) {
        setValueDate(`${moment(end).format('DD/MM/YYYY')}`);

      }
    }
  }

  const handleResetFilter = () => {
    reset({ status: '', date: '', id: '', system_order_id: '', customer_id: '' });
    setStartDate(null);
    setEndDate(null);
    setValueDate('');
  };

  return (
    <div className="orders-page">
      <div className="orders-filter">
        <div className="orders-filter-header">
          <div className="orders-filter-link-btn">
            <LinkButton title={t('_my_recurring_orders')} icon={faStar} />
          </div>
          <div className="filter-title">{t('_order_search')}</div>
          <div className="filter-reliquats">
            <input type="checkbox" />
            {t('_only_leftovers')}
          </div>
        </div>
        <form onSubmit={handleSubmit(() => onSubmit())}>
          <div className="orders-filter-body">
            {/* <div className="orders-filter-input-item">
              <Controller
                control={control}
                name="status"
                render={({ field: { onChange, value } }) => {
                  return (
                    <Select
                      value={value}
                      className="select-dropdown"
                      onChange={(value) => onChange(value)}
                      options={getStatusOptions()}
                      placeholder={t('_status')}
                      isSearchable={!isMobile && !isTablet}
                      styles={{
                        menu: provided => ({ ...provided, zIndex: 50 })
                      }}
                    />
                  );
                }}
              />
            </div> */}
            <div className="orders-filter-input-item">
              <div className={`orders-react-picker-dropdown ${openDate ? 'disable' : ''}`} onClick={() => setOpenDate(true)}>
                <TextInput readOnly placeholder={t('_select_date')} defaultValue={valueDate ? valueDate : ''} />
              </div>
              {openDate && (
                <div className="orders-date-picker" ref={dateRef}>
                  <DatePicker
                    {...register('date')}
                    dateFormat="YYYY-MM-DD"
                    selected={startDate}
                    onChange={onDateChange}
                    startDate={startDate}
                    endDate={endDate}
                    monthsShown={isMobile ? 1 : 2}
                    selectsRange
                    inline
                    locale={i18n.language.split('-')[0]}
                  />
                </div>
              )}
            </div>
            <div className="orders-filter-input-item">
              <TextInput {...register('id')} placeholder={t('_by_id')} />
            </div>
            <div className="orders-filter-input-item">
              <TextInput {...register('system_order_id')} placeholder={t('_by_system_af_id')} />
            </div>
            <div className="orders-filter-input-item">
              <TextInput {...register('customer_id')} placeholder={t('_by_client_id')} />
            </div>
            <DefaultButton text={t('_filter')} type="submit" />
            <DefaultButton red text={t('_reset')} onClick={handleResetFilter} />
          </div>
        </form>
      </div>
      <div className="orders-table">
        <SellersTable data={data} handlePageClick={handlePageClick} currentPage={currentPage} totalPage={totalPage} loading={loading} />
      </div>
    </div>
  );
};

export default Orders;
