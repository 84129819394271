import React, { useState } from 'react';
import EspaceReliableNavbarBelow from './espace-reliable-navbar/espace-reliable-navbar-bellow/EspaceReliableNavbarBelow';
import EspaceReliableNavbarGroupBtn from './espace-reliable-navbar/espace-reliable-navbar-group-btn/EspaceReliableNavbarGroupBtn';
import EspaceReliableNavbar from './espace-reliable-navbar/EspaceReliableNavbar';

import CategoriesSidebar from './CategoriesSidebar/CategoriesSidebar';
import EbooksSidebar from './EbooksSidebar/EbooksSidebar';
import UploadProforma from '../../../../amerigo/views/Proforma/UploadProforma';
import NewClientModal from './NewClientModal/NewClientModal';
import EspaceReliableNavbarCatalogNaf from './espace-reliable-navbar/espace-reliable-navbar-catalog-naf/EspaceReliableNavbarCatalogNaf';

import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import EspaceReliableNavbarMobile from './espace-reliable-navbar/Mobile/EspaceReliableNavbarMobile';
import EspaceAutomotorNavbar from 'applications/automotor/layouts/espace-automotor-layout/espace-automotor-navbar/EspaceAutomotorNavbar';
import AutomotorMenuNavbar from 'applications/automotor/layouts/espace-automotor-layout/automotor-menu-navbar/AutomotorMenuNavbar';

const NavBarAutomotor: React.FC = () => {
  const [showDetailsState, setShowDetailsState] = useState(false);
  const [showCategoryState, setShowCategoriesState] = useState(false);
  const [showEbooks, setShowEbooks] = useState(false);
  const { isMobile, isTablet } = useDeviceDetect();

  const handleReturnButtonClick = () => {
    setShowDetailsState(() => false);
  };

  const handleCloseButtonClick = () => {
    setShowCategoriesState(() => false);
  };

  const handleCloseEbook = () => {
    setShowEbooks(() => false);
  };

  return (
    <div>
      {/* {isMobile || isTablet ? <EspaceReliableNavbarMobile /> : <EspaceReliableNavbar />} */}
      {isMobile || isTablet ? <EspaceReliableNavbarMobile /> : <EspaceAutomotorNavbar />}
      {isMobile || isTablet ? null : <AutomotorMenuNavbar />}
      <EspaceReliableNavbarBelow />
      <EspaceReliableNavbarCatalogNaf />
      <UploadProforma />
      <EspaceReliableNavbarGroupBtn />
      <NewClientModal />
      <CategoriesSidebar
        onCloseButtonClick={handleCloseButtonClick}
        onReturnButtonClick={handleReturnButtonClick}
        showDetails={showDetailsState}
        showCategories={showCategoryState}
      ></CategoriesSidebar>
      <EbooksSidebar showEbooks={showEbooks} handleCloseEbook={handleCloseEbook} />
    </div>
  );
};

NavBarAutomotor.displayName = 'NavBarAutomotor';

export default NavBarAutomotor;
