import React from 'react';
import TabCardView from './TabCard.view';

type Props = {
  type: string;
  img?: string;
  date: string;
  title: string;
  description: string;
  model: string;
  modelDescription: string;
  card?;
};

const TabCard: React.FC<Props> = (props: Props) => {
  return (
    <TabCardView
      type={props.type}
      card={props.card}
      img={props.img}
      date={props.date}
      title={props.title}
      description={props.description}
      model={props.model}
      modelDescription={props.modelDescription} 
    />
  );
};

TabCard.displayName = 'TabCard';

export default TabCard;
