import i18n from 'i18n';
import { IVehicleManualList, IVehicleManualSubGroups } from 'interfaces/vehicle-manuals.interface';
import { isEmpty } from 'underscore';
import { CONSUMABLES_GROUP_ID, ERROR_CODE_GROUP_ID } from 'utils/constants/defaultConstants';
import { RELIABLE_CONTENT_TABS } from 'utils/rmi/reliableTabs';
import { getCurrentCountryRMI, getCurrentLanguageRMI } from 'utils/rmi/rmi-utils/rmi-utils';
import useHttp from 'utils/hooks/useHttp';


export function useHasTecrmiTabs(): { hasTecRMITabs: (number) => Promise<{ [key: string]: boolean; }> } {
  const http = useHttp();

  async function hasTecrmiTabs(typeId: number): Promise<{ [key: string]: boolean; }> {

    const params = {
      typeId: Number(typeId),
      countryCode: getCurrentCountryRMI(i18n.language),
    };

    const hasTabs: { [key: string]: boolean; } = {};

    const results = await Promise.allSettled([
      http.hasTecRMITabs.fetchRMIVehicleHasRepairItems(params),
      http.hasTecRMITabs.fetchRMIVehicleHasMaintenance(params),
      http.hasTecRMITabs.fetchRMIVehicleHasAdjust(params),
      http.hasTecRMITabs.fetchRMIVehicleHasManuals(params),
      http.hasTecRMITabs.fetchRMIVehicleHasTyres(params),
      http.hasTecRMITabs.fetchRMIVVehicleServiceNewsletterHasBulletins(params),
      http.hasTecRMITabs.fetchRMIVehicleHasRelaysFuses(params),
      http.hasTecRMITabs.fetchRMIVehicleHasComponents(params),
      http.hasTecRMITabs.fetchRMIVehicleHasDiagnostics(params),
      http.hasTecRMITabs.fetchRMIVehicleHasWiring(params),
      http.hasTecRMITabs.fetchRMIVehicleHasGraphics(params),
      http.hasTecRMITabs.fetchRMIVehicleManualsList({
        ...params,
        languageCode: getCurrentLanguageRMI(i18n.language),
      }),
      http.hasTecRMITabs.fetchRMIVehicleMainGroupData({
        ...params,
        languageCode: getCurrentLanguageRMI(i18n.language),
        mainGroupId: CONSUMABLES_GROUP_ID,
      }),
      // Promise.resolve({ data: true }),
    ]);

    const keys = Object.keys(results).map(k => Number(k));

    Object.values(results).forEach((res, index) => {

      if (res.status !== 'rejected') {
        //11th is the technical manuals request in Promise.allSettled 
        if (index == 11) {
          if (Array.isArray(res?.value?.data) && !isEmpty(res?.value?.data)) {
            res?.value?.data.forEach((mainGroup: IVehicleManualList) => {
              mainGroup.SubGroups.forEach((subGroup: IVehicleManualSubGroups) => {
                if (subGroup.SubGroupId == ERROR_CODE_GROUP_ID) {
                  hasTabs[RELIABLE_CONTENT_TABS[keys[index]].tab_name] = true;
                }
              });
            });
          } else {
            hasTabs[RELIABLE_CONTENT_TABS[keys[index]].tab_name] = false;
          }
        } else {
          if (Array.isArray(res?.value?.data)) {
            hasTabs[RELIABLE_CONTENT_TABS[keys[index]].tab_name] = !isEmpty(res?.value?.data);
          } else {

            hasTabs[RELIABLE_CONTENT_TABS[keys[index]].tab_name] = res?.value?.data;
          }
        }
      } else {
        hasTabs[RELIABLE_CONTENT_TABS[keys[index]].tab_name] = false;
      }

    });

    return hasTabs;
  }

  return { hasTecRMITabs: hasTecrmiTabs };
}

