import { RootState } from 'store/store';
import { createDeepEqualSelector } from './index';

export const carPartsSelector = (state: RootState): RootState['carParts'] => state.carParts;

export const getParts = createDeepEqualSelector(carPartsSelector, (carParts) => carParts.parts);

export const getIsLoadingParts = createDeepEqualSelector(carPartsSelector, (carParts) => carParts.isLoading);

export const getPage = createDeepEqualSelector(carPartsSelector, (carParts) => carParts.page);

export const getTotalPages = createDeepEqualSelector(carPartsSelector, (carParts) => carParts.total_pages);

export const getTotalCount = createDeepEqualSelector(carPartsSelector, (carParts) => carParts.total_count);
