import React, { PropsWithChildren, useState } from 'react';
import './DeleteConfirmationItemsModal.scss';
import { IAddToFavorite, IFavoriteList } from 'models/favorite';
import useHttp from 'utils/hooks/useHttp';
import { ICarPartData } from 'models/car-parts.model';
import { CATALOG_MANUFACTURER } from 'utils/constants/applications';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import { useTranslation } from 'react-i18next';
import { notify } from '../../../../../../../utils/marketplace';

type Props = {
  setOpenModal: (e: boolean) => void;
  favoriteList: IFavoriteList | undefined;
  checkedList: ICarPartData[];
  onDone: () => void;
};

const DeleteConfirmationItemsModal: React.FC<Props> = (props: PropsWithChildren<Props>) => {
  const [loading, isLoading] = useState<boolean>(false);
  const http = useHttp();
  const { t } = useTranslation();

  const deleteListItems = async () => {
    let params: IAddToFavorite[] | [] = [];

    props.checkedList.map((checkedListItem) => {
      const param: IAddToFavorite = {
        catalog_id: checkedListItem.catalog_id,
        cod_fou: checkedListItem.cod_fou,
        is_oe_part: checkedListItem?.catalog_type === CATALOG_MANUFACTURER,
        manufacturer_id: null,
        oe_reference: null,
        price: null,
        quantity: 1,
        supplier_id: checkedListItem.data_supplier.id,
        supplier_reference: checkedListItem.data_supplier_reference,
        source: checkedListItem.source
      };

      params = [...params, param];
    });

    try {
      isLoading(true);
      await http.favorite.deleteFavoriteListItems(params, String(props.favoriteList?.id));
      props.onDone();
    } catch (error) {
      notify(t('_error'));
    } finally {
      console.log('finally');
      isLoading(false);
      props.setOpenModal(false);
    }
  };

  return (
    <div className="delete-confirmation-modal-item">
      <div className="modal-dialog">
        <div className="modal-container">
          <div className="modal-body">
            <div className="content">
              <div className="message">{t('_remove_all_checked_items')}</div>
              <div className="actions">
                <div className="no">
                  <DefaultButton text={t('_no')} onClick={() => props.setOpenModal(false)} />
                </div>
                <div className="yes">
                  <DefaultButton text={t('_yes')} blue isloading={loading} onClick={deleteListItems} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

DeleteConfirmationItemsModal.displayName = 'DeleteConfirmationItemsModal';

export default DeleteConfirmationItemsModal;
