import { HttpResponse } from 'utils/hooks/http/history.http';
import { tecRMIAxios } from '../../http';

class hasTecRMITabsHttp {
  private static HasRepairItems = '/Times/VehicleHasTimes';
  private static HasMaintenance = '/Maintenance/VehicleHasMaintenance';
  private static HasAdjust = '/Adjust/VehicleHasAdjust';
  private static HasManuals = '/Manuals/VehicleHasManuals';
  private static HasTyres = '/Tyres/VehicleHasTyres';
  private static HasServiceNewsletter = '/Bulletins/VehicleHasBulletins';
  private static HasRelaysFuses = '/RelaysFuses/VehicleHasRelaysFuses';
  private static HasComponentsPosition = 'Components/VehicleHasComponents';
  private static HasDiagnostics = '/Diagnostics/VehicleHasDiagnostics';
  private static HasWiring = '/Wiring/VehicleHasWiring';
  private static HasGraphics = '/Graphics/VehicleHasGraphics';
  private static HasManualsList = '/Manuals/ManualList';
  private static HasMainGroupData = '/Adjust/MainGroupData';

  public async fetchRMIVehicleHasRepairItems(params: IHasTabParams): Promise<HttpResponse<boolean>> {
    return tecRMIAxios.get(hasTecRMITabsHttp.HasRepairItems, {params});
  }
  public async fetchRMIVehicleHasMaintenance(params: IHasTabParams): Promise<HttpResponse<boolean>> {
    return tecRMIAxios.get(hasTecRMITabsHttp.HasMaintenance, {params});
  }
  public async fetchRMIVehicleHasAdjust(params: IHasTabParams): Promise<HttpResponse<boolean>> {
    return tecRMIAxios.get(hasTecRMITabsHttp.HasAdjust, {params});
  }
  public async fetchRMIVehicleHasManuals(params: IHasTabParams): Promise<HttpResponse<boolean>> {
    return tecRMIAxios.get(hasTecRMITabsHttp.HasManuals, {params});
  }
  public async fetchRMIVehicleHasTyres(params: IHasTabParams): Promise<HttpResponse<boolean>> {
    return tecRMIAxios.get(hasTecRMITabsHttp.HasTyres, {params});
  }
  public async fetchRMIVVehicleServiceNewsletterHasBulletins(params: IHasTabParams): Promise<HttpResponse<boolean>> {
    return tecRMIAxios.get(hasTecRMITabsHttp.HasServiceNewsletter, {params});
  }
  public async fetchRMIVehicleHasRelaysFuses(params: IHasTabParams): Promise<HttpResponse<boolean>> {
    return tecRMIAxios.get(hasTecRMITabsHttp.HasRelaysFuses, {params});
  }
  public async fetchRMIVehicleHasComponents(params: IHasTabParams): Promise<HttpResponse<boolean>> {
    return tecRMIAxios.get(hasTecRMITabsHttp.HasComponentsPosition, {params});
  }
  public async fetchRMIVehicleHasDiagnostics(params: IHasTabParams): Promise<HttpResponse<boolean>> {
    return tecRMIAxios.get(hasTecRMITabsHttp.HasDiagnostics, {params});
  }
  public async fetchRMIVehicleHasWiring(params: IHasTabParams): Promise<HttpResponse<boolean>> {
    return tecRMIAxios.get(hasTecRMITabsHttp.HasWiring, {params});
  }
  public async fetchRMIVehicleHasGraphics(params: IHasTabParams): Promise<HttpResponse<boolean>> {
    return tecRMIAxios.get(hasTecRMITabsHttp.HasGraphics, {params});
  }
  public async fetchRMIVehicleManualsList(params: IHasTabParams): Promise<HttpResponse<boolean>> {
    return tecRMIAxios.get(hasTecRMITabsHttp.HasManualsList, {params});
  }
  public async fetchRMIVehicleMainGroupData(params: IHasTabParams): Promise<HttpResponse<boolean>> {
    return tecRMIAxios.get(hasTecRMITabsHttp.HasMainGroupData, {params});
  }
}

export interface IHasTabParams{
    typeId: number,
    countryCode: string,
    languageCode?: string,
    mainGroupId?: number,
}

export default hasTecRMITabsHttp;
