import React from 'react';
import Head from 'components/tables/Cells/Head/Head';
import { t } from 'i18next';
import { Column } from 'react-table';
import { IDetailsListFinancial } from 'interfaces/financial';
import ExpandButton from 'components/tables/TableMoreInformation/ExpandButton/ExpandButton';


export function columnTableFinancial(): Column<IDetailsListFinancial>[] {
  return [
    {
      id: 'expander',
      // eslint-disable-next-line react/destructuring-assignment
      Header: '',
      width: 15,
      // eslint-disable-next-line react/destructuring-assignment
      Cell: ({ row }) => {
        return (
          //Applying the toggle expander props i.e onClick, style and title
          <ExpandButton row={row} />
        );
      }
    },

    {
      Header: <Head text={t('_financial_date')}></Head>,
      accessor: 'date',

    },

    {
      Header: <Head text={t('_financial_invoiced')}></Head>,
      accessor: 'invoiced',
    },
    {
      Header: <Head text={t('_financial_debit')}></Head>,
      accessor: 'debit',

    },


    {
      Header: <Head text={t('_financial_credit')}></Head>,
      accessor: 'credit'
    },
    {
      Header: <Head text={t('_financial_pay')}></Head>,
      accessor: 'pay',

    },
    {
      Header: <Head text={t('_financial_debt')}></Head>,
      accessor: 'debt',
      Cell: (row) => <span>{Number(row.row.original?.debt).toFixed(2)}</span>
    },


    {
      Header: <Head text={t('_financial_id_folder')}></Head>,
      accessor: 'id_folder',

    },
    {
      Header: <Head text={t('_financial_deadline')}></Head>,
      accessor: 'deadline',

    }

  ];

}


