import React from 'react';

import './ModalFile.scss';
import { useTranslation } from 'react-i18next';
import Spinner from 'components/spinner/Spinner';


type Props = {

};
const ModalFile: React.FC<Props> = () => {
  const { t } = useTranslation();

  return (
    <div className="modal_file">
      <div className="text">
        {t('_waiting_file')}
      </div>
      <div>
        <Spinner class="small" />
      </div>

    </div>

  );
};

ModalFile.displayName = 'ModalFile';

export default ModalFile;
