import React, { useState } from 'react';
import './ActiaTableOptions.scss';
import { faEdit, faRemove, faDownload, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IActiaTableData, ISessionModal } from '../../../DiagnosticItems/Actia/actia';
import Modal from '../../../modal/Modal';
import useHttp from 'utils/hooks/useHttp';
import Spinner from '../../../spinner/Spinner';
import { getURLParams, notify } from '../../../../utils/marketplace';
import ModalDelete from '../../../CatalogNaf/ModalDelete/ModalDelete';
import { createSearchParams, generatePath, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useReliableCatalogCarQueryParams from 'utils/hooks/useReliableCatalogCarQueryParams';

type Props = {
  data: IActiaTableData;
  setOpenModal: (boolean) => void;
  setModalValues: (values: ISessionModal) => void;
  getActiaListItems: () => void;

}

interface IUrlParams {
  source?: string;
  vehicle_identifier?: string;
}


const ActiaTableOptions: React.FC<Props> = (props) => {
  const { actiaHttp } = useHttp();
  const [downloadLoading, setDownloadLoading] = useState(false);
  const navigate = useNavigate();
  const { vehicle_id } = useParams();
  const { t } = useTranslation();
  const reliableCatalogCarQueryParams = useReliableCatalogCarQueryParams();

  const handleEdit = () => {
    props.setModalValues({
      id: props.data.id,
      first_name: props.data.first_name,
      last_name: props.data.last_name,
      email: props.data.email,
      phone: props.data.phone
    });
    props.setOpenModal(true);
  };

  const handleDownload = () => {
    setDownloadLoading(true);

    actiaHttp.downloadReport(props.data.session_id)
      .then((data) => {

        if (data.status === 404) {
          notify(t('_actia_file_not_ready'));
        } else if (data.status === 200 && typeof data.data === 'string') {
          const link = document.createElement('a');

          link.href = data.data;
          link.target = 'blank';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      })
      .catch((e) => {
        console.log(e);
        notify('_error');

      })
      .finally(() => {
        setDownloadLoading(false);
      });

  };

  const handleErrorCodes = () => {
    const paramsObject = { ...reliableCatalogCarQueryParams };

    navigate({
      pathname: generatePath(`/reliable/rmi/${vehicle_id}/diagnostic/${props.data.session_id}/error-codes`, { vehicle_id }),
      search: `?${createSearchParams(paramsObject)}`
    });

  };

  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  return (
    <div>
      <div className="actia-table-options">
        {/*<div className="option edit" onClick={handleEdit}>*/}
        {/*  <FontAwesomeIcon icon={faEdit}/>*/}
        {/*</div>*/}
        <div className="option error-codes" onClick={handleErrorCodes}>
          <FontAwesomeIcon icon={faTimesCircle} />
        </div>
        <div className="option download" onClick={handleDownload}>
          {
            downloadLoading ? (
              <Spinner class="extra-small" />
            ) : (
              <FontAwesomeIcon icon={faDownload} />
            )
          }
        </div>
        <div className="option delete" onClick={() => setOpenDeleteModal(true)}>
          <FontAwesomeIcon icon={faRemove} />

        </div>
      </div>
      <div className="delete-confirmation-modal-session">
        <Modal
          openModal={openDeleteModal}
          setOpenModal={setOpenDeleteModal}
          childrenComponent={<ModalDelete class="small" onDone={props.getActiaListItems} setOpenModalDelete={setOpenDeleteModal} id={Number(props.data.id)} link={actiaHttp.deleteSession} />}
        />
      </div>
    </div>

  );
};

ActiaTableOptions.displayName = 'ActiaTableOptions';

export default ActiaTableOptions;