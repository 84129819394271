import React, { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCar, faTruck } from '@fortawesome/free-solid-svg-icons';

import EquivalenceTruck from './EquivalenceTruck/EquivalenceTruck';
import EquivalenceCar from './EquivalenceCar/EquivalenceCar';
import './VehicleConcern.scss';
import { IEquivalenceCarsResponse, IEquivalenceTrucksResponse } from '../../../../models/car-parts.model';
import Tabs from './Tabs/Tabs';
import Tab from './Tabs/Tab';

type Props = {
  loadingCars: boolean;
  loadingTrucks: boolean;
  equivalenceCarsResponse?: IEquivalenceCarsResponse;
  equivalenceCarsFilters?: IEquivalenceCarsResponse;
  equivalenceTrucksResponse?: IEquivalenceTrucksResponse;
};

const VehicleConcern: React.FC<Props> = (props) => {
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    if ((props.equivalenceTrucksResponse && Object.keys(props.equivalenceTrucksResponse?.filters).length) && (props.equivalenceCarsFilters && Object.keys(props.equivalenceCarsFilters?.filters).length == 0)) {
      setTabIndex(1);
    }
  }, []);

  return (
    <div className="vehicle-concern-wrapper-content">
      <div className="vehicle-concern-container-content">
        <div className="tabs-container">
          <Tabs selectedTab={tabIndex} >
            <Tab title={<FontAwesomeIcon icon={faCar} />}>
              <EquivalenceCar data={props.equivalenceCarsResponse} filterData={props.equivalenceCarsFilters} loading={props.loadingCars} items={[]} />
            </Tab>
            <Tab title={<FontAwesomeIcon icon={faTruck} />}>
              <EquivalenceTruck data={props.equivalenceTrucksResponse} loading={props.loadingTrucks} />
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

VehicleConcern.displayName = 'VehicleConcern';

export default VehicleConcern;
