import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { IDropdownModel } from '../Dropdown.model';
import { useOnClickOutside } from '../../../utils/hooks/useOnClickOutside';
import './MiniDropdown.scss';

type Props = {
  content?: string;
  image?: string;
  data?: IDropdownModel[];
  onClick?: (item: IDropdownModel) => void;
  openUp?: boolean;
};

const MiniDropdown: React.FC<Props> = (props: PropsWithChildren<Props>) => {
  const [show, setShow] = useState<boolean>(false);
  const dropdownRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  const [selectedItem, setSelectedItem] = useState<IDropdownModel>({
    image: props.image,
    id: props.content,
  });

  useOnClickOutside(dropdownRef, () => setShow(false));

  const handleOnClick = (item: IDropdownModel) => {
    setSelectedItem(item);

    if (props.onClick) {
      props.onClick(item);
    }
  };

  useEffect(() => {
    setSelectedItem({
      image: props.image,
      id: props.content,
    });
  }, [props]);

  return (
    <div ref={dropdownRef} className="mini-dropdown" onClick={() => setShow(!show)}>
      {selectedItem.image ? <img src={selectedItem.image} alt={selectedItem.text} width="14" height="9" /> : <div>{selectedItem.id} </div>}
      <FontAwesomeIcon icon={show ? faCaretUp : faCaretDown} className="caret-icon" />
      <div className={show ? 'show' : 'hide'}>
        <div className={props.openUp ? 'content-data-top' : 'content-data'}>
          {props.data
            ? props.data.map((item: IDropdownModel, index) => {
              return (
                <div className="item" onClick={() => handleOnClick(item)} key={index}>
                  {item.icon ? <FontAwesomeIcon icon={item.icon} className="icon" /> : null}
                  {item.image ? <img className="image" src={item.image} width="14" height="9" alt={item.text} /> : null}
                  {item.text ? <p className="text">{item.text}</p> : null}
                </div>
              );
            })
            : null}
        </div>
      </div>
    </div>
  );
};

MiniDropdown.displayName = 'MiniDropdown';

export default MiniDropdown;
