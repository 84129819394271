
import { faAdd } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import TextInput from 'components/inputs/TextInput/TextInput';
import i18n from 'i18n';
import moment from 'moment';
import React, { MutableRefObject, useRef, useState } from 'react';
import ReactDatePicker from 'react-datepicker';

import { useForm } from 'react-hook-form';

import { useTranslation } from 'react-i18next';
import { useOnClickOutside } from 'utils/hooks/useOnClickOutside';
import { useLocaleDatePicker } from 'utils/localeDatePicker';


type Props = {
  setOpenModal: (e: boolean) => void;
  setParams;
  setForcePage: (forcePage: number) => void;
  setIsActive: (e: boolean) => void;
}

const FilterPromotion: React.FC<Props> = (props) => {

  const { reset, getValues, setValue, register, handleSubmit } = useForm();
  const dateRef = useRef() as MutableRefObject<HTMLDivElement>;
  const { t } = useTranslation();
  const [openStartDate, setOpenStartDate] = useState(false);
  const [openEndDate, setOpenEndDate] = useState(false);
  const [valueStartDate, setValueStartDate] = useState('');
  const [valueEndDate, setValueEndDate] = useState('');
  const [endDate, setEndDate] = useState(null);
  const [startDate, setStartDate] = useState(null);

  useOnClickOutside(dateRef, () => { setOpenStartDate(false); setOpenEndDate(false); });
  useLocaleDatePicker(i18n.language);
  const onSubmit = () => {


    const values = getValues();

    Object.keys(values).forEach((key) => {
      if (values[key] == undefined || values[key] == '') {
        delete values[key];
      }
    });

    if (Object.keys(values).length == 0) {
      return;
    }

    props.setParams({ ...getValues(), ...{ page: 1 } });
    props.setForcePage(1);
    props.setIsActive(false);
  };

  const resetFilter = () => {
    const values = getValues();

    Object.keys(values).forEach((key) => {
      if (values[key] == undefined || values[key] == '') {
        delete values[key];
      }
    });


    reset({ title: '', start_date: '', end_date: '' });
    setValueStartDate('');
    setValueEndDate('');
    props.setParams({ page: 1 });
    props.setForcePage(1);
    props.setIsActive(false);
    setStartDate(null);
    setEndDate(null);
  };

  function onDateChangeStart(date) {
    setValueStartDate(`${moment(date).format('DD/MM/YYYY')}`);
    setValue('start_date', `${moment(date).format('YYYY-MM-DD')}`);
    setStartDate(date);
  }

  function onDateChangeEnd(date) {
    setValueEndDate(`${moment(date).format('DD/MM/YYYY')}`);
    setValue('end_date', `${moment(date).format('YYYY-MM-DD')}`);
    setEndDate(date);
  }

  return (
    <div className="filter">
      <div className="filter-header">

        <div className="filter-title">{t('_advanced_search')}</div>

        <div className="filter-link-btn">
          <button className="add-btn" onClick={() => props.setOpenModal(true)}> <FontAwesomeIcon icon={faAdd} className="icon" /> {t('_create_promotions')} </button>
        </div>
      </div>
      <form onSubmit={handleSubmit(() => onSubmit())}>
        <div className="filter-body">


          <div className="filter-input-item">
            <TextInput {...register('title')} placeholder={t('_promotions_title')} />
          </div>
          <div className="filter-input-item">
            <div className="date-input-item">
              <div className="orders-date-picker" onClick={() => setOpenStartDate(true)}>
                <TextInput placeholder={t('_promotions_start_date')} defaultValue={valueStartDate} readOnly />
              </div>
              {openStartDate && (
                <div className="date-picker" ref={dateRef}>
                  <ReactDatePicker
                    {...register('start_date')}
                    onChange={onDateChangeStart}
                    inline
                    maxDate={endDate}
                    locale={i18n.language.split('-')[0]}

                  />
                </div>
              )}
            </div>
          </div>
          <div className="filter-input-item">
            <div className="date-input-item">
              <div className="orders-date-picker" onClick={() => setOpenEndDate(true)}>
                <TextInput placeholder={t('_promotions_end_date')} defaultValue={valueEndDate} readOnly />

              </div>
              {openEndDate && (
                <div className="date-picker" ref={dateRef}>
                  <ReactDatePicker
                    {...register('end_date')}
                    onChange={onDateChangeEnd}
                    inline
                    minDate={startDate}
                    locale={i18n.language.split('-')[0]}
                  />
                </div>
              )}
            </div>
          </div>

          <DefaultButton text={t('_filter')} type="submit" />
          <DefaultButton text={t('_reset')} red onClick={() => { resetFilter(); }} type="button" />
        </div>
      </form>
    </div >
  );
};

FilterPromotion.displayName = 'FilterPromotion';

export default FilterPromotion;
