import React, { useCallback, useState } from 'react';

import { IClient, IClientEdit } from 'models/client.model';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UseFormHandleSubmit, UseFormRegister, FieldErrors, Controller } from 'react-hook-form';
import { faRefresh, faXmark, faCheck, faClose } from '@fortawesome/free-solid-svg-icons';
import { IOption } from 'interfaces/select';
import { useDropzone } from 'react-dropzone';
import { Trans, useTranslation } from 'react-i18next';
import { getBackendAssetsBasePath } from 'utils/marketplace';
import { IDateOption } from 'models/date-picker';
import FormSelect from 'components/FormSelect/FormSelect';
import Spinner from 'components/spinner/Spinner';
import fileUpload from 'assets/Icons/file-upload.svg';
import useHttp from 'utils/hooks/useHttp';
import CustomDatePicker from 'components/custom-date-picker/CustomDatePicker';
import Select from 'react-select';
import './EditClient.scss';


type Props = {
  handleEditClientModal: () => void;
  handleSubmit: UseFormHandleSubmit<IClientEdit>;
  onSubmit: () => void;
  register: UseFormRegister<IClientEdit>;
  errors: FieldErrors<IClientEdit>;
  control;
  businessTypeOptions: IOption[];
  languagesOptions: IOption[];
  countriesOptions: IOption[];
  loading: boolean;
  files?;
  setSelectedFile: (file: File) => void;
  setSubscriptionValueDate: (date: IDateOption) => void
  setFiles: (files) => void;
  clientData?: IClient | null;
  country
  lang
  businessType
  setCountry
  setLang
  setBusinessType
};

const EditClientView: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();
  const http = useHttp();
  const [logoDeleted, setLogoDeleted] = useState(false);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      props.setSelectedFile(acceptedFiles[0]);
      props.setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      );
      setLogoDeleted(false);
    },
    [props.files],
  );

  const removeFile = (file) => {
    const newFiles = [...props.files];

    newFiles.splice(newFiles.indexOf(file), 1);
    props.setFiles(newFiles);
  };

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    onDrop,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpg', '.jpeg'],
    },
  });

  function deleteLogo(id) {
    removeFile(props.files[0]);
    setLogoDeleted(true);
    http.clients.deleteLogo(id);
  }

  function getFileLogo() {
    const { files, clientData } = props;

    if (files.length) {
      return files[0].preview;
    }

    if (logoDeleted) {
      return null;
    }

    if (!clientData?.image) {
      return;
    }

    return `${getBackendAssetsBasePath()}/${clientData?.image}`;
  }

  const fileLogo = getFileLogo();

  return (
    <div className="edit-client-container">
      <form className="popup-wrapper" onSubmit={props.handleSubmit(props.onSubmit)}>
        {props.loading && <Spinner />}
        <FontAwesomeIcon icon={faXmark} onClick={props.handleEditClientModal} className="close-btn" />
        <h1 className="popup-title">
          <Trans i18nKey="_edit_client_header" components={{ b: <b /> }} />
        </h1>
        <div className="form">
          <h4 className="form-title">{t('_client').toLocaleUpperCase()}</h4>
          <div className="row">
            <div className="client-select">
              <div className="radio">
                <input className="check" type="radio" value="true" {...props.register('is_active')} />
                <label htmlFor="is-active-true">{t('_active')}</label>
                <FontAwesomeIcon icon={faCheck} className="check-icon" />
              </div>
              <div className="radio">
                <input className="check" type="radio" value="false" {...props.register('is_active')} />
                <label htmlFor="is-active-false">{t('_inactive')}</label>
                <FontAwesomeIcon icon={faCheck} className="check-icon" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className={`edit-container ${props.errors?.name?.type == 'required' ? 'error' : ''}`}>
              <label htmlFor="#garage.name">{t('_name')}</label>
              <div className="text-input-container">
                <input
                  className="text-input"
                  type="text"
                  placeholder="Cesarelli Garage"
                  {...props.register('name', { required: true })}
                ></input>
                {props.errors?.name?.type === 'required' && <p className="error-message">{t('_name_required')}</p>}
              </div>
            </div>
            <div className={`edit-container ${props.errors?.business_type_id?.type == 'required' ? 'error' : ''}`}>
              <label>{t('_business_type')}</label>
              <div className="input-item">
                <Controller
                  control={props.control}
                  name="business_type_id"
                  rules={{ required: true }}
                  render={({ field: { onChange } }) => {
                    return (
                      <Select
                        value={props.businessType}
                        className="select-dropdown"
                        onChange={(val) => {
                          onChange(val.value);
                          props.setBusinessType(val);
                        }}
                        options={props.businessTypeOptions}
                      />
                    );
                  }}
                />
                {props.errors?.business_type_id?.type === 'required' && (
                  <p className="error-message">{t('_business_type_required')}</p>
                )}
              </div>
            </div>
            <div className={`edit-container ${props.errors?.language?.type == 'required' ? 'error' : ''}`}>
              <label>{t('_language')}</label>
              <div className="input-item">
                <Controller
                  control={props.control}
                  name="language"
                  rules={{ required: true }}
                  render={({ field: { onChange } }) => {
                    return (
                      <Select
                        value={props.lang}
                        className="select-dropdown"
                        onChange={(val) => {
                          onChange(val.value);
                          props.setLang(val);
                        }}
                        options={props.languagesOptions}
                      />
                    );
                  }}
                />
              </div>
              {props.errors?.language?.type === 'required' && <p className="error-message">{t('_language_required')}</p>}
            </div>
          </div>
          <div className="row">
            <div className="edit-container">
              <label htmlFor="#garage.name">{t('_garage_address')}</label>
              <div className="text-input-container">
                <input
                  className="text-input"
                  type="text"
                  placeholder="12 avenue Auguste Renoir"
                  {...props.register('address')}
                ></input>
              </div>
            </div>
            <div className={`edit-container ${props.errors?.country?.type == 'required' ? 'error' : ''}`}>
              <label>{t('_country')}</label>
              <div className="input-item">
                <Controller
                  control={props.control}
                  name="country"
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Select
                        value={props.country}
                        className="select-dropdown"
                        onChange={(val) => {
                          onChange(val.value);
                          props.setCountry(val);
                        }}
                        options={props.countriesOptions}
                      />
                    );
                  }}
                />
              </div>
              {props.errors?.country?.type === 'required' && <p className="error-message">{t('_country_required')}</p>}
            </div>
            <div className="edit-container">
              <label htmlFor="#garage.zipcode">{t('_code_postal')}</label>
              <div className="text-input-container">
                <input className="text-input" type="text" placeholder="78500" {...props.register('zipcode')}></input>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="edit-container">
              <label htmlFor="#garage.phone">{t('_mobile')}</label>
              <div className="text-input-container">
                <input className="text-input" type="tel" placeholder="06 55 11 99 55" {...props.register('phone')}></input>
              </div>
            </div>
            <div className="edit-container">
              <label htmlFor="#garage.commentary">{t('_comment')}</label>
              <div className="text-input-container">
                <textarea className="text-input" placeholder="" {...props.register('commentary')}></textarea>
              </div>
            </div>
            <div className="edit-container">
              <label htmlFor="#garage.image">{t('_logo')}</label>
              <div className="logo-upload-container">
                <div className="logo-upload">
                  {fileLogo ? (
                    <div className="uploaded-message-file">
                      <div className="icon">
                        <img alt="icon" src={fileLogo} />
                      </div>

                      <div className="remove-file" onClick={() => deleteLogo(props.clientData?.client_id)}>
                        <FontAwesomeIcon className="remove-client-img" icon={faClose} />
                      </div>
                    </div>
                  ) : (
                    <div className="select-logo-title">{t('_please_select_a_logo')}</div>
                  )}
                </div>
                <div {...getRootProps({ className: 'content' })}>
                  <div className="logo-upload-button">
                    <input {...getInputProps()} />
                    <img src={fileUpload} className="upload-image-btn" alt="file-upload" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row subscription">
            <div className={`edit-container ${props.errors?.client_erp_id?.type == 'required' ? 'error' : ''}`}>
              <label htmlFor="#garage.client_erp_id">{t('_client_erp_id')}</label>
              <div className="text-input-container">

                <input className="text-input" placeholder={t('_client_erp_id')} {...props.register('client_erp_id', { required: true })}></input>
                {props.errors?.client_erp_id?.type === 'required' && <p className="error-message">{t('_fild_required')}</p>}
              </div>
            </div>
            <div className="item-date-picker subscription-date-picker">
              <label>{t('_subscription')}</label>
              <CustomDatePicker setValue={props.setSubscriptionValueDate} defaultValues={props.clientData?.client_subscription} />
            </div>
          </div>
        </div>
        <button className="update-button">
          <FontAwesomeIcon icon={faRefresh} className="icon" />
          {t('_save')}
        </button>
      </form>
    </div>
  );
};

EditClientView.displayName = 'EditClientView';

export default EditClientView;
