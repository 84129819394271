
import React from 'react';
import './PartInfoModal.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';

type Props = {
  data?;
  setOpenModal: (e: boolean) => void;
  line?;
};

const PartInfoModal: React.FC<Props> = (props) => {


  return (
    <div className="part-item-oe-list-info">
      <div className="close-modal" onClick={() => { props.setOpenModal(false); }}><FontAwesomeIcon icon={faClose} /></div>

      {

        !props.line
          ?
          <div className="description">
            {Object.keys(props.data).map((key, index) => {

              return <div key={index} className="description-lable"> <span>{key} </span> : {props.data[key].join('; ')} </div>;

            })
            }
          </div>
          :
          <div className="description-line">
            {props.data}
          </div>

      }

    </div>

  );
};

export default PartInfoModal;
