import { createAsyncThunk } from '@reduxjs/toolkit';
import useHttp from 'utils/hooks/useHttp';
import {useSelector} from 'react-redux';
import {RootState} from '../store';
import {CAR_PRO, CAR_TECH, NONE_SUBSCRIPTION} from '../../utils/rmi/reliableTabs';
import { IUser } from 'models/IUser';


export const fetchVehicleTreeAdcTypeIdByTcdTypeAndClass = createAsyncThunk('tecrmi/fetchTcdIdByPlkId', async (vehicle_id: number, { rejectWithValue }) => {
  
  
  try {
    if (localStorage.getItem('userData')) {
      const userData: IUser = JSON.parse(localStorage.getItem('userData') || '');

        
      if(userData && ![CAR_PRO, CAR_TECH].includes(userData?.client_settings?.rmi_modules_type)) {return null;}

      const http = useHttp();
      const { data } = await http.authTecRmi.fetchVehicleTreeAdcTypeIdByTcdTypeAndClass({ tcdTypeId: vehicle_id, classId: 1 });

      return data;
    
    }
  
  } catch (e) {

    console.log(e);
    
    return rejectWithValue('fetch catalog info error');
  }
});
