import React, { useState } from 'react';
import './EspaceAutomotorOrder.scss';
import Felicitations from './felicitations/Felicitations';
import OrderFinancial from './order-financial/OrderFinancial';
import OrderInProgress from './order-in-progress/OrderInProgress';
import OrderRemaining from './order-remaining/OrderRemaining';
import UploadCsv from './upload-csv/UploadCsv';

const EspaceAutomotorOrder: React.FC = () => {
  const [showCongratulationMessage, setShowCongratulationMessage] = useState(false);
  const [showMessageType, setShowMessageType] = useState('');


  return (
    <div className="espace-automotor-order-container">
      <div className="actions">
        {showCongratulationMessage ? (
          <Felicitations setShowCongratulationMessage={setShowCongratulationMessage} showMessageType={showMessageType} />
        ) : (
          <React.Fragment>
            <UploadCsv setShowCongratulationMessage={setShowCongratulationMessage} setShowMessageType={setShowMessageType} />
            <OrderInProgress />
            <OrderRemaining />
            <OrderFinancial />
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

EspaceAutomotorOrder.displayName = 'EspaceAutomotorOrder';

export default EspaceAutomotorOrder;
