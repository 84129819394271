
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import TextInput from 'components/inputs/TextInput/TextInput';
import { IUserProfile } from 'models/IUser';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'store/store';
import { countries, languages } from 'utils/constants/locales';
import useHttp from 'utils/hooks/useHttp';
import { notify } from 'utils/marketplace';
import './EspaceAutomotorAccount.scss';
import { useAppDispatch } from 'utils/hooks/redux';
import { setUser } from 'store/reducers/useSlices';
import i18n from 'i18n';
import PasswordStrengthInput from '../../../../components/inputs/PasswordStrengthInput/PasswordStrengthInput';


const EspaceAutomotorAccount: React.FC = () => {
  const { user } = useSelector((state: RootState) => state.userData);
  const [loading, setLoading] = useState(false);
  const [passwordMatchError, setPasswordMatchError] = useState(false);
  const http = useHttp();
  const { register, formState: { errors }, getValues, setValue, handleSubmit, watch } = useForm<IUserProfile>();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  //--------- Set Default Values For Inputs --------//
  useEffect(() => {
    if (user) {
      setValue('gender', user!.gender);
      setValue('first_name', user!.full_name.split(' ')[0]);
      setValue('last_name', user!.full_name.split(' ')[1]);
      setValue('email', user!.email);
      setValue('mobile', user?.mobile);
      setValue('phone', user?.phone);
      setValue('language', user?.language);
    }
  }, [user]);

  //------------------------------------------------//
  function validCountryCode(userCountryCode){
    return countries.find(country => country.id === userCountryCode);
  }

  function submit() {


    if (watch('password.first') !== watch('password.second') && getValues('password.first')) {
      return;
    }

    if (getValues('old_password') != '' && watch('password.first') == '') {
      return;
    }


    if (getValues('old_password') == '' && watch('password.second') != '') {
      return;
    }

    if ((watch('password.second') != '' && watch('password.first') != '') && getValues('old_password') == '') {
      return;
    }

    setLoading(true);
    setPasswordMatchError(false);

    const data = {
      email: getValues().email,
      first_name: getValues().first_name,
      gender: getValues().gender,
      language: getValues().language,
      last_name: getValues().last_name,
      mobile: getValues().mobile,
      phone: getValues().phone,
      old_password: getValues().old_password,
      password: (getValues().password.first && getValues().password.second) && getValues().password
    };


    const validData = Object.entries(data).reduce((acc, [key, value]) => {

      if (!value) {
        return acc;
      }

      acc[key] = value;

      return acc;
    }, {});

    http.user.putUser(validData).then(() => {
      notify(t('_updated_successfully'), 'success');
    }).finally(() => {
      setLoading(false);
    }).catch(() => {
      notify(t('_error'));
    });
    const userInfo = JSON.parse(localStorage.getItem('userData')!);
    const user = { ...userInfo, ...validData, full_name: validData['first_name'] + ' ' + validData['last_name'] };

    dispatch(setUser(user));
    localStorage.setItem('locale', `${user.language || 'en'}-${validCountryCode(user.client_country_code)?.id || 'FR'}`);
    i18n.changeLanguage(`${user.language || 'en'}-${validCountryCode(user.client_country_code)?.id || 'FR'}`);
    localStorage.setItem('userData', JSON.stringify(user));
  }


  return (
    <div className="form-container-section-account" >
      <div className="form-container-account">

        <h1 className="title">{t('_personal_account')}</h1>
        <form onSubmit={handleSubmit(submit)} className="inputs">
          <div className="form">
            <h4>{t('_personal_information')}</h4>
            <div className="line">
              <div className={`radio-group ${errors.gender ? 'error-input' : ''}`}>
                <div className="radio">
                  <input type="radio" value="m" {...register('gender', { required: true })} />
                  <label htmlFor="#gender" className={`${errors.gender ? 'error-input' : ''}`}>
                    {t('_male')}
                  </label>
                </div>
                <div className="radio">
                  <input type="radio" value="f" {...register('gender', { required: true })} />
                  <label htmlFor="#gender" className={`${errors.gender ? 'error-input' : ''}`}>
                    {t('_female')}
                  </label>
                </div>
                {errors.gender?.type === 'required' && <span className="error-message">{t('_gender_required')}</span>}
              </div>
            </div>
            <div className="row">
              <div className={`container-automotor-account ${errors.first_name && 'error-input'}`}>
                <label htmlFor="#first_name" className={`${errors.first_name && 'error-input'}`}>
                  {t('_firstname')}
                </label>
                <TextInput
                  placeholder="Cesarelli"
                  {...register('first_name', { required: true })}
                />
                {errors.first_name?.type === 'required' && <span className="error-message">{t('_firstname_required')}</span>}
              </div>
              <div className={`container-automotor-account ${errors.last_name && 'error-input'}`}>
                <label htmlFor="#last_name_name" className={`${errors.last_name && 'error-input'}`}>
                  {t('_lastname')}
                </label>
                <TextInput
                  placeholder="Sylvain"
                  {...register('last_name', { required: true })}
                />
                {errors.last_name?.type === 'required' && <span className="error-message">{t('_lastname_required')}</span>}
              </div>
            </div>
            <div className="row">
              <div className="container-automotor-account email">
                <label htmlFor="#email">
                  {t('E-mail')}
                </label>
                <TextInput
                  disabled
                  value={user?.email ?? ''}
                  placeholder={t('cesarelli@gmail.com')}
                  {...register('email')}
                ></TextInput>
              </div>

            </div>
            <div className="row">
              <div className={`container-automotor-account ${errors.mobile && 'error-input'}`}>
                <label htmlFor="#mobile" className={`${errors.mobile && 'error-input'}`}>
                  {t('_mobile')}
                </label>
                <TextInput
                  placeholder={t('06 55 11 99 55')}
                  {...register('mobile', { required: true })}
                />
                {errors.mobile?.type === 'required' && <span className="error-message">{t('_mobile_required')}</span>}
              </div>
              <div className={`container-automotor-account ${errors.phone && 'error-input'}`}>
                <label htmlFor="#phone" className={`${errors.phone && 'error-input'}`}>
                  {t('_phone')}
                </label>
                <TextInput
                  placeholder={t('06 55 11 99 55')}
                  {...register('phone', { required: true })}
                />
                {errors.phone?.type === 'required' && <span className="error-message">{t('_phone_required')}</span>}
              </div>
            </div>
          </div>
          <div className="form">
            <h4>{t('_account_settings')}</h4>
            <div className="row">
              <div className={`container-automotor-account ${errors?.old_password
                || (watch('password.second') && watch('old_password') == '')
                || (watch('password.first') && watch('old_password') == '') ? 'error-input' : ''}`}
              >
                <label>{t('_old_password')}</label>
                <TextInput
                  type="password"
                  placeholder="* * * * * *"
                  {...register('old_password')}
                />
                {errors.password?.type === 'required' ||
                  (watch('password.second') && watch('old_password') == '') ||
                  (watch('password.first') && watch('old_password') == '')

                  ? <span className="error-message">{t('_password_required')}</span> : ''}
              </div>
            </div>
            <div className="row">
              <div className={`container-automotor-account ${errors.password?.first
                || (watch('old_password') != '' && watch('password.first') == '')
                || (watch('password.second') && watch('password.first') == '') ? 'error-input' : ''}`}
              >
                <label>{t('_password')}</label>
                {/*<TextInput type="password" placeholder="* * * * * *" {...register('password.first')} />*/}
                <PasswordStrengthInput placeholder="* * * * * *" {...register('password.first')} />
                {errors.password?.first?.type === 'required' || (watch('old_password') != '' && watch('password.first') == '')
                  || (watch('password.second') && watch('password.first') == '') ? <span className="error-message">{t('_password_required')}</span> : null}

              </div>
              <div className={`container-automotor-account ${errors.password?.second || watch('password.first') !== watch('password.second') && getValues('password.first') ? 'error-input' : ''}`}>
                <label >{t('_repeat_password')}</label>
                {/*<TextInput type="password" placeholder="* * * * * *" {...register('password.second')} />*/}
                <PasswordStrengthInput placeholder="* * * * * *" {...register('password.second')} />
                {errors.password?.second?.type === 'required' && <span className="error-message">{t('_password_required')}</span>}
                {watch('password.first') !== watch('password.second') && getValues('password.first') ? (<span className="error-message">{t('_password_not_match')}</span>) : null}
              </div>
            </div>
          </div>
          <div className="form">
            <h4>{t('_language')}</h4>
            <div className="row">
              <div className="line">
                <div className="radio-group-languages">
                  {languages.map(language => {
                    return (
                      <div key={language.id + language.text!} className="radio">
                        <input type="radio" id="language" value={language.id} {...register('language', { required: true })} />
                        <label htmlFor="#language" className={`${errors.language ? 'error-input' : ''}`}>
                          {language.text}
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="form">
            <DefaultButton isloading={loading} text={t('_update')} type="submit" />
          </div>
        </form>
      </div>
    </div>
  );
};

EspaceAutomotorAccount.displayName = 'EspaceAutomotorAccount';

export default EspaceAutomotorAccount;
