import { IBrand } from 'interfaces/consumableBrand';
import React from 'react';
import ItemEshopConsumableBrandView from './ItemEshopConsumableBrandView';


type Props = {
  data: IBrand[],
  loading: boolean
  openModalConsumableBrand: (id: number) => void;
  deleteItem: (id: number) => void;
  handleOnChangeIsPartner: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const ItemEshopConsumableBrand: React.FC<Props> = (props) => {
  return <ItemEshopConsumableBrandView data={props.data} loading={props.loading} openModalConsumableBrand={props.openModalConsumableBrand} deleteItem={props.deleteItem} handleOnChangeIsPartner={props.handleOnChangeIsPartner} />;
};

ItemEshopConsumableBrand.displayName = ' ItemEshopConsumableBrand';

export default ItemEshopConsumableBrand;
