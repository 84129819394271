import { CancelToken } from 'axios';
import { addRouteParam, IHttpResponse, serverAxios } from '../../http';

class SearchByRefHttp {
  private static route = 'reliable/autocomplete/searchV2';
  private static routeGlobalSearchSimple = 'reliable/global-search-simple';
  private static carPartsAutocompleteCatalogRoute = 'catalog/:catalog_id/car-parts/autocomplete';

  public async SearchByRef(data: string, cancelToken: CancelToken): Promise<IRefData> {
    return serverAxios.get(SearchByRefHttp.route, { params: { q: data }, cancelToken: cancelToken });
  }
  public async SearchByRefAdmin(data: { q: string; is_platform_admin: boolean }, cancelToken: CancelToken): Promise<IRefData> {
    return serverAxios.get(SearchByRefHttp.route, { params: data, cancelToken: cancelToken });
  }

  public async searchByRefAutocompleteCatalog(query: string, catalog_id: string, cancelToken: CancelToken): Promise<IHttpResponse<IData[]>> {
    return serverAxios.get(addRouteParam(SearchByRefHttp.carPartsAutocompleteCatalogRoute, { catalog_id }), { params: { q: query }, cancelToken: cancelToken });

  }

  public async routeGlobalSearchSimple(params: { q: string, codfou?: string }): Promise<IHttpResponse<{ parts: IData[] }>> {
    return serverAxios.get(SearchByRefHttp.routeGlobalSearchSimple, { params });
  }
}


export interface ICustomValidation {
  regex: RegExp;
  message: string;
}

export interface IData {
  category_name: string;
  man_sup_id: number | string
  reference: string;
  replacement: number;
  source: string;
  subcategory_id: number;
  supplier_or_manufacturer: string;
  reference_commerciale: string
  type: string
}

export interface IRefData {
  data: IData[];
}


export default SearchByRefHttp;
