import React, { CSSProperties } from 'react';
import './Spinner.scss';

interface SpinnerProps {
  type?: string;
  style?: CSSProperties;
  class?: string;
}

const Spinner: React.FC<SpinnerProps> = (props) => {
  return (
    <div style={props.style} className={`spinner ${props.class ?? ''}`}>
      <svg viewBox="25 25 50 50">
        <circle cx="50" cy="50" r="20" />
      </svg>
    </div>
  );
};

Spinner.displayName = 'Spinner';

export default Spinner;
