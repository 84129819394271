import React from 'react';
import { SwiperSlide } from 'swiper/react';
import SwiperSlider from 'components/SwiperSlider/SwiperSlider';
import { useTranslation } from 'react-i18next';
import './PromotionMobile.scss';
import { IPromotionDetails } from 'utils/hooks/http/promotions.http';
import { getMarketplaceBaseUrl } from 'utils/marketplace';
import moment from 'moment';

type Props = {
  isLoading?: boolean;
  items: IPromotionDetails[];
  slidesPerView?: number | 'auto';
  navigation?: boolean;
  pagination?: boolean;
  autoplay?: boolean;
  loop?: boolean;
};

const PromotionMobileView: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  const handleItemClick = (item: IPromotionDetails) => {
    const element = document.createElement('a');

    element.href = item.pdf_path ? getMarketplaceBaseUrl() + item.pdf_path : '';
    element.target = '_blank';
    element.click();
  };

  return (
    <SwiperSlider
      slidesPerView={props.slidesPerView}
      isLoading={props.isLoading}
      navigation={props.navigation}
      pagination={props.pagination}
      autoplay={props.autoplay}
      loop={props.loop}
    >
      {
        props.items.map((item: IPromotionDetails, index: number) => {
          return (
            <SwiperSlide className="swiper-slide" key={index} >
              <div className="promotion-container default-promotion mobile" onClick={() => handleItemClick(item)} key={index}>
                <div className="promotion-header">
                  <span className="title">{item.title}</span>
                  {/* <span className="discount">{item.discount}</span> */}
                </div>
                <div className="promotion-content">
                  <div className="promotion-image">
                    <img src={item.images_path.mobile ? getMarketplaceBaseUrl() + item.images_path.mobile : '/images/car-part-placeholder.png'} alt="part-img"
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = '/images/car-part-placeholder.png';
                      }}
                    />
                  </div>
                  <div className="promotion-info">
                    <div className="information">
                      <div className="availability">{`${t('_availability_promo')}  ${moment(item.start_date).format('DD/MM/YYYY')}  ${t('_to')}  ${moment(item.end_date).format('DD/MM/YYYY')}`}</div>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          );
        })
      }
    </SwiperSlider>
  );
};

PromotionMobileView.displayName = 'PromotionMobileView';

export default PromotionMobileView;
