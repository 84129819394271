import MainNews from 'components/news/main-news/MainNews';
import SideNews from 'components/news/side-news/SideNews';
import Promotion from 'components/Promotions/Default/Promotion';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import { INews, INewsData } from 'utils/hooks/http/news.http';
import useHttp from 'utils/hooks/useHttp';
import Spinner from 'components/spinner/Spinner';
import Pagination from 'components/pagination/Pagination';
import { useSearchParams } from 'react-router-dom';
import { IPromotionDetails } from 'utils/hooks/http/promotions.http';
import { ReactComponent as NoNews } from 'assets/images/no-news.svg';
import './NewsPage.scss';


const NewsPageView: React.FC = () => {
  const { i18n } = useTranslation();
  const { isMobile, isTablet } = useDeviceDetect();
  const [mainNews, setMainNews] = useState<INews>();
  const [news, setNews] = useState<INews[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [paginationData, setPaginationData] = useState<INewsData>();
  const [searchParams] = useSearchParams();
  const http = useHttp();
  const [promotionItems, setPromotionItems] = useState<IPromotionDetails[]>([]);
  const [loadingPromo, setLoadingPromo,] = useState<boolean>(false);


  function getNews(page) {
    setLoading(true);
    http.news.getNews({ page }).then((res) => {
      const data = res.data.data;

      const firstNews = data.shift();

      setPaginationData(res.data);
      setMainNews(firstNews);
      setNews(data);
    }
    ).finally(() => setLoading(false));
  }

  const handlePageClick = (page) => {
    if (paginationData) {
      const paginationCopy = paginationData;

      paginationCopy.page = page;
      setPaginationData(paginationCopy);
      getNews(page);
    }
  };

  useEffect(() => {
    getNews(searchParams.get('page') || 1);
    getPromotions();
  }, [i18n.language]);


  function getPromotions() {
    setLoadingPromo(true);
    http.promotions.activePromotions().then((res) => {
      setPromotionItems(res.data);
    }).finally(() => { setLoadingPromo(false); })
      .catch(() => {
        setLoadingPromo(false);
      });
  }


  const rssSlidesPerView = isMobile || isTablet ? 'auto' : 5;

  return (
    <div className="news-page-container">
      <div className={`news-promotion-video-container ${loading ? 'loading' : ''}`}>
        <div className={`promotions-container ${loadingPromo ? 'loadingPromo' : ''}`}>
          {
            loadingPromo ? <Spinner class="small" /> :
              promotionItems.length ?
                <div className="news-promo container-fluid-large">
                  <Promotion
                    items={promotionItems}
                    slidesPerView={isMobile || isTablet || promotionItems.length == 1 ? 'auto' : 2}
                    navigation={!(isMobile || isTablet) && promotionItems.length != 1}
                    pagination={isMobile || isTablet}
                    autoplay={promotionItems.length != 1}
                    loop={promotionItems.length != 1}
                  />
                </div>
                : null
          }
        </div>
        {loading ? <Spinner class="small" /> : (
          <React.Fragment>
            <div className={`news-video ${!news.length ? 'centered' : null}`}>
              {news.length || mainNews ? (
                <React.Fragment>
                  <div className="main-news-video">
                    <MainNews data={mainNews} />
                  </div>
                  <div className="side-news-videos">
                    {news.map(article => {
                      return (
                        <SideNews key={article.id} data={article} />
                      );
                    })}
                  </div>
                </React.Fragment>
              )
                : <div className="no-news-container">
                  <NoNews />
                </div>
              }
            </div>
            {paginationData && mainNews && <Pagination pageCount={paginationData?.total_pages} handlePageClick={handlePageClick!} forcePage={paginationData?.page} />}
          </React.Fragment>
        )}

      </div>
      {/* <div className="news-flux-rss">
        <SwiperSlider
          slidesPerView={rssSlidesPerView}
          spaceBetween={isMobile || isTablet ? 10 : 30}
          navigation={isMobile || isTablet}
          loop
          breakpoints={{
            990: {
              slidesPerView: 4,
              centeredSlides: true,
            },
            1200: {
              slidesPerView: rssSlidesPerView,
            }
          }}
        >
          <SwiperSlide className="swiper-slide">
            <SideRss />
          </SwiperSlide>
          <SwiperSlide className="swiper-slide">
            <RufinusItem />
          </SwiperSlide>
          <SwiperSlide className="swiper-slide">
            <RufinusItem />
          </SwiperSlide>
          <SwiperSlide className="swiper-slide">
            <RufinusItem />
          </SwiperSlide>
          <SwiperSlide className="swiper-slide">
            <RufinusItem />
          </SwiperSlide>
        </SwiperSlider>
      </div> */}
    </div>
  );
};

export default NewsPageView;
