import { CancelToken } from 'axios';
import { serverAxios } from '../../http';

class SearchByRefAllHttp {

  private static route = 'reliable/autocomplete/all-reference/search';

  public async SearchByRefAll(data: string  ,cancelToken: CancelToken ): Promise<IRefDataAll> {
   
    return serverAxios.get(SearchByRefAllHttp.route, { params: { q: data } , cancelToken:cancelToken } );
  }
}

export interface IDataRefAll {
  reference: string;
  ind_equivalence,
  source: string;
  codfou: number;
  name: string;
  supplier_id: number;
  reference_full: string;
}

export interface IRefDataAll {
  data: IDataRefAll[];
}

export default SearchByRefAllHttp;
