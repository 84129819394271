import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faComment} from '@fortawesome/free-solid-svg-icons';
import Spinner from '../../spinner/Spinner';
import {useTranslation} from 'react-i18next';
import './MessageRecipientInfo.scss';

type Props = {
    loading: boolean;
    recipientName: string;
}

const MessageRecipientInfo: React.FC<Props> = (props) => {
  const {t} = useTranslation();

  return (
    <div className="message-recipient-info">
      <FontAwesomeIcon icon={faComment}/>
      <span>{t('_messages_with')}</span>
      {
        props.loading ? (
          <Spinner class="extra-small"></Spinner>
        ) : (
          ` ${props.recipientName}`
        )
      }
    </div>
  );
};

MessageRecipientInfo.displayName = 'MessageRecipientInfo';

export default MessageRecipientInfo;
