import React, { useEffect, useState } from 'react';
import { CellProps } from 'react-table';
import { useAppDispatch } from 'utils/hooks/redux';
import useHttp from 'utils/hooks/useHttp';
import { CartTableItem } from '../../CartTable.model';
import QuantityAvailabilityView from './QuantityAvailability.view';
import { setQuantityToolbar } from 'store/slices/cart.slice';
import { RootState } from 'store/store';
import { useSelector } from 'react-redux';

type Props = CellProps<CartTableItem> & {
  refetch: () => Promise<void>;
  availabilityUnspecified?: boolean;
  totalPartsPrice: number;
  setTotalPartsPrice?: (totalPartsPrice: number) => void;
};

const QuantityAvailability: React.FC<Props> = (props: Props) => {
  const http = useHttp();
  const [quantity, setQuantity] = useState<number>(props.value?.quantity);
  const [quantityLoading, setQuantityLoading] = useState<boolean>(false);
  const { quantityToolbar } = useSelector((state: RootState) => state.cart);
  const workRow = props.cell.row.original.reference.includes('Work');
  const [disableAddBtn, setDisableAddBtn] = useState(false);
  const [disableSubBtn, setDisableSubBtn] = useState(false);
  const dispatch = useAppDispatch();

  const handleAddClick = async () => {
    setQuantityLoading(true);
    let updatedQty: number;

    if (workRow) {
      updatedQty = quantity + 0.1;
    } else if (props.cell.row.original.available?.uv) {
      updatedQty = quantity + Number(props.cell.row.original.available.uv);
    } else {
      updatedQty = quantity + 1;
    }

    dispatch(setQuantityToolbar(+quantityToolbar + Number(props.cell.row.original?.available?.uv || 1)));
    props.row.original.quantity_availability.quantity = updatedQty;

    await http.order.update(props.row.original.id, { quantity: workRow ? +Number(updatedQty).toFixed(1) : updatedQty })
      .then((res) => {
        props.setTotalPartsPrice?.(res.data.total_price ? Number(res.data.total_price) : 0);
      }).catch(() => {
        dispatch(setQuantityToolbar(Number(quantityToolbar) - Number(props.cell.row.original?.available?.uv || 1)));
      });

    setQuantity(updatedQty);

    setQuantityLoading(false);
  };

  const handleSubstractClick = async () => {
    setQuantityLoading(true);
  
    let updatedQty: number;

    if (workRow) {
      updatedQty = quantity - 1 >= 1 ? quantity - 0.1 : 1;
    } else if (props.cell.row.original.available.uv) {
      updatedQty = quantity - 1 >= 1 ? quantity - Number(props.cell.row.original.available.uv) : Number(props.cell.row.original.available.uv);
    } else {
      updatedQty = quantity - 1 >= 1 ? quantity - 1 : 1;
    }

    if (updatedQty < Number(props.cell.row.original.available.uv)) {
      updatedQty = Number(props.cell.row.original.available.uv);
    }

    dispatch(setQuantityToolbar(Number(quantityToolbar) - Number(props.cell.row.original?.available?.uv || 1)));
    props.row.original.quantity_availability.quantity = updatedQty;

    await http.order.update(props.row.original.id, { quantity: workRow ? +Number(updatedQty).toFixed(1) : updatedQty })
      .then((res) => {
        props.setTotalPartsPrice?.(res.data.total_price ? Number(res.data.total_price) : 0);
      })

      .catch(() => {
        dispatch(setQuantityToolbar(Number(quantityToolbar) + Number(props.cell.row.original?.available?.uv || 1)));

      });

    setQuantity(updatedQty);

    setQuantityLoading(false);
    
  };

  useEffect(()=>{
    if(quantity == props.cell.row.original.available?.uv){
      setDisableSubBtn(true);
    }

    if(quantity === props.cell.row.original.available?.warehouse_main?.quantity){
      setDisableAddBtn(true);
    }

    if(!props.cell.row.original.available?.warehouse_main?.quantity){
      setDisableAddBtn(true);
    }
  },[quantity, props.cell.row.original.available.warehouse_main?.quantity]);

  return (
    <QuantityAvailabilityView
      available={props.cell.row.original.available}
      availabilityUnspecified={props.availabilityUnspecified}
      availability={props.value.availability}
      quantity={+Number(quantity).toFixed(1)}
      setQuantity={setQuantity}
      quantityLoading={quantityLoading}
      onAdd={handleAddClick}
      onSubstract={handleSubstractClick}
      workRow={workRow}
      disableAddBtn={disableAddBtn}
      disableSubBtn={disableSubBtn}
      readonly
    ></QuantityAvailabilityView>
  );
};

QuantityAvailability.displayName = 'QuantityAvailability';

export default QuantityAvailability;
