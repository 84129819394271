import { createSlice } from '@reduxjs/toolkit';

type buttonPriceCheckedStatus = {
  loadingPriceCheckedStatus: boolean;

};

const initialState: buttonPriceCheckedStatus = {
  loadingPriceCheckedStatus: false,
};

const buttonPriceCheckedStatus = createSlice({
  name: 'priceCheckedStatus',
  initialState,
  reducers: {
    setLoadingPriceCheckedStatus(state, { payload }) {
      state.loadingPriceCheckedStatus = payload;
    }

  },

});

export const { setLoadingPriceCheckedStatus } = buttonPriceCheckedStatus.actions;

export default buttonPriceCheckedStatus.reducer;
