import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import { getClientsData } from 'store/selectors/clients.selector';

import MyClientTableView from './MyClientsTable.view';

type Props = {};

const MyClientsTable: React.FC<Props> = () => {
  const clients = useSelector(getClientsData);
  const navigate = useNavigate();
  const { search } = useLocation();

  const handleClientClick = (key: React.Key) => {
    const clientId = clients[key.toString().replace('row_', '')].client_id;

    navigate({
      pathname: `/espace-clients/my-clients/${clientId}`,
      search: `${search}`,
    });
  };

  return <MyClientTableView clients={clients} handleClientClick={handleClientClick}></MyClientTableView>;
};

MyClientsTable.displayName = 'MyClientsTable';

export default MyClientsTable;
