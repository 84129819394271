export const contentStyle = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(172, 173, 185, 0.75)',
    zIndex: 10,
  },
  content: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '72rem',
    width: '100%',
    border: 'none',
    backgroundColor: '#ffffff',
    borderRadius: '10px',
  },
};
