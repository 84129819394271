import React, { useState } from 'react';
import useHttp from '../../../utils/hooks/useHttp';
import {IPlateNumberData} from '../../../utils/hooks/http/search-by-plate.http';
import {useForm} from 'react-hook-form';
import {IPlateForm} from '../../../utils/hooks/http/search-by-vin.http';
import {IHttpResponse} from '../../../utils/http';
import PlateSearchView from './PlateSearch.view';

type Props = {
  setOpenModal?: (e: boolean) => void;
  link?:string;
  };


const PlateSearch: React.FC<Props> = (props) => {
  const http = useHttp();
  const [plateData, setPlateData] = useState<IPlateNumberData | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);


  const {
    getValues,
    register,
    handleSubmit,
    formState: { errors },

  } = useForm<IPlateForm>();

  const onSubmit = () => {
    setIsLoading(true);
    setIsOpen(true);
    http.searchByPlate
      .search(getValues())
      .then((res: IHttpResponse<IPlateNumberData>) => {
        setPlateData(res.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <PlateSearchView
      errors={errors}
      registrationPlate={getValues('registration_plate')}
      plateData={plateData}
      onSubmit={onSubmit}
      isLoading={isLoading}
      handleSubmit={handleSubmit}
      register={register}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      setOpenModal={props.setOpenModal}
      link={props.link}
    />
  );
};

PlateSearch.displayName = 'PlateSearch';

export default PlateSearch;