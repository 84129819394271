import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { IData, IDataResponse, IStatus } from 'models/order';
import { getURLParams } from 'utils/marketplace';
import { Controller, useForm } from 'react-hook-form';
import useHttp from 'utils/hooks/useHttp';
import TextInput from 'components/inputs/TextInput/TextInput';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import { useOnClickOutside } from 'utils/hooks/useOnClickOutside';
import moment from 'moment';

import 'react-datepicker/dist/react-datepicker.css';
import './OrdersClients.scss';
import OrderClientsTable from './Table/OrderClientsTable';
import { useTranslation } from 'react-i18next';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faXmark } from '@fortawesome/free-solid-svg-icons';
import { useLocaleDatePicker } from 'utils/localeDatePicker';
import i18n from 'i18n';

type Props = {
  catalogId?: string;
};
interface IURLParams {
  page?: number;
}
const OrdersClients: React.FC<Props> = (props) => {
  const { control, getValues, register, handleSubmit, setValue } = useForm();
  const http = useHttp();
  const urlParams: IURLParams = getURLParams(location.search);
  const { isMobile, isTablet } = useDeviceDetect();
  const [data, setData] = useState<IData[]>([]);
  const [paginationData, setPaginationData] = useState<IDataResponse>();
  const [status, setStatus] = useState<IStatus>();
  const [clients, setClients] = useState<{client_id: number, name: string}[]>();

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalPage, setTotalPage] = useState<number>(0);

  const [startDate, setStartDate] = useState(null);

  const [valueDate, setValueDate] = useState('');

  const [openDate, setOpenDate] = useState(false);

  const [loading, setLoading] = useState(false);
  const dateRef = useRef() as MutableRefObject<HTMLDivElement>;
  const { t } = useTranslation();
  const [isActive, setIsActive] = useState(false);

  useOnClickOutside(dateRef, () => setOpenDate(false));
  useLocaleDatePicker(i18n.language);

  function getOrderList(params) {
    setLoading(true);
    http.orderClients
      .getOrders(params)
      .then((res) => {
        const { data, page, total_pages } = res.data as IDataResponse;

        setData(data);
        setPaginationData({ data, page, total_pages });
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function getStatus() {
    http.orderClients
      .getOrdersStatus()
      .then((res) => setStatus(res.data));
  }

  function getClients() {
    http.orderClients
      .getOrderClients()
      .then((res) => setClients(res.data));
  }

  function getStatusOptions() {
    return status ? Object.entries(status).map(([value, label]) => ({ value, label })) : [];
  }

  function getClientsOptions() {
    return clients?.length ? clients.map((client) => ({ value: client.client_id, label: client.name })) : [];
  }

  useEffect(() => {
    getStatus();
    getClients();
    getOrderList({ page: urlParams.page ?? 1, catalog_id: props.catalogId });
  }, []);

  useEffect(() => {
    if (paginationData) {
      setCurrentPage(paginationData.page);
      setTotalPage(paginationData.total_pages);
    }
  }, [paginationData]);

  const onSubmit = async () => {
    setLoading(true);

    const data = getValues();

    if (data.status) {
      data.status = data.status.label;
    }

    if (data.client) {
      data.client_id = data.client.value;
    }

    delete data.client;

    const start_date = moment(startDate).format('YYYY-MM-DD');


    if (moment(start_date).isValid()) {
      data.date = moment(startDate).format('YYYY-MM-DD');
    }


    const validParams = Object.entries(data).reduce((acc, [key, value]) => {
      if (!value) {
        return acc;
      }

      acc[key] = value;

      return acc;
    }, {});

    await getOrderList(validParams);
    setIsActive(false);
  };

  const handlePageClick = async (page: number) => {
    setCurrentPage(page);
    setLoading(true);
    const selectPage = page;
    const data = getValues();


    if (data.status) {
      data.status = data.status.label;
    }

    if (data.client) {
      data.client_id = data.client.value;
    }

    delete data.client;
    const start_date = moment(startDate).format('YYYY-MM-DD');


    if (moment(start_date).isValid()) {
      data.date = moment(startDate).format('YYYY-MM-DD');
    }

    await getOrderList({ ...data, page: selectPage });
  };


  function onDateChange(date) {
    setStartDate(date);
    setValueDate(`${moment(date).format('DD/MM/YYYY')}`);
  }

  const handleResetFilter = () => {
    setValue('status', '');
    setValue('date', '');
    setValue('id', '');
    setValue('client', '');
    setStartDate(null);
    setValueDate('');
  };

  return (
    <div className="orders-page-client">
      <div className="orders-page-title">
        {t('_orders')}
      </div>
      {
        isMobile ?
          <div className="filter-btns">
            <button className="button-filter-orders-page" onClick={() => setIsActive(true)}>< FontAwesomeIcon icon={faFilter} /> {t('_filter')}</button >
          </div>
          :
          null
      }
      <div className={`orders-filter ${isMobile && !isActive ? 'hidden-filter-order' : 'show-filter-order'}`}>
        {
          isMobile ?
            <div className="close-btn">
              <FontAwesomeIcon icon={faXmark} onClick={() => { setIsActive(false); }} className="close-btn-icon" />
            </div> :
            null
        }
        <div className="orders-filter-header">

          <div className="filter-title">{t('_order_search_client')}</div>

        </div>
        <form onSubmit={handleSubmit(() => onSubmit())}>
          <div className="orders-filter-body">
            <div className="orders-filter-input-item">
              <Controller
                control={control}
                name="status"
                render={({ field: { onChange, value } }) => {
                  return (
                    <Select
                      value={value}
                      className="select-dropdown"
                      onChange={(value) => onChange(value)}
                      options={getStatusOptions()}
                      placeholder={t('_status')}
                      styles={{
                        menu: provided => ({ ...provided, zIndex: 50 })
                      }}
                      isSearchable={!isMobile && !isTablet}
                    />
                  );
                }}
              />
            </div>
            <div className="orders-filter-input-item">
              <div className={`orders-react-picker-dropdown ${openDate ? 'disable' : ''}`} onClick={() => setOpenDate(true)}>
                <TextInput readOnly placeholder={t('_select_date')} defaultValue={valueDate ? valueDate : ''} />
              </div>
              {openDate && (
                <div className="orders-date-picker" ref={dateRef}>
                  <DatePicker
                    {...register('date')}
                    onChange={onDateChange}
                    inline
                    locale={i18n.language.split('-')[0]}
                  />
                </div>
              )}
            </div>
            <div className="orders-filter-input-item">
              <TextInput {...register('id')} placeholder={t('_order_number')} />
            </div>
            <div className="orders-filter-input-item">
              <Controller
                control={control}
                name="client"
                render={({ field: { onChange, value } }) => {
                  return (
                    <Select
                      value={value}
                      className="select-dropdown"
                      onChange={(value) => onChange(value)}
                      options={getClientsOptions()}
                      placeholder={t('_client')}
                      styles={{
                        menu: provided => ({ ...provided, zIndex: 50 })
                      }}
                      isSearchable={!isMobile && !isTablet}
                    />
                  );
                }}
              />
            </div>
            <DefaultButton text={t('_filter')} type="submit" />
            <DefaultButton red text={t('_reset')} onClick={() => { handleResetFilter(); setIsActive(false); }} />
          </div>
        </form>
      </div>
      <div className="orders-table">
        <OrderClientsTable data={data} handlePageClick={handlePageClick} currentPage={currentPage} totalPage={totalPage} loading={loading} />
      </div>
    </div >
  );
};

export default OrdersClients;
