
import TextInput from 'components/inputs/TextInput/TextInput';
import Select from 'react-select';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useHttp from 'utils/hooks/useHttp';
import './ConsumableEdit.scss';

import UploadImage from '../../../../components/UploadImage/UploadImage';
import Spinner from 'components/spinner/Spinner';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { notify } from 'utils/marketplace';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { IConsumableInfo, IConsumableSelect } from 'interfaces/consumable';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import UploadPdf from 'components/UploadPdf/UploadPdf';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';

type Props = {
  setOpenModal: (e: boolean) => void;
  setSubmit: (e: boolean) => void;
  consumable: number | undefined;
};


const ConsumableEdit: React.FC<Props> = (props) => {

  let isMounted = true;
  const { t } = useTranslation();
  const http = useHttp();
  const user = JSON.parse(localStorage.getItem('userData')!);

  const [brands, setBrands] = useState<IConsumableSelect[]>([]);
  const [brand, setBrand] = useState<IConsumableSelect | null>();
  const { isMobile, isTablet } = useDeviceDetect();
  const [categories, setCategories] = useState<IConsumableSelect[]>([]);
  const [category, setCategory] = useState<IConsumableSelect | null>();
  const [loadingErpPartInfo, setLoadingErpPartInfo] = useState<boolean>(false);
  const [image, setImage] = useState<File[]>([]);
  const [file, setFile] = useState<File[]>([]);
  const [consumable, setConsumable] = useState<IConsumableInfo>();
  const [loading, setLoading] = useState<boolean>(false);


  const validate = Yup.object().shape({
    reference: Yup.string().trim().required(t('_fild_required')),
    // gencode: Yup.string().trim().required(t('_fild_required')),
    // conditioning: Yup.string().trim().required(t('_fild_required')),
    // unity: Yup.string().trim().required(t('_fild_required')),
    // description: Yup.string().trim().required(t('_fild_required')),
    short_description: Yup.string().trim().required(t('_fild_required')),
    //image: Yup.string().required(t('_fild_required')),

  });

  const { getValues, resetField, register, handleSubmit, clearErrors, setValue, control, formState: { errors }, } = useForm<IConsumableInfo>({ resolver: yupResolver(validate) });

  useEffect(() => {

    if (props.consumable && isMounted) {
      Promise.all([
        http.consumableHttp.getConsumableBrand({ per_page: 1000 }),
        http.consumableHttp.getConsumableCategory({ per_page: 1000 }),
        http.consumableHttp.getConsumableInfo(props.consumable)])
        .then((res) => {

          const setBrandData = res[0].data.data.map((item) => {
            return { value: item.id, label: item.name };
          });

          setBrands(setBrandData);

          const setCategoryData = res[1].data.data.map((item) => {
            return { value: item.id, label: item.name };
          });

          setCategories(setCategoryData);
          setConsumable(res[2].data);

          setBrand({ value: res[2].data.brand.id, label: res[2].data.brand.name });
          setCategory({ value: res[2].data.category.id, label: res[2].data.category.name });
          setValue('image', res[2].data?.image ? res[2].data?.image : '');
        });
    }


    return () => {
      isMounted = false;
    };

  }, []);

  const getErpPart = () => {
    let erpClientId = null;
    const values = getValues();
    const reference = values?.reference.trim();
    const brand_id = brand?.value;

    if (localStorage.getItem('infoSelectClientErp')) {
      const info = JSON.parse(localStorage.getItem('infoSelectClientErp') as string);

      erpClientId = info.erpClientId;
    }

    if (erpClientId == null || reference == '' || brand_id == undefined) {
      if (erpClientId == null) {
        notify(t('_select_client'), 'error', t('_error'));
      }

      if (reference == '') {
        notify(t('_reference'), 'error', t('_error'));
      }

      if (brand_id == undefined) {
        notify(t('_brand_id'), 'error', t('_error'));
      }

      return;
    } else {

      const param = {
        erp_id: erpClientId,
        reference: reference,
        brand_id: brand_id,
        type: 'consumable'
      };

      setLoadingErpPartInfo(true);

      if (values.short_description) {
        resetField('short_description');
      }

      http.retailerHttp.getErpPart(param).then((data) => {

        if (data.data?.description == undefined) {
          notify(t('_not_found_description'), 'error', t('_error'));
        } else {
          setValue('short_description', data.data?.description);
        }

      }).finally(() => {
        setLoadingErpPartInfo(false);
      });
    }


  };

  const onSubmit = async () => {
    setLoading(true);
    const values = getValues();

    const formData: FormData = new FormData();

    formData.append('reference', values.reference.trim());
    formData.append('brand', String(brand?.value));
    formData.append('category', String(category?.value));
    formData.append('gencode', String(values.gencode).trim());
    formData.append('conditioning', String(values.conditioning).trim());
    formData.append('description', values.description.trim());
    formData.append('short_description', values.short_description.trim());
    // formData.append('unity', String(values.unity).trim());
    formData.append('top_offer', String(values.top_offer));

    if(user?.hasTopSales){ 
      formData.append('top_sales', String(values.top_sales));
    }

    if (file.length) {
      formData.append('file', file[0]);
    }

    if (image.length) {
      formData.append('image', image[0]);
    }

    http.consumableHttp.postConsumable(Number(props?.consumable), formData).then(() => {
      notify(t('_consumable_updated'), 'success', t('_success'));
    })
      .finally(() => {
        setLoading(false);
        props.setOpenModal(false);
        props.setSubmit(true);
      });

  };


  return (
    <div className="consumable-edit-container" >
      <div className="close-modal" onClick={() => { props.setOpenModal(false); }}><FontAwesomeIcon icon={faClose} /></div>

      <div className="title">
        {t('_consumable_title_edit')}
        <br />
        <span>{t('_consumable_title_edit_catalog')}</span>
      </div>
      {
        !consumable ?
          <div className="spinner-info"> <Spinner />  </div> :
          <React.Fragment >
            <div className="info-item">
              {t('_consumable_item_count')}
              <br />
              <span>{consumable.brand.name} {consumable?.reference}</span>
            </div>

            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="consumable-edit-body">
                <div className="row">
                  <div className="container">
                    <label>
                      {t('_consumable_brand')} <span className="placeholderRed"> * </span>
                    </label>
                    <Controller
                      control={control}
                      name="brand"
                      render={({ field: { onChange } }) => (
                        <Select
                          value={brand}
                          className="select-dropdown "
                          onChange={(val) => {
                            onChange(val?.value);
                            setBrand(val);
                          }}
                          options={brands}
                          isSearchable={!isMobile && !isTablet}
                        />
                      )}
                    />
                  </div>
                  <div className="container">
                    <label>
                      {t('_category')} <span className="placeholderRed"> * </span>
                    </label>
                    <Controller
                      control={control}
                      name="category"
                      render={({ field: { onChange } }) => (
                        <Select
                          value={category}
                          className="select-dropdown "
                          onChange={(val) => {
                            onChange(val?.value);
                            setCategory(val);
                          }}
                          options={categories}
                          isSearchable={!isMobile && !isTablet}
                        />
                      )}
                    />
                  </div>
                  <div className={`container ${errors.reference?.type == 'required' ? 'error' : ''}`}>
                    <label>
                      {t('_reference')}  <span className="placeholderRed"> * </span>
                    </label>
                    <TextInput defaultValue={consumable?.reference} {...register('reference')} />
                    <p className="error-message">{errors.reference?.type == 'required' ? errors.reference?.message : ''}</p>
                  </div>

                  <div className="container">
                    <label>
                      {t('_gencode')}
                    </label>
                    <TextInput defaultValue={consumable?.gencode} {...register('gencode')} />
                    <p className="error-message">{errors.gencode?.type == 'required' ? errors.gencode?.message : ''}</p>
                  </div>
                </div>

                <div className="row">
                  <div className="container">
                    <label>
                      {t('_conditioning')} / {t('_unity')}
                    </label>
                    <TextInput defaultValue={consumable?.conditioning} {...register('conditioning')} />
                    <p className="error-message">{errors.conditioning?.type == 'required' ? errors.conditioning?.message : ''}</p>
                  </div>

                  {/*<div className={`container ${errors.unity?.type == 'required' ? 'error' : ''}`}>*/}
                  {/*  <label>*/}
                  {/*    {t('_unity')}  <span className="placeholderRed"> * </span>*/}
                  {/*  </label>*/}
                  {/*  <TextInput defaultValue={consumable?.unity} {...register('unity')} />*/}
                  {/*  <p className="error-message">{errors.unity?.type == 'required' ? errors.unity?.message : ''}</p>*/}
                  {/*</div>*/}
                </div>

                <div className="row">
                  <div className="container top-offer-checkbox-container">
                    <label htmlFor="top_offer">{t('_top_offer')}</label>
                    <input type="checkbox" id="top_offer" defaultChecked={consumable?.top_offer} {...register('top_offer')} />
                  </div>
                  {user?.hasTopSales ?
                    <div className="container top-offer-checkbox-container">
                      <label htmlFor="top_sales">{t('_top_sales')}</label>
                      <input type="checkbox" id="top_sales" defaultChecked={consumable?.top_sales} {...register('top_sales')} />
                    </div>
                    : null }
                </div>
                <div className="row">


                  <div className={`container ${errors.image?.type == 'required' ? 'error' : ''}`}>
                    <UploadImage setFile={setImage} image={consumable?.image} errors={errors} setValue={setValue} clearErrors={clearErrors} />
                    <span className="upload_image_info"> {t('_upload_image_info')}</span>
                  </div>

                  <div className={`container ${errors.file?.type == 'required' ? 'error' : ''}`}>
                    <UploadPdf setFile={setFile} errors={errors} pdf={consumable?.file} clearErrors={clearErrors} setValue={setValue} title="_technical_sheet" />
                  </div>
                </div>

                <div className="row">
                  <div className={`container ${errors.short_description?.type == 'required' ? 'error' : ''}`}>
                    <label>
                      {t('_short_description')} <span className="placeholderRed"> * </span>
                    </label>
                    <TextInput defaultValue={consumable?.short_description} {...register('short_description')} />
                    <p className="error-message">{errors.short_description?.type == 'required' ? errors.short_description?.message : ''}</p>
                  </div>
                </div>

                <div className="row file-container">
                  <div className="container">

                    <div className="description_info">
                      <label>
                        {t('_consumable_description')}
                      </label>
                      <DefaultButton type="button" red text={t('_get_erp_description')} onClick={() => getErpPart()} isloading={loadingErpPartInfo} />
                    </div>
                    <textarea rows={7} defaultValue={consumable?.description}
                      {...register('description', {
                      })}
                    />
                    <p className="error-message">{errors.description?.type == 'required' ? errors.description?.message : ''}</p>
                  </div>


                </div>
              </div>

              <div className="btn-save">
                <button type="submit" className={loading ? 'disabled' : ''}> {loading ?
                  <Spinner class="extra-small" /> : null}<div className="title-btn-save">{t('_consumable_update_article')}</div>
                </button>
              </div>
            </form>
          </React.Fragment>
      }

    </div>

  );

};

ConsumableEdit.displayName = 'ConsumableEdit';

export default ConsumableEdit; 