import React from 'react';
import './OrderInProgress.scss';
import ORDER_IN_PROGRESS_IMG from 'assets/images/order_in_progress.svg';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const OrderInProgress: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div
      onClick={() => {
        navigate('/order/list');
      }}
      className="order-in-progress"
    >
      <div className="title">{t('_order_in_progress')}</div>
      <div className="upload-btn">
        <div className="icon">
          <img src={ORDER_IN_PROGRESS_IMG} alt="" />
        </div>
        <div className="text">{t('_display_status_order_in_progress')}</div>
      </div>
    </div>
  );
};

OrderInProgress.displayName = 'OrderInProgress';

export default OrderInProgress;
