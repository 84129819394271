
import React, { useState } from 'react';
import IconItem from '../../../../../components/Item/IconItem/IconItem';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { IActiaSessionRequest, ISessionModal } from '../../../../../components/DiagnosticItems/Actia/actia';
import './SessionModal.scss';
import save from 'assets/Icons/save.svg';
import Spinner from '../../../../../components/spinner/Spinner';
import useHttp from 'utils/hooks/useHttp';
import { userSlice } from '../../../../../store/reducers/useSlices';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store/store';
import { notify } from '../../../../../utils/marketplace';
import { useParams, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

type Props = {
  setOpenModal: (e: boolean) => void;
  values: ISessionModal;
  onDone: () => void;
}

const SessionModal: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { actiaHttp } = useHttp();
  const { car } = useSelector((state: RootState) => state.carSelected);
  const { vehicle_id } = useParams();
  const { user } = useSelector((state: RootState) => state.userData);
  const [searchParams] = useSearchParams();


  const [loading, setLoading] = useState(false);
  const defaultValues = props.values;


  const validate = Yup.object().shape({
    first_name: Yup.string().trim().required(t('_fild_required')),
    last_name: Yup.string().trim().required(t('_fild_required')),
    email: Yup.string().trim().email(t('_valid_email')).required(t('_fild_required')),
    phone: Yup.string().trim().required(t('_fild_required')),
  });

  const {
    getValues,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ISessionModal>({ defaultValues, resolver: yupResolver(validate) });


  const onSubmit = () => {
    const values = getValues();

    const request: IActiaSessionRequest = {
      brand: car?.manufacturer_name ?? '-',
      email: values.email,
      first_name: values.first_name,
      ktypnr: String(vehicle_id),
      last_name: values.last_name,
      model: car?.model_name ?? '-',
      numcli: String(user?.client_id),
      phone_number: values.phone,
    };

    if (searchParams.get('plate_number')) {
      request.registration_plate = searchParams.get('plate_number') ?? '';
    }

    if (car?.vin) {
      request.vin = car.vin;
    }

    if (!props.values.id) {
      handleCreate(request);
    } else {
      handleEdit(request);
    }

  };

  const handleCreate = (request: IActiaSessionRequest) => {
    setLoading(true);
    actiaHttp.createSession(request)
      .then((data) => {
        props.setOpenModal(false);
        props.onDone();
      })
      .catch((e) => {
        if (Array.isArray(e)) {
          e.map((errorItem) => {

            if (Array.isArray(errorItem)) {
              errorItem.map((item) => {
                const message = `${item.propertyPath}: ${item.message}`;

                notify(message);
              });
            }
          });
        } else if (typeof e === 'string') {
          notify(t(e));
        }


        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleEdit = (request: IActiaSessionRequest) => {
    setLoading(true);
    actiaHttp.createSession(request)
      .then(() => {

        props.setOpenModal(false);
        props.onDone();
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="session-modal">
      <div className="close" onClick={() => props.setOpenModal(false)}>
        <IconItem icon={faClose} onClick={() => null} />
      </div>
      <div className="modal-dialog">

        <div className="modal-container">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="modal-header">
              <p>
                {t('_session')}
              </p>
            </div>

            <div className="modal-body">
              <div className="row">
                <div className="first-name form-field">
                  <label htmlFor="#first_name">{t('_firstname')}</label>
                  <div className="text-input-container">
                    <input
                      className="text-input"
                      id="first_name"
                      type="text"
                      placeholder=""
                      {...register('first_name')}
                    ></input>
                    {errors?.first_name?.type === 'required' && <p className="error-message">{errors.first_name?.message}</p>}

                  </div>
                </div>
                <div className="last-name form-field">
                  <label htmlFor="#last_name">{t('_lastname')}</label>
                  <div className="text-input-container">
                    <input
                      id="last_name"
                      className="text-input"
                      type="text"
                      placeholder=""
                      {...register('last_name')}
                    ></input>
                    {errors?.last_name?.type === 'required' && <p className="error-message">{errors.last_name?.message}</p>}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="email form-field">
                  <label htmlFor="#email">{t('_email')}</label>
                  <div className="text-input-container">
                    <input
                      id="email"
                      className="text-input"
                      type="text"
                      placeholder=""
                      {...register('email', { required: true })}
                    ></input>
                    {errors?.email?.type === 'required' && <p className="error-message">{errors?.email?.message}</p>}
                    {errors?.email?.type === 'email' && <p className="error-message">{errors?.email?.message}</p>}
                  </div>
                </div>
                <div className="phone form-field">
                  <label htmlFor="#phone">{t('_phone')}</label>
                  <div className="text-input-container">
                    <input
                      id="phone"
                      className="text-input"
                      type="text"
                      placeholder=""
                      {...register('phone', { required: true })}
                    ></input>
                    {errors?.phone?.type === 'required' && <p className="error-message">{errors.phone?.message}</p>}
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <div className="separator"></div>
              <div className="save" onSubmit={onSubmit}>
                <button type="submit" >
                  <span>
                    {
                      loading ?
                        <Spinner class="extra-small" />
                        :
                        <img alt="save" src={save} />
                    }
                  </span>
                  {t('_save')}
                </button>
              </div>
            </div>
          </form>

        </div>
      </div >
    </div >

  );
};

SessionModal.displayName = 'SessionModal';

export default SessionModal;