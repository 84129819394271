import React, { useEffect, useState } from 'react';
import '../Navbar.scss';
import useHttp from 'utils/hooks/useHttp';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { setQuantityToolbar } from 'store/slices/cart.slice';
import { useAppDispatch } from 'utils/hooks/redux';
import AccountDropdown from 'components/dropdowns/AccountDropdown/AccountDropdown';
import CartButton from 'components/buttons/CartButton/CartButton';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import Sidenav from 'applications/retailer/components/SidenavMobile/Sidenav';
import SidenavMobileMecadepot from 'applications/retailer/components/SidenavMobileMecadepot/SidenavMobileMecadepot';

const NavbarMobile: React.FC = () => {
  const { quantityToolbar } = useSelector((state: RootState) => state.cart);
  const { user } = useSelector((state: RootState) => state.userData);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const http = useHttp();
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      http.cart.getCartQuantity().then((res) => {
        dispatch(setQuantityToolbar(res.quantity));
      });
    }, 700);
  }, []);

  const handleClick = () => {
    setIsActive(true);
  };

  return (
    <div className="navbar">
      <div className="sidebar-mobile">
        <FontAwesomeIcon icon={faBars} className="icon" onClick={handleClick} />
      </div>
      {user?.client_logo ? (
        <div className="logos" onClick={() => navigate('/')}>
          <div className="garage-michel">
            <img
              src={
                sessionStorage.getItem('logo_url') !== 'null' && sessionStorage.getItem('logo_url')
                  ? sessionStorage.getItem('logo_url')!
                  : String(user?.client_logo)
              }
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = '/images/car-part-placeholder.png';
              }}
            />
          </div>
        </div>
      ) : null}
      <div className="user-settings">
        <AccountDropdown />
        <CartButton quantityToolbar={quantityToolbar} />
      </div>
      {
        user?.numcli == Number(process.env.REACT_APP_MECADEPOT_NUMCLI) ?
          <SidenavMobileMecadepot isActive={isActive} setIsActive={setIsActive} /> :
          <Sidenav isActive={isActive} setIsActive={setIsActive} />
      }
    </div>
  );
};

NavbarMobile.displayName = 'NavbarMobile';

export default NavbarMobile;
