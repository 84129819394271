import React from 'react';
import { faClose, faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { INotificationResponse } from 'utils/hooks/http/notifications.http';
import './NotificationModal.scss';
import { getMarketplaceBaseUrl } from 'utils/marketplace';

type Props = {
    setOpen: (val: boolean) => void
    data?: INotificationResponse
}

const NotificationModal:React.FC<Props> = (props) => {
  const {t} = useTranslation();

  function download(item){
    const url = getMarketplaceBaseUrl() + item.payload.file;
    const link = document.createElement('a');

    link.href = url;
    link.setAttribute('download', item.payload.original_file);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return (
    <div className="notification-modal">
      <div className="notification-modal-header">
        <div className="notification-modal-header-title">{t('_notification')}</div>
        <div className="notification-modal-header-close"><FontAwesomeIcon icon={faClose} onClick={() => props.setOpen(false)}/></div>
      </div>
      <div className="notification-modal-content">
        <div className="notification-modal-content-message">
          <div className="notification-modal-content-message-label">
            <span>{t('_message')}:</span>
            <span>{props.data?.created_at}</span>
          </div>
          <div className="notification-modal-content-message-value">
            {props.data?.message}
          </div>
        </div>
        {props.data?.payload.file && 
        <div className="notification-modal-content-file" >
          <span className="notification-download-icon"><FontAwesomeIcon icon={faDownload} onClick={() => download(props.data)}/></span>
          <span className="notification-filename">{props.data?.payload.original_file}</span>
        </div>}
      </div>
    </div>
  );
};

export default NotificationModal;