import { IParams } from 'models/order';
import { IHttpResponse, serverAxios } from '../../http';
import { IDataResponseStock, IDataSuppliers, IDataSuppliersType } from 'models/stock';
import { CancelToken } from 'axios';

class StockHttp {
  private static route = '/retailer/stock-management';
  private static suppliers = '/retailer/stock-management/short-list';
  private static types = 'retailer/stock-management/short-list-all-type';


  public async getStock(params: IParams, cancelToken: CancelToken): Promise<IHttpResponse<IDataResponseStock>> {
    return serverAxios.get(StockHttp.route, { params: params, cancelToken: cancelToken });
  }
  public async getSuppliers(): Promise<IHttpResponse<IDataSuppliers[]>> {
    return serverAxios.get(StockHttp.suppliers);
  }
  public async getSupplierTypes(): Promise<IHttpResponse<IDataSuppliersType>> {
    return serverAxios.get(StockHttp.types);
  }
  public async createStock(data: { reference: string; data_supplier: number; quantity: number; }): Promise<IHttpResponse<{}>> {
    return serverAxios.post(StockHttp.route, data);
  }

  public async updateStock(id: string, data: { reference: string; data_supplier: number; quantity: number; }): Promise<IHttpResponse<null>> {

    return serverAxios.patch(`${StockHttp.route}/${id}`, data);
  }
  public async deleteStock(id: string): Promise<IHttpResponse<null>> {

    return serverAxios.delete(`${StockHttp.route}/${id}`);
  }
}

export default StockHttp;
