import React, {useEffect, useRef, useState} from 'react';
import RefContentView from './RefContent.view';
import { debounce } from 'lodash';
import useHttp from 'utils/hooks/useHttp';
import {IData} from 'utils/hooks/http/search-by-reference.http';
import axios from 'axios';
import {useSelector} from 'react-redux';
import {getCurrentCatalog} from '../../../../store/selectors/catalogs.selector';

type Props = {};

const RefContent: React.FC<Props> = () => {
  const http = useHttp();
  const [inputValue, setInputValue] = useState('');
  const [refDataAutocomplete, setRefDataAutocomplete] = useState<IData[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const cancelToken = axios.CancelToken.source();
  const currentCatalog = useSelector(getCurrentCatalog);


  const debouncedSearch = useRef(
    debounce(async (input) => {
      getRefData(input);
      setIsOpen(true);
    }, 500),
  ).current;

  useEffect(() => {
    if (inputValue.length >= 3) {
      getRefData(inputValue);

      return () => {
        cancelToken.cancel();
      };
    } else {
      setIsLoading(false);
      setRefDataAutocomplete([]);
      setIsOpen(false);
    }

  }, [inputValue]);

  useEffect(() => {
    if(isOpen && inputValue.length >= 3 && currentCatalog?.id){
      getRefData(inputValue);
    }
  }, [currentCatalog?.id]);


  function getRefData(q) {
    if(q.length < 3) {return;}

    const catalog_id = currentCatalog?.id;

    if(!catalog_id) {
      setIsLoading(true);
      setIsOpen(true);

      return;
    }
    
    setIsLoading(true);
    setIsOpen(true);

    http.searchByRef
      .searchByRefAutocompleteCatalog(q, catalog_id, cancelToken.token)
      .then((res) => {
        setRefDataAutocomplete(res.data);
        setIsLoading(false);
      })
      .catch(() => {
        console.log('cancelToken');
      });
  }

  function refInputChange(event) {
    const inputData = event.target.value.replace(/\s/g, '').length > 0 ? event.target.value : event.target.value.trim();
    //
    // if (inputData.length >= 4) {
    //   debouncedSearch(inputData);
    // }

    setInputValue(inputData);


    if (inputData.length === 0) {
      setRefDataAutocomplete([]);
      setIsOpen(false);
    }
  }

  const onSubmit = (data) => {
    if(!data) {return;}

    getRefData(data.reference);
    setIsOpen(true);
  };


  return (
    <RefContentView
      onSubmit={onSubmit}
      isLoading={isLoading}
      refInputChange={refInputChange}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      refDataAutocomplete={refDataAutocomplete}
      inputValue={inputValue}
      getRefData={getRefData}
    />
  );
};

RefContent.displayName = 'RefContent';

export default RefContent;
