import React, { useContext, useEffect, useState } from 'react';
import UploadProformaAmerigoFile from '../UploadProformaAmerigoFile/UploadProformaAmerigoFile';
import ProformaSupplierList from '../ProformaSupplierList/ProformaSupplierList';
import { IUploadProformaContext, UploadProformaAmerigoContext } from 'context/UploadProformaAmerigoContext';
import ProformaRows from './ProformaRows/ProformaRows';
import { useTranslation } from 'react-i18next';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import { IProformaAmerigoRowItem } from 'utils/hooks/http/proforma.http';
import './SendFileModalContent.scss';
import useHttp from 'utils/hooks/useHttp';
import { useDispatch } from 'react-redux';
import { setProformaActive } from 'store/slices/proforma.slice';
import { notify } from 'utils/marketplace';
import { useNavigate, useParams } from 'react-router-dom';

const SendFileModalContent: React.FC = () => {
  const { uploadFileResponse, fileName, selectedSupplier } = useContext(UploadProformaAmerigoContext) as IUploadProformaContext;
  const [loading, setLoading] = useState<boolean>(false);
  const [buttonDisable, isButtonDisable] = useState<boolean>(false);
  const { t } = useTranslation();
  const http = useHttp();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: catalogId } = useParams();

  useEffect(() => {
    if (uploadFileResponse) {
      isButtonDisable(!uploadFileResponse.rows?.AVAILABLE?.length && !uploadFileResponse.rows?.AVAILABILITY_NOT_FOUND?.length);
    }
  }, [uploadFileResponse]);

  const handleAddToCart = async () => {
    if (!uploadFileResponse) {
      return;
    }

    let items: IProformaAmerigoRowItem[] = [];

    if (uploadFileResponse.rows.AVAILABLE) {
      items = [...items, ...uploadFileResponse.rows.AVAILABLE];
    }

    if (uploadFileResponse.rows.AVAILABILITY_NOT_FOUND) {
      items = [...items, ...uploadFileResponse.rows.AVAILABILITY_NOT_FOUND];
    }

    try {
      setLoading(true);


      const param: { dlnr: string, catalog_id?: string } = {
        ...{ dlnr: String(selectedSupplier?.value) },
        ...(catalogId && { catalog_id: String(catalogId) })

      };

      await http.proformaAmerigo.orderProformaAmerigoFile(param, items);
      notify('SUCCESS', 'success');

      if (catalogId) {
        navigate(`/catalog/${catalogId}/orders`);
      } else {
        navigate('/orders');
      }
    } catch (e) {
      console.log(e);
      notify(t('_error'));
    } finally {
      setLoading(false);
      dispatch(setProformaActive(false));
    }
  };

  return (
    <div>
      <div id="send-file-modal-content" className="modal-container">
        <div className="modal-body">
          <div className="upload-file">
            <UploadProformaAmerigoFile />
            <div className="file-name">
              <p>{fileName}</p>
            </div>
            <div className="items-length">
              <p>
                {uploadFileResponse?.count} {t('ITEMS')}
              </p>
            </div>
          </div>
          <div className="data">
            <ProformaRows />
          </div>
          <div className="footer">
            <div className="supplier-list">
              <ProformaSupplierList />
            </div>
            <div className="add-to-cart">
              <DefaultButton disabled={buttonDisable} isloading={loading} onClick={handleAddToCart} text={t('_create_order')} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

SendFileModalContent.displayName = 'SendFileModalContent';

export default SendFileModalContent;
