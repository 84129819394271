import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import useCollapse from 'react-collapsed';
import { useSearchParams } from 'react-router-dom';
import './CollapseList.scss';
import ItemGroup from './ItemGroup';

type Props = {
    data?
    onSubGroupClick?: (item) => void;
    onItemMpClick?: (item) => void;
    iframeInfo?: boolean;
}

const SubGroup:React.FC<Props> = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [isExpanded, setIsExpanded] = useState(String(props.data.SubGroupId) === searchParams.get('subgroup_id'));

  useEffect(() => {
    setIsExpanded(String(props.data.SubGroupId) === searchParams.get('subgroup_id'));
  }, [searchParams.get('subgroup_id')]);

  function handleOnClick() {
    setIsExpanded(!isExpanded);
  }

  const config={
    isExpanded: isExpanded,
    expandStyles: {
      height: '100%'
    },
    duration: 0,
  };
    
  const { getCollapseProps, getToggleProps } = useCollapse(config);

  return (
    <div className="collapse-list">
      <div className="item level-2">
        <div className="collapse-item" {...getToggleProps({onClick: handleOnClick})}>
          <FontAwesomeIcon icon={isExpanded ? faChevronDown : faChevronRight}/>
          <div>{props.data.SubGroupName}</div> 
        </div>
        {
          props.iframeInfo && (
            <span className="iframe-info-btn" onClick={() => props.onSubGroupClick!(props.data)}>i</span>
          )
        }
      </div>
      {props.data.ItemMps.map((itemMp, index) => {  
        return (
          <div {...getCollapseProps()} key={index}>
            <ItemGroup data={itemMp} onItemMpClick={props.onItemMpClick} />
          </div>
        );
      })}
    </div>
  );
};

export default SubGroup;