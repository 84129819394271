import React, { ReactElement, useState } from 'react';
import TabTitle from './TabTitle';
import './TabStyle.scss';
import { SwiperSlide } from 'swiper/react';
import SwiperSlider from 'components/SwiperSlider/SwiperSlider';
import { Tab } from 'react-tabs';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';

type Props = {
  children: ReactElement[];
};

const Tabs: React.FC<Props> = (props) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const { isMobile } = useDeviceDetect();

  return (
    <div className="tabs-container">
      <div className="tabs">
        {
          isMobile ? (
            <SwiperSlider
              slidesPerView="auto"
              centeredSlides
            >
              {
                props.children.map((item, index) => {
                  return (
                    <SwiperSlide className="swiper-slide" key={index}>
                      <TabTitle key={index} title={item.props.title} index={index} selectedTab={selectedTab}
                        setSelectedTab={setSelectedTab}
                      />
                    </SwiperSlide>
                  );
                })
              }
            </SwiperSlider>
          ) : (
            <React.Fragment>
              {props.children.map((item, index) => (
                <TabTitle key={index} title={item.props.title} index={index} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
              ))}
            </React.Fragment>
          )
        }
      </div>
      {props.children[selectedTab]}
    </div>
  );
};

Tabs.displayName = 'Tabs';

export default Tabs;
