import React, { PropsWithChildren } from 'react';
import IconNotificationView from './IconNotification.view';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

type Props = {
  icon: IconProp;
  number: number;
  onClick: () => void;
};

const IconNotification: React.FC<Props> = (props: PropsWithChildren<Props>) => {
  return <IconNotificationView icon={props.icon} onClick={props.onClick} number={props.number} />;
};

IconNotification.displayName = 'IconNotification';

export default IconNotification;
