import React, { useEffect, useState } from 'react';
import { ReactComponent as PartsNotFoundImage } from 'assets/images/parts-not-found.svg';
import Pagination from 'components/pagination/Pagination';
import Spinner from 'components/spinner/Spinner';
import { useTranslation } from 'react-i18next';
import useHttp from 'utils/hooks/useHttp';
import { useSearchParams } from 'react-router-dom';
import { IBatteryCatalogFilter, IBatterySearch, IBodyResponseBattery } from 'interfaces/battery';
import CatalogBatteryFilter from 'pages/CatalogVehicle/Catalogs/CatalogsBatteriesPage/CatalogBatteryFilter/CatalogBatteryFilter';
import TextInput from 'components/inputs/TextInput/TextInput';
import BatteryDropdownContent from 'components/BatteryDropdownContent/BatteryDropdownContent';
import { useForm } from 'react-hook-form';
import CatalogNavbar from 'components/navbar/catalog-navbar/CatalogNavbar';
import './Batteries.scss';
import ProductsViewMode from 'components/ProductsViewMode/ProductsViewMode';
import { DEFAULT_VIEW } from 'components/ProductsViewMode/ProductsViewMode.constants';
import ProductsViewModeChanger from 'components/ProductsViewModeChanger/ProductsViewModeChanger';
import { CATALOG_BATTERY } from 'components/ProductsViewModeChanger/products-view-mode-changer-utils';
import axios from 'axios';

const Batteries: React.FC = () => {
  const [searchParams] = useSearchParams();
  const { t, i18n } = useTranslation();
  const http = useHttp();
  const [loading, setLoading] = useState(true);
  const [refData, setRefData] = useState<IBodyResponseBattery>();
  const [filterData, setFilterData] = useState<IBatteryCatalogFilter>();
  const [openFilter, setOpenFilter] = useState(false);
  const [openBatteryDropdown, setOpenBatteryDropdown] = useState(false);
  const [batteryData, setBatteryData] = useState<IBatterySearch[]>([]);
  const [loadingBattery, setLoadingBattery] = useState(false);
  const { register, getValues, setValue } = useForm();
  const [page, setPage] = useState(Number(searchParams.get('page')));

  const user = JSON.parse(localStorage.getItem('userData')!);
  const [viewMode, setViewMode] = useState(user?.user_settings.products_view_mode || DEFAULT_VIEW);
  const cancelToken = axios.CancelToken.source();

  function getBatteriesData(data) {
    setLoading(true);
    const params = {
      ...data,
      page,
    };

    const validParams = validateParams(params);

    http.batteriesHttp
      .getBatteries(validParams, cancelToken.token)
      .then((res) => {
        setRefData(res.data);
      })
      .finally(() => setLoading(false));
  }

  function getBatteriesFilter(params) {
    const filterParams = {
      ...params,
      page,
    };

    const validFilterParams = validateParams(filterParams);

    http.batteriesHttp.getBatteryFilters(validFilterParams).then((res) => {
      setFilterData(res.data);
    });
  }

  const validateParams = (data) => {
    return Object.entries(data).reduce((acc, [key, value]) => {
      if (!value) {
        return acc;
      }

      acc[key] = value;

      return acc;
    }, {});
  };

  const handlePageClick = (pageN: number) => {
    setPage(pageN);
  };

  function batterySearch() {
    setOpenBatteryDropdown(true);
    setLoadingBattery(true);
    http.batteriesHttp.batterySearch({ q: getValues()?.battery }).then((res) => {
      setBatteryData(res.data);
    }).finally(() => setLoadingBattery(false));
  }


  useEffect(() => {
    const params = {
      category_ids: searchParams.getAll('category_ids'),
      brand_ids: searchParams.getAll('brand_ids'),
      ah: searchParams.getAll('ah'),
      amp: searchParams.getAll('amp'),
      battery_id: searchParams.get('battery_id'),
      q: searchParams.get('q')
    };

    setValue('battery', searchParams.get('q'));
    getBatteriesData(params);
    getBatteriesFilter(params);
  }, [i18n.language, page, searchParams.get('category_ids'), searchParams.get('brand_ids'), searchParams.get('q'), searchParams.get('ah'), searchParams.get('amp'), searchParams.get('battery_id')]);

  function filterOptionsElement() {
    return (
      <div className="catalog-parts-filter">
        <button onClick={() => setOpenFilter(true)} className={`catalog-parts-button ${openFilter ? 'active' : ''}`}>
          {t('_filter_results_btn')}
        </button>
      </div>
    );
  }

  function filterInputElement() {
    return (
      <div className="catalog-parts-filter">
        <div className="catalog-search-input-container">
          <TextInput
            placeholder="Ex: T170G"
            {...register('battery')}
            onClick={batterySearch}
            withIcon
          />
          {openBatteryDropdown && <BatteryDropdownContent loading={loadingBattery} setIsOpen={setOpenBatteryDropdown} data={batteryData} value={getValues()?.battery} />}
        </div>
      </div>
    );
  }

  return (
    <div className="batteries-wrapper">
      <CatalogNavbar option={filterOptionsElement()} input={filterInputElement()} title="_battery_catalog" />
      <div className="separator"></div>
      {
        !loading && (
          <div className="filterOptions">
            <div className="results">
              {refData?.total_count} {t('_results')}
            </div>

            <ProductsViewMode viewMode={viewMode} setViewMode={setViewMode} />

          </div>
        )
      }

      <div className="wrapperItems">
        {loading ? (
          <Spinner class="car-parts-spinner" />
        ) : refData?.data?.length ? (
          <ProductsViewModeChanger catalog={CATALOG_BATTERY} viewMode={viewMode} data={refData?.data} />
        ) : (
          <div className="no-parts-data">
            <div className="notFoundSvg">
              <PartsNotFoundImage />
            </div>
            <h1>{t('_batteries_not_found')}</h1>
          </div>
        )}
      </div>

      {refData && refData?.total_pages && refData?.page && refData?.total_pages > 1 ? (
        <Pagination pageCount={refData.total_pages} handlePageClick={handlePageClick} forcePage={refData.page} />
      ) : (
        ''
      )}
      <CatalogBatteryFilter getData={getBatteriesData} filterData={filterData} openFilter={openFilter} setOpenFilter={setOpenFilter} />
    </div>
  );
};

export default Batteries;
