
import {IBodyRequest, IBodyResponse } from 'interfaces/remaining';
import {  IHttpResponse, serverAxios } from '../../http';

class RemainingHttp {
  private static route = 'financial_history/list/V2';
  private static export = 'financial_history/export/v2';
  public async getList(param: IBodyRequest): Promise<IHttpResponse<IBodyResponse>> {
    return serverAxios.get(RemainingHttp.route, { params: param });
  }
  public async export(param: IBodyRequest): Promise<IHttpResponse<Blob>> {
    return serverAxios.get(RemainingHttp.export, { params: param , responseType: 'blob' });
  }

}

export default RemainingHttp;
