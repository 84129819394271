import React, { useEffect, useState } from 'react';

import { IHistory, IPagination } from '../../models/IHistory';
import Pagination from '../../components/pagination/Pagination';
import Spinner from '../../components/spinner/Spinner';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { converObjectToParams, getCurrentMarketplace, getURLParams } from '../../utils/marketplace';
import { IUrlParams } from '../../interfaces/marketplace';
import { useTranslation } from 'react-i18next';
import TextInput from 'components/inputs/TextInput/TextInput';
import './History.scss';
import { Marketplaces } from 'utils/constants/applications';

type Props = {
  isLoading: boolean;
  tabs: Tab[] | [];
  carsPagination: IPagination | null;
  partsPagination: IPagination | null;
  handleCarsPagination: (page: number) => void;
  handlePartsPagination: (page: number) => void;
  historyData: IHistory | null;
  register?;
  reset?;
  noChangeUrl?: boolean
  partView?: boolean
  hideSearch?: boolean
};

enum HistoryTabs {
  Vehicles = 'vehicles',
  DetachedPieces = 'pieces',
}

type Tab = { id: HistoryTabs; title: string; component: JSX.Element };
const c = 'history-page';

const HistoryView: React.FC<Props> = (props: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const urlParams: IUrlParams = getURLParams(location.search);
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const [tabState, setTabState] = useState<Tab | null>(props?.partView && props.partView == true ? props.tabs[1] : props.tabs[0]);
  const isAutomotor = getCurrentMarketplace() === Marketplaces.AUTOMOTOR;

  useEffect(() => {
    const selectedTab = props.tabs.find((tabItem) => tabItem.id === tabState?.id);

    if (selectedTab) {
      setTabState(selectedTab);

    } else {
      if (props?.partView && props.partView) {
        setTabState(props.tabs[1]);
      } else {
        const tabFromUrl = props.tabs ? props.tabs.find((tabItem) => tabItem.id === urlParams.tab) : null;

        setTabState(tabFromUrl ? tabFromUrl : props.tabs[0]);
      }

    }
  }, [props.tabs]);

  useEffect(() => {
    if (tabState && !props.noChangeUrl) {
      urlParams.tab = tabState.id;
      const urlParamsString: string = converObjectToParams(urlParams);

      isAutomotor ? navigate(`/page/history?${urlParamsString}`, { replace: true }) : navigate(`/history?${urlParamsString}`, { replace: true });
    }
  }, [tabState?.id]);

  const handleChangeTab = (tab: Tab) => {
    props.reset({ q_cars: '', q_parts: '' });
    setSearchParams({ ...searchParams });
    setTabState(tab);
  };

  return (
    <div className={c}>
      <div className={`${c}__wrapper`}>
        <div className={`${c}__wrapper-title`}>{t('_my_last_searches')}</div>

        {
          !props.hideSearch
            ?
            <React.Fragment>
              <div className={`${c}__wrapper-search ${tabState?.id === 'vehicles' ? 'show-cars' : ''}`}>
                <TextInput {...props.register('q_cars')} placeholder={t('_search_cars')} withIcon />
              </div>
              <div className={`${c}__wrapper-search ${tabState?.id === 'pieces' ? 'show-parts' : ''}`}>
                <TextInput {...props.register('q_parts')} placeholder={t('_search_parts')} withIcon />
              </div>
            </React.Fragment>
            : null
        }

        <div className={`${c}__wrapper-tabs`}>
          {props.tabs.map((tab: Tab, index) => {
            return (
              <div
                key={index}
                onClick={() => handleChangeTab(tab)}
                className={`${c}__wrapper-tabs-item  ${tabState?.id === tab.id && 'activeTabs'}`}
              >
                {t(tab.title)}
              </div>
            );
          })}
        </div>
        <div className={`${c}__wrapper-tabs_content`}>{props.isLoading ? <Spinner /> : tabState?.component}</div>
        {tabState?.id === 'vehicles' && !props.historyData?.cars.data.length && !props.isLoading ? (
          <div className={`${c}__wrapper-no_data`}> {t('_no_data')} </div>
        ) : null}
        {tabState?.id === 'pieces' && !props.historyData?.parts.data.length && !props.isLoading ? (
          <div className={`${c}__wrapper-no_data`}> {t('_no_data')} </div>
        ) : null
        }
        <div className={`${c}__wrapper-pagination`}>
          {tabState?.id === 'vehicles' && props.carsPagination && props.carsPagination.total_pages > 1 ? (
            <Pagination
              pageCount={props.carsPagination.total_pages}
              handlePageClick={props.handleCarsPagination}
              forcePage={props.carsPagination.page}
              hiddenParamPage={props.partView}
            />
          ) : null}
          {tabState?.id === 'pieces' && props.partsPagination && props.partsPagination.total_pages > 1 ? (
            <Pagination
              pageCount={props.partsPagination.total_pages}
              handlePageClick={props.handlePartsPagination}
              forcePage={props.partsPagination.page}
              hiddenParamPage={props.partView}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

HistoryView.displayName = 'HistoryView';

export default HistoryView;
