import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import useCollapse from 'react-collapsed';
import { useSearchParams } from 'react-router-dom';
import './CollapseList.scss';
import ItemGroup from './ItemGroup';

type Props = {
  data?;
  onSubGroupClick?: (item) => void;
  onItemMpClick?: (item) => void;
};

const SubGroup: React.FC<Props> = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();

  function handleExpand() {
    searchParams.set('subgroup_id', String(props.data.SubGroupId));
    setSearchParams(searchParams);
  }

  const config = {
    defaultExpanded: String(props.data.SubGroupId) === searchParams.get('subgroup_id'),
    expandStyles: {
      height: '100%',
    },
    duration: 0,
    onExpandStart: handleExpand,
  };

  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse(config);

  return (
    <div className="consumable-collapse-list">
      <div className="item level-2">
        <div className="collapse-item" {...getToggleProps()}>
          <FontAwesomeIcon icon={isExpanded ? faChevronDown : faChevronRight} />
          <div>{props.data.SubGroupName}</div>
        </div>
        <div className="info-btn-container">
          <span className="iframe-info-btn" onClick={() => props.onSubGroupClick!(props.data)}>
            i
          </span>
        </div>
      </div>
      {props.data.ItemMps.map((itemMp, index) => {
        return (
          <div {...getCollapseProps()} key={index}>
            <ItemGroup data={itemMp} onItemMpClick={props.onItemMpClick} />
          </div>
        );
      })}
    </div>
  );
};

export default SubGroup;
