import {useEffect, useState} from 'react';

const useTimerMessages = (interval = 10000): number => {
  const [time, setTime] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setTime((time) => time + 1);
    }, interval);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return time;
};

export default useTimerMessages;