import React, { ReactElement, ReactNode } from 'react';
import './MaintenanceTab.scss';

type Props = {
  title: string;
  children?: ReactNode;
  icon?: ReactElement
  onClick?: ()=> void
  disabled?: boolean
  activeParam?: string
};

const MaintenanceTab: React.FC<Props> = (props) => {
  return (
    <div>
      {props.children}
    </div>
  );
};

export default MaintenanceTab;
