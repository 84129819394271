import { serverAxios, addRouteParam } from '../../http';
import { IPartsCategory, IItemCategory, ICategiryByItem } from 'models/vehicle-catalogs';


class VehicleCatalogssHttp {
  private static categories = 'reliable/:vehicleId/categoriesV2';
  private static itemIdsByCategory = 'reliable/categories/itemMpIds';

  public async getCategories(vehicleId: string, params: { [key: string]: string }): Promise<IPartsCategory[]> {

    const { data } = await serverAxios.get(addRouteParam(VehicleCatalogssHttp.categories, { vehicleId }) + '?' + new URLSearchParams(params).toString());

    return data;
  }

  public async getItemIdsByCategory(params: IItemCategory[]): Promise<ICategiryByItem[]> {

    const { data } = await serverAxios.post(VehicleCatalogssHttp.itemIdsByCategory, params);

    return data;
  }

}

export default VehicleCatalogssHttp;
