import { AxiosError } from 'axios';
import PartsList from 'components/partsList/PartsList';
import CarPartView from 'components/partsView/CarPartView';
import Spinner from 'components/spinner/Spinner';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import { login } from 'store/reducers/useSlices';
import { Marketplaces } from 'utils/constants/applications';
import { ILoginResponse } from 'utils/hooks/http/auth.http';
import useCatalogVehicleRoutes from 'utils/hooks/use-catalog-vehicle-routes';
import useHttp from 'utils/hooks/useHttp';
import { getCurrentMarketplace, notify } from 'utils/marketplace';
import PublicLayout from './layouts/PublicLayout/PublicLayout';
import PublicHome from './pages/PublicHome/PublicHome';
import { countries } from 'utils/constants/locales';


const Public: React.FC = () => {
  const http = useHttp();
  const dispatch = useDispatch();
  const {t, i18n} = useTranslation();
  const [loading, setLoading] = useState(true);
  const catalogVehicleRoutes = useCatalogVehicleRoutes();

  function getPublicCredentials(){
    if(getCurrentMarketplace() === process.env.REACT_APP_MARKETPLACE_PUBLIC_TR){
      return {
        username: process.env.REACT_APP_AUTOLOGIN_PUBLIC_TR_EMAIL!,
        password: process.env.REACT_APP_AUTOLOGIN_PUBLIC_TR_PASSWORD!
      };
    }

    if(getCurrentMarketplace() === process.env.REACT_APP_MARKETPLACE_PUBLIC_MA){
      return {
        username: process.env.REACT_APP_AUTOLOGIN_PUBLIC_MA_EMAIL!,
        password: process.env.REACT_APP_AUTOLOGIN_PUBLIC_MA_PASSWORD!
      };
    }

    if(getCurrentMarketplace() === process.env.REACT_APP_MARKETPLACE_PUBLIC_TN){
      return {
        username: process.env.REACT_APP_AUTOLOGIN_PUBLIC_TN_EMAIL!,
        password: process.env.REACT_APP_AUTOLOGIN_PUBLIC_TN_PASSWORD!
      };
    }

    return {  
      username: process.env.REACT_APP_AUTOLOGIN_PUBLIC_DZ_EMAIL!,
      password: process.env.REACT_APP_AUTOLOGIN_PUBLIC_DZ_PASSWORD!
    };
  }

  function validCountryCode(userCountryCode){
    return countries.find(country => country.id === userCountryCode);
  }

  useEffect(()=>{
    setLoading(true);
    http.auth
      .publicLogin({domain: getCurrentMarketplace().slice(0, 2)})
      .then((res: ILoginResponse) => {
        localStorage.setItem('locale', `${res.user.language || 'en'}-${validCountryCode(res.user.client_country_code)?.id || 'FR'}`);
        i18n.changeLanguage(`${res.user.language || 'en'}-${validCountryCode(res.user.client_country_code)?.id || 'FR'}`);
        localStorage.setItem('token', res.token);
        localStorage.setItem('userData', JSON.stringify(res.user));
        
        dispatch(login(res.user));
      })
      .catch((error: AxiosError) => {
        if (error.response?.status == 401) {
          notify(t('_invalid_credentials'));
        } else {
          notify(t('_something_went_wrong'));
        }
      }).finally(() => setLoading(false));

  },[]);

  return (
    !loading ? <Routes>
      <Route element={<PublicLayout />}>
        {catalogVehicleRoutes}
        <Route path="" element={<PublicHome />} />
        <Route path="/car-parts" element={<PartsList />} />
        <Route path="/car-parts/view/:id" element={<CarPartView />} />
      </Route>
      <Route path="*" element={<Navigate to="/" />} />
    </Routes> : <Spinner />
  );
};

Public.displayName = 'Public';

export default Public;
