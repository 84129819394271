import i18n from 'i18n';
import  { Dispatch, SetStateAction, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import useHttp from 'utils/hooks/useHttp';
import { IDataModalGraphics, IGraphicsResponce, ISelectModal } from 'utils/rmi/interface/graphics.interface';

import { getCurrentCountryRMI, getCurrentLanguageRMI } from 'utils/rmi/rmi-utils/rmi-utils';
  

export const useFetchBodiesForGraphics = ({
  typeId,
  setLoading,
  setOpenModal , 
  setBoxDetailOptions,
  setParamType
}: {
    typeId:number | null;
    setLoading: (b: boolean) => void;
    setOpenModal: (b: boolean) => void;
    setBoxDetailOptions?;
    setParamType: (b: string) => void;
  }): void => {


  let componentMounted = true;
  const http = useHttp();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (typeId && searchParams.get('body_qualcol_id') === null && componentMounted) {
      setLoading(true);
      http.graphicstHttp.fetchRMIVehicleBodiesForGraphics({
        typeId,
        countryCode: getCurrentCountryRMI(i18n.language),
        languageCode: getCurrentLanguageRMI(i18n.language),
           
      })
        .then((res) => {
          if(res.data.length == 1)
          {
            searchParams.set('body_qualcol_id', String(res.data[0]?.QualColId));
            setSearchParams(searchParams);
          }else{

            const boxDetailListOptions: Array<ISelectModal> = [];
      
            res.data.forEach((opt: IGraphicsResponce): void => {
                
              boxDetailListOptions.push(
                {
                  value: opt.QualColId,
                  label: opt.QualColText,
                },
              );
                  
                  
            });
            setParamType('body_qualcol_id'); 
            setBoxDetailOptions(boxDetailListOptions);
            setOpenModal(true);
          }
            
        }).finally(()=>{
          setLoading(false);
        });

      return () => {
        componentMounted = false;
      };
    }

   
  }, [typeId, i18n.language]);
};


export const useFetchGraphicsHtml = ({
  setLoadingHTML,
  typeId,
  setIframeData
}: {
    setLoadingHTML: (b: boolean) => void;
    typeId:number | null;
    setIframeData: (html: string) => void
  }): void => {
 

  let componentMountedManual = true;
  const http = useHttp();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (typeId && searchParams.get('body_qualcol_id') !== null && searchParams.get('main_group_id') === null && componentMountedManual) {
      setLoadingHTML(true);
  
      http.graphicstHttp.fetchRMIVehicleManualGraphics({
        typeId,
        countryCode: getCurrentCountryRMI(i18n.language),
        languageCode: getCurrentLanguageRMI(i18n.language),
        bodyQualColId: Number(searchParams.get('body_qualcol_id'))
       
      })
        .then((res) => {
        
          setIframeData(res.data);
         
        }).finally(()=>{
          setLoadingHTML(false);
        });

      return () => {
        componentMountedManual = false;
      };
    }

   
  }, [typeId, searchParams.get('body_qualcol_id'), searchParams.get('main_group_id'), i18n.language]);
};


export const useFetchGraphicsMainGroupHtml = ({
  typeId,
  setLoadingHTML,
  setIframeData,
}: {
    typeId: number | null;
    setLoadingHTML: (b: boolean) => void;
    setIframeData: (html: string) => void
  }): void => {

  let componentMountedMainGroupHtml = true;
  const [searchParams, setSearchParams] = useSearchParams();
  const http = useHttp();

  useEffect(() => {
    if (typeId && searchParams.get('main_group_id') !== null && searchParams.get('body_qualcol_id') !== null && searchParams.get('sub_group_id') === null && componentMountedMainGroupHtml) {
      setLoadingHTML(true);
  
      http.graphicstHttp.fetchRMIGraphicsManualMaingroupHtml({
        typeId,
        countryCode: getCurrentCountryRMI(i18n.language),
        languageCode: getCurrentLanguageRMI(i18n.language),
        bodyQualColId: Number(searchParams.get('body_qualcol_id')),
        mainGroupId: Number(searchParams.get('main_group_id'))
      })
        .then((res) => {
          
          setIframeData(res.data);
          setSearchParams(searchParams);
         
        
        }).finally(()=>{
          setLoadingHTML(false);
        });

      return () => {
        componentMountedMainGroupHtml = false;
      };
    }


  }, [typeId,searchParams.get('main_group_id') , searchParams.get('body_qualcol_id') , searchParams.get('sub_group_id'), i18n.language]);
};


export const useFetchGraphicsSubGroupHtml = ({
  setLoadingHTML,
  typeId,
  setIframeData
}: {
    setLoadingHTML: (b: boolean) => void;
    typeId: number | null;
    setIframeData: (html: string) => void
  }): void => {

  let componentMountedSubGroupHtml = true;
  const [searchParams, setSearchParams] = useSearchParams();
  const http = useHttp();
  
  useEffect(() => {
    if (typeId && searchParams.get('sub_group_id') !== null && searchParams.get('body_qualcol_id') !== null && searchParams.get('sub_group_qualcol') !== null && componentMountedSubGroupHtml) {
      setLoadingHTML(true);
      http.graphicstHttp.fetchRMIGraphicsSubgroupHtml({
        typeId,
        countryCode: getCurrentCountryRMI(i18n.language),
        languageCode: getCurrentLanguageRMI(i18n.language),
        bodyQualColId: Number(searchParams.get('body_qualcol_id')),
        subGroupQualCol: Number(searchParams.get('sub_group_qualcol')),
        subGroupId:  Number( searchParams.get('sub_group_id'))
      })
        .then((res) => {
          setIframeData(res.data);
          
        }).finally(()=>{
          setLoadingHTML(false);
        }).catch(()=>{
          searchParams.delete('sub_group_id');
          setSearchParams(searchParams);
        });

     
    }

    return () => {
      componentMountedSubGroupHtml = false;
    };

  }, [typeId, searchParams.get('body_qualcol_id'), searchParams.get('sub_group_id'), searchParams.get('sub_group_qualcol'), i18n.language]);
};


export const useFetchComponentLinkedData = ({
  typeId,
  setOpenModalGrafics,
  setBoxDetailOptionsGrafics

}: {
     
      typeId: number | null;
      setOpenModalGrafics:(e:boolean)=>void;
      setBoxDetailOptionsGrafics:Dispatch<SetStateAction<IDataModalGraphics[]>>
    }): void => {
  const [searchParams] = useSearchParams();
  const http = useHttp();
  let componentMountedTab = true;

  useEffect(() => {
   
    if (typeId && searchParams.get('sub_group_id') !== null && searchParams.get('main_group_id') !== null &&
    searchParams.get('sub_group_qualcol') !== null && searchParams.get('sub_group_qualcol') !== null && 
    searchParams.get('item_group_id') !== null && componentMountedTab) {
     
      const params =  {
        typeId,
        countryCode: getCurrentCountryRMI(i18n.language),
        languageCode: getCurrentLanguageRMI(i18n.language),
        bodyQualColId: Number(searchParams.get('body_qualcol_id')),
        subGroupQualCol: Number(searchParams.get('sub_group_qualcol')),
        subGroupId:  Number( searchParams.get('sub_group_id')),
        mainGroupId:  Number( searchParams.get('main_group_id')),
        itemGroupId:  Number( searchParams.get('item_group_id'))
      };

      Promise.allSettled([
        http.graphicstHttp.fetchRMIGraphicsLinkedTechnicalManuals(params),
        http.graphicstHttp.fetchRMIGraphicsLinkedAdjustmentData(params),
        http.graphicstHttp.fetchRMIGraphicsLinkedWorkPositions(params),
        http.graphicstHttp.fetchRMIGraphicsLinkedSpareParts(params),
        http.graphicstHttp.fetchRMIGraphicsLinkedGenArt(params),
      ]).then(([
        linkedTechnicalManualsPromiseWrapper,
        linkedAdjustmentPromiseWrapper,
        linkedWorkPositionsPromiseWrapper,
        linkedSparePartsPromiseWrapper,
        linkedGenArtPromiseWrapper,
      ]) => {
        let linkedTechnicalManuals;
        let linkedAdjustment;
        let linkedWorkPositions;
        let linkedSpareParts;
        let linkedGenArt;
  
        if (linkedTechnicalManualsPromiseWrapper.status !== 'rejected') {
          linkedTechnicalManuals = linkedTechnicalManualsPromiseWrapper?.value?.data;
        }

        if (linkedAdjustmentPromiseWrapper.status !== 'rejected') {
          linkedAdjustment = linkedAdjustmentPromiseWrapper.value.data;
        }
  
        if (linkedWorkPositionsPromiseWrapper.status !== 'rejected') {
          linkedWorkPositions = linkedWorkPositionsPromiseWrapper.value.data;
        }
  
        if (linkedSparePartsPromiseWrapper.status !== 'rejected') {
          linkedSpareParts = linkedSparePartsPromiseWrapper.value.data;
        }
  
        if (linkedGenArtPromiseWrapper.status !== 'rejected') {
          linkedGenArt = linkedGenArtPromiseWrapper.value.data;
        }

        const data = [
          {
            linkedTechnicalManuals : linkedTechnicalManuals 
          },
          {
            linkedAdjustment : linkedAdjustment
          },
          {
            linkedWorkPositions : linkedWorkPositions
          },
          {
            linkedSpareParts : linkedSpareParts
          },
          {
            linkedGenArt : linkedGenArt
          }
        ];

        setBoxDetailOptionsGrafics(data);
        setOpenModalGrafics(true);

        return () => {
          componentMountedTab = false;
        };
      });

    
    }
  }, [typeId,  searchParams.get('body_qualcol_id'), searchParams.get('main_group_id'),searchParams.get('sub_group_id'), searchParams.get('sub_group_qualcol'), searchParams.get('item_group_id'), i18n.language]);
};
  