import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import UploadProformaAutomotorContent from './UploadProformaAutomotorContent/UploadProformaAutomotorContent';
import Modal from 'components/modal/Modal';
import { setProformaAutomotorActive } from 'store/slices/proformaAutomotor.slice';
import { RootState } from 'store/store';


type Props = {};

const UploadProformaAutomotor: React.FC<Props> = () => {
  const { proformaAutomotorActive } = useSelector((state: RootState) => state.proformaAutomotor);
  const [openModal, setOpenModal] = useState<boolean>(proformaAutomotorActive);
  const dispatch = useDispatch();

  useEffect(() => {
    setOpenModal(proformaAutomotorActive);
  }, [proformaAutomotorActive]);

  useEffect(() => {
    if (!openModal) {
      dispatch(setProformaAutomotorActive(false));
    }
  }, [openModal]);

  return (
    <div>
      <Modal
        openModal={openModal}
        setOpenModal={setOpenModal}
        childrenComponent={<UploadProformaAutomotorContent setOpenModal={setOpenModal} />}
      />

    </div>
  );
};

UploadProformaAutomotor.displayName = 'UploadProformaAutomotor';

export default UploadProformaAutomotor;