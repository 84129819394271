import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoxOpen, faComment, faPhone } from '@fortawesome/free-solid-svg-icons';

import Modal from 'components/modal/Modal';

import { useTranslation } from 'react-i18next';

import i18n from 'i18n';
import { IUserPut } from 'utils/hooks/http/user.http';
import { getCurrentMarketplace, notify } from 'utils/marketplace';
import { IDropdownModel } from 'components/dropdowns/Dropdown.model';
import { getCountryFromLocale, getCountryFromLocalStorage, getLanguageFromLocale, getLanguageFromLocalStorage } from 'utils/locale';
import useHttp from 'utils/hooks/useHttp';
import { countries, DEFAULT_COUNTRY_IMAGE, languages } from 'utils/constants/locales';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { setQuantityToolbar } from 'store/slices/cart.slice';
import { useAppDispatch } from 'utils/hooks/redux';
import { Marketplaces } from 'utils/constants/applications';
import './NavbarMecadepot.scss';
import CartButton from 'components/buttons/CartButton/CartButton';
import AccountDropdown from 'components/dropdowns/AccountDropdown/AccountDropdown';
import MiniDropdown from 'components/dropdowns/MiniDropdown/MiniDropdown';

import ModalCars from 'components/navbar/ModalCars/ModalCars';

const NavbarMecadepot: React.FC = () => {
  const location = useLocation();
  const { user } = useSelector((state: RootState) => state.userData);
  const { quantityToolbar } = useSelector((state: RootState) => state.cart);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const http = useHttp();
  const { newMessagesNumber } = useSelector((state: RootState) => state.messenger);
  const [link, setLink] = useState<string>('');
  const [selectedLanguage, setSelectedLanguage] = useState<IDropdownModel>(getLanguageFromLocalStorage() ?? languages[0]);
  const [selectedCountry, setSelectedCountry] = useState<IDropdownModel>(getCountryFromLocalStorage() ?? countries[0]);
  const navigate = useNavigate();


  useEffect(() => {
    const language = getLanguageFromLocale(i18n);

    if (language) {
      setSelectedLanguage(language);
    }

    const country = getCountryFromLocale(i18n);

    if (country) {
      setSelectedCountry(country);
    }
  }, [i18n.language]);

  const changeLanguage = (item: IDropdownModel) => {
    const country = getCountryFromLocale(i18n);

    if (!country) {
      return;
    }

    const locale = `${item.id}-${country.id}`;

    const userData: IUserPut = {
      language: String(item.id),
    };

    try {
      http.user.putUser(userData);
    } catch (e) {
      notify('Error');
    }

    localStorage.setItem('locale', locale);
    i18n.changeLanguage(locale);
  };

  const changeCountry = (item: IDropdownModel) => {
    const language = getLanguageFromLocale(i18n);

    if (!language) {
      return;
    }

    const locale = `${language.id}-${item.id}`;

    localStorage.setItem('locale', locale);
    i18n.changeLanguage(locale);
  };

  useEffect(() => {
    setTimeout(() => {
      http.cart.getCartQuantity().then((res) => {
        dispatch(setQuantityToolbar(res.quantity));
      });
    }, 700);
  }, []);


  const home = () => {
    getCurrentMarketplace() == Marketplaces.PUNCHOUT ? window.location.href = String(sessionStorage.getItem('callback_url')) : navigate('/');

  };


  return (
    <div className="navbar-mecadepot">
      <div className="logos">
        <div className="garage-michel" onClick={() => home()}>
          <img
            src={
              sessionStorage.getItem('logo_url') !== 'null' && sessionStorage.getItem('logo_url')
                ? sessionStorage.getItem('logo_url')!
                : String(user?.client_logo)
            }
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = '/images/car-part-placeholder.png';
            }}
          />
          <img src={require('assets/images/e-reliable.png')} width="87" height="10" alt="e-reliable" onClick={() => navigate('/')} />
        </div>
      </div>

      <div className="user-settings">
        <div className="tabs">
          <div className={`tab ${location.pathname.includes('/orders') ? 'active' : ''}`} onClick={() => navigate('/orders')}>
            <FontAwesomeIcon className="tab-icon" icon={faBoxOpen} width="15" height="15" />
            <div className="text">{t('_my_orders')}</div>
          </div>
        </div>
        <div className="phone-div" onClick={() => navigate('/messages')}>
          <FontAwesomeIcon className="tab-icon tab-icon-white" icon={faComment} width="15" height="15" />
          {!!newMessagesNumber && <div className="mecadepot-messages-count">{newMessagesNumber}</div>}
        </div>
        <div className="phone-div" >
          <div className="text">
            {t('_support')}:{' '}
            <div className="phone">
              <FontAwesomeIcon icon={faPhone} />{' '}<a href={`tel:${user?.distributor_info?.main_address?.tel}`}>
                {user?.distributor_info?.main_address?.tel}
              </a>
            </div>
          </div>
        </div>
        {/* <ThemeButton /> */}
        <CartButton quantityToolbar={quantityToolbar} />

        <AccountDropdown />
        <div className="mini-dropdowns">
          <MiniDropdown onClick={changeLanguage} content={selectedLanguage?.id} data={languages} />
          <MiniDropdown onClick={changeCountry} image={selectedCountry ? selectedCountry.image : DEFAULT_COUNTRY_IMAGE} data={countries} />
        </div>
      </div>

      <Modal openModal={openModal} setOpenModal={setOpenModal} childrenComponent={<ModalCars setOpenModal={setOpenModal} link={link} />} />
    </div>
  );
};

NavbarMecadepot.displayName = 'NavbarMecadepot';

export default NavbarMecadepot;
