import { AxiosError } from 'axios';
import Spinner from 'components/spinner/Spinner';
import { ICarPartData } from 'models/car-parts.model';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import useHttp from 'utils/hooks/useHttp';
import { getCurrentMarketplace, notify } from 'utils/marketplace';
import PartItemDetails from '../CarPartDetails/PartItemDetails';
import FavoriteButton from '../CarPartOptions/FavoriteButton/FavoriteButton';
import PartItemPhotoGallery from '../CarPartPhotoGallery/PartItemPhotoGallery';
import './CarPartViewMobile.scss';

import BarCode from '../CarPartOptions/BarCode/BarCode';
import { useTranslation } from 'react-i18next';
import PartItemOptionsMobile from './PartItemOptionsMobile';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleArrowLeft } from '@fortawesome/free-solid-svg-icons';
import Equivalence from '../Equivalence/Equivalence';
import { Marketplaces } from 'utils/constants/applications';
import EquivalenceMobile from 'components/PartItem/Equivalence/Equivalence';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import EquivalenceV2 from '../EquivalenceV2/EquivalenceV2';

const CarPartViewMobile: React.FC = () => {
  const http = useHttp();
  const { id, reference } = useParams();
  const [searchParams] = useSearchParams();
  const { isMobile, isTablet } = useDeviceDetect();
  const [carPart, setCarPart] = useState<ICarPartData | null>(null);
  const [loading, isLoading] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [has360, setHas360] = useState(false);
  const [checked, setChecked] = useState(true);
  const { vehicle_id } = useParams<'vehicle_id'>();

  useEffect(() => {
    document.body.classList.add('footer-padding');
    getCarPart();

    return () => {
      document.body.classList.remove('footer-padding');
    };
  }, [
    searchParams.get('data_supplier_id'),
    searchParams.get('provider_id'),
    searchParams.get('catalog_id'),
    searchParams.get('reference'),
    id,
    searchParams.get('is_platform_admin'),
  ]);

  const getCarPart = async () => {
    isLoading(true);

    try {
      let response;

      const params = {
        data_supplier_id: String(searchParams.get('data_supplier_id')),
        provider_id: String(searchParams.get('provider_id')),
        ...(searchParams.get('is_platform_admin') && { is_platform_admin: true }),
        ...(searchParams.get('vehicle_identifier') && { vehicle_identifier: searchParams.get('vehicle_identifier') }),
        ...(vehicle_id && { ktypnr: vehicle_id }),
      };

      if (searchParams.get('catalog_id') && searchParams.get('catalog_id') !== 'T') {
        const param = {
          ...params,
          ...{
            catalog_id: String(searchParams.get('catalog_id')),
          },
          ...(searchParams.get('is_platform_admin') && { is_platform_admin: true }),
        };

        response = await http.carParts.getCarPartView(String(reference), param);
      } else {
        response = await http.carParts.getReliableCarPartView(String(id), params);
      }

      setCarPart(response);
    } catch (e: unknown) {
      if (e instanceof AxiosError) {
        const message = e?.response?.data?.detail;

        notify(message);
      }
    } finally {
      isLoading(false);
    }
  };


  useEffect(() => {

    if (checked && carPart != null && carPart.image_360 && carPart.image_360.endsWith('.html')) {
      http.ebooks.checkImage({ url: carPart.image_360 }).then(reps => {
        setChecked(false);

        if (reps.data.status) {
          setHas360(true);
        }
      });
    }
  }, [carPart]);

  return (
    <div className="part-view item-view-wrapper">
      {loading ? (
        <Spinner class="small" />
      ) : (
        carPart && (
          <React.Fragment>
            <div className="item-view-header">
              <div className="mobile-part-item-references">
                <div className="mobile-part-item-reference-wrapper">
                  <div className="mobile-part-item-reference">
                    <span>Ref : </span>
                    {` ${carPart.data_supplier_reference_original}`}
                  </div>
                </div>
                <div className="mobile-part-item-brand-reference">{`${carPart.data_supplier.name} ${carPart.data_supplier_reference_original}`}</div>
              </div>
              <div className="mobile-part-item-options">
                <FavoriteButton carPart={carPart} />
                {/* <TimeIcon className="time-icon" /> */}
              </div>
              <div className="return-button">
                <span
                  className="returnButton"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <FontAwesomeIcon icon={faCircleArrowLeft} className="icon" />
                  {t('_return')}
                </span>
              </div>
            </div>
            <div className="item-view-content">
              <div className="gallery">
                <PartItemPhotoGallery carPart={carPart} has360={has360} />
              </div>
              <div className="mobile-car-part-details">
                <PartItemDetails carPart={carPart} />
              </div>
              <div className="mobille-barcode-container">
                <BarCode code={carPart?.barcode?.code} image={carPart?.barcode?.image} />
              </div>
              <div className="options">
                <PartItemOptionsMobile carPart={carPart} />
              </div>
            </div>
            <div className="item-view-footer">
              {/* {getCurrentMarketplace() === Marketplaces.AMERIGO && (isMobile || isTablet) && searchParams.get('catalog_id')
                ? <EquivalenceMobile />
                : <Equivalence carPart={carPart} />} */}

              <EquivalenceV2 carPart={carPart} />
            </div>
          </React.Fragment>
        )
      )}
    </div>
  );
};

CarPartViewMobile.displayName = 'CarPartViewMobile';

export default CarPartViewMobile;
