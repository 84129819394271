
import TextInput from 'components/inputs/TextInput/TextInput';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useHttp from 'utils/hooks/useHttp';
import './BrandCreate.scss';

import Spinner from 'components/spinner/Spinner';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { notify } from 'utils/marketplace';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import UploadImage from 'components/UploadImage/UploadImage';
import Select from 'react-select';
import { ITyreSelect } from 'interfaces/tyre';
import { ITBrandValidate } from 'interfaces/tyresBrand';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';


type Props = {
  setOpenModalCreate: (e: boolean) => void;
  setSubmit: (e: boolean) => void;
};


const BrandCreate: React.FC<Props> = (props) => {

  const { t } = useTranslation();
  const http = useHttp();
  let isMounted = true;

  const [loading, setLoading] = useState<boolean>(false);
  const [categories, setCategories] = useState<ITyreSelect[]>([]);
  const { isMobile, isTablet } = useDeviceDetect();

  const validate = Yup.object().shape({
    name: Yup.string().trim().required(t('_fild_required')),
    erp_brand_id: Yup.string().trim().required(t('_fild_required')),
    image: Yup.string().required(t('_fild_required')),
    brand_category_id: Yup.object().shape({
      label: Yup.string().required(t('_fild_required')),
      value: Yup.string().required(t('_fild_required')),
    })

  });

  const { getValues, register, handleSubmit, clearErrors, setValue, control, formState: { errors }, } = useForm<ITBrandValidate>({ resolver: yupResolver(validate) });


  useEffect(() => {

    if (isMounted) {

      http.tyreBrandHttp.category().then((res) => {

        const categories = res.data.map((item) => {
          return { value: item.id, label: item.name };
        });

        setCategories(categories);
      });
    }

    return () => {
      isMounted = false;
    };

  }, []);

  const onSubmit = async () => {


    setLoading(true);
    const values = getValues();
    const formData: FormData = new FormData();

    formData.append('name', values.name.trim());
    formData.append('erp_brand_id', values.erp_brand_id.trim());
    formData.append('image', values?.image);
    formData.append('brand_category_id', String(values?.brand_category_id.value));
    formData.append('is_partner', String(values.is_partner));

    http.tyreBrandHttp.create(formData).then(() => {
      notify(t('_tyre_brand_created'), 'success', t('_success'));
      props.setOpenModalCreate(false);
      props.setSubmit(true);
    }).catch((error) => {
      let erorrs = '';

      Object.entries(error).forEach(([key, value]) => {
        erorrs += `${t('_' + key)} : ${value} `;
      });
      notify(erorrs, 'error', t('_error'));
      setLoading(false);
    });

  };

  const customStyles = (hasError) => ({
    control: (styles) => ({
      ...styles,
      ...(hasError ? { borderColor: 'red' } : { border: 'none' }),
    }),
  });

  return (
    <div className="tyre-brand-create-container" >
      <div className="close-modal" onClick={() => { props.setOpenModalCreate(false); }}><FontAwesomeIcon icon={faClose} /></div>

      <div className="title">
        {t('_oil_title_create')}

      </div>


      {
        !categories.length ?
          <div className="spinner-info"> <Spinner />  </div> :
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="tyre-create-body">
              <div className="row">
                <div className="container ">
                  <label  >
                    {t('_oil_category')}
                  </label>
                  <Controller
                    control={control}
                    name="brand_category_id"
                    render={({ field: { onChange, value },

                    }) => (
                      <Select
                        value={value}
                        styles={customStyles(errors.brand_category_id?.label?.type)}
                        className="select-dropdown"
                        onChange={(val) => {
                          onChange(val);

                        }}
                        options={categories}
                        placeholder={t('_oil_select_category')}
                        isSearchable={!isMobile && !isTablet}
                      />
                    )}
                  />
                  <p className="error" >{errors.brand_category_id?.label?.type == 'required' ? errors.brand_category_id?.label?.message : ''}</p>
                </div>
                <div className={`container ${errors.name?.type == 'required' ? 'error' : ''}`}>
                  <label  >
                    {t('_name')}
                  </label>
                  <TextInput {...register('name')} />
                  <p className="error-message">{errors.name?.type == 'required' ? errors.name?.message : ''}</p>

                </div>
                <div className={`container ${errors.erp_brand_id?.type == 'required' ? 'error' : ''}`}>
                  <label  >
                    {t('_erp_brand_id')}
                  </label>
                  <TextInput {...register('erp_brand_id')} />
                  <p className="error-message">{errors.erp_brand_id?.type == 'required' ? errors.erp_brand_id?.message : ''}</p>

                </div>
                <div className={`container ${errors.image?.type == 'required' ? 'error' : ''}`}>
                  <UploadImage errors={errors} clearErrors={clearErrors} setValue={setValue} />

                </div>

              </div>
              <div className="row">
                <div className="container is-partner-checkbox-container">
                  <label htmlFor="is_partner">{t('_partner_brand')}</label>
                  <input type="checkbox" id="is_partner" {...register('is_partner')} />
                </div>
              </div>
              <div className="btn-save">
                <button type="submit" className={loading ? 'disabled' : ''}> {loading ? <Spinner class="extra-small" /> : null} <div className="title-btn-save">{t('_create_article')}</div></button>
              </div>
            </div>

          </form>

      }


    </div>

  );

};

BrandCreate.displayName = 'BrandCreate';

export default BrandCreate; 