import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import './ConfigurationCard.scss';

type Props ={
  children?: React.ReactNode
  editable?: boolean
  handleEdit?: ()=> void,
  step?: string
}
const ConfigurationCard:React.FC<Props> = (props) => {
  const {t} = useTranslation();

  return (
    <div className="configuration-card">
      <div className="card-header">
        <div className="card-title">
          <Trans 
            i18nKey="_step_maintenance"
            values={{step: props.step}}
          />
        </div>
        {props.editable &&(
          <div className="card-edit">
            <FontAwesomeIcon icon={faEdit} onClick={props.handleEdit}/>
          </div>
        )}
      </div>
      {props.children}
    </div>
  );
};

export default ConfigurationCard;