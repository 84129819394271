import { HttpResponse } from 'utils/hooks/http/history.http';
import { tecRMIAxios, serverAxios } from '../../http';
import { ICooGroupFilter, ICooGroupFilterParams, IGenArtsForItemMpIds, IGenArtsForItemMpIdsParams, IMaintenancePlanHtmlParams, IMaintenanceWorks, IMaintenanceWorksParams, IMakeInfo, IPartsForWorks, IPartsForWorksParams, IServiceProposal, IServiceProposalCooGroups, IServiceProposalCooGroupsParams, IServiceProposalParams } from '../interface/maintenance.interface';


class MaintenanceHttp {
  private static ManualHtml = '/Manuals/ManualHtml';
  private static MaintenanceMakeInfo = '/Maintenance/MakeInfoManuals';
  private static CooGroupFilterParams = '/Maintenance/PossibleCooGroupFilterParameters';
  private static PossibleGearboxCodes = '/Maintenance/PossibleGearboxCodes';
  private static ServiceProposalCooGroups = '/Maintenance/ServiceProposalCooGroups';
  private static ServiceProposal = '/Maintenance/ServiceProposal';
  private static MaintenanceWorks = '/Maintenance/Works';
  private static MaintenancePlanHtml = '/Maintenance/MaintenancePlanHtml';
  private static PartsForWorks = '/Prices/PartsForWorks';
  private static GenArtsForItemMpIds = '/Prices/GenArtsForItemMpIds';
  private static hasServiceProposal = '/Maintenance/VehicleHasServiceProposal';
  private static HtmlToPdf = '/htmltopdf';


  public async fetchManualsHtml(params: {}): Promise<HttpResponse<string>> {
    return tecRMIAxios.get(MaintenanceHttp.ManualHtml, {params});
  }
  public async hasServiceProposal(params: {}): Promise<HttpResponse<boolean>> {
    return tecRMIAxios.get(MaintenanceHttp.hasServiceProposal, {params});
  }
  public async fetchMakeInfo(params: {}): Promise<HttpResponse<IMakeInfo[]>> {
    return tecRMIAxios.get(MaintenanceHttp.MaintenanceMakeInfo, {params});
  }
  public async fetchCooGroupFilterParams(params: ICooGroupFilterParams): Promise<HttpResponse<ICooGroupFilter>> {
    return tecRMIAxios.post(MaintenanceHttp.CooGroupFilterParams, {...params});
  }
  public async fetchPossibleGearboxCodes(params: ICooGroupFilterParams): Promise<HttpResponse<number[]>> {
    return tecRMIAxios.post(MaintenanceHttp.PossibleGearboxCodes, {...params});
  }
  public async fetchServiceProposalCooGroups(params: IServiceProposalCooGroupsParams): Promise<HttpResponse<IServiceProposalCooGroups[]>> {
    return tecRMIAxios.post(MaintenanceHttp.ServiceProposalCooGroups, {...params});
  }
  public async fetchServiceProposal(params: IServiceProposalParams): Promise<HttpResponse<IServiceProposal>> {
    return tecRMIAxios.post(MaintenanceHttp.ServiceProposal, {...params});
  }
  public async fetchMaintenanceWorks(params: IMaintenanceWorksParams): Promise<HttpResponse<IMaintenanceWorks>> {
    return tecRMIAxios.get(MaintenanceHttp.MaintenanceWorks, {params});
  }
  public async fetchMaintenancePlanHtml(params: IMaintenancePlanHtmlParams): Promise<HttpResponse<string>> {
    return tecRMIAxios.post(MaintenanceHttp.MaintenancePlanHtml, {...params});
  }
  public async fetchPartsForWorks(params: IPartsForWorksParams): Promise<HttpResponse<IPartsForWorks[]>> {
    return tecRMIAxios.post(MaintenanceHttp.PartsForWorks, {...params});
  }
  public async fetchGenartForItemMpIds(params: IGenArtsForItemMpIdsParams): Promise<HttpResponse<IGenArtsForItemMpIds[]>> {
    return tecRMIAxios.post(MaintenanceHttp.GenArtsForItemMpIds, {...params});
  }
  public async htmlToPDF(params: {html: string}): Promise<HttpResponse<string>> {
    return serverAxios.post(MaintenanceHttp.HtmlToPdf, {...params}, {responseType: 'blob'});
  }
}


export default MaintenanceHttp;
