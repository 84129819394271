import React from 'react';

import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Spinner from 'components/spinner/Spinner';
import './DeleteCell.scss';

type Props = {
  onDelete: (id) => void;
  loading?: boolean
  disabled?: boolean
};

const DeleteCellView: React.FC<Props> = (props: Props) => {

  return (
    <div className="delete-cell-wrapper">
      {props.loading ? <Spinner class="extra-small"/> : <FontAwesomeIcon onClick={props.onDelete} className={`delete-cell-icon ${props.disabled ? 'disabled' : ''}`} icon={faTimes}></FontAwesomeIcon>}
    </div>
  );
};

DeleteCellView.displayName = 'DeleteCellView';

export default DeleteCellView;
