import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faFile,
  faForward,
  faPersonWalkingArrowLoopLeft,
  faScrewdriverWrench,

} from '@fortawesome/free-solid-svg-icons';
import React, { useRef, useState } from 'react';
import './TechnicalHelpMobile.scss';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import RoundedIcon from 'components/buttons/RoundedIcon/RoundedIcon';
import { RootState } from 'store/store';
import { useOnClickOutside } from 'utils/hooks/useOnClickOutside';


type Props = {
  setOpenModal: (e: boolean) => void;
  setLink: (e: string) => void;
  setShow: (e: boolean) => void;
  setIsActive: (e: boolean) => void;
}

const TechnicalHelpMobile: React.FC<Props> = (props) => {
  const [show, setShow] = useState<boolean>(false);


  const dropdownRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const { t } = useTranslation();
  const { user } = useSelector((state: RootState) => state.userData);

  const vehicleSelected = localStorage.getItem('carSelected') ? JSON.parse(localStorage.getItem('carSelected') as string) : null;

  useOnClickOutside(dropdownRef, () => { setShow(false), props.setShow(false); });

  const technicalDocumentationIsDisabled = () => user?.client_rmi_modules_type === 'none';

  const methodStepByStepIsDisabled = () => user?.client_rmi_modules_type === 'none' && user?.client_settings.eina_subscription === false;

  const technicalDocumentationOnClick = () => {

    if (technicalDocumentationIsDisabled()) {
      return;
    }

    props.setIsActive(false);
    props.setShow(false);
    props.setOpenModal(true);
    props.setLink('/technical-documentation');
  };

  const methodStepByStepOnClick = () => {
    if (methodStepByStepIsDisabled()) {
      return;
    }

    props.setIsActive(false);
    props.setShow(false);
    props.setOpenModal(true);
    props.setLink('/error-codes');
  };

  return (
    <div ref={dropdownRef} className="account-dropdown-mobile" onClick={() => { setShow(!show), props.setShow(!show); }}>
      <div className="header-drop">
        <FontAwesomeIcon className="tab-icon" icon={faScrewdriverWrench} width="14" height="14" />
        <div className="text">{t('_technical_help')}  <FontAwesomeIcon icon={show ? faArrowDown : faArrowRight} width="15" height="15" /> </div>
      </div>

      <div className={show ? 'show' : 'hide'}>
        <div className="content-data-mobile">
          {vehicleSelected != null ?
            <React.Fragment>
              <NavLink onClick={() => { props.setIsActive(false), props.setShow(false); }} to={technicalDocumentationIsDisabled() ? '' : `reliable/rmi/${vehicleSelected.car.id.toString()}/technical-documentation?${(new URLSearchParams({
                source: vehicleSelected.car.source,
                vehicle_identifier: vehicleSelected.car.vehicle_identifier,
                ...(vehicleSelected.car.vin && { vin: vehicleSelected.car.vin }),
              })).toString()}`} className={`item ${technicalDocumentationIsDisabled() ? 'disabled' : ''}`}

              >
                <RoundedIcon icon={faFile} text={t('_technical_documentation')} />
              </NavLink>
              <NavLink onClick={() => { props.setIsActive(false), props.setShow(false); }} to={methodStepByStepIsDisabled() ? '' : `reliable/rmi/${vehicleSelected.car.id.toString()}/error-codes?${(new URLSearchParams({
                source: vehicleSelected.car.source,
                vehicle_identifier: vehicleSelected.car.vehicle_identifier,
                ...(vehicleSelected.car.vin && { vin: vehicleSelected.car.vin }),
              })).toString()}`} className={`item ${methodStepByStepIsDisabled() ? 'disabled' : ''}`}
              >
                <RoundedIcon icon={faForward} text={t('_step_by_step_method')} />
              </NavLink>
            </React.Fragment> :

            <React.Fragment>

              <NavLink to="#" className={`item ${technicalDocumentationIsDisabled() ? 'disabled' : ''}`} onClick={() => { technicalDocumentationOnClick(); }}>
                <RoundedIcon icon={faFile} text={t('_technical_documentation')} />
              </NavLink>
              <NavLink to="#" className={`item ${methodStepByStepIsDisabled() ? 'disabled' : ''}`} onClick={() => { methodStepByStepOnClick(); }}>
                <RoundedIcon icon={faForward} text={t('_step_by_step_method')} />
              </NavLink>
            </React.Fragment>

          }
        </div>
      </div>
    </div>
  );
};

TechnicalHelpMobile.displayName = 'TechnicalHelpMobile';

export default TechnicalHelpMobile;
