import { FieldValues, UseFormRegister } from 'react-hook-form';
import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

import './PasswordInput.scss';
import { ILoginForm } from 'models/ILoginForm.model';

type Props = {
  register: UseFormRegister<FieldValues>;
  onShowPasswordClick: () => void;
  placeholder?: string;
  showPassword: boolean;
  name: keyof ILoginForm;
  required?: boolean;
  autoComplete?: string
};

const PasswordInputView: React.FC<Props> = (props: Props) => {
  return (
    <div className="text-input-container-password">
      <input
        className="text-input"
        type={props.showPassword ? 'text' : 'password'}
        placeholder={props.placeholder}
        autoComplete={props.autoComplete}
        {...props.register(props.name, { required: props.required })}
      />
      <FontAwesomeIcon
        onClick={() => props.onShowPasswordClick()}
        className="input-search-icon"
        icon={props.showPassword ? faEye : faEyeSlash}
      ></FontAwesomeIcon>
    </div>
  );
};

PasswordInputView.displayName = 'PasswordInputView';

export default PasswordInputView;
