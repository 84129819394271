
import { CancelToken } from 'axios';
import { IBodyResponseBrand, IBrand, IParamsBrand } from 'interfaces/oilBrand';

import { addRouteParam, IHttpResponse, serverAxios } from '../../http';

class OilBrandHttp {

  private static list = '/oil/brand';
  private static info = '/oil/brand/';
  private static update = '/oil/update/brand/';
  private static create = '/oil/register/brand';
  private static delete = '/oil/brand/';
  private static isPartner = '/oil/update/brand/:id/is_partner';


  public getList(urlParams: IParamsBrand, cancelToken: CancelToken): Promise<IHttpResponse<IBodyResponseBrand>> {
    return serverAxios.get(OilBrandHttp.list, { params: urlParams, cancelToken: cancelToken });
  }


  public getBrandInfo(id: number | undefined): Promise<IHttpResponse<IBrand>> {
    return serverAxios.get(OilBrandHttp.info + id);
  }

  public edit(id: number, data: FormData): Promise<IHttpResponse<IBrand>> {
    return serverAxios.post(OilBrandHttp.update + id, data);
  }

  public create(data: FormData): Promise<IHttpResponse<IBrand>> {
    return serverAxios.post(OilBrandHttp.create, data);
  }

  public delete(id: number): Promise<IHttpResponse<null>> {
    return serverAxios.delete(OilBrandHttp.delete + id);
  }
  public isPartner(id: number): Promise<IHttpResponse<IBrand>> {
    return serverAxios.post(OilBrandHttp.isPartner);
  }
  public updateIsPartner(id: number, data: {}): Promise<IHttpResponse<[]>> {
    return serverAxios.post(addRouteParam(OilBrandHttp.isPartner, { id }), data);
  }

}

export default OilBrandHttp;
