import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch } from './redux';
import { login } from '../../store/reducers/useSlices';
import { IUser } from 'models/IUser';
import { searchParamsToObject } from 'utils/http';
import {publicRoutes} from './public-routes';
import { getCurrentMarketplace } from 'utils/marketplace';
import { Marketplaces } from 'utils/constants/applications';

const useAuthCheck = (): void => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!localStorage.getItem('token') && !publicRoutes.includes(location.pathname.split('/')?.[1]) && getCurrentMarketplace() !== Marketplaces.PUNCHOUT && !(getCurrentMarketplace() === Marketplaces.RETAILER && searchParams.get('authorization-token'))) {

      const queryParams: Object = searchParamsToObject();

      if(Object.prototype.hasOwnProperty.call(queryParams, 'redirectedHref') )
      {
        navigate(`/login?redirectedHref=${queryParams['redirectedHref']}`);
      }else{
        navigate('/login');
      }
    }


    if (localStorage.getItem('userData')) {
      const userData: IUser = JSON.parse(localStorage.getItem('userData') || '');

      dispatch(login(userData));
    }
  }, []);
};

export default useAuthCheck;
