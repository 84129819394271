import React from 'react';

import './EquivalenceIAMItemV2.scss';
import { IAim } from 'models/car-parts.model';
import { useTranslation } from 'react-i18next';
import { createSearchParams, generatePath, Link, useSearchParams } from 'react-router-dom';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';

type Props = {
  item: IAim;
};

const EquivalenceIAMItemV2: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { isMobile } = useDeviceDetect();
  const [searchParams] = useSearchParams();

  const getCarPartImage = () => {
    return props.item.logo ? props.item.logo : '/images/car-part-placeholder.png';
  };

  function generateLink() {
    let params;


    if ((props.item?.source && props.item?.source !== 'T' && props.item?.source !== 'P')) {
      params = [
        ['data_supplier_id', props.item.supplier_id],
        ['catalog_id', props.item.source],
        ['provider_id', props.item.codfou],
        ['reference', props.item.car_part_reference]
      ];
    } else {
      params = [
        ['data_supplier_id', props.item.supplier_id],
        ['provider_id', props.item.codfou],
      ];
    }

    if (searchParams.get('is_platform_admin')) {
      params = [...params, ['is_platform_admin', searchParams.get('is_platform_admin')]];
    }

    return generatePath(`/car-parts/view/${props.item.car_part_reference}?${createSearchParams(params)}`);
  }

  return (
    <div className="equivalence-oem-item">
      <Link className="image" to={generateLink()}>
        <img alt="image" src={getCarPartImage()} onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src = '/images/car-part-placeholder.png';
        }}
        />
      </Link>
      <div className="content">
        <Link className="title" to={generateLink()}>
          {props.item.reference_original}
        </Link>
        <div className="category">{props.item.category_name}</div>
        {isMobile ? (
          <div className="subtitle-client">
            <span>{t('_supplier')}</span>: {props.item.data_supplier_name}
          </div>
        ) : (
          <div className="subtitle">
            <span>{t('_supplier')}</span>: {props.item.data_supplier_name}
          </div>
        )}
        <div className="subtitle">
          {isMobile ? <span>Ref</span> : <span>{t('_reference')}</span>}: {props.item.reference_original}
        </div>
      </div>
    </div>
  );
};

EquivalenceIAMItemV2.displayName = 'EquivalenceIAMItemV2';

export default EquivalenceIAMItemV2;
