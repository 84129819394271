import React from 'react';
import VehicleContentView from './VehicleContent.view';

type Props = {};

const VehicleContent: React.FC<Props> = () => {
  return <VehicleContentView />;
};

VehicleContent.displayName = 'VehicleContent';

export default VehicleContent;
