import React, { useEffect, useState } from 'react';
import { faClose, faPrint } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import VehicleIframe from 'pages/CatalogVehicle/TechnicalDocumentation/VehicleIframe/VehicleIframe';
import { getCurrentCountryRMI, getCurrentLanguageRMI, getOptionalHeaderValues } from 'utils/rmi/rmi-utils/rmi-utils';
import i18n from 'i18n';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import useHttp from 'utils/hooks/useHttp';
import './PrintIframeModal.scss';
import Spinner from 'components/spinner/Spinner';
import { TECRMI_FOOTER_REGEX, TECRMI_HEADER_REGEX } from 'utils/constants/defaultConstants';

interface IMaintenanceWorks{
  additionalWorkIds?: number[],
  serviceWorkIds?: number[]
}
type Props = {
    setOpenModal?: (boolean)=> void
    showTyreTable?: boolean,
    data:{
      first_name?: string,
      last_name?: string,
      phone?: string,
      plate_number?: string,
      milage?: string,
      order_number?: string
    }
    maintenanceWorks?: IMaintenanceWorks
}

const PrintIframeModal:React.FC<Props> = (props: Props) => {
  const {t} = useTranslation();
  const http = useHttp();
  const {typeId} = useSelector((state: RootState) => state.tecrmi);
  const [file, setFile] = useState<string>();
  const [loadingFile, setLoadingFile] = useState(false);
  const [iframe, setIframe] = useState('');
  const [loadingIframe, setLoadingIframe] = useState(false);

  function fetchMaintenancePlanHtml(){
    if(typeId){
      setLoadingIframe(true);
      setLoadingFile(true);
      http.maintenanceHttp.fetchMaintenancePlanHtml({
        typeId,
        bodyQualColId: 0,
        countryCode: getCurrentCountryRMI(i18n.language),
        languageCode: getCurrentLanguageRMI(i18n.language),
        optionalHeaderValues: getOptionalHeaderValues(props.data),
        printView: false,
        serviceWorkIds: props.maintenanceWorks?.serviceWorkIds || [],
        showTyreTable: props.showTyreTable ? props.showTyreTable : false,
        additionalWorkIds: props.maintenanceWorks?.additionalWorkIds || [] 
      }).then(res=>{
        setIframe(res.data);
      }).finally(()=> setLoadingIframe(false));
    }
  }

  useEffect(()=>{
    if(iframe){
      http.maintenanceHttp.htmlToPDF({html: iframe.replace(TECRMI_HEADER_REGEX, '').replace(TECRMI_FOOTER_REGEX, '')}).then(res=> {
        const file = new Blob(
          [res.data],
          {type: 'application/pdf'},
        );

        setFile(URL.createObjectURL(file));
      }).finally(()=> setLoadingFile(false));
    }
  }, [iframe]);

  useEffect(()=>{
    fetchMaintenancePlanHtml();
  },[typeId, i18n.language]);

  const openPDF = () => {
    window.open(file);
  };

  return (
    <div className="print-iframe-modal">
      <div className="print-iframe-modal-header">
        <div className="title">{t('_service_plan')}</div>
        <div className="header-actions">
          {loadingFile ? <Spinner class="small print-spinner"/> : (
            <div className="print-btn" onClick={openPDF}>
              <FontAwesomeIcon icon={faPrint}/> 
              <div className="print-text">{t('_print')}</div>
            </div>
          )}
          <FontAwesomeIcon className="close-modal" icon={faClose} onClick={()=> props.setOpenModal!(false)}/>
        </div>
      </div>
      <div className="print-iframe">
        <VehicleIframe vehicleIframe={iframe} loading={loadingIframe} removeFixedHeight/>
      </div>
    </div>
  );
};

export default PrintIframeModal;