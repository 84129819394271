import React, { useEffect, useRef, useState } from 'react';

import { useOnClickOutside } from '../../../../../../utils/hooks/useOnClickOutside';
import './UploadProformaAutomotorContent.scss';
import { useDispatch } from 'react-redux';
import IconItem from '../../../../../../components/Item/IconItem/IconItem';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { setProformaAutomotorActive } from 'store/slices/proformaAutomotor.slice';
import { useDropzone } from 'react-dropzone';
import { useForm } from 'react-hook-form';
import Spinner from 'components/spinner/Spinner';
import FILE_XLSX from 'assets/images/file-xlsx.svg';
import { useTranslation } from 'react-i18next';
import { IHttpResponse } from 'utils/http';
import { IUploadFileProformaResponse } from 'utils/hooks/http/proforma.http';
import useHttp from 'utils/hooks/useHttp';
import { useNavigate } from 'react-router-dom';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';

type ICsvForm = {
  files: File;
};
type Props = {
  setOpenModal: (e: boolean) => void;
};

const UploadProformaAutomotorContent: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const ref = useRef() as React.MutableRefObject<HTMLDivElement>;
  const dispatch = useDispatch();
  const [sendFile, setSendFile] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { setValue, watch, getValues, formState: { errors } } = useForm<ICsvForm>();
  const http = useHttp();
  const navigator = useNavigate();

  useOnClickOutside(ref, () => {
    dispatch(setProformaAutomotorActive(false));
  });


  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    onDrop: (file) => {
      setValue('files', file[0]);
    },
    multiple: false,
    accept: {
      'text/csv': ['.csv', '.xls', '.xlsx'],
      'application/vnd.ms-excel': ['.csv', '.xls', '.xlsx'],
    },
  });

  useEffect(() => {
    const file = getValues('files');

    if (!file || !file.size) {
      return;
    }


    if (sendFile) {
      const formData: FormData = new FormData();

      formData.append('csv', file);
      setIsLoading(true);
      http.proforma
        .uploadProforma(formData)
        .then((res: IHttpResponse<IUploadFileProformaResponse>) => {
          localStorage.setItem('proformaFileInProgress', JSON.stringify(res.data));
          dispatch(setProformaAutomotorActive(false));
          navigator('/automotor-online/order/summary');
        })
        .catch((e) => {
          if (e.message && e.message[0] == 'MAPPING_DOES_NOT_EXIST') {
            dispatch(setProformaAutomotorActive(false));
            navigator('/automotor-online//felicitations', { state: { messageType: 'MAPPING_DOES_NOT_EXIST' } });
          } else {
            dispatch(setProformaAutomotorActive(false));
            navigator('/automotor-online/felicitations', { state: { messageType: 'INVALID_FILE' } });
          }

          setSendFile(false);
        })
        .finally(() => {
          setIsLoading(false);
          setSendFile(false);
        });
    }
  }, [watch('files'), sendFile]);

  return (

    <div className="upload-proforma-automotor" ref={ref}>
      <div className="modal-dialog">
        <div className="close">
          <IconItem icon={faClose} onClick={() => { props.setOpenModal(false); setProformaAutomotorActive(false); }} />
        </div>
        <form>
          <div className="upload-btn" >
            <div {...getRootProps({ className: 'icon' })} className="icon">
              {isLoading ? <Spinner class="small" /> : <img src={FILE_XLSX} alt="" />}
              <input {...getInputProps()} />
            </div>

            <div className="text">
              <div>{fileRejections.length > 0 ? <div className="error">{t('WRONG_PROFORMA_FILE')}</div> : ''}</div>
              <p className="title">
                {t('_browse_your_proforma_file')}
              </p>
              <p className="subtitle">
                <span>{t('_upload_proforma_supports')} </span>
              </p>
              {watch('files') ? 
                <p className="subtitle">
                  <span>{t('_file')}: {watch('files').name} </span>
                </p>
                : null}
            </div>
          </div>
        </form>
        {
          watch('files') ?
            <div className="actions">
              <div className="no">
                <DefaultButton text={t('_no')} onClick={() => { setSendFile(false); dispatch(setProformaAutomotorActive(false)); }} red />
              </div>
              <div className="yes">
                <DefaultButton text={t('_yes')} onClick={() => { setSendFile(true); }} blue />
              </div>
            </div>
            : null
        }
      </div>
    </div >
  );
};

export default UploadProformaAutomotorContent;