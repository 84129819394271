import {  ITariff } from 'models/catalog.model';
import {  serverAxios } from '../../http';

class TariffCatalogsHttp {
  private static route = '/amerigo/space-suppliers/tariffs';

  public async getTariffCatalogs(): Promise<ITariff[]> {
    const { data } = await serverAxios.get(TariffCatalogsHttp.route);

    return data;
  }
}

export default TariffCatalogsHttp;
