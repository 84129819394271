import React, { useEffect, useState } from 'react';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import { generatePath, NavLink, useNavigate, useParams } from 'react-router-dom';
import VideoPlayer from '../VideoPlayer/VideoPlayer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import { IVideoData } from '../../models/video';
import Spinner from '../spinner/Spinner';
import Pagination from '../pagination/Pagination';
import { useTranslation } from 'react-i18next';
import './VideoSection.scss';

type Props = {
  previewImage?: string;
  items: IVideoData[];
  loading: boolean;
  showPagination?: boolean;
  handlePageClick: (page: number) => void;
  currentPage?: number;
  totalPage?: number;
};

const VideoSectionView: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const { id: catalogId } = useParams();
  const { t } = useTranslation();

  const [selectedVideo, setSelectedVideo] = useState<IVideoData>(props.items[0]);
  const [videoIsChanged, setVideoIsChanged] = useState(false);

  const changeVideo = (video: IVideoData) => {
    setSelectedVideo(video);
    setVideoIsChanged(true);
  };

  useEffect(() => {
    setSelectedVideo(props.items[0]);
  }, [props.items]);

  return (
    <React.Fragment>
      {props.loading ? (
        <Spinner />
      ) : (
        <div className="video-container">
          <div className="video-main">
            <div className="player">
              <VideoPlayer previewImage={videoIsChanged ? false : props.previewImage} url={selectedVideo.video} />
            </div>
            <div className="informations">
              <div className="date">{selectedVideo.date}</div>
              <div className="title">
                <strong>{selectedVideo.title}</strong>
              </div>
              <div className="description">{selectedVideo.content}</div>
            </div>
          </div>
          <div className="video-list">
            {props.items.map((item, index) => {
              return selectedVideo.id === item.id ? (
                ''
              ) : (
                <div className="video-item" key={index}>
                  <div className="player" onClick={() => changeVideo(item)}>
                    <img className="video-preview" src={props.previewImage} alt="PREVIEW_IMAGE" />
                    <span className="button-play">
                      <FontAwesomeIcon icon={faPlay}></FontAwesomeIcon>
                    </span>
                  </div>
                  <div className="informations">
                    <div className="date">{item.date}</div>
                    <div className="title text-ellipsis">
                      <NavLink to="#" className="item">
                        <strong>{item.title}</strong>
                      </NavLink>
                    </div>
                  </div>
                </div>
              );
            })}
            {props.showPagination ? (
              ''
            ) : (
              <div className="video-link-all button">
                <DefaultButton
                  text={t('_more_videos')}
                  onClick={() => navigate(generatePath('/catalog/:id/video', { id: String(catalogId) }))}
                />
              </div>
            )}
          </div>
        </div>
      )}
      {props.showPagination && props.totalPage! > 1 && !props.loading && (
        <Pagination pageCount={props.totalPage!} handlePageClick={props.handlePageClick} forcePage={props.currentPage!}></Pagination>
      )}
    </React.Fragment>
  );
};

VideoSectionView.displayName = 'VideoSectionView';

export default VideoSectionView;
