import { IHttpResponse, serverAxios } from '../../http';
import { IRetailerInfo } from 'interfaces/messaging';
import { ILoginResponse } from './auth.http';
import { IBattery } from 'interfaces/battery';
import { IOil } from 'interfaces/oil';
import { ITyre } from 'interfaces/tyre';
import { IConsumable } from 'interfaces/consumable';

class RetailerHttp {
  private static supplierInfo = 'retailer/supplier-info';
  private static autologin = 'retailer/autologin';
  private static informativeMessage = 'retailer/home-informative-message';
  private static shopInformativeMessage = 'retailer/shop-home-informative-message';
  private static productTopOffer = 'retailer/catalogs/products';
  private static brandsSlideshow = 'retailer/brands/slideshow';
  private static getErpPart = 'retailer/mecadepot/get-erp-part';
  public async getSupplierInfo(): Promise<IHttpResponse<IRetailerSupplierInfo>> {
    return serverAxios.get(RetailerHttp.supplierInfo);
  }
  public async retailerAutoLogin(token: string): Promise<IHttpResponse<ILoginResponse>> {
    return serverAxios.get(RetailerHttp.autologin, { params: { 'authorization-token': token } });
  }

  public async getInformativeMessage(): Promise<IHttpResponse<IinformativeMessage>> {
    return serverAxios.get(RetailerHttp.informativeMessage);
  }
  public async getShopInformativeMessage(): Promise<IHttpResponse<IinformativeMessage>> {
    return serverAxios.get(RetailerHttp.shopInformativeMessage);
  }
  public async postShopInformativeMessage(message: string): Promise<IHttpResponse<IinformativeMessage>> {
    return serverAxios.post(RetailerHttp.shopInformativeMessage, { message });
  }
  public async getProductTopOffer(): Promise<IHttpResponse<IProductTopOffer[]>> {
    return serverAxios.get(RetailerHttp.productTopOffer);
  }
  public async getBrandsSlideShow(): Promise<IHttpResponse<string[]>> {
    return serverAxios.get(RetailerHttp.brandsSlideshow);
  }
  public getErpPart(param: {
    erp_id: string,
    reference: string,
    brand_id: number | string,
    type: string
  }): Promise<IHttpResponse<IErpPart>> {
    return serverAxios.get(RetailerHttp.getErpPart, { params: param });
  }

}

export interface IRetailerSupplierInfo {
  parent_supplier: IRetailerInfo | null;
  parent_contact: IRetailerInfo | null;
}

export interface IinformativeMessage {
  message: string;
}

export interface IErpPart {
  price: string;
  description: string;
}


export interface IProductTopOffer {
  data: {
    battery: IBattery[];
    oil: IOil[];
    tyre: ITyre[];
    consumable: IConsumable[];
  };
}

export default RetailerHttp;
