import { IGetCarPartDataParams } from 'models/car-parts.model';
import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSearchParams, generatePath, useNavigate } from 'react-router-dom';
import { getCurrentCatalog } from 'store/selectors/catalogs.selector';
import {
  getActiveCategory,
  getCategories,
  getCategorySidebarDetailsShow,
  getCategorySidebarShow,
} from 'store/selectors/categories.selector';
import { setPage } from 'store/slices/car-parts.slice';
import { setActiveCategory, setShowCategoryDetailsSidebar, setShowCategorySidebar } from 'store/slices/categories.slice';
import { useOnClickOutside } from 'utils/hooks/useOnClickOutside';
import CategoriesSidebarView from './CategoriesSidebar.view';

type Props = {
  showCategories?: boolean;
  showDetails?: boolean;
  onCloseButtonClick?: () => void;
  onItemClick?: () => void;
  onReturnButtonClick?: () => void;
};

const CategoriesSidebar: React.FC<Props> = (props: Props) => {
  const showSidebar = useSelector(getCategorySidebarShow);
  const showDetails = useSelector(getCategorySidebarDetailsShow);
  const activeCategory = useSelector(getActiveCategory);
  const categories = useSelector(getCategories);
  const currentCatalog = useSelector(getCurrentCatalog);
  const dispatch = useDispatch();
  const ref = useRef() as React.MutableRefObject<HTMLDivElement>;
  const navigate = useNavigate();

  const handleItemClick = (item) => {
    const category = categories.find((category) => category.name === item) || null;

    dispatch(setActiveCategory(category));
    dispatch(setShowCategoryDetailsSidebar(true));
    dispatch(setShowCategorySidebar(false));
    props.onItemClick?.();
  };

  const handleReturnItemClick = () => {
    dispatch(setActiveCategory(null));
    dispatch(setShowCategoryDetailsSidebar(false));
    dispatch(setShowCategorySidebar(true));
    props.onReturnButtonClick?.();
  };

  const handleCloseButtonClick = () => {
    dispatch(setActiveCategory(null));
    dispatch(setShowCategoryDetailsSidebar(false));
    dispatch(setShowCategorySidebar(false));
    props.onCloseButtonClick?.();
  };

  useOnClickOutside(ref, () => {
    dispatch(setActiveCategory(null));
    dispatch(setShowCategoryDetailsSidebar(false));
    dispatch(setShowCategorySidebar(false));
  });

  const handleSidebarSubCategoryClick = async (params: Omit<IGetCarPartDataParams, 'catalog_id' | 'page' | 'per_page'>) => {
    const searchParams = createSearchParams([
      ['code_groupe', String(params.code_groupe)],
      ['code_ssgroupe', String(params.code_ssgroupe)],
      ['code_repere', String(params.code_repere)],
      ['page', '1'],
    ]);

    navigate({
      pathname: generatePath('/catalog/:id/parts', {
        id: String(currentCatalog?.id),
      }),
      search: `?${searchParams}`,
    });

    dispatch(setPage(1));
    dispatch(setShowCategoryDetailsSidebar(false));
    dispatch(setShowCategorySidebar(false));
  };

  return (
    <CategoriesSidebarView
      activeCategory={activeCategory}
      categories={categories}
      ref={ref}
      onSidebarSubCategoryClick={handleSidebarSubCategoryClick}
      onCloseButtonClick={handleCloseButtonClick}
      onItemClick={handleItemClick}
      onReturnButtonClick={handleReturnItemClick}
      showSidebar={showSidebar}
      showDetails={showDetails}
      currentCatalog={currentCatalog}
    ></CategoriesSidebarView>
  );
};

export default CategoriesSidebar;
