import React, { PropsWithChildren, useContext, useState } from 'react';
import './EspaceReliableNavbarBelow.scss';
import IconTextItem from 'components/Item/IconTextItem/IconTextItem';
import { INavbarItem } from '../../Navbar.model';
import { useNavigate } from 'react-router-dom';
import Modal from 'components/modal/Modal';
import ModalCars from 'components/navbar/ModalCars/ModalCars';
import { SidebarContext, ISidebarContext } from 'context/SidebarContext';
import { TabCategories } from 'views/Sidebar/Sidebar.model';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import SwiperSlider from 'components/SwiperSlider/SwiperSlider';
import { SwiperSlide } from 'swiper/react';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';

type Props = {
  items: INavbarItem[];
};

const EspaceReliableNavbarBelowView: React.FC<Props> = (props: PropsWithChildren<Props>) => {
  const navigate = useNavigate();
  const { isMobile, isTablet } = useDeviceDetect();
  const [activeLink, setActiveLink] = useState<string>('');
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [link, setLink] = useState<string>('');
  const { setSidebarTab } = useContext(SidebarContext) as ISidebarContext;
  const { carInfo } = useSelector((state: RootState) => state.carSelected);
  const { newMessagesNumber } = useSelector((state: RootState) => state.messenger);

  const carInfoSelected = localStorage.getItem('carSelected') || carInfo ? JSON.parse(localStorage.getItem('carSelected') as string) : null;

  const navigateLink = (item) => {
    if (item.localStorage != undefined) {
      if (carInfoSelected != null) {
        setSidebarTab(TabCategories.Pieces);
      } else {
        setOpenModal(true);
      }
    } else {
      navigate(item.link);

      setActiveLink(item.link);
    }
  };

  return props.items.length > 0 ? (
    <div className="navbar-below-container">
      <div className="navbar-below">
        <div className="items">
          {(isMobile || isTablet) && props.items.length >= 5 ? (
            <SwiperSlider slidesPerView="auto" loop >
              {props.items.map((item: INavbarItem, index: number) => {
                if (item.hidden != undefined && item.hidden) {
                  return null;
                }

                if (item.component) {
                  return (
                    <SwiperSlide key={index} className="swiper-slide swiper-navbar-below-item">
                      <div className={`navbar-item-container ${item.disabled ? 'disabled' : ''}`} key={index} >
                        <div className={`item ${location.pathname.includes('error-codes') || location.pathname.includes('technical-documentation') ? 'active' : ''}`} >
                          <item.component disabled={item.disabled} setOpenModal={setOpenModal} setLink={setLink} />
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                } else {

                  return (
                    <SwiperSlide key={index} className="swiper-slide swiper-navbar-below-item">
                      <IconTextItem disabled={item.disabled} active={item.link === activeLink || location.pathname.includes(item.link)} text={item.text} icon={item.icon} onClick={() => { navigateLink(item); setActiveLink(''); }} key={index} />
                    </SwiperSlide>

                  );
                }

              })}
            </SwiperSlider>
          ) : (

            props.items.map((item: INavbarItem, index: number) => {

              if (item.hidden) {

                return null;
              }

              if (item.component) {
                return (
                  <div className={`navbar-item-container ${item.disabled ? 'disabled' : ''}`} key={index} >
                    <div className={`item ${location.pathname.includes('error-codes') || location.pathname.includes('technical-documentation') ? 'active' : ''}`} >
                      <item.component disabled={item.disabled} setOpenModal={setOpenModal} setLink={setLink} />
                    </div>
                  </div>
                );
              } else {

                return (
                  <IconTextItem number={item?.link?.includes('messages') && newMessagesNumber} disabled={item.disabled} active={item.link === activeLink || location.pathname.includes(item.link)} text={item.text} icon={item.icon} onClick={() => { navigateLink(item); setActiveLink(''); }} key={index} />
                );
              }

            })
          )}
        </div>
      </div>
      <Modal openModal={openModal} setOpenModal={setOpenModal} childrenComponent={<ModalCars setOpenModal={setOpenModal} link={link} />} />
    </div>
  ) : null;
};

EspaceReliableNavbarBelowView.displayName = 'EspaceReliableNavbarBelowView';

export default EspaceReliableNavbarBelowView;
