import React from 'react';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import CatalogsBatteriesPageMobile from './CatalogBatteriesPageMobile/CatalogsBatteriesPageMobile';
import CatalogsBatteriesPage from './CatalogsBatteriesPage';


const CatalogsBatteriesPageView: React.FC = () => {
  const {isMobile} = useDeviceDetect();

  return (
    isMobile ? <CatalogsBatteriesPageMobile /> : <CatalogsBatteriesPage />
  );
};

export default CatalogsBatteriesPageView;