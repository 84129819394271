import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import './MessageModal.scss';
import TextareaInput from 'components/inputs/TextareaInput/TextareaInput';
import useHttp from 'utils/hooks/useHttp';
import Spinner from 'components/spinner/Spinner';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import { notify } from 'utils/marketplace';

type Props = {
  setOpenModal: (bool: boolean) => void
};

const MessageModal: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const http = useHttp();

  useEffect(() => {
    setLoading(true);
    http.retailerHttp.getShopInformativeMessage().then(res => setMessage(res.data.message)).finally(()=> setLoading(false));
  },[]);

  function submitMessage(){
    setLoadingSubmit(true);
    http.retailerHttp.postShopInformativeMessage(message)
      .then(() => {
        notify(t('_success'), 'success');
        props.setOpenModal(false);
      })
      .catch(() => {
        notify(t('_error'));
      })
      .finally(() => {
        setLoadingSubmit(false);
      });
  }

  return (
    <div className="message-modal-info">
      <div className="message-modal-header">
        <div className="message-modal-title">{t('_message')}</div>
        <div className="close-modal" onClick={() => props.setOpenModal(false)}><FontAwesomeIcon icon={faClose} /></div>
      </div>
      {loading ? <Spinner class="extra-small"/> : (
        <div className="description">
          <TextareaInput noResize rows={10} value={message} onChange={(e) => setMessage(e.target.value)} />
        </div>
      )}
      <div className="footer">
        {!loading && (
          <div className="submit-btn">
            <DefaultButton text={t('_save')} onClick={submitMessage} isloading={loadingSubmit} />
          </div>
        )}
      </div>
    </div>
  );
};

export default MessageModal;
