import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { getAuthApi } from './marketplace';

const rootAxios = axios.create({
  baseURL: getAuthApi(),
});

rootAxios.interceptors.response.use((response: AxiosResponse): AxiosResponse => response.data);

rootAxios.interceptors.request.use((config: AxiosRequestConfig) => {
  return config;
});

export const serverAxios = rootAxios;
