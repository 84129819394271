import React from 'react';
import { SwiperSlide } from 'swiper/react';
import SwiperSlider from 'components/SwiperSlider/SwiperSlider';
import CarPartCard from '../car-part-card/CarPartCard';
import './CarPartsSlider.scss';

type Props = {
  isLoading?: boolean;
  data?
  type?: string
  slidesPerView?: number
  handleClick: (item)=> void

};

const CarPartsSlider: React.FC<Props> = (props) => {


  return (
    <SwiperSlider
      isLoading={props.isLoading}
      slidesPerView={props.slidesPerView}
    >
      {
        props.data?.map((card, index: number) => {
          return (
            <SwiperSlide className="swiper-slide" key={index}>
              <CarPartCard name={card.data_supplier_reference_original} image={card.image} handleClick={()=> props.handleClick(card)}/>
            </SwiperSlide>
          );
        })
      }
    </SwiperSlider>
  );
};


export default CarPartsSlider;
