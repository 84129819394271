import React, { PropsWithChildren } from 'react';
import SwiperSliderView from './SwiperSlider.view';
import SwiperSliderMobileView from './Mobile/SwiperSliderMobile.view';

import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import { SwiperOptions } from 'swiper';

type Props = {
  onClick?: () => void;
  isLoading?: boolean;
  slidesPerView?: number | 'auto';
  spaceBetween?: number;
  slidesOffsetBefore?: number;
  slidesOffsetAfter?: number;
  slideToClickedSlide?: boolean;
  initialSlide?: number;
  loop?: boolean;
  centeredSlides?: boolean;
  navigation?: boolean;
  pagination?: boolean;
  autoplay?: boolean;
  slideToIndex?: number | 'auto';
  getActiveSlideFromDom?: () => number;
  breakpoints?: SwiperOptions['breakpoints'];
  handleNextClick?: () => void;
  handlePrevClick?: () => void;
  allowTouchMove?: boolean;
  containerClass?: string;
  iconsArrowRight?
  iconsArrowLeft?
  speed?: number
  delay?: number
};

const SwiperSlider: React.FC<PropsWithChildren<Props>> = (props: PropsWithChildren<Props>) => {
  const { isMobile, isTablet } = useDeviceDetect();

  return isMobile || isTablet ? (
    <SwiperSliderMobileView {...props}>{props.children}</SwiperSliderMobileView>
  ) : (
    <SwiperSliderView {...props}>{props.children}</SwiperSliderView>
  );
};

SwiperSlider.displayName = 'SwiperSlider';

export default SwiperSlider;
