import React from 'react';
import './DeleteCell.scss';

import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Props = {
  onDelete: () => void;
};

const DeleteCellView: React.FC<Props> = (props: Props) => {
  return <FontAwesomeIcon onClick={props.onDelete} className="icon-del" icon={faTimes}></FontAwesomeIcon>;
};

DeleteCellView.displayName = 'DeleteCellView';

export default DeleteCellView;
