import React from 'react';
import SideNewsView from './SideNews.view';

type Props = {
  data?
};

const SideNews: React.FC<Props> = (props) => {
  return <SideNewsView data={props.data} />;
};

SideNews.displayName = 'SideNews';

export default SideNews;
