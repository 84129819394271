import { CancelToken } from 'axios';
import {
  IBodyResponseOil,
  IBodyResponseOilBrand,
  IBodyResponseOilCategory,
  IOilFilter,
  IOilInfo,
  IOilSearch,
  IParamsOil,
  IParamsOilBrand,
} from 'interfaces/oil';

import { addRouteParam, IHttpResponse, serverAxios } from '../../http';

class OilHttp {
  private static list = '/oil/list/platform_admin';
  private static categories = '/oil/categories';
  private static brands = '/oil/brands';
  private static info = '/oil/';
  private static update = '/oil/update/';
  private static updateTopOffer = '/oil/update/:id/top_offer';
  private static updateTopSales = '/oil/update/:id/top_sales';
  private static create = '/oil/register';
  private static filter = '/oil/filters/admin-platform';
  private static delete = '/oil/';
  private static search = '/oil/search';
  private static deleteAll = '/oil/delete_all';
  private static deleteSelected = '/oil/delete_all';

  public getOil(urlParams: IParamsOil, cancelToken: CancelToken): Promise<IHttpResponse<IBodyResponseOil>> {
    return serverAxios.get(OilHttp.list, { params: urlParams, cancelToken: cancelToken });
  }
  public oilSearch(params: { q: string }): Promise<IHttpResponse<IOilSearch[]>> {
    return serverAxios.get(OilHttp.search, { params });
  }

  public getCategories(): Promise<IHttpResponse<IBodyResponseOilCategory[]>> {
    return serverAxios.get(OilHttp.categories);
  }
  public getBrands(urlParams: IParamsOilBrand): Promise<IHttpResponse<IBodyResponseOilBrand>> {
    return serverAxios.get(OilHttp.brands, { params: urlParams });
  }

  public getOilInfo(id: number | undefined): Promise<IHttpResponse<IOilInfo>> {
    return serverAxios.get(OilHttp.info + id);
  }

  public postOil(id: number, data: FormData): Promise<IHttpResponse<IOilInfo>> {
    return serverAxios.post(OilHttp.update + id, data);
  }

  public postOilTopOffer(id: number, data: {}): Promise<IHttpResponse<[]>> {
    return serverAxios.post(addRouteParam(OilHttp.updateTopOffer, { id }), data);
  }

  public postOilTopSales(id: number, data: {}): Promise<IHttpResponse<[]>> {
    return serverAxios.post(addRouteParam(OilHttp.updateTopSales, { id }), data);
  }

  public createOil(data: FormData): Promise<IHttpResponse<IOilInfo>> {
    return serverAxios.post(OilHttp.create, data);
  }
  public getFilter(): Promise<IHttpResponse<IOilFilter>> {
    return serverAxios.get(OilHttp.filter);
  }
  public deleteOil(id: number): Promise<IHttpResponse<null>> {
    return serverAxios.delete(OilHttp.delete + id);
  }
  public deleteOilAll(): Promise<IHttpResponse<null>> {
    return serverAxios.delete(OilHttp.deleteAll);
  }

  public deleteOilSelected(ids: Array<string>): Promise<IHttpResponse<null>> {
    return serverAxios.delete(OilHttp.deleteSelected, { params: { ids: ids } });
  }
}

export default OilHttp;
