import { IEbook } from 'applications/automotor/components/espace/navbar/Navbar.model';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import ButtonLink from '../buttons/ButtonLink/ButtonLink';
import Select from 'react-select';
import useHttp from 'utils/hooks/useHttp';
import { ICatalogs } from 'models/catalog.model';
import { useAppDispatch } from 'utils/hooks/redux';
import { fetchEbooksData } from 'store/reducers/catalog/ebooks.actions';
import Spinner from 'components/spinner/Spinner';
import './EbookList.scss';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';

type Props = {
  title: string;
  ebooks: IEbook[];
  className?: string;
  itemClassName?: string;
  onItemClick?: (item: string) => void;
  image?: string;
};

const EbookListView: React.FC<Props> = (props: Props) => {
  const { control } = useForm();
  const http = useHttp();
  const [catalogs, setCatalogs] = useState<ICatalogs[]>([]);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const { id: catalogId } = useParams();
  const { t } = useTranslation();
  const { isMobile, isTablet } = useDeviceDetect();

  function getCatalogs() {
    http.catalogs.getCatalogsData().then((res) => setCatalogs(res));
  }

  useEffect(() => {
    getCatalogs();
  }, []);

  const getCatalogOptions = () => {
    if (catalogs.length) {
      return (
        catalogs.map((item) => ({
          label: item.name,
          value: item.id,
        })) || []
      );
    }
  };

  function handleSelectCatalogChange({ value }) {
    setLoading(true);
    dispatch(fetchEbooksData(value)).finally(() => {
      setLoading(false);
    });
  }

  useEffect(() => {
    setLoading(true);
    dispatch(fetchEbooksData(catalogId || '')).finally(() => {
      setLoading(false);
    });
  }, []);

  return (
    <div className="container-ebook-body">
      <h1 className={`heading ${props.className}`}>{props.title}</h1>
      {!catalogId && (
        <div className="catalog-select">
          <label className="catalog-label" htmlFor="">
            {t('_select_supplier')}
          </label>
          <Controller
            control={control}
            name="catalog"
            render={({ field: { onChange, value } }) => (
              <Select
                value={value}
                defaultValue={getCatalogOptions()?.[0]}
                className="ebooks-select-dropdown"
                onChange={(val) => {
                  onChange(val);
                  handleSelectCatalogChange(val);
                }}
                options={getCatalogOptions()}
                placeholder={t('_select_catalog')}
                isSearchable={!isMobile && !isTablet}
              />
            )
            }
          />
        </div >
      )}

      {
        !loading ? (
          <div className="container-ebook">
            {
              props.ebooks.length ? (
                props.ebooks.map((item, index) => {
                  return (
                    <ButtonLink
                      text={item.name}
                      image={item.image}
                      pdf_name={item.pdf_name}
                      key={index}
                      onClick={() => props.onItemClick?.(item.pdf_name)}
                      className={`check ${props.itemClassName}`}
                    ></ButtonLink>
                  );
                })
              ) : (
                <div className="no-data">{t('_no_data').toLocaleUpperCase()}</div>
              )}
          </div>
        ) : (
          <Spinner class="fetch-ebooks-spinner" />
        )}
    </div>
  );
};

EbookListView.displayName = 'EbookListView';

export default EbookListView;
