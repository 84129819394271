import React from 'react';
import './TabStyle.scss';

type Props = {
  title: string;
  index: number;
  selectedTab: number;
  setSelectedTab: (index: number) => void;
  disabled?: boolean
};

const TabTitle: React.FC<Props> = (props) => {
  return (
    <div className={`tab-item ${props.selectedTab === props.index ? 'active' : ''} ${props.disabled ? 'disabled': ''}`} onClick={() => props.setSelectedTab(props.index)}>
      {props.title}
    </div>
  );
};

TabTitle.displayName = 'TabTitle';

export default TabTitle;
