import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { ReactComponent as PriceTag } from 'assets/images/price-tag.svg';

type Props = {};

const PriceList: React.FC<Props> = () => {
  const { t } = useTranslation();

  return (
    <div className="item-price">
      <NavLink to="/price/tariffs">
        <div className="title">{t('_price_list')}</div>
        <div className="icon-wrapper">
          <div className="icon">
            <PriceTag />
          </div>
          <div className="text">{t('_price_list')}</div>
        </div>
      </NavLink>
    </div>
  );
};

PriceList.displayName = 'PriceList';

export default PriceList;
