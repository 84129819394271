
import {useTranslation} from 'react-i18next';
import {langDefaultEina} from '../rmi/rmi-utils/rmi-utils';


const useEinaLanguage = (): string => {
  const { i18n } = useTranslation();

  const currentLocale = i18n.language;
  const currentLanguage = currentLocale.split('-')[0];

  //check if current language is in the list of languages supported by Eina
  if (langDefaultEina.includes(currentLanguage)) {
    return currentLanguage;
  }

  return 'en';
};

export default useEinaLanguage;