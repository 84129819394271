import React, {useRef, useState} from 'react';
import DefaultIcon from 'components/buttons/DefaultIcon/DefaultIcon';

import {faUpload, faComment, faFileContract, faHeart, faGear, faXmark} from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { setShowEbookSidebar } from 'store/slices/ebooks.slice';
import {useDeviceDetect} from 'utils/hooks/deviceDetect';
import {useOnClickOutside} from 'utils/hooks/useOnClickOutside';
import {RootState} from 'store/store';
import './NavbarGroupBtns.scss';
import useTimer from 'utils/hooks/useTimer';
import useCountNewMessages from 'utils/hooks/useCountNewMessages';

type Props = {};

const EspaceTariffsFloatableButtons: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [buttonsAreaExpanded,setButtonsAreaExpanded] = useState<boolean>(false);
  const {isMobile} =useDeviceDetect();
  const { id: catalogId } = useParams();
  const { t } = useTranslation();
  const clickRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  useTimer();
  useCountNewMessages();
  const {newMessagesNumber} = useSelector((state: RootState) => state.messenger);
  const totalNotificationsNumber = newMessagesNumber + 0;
    
  useOnClickOutside(clickRef, () => {
    setButtonsAreaExpanded(false);
  });


  const changeAreaExpanded = () => {
    setButtonsAreaExpanded((prevState) => !prevState);
  };

  const goToCatalogMessages = () => {
    if (catalogId) {
      navigate(`catalog/${catalogId}/messages`);
    }
  };


  const handleEbooksClick = () => {
    dispatch(setShowEbookSidebar(true));
  };

  return (

    isMobile ? (
      <div className="floatable-buttons-wrapper" ref={clickRef}>
        <DefaultIcon onClick={changeAreaExpanded} number={buttonsAreaExpanded ? 0 : totalNotificationsNumber} icon={buttonsAreaExpanded ? faXmark : faGear} />        <div className={`buttons ${buttonsAreaExpanded ? 'show':'hidden'}`}>
          <DefaultIcon onClick={() => null} icon={faUpload}  />
          <DefaultIcon onClick={goToCatalogMessages} number={newMessagesNumber} icon={faComment}  />
          <DefaultIcon onClick={goToCatalogMessages} icon={faHeart}  />
          <DefaultIcon onClick={handleEbooksClick} icon={faFileContract}  />
        </div>
      </div>
    ) : (
      <React.Fragment>
        <DefaultIcon onClick={() => null} icon={faUpload} text={t('_import_csv')} />
        <DefaultIcon onClick={goToCatalogMessages} number={newMessagesNumber} icon={faComment} text={t('_messages')} />
        <DefaultIcon onClick={goToCatalogMessages} icon={faHeart} text={t('_favorite')} />
        <DefaultIcon onClick={handleEbooksClick} icon={faFileContract} text={t('_catalog_ebooks')} />
      </React.Fragment>
    )
  );
};

EspaceTariffsFloatableButtons.displayName = 'SupplierFolatableBtns';

export default EspaceTariffsFloatableButtons;
