import React from 'react';
import { CellProps } from 'react-table';
import {CartTableItem} from '../../CartTable.model';
import PriceQuantityCellView from './PriceQuantityCell.view';

type Props = CellProps<CartTableItem> & {
  price: number
  quantity: number
  priceVAT?: string
};

const PriceQuantityCell: React.FC<Props> = (props: Props) => {
  // only two decimals
  const totalPrice = parseFloat((props.price * props.quantity).toFixed(2));
  const totalPriceWithVAT = parseFloat((Number(props.priceVAT) * props.quantity).toFixed(2));

  return <PriceQuantityCellView value={totalPrice} priceVAT={totalPriceWithVAT}></PriceQuantityCellView>;
};

PriceQuantityCell.displayName = 'PriceCell';

export default PriceQuantityCell;
