import React, {useState} from 'react';
import './ErrorCodes.scss';
import {IActiaReportData} from '../../../../components/DiagnosticItems/Actia/actia';
import Spinner from '../../../../components/spinner/Spinner';
import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faFile, faForward, faList} from '@fortawesome/free-solid-svg-icons';
import ErrorCodeItem from './ErrorCodeItem/ErrorCodeItem';
import ErrorCodesNavLinks from '../../ErrorCodes/ErrorCodesNavLinks/ErrorCodesNavLinks';
import {generatePath, Outlet, useParams} from 'react-router-dom';
import { uniqBy } from 'lodash';
import useReliableCatalogCarQueryParams from '../../../../utils/hooks/useReliableCatalogCarQueryParams';
import {notify} from '../../../../utils/marketplace';
import useHttp from '../../../../utils/hooks/useHttp';
import {useSelector} from 'react-redux';
import {RootState} from '../../../../store/store';

type Props = {
    fileName: string | null;
    loading: boolean;
    errorCodesOrganized: Array<IActiaReportData>[];
    selectedCode: string;
    setSelectedCode: (code:string) => void
}

const ErrorCodesView: React.FC<Props> = (props) => {
  const {t} = useTranslation();
  const { vehicle_id, session_id } = useParams();
  const {user} = useSelector((state: RootState) => state.userData);
  const code = props.selectedCode;
  const reliableCatalogCarQueryParams = useReliableCatalogCarQueryParams();
  const paramsObject = {...reliableCatalogCarQueryParams, code};
  
  const {actiaHttp} = useHttp();
  const [downloadLoading, setDownloadLoading] = useState(false);

  const handleDownload = () => {
    setDownloadLoading(true);

    actiaHttp.downloadReport(String(session_id))
      .then((data) => {

        if(data.status === 404) {
          notify(t('_actia_file_not_ready'));
        } else if (data.status === 200 && typeof data.data === 'string'){
          const link = document.createElement('a');

          link.href = data.data;
          link.target = 'blank';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      })
      .catch((e) => {
        console.log(e);
        notify('_error');

      })
      .finally(() => {
        setDownloadLoading(false);
      });

  };


  const navLinks = [
    {
      icon: faFile,
      text: t('Docs technique'),
      // link: `/reliable/rmi/${vehicle_id}/diagnostic/${session_id}/error-codes/manuals?${(new URLSearchParams(paramsObject)).toString()}`,
      link: generatePath('/reliable/rmi/:vehicle_id/diagnostic/:session_id/error-codes/manuals', {vehicle_id: String(vehicle_id), session_id: String(session_id)}),
      disabled: user?.client_rmi_modules_type === 'none',
      queryParams: {code: code}
    },
    {
      icon: faForward,
      text: t('Solutions'),
      // link: `/reliable/rmi/${vehicle_id}/diagnostic/${session_id}/error-codes/solutions?${(new URLSearchParams(paramsObject)).toString()}`,
      link: generatePath('/reliable/rmi/:vehicle_id/diagnostic/:session_id/error-codes/solutions', {vehicle_id: String(vehicle_id), session_id: String(session_id)}),
      disabled: user?.client_settings.eina_subscription === false,
      queryParams: {keyword: code}

    },
    {
      icon: faList,
      text: t('Catalogues'),
      // link: `/reliable/rmi/${vehicle_id}/diagnostic/${session_id}/error-codes/catalogs?${(new URLSearchParams(paramsObject)).toString()}`,
      link:  generatePath('/reliable/rmi/:vehicle_id/diagnostic/:session_id/error-codes/catalogs', {vehicle_id: String(vehicle_id), session_id: String(session_id)}),
      disabled: true
    },
  ];

  return (
    <div className="diagnostic-error-codes">
      <div className="content">
        <div className="header">
          {
            !props.loading &&  (
              <div className="file">
                <a href="#" onClick={handleDownload}>
                  {
                    downloadLoading ? (
                      <Spinner class="extra-small"/>
                    ) : (
                      <FontAwesomeIcon icon={faFile}/>
                    )
                  }
                  <span>{t(props.fileName as string)}</span>
                </a>
              </div>
            )
          }

          <div className="title">
            <p>{t('_header_diagnostic_error_code_title')}</p>
          </div>
        </div>
        <div className={`${props.errorCodesOrganized.length ? 'data' : ''}`}>
          {
            props.loading ? (
              <Spinner/>

            ) : (
              props.errorCodesOrganized.map((item, index) => {
                return (
                  <ErrorCodeItem setSelectedCode={props.setSelectedCode} selectedCode={props.selectedCode} key={index} items={uniqBy(item, 'code')}/>
                );
              })
            )
          }
        </div>
        {
          !props.loading && !props.errorCodesOrganized.length && (
            <p className="no-data">{t('_no_data')}</p>
          )
        }
        {
          props.selectedCode !== 'undefined' && (
            <div className="info">
              <ErrorCodesNavLinks navLinks={navLinks}/>
            </div>
          )
        }

      </div>
    </div>
  );
};

ErrorCodesView.displayName = 'ErrorCodesView';

export default ErrorCodesView;