import React, {useEffect} from 'react';
import {useAppDispatch} from '../redux';
import {useSelector} from 'react-redux';
import {RootState} from 'store/store';
import {setActivePartCategory} from 'store/slices/car-parts-categories.slice';

const useSelectFirstActiveCategory = ():void => {
  const { partsCategories, activePartCategory} = useSelector((state: RootState) => state.carPartsCategories);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if(partsCategories.length && !activePartCategory){
      dispatch(setActivePartCategory(partsCategories[0]));
    }
  },[partsCategories]);
};

export default useSelectFirstActiveCategory;