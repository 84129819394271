import React from 'react';
import ContentPriceManagement from './ContentPriceManagement/ContentPriceManagement';
import ProvidersPrice from './ProvidersPrice/ProvidersPrice';
import PriceList from './Pricelist/PriceList';
import PartsReplacement from './PartsReplacement/PartsReplacement';
import './PriceGroup.scss';

const PriceGroup: React.FC = () => {

  return (
    <div className="price-group-container">
      <div className="actions">
        <ContentPriceManagement />
        <ProvidersPrice />
        <PriceList />
        <PartsReplacement />
      </div>
    </div>
  );
};

PriceGroup.displayName = 'PriceGroup';

export default PriceGroup;
