import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useHttp from 'utils/hooks/useHttp';
import { notify } from 'utils/marketplace';
import { AxiosError } from 'axios';
import Spinner from 'components/spinner/Spinner';
import { IBattery } from 'interfaces/battery';
import BatteryPhotoGallery from '../BatteriesPhotoGallery/BatteryPhotoGallery';
import BatteryDetails from '../BatteriesDetails/BatteryDetails';
import BatteryOptions from '../BatteriesOptions/BatteryOptions';
import './BatteriesViewMobile.scss';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleArrowLeft } from '@fortawesome/free-solid-svg-icons';

const BatteriesViewMobile: React.FC = () => {
  const http = useHttp();
  const { id } = useParams<'id'>();

  const [batteries, setBatteries] = useState<IBattery>();
  const [loading, isLoading] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    document.body.classList.add('footer-padding');
    getBattery();

    return () => {
      document.body.classList.remove('footer-padding');
    };
  }, []);

  const getBattery = async () => {
    isLoading(true);

    try {
      await http.batteriesHttp.getBattery(String(id)).then((res) => setBatteries(res.data));
    } catch (e: unknown) {
      if (e instanceof AxiosError) {
        const message = e?.response?.data?.detail;

        notify(message);
      }
    } finally {
      isLoading(false);
    }
  };

  return (
    <div className="battery-view item-view-wrapper">
      {loading ? (
        <Spinner class="small" />
      ) : (
        batteries && (
          <React.Fragment>
            <div className="item-view-header">
              <div className="mobile-battery-item-reference">
                <span>Ref</span>: {` ${batteries.data_supplier_reference_original}`}
              </div>
              <div className="mobile-battery-brand">
                {`${batteries.data_supplier.name} ${batteries.data_supplier_reference_original}`}
              </div>
              <div className="return-button">
                <span
                  className="returnButton"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <FontAwesomeIcon icon={faCircleArrowLeft} className="icon" />
                  {t('_return')}
                </span>
              </div>
            </div>
            <div className="item-view-content">
              <div className="gallery">
                <BatteryPhotoGallery battery={batteries} />
              </div>
              <div className="details">
                <BatteryDetails battery={batteries} />
              </div>
              <div className="options">
                <BatteryOptions battery={batteries} />
              </div>
            </div>
            {/*<div className="item-view-footer"></div>*/}
          </React.Fragment>
        )
      )}
    </div>
  );
};

export default BatteriesViewMobile;
