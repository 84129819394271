import SkeletonSpinner from 'components/skeleton-spinner/SkeletonSpinner';
import { IData } from 'models/order';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import useHttp from 'utils/hooks/useHttp';
import './RetailerOrderInfo.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../store/store';
import CURRENCY from '../../../../../../utils/constants/currency';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';

type Props = {
  loading: boolean;
  setLoading: (loading: boolean) => void;
}

const RetailerOrderInfo: React.FC<Props> = (props) => {
  const http = useHttp();
  const { order_id } = useParams();
  const [data, setData] = useState<IData>();
  const { t } = useTranslation();
  const { user } = useSelector((state: RootState) => state.userData);
  const currency = user?.currency ? CURRENCY[user?.currency] : '';
  const { isMobile, isTablet } = useDeviceDetect();

  function getOrderInfo() {
    props.setLoading(true);
    http.orderClients.getOrderById(order_id!).then(res => setData(res.data)).finally(() => {
      props.setLoading(false);
    });
  }

  const titleCase = (string) => {
    if (!string.length) {
      return '';
    }

    return string[0].toUpperCase() + string.slice(1).toLowerCase();

  };

  useEffect(() => {
    getOrderInfo();
  }, []);

  return (
    <div className="order-client-info" style={props.loading ? { display: 'flex' } : isMobile || isTablet ? { display: 'grid' } : { display: 'flex' }} >
      {props.loading ? <SkeletonSpinner /> : (
        <React.Fragment>
          <div className="order-info-item">
            <div className="order-label">{titleCase(t('_date'))}:</div>
            <div className="order-value">{data?.time}</div>
          </div>
          <div className="order-info-item">
            <div className="order-label">{titleCase(t('_order_number'))}:</div>
            <div className="order-value">#{data?.id}</div>
          </div>
          <div className="order-info-item">
            <div className="order-label">{titleCase(t('_proforma_id'))}:</div>
            <div className="order-value">{data?.proforma_id}</div>
          </div>
          <div className="order-info-item">
            <div className="order-label">{titleCase(t('_order_ref'))}:</div>
            <div className="order-value">{data?.system_order_id ? data?.system_order_id : '#'}</div>
          </div>
          <div className="order-info-item">
            <div className="order-label">{titleCase(t('_status'))}:</div>
            <div className="order-value">{t('_sent')}</div>
          </div>
          <div className="order-info-item">
            <div className="order-label">{titleCase(t('_name'))}:</div>
            <div className="order-value">{data?.user_full_name}</div>
          </div>
          <div className="order-info-item">
            <div className="order-label">{titleCase(t('_email'))}:</div>
            <div className="order-value">{data?.user_email}</div>
          </div>
          <div className="order-info-item">
            <div className="order-label">{titleCase(t('_amount'))}:</div>
            <div className="order-value">{data?.total_price ? data?.total_price.toFixed(2) : ' - '} {currency}</div>

          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default RetailerOrderInfo;