import React from 'react';
import './DiagnosticLayout.scss';
import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DiagnosticsItems from '../../../../../components/DiagnosticItems/DiagnosticsItems';

const DiagnosticLayout: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="diagnostic-layout">
      <div className="diagnostic-header">
        <div className="title">
          <h2>{t('_diagnostic_title_page')}</h2>
        </div>
        <DiagnosticsItems />
      </div>
      <div className="diagnostic-content">
        <Outlet />
      </div>
    </div>

  );
};

DiagnosticLayout.displayName = 'DiagnosticLayout';

export default DiagnosticLayout;
