import React, { useEffect, useState } from 'react';
import { ReactComponent as PartsNotFoundImage } from 'assets/images/parts-not-found.svg';
import Pagination from 'components/pagination/Pagination';
import Spinner from 'components/spinner/Spinner';
import { useTranslation } from 'react-i18next';
import useHttp from 'utils/hooks/useHttp';
import { useLocation, useSearchParams } from 'react-router-dom';
import CatalogNavbar from 'components/navbar/catalog-navbar/CatalogNavbar';
import './CatalogTyres.scss';
import ProductsViewMode from 'components/ProductsViewMode/ProductsViewMode';
import { DEFAULT_VIEW } from 'components/ProductsViewMode/ProductsViewMode.constants';
import ProductsViewModeChanger from 'components/ProductsViewModeChanger/ProductsViewModeChanger';
import { CATALOG_TYRE } from 'components/ProductsViewModeChanger/products-view-mode-changer-utils';
import List from 'components/List/List';
import { ICategory } from 'views/Sidebar/Sidebar.model';
import axios from 'axios';
import { sortByOptions } from 'components/SortBy/SortBy.map';
import SortBy, { SortByParams } from 'components/SortBy/SortBy';
import CatalogTyresFilter from './CatalogTyresFilter/CatalogTyresFilter';
import { IBodyResponseTyre, ITyreFilter } from 'interfaces/tyre';
import { filterObject } from 'utils/utils';
import { getTranslationName } from 'utils/locale';
import TextInput from '../../../../components/inputs/TextInput/TextInput';
import { isRetailerMecadepotChild } from 'utils/isRetailerMecadepotChild';

const CatalogTyres: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { t, i18n } = useTranslation();
  const http = useHttp();
  const { search } = useLocation();

  const [loading, setLoading] = useState(true);
  const [loadingCategories, setLoadingCategories] = useState(true);
  const [refData, setRefData] = useState<IBodyResponseTyre>();
  const [filterData, setFilterData] = useState<ITyreFilter>();
  const [openFilter, setOpenFilter] = useState(false);
  const [page, setPage] = useState(Number(searchParams.get('page')));
  const [searchInput, setSearchInput] = useState('');

  const [tyreCategories, setTyreCategories] = useState<ICategory[]>([]);
  const [activeCategory, setActiveCategory] = useState<{ id: number }>({ id: searchParams.get('category_id') ? Number(searchParams.get('category_id')) : tyreCategories[0]?.id });

  const user = JSON.parse(localStorage.getItem('userData')!);
  const [viewMode, setViewMode] = useState(user?.user_settings.products_view_mode || DEFAULT_VIEW);
  const cancelToken = axios.CancelToken.source();

  function getTyreCategories() {
    setLoadingCategories(true);
    http.catalogs.getTyreAllCategories().then((res) => {
      setTyreCategories(res);
    }).finally(() => setLoadingCategories(false));
  }

  function getTyresData(data) {
    setLoading(true);

    const sortBySelectedOption = sortByOptions.filter((item) => String(item.value) == String(searchParams.get('sort')))[0] ?? [];

    const sortParams: SortByParams = {
      sort_by_column: String(searchParams.get('sort') ?? ''),
      sort_by_asc: String(searchParams.get('asc') ?? sortBySelectedOption?.default_sort_asc ?? ''),
    };

    const params = {
      ...data,
      ...sortParams,
      page,
    };

    const validParams = validateParams(params);

    http.tyreHttp
      .getTyresProducts(validParams, cancelToken.token)
      .then((res) => {
        setRefData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          setLoading(false);
        }
      });
  }

  function getTyresFilter(params) {
    const filterParams = {
      ...params,
      page,
    };

    const validFilterParams = validateParams(filterParams);


    http.tyreHttp.getTyresFilters(validFilterParams).then((res) => {
      setFilterData(res.data);
    });
  }

  const validateParams = (data) => {
    return Object.entries(data).reduce((acc, [key, value]) => {
      if (!value) {
        return acc;
      }

      acc[key] = value;

      return acc;
    }, {});
  };

  const handlePageClick = (pageN: number) => {
    setPage(pageN);
  };

  const onCategoryClick = (category) => {

    setActiveCategory({ id: category.id });
    setSearchParams({ season_id: [category.id] });
    setPage(1);
  };

  useEffect(() => {
    if (tyreCategories.length) {
      const firstValidCategory = tyreCategories.find((category) => category.number_of_products !== 0);

      setActiveCategory({ id: Number(searchParams.get('season_id')) || Number(firstValidCategory?.id) });

      const params = {
        season_ids: searchParams.get('season_id') ? searchParams.getAll('season_id') : [String(firstValidCategory?.id)],
        brand_ids: searchParams.getAll('brand_ids'),
        widths: searchParams.getAll('widths'),
        heights: searchParams.getAll('heights'),
        diameters: searchParams.getAll('diameters'),
        load_indexes: searchParams.get('load_indexes'),
        speed_ratings: searchParams.get('speed_ratings'),
        page: searchParams.get('page'),
        search: searchInput,
        q: searchParams.get('q')
      };

      const filteredParams = filterObject(params);

      getTyresData(filteredParams);
    }

    return () => {
      cancelToken.cancel();
    };
  }, [
    i18n.language,
    page,
    search,
    searchInput,
    tyreCategories.length
  ]);

  useEffect(() => {
    getTyreCategories();
    getTyresFilter({});

  }, [i18n.language]);

  useEffect(() => {
    if (searchParams.get('season_id')) {
      setActiveCategory({ id: Number(searchParams.get('season_id')) });
    }

  }, [tyreCategories, searchParams.get('season_id')]);

  function filterOptionsElement() {
    return (
      <div className="catalog-parts-filter">
        <button onClick={() => setOpenFilter(true)} className={`catalog-parts-button ${openFilter ? 'active' : ''}`}>
          {t('_filter_results_btn')}
        </button>
      </div>
    );
  }

  return (
    <div className="tyres-wrapper">
      <CatalogNavbar option={filterOptionsElement()} title="_tyres_catalog" showSortBy={isRetailerMecadepotChild()} />
      <div className="separator"></div>

      <div className="filterOptions">
        <span className="search-parts-title">{t('_search')}</span>
        <TextInput
          name="search"
          defaultValue={searchInput}
          placeholder="Ex: 205 55 16 91"
          onChange={(e) => setSearchInput(e.target.value as string)}
          withIcon
        />

        {(refData?.data?.length && refData?.data?.length > 0 && !isRetailerMecadepotChild()) ? <SortBy /> : null}
        <div className="results">
          {refData?.total_count} {t('_results')}
        </div>

        <ProductsViewMode viewMode={viewMode} setViewMode={setViewMode} />

      </div>
      <div className="retailer-catalog-tyres-container">
        <div className="catalog-tyres-sidebar">
          {tyreCategories && tyreCategories.length ? (
            <List
              content={tyreCategories?.map((item) => {
                return { ...item, id: item.id, name: getTranslationName(item.name) };
              })}
              images={tyreCategories?.map((item) => item.image)}
              itemClassName="parts-list"
              itemId="id"
              onItemClick={onCategoryClick}
              activeCategory={activeCategory}
            />
          ) : null}
        </div>


        <div className="wrapperItems">
          {loadingCategories || loading ? (
            <Spinner class="car-parts-spinner" />
          ) : refData?.data?.length ? (
            <ProductsViewModeChanger catalog={CATALOG_TYRE} viewMode={viewMode} data={refData?.data} />
          ) : (
            <div className="no-parts-data">
              <div className="notFoundSvg">
                <PartsNotFoundImage />
              </div>
              <h1>{t('_tyres_not_found')}</h1>
            </div>
          )}
          {(refData && refData?.total_pages && refData?.page && refData?.total_pages > 1) && !loading ? (
            <Pagination pageCount={refData.total_pages} handlePageClick={handlePageClick} forcePage={refData.page} />
          ) : (
            ''
          )}
        </div>
      </div>
      <CatalogTyresFilter getData={getTyresData} filterData={filterData} openFilter={openFilter} setOpenFilter={setOpenFilter} />
    </div>
  );
};

export default CatalogTyres;
