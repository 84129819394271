import React from 'react';
import './QuantityInput.scss';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import Spinner from 'components/spinner/Spinner';

type Props = {
  initialValue?: number;
  onAdd?: () => void;
  onSubstract?: () => void;
  value: number | string;
  onChange?
  readonly?: boolean;
  red?: boolean
  disabled?: boolean
  disableAddBtn?: boolean
  disableSubBtn?: boolean
};
const QuantityInputView: React.FC<Props> = (props: Props) => {
  const { user } = useSelector((state: RootState) => state.userData);

  const disableAdd = (user?.numcli === Number(process.env.REACT_APP_CIRRUS_NUMCLI)) && props.disableAddBtn;

  return (
    <div className={`wrapper-quantity ${props.disabled ? 'disabled' : ''}`}>
      <button disabled={props.disableSubBtn} className="plusminus" onClick={props.onSubstract}>
        -
      </button>

      <input readOnly={props.readonly || false} onChange={props.onChange} type="number" className={`num ${props.red ? 'wrapper-quantity-red' : ''}`} value={Number(props.value).toString()} />

      < button disabled={disableAdd} className={`plusminus ${disableAdd ? 'disable-add-btn' : ''}`} onClick={props.onAdd}>
        +
      </button>
    </div >
  );
};


QuantityInputView.displayName = 'QuantityInputView';

QuantityInputView.defaultProps = {
  initialValue: 1,
};

export default QuantityInputView;
