import React, { useContext, useRef, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { IFavoriteList } from 'models/favorite';
import './FavoriteListSidebarItem.scss';
import { useOnClickOutside } from 'utils/hooks/useOnClickOutside';

import { useTranslation } from 'react-i18next';
import { FavoriteContext, IFavoriteContext } from 'context/FavoriteContext';
import { useSearchParams } from 'react-router-dom';
import { ReactComponent as EditImage } from 'assets/images/edit.svg';
import { ReactComponent as FolderImage } from 'assets/images/folder.svg';
import { ReactComponent as CloseImage } from 'assets/images/x.svg';

type Props = {
  listItem: IFavoriteList;
  getFavoriteItems: (favorite_category_id: string) => void;
  setOpenModal: (status: boolean) => void;
  setNewListActive: (status: boolean) => void;
  setNewListValue: (value: string) => void;
  setEditListItem: (item: IFavoriteList | null) => void;

}

const FavoriteListSidebarItem: React.FC<Props> = (props) => {
  const { selectedList, setSelectedList, favoriteItemsLoading, loadingCreateList, setNewListActive } = useContext(FavoriteContext) as IFavoriteContext;
  const [, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const [optionsActive, isOptionsActive] = useState<boolean>(false);
  const dropdownRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  useOnClickOutside(dropdownRef, () => isOptionsActive(false));

  const isSelected = () => {
    return selectedList?.id === props.listItem.id;
  };

  const handleOnClick = () => {
    if (favoriteItemsLoading) {
      return;
    }

    if (isSelected()) {
      return;
    }

    setSearchParams({ category: String(props.listItem.id) });
    setSelectedList(props.listItem);
    props.getFavoriteItems(String(props.listItem.id));

  };

  const handleOptionsClick = (e) => {
    if (loadingCreateList) {
      isOptionsActive(false);

      return;
    }

    e.stopPropagation();
    isOptionsActive(!optionsActive);
  };

  const handleEdit = (e) => {
    e.stopPropagation();
    props.setNewListActive(true);
    props.setNewListValue(props.listItem.name);
    props.setEditListItem(props.listItem);
    isOptionsActive(false);
  };

  const handleRemove = () => {
    props.setNewListActive(false);
    props.setEditListItem(props.listItem);
    props.setOpenModal(true);
    isOptionsActive(false);
  };

  return (
    <div className={`favorite-list-sidebar-item-wrapper ${isSelected() ? 'selected' : ''}`} onClick={handleOnClick}>
      <div className="favorite-list-sidebar-item">
        <div className="icon">
          <FolderImage />
        </div>
        <div className="title-favorite">
          <p>{props.listItem.name}</p>
        </div>
        <div className="options-wrapper" onClick={(e) => handleOptionsClick(e)}>
          <div className="options" >
            <FontAwesomeIcon icon={faEllipsisV} />
            {
              optionsActive && (
                <div className="options-content" ref={dropdownRef}>
                  <div className="option" onClick={handleEdit}>
                    <EditImage />
                    <span>{t('_edit')}</span>
                  </div>
                  <div className="option" onClick={handleRemove}>
                    <CloseImage />
                    <span>{t('_delete')}</span>
                  </div>
                </div>
              )
            }
          </div>
        </div>
      </div>
    </div>
  );
};

FavoriteListSidebarItem.displayName = 'FavoriteListSidebarItem';

export default FavoriteListSidebarItem;