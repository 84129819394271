import React, { useEffect, useState } from 'react';
import GlobalSearchInput from 'components/inputs/GlobalSearchInput/GlobalSearchInput';
import RegisterClientModal from './RegisterClientModal/RegisterClientModal';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import GeneralSearch from 'components/general-search/GeneralSearch';
import {ReactComponent as ReliableIcon} from 'assets/images/reliable_logo.svg';
import { useSearchParams } from 'react-router-dom';
import useHttp from 'utils/hooks/useHttp';
import './PublicHome.scss';
import Modal from 'components/modal/Modal';
import { Trans, useTranslation } from 'react-i18next';

type Props = {}

const PublicHome:React.FC<Props> = () => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const http = useHttp();
  const {t} = useTranslation();
  const [hasAccess, setHasAccess] = useState(localStorage.getItem('accessToken'));

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (!searchParams.get('accessToken') && !localStorage.getItem('accessToken')) {
      setOpenModal(true);

      return;
    }

    const token = searchParams.get('accessToken');

    http.publicApp.accessToken(token || localStorage.getItem('accessToken')).then(res => {
      setHasAccess(res.data);

      if (token) {
        localStorage.setItem('accessToken', token);
      }

      setOpenModal(false);
    }).then(() => {
      searchParams.delete('accessToken');
      setSearchParams(searchParams);
      // history.replace({
      //   search: Object.keys(urlParams).map(key => `${key}=${urlParams[key]}`).join('&'),
      // });
    });
  }, []);

  return (
    <div className="public-home">
      {!hasAccess && (
        <div className="register-client-bar">
          <div className="register-btn">
            <DefaultButton text={t('_register')} onClick={() => setOpenModal(true)} />
          </div>
        </div>
      )}
      <div className="public-heading">
        <div className="title">
          <Trans
            i18nKey="_public_title"
            components={{b: <b/>}}
          />
        </div>
        <div className="public-reliable-logo">
          <ReliableIcon />
        </div>
        <div className="header-search-input">
          <GlobalSearchInput
            placeholder={t('_free_search')}
            size="l"
            withIcon
          />
        </div>
      </div>
      <div className="general-search">
        <GeneralSearch />
      </div>
      {hasAccess ? 
        <Modal
          openModal={openModal}
          setOpenModal={setOpenModal}
          childrenComponent={<RegisterClientModal hasAccess={hasAccess} setOpenModal={setOpenModal} />}
        />
        :
        <Modal
          openModal={openModal}
          childrenComponent={<RegisterClientModal hasAccess={hasAccess} setOpenModal={setOpenModal} />}
        />
      }
    </div>
  );
};

export default PublicHome;