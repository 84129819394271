import React from 'react';
import PromotionView from './Promotion.view';
import PromotionMobileView from './Mobile/PromotionMobile.view';


import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import { IPromotionDetails } from 'utils/hooks/http/promotions.http';

type Props = {
  items: IPromotionDetails[];
  slidesPerView?: number | 'auto';
  navigation?: boolean;
  pagination?: boolean;
  autoplay?: boolean;
  loop?: boolean;
  iconsArrowRight?
  iconsArrowLeft?
};

const Promotion: React.FC<Props> = (props) => {
  const { isMobile, isTablet } = useDeviceDetect();


  return (
    isMobile || isTablet ?
      <PromotionMobileView {...props} />
      :
      <PromotionView {...props} />
  );
};

Promotion.displayName = 'Promotion';

export default Promotion;
