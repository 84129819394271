import { CancelToken } from 'axios';
import { IHttpResponse, serverAxios } from '../../http';
import { IGlobalSearchData } from '../../../interfaces/globalSearch';

class GlobalSearchHttp {
  private static route = 'reliable/global-search';


  public async search(data: string, cancelToken: CancelToken): Promise<IHttpResponse<IGlobalSearchData>> {
    return serverAxios.get(GlobalSearchHttp.route, { params: { q: data }, cancelToken: cancelToken });
  }


}


export default GlobalSearchHttp;
