import React, { useEffect, useState } from 'react';

import MenuSidebarView from './MenuSidebar.view';
import { useLocation } from 'react-router-dom';

type Props = {};

const MenuSidebar: React.FC<Props> = () => {
  const location = useLocation();

  const pathName = location.pathname.replace('/', '');
  const [selected, setSelected] = useState<string>(pathName);

  useEffect(() => {
    const pathName = location.pathname.replace('/', '');

    setSelected(pathName);
  }, [location]);

  return <MenuSidebarView selected={selected} setSelected={setSelected} />;
};

MenuSidebar.displayName = 'MenuSidebar';

export default MenuSidebar;
