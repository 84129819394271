import React from 'react';
import { NavLink, useParams, useSearchParams } from 'react-router-dom';
import { CATEGORY_FROM_POLK, CATEGORY_FROM_POLK_2 } from 'utils/utils';
import { IPartsCategory } from 'models/vehicle-catalogs';

import './GenArt.scss';
import { useTranslation } from 'react-i18next';
import useReliableCatalogCarQueryParams from 'utils/hooks/useReliableCatalogCarQueryParams';

type Props = {
  
  categories: IPartsCategory[];
  setOpenModalGrafics : (e:boolean)=> void
};

const GenArt: React.FC<Props> = (props) => {
  const [searchParams] = useSearchParams();
  const { vehicle_id } = useParams();

  const { t } = useTranslation();
  const reliableCatalogCarQueryParams = useReliableCatalogCarQueryParams();


  const manualLink = (subcateogry) => {

   
    const {
      code_groupe,
      code_repere,
      code_ssgroupe,
      fictiv_status,
      product_id,
      reference_name,
      reference_count,
      sourceg,
    } = subcateogry;

 
    let paramsObject: { [key: string]: string } = {};

    paramsObject.source = searchParams.get('source')!;
    paramsObject.vehicle_identifier = searchParams.get('vehicle_identifier')!;
    paramsObject.code_groupe = code_groupe;
    paramsObject.code_repere = code_repere;
    paramsObject.code_ssgroupe = code_ssgroupe;
    paramsObject.reference_count = reference_count;
    
    paramsObject = {...paramsObject, ...reliableCatalogCarQueryParams};
  
  
    // if ((sourceg === CATEGORY_FROM_POLK || sourceg === CATEGORY_FROM_POLK_2) && reference_count > 0) {
    //   if (reference_count === 1 && fictiv_status === 'Yes') {
    //     link = `/reliable/${String(vehicle_id)}/car-parts?${new URLSearchParams(paramsObject).toString()}`;
    //   } else if (reference_count === 1 && fictiv_status === 'No') {
    //     link = `/reliable/${String(vehicle_id)}/${product_id}/${reference_name}/car-parts?${new URLSearchParams(paramsObject).toString()}`;
    //   } else {
    //     link = `/reliable/${String(vehicle_id)}/polk-subcategory?${new URLSearchParams(paramsObject).toString()}`;
    //   }
    // } else {
    //   link = `/reliable/${String(vehicle_id)}/car-parts?${new URLSearchParams(paramsObject).toString()}`;
    // }
   
   
    return  `/reliable/rmi/${String(vehicle_id)}/catalogs/parts/part-catalog?${new URLSearchParams(paramsObject).toString()}`;
  };

  return (
    
    <div className="parts-modal">
      {
       
        props.categories.length  ?
          props.categories.map((item ,index) => {

            return (
              <div key={index} className="parts-modal-body">
                <div className="image">
                  <img src={item.image} alt="" key={`img-${index}`} />
                      
                </div>
                   
                <div className="content">
                  <p> {item.name}</p>
                   
                  {
                    item.subcategories.map((subcategory, key) =>{

                      return (
                        subcategory.is_three ?
                          (
                            <div key={key} className="content-subcategory" >
                              <ul>
                                <li key={key} >
                                  <p>{subcategory.name}</p> 
                                  <ul>
                                    {
                                      subcategory && subcategory.subcategories.map((subCat, subCatKey) => (
                                        subCat.disponible ? (
                                          <li key={key} >
                                            <NavLink
                                              key={subCatKey}
                                              to={manualLink(subCat)}
                                            >
                                              {`${subCat.name}`}
                                            </NavLink>
                                          </li>
                                        ) : (
                                          <p key={`disabled-${key}${subCatKey}`} className="disabled-subcategory">{`${subCat.name}`}</p>
                                        )
                                      ))
                                    }
                                  </ul>
                                </li>
                              </ul>
                            </div>
                          )
                          :
                          (
                            subcategory && subcategory.subcategories.map((subCat, subCatKey) => (
                              subCat.disponible ? (
                                <NavLink
                                  key={subCatKey}
                                  to={manualLink(subCat)}
                                >
                                  {`${subCat.name}`}
                                </NavLink>
                              ) : (
                                <p key={`disabled-${key}${subCatKey}`} className="disabled-subcategory">{`${subCat.name}`}</p>
                              )
                            ))
                
                          )
                      );

                    })
                  }
                </div>
              </div>
            );
               
          })
          :<div className="no-data"> 
            {t('_no_data')}
          </div>
      }

    </div>
 
  );

};

GenArt.displayName = 'GenArt';

export default GenArt;