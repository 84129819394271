import React from 'react';
import './DiagnosticItem.scss';
import DefaultButton from '../../buttons/DefaultButton/DefaultButton';

type Props = {
  name: string;
  buttonName: string;
  onClick: () => void;
  selected?: boolean | undefined;
  disabled: boolean;

}

const DiagnosticItemView: React.FC<Props> = (props) => {


  return (
    <div className={`diagnostic-item-container ${props.disabled ? 'inactive' : ''} ${props.selected === undefined ? '' : props.selected && props.selected ? 'active' : 'inactive'}`}>
      <div className="content">

        <div className="image">
        </div>
        <div className="name">
          <p>{props.name}</p>
        </div>
        <div className="button">
          <DefaultButton onClick={props.disabled ? () => null : props.onClick} text={props.buttonName}></DefaultButton>
        </div>
      </div>
    </div>
  );
};

DiagnosticItemView.displayName = 'DiagnosticItemViews';

export default DiagnosticItemView;