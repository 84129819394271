import React, { createContext, useState } from 'react';
import { TabCategories } from 'views/Sidebar/Sidebar.model';

type SidebarProviderProps = {
  children: React.ReactNode;
};

export type ISidebarContext = {
  sideBarTab: TabCategories | null;
  setSidebarTab: (tab: TabCategories | null) => void;
};

export const SidebarContext = createContext<ISidebarContext | null>(null);

export const SidebarContextProvider: React.FC<SidebarProviderProps> = (props) => {
  const [sideBarTab, setSidebarTab] = useState<TabCategories | null>(null);

  return <SidebarContext.Provider value={{ sideBarTab, setSidebarTab }}>{props.children}</SidebarContext.Provider>;
};
