import React, { useEffect, useState } from 'react';
import { ICustomValidation, IData } from 'utils/hooks/http/search-by-reference.http';
import useHttp from 'utils/hooks/useHttp';
import RefCardView from './RefCard.view';
import RefCardMobileView from './Mobile/RefCardMobile.view';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { IConsumableSearch } from 'interfaces/consumable';
import { IOilSearch } from 'interfaces/oil';
import { IBatterySearch } from 'interfaces/battery';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import { useTranslation } from 'react-i18next';


const RefCard: React.FC = () => {
  const [refData, setRefData] = useState<IData[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [inputConsumableValue, setConsumableValue] = useState('');
  const [inputOilValue, setOilValue] = useState('');
  const [inputBatteryValue, setBatteryValue] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [openPartDropdown, setOpenPartDropdown] = useState(false);
  const [loading, setLoading] = useState(false);
  const cancelToken = axios.CancelToken.source();
  const http = useHttp();
  const { register, getValues, resetField } = useForm();
  const { isMobile, isTablet } = useDeviceDetect();

  const [openConsumableDropdown, setOpenConsumableDropdown] = useState(false);
  const [loadingConsumable, setLoadingConsumable] = useState(false);
  const [consumableData, setConsumableData] = useState<IConsumableSearch[]>([]);

  const [oilData, setOilData] = useState<IOilSearch[]>([]);
  const [openOilDropdown, setOpenOilDropdown] = useState(false);
  const [loadingOil, setLoadingOil] = useState(false);

  const [openBatteryDropdown, setOpenBatteryDropdown] = useState(false);
  const [batteryData, setBatteryData] = useState<IBatterySearch[]>([]);
  const [loadingBattery, setLoadingBattery] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    if (!isOpen) {
      setOpenPartDropdown(false);
      setOpenConsumableDropdown(false);
      setOpenOilDropdown(false);
      setOpenBatteryDropdown(false);
    }

  }, [isOpen]);

  useEffect(() => {
    setIsOpen(openPartDropdown || openConsumableDropdown || openOilDropdown || openBatteryDropdown);
  }, [openPartDropdown, openConsumableDropdown, openOilDropdown, openBatteryDropdown]);

  const customValidations: ICustomValidation[] = [
    {
      regex: new RegExp(/^.{3,}$/),
      message: t('_ref_search_min_length'),
    }
  ];

  function refInputChange(event) {
    const inputData = event.target.value;

    setInputValue(inputData);

    if (inputData.length === 0) {
      setRefData([]);
      setOpenPartDropdown(false);
    }

  }

  function consumableChange(event) {
    const inputData = event.target.value;

    setConsumableValue(inputData);

    if (inputData.length === 0) {
      setConsumableData([]);
      setOpenConsumableDropdown(false);
    }

  }

  function oilChange(event) {
    const inputData = event.target.value;

    setOilValue(inputData);

    if (inputData.length === 0) {
      setOilData([]);

      setOpenOilDropdown(false);
    }

  }

  function batteryChange(event) {
    const inputData = event.target.value;

    setBatteryValue(inputData);

    if (inputData.length === 0) {
      setBatteryData([]);
      setOpenBatteryDropdown(false);
    }

  }


  useEffect(() => {
    if (inputValue.length >= 3) {
      getRefData(inputValue);
      setLoading(true);

      return () => {
        cancelToken.cancel();
      };
    }

  }, [inputValue]);

  useEffect(() => {
    if (inputConsumableValue.length >= 3) {
      setOpenConsumableDropdown(true);
      setLoadingConsumable(true);
      http.consumableHttp.consumableSearch({ q: inputConsumableValue }).then((res) => {
        setConsumableData(res.data);
      }).finally(() => setLoadingConsumable(false));

      return () => {
        cancelToken.cancel();
      };
    }

  }, [inputConsumableValue]);


  useEffect(() => {
    if (inputOilValue.length >= 3) {
      setOpenOilDropdown(true);
      setLoadingOil(true);
      http.oilHttp.oilSearch({ q: inputOilValue }).then((res) => {
        setOilData(res.data);
      }).finally(() => setLoadingOil(false));

      return () => {
        cancelToken.cancel();
      };
    }

  }, [inputOilValue]);

  useEffect(() => {
    if (inputBatteryValue.length >= 3) {
      setOpenBatteryDropdown(true);
      setLoadingBattery(true);
      http.batteriesHttp.batterySearch({ q: inputBatteryValue }).then((res) => {
        setBatteryData(res.data);
      }).finally(() => setLoadingBattery(false));

      return () => {
        cancelToken.cancel();
      };
    }

  }, [inputBatteryValue]);


  function getRefData(q) {

    setLoading(true);
    setOpenPartDropdown(true);

    http.searchByRef.SearchByRef(q, cancelToken.token)
      .then((res) => {
        setRefData(res.data);
        setLoading(false);
      }).catch((err) => {
        console.error(err);
      });
  }

  function submitData() {
    if (!inputValue) { return; }

    getRefData(inputValue);
    setOpenPartDropdown(true);
  }

  function consumableSearch() {
    if (inputConsumableValue.length < 3) { return; }

    setOpenConsumableDropdown(true);
    setLoadingConsumable(true);
    http.consumableHttp.consumableSearch({ q: inputConsumableValue }).then((res) => {
      setConsumableData(res.data);
    }).finally(() => setLoadingConsumable(false));
  }

  function oilSearch() {
    if (inputOilValue.length < 3) { return; }

    setOpenOilDropdown(true);
    setLoadingOil(true);
    http.oilHttp.oilSearch({ q: inputOilValue }).then((res) => {
      setOilData(res.data);
    }).finally(() => setLoadingOil(false));
  }

  function batterySearch() {
    if (inputBatteryValue.length < 3) { return; }

    setOpenBatteryDropdown(true);
    setLoadingBattery(true);
    http.batteriesHttp.batterySearch({ q: inputBatteryValue }).then((res) => {
      setBatteryData(res.data);
    }).finally(() => setLoadingBattery(false));
  }

  return (
    isMobile || isTablet ?
      <RefCardMobileView
        loading={loading}
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        refData={refData}
        refInputChange={refInputChange}
        submitData={submitData}
        refValue={inputValue}
        register={register}
        resetField={resetField}
        getValues={getValues}
        consumableSearch={consumableSearch}
        oilSearch={oilSearch}
        batterySearch={batterySearch}
        openConsumableDropdown={openConsumableDropdown}
        setOpenConsumableDropdown={setOpenConsumableDropdown}
        loadingConsumable={loadingConsumable}
        consumableData={consumableData}
        openOilDropdown={openOilDropdown}
        setOpenOilDropdown={setOpenOilDropdown}
        loadingOil={loadingOil}
        oilData={oilData}
        openBatteryDropdown={openBatteryDropdown}
        setOpenBatteryDropdown={setOpenBatteryDropdown}
        loadingBattery={loadingBattery}
        batteryData={batteryData}
        consumableChange={consumableChange}
        oilChange={oilChange}
        batteryChange={batteryChange}
        customValidations={customValidations}
        openPartDropdown={openPartDropdown}
        setOpenPartDropdown={setOpenPartDropdown}
      />
      :
      <RefCardView
        loading={loading}
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        refData={refData}
        refInputChange={refInputChange}
        submitData={submitData}
        refValue={inputValue}
        register={register}
        resetField={resetField}
        getValues={getValues}
        consumableSearch={consumableSearch}
        oilSearch={oilSearch}
        batterySearch={batterySearch}
        openConsumableDropdown={openConsumableDropdown}
        setOpenConsumableDropdown={setOpenConsumableDropdown}
        loadingConsumable={loadingConsumable}
        consumableData={consumableData}
        openOilDropdown={openOilDropdown}
        setOpenOilDropdown={setOpenOilDropdown}
        loadingOil={loadingOil}
        oilData={oilData}
        openBatteryDropdown={openBatteryDropdown}
        setOpenBatteryDropdown={setOpenBatteryDropdown}
        loadingBattery={loadingBattery}
        batteryData={batteryData}
        consumableChange={consumableChange}
        oilChange={oilChange}
        batteryChange={batteryChange}
        customValidations={customValidations}
        openPartDropdown={openPartDropdown}
        setOpenPartDropdown={setOpenPartDropdown}
        consumableValue={inputConsumableValue}
        batteryValue={inputBatteryValue}
        oilValue={inputOilValue}
      />
  );
};

RefCard.displayName = 'RefCard';

export default RefCard;
