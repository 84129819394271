import React from 'react';
import './ErrorCodesNavLinks.scss';
import { generatePath, useParams } from 'react-router-dom';
import { faFile, faForward, faList } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { IErrorCodesNavLinks } from './errorCodes';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';

import ErrorCodesNavLinksView from './ErrorCodesNavLinks.view';
import ErrorCodesNavLinksMobileView from './Mobile/ErrorCodesNavLinksMobile.view';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';

type Props = {
  navLinks?: IErrorCodesNavLinks[];
}

const ErrorCodesNavLinks: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { vehicle_id } = useParams<'vehicle_id'>();
  const { user } = useSelector((state: RootState) => state.userData);
  const { isMobile } = useDeviceDetect();

  let navLinks = props.navLinks;

  if (!navLinks) {
    navLinks = [
      {
        icon: faFile,
        text: t('_technical_documents'),
        disabled: user?.client_rmi_modules_type === 'none',
        link: generatePath('/reliable/rmi/:vehicle_id/error-codes/manuals', { vehicle_id: String(vehicle_id) })
      },
      {
        icon: faForward,
        text: t('_solutions'),
        disabled: user?.client_settings.eina_subscription === false,
        link: generatePath('/reliable/rmi/:vehicle_id/error-codes/solutions', { vehicle_id: String(vehicle_id) })
      },
      {
        icon: faList,
        text: t('_catalogues'),
        disabled: true,
        link: generatePath('/reliable/rmi/:vehicle_id/error-codes/catalogs', { vehicle_id: String(vehicle_id) })
      },
    ];
  }

  return (
    isMobile ?
      <ErrorCodesNavLinksMobileView navLinks={navLinks} />
      :
      <ErrorCodesNavLinksView navLinks={navLinks} />
  );
};

ErrorCodesNavLinks.displayName = 'ErrorCodesNavLinks';

export default ErrorCodesNavLinks;
