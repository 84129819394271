import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import './ErrorCodesListMobile.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronRight, faClose} from '@fortawesome/free-solid-svg-icons';
import Modal from 'components/modal/Modal';
import {IVehicleManual} from 'interfaces/vehicle-manuals.interface';

type Props = {
  onClick: (item) => void;
  manuals?: IVehicleManual[];
};

const ErrorCodesListMobile:React.FC<Props> = (props: Props) => {
  const {t} = useTranslation();
  const [showModal, setShowModal] = useState(false);

  return (
    <div className="error-codes-list container-fluid-medium">
      <div className="list-modal-btn" onClick={() => setShowModal(true)}>
        <div className="modal-btn">
          <div className="modules-modal-btn-text">{t('_error_codes_list')}</div>
        </div>
        <FontAwesomeIcon icon={faChevronRight} />
      </div>
      <Modal
        openModal={showModal}
        setOpenModal={setShowModal}
        customCssClass="error-codes-list-wrapper"
        childrenComponent={
          <div className="error-codes-list-modal-container">
            <div className="list-modal-header">
              <div className="title">{t('_error_codes_list')}</div>
              <div className="header-actions">
                <FontAwesomeIcon className="close-modal" icon={faClose} onClick={() => setShowModal!(false)} />
              </div>
            </div>
            <div className="list-modal-content">
              <div className="items">
                {props.manuals?.map((item, key) => {
                  return (
                    <div key={key} className="item" onClick={() => {
                        setShowModal(false);
                        props.onClick(item);
                      }}
                    >
                      {item.QualColText}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        }
      />
    </div>
  );
};

ErrorCodesListMobile.displayName = 'ErrorCodesListMobile';

export default ErrorCodesListMobile;