import React, { PropsWithChildren } from 'react';
import { IEspaceTabItem } from '../../../views/espace/Espace.model';
import { useSelector } from 'react-redux';
import { getIsLoadingCatalogData } from 'store/selectors/catalogs.selector';
import EspaceTabView from './EspaceTab.view';

type Props = {
  type: string | JSX.Element;
  onClick: () => void;
  description: string;
  buttonText: string;
  buttonLink: string;
  items: IEspaceTabItem[];
  enabled?: boolean;
  disableCatalog?: boolean;
  messagesNumber: number;
  warningsNumber: number;
  loading?:boolean;
  from?:string;
};
const EspaceTab: React.FC<Props> = (props: PropsWithChildren<Props>) => {
  const isLoading =  useSelector(getIsLoadingCatalogData);

  const result = props.items.reduce((resultArray: IEspaceTabItem[][], item, index) => {
    const chunkIndex = Math.floor(index / 6);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [];
    }

    resultArray[chunkIndex].push(item);

    return resultArray;
  }, []);

  return (
    <EspaceTabView
      messagesNumber={props.messagesNumber}
      warningsNumber={props.warningsNumber}
      disableCatalog={props.disableCatalog}
      enabled={props.enabled}
      buttonLink={props.buttonLink}
      buttonText={props.buttonText}
      description={props.description}
      items={result}
      itemsMobile={props.items}
      isLoading={props.loading ? props.loading : isLoading}
      type={props.type}
      onClick={props.onClick}
      from={props.from}
    />
  );
};

EspaceTab.displayName = 'EspaceTab';

export default EspaceTab;
