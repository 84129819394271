import React from 'react';

import HeadView from './Head.view';

type Props = {
  text: string;
  sortable?: boolean
  center?: boolean;
};

const Head: React.FC<Props> = (props: Props) => {
  return <HeadView center={props.center} sortable={props.sortable!} text={props.text}></HeadView>;
};

Head.defaultProps = {
  sortable: false,
  center: false
};
Head.displayName = 'Head';

export default Head;
