import { faDownload, faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Spinner from 'components/spinner/Spinner';
import { ITariff } from 'models/catalog.model';
import moment from 'moment';
import React from 'react';
import { getBackendAssetsBasePath } from 'utils/marketplace';
import './TariffFile.scss';

type Props = {
  file: ITariff;
  handleDownloadClick: (id: number, name: string) => void;
  handleDownloadPreviewClick: (id: number, name: string) => void;
  downloadIsLoading: boolean;
  downloadFromPreview: boolean;
};

const TariffFileView: React.FC<Props> = (props) => {
  const assetsBaseUrl = getBackendAssetsBasePath();
  const catalogData = props.file.created_at.date && props.file.created_at.date;
  const date = moment(catalogData).format('YYYY-MM-DD');

  return (
    <div className="tariff">
      <div className="actualisation">{date}</div>
      <div className="body">
        {/* {props.file.catalog_image && <img className="logo" src={`${assetsBaseUrl}${props.file.catalog_image}`} />} */}
        <div className="catalog-logo">
          {props.file.catalog_image && <img className="logo" src={`${assetsBaseUrl}${props.file.catalog_image}`} />}
        </div>
        <div className="file-name">Tariffs {props.file.catalog_name} 2022</div>
        <div className="action">
          <div className="preview">
            {!props.downloadFromPreview ? (
              <FontAwesomeIcon
                onClick={() => {
                  props.handleDownloadPreviewClick(props.file.id, props.file.catalog_name);
                }}
                className="preview-btn"
                icon={faEye}
              />
            ) : (
              <Spinner class="extra-small rem-1" />
            )}
          </div>
          <div className="download">
            {!props.downloadIsLoading ? (
              <FontAwesomeIcon
                onClick={() => {
                  props.handleDownloadClick(props.file.id, props.file.catalog_name);
                }}
                className="tariff-btn"
                icon={faDownload}
              />
            ) : (
              <Spinner class="extra-small rem-1" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

// const TariffFileView: React.FC<Props> = (props) => {
//   return (
//     <div className="tariff">
//       <div className="actualisation">Mis à jour</div>
//       <div className="body">
//         {props.images && <LogoItem image={`http://automotor.e-reliable.pro/api/${props.images} `} />}
//         <div className="file-name">Tariffs {props.name} 2022</div>
//         <div className="action">
//           <FontAwesomeIcon icon={faEye} />
//           <FontAwesomeIcon className="tariff-btn" icon={faDownload} />
//         </div>
//       </div>
//     </div>
//   );
// };

TariffFileView.displayName = 'TariffFile';

export default TariffFileView;
