import _ from 'underscore';
import { ICarPartData } from 'models/car-parts.model';
import React, { useRef, useState } from 'react';
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { checkIfImageExists } from 'utils/utils';
import Placeholder from 'assets/images/car-part-placeholder.png';
import './CarPartPhotoGallery.scss';

type Props = {
  carPart: ICarPartData;
};

const CarPartPhotoGallery: React.FC<Props> = (props) => {
  const [, setIsFullscreen] = useState<boolean>(false);
  const imageRef = useRef(null);
  // const [image, setImage] = useState('');
  // const [thumb, setThumb] = useState('');


  const renderImage = (item) => {
    // checkIfImageExists(item.original, (exists)=> exists ? setImage(item.original) : setImage(Placeholder));

    return (
      <div className="image-gallery-image">
        <img alt="car part" src={item.original} />
      </div>
    );
  };

  const renderThumb = (item) => {
    // checkIfImageExists(item.original, (exists)=> exists ? setThumb(item.original) : setThumb(Placeholder));

    return <div className="image-gallery-thumbnail-inner">
      <LazyLoadImage alt="car part" src={item.original} />
    </div>;
  };

  const getImages = () => {
    const { data_supplier_logo, images, image_360 } = props.carPart;

    const imageList: ReactImageGalleryItem[] = [];

    if (image_360 && image_360.endsWith('.html')) {
      const imageItem360: ReactImageGalleryItem = {
        renderThumbInner: () => (
          <i style={{ fontSize: '38px', color: '$dark-blue' }} className="material-icons">
            360
          </i>
        ),
        renderItem: () => (
          <div className="image-gallery-image">
            <div className="iframe-container">
              <iframe title="image360" src={image_360} scrolling="no" />
            </div>
          </div>
        ),
        original: image_360,
      };

      imageList.push(imageItem360);
    }

    images
      .filter((image) => image.split('.').reverse()[0] !== 'PDF')
      .map((image) => {
        const item = {
          original: image,
          thumbnail: image,
        };

        return imageList.push(item);
      });

    if (_.isEmpty(imageList)) {
      const dataSupplierImage = {
        original: data_supplier_logo,
        thumbnail: data_supplier_logo,
      };

      imageList.push(dataSupplierImage);
    }


    return (
      <ImageGallery
        ref={imageRef}
        items={imageList}
        showPlayButton={false}
        showNav
        showFullscreenButton
        renderFullscreenButton={(triggerFullScreen, isFullscreen) => (
          <button
            type="button"
            className={`${!isFullscreen ? 'image-gallery-fullscreen-button' : 'image-gallery-fullscreen-button width'}`}
            onClick={(event) => !isFullscreen && triggerFullScreen(event)}
          >
            {isFullscreen && <i className="fas fa-times" onClick={triggerFullScreen} />}
          </button>
        )}
        showBullets
        onScreenChange={() => setIsFullscreen((prev) => !prev)}
        renderItem={renderImage}
        renderThumbInner={(item) => renderThumb(item)}
      />
    );
  };

  return <div className={`car-part-thumbnail ${String(!props.carPart.image) ? 'empty' : ''}`}>{getImages()}</div>;
};

CarPartPhotoGallery.displayName = 'CarPartPhotoGallery';

export default CarPartPhotoGallery;
