import React, { useContext, useEffect, useState } from 'react';
import { ICarPartData } from 'models/car-parts.model';
import './FavoriteItem.scss';
import FavoriteButton from 'components/PartItem/PartItemOptions/FavoriteButton/FavoriteButton';

import { IFavoriteList } from 'models/favorite';
import { useTranslation } from 'react-i18next';
import { FavoriteContext, IFavoriteContext } from 'context/FavoriteContext';
import { NavLink } from 'react-router-dom';
import { getCurrentMarketplace } from 'utils/marketplace';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { Marketplaces } from 'utils/constants/applications';
import { ReactComponent as CheckedImage } from 'assets/images/checked.svg';
import { ReactComponent as UncheckedImage } from 'assets/images/unchecked.svg';

type Props = {
  car;
  checkedList: ICarPartData[] | [];
  setCheckedList: (checkedList: ICarPartData[]) => void;
};

const FavoriteItemView: React.FC<Props> = (props) => {
  const [checked, setChecked] = useState<boolean>(false);
  const { t } = useTranslation();
  const { list, setList, selectedList, getFavoriteItems } = useContext(FavoriteContext) as IFavoriteContext;
  const [link, setLink] = useState('');
  const userInfo = JSON.parse(localStorage.getItem('userData')!);

  useEffect(() => {
    setChecked(isChecked());
  }, [props.checkedList]);

  const handleChecked = (e) => {
    const isChecked = e.target.checked;

    setChecked(isChecked);

    if (isChecked) {
      addItemInList();
    } else {
      removeItemFromList();
    }
  };

  const addItemInList = () => {
    if (isChecked()) {
      return;
    }

    const newList = [...props.checkedList, props.car];

    props.setCheckedList(newList);
  };

  const removeItemFromList = () => {
    const newList = props.checkedList.filter((item) => item.data_supplier_reference !== props.car.data_supplier_reference);

    props.setCheckedList(newList);
  };

  const isChecked = () => {
    return props.checkedList.some((list) => list.data_supplier_reference === props.car.data_supplier_reference);
  };

  const getFirstImage = () => {
    return props.car.images[0] ?? '';
  };

  const getTitle = () => {
    let title = '-';

    if (props.car.data_supplier.name) {
      title = props.car.data_supplier.name;
    }

    if (props.car.data_supplier_reference) {
      title = `${title} ${props.car.data_supplier_reference}`;
    }

    return title;
  };

  const getSubcategory = () => {
    let subCategory = '-';

    if (props.car.subcategory) {
      subCategory = props.car.subcategory.name;
    }

    return subCategory;
  };

  const getDate = () => {
    return props.car.favorite_created_at;
  };

  const onDone = () => {
    getFavoriteItems(String(selectedList?.id));
  };

  const onListCreated = (listItem: IFavoriteList) => {
    setList([listItem, ...list]);
  };

  useEffect(() => {
    setLink(
      `/car-parts/view/${props.car.data_supplier_reference}?data_supplier_id=${props.car.data_supplier.id}&provider_id=${props.car.cod_fou}`,
    );
  });

  function calcLimit() {
    if (props.car.available && typeof props.car.available === 'object') {
      const newLimit = { ...props.car.available };

      delete newLimit.uv;

      return Object.values(newLimit).reduce((acc, item: typeof newLimit) => {
        if (item?.quantity === undefined) {
          return Number(acc) + Number(item);
        }

        return Number(acc) + Number(item.quantity);
      }, 0);
    }

    return props.car.available;
  }


  return (
    <div className="favorite-item-container">
      <div className="favorite-item">
        <NavLink className="favorite-image-wrapper" to={link}>
          <img className="image" alt="part_image" src={getFirstImage()} />
        </NavLink>
        <div className="wrapper">
          <div className="date">{getDate()}</div>
          <div className="nameWrapper">
            <NavLink className="title-item-favorite" to={link}>
              {getTitle()}
            </NavLink>
            <div className="description">{getSubcategory()}</div>
          </div>
          <div className="model">
            <span>{t('_reference')}: </span> <div className="modelDescription">{props.car.data_supplier_reference}</div>
          </div>
        </div>
        <div className="options">
          <div className="option-favorite">
            {props.car && <FavoriteButton onListCreate={onListCreated} onDone={onDone} carPart={props.car} />}
          </div>
          <div className="fav-status">
            <label htmlFor={'checkbox' + String(props.car.data_supplier_reference)}>
              <input
                id={'checkbox' + String(props.car.data_supplier_reference)}
                type="checkbox"
                className="checkboxField"
                checked={checked}
                onChange={(e) => handleChecked(e)}
              />
              {checked ? <CheckedImage /> : <UncheckedImage />}
            </label>
          </div>
        </div>
      </div>
      <div className={`disponible-title ${!calcLimit() ? 'unavailable' : ''}`}>
        {(getCurrentMarketplace() == Marketplaces.RETAILER ||
          getCurrentMarketplace() == Marketplaces.PUNCHOUT) && !calcLimit() ?
          (
            <div className="contact_number">
              <span className="stock-unavailability">{t('_non_disponible')}</span>
              {userInfo.phone ? (
                <span className="stock-unavailability">
                  {t('_contact_number')}
                  <a href={`tel:${userInfo?.distributor_info?.main_address?.tel}`}>
                    {' '}
                    <FontAwesomeIcon icon={faPhone} />
                    {userInfo?.distributor_info?.main_address?.tel}
                  </a>
                </span>
              ) : (
                ''
              )}
            </div>
          ) : null}
      </div>
    </div>
  );
};

FavoriteItemView.displayName = 'FavoriteItemView';

export default FavoriteItemView;
