import { createSlice } from '@reduxjs/toolkit';
import { IClient, IClientContact, IClientResponse } from 'models/client.model';
import { fetchClientContacts, fetchClients, fetchFilteredClients } from 'store/reducers/clients/clients.actions';

type ClientsSlice = {
  clients: IClientResponse | null;
  filteredClients: IClientResponse | null;
  clientsData: IClient[];
  clientContacts: IClientContact[];
  isLoadingClients: boolean;
  isLoadingContacts: boolean;
  newClientModal: boolean,
  page: number;
};

const initialState: ClientsSlice = {
  clients: null,
  filteredClients: null,
  clientsData: [],
  clientContacts: [],
  isLoadingClients: false,
  isLoadingContacts: false,
  newClientModal: false,
  page: 1,
};

const ClientsSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    setIsLoadingClients(state, { payload }) {
      state.isLoadingClients = payload;
    },
    setIsLoadingContacts(state, { payload }) {
      state.isLoadingContacts = payload;
    },
    nextPage(state) {
      state.page = +state.page + 1;
    },
    setPage(state, { payload }) {
      state.page = +payload;
    },
    previousPage(state) {
      if (state.page > 1) {
        state.page = +state.page - 1;
      }
    },
    setNewClientModal(state, {payload}){
      state.newClientModal = payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchClients.pending, (state) => {
      state.isLoadingClients = true;
    });

    builder.addCase(fetchFilteredClients.pending, (state) => {
      state.isLoadingClients = true;
    });

    builder.addCase(fetchClients.fulfilled, (state, { payload }) => {
      state.clients = payload;
      state.clientsData = state.clients.data;
      state.isLoadingClients = false;
      state.filteredClients = null;
    });

    builder.addCase(fetchFilteredClients.fulfilled, (state, { payload }) => {
      state.filteredClients = payload;
      state.clientsData = state.filteredClients.data;
      state.isLoadingClients = false;
    });

    builder.addCase(fetchClientContacts.pending, (state) => {
      state.isLoadingContacts = true;
    });
    builder.addCase(fetchClientContacts.fulfilled, (state, { payload }) => {
      state.clientContacts = payload;
      state.isLoadingContacts = false;
    });

    builder.addCase(fetchFilteredClients.rejected, (state) => {
      state.filteredClients = null;
      state.clientsData = state.clients?.data || [];
      state.isLoadingClients = false;
    });
  },
});

export const { setIsLoadingClients, setPage, setIsLoadingContacts, setNewClientModal } = ClientsSlice.actions;

export default ClientsSlice.reducer;
