import React from 'react';
import './Navbar.scss';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../store/store';
import moment from 'moment';
import useDefaultRouteForRole from 'utils/hooks/useDefaultRouteForRole';
import LogoutButton from 'components/buttons/LogoutButton/LogoutButton';
import SelectClientButton from 'components/buttons/SelectClientButton/SelectClientButton';
import { usePermission } from 'utils/usePermission';
import { ROLE_SELLER, ROLE_CLIENT_AUTOMOTOR_GROUP_MANAGER, ROLE_CLIENT_AUTOMOTOR, ROLE_COMMERCIAL_DIRECTOR } from 'utils/constants/roles';
import AutomotorClientManagerGrupClientButton from 'components/buttons/AutomotorClientManagerGrupClientButton/AutomotorClientManagerGrupClientButton';
import NotificationsDropdown from 'components/buttons/NotificationsDropdown/NotificationsDropdown';
import { Marketplaces } from 'utils/constants/applications';
import { getCurrentMarketplace } from 'utils/marketplace';

const EspaceNavbar: React.FC = () => {
  const { t } = useTranslation();
  const { user } = useSelector((state: RootState) => state.userData);
  const Seller = usePermission([ROLE_SELLER]);
  const Commercial = usePermission([ROLE_COMMERCIAL_DIRECTOR]);
  const isNotificationVisible = usePermission([ROLE_SELLER,ROLE_COMMERCIAL_DIRECTOR, ROLE_CLIENT_AUTOMOTOR]) && getCurrentMarketplace() === Marketplaces.AUTOMOTOR;
  const CLIENT_AUTOMOTOR_GROUP_MANAGE = usePermission([ROLE_CLIENT_AUTOMOTOR_GROUP_MANAGER]);

  const localTime = user?.last_connection_date ? moment.utc(user?.last_connection_date).local() : null;

  const defaultLinkForRole = useDefaultRouteForRole();

  return (
    <div className="espace-navbar-container">
      <div className="espace-navbar">
        <div className="espace-navbar-logo-select-client">
          <div className="espace-navbar-logo">
            {user?.client_logo &&
              <NavLink to={defaultLinkForRole}>
                <img src={user?.client_logo} className="svg-blue" alt="espace-logo" />
              </NavLink>
            }
          </div>
          <div className="select-client-button-container">
            {(Seller || Commercial) && <SelectClientButton />}
            {CLIENT_AUTOMOTOR_GROUP_MANAGE && <AutomotorClientManagerGrupClientButton />}
          </div>
        </div>
        <div className="espace-navbar-group">
          <div className="espace-navbar-user">
            <p className="user-name">
              {t('_hello')} {user && user.client_name ? <span>{user?.client_name},</span> : null}
            </p>
            {localTime && (
              <p className="user-activity">
                {t('_last_connection_on', { date: localTime.format('YYYY-MM-DD'), time: localTime.format('H:mm') })}
              </p>
            )}
          </div>
          <div className="espace-action-btns">
            {isNotificationVisible && <div className="notifications-dropdown">
              <NotificationsDropdown />
            </div>}

            <LogoutButton />
          </div>
        </div>
      </div>
    </div>
  );
};

EspaceNavbar.displayName = 'EspaceNavbar';

export default EspaceNavbar;
