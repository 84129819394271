import { addRouteParam, serverAxios } from '../../http';
import { ICategorieGroup } from 'applications/automotor/components/espace/navbar/Navbar.model';

class CategoriesHttp {
  private static route = '/categories';
  private static routeCategoriesVin = 'catalog/:catalog_id/:vehicle_id/categories';
  public async getCategories({ catalog_id, manufacturer_id, model_id }: ICategoriesParams, page = 1): Promise<ICategorieGroup[]> {

    const { data } = await serverAxios.get(CategoriesHttp.route, { params: { catalog_id, manufacturer_id, model_id, page }, });

    return data;
  }

  public async getCategoriesVin(param: ICategoriesParams): Promise<ICategorieGroup[]> {
    const { data } = await serverAxios.get(addRouteParam(CategoriesHttp.routeCategoriesVin, { catalog_id: param.catalog_id, vehicle_id: param.vehicle_id }), { params: param });

    return data;
  }
}

export interface ICategoriesParams {
  catalog_id?: string;
  vin?: string;
  vehicle_id?: string;
  manufacturer_id?: string;
  source?: string;
  vehicle_identifier?: string;
  model_id?: string[];
  plate_number?: string;
}

export default CategoriesHttp;
