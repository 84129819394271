import React, { useState } from 'react';
import { useOutletContext, useSearchParams } from 'react-router-dom';
import ConfigurationCard from './components/ConfigurationCard/ConfigurationCard';
import StepOne from './components/Steps/StepOne';
import StepThree from './components/Steps/StepThree';
import StepTwo from './components/Steps/StepTwo';
import './SuggestedMaintenance.scss';

interface IOutletContext{
  setHasMaintenanceWorks: (boolean)=> void
  setHasServicePlan: (boolean)=> void
}
const SuggestedMaintenance:React.FC = () => {
  const {setHasMaintenanceWorks, setHasServicePlan} = useOutletContext<IOutletContext>();
  const [searchParams, setSearchParams] = useSearchParams();
  const [step, setStep] = useState(searchParams.get('step') || '1');

  function handleEditStepOne(){
    setHasMaintenanceWorks(true);
    setHasServicePlan(true);
    searchParams.delete('step');
    setSearchParams(searchParams);
    setStep!('1');
  }

  function handleEditStepTwo(){
    setHasMaintenanceWorks(true);
    setHasServicePlan(true);
    searchParams.set('step', '12');
    setSearchParams(searchParams);
    setStep!('12');
  }

  return (
    <div className="suggested-maintenance">
      <div className="steps-container">
        {step.includes('1') &&
        <ConfigurationCard
          step="1"
          editable={searchParams.get('step')?.includes('2')}
          handleEdit={handleEditStepOne}
        >
          <StepOne setStep={setStep}/>
        </ConfigurationCard>
        }
        {step.includes('2') &&
        <ConfigurationCard
          step="2"
          editable={searchParams.get('step')?.includes('3')}
          handleEdit={handleEditStepTwo}
        >
          <StepTwo setStep={setStep}/>
        </ConfigurationCard>
        }
        {step.includes('3') &&
        <ConfigurationCard
          step="3"
          editable={false}
        >
          <StepThree setStep={setStep} setHasMaintenanceWorks={setHasMaintenanceWorks} setHasServicePlan={setHasServicePlan}/>
        </ConfigurationCard>
        }
      </div>
    </div>
  );
};

export default SuggestedMaintenance;