import React, { useEffect, useState } from 'react';

import './ComponentsModal.scss';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import IconItem from 'components/Item/IconItem/IconItem';
import { IDataModalGraphics, IGraphicsLinkedAdjustmentData, IGraphicsLinkedSpareParts, IGraphicsLinkedTechnicalManuals, IGraphicsLinkedWorkPositions } from 'utils/rmi/interface/graphics.interface';
import { useTranslation } from 'react-i18next';
import RepairItems from './RepairItems/RepairItems';
import TechnicalManuals from './TechnicalManuals/TechnicalManuals';
import Adjust from './Adjust/Adjust';
import SpareParts from './SpareParts/SpareParts';
import GenArt from './GenArt/GenArt';
import { useParams, useSearchParams } from 'react-router-dom';
import { IPartsCategory } from 'models/vehicle-catalogs';
import { getGenerat } from 'utils/utils';
import useHttp from 'utils/hooks/useHttp';


type Props = {
  setOpenModalGrafics: (e: boolean) => void;
  boxDetailOptionsGrafics: Array<IDataModalGraphics>;
  openModalGrafics: boolean;
};

const ComponentsModal: React.FC<Props> = (props) => {

  const [searchParams] = useSearchParams();
  const http = useHttp();
  const { t } = useTranslation();
  const { vehicle_id } = useParams();
  let componentMounted = true;
  const [linkedWorkPositions, setLinkedWorkPositions] = useState<IGraphicsLinkedWorkPositions[]>();
  const [linkedTechnicalManuals, setLinkedTechnicalManuals] = useState<IGraphicsLinkedTechnicalManuals[]>();
  const [linkedAdjustment, setLinkedAdjustment] = useState<IGraphicsLinkedAdjustmentData[]>();
  const [linkedSpareParts, setLinkedSpareParts] = useState<IGraphicsLinkedSpareParts[]>();
  const [categories, setCategories] = useState<IPartsCategory[]>([]);

  const [tabOnSelect, setTabOnSelect] = useState<number>(0);

  useEffect(() => {
    if (props.boxDetailOptionsGrafics.length && props.openModalGrafics && componentMounted) {

      props.boxDetailOptionsGrafics.forEach((value) => {
        if (value.linkedWorkPositions?.length) {

          setLinkedWorkPositions(value.linkedWorkPositions);
        }

        if (value.linkedTechnicalManuals?.length) {
          setLinkedTechnicalManuals(value.linkedTechnicalManuals);
        }

        if (value.linkedAdjustment?.length) {
          setLinkedAdjustment(value.linkedAdjustment);
        }

        if (value.linkedSpareParts?.length) {
          setLinkedSpareParts(value.linkedSpareParts);
        }

        if (value.linkedGenArt?.length) {

          const data = {
            vehicle_identifier: String(searchParams.get('vehicle_identifier')),
            source: String(searchParams.get('source')),
            genartnrs: Array.from(
              new Set(value.linkedGenArt.map((item) => getGenerat(item?.GenArtNo))),
            ),
          };

          http.graphicstHttp.fetchRMIGraphicsCategoryGenArt(Number(vehicle_id), data)
            .then(({ data }) => {
              setCategories(data);
            })
            .catch((e: Error) => console.error(e));

          return () => {
            componentMounted = false;
          };
        }


      });


    }

    return () => {
      componentMounted = false;
    };
  }, [props.boxDetailOptionsGrafics, props.openModalGrafics]);


  return (

    <div className="graphics-modal">
      <div className="modal-dialog">
        <div className="close">
          <IconItem icon={faClose} onClick={() => props.setOpenModalGrafics(false)} />
        </div>
        <div className="rmi-module-link-tabs">
          {props.boxDetailOptionsGrafics.length && props.openModalGrafics &&
            <Tabs onSelect={(index) => setTabOnSelect(index)}>
              <TabList >
                <Tab key="1" >{t('_repair_items')}</Tab>
                {linkedTechnicalManuals?.length ? <Tab key="2">{t('_technical_manuals')}</Tab> : null}
                {linkedAdjustment?.length ? <Tab key="3">{t('_adjust')}</Tab> : null}
                {linkedSpareParts?.length ? <Tab key="4">{t('_spare_parts')}</Tab> : null}
                <Tab key="5" disabled={categories.length ? false : true} >{t('_by_gen_art')}</Tab>
              </TabList>

              <TabPanel key={t('_repair_items')} forceRender className={(tabOnSelect !== 0) ? 'react-tabs__tab-panel none' : 'react-tabs__tab-panel'} >

                <RepairItems linkedWorkPositions={linkedWorkPositions} />
              </TabPanel>
              {linkedTechnicalManuals?.length ?
                <TabPanel key={t('_technical_manuals')} >
                  <TechnicalManuals linkedTechnicalManuals={linkedTechnicalManuals} setOpenModalGrafics={props.setOpenModalGrafics} />
                </TabPanel>
                : null}
              {linkedAdjustment?.length ?
                <TabPanel key={t('_adjust')}>
                  <Adjust linkedAdjustment={linkedAdjustment} setOpenModalGrafics={props.setOpenModalGrafics} />
                </TabPanel>
                : null}
              {linkedSpareParts?.length ?
                <TabPanel key={t('_spare_parts')}>
                  <SpareParts linkedSpareParts={linkedSpareParts} />
                </TabPanel>
                : null}
              <TabPanel key={t('_by_gen_art')}>
                <GenArt categories={categories} setOpenModalGrafics={props.setOpenModalGrafics} />
              </TabPanel>
            </Tabs>
          }

        </div>
      </div>
    </div>
  );
};

ComponentsModal.displayName = 'ComponentsModal';

export default ComponentsModal;