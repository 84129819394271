import { createSlice } from '@reduxjs/toolkit';
import { ICategorieGroup } from 'applications/automotor/components/espace/navbar/Navbar.model';
import { fetchCategoriesData } from 'store/reducers/catalog/categories.actions';

type CategoriesSlice = {
  categories: ICategorieGroup[];
  activeCategory: ICategorieGroup | null;
  isLoading: boolean;
  showCategoryDetailsSidebar: boolean;
  showCategorySidebar: boolean;
};

const initialState: CategoriesSlice = {
  categories: [],
  activeCategory: null,
  showCategoryDetailsSidebar: false,
  isLoading: false,
  showCategorySidebar: false,
};

const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    setCategories(state, { payload }) {
      state.categories = payload;
    },
    setActiveCategory(state, { payload }) {
      state.activeCategory = payload;
    },
    setIsLoading(state, { payload }) {
      state.isLoading = payload;
    },
    setShowCategorySidebar(state, { payload }) {
      state.showCategorySidebar = payload;
    },
    setShowCategoryDetailsSidebar(state, { payload }) {
      state.showCategoryDetailsSidebar = payload;
    },
    setIsCategoriesLoading(state, { payload }) {
      state.isLoading = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCategoriesData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchCategoriesData.fulfilled, (state, { payload }) => {
      state.categories = payload;
      state.isLoading = false;
    });
  },
});

export const { setCategories, setIsCategoriesLoading, setActiveCategory, setShowCategorySidebar, setShowCategoryDetailsSidebar } =
  categoriesSlice.actions;

export default categoriesSlice.reducer;
