import React from 'react';
import {useTranslation} from 'react-i18next';
import TabCard from 'components/TabCard/TabCard';
import './RapidAccess.scss';
import { IDataHistoryCars } from 'models/IHistory';
import FavCategoryTabCard from 'components/FavCategoryTabCard/FavCategoryTabCard';
import Spinner from 'components/spinner/Spinner';

type Props = {
  isLoading?: boolean;
  data?
  type?: string
};

const RapidAccess: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  const renderHistoryCardModelDescription = (card: IDataHistoryCars) => {
    if (card.car_variant?.vin) {
      return card.car_variant?.vin;
    }
  
    if (card.car_variant?.immat) {
      return card.car_variant.immat;
    }
  
    return '-';
  };


  return (
    <div className="rapid-access">
      {!props.isLoading ? 
        props.data?.length ?
          props.data?.map((card, index: number) => {
            return (
              <div className="rapid-access-item" key={index}>
                { props.type === 'history-vehicles' &&
              <TabCard
                type="vehicles"
                key={`${index}car`}
                img={card.car_variant?.image}
                card={card} 
                date={card.updated_at ?? ''}
                title={`${card.car_variant?.manufacturer_name ?? ''} ${card.car_variant?.model_name ?? ''} ${
                  card.car_variant?.capacity_qualifier ?? ''
                }`}
                description={`${card.car_variant?.capacity_qualifier ?? ''} ${card.car_variant?.model_name ?? ''} ${
                  card.car_variant?.engine_capacity ?? ''
                } ${card.car_variant?.power_hp_from ?? ''} ${card.car_variant?.power_hp_to ?? ''}`}
                model={t('_vin_plate')}
                modelDescription={renderHistoryCardModelDescription(card)}
              />
                }
                { props.type === 'history-parts' &&
               <TabCard
                 type="pieces"
                 card={card}
                 key={`${index}part`}
                 img={card.image}
                 date={card.updated_at}
                 title={card.name ?? ''}
                 description={`${card.category_name ?? ''}`}
                 model={t('_reference') + ': '}
                 modelDescription={card.reference ?? ''}
               />
                }
                {props.type === 'favourite-parts' &&
                 <FavCategoryTabCard
                   card={card}
                 />
                }
              </div>
            );
          })
          : <div className="no-data">{t('_no_data')}</div>
        : <Spinner class="small rapid-access-spinner"></Spinner>}
    </div>
  );
};


export default RapidAccess;
