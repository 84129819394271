import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ICatalogModel } from './catalog.model';
import { SidebarContext, ISidebarContext } from 'context/SidebarContext';

import Modal from 'components/modal/Modal';
import ImportCatalog from '../ImportCatalog/ImportCatalog';
import { contentStyle } from '../FilterSuppliers/contentStyle';
import parts from 'assets/Icons/parts.svg';
import { TabCategories } from '../../../../../../views/Sidebar/Sidebar.model';
import tyre from '../../../../../../assets/Icons/tyre.svg';
import oil from '../../../../../../assets/Icons/oil_new.svg';
import { FaSprayCan } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../store/store';
import { getCurrentMarketplace } from 'utils/marketplace';
import { useNavigate } from 'react-router-dom';
import battery from 'assets/Icons/battery_new.svg';
import ProductCategoryItemNew from 'components/Item/ProductCategoryItemNew/ProductCategoryItemNew';
import MessageModal from '../MessageModal/MessageModal';
import './Catalog.scss';

type Props = {
  openModal: boolean;
  setOpenModal: (e: boolean) => void;
  openMessageModal: boolean;
  setOpenMessageModal: (e: boolean) => void;
  openCatalogModal: boolean;
  setOpenCatalogModal: (e: boolean) => void;
  loading?: boolean;
};

const CatalogView: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { setSidebarTab } = useContext(SidebarContext) as ISidebarContext;
  const { user } = useSelector((state: RootState) => state.userData);
  const currentMarketplace = getCurrentMarketplace();
  const navigate = useNavigate();

  const catalogItems: ICatalogModel[] = [
    {
      text: '_parts',
      image: parts,
      link: '',
      tab: TabCategories.Pieces,
      disabled: true,
    },
    {
      text: '_tyre',
      image: tyre,
      link: '',
      tab: TabCategories.Pneus,
      disabled: !user?.hasTyre && currentMarketplace !== 'retailer',
    },
    {
      text: '_oil',
      image: oil,
      link: '',
      tab: TabCategories.Huiles,
      disabled: !user?.hasOil && currentMarketplace !== 'retailer',
    },
    {
      text: '_consumables',
      image: FaSprayCan,
      link: '',
      tab: TabCategories.Consumables,
      disabled: !user?.hasConsumable && currentMarketplace !== 'retailer',
    },
    {
      text: '_battery',
      image: battery,
      link: '',
      tab: TabCategories.Battery,
      disabled: !user?.hasBattery && currentMarketplace !== 'retailer',
    },
    {
      text: '_cnt_parts',
      image: parts,
      link: '',
      tab: TabCategories.Parts,
      disabled: !user?.has_nonTecDocCatalog && currentMarketplace !== 'retailer',
    },
  ];

  const filteredCatalogItems = catalogItems.filter((item) => item.disabled === false);

  return (
    <div className="clients-catalog">
      <div className="row">
        <p dangerouslySetInnerHTML={{ __html: t('_espace_clients_title') }} className="title" />
      </div>
      <div className="row">
        <div className="buttons">
          <div>
            <button className="header-btn" onClick={() => navigate('/espace-clients/stock-management/parts')}>
              {t('_stock_management')}
            </button>
          </div>
          <div>
            <button className="header-btn" onClick={() => navigate('/espace-clients/filter-suppliers')}>
              {t('_access_catalog')}
            </button>
          </div>
          <div>
            <button className="header-btn" onClick={() => props.setOpenModal(true)}>
              {t('_import_non_catalog_file')}
            </button>
          </div>
          {/* <div>
            <button className="header-btn" onClick={() => props.setOpenMessageModal(true)}>
              {t('_mecadepot_message')}
            </button>
          </div> */}
        </div>
      </div>
      <div className="subtitle">
        <p>{t('_espace_clients_subtitle')}</p>
      </div>
      {filteredCatalogItems.length > 0 && (
        <div className="catalog-items">
          {filteredCatalogItems.map((item: ICatalogModel, index: number) => {
            return <ProductCategoryItemNew text={t(item.text)} image={item.image} onClick={() => setSidebarTab(item.tab)} key={index} />;
          })}
        </div>
      )}
      <div className="catalog-import-container">
        <Modal
          openModal={props.openModal}
          setOpenModal={props.setOpenModal}
          shouldCloseOnOverlayClick={false}
          childrenComponent={<ImportCatalog setOpenModal={props.setOpenModal} />}
          contentStyle={contentStyle}
        />
        <Modal
          openModal={props.openMessageModal}
          setOpenModal={props.setOpenMessageModal}
          shouldCloseOnOverlayClick={false}
          childrenComponent={<MessageModal setOpenModal={props.setOpenMessageModal} />}
          contentStyle={contentStyle}
        />
      </div>
    </div>
  );
};

CatalogView.displayName = 'CatalogView';

export default CatalogView;
