import React, { useMemo } from 'react';
import './RetailerOrderTable.scss';
import { useTranslation } from 'react-i18next';
import { Column, useTable } from 'react-table';
import { IData } from '../../../../models/order';
import Head from '../../../../components/tables/Cells/Head/Head';
import IconNotification from '../../../../components/notification/IconNotification/IconNotification';
import { faComment } from '@fortawesome/free-solid-svg-icons';
import Spinner from '../../../../components/spinner/Spinner';
import Pagination from '../../../../components/pagination/Pagination';
import { useNavigate } from 'react-router-dom';
import i18n from 'i18n';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import CURRENCY from 'utils/constants/currency';


type Props = {
  data: IData[]
  loading: boolean
  handlePageClick?: (data) => Promise<void>
  totalPage?: number
  currentPage?: number
};


const RetailerOrderTable: React.FC<Props> = (props) => {
  const user = JSON.parse(localStorage.getItem('userData')!);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isMobile, isTablet } = useDeviceDetect();


  const columns = useMemo(
    (): Column<IData>[] => [
      {
        Header: <Head text={t('_date').toLocaleUpperCase()}></Head>,
        accessor: 'time',
      },
      {
        Header: <Head text={t('_order_number').toLocaleUpperCase()}></Head>,
        accessor: 'id',
      },
      {
        Header: t('_customer_id').toLocaleUpperCase(),
        Cell: (cell) => <span>{cell.row.original.customer_id ?? '-'}</span>,
        accessor: 'customer_id',
      },
      {
        Header: t('_proforma_id').toLocaleUpperCase(),
        Cell: (cell) => <span>{cell.row.original.proforma_id ?? '-'}</span>,
        accessor: 'proforma_id',
      },
      {
        Header: <Head text={t('_name').toLocaleUpperCase()}></Head>,
        accessor: 'client_name',
      },
      {
        Header: <Head text={t('_email').toLocaleUpperCase()}></Head>,
        accessor: 'user_email',
      },
      {
        Header: <Head text={t('_amount').toLocaleUpperCase()}></Head>,
        accessor: 'total_price',
        Cell: (cell) => <span>{CURRENCY[user!.currency]}{Number(cell.row.original.total_price).toFixed(2)}</span>,
      },
      {
        Header: t('_comment').toString().toLocaleUpperCase(),

        Cell: (cell) => <span><IconNotification icon={faComment} number={cell.row.original?.unseen_messages_count ? cell.row.original?.unseen_messages_count : 0} onClick={() => null} /></span>,
        accessor: 'unseen_messages_count',
      },
      {
        Header: t('_status').toLocaleUpperCase(),
        Cell: (cell) => <span>{t('_sent')}</span>,
      },
    ],
    [i18n.language],
  );

  const initialState = isMobile ? { hiddenColumns: ['customer_id', 'proforma_id', 'client_name', 'user_email', 'comment', 'unseen_messages_count'] } :
    isTablet ? { hiddenColumns: ['customer_id', 'comment', 'unseen_messages_count'] } : {};

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data: props.data,
    initialState
  });

  const handleOrderView = (orderId) => {
    navigate(`/orders/${orderId}`);
  };

  return (
    <div className="table-wrapper table-retailer-order">

      <table {...getTableProps()}>
        <thead className="headings">
          {headerGroups.map((headerGroup, index) => {
            return (
              <tr key={headerGroup.headers[index].id} className="row">
                {headerGroup.headers.map((column) => {
                  const { key, ...restColumn } = column.getHeaderProps();

                  return (
                    <th key={key} {...restColumn} className="heading">
                      {column.render('Header')}
                    </th>
                  );
                })}
              </tr>
            );
          })}
        </thead>

        {
          props.loading ?
            <tbody className="body">
              <tr className="no-data">
                <td colSpan={9}>
                  <Spinner class="extra-small" />
                </td>
              </tr>
            </tbody> :
            props.data.length ?
              <tbody {...getTableBodyProps} className="body">
                {rows.map((row) => {
                  prepareRow(row);
                  const { key, ...restRowProps } = row.getRowProps();

                  return (

                    <tr key={key} {...restRowProps} onClick={() => handleOrderView(row.original.id)} className="cell-row">
                      {row.cells.map((cell) => {
                        const { key, ...restCellProps } = cell.getCellProps();

                        return (
                          <td key={key} {...restCellProps} className="cell">
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
              :
              <tbody className="body">
                <tr className="no-data">
                  <td colSpan={9}>{t('_no_data')}</td>
                </tr>
              </tbody>
        }
      </table>
      <div>
        {props.totalPage! > 1 && (
          <Pagination pageCount={props.totalPage!} handlePageClick={props.handlePageClick!} forcePage={props.currentPage!} />
          // <Pagination pageCount={props.totalPage!} handlePageClick={props.handlePageClick!} forcePage={props.currentPage! - 1} />
        )}
      </div>
    </div>

  );
};

RetailerOrderTable.displayName = 'RetailerOrderTable';

export default RetailerOrderTable;
