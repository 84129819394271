import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'components/modal/Modal';
import React, { useState } from 'react';
import { IProvider } from 'utils/hooks/http/providers.http';
import ProviderModal from '../provider-modal/ProviderModal';
import './ProviderItem.scss';
import CURRENCY from 'utils/constants/currency';

type Props = {
    data: IProvider
    getAllProviders?: (data) => void
}

const ProviderItem: React.FC<Props> = (props: Props) => {
  const [openModal, setOpenModal] = useState(false);
  const user = JSON.parse(localStorage.getItem('userData')!);


  return (
    <div className="provider-item">
      <div className="provider-image">
        <img src={props.data.logo ? props.data.logo : '/images/car-part-placeholder-big.png'} onError={({ currentTarget }) => {
          currentTarget.onerror = null;
          currentTarget.src = '/images/car-part-placeholder-big.png';
        }} alt="provider-logo"
        />
      </div>
      <div className="provider-name">{props.data.name}</div>
      <div className="provider-franco">
        <span className="franco-text">FRANCO: {props.data.franco ? `${CURRENCY[user!.currency]}${props.data.franco}` : `${CURRENCY[user!.currency]}0.00`}</span>
        <FontAwesomeIcon className="edit-franco" icon={faEdit} onClick={() => setOpenModal(true)} />
      </div>
      <Modal
        openModal={openModal}
        setOpenModal={setOpenModal}
        childrenComponent={<ProviderModal getAllProviders={props.getAllProviders} data={props.data} setOpenModal={setOpenModal} />}
      />
    </div>
  );
};

export default ProviderItem;