import Spinner from 'components/spinner/Spinner';
import { IClientInfo, IOption, IUpdateForm } from 'interfaces/selectClient';
import React, { PropsWithChildren, useState } from 'react';
import TabContainer from './ContentTabs/TabContainer';
import './style.scss';

type Props = {
  setOpenModal: (e: boolean) => void;
  setTabIndex: (index: number) => void;
  tabIndex: number;
  loading: boolean;
  infoClient: IClientInfo;
  optionsSupplierMapping: IOption[];
  openModalClientId: number;
  save: () => void;
  reference: string
  setReference: (reference) => void
  teccomMappingData: IUpdateForm[]
  setTeccomMappingData: (teccomMappingData: IUpdateForm[]) => void
};

const ModalClientView: React.FC<Props> = (props: PropsWithChildren<Props>) => {
  const [disabledBtn, setEdisabledBtn] = useState<boolean>(false);

  return (
    <div className="modal-select-client">
      <div className="modal-dialog">
        <button onClick={() => props.setOpenModal(false)} className="close">
          {' '}
          &times;
        </button>
        <div className="modal-container">
          <div className="modal-heder">Teccom Mapping</div>
          <div className="modal-body">
            {
              props.loading ? <Spinner /> :
                <TabContainer
                  teccomMappingData={props.teccomMappingData}
                  setTeccomMappingData={props.setTeccomMappingData}
                  reference={props.reference}
                  setReference={props.setReference}
                  openModalClientId={props.openModalClientId}
                  optionsSupplierMapping={props.optionsSupplierMapping}
                  infoClient={props.infoClient}
                  tabIndex={props.tabIndex}
                  setTabIndex={props.setTabIndex}
                  setEdisabledBtn={setEdisabledBtn}
                />
            }
          </div>
          <div className="modal-footer">
            <button onClick={() => props.setOpenModal(false)} className="btn-close">
              Cancel
            </button>
            <button onClick={props.save} className={`btn-submit ${disabledBtn ? 'disabled' : ''}`} disabled={disabledBtn}>
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

ModalClientView.displayName = 'ModalClientView';

export default ModalClientView;
