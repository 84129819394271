import React, { useEffect, useRef } from 'react';
import { faClose, faRedo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import LinkButton from 'components/buttons/LinkButton/LinkButton';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { Trans, useTranslation } from 'react-i18next';
import { useOnClickOutside } from 'utils/hooks/useOnClickOutside';
import { useSearchParams } from 'react-router-dom';
import useReliableCatalogCarQueryParams from 'utils/hooks/useReliableCatalogCarQueryParams';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import './CatalogBatteryFilter.scss';

type Props = {
  setOpenFilter?: (boolean) => void;
  openFilter?: boolean;
  filterData?;
  getData?;
};

const CatalogBatteryFilter: React.FC<Props> = (props) => {
  const { control, getValues, reset, setValue } = useForm();
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const filterRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const reliableSearchParams = useReliableCatalogCarQueryParams();
  const { isMobile, isTablet } = useDeviceDetect();

  // useOnClickOutside(filterRef, () => {
  //   props.setOpenFilter!(false);
  // });


  const getOptions = (key) => {
    if (props.filterData && (key in props.filterData)) {
      return (
        props.filterData[key]?.map((item) => ({
          label: item.key,
          value: item.value,
        })) || []
      );
    }
  };


  function handleClick() {
    const params = {
      brand_ids: Array.isArray(getValues()?.brand) ? getValues()?.brand?.map(({ value }) => value) : (getValues()?.brand?.value && [getValues()?.brand?.value]),
      category_id: searchParams.get('category_id'),
      ah: Array.isArray(getValues()?.ah) ? getValues()?.ah?.map(({ value }) => value) : (getValues()?.ah?.value && [getValues()?.ah?.value]),
      amp: Array.isArray(getValues()?.amp) ? getValues()?.amp?.map(({ value }) => value) : (getValues()?.amp?.value && [getValues()?.amp?.value]),
      page: searchParams.get('page'),
      q: searchParams.get('q')
    };

    const validParams = Object.entries(params).reduce((acc, [key, value]) => {
      if (!value || !value.length) {
        return acc;
      }

      acc[key] = value;

      return acc;
    }, {} as { [key: string]: string | string[] });


    setSearchParams({...validParams, ...reliableSearchParams});
    props.setOpenFilter!(false);
  }

  function handleReset() {
    reset({ brand: '', ah: '', amp: '' });
    setSearchParams({ ...reliableSearchParams });
    props.setOpenFilter!(false);
  }

  useEffect(() => {
    const foundBrands = props.filterData && props.filterData?.brand?.filter(item => {
      return searchParams.getAll('brand_ids').includes(String(item.value));
    });
  
    if(foundBrands?.length){
      setValue('brand', foundBrands.map(item => {
        return {label: item.key, value: item.value};
      }));
    }

    const foundAh = props.filterData && props.filterData?.ah?.filter(item => {
      return searchParams.getAll('ah').includes(String(item.value));
    });

    if(foundAh?.length){
      setValue('ah', foundAh.map(item => {
        return {label: item.key, value: item.value};
      }));
    }

    const foundAmp = props.filterData && props.filterData?.amp?.filter(item => {
      return searchParams.getAll('amp').includes(String(item.value));
    });

    if(foundAmp?.length){
      setValue('amp', foundAmp.map(item => {
        return {label: item.key, value: item.value};
      }));
    }

  }, [props.filterData]);

  return (
    <div ref={filterRef} className={`battery-filters-catalog ${props.openFilter ? 'active' : ''}`}>
      <div className="header">
        <div className="buttons-container">
          <LinkButton title={t('_reset')} icon={faRedo} onClick={handleReset} />
        </div>
        <div onClick={() => props.setOpenFilter!(false)}>
          <FontAwesomeIcon className="icon" icon={faClose} />
        </div>
      </div>
      <div className="title">
        <Trans i18nKey="_filter_results" components={{ b: <strong /> }} />
      </div>
      <div className="filters">
        <div className="dropdowns-container">
          {/* <div className="dropdown-item">
            <label className="dropdown-label">{t('_battery_category')}</label>
            <Controller
              control={control}
              name="category"
              render={({ field: { onChange, value } }) => (
                <Select
                  value={value}
                  className="parts-filter-select-dropdown"
                  onChange={(val) => {
                    onChange(val);
                  }}
                  isDisabled={value?.is_amerigo}
                  options={getOptions('category')}
                  placeholder={t('_battery_category')}
                  isClearable={false}
                  menuPlacement="auto"
                  menuPosition="fixed"
                  styles={{ menuPortal: base => ({ ...base, zIndex: 150 }) }}
                  isSearchable={!isMobile && !isTablet}
                />
              )}
            />
          </div> */}
          <div className="dropdown-item">
            <label className="dropdown-label">{t('_battery_brand')}</label>
            <Controller
              control={control}
              name="brand"
              render={({ field: { onChange, value } }) => (
                <Select
                  value={value}
                  isMulti
                  className="parts-filter-select-dropdown"
                  onChange={(val) => {
                    onChange(val);
                  }}
                  options={getOptions('brand')}
                  placeholder={t('_battery_brand')}
                  menuPlacement="top"
                  isClearable={false}
                  menuPosition="fixed"
                  styles={{ menuPortal: base => ({ ...base, zIndex: 150 }) }}
                  isSearchable={!isMobile && !isTablet}

                />
              )}
            />
          </div>
          <div className="dropdown-item">
            <label className="dropdown-label">{t('_battery_ah')}</label>
            <Controller
              control={control}
              name="ah"
              render={({ field: { onChange, value } }) => (
                <Select
                  value={value}
                  isMulti
                  className="parts-filter-select-dropdown"
                  onChange={(val) => {
                    onChange(val);
                  }}
                  options={getOptions('ah')}
                  placeholder={t('_battery_ah')}
                  menuPlacement="top"
                  isClearable={false}
                  menuPosition="fixed"
                  styles={{ menuPortal: base => ({ ...base, zIndex: 150 }) }}
                  isSearchable={!isMobile && !isTablet}
                />
              )}
            />
          </div>
          <div className="dropdown-item">
            <label className="dropdown-label">{t('_battery_amp')}</label>
            <Controller
              control={control}
              name="amp"
              render={({ field: { onChange, value } }) => (
                <Select
                  value={value}
                  isMulti
                  className="parts-filter-select-dropdown"
                  onChange={(val) => {
                    onChange(val);
                  }}
                  options={getOptions('amp')}
                  placeholder={t('_battery_amp')}
                  menuPlacement="top"
                  isClearable={false}
                  menuPosition="fixed"
                  styles={{ menuPortal: base => ({ ...base, zIndex: 150 }) }}
                  isSearchable={!isMobile && !isTablet}
                />
              )}
            />
          </div>
        </div>
      </div>
      <div className="apply-btn">
        <DefaultButton text={t('_apply')} onClick={() => handleClick()} />
      </div>
    </div>
  );
};

export default CatalogBatteryFilter;
