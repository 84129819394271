import React, { useState } from 'react';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import { SwiperSlide } from 'swiper/react';
import SwiperSlider from 'components/SwiperSlider/SwiperSlider';
import { useTranslation } from 'react-i18next';
import { IPromotionDetails } from 'utils/hooks/http/promotions.http';
import { getMarketplaceBaseUrl } from 'utils/marketplace';
import moment from 'moment';
import { RootState } from 'store/store';
import { useSelector } from 'react-redux';
import Modal from 'components/modal/Modal';
import PromotionDescriptionModal from 'components/PromotionDescriptionModal/PromotionDescriptionModal';
import './Promotion.scss';

type Props = {
  isLoading?: boolean;
  items: IPromotionDetails[];
  slidesPerView?: number | 'auto';
  navigation?: boolean;
  pagination?: boolean;
  autoplay?: boolean;
  loop?: boolean;
  iconsArrowRight?
  iconsArrowLeft?
};

const PromotionView: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const [description, setDescription] = useState('');
  const { user } = useSelector((state: RootState) => state.userData);

  const handleItemClick = (item: IPromotionDetails) => {
    const element = document.createElement('a');

    element.href = item.pdf_path ? getMarketplaceBaseUrl() + item.pdf_path : '';
    element.target = '_blank';
    element.click();

  };

  const slidesPerViewForceAuto = 'auto';

  function readMore(item) {
    setOpenModal(true);
    setDescription(item.description);
  }

  return (
    <React.Fragment>
      <SwiperSlider
        isLoading={props.isLoading}
        navigation={props.navigation}
        pagination={props.pagination}
        autoplay={props.autoplay}
        loop={props.loop}
        iconsArrowRight={props.iconsArrowRight}
        iconsArrowLeft={props.iconsArrowLeft}
        breakpoints={{
          990: {
            slidesPerView: slidesPerViewForceAuto,
          },
          1440: {
            slidesPerView: props.slidesPerView ?? 2,
          }
        }}
      >
        {
          props.items.map((item: IPromotionDetails, index: number) => {
            return (
              <SwiperSlide className="swiper-slide" key={index}>
                <div className="promotion-container default-promotion">
                  <div className="promotion-image">
                    <img src={item.images_path.desktop ? getMarketplaceBaseUrl() + item.images_path.desktop : '/images/car-part-placeholder.png'} alt="part-img"
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = '/images/car-part-placeholder.png';
                      }}
                    />
                  </div>
                  <div className="promotion-info">
                    <div className="information">
                      <div className="description">{item.title}</div>
                      <div className="availability">{`${t('_availability_promo')}  ${moment(item.start_date).format('DD/MM/YYYY')}  ${t('_to')}  ${moment(item.end_date).format('DD/MM/YYYY')}`}</div>
                      {/* {user?.numcli === Number(process.env.REACT_APP_MECADEPOT_NUMCLI) ?
                        <div className="availability">{item.description}</div> :
                        <div className="availability">{`${t('_availability_promo')}  ${moment(item.start_date).format('DD/MM/YYYY')}  ${t('_to')}  ${moment(item.end_date).format('DD/MM/YYYY')}`}</div>
                      } */}
                      {/* <div className="read-more" onClick={() => readMore(item)}>{t('_read_more')}</div> */}
                    </div>
                  </div>
                  <div className="promotion-submit">
                    {/* <div className="title">{t('_promotions')}</div> */}
                    {/* <div className="discount">{item.discount}</div> */}
                    <DefaultButton text={t('_discover')} onClick={() => handleItemClick(item)} key={index} disabled={item.pdf_path == null ? true : false} />
                  </div>
                </div>
              </SwiperSlide>
            );
          })
        }
      </SwiperSlider>
      <Modal
        openModal={openModal}
        setOpenModal={setOpenModal}
        childrenComponent={<PromotionDescriptionModal setOpenModal={setOpenModal} data={description} />}
      />
    </React.Fragment>
  );
};

PromotionView.displayName = 'PromotionView';

export default PromotionView;
