import CatalogSupplierNavbar from 'components/navbar/catalog-supplier-navbar/CatalogSupplierNavbar';
import { t } from 'i18next';
import OrderView from 'pages/OrderView/OrderView';
import React from 'react';
import './CatalogOrdersView.scss';

type Props = {}

const CatalogOrdersView: React.FC<Props> = () => {

  return (
    <div className="catalog-orders-view">
      <CatalogSupplierNavbar title={`${t('_orders')} /`} />
      <OrderView />
    </div>
  );
};

export default CatalogOrdersView;