import { useEffect } from 'react';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { ROLE_CLIENT_AUTOMOTOR_GROUP_MANAGER } from 'utils/constants/roles';

const useForceClientAutomotorManagerGroupSelectClient = (): void => {
  const location = useLocation();
  const navigate = useNavigate();
  const userData = useSelector((state: RootState) => state.userData.user);
  const roles = userData?.roles;

  useEffect(() => {
    if (roles && roles.includes(ROLE_CLIENT_AUTOMOTOR_GROUP_MANAGER) && !localStorage.getItem('clientId')) {

      const searchParams = createSearchParams([
        ['page', '1'],
      ]);

      navigate({
        pathname: '/manager-group-select-client',
        search: `?${searchParams}`,
      });
    }
  }, [location.pathname, userData]);
};

export default useForceClientAutomotorManagerGroupSelectClient;
