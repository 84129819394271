import React from 'react';
import './CustomSwitch.scss';

type Props = {
    name: string
    value?: boolean
    onChange: (value) => void
}

const CustomSwitch: React.FC<Props> = (props) => {
  return (
    <div className="toggle-switch small-switch">
      <input
        type="checkbox"
        className="toggle-switch-checkbox"
        name={props.name}
        id={props.name}
        checked={props.value}
        onChange={(e) => props.onChange(e.target.checked)}
      />
      <label className="toggle-switch-label" htmlFor={props.name}>
        <span className="toggle-switch-inner" />
        <span className="toggle-switch-switch" />
      </label>
    </div>
  );
};

export default CustomSwitch;