import React from 'react';

import './CustomValidationMessages.scss';

type Props = {
    customValidationMessages: string[]


}
const CustomValidationMessages: React.FC<Props> = (props) => {
  return (

    <div className="CustomValidationMessages custom-validation-messages">
      {
        props.customValidationMessages.length > 0 &&
            (
              <div>
                {
                  props.customValidationMessages.map(
                    (message, index) => <div className="error-message" key={index}>{message}</div>
                  )
                }
              </div>
            )
      }
    </div>
  );
};

export default CustomValidationMessages;