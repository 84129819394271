import SkeletonSpinner from 'components/skeleton-spinner/SkeletonSpinner';
import { IData } from 'models/order';
import React, { useState , useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import useHttp from 'utils/hooks/useHttp';
import './OrderInfo.scss';

type Props = {
  setClientName: (clientName: string) => void;
  setFullUSerName: (fullUserName: string) => void;
  setClientId: (clientId: number) => void;
}

const OrderInfo: React.FC<Props> = (props) => {
  const http = useHttp();
  const {order_id} = useParams();
  const [data, setData] = useState<IData>();
  const [loading, setLoading] = useState(false);
  const {t} = useTranslation();

  function getOrderInfo(){
    setLoading(true);
    http.orderClients.getOrderById(order_id!).then(res => setData(res.data)).finally(()=>{
      setLoading(false);
    });
  }

  useEffect(()=>{
    getOrderInfo();
  },[]);

  useEffect(() => {
    if(data?.client_name) {
      props.setClientName(data?.client_name);
    }

    if(data?.user_full_name) {
      props.setFullUSerName(data?.user_full_name);
    }

    if(data?.client_id) {
      props.setClientId(data?.client_id);
    }
  }, [data]);

  return (
    <div className="order-client-info">
      {loading ? <SkeletonSpinner /> : (
        <React.Fragment>
          <div className="order-info-item">
            <div className="order-label">{t('_date').toLocaleUpperCase()}:</div>
            <div className="order-value">{data?.time}</div>
          </div>
          <div className="order-info-item">
            <div className="order-label">{t('_client').toLocaleUpperCase()}:</div>
            <div className="order-value">{data?.client_name}</div>
          </div>
          <div className="order-info-item">
            <div className="order-label">{t('_order_number').toLocaleUpperCase()}:</div>
            <div className="order-value">#{data?.id}</div>
          </div>

          <div className="order-info-item">
            <div className="order-label">{t('_order_ref').toLocaleUpperCase()}</div>
            <div className="order-value">{data?.customer_id ? data?.customer_id : '#'}</div>
          </div>
          <div className="order-info-item">
            <div className="order-label">{t('_status').toLocaleUpperCase()}:</div>
            <div className="order-value">{data?.status}</div>
          </div>
      
        
          <div className="order-info-item">
            <div className="order-label">{t('_email').toLocaleUpperCase()}:</div>
            <div className="order-value">{data?.user_email}</div>
          </div>
          <div className="order-info-item">
            <div className="order-label">{t('_teccom_id').toLocaleUpperCase()}:</div>
            <div className="order-value">{data?.proforma_id ? data?.proforma_id : '#'}</div>
          </div>
         
          <div className="order-info-item">
            <div className="order-label">{t('_amount').toLocaleUpperCase()}:</div>
            <div className="order-value">{data?.total_price.toFixed(2)}</div>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default OrderInfo;