import React from 'react';
import { Link } from 'react-router-dom';
import { INews } from 'utils/hooks/http/news.http';
import { getBackendAssetsBasePath } from 'utils/marketplace';
import './SideNews.scss';

type Props = {
  data: INews
};

const SideNewsView: React.FC<Props> = (props) => {

  return (
    <div className="side-news-item">
      <img src={`${getBackendAssetsBasePath()}/${props.data?.image}`} />
      <div className="side-news-info">
        <div className="side-news-date">{props.data?.created_at.substring(0, props.data?.created_at.indexOf(' '))}</div>
        <Link className="side-news-title" to={`/news/${props.data?.id}`}>{props.data?.title}</Link>
      </div>
    </div>
  );
};

SideNewsView.displayName = 'SideNewsView';

export default SideNewsView;
