import React, { ChangeEventHandler, KeyboardEventHandler } from 'react';
import TextareaInputView from './TextareaInput.view';

type Props = {
  placeholder?: string;
  onKeyPress?: KeyboardEventHandler<HTMLTextAreaElement>;
  onBlur?: ChangeEventHandler<HTMLTextAreaElement>;
  name?: string;
  value?: string;
  withIcon?: boolean;
  onClick?: (e) => void;
  noResize?: boolean;
  rows?: number;
  onChange?;
};

const TextareaInput: React.FC<Props> = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  return <TextareaInputView ref={ref} {...props} ></TextareaInputView>;
});

TextareaInput.displayName = 'TextareaInput';

export default TextareaInput;
