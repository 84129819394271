import React from 'react';
import { CellProps } from 'react-table';
import { CartTableItem } from '../../SupplierCartTable.model';
import PriceCellView from './PriceCell.view';

type Props = CellProps<CartTableItem>;

const PriceCell: React.FC<Props> = (props: Props) => {
  return <PriceCellView value={props.value}></PriceCellView>;
};

PriceCell.displayName = 'PriceCell';

export default PriceCell;
