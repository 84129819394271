import React from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { GearBoxFilter } from 'utils/rmi/rmi-utils/rmi-utils';

type Props = {
  gearboxCodes?: number[]
  control?
}

const GearBoxSelect: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  function gearboxOptions() {
    return props.gearboxCodes?.map(gearbox => {
      return {
        value: gearbox,
        label: t(GearBoxFilter[gearbox])
      };
    });
  }

  return (
    <div className="maintenance-dropdown">
      <Controller
        control={props.control}
        name="kindOfGearBox"
        render={({ field: { onChange, value } }) => (
          <Select
            value={value}
            className="configuration-dropdown"
            onChange={(val) => {
              onChange(val);
            }}
            options={gearboxOptions()}
            placeholder={t('_transmission')}
            isClearable
            menuPosition="fixed"
            styles={{ menuPortal: base => ({ ...base, zIndex: 50 }) }}
          />
        )}
      />
    </div>
  );
};

export default GearBoxSelect;