import { createAsyncThunk } from '@reduxjs/toolkit';
import useHttp from 'utils/hooks/useHttp';

export const fetchAutomotorTariffInfo = createAsyncThunk('automotorTariff/fetchAutomotorTariffInfo', async () => {
  try {
    const http = useHttp();

    const {data} = await http.automotorTariff.getUsers();

    return data;
  } catch (e) {
    return 'fetch seller info error';
  }
});

export const fetchAutomotorTariffs = createAsyncThunk('automotorTariff/fetchAutomotorTariffs', async () => {
  try {
    const http = useHttp();

    const data = await http.automotorTariff.getTariffs();

    return data;
  } catch (e) {
    return 'fetch tariff info error';
  }
});