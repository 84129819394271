import React from 'react';
import FavoriteItemView from './FavoriteItem.view';
import {ICarPartData} from 'models/car-parts.model';

type Props = {
  car: ICarPartData;
  checkedList:ICarPartData[] | [];
  setCheckedList: (checkedList: ICarPartData[]) => void;

}

const FavoriteItem: React.FC<Props> = (props) => {

  return (
    <FavoriteItemView checkedList={props.checkedList} setCheckedList={props.setCheckedList} car={props.car}/>
  );

};

FavoriteItem.displayName = 'FavoriteItem';

export default FavoriteItem;