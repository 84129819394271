import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import Pagination from 'components/pagination/Pagination';
import Spinner from 'components/spinner/Spinner';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { IPaginationData } from 'utils/hooks/http/providers.http';
import useHttp from 'utils/hooks/useHttp';
import ProviderItem from './provider-item/ProviderItem';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import ProviderFilter from './provider-filter/ProviderFilter';
import './Providers.scss';


const Providers: React.FC = () => {
  const http = useHttp();
  const {t} = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const [loading, setLoading] = useState(true);
  const [providers, setProviders] = useState<IPaginationData>();
  const [filterData, setFilterData] = useState<IPaginationData>();

  const [openFilter, setOpenFilter] = useState(false);
  const containerRef = useRef(null) as MutableRefObject<HTMLDivElement | null>;

  function getAllProviders(data) {
    setLoading(true);
    containerRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    const params = {
      ...data,
      page: searchParams.get('page')
    };

    http.providers.getProviders(params)
      .then(res => {
        setProviders(res);
      })
      .finally(() => setLoading(false));
  }

  function handlePageClick(pageN: number) {
    setSearchParams({...searchParams, page: String(pageN)});
  }

  useEffect(() => {
    getAllProviders({
      page: searchParams.get('page'),
      code_fou: searchParams.get('code_fou')
    });
  }, [searchParams.get('page'), searchParams.get('code_fou')]);

  useEffect(() => {
    http.providers.getProviders({per_page: 1000}).then(setFilterData);
  },[]);

  return (
    <div ref={containerRef} className="providers">
      <div className="providers-filter">
        <div className="filter-btn">
          <DefaultButton onClick={() => setOpenFilter(true)} text={t('_filter_results_btn')} className={`providers-filter-button ${openFilter ? 'active' : ''}`} />
        </div>
      </div>
      <div className="providers-container">
        {loading ? <Spinner class={`small ${providers?.data.length && providers?.data.length > 4 ? 'provider-spinner' : ''}`}/> : null}
        <div className={`provider-items ${!providers?.data.length ? 'no-items' : ''} ${loading ? 'is-loading' : ''}`}>
          {providers?.data.length ? providers.data.map((provider, index) => (
            <ProviderItem key={index} data={provider} getAllProviders={getAllProviders}/>
          )) : 
            !loading ? <div className="provider-no-data">{t('_no_data')}</div> : null}
        </div>
      </div>
      {(providers && providers?.total_pages && providers?.page && providers?.total_pages > 1) ? (
        <Pagination pageCount={providers.total_pages} handlePageClick={handlePageClick} forcePage={providers.page} />
      ) : (
        null
      )}
      <ProviderFilter getData={getAllProviders} filterData={filterData?.data} openFilter={openFilter} setOpenFilter={setOpenFilter} />
    </div>
  );
};

export default Providers;