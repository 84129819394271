import React from 'react';
import './ActiaPage.scss';
import { useTranslation } from 'react-i18next';
import ActiaTable from '../../../../components/tables/ActiaTable/ActiaTable';
import { IActiaPagination, IActiaTableData, ISessionModal } from '../../../../components/DiagnosticItems/Actia/actia';
import Spinner from '../../../../components/spinner/Spinner';
import Pagination from '../../../../components/pagination/Pagination';
import DefaultButton from '../../../../components/buttons/DefaultButton/DefaultButton';
import Modal from '../../../../components/modal/Modal';
import FavoriteListModal
  from '../../../../components/PartItem/PartItemOptions/FavoriteButton/FavoriteListModal/FavoriteListModal';
import { contentStyle } from '../../../../components/modal/ContentStyleModal';
import SessionModal from './SessionModal/SessionModal';
import MobileTableExample from '../../../../components/tables/MobileTableExample/MobileTableExample';

type Props = {
  data: IActiaTableData[];
  actiaPagination: IActiaPagination;
  loading: boolean;
  handlePageClick: (page: number) => void;
  setOpenModal: (boolean) => void;
  openModal: boolean;
  modalValues: ISessionModal;
  setModalValues: (values: ISessionModal) => void;
  handleOpenModal: () => void;
  getActiaListItems: () => void;
}

const ActiaPageView: React.FC<Props> = (props) => {
  const { t } = useTranslation();


  return (
    <div className="actia-page">
      <div className="create-session">
        <DefaultButton onClick={props.handleOpenModal} text={t('_create_session')} />
      </div>
      <div className="table-wrapper">
        {/*<MobileTableExample />*/}
        <ActiaTable getActiaListItems={props.getActiaListItems} setModalValues={props.setModalValues} setOpenModal={props.setOpenModal} data={props.data} loading={props.loading}/>
      </div>
      <div className="pagination-wrapper">
        {
          props.actiaPagination.total_count > 0 && (
            <Pagination handlePageClick={props.handlePageClick} pageCount={props.actiaPagination?.total_pages} forcePage={props.actiaPagination?.page} />
          )
        }
      </div>

      <Modal
        openModal={props.openModal}
        setOpenModal={props.setOpenModal}
        childrenComponent={<SessionModal onDone={props.getActiaListItems} values={props.modalValues} setOpenModal={props.setOpenModal} />}
        contentStyle={contentStyle}
      />


    </div >
  );
};

ActiaPageView.displayName = 'DiagnosticPageView';

export default ActiaPageView;
