import React from 'react';
import { useTranslation } from 'react-i18next';
import './ModalReferenceEquivalences.scss';
import { IData } from 'utils/hooks/http/search-by-reference.http';
import { createSearchParams, generatePath, NavLink } from 'react-router-dom';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';

type Props = {

  referenceSearch
  refDataAutocomplete: IData[];
  setShowModal: (boolean) => void;
};

const ModalReferenceEquivalences: React.FC<Props> = (props) => {

  const { t } = useTranslation();

  function generatePathLink(item) {
    const param = {
      ...{ page: 1 },
      ...(item.source && { source: item.source }),
      ...(item.subcategory_id && { subcategory_id: item.subcategory_id }),
      ...(item.man_sup_id && { man_sup_id: item.man_sup_id }),
      ...(item.reference && { q: item.reference }),
      ...(item.type && { type: item.type }),
      ...{ codfou: props.referenceSearch.ind_equivalence && props.referenceSearch.ind_equivalence_select ? props.referenceSearch.ind_equivalence.codfou : props.referenceSearch.codfou },
    };


    return {
      pathname: generatePath('/car-parts'),
      search: `?${createSearchParams(param)}`,
    };

  }

  return (
    <div className="modal-reference-equivalences">
      <div className="ref-header">
        {t('_search_result')}
        <div className="ref-info">

          {

            props.referenceSearch.ind_equivalence && props.referenceSearch.ind_equivalence_select ?
              props.referenceSearch.ind_equivalence.name + ' ' + props.referenceSearch.ind_equivalence.codfou + props.referenceSearch.ind_equivalence.reference
              : props.referenceSearch.name + ' ' + props.referenceSearch.reference
          }

        </div>
        {props.refDataAutocomplete.length ? <span>{t('_select_category')}</span> : null}
      </div>
      <div className="ref-body">
        <div className="ref-content">
          <ul>
            {props.refDataAutocomplete.length ?

              props.refDataAutocomplete.map((item, i) => {

                return (

                  <li key={i}>
                    <NavLink to={generatePathLink(item)} key={i} className="link-ref" target="_blank"> {item.category_name} </NavLink>
                  </li>


                );
              })

              : <div className="ref-content-no-data">{t('_equivalence_not_fond')}</div>
            }
          </ul>
        </ div >
        < DefaultButton className="close" onClick={() => props.setShowModal(false)} text={t('_close')} />

      </div >
    </div >
  );
};

ModalReferenceEquivalences.displayName = 'ModalReferenceEquivalences';

export default ModalReferenceEquivalences;
