import { IAutomotorOrderItem, IData, IDataResponse, IDataResponseHistory, IOrderItemsParams, IParams, IStatus } from 'models/order';
import { ICartResponse, IOrderItem } from 'models/order.model';
import { addRouteParam, IDataPagination, IHttpResponse, serverAxios } from '../../http';
import { IAddToCartParams } from '../../../models/car-parts.model';
import { IOption } from 'interfaces/select';
import { IProformaResponse } from './proforma.http';

class OrderHttp {
  private static route = '/orders';
  private static clients = '/orders/clients';
  private static action = '/orders/:id/action';
  private static export = '/orders/:id/export';
  private static routeAol = '/aol/add-to-cart';
  private static order = '/orders/:id';
  private static orderItems = '/orders/:id/items';
  private static orderStatus = '/orders/status';
  private static automotorOrderItems = 'automotor/orders/:id/items';
  private static orderHistoryStatus = '/statuses/list';
  private static statusList = '/statuses/short';
  public async getOrdersStatus(): Promise<IHttpResponse<IStatus>> {
    return serverAxios.get(OrderHttp.orderStatus);
  }
  public async getOrders(params: IParams): Promise<IHttpResponse<IDataResponse>> {
    return serverAxios.get(OrderHttp.route, { params });
  }
  public async getClients(params?: { status?: string }): Promise<IHttpResponse<IOption[]>> {
    return serverAxios.get(OrderHttp.clients, { params });
  }

  public async getStatus(): Promise<IHttpResponse<IOption[]>> {
    return serverAxios.get(OrderHttp.statusList);
  }
  public async addToCart(params: IAddToCartParams[]): Promise<IHttpResponse<[]>> {
    return serverAxios.post(OrderHttp.route, params);
  }
  public async orderAction(id: string, params: IOrderActionParams): Promise<IHttpResponse<IProformaResponse>> {
    return serverAxios.get(addRouteParam(OrderHttp.action, { id }), { params });
  }
  public async orderExport(id: string): Promise<IHttpResponse<Blob>> {
    return serverAxios.get(addRouteParam(OrderHttp.export, { id }), { responseType: 'blob' });
  }
  public async addToCartAol(params: IAddToCartParams[]): Promise<IHttpResponse<[]>> {
    return serverAxios.post(OrderHttp.routeAol, params);
  }
  public async getOrderById(id: string): Promise<IHttpResponse<IData>> {
    return serverAxios.get(addRouteParam(OrderHttp.order, { id }));
  }
  public async getOrderItems(id: string): Promise<IHttpResponse<IHttpResponse<IOrderItem[]>>> {
    return serverAxios.get(addRouteParam(OrderHttp.orderItems, { id }));
  }
  public async update(id: number, data: { quantity: number }): Promise<IHttpResponse<ICartResponse>> {
    return serverAxios.patch(`${OrderHttp.route}/${id}`, data);
  }
  public async delete(id: number, params?: { is_work_time: boolean }): Promise<IHttpResponse<ICartResponse>> {
    if (localStorage.getItem('external_cart_id')) {
      return serverAxios.delete(`${OrderHttp.route}/${id}`, {
        params: { ...params, external_cart_id: localStorage.getItem('external_cart_id') },
      });
    } else {
      return serverAxios.delete(`${OrderHttp.route}/${id}`, { params });
    }
  }
  public async getAutomotorOrderItems(id: string, params: IOrderItemsParams): Promise<IHttpResponse<IDataPagination<IAutomotorOrderItem>>> {
    return serverAxios.get(addRouteParam(OrderHttp.automotorOrderItems, { id }), { params });
  }

  public async getClientOrderHistoryStatus(params: IParams): Promise<IHttpResponse<IDataResponseHistory>> {
    return serverAxios.get(OrderHttp.orderHistoryStatus, { params });
  }
}

interface IOrderActionParams {
  action?: string;
}

export default OrderHttp;
