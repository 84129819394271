import React from 'react';
import { CellProps } from 'react-table';
import { CartTableItem } from '../../CartTable.model';

import CategoriesCellView from './CategoriesCell.view';

type Props = CellProps<CartTableItem> & {};

const CategoriesCell: React.FC<Props> = (props: Props) => {

  return <CategoriesCellView {...props} value={props.value}></CategoriesCellView>;
};

CategoriesCell.displayName = 'CategoriesCell';

export default CategoriesCell;
