import React from 'react';
import CatalogSearchCategoriesView from './CatalogSearchCategories.view';


const CatalogSearchCategories: React.FC = () => {

  return (
    <CatalogSearchCategoriesView />
  );
};

CatalogSearchCategories.displayName = 'CatalogSearchCategories';

export default CatalogSearchCategories;
