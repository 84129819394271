import React, { useRef, useState } from 'react';
import DefaultIcon from 'components/buttons/DefaultIcon/DefaultIcon';

import {
  faUpload,
  faList,
  faBoxOpen,
  faFileContract,
  faComment,
  faEuroSign,
  faGear, faXmark, faPercent
} from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setShowCategorySidebar } from 'store/slices/categories.slice';

import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { setShowEbookSidebar } from 'store/slices/ebooks.slice';
import { RootState } from '../../../../store/store';
import { getCurrentCatalog } from 'store/selectors/catalogs.selector';
import { setProformaActive } from '../../../../store/slices/proforma.slice';

import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import { useOnClickOutside } from 'utils/hooks/useOnClickOutside';
import './CatalogNavbarGroupBtn.scss';
import { usePermission } from 'utils/usePermission';
import { ROLE_SUPPLIER_AMERIGO } from 'utils/constants/roles';

type Props = {};

const CatalogNavbarGroupBtn: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: catalogId } = useParams();
  const permission = usePermission([ROLE_SUPPLIER_AMERIGO]);
  const currentCatalog = useSelector(getCurrentCatalog);
  const { isMobile } = useDeviceDetect();
  const [buttonsAreaExpanded, setButtonsAreaExpanded] = useState<boolean>(false);

  const clickRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const { newMessagesNumber } = useSelector((state: RootState) => state.messenger);
  const totalNotificationsNumber = newMessagesNumber + 0;

  useOnClickOutside(clickRef, () => {
    setButtonsAreaExpanded(false);
  });


  const changeAreaExpanded = () => {
    setButtonsAreaExpanded((prevState) => !prevState);
  };

  const goToCatalogMessages = () => {
    if (catalogId) {
      navigate(`catalog/${catalogId}/messages`);
    }
  };

  const goToCatalogOrders = () => {
    if (catalogId) {
      navigate(`catalog/${catalogId}/orders`);
    }
  };

  const { t } = useTranslation();

  const handleCatalogKaweClick = () => {
    dispatch(setShowCategorySidebar(true));
  };

  const handleEbooksClick = () => {
    dispatch(setShowEbookSidebar(true));
  };

  const handleProformaUpload = () => {
    dispatch(setProformaActive(true));
  };


  return (
    isMobile ? (
      <div className="floatable-buttons-wrapper" ref={clickRef}>
        <DefaultIcon onClick={changeAreaExpanded} number={buttonsAreaExpanded ? 0 : totalNotificationsNumber} icon={buttonsAreaExpanded ? faXmark : faGear} />
        <div className={`buttons ${buttonsAreaExpanded ? 'show' : 'hidden'}`}>
          <DefaultIcon onClick={handleProformaUpload} icon={faUpload} />
          <DefaultIcon onClick={handleCatalogKaweClick} icon={faList} />
          <DefaultIcon onClick={goToCatalogOrders} icon={faBoxOpen} />
          <DefaultIcon onClick={goToCatalogMessages} number={newMessagesNumber} icon={faComment} />
          <DefaultIcon onClick={handleEbooksClick} icon={faFileContract} />
          <DefaultIcon onClick={() => navigate(generatePath('/catalog/:id/tariffs', { id: String(catalogId) }))} icon={faEuroSign} />
          {permission ? <DefaultIcon onClick={() => navigate(generatePath('/catalog/:id/promotions', { id: String(catalogId) }))} icon={faPercent} /> : null}
        </div>
      </div>
    )
      : (
        <React.Fragment>
          <DefaultIcon onClick={handleProformaUpload} icon={faUpload} text={t('_import_csv')} />

          <DefaultIcon onClick={handleCatalogKaweClick} icon={faList} text={`${t('_catalog')} ${currentCatalog?.name}`} />
          <DefaultIcon onClick={goToCatalogOrders} icon={faBoxOpen} text={t('_orders')} />
          <DefaultIcon onClick={goToCatalogMessages} number={newMessagesNumber} icon={faComment} text={t('_messages')} />
          <DefaultIcon onClick={handleEbooksClick} icon={faFileContract} text={t('_catalog_ebooks')} />
          <DefaultIcon
            onClick={() => navigate(generatePath('/catalog/:id/tariffs', { id: String(catalogId) }))}
            icon={faEuroSign}
            text={t('_tariffs')}
          />
          {permission ? <DefaultIcon onClick={() => navigate(generatePath('/catalog/:id/promotions', { id: String(catalogId) }))} icon={faPercent} text={t('_promotions')} /> : null}
        </React.Fragment>

      )
  );
};

CatalogNavbarGroupBtn.displayName = 'EspaceReliableNavbarGroupBtn';

export default CatalogNavbarGroupBtn;
