import {useSelector} from 'react-redux';
import {RootState} from '../../store/store';
import {
  ROLE_CLIENT_AMERIGO,
  ROLE_CLIENT_AUTOMOTOR,
  ROLE_CLIENT_PLATFORM,
  ROLE_COMMERCIAL_DIRECTOR,
  ROLE_SELLER,
  ROLE_SUPPLIER_AMERIGO,
} from '../constants/roles';
import {getCurrentMarketplace} from '../marketplace';
import {Marketplaces} from '../constants/applications';
import {useTranslation} from 'react-i18next';


const useMessageText = (): string => {
  const {user} = useSelector((state: RootState) => state.userData);
  const {t} = useTranslation();

  const messageFromAutomotor = () => {
    switch (true) {
      case  user?.roles.includes(ROLE_SELLER) || user?.roles.includes(ROLE_COMMERCIAL_DIRECTOR):
        return  t('_messages_clients');
      case  user?.roles.includes(ROLE_CLIENT_AUTOMOTOR):
        return t('_messages_sellers');
      default:
        return t('_messages');
    }
  };

  const messageFromAmerigo = () => {
    switch (true) {
      case  user?.roles.includes(ROLE_SUPPLIER_AMERIGO):
        return  t('_messages_clients');
      case  user?.roles.includes(ROLE_CLIENT_AMERIGO):
        return t('_supplier_messages');
      default:
        return t('_messages');
    }
  };

  const messageFromRetailer = () => {
    switch (true) {
      case  user?.roles.includes(ROLE_CLIENT_PLATFORM):
        return  t('_my_supplier');
      default:
        return t('_messages');
    }
  };


  const messageText = () => {
    switch (getCurrentMarketplace()) {
      case Marketplaces.AUTOMOTOR:
        return messageFromAutomotor();
      case Marketplaces.AMERIGO:
        return messageFromAmerigo();
      case Marketplaces.RETAILER:

        return messageFromRetailer();
      default:
        return t('_messages');
    }
  };

  return messageText();
};

export default useMessageText;