import React from 'react';
import OrdersView from './Orders.view';

const Orders: React.FC = () => {
  return <OrdersView />;
};

Orders.displayName = 'Orders';

export default Orders;
