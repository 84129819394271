import { IDataPagination } from 'utils/http';
import { serverAxios } from '../../http';

class IndustrialHttp {
  private static carPartsRoute = '/automotor-supplier/carparts';
  private static equivalancesRoute = '/automotor-supplier/equivalences';

  public async industrialCarParts(params?: IIndustrialParams): Promise<IDataPagination<IIndustrialCarPartsResponse>> {
    const { data } = await serverAxios.get(IndustrialHttp.carPartsRoute, { params });

    return data;
  }
  public async industrialEquivalences(params?: IIndustrialParams): Promise<IDataPagination<IIndustrialEquivalencesResponse>> {
    const { data } = await serverAxios.get(IndustrialHttp.equivalancesRoute, { params });

    return data;
  }
}

export type IIndustrialParams = {
  page?: number;
  per_page?: number;
  artnr?: string;
  hernr?: string;
  codfou?: string;
  refnr?: string;
};

export type IIndustrialEquivalencesResponse = {
  artnr: string;
  hernr: string;
  codfou: string;
  refnr: string;
};

export type IIndustrialCarPartsResponse = {
  artnr: string;
  codfou: string;
};

export default IndustrialHttp;
