import {IData, IDataResponse, IParams, IStatus } from 'models/order';
import { IOrderItem } from 'models/order.model';

import { addRouteParam, IHttpResponse, serverAxios } from '../../http';

class OrdeClientsHttp {
  private static route = '/orders-clients';
  private static clients = '/orders-clients/clients';
  private static order = '/orders-clients/:id';
  private static orderStatus = '/orders/status';
  private static orderItems = '/orders-clients/:id/items';

  public async getOrdersStatus(): Promise<IHttpResponse<IStatus>> {
    return serverAxios.get(OrdeClientsHttp.orderStatus);
  }
  public async getOrders(params: IParams): Promise<IHttpResponse<IDataResponse>> {
    return serverAxios.get(OrdeClientsHttp.route, { params });
  }
  public async getOrderClients(): Promise<IHttpResponse<{client_id: number, name: string}[]>> {
    return serverAxios.get(OrdeClientsHttp.clients);
  }
  public async getOrderById(id: string): Promise<IHttpResponse<IData>> {
    return serverAxios.get(addRouteParam(OrdeClientsHttp.order, { id }));
  }
  public async getOrderItems(id: string): Promise<IHttpResponse<IHttpResponse<IOrderItem[]>>> {
    return serverAxios.get(addRouteParam(OrdeClientsHttp.orderItems, { id }));
  }
 
}

export default OrdeClientsHttp;
