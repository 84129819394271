import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import GeneralSearch from 'components/general-search/GeneralSearch';
import MainNews from 'components/news/main-news/MainNews';
import SideNews from 'components/news/side-news/SideNews';
import React, { useEffect, useState } from 'react';
import RufinusItem from './rufinusItem/RufinusItem';
import SearchCatalog from './searchCatalog/SearchCatalog';
import SideRss from './sideRss/SideRss';
import { useTranslation } from 'react-i18next';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import SwiperSlider from 'components/SwiperSlider/SwiperSlider';
import { SwiperSlide } from 'swiper/react';
import useHttp from 'utils/hooks/useHttp';
import { INews } from 'utils/hooks/http/news.http';
import Spinner from 'components/spinner/Spinner';

import './EspaceCatalog.scss';
import { useNavigate } from 'react-router-dom';


type Props = {};

const EspaceCatalogView: React.FC<Props> = () => {
  const { isMobile, isTablet } = useDeviceDetect();
  const { t, i18n } = useTranslation();
  const [mainNews, setMainNews] = useState<INews>();
  const [news, setNews] = useState<INews[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const http = useHttp();

  function getNews() {
    setLoading(true);
    http.news.getNews({}).then((res) => {
      const data = res.data.data;


      const firstNews = data.shift();

      setMainNews(firstNews);
      setNews(data);
    }
    ).finally(() => setLoading(false));
  }

  useEffect(() => {
    getNews();
  }, [i18n.language]);

  const rssSlidesPerView = isMobile || isTablet ? 'auto' : 5;

  return (
    <div className="espace-catalog-container">
      <div className="espace-catalog-search">
        <SearchCatalog />
        <div className="general-search">
          <GeneralSearch />
        </div>
      </div>
      {mainNews &&
        <div className={`news container-fluid-large  ${loading ? 'loading' : ''}`}>
          {loading ? <Spinner class="small" /> : (
            <React.Fragment>
              <div className="main-news">
                <MainNews data={mainNews} />
              </div>
              <div className="side-news">
                {news.map(article => {
                  return (
                    <SideNews key={article.id} data={article} />
                  );
                })}
                <div className="side-news-button">
                  <DefaultButton text={t('_all_the_news')} onClick={() => navigate('/news')} />
                </div>
              </div>
            </React.Fragment>
          )}
        </div>
      }
      {/* <div className="side-rss">
        <SwiperSlider
          slidesPerView={rssSlidesPerView}
          spaceBetween={isMobile || isTablet ? 10 : 30}
          navigation={isMobile || isTablet}
          loop
          breakpoints={{
            990: {
              slidesPerView: 4,
              centeredSlides: true,
            },
            1200: {
              slidesPerView: rssSlidesPerView,
            }
          }}
        >
          <SwiperSlide className="swiper-slide">
            <SideRss />
          </SwiperSlide>
          <SwiperSlide className="swiper-slide">
            <RufinusItem />
          </SwiperSlide>
          <SwiperSlide className="swiper-slide">
            <RufinusItem />
          </SwiperSlide>
          <SwiperSlide className="swiper-slide">
            <RufinusItem />
          </SwiperSlide>
          <SwiperSlide className="swiper-slide">
            <RufinusItem />
          </SwiperSlide>
        </SwiperSlider>
      </div> */}
    </div>
  );
};

EspaceCatalogView.displayName = 'EspaceCatalogView';

export default EspaceCatalogView;
