import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Column } from 'react-table';

import { useSearchParams } from 'react-router-dom';
import { IData } from 'models/order';
import { useTranslation } from 'react-i18next';
import DefaultTable from 'components/tables/DefaultTable/DefaultTable';
import useHttp from 'utils/hooks/useHttp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft } from '@fortawesome/free-solid-svg-icons';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import './ClientOfferDetails.scss';
import CURRENCY from 'utils/constants/currency';

type Props = {
setOpenDetails?
getClientOfferList?
orderAction?: string;
setOrderAction?
};

interface IOrderSummaryTable {
  name: string,
  value: string | number
}

interface IOrderItemTable {
  reference: string,
  quantity: number,
  price: number,
  supplier: string,
  status: string,
  total: number
}

interface OrderItem {
  reference: string;
  price: number;
  supplier: string;
  quantity: number;
  status: string;
  total: number;
}

const ClientOfferDetails: React.FC<Props> = (props) => {
  const http = useHttp();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const user = JSON.parse(localStorage.getItem('userData')!);
  const [loadingOrder, setLoadingOrder] = useState(false);
  const [loadingOrderItems, setLoadingOrderItems] = useState(false);
  const [orderSymmary, setOrderSymmary] = useState<Array<{ [key: string]: string | number }>>([]);
  const [orderItems, setOrderItems] = useState<Array<OrderItem>>([]);
  const [orderTotalPrice, setOrderTotalPrice] = useState<number>();
  const [data, setData] = useState<IData>();
  const [hasMore, setHasMore] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [,setPageCount] = useState(0);
  const [page, setPage] = useState<number>(searchParams.get('page') ? Number(searchParams.get('page')) : 1);
  const observer = useRef<IntersectionObserver | null>(null);

  const lastItemRef = useCallback(
    (node) => {
      if (loadingOrderItems) {return;}
  
      if (observer.current) {observer.current.disconnect();}
  
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          // Load more data when the last item is in view
          getItems(page + 1);
        }
      });
  
      if (node) {observer.current.observe(node);}
    },
    [loadingOrderItems, hasMore, page]
  );

  const columnsOrderSummary = useMemo(
    (): Column<IOrderSummaryTable>[] => [
      {
        Header: '1',
        columns: [
          {
            Header: '2',
            accessor: 'name',

          },
          {
            Header: '4',
            accessor: 'value',
          },
        ],
      },
    ],
    [
    ],
  );

  const columnsOrderItems = useMemo(
    (): Column<IOrderItemTable>[] => [

      {
        Header: `${t('_reference_submited')}`,
        accessor: 'reference',
      },
      {
        Header: `${t('_mark')}`,
        accessor: 'supplier',
      },
      {
        Header: `${t('_sell_price')}`,
        accessor: 'price',
        Cell: (cell) => <span>{CURRENCY[user!.currency]}{cell.row.original.price}</span>

      },
      {
        Header: `${t('_qte')}`,
        accessor: 'quantity',

      },
      {
        Header: `${t('_status')}`,
        accessor: 'status',

      },
      {
        Header: 'Total H.T',
        accessor: 'total',
        Cell: ( row ) => {
          return <div className="total">{CURRENCY[user!.currency]}{row.value.toFixed(2)}</div>;
        }
      },
    ],
    [],
  );

  useEffect(() => {
    if (searchParams.get('id')) {
      setLoadingOrder(true);
      http.order.getOrderById(String(searchParams.get('id'))).then(
        res => {


          setData(res.data);
          setOrderSymmary([
            { name: t('_order_submission'), value: res.data.time },
            { name: t('_confirmation_of_the_order'), value: 'YYYY/MM/DD' },
            // { name: t('_estimated_delivery'), value: res.data.delivery_date ?? 'YYYY/MM/DD' },
            { name: t('_request_of_delivery_date'), value: res.data.delivery_date ?? 'YYYY/MM/DD' },
            { name: t('_proforma_id'), value: res.data.m3_order_id ? String(res.data.m3_order_id) : res.data.proforma_id || ''},

          ]);
          setOrderTotalPrice(res.data.total_price);
        })
        .finally(
          () => { setLoadingOrder(false); }
        );


    }

    getItems(page);
  }, []);

  const getItems = (page) => {
    setLoadingOrderItems(true);
    http.order.getAutomotorOrderItems(searchParams.get('id')!, { page: page, per_page: 10 }).then((res) => {
      setPageCount(res.data.total_pages);
  
      const uniqueReferences = new Set(orderItems.map(item => item.reference));

      const uniqueNewItems = res.data.data.filter(item => !uniqueReferences.has(item.reference));
  
      setOrderItems(() => [
        ...orderItems,
        ...uniqueNewItems.map((item) => ({
          reference: item.reference,
          price: item.price,
          supplier: item.supplier,
          quantity: item.quantity,
          status: item.status,
          total: item.price * item.quantity,
        })),
      ]);

      setHasMore(page < res.data.total_pages);
      setPage(res.data.page);
    }).finally(() => {
      setLoadingOrderItems(false);
    });
  };

  function exportFile(){
    setExportLoading(true);
    http.order.orderExport(String(data?.id)).then(res => {
      const element = document.createElement('a');

      const file = new Blob(
        [res.data],
        { type: res.type },
      );

      element.href = URL.createObjectURL(file);
      element.download = `order-${String(String(data?.id))}.xlsx`;
      element.click();

    }).finally(() => {
      setExportLoading(false);
    });
  }

  return (
    <div className="client-offer-details-container">
      <div className="back-btn" onClick={() => props.setOpenDetails(false)}>
        <FontAwesomeIcon icon={faArrowAltCircleLeft}/> {t('_return')}
      </div>
      <div className="title">
        {t('_summary_of_your_order')}
      </div>
      <div className="order-status">
        <div className="id">
          {t('_order')} <span>#{data?.id}</span>
        </div>
        <div className="status">
          {t('_status')}: <span>{data?.status && t(String(data?.status))}</span>
        </div>
      </div>
      <div className="offer-tables">
        <div className="offer-tables-export_btn">
          <DefaultButton text={t('_export')} onClick={exportFile} isloading={exportLoading} />
        </div>
        <div className="offer-summary">
          <DefaultTable columns={columnsOrderSummary} data={orderSymmary} total={orderTotalPrice} loading={loadingOrder} header={false} />
        </div>
        <div className="automotor-order-items">
          <DefaultTable lastItemRef={lastItemRef} columns={columnsOrderItems} data={orderItems} loading={loadingOrderItems} />
        </div>
        {/* {pageCount > 1 && <Pagination pageCount={pageCount} forcePage={page} handlePageClick={handlePageClick} />} */}
      </div>
    </div>

  );
};

export default ClientOfferDetails;
