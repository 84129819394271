import React from 'react';
import { useTranslation } from 'react-i18next';
import DefaultButton from '../../../../../../components/buttons/DefaultButton/DefaultButton';
import CommandsTable from '../../../../../../components/tables/CommandsTable/CommandsTable';

import './Orders.scss';

type Props = {};

const OrdersView: React.FC<Props> = () => {
  const { t } = useTranslation();

  return (
    <div className="orders">
      <div className="title">
        <p dangerouslySetInnerHTML={{ __html: t('_new_orders') }} />
      </div>
      <div className="table">
        <CommandsTable />
      </div>
      <div className="all-orders-button">
        <DefaultButton text={t('_all_orders')} onClick={() => null} />
      </div>
    </div>
  );
};

OrdersView.displayName = 'CatalogView';

export default OrdersView;
