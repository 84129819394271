import TextInput from 'components/inputs/TextInput/TextInput';
import i18n from 'i18n';
import moment from 'moment';
import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import { useOnClickOutside } from 'utils/hooks/useOnClickOutside';
import './CustomDatePicker.scss';

type Props = {
    setValue: (value) => void
    defaultValues?: {
        start_date: string,
        end_date: string
    }
}

const CustomDatePicker: React.FC<Props> = (props) => {
  const {t} = useTranslation();
  const {isMobile} = useDeviceDetect();

  const [startDate, setStartDate] = useState<Date | null>();
  const [endDate, setEndDate] = useState<Date | null>();

  const [openStartDate, setOpenStartDate] = useState(false);
  const [openEndDate, setOpenEndDate] = useState(false);
  
  const startDateRef = useRef() as MutableRefObject<HTMLDivElement>;
  const endDateRef = useRef() as MutableRefObject<HTMLDivElement>;

  useOnClickOutside(startDateRef, () => setOpenStartDate(false));
  useOnClickOutside(endDateRef, () => setOpenEndDate(false));

  useEffect(()=>{
    if(!startDate && !endDate){
      props.setValue({
        start_date: null,
        end_date: null
      });
    }

    if(startDate && endDate){
      props.setValue({
        start_date: moment(startDate).format('YYYY-MM-DD'),
        end_date: moment(endDate).format('YYYY-MM-DD'),
      });
    }

    if(startDate && !endDate){
      props.setValue({
        start_date: moment(startDate).format('YYYY-MM-DD'),
        end_date: null
      });
    }

    if(!startDate && endDate){
      props.setValue({
        start_date: null,
        end_date: moment(endDate).format('YYYY-MM-DD')
      });
    }
    
  },[startDate, endDate]);

  useEffect(()=>{
    if(props.defaultValues?.start_date){
      setStartDate(new Date(props.defaultValues?.start_date));
    }

    if(props.defaultValues?.end_date){
      setEndDate(new Date(props.defaultValues?.end_date));
    }
  },[props.defaultValues]);

  return (
    <div className="custom-date-container">
      <div className="custom-date-item">
        <div className="date-input-container">
          <div className={`custom-picker-dropdown ${openStartDate ? 'disable-input' : ''}`} onClick={() => setOpenStartDate(true)}>
            <TextInput placeholder={t('_start_date')} value={startDate ? moment(startDate).format('YYYY-MM-DD') : ''} />
          </div>
          <div className="date-separator">To</div>
          <div className={`custom-picker-dropdown ${openEndDate ? 'disable-input' : ''}`} onClick={() => setOpenEndDate(true)}>
            <TextInput placeholder={t('_end_date')} value={endDate ? moment(endDate).format('YYYY-MM-DD') : ''} />
          </div>
        </div>
        {openStartDate && (
          <div className="start-date-picker" ref={startDateRef}>
            <ReactDatePicker
              dateFormat="YYYY-MM-DD"
              selected={startDate}
              onChange={(date) => {
                setStartDate(date);
                setEndDate(null);
              }}
              monthsShown={isMobile ? 1 : 2}
              inline
              locale={i18n.language.split('-')[0]}
            />
          </div>
        )}
        {openEndDate && (
          <div className="end-date-picker" ref={endDateRef}>
            <ReactDatePicker
              dateFormat="YYYY-MM-DD"
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              minDate={startDate}
              monthsShown={isMobile ? 1 : 2}
              inline
              locale={i18n.language.split('-')[0]}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomDatePicker;