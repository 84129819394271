import React, { useEffect, useState } from 'react';
import './FavoriteButton.scss';
import Modal from '../../../modal/Modal';
import FavoriteListModal from './FavoriteListModal/FavoriteListModal';
import IconButton from 'components/buttons/IconButton/IconButton';
import { contentStyle } from '../../../modal/ContentStyleModal';
import { IFavoriteCheckedList, IFavoriteList } from '../../../../models/favorite';
import { notify } from '../../../../utils/marketplace';
import { ICarPartData } from '../../../../models/car-parts.model';
import { AxiosError } from 'axios';
import { CATALOG_MANUFACTURER } from '../../../../utils/constants/applications';
import Spinner from '../../../spinner/Spinner';
import Icon from '../../../Icon/Icon';
import useHttp from 'utils/hooks/useHttp';

type Props = {
  carPart: ICarPartData;
  text?: string;
  onDone?: () => void;
  onListCreate?: (listItem: IFavoriteList) => void;
  updateFavorite?: (isChecked: boolean) => void;
};

const FavoriteButton: React.FC<Props> = (props) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const http = useHttp();
  const [favoriteListLoading, setFavoriteListLoading] = useState<boolean>(false);
  const [checkedIdsLoading, setCheckedIdsLoading] = useState<boolean>(false);
  const [list, setList] = useState<IFavoriteList[]>([]);
  const [checkedList, setCheckedList] = useState<IFavoriteList[]>([]);
  const [heartFilled, setHeartFilled] = useState<boolean>(props.carPart.is_favorite);

  const handleAddToFavorite = () => {
    setOpenModal(!openModal);

    if (!list.length) {
      getFavoriteList();
    }

    if (heartFilled) {
      getFavoriteCheckedList();
    }
  };


  useEffect(() => {
    if (heartFilled != props.carPart.is_favorite) {
      if (props.updateFavorite) {
        props.updateFavorite(heartFilled);
      }
    }

  }, [heartFilled]);

  const getFavoriteList = async () => {
    try {
      setFavoriteListLoading(true);
      const response = await http.favorite.getFavoriteLists();

      setList(response);
    } catch (e) {
      console.log(e);
      notify('error');
    } finally {
      setFavoriteListLoading(false);
    }
  };

  const getFavoriteCheckedList = async () => {
    try {
      const params: IFavoriteCheckedList = {
        catalog_id: props.carPart.catalog_id,
        cod_fou: props.carPart.cod_fou,
        is_oe_part: props.carPart?.catalog_type === CATALOG_MANUFACTURER,
        manufacturer_id: null,
        oe_reference: null,
        price: null,
        quantity: 0,
        supplier_id: props.carPart.data_supplier.id,
        supplier_reference: props.carPart.data_supplier_reference,
        source: props.carPart.source
      };

      setCheckedIdsLoading(true);
      const response = await http.favorite.getFavoriteCheckedList(params);

      setCheckedList(response);
    } catch (e) {
      notify('error');

      if (e instanceof AxiosError) {
        const message = e?.response?.data;

        notify(message);
      }
    } finally {
      setCheckedIdsLoading(false);
    }
  };

  return (
    <div className="favorite-button">
      {favoriteListLoading || checkedIdsLoading ? (
        <div className="spinner-container">
          <Spinner />
        </div>
      ) : (
        <div>
          <Modal
            openModal={openModal}
            setOpenModal={setOpenModal}
            childrenComponent={
              <FavoriteListModal
                onListCreate={props.onListCreate}
                onDone={props.onDone}
                setHeartFilled={setHeartFilled}
                setFavoriteList={setList}
                setCheckedList={setCheckedList}
                carPart={{
                  ...props.carPart,
                  is_favorite: heartFilled,
                }}
                checkedList={checkedList}
                favoriteList={list}
                setOpenModal={setOpenModal}
              />
            }
            contentStyle={contentStyle}
          />
          <IconButton
            onClick={handleAddToFavorite}
            text={props.text}
            className="iconButton favorite"
            icon={<Icon className="heart" name={heartFilled ? 'heart' : 'emptyHeart'}></Icon>}
          ></IconButton>
        </div>
      )}
    </div>
  );
};

FavoriteButton.displayName = 'FavoriteButton';

export default FavoriteButton;
