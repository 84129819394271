import { IBattery } from 'interfaces/battery';
import React from 'react';
import {useTranslation} from 'react-i18next';
import './BatteryDetails.scss';

type Props = {
  battery: IBattery ;
}

const BatteryDetails: React.FC<Props> = (props) => {
  const battery = props.battery;
  const {t} = useTranslation();

  return (

    <div className="battery-details-wrapper">
      <div className="battery__header">
        <div className="battery__header__article">
          <span>{t('_reference')}</span>:
          {` ${battery.data_supplier_reference_original}`}
        </div>
        <h5 className="battery__header__title">{`${battery.data_supplier.name} ${battery.data_supplier_reference_original}`}</h5>
        <div className="battery__header__category">
          {`${battery.description ? battery.description : '-'}`}
        </div>
      </div>
      <div className="battery__content">
        {

          Object.entries(battery.tags).map((characteristic, index) => (
            characteristic[1] && <div className="content-item" key={index}>
              <p className="label">{t(`_battery_${characteristic[0]}`)}: </p>
              <p className="value">{`${characteristic[1]}`}</p>
            </div>
          ))
        }
      </div>
    </div>
  );
};

export default BatteryDetails;