import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import Modal from 'components/modal/Modal';
import { IClientContactsForm, IClientContact } from 'models/client.model';
import { getLanguagesAsClientsOption } from 'utils/constants/locales';
import EditContactView from './EditContactView';
import { IClientOption, IOption } from 'interfaces/select';
import useHttp from 'utils/hooks/useHttp';
import { notify } from 'utils/marketplace';

type Props = {
  showContactEdit: boolean;
  newContactMode: boolean;
  contactDetails: IClientContact | null;
  handleNewClientModal: () => void;
  refreshData: () => void;
};

const EditContact: React.FC<Props> = (props: Props) => {
  const [passwordMarchError, setPasswordMatchError] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [languagesOptions, setLanguagesOptions] = useState<IOption[]>([{ label: '', value: '' }]);
  const [lang, setLang] = useState<IOption>();
  const http = useHttp();
  const { id } = useParams<'id'>();

  let defaultValues: IClientContactsForm = {
    password: {},
  };

  if (!props.newContactMode) {
    defaultValues = {
      gender: props.contactDetails?.gender,
      is_active: props.contactDetails?.is_active ? 'true' : 'false',
      first_name: props.contactDetails?.first_name || '',
      last_name: props.contactDetails?.last_name || '',
      language: props.contactDetails?.language || '',
      email: props.contactDetails?.email,
      phone: props.contactDetails?.mobile ? props.contactDetails?.mobile != 'null' ? props.contactDetails?.mobile : '' : '',
      mobile: props.contactDetails?.mobile ? props.contactDetails?.mobile != 'null' ? props.contactDetails?.mobile : '' : '',
      password: { first: '', second: '' },
      commentary: props.contactDetails?.commentary,
    };
  }

  const {
    control,
    getValues,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IClientContactsForm>({ defaultValues });

  useEffect(() => {
    setLanguagesOptions(getLanguagesAsClientsOption());
    reset();

    if (props.contactDetails?.language) {
      const lang = getLanguagesAsClientsOption()?.find((item) => item.value === props.contactDetails?.language);

      if (lang) {
        setLang(lang);
      }

    }


  }, []);

  useEffect(() => {
    reset();
  }, [languagesOptions]);

  const onSubmit = () => {
    if (props.newContactMode) {
      onSumbitNew();
    } else {
      onSumbitEdit();
    }
  };

  const onSumbitNew = () => {
    const contact = getValues();


    if (contact.password?.first === '' || contact.password?.second === '' || contact.password?.first !== contact.password?.second) {
      setPasswordMatchError(true);

      return;
    }

    setPasswordMatchError(false);
    setLoading(true);
    contact.has_password = true;
    http.clients
      .createContact(Number(id), contact)
      .then(() => {
        setLoading(false);
        props.refreshData();
        props.handleNewClientModal();
      })
      .catch((error) => {
        setLoading(false);
        notify(error?.message ? error?.message : 'Error creating the user');
      });
  };

  const onSumbitEdit = () => {
    const contact = getValues();

    if ((contact.password?.first !== '' || contact.password?.second !== '') && contact.password?.first !== contact.password?.second) {
      setPasswordMatchError(true);

      return;
    }

    setPasswordMatchError(false);
    setLoading(true);
    http.clients
      .patchContact(Number(id), Number(props.contactDetails?.id), contact)
      .then(() => {
        setLoading(false);
        props.refreshData();
        props.handleNewClientModal();
      })
      .catch((error) => {
        setLoading(false);
        notify(error?.message ? error?.message : 'Error creating the user');
      });
  };

  return (
    <Modal
      openModal={props.showContactEdit}
      setOpenModal={props.handleNewClientModal}
      childrenComponent={
        <EditContactView
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
          register={register}
          handleNewClientModal={props.handleNewClientModal}
          languagesOptions={languagesOptions}
          passwordMarchError={passwordMarchError}
          newContactMode={props.newContactMode}
          errors={errors}
          loading={loading}
          control={control}
          lang={lang}
          setLang={setLang}
        ></EditContactView>
      }
    ></Modal>
  );
};

EditContact.displayName = 'EditContact';

export default EditContact;
