import React  from 'react';
import './DiagnosticErrorCodesLayout.scss';
import {generatePath, Outlet, useParams} from 'react-router-dom';
import ErrorCodes from 'pages/CatalogVehicle/Diagnostic/ErrorCodes/ErrorCodes';
import {getURLParams} from 'utils/marketplace';
import {ISearchLinks} from 'pages/CatalogVehicle/TechnicalDocumentation/components/ErrorCodes/Solutions/Solutions';

interface IUrlParams {
    code?: string;
}

const DiagnosticErrorCodesLayout:React.FC = () => {
  const { vehicle_id, session_id } = useParams();
  const {code}: IUrlParams = getURLParams(location.search);

  const searchLinks: ISearchLinks = {
    code: generatePath('/reliable/rmi/:vehicle_id/diagnostic/:session_id/error-codes/manuals', {vehicle_id: String(vehicle_id), session_id: String(session_id)}),
    keyword: generatePath('/reliable/rmi/:vehicle_id/diagnostic/:session_id/error-codes/solutions', {vehicle_id: String(vehicle_id), session_id: String(session_id)}),
  };

  if(code) {
    searchLinks.keywordQueryParams =  {keyword: code};
  }

  return (
    <div className="diagnostic-layout">
      <ErrorCodes/>
      <div className="content">
        <Outlet context={{searchLinks}} />
      </div>
    </div>

  );
};

DiagnosticErrorCodesLayout.displayName = 'DiagnosticErrorCodesLayout';

export default DiagnosticErrorCodesLayout;
