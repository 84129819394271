import { RootState } from 'store/store';
import { createDeepEqualSelector } from './index';

export const automotorTariffSelector = (state: RootState): RootState['automotorTariff'] => state.automotorTariff;


export const getAutomotorTariff = createDeepEqualSelector(automotorTariffSelector, (user) => user.automotorTariff);

export const getTariffs = createDeepEqualSelector(automotorTariffSelector, (tariff) => tariff.tariffs);

export const loadingTariffs = createDeepEqualSelector(automotorTariffSelector, (tariff) => tariff.loadingTariff);


