import React, { useEffect, useState } from 'react';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import ProductCategoryItem from 'components/Item/ProductCategoryItem/ProductCategoryItem';
import TabFilter from 'components/tab-filter/TabFilter';
import { ICatalogInfo } from 'models/catalog.model';

import { ICategorieGroup } from 'applications/automotor/components/espace/navbar/Navbar.model';
import { getBackendAssetsBasePath } from 'utils/marketplace';
import Spinner from 'components/spinner/Spinner';
import './CatalogPage.scss';
import TariffFile from 'components/tariff-file/TariffFile';
import SellersTable from 'components/tables/SellersTable/SellersTable';
import { IData } from 'models/order';
import useHttp from 'utils/hooks/useHttp';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import VideoSection from 'components/VideoSection/VideoSection';

import CategorySlider from './category-slider/CategorySlider';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-regular-svg-icons';
import Modal from 'components/modal/Modal';
import EditCatalog from './modal-edit/EditCatalog';
import HasRoles from 'components/high-order-components/HasRoles/HasRoles';
import { ROLE_SUPPLIER_AMERIGO } from 'utils/constants/roles';
import { fetchCatalogInfo } from 'store/reducers/catalog/catalogs.actions';
import { useAppDispatch } from 'utils/hooks/redux';
import i18n from 'i18n';
import { IPromotionDetails } from 'utils/hooks/http/promotions.http';
import Promotion from 'components/Promotions/Default/Promotion';
import MainNews from '../../components/news/main-news/MainNews';
import SideNews from '../../components/news/side-news/SideNews';
import { INews } from 'utils/hooks/http/news.http';

type Props = {
  categories: ICategorieGroup[];
  onCategoryItemClick: (item: string) => void;
  isLoadingCatalogs: boolean;
  isLoadingCategories: boolean;
  catalogInfo: ICatalogInfo | null;
  catalogId: string;
};

const CatalogPageView: React.FC<Props> = (props: Props) => {
  const [data, setData] = useState<IData[]>([]);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [submit, setSubmit] = useState<boolean>(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const http = useHttp();
  const { isMobile, isTablet } = useDeviceDetect();
  const dispatch = useAppDispatch();
  const [promotionItems, setPromotionItems] = useState<IPromotionDetails[]>([]);
  const [loadingPromo, setLoadingPromo,] = useState<boolean>(false);
  const [mainNews, setMainNews] = useState<INews>();
  const [news, setNews] = useState<INews[]>([]);

  function getOrderList(params) {
    setLoading(true);
    http.order
      .getOrders(params)
      .then((res) => setData(res.data.data))
      .finally(() => {
        setLoading(false);
      });
  }

  function getNews() {
    setLoading(true);
    http.news.getNews({}).then((res) => {
      const data = res.data.data;
      const firstNews = data.shift();

      setMainNews(firstNews);
      setNews(data);
    }
    ).finally(() => setLoading(false));
  }

  useEffect(() => {
    getNews();
  }, [i18n.language]);

  useEffect(() => {
    getOrderList({ per_page: 5, catalog_id: props.catalogId });
    getPromotions();
  }, [i18n.language]);

  useEffect(() => {
    if (submit) {
      dispatch(fetchCatalogInfo(props.catalogId));
      setSubmit(false);

    }

  }, [submit]);

  const nagivateToSupplierWebsite = () => {
    if (props.catalogInfo?.official_website_url) {
      const element = document.createElement('a');

      element.href = props.catalogInfo.official_website_url;
      element.target = '_blank';
      element.click();
    }
  };

  function getPromotions() {
    setLoadingPromo(true);
    http.promotions.activePromotionsAmerigo(props.catalogId).then((res) => {
      setPromotionItems(res.data);
    }).finally(() => { setLoadingPromo(false); })
      .catch(() => {
        setLoadingPromo(false);
      });
  }

  return (
    <div className="catalog-page-container">
      <div className="catalog-page">
        <div className="catalog-header-container">
          <div className="catalog-header">
            <HasRoles roles={[ROLE_SUPPLIER_AMERIGO]}>
              <div className="catalog-header-edit">
                <FontAwesomeIcon className="supplier-delete" onClick={() => setOpenModal(true)} icon={faEdit} />
              </div>
            </HasRoles>
            {props.catalogInfo && !props.isLoadingCatalogs ? (
              <React.Fragment>
                <div className="catalog-header-info">
                  <img
                    className="supplier-img"
                    src={`${getBackendAssetsBasePath()}${props.catalogInfo.image}`}
                    alt={props.catalogInfo.name}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = '/images/car-part-placeholder11.png';
                    }}
                  />
                  <div className="description" dangerouslySetInnerHTML={{ __html: props.catalogInfo.description }}></div>
                  <div className="website-btn">
                    {
                      props.catalogInfo?.official_website_url != null && props.catalogInfo?.official_website_url != '' ?
                        <DefaultButton
                          onClick={() => {
                            nagivateToSupplierWebsite();
                          }}
                          text={t('_see_the_website')}
                        /> : null
                    }

                  </div>
                </div>
                <div className="tariff-container">
                  {props.catalogInfo.tariff_file && <TariffFile file={props.catalogInfo.tariff_file} />}
                </div>
              </React.Fragment>
            ) : (
              <Spinner class="small"></Spinner>
            )}
          </div>
          <div className="catalog-name">{props.catalogInfo && `${t('_catalog')} ${props.catalogInfo.name}`}</div>
        </div>
      </div>
      <TabFilter />
      {isMobile || isTablet ?
        <div className="category-slider">
          <CategorySlider data={props.categories} onCategoryClick={props.onCategoryItemClick} />
        </div>
        : (
          <div className="category-items">
            {props.categories && !props.isLoadingCategories ? (
              props.categories.map((item: ICategorieGroup) => {
                return (
                  <div className="category-item" key={item.code_groupe}>
                    <ProductCategoryItem
                      text={item.name}
                      image={item.image}
                      onClick={() => props.onCategoryItemClick(item.name)}
                    ></ProductCategoryItem>
                  </div>
                );
              })
            ) : (
              <Spinner class="small"></Spinner>
            )}
          </div>
        )}
      <div className="container-fluid-large">
        <div className="promotions-slider">
          {
            loadingPromo ? <Spinner class="small" /> :
              promotionItems.length ?
                <Promotion
                  items={promotionItems}
                  slidesPerView={isMobile || promotionItems.length == 1 ? 'auto' : 2}
                  navigation={!(isMobile || isTablet) && promotionItems.length != 1}
                  pagination={isMobile || isTablet}
                  autoplay={promotionItems.length != 1}
                  loop={promotionItems.length != 1}
                />
                : null
          }
        </div>
        {/*<div className="catalog-video">*/}
        {/*  <VideoSection previewImage={`${getBackendAssetsBasePath()}${props.catalogInfo?.image}`} perPage={4} />*/}
        {/*</div>*/}
        {/*{mainNews &&*/}
        <div className={`news ${loading ? 'loading' : ''}`}>
          {loading ? <Spinner class="small" /> : (
            <React.Fragment>
              <div className="main-news">
                <MainNews data={mainNews} />
              </div>
              <div className="side-news">
                {news.map(article => {
                  return (
                    <SideNews key={article.id} data={article} />
                  );
                })}
                <div className="side-news-button">
                  <DefaultButton text={t('_all_the_news')} onClick={() => navigate('/news')} />
                </div>
              </div>
            </React.Fragment>
          )}
        </div>
        {/*}*/}
      </div>

      <div className="catalog-orders-table">
        <SellersTable data={data} loading={loading} />
        <div className="button">
          <DefaultButton text={t('_all_orders')} onClick={() => navigate('orders')} />
        </div>
      </div>
      <Modal
        openModal={openModal}
        setOpenModal={setOpenModal}
        childrenComponent={<EditCatalog setOpenModal={setOpenModal} setSubmit={setSubmit} />}
      />

    </div >
  );
};

CatalogPageView.displayName = 'CatalogPageView';

export default CatalogPageView;
