import React, { useState } from 'react';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './ClientRejectModal.scss';
import useHttp from 'utils/hooks/useHttp';
import { useForm } from 'react-hook-form';
import { notify } from 'utils/marketplace';

type Props = {
    setOpenModal: (value: boolean) => void;
    invitationId;
    fetchData?
}

const ClientRejectModal: React.FC<Props> = (props) => {
  const {watch, register} = useForm();
  const { t } = useTranslation();
  const http = useHttp();
  const [loading, setLoading] = useState(false);

  function rejectClient() {
    setLoading(true);
    http.clients.postRejectClient(Number(props.invitationId), watch('message'))
      .then(async (res) => {
        // notify(res.data[0], 'success');
        notify('User invitation declined!', 'info');

        props.setOpenModal(false);

        await props.fetchData();
      })
      .finally(() => setLoading(false));
  }

  return (
    <div className="client-reject-modal">
      <div className="modal-head">
        <div className="title">{t('_reject')}</div> 
        <div className="close">
          <FontAwesomeIcon className="close-icon" icon={faClose} onClick={() => props.setOpenModal(false)}/>
        </div>
      </div>
      <div className="modal-content">
        <div className="client-message">
          <textarea {...register('message')} className="client-reject-textarea" placeholder={t('_message')}/>
        </div>
        <div className="actions">
          <DefaultButton isloading={loading} onClick={() => rejectClient()} text={t('_send')}/>
        </div>
      </div>
    </div>
  );
};

export default ClientRejectModal;