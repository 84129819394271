import React, {useEffect, useState} from 'react';
import DiscutionsView from './Discutions.view';
import {IGetMessageSupplierParams, IMessageSupplier, IMessageSupplierPaginate} from '../../interfaces/messaging';
import useHttp from 'utils/hooks/useHttp';
import MessagingHttp from '../../utils/hooks/http/messaging';
import {notify} from '../../utils/marketplace';
import {ICatalogs, ICatalogSupplierMappingGroup} from '../../models/catalog.model';

type Props = {};

const Discutions: React.FC<Props> = () => {
  const {catalogs, messaging} = useHttp();
  const [loading, isLoading] = useState<boolean>(false);
  const [messages, setMessages] = useState<IMessageSupplier[] | []>([]);
  const [selectedSupplier, setSelectedSupplier] = useState<ICatalogSupplierMappingGroup | null>(null);
  const [suppliers, setSuppliers] = useState<ICatalogSupplierMappingGroup[] | []>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);

  const [pagination, setPagination] = useState<IMessageSupplierPaginate>({
    page: 1,
    per_page: MessagingHttp.SUPPLIER_MESSAGES_PER_PAGE,
    total_pages: 0,
    total_count: 0
  });

  useEffect(() => {
    getCatalogs();
  }, []);

  useEffect(() => {
    getMessages();
  }, [selectedSupplier]);

  const getCatalogs = async() => {

    try {
      const response = await catalogs.getCatalogSupplierMappingGroup();

      setSuppliers(response);

    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      notify(e.message);
    }

  };

  const getMessages = async () => {
    isLoading(true);

    try {
      const params: IGetMessageSupplierParams = {
        page: pagination.page,
        per_page: pagination.per_page,
      };

      if(selectedSupplier) {
        params.supplier_mapping_group_id = selectedSupplier?.supplier_mapping_group_id;
      }

      const {data} = await messaging.getLastMessages(params);

      setMessages(data.data);
      setPagination({
        page: data.page,
        per_page: data.per_page,
        total_count: data.total_count,
        total_pages: data.total_pages
      });

    }catch (e: unknown) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      notify(e.message);
    } finally {
      isLoading(false);
    }
  };

  const handleChangeSupplier = (val ) => {
    if(!val){
      setSelectedSupplier(null);

      return;
    }

    const supplier = suppliers.find((item) => item.supplier_mapping_group_id == val.value );

    if(supplier) {
      setSelectedSupplier(supplier);
    }
  };

  const handlePageClick = (page: number) => {
    if(!pagination) {
      return;
    }

    const paginationCopy = pagination;

    paginationCopy.page = page ;

    setPagination(paginationCopy);
    getMessages();
  };

  return <DiscutionsView getMessages={getMessages} openModal={openModal} setOpenModal={setOpenModal} handlePageClick={handlePageClick} selectedSupplier={selectedSupplier} handleChangeSupplier={handleChangeSupplier} loading={loading} pagination={pagination} messages={messages} suppliers={suppliers}></DiscutionsView>;
};

Discutions.displayName = 'Discutions';

export default Discutions;
