import React from 'react';
import { NavLink } from 'react-router-dom';
import RELIABLE_LOGO from 'assets/images/reliable-logo.svg';
import useDefaultRouteForRole from 'utils/hooks/useDefaultRouteForRole';
import  './PublicNavbar.scss';

type Props = {};

const PublicNavbar: React.FC<Props> = () => {
 
  const defaultLinkForRole = useDefaultRouteForRole();

  return (
    <div className="public-navbar-container">
      <div className="public-navbar">
        <NavLink to={defaultLinkForRole} className="logos">
          <img src={RELIABLE_LOGO} width="87" height="10" alt="espace-logo" />
        </NavLink>
      </div>
    </div>
  );
};

export default PublicNavbar;
