import React, { useEffect, useState } from 'react';
import { ReactComponent as PartsNotFoundImage } from 'assets/images/parts-not-found.svg';
import Pagination from 'components/pagination/Pagination';
import Spinner from 'components/spinner/Spinner';
import { useTranslation } from 'react-i18next';
import useHttp from 'utils/hooks/useHttp';
import { useLocation, useSearchParams } from 'react-router-dom';
import { IBodyResponseOil } from 'interfaces/oil';
import { IOilCatalogFilter } from 'utils/hooks/http/oils-catalog.http';

import OilNavbar from 'components/navbar/oil-navbar/OilNavbar';
import ProductsViewMode from '../../../../components/ProductsViewMode/ProductsViewMode';
import { DEFAULT_VIEW } from '../../../../components/ProductsViewMode/ProductsViewMode.constants';
import ProductsViewModeChanger from 'components/ProductsViewModeChanger/ProductsViewModeChanger';
import {
  CATALOG_OIL
} from '../../../../components/ProductsViewModeChanger/products-view-mode-changer-utils';
import List from 'components/List/List';
import CatalogOilFilter from './CatalogOilFilter/CatalogOilFilter';
import axios from 'axios';
import { ICategory } from 'views/Sidebar/Sidebar.model';
import './CatalogOils.scss';
import { sortByOptions } from 'components/SortBy/SortBy.map';
import SortBy, { SortByParams } from 'components/SortBy/SortBy';
import { filterObject } from '../../../../utils/utils';
import TextInput from '../../../../components/inputs/TextInput/TextInput';
import { isRetailerMecadepotChild } from 'utils/isRetailerMecadepotChild';

const CatalogOils: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { search } = useLocation();
  const { t, i18n } = useTranslation();
  const http = useHttp();

  const [loading, setLoading] = useState(true);
  const [loadingCategories, setLoadingCategories] = useState(true);
  const [refData, setRefData] = useState<IBodyResponseOil>();
  const [filterData, setFilterData] = useState<IOilCatalogFilter>();
  const [openFilter, setOpenFilter] = useState(false);
  const [searchInput, setSearchInput] = useState('');

  const [oilCategories, setOilCategories] = useState<ICategory[]>([]);
  const [activeCategory, setActiveCategory] = useState<{ id: number }>({ id: searchParams.get('category_id') ? Number(searchParams.get('category_id')) : oilCategories[0]?.id });
  const [page, setPage] = useState(Number(searchParams.get('page')));

  const user = JSON.parse(localStorage.getItem('userData')!);
  const [viewMode, setViewMode] = useState(user?.user_settings.products_view_mode || DEFAULT_VIEW);
  const cancelToken = axios.CancelToken.source();

  function getOilData(data) {
    setLoading(true);

    const sortBySelectedOption = sortByOptions.filter((item) => String(item.value) == String(searchParams.get('sort')))[0] ?? [];

    const sortParams: SortByParams = {
      sort_by_column: String(searchParams.get('sort') ?? ''),
      sort_by_asc: String(searchParams.get('asc') ?? sortBySelectedOption?.default_sort_asc ?? ''),
    };

    const params = {
      ...data,
      ...sortParams,
      page,
    };

    const validParams = validateParams(params);

    http.oilCatalogHttp
      .getOils(validParams, cancelToken.token)
      .then((res) => {
        setRefData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          setLoading(false);
        }
      });
  }

  function getOilFilter(data) {

    const validFilterParams = validateParams(data);

    http.oilCatalogHttp.getOilFilters(validFilterParams).then((res) => {
      setFilterData(res.data);
    });
  }

  const validateParams = (data) => {
    return Object.entries(data).reduce((acc, [key, value]) => {
      if (!value) {
        return acc;
      }

      acc[key] = value;

      return acc;
    }, {});
  };

  function getOilCategories() {
    setLoadingCategories(true);
    http.catalogs.getCarOilAllCategories({}).then((res) => {
      setOilCategories(res);
    }).finally(() => setLoadingCategories(false));
  }

  const handlePageClick = (pageN: number) => {
    setPage(pageN);
  };


  useEffect(() => {
    if (oilCategories.length) {
      const firstValidCategory = oilCategories.find((category) => category.number_of_products !== 0);

      setActiveCategory({ id: Number(searchParams.get('category_id')) || Number(firstValidCategory?.id) });

      const params = {
        q: searchParams.get('q'),
        category_ids: searchParams.get('category_id') ? searchParams.getAll('category_id') : [String(firstValidCategory?.id)],
        brand_id: searchParams.getAll('brand_id'),
        viscosity: searchParams.getAll('viscosity'),
        quantity: searchParams.getAll('quantity'),
        search: searchInput,
        family: searchParams.getAll('family')
      };

      const validParams = filterObject(params);


      getOilData(validParams);
    }

    return () => {
      cancelToken.cancel();
    };
  }, [
    i18n.language,
    page,
    search,
    searchInput,
    oilCategories.length
  ]);


  const onCategoryClick = (category) => {
    setActiveCategory(category);
    setSearchParams({ category_id: [category.id] });
    setPage(1);
  };

  useEffect(() => {
    getOilCategories();
    getOilFilter({});
  }, [i18n.language]);

  useEffect(() => {
    if (searchParams.get('category_id')) {
      setActiveCategory({ id: Number(searchParams.get('category_id')) });
    }
  }, [oilCategories, searchParams.get('category_id')]);


  function filterOptionsElement() {
    return (
      <div className="catalog-parts-filter">
        <button onClick={() => setOpenFilter(true)} className={`catalog-parts-button ${openFilter ? 'active' : ''}`}>
          {t('_filter_results_btn')}
        </button>
      </div>
    );
  }


  return (
    <div className="catalog-oils-wrapper">
      <OilNavbar option={filterOptionsElement()} title="_oil_catalog" showSortBy={isRetailerMecadepotChild()} />
      <div className="separator"></div>

      <div className="filterOptions">
        <span className="search-parts-title">{t('_search')}</span>
        <TextInput
          name="search"
          defaultValue={searchInput}
          placeholder="Ex: 1003001F"
          onChange={(e) => setSearchInput(e.target.value as string)}
          withIcon
        />
        {(refData?.data?.length && refData?.data?.length > 0 && !isRetailerMecadepotChild()) ? <SortBy /> : null}
        <div className="results">
          {refData?.total_count} {t('_results')}
        </div>
        <ProductsViewMode viewMode={viewMode} setViewMode={setViewMode} />

      </div>

      <div className="retailer-catalog-oil-container">
        <div className="catalog-oil-sidebar">
          {oilCategories && oilCategories.length ? (
            <List
              content={oilCategories?.map((item) => {
                return { ...item, id: item.id, name: item.name };
              })}
              images={oilCategories?.map((item) => item.image)}
              itemClassName="parts-list"
              itemId="id"
              onItemClick={onCategoryClick}
              activeCategory={activeCategory}
            />
          ) : null}
        </div>

        <div className="wrapperItems">
          {loadingCategories || loading ? (
            <Spinner class="car-parts-spinner" />
          ) : refData?.data?.length ? (
            <div>
              <ProductsViewModeChanger catalog={CATALOG_OIL} viewMode={viewMode} data={refData?.data} />
            </div>
          ) : (
            <div className="no-parts-data">
              <div className="notFoundSvg">
                <PartsNotFoundImage />
              </div>
              <h1>{t('_oils_not_found')}</h1>
            </div>
          )}
          {(refData && refData?.total_pages && refData?.page && refData?.total_pages > 1) && !loading ? (
            <Pagination pageCount={refData.total_pages} handlePageClick={handlePageClick} forcePage={refData.page} />
          ) : (
            null
          )}
        </div>
      </div>
      <CatalogOilFilter getData={getOilData} filterData={filterData} openFilter={openFilter} setOpenFilter={setOpenFilter} />
    </div>
  );
};

export default CatalogOils;
