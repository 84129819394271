import { ITyre } from 'interfaces/tyre';
import React from 'react';
import ItemEshopTyreView from './ItemEshopTyre.view';

type Props = {
  data: ITyre[];
  loading: boolean;
  openModalTyre?: (id: number) => void;
  deleteItem?: (id: number) => void;
  register;
  handleOnChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleOnChangeTopOffer: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleOnChangeTopSales: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const ItemEshopTyre: React.FC<Props> = (props) => {
  return (
    <ItemEshopTyreView
      data={props.data}
      loading={props.loading}
      openModalTyre={props.openModalTyre}
      deleteItem={props.deleteItem}
      register={props.register}
      handleOnChange={props.handleOnChange}
      handleOnChangeTopOffer={props.handleOnChangeTopOffer}
      handleOnChangeTopSales={props.handleOnChangeTopSales}
    />);
};

ItemEshopTyre.displayName = ' ItemEshopTyre';

export default ItemEshopTyre;
