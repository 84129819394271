import React from 'react';
import { ICarPartData } from '../../../models/car-parts.model';
import './PartItemDetails.scss';
import { useTranslation } from 'react-i18next';

type Props = {
  carPart: ICarPartData;
}

const PartItemDetails: React.FC<Props> = (props) => {
  const carPart = props.carPart;
  const { t } = useTranslation();

  return (

    <div className="car-part-details-wrapper">
      <div className="car-detail__header">
        <div className="car-detail__header__article">
          <span>{t('_reference')}</span> :
          {` ${carPart.data_supplier_reference_original}`}
        </div>
        <h5 className="car-detail__header__title">{`${carPart.data_supplier.name} ${carPart.data_supplier_reference_original}`}</h5>
        <div className="car-detail__header__category">
          {`${carPart.subcategory?.name ? carPart.subcategory?.name : '-'}`}
        </div>
        <div className="car-detail__header__set">
          {carPart.quantity && Number(carPart.quantity) ? `${carPart.quantity} ${t('_items_in_pack')}, ` : ''}
        </div>
      </div>
      <div className="car-detail__content">
        {
          carPart.characteristics.map((characteristic, index) => (
            <div className="content-item" key={index}>
              <p className="label">{t(characteristic.name)}: </p>
              <p className="value">{characteristic.name === '_reference_commerciale' ? <span className="commercial-ref-value">{characteristic.value}</span> : characteristic.value} {characteristic.unit ? characteristic.unit : ''}</p>
            </div>
          ))
        }
      </div>
    </div>
  );
};

PartItemDetails.displayName = 'PartItemDetails';


export default PartItemDetails;