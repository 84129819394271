import DefaultToastContainer from 'components/toast-container/DefaultToastContainer';
import React from 'react';
import { Outlet } from 'react-router-dom';
import '../../DefaultCssV2.scss';
import NavBarPresentation from 'applications/presentation/components/navbar/NavBarPresentation';
import PresentationBanner from '../components/banner/PresentationBanner';

const MainLayout: React.FC = () => {
  return (
    <div className="container-wrapper-v2">
      <DefaultToastContainer />
      <div className="container">
        <div className="header">
          <NavBarPresentation />
        </div>
        <div className="body">
          <PresentationBanner />
          <Outlet />
        </div>
        <div className="footer"></div>
      </div>
    </div>
  );
};

MainLayout.displayName = 'MainLayout';

export default MainLayout;
