import React, { useMemo } from 'react';

import Af from 'applications/af/AF';
import Amerigo from 'applications/amerigo/Amerigo';
import Automotor from 'applications/automotor/Automotor';
import Public from 'applications/public/Public';
import Punchout from 'applications/punchout/Punchout';
import Retailer from 'applications/retailer/Retailer';
import Presentation from 'applications/presentation/Presentation';
import { Marketplaces } from 'utils/constants/applications';
import { getCurrentMarketplace } from '../marketplace';

interface JSXRoute extends BaseRoute {
  jsx: React.ReactNode;
}

interface ComponentRoute extends BaseRoute {
  component: React.FC;
}

interface BaseRoute {
  path: string;
  name?: string;
  module?: { code: string; action: string; fallback?: string } | string;
  guard?: ((guardData: GuardData) => boolean)[];
  fallback?: string;
  children?: IRoute[] | null;
}

interface ComponentRoute extends BaseRoute {
  component: React.FC;
}

const useRoutes = (): JSX.Element => {
  const currentMarketplace = getCurrentMarketplace();

  return useMemo(() => {
    switch (currentMarketplace) {
      case Marketplaces.PUNCHOUT:
        return <Punchout />;
      case Marketplaces.AMERIGO:
        return <Amerigo />;
      case Marketplaces.RETAILER:
        return <Retailer />;
      case Marketplaces.PUBLIC:
        return <Public />;
      case Marketplaces.PUBLIC_TR:
        return <Public />;
      case Marketplaces.PUBLIC_TN:
        return <Public />;
      case Marketplaces.PUBLIC_MA:
        return <Public />;
      case Marketplaces.PUBLIC_DZ:
        return <Public />;
      case Marketplaces.MRA:
        return <Retailer />;
      case Marketplaces.FOURNISSEUR:
        return <Amerigo />;
      case Marketplaces.DISTRIBUTEUR:
        return <Amerigo />;  
      case Marketplaces.AF:
        return <Af />;
    
      case Marketplaces.AUTOMOTOR :
        if(location.toString().includes('public')){
          return <Presentation />;
        }else{
          return <Automotor />;
        }
      default:
        return <Automotor />;
    }
  }, [currentMarketplace]);
};

export type IRoute = ComponentRoute | JSXRoute;

export interface AppState {}

export interface GuardData {
  store: AppState;
}

export default useRoutes;
