import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import { IFuelType, IManufacturers, IModels, IVersion } from 'interfaces/vehicleSelector';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { createSearchParams, generatePath, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import useHttp from 'utils/hooks/useHttp';
import '../VehicleCard.scss';

type Props = {
  setOpenModal?: (e: boolean) => void;
  link?: string;
};

const CarSelector: React.FC<Props> = (props) => {
  const { isMobile, isTablet } = useDeviceDetect();
  const { reset, control, getValues, handleSubmit } = useForm();
  const http = useHttp();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [manufacturers, setManufacturers] = useState<IManufacturers[]>([]);
  const [manufacturersLoading, setManufacturersLoading] = useState(false);

  const [models, setModels] = useState<IModels[]>([]);
  const [modelsLoading, setModelsLoading] = useState(false);

  const [fuelType, setFuelType] = useState<IFuelType[]>([]);
  const [fuelTypeLoading, setFuelTypeLoading] = useState(false);

  const [versions, setVersions] = useState<IVersion[]>([]);
  const [versionsLoading, setVersionsLoading] = useState(false);

  const [activeButton, setActiveButton] = useState(false);

  function getData(request, setLoading) {
    setLoading(true);

    return request
      .then((res) => res.data)
      .finally(() => {
        setLoading(false);
      });
  }

  const manufacturerOptions = () => {
    return (
      manufacturers?.map((item) => ({
        label: item.description,
        value: item.id,
        quantity: item.quantity,
      })) || []
    );
  };

  const modelOptions = () => {
    return (
      models?.map((item) => ({
        label: item.description,
        value: item.id,
        source: item.source,
        country_code: item.country_code,
      })) || []
    );
  };

  const fuelOptions = () => {
    return (
      fuelType?.map((item) => ({
        label: item.description,
        value: item.id,
      })) || []
    );
  };

  const versionOptions = () => {
    return (
      versions?.map((item) => ({
        label: `${item.shortName} ${item.name} ${item.engineCapacity} ${item.capacityQualifier} ${item.startMonth}/${item.startYear} - ${item.endMonth}/${item.endYear} ${item.fapDescription} ${item.nbOfCylinder} - ${item.kwPower} KW - ${item.horsePower} CH DIN`,
        value: item.id,
      })) || []
    );
  };

  const handleManufacturerChange = (val) => {
    getData(http.vehicleSelector.getCarModels(val), setModelsLoading).then((models) => setModels(models));
    reset({ model: '', fueltype: '', version: '' });
    reset({ model: '', fueltype: '', version: '' });
    setModels([]);

    setFuelType([]);
    setVersions([]);
    setActiveButton(false);
  };


  const handleModelChange = (val) => {
    getData(http.vehicleSelector.getCarFuel(val), setFuelTypeLoading).then((fuel) => setFuelType(fuel));
    getData(http.vehicleSelector.getCarVersion(val), setVersionsLoading).then((versions) => setVersions(versions));
    setActiveButton(false);
  };

  function submit() {

    if (props.link) {
      navigate({
        pathname: `/reliable/rmi/${getValues()?.version.value}${props.link}`,
        search: `${createSearchParams({ source: 'T', vehicle_identifier: '002' })}`
      });
    } else {
      navigate({
        pathname: generatePath('/reliable/rmi/:vehicle_id/catalogs', { vehicle_id: getValues()?.version.value }),
        search: `${createSearchParams({ source: 'T', vehicle_identifier: '002' })}`
      });
    }

    if (props.setOpenModal) {
      props.setOpenModal(false);
    }

  }

  useEffect(() => {
    getData(http.vehicleSelector.getCarManufacturers(), setManufacturersLoading).then((manufacturers) => {
      setManufacturers(manufacturers);
    });
  }, []);

  return (
    <form onSubmit={handleSubmit(submit)} className="dropdowns-container">
      <div className="dropdown-item">
        <label className="dropdown-label">{t('_manufacturer')}</label>
        <Controller
          control={control}
          name="manufacturer"
          render={({ field: { onChange, value } }) => (
            <Select
              value={value}
              menuPlacement="auto"
              maxMenuHeight={200}
              className={`select-dropdown ${!manufacturers.length ? 'disabled' : ''}`}
              onChange={(val) => {
                handleManufacturerChange(val);
                onChange(val);
              }}
              options={manufacturerOptions()}
              placeholder={manufacturersLoading ? t('_loading') : t('_manufacturer')}
              styles={{
                menu: provided => ({ ...provided, zIndex: 9999 })
              }}
              isSearchable={!isMobile && !isTablet}
            />
          )}
        />
      </div>
      <div className="dropdown-item">
        <label className="dropdown-label">{t('_model')}</label>
        <Controller
          control={control}
          name="model"
          render={({ field: { onChange, value } }) => (
            <Select
              value={value}
              menuPlacement="auto"
              maxMenuHeight={180}
              className={`select-dropdown ${!models.length ? 'disabled' : ''}`}
              onChange={(val) => {
                onChange(val);
                handleModelChange(val);
              }}
              options={modelOptions()}
              placeholder={modelsLoading ? t('_loading') : t('_model')}
              styles={{
                menu: provided => ({ ...provided, zIndex: 9999 })
              }}
              isSearchable={!isMobile && !isTablet}
            />
          )}
        />
      </div>
      <div className="dropdown-item">
        <label className="dropdown-label">{t('_fuel_type')}</label>
        <Controller
          control={control}
          name="fueltype"
          render={({ field: { onChange, value } }) => (
            <Select
              value={value}
              menuPlacement="auto"
              maxMenuHeight={100}
              className={`select-dropdown ${!fuelType.length ? 'disabled' : ''}`}
              onChange={(val) => {
                onChange(val);
              }}
              options={fuelOptions()}
              placeholder={fuelTypeLoading ? t('_loading') : t('_fuel_type')}
              styles={{
                menu: provided => ({ ...provided, zIndex: 9999 })
              }}
              isSearchable={!isMobile && !isTablet}
            />
          )}
        />
      </div>
      <div className="dropdown-item">
        <label className="dropdown-label">{t('_version')}</label>
        <Controller
          control={control}
          name="version"
          render={({ field: { onChange, value } }) => (
            <Select
              value={value}
              menuPlacement="auto"
              menuPosition="fixed"
              minMenuHeight={150}
              maxMenuHeight={200}
              className={`select-dropdown ${!versions.length ? 'disabled' : ''}`}
              onChange={(val) => {
                onChange(val);
                setActiveButton(true);
              }}
              options={versionOptions()}
              placeholder={versionsLoading ? t('_loading') : t('_version')}
              styles={{
                menu: provided => ({ ...provided, zIndex: 9999 })
              }}
              isSearchable={!isMobile && !isTablet}
            />
          )}
        />
      </div>
      <div className="submit-button">
        <DefaultButton
          text={t('_send')}
          disabled={!activeButton}
          type="submit"
        />
      </div>
    </form>
  );
};

CarSelector.displayName = 'CarSelector';

export default CarSelector;
