import React, { useEffect, useState } from 'react';
import  './EspaceReliableNavbarGroupBtn.scss';
import { useLocation } from 'react-router-dom';
import DefaultRetailerFloatableButtons from './NavbarGroupBtns/DefaultRetailerFloatableButtons';

type Props = {};

const EspaceReliableNavbarGroupBtn: React.FC<Props> = () => {
  const [icons, setIcons] = useState<JSX.Element | undefined>();
  const location = useLocation();

  useEffect(() => {
    setIcons(getCorrespondingBtn());
  }, [location]);

  const getCorrespondingBtn = () => {
    switch (true) {
      case (location.pathname === '/' || location.pathname === '/news'):
        return <DefaultRetailerFloatableButtons/>;

      default:
        return;
    }
  };

  return <div className="header-btn-search-retailer">{icons}</div>;
};

EspaceReliableNavbarGroupBtn.displayName = 'EspaceReliableNavbarGroupBtn';

export default EspaceReliableNavbarGroupBtn;
