import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTable } from 'react-table';
import './IndustrialTable.scss';

type Props = {
  data;
  columnsTable;
  loading?: boolean;
};

const IndustrialTable: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns: props.columnsTable,
    data: props.data,
  });

  return (
    <div className="table-wrapper-industrial">
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, key) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={key} className="row">
              {headerGroup.headers.map((column, index) => (
                <th {...column.getHeaderProps()} key={index} className="heading">
                  <div className="table-title">{column.render('Header')}</div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps} className="body">
          {props.data.length ? (
            rows.map((row) => {
              prepareRow(row);
              const { key, ...restRowProps } = row.getRowProps();

              return (
                <tr key={key} {...restRowProps} className="row">
                  {row.cells.map((cell) => {
                    const { key, ...restCellProps } = cell.getCellProps();

                    return (
                      <td key={key} {...restCellProps} className="cell">
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={20} className="notFond">{`${t('_no_data_found')}`}</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default IndustrialTable;
