import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import useHttp from 'utils/hooks/useHttp';
import TextInput from 'components/inputs/TextInput/TextInput';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import DatePicker from 'react-datepicker';
import { useOnClickOutside } from 'utils/hooks/useOnClickOutside';
import moment from 'moment';

import { useTranslation } from 'react-i18next';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import { useLocaleDatePicker } from 'utils/localeDatePicker';
import { useSearchParams } from 'react-router-dom';
import i18n from 'i18n';
import { INotificationResponse } from 'utils/hooks/http/notifications.http';
import NotificationItem from './notification-item/NotificationItem';
import Spinner from 'components/spinner/Spinner';
import Pagination from 'components/pagination/Pagination';
import Modal from 'components/modal/Modal';
import NotificationModal from './notification-modal/NotificationModal';
import Select from 'react-select';
import 'react-datepicker/dist/react-datepicker.css';
import './Notifications.scss';
import { usePermission } from 'utils/usePermission';
import { ROLE_SELLER } from 'utils/constants/roles';

type Props = {};

const Notifications: React.FC<Props> = () => {
  const { control, getValues, register, handleSubmit, reset } = useForm();
  const http = useHttp();
  const [urlParams, setSearchParams] = useSearchParams();
  const Seller = usePermission([ROLE_SELLER]);
  
  const [data, setData] = useState<INotificationResponse[]>([]);
  const [modalData, setModalData] = useState<INotificationResponse>();
  const [open, setOpen] = useState(false);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(0);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [valueDate, setValueDate] = useState('');

  const [openDate, setOpenDate] = useState(false);
  const [loading, setLoading] = useState(false);
  const dateRef = useRef() as MutableRefObject<HTMLDivElement>;

  const { t } = useTranslation();
  const { isMobile } = useDeviceDetect();

  const TYPE_OPTIONS = [
    {label: t('_order'), value: 'Order'},
    {label: t('_wrong_mapping'), value: 'Wrong mapping'},
  ];

  useOnClickOutside(dateRef, () => setOpenDate(false));
  useLocaleDatePicker(i18n.language);

  function getNotifications(params) {
    setLoading(true);
    http.notifications
      .getNotifications({ ...params })
      .then((res) => {
        const { data, total_pages } = res;

        setTotalPage(total_pages);

        setData(data);
      })
      .finally(() => {
        setLoading(false);
      });
  }


  useEffect(() => {
    urlParams.get('page') && setCurrentPage(Number(urlParams.get('page')) ?? 1);
    getNotifications({ page: urlParams.get('page') ?? 1 });
  }, [urlParams.get('page')]);

  const onSubmit = async () => {
    setLoading(true);
    const data = getValues();

    if (data.type) {
      data.type = data.type.value;
    }


    const start_date = moment(startDate).format('YYYY-MM-DD');
    const end_date = moment(endDate).format('YYYY-MM-DD');

    if (moment(start_date).isValid()) {
      data.start_date = moment(startDate).format('YYYY-MM-DD');
    }

    if (moment(end_date).isValid()) {
      data.end_date = moment(endDate).format('YYYY-MM-DD');
    }


    const validParams = Object.entries(data).reduce((acc, [key, value]) => {
      if (!value) {
        return acc;
      }

      acc[key] = value;

      return acc;
    }, {});

    await getNotifications({ ...validParams, page: 1 });
  };

  const handlePageClick = (data: number) => {
    setCurrentPage(data);
  };

  function onDateChange(dates) {
    const [start, end] = dates;

    setStartDate(start);
    setEndDate(end);

    if (start && end) {
      setValueDate(`${moment(start).format('DD/MM/YYYY')} - ${moment(end).format('DD/MM/YYYY')}`);
    }
    else {

      if (start) {
        setValueDate(`${moment(start).format('DD/MM/YYYY')}`);

      }

      if (end) {
        setValueDate(`${moment(end).format('DD/MM/YYYY')}`);

      }
    }
  }

  function markAsRead(id: number) {
    const params = {
      seen_ids: [Number(id)]
    };

    setData((prev) => {
      const newData = [...prev];


      newData.map((item) => {
        if(item.id === id){
          item.seen = true;
        }
      });

      return newData as INotificationResponse[]; 
      
    });

    http.notifications.markAsRead(params);
  }

  function markAllAsRead() {
    const params = {
      seen_ids: data.map(item => Number(item.id))
    };

    setData((prev) => {
      const newData = [...prev];


      newData.map((item) => {
        item.seen = true;
      });

      return newData as INotificationResponse[]; 
      
    });

    http.notifications.markAsRead(params);
  }

  const handleResetFilter = () => {
    reset({ date: '', type: '' });
    setCurrentPage(1);
    setSearchParams({page: '1'});
    setStartDate(null);
    setEndDate(null);
    setValueDate('');
  };

  function handleClick(data){
    setModalData(data);
    setOpen(true);
    markAsRead(data.id);
  }

  return (
    <div className="automotor-notifications-page">
      <div className="notifications-container">
        <div className="notifications-filter">
          <div className="notifications-filter-header">
            <div className="filter-title">{t('_notifications')}</div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="notifications-filter-body">
              <div className="notifications-filter-input-item">
                <div className={`notifications-react-picker-dropdown ${openDate ? 'disable' : ''}`} onClick={() => setOpenDate(true)}>
                  <TextInput disabled={openDate} placeholder={t('_select_date')} defaultValue={valueDate ? valueDate : ''} />
                </div>
                {openDate && (
                  <div className="notifications-date-picker" ref={dateRef}>
                    <DatePicker
                      {...register('date')}
                      dateFormat="YYYY-MM-DD"
                      selected={startDate}
                      onChange={onDateChange}
                      startDate={startDate}
                      endDate={endDate}
                      monthsShown={isMobile ? 1 : 2}
                      selectsRange
                      inline
                      locale={i18n.language.split('-')[0]}
                    />
                  </div>
                )}
              </div>
              {Seller && (
                <div className="notifications-filter-input-item">
                  <Controller
                    control={control}
                    name="type"
                    render={({ field: { onChange, value } }) => (
                      <Select
                        value={value}
                        className="select-dropdown"
                        onChange={(val) => {
                          onChange(val);
                        }}
                        options={TYPE_OPTIONS}
                        placeholder={t('_type')}
                        menuPlacement="auto"
                        isClearable={false}
                        isSearchable={false}
                        menuPosition="fixed"
                        styles={{ menuPortal: base => ({ ...base, zIndex: 150 }) }}
                      />
                    )}
                  />
                </div>
              )}
              <div className="filter-btn">
                <DefaultButton text={t('_filter')} type="submit" />
              </div>
              <div className="filter-btn">
                <DefaultButton red text={t('_reset')} onClick={handleResetFilter} />
              </div>
            </div>
          </form>
        </div>
        <div className="notifications-all-read" >
          <span onClick={markAllAsRead}>{t('_mark_all_as_read')}</span>
        </div>
        <div className="notifications-list">
          {loading ? <Spinner class="small" /> : (
            data.length ? data.map(item => (
              <NotificationItem key={item.id} data={item} markAsRead={markAsRead} handleClick={handleClick}/>
            ))
              : <div className="no-notifications">{t('_no_notifications')}</div>
          )}
        </div>
        <div>
          {(totalPage > 1 && !loading) && (
            <Pagination pageCount={totalPage!} handlePageClick={handlePageClick!} forcePage={currentPage!} />
          )}
        </div>
      </div>
      <Modal
        openModal={open}
        setOpenModal={setOpen}
        childrenComponent={<NotificationModal data={modalData} setOpen={setOpen} />}
      />
    </div>
  );
};

export default Notifications;
