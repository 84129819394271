
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';


type Props = {
  dataCountryRestriction;
  setOpenModalCountryRestriction;
  removeRestrictionParts;
  setSubmitCountryRestriction;

}
const ModalRestriction: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  return (
    <div className="modal-proforma-restriction">
      <h1>
        <Trans i18nKey="_upload_proforma_restriction" components={{ span: <span className="color-red-h1" /> }} />
      </h1>
      <div>


        {
          props.dataCountryRestriction ?
            Object.keys(props.dataCountryRestriction).map((key) => {
              return (
                <div className="restriction-reference" key={key}> {key + '  :  ' + props.dataCountryRestriction[key].join(',')}</div>

              );
            })
            : null
        }
      </div>
      <div className="btn-validate-restriction">
        <DefaultButton
          onClick={() => {
            props.setOpenModalCountryRestriction(false);
            props.removeRestrictionParts();
            props.setSubmitCountryRestriction(true);

          }}
          red
          text={t('_remove_parts_restriction')}
        />
        <DefaultButton onClick={() => {
          props.setOpenModalCountryRestriction(false);
          props.setSubmitCountryRestriction(true);

        }} text={t('_accept_parts_restriction')}
        />
      </div>
    </div>
  );

};

ModalRestriction.displayName = 'ModalRestriction';

export default ModalRestriction;
