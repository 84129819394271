import React from 'react';
import { CellProps } from 'react-table';
import { CartTableItem } from '../../CartTable.model';
import ReferenceCellView from './ReferenceCell.view';

type Props = CellProps<CartTableItem>;

const ReferenceCell: React.FC<Props> = (props: Props) => {
  return <ReferenceCellView value={props.value}></ReferenceCellView>;
};

ReferenceCell.displayName = 'ReferenceCell';

export default ReferenceCell;
