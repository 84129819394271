import React, { PropsWithChildren } from 'react';
import './DefaultButton.scss';
import Spinner from '../../spinner/Spinner';

type Props = {
  text: string | JSX.Element;
  onClick?: (e) => void;
  type?: 'button' | 'submit' | 'reset' | undefined;
  disabled?: boolean;
  isloading?: boolean;
  red?: boolean
  blue?: boolean
  className?: string
};

const DefaultButtonView: React.FC<PropsWithChildren<Props>> = (props: PropsWithChildren<Props>) => {
  return (
    <button className={`default-button ${props.red ? 'red' : ''}  ${props.blue ? 'blue' : ''} ${props.disabled ? 'disabled' : ''} ${props.className}`} onClick={(e) => { !props.isloading && props.onClick && props.onClick(e); }} disabled={props.disabled} type={props.type}>
      {
        props.isloading ? (
          <Spinner class={`extra-small default-btn-spinner ${props.red ? 'red' : ''} ${props.blue ? 'blue' : ''} `}></Spinner>
        ) : (
          props.text || props.children
        )
      }
    </button>
  );
};

DefaultButtonView.defaultProps = {
  disabled: false,
};

DefaultButtonView.displayName = 'DefaultButtonView';

export default DefaultButtonView;
