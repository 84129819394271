import React from 'react';
import VehicleCardView from './VehicleCard.view';
import VehicleCardMobileView from './Mobile/VehicleCardMobile.view';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';


type Props = {
  setOpenModal?: (e: boolean) => void;
  link?: string;
};

const VehicleCard: React.FC<Props> = (props) => {
  const { isMobile, isTablet } = useDeviceDetect();

  return (
    isMobile || isTablet ?
      <VehicleCardMobileView setOpenModal={props.setOpenModal} link={props.link} />
      :
      <VehicleCardView setOpenModal={props.setOpenModal} link={props.link} />
  );
};

VehicleCard.displayName = 'VehicleCard';

export default VehicleCard;
