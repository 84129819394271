import Footer from 'components/footer/Footer';
import Navbar from 'components/navbar/Navbar';
import { SidebarContextProvider } from 'context/SidebarContext';
import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';

import EspaceReliableNavbarGroupBtn
  from 'applications/retailer/components/espace-reliable-navbar-group-btn/EspaceReliableNavbarGroupBtn';
import EbooksSidebar from 'applications/automotor/components/espace/navbar/EbooksSidebar/EbooksSidebar';
import DefaultToastContainer from 'components/toast-container/DefaultToastContainer';
import RetailerSidebar from 'applications/retailer/views/RetailerSidebar/RetailerSidebar';
import 'applications/DefaultCssV2.scss';
import NavbarMobile from 'components/navbar/Mobile/NavbarMobile';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';


const MainLayout: React.FC = () => {
  const [showEbooks, setShowEbooks] = useState(false);
  const { isMobile, isTablet } = useDeviceDetect();

  const handleCloseEbook = () => {
    setShowEbooks(() => false);
  };

  return (
    <div className={`container-wrapper-v2 ${isMobile ? 'app-layout-mobile' : isTablet ? 'app-layout-tablet' : 'app-layout-desktop'}`}>
      <DefaultToastContainer />
      <SidebarContextProvider>
        <div className="container">
          <div className="header">
            {isMobile || isTablet ?
              <React.Fragment>
                <NavbarMobile />
                <EbooksSidebar showEbooks={showEbooks} handleCloseEbook={handleCloseEbook} />
                <EspaceReliableNavbarGroupBtn />
              </React.Fragment>
              :
              <React.Fragment>
                <Navbar />
                <EbooksSidebar showEbooks={showEbooks} handleCloseEbook={handleCloseEbook} />
                <EspaceReliableNavbarGroupBtn />
              </React.Fragment>
            }
          </div>
          <div className="sidebar">
            <RetailerSidebar />
          </div>
          <div className="body">
            <Outlet />
          </div>
          <div className="footer">
            <Footer />
          </div>
        </div>
      </SidebarContextProvider>
    </div>
  );
};

MainLayout.displayName = 'MainLayout';

export default MainLayout;
