import { RootState } from 'store/store';
import { createDeepEqualSelector } from './index';

export const catalogSelector = (state: RootState): RootState['catalogs'] => state.catalogs;

export const getCurrentCatalog = createDeepEqualSelector(catalogSelector, (catalogs) => catalogs.currentCatalog);

export const getCurrentCatalogLanguage = createDeepEqualSelector(catalogSelector, (catalogs) => catalogs.language);

export const getCatalogData = createDeepEqualSelector(catalogSelector, (catalogs) => catalogs.catalogData);

export const getIsLoadingCatalogs = createDeepEqualSelector(catalogSelector, (catalogs) => catalogs.isLoadingInfo);

export const getIsLoadingCatalogData = createDeepEqualSelector(catalogSelector, (catalogs) => catalogs.isLoadingData);
