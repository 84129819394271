import TextInput from 'components/inputs/TextInput/TextInput';
import PlateDropdownContent from 'components/plate-dropdown-content/PlateDropdownContent';
import VinDropdownContent from 'components/vin-dropdown-content/VinDropdownContent';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { createSearchParams, generatePath, useNavigate } from 'react-router-dom';
import { getCurrentCatalog } from 'store/selectors/catalogs.selector';
import { RootState } from 'store/store';
import { IPlateNumberData } from 'utils/hooks/http/search-by-plate.http';
import { IVinData } from 'utils/hooks/http/search-by-vin.http';
import useHttp from 'utils/hooks/useHttp';
import { notify } from 'utils/marketplace';
import './VinContent.scss';
import VinSearch from 'components/general-search/vin-search/VinSearch';

type Props = {};

const VinContent: React.FC<Props> = () => {
  const http = useHttp();
  const { user } = useSelector((state: RootState) => state.userData);
  const [isPlateOpen, setIsPlateOpen] = useState(false);
  const [plateData, setPlateData] = useState<IPlateNumberData>();
  const [plateLoading, setPlateLoading] = useState(false);
  const [plateValue, setPlateValue] = useState('');
  const currentCatalog = useSelector(getCurrentCatalog);
  const navigate = useNavigate();
  const { t } = useTranslation();

  function handlePlateClick(item) {
    navigate({
      pathname: generatePath('/catalog/:id/categories', {
        id: String(currentCatalog?.id),

      }),
      search: `?${createSearchParams([
        ['plate_number', plateValue],
        ['model_id', item?.model_id],
        ['ktypnr', String(item.id)],
        ['manufacturer_id', item?.manufacturer_id],
        ['source', item?.source],
        ['vehicle_identifier', item?.vehicle_identifier]
      ])}`
    });
  }

  function searchByPlateNumber() {

    const re = new RegExp(/([^\s])/);

    if (plateValue.match(re) == null) {
      notify(t('_plate_number_invalid'), 'error');

      return;
    }

    setPlateLoading(true);
    setIsPlateOpen(true);
    const params = {
      registration_plate: plateValue,
      registration_country: 'fr',
    };

    http.searchByPlate
      .search(params)
      .then((res) => setPlateData(res.data))
      .finally(() => {
        setPlateLoading(false);
      })
      .catch((err) => console.log(err));
  }


  function handlePlateChange(e) {
    const result = e.target.value.replace(/[^a-zA-Z0-9_.-]/gi, '');

    setPlateValue(result);
  }


  return (
    <div className="vin-search-card">
      <div className="catalog-vin-container">
        <VinSearch catalog />
      </div>
      {user?.client_settings.plate_number_search ? (
        <React.Fragment>
          <div className="or-separator">{t('_or')}</div>
          <div className="catalog-plate-number-container">
            <label className="reference-label">{t('_plate')}</label>
            <TextInput
              placeholder=""
              onChange={handlePlateChange}
              onClick={searchByPlateNumber}
              withIcon
            />
            {isPlateOpen && (
              <PlateDropdownContent handleClick={handlePlateClick} loading={plateLoading} setIsOpen={setIsPlateOpen} data={plateData} />
            )}
          </div>
        </React.Fragment>
      ) : null}
    </div>
  );
};

export default VinContent;
