import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useHttp from 'utils/hooks/useHttp';
import { notify } from 'utils/marketplace';
import { AxiosError } from 'axios';
import Spinner from 'components/spinner/Spinner';
import { IOil } from 'interfaces/oil';
import OilPhotoGallery from '../OilPhotoGallery/OilPhotoGallery';
import OilDetails from '../OilDetails/OilDetails';
import OilOptions from '../OilOptions/OilOptions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import './OilViewMobile.scss';

const OilsViewMobile: React.FC = () => {
  const http = useHttp();
  const { id } = useParams<'id'>();

  const [oils, setOils] = useState<IOil>();
  const [loading, isLoading] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    document.body.classList.add('footer-padding');
    getOils();

    return () => {
      document.body.classList.remove('footer-padding');
    };
  }, []);

  const getOils = async () => {
    isLoading(true);

    try {
      await http.oilCatalogHttp.getOil(String(id)).then((res) => setOils(res.data));
    } catch (e: unknown) {
      if (e instanceof AxiosError) {
        const message = e?.response?.data?.detail;

        notify(message);
      }
    } finally {
      isLoading(false);
    }
  };

  return (
    <div className="oil-view item-view-wrapper">
      {loading ? (
        <Spinner class="small" />
      ) : (
        oils && (
          <React.Fragment>
            <div className="item-view-header">
              <div className="mobile-oil-reference">
                <span>Ref</span>: {` ${oils.data_supplier_reference_original}`}
              </div>
              <div className="mobile-oil-brand">
                {`${oils.data_supplier.name} ${oils.data_supplier_reference_original}`}
              </div>
              <div className="return-button">
                <span
                  className="returnButton"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <FontAwesomeIcon icon={faCircleArrowLeft} className="icon" />
                  {t('_return')}
                </span>
              </div>
            </div>
            <div className="item-view-content">
              <div className="gallery">
                <OilPhotoGallery oil={oils} />
              </div>
              <div className="details">
                <OilDetails oil={oils} />
              </div>
              <div className="options">
                <OilOptions oil={oils} />
              </div>
            </div>
            {/*<div className="item-view-footer"></div>*/}
          </React.Fragment>
        )
      )}
    </div>
  );
};

export default OilsViewMobile;
