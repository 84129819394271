import React, { useEffect, useState } from 'react';
import { ReactComponent as PartsNotFoundImage } from 'assets/images/parts-not-found.svg';
import Pagination from 'components/pagination/Pagination';
import Spinner from 'components/spinner/Spinner';
import { useTranslation } from 'react-i18next';
import useHttp from 'utils/hooks/useHttp';
import { useSearchParams } from 'react-router-dom';
import { ITyreFilter } from 'utils/rmi/interface/tyres.interface';
import { IBodyResponseTyre } from 'interfaces/tyre';
import CatalogGenericFilter from 'pages/CatalogVehicle/Catalogs/CatalogGenericFilter/CatalogGenericFilter';
import './TyresCatalog.scss';
import TyreDropdownContent from 'components/TyreDropdownContent/TyreDropdownContent';
import ProductsViewMode from 'components/ProductsViewMode/ProductsViewMode';
import { DEFAULT_VIEW } from 'components/ProductsViewMode/ProductsViewMode.constants';
import ProductsViewModeChanger from 'components/ProductsViewModeChanger/ProductsViewModeChanger';
import { CATALOG_TYRE } from 'components/ProductsViewModeChanger/products-view-mode-changer-utils';
import { sortByOptions } from 'components/SortBy/SortBy.map';
import SortBy, { SortByParams } from 'components/SortBy/SortBy';
import TyreNavbar from 'components/navbar/tyre-navbar/TyreNavbar';

const tyreParams = [{ name: 'brand' }, { name: 'season' }, { name: 'speed_rating' }];

const TyresCatalog: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { t, i18n } = useTranslation();
  const http = useHttp();

  const [loading, setLoading] = useState(true);
  const [refData, setRefData] = useState<IBodyResponseTyre>();
  const [filterData, setFilterData] = useState<ITyreFilter>();
  const [openFilter, setOpenFilter] = useState(false);

  const [page, setPage] = useState(Number(searchParams.get('page')));
  const user = JSON.parse(localStorage.getItem('userData')!);
  const [viewMode, setViewMode] = useState(user?.user_settings.products_view_mode || DEFAULT_VIEW);


  function getTyreData(data?) {
    setLoading(true);

    const sortBySelectedOption = sortByOptions.filter((item) => String(item.value) == String(searchParams.get('sort')))[0] ?? [];

    const sortParams: SortByParams = {
      sort_by_column: String(searchParams.get('sort') ?? ''),
      sort_by_asc: String(searchParams.get('asc') ?? sortBySelectedOption?.default_sort_asc ?? ''),
    };

    const params = {
      ...data,
      ...sortParams,
      ...getParamsFromURL(),
      page,
    };

    const validParams = validateParams(params);

    http.rmiTyresHttp
      .fetchVehicleTyresProducts(validParams)
      .then((res) => {
        setRefData(res.data);
      })
      .finally(() => setLoading(false));
  }

  function getTyreFilter(data) {
    const validFilterParams = validateParams(data);

    http.rmiTyresHttp.fetchVehicleTyresFilters(validFilterParams).then((res) => {
      setFilterData(res.data);
    });
  }

  const validateParams = (data) => {
    return Object.entries(data).reduce((acc, [key, value]) => {
      if (!value) {
        return acc;
      }

      acc[key] = value;

      return acc;
    }, {});
  };

  const handlePageClick = (pageN: number) => {
    setPage(pageN);
  };

  const getParamsFromURL = () => {
    return {
      tyre_ids: searchParams.getAll('tyre_id'),
      widths: searchParams.getAll('widths'),
      heights: searchParams.getAll('heights'),
      diameters: searchParams.getAll('diameters'),
      load_indexes: searchParams.getAll('load_index_id'),
      speed_ratings: searchParams.getAll('speed_rating_id'),
      q: searchParams.getAll('q'),
    };
  };

  useEffect(() => {
    getTyreData();
    getTyreFilter(getParamsFromURL());
  }, [
    i18n.language,
    page,
    searchParams.get('widths'),
    searchParams.get('heights'),
    searchParams.get('diameters'),
    searchParams.get('load_index_id'),
    searchParams.get('speed_rating_id'),
    searchParams.get('sort'),
    searchParams.get('asc'),
    searchParams.get('tyre_id')
  ]);

  function filterOptionsElement() {
    return (
      <div className="catalog-parts-filter">
        <button onClick={() => setOpenFilter(true)} className={`catalog-parts-button ${openFilter ? 'active' : ''}`}>
          {t('_filter_results_btn')}
        </button>
      </div>
    );
  }

  function filterInputElement() {
    return (
      <div className="catalog-parts-filter">
        <TyreDropdownContent />
      </div>
    );
  }

  return (
    <div className="tyres-wrapper">
      <TyreNavbar option={filterOptionsElement()} input={filterInputElement()} title="_tyres_catalog" />
      <div className="separator"></div>
      {
        !loading && (
          <div className="filterOptions">
            {refData?.data?.length && refData?.data?.length > 0 ? <SortBy /> : null}
            <div className="results">
              {refData?.total_count} {t('_results')}
            </div>
            <ProductsViewMode viewMode={viewMode} setViewMode={setViewMode} />

          </div>
        )
      }

      <div className="wrapperItems">
        {loading ? (
          <Spinner class="car-parts-spinner" />
        ) : refData?.data?.length ? (
          <ProductsViewModeChanger catalog={CATALOG_TYRE} viewMode={viewMode} data={refData?.data} />
        ) : (
          <div className="no-parts-data">
            <div className="notFoundSvg">
              <PartsNotFoundImage />
            </div>
            <h1>{t('_tyres_not_found')}</h1>
          </div>
        )}
      </div>

      {refData && refData?.total_pages && refData?.page && refData?.total_pages > 1 ? (
        <Pagination pageCount={refData.total_pages} handlePageClick={handlePageClick} forcePage={refData.page} />
      ) : (
        ''
      )}
      <CatalogGenericFilter
        getData={getTyreData}
        filterData={filterData}
        openFilter={openFilter}
        setOpenFilter={setOpenFilter}
        filterParams={tyreParams}
      />
    </div>
  );
};

export default TyresCatalog;
