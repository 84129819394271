import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faAngleDown, faFileExport } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RadioboxInput from 'components/inputs/RadioboxInput/RadioboxInput';
import React from 'react';
import { FieldErrors, FieldValues, UseFormRegister } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import './PartsSection.scss';
import CURRENCY from 'utils/constants/currency';
import Spinner from 'components/spinner/Spinner';

type Props = {
  type: ISectionType;
  icon: IconProp;
  register: UseFormRegister<FieldValues>;
  errors: FieldErrors<FieldValues>;
  checkboxValues: Array<string>;
  sectionTitle: string;
  sectionDescription: string | React.ReactElement;
  totalConfirmedLabel?: string;
  totalConfirmedLabelPrice?: number;
  onClickViewDetails: () => void;
  errorsValidate?
  export?: boolean
  onClickExport?
  exportLoad?: boolean;
};

const PartsSection: React.FC<Props> = (props) => {
  const user = JSON.parse(localStorage.getItem('userData')!);
  const { t } = useTranslation();

  return (
    <div className="summary-sections">
      <div className="info-bloc">
        <div className="title">
          {/* {props.sectionTitle}   */} {' '}
          {props.errorsValidate && props.errorsValidate.length ?
            // <div className="errorsValidate"> {t('_error')} : {props.errorsValidate.toString()}</div> 
            <div className="errorsValidate"> {props.errorsValidate.toString()}</div>
            : null}
        </div>
        <div className="description" dangerouslySetInnerHTML={{ __html: String(props.sectionDescription) }}></div>
        <div className="btn-group">
          <div className="summary_btn" onClick={props.onClickViewDetails}>
            {t('_details')} <FontAwesomeIcon icon={faAngleDown} className="" />
          </div>
          {props.export &&
            <div className="summary_btn_export" onClick={props.onClickExport}>
              {props.exportLoad ? <Spinner class="extra-small" /> : <FontAwesomeIcon icon={faFileExport} className="" />} {t('_export')}
            </div>
          }
        </div>
        <div className="resume">
          <div className="label">{props.totalConfirmedLabel}</div>
          <div className="value"> {props.totalConfirmedLabelPrice && `${CURRENCY[user!.currency]}${Number(props.totalConfirmedLabelPrice).toFixed(2)}`} </div>
        </div>

        <div className={`icon-confirmed ${props.type}`}>
          <FontAwesomeIcon icon={props.icon} className="" />
        </div>
      </div>
      <div className="actions">
        <div className="form-items">
          {props.checkboxValues.map((checkboxValue, index) => (
            <RadioboxInput
              key={index}
              value={checkboxValue}
              {...props.register(props.type, { required: true })}
              label={t(`_${checkboxValue}`)}
            />
          ))}
        </div>
        {props.errors[props.type] && <div className="errors">{t('_please_make_a_choice')}
        </div>
        }

      </div>
    </div>
  );
};

PartsSection.displayName = 'OrderSummary';

export type ISectionType = 'confirmed' | 'not-confirmed' | 'not-found' | 'not-valid';

export const SectionConfirmed = 'confirmed';

export const SectionNOTConfirmed = 'not-confirmed';

export const SectionNOTFound = 'not-found';

export const SectionNOTValidate = 'not-valid';

export default PartsSection;
