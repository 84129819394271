import {ISortByOption} from 'interfaces/select';

export const sortByOptions: ISortByOption[] = [
  {
    label: 'Default',
    value: '',
    default_sort_asc: true
  },
  {
    label: 'Name',
    value: 'name',
    default_sort_asc: true
  },
  {
    label: 'Reference',
    value: 'reference_original',
    default_sort_asc: true
  },
  {
    label: 'Price',
    value: 'price',
    default_sort_asc: false
  },
  {
    label: 'Availability',
    value: 'available',
    default_sort_asc: false
  },
];
