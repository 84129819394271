import React from 'react';
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CartListButton from 'components/buttons/CartListButton/CartListButton';
import useCollapse from 'react-collapsed';
import { useSearchParams } from 'react-router-dom';
import GenartCarPartsLink from '../GenartCarPartsLink/GenartCarPartsLink';

type Props = {
  data?
}

const ServiceItem: React.FC<Props> = (props) => {
  const config = {
    defaultExpanded: true,
    duration: 500,
    easing: 'cubic-bezier(.93,.12,.42,.25)'
  };

  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse(config);
  const [searchParams, setSearchParams] = useSearchParams();

  function handleCheck(workId) {
    const workIds = searchParams.getAll('workIds').map(Number);

    if (workIds.includes(workId)) {
      const index = workIds.indexOf(workId);

      workIds.splice(index, 1);
      setSearchParams({ ...Object.fromEntries(searchParams.entries()), workIds: workIds.map(String) });

      return;
    }

    workIds.push(workId);

    setSearchParams({ ...Object.fromEntries(searchParams.entries()), workIds: workIds.map(String) });
  }

  return (
    <div className="toggle-list" >
      <div className={`toggle-list-header ${isExpanded ? 'expanded' : ''}`} {...getToggleProps()}>
        {isExpanded ? <FontAwesomeIcon className="toggle-icon" icon={faChevronDown} /> : <FontAwesomeIcon className="toggle-icon" icon={faChevronRight} />}
        <div className="toggle-text">{props.data.ItemMpText}</div>
      </div>
      <div className="content-container">
        {props.data.WorkSteps.map(workStep => {
          return (
            <div className="toggle-list-content" {...getCollapseProps()} key={Math.random() + workStep.ItemMpText}>
              <div className="toggle-list-item">
                <div className="checkbox">
                  <input type="checkbox" onChange={() => handleCheck(workStep.WorkId)} defaultChecked={searchParams.getAll('workIds').includes(String(workStep.WorkId))} />
                  {workStep.WorkTime}h - {workStep.ItemMpText}, {workStep.QualColText}
                </div>
                <div className="toggle-list-actions">
                  <GenartCarPartsLink workId={workStep.WorkId} />
                  <CartListButton />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>

  );
};

export default ServiceItem;