import React from 'react';
import './PriceCell.scss';
import CURRENCY from 'utils/constants/currency';

type Props = { value: number };

const PriceCellView: React.FC<Props> = (props: Props) => {
  const user = JSON.parse(localStorage.getItem('userData')!);

  return <div className="wrapper-price-cart"> {CURRENCY[user!.currency]}{props.value} TTC</div >;
};

PriceCellView.displayName = 'PriceCellView';

export default PriceCellView;
