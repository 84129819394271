import React from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { EuroExhaustNormOptions } from 'utils/rmi/rmi-utils/rmi-utils';

type Props = {
  control?
}

const EuroExhaustEmissionSelect: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  return (
    <div className="maintenance-dropdown">
      <Controller
        control={props.control}
        name="euro_exhaust"
        render={({ field: { onChange, value } }) => (
          <Select
            value={value}
            className="configuration-dropdown"
            onChange={(val) => {
              onChange(val);
            }}
            options={EuroExhaustNormOptions}
            placeholder={t('_euro_exhaust')}
            isClearable
            menuPlacement="auto"
            menuPosition="fixed"
            styles={{ menuPortal: base => ({ ...base, zIndex: 50 }) }}
          />
        )}
      />
    </div>
  );
};

export default EuroExhaustEmissionSelect;