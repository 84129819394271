import React, {useEffect, useState } from 'react';
import { IData, IDataResponse } from 'models/order';

import useHttp from 'utils/hooks/useHttp';


import 'react-datepicker/dist/react-datepicker.css';
import './OrdersClientsEspace.scss';
import OrderClientsTable from 'pages/OrdersClients/Table/OrderClientsTable';


const OrdersClientsEspace: React.FC = () => {
  const http = useHttp();

  const [data, setData] = useState<IData[]>([]);
  const [loading, setLoading] = useState(false);
 
  useEffect(() => {
   
    setLoading(true);
    http.orderClients
      .getOrders({per_page: 5 })
      .then((res) => {
        const { data } = res.data as IDataResponse;

        setData(data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);


  return (
    <div className="orders-page-client-espace">
      <div className="orders-table">
        <OrderClientsTable data={data} loading={loading} />
      </div>
    </div>
  );
};

export default OrdersClientsEspace;
