import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import { RootState } from 'store/store';
import { IReliableRoute } from 'utils/rmi/reliableTabs';
import './ModulesModal.scss';

type Props = {
  setOpenModal: (e: boolean) => void;
  content?;
  link: (item) => string;
  hasModule?: { [key: string]: boolean };
  setActiveModule: (module) => void;
  title?: string;
};

const ModulesModal: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { user } = useSelector((state: RootState) => state.userData);
  const { pathname } = useLocation();

  const renderIcon = (item: IReliableRoute): JSX.Element => {
    const Icon = item.icon;

    return <Icon />;
  };

  function handleModuleClick(module) {
    props.setOpenModal(false);
    props.setActiveModule(module);
  }

  return (
    <div className="modules-modal">
      <div className="modules-modal-header">
        <div className="modal-title">{props.title ? props.title : ''}</div>
        <FontAwesomeIcon className="close-modal" icon={faClose} onClick={() => props.setOpenModal!(false)} />
      </div>
      <div className="modules-modal-content">
        {props.content?.map((item, index) => {
          if (!item.subscription_type.includes(user?.client_rmi_modules_type)) {
            return;
          }

          return (
            <NavLink
              to={props.link(item)}
              className={`modules-modal-item ${!props.hasModule![item.tab_name] ? 'disabled' : ''} ${
                pathname.includes(item.tab_name) && !pathname.includes('error-codes') ? 'active' : ''
              }`}
              onClick={() => handleModuleClick(item)}
              key={index}
            >
              <div className="modules-modal-item-icon">{renderIcon(item)}</div>
              <div className="modules-modal-item-text">{t(item.title)}</div>
            </NavLink>
          );
        })}
      </div>
    </div>
  );
};

export default ModulesModal;
