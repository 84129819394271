import AFLogo from '../../../../assets/images/logo/automotor-france.svg';
import JtektLogo from '../../../../assets/images/logo/jtekt.svg';
import SpidanLogo from '../../../../assets/images/logo/spidan.svg';
import BMLogo from '../../../../assets/images/logo/bm.svg';
import KaweLogo from '../../../../assets/images/logo/kawe.svg';
import TextarLogo from '../../../../assets/images/logo/textar.svg';

import GMAutoLogo from '../../../../assets/images/logo/gm-auto.svg';
import MotorLogo from '../../../../assets/images/logo/motor.svg';
import ASCarRepairLogo from '../../../../assets/images/logo/as-car-repair.svg';
import AMDLogo from '../../../../assets/images/logo/amd.svg';
import BekeLogo from '../../../../assets/images/logo/beke.svg';
import NationalLogo from '../../../../assets/images/logo/national.svg';

export const supplierItems = [
  {
    id: '1',
    name: 'Supplier 1',
    logo: AFLogo,
  },
  {
    id: '2',
    name: 'Supplier 2',
    logo: JtektLogo,
  },
  {
    id: '3',
    name: 'Supplier 3',
    logo: SpidanLogo,
  },
  {
    id: '4',
    name: 'Supplier 4',
    logo: BMLogo,
  },
  {
    id: '5',
    name: 'Supplier 5',
    logo: KaweLogo,
  },
  // Test items for sliders
  {
    id: '6',
    name: 'Supplier 6',
    logo: TextarLogo,
  },
  {
    id: '1',
    name: 'Supplier 1',
    logo: AFLogo,
  },
  {
    id: '2',
    name: 'Supplier 2',
    logo: JtektLogo,
  },
  {
    id: '3',
    name: 'Supplier 3',
    logo: SpidanLogo,
  },
  {
    id: '4',
    name: 'Supplier 4',
    logo: BMLogo,
  },
  {
    id: '5',
    name: 'Supplier 5',
    logo: KaweLogo,
  },
  {
    id: '6',
    name: 'Supplier 6',
    logo: TextarLogo,
  },
];

export const clientItems = [
  {
    id: '1',
    name: 'Client 1',
    logo: GMAutoLogo,
  },
  {
    id: '2',
    name: 'Client 2',
    logo: MotorLogo,
  },
  {
    id: '3',
    name: 'Client 3',
    logo: ASCarRepairLogo,
  },
  {
    id: '4',
    name: 'Client 4',
    logo: AMDLogo,
  },
  {
    id: '5',
    name: 'Client 5',
    logo: BekeLogo,
  },
  {
    id: '6',
    name: 'Client 6',
    logo: NationalLogo,
  },
];
