import React, { useEffect, useState } from 'react';
import { IDataResponseStock, IDataStock, IStockFilter } from 'models/stock';
import { getURLParams } from 'utils/marketplace';
import useHttp from 'utils/hooks/useHttp';

import 'react-datepicker/dist/react-datepicker.css';
import './StockManagementAdminNew.scss';
import { useTranslation } from 'react-i18next';
import { useLocaleDatePicker } from 'utils/localeDatePicker';
import i18n from 'i18n';
import StockManagementTable from './Table/StockManagementTableNew';
import Modal from 'components/modal/Modal';
import StockCreateNew from './ModalCreate/StockCreateNew';
import StockFilter from 'components/filter/StockFilter/StockFilter';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import StockEditNew from './ModalEdit/StockEditNew';
import ModalDelete from 'components/CatalogNaf/ModalDelete/ModalDelete';


type Props = {
  catalogId?: string;
};

interface IURLParams {
  page?: number;
}

const StockManagementAdminNew: React.FC<Props> = () => {
  const http = useHttp();
  const urlParams: IURLParams = getURLParams(location.search);
  const [data, setData] = useState<IDataStock[]>([]);
  const [paginationData, setPaginationData] = useState<IDataResponseStock>();
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [openFilter, setOpenFilter] = useState(false);
  const [openModalCreate, setOpenModalCreate] = useState<boolean>(false);
  const [submit, setSubmit] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [filter, setFilter] = useState<IStockFilter>();
  const [loadingFilter, setLoadingFilter] = useState<boolean>(false);
  const cancelToken = axios.CancelToken.source();
  const [searchParams] = useSearchParams();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openModalDelete, setOpenModalDelete] = useState<boolean>(false);
  const [editItem, setEditItem] = useState<IDataStock>();
  const [deleteId, setDeleteId] = useState<number>();

  useLocaleDatePicker(i18n.language);

  function getStockList(params) {
    setLoading(true);
    http.stockHttp
      .getStock(params, cancelToken.token)
      .then((res) => {
        const { data, page, total_pages } = res.data as IDataResponseStock;

        setData(data);
        setPaginationData({ data, page, total_pages });
      })
      .finally(() => {
        setLoading(false);
      });
  }


  useEffect(() => {
    let param = { type_supplier: location.pathname.substring(location.pathname.lastIndexOf('/') + 1) };

    searchParams.forEach((value, key) => {
      param = { ...param, [key]: String(value) };
    });

    getStockList(Object.keys(param).length ? param : { page: urlParams.page ?? 1 });
    setSubmit(false);

  }, [submit, searchParams.get('data_supplier'), searchParams.get('reference'), location.pathname.substring(location.pathname.lastIndexOf('/') + 1)]);

  useEffect(() => {

    http.stockHttp.getSupplierTypes()
      .then((res) => {

        const setSuppliersData = res.data[location.pathname.substring(location.pathname.lastIndexOf('/') + 1)].map((item) => {
          return { value: item.id, label: item.name };
        });

        setFilter({ suppliers: setSuppliersData });
        setLoadingFilter(false);
      }).catch(() => {
        setLoadingFilter(false);
      });
  }, [location.pathname.substring(location.pathname.lastIndexOf('/') + 1)]);


  useEffect(() => {
    if (paginationData) {
      setCurrentPage(paginationData.page);
      setTotalPage(paginationData.total_pages);
    }
  }, [paginationData]);

  const handlePageClick = async (data: number) => {
    setCurrentPage(data);
    setLoading(true);
    const selectPage = data;

    await getStockList({ page: selectPage });
  };

  const openModalEditItem = (item: IDataStock) => {
    setOpenModal(true);
    setEditItem(item);

  };

  const openModalDeleteItem = (item: IDataStock) => {
    setOpenModalDelete(true);
    setDeleteId(item.id);

  };

  return (
    <div className="stock">
      <div className="stock-header">
        <div className="title">
          {t('_customer_stock')}
        </div>
        <div className="btn-new">
          <button onClick={() => setOpenModalCreate(true)}>  {t('_create_stock')}</button>
        </div>
        <div className="filter">
          <div className="title-filter">
            {t('_filter_desc_stock')}
          </div>
          <div className={`btn-filter ${openFilter ? 'active' : ''}`}>
            <button onClick={() => setOpenFilter(true)}>{t('_filter_result')}</button>
          </div>
        </div>

      </div>
      <div className="stock-table">
        <StockManagementTable data={data} handlePageClick={handlePageClick} openModalDeleteItem={openModalDeleteItem} openModalEditItem={openModalEditItem} currentPage={currentPage} totalPage={totalPage} loading={loading} />
      </div>

      <Modal
        openModal={openModalCreate}
        setOpenModal={setOpenModalCreate}
        childrenComponent={<StockCreateNew setOpenModalCreate={setOpenModalCreate} setSubmit={setSubmit} />}
      />

      <Modal
        openModal={openModal}
        setOpenModal={setOpenModal}
        childrenComponent={<StockEditNew setOpenModal={setOpenModal} item={editItem} setSubmit={setSubmit} />}
      />
      <StockFilter
        openFilter={openFilter}
        setOpenFilter={setOpenFilter}
        filter={filter}
        loadingFilter={loadingFilter}
      />

      <Modal
        openModal={openModalDelete}
        setOpenModal={setOpenModalDelete}
        childrenComponent={
          <ModalDelete setOpenModalDelete={setOpenModalDelete} setSubmit={setSubmit} id={deleteId} link={http.stockHttp.deleteStock} />}
      />

    </div >
  );
};

export default StockManagementAdminNew;
