import React, { useRef, useState } from 'react';
import DefaultIcon from 'components/buttons/DefaultIcon/DefaultIcon';

import {
  faUpload,
  faComment,
  faGear, faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import { useOnClickOutside } from 'utils/hooks/useOnClickOutside';
import './AutomotorNavbarGroupBtn.scss';
import { RootState } from 'store/store';
import { setProformaAutomotorActive } from 'store/slices/proformaAutomotor.slice';


type Props = {};

const AutomotorNavbarGroupBtn: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isMobile } = useDeviceDetect();
  const [buttonsAreaExpanded, setButtonsAreaExpanded] = useState<boolean>(false);
  const clickRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const { newMessagesNumber } = useSelector((state: RootState) => state.messenger);
  const totalNotificationsNumber = newMessagesNumber + 0;

  useOnClickOutside(clickRef, () => {
    setButtonsAreaExpanded(false);
  });


  const changeAreaExpanded = () => {
    setButtonsAreaExpanded((prevState) => !prevState);
  };

  const goToMessages = () => {

    navigate('/automotor-online/messages');

  };

  const { t } = useTranslation();

  const handleProformaUpload = () => {
    dispatch(setProformaAutomotorActive(true));
  };

  return (
    isMobile ? (
      <div className="floatable-buttons-wrapper" ref={clickRef}>
        <DefaultIcon onClick={changeAreaExpanded} number={buttonsAreaExpanded ? 0 : totalNotificationsNumber} icon={buttonsAreaExpanded ? faXmark : faGear} />
        <div className={`buttons ${buttonsAreaExpanded ? 'show' : 'hidden'}`}>
          <DefaultIcon onClick={handleProformaUpload} icon={faUpload} />
          <DefaultIcon onClick={goToMessages} number={newMessagesNumber} icon={faComment} />
        </div>

      </div>
    )
      : (
        <React.Fragment>
          <DefaultIcon onClick={handleProformaUpload} icon={faUpload} text={t('_import_csv')} />
          <DefaultIcon onClick={goToMessages} number={newMessagesNumber} icon={faComment} text={t('_messages')} />
        </React.Fragment>

      )
  );
};

AutomotorNavbarGroupBtn.displayName = 'AutomotorNavbarGroupBtn';

export default AutomotorNavbarGroupBtn;
