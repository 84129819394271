import React from 'react';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import GeneralSearchView from './GeneralSearch.view';
import GeneralSearchViewMobile from './Mobile/GeneralSearchMobile.view';

type Props = {};

const GeneralSearch: React.FC<Props> = () => {
  const { isMobile, isTablet } = useDeviceDetect();

  return (
    isMobile || isTablet ? <GeneralSearchViewMobile /> : <GeneralSearchView />
  );
};

GeneralSearch.displayName = 'GeneralSearch';

export default GeneralSearch;
