import { createSlice } from '@reduxjs/toolkit';

type proformaSlice = {
  proformaActive: boolean;
  isLoading: boolean;
};

const initialState: proformaSlice = {
  proformaActive: false,
  isLoading: false,
};

const proformaSlice = createSlice({
  name: 'proforma',
  initialState,
  reducers: {
    setProformaActive(state, { payload }) {
      state.proformaActive = payload;
    },
    setIsLoading(state, { payload }) {
      state.isLoading = payload;
    },

  },

});

export const { setProformaActive, setIsLoading } = proformaSlice.actions;

export default proformaSlice.reducer;
