import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import useHttp from 'utils/hooks/useHttp';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import { notify } from 'utils/marketplace';
import Spinner from 'components/spinner/Spinner';
import TextareaInput from 'components/inputs/TextareaInput/TextareaInput';
import './MecadepotInfo.scss';

type Props = {};
const c = 'mecadepot-info';

const MecadepotInfo: React.FC<Props> = () => {
  const { t } = useTranslation();
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const http = useHttp();

  useEffect(() => {
    setLoading(true);
    http.retailerHttp
      .getShopInformativeMessage()
      .then((res) => setMessage(res.data.message))
      .finally(() => setLoading(false));
  }, []);

  function submitMessage() {
    setLoadingSubmit(true);
    http.retailerHttp
      .postShopInformativeMessage(message)
      .then(() => {
        notify(t('_success'), 'success');
      })
      .catch(() => {
        notify(t('_error'));
      })
      .finally(() => {
        setLoadingSubmit(false);
      });
  }

  return (
    <div className={c}>
      <div className={`${c}__header`}>
        <div className={`${c}__header-title`}>{t('_mecadepot_message')}</div>
      </div>
      <div className={`${c}__content ${loading ? 'is-loading' : ''}`}>
        {loading ? (
          <Spinner class="small" />
        ) : (
          <div className={`${c}__content-description`}>
            <TextareaInput noResize rows={10} value={message} onChange={(e) => setMessage(e.target.value)} />
          </div>
        )}
        <div className={`${c}__content-footer`}>
          {!loading && (
            <div className={`${c}__content-footer-submit`}>
              <DefaultButton text={t('_save')} onClick={submitMessage} isloading={loadingSubmit} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MecadepotInfo;
