import React, { useEffect } from 'react';
import CatalogPageView from './CatalogPage.view';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from 'utils/hooks/redux';
import { useSelector } from 'react-redux';
import { getCurrentCatalog, getIsLoadingCatalogs } from 'store/selectors/catalogs.selector';
import { getCategories, getIsLoadingCategories } from 'store/selectors/categories.selector';
import { setActiveCategory, setShowCategoryDetailsSidebar } from 'store/slices/categories.slice';
import { fetchEbooksData } from 'store/reducers/catalog/ebooks.actions';
import { countNewMessages } from '../../store/actions/messenger.actions';

type Props = {};

const CatalogPage: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const currentCatalog = useSelector(getCurrentCatalog);
  const categories = useSelector(getCategories);
  const isLoadingCategories = useSelector(getIsLoadingCategories);
  const isLoadingCatalogs = useSelector(getIsLoadingCatalogs);
  const { id: catalogId } = useParams();

  useEffect(() => {
    dispatch(fetchEbooksData(catalogId!));
  }, []);

  useEffect(() => {
    if (Number(currentCatalog?.supplier_group_id)) {
      dispatch(countNewMessages(Number(currentCatalog?.supplier_group_id)));
    }
  }, [currentCatalog]);
  // const { i18n } = useTranslation();

  // useEffect(() => {
  //   if (params.id && (!currentCatalog?.id || currentCatalog.id !== params.id)) {
  //     dispatch(fetchCatalogInfo(params.id));
  //     dispatch(fetchCategoriesData(params.id));
  //   }
  // }, [params.id, i18n.language, currentCatalog]);

  const handleCategoryItemClick = (categoryName) => {
    const category = categories.find((category) => category.name === categoryName) || null;

    dispatch(setActiveCategory(category));
    dispatch(setShowCategoryDetailsSidebar(true));
  };

  return (
    <CatalogPageView
      categories={categories}
      onCategoryItemClick={handleCategoryItemClick}
      isLoadingCategories={isLoadingCategories}
      isLoadingCatalogs={isLoadingCatalogs}
      catalogInfo={currentCatalog}
      catalogId={catalogId!}
    />
  );
};

CatalogPage.displayName = 'CatalogPage';

export default CatalogPage;
