import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from 'store/store';
import { setNewClientModal } from 'store/slices/clients.slice';
import NewClient from 'pages/MyClients/NewClient/NewClient';

type Props = {};

const NewClientModal: React.FC<Props> = () => {
  const { newClientModal } = useSelector((state: RootState) => state.clients);
  const [openModal, setOpenModal] = useState<boolean>(newClientModal);
  const dispatch = useDispatch();

  useEffect(() => {
    setOpenModal(newClientModal);
  },[newClientModal]);

  useEffect(() => {
    if(!openModal) {
      dispatch(setNewClientModal(false));
    }
  }, [openModal]);

  function handleNewClientModal(){
    setOpenModal((prevState: boolean) => !prevState);
  }

  return (
    <div>
      {openModal ? (
        <NewClient showNewClient={openModal} handleNewClientModal={handleNewClientModal}/>
      ) : null}
    </div>
  );
};

export default NewClientModal;