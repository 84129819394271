import Spinner from 'components/spinner/Spinner';
import { IOrderItem } from 'models/order.model';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Column, useTable } from 'react-table';
import useHttp from 'utils/hooks/useHttp';
import { getMarketplaceBaseUrl, notify } from 'utils/marketplace';
import './RetailerOrderItemsTable.scss';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import CURRENCY from 'utils/constants/currency';
import { useMobileDetect } from 'utils/hooks/use-mobile-detect';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';

type Props = {
  loading: boolean;
  setLoading: (loading: boolean) => void;
};

const RetailerOrderItemsTable: React.FC<Props> = (props) => {
  const [data, setData] = useState<IOrderItem[]>([]);
  const { order_id } = useParams();
  const http = useHttp();
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const user = JSON.parse(localStorage.getItem('userData')!);
  const currency = user?.currency ? CURRENCY[user?.currency] : '';
  const { isMobile, isTablet } = useDeviceDetect();

  const fetchData = () => {
    setLoading(true);
    http.order.getOrderItems(order_id!)
      .then(res => setData(res.data.data))
      .catch(e => {
        notify(t('_error'));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, [i18n.language]);

  const columns = useMemo(
    (): Column<IOrderItem>[] => [
      {
        Header: '',
        accessor: 'images',
        Cell: (rows) => rows.cell.value ? <img src={rows.cell.value[0]} width={40} height={35}></img> : <img width={40} height={35} src={require('assets/images/placeholder.png')}></img>,
        width: 60
      },
      {
        Header: t('_reference').toLocaleUpperCase(),
        accessor: (rows) => rows.data_supplier.name + ' ' + rows.data_supplier_reference,
        Cell: (rows) => <div className="order-table-ref-cell">{rows.cell.value}</div>,
        width: 200

      },
      {
        Header: t('_category').toLocaleUpperCase(),
        // Cell: (cell) => <div className="order-table-ref-cell">{cell.row.original.subcategory ? cell.row.original.subcategory.name : cell.row.original.description}</div>,
        Cell: (cell) => <div className="order-table-ref-cell">{cell.row.original?.subcategory?.name ?? cell.row.original?.category ?? cell.row.original.description}</div>,
        accessor: 'subcategory'

      },
      {
        Header: t('_quantity').toLocaleUpperCase(),
        accessor: 'order_quantity',

      },
      {
        Header: t('_price_per_unit').toLocaleUpperCase(),
        accessor: 'order_unit_price',
        Cell: (cell) => <div>{cell.row.original.order_unit_price ? cell.row.original.order_unit_price.toFixed(2) : ' - '} {currency}</div>

      },
      {
        Header: t('_price').toLocaleUpperCase(),
        accessor: 'order_total_price',
        Cell: (cell) => <div>{cell.row.original.order_total_price ? cell.row.original.order_total_price.toFixed(2) : ' - '} {currency}</div>
      },
      // {
      //   Header: 'Relicat',
      //   accessor: 'name',
      //   Cell: <div className="order-view-relicats">0 <FontAwesomeIcon className="relicats-red" icon={faCheck}/></div>
      // },
      {
        Header: t('_barcode').toLocaleUpperCase(),
        accessor: 'barcode',
        Cell: (cell) => <div className="order-table-barcode">{cell.row.original.barcode ? <React.Fragment><img width={100} height={40} src={`${getMarketplaceBaseUrl() ?? ''}/${cell.row.original.barcode.image}`} alt="barcode" /><span>{cell.row.original.barcode.code}</span></React.Fragment> : ''}</div>,
      },
    ],
    [],
  );

  const initialState = isMobile ? { hiddenColumns: ['images', 'subcategory', 'order_unit_price', 'barcode'] } : isTablet ? { hiddenColumns: ['subcategory', 'order_unit_price'] } : {};

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
    initialState
  });

  return (
    <div className={`order-table-wrapper-retailer-info ${!rows.length && !loading ? 'centered' : ''}`} >
      {loading ? <Spinner class="order-table-spinner" /> : (
        <React.Fragment>
          {!rows.length ? <div className="no-data">{t('_no_data').toLocaleUpperCase()}</div> : (
            <table {...getTableProps()}>
              <thead className="headings">
                {headerGroups.map((headerGroup, index) => {
                  return (
                    <tr key={headerGroup.headers[index].id} className="row">
                      {headerGroup.headers.map((column) => {
                        const { key, ...restColumn } = column.getHeaderProps({
                          style: { minWidth: column.minWidth, width: column.width, maxWidth: column.maxWidth },
                        });

                        return (
                          <th key={key} {...restColumn} className="heading">
                            {column.render('Header')}
                          </th>
                        );
                      })}
                    </tr>
                  );
                })}
              </thead>
              <tbody {...getTableBodyProps} className="body">
                {rows.map((row) => {
                  prepareRow(row);
                  const { key, ...restRowProps } = row.getRowProps();

                  return (
                    <tr key={key} {...restRowProps} className="row">
                      {row.cells.map((cell) => {
                        const { key, ...restCellProps } = cell.getCellProps();

                        return (
                          <td key={key} {...restCellProps} className="cell">
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
          { }
        </React.Fragment>
      )}
    </div>
  );
};

RetailerOrderItemsTable.displayName = 'RetailerOrderItemsTable';

export default RetailerOrderItemsTable;
