import React from 'react';
import { cssTransition, toast, ToastContainer } from 'react-toastify';

type Props = {};

const DefaultToastContainer: React.FC<Props> = () => {
  const defaultOptions = {
    position: toast.POSITION.TOP_LEFT,
    autoClose: 5000,
    closeButton: false,
    rtl: false,
    hideProgressBar: true,
    pauseOnHover: false,
    newestOnTop: true,
    icon: false,
    draggable: false,
  };

  const fadeInOut = cssTransition({
    enter: 'toastifyFadeIn',
    exit: 'toastifyFadeOut',
    appendPosition: false,
    collapse: true,
    collapseDuration: 600,
  });

  return <ToastContainer {...defaultOptions} transition={fadeInOut} theme="colored" />;
};

DefaultToastContainer.displayName = 'ToastContainer';

export default DefaultToastContainer;
