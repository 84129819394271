import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ProformaMappingTableContainer from './ProformaMappingTableContainer/ProformaMappingTableContainer';
import { IRow, PROFORMA_MAPPING_ROWS } from './schema';
import ProformaMappingFile from './ProformaMappingFile/ProformaMappingFile';
import './ProformaMapping.scss';
import useHttp from 'utils/hooks/useHttp';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import { notify } from 'utils/marketplace';

type Props = {

}

const ProformaMappingBasic:React.FC<Props> = () => {
  const { t } = useTranslation();
  const [rows, setRows] = useState(PROFORMA_MAPPING_ROWS);
  const [fileHeader, setFileHeader] = useState([]);
  const [disableBtn, setDisableBtn] = useState(false);
  const [disableMapping, setDisableMapping] = useState(false);
  const [loadingTableData, setLoadingTableData] = useState(true);
  const [sendingData, setSendingData] = useState(false);
  const [uploadingFile, setUploadingFile] = useState(false);
  const [headerLine, setHeaderLine] = useState(1);
  const http = useHttp();

  const doCommitProformaMap = async () => {
    const proforma = {};


    rows.forEach(({ ID, name, title, number, order }: IRow) => {
      if (disableMapping) {
        proforma[`${ID}_name`] = title;
      } else {
        proforma[`${ID}_name`] = name && name !== 'null' ? name : '';
      }


      if (disableMapping) {
        proforma[`${ID}_number`] = order;
      } else {
        proforma[`${ID}_number`] =  name && name !== 'null' ? number : null;
      }
    });

    proforma['status'] = !disableMapping;
    proforma['header_line'] = headerLine;

    setSendingData(true);
    await http.proformaMapping.commitProforma({...proforma}).finally(() => {
      setSendingData(false);
      notify(t('_success'), 'success');
    });
  };

  const getProforma = async () => {
    const { data } = await http.proformaMapping.getProforma().finally(() => setLoadingTableData(false));

    setDisableMapping(!data.status);

    const rows = [...PROFORMA_MAPPING_ROWS];

    Object.entries(data.columns).forEach(([fieldID, value]) => {
      let newValue = value;

      if (newValue === null) {
        newValue = '';
      }

      const rowID = fieldID
        .replace('_number', '')
        .replace('_name', '');

      const rowIndex = rows.findIndex(row => row.ID === rowID);

      if (rowIndex === -1) {return;}

      if (fieldID.endsWith('_name')) {
        rows[rowIndex].name = String(value);
      }

      if (fieldID.endsWith('_number')) {
        rows[rowIndex].number = +value;
      }
    });
    setRows(rows);
  };


  useEffect(() => {
    getProforma();
  }, []);

  const c = 'proforma-mapping';
  const IS_SAVE_BUTTON_DISABLED = rows.filter(row => row.required).some(row => !row.name) && !disableMapping;

  return (
    <div className="proforma-mapping-container">
      <div className="proforma-mapping-file-container">
        <ProformaMappingFile
          fileHeader={fileHeader}
          uploadingFile={uploadingFile}
          setFileHeader={setFileHeader}
          setUploadingFile={setUploadingFile}
          setRows={setRows}
          rows={rows}
          disableMapping={disableMapping}
          headerLine={headerLine}
          setHeaderLine={setHeaderLine}
        />
      </div>
      <div className="proforma-left">
        <img src={require('assets/images/proforma-mapping-banner.png')} alt="" />
        <div className="proforma-mapping-content">
          <div className={c}>
            <div className="proforma-header">
              <div className={`${c}-save`}>
                <div className="proforma-header-title-container">
                  <h1>{t('_proforma_mapping')}</h1>
                </div>
                <div className="proforma-header-save-btn">
                  <DefaultButton
                    type="button"
                    disabled={IS_SAVE_BUTTON_DISABLED || disableBtn}
                    onClick={doCommitProformaMap}
                    text={t('_save')}
                  />
                </div>
              </div>
            </div>
            <div className="proforma-mapping-body">
              <ProformaMappingTableContainer
                fileHeader={fileHeader}
                setFileHeader={setFileHeader}
                uploadingFile={uploadingFile}
                loadingData={loadingTableData || sendingData}
                setDisableBtn={setDisableBtn}
                rows={rows}
                setRows={setRows}
                disabled={disableMapping}
                setDisableMapping={setDisableMapping}
                disableMapping={disableMapping}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProformaMappingBasic;
