import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { useEffect } from 'react';
import { useAppDispatch } from './redux';
import { countNewMessages } from '../../store/actions/messenger.actions';

const useCountNewMessages = (): void => {
  // const { count } = useSelector((state: RootState) => state.timer);
  // const { newMessagesLoading } = useSelector((state: RootState) => state.messenger);
  const dispatch = useAppDispatch();
  const { currentCatalog } = useSelector((state: RootState) => state.catalogs);
  const { user } = useSelector((state: RootState) => state.userData);

  useEffect(() => {
    // if (!newMessagesLoading && user) {

    if (user && localStorage.getItem('sessionExpired') == null) {
      dispatch(countNewMessages(Number(currentCatalog?.supplier_group_id)));
    }
  }, [user, currentCatalog]);
};

export default useCountNewMessages;
