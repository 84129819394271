import React, { ChangeEvent } from 'react';

import MainSearchInput from 'components/inputs/MainSearchInput/MainSearchInput';
import Pagination from 'components/pagination/Pagination';
import MyClientsTable from 'components/tables/MyClientsTable/MyClientsTable';
import { IClientResponse, IClientFilters } from 'models/client.model';
import NewClient from './NewClient/NewClient';
import { faCheck, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './MyClients.scss';
import { Trans, useTranslation } from 'react-i18next';
import Spinner from 'components/spinner/Spinner';
import Select from 'react-select';

type Props = {
  clients: IClientResponse | null;
  clientsPage: number;
  showNewClient: boolean;
  searchInputValue: string;
  handlePageClick: (pageN: number) => void;
  handleNewClientModal: () => void;
  changeRadioHandler: (value: string) => void;
  changeSearchHandler: (event: ChangeEvent<HTMLInputElement>) => void;
  onFilterSubmit: () => void;
  onChangeFilterType: (value: IClientFilters) => void;
  navigatePendingInvitations: () => void;
  searchFilterType: IClientFilters;
  isLoading: boolean;
  invitationsCount: number;
};

const MyClientsView: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();

  const filterOptionsText = {
    ID: 'Client Id',
    CLIENT_ERP_ID: t('_client_erp_id'),
    // CITY: t('_city'),
    NAME: t('_name'),
    ACTIVE: t('_active_yes_no'),
  };

  const getStatusOptions = () => {
    return Object.keys(IClientFilters).map((key) => ({
      value: IClientFilters[key],
      label: filterOptionsText[key],
    }));
  };

  return (
    <div className="wrapper-myclients">
      <div className="top-content">
        <div className="notification" onClick={props.navigatePendingInvitations}>
          {props.invitationsCount > 0 ? (
            <Trans i18nKey="_pending_invitations" values={{ pendingInvitations: props.invitationsCount.toString() }} />
          ) : (
            t('_all_invitations')
          )}
        </div>
        <h1 className="title">{t('_my_clients')}</h1>
        <div className="new-client" onClick={props.handleNewClientModal}>
          + {t('_new_client')}
        </div>
        {props.showNewClient && (
          <NewClient showNewClient={props.showNewClient} handleNewClientModal={props.handleNewClientModal}></NewClient>
        )}
      </div>
      <div className="search-wrapper">
        <div className="wrapper-title">
          <h1 className="search">{t('_search_for_client').toLocaleUpperCase()}</h1>
        </div>
        <div className="inside-content">
          <div className="client-search">
            <div className="test">
              {props.searchFilterType !== IClientFilters.ACTIVE ? (
                <MainSearchInput
                  size="m"
                  placeholder={t('_client_or_contact_name')}
                  onChange={props.changeSearchHandler}
                  value={props.searchInputValue}
                  withIcon
                  onSubmit={props.onFilterSubmit}
                ></MainSearchInput>
              ) : (
                <div className="radio-container">
                  <div className="radio">
                    <input
                      className="check"
                      type="radio"
                      value="yes"
                      defaultChecked
                      name="active-radio"
                      onChange={() => props.changeRadioHandler('yes')}
                    />
                    <label htmlFor="active-yes">{t('_yes')}</label>
                    <FontAwesomeIcon icon={faCheck} className="check-icon" />
                  </div>
                  <div className="radio">
                    <input
                      className="check"
                      type="radio"
                      value="no"
                      name="active-radio"
                      onChange={() => props.changeRadioHandler('no')}
                    />
                    <label htmlFor="active-no">{t('_no')}</label>
                    <FontAwesomeIcon icon={faCheck} className="check-icon" />
                  </div>
                  <button className="input-search-icon" onClick={() => props.onFilterSubmit()}>
                    <FontAwesomeIcon icon={faSearch} />
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className="sort-wrapper">
            <Select
              className="sort"
              onChange={(option) => props.onChangeFilterType(option?.value as IClientFilters)}
              options={getStatusOptions()}
              defaultValue={{ value: IClientFilters.NAME, label: t('_' + IClientFilters.NAME) }}
              placeholder={t('_status')}
              styles={{
                menu: (provided) => ({ ...provided, zIndex: 50 }),
              }}
            />
          </div>
        </div>
      </div>
      {!props.isLoading ? (
        <div>
          <MyClientsTable />
          <Pagination
            handlePageClick={props.handlePageClick}
            pageCount={props.clients?.total_pages || 0}
            forcePage={props.clients?.page || 0}
          ></Pagination>
        </div>
      ) : (
        <Spinner class="content-spinner" />
      )}
    </div>
  );
};

MyClientsView.displayName = 'MyClientsView';

export default MyClientsView;
