import React from 'react';

import reliable from 'assets/images/e-reliable.png';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import TextInput from 'components/inputs/TextInput/TextInput';
import { Trans, useTranslation } from 'react-i18next';
import { FieldErrors, FieldValues, UseFormHandleSubmit, UseFormRegister } from 'react-hook-form';
import DefaultToastContainer from 'components/toast-container/DefaultToastContainer';
import Spinner from 'components/spinner/Spinner';
import PasswordInput from 'components/inputs/PasswordInput/PasswordInput';
import { NavLink } from 'react-router-dom';
import './Login.scss';

type Props = {
  onSubmit: () => void;
  handleSubmit: UseFormHandleSubmit<FieldValues>;
  register: UseFormRegister<FieldValues>;
  errors: FieldErrors<ILoginForm>;
  isLoading: boolean;
};
const c = 'retailer-login';

const LoginView: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <DefaultToastContainer />
      <div className={c}>
        <div className={`${c}__card`}>
          <div className={`${c}__card-header`}>
            <div className={`${c}__card-header-logo`}>
              <img src={reliable} height="42" width="365" alt="e-reliable" />
            </div>
            <div className={`${c}__card-header-description`}>
              <Trans
                i18nKey="_app_description"
                components={{ b: <b />, br: <br /> }}
              />
            </div>
          </div>
          <div className={`${c}__card-body`}>
            {props.isLoading && <Spinner class="Spinner" />}
            <div className={`${c}__card-body-title`}>{t('_log_in')}</div>
            <form
              //  autoComplete="off"
              onSubmit={props.handleSubmit(props.onSubmit)}
            >
              <div className={`${c}__card-body-input_container`}>
                <div className={`${c}__card-body-input_container-input_item ${props.errors.email && 'error'}`}>
                  <label htmlFor="#email">{t('_identifier')}</label>
                  <TextInput
                    placeholder="garagiste@mail.com"
                    {...props.register('email', {
                      required: true,
                      pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: t('_email_invalid') },
                    })}
                  // autoComplete="off"
                  />
                  {props.errors.email?.type == 'pattern' && <p className="error-message">{t('_email_invalid')}</p>}
                  {props.errors.email?.type == 'required' && <p className="error-message">{t('_email_required')}</p>}
                </div>

                <div className={`${c}__card-body-input_container-input_item ${props.errors.password && 'error'}`}>
                  <label htmlFor="#password">{t('_password')}</label>
                  <PasswordInput autoComplete="new-password" placeholder="* * * * * *" name="password" id="password" register={props.register} required></PasswordInput>
                  {props.errors.password?.type == 'required' && <p className="error-message">{t('_password_required')}</p>}
                </div>
                <div className={`${c}__forgot_password`}>
                  <NavLink to="/forgot-password">
                    <React.Fragment>{t('_forgot_password')}?</React.Fragment>
                  </NavLink>
                </div>
                <DefaultButton
                  text={
                    <span>
                      {t('_confirm')}
                    </span>
                  }
                  disabled={props.isLoading}
                  type="submit"
                />
              </div>
            </form>
          </div>
        </div>
      </div >
    </React.Fragment >
  );
};

LoginView.displayName = 'LoginView';

export type ILoginForm = {
  email: string;
  password: string;
};

export default LoginView;
