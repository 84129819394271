import React from 'react';
import { CellProps } from 'react-table';
import { CartTableItem } from '../../CartTable.model';
import CodeCellView from './CodeCell.view';

type Props = CellProps<CartTableItem> & {};

const CodeCell: React.FC<Props> = (props: Props) => {
  return <CodeCellView src={props.value.image} code={props.value.code}></CodeCellView>;
};

CodeCell.displayName = 'CodeCell';

export default CodeCell;
