import { faPen, faRemove } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Disponibility from 'components/disponibility/Disponibility';
import Spinner from 'components/spinner/Spinner';
import { IBattery } from 'interfaces/battery';

import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import './ItemEshopBattery.scss';

import CheckboxCellTab from 'components/CheckboxCellTab/CheckboxCellTab';
import CURRENCY from 'utils/constants/currency';
import { getBackendAssetsBasePath } from 'utils/marketplace';

type Props = {
  data: IBattery[],
  loading: boolean,
  openModalBattery: (id: number) => void
  deleteItem: (id: number) => void;
  register;
  handleOnChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleOnChangeTopOffer: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleOnChangeTopSales: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const ItemEshopBatteryView: React.FC<Props> = (props) => {
  const user = JSON.parse(localStorage.getItem('userData')!);
  const { t } = useTranslation();

  function getPrice(item) {
    if (item.price && typeof item.price === 'object') {
      if (item.price?.price) {
        return item.price.price;
      } else {
        return null;
      }
    }

    return item.price;
  }

  function getAvailablityTitle(item) {


    if (item.available && typeof item.available === 'object') {
      const newLimit: { uv?: string, warehouse_main } = { ...item.available };

      delete newLimit.uv;

      return Object.values(newLimit).reduce((acc, item) => Number(acc) + Number(item?.quantity), 0);
    }

    return item.available;
  }

  return (
    <div className="item-container-shop-battery">
      {
        props.loading ? <Spinner /> :

          props.data.length ?
            props.data.map((item, index) => {
              return (
                <div className="item-shop" key={index}>
                  <div className="header">
                    <div className="checkboxCellTab">
                      <CheckboxCellTab handleOnChange={props.handleOnChange} id={item.battery_id} register={props.register}></CheckboxCellTab>
                    </div>
                    <div className="img">
                      <img
                        src={item.image || item.data_supplier_logo || getBackendAssetsBasePath() + '/images/default/battery.jpg'}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.src = '/images/car-part-placeholder.png';
                        }}
                      />
                    </div>
                    <div className="content">
                      <div className="title">{item.data_supplier.name}</div>
                      <div className="reference">{item.tags.category} {item.data_supplier_reference}</div>
                    </div>
                  </div>

                  <div className="body">
                    <div className="description-short"> {item.tags.ah} AH {item.tags.amp} Amp {item.tags.size}  {item.tags.type_battery} </div>
                    <div className="description">
                      {item.description}
                    </div>

                    <div className="top-offer-checkbox-container">
                      <label htmlFor={`top_offer_${item.battery_id}`}>{t('_top_offer')}</label>
                      <input
                        type="checkbox"
                        id={`top_offer_${item.battery_id}`}
                        defaultChecked={item.top_offer}
                        value={item.battery_id}
                        {...props.register('top_offer')}
                        onChange={props.handleOnChangeTopOffer}
                      />
                    </div>
                    {user?.hasTopSales ?
                      <div className="top-offer-checkbox-container">
                        <label htmlFor={`top_sales_${item.battery_id}`}>{t('_top_sales')}</label>
                        <input
                          type="checkbox"
                          id={`top_sales_${item.battery_id}`}
                          defaultChecked={item.top_sales}
                          value={item.battery_id}
                          {...props.register('top_sales')}
                          onChange={props.handleOnChangeTopSales}
                        />
                      </div>
                      : null}
                  </div>

                  <div className="footer">
                    {/* <div className="quantity">
                      <Disponibility value={0} limit={item.available} />
                      <div className="disponibility-porduct"> {t('_up_to')}   :  <span className="disponibility-count" >{item.available} {t('_products')}</span>
                      </div>
                    </div> */}
                    <div className="quantity">

                      <Disponibility value={0} limit={getAvailablityTitle(item)} />
                      <div className="disponibility-porduct">
                        {' '}
                        {t('_up_to')} :{' '}
                        <span className="disponibility-count">
                          {getAvailablityTitle(item)} {t('_products')}
                        </span>
                      </div>

                    </div>
                    <div className="cart">
                      <div className="cart-price">{getPrice(item) ? `${CURRENCY[user!.currency]}${getPrice(item)}` : '-'} </div>
                      <div className="cart-button">
                        <button className="button-edit" onClick={() => props.openModalBattery(item.battery_id)}>
                          <FontAwesomeIcon icon={faPen} className="icon-edit" />
                        </button>
                        <button className="button-delete" onClick={() => props.deleteItem(item.battery_id)}>
                          <FontAwesomeIcon icon={faRemove} className="icon-delete" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }) :
            <div className="no-data">
              {t('_no_data')}
            </div>

      }
    </div>
  );
};

ItemEshopBatteryView.displayName = 'ItemEshopBatteryView';

export default ItemEshopBatteryView;
