import Spinner from 'components/spinner/Spinner';
import { IAutomotorTariff } from 'models/automotor-tariffs.model';
import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useHttp from 'utils/hooks/useHttp';
import './Card.scss';
import { notify } from 'utils/marketplace';
import Modal from 'components/modal/Modal';
import ModalFile from './ModalFile/ModalFile';
import ModalFileNotFond from './ModalFileNotFond/ModalFileNotFond';

type Props = {
  title?: string;
  data?: IAutomotorTariff[];
  loadingData?: boolean;
  type?: string;
};
const Card: React.FC<Props> = (props) => {
  const http = useHttp();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [supplierName, setSupplierName] = useState('');
  const userInfo = JSON.parse(localStorage.getItem('userData')!);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openModalNotFile, setOpenModalNotFile] = useState(false);

  function download(item) {


    if (userInfo?.is_using_tariffs_tables_v2) {
      if (!item.has_price) {
        setOpenModalNotFile(true);

        return;
      }

      setLoading(true);

      setSupplierName(item.supplier_name);
      setOpenModal(true);

      http.automotorTariff.getFileV2({ codfou: item.codfou, type: props.type }).then((res) => {
        const element = document.createElement('a');

        const file = new Blob([res.data], { type: res.data.type });

        element.href = URL.createObjectURL(file);
        element.download = item.supplier_name + `_${moment().format('MM-DD-YY')}`;
        element.click();
      })
        .catch(() => {
          window.scrollTo({ top: 0, behavior: 'smooth' });
          notify(t('_please_contact_seller'));
          setOpenModal(false);
        })
        .finally(() => {
          setLoading(false);
          setOpenModal(false);
        });

      return;
    }

    setLoading(true);
    setSupplierName(item.supplier_name);
    http.automotorTariff.getFile(item.file_id, { name: item.supplier_name }).then((res) => {
      const element = document.createElement('a');

      const file = new Blob([res.data], { type: res.data.type });

      element.href = URL.createObjectURL(file);
      element.download = item.supplier_name + `_${moment().format('MM-DD-YY')}`;
      element.click();
    })
      .catch(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        notify(t('_please_contact_seller'));
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <div className="automotor-tariff-card">
      <div className="automotor-tariff-title">{props.title}</div>
      {props.loadingData ? (
        <Spinner class="small" />
      ) : (
        <React.Fragment>
          { }
          {props.data?.length && !props.data!.length ? (
            <div className="no-data">{t('_no_data').toLocaleUpperCase()}</div>
          ) :
            props.data?.length ?
              <div className="automotor-tariff-container">
                {
                  props.data.map((item, key) => {
                    return (
                      <div
                        onClick={() => download(item)}
                        className="automotor-tariff-logo-container"
                        key={key}
                      >
                        {loading && item.supplier_name === supplierName ? (
                          <Spinner class="small automotor-tariff-spinner" />
                        ) : (
                          <div className="tariff-logo-item">
                            {/* {item.supplier_logo ? (
                            <img className="automotor-tariff-logo" src={item.supplier_logo} alt={item.name} />
                          ) : (
                            <img
                              className="automotor-tariff-logo"
                              src={require('assets/images/car-part-placeholder.png')}
                              alt={item.name}
                            />
                        )} */}

                            <img className="automotor-tariff-logo" src={item.supplier_logo} alt={item.supplier_logo ? item.supplier_logo.substring(item.supplier_name.lastIndexOf('/')) : '/images/car-part-placeholder.png'} onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src = '/images/car-part-placeholder.png';
                            }}
                            />
                            <div className="automotor-tariff-name">{item.supplier_name}</div>
                          </div>
                        )}
                      </div>
                    );
                  })
                }
              </div>
              : (
                <div className="no-data">{t('_no_data')}</div>
              )
          }
        </React.Fragment>
      )}
      <Modal
        openModal={openModal}
        setOpenModal={setOpenModal}
        shouldCloseOnOverlayClick={false}
        childrenComponent={
          <ModalFile />
        }
      />
      <Modal
        openModal={openModalNotFile}
        setOpenModal={setOpenModalNotFile}
        shouldCloseOnOverlayClick={false}
        childrenComponent={
          <ModalFileNotFond setModalNotFile={setOpenModalNotFile} />
        }
      />
    </div>

  );
};

Card.displayName = 'Card';

export default Card;
