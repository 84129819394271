import React, { useEffect, useState } from 'react';
import { useNavigate, createSearchParams } from 'react-router-dom';
import { getCarDescription } from 'utils/getCarDescription';
import { ICars } from 'utils/hooks/http/search-by-vin.http';
import { useAppDispatch } from 'utils/hooks/redux';
import { setCarInfo, setCarSelected } from 'store/reducers/carSelectedSlices';

import './DropDownContent.scss';
import { useTranslation } from 'react-i18next';
import {IPlateNumberData} from '../../../../utils/hooks/http/search-by-plate.http';
import useHttp from '../../../../utils/hooks/useHttp';

type Props = {
  registration_plate: string;
  plateData: IPlateNumberData | null;
  setIsOpen: (boolean) => void;
  setOpenModal?: (e: boolean) => void;
  link?:string;
};

const DropDownContent: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [sugestion, setSugestion] = useState<ICars[] | null>();
  const {searchByPlate} = useHttp();

  const checkCarSelected = (): null | ICars=> {
    if(!props.plateData) {
      return null;
    }

    let selectedCars: [] | ICars[] = [];
    let selectedCar: null | ICars = null;

    if(props.plateData?.cars) {
      selectedCars =  props.plateData?.cars.filter((car) => car.is_selected);

      if(selectedCars && selectedCars.length > 0) {
        selectedCar = selectedCars[0];
      }
    }

    return selectedCar;
  };

  const [selectedSuggestion, setSelectedSuggestion] = useState<ICars | null>(checkCarSelected());
  const [viewAllModels, setViewAllModels] = useState(selectedSuggestion === null);

  const [, setVehicleType] = useState<string>('');

  const plateHandler = (car: ICars) => {
    if(props.link) {
      navigate({
        pathname: `/reliable/rmi/${car.id}${props.link}`,
        search: createSearchParams({
          source: car.source,
          vehicle_identifier: car.vehicle_identifier ?? '002',
          registration_plate: props.registration_plate
        }).toString(),
      });
    }else{
      navigate({
        pathname: `/reliable/rmi/${car.id}/catalogs/parts`,
        search: createSearchParams({
          source: car.source,
          vehicle_identifier: car.vehicle_identifier ?? '002',
          registration_plate: props.registration_plate
        }).toString(),
      });
    }

    
    if(props.setOpenModal !=undefined){
      props.setOpenModal(false);
    }
   
  };

  const plateUpdateHandler = (car: ICars) => {
    if(car !== selectedSuggestion) {
      searchByPlate.updateVinCar({registration_plate: props.registration_plate, ktypnr: String(car.id)})
        .catch((e) => {
          console.log(e);
        });
    }

    setSelectedSuggestion(car);

    plateHandler(car);
  };

  useEffect(() => {
    switch (true) {
      case !!props.plateData?.cars:
        setVehicleType('cars');
        setSugestion(props.plateData?.cars);

        break;
      default:
        setSugestion(null);
    }
  }, [props.plateData]);

  return (

    <div className="suggestion-list-container">
      {
        selectedSuggestion && (
          <li className="suggestion-list-item selected" onClick={() => plateHandler(selectedSuggestion)}>
            <div className="suggestion-item-title">
              <span>{getCarDescription(selectedSuggestion)}</span>
            </div>
          </li>
        )
      }
      {
        viewAllModels && (
          sugestion && sugestion.length ? (
            sugestion.map((item, index) => {
              if(item !== selectedSuggestion) {
                return (
                  <li key={index} className="suggestion-list-item" onClick={() => plateUpdateHandler(item)}>
                    <div className="suggestion-item-title">
                      <span>{getCarDescription(item)}</span>
                    </div>
                  </li>
                );
              }
            })
          ) : (
            <div className="no-data">{t('_no_data').toLocaleUpperCase()}</div>
          )
        )
      }
      {
        !viewAllModels && sugestion && sugestion.length > 1 &&
            (
              <button className="change-model-btn" type="button" onClick={() => setViewAllModels(true)}>Select another car variant</button>
            )
      }
    </div>
  );
};

DropDownContent.displayName = 'DropDownContent';

export default DropDownContent;
