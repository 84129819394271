import React, { useEffect, useState } from 'react';
import {
  ICarPartData,
  IEquivalenceCarsResponse,
  IEquivalencesV2Response,
  IEquivalenceTrucksResponse
} from '../../../models/car-parts.model';
import useHttp from 'utils/hooks/useHttp';
import { useParams, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getCurrentMarketplace } from 'utils/marketplace';
import EquivalenceOEMV2 from './EquivalenceOEMV2/EquivalenceOEMV2';
import EquivalenceTitle from '../Equivalence/EquivalenceTitle/EquivalenceTitle';
import VehicleConcern from '../Equivalence/VehicleConcern/VehicleConcern';
import EquivalenceIAMV2 from './EquivalenceIAMV2/EquivalenceIAMV2';
import './EquivalenceV2.scss';
import Tab from './Tabs/Tab';
import Tabs from './Tabs/Tabs';

type Props = {
  carPart: ICarPartData;
  paramsModal?
};

const EquivalenceV2: React.FC<Props> = (props) => {

  const [equivalenceResponseV2, setEquivalenceResponseV2] = useState<IEquivalencesV2Response>();
  const [loading, setLoading] = useState<boolean>(true);
  const http = useHttp();
  const [searchParams] = useSearchParams();
  const { id } = useParams<'id'>();
  const { t } = useTranslation();
  const [equivalenceCarsResponse, setEquivalenceCarsResponse] = useState<IEquivalenceCarsResponse>();
  const [equivalenceCarsFilters, setEquivalenceCarsFilters] = useState<IEquivalenceCarsResponse>();
  const [equivalenceTrucksResponse, setEquivalenceTrucksResponse] = useState<IEquivalenceTrucksResponse>();
  const currentMarketplace = getCurrentMarketplace();
  const [loadingCars, setLoadingCars] = useState<boolean>(false);
  const [loadingTrucks, setLoadingTrucks] = useState<boolean>(false);
  let params;

  if (props.paramsModal) {
    params = {
      data_supplier_id: props.paramsModal?.data_supplier_id,
      model_id: props.paramsModal?.model_id,
      provider_id: props.paramsModal?.provider_id,
      is_platform_admin: props.paramsModal?.is_platform_admin,
      catalog_id: props.paramsModal?.catalog_id
    };
  } else {
    params = {
      data_supplier_id: String(searchParams.get('data_supplier_id')),
      model_id: String(searchParams.get('car_model_id')),
      provider_id: String(searchParams.get('provider_id')),
      is_platform_admin: String(searchParams.get('is_platform_admin')),
      catalog_id: String(searchParams.get('catalog_id'))
    };

  }


  if (!searchParams.get('catalog_id') || !props.paramsModal?.catalog_id) {
    params = { ...params, ...{ genartnr: props.carPart?.subcategory?.id } };
  }


  const validParams = Object.entries(params).reduce((acc, [key, value]) => {
    if (!value || value === 'null') {
      return acc;
    }

    acc[key] = value;

    return acc;
  }, {});

  useEffect(() => {
    if (!equivalenceResponseV2) {
      getEquivalenceResponse();
    }
  }, []);


  useEffect(() => {
    if (searchParams.get('car_model_id')) {
      getEquivalenceCarsResponse();
      //  getEquivalenceTrucksResponse();
    }
  }, [searchParams.get('car_model_id')]);

  useEffect(() => {
    getEquivalenceCarsFilters();
    getEquivalenceTrucksResponse();
  }, []);

  const getEquivalenceTrucksResponse = async () => {
    try {

      setLoadingTrucks(true);

      let validParamsNew;

      if (props.paramsModal) {
        if (props.paramsModal?.catalog_id) {
          validParamsNew = { ...validParams, ...{ catalog_id: props.paramsModal?.catalog_id } };
        } else {
          validParamsNew = validParams;
        }

        if (props.paramsModal?.catalog_id && props.paramsModal?.catalog_id !== 'T') {
          const response = await http.carParts.getCarPartTrucksEquivalences(props.paramsModal?.reference, validParamsNew);

          setEquivalenceTrucksResponse(response);
        } else {
          const response = await http.carParts.getReliableCarPartTrucksEquivalences(props.paramsModal?.reference, validParamsNew);

          setEquivalenceTrucksResponse(response);
        }
      } else {


        if (searchParams.get('catalog_id')) {
          validParamsNew = { ...validParams, ...{ catalog_id: searchParams.get('catalog_id') } };
        } else {
          validParamsNew = validParams;
        }

        if (searchParams.get('catalog_id') && searchParams.get('catalog_id') !== 'T') {
          const response = await http.carParts.getCarPartTrucksEquivalences(searchParams.get('reference') ? String(searchParams.get('reference')) : String(searchParams.get('data_supplier_reference')), validParamsNew);

          setEquivalenceTrucksResponse(response);
        } else {
          const response = await http.carParts.getReliableCarPartTrucksEquivalences(String(id), validParamsNew);

          setEquivalenceTrucksResponse(response);
        }
      }


    } catch (err) {
      console.error(err);
    } finally {
      setLoadingTrucks(false);
    }
  };

  const getEquivalenceCarsResponse = async () => {
    try {

      setLoadingCars(true);


      if (props.paramsModal) {
        if (props.paramsModal?.catalog_id && props.paramsModal?.catalog_id !== 'T') {
          const response = await http.carParts.getReliableCarPartCarsEquivalences(props.paramsModal?.reference, validParams);

          setEquivalenceCarsResponse(response);
        } else {
          const response = await http.carParts.getReliableCarPartCarsEquivalences(props.paramsModal?.reference, validParams);

          setEquivalenceCarsResponse(response);
        }
      } else {
        if (searchParams.get('catalog_id') && searchParams.get('catalog_id') !== 'T') {
          const response = await http.carParts.getReliableCarPartCarsEquivalences(searchParams.get('reference') ? String(searchParams.get('reference')) : String(searchParams.get('data_supplier_reference')), validParams);

          setEquivalenceCarsResponse(response);
        } else {
          const response = await http.carParts.getReliableCarPartCarsEquivalences(String(id), validParams);

          setEquivalenceCarsResponse(response);
        }
      }


    } catch (err) {
      console.error(err);
    } finally {
      setLoadingCars(false);
    }
  };

  const getEquivalenceCarsFilters = async () => {

    try {
      if (props.paramsModal) {
        if (props.paramsModal?.catalog_id && props.paramsModal?.catalog_id !== 'T') {
          const filterResponse = await http.carParts.getCarPartEquivalencesFilter(props.paramsModal?.reference, validParams);

          setEquivalenceCarsFilters(filterResponse);
        } else {
          const filterResponse = await http.carParts.getCarPartEquivalencesFilter(props.paramsModal?.reference, validParams);

          setEquivalenceCarsFilters(filterResponse);
        }

      } else {
        if (searchParams.get('catalog_id') && searchParams.get('catalog_id') !== 'T') {
          const filterResponse = await http.carParts.getCarPartEquivalencesFilter(searchParams.get('reference') ? String(searchParams.get('reference')) : String(searchParams.get('data_supplier_reference')), validParams);

          setEquivalenceCarsFilters(filterResponse);
        } else {
          const filterResponse = await http.carParts.getCarPartEquivalencesFilter(String(id), validParams);

          setEquivalenceCarsFilters(filterResponse);
        }
      }


    } catch (err) {
      console.error(err);
    }
  };

  const getEquivalenceResponse = async () => {

    try {
      setLoading(true);
      let response;

      if (props.paramsModal) {

        if (props.paramsModal?.catalog_id && props.paramsModal?.catalog_id !== 'T') {

          currentMarketplace == 'retailer' ?
            response = await http.carParts.getRetailerCarPartEquivalences(props.paramsModal?.reference, validParams)
            :
            response = await http.carParts.getCarPartEquivalencesV3(props.paramsModal?.reference, validParams);

        } else {

          currentMarketplace == 'retailer' ?
            response = await http.carParts.getRetailerCarPartEquivalences(props.paramsModal?.reference, validParams)
            :
            response = await http.carParts.getCarPartEquivalencesV3(props.paramsModal?.reference, validParams);


        }

      } else {
        if (searchParams.get('catalog_id') && searchParams.get('catalog_id') !== 'T') {

          currentMarketplace == 'retailer' ?
            response = await http.carParts.getRetailerCarPartEquivalences(searchParams.get('reference') ? String(searchParams.get('reference')) : String(searchParams.get('data_supplier_reference')), validParams)
            :
            response = await http.carParts.getCarPartEquivalencesV3(searchParams.get('reference') ? String(searchParams.get('reference')) : String(searchParams.get('data_supplier_reference')), validParams);

        } else {

          currentMarketplace == 'retailer' ?
            response = await http.carParts.getRetailerCarPartEquivalences(String(id), validParams)
            :
            response = await http.carParts.getCarPartEquivalencesV3(String(id), validParams);


        }
      }


      setEquivalenceResponseV2(response);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };


  return (
    <div className="equivalence-cars-part">
      <div className="tabs-container">
        <Tabs disabled={loading}>
          <Tab title={<EquivalenceTitle title={t('_equivalence_oem')} iconType="parts" />}>
            <EquivalenceOEMV2 loading={loading} equivalencesResponse={equivalenceResponseV2} />
          </Tab>
          <Tab title={<EquivalenceTitle title={t('_equivalence_iam')} iconType="parts" />}>
            <EquivalenceIAMV2 loading={loading} equivalencesResponse={equivalenceResponseV2} />
          </Tab>
          <Tab title={<EquivalenceTitle title={t('_vehicule_concern')} iconType="cars" />}>
            <VehicleConcern loadingCars={loadingCars} loadingTrucks={loadingTrucks} equivalenceCarsFilters={equivalenceCarsFilters} equivalenceCarsResponse={equivalenceCarsResponse} equivalenceTrucksResponse={equivalenceTrucksResponse} />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

EquivalenceV2.displayName = 'EquivalenceV2';

export default EquivalenceV2;

