import React from 'react';

import './Icon.scss';
import icons from '../../assets/Icons';

type Props = {
  name: keyof typeof icons;
  className?: string;
  onClick?: () => void;
  ios?: boolean;
};

const IconView: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  return (
    <svg
      width={props.ios ? '2rem' : '100%'}
      height={props.ios ? '2rem' : '100%'}
      onClick={props.onClick}
      version="1.1"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={'0 0 ' + icons[props.name][0]}
      dangerouslySetInnerHTML={{ __html: icons[props.name][1] }
      }
      className={`wrapper ${props.className || ''}`.trim()}
    ></svg >
  );
};

IconView.displayName = 'IconView';
IconView.defaultProps = {};

export default IconView;
