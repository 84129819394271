import CatalogPage from 'pages/CatalogPage/CatalogPage';
import Login from 'pages/Retailer/Login/Login';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import useCatalogVehicleRoutes from 'utils/hooks/use-catalog-vehicle-routes';


import AccountLayout from '../../automotor/layouts/account-layout/AccountLayout';
import Profil from '../../../pages/Profil/Profil';
import Parametres from '../../../pages/Parametres/Parametres';
import History from '../../../pages/History/History';
import Favorite from '../../../pages/Favorite/Favorite';

import RetailerMessagesPage from 'applications/retailer/views/RetailerMessagesPage/RetailerMessagesPage';

import Oils from '../views/Oils/Oils';
import TyresCatalog from '../views/Tyres/TyresCatalog';

import RetailerOrderLayout from '../layouts/RetailerOrderLayout/RetailerOrderLayout';
import RetailerOrders from '../views/RetailerOrders/RetailrOrders';
import RetailerOrderView from '../views/RetailerOrders/RetailerOrderView/RetailerOrderView';
import OilsView from '../views/OilsView/OilsView';
import TyreView from '../views/TyreView/TyreView';
import useCommonRoutes from 'utils/hooks/use-common-routes';
import GenartCarParts from 'pages/GenartCarParts/GenartCarParts';
import Cart from '../views/Cart/Cart';
import CarPartView from 'components/partsView/CarPartView';
import Consumables from '../views/Consumables/Consumables';
import ConsumablesView from '../views/ConsumablesView/ConsumablesView';
import BatteriesView from '../views/BatteriesView/BatteriesView';
import Batteries from '../views/Batteries/Batteries';

import CarPartViewMobile from 'components/partsView/Mobile/CarPartViewMobile';
import Ebook from 'components/Ebook';
import BatteriesViewMobile from '../views/BatteriesView/Mobile/BatteriesViewMobile';
import ConsumablesViewMobile from '../views/ConsumablesView/mobile/ConsumablesViewMobile';
import OilsViewMobile from '../views/OilsView/Mobile/OilViewMobile';
import TyreViewMobile from '../views/TyreView/Mobile/TyreViewMobile';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import SessionExpired from 'applications/punchout/pages/SessionExpired/SessionExpired';
import CatalogOilsView from '../views/CatalogOils/CatalogOilsView';
import CatalogConsumablesView from '../views/CatalogConsumables/CatalogConsumablesView';
import CatalogBatteryView from '../views/CatalogBatteries/CatalogBatteryView';
import CatalogTyresView from '../views/CatalogTyres/CatalogTyresView';

import PartsListV2 from 'components/PartsListV2/PartsListV2';
import HomeMecadepot from 'views/HomeMecadepot/HomeMecadepot';
import MainLayoutMecadepot from '../layouts/main-layout-mecadepot/MainLayoutMecadepot';

const DefaultRouteRetailerMecadepot: React.FC = () => {
  const catalogVehicleRoutes = useCatalogVehicleRoutes();
  const commonRoutes = useCommonRoutes();
  const { isMobile, isTablet } = useDeviceDetect();

  return (
    <Routes>
      <Route path="/session-expired" element={<SessionExpired />} />
      <Route path="/flipbook" element={<Ebook />} />
      <Route element={<MainLayoutMecadepot />}>
        {catalogVehicleRoutes}
        <Route path="/catalogs">
          <Route path="consumables" element={<CatalogConsumablesView />} />
          <Route path="batteries" element={<CatalogBatteryView />} />
          <Route path="oils" element={<CatalogOilsView />} />
          <Route path="tyres" element={<CatalogTyresView />} />
        </Route>
        <Route path="/" element={<HomeMecadepot />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/catalog" element={<CatalogPage />} />
        {/* <Route path="/rapid-search" element={<RapidSearch />} /> */}
        {/* <Route path="/news" element={<NewsPage />} />
  <Route path="/news/:id" element={<NewsView />} /> */}
        <Route path="/oils" element={<Oils />} />
        <Route path="/oils/view/:id" element={isMobile || isTablet ? <OilsViewMobile /> : <OilsView />} />
        <Route path="/consumables" element={<Consumables />} />
        <Route path="/consumables/view/:id" element={isMobile || isTablet ? <ConsumablesViewMobile /> : <ConsumablesView />} />
        <Route path="/tyres/view/:id" element={isMobile || isTablet ? <TyreViewMobile /> : <TyreView />} />
        <Route path="/tyres" element={<TyresCatalog />} />
        <Route path="/reliable/:vehicle_id/car-parts-by-genart" element={<GenartCarParts />} />
        {/* <Route path="/car-parts" element={<PartsList />} /> */}
        <Route path="/car-parts" element={<PartsListV2 />} />
        <Route path="/car-parts/view/:id" element={isMobile || isTablet ? <CarPartViewMobile /> : <CarPartView />} />
        <Route path="/batteries" element={<Batteries />} />
        <Route path="/batteries/view/:id" element={isMobile || isTablet ? <BatteriesViewMobile /> : <BatteriesView />} />
        <Route element={<AccountLayout />}>
          <Route path="/my-profile" element={<Profil />} />
          <Route path="/messages" element={<RetailerMessagesPage />} />
          <Route path="/settings" element={<Parametres />} />
          <Route path="/history" element={<History />} />
          <Route path="/favorite" element={<Favorite />} />
        </Route>
        <Route path="orders" element={<RetailerOrderLayout />}>
          <Route path="" element={<RetailerOrders />} />
          <Route path=":order_id" element={<RetailerOrderView />} />
        </Route>
      </Route>
      <Route path="/login" element={<Login />}></Route>
      {commonRoutes}
    </Routes>
  );
};

DefaultRouteRetailerMecadepot.displayName = 'DefaultRouteRetailerMecadepot';

export default DefaultRouteRetailerMecadepot;
