import React, { useEffect, useState } from 'react';
import ModalDelete from 'components/CatalogNaf/ModalDelete/ModalDelete';
import Modal from 'components/modal/Modal';
import Pagination from 'components/pagination/Pagination';

import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import useHttp from 'utils/hooks/useHttp';
import SupplierCreate from './ModalCreate/SupplierCreate';
import { INonTecdocPartSupplier } from 'interfaces/nonTecdocCatalog';
import NonTecdocSupplierPart from 'components/CatalogNaf/NonTecdocSupplierPart/NonTecdocSupplierPart';
import NonTecdocSupplierFilter from 'components/filter/NonTecdocSupplierFilter/NonTecdocSupplierFilter';
import SupplierEdit from './ModalEdit/SupplierEdit';
import './NonTecdocPartsSuppliers.scss';

const NonTecdocPartsSuppliers: React.FC = () => {
  const http = useHttp();
  const [searchParams] = useSearchParams();
  const [data ,setData] = useState<INonTecdocPartSupplier[]>([]);
  const [loading,setLoading] = useState<boolean>(true);
  const [totalPage,setTotalPage] = useState<number>(1);
  const [forcePage] = useState<number>(searchParams.get('page') ?  Number(searchParams.get('page')) : 1);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openModalCreate, setOpenModalCreate] = useState<boolean>(false);
  const [submit, setSubmit] = useState<boolean>(false);
  const [supplierId ,setSupplierId] = useState<number>();
  const [openFilter, setOpenFilter] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState<boolean>(false);
  let   isMounted = true;

  const {t} = useTranslation();

  function fetchSuppliers(page) {
    setLoading(true);
     
    let param = {};

    searchParams.forEach((value, key) => {
    
      param = {... param, [key]:String(value)};
        
    });

    http.nonTecDocCatalog.getNonTecdocSuppliers({...{page} ,...param }).then((resp) => {
      setData(resp.data.data);
      setTotalPage(resp.data.total_pages);
      setLoading(false);
    }).catch(() => {
      console.log('cancelToken.token!!');
      setLoading(false);
    });
    

  }

  useEffect(() => {
    if(isMounted) {
      setLoading(true);
      fetchSuppliers(searchParams.get('page') ?  Number(searchParams.get('page')) : 1);
      setSubmit(false);
    }
   
    return () => {
      isMounted = false;
    };
  },[submit , searchParams.get('category_ids[]')]);


  const handlePageClick = (page) =>{
    setLoading(true);
    fetchSuppliers(page);
  };


  const openModalNonTecdoc = (id:number) =>{
    setOpenModal(true);
    setSupplierId(id);
   
  };

  const deleteItem = (id:number) =>{
    setOpenModalDelete(true);
    setSupplierId(id);
  };


  return (
    <div className="battery-brand-container" >
      <div className="body">
        <div className="battery-brand-header">
          <div className="title">
            {t('_customer_catalog')} 
          </div>
          <div className="btn-new">
            <button onClick={() => setOpenModalCreate(true)}>  {t('_create_battery')}</button>
          </div> 
          <div className="filter">
            <div className="btn-filter">
              <button onClick={() => setOpenFilter(true)}>{t('_filter_result')}</button>
            </div>
          </div>
        
        </div>

        <hr className="hr"/>
        <NonTecdocSupplierPart data={data} loading={loading} openModalNonTecdoc={openModalNonTecdoc} deleteItem={deleteItem}/>

        {totalPage > 1 && (
          <Pagination pageCount={totalPage} handlePageClick={handlePageClick} forcePage={forcePage} />
        )}
      </div>

      <NonTecdocSupplierFilter
        openFilter={openFilter} 
        setOpenFilter={setOpenFilter} 
        setData={setData} 
        setTotalPage={setTotalPage}
        setLoading={setLoading}
        
      />

      <Modal
        openModal={openModal}
        setOpenModal={setOpenModal}
        childrenComponent={<SupplierEdit setOpenModal={setOpenModal} id={supplierId} setSubmit={setSubmit}  />}
      />

      <Modal
        openModal={openModalCreate}
        setOpenModal={setOpenModalCreate}
        childrenComponent={<SupplierCreate setOpenModalCreate={setOpenModalCreate} setSubmit={setSubmit}  />}
      />

      <Modal
        openModal={openModalDelete}
        setOpenModal={setOpenModalDelete}
        childrenComponent={<ModalDelete setOpenModalDelete={setOpenModalDelete} setSubmit={setSubmit} id={supplierId} link={http.nonTecDocCatalog.supplierDelete} />}
      />
    </div>
  );
};

export default NonTecdocPartsSuppliers; 