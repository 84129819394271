import React, { useEffect, useState } from 'react';
import './EspaceReliableNavbarGroupBtn.scss';
import { useLocation } from 'react-router-dom';
import CatalogNavbarGroupBtn from 'pages/CatalogPage/partial/CatalogNavbarGroupBtn/CatalogNavbarGroupBtn';
import EspaceTariffsFloatableButtons from './NavbarGroupBtns/EspaceTariffsFloatableButtons';
import ReliableFloatableButtons from './NavbarGroupBtns/ReliableFloatableButtons';
import ClientFloatableButtons from './NavbarGroupBtns/ClientFloatableButtons';
import SupplierFolatableBtns from './NavbarGroupBtns/SupplierFolatableBtns';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';


type Props = {};

const EspaceReliableNavbarGroupBtn: React.FC<Props> = () => {
  // const navigate = useNavigate();
  // const [openModal, setOpenModal] = useState<boolean>(false);
  const [icons, setIcons] = useState<JSX.Element | undefined>();
  const { isMobile } = useDeviceDetect();
  // const [modalChildrenComponent, setModalChildrenComponent] = useState<ReactElement | null>(null);
  const location = useLocation();


  useEffect(() => {
    setIcons(getCorrespondingBtn());
  }, [location, isMobile]);

  // const handleClick = (item: INavbarFloatableButton): void => {
  //   if (item.modalChildren) {
  //     setOpenModal(true);
  //     setModalChildrenComponent(item.modalChildren);
  //   }

  //   if (item.link) {
  //     navigate(item.link);
  //   }
  // };

  // const renderBtnFromArray = (flotableIcons: INavbarFloatableButton[] = []) => {
  //   return (
  //     <React.Fragment>
  //       {modalChildrenComponent ? (
  //         <Modal openModal={openModal} childrenComponent={modalChildrenComponent} setOpenModal={setOpenModal} />
  //       ) : null}
  //       {flotableIcons.map((item, index) => {
  //         return (
  //           <div key={index} onClick={() => handleClick(item)}>
  //             <DefaultIcon onClick={() => null} icon={item.icon} text={item.text} />
  //           </div>
  //         );
  //       })}
  //     </React.Fragment>
  //   );
  // };

  const getCorrespondingBtn = () => {
    switch (true) {
      case (location.pathname === '/universal-catalog' || location.pathname === '/news'):
        return <ReliableFloatableButtons />;

      case location.pathname.includes('/espace-clients'):
        return <ClientFloatableButtons />;

      case location.pathname === '/espace-suppliers':
        return <SupplierFolatableBtns />;

      case location.pathname === '/my-suppliers':
        return <EspaceTariffsFloatableButtons />;

      case location.pathname === '/espace-tariffs':
        return <EspaceTariffsFloatableButtons />;

      case /\/catalog\/[A-Z]{0,3}/.test(location.pathname):
        return <CatalogNavbarGroupBtn />;

      default:
        return;
    }
  };

  return <div className="header-btn-search">{icons}</div>;


};

EspaceReliableNavbarGroupBtn.displayName = 'EspaceReliableNavbarGroupBtn';

export default EspaceReliableNavbarGroupBtn;
