import React from 'react';
import SkeletonSpinner from 'components/skeleton-spinner/SkeletonSpinner';
import { useTranslation } from 'react-i18next';
import './VehicleInfo.scss';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import moment from 'moment/moment';

type Props = {
  data?;
  loading: boolean;
  setVehicleInfoIsOpen?
};

const VehicleInfo: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { car, carInfo } = useSelector((state: RootState) => state.carSelected);
  const { isMobile } = useDeviceDetect();

  return (
    <div className="vehicle-info-container">
      <div className="vehicle-info-header">
        <div className="title">
          {carInfo?.manufacturer_name} {carInfo?.model_name ? carInfo?.model_name : carInfo?.model}
        </div>
        {isMobile ?
          <div className="close" onClick={() => { props.setVehicleInfoIsOpen && props.setVehicleInfoIsOpen(false); }}><FontAwesomeIcon icon={faClose} /></div>
          : null}
      </div>
      <div className="content">
        {props.loading ? (
          <SkeletonSpinner />
        ) : (
          <ul>
            {carInfo?.manufacturer_name && <li className="list-item">
              <span>{`${t('_mark')} : `}</span> {carInfo?.manufacturer_name}
            </li>}
            {(carInfo?.model_name || carInfo?.model) && <li className="list-item">
              <span>{`${t('_model')} : `}</span>{' '}{carInfo?.model_name ?? carInfo?.model}
            </li>}
            {(carInfo?.body_type || carInfo?.body_description) && <li className="list-item">
              <span>{`${t('_type_of_body')} : `}</span>{carInfo?.body_type}{carInfo?.body_description ? ` ${carInfo?.body_description}` : ''}
            </li>}
            {carInfo?.version && <li className="list-item">
              <span>{`${t('_version')} : `}</span> {carInfo?.version}
            </li>}
            {carInfo?.vehicle_color && <li className="list-item">
              <span>{`${t('_color')} : `}</span>{carInfo?.vehicle_color}
            </li>}
            {(carInfo?.seats || carInfo?.door_count) &&
              <li className="list-item">
                <span>{`${t('_number_of_seats_doors')} : `}</span>{carInfo?.seats ? carInfo?.seats : '-'} / {carInfo?.door_count ? carInfo?.door_count : '-'}
              </li>}
            {carInfo?.types_mines && <li className="list-item">
              <span>{t('CNIT : ')}</span>{carInfo?.types_mines}
            </li>}
            {carInfo?.euro && <li className="list-item">
              <span>{t('EURO : ')}</span>{carInfo?.euro}
            </li>}
            {carInfo?.vin && <li className="list-item">
              <span>{t('VIN : ')}</span>{carInfo?.vin}
            </li>}
            {carInfo?.co2 && <li className="list-item">
              <span>{t('CO2 : ')}</span>{carInfo?.co2 ? `${carInfo?.co2} g/km` : ''}
            </li>}
            {carInfo?.reg_plate && <li className="list-item">
              <span>{`${t('_plate')} : `}</span>{carInfo?.reg_plate}
            </li>}
            {carInfo?.reg_date && <li className="list-item">
              <span>{`${t('_registration_date')} : `}</span>{carInfo?.reg_date ? moment(carInfo?.reg_date).format('YYYY-MM-DD') : ''}
            </li>}
            {(carInfo?.production_years?.start_year || carInfo?.production_years?.end_year) && <li className="list-item">
              <span>{`${t('_production_years')} : `}</span>{`${carInfo?.production_years?.start_month} ${carInfo?.production_years?.start_year} - ${carInfo?.production_years?.end_month} ${carInfo?.production_years?.end_year}` }
            </li>}
            {carInfo?.serial_number && <li className="list-item">
              <span>{`${t('_serial_number')} : `}</span>{carInfo?.serial_number}
            </li>}
            {carInfo?.fuel && <li className="list-item">
              <span>{`${t('_fuel_type')} : `}</span> {carInfo?.fuel}
            </li>}
            {carInfo?.unloaded_weight && <li className="list-item">
              <span>{`${t('_weight_empty')} : `}</span>{carInfo?.unloaded_weight} Kg {carInfo?.loaded_weight ? ` ${carInfo?.loaded_weight} Kg` : ''}
            </li>}
            {carInfo?.engine_code && <li className="list-item">
              <span>{`${t('_engine_code')} : `}</span>{carInfo?.engine_code}
            </li>}
            {carInfo?.engine_size && <li className="list-item">
              <span>{`${t('_engine_size')} : `}</span>{carInfo?.engine_size ? `${carInfo?.engine_size} cm3` : ''}
            </li>}
            {carInfo?.kw_power && <li className="list-item">
              <span>{`${t('_engine_power')} : `}</span>{carInfo?.kw_power} KW {carInfo?.horse_power ? ` (${carInfo?.horse_power} HP)` : ''}
            </li>}
            {carInfo?.gearbox && <li className="list-item">
              <span>{`${t('_gearbox')} : `}</span>{carInfo?.gearbox}
            </li>}
            {carInfo?.wheel_base && <li className="list-item">
              <span>{`${t('_wheel_base')} : `}</span>{carInfo?.wheel_base ? ` ${carInfo?.wheel_base} mm` : ''}
            </li>}
          </ul>
        )}
      </div>
    </div>
  );
};

export default VehicleInfo;
