import React from 'react';

import MenuItemView from './MenuItem.view';

import './MenuItem.scss';
import icons from 'assets/Icons';

type Props = {
  icon: keyof typeof icons;
  text: string;
  className?: string;
};

const MenuItem: React.FC<Props> = (props: Props) => {
  const classesContainer = ['wrapper'];

  if (props.className) {
    classesContainer.push(props.className);
  }

  return <MenuItemView className={classesContainer.join(' ')} icon={props.icon} text={props.text} />;
};

MenuItem.displayName = 'MenuItem';

export default MenuItem;
