import Spinner from 'components/spinner/Spinner';
import i18n from 'i18n';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { createSearchParams, generatePath, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { RootState } from 'store/store';
import useHttp from 'utils/hooks/useHttp';
import { IServiceProposal } from 'utils/rmi/interface/maintenance.interface';
import { getCurrentCountryRMI, getCurrentLanguageRMI } from 'utils/rmi/rmi-utils/rmi-utils';

type Props = {
  setStep?: (string) => void
  setHasMaintenanceWorks: (boolean) => void
  setHasServicePlan: (boolean) => void
}
const StepThree: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { register, control, setValue } = useForm();
  const http = useHttp();
  const { vehicle_id } = useParams();
  const { typeId } = useSelector((state: RootState) => state.tecrmi);
  const [service, setService] = useState<IServiceProposal>();
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  function fetchServiceProposal() {
    setLoading(true);
    const monthToNumber = moment().month(String(searchParams.get('registration_date_month'))).format('M');

    if (typeId) {
      http.maintenanceHttp.fetchServiceProposal({
        typeId,
        cooGroupId: Number(searchParams.get('cooGroupId')),
        countryCode: getCurrentCountryRMI(i18n.language),
        languageCode: getCurrentLanguageRMI(i18n.language),
        InitalRegistrationDate: new Date(Number(searchParams.get('registration_date_year')), Number(monthToNumber)).toISOString(),
        currentMileage: String(searchParams.get('milage')),
        kindOfVehicleTreeId: 3,
        mdBodyQualColId: 0
      }).then(res => setService(res.data)).finally(() => setLoading(false));
    }
  }

  function handleRadioChange(e) {
    props.setHasMaintenanceWorks(false);
    props.setHasServicePlan(false);

    if (!service) { return; }

    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      service: e.target.value,
      workIds: service[e.target.value].map(item => item.WorkId),
    });
    navigate({
      pathname: generatePath('/reliable/rmi/:vehicle_id/technical-documentation/maintenance/repair', {
        vehicle_id: String(vehicle_id)
      }),
      search: createSearchParams({
        ...Object.fromEntries(searchParams.entries()),
        service: e.target.value,
        workIds: service[e.target.value].map(item => item.WorkId),
      }).toString()
    });
  }

  useEffect(() => {
    fetchServiceProposal();
  }, [searchParams.get('cooGroupId'), i18n.language, typeId]);

  useEffect(() => {
    setValue('service', searchParams.get('service'));
  }, []);

  return (
    <div className="maintenance-step">
      {loading ? <Spinner class="small" /> : <React.Fragment>
        <label className="maintenance-radio-labels" htmlFor="field">
          <div className="maintenance-radio-label">
            <Controller
              control={control}
              name="service"
              render={({ field: { onChange } }) => (
                <input
                  {...register('possible_service')}
                  type="radio"
                  value="LastServiceBeforKmMonth"
                  id="field"
                  checked={searchParams.get('service') === 'LastServiceBeforKmMonth'}
                  onChange={(val) => {
                    onChange(val);
                    handleRadioChange(val);
                  }}
                />
              )}
            />
            <span className="label-title">{t('_possible_previous_service')}</span>
          </div>
          <ul>
            {service?.LastServiceBeforKmMonth.map(item => {
              return (
                <li key={item.ItemMpId}>{item.ItemMpText} - {item.KorText}</li>
              );
            })}
          </ul>
        </label>
        <label className="maintenance-radio-labels" htmlFor="field">
          <div className="maintenance-radio-label">
            <Controller
              control={control}
              name="service"
              render={({ field: { onChange } }) => (
                <input
                  {...register('possible_service')}
                  type="radio"
                  value="NextServiceAfterKmMonth"
                  checked={searchParams.get('service') === 'NextServiceAfterKmMonth'}
                  id="field"
                  onChange={(val) => {
                    onChange(val);
                    handleRadioChange(val);

                  }}
                />
              )}
            />
            <span className="label-title">{t('_possible_next_service')}</span>
          </div>
          <ul>
            {service?.NextServiceAfterKmMonth.map(item => {
              return (
                <li key={item.ItemMpId}>{item.ItemMpText} - {item.KorText}</li>
              );
            })}
          </ul>
        </label>
      </React.Fragment>}
    </div>
  );
};

export default StepThree;