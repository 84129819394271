import React, { ReactElement } from 'react';

import './PlatformAdminClientMessagesNavbar.scss';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

type Props = {
  title: string;
  option?: ReactElement
}
const PlatformAdminClientMessagesNavbar: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className="admin-client-navbar-container">
      <div className="breadcrumbs" onClick={() => navigate('/espace-clients/messages')}>
        <div className="icon">
          <FontAwesomeIcon icon={faArrowLeft} />
        </div>
        <p className="back-description">{t('_return')}</p>
      </div>
      <div className="title">
        <h1>{props.title}</h1>
      </div>
      <div className="options">
        <span></span>
      </div>
    </div>
  );
};

PlatformAdminClientMessagesNavbar.displayName = 'PlatformAdminClientMessagesNavbar';

export default PlatformAdminClientMessagesNavbar;