import React from 'react';
import './ComponentsLinkModal.scss';

import IconItem from '../../../../../components/Item/IconItem/IconItem';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { IRMIModulesLinkChildren, IRMIModulesLinkModal } from 'utils/rmi/reliableTabs';
import { useTranslation } from 'react-i18next';


type Props = {
  setOpenModal: (e: boolean) => void;
  rmiModulesLink: Array<IRMIModulesLinkModal>;
};

const ComponentsLinkModal: React.FC<Props> = (props) => {
  const {t} = useTranslation();

  function generateList(children: Array<IRMIModulesLinkChildren>) {

    return (
      <ul>
        {children.map((childe, index) => {
          return <li key={index}>
            {childe.link ?
              <NavLink onClick={() => { props.setOpenModal(false); }} to={childe.link}>
                {childe.label}
              </NavLink>
              :
              <React.Fragment>
                <p>{childe.label}</p>
                {childe.children && generateList(childe.children)}
              </React.Fragment>
            }
          </li>;
        })}
      </ul>);

  }

  return (

    <div className="component-link-modal">
      <div className="modal-dialog">
        <div className="close">
          <IconItem icon={faClose} onClick={() => {
            props.setOpenModal(false);
          }}
          />
        </div>
        <div className={`rmi-module-link-tabs ${!props.rmiModulesLink.length ? 'no_data' : ''}`}>
          {props.rmiModulesLink.length ?
            <Tabs>
              <TabList>
                {props.rmiModulesLink.map(module => {
                  return (<Tab key={module.tabName}>
                    {module.tabName}
                  </Tab>);
                })}

              </TabList>
              {props.rmiModulesLink.map(module => {
                return (<TabPanel key={module.tabName}>
                  {generateList(module.children)}
                </TabPanel>);
              })}
            </Tabs> : <div className="no-data">{t('_no_data')}</div>
          }
        </div>
      </div>
    </div>
  );
};

ComponentsLinkModal.displayName = 'ComponentsLinkModal';

export default ComponentsLinkModal;