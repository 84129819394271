import React from 'react';
import {NavLink} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IErrorCodesNavLinks} from './errorCodes';
import useReliableCatalogCarQueryParams from 'utils/hooks/useReliableCatalogCarQueryParams';
import './ErrorCodesNavLinks.scss';

type Props = {
  navLinks?: IErrorCodesNavLinks[];
}

const ErrorCodesNavLinksView:React.FC<Props> = (props) => {
  const reliableCatalogCarQueryParams = useReliableCatalogCarQueryParams();

  return (
    <div className="error-codes-navbar-links">
      <div className="navbar-links">
        {
          props.navLinks?.map((item, index) => {

            return (
              <div className="navbar-link" key={index}>
                {
                  item.disabled ? (
                    <a className="disabled" href="#">
                      <FontAwesomeIcon icon={item.icon} /> {item.text}
                    </a>
                  ) : (
                    <NavLink to={`${item.link}?${(new URLSearchParams({...reliableCatalogCarQueryParams, ...item.queryParams})).toString()}`}>
                      <FontAwesomeIcon icon={item.icon} /> {item.text}
                    </NavLink>
                  )
                }
              </div>
            );
          })
        }
      </div>
    </div>
  );
};

ErrorCodesNavLinksView.displayName = 'ErrorCodesNavLinksView';

export  default ErrorCodesNavLinksView;
