import React from 'react';
import './SupplierCard.scss';

type Props = {
    name: string
    image: string
    handleClick: () => void
}

const SupplierCard:React.FC<Props> = (props) => {
  return (
    <div className="supplier-card" onClick={props.handleClick}>
      <div className="image-holder">
        <img src={props.image}/>
      </div>
      <div className="supplier-name" >
        {props.name}
      </div>
    </div>
  );
};

export default SupplierCard;