import { IHttpResponse, serverAxios } from '../../http';
import { IEbook } from 'applications/automotor/components/espace/navbar/Navbar.model';

class EbooksHttp {
  private static route = '/ebook';
  private static checkImage = '/check_image';
  public async getEbooks(catalog_id: string): Promise<IEbook[]> {
    const { data } = await serverAxios.get(EbooksHttp.route, { params: { catalog_id } });

    return data;
  }


  public async checkImage(params: { url: string }): Promise<IHttpResponse<{ status: boolean }>> {
    return serverAxios.get(EbooksHttp.checkImage, { params: params });


  }
}

export default EbooksHttp;
