import React from 'react';
import { faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './Head.scss';

type Props = {
  text: string;
  sortable: boolean;
  center?: boolean;
};

const HeadView: React.FC<Props> = (props: Props) => {
  return (
    <div className={`table-head-wrapper ${props.center ? 'centered' : null}`}>
      {props.text}
      {props.sortable && <div className="icons">
        <FontAwesomeIcon icon={faCaretUp} className="icon" />
        <FontAwesomeIcon icon={faCaretDown} className="icon" />
      </div>}
    </div>
  );
};

HeadView.displayName = 'HeadView';

export default HeadView;
