import SkeletonSpinner from 'components/skeleton-spinner/SkeletonSpinner';
import React, { MutableRefObject, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { getCarDescription } from 'utils/getCarDescription';
import { useOnClickOutside } from 'utils/hooks/useOnClickOutside';
import './PlateDropdownContent.scss';

type Props = {
  data?
  setIsOpen: (isOpen: boolean) => void;
  loading: boolean;
  handleClick: (data) => void
};

const PlateDropdownContent: React.FC<Props> = (props) => {
  const referenceDropdownRef = useRef() as MutableRefObject<HTMLDivElement>;
  const {t} = useTranslation();

  useOnClickOutside(referenceDropdownRef, () => props.setIsOpen(false));

  function carData(){
    if(props.data?.cars){
      return props.data.cars;
    }

    if(props.data?.cars && props.data.cars?.cars){
      return props.data.cars?.cars;
    }

    if(props.data?.manufacturers){
      return props.data.manufacturers;
    }

    if(props.data?.models){
      return props.data.models;
    }
  }


  return (
    <div ref={referenceDropdownRef} className="plate-dropdown-content">
      <div className="plate-dropdown-header">
        <div className="plate-dropdown-title">{t('_search_result')}</div>
      </div>
      <div className="plate-search-result-content">
        {props.loading ? (
          <SkeletonSpinner />
        ) : (
          <React.Fragment>
            {carData()?.length ? (
              carData()?.map((item, index) => {
                return (
                  <li key={index} className="suggestion-list-item" onClick={()=>props.handleClick(item)}>
                    <div className="suggestion-item-title">
                      <span>{getCarDescription(item)}</span>
                    </div>
                  </li>
                );
              })
            ) : (
              <div className="no-data">{t('_no_data').toLocaleUpperCase()}</div>
            )}
            {}
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

PlateDropdownContent.displayName = 'PlateDropdownContent';

export default PlateDropdownContent;
