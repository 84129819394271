import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import $ from 'jquery';
import { isEmpty } from 'underscore';
import { replaceTecRmiIframeBrandingInfo } from 'utils/rmi/rmi-utils/rmi-utils';
import './VehicleIframe.scss';
import Spinner from 'components/spinner/Spinner';
import { useMobileDetect } from 'utils/hooks/use-mobile-detect';

interface IVehicleIframe {
  vehicleIframe: string;
  loading: boolean;
  onClickHandler?: (e) => void;
  onIframeAnchorClick?: (e) => void;
  widthCssClass?: string;
  removeFixedHeight?: boolean;
}

const VehicleIframe: React.FC<IVehicleIframe> = (props): JSX.Element | null => {
  const [height, setHeight] = useState<number>(0);
  const [iframeEl, setIframeEl] = useState<HTMLIFrameElement>();
  const [iframeHtml, setIframeHtml] = useState<string>('');
  const { t } = useTranslation();
  const additionalOffset = 100;
  const isMobile = useMobileDetect(490);

  useEffect(() => {
    setIframeHtml('');
    setIframeHtml(replaceTecRmiIframeBrandingInfo(props.vehicleIframe));
  }, [props.vehicleIframe]);

  useEffect(() => {
    if (!isMobile) {
      setTimeout(() => {
        window.scroll({
          top: 0,
          behavior: 'smooth',
        });
      }, 0);
    }

    if (props.loading) {
      setHeight(0);
    }
  }, [props.loading]);

  useEffect(() => {
    if (iframeEl) {
      iframeEl.addEventListener('resize', resizeCb);

      if (props.onClickHandler) {
        iframeEl.addEventListener('mouseup', props.onClickHandler, false);
      }
    }

    return () => {
      iframeEl?.removeEventListener('resize', resizeCb);

      if (props.onClickHandler) {
        iframeEl?.removeEventListener('mouseup', props.onClickHandler);
      }
    };
  }, [iframeEl]);

  const resizeCb = (resizeEvent): void => {
    setHeight(Number(resizeEvent.currentTarget.document.body.offsetHeight) + additionalOffset);
  };

  const injectStylesForAnchor = (iframeDocument: HTMLDocument): void => {
    iframeDocument.body.innerHTML = `${iframeDocument.body.innerHTML}<style> 
    a[data-href]:not(.button) {
        color: blue;
        text-decoration: underline;
    }
    a[data-href], .button {
        cursor: pointer;
    }
  </style>`;
  };

  const onLoadCallBack = (event): void => {
    const iframeWindow = event.target.contentWindow;
    const iframeDocument = iframeWindow.document;

    injectStylesForAnchor(iframeDocument);
    const selector = 'a:not([download]):not([target=_blank])';

    $(iframeDocument).on('click', selector, (event: { currentTarget; preventDefault: () => void }) => {
      const anchorElement: HTMLAnchorElement = event.currentTarget;
      const anchorHref: string | null = anchorElement.getAttribute('data-href');
      const anchorDataItemMpId: string | null = anchorElement.getAttribute('data-itemmpid');
      const anchorId: string | null = anchorElement.getAttribute('id');

      const anchorRelationElements: Array<HTMLAnchorElement> =
        typeof anchorHref === 'string' ? iframeWindow.document.getElementsByName(anchorHref.replace('#', '')) : [];

      if (props.onIframeAnchorClick && (anchorHref || anchorDataItemMpId || anchorId) && !anchorRelationElements.length) {
        props.onIframeAnchorClick(anchorHref || anchorDataItemMpId || anchorId);
      } else if (anchorHref && anchorRelationElements.length) {
        const element = anchorRelationElements[0];
        const nextElement = element.nextElementSibling;

        if (nextElement?.tagName == 'IMG') {
          nextElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
        } else {
          element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
        }
      }

      event.preventDefault();
    });
    setIframeEl(iframeWindow);
    setHeight(Number(iframeWindow.document.body.offsetHeight) + additionalOffset);
  };

  return (
    <div
      style={{ height: !props.removeFixedHeight ? height : '100%' }}
      className={`vehicle-iframe rounded ${props.widthCssClass || 'shadow-sm border'}`}
    >
      {props.loading ? (
        <Spinner class="small" />
      ) : isEmpty(iframeHtml) ? (
        <p className="vehicle-iframe__no-data">{t('_no_data')}</p>
      ) : (
        <iframe title="vehicle" srcDoc={iframeHtml} frameBorder="0" scrolling="auto" width="100%" onLoad={onLoadCallBack} />
      )}
    </div>
  );
};

export default VehicleIframe;
