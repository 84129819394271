import React from 'react';
import RefCard from '../ref-card/RefCard';
import VinPlateCard from '../vin-plate-card/VinPlateCard';
import VehicleCard from '../vehicle-card/VehicleCard';
import './GeneralSearchMobile.scss';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';

type Props = {};

const GeneralSearchView: React.FC<Props> = () => {
  const { user } = useSelector((state: RootState) => state.userData);

  return (
    <React.Fragment>
      {
        user?.numcli !== Number(process.env.REACT_APP_MECADEPOT_NUMCLI) ? <RefCard /> : null
      }

      <VinPlateCard />
      <VehicleCard />
    </React.Fragment>
  );
};

GeneralSearchView.displayName = 'GeneralSearchView';

export default GeneralSearchView;
