import React from 'react';
import RefCard from './ref-card/RefCard';
import VinPlateCard from './vin-plate-card/VinPlateCard';
import VehicleCard from './vehicle-card/VehicleCard';
import History from 'pages/History/History';
import './GeneralSearch.scss';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';

type Props = {};

const GeneralSearchView: React.FC<Props> = () => {
  const { user } = useSelector((state: RootState) => state.userData);

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;

    return {
      width,
      height
    };
  }

  return (
    user?.numcli !== Number(process.env.REACT_APP_MECADEPOT_NUMCLI) ?
      <React.Fragment>
        <div className="general-search-group">
          <RefCard/>
          <VinPlateCard/>
        </div>
        <VehicleCard/>
      </React.Fragment>
      :
      <React.Fragment>
        <div className="general-search-group">
          <VinPlateCard/>
          {getWindowDimensions().width > 990 && < History noChangeUrl partView hideSearch/>}
        </div>
        <VehicleCard/>
      </React.Fragment>
  );
};

GeneralSearchView.displayName = 'GeneralSearchView';

export default GeneralSearchView;
