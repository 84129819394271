import React from 'react';
import { IPromotionDetails } from 'utils/hooks/http/promotions.http';
import InterstitialAdView from './InterstitialAd.view';

type Props = {
  items: IPromotionDetails[];
  slidesPerView?: number | 'auto';
  navigation?: boolean;
  pagination?: boolean;
  autoplay?: boolean;
  loop?: boolean;
  onClick: () => void;
};

const InterstitialAd: React.FC<Props> = (props) => {
  return (
      <InterstitialAdView  {...props} />
  );
};

InterstitialAd.displayName = 'Promotion';

export default InterstitialAd;