import { createSlice } from '@reduxjs/toolkit';
import { IEbook } from 'applications/automotor/components/espace/navbar/Navbar.model';
import { fetchEbooksData } from 'store/reducers/catalog/ebooks.actions';

type EbooksSlice = {
  ebooks: IEbook[];
  isLoading: boolean;
  showEbookSidebar: boolean;
};

const initialState: EbooksSlice = {
  ebooks: [],
  isLoading: false,
  showEbookSidebar: false,
};

const ebooksSlice = createSlice({
  name: 'ebooks',
  initialState,
  reducers: {
    setEbooks(state, { payload }) {
      state.ebooks = payload;
    },
    setIsLoading(state, { payload }) {
      state.isLoading = payload;
    },
    setShowEbookSidebar(state, { payload }) {
      state.showEbookSidebar = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchEbooksData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchEbooksData.fulfilled, (state, { payload }) => {
      state.ebooks = payload;
      state.isLoading = false;
    });
  },
});

export const { setEbooks, setIsLoading, setShowEbookSidebar } = ebooksSlice.actions;

export default ebooksSlice.reducer;
