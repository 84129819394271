import { createSlice } from '@reduxjs/toolkit';

type buttonSubmitProforma = {
  loadingButtonSubmitProforma: boolean;
  rederTable: boolean;

};

const initialState: buttonSubmitProforma = {
  loadingButtonSubmitProforma: false,
  rederTable: false
};

const buttonSubmitProforma = createSlice({
  name: 'proforma',
  initialState,
  reducers: {
    setLoadingButtonSubmitProforma(state, { payload }) {
      state.loadingButtonSubmitProforma = payload;
    },
    setRederTable(state, { payload }) {
      state.rederTable = payload;
    }
  },

});

export const { setLoadingButtonSubmitProforma, setRederTable } = buttonSubmitProforma.actions;

export default buttonSubmitProforma.reducer;
