import { IClientInfo, IOption, IUpdateForm } from 'interfaces/selectClient';
import React, { PropsWithChildren } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import ConfigurationTab from './ConfigurationTab';
import TestTab from './TestTab';

type Props = {
  setTabIndex: (index: number) => void;
  tabIndex: number;
  infoClient: IClientInfo;
  optionsSupplierMapping: IOption[];
  openModalClientId: number;
  reference: string
  setReference: (reference) => void
  teccomMappingData: IUpdateForm[]
  setTeccomMappingData: (teccomMappingData: IUpdateForm[]) => void
  setEdisabledBtn
};

const TabContainer: React.FC<Props> = (props: PropsWithChildren<Props>) => {
  return <Tabs className="tabs" selectedIndex={props.tabIndex} onSelect={(index) => props.setTabIndex(index)}>
    <TabList className="tab-list">
      <Tab className="tab">Configuration</Tab>
      <Tab className="tab">Test</Tab>
    </TabList>
    <TabPanel className="tab-body" >
      <ConfigurationTab
        teccomMappingData={props.teccomMappingData}
        setTeccomMappingData={props.setTeccomMappingData}
        infoClient={props.infoClient}
      />
    </TabPanel>
    <TabPanel className="tab-body">
      <TestTab
        setReference={props.setReference}
        reference={props.reference}
        openModalClientId={props.openModalClientId}
        optionsSupplierMapping={props.optionsSupplierMapping}
        setEdisabledBtn={props.setEdisabledBtn}
      />
    </TabPanel>
  </Tabs>;
};

TabContainer.displayName = 'TabContainer';

export default TabContainer;
