
import { CancelToken } from 'axios';
import { IBodyResponseTBrand, ITBrand, IParamsTBrand, ITCategory } from 'interfaces/tyresBrand';

import { addRouteParam, IHttpResponse, serverAxios } from '../../http';

class TyreBrandHttp {

  private static list = '/tyres/brand';
  private static info = '/tyres/brand/';
  private static update = '/tyres/update/brand/';
  private static create = '/tyres/register/brand';
  private static delete = '/tyres/brand/';
  private static category = '/tyres/brand_category';
  private static isPartner = '/tyres/update/brand/:id/is_partner';

  public getList(urlParams: IParamsTBrand, cancelToken: CancelToken): Promise<IHttpResponse<IBodyResponseTBrand>> {
    return serverAxios.get(TyreBrandHttp.list, { params: urlParams, cancelToken: cancelToken });
  }


  public getBrandInfo(id: number | undefined): Promise<IHttpResponse<ITBrand>> {
    return serverAxios.get(TyreBrandHttp.info + id);
  }

  public edit(id: number, data: FormData): Promise<IHttpResponse<ITBrand>> {
    return serverAxios.post(TyreBrandHttp.update + id, data);
  }

  public create(data: FormData): Promise<IHttpResponse<ITBrand>> {
    return serverAxios.post(TyreBrandHttp.create, data);
  }

  public delete(id: number): Promise<IHttpResponse<null>> {
    return serverAxios.delete(TyreBrandHttp.delete + id);
  }

  public category(): Promise<IHttpResponse<ITCategory[]>> {
    return serverAxios.get(TyreBrandHttp.category);
  }

  public updateIsPartner(id: number, data: {}): Promise<IHttpResponse<[]>> {
    return serverAxios.post(addRouteParam(TyreBrandHttp.isPartner, { id }), data);
  }

}

export default TyreBrandHttp;
