import { ComponentType } from 'react';

export function retryPromise(fn: { (): Promise<{}>;}, retriesLeft = 5, interval = 1000): Promise<{ default: ComponentType<{}>; }> {
  return new Promise((resolve, reject) => {
    fn()
      .then()
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            // reject('maximum retries exceeded');
            reject(error);

            return;
          }

          // Passing on "reject" is the important part
          retryPromise(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
}
