import React from 'react';
import SearchReferenceView from './SearchReference.view';

const SearchReference: React.FC = () => {
  return <SearchReferenceView />;
};

SearchReference.displayName = 'SearchReference';

export default SearchReference;
