
import React, { useEffect, useState } from 'react';
import { INonTecdocInfo, INonTecdocPart } from 'interfaces/nonTecdocCatalog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { IConsumableSelect } from 'interfaces/consumable';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { notify } from 'utils/marketplace';
import TextInput from 'components/inputs/TextInput/TextInput';
import Spinner from 'components/spinner/Spinner';
import useHttp from 'utils/hooks/useHttp';
import Select from 'react-select';
import './NonTecdocEdit.scss';

type Props = {
  setOpenModal: (e: boolean) => void;
  setSubmit: (e: boolean) => void;
  nonTecdocId: number | undefined;
};


const NonTecdocEdit: React.FC<Props> = (props) => {
  let isMounted = true;
  const { isMobile, isTablet } = useDeviceDetect();
  const { t } = useTranslation();
  const http = useHttp();
  const [supplierTecdoc, setSupplierTecdoc] = useState<IConsumableSelect | null>();
  const [suppliersTecdoc, setSuppliersTecdoc] = useState<IConsumableSelect[]>([]);
  const [nonTecdocInfo, setNonTecdocInfo] = useState<INonTecdocPart>();
  const [manufacturer, setManufacturer] = useState<IConsumableSelect | null>();
  const [manufacturers, setManufacturers] = useState<IConsumableSelect[]>([]);
  const [supplier, setSupplier] = useState<IConsumableSelect | null>();
  const [suppliers, setSuppliers] = useState<IConsumableSelect[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  // const [file, setFile] = useState<File[]>([]);

  const { getValues, setValue, watch, register, handleSubmit, control, formState: { errors }, } = useForm<INonTecdocInfo>();

  useEffect(() => {
    if (props.nonTecdocId && isMounted) {
      Promise.all([
        http.nonTecDocCatalog.getNonTecdocSuppliers({ per_page: 1000 }),
        http.selectClient.manufacturerMappingList(),
        http.selectClient.supplierMappingList(),
        http.nonTecDocCatalog.partInfo(props.nonTecdocId)
      ])
        .then((res) => {
          const suppliersData = res[0].data.data.map((item) => {
            return { value: item.id, label: item.name };
          });

          setSuppliers(suppliersData);
          const manufacturerData = res[1].map((item) => {
            return { value: item.id, label: item.name };
          });

          setManufacturers(manufacturerData);
          const supplierTecdocData = res[2].map((item) => {
            return { value: item.id, label: item.name };
          });

          setSuppliersTecdoc(supplierTecdocData);
          setNonTecdocInfo(res[3].data);
        });
    }

    return () => {
      isMounted = false;
    };
  }, []);

  const onSubmit = async () => {
    setLoading(true);
    const values = getValues();

    const formData: FormData = new FormData();

    formData.append('reference', values.reference.trim());
    formData.append('supplier_id', String(supplier?.value));
    formData.append('hernr', String(manufacturer?.value || null));
    formData.append('artnr', String(values?.iam_reference || null));
    formData.append('refnr', String(values?.oem_reference || null));
    formData.append('dlnr', String(supplierTecdoc?.value || null));
    formData.append('description', String(values.description || null));

    // if (file.length) {
    //   formData.append('image', file[0]);
    // }

    http.nonTecDocCatalog.partUpdate(Number(props?.nonTecdocId), formData).then(() => {
      notify(t('_success'), 'success');
    })
      .finally(() => {
        setLoading(false);
        props.setOpenModal(false);
        props.setSubmit(true);
      }).catch((error)=> notify(error?.part[0]));
  };

  useEffect(()=>{
    if(suppliers.length){
      const sup = suppliers.find((supplier) => String(supplier.value) === String(nonTecdocInfo?.supplier_id));

      setSupplier(sup);
      setValue('supplier', sup);
    }

    if(manufacturers.length){
      const man = manufacturers.find((manufacturer) => String(manufacturer.value) === String(nonTecdocInfo?.hernr));

      setManufacturer(man);
      setValue('manufacturer', man);
    }

    if(suppliersTecdoc.length){
      const supTecdoc = suppliersTecdoc.find((supplierTecdoc) => String(supplierTecdoc.value) === String(nonTecdocInfo?.dlnr));

      setSupplierTecdoc(supTecdoc);
      setValue('supplier_tecdoc', supTecdoc);
    }
  },[nonTecdocInfo, suppliers]);

  return (
    <div className="non-tecdoc-edit-container" >
      <div className="close-modal" onClick={() => props.setOpenModal(false)}><FontAwesomeIcon icon={faClose} /></div>
      <div className="title">
        {t('_consumable_title_edit')}
        <br />
        <span>{t('_non_tecdoc_title_edit_catalog')}</span>
      </div>
      {
        !nonTecdocInfo ?
          <div className="spinner-info"> <Spinner />  </div> :
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="non-tecdoc-edit-body" >
              <div className="row">
                <div className={`container ${errors.supplier?.type == 'required' ? 'error' : ''}`}>
                  <label>
                    {t('_supplier')}
                  </label>
                  <Controller
                    control={control}
                    name="supplier"
                    rules={{required: true}}
                    render={({ field: { onChange } }) => (
                      <Select
                        value={supplier}
                        className="select-dropdown"
                        onChange={(val) => {
                          onChange(val?.value);
                          setSupplier(val);
                        }}
                        options={suppliers}
                        isSearchable={!isMobile && !isTablet}
                      />
                    )}
                  />
                  <p className="error-message">{errors.supplier?.type === 'required' ? t('_fild_required') : ''}</p>
                </div>
                <div className={`container ${errors.reference?.type == 'required' ? 'error' : ''}`}>
                  <label>
                    {t('_reference')}
                  </label>
                  <TextInput defaultValue={nonTecdocInfo?.reference || ''} placeholder={t('_reference')} {...register('reference', {required: true})} />
                  <p className="error-message">{errors.reference?.type == 'required' ? errors.reference?.message : ''}</p>
                </div>
              </div>
              <div className="row">
                <div className={`container ${errors.manufacturer?.type == 'required' ? 'error' : ''}`}>
                  <label>
                    {t('_manufacturer')}
                  </label>
                  <Controller
                    control={control}
                    name="manufacturer"
                    rules={{required: !!watch().oem_reference}}
                    render={({ field: { onChange } }) => (
                      <Select
                        value={manufacturer}
                        className="select-dropdown"
                        onChange={(val) => {
                          onChange(val?.value);
                          setManufacturer(val);
                        }}
                        options={manufacturers}
                        isClearable
                        isSearchable={!isMobile && !isTablet}
                      />
                    )}
                  />
                  <p className="error-message">{errors.manufacturer?.type === 'required' ? t('_fild_required') : ''}</p>
                </div>
                <div className={`container ${errors.oem_reference?.type == 'required' ? 'error' : ''}`}>
                  <label>
                    {t('_oem_reference')}
                  </label>
                  <TextInput defaultValue={nonTecdocInfo?.refnr || ''} placeholder={t('_oem_reference')} {...register('oem_reference', {required: !!watch().manufacturer})} />
                  <p className="error-message">{errors.oem_reference?.type === 'required' ? t('_fild_required') : ''}</p>
                </div>
              </div>
              <div className="row">
                <div className={`container ${errors.supplier_tecdoc?.type == 'required' ? 'error' : ''}`}>
                  <label>
                    {t('_supplier_tecdoc')}
                  </label>
                  <Controller
                    control={control}
                    name="supplier_tecdoc"
                    rules={{required: !!watch().iam_reference}}
                    render={({ field: { onChange } }) => (
                      <Select
                        value={supplierTecdoc}
                        className="select-dropdown"
                        onChange={(val) => {
                          onChange(val?.value);
                          setSupplierTecdoc(val);
                        }}
                        options={suppliersTecdoc}
                        isClearable
                        isSearchable={!isMobile && !isTablet}
                      />
                    )}
                  />
                  <p className="error-message">{errors.supplier_tecdoc?.type === 'required' ? t('_fild_required') : ''}</p>
                </div>
                <div className={`container ${errors.iam_reference?.type == 'required' ? 'error' : ''}`}>
                  <label>
                    {t('_iam_reference')}
                  </label>
                  <TextInput defaultValue={nonTecdocInfo?.artnr || ''} placeholder={t('_iam_reference')} {...register('iam_reference', {required: !!watch().supplier_tecdoc})} />
                  <p className="error-message">{errors.iam_reference?.type === 'required' ? t('_fild_required') : ''}</p>
                </div>
              </div>
              <div className="row">
                <div className="container">
                  <label>
                    {t('_description')}
                  </label>
                  <textarea rows={3}
                    defaultValue={nonTecdocInfo?.description}
                    {...register('description')}
                  />
                </div>
              </div>
              {/* <div className="row">
                <div className="container">
                  <UploadImage setFile={setFile} image={nonTecdocInfo?.image} />
                </div>
              </div> */}
            </div>
            <div className="btn-save">
              <button type="submit" className={loading ? 'disabled' : ''}> {loading ? <Spinner class="extra-small" /> : null} <div className="title-btn-save">{t('_save')}</div></button>
            </div>
          </form>
      }
    </div>
  );
};

export default NonTecdocEdit; 