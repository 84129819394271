import { faArrowAltCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AxiosError } from 'axios';
import Spinner from 'components/spinner/Spinner';
import i18n from 'i18n';
import { ICarPartData } from 'models/car-parts.model';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { IBreadCrumb } from 'utils/hooks/http/presentation.http';
import useHttp from 'utils/hooks/useHttp';
import { notify } from 'utils/marketplace';
import CarPartBarCode from './CarPartBarCode/CarPartBarCode';
import CarPartDetails from './CarPartDetails/CarPartDetails';
import CarPartPhotoGallery from './CarPartPhotoGallery/CarPartPhotoGallery';
import './PresentationCarPartsView.scss';

type Props = {};

const PresentationCarPartsView: React.FC<Props> = () => {
  const http = useHttp();
  const { id } = useParams<'id'>();
  const { t } = useTranslation();

  const [carParts, setCarParts] = useState<ICarPartData>();
  const [breadCrumbData, setBreadCrumbData] = useState<IBreadCrumb>();
  const [loading, isLoading] = useState(false);
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  const getPresentationCarParts = async () => {
    isLoading(true);

    try {
      await http.presentation
        .getCarPartsView(String(id), {
          data_supplier_id: String(searchParams.get('data_supplier_id')),
          catalog_id: String(searchParams.get('catalog_id')),
          provider_id: String(searchParams.get('provider_id')),
        })
        .then((res) => {
          setCarParts(res.data);
        });
    } catch (e: unknown) {

      if (e instanceof AxiosError) {
        const message = e?.response?.data?.detail;

        notify(message);
      }
    } finally {
      isLoading(false);
    }
  };

  const getBreadCrumb = () => {
    http.presentation
      .getBreadCrumb({
        catalog_id: String(searchParams.get('catalog_id')),
        code_groupe: Number(searchParams.get('code_groupe')),
        code_ssgroupe: Number(searchParams.get('code_ssgroupe')),
        code_repere: Number(searchParams.get('code_repere')),
      })
      .then((res) => {
        setBreadCrumbData(res.data);
      });
  };

  useEffect(() => {
    getBreadCrumb();
  }, [i18n.language]);

  useEffect(() => {
    getPresentationCarParts();
  }, [i18n.language]);

  return (
    <div className="car-parts-detail-wrapper">
      {loading ? (
        <Spinner class="catalog-spinner" />
      ) : (
        <div className="car-part-description-wrapper">
          <div className="car-pats-header">
            <div
              className="return-button"
              onClick={() => {
                navigate(-1);
              }}
            >
              <FontAwesomeIcon icon={faArrowAltCircleLeft} className="return-button-icon" />
              {t('_return')}
            </div>
            <div className="header-breadcrumb">
              <span className="first-group">{breadCrumbData?.name}</span> /{' '}
              <span className="sub-group">{breadCrumbData?.subcategories.name}</span> /{' '}
              <span className="sub-sub-group">{breadCrumbData?.subcategories.subcategories.name}</span>
            </div>
          </div>

          <div className="car-part-item-container">
            <div className="car-part-image-container">{carParts && <CarPartPhotoGallery carPart={carParts} />}</div>
            <div className="car-part-details-container">{carParts && <CarPartDetails carPart={carParts} />}</div>
            <div className="car-part-barcode-container">{carParts && <CarPartBarCode item={carParts} />}</div>
          </div>
        </div>
      )}
    </div>
  );
};

PresentationCarPartsView.displayName = 'PresentationCarPartsView';

export default PresentationCarPartsView;
