import React from 'react';
import './TabStyle.scss';

type Props = {
  title: string;
  index: number;
  selectedTab: number;
  setSelectedTab: (index: number) => void;
};

const TabTitle: React.FC<Props> = (props) => {
  function handleClick() {
    props.setSelectedTab(props.index);
  }

  return (
    <div className={`tab-item ${props.selectedTab === props.index ? 'active' : ''}`} onClick={handleClick}>
      {props.title}
    </div>
  );
};

TabTitle.displayName = 'TabTitle';

export default TabTitle;
