import React, { ChangeEventHandler, PropsWithChildren } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import './MainSearchInput.scss';

type Props = {
  placeholder: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  value?: string;
  withIcon: boolean;
  size?: 's' | 'm' | 'l';
  onSubmit?: () => void;
};

const MainSearchInput: React.FC<Props> = (props: PropsWithChildren<Props>) => {
  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    if (event) {
      event.preventDefault();
    }

    props.onSubmit?.();
  };

  return (
    <form onSubmit={(event) => onSubmit(event)} className="MainSearchInput text-input-container">
      <input
        className={`text-input ${props.size && `text-input--${props.size}`}`}
        type="text"
        onChange={props.onChange}
        placeholder={props.placeholder}
        value={props.value}
      />
      {props.withIcon &&
        <button
          type={props.onSubmit && 'submit' || undefined}
          className={`input-search-icon ${props.onSubmit && 'cursor-pointer'}`}
        >
          <FontAwesomeIcon icon={faSearch} />
        </button>
      }
    </form>
  );
};

MainSearchInput.displayName = 'MainSearchInput';

export default MainSearchInput;
