import Spinner from 'components/spinner/Spinner';
import ExpandButton from 'components/tables/TableMoreInformation/ExpandButton/ExpandButton';
import TableMoreInformation from 'components/tables/TableMoreInformation/TableMoreInformation';
import { IOrderItem } from 'models/order.model';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Column, useExpanded, useTable } from 'react-table';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import useHttp from 'utils/hooks/useHttp';
import { getMarketplaceBaseUrl } from 'utils/marketplace';
import './OrderTable.scss';

type Props = {

};

const OrderTable: React.FC<Props> = () => {
  const [data, setData] = useState<IOrderItem[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { order_id } = useParams();
  const http = useHttp();
  const { t } = useTranslation();
  const { isMobile, isTablet, isDesktop } = useDeviceDetect();

  const fetchData = () => {
    setLoading(true);
    http.orderClients.getOrderItems(order_id!).then(res => setData(res.data.data)).finally(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const columns = useMemo(
    (): Column<IOrderItem>[] => [
      {
        id: 'expander',
        // eslint-disable-next-line react/destructuring-assignment
        Header: '',
        width: 15,
        // eslint-disable-next-line react/destructuring-assignment
        Cell: ({ row }) => {
          return (
            //Applying the toggle expander props i.e onClick, style and title
            <ExpandButton row={row} />
          );
        }
      },
      {
        Header: '',
        accessor: 'images',
        Cell: (rows) => rows.cell.value ? <img src={rows.cell.value[0]} width={40} height={35}></img> : <img width={40} height={35} src={require('../../../assets/images/placeholder.png')}></img>,
        width: 60
      },
      {
        Header: t('_reference').toLocaleUpperCase(),
        accessor: (rows) => rows.data_supplier?.name + ' ' + rows?.data_supplier_reference,
        Cell: (rows) => <div className="order-table-ref-cell">{rows.cell.value}</div>,
        width: 200

      },
      {
        Header: t('_category').toLocaleUpperCase(),
        accessor: 'subcategory',
        Cell: (cell) => <div className="order-table-ref-cell">{cell.row.original.subcategory ? cell.row.original.subcategory.name : cell.row.original.description}</div>,

      },
      {
        Header: t('_quantity').toLocaleUpperCase(),
        accessor: 'order_quantity',

      },
      {
        Header: t('_price_per_unit').toLocaleUpperCase(),
        accessor: 'order_unit_price',

      },
      {
        Header: t('_price').toLocaleUpperCase(),
        // accessor: 'order_total_price',
        accessor: (rows) => Number(rows.order_total_price).toFixed(2),
      },
      // {
      //   Header: 'Relicat',
      //   accessor: 'name',
      //   Cell: <div className="order-view-relicats">0 <FontAwesomeIcon className="relicats-red" icon={faCheck}/></div>
      // },
      {
        Header: t('_barcode').toLocaleUpperCase(),
        accessor: 'barcode',
        Cell: (cell) => <div className="order-table-barcode">{cell.row.original.barcode ? <React.Fragment><img width={100} height={40} src={`${getMarketplaceBaseUrl() ?? ''}/${cell.row.original.barcode.image}`} alt="barcode" /><span>{cell.row.original.barcode.code}</span></React.Fragment> : ''}</div>,
      },
    ],
    [],
  );


  let hiddenColumns: string[] = [];

  if (isTablet) {
    hiddenColumns = ['barcode', 'order_unit_price', 'images'];
  }

  if (isMobile) {
    hiddenColumns = ['barcode', 'order_unit_price', 'images'];
  }


  const initialState = hiddenColumns ? {
    'hiddenColumns': hiddenColumns
  } : {};

  if (!isMobile && !isTablet) {
    columns.shift();
  }

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
    initialState: initialState
  }, useExpanded);


  return (
    <div className={`order-table-wrapper OrderTable ${!rows.length && !loading ? 'centered' : ''}`} >
      {loading ? <Spinner class="order-table-spinner" /> : (
        <React.Fragment>
          {!rows.length ? <div className="no-data">{t('_no_data').toLocaleUpperCase()}</div> : (
            <table {...getTableProps()}>
              <thead className="headings">
                {headerGroups.map((headerGroup, index) => {
                  return (
                    <tr key={headerGroup.headers[index].id} className="row">
                      {headerGroup.headers.map((column) => {
                        const { key, ...restColumn } = column.getHeaderProps({
                          style: { minWidth: column.minWidth, width: column.width, maxWidth: column.maxWidth },
                        });

                        return (
                          <th key={key} {...restColumn} className="heading">
                            {column.render('Header')}
                          </th>
                        );
                      })}
                    </tr>
                  );
                })}
              </thead>
              <tbody {...getTableBodyProps} className="body">
                {rows.map((row) => {
                  prepareRow(row);
                  const { key, ...restRowProps } = row.getRowProps();

                  return (
                    <React.Fragment key={key}>
                      <tr key={key} {...restRowProps} className="row">
                        {row.cells.map((cell) => {
                          const { key, ...restCellProps } = cell.getCellProps();

                          return (
                            <td key={key} {...restCellProps} className="cell">
                              {cell.render('Cell')}
                            </td>
                          );
                        })}
                      </tr>
                      {row.isExpanded ? TableMoreInformation({ row, hiddenColumns: hiddenColumns, extraFieldsNumber: 2 }) : null}
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
          )}
          { }
        </React.Fragment>
      )}
    </div>
  );
};

OrderTable.displayName = 'OrderTable';

export default OrderTable;
