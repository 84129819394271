
import React from 'react';
import { useSearchParams } from 'react-router-dom';


const useReliableCatalogCarQueryParams = (): { [key: string]: string } => {

  const [searchParams] = useSearchParams();

  return React.useMemo(
    () => {
      const commonParams: { [key: string]: string } = {};

      if (searchParams.get('source')) { commonParams.source = searchParams.get('source') ?? ''; }

      if (searchParams.get('vehicle_identifier')) { commonParams.vehicle_identifier = searchParams.get('vehicle_identifier') ?? ''; }

      if (searchParams.get('vin')) { commonParams.vin = searchParams.get('vin') ?? ''; }

      if (searchParams.get('plate_number')) { commonParams.plate_number = searchParams.get('plate_number') ?? ''; }

      if (searchParams.get('registration_plate')) { commonParams.registration_plate = searchParams.get('registration_plate') ?? ''; }

      return commonParams;

    },
    [searchParams.get('source'), searchParams.get('vehicle_identifier'), searchParams.get('vin'), searchParams.get('plate_number'), searchParams.get('registration_plate')]);
};

export default useReliableCatalogCarQueryParams;