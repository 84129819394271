import React from 'react';
import './DiagnosticItems.scss';
import { createSearchParams, generatePath, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DiagnosticItem from './DiagnosticItem/DiagnosticItem';
import { IDiagnostic } from './diagnostics';
import { getURLParams } from 'utils/marketplace';
import useReliableCatalogCarQueryParams from '../../utils/hooks/useReliableCatalogCarQueryParams';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';

interface IUrlParams {
  source?: string;
  vehicle_identifier?: string;
}

type Props = {

}

const DiagnosticItemsView: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const lastRouteSegment = location.pathname.split('/').pop();
  const { user } = useSelector((state: RootState) => state.userData);

  const diagnostics: IDiagnostic[] = [
    {
      route: 'actia',
      name: '_actia',
      button_name: '_diagnostic_item_button_text',
      disabled: user?.client_settings.actia === false
    },
    // {
    //   route: 'actia2',
    //   name: '_actia2',
    //   button_name: '_diagnostic_item_button_text',
    //   disabled: user?.client_settings.actia === false
    // }
  ];


  const diagnosticAllowedRoutes = diagnostics.map(a => a.route);
  const { vehicle_id } = useParams<'vehicle_id'>();
  const reliableCatalogCarQueryParams = useReliableCatalogCarQueryParams();


  const isActive = (item: IDiagnostic) => {

    if (diagnosticAllowedRoutes.includes(String(lastRouteSegment))) {
      return lastRouteSegment === item.route;
    }

    return undefined;
  };

  const handleClick = (route: string) => {
    const paramsObject = { ...reliableCatalogCarQueryParams };

    navigate({
      pathname: generatePath(route, { vehicle_id }),
      search: `?${createSearchParams(paramsObject)}`
    });
  };

  return (
    <div className="diagnostic-items">
      {
        diagnostics.map((item, value) => {
          return (
            <DiagnosticItem disabled={item.disabled} selected={isActive(item)} key={item.route} name={t(item.name)} buttonName={t(item.button_name)} onClick={() => handleClick(item.route)} />
          );
        })
      }
    </div>
  );
};

DiagnosticItemsView.displayName = 'DiagnosticItemsView';

export default DiagnosticItemsView;