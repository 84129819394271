import React from 'react';
import './ErrorCodesSearch.scss';
import { useTranslation } from 'react-i18next';
import TextInput from 'components/inputs/TextInput/TextInput';
import {UseFormGetValues, UseFormHandleSubmit, UseFormRegister} from 'react-hook-form';
import {useSelector} from 'react-redux';
import {RootState} from '../../../store/store';

type Props = {
  handleSearchByErrorCode;
  handleSearchByKeyword;
  handleSubmit: UseFormHandleSubmit<IErrorSearchForm>;
  onSubmit: (data: object) => void;
  register: UseFormRegister<IErrorSearchForm>;
  getValues: UseFormGetValues<IErrorSearchForm>;
  einaVtsLink: string;
};

const ErrorCodesSearchView: React.FC<Props> = (props) => {
  const { t }= useTranslation();
  const {user} = useSelector((state: RootState) => state.userData);

  return (
    <div className="error-codes-header">
      <form className="form" onSubmit={props.handleSubmit(props.onSubmit)}>
        <div className="error-codes-search">
          <div className="">
            <label htmlFor="#search-code">{t('_error_code')}</label>
            <TextInput
              {...props.register(
                'code',
                {
                  required: false,
                  pattern: {
                    value: /[0-9a-zA-Z]{2,}/i,
                    message: t('_empty_field')
                  },
                })}
              onClick={() => props.handleSearchByErrorCode(props.getValues('code'))}
              placeholder={t('_error_placeholder_CODE')}
              withIcon
              disabled={user?.client_rmi_modules_type === 'none'}
            />
          </div>
          <div className="text">{t('_or')}</div>
          <div className="">
            <label htmlFor="#search-keyword">{t('_search_keyword')}</label>
            <TextInput
              {...props.register(
                'keyword',
                {
                  required: false,
                  pattern: {
                    value: /[0-9a-zA-Z]{2,}/i,
                    message: t('_empty_field')
                  },
                })}
              onClick={() => {
                props.handleSearchByKeyword(props.getValues('keyword'));
              }}
              placeholder={t('_error_placeholder_KEYWORD')}
              // disabled={isLoading}
              withIcon
              disabled={user?.client_settings.eina_subscription === false}

            />
          </div>
        </div>
      </form>
      {user?.client_settings.eina_subscription && props.einaVtsLink && (
        <div className="error-codes-login-vts">
          <a className="default-button" href={props.einaVtsLink} target="_blank" rel="noreferrer">
            { t('_database_solution') }
          </a>
        </div>
      )}
    </div>
  );
};

ErrorCodesSearchView.displayName = 'ErrorCodesSearchView';

export default ErrorCodesSearchView;

export interface IErrorSearchForm {
  code?: string;
  keyword?: string;
}