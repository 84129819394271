import React, { useEffect } from 'react';
import { getBackendAssetsBasePath } from 'utils/marketplace';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const { $ } = global;

const Ebook: React.FC = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const categoryName = urlParams.get('name');
  const pdfName = urlParams.get('pdf_name');

  useEffect(() => {
    let marketplace = process.env.REACT_APP_MARKETPLACE;

    if (window.location.href.indexOf('automotor') !== -1) {
      marketplace = 'Automotor';
    } else if (window.location.href.indexOf('amerigo') !== -1) {
      marketplace = 'Amerigo';
    }

    document.title = `${categoryName || 'PDF'} - ${marketplace}`;
  });

  useEffect(() => {
    $(document).ready(() => {
      $('#container').flipBook({
        pdfUrl: `${getBackendAssetsBasePath()}${pdfName}`,

        // pdfUrl: 'http://automotor-api.amerigointernational.pro/e_book/pdf/vannes-egr.pdf',
      });
    });
  }, []);

  return <div id="container" />;
};

Ebook.displayName = 'Ebook';

export default Ebook;
