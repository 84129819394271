import React, { useEffect, useState } from 'react';
import GeneralSearch from 'components/general-search/GeneralSearch';
import SideNews from 'components/news/side-news/SideNews';
import MainNews from 'components/news/main-news/MainNews';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import { IUser } from 'models/IUser';
import Promotion from 'components/Promotions/Default/Promotion';

import { Trans, useTranslation } from 'react-i18next';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import GlobalSearchInput from '../../components/inputs/GlobalSearchInput/GlobalSearchInput';
import useHttp from 'utils/hooks/useHttp';
import { INews } from 'utils/hooks/http/news.http';
import Spinner from 'components/spinner/Spinner';
import { IPromotionDetails } from 'utils/hooks/http/promotions.http';
import { useNavigate } from 'react-router-dom';
import { retailerCatalogItems } from 'applications/automotor/components/espace/client/catalog/Catalog.map';
import { ICatalogModel } from 'applications/automotor/components/espace/client/catalog/catalog.model';

import SwiperSlider from 'components/SwiperSlider/SwiperSlider';
import { SwiperSlide } from 'swiper/react';
import ProductCategoryItemNew from 'components/Item/ProductCategoryItemNew/ProductCategoryItemNew';
import './Home.scss';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { Marketplaces } from 'utils/constants/applications';
import { getCurrentMarketplace } from 'utils/marketplace';

type Props = {
  handleLogout: () => void;
  user: IUser | null;
};

const HomeView: React.FC<Props> = () => {
  const { t, i18n } = useTranslation();
  const { isMobile, isTablet } = useDeviceDetect();
  const [mainNews, setMainNews] = useState<INews>();
  const [news, setNews] = useState<INews[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingPromo, setLoadingPromo,] = useState<boolean>(false);
  const http = useHttp();
  const navigate = useNavigate();
  const [promotionItems, setPromotionItems] = useState<IPromotionDetails[]>([]);
  const { user } = useSelector((state: RootState) => state.userData);
  const [userData, setUserData] = useState<IUser>(user as IUser);


  function getNews() {
    setLoading(true);
    http.news.getNews({}).then((res) => {
      const data = res.data.data;

      const firstNews = data.shift();

      setMainNews(firstNews);
      setNews(data);
    }
    ).finally(() => setLoading(false));
  }

  useEffect(() => {
    getNews();
    getPromotions();
  }, [i18n.language]);


  function getPromotions() {
    setLoadingPromo(true);
    http.promotions.activePromotions().then((res) => {
      setPromotionItems(res.data);
    }).finally(() => { setLoadingPromo(false); })
      .catch(() => {
        setLoadingPromo(false);
      });
  }

  useEffect(() => {
    getNews();
  }, [i18n.language]);
  
  useEffect(()=>{
    if(user){
      setUserData(user);
    }
  },[user]);

  const filteredCatalogItems = getCurrentMarketplace() ===  Marketplaces.RETAILER || getCurrentMarketplace() === Marketplaces.PUNCHOUT ? retailerCatalogItems(userData).filter((item) => item.disabled === false) : [];

  return (
    <div className="home">
      <div className="retailer-catalog-search">
        <div className="header-search">
          <div className="title">
            <Trans i18nKey="_home_title" components={{ b: <b /> }} />
          </div>
          {/* <div className="subtitle">
            {t('_run_search_with_saved_filters')} <FontAwesomeIcon icon={faArrowRight} />
          </div> */}
          <div className="header-search-input">
            <GlobalSearchInput
              // value=""
              placeholder={t('_free_search')}
              size="l"
              withIcon
            />
          </div>
        </div>
        {!isMobile ? (
          filteredCatalogItems.length > 0 &&
          <div className="retailer-catalog-items">
            {filteredCatalogItems.map((item: ICatalogModel, index: number) => {
              return (<ProductCategoryItemNew text={t(item.text)} image={item.image} onClick={() => navigate(item.link)} key={index} />);
            })}
          </div>
        ) : (
          filteredCatalogItems.length > 0 &&
          <div className="retailer-catalog-items">
            <SwiperSlider slidesPerView="auto" spaceBetween={0} slideToIndex="auto" loop>
              {filteredCatalogItems.map((item: ICatalogModel, index: number) => {
                return (
                  <SwiperSlide key={index} className="swiper-slide swiper-navbar-item">
                    <ProductCategoryItemNew text={t(item.text)} image={item.image} onClick={() => navigate(item.link)} />
                  </SwiperSlide>
                );
              })}
            </SwiperSlider>
          </div>
        )}
        <div className="general-search">
          <GeneralSearch />
        </div>
      </div>
      <div className="retailer-home-content">
        <div className="promotions">
          <div className="promotions-slider">
            {

              loadingPromo ? <Spinner class="small" /> :
                promotionItems.length ?
                  <Promotion
                    items={promotionItems}
                    slidesPerView={isMobile || isTablet || promotionItems.length == 1 ? 'auto' : 2}
                    navigation={!(isMobile || isTablet) && promotionItems.length != 1}
                    pagination={isMobile || isTablet}
                    autoplay={promotionItems.length != 1}
                    loop={promotionItems.length != 1}
                  />
                  : null
            }
          </div>
        </div>
        <div className={`news container-fluid-large  ${loading ? 'loading' : ''}`}>
          {loading ? <Spinner class="small" /> : (
            mainNews || news.length ? (
              <React.Fragment>
                <div className="main-news">
                  <MainNews data={mainNews} />
                </div>
                <div className="side-news">
                  {news.map(article => {
                    return (
                      <SideNews key={article.id} data={article} />
                    );
                  })}
                  <div className="side-news-button">
                    <DefaultButton text={t('_all_the_news')} onClick={() => navigate('/news')} />
                  </div>
                </div>
              </React.Fragment>
            ) : null
          )}
        </div>
        {/* <div className="news-flux-rss">
          <SwiperSlider
            slidesPerView={rssSlidesPerView}
            spaceBetween={isMobile || isTablet ? 10 : 30}
            navigation={isMobile || isTablet}
            loop
            breakpoints={{
              990: {
                slidesPerView: 4,
                centeredSlides: true,
              },
              1200: {
                slidesPerView: rssSlidesPerView,
              }
            }}
          >
            <SwiperSlide className="swiper-slide">
              <SideRss />
            </SwiperSlide>
            <SwiperSlide className="swiper-slide">
              <RufinusItem />
            </SwiperSlide>
            <SwiperSlide className="swiper-slide">
              <RufinusItem />
            </SwiperSlide>
            <SwiperSlide className="swiper-slide">
              <RufinusItem />
            </SwiperSlide>
            <SwiperSlide className="swiper-slide">
              <RufinusItem />
            </SwiperSlide>
          </SwiperSlider>
        </div> */}
      </div>
    </div>
  );
};

HomeView.displayName = 'HomeView';

export default HomeView;
