import { IClientSettings } from './IUser';

export type IClientContact = {
  id: number;
  client: IClientBusiness;
  first_name: string;
  last_name: string;
  gender: string;
  email: string;
  is_active: boolean;
  mobile: string;
  phone: string;
  roles: string[];
  language: string;
  commentary: string;
};

export type IClientResponse = {
  data: IClient[];
  page: number;
  per_page: number;
  total_count: number;
  total_pages: number;
};

export type IClientInvitationResponse = {
  data: IClientInvitation[];
  page: number;
  per_page: number;
  total_count: number;
  total_pages: number;
};

export type IClientInvitation = {
  id: number;
  full_name: string;
  address: string;
  email: string,
  phone: string,
  status: boolean;
  created_at: string;
  seen: boolean;
  distributor: {
    id: number;
    name: string;
  };
  store: {
    id: number;
    name: string;
  }
  user_account: boolean
  approval?
}

export type IClientCountResponse = {
  data: number;
};

export type ICodeOption = {
  id: number | string;
  code: string;
}

export type IOption = {
  id: number | string;
  name: string;
}

export type IClientEdit = {
  is_active?
  name: string;
  image: string;
  commentary: string;
  country
  language?
  client_business: string | number;
  business_type_id?
  phone: string;
  address: string;
  zipcode: string;
  client_erp_id: string
};

export type IClient = {
  addresses: IClientAddress[];
  client_business: IClientBusiness;
  client_id: number;
  client_settings: IClientSettings;
  client_subscription: {
    start_date: string
    end_date: string
  }
  commentary: string;
  country: ICodeOption;
  created_by: IClient;
  created_date: Date;
  currency: IClient;
  delivery_fee: number;
  delivery_fee_apply_before: number;
  invoice_address: string;
  is_active: boolean;
  language: ICodeOption;
  mailing: string;
  name: string;
  image: string;
  price_strategy: IClientPriceStrategy;
  repere: string;
  updated_by: IClient;
  updated_date: Date;
  url: string;
  zone_commercial: string;
  activate?: boolean;
  client_erp_id: string,
  client_logo: string,
};

export type IClientBusiness = {
  id: number;
  name: string;
};

export type IClientData = {
  id: number;
  code: string;
};

export type IClientPriceStrategy = {
  marge: string;
  strategy: IClient;
  value: string;
};

export type IClientAddress = {
  addressL1: string; // address
  addressL2: string;
  addressL3: string;
  addressL4: string;
  addressL5: string;
  addressL6: string; // zip code
  addressL7: number;
  tel: string;
};

export type ICreateClientForm = IClientContactsForm & IClientDetailsForm;

export type IClientDetailsForm = {
  client_name: string;
  client_is_active: string;
  client_commentary: string;
  client_business_type_id: string;
  client_country_id: string;
  client_language: string;
  client_phone: string;
  client_address: string;
  client_zipcode: string;
  client_image?: Blob
};

export type IClientContactsForm = {
  gender?: string,
  is_active?: string,
  language?: string;
  first_name?: string,
  last_name?: string,
  email?: string,
  phone?: string,
  mobile?: string,
  password?: { first?: string, second?: string },
  commentary?: string;
  has_password?: boolean;
  client_erp_id?: string
};

export enum IClientFilters {
  ID = 'id',
  CLIENT_ERP_ID = 'client_erp_id',
  // CITY = 'address_l6',
  NAME = 'name',
  ACTIVE = 'is_active'
}

export type IFilterParams<T> = {
  filterBy: T;
  filterValue: string;
  page: number;
  perPage: number;
};

export type IClientsFilterParams = IFilterParams<IClientFilters>;


export type IClientUserSettings = {
  rmi_modules_type?: string;
  plate_number_search?: boolean;
  vrm_lookup?: boolean;
  eina_subscription?: boolean;
  diagnostic?: {
    actia?: boolean;
  };
  actia?: boolean;
  plate_number_start_date?: string | null,
  plate_number_end_date?: string | null,
  rmi_modules_start_date?: string | null,
  rmi_modules_end_date?: string | null,
  subscription_start_date?: string | null,
  subscription_end_date?: string | null,
  work_cost_t1?: number
  rmi_modules_subscription_period?
  plate_number_subscription_period?
}

export enum IInvitationFilters {
  NAME = 'full_name',
  EMAIL = 'email',
  SEEN = 'seen',
}

export type IInvitationsFilterParams = IFilterParams<IInvitationFilters>;


export type IClientErpID = {
  id: number;
  name: string
  erpClientId: string
};
