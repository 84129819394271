import React, {useMemo} from 'react';
import './ActiaTable.scss';
import Spinner from '../../spinner/Spinner';
import {Column, useExpanded, useTable} from 'react-table';
import {IData} from '../AutomotorTable/AutomotorTableData';
import {IActiaTableData, ISessionModal} from '../../DiagnosticItems/Actia/actia';
import {useTranslation} from 'react-i18next';
import TableMoreInformation from '../TableMoreInformation/TableMoreInformation';

type Props = {
  columns:Column<IActiaTableData>[];
  data: IActiaTableData[];
  loading: boolean;
  hiddenColumns?: string[];

}

const ActiaTableView:React.FC<Props> = (props) => {
  const {t} = useTranslation();

  const initialState =  props.hiddenColumns ? {
    'hiddenColumns': props.hiddenColumns
  } : {};

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns: props.columns,
    data: props.data,
    initialState
  },
  useExpanded);

  return (
    <div className="actia-table">

      <table {...getTableProps()}>
        <thead className="headings">
          {headerGroups.map((headerGroup, index) => {
            return (
              <tr key={headerGroup.headers[index].id} className="row">
                {headerGroup.headers.map((column) => {
                  const { key, ...restColumn } = column.getHeaderProps();

                  return (
                    <th key={key} {...restColumn} className="heading">
                      {column.render('Header')}
                    </th>
                  );
                })}
              </tr>
            );
          })}
        </thead>

        {
          props.loading ?
            <tbody className="body">
              <tr className="no-data">
                <td colSpan={9}>
                  <Spinner class="extra-small"/>
                </td>
              </tr>
            </tbody>:
            props.data.length ?
              <tbody {...getTableBodyProps} className="body">
                {rows.map((row) => {
                  prepareRow(row);
                  const { key, ...restRowProps } = row.getRowProps();

                  return (
                    <React.Fragment key={key}>
                      <tr {...restRowProps} className="row">
                        {row.cells.map((cell) => {
                          const { key, ...restCellProps } = cell.getCellProps();

                          return (
                            <td key={key} {...restCellProps} className="cell">
                              {cell.render('Cell')}
                            </td>
                          );
                        })}
                      </tr>
                      {row.isExpanded ? TableMoreInformation({ row, hiddenColumns:props.hiddenColumns }) : null}
                    </React.Fragment>
                  );
                })}
              </tbody>
              :
              <tbody className="body">
                <tr className="no-data">
                  <td colSpan={9}>{t('_no_data')}</td>
                </tr>
              </tbody>
        }
      </table>
    </div>

  );
};

ActiaTableView.displayName = 'ActiaTableView';

export default ActiaTableView;
