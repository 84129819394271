import { RootState } from 'store/store';
import { createDeepEqualSelector } from './index';

export const categoriesSelector = (state: RootState): RootState['categories'] => state.categories;

export const getActiveCategory = createDeepEqualSelector(categoriesSelector, (categories) => categories.activeCategory);

export const getCategories = createDeepEqualSelector(categoriesSelector, (categories) => categories.categories);

export const getCategorySidebarShow = createDeepEqualSelector(categoriesSelector, (categories) => categories.showCategorySidebar);

export const getCategorySidebarDetailsShow = createDeepEqualSelector(
  categoriesSelector,
  (categories) => categories.showCategoryDetailsSidebar,
);

export const getIsLoadingCategories = createDeepEqualSelector(categoriesSelector, (catalogs) => catalogs.isLoading);
