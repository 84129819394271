import React, { useEffect, useState } from 'react';
import VehicleIframe from 'pages/CatalogVehicle/TechnicalDocumentation/VehicleIframe/VehicleIframe';
import useHttp from 'utils/hooks/useHttp';
import { getURLParams } from 'utils/marketplace';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import {
  getCurrentCountryRMI,
  getCurrentLanguageRMI,
  replaceTecRmiIframeBrandingInfo
} from 'utils/rmi/rmi-utils/rmi-utils';
import i18n from 'i18n';
import './TechnicalDocumentation.scss';

interface IUrlParams {
  code?: string;
  manual_id?: string;
}

const TechnicalDocumentation: React.FC = () => {

  const http = useHttp();
  const [loading, setLoading] = useState<boolean>(false);
  const { code }: IUrlParams = getURLParams(location.search);
  const { manual_id }: IUrlParams = getURLParams(location.search);

  const { typeId } = useSelector((state: RootState) => state.tecrmi);
  const [vehicleManual, setVehicleManual] = useState<string>('');

  useEffect(() => {

    setVehicleManual('');

    if (code && code.length > 1) {

      fetchRMIVehicleErrorCodeHtml(code);
    }

    if (manual_id) {
      fetchRMIVehicleManualHTML(manual_id);
    }

  }, [typeId, code, manual_id, i18n.language]);

  function fetchRMIVehicleErrorCodeHtml(code: string) {
    if ((code).length > 1)
    {
      setLoading(true);

      http.rmiManuals.fetchRMIVehicleErrorCodeHtml({
        typeId: typeId ?? 52043, // FOR DEV PURPOSES
        countryCode: getCurrentCountryRMI(i18n.language),
        languageCode: getCurrentLanguageRMI(i18n.language),
        errorCode: code,
      }).then((res) => {
        setVehicleManual(replaceTecRmiIframeBrandingInfo(res.data));

      }).finally(() => {
        setLoading(false);
      });}
  }

  function fetchRMIVehicleManualHTML(manualId) {
    if (manualId) {
      setLoading(true);
      http.manualsHttp.fetchRMIVehicleManualHtml({
        typeId,
        countryCode: getCurrentCountryRMI(i18n.language),
        languageCode: getCurrentLanguageRMI(i18n.language),
        printView: true,
        manualId,
      })
        .then(({ data }) => {
          setVehicleManual(data);

        })
        .catch((e: Error) => console.log(e))
        .finally(() => setLoading(false));
    }
  }


  return (
    <div className="technical-documentation-container">
      <VehicleIframe loading={loading} vehicleIframe={vehicleManual!} />
    </div>
  );
};

TechnicalDocumentation.displayName = 'TechnicalDocumentation';

export default TechnicalDocumentation;
