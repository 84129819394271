import React from 'react';

import {  Navigate, Route } from 'react-router-dom';
import ErrorPage404 from 'pages/ErrorPage404/ErrorPage404';
import ErrorPage500 from 'pages/ErrorPage500/ErrorPage500';
import RestorePassword from 'pages/ForgotPassword/RestorePassword/RestorePassword';
import ForgotPassword from 'pages/ForgotPassword/ForgotPassword';

const useCommonRoutes = (): JSX.Element => {
  return (
    <React.Fragment>
      <Route path="/restore-password/:id" element={<RestorePassword />}></Route>
      <Route path="/forgot-password" element={<ForgotPassword />}></Route>
      <Route path="/404" element={<ErrorPage404 />} />
      <Route path="/500" element={<ErrorPage500 />} />
      <Route path="*" element={<Navigate to="/404" replace />} />
    </React.Fragment>
  );
};

export default useCommonRoutes;
