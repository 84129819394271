import React from 'react';
import SkeletonSpinner from 'components/skeleton-spinner/SkeletonSpinner';
import { useTranslation } from 'react-i18next';
import './PopupInfo.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faClock} from '@fortawesome/free-solid-svg-icons';
import {IVehicleRepairItemWork} from '../../utils/rmi/interface/repair-items.interface';

type Props = {
  loading: boolean;
  data?: IVehicleRepairItemWork;
};

const PopupInfo: React.FC<Props> = (props) => {
  const {t} = useTranslation();

  return (
    <div className="popup-info-container">
      <div className="popup-info-header">
        <div className="title">
          <FontAwesomeIcon icon={faClock} />
          {t('_work_time')}
        </div>
      </div>
      {props.loading ? (
        <SkeletonSpinner />
      ) : (
        <React.Fragment>

          <div className="popup-info-content">
            {props.data ? (
              <ul>
                <li className="item">{props.data?.WorkText}</li>
              </ul>
            ):(
              <div className="no-data">{t('_no_data').toLocaleUpperCase()}</div>
            )}
          </div>
          <div className="popup-info-footer">
            {props.data &&
              <div className="title">
                {t('_work_total')} {props.data?.WorkTime} h
              </div>
            }
          </div>
        </React.Fragment>
      )}
    </div>
  );
};


export default PopupInfo;
