import { IHttpResponse, serverAxios } from '../../http';
import { IOption } from '../../../interfaces/select';
import {
  IProformaAmerigoResponse,
  IProformaAmerigoRowItem
} from './proforma.http';

class ProformaAmerigoHttp {
  private static route = 'amerigo/supplier-mapping-options';
  private static uploadProformaAmerigo = '/amerigo/upload-csv';
  private static orderFile = '/amerigo-order/file/checkout';

  public async getSupplierMappingOptions(params: { catalog_id: string | undefined }): Promise<IHttpResponse<IOption[]>> {
    {
      return serverAxios.get(ProformaAmerigoHttp.route, { params });
    }
  }

  public async uploadProformaAmerigo(data: FormData): Promise<IHttpResponse<IProformaAmerigoResponse>> {
    return serverAxios.post(ProformaAmerigoHttp.uploadProformaAmerigo, data);
  }

  public async orderProformaAmerigoFile(params: { dlnr: string; catalog_id?: string }, data: IProformaAmerigoRowItem[]): Promise<IHttpResponse<null>> {
    return serverAxios.post(ProformaAmerigoHttp.orderFile, data, { params });
  }


}

export default ProformaAmerigoHttp;
