
import TextInput from 'components/inputs/TextInput/TextInput';
import Select from 'react-select';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useHttp from 'utils/hooks/useHttp';
import './TyreEdit.scss';

import Spinner from 'components/spinner/Spinner';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { notify } from 'utils/marketplace';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { IFilterValue, ITyreInfo, ITyreSelect, ITyreValidate } from 'interfaces/tyre';
import UploadImage from 'components/UploadImage/UploadImage';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import UploadPdf from 'components/UploadPdf/UploadPdf';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';

type Props = {
  setOpenModal: (e: boolean) => void;
  setSubmit: (e: boolean) => void;
  tyre: number | undefined
};


const TyreEdit: React.FC<Props> = (props) => {
  let isMounted = true;
  const { t } = useTranslation();
  const http = useHttp();
  const { isMobile, isTablet } = useDeviceDetect();
  const [speedRatings, setSpeedRatings] = useState<ITyreSelect[]>([]);
  const [speedRating, setSpeedRating] = useState<ITyreSelect | null>();

  const [seasons, setSeasons] = useState<ITyreSelect[]>([]);
  const [season, setSeason] = useState<ITyreSelect | null>();
  const [loadIndexs, setLoadIndexs] = useState<ITyreSelect[]>([]);
  const [loadIndex, setLoadIndex] = useState<IFilterValue | null>();

  const [brands, setBrands] = useState<ITyreSelect[]>([]);
  const [brand, setBrand] = useState<ITyreSelect | null>();
  const [file, setFile] = useState<File[]>([]);
  const [image, setImage] = useState<File[]>([]);
  const [tyre, setTyre] = useState<ITyreInfo>();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingErpPartInfo, setLoadingErpPartInfo] = useState<boolean>(false);
  const user = JSON.parse(localStorage.getItem('userData')!);


  const validate = Yup.object().shape({
    reference: Yup.string().trim().required(t('_fild_required')),
    diameter: Yup.number().required().typeError(t('_must_number')),
    width: Yup.number().required().typeError(t('_must_number')),
    height: Yup.number().required().typeError(t('_must_number')),
    description: Yup.string().trim().required(t('_fild_required')),
    short_description: Yup.string().trim().required(t('_fild_required')),
    gencode: Yup.string().trim().required(t('_fild_required')),
    load_index: Yup.object().shape({
      label: Yup.string().required(t('_fild_required')),
      value: Yup.string().required(t('_fild_required')),
    }),
    speed_rating: Yup.object().shape({
      label: Yup.string().required(t('_fild_required')),
      value: Yup.string().required(t('_fild_required')),
    }),
    // image: Yup.string().required(t('_fild_required')),

  });

  const { getValues, resetField, register, setValue, clearErrors, handleSubmit, control, formState: { errors }, } = useForm<ITyreValidate>({ resolver: yupResolver(validate) });

  useEffect(() => {
    if (props.tyre && isMounted) {
      Promise.all([
        http.tyreHttp.getSpeedRating(),
        http.tyreHttp.getSeason(),
        http.tyreHttp.getLoadIndex(),
        http.tyreHttp.getBrands({ per_page: 1000 }),
        http.tyreHttp.getTyreInfo(props.tyre)])
        .then((res) => {

          const speedRatingData = res[0].data.map((item) => {
            return { value: item.id, label: item.index };
          });

          setSpeedRatings(speedRatingData);


          const seasonsData = res[1].data.map((item) => {
            return { value: item.id, label: item.name };
          });

          setSeasons(seasonsData);

          const loadIndexsData = res[2].data.map((item) => {
            return { value: item.id, label: String(item.index) };
          });

          setLoadIndexs(loadIndexsData);
          const setBrandData = res[3].data.data.map((item) => {
            return { value: item.id, label: item.name };
          });

          setBrands(setBrandData);
          setTyre(res[4].data);

          setBrand({ value: res[4].data.brand.id, label: res[4].data.brand.name });

          setSeason({ value: res[4].data?.season.id, label: res[4]?.data.season.name });

          if (res[4].data?.load_index.id) {

            setLoadIndex({
              value: Number(res[4].data?.load_index.id),
              label: String(res[4].data?.load_index.index),
            });

            setValue('load_index', {
              value: Number(res[4].data?.load_index.id),
              label: String(res[4].data?.load_index.index),
            });
            //   clearErrors('load_index'); // Clear validation error if it exists
          }


          if (res[4].data?.speed_rating.id) {

            setSpeedRating({ value: res[4].data?.speed_rating.id, label: res[4]?.data.speed_rating.index });

            setValue('speed_rating', { value: res[4].data?.speed_rating.id, label: res[4]?.data.speed_rating.index });

          }


          setValue('image', res[4].data?.image ? res[4].data?.image : '');

        });
    }


    return () => {
      isMounted = false;
    };

  }, []);


  const getErpPart = () => {
    let erpClientId = null;
    const values = getValues();
    const reference = values?.reference.trim();
    const brand_id = brand?.value;

    if (localStorage.getItem('infoSelectClientErp')) {
      const info = JSON.parse(localStorage.getItem('infoSelectClientErp') as string);

      erpClientId = info.erpClientId;
    }

    if (erpClientId == null || reference == '' || brand_id == undefined) {
      if (erpClientId == null) {
        notify(t('_select_client'), 'error', t('_error'));
      }

      if (reference == '') {
        notify(t('_reference'), 'error', t('_error'));
      }

      if (brand_id == undefined) {
        notify(t('_brand_id'), 'error', t('_error'));
      }

      return;
    } else {

      const param = {
        erp_id: erpClientId,
        reference: reference,
        brand_id: brand_id,
        type: 'tyre'
      };

      setLoadingErpPartInfo(true);

      if (values.short_description) {
        resetField('short_description');
      }

      http.retailerHttp.getErpPart(param).then((data) => {

        if (data.data?.description == undefined) {
          notify(t('_not_found_description'), 'error', t('_error'));
        } else {
          setValue('short_description', data.data?.description);
        }

      }).finally(() => {
        setLoadingErpPartInfo(false);


      });
    }


  };


  const onSubmit = async () => {

    setLoading(true);
    const values = getValues();

    const formData: FormData = new FormData();

    formData.append('reference', values.reference.trim());
    formData.append('brand_id', String(brand?.value));
    formData.append('season_id', String(season?.value));
    formData.append('width', String(values.width).trim());
    formData.append('height', String(values.height).trim());
    formData.append('load_index', String(loadIndex?.value));
    formData.append('speed_rating', String(speedRating?.value));
    formData.append('description', values.description.trim());
    formData.append('short_description', values.short_description.trim());
    formData.append('diameter', String(values.diameter).trim());
    formData.append('top_offer', String(values.top_offer));
    formData.append('gencode', String(values.gencode).trim());

    if(user?.hasTopSales){
      formData.append('top_sales', String(values.top_sales));
    }

    if (file.length) {
      formData.append('file', file[0]);
    }

    if (image.length) {
      formData.append('image', image[0]);
    }


    http.tyreHttp.postTyre(Number(props?.tyre), formData).then(() => {
      notify(t('_tyre_updated'), 'success', t('_success'));
      props.setOpenModal(false);
      props.setSubmit(true);
    }).catch((error) => {
      console.log(error);
      let erorrs = '';

      Object.entries(error).forEach(([key, value]) => {
        erorrs += `${t('_' + key)} : ${value} `;
      });


      notify(erorrs, 'error', t('_error'));
      setLoading(false);
    });

  };


  return (
    <div className="tyre-edit-container" >
      <div className="close-modal" onClick={() => { props.setOpenModal(false); }}><FontAwesomeIcon icon={faClose} /></div>

      <div className="title">
        {t('_tyre_title_edit')}
        <br />
        <span>{t('_tyre_title_edit_catalog')}</span>
      </div>
      {
        !tyre ?
          <div className="spinner-info"> <Spinner />  </div> :
          <React.Fragment >
            <div className="info-item">
              {t('_tyre_item_count')}
              <br />
              <span>{tyre.brand.name} {tyre?.reference}</span>
            </div>

            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="tyre-edit-body">
                <div className="row">
                  <div className="container">
                    <label>
                      {t('_tyre_brand')} <span className="placeholderRed"> * </span>
                    </label>
                    <Controller
                      control={control}
                      name="brand"
                      render={({ field: { onChange } }) => (
                        <Select
                          value={brand}
                          className="select-dropdown "
                          onChange={(val) => {
                            onChange(val?.value);
                            setBrand(val);
                          }}
                          options={brands}
                          isSearchable={!isMobile && !isTablet}
                        />
                      )}
                    />
                  </div>
                  <div className="container ">
                    <label>
                      {t('_season')} <span className="placeholderRed"> * </span>
                    </label>
                    <Controller
                      control={control}
                      name="season"
                      render={({ field: { onChange } }) => (
                        <Select
                          value={season}
                          className="select-dropdown "
                          onChange={(val) => {
                            onChange(val?.value);
                            setSeason(val);
                          }}
                          options={seasons}
                          placeholder={t('_oil_select_category')}
                          isSearchable={!isMobile && !isTablet}
                        />
                      )}
                    />
                  </div>
                  <div className="container">
                    <label>
                      {t('_load_index')} <span className="placeholderRed"> * </span>
                    </label>
                    <Controller
                      control={control}
                      name="load_index"
                      render={({ field: { onChange } }) => (
                        <Select
                          value={loadIndex}
                          className="select-dropdown "
                          onChange={(val) => {
                            onChange(val);
                            setLoadIndex(val);

                          }}
                          options={loadIndexs}
                          placeholder={t('_oil_select_category')}
                          isSearchable={!isMobile && !isTablet}
                        />
                      )}
                    />
                    <p className="error" >{errors.load_index?.label?.type == 'required' ? errors.load_index?.label.message : ''}</p>
                  </div>

                  <div className="container">
                    <label>
                      {t('_speed_rating')} <span className="placeholderRed"> * </span>
                    </label>
                    <Controller
                      control={control}
                      name="speed_rating"
                      render={({ field: { onChange } }) => (
                        <Select
                          value={speedRating}
                          className="select-dropdown "
                          onChange={(val) => {
                            onChange(val);
                            setSpeedRating(val);

                          }}
                          options={speedRatings}
                          placeholder={t('_oil_select_category')}
                          isSearchable={!isMobile && !isTablet}
                        />
                      )}
                    />
                    <p className="error" >{errors.speed_rating?.label?.type == 'required' ? errors.speed_rating?.label.message : ''}</p>
                  </div>
                </div>

                <div className="row">
                  <div className={`container ${errors.reference?.type == 'required' ? 'error' : ''}`}>
                    <label>
                      {t('_reference')} <span className="placeholderRed"> * </span>
                    </label>
                    <TextInput defaultValue={tyre?.reference} {...register('reference')} />
                    <p className="error-message">{errors.reference?.type == 'required' ? errors.reference?.message : ''}</p>
                  </div>

                  <div className={`container ${errors.diameter?.type ? 'error' : ''}`}>
                    <label>
                      {t('_diameter')} <span className="placeholderRed"> * </span>
                    </label>
                    <TextInput defaultValue={tyre?.diameter} {...register('diameter')} />
                    <p className="error-message">{errors.diameter?.type ? errors.diameter?.message : ''}</p>
                  </div>

                  <div className={`container ${errors.width?.type ? 'error' : ''}`}>
                    <label>
                      {t('_width')} <span className="placeholderRed"> * </span>
                    </label>
                    <TextInput defaultValue={tyre?.width} {...register('width')} />
                    <p className="error-message">{errors.width?.type ? errors.width?.message : ''}</p>
                  </div>


                </div>
                <div className="row">
                  <div className={`container ${errors.height?.type ? 'error' : ''}`}>
                    <label>
                      {t('_height')} <span className="placeholderRed"> * </span>
                    </label>
                    <TextInput defaultValue={tyre?.height} {...register('height')} />
                    <p className="error-message">{errors.height?.type ? errors.height?.message : ''}</p>
                  </div>
                  <div className={`container ${errors.gencode?.type == 'required' ? 'error' : ''}`}>
                    <label>
                      {t('_gencode')} <span className="placeholderRed"> * </span>
                    </label>
                    <TextInput defaultValue={tyre?.gencode} {...register('gencode')} />
                    <p className="error-message">{errors.gencode?.type == 'required' ? errors.gencode?.message : ''}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="container top-offer-checkbox-container">
                    <label htmlFor="top_offer">{t('_top_offer')} </label>
                    <input type="checkbox" id="top_offer" defaultChecked={tyre?.top_offer} {...register('top_offer')} />
                  </div>
                  {user?.hasTopSales ?
                    <div className="container top-offer-checkbox-container">
                      <label htmlFor="top_sales">{t('_top_sales')} </label>
                      <input type="checkbox" id="top_sales" defaultChecked={tyre?.top_sales} {...register('top_sales')} />
                    </div>
                    : null}
                </div>
                <div className="row">
                  <div className={`container ${errors.image?.type == 'required' ? 'error' : ''}`}>
                    <UploadImage setFile={setImage} image={tyre?.image} errors={errors} setValue={setValue} clearErrors={clearErrors} />
                    <span className="upload_image_info"> {t('_upload_image_info')}</span>
                  </div>

                  <div className={`container ${errors.file?.type == 'required' ? 'error' : ''}`}>
                    <UploadPdf setFile={setFile} errors={errors} pdf={tyre?.file} clearErrors={clearErrors} setValue={setValue} title="_technical_sheet" />
                  </div>
                </div>

                <div className="row">
                  <div className={`container ${errors.short_description?.type == 'required' ? 'error' : ''}`}>
                    <label>
                      {t('_short_description')} <span className="placeholderRed"> * </span>
                    </label>
                    <TextInput defaultValue={tyre?.short_description} {...register('short_description')} />
                    <p className="error-message">{errors.short_description?.type == 'required' ? errors.short_description?.message : ''}</p>
                  </div>
                </div>

                <div className="row file-container">
                  <div className={`container ${errors.description?.type == 'required' ? 'error' : ''}`}>
                    <div className="description_info">
                      <label>
                        {t('_tyre_description')} <span className="placeholderRed"> * </span>
                      </label>
                      <DefaultButton type="button" red text={t('_get_erp_description')} onClick={() => getErpPart()} isloading={loadingErpPartInfo} />
                    </div>
                    <textarea rows={7} defaultValue={tyre?.description}
                      {...register('description', {
                        required: true,
                      })}
                    />
                    <p className="error-message">{errors.description?.type == 'required' ? errors.description?.message : ''}</p>
                  </div>


                </div>
              </div>

              <div className="btn-save">
                <button type="submit" className={loading ? 'disabled' : ''}> {loading ? <Spinner class="extra-small" /> : null} <div className="title-btn-save">{t('_tyre_update_article')}</div></button>
              </div>
            </form>
          </React.Fragment>
      }
    </div>
  );
};

TyreEdit.displayName = 'TyreEdit';

export default TyreEdit; 