import { IConsumable } from 'interfaces/consumable';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './ConsumableDetails.scss';

type Props = {
  consumable: IConsumable;
}

const ConsumableDetails: React.FC<Props> = (props) => {
  const consumable = props.consumable;
  const { t } = useTranslation();

  return (

    <div className="consumable-details-wrapper">
      <div className="consumable__header">
        <div className="consumable__header__article">
          <span>{t('_reference')}</span>:
          {` ${consumable.data_supplier_reference_original}`}
        </div>
        <h5 className="consumable__header__title">{`${consumable.data_supplier.name} ${consumable.data_supplier_reference_original}`}</h5>
        <div className="consumable__header__category">
          {`${consumable.description ? consumable.description : '-'}`}
        </div>
      </div>
      <div className="consumable__content">
        {

          Object.entries(consumable.tags).map((characteristic, index) => (
            <div className="content-item" key={index}>
              {
                characteristic[1] ?
                  <React.Fragment>
                    <p className="label">{t(`_consumable_${characteristic[0]}`)}: </p> <p className="value">{`${characteristic[1]}`}</p>
                  </React.Fragment>
                  : null

              }

            </div>
          ))
        }
      </div>
    </div>
  );
};

ConsumableDetails.displayName = 'ConsumableDetails';


export default ConsumableDetails;