import { HttpResponse } from 'utils/hooks/http/history.http';
import { tecRMIAxios } from '../../http';
import { ILinkedDiagnosticsResponce, ITecCompPositionFetchBase, ITecCompPositionFetchLinkedElement, ITecCompPositionFetchLinkedResponce, ITecCompPositionFetchManual, ITecCompPositionGroup } from '../interface/tec-com-positions';


class ComponentsHttpTecRmi {
  private static List = 'Components/List';
  private static Manual = 'Components/ManualHtml';
  private static LinkedWiringDiagram = 'Components/LinkedWiringDiagram';
  private static LinkedDiagnostics = 'Components/LinkedDiagnostics';
  public async fetchRMIVehicleList(params: ITecCompPositionFetchBase): Promise<HttpResponse<ITecCompPositionGroup[]>> {
    return tecRMIAxios.get(ComponentsHttpTecRmi.List, {params});
  }

  public async fetchRMIVehicleManual(params: ITecCompPositionFetchManual): Promise<HttpResponse<string>> {
    return tecRMIAxios.get(ComponentsHttpTecRmi.Manual, {params});
  }

  public async fetchRMIVehicleTecCompPositionLinkedWiringDiagram(params: ITecCompPositionFetchLinkedElement): Promise<HttpResponse<ITecCompPositionFetchLinkedResponce[]>> {
    return tecRMIAxios.get(ComponentsHttpTecRmi.LinkedWiringDiagram, {params});
  }
  public async fetchRMIVehicleTecCompPositionLinkedDiagnostics(params: ITecCompPositionFetchLinkedElement): Promise<HttpResponse<ILinkedDiagnosticsResponce[]>> {
    return tecRMIAxios.get(ComponentsHttpTecRmi.LinkedDiagnostics, {params});
  }
}


export default ComponentsHttpTecRmi;