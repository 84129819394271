import React, { useRef, useState } from 'react';
import { ICarPartData } from '../../../models/car-parts.model';
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Placeholder from 'assets/images/car-part-placeholder.png';
import _ from 'underscore';
import './PartItemPhotoGallery.scss';


type Props = {
  carPart: ICarPartData;
  has360: boolean
}

const PartItemPhotoGallery: React.FC<Props> = (props) => {
  const [, setIsFullscreen] = useState<boolean>(false);
  const imageRef = useRef(null);
  // const [image, setImage] = useState('');
  // const [thumb, setThumb] = useState('');

  const renderImage = item => {
    // checkIfImageExists(item.original, (exists)=> exists ? setImage(item.original) : setImage(Placeholder));

    return <div className="image-gallery-image">
      <img
        alt="car part"
        src={item.original || '/images/car-part-placeholder-big.png'}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src = '/images/car-part-placeholder-big.png';
        }}
      />
    </div>;
  };

  const renderThumb = item => {

    // checkIfImageExists(item.original, (exists)=> exists ? setThumb(item.original) : setThumb(Placeholder));

    return <div className="image-gallery-thumbnail-inner">
      <LazyLoadImage
        alt="car part"
        src={item.original}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src = '/images/car-part-placeholder-big.png';
        }}
      />
    </div>;
  };

  const getImages = () => {
    const { data_supplier_logo, images, image_360 } = props.carPart;
    const imageList: ReactImageGalleryItem[] = [];

    if (props.has360) {
      const imageItem360: ReactImageGalleryItem = {
        renderThumbInner: () => (
          <i style={{ fontSize: '38px', color: '$dark-blue' }} className="material-icons">360</i>
        ),
        renderItem: () => (
          <div className="image-gallery-image">
            <div className="iframe-container">
              <iframe title="image360" src={image_360} scrolling="no" />
            </div>
          </div>
        ),
        original: ''
      };

      imageList.push(imageItem360);
    }

    images
      .filter(image => image.split('.').reverse()[0] !== 'PDF')
      .map(image => {
        const item = {
          original: image,
          thumbnail: image,
        };

        return imageList.push(item);
      });

    if (_.isEmpty(imageList)) {
      const dataSupplierImage = {
        original: data_supplier_logo,
        thumbnail: data_supplier_logo,
      };

      imageList.push(dataSupplierImage);
    }


    return (
      <ImageGallery
        ref={imageRef}
        items={imageList}
        showPlayButton={false}
        showNav
        showFullscreenButton
        renderFullscreenButton={(triggerFullScreen, isFullscreen) => (
          <button
            type="button"
            className={`${!isFullscreen ? 'image-gallery-fullscreen-button' : 'image-gallery-fullscreen-button width'}`}
            // onClick={() => !isFullscreen && triggerFullScreen()}
            onClick={(event) => !isFullscreen && triggerFullScreen(event)}
          >
            {isFullscreen && <i className="fas fa-times" onClick={triggerFullScreen} />}
          </button>
        )}
        showBullets
        onScreenChange={() => setIsFullscreen(prev => !prev)}
        renderItem={renderImage}
        renderThumbInner={renderThumb}
      />
    );
  };


  return (

    <div className={`car-thumbnail ${!props.carPart.images ? 'empty' : ''}`}>
      {getImages()}
    </div>
  );
};

PartItemPhotoGallery.displayName = 'PartItemPhotoGallery';

export default PartItemPhotoGallery;