import React, { PropsWithChildren } from 'react';
import './IconNotification.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

type Props = {
  icon: IconProp;
  number: number;
  onClick: () => void;
};

const IconNotificationView: React.FC<Props> = (props: PropsWithChildren<Props>) => {

  return (
    <div className="icon-notification" onClick={props.onClick}>
      <FontAwesomeIcon icon={props.icon} className="icon" />
      {
        props.number && props.number > 0 ? (
          <span>{props.number}</span>
        ) : null
      }
    </div>
  );
};

IconNotificationView.displayName = 'IconNotificationView';

export default IconNotificationView;
