import React from 'react';
import './CategoriesCell.scss';

type Props = {
  value: string;
  cell?
};

const CategoriesCellView: React.FC<Props> = (props: Props) => {
  return <div className="category-cell-wrapper">{`${props.value ? props.value : ''} ${props.cell.row.original.work_id ? props.cell.row.original.work_id : ''}`}</div>;
};

CategoriesCellView.displayName = 'CategoriesCellView';

export default CategoriesCellView;
