import React from 'react';
import { ReactComponent as Error404 } from 'assets/images/404.svg';
import './ErrorPage404.scss';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

type Prop = {};

const ErrorPage404: React.FC<Prop> = () => {
  const navigate = useNavigate();
  const {t} = useTranslation();

  return (
    <div className="error-page">
      <Error404 />
      <div className="error-page-text">{t('_page_not_found_404')}!</div>
      <div className="go-home-button">
        <DefaultButton text={t('_go_back')} onClick={() => navigate('/')} />
      </div>
    </div>
  );
};

export default ErrorPage404;
