import { HttpResponse } from 'utils/hooks/http/history.http';
import { tecRMIAxios } from '../../http';
import { IRepairItemsWorkList, IVehicleRepairItemWork } from '../interface/repair-items.interface';

class PricesHttp {

  private static PartsForWorks = '/Prices/PartsForWorks';
  private static GenArtsForItemMpIds = '/Prices/GenArtsForItemMpIds';
  private static ItemMpIdForGenArt = '/Prices/ItemMpIdForGenArt';

  public async fetchRMIPartsForWorks(params: {}): Promise<HttpResponse<[]>> {
    return tecRMIAxios.post(PricesHttp.PartsForWorks, {...params});
  }

  public async fetchRMIGenArtsForItemMpIds(params: {}): Promise<HttpResponse<[]>> {
    return tecRMIAxios.post(PricesHttp.GenArtsForItemMpIds, {...params});
  }
    
  public async fetchRMIItemMpIdForGenArt(params: {}): Promise<HttpResponse<[]>> {
    return tecRMIAxios.get(PricesHttp.ItemMpIdForGenArt, { params });
  }
}

export default PricesHttp;
