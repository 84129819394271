
import React from 'react';
import './TableMoreInformation.scss';
import CodeCellView from 'pages/Cart/SupplierCartTable/Cells/CodeCell/CodeCell.view';
import { t } from 'i18next';
import { getMarketplaceBaseUrl } from 'utils/marketplace';

//type ITableMoreInformationRow = Row<MobileTableExampleData> | Row<IActiaTableData> | Row<CartTableItem> | Row<IDetailsList> | Row<IDetailsListFinancial>;

type Props = {
  // row: ITableMoreInformationRow
  row;
  hiddenColumns?: string[];
  extraFieldsNumber?: number;
}

const TableMoreInformation: React.FC<Props> = (props) => {

  const extraFieldsNumber = props.extraFieldsNumber || 1;

  const moreInfo = props.hiddenColumns?.reduce((o, key) => Object.assign(o, { [key]: props.row.original[key] }), {}) ?? {};

  console.log(moreInfo);

  const colSpanLength = props.hiddenColumns ? (Object.values(props.row.original).length + extraFieldsNumber) - props.hiddenColumns?.length : 0;

  return (
    <tr className="more-information">
      <td colSpan={colSpanLength}>
        {
          Object.keys(moreInfo)?.map((key, index) => {
            if (key == 'barCode') {
              return (
                <div key={index} style={{ textAlign: 'center' }}>
                  <span>{`${t('_' + key)}`}</span>:  <CodeCellView src={moreInfo[key]?.image} code={moreInfo[key]?.code} />
                </div>
              );

            }

            else if (key == 'barcode') {
              return (
                <div key={index} style={{ textAlign: 'center' }}>

                  <span>{`${t('_' + key)}`}</span>:  <CodeCellView src={`${getMarketplaceBaseUrl() ?? ''}/${moreInfo[key]?.image}`} code={moreInfo[key]?.code} />
                </div>
              );

            }

            else if (key == 'images' && moreInfo['images']) {
              return (
                <div key={index} style={{ textAlign: 'center' }}>
                  <span>{`${t('_' + key)}`}</span>:   <p key={index}><img width="52px" src={moreInfo[key][0]} /></p>
                </div>
              );

            }
            else if (key == 'image') {
              return (
                <div key={index} style={{ textAlign: 'center' }}>
                  <span>{`${t('_' + key)}`}</span>:   <p key={index}><img width="52px" src={moreInfo[key]} /></p>
                </div>
              );

            }

            else if (key == 'short_description') {
              return (
                <div key={index} style={{ textAlign: 'center' }}>
                  <span>{`${t(key === 'short_description' ? '_short_description' : '_' + key)}`}</span>: {moreInfo[key]}
                </div>
              );

            }

            else {
              return (
                <p key={index} style={{ textAlign: 'center' }}>
                  <span>{`${t(key === 'subcategory' ? '_category' : '_' + key)}`}</span>: {moreInfo[key]}
                </p>
              );
            }
          })
        }
      </td>

    </tr >
  );
};

export default TableMoreInformation;

