import React from 'react';
import { IClientInvitation } from 'models/client.model';
import Spinner from 'components/spinner/Spinner';
import Icon from 'components/Icon/Icon';
import './InvitationView.scss';
import { useTranslation } from 'react-i18next';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import { setNewClientModal } from 'store/slices/clients.slice';
import { useAppDispatch } from 'utils/hooks/redux';

type Props = {
  loading: boolean;
  clientInvitation: IClientInvitation | null;
  refreshData: () => void;
  handleReturnClick: () => void;
};

const InvitationPageView: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleNewClient = () => {
    dispatch(setNewClientModal(true));
  };


  return (
    <div className="content-wrapper-body">
      {props.loading ? <Spinner class="small" /> : (
        <React.Fragment>
          <div className="top-content">
            <div className="return-button-wrapper">
              <Icon name="circleLeftRegular" className="button" onClick={props.handleReturnClick}></Icon>
              <h4 className="title-back">{t('_back_to_invitations_list')}</h4>
              <div></div>
            </div>
            <h1 className="title">{props.clientInvitation?.full_name}</h1>
            {!props.clientInvitation?.user_account ? <div className="new-client-btn">
              <DefaultButton text={t('_new_client')} onClick={handleNewClient} />
            </div> : null}
          </div>
          <hr className="separator"></hr>
          <div className="middle-content">
            <div className="client-wrapper">
              <h4 className="client-title">{t('_pending_invitation').toLocaleUpperCase()}</h4>
              <div className="client-box">
                <div className="name-wrapper--m">
                  <h4 className="client-name">{t('_id')} :</h4>
                  <h6 className="client-name-value">
                    {props.clientInvitation?.id}
                    &#00;
                  </h6>
                </div>
                <div className="name-wrapper--m">
                  <h4 className="client-name">{t('_name')}:</h4>
                  <h6 className="client-name-value">{props.clientInvitation?.full_name}&#00;</h6>
                </div>
                <div className="name-wrapper--m">
                  <h4 className="client-name">{t('_email')} :</h4>
                  <h6 className="client-name-value">
                    {props.clientInvitation?.email}
                    &#00;
                  </h6>
                </div>
                <div className="name-wrapper--m">
                  <h4 className="client-name">{t('_phone')} :</h4>
                  <h6 className="client-name-value">
                    {props.clientInvitation?.phone}
                    &#00;
                  </h6>
                </div >
                <div className="name-wrapper--m">
                  <h4 className="client-name">{t('_address')} :</h4>
                  <h6 className="client-name-value">
                    {props.clientInvitation?.address}
                    &#00;
                  </h6>
                </div >
                <div className="name-wrapper--m">
                  <h4 className="client-name">{t('_store')} :</h4>
                  <h6 className="client-name-value">
                    {props.clientInvitation?.store?.name}
                    &#00;
                  </h6>
                </div >
                <div className="name-wrapper--m">
                  <h4 className="client-name">{t('_creation_date')} :</h4>
                  <h6 className="client-name-value">
                    {props.clientInvitation?.created_at}
                    &#00;
                  </h6>
                </div >
              </div >
            </div >
          </div >
        </React.Fragment >
      )}
    </div >
  );
};

InvitationPageView.displayName = 'InvitationPageView';

export default InvitationPageView;
