import React, { useState } from 'react';
import useHttp from 'utils/hooks/useHttp';
import ContentView from './Content.view';
import { notify } from 'utils/marketplace';
import { useAppDispatch } from 'utils/hooks/redux';
import { setQuantityToolbar } from 'store/slices/cart.slice';

type Props = {
  dlnr?: string
  setTabIndex?: (number) => void
  getSuppliers?
  supplierName?: string;
};

const Content: React.FC<Props> = (props) => {
  const http = useHttp();
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [orderId, setOrderId] = useState<null | number>(null);
  const dispatch = useAppDispatch();

  function handleCheckout() {
    setLoading(true);
    http.cart.supplierCartCheckout(props.dlnr!).then(({ data }) => {
      setOrderId(data);
      http.cart.getCartQuantity().then((res) => {
        dispatch(setQuantityToolbar(res.quantity));
      });
      setOpenModal(true);
    }).catch(() => {
      notify('Error');
    }).finally(() => {
      setLoading(false);
    });
  }

  return <ContentView orderId={orderId} supplierName={props.supplierName} getSuppliers={props.getSuppliers} setTabIndex={props.setTabIndex} openModal={openModal} setOpenModal={setOpenModal} loading={loading} handleCheckout={handleCheckout} dlnr={props.dlnr} />;
};

Content.displayName = 'Content';

export default Content;