import React from 'react';
import { CellProps } from 'react-table';
import useHttp from 'utils/hooks/useHttp';
import { CartTableItem } from '../../SupplierCartTable.model';
import DeleteCellView from './DeleteCell.view';
import { useAppDispatch } from 'utils/hooks/redux';
import { setQuantityToolbar } from 'store/slices/cart.slice';

type Props = CellProps<CartTableItem> & {
  refetch: () => Promise<void>;
  setLoading: (state: boolean) => void;
};

const DeleteCell: React.FC<Props> = (props: Props) => {
  const http = useHttp();
  const dispatch = useAppDispatch();

  const handleDeleteClick = async () => {
    props.setLoading(true);
    await http.order.delete(props.row.original.id).then(async(res)=>{
      dispatch(setQuantityToolbar(res.data.total_quantity));
      await props.refetch();
    });
    props.setLoading(false);
  };

  return <DeleteCellView onDelete={handleDeleteClick}></DeleteCellView>;
};

DeleteCell.displayName = 'DeleteCell';

export default DeleteCell;
