import React, { useEffect, useState } from 'react';
import { CellProps } from 'react-table';
import { useAppDispatch } from 'utils/hooks/redux';
import useHttp from 'utils/hooks/useHttp';
import { CartTableItem } from '../../CartTable.model';
import QuantityAvailabilityView from './QuantityAvailability.view';
import { setQuantityToolbar } from 'store/slices/cart.slice';

type Props = CellProps<CartTableItem> & {
  refetch: () => Promise<void>;
  availabilityUnspecified?: boolean;
  totalPartsPrice: number;
  setTotalPartsPrice?: (totalPartsPrice: number) => void;
};

const QuantityAvailability: React.FC<Props> = (props: Props) => {
  const http = useHttp();
  const [quantity, setQuantity] = useState<number>(props.value.quantity);
  const [quantityLoading, setQuantityLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const handleAddClick = async () => {
    setQuantityLoading(true);

    const updatedQty = quantity + 1;

    const res = await http.order.update(props.row.original.id, { quantity: updatedQty });

    // Update total parts price
    props.setTotalPartsPrice?.(res.data.total_price ? Number(res.data.total_price) : 0);

    // Update cart part quantity [fix for reload table]
    props.row.original.quantity_availability.quantity = updatedQty;

    // Update quantity toolbar
    dispatch(setQuantityToolbar(res.data.total_quantity));

    setQuantity(updatedQty);

    setQuantityLoading(false);
  };

  const handleSubstractClick = async () => {
    setQuantityLoading(true);

    const updatedQty = quantity - 1 >= 1 ? quantity - 1 : 1;

    const res = await http.order.update(props.row.original.id, { quantity: updatedQty });

    // Update total parts price
    props.setTotalPartsPrice?.(res.data.total_price ? Number(res.data.total_price) : 0);

    // Update cart part quantity [fix for reload table]
    props.row.original.quantity_availability.quantity = updatedQty;

    // Update quantity toolbar
    dispatch(setQuantityToolbar(res.data.total_quantity));

    setQuantity(updatedQty);

    setQuantityLoading(false);
  };

  return (
    <QuantityAvailabilityView
      availabilityUnspecified={props.availabilityUnspecified}
      availability={props.value.availability}
      quantity={quantity}
      setQuantity={setQuantity}
      quantityLoading={quantityLoading}
      onAdd={handleAddClick}
      onSubstract={handleSubstractClick}
    ></QuantityAvailabilityView>
  );
};

QuantityAvailability.displayName = 'QuantityAvailability';

export default QuantityAvailability;
