import React from 'react';
import {useDrop} from 'react-dnd';
import { IRow } from '../../schema';


import './DragDrop.scss';
import {useTranslation} from 'react-i18next';

interface IDropComponentProps{
    rows:IRow[],
    setRows: (rows:IRow[]) => void,
    index: number,
    fileHeader: string[],
    setFileHeader: (header) => void,
    disableMapping?:boolean,
}
interface IDrop{
  name: string,
  type: string,
  number: number
}
const DropComponent = (props:IDropComponentProps): JSX.Element => {
  const [{canDrop, isOver}, drop] = useDrop({
    accept: ['any'],
    drop: (e: IDrop) => {
      const newRows = [...props.rows];
      const element = newRows.find(row => row.name === e.name);

      if (element) {
        const el = element;

        el.name = '';
      }

      if (Number(newRows[props.index].number) >= 0 && newRows[props.index].name) {
        props.fileHeader[Number(newRows[props.index].number)] = newRows[props.index].name;
      }

      newRows[props.index].number = e.number;
      newRows[props.index].name = e.name;

      props.fileHeader[e.number] = '';
      props.setRows(newRows);
      props.setFileHeader(props.fileHeader);
    },
    collect: monitor => {
      return {
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      };
    },
  });

  const { t } = useTranslation();
  const isActive = canDrop && isOver;

  return (
    <td colSpan={2} className={`proforma-drop ${isActive ? 'drop-background' : ''}`} ref={drop}>
      {isActive ? 'Release to drop' : (
        <React.Fragment>
          {!props.rows[props.index].name || props.rows[props.index].name === 'null'
            ? <div style={{color: 'gray'}}>{t('_empty')}</div>
            : (
              <React.Fragment>
                <span className={`proforma-drop-text ${props.disableMapping ? 'disabled-drop-text' : ''}`}>
                  {props.rows[props.index].name}
                </span>
                {!props.disableMapping ? (
                  <React.Fragment>
                    {Number(props.rows[props.index].number) >= 0 && (

                      <div
                        style={{float: 'right'}}
                        onClick={() => {
                          if (Number(props.rows[props.index].number) >= 0 && props.rows[props.index].name) {
                            props.fileHeader[Number(props.rows[props.index].number)] = props.rows[props.index].name;
                            props.rows[props.index].name = '';
                            props.setFileHeader([...props.fileHeader]);
                            props.setRows([...props.rows]);
                          }
                        }}
                      >
                        <i className="fas fa-times dropback" />
                      </div>
                    )}
                    {}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {Number(props.rows[props.index].number) >= 0 && (

                      <div
                        style={{float: 'right', color: 'gray'}}
                      >
                        <i className="fas fa-times dropback" />
                      </div>
                    )}
                    {}
                  </React.Fragment>
                )}

              </React.Fragment>
            )}
          {}
        </React.Fragment>
      )}

    </td>
  );
};

export default DropComponent;
