
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useHttp from 'utils/hooks/useHttp';
import './CategoryCreate.scss';

import Spinner from 'components/spinner/Spinner';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { notify } from 'utils/marketplace';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import UploadImage from 'components/UploadImage/UploadImage';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { languages } from '../../../../utils/constants/locales';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';


type Props = {
  setOpenModalCreate: (e: boolean) => void;
  setSubmit: (e: boolean) => void;
};


const CategoryCreate: React.FC<Props> = (props) => {

  const { t } = useTranslation();
  const http = useHttp();
  const [tabColor, setTabColor] = useState<string[]>([]);
  const { isMobile, isTablet } = useDeviceDetect();
  const [errorName, setErrorName] = useState(false);


  const [loading, setLoading] = useState<boolean>(false);


  const validate = Yup.object().shape({
    image: Yup.string().required(t('_fild_required')),

  });

  const {
    getValues,
    register,
    handleSubmit,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm({ resolver: yupResolver(validate) });

  const onError = () => {

    const values = getValues();

    Object.keys(values.translations).forEach(key => {
      if (values.translations[key] == undefined || values.translations[key] == '') {
        delete values.translations[key];
      }
    });

    if (Object.keys(values.translations).length == 0) {
      setErrorName(true);


    } else {
      setErrorName(false);
    }
  };

  const onSubmit = async () => {
    if (loading) {
      return;
    }

    const values = getValues();

    Object.keys(values.translations).forEach(key => {
      if (values.translations[key] == undefined || values.translations[key] == '') {
        delete values.translations[key];
      }
    });

    if (Object.keys(values.translations).length == 0) {
      setErrorName(true);

      return;
    }

    if (errorName) {
      return;
    }

    setLoading(true);
    const formData: FormData = new FormData();

    formData.append('image', values?.image);
    Object.keys(values.translations).forEach(key => {
      if (values.translations[key] == undefined || values.translations[key] == '') {
        delete values.translations[key];
      } else {
        formData.append(`translations[${key}][name]`, String(values.translations[key]));
      }
    });
    http.consumableCategoryHttp.create(formData).then(() => {
      notify(t('_consumable_category_created'), 'success', t('_success'));
      props.setOpenModalCreate(false);
      props.setSubmit(true);
    }).catch((error) => {
      let erorrs = '';

      Object.entries(error).forEach(([key, value]) => {
        erorrs += `${t('_' + key)} : ${value} `;
      });
      notify(erorrs, 'error', t('_error'));
      setLoading(false);
    });

  };

  const changeName = (key: string, value: string) => {
    if (value != '') {
      if (!tabColor.includes(String(key))) {
        const lang: string[] = [key];

        setTabColor([...tabColor, ...lang]);
      }

      setErrorName(false);
    } else {
      if (tabColor.includes(String(key))) {
        tabColor.splice(tabColor.indexOf(key), 1);
        setTabColor([...tabColor]);
      }
    }
  };

  const showErrorName = () => {
    console.log(errors);

    if (!errors.name) {
      return '';
    }

    if (errors?.name?.type !== 'required') {
      return '';
    }

    if (!(errors?.name?.message)) {
      return '';
    }

    console.log(errors.name.message);

    // return '';

    return String(errors.name.message);
  };

  return (
    <div className="consumable-category-create-container">
      <div className="close-modal" onClick={() => {
        props.setOpenModalCreate(false);
      }}
      ><FontAwesomeIcon icon={faClose} /></div>

      <div className="title">
        {t('_consumable_title_create')}

      </div>

      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <div>

          <div className="row">
            <div className={`container  file ${errors.image?.type == 'required' ? 'error' : ''}`}>
              <UploadImage errors={errors} clearErrors={clearErrors} setValue={setValue} />
            </div>

          </div>
          <div className="row">
            <div className={`container ${errorName ? 'error' : ''}`}>
              {
                errorName ? <p className="error-message"> {t('_fild_required_title')} </p> : ''
              }
              <div className="tabs-container">
                <Tabs>
                  <TabList>
                    {
                      languages.map((item, index) => {
                        let classActive = 'empty_text';

                        tabColor.map((trans) => {
                          if (trans == item.id) {
                            classActive = 'active_text';
                          }
                        });

                        return <Tab key={index} className={classActive}>{isMobile || isTablet ? String(item.id).toUpperCase() : item.text}</Tab>;
                      })
                    }
                  </TabList>
                  {languages.map((item, index) => {

                    return (<TabPanel key={index}>
                      <label>
                        {t('_name')}
                      </label>
                      <textarea {...register(`translations[${item.id}]`)} onChange={(event) => { changeName(String(item.id), event?.target.value); }} rows={2} />


                    </TabPanel>
                    );
                  })
                  }
                </Tabs>
              </div>


            </div>

          </div>
        </div>
        <div className="btn-save">
          <button type="submit" className={loading ? 'disabled' : ''}> {loading ? <Spinner class="extra-small" /> : null}
            <div className="title-btn-save">{t('_create_article')}</div>
          </button>
        </div>
      </form>


    </div>

  );

};

CategoryCreate.displayName = 'CategoryCreate';

export default CategoryCreate; 