import React, { ChangeEventHandler, KeyboardEventHandler } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import './TextareaInput.scss';

type Props = {
  placeholder?: string;
  onBlur?: ChangeEventHandler<HTMLTextAreaElement>;
  onChange?;
  name?: string;
  value?: string;
  withIcon?: boolean;
  onClick?: (e) => void;
  noResize?: boolean;
  rows?: number;
  onKeyDown?: KeyboardEventHandler<HTMLTextAreaElement>;
  ref;
};

const TextareaInputView: React.FC<Props> = React.forwardRef<HTMLTextAreaElement, Props>((props, ref) => {
  return (
    <div className="textarea-container">
      <textarea
        ref={ref}
        className={`textarea ${props.noResize ? 'no-resize' : ''}`}
        onBlur={props.onBlur}
        defaultValue={props.value}
        name={props.name}
        rows={props.rows || 2}
        onChange={props.onChange}
      />
      {props.withIcon && (
        <div onClick={props.onClick}>
          <FontAwesomeIcon className="textarea-search-icon" icon={faSearch} />
        </div>
      )}
    </div>
  );
});

TextareaInputView.displayName = 'TextareaInputView';

export default TextareaInputView;
