import React from 'react';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import { SwiperSlide } from 'swiper/react';
import SwiperSlider from 'components/SwiperSlider/SwiperSlider';
import './SupplierPromo.scss';
import {ISupplierPromoModel} from './SupplierPromo.model';
import {useTranslation} from 'react-i18next';

type Props = {
  isLoading?: boolean;
  items: ISupplierPromoModel[];
};

const CatalogPromoView: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  const handleItemClick = (item: ISupplierPromoModel) => {
    // navigate(`/`);
  };

  return (
    <SwiperSlider
      slidesPerView={2}
      isLoading={props.isLoading}
    >
      {
        props.items.map((item: ISupplierPromoModel, index: number) => {
          return (
            <SwiperSlide className="swiper-slide" key={index}>
              <div className="promotion-container">
                <div className="promotion-title">{item.title}</div>
                <div className="promotion-info">
                  <div className="information">
                    <div className="discount">{item.discount}</div>
                    <div className="availability">{item.availability}</div>
                  </div>
                  <div className="promotion-submit">
                    <DefaultButton text={t('_discover')} onClick={() => handleItemClick(item)} key={index} />
                  </div>
                </div>
              </div>
            </SwiperSlide>
          );
        })
      }
    </SwiperSlider>
  );
};

CatalogPromoView.displayName = 'CatalogPromoView';

export default CatalogPromoView;
