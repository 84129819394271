import React from 'react';

import { ISellers } from './Tab.model';

import './Tab.scss';

type Props = {
  tabs: ISellers[];
  selectedTab: ISellers;
  onClickChanageTab: (tab: ISellers) => void;
};

const TabView: React.FC<Props> = (props: Props) => {
  return (
    <div className="grid">
      <div className="tabs">
        {props.tabs.map((tab) => {
          return (
            <div
              key={tab.id}
              className={`equivalence ${props.selectedTab === tab && 'selected'}`}
              onClick={() => props.onClickChanageTab(tab)}
            >
              {tab.text}
            </div>
          );
        })}
      </div>
      <div className="tabContainer">{props.selectedTab.content}</div>
    </div>
  );
};

TabView.displayName = 'TabView';

export default TabView;
