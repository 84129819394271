import React, { forwardRef, LegacyRef } from 'react';
import { IEbook } from '../Navbar.model';
import './EbooksSidebar.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import EbookList from 'components/EbookList/EbookList';
import { useTranslation } from 'react-i18next';


type Props = {
  showSidebar?: boolean;
  showDetails?: boolean;
  ebooks: IEbook[];
  onCloseButtonClick?: () => void;
  onItemClick?: (item: string) => void;
  onReturnButtonClick?: () => void;
};

const EbooksSidebarView = forwardRef((props: Props, ref: LegacyRef<HTMLDivElement>): JSX.Element | null => {
  const { t } = useTranslation();

  return (
    <div ref={ref}>
      {props.showSidebar ? (
        <div className="wrapper-ebooks">
          <FontAwesomeIcon icon={faXmark} onClick={props.onCloseButtonClick} className="icon" />
          <EbookList
            ebooks={props.ebooks}
            onItemClick={props.onItemClick}
            className="title"
            itemClassName="listItem"
            title={t('_catalog_ebooks')}
          ></EbookList>
        </div>
      ) : ''}
    </div>
  );
});

EbooksSidebarView.displayName = 'EbooksSidebarView';

export default EbooksSidebarView;
