import { IHttpResponse, serverAxios } from '../../http';
import {
  IGetCountNewMessagesParams,
  IGetMessagesParams, IGetMessageSupplierParams,
  IMessage,
  IMessageAll,
  IMessageSuppliersAll
} from '../../../interfaces/messaging';

class PlatformAdminMessaging {
  private static getMessages = 'messages-platform-admin';
  private static getOrderMessages = 'messages-platform-admin/list-order';
  private static getCartMessages = 'messages-platform-admin/list-cart';
  private static getNewMessages = 'messages-platform-admin/new';
  private static getOrderNewMessages = 'messages-platform-admin/new-order';
  private static getLastMessages = 'messages-platform-admin/last';
  private static createMessage = 'messages-platform-admin/message';
  private static createOrderMessage = 'messages-platform-admin/message-order';
  private static countNewMessages = 'messages-platform-admin/count-new';
  private static downloadFile = 'messages-platform-admin/download-file/{{id}}';
  private static deleteMessage = 'messages-platform-admin/message/{{id}}';
  static PER_PAGE = 10;
  static SUPPLIER_MESSAGES_PER_PAGE = 12;

  public async getMessages(params: IGetMessagesParams): Promise<IHttpResponse<IMessageAll>> {

    return serverAxios.get(PlatformAdminMessaging.getMessages, { params });
  }

  public async getOrderMessages(params: IGetMessagesParams): Promise<IHttpResponse<IMessageAll>> {

    return serverAxios.get(PlatformAdminMessaging.getOrderMessages, { params });
  }

  public async getCartMessages(params: IGetMessagesParams): Promise<IHttpResponse<IMessageAll>> {

    return serverAxios.get(PlatformAdminMessaging.getCartMessages, { params });
  }

  public async getNewMessages(last_message_id: number | null, client_id: number): Promise<IHttpResponse<IMessage[]>> {

    return serverAxios.get(PlatformAdminMessaging.getNewMessages, { params: { last_message_id, client_id } });
  }

  public async getOrderNewMessages(last_message_id: number | null, order_id: string, client_id: number | undefined): Promise<IHttpResponse<IMessage[]>> {

    return serverAxios.get(PlatformAdminMessaging.getOrderNewMessages, { params: { last_message_id, order_id, client_id } });
  }


  public async getLastMessages(params: IGetMessageSupplierParams): Promise<IHttpResponse<IMessageSuppliersAll>> {

    return serverAxios.get(PlatformAdminMessaging.getLastMessages, { params });
  }

  public async countNewMessages(params: IGetCountNewMessagesParams): Promise<IHttpResponse<number>> {

    return serverAxios.get(PlatformAdminMessaging.countNewMessages, { params });
  }

  public async createMessage(data: FormData): Promise<IHttpResponse<IMessage>> {
    return serverAxios.post(PlatformAdminMessaging.createMessage, data, { headers: { 'content-type': 'multipart/form-data' } });
  }

  public async createOrderMessage(data: FormData): Promise<IHttpResponse<IMessage>> {
    return serverAxios.post(PlatformAdminMessaging.createOrderMessage, data, { headers: { 'content-type': 'multipart/form-data' } });
  }


  public async downloadMessage(id: string): Promise<IHttpResponse<Blob>> {
    const url = PlatformAdminMessaging.downloadFile.replace('{{id}}', id);

    return serverAxios.get(url, { responseType: 'blob' });
  }

  public async deleteMessage(id: string): Promise<IHttpResponse<null>> {
    const url = PlatformAdminMessaging.deleteMessage.replace('{{id}}', id);

    return serverAxios.delete(url);
  }
}

export default PlatformAdminMessaging;