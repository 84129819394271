import React, { useEffect, useState } from 'react';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import { useTranslation } from 'react-i18next';
import { notify } from 'utils/marketplace';
import { faFileExcel } from '@fortawesome/free-regular-svg-icons';
import './UploadPriceModalInfo.scss';
import { ILoadingPriceList } from 'utils/hooks/http/price-list.http';
import ProgressBar from '@ramonak/react-progress-bar';
import useHttp from 'utils/hooks/useHttp';

import Spinner from 'components/spinner/Spinner';
import useTimerMessages from 'utils/hooks/useTimerMessages';

type Props = {
  setOpenModalInfo: (openModal: boolean) => void;
  id: number | null
}


const UploadPriceModalInfo: React.FC<Props> = (props: Props) => {
  const http = useHttp();
  const { t } = useTranslation();

  const [loadingPrice, setLoadingPrice] = useState(false);
  const [loadingFile, setLoadingFile] = useState(false);
  const [priceList, setPriceList] = useState<ILoadingPriceList>();
  const time = useTimerMessages(15000);

  useEffect(() => {

    if (props.id && priceList == undefined) {
      setLoadingPrice(true);
      getLoadingPriceInfo();

    }


    if (props.id && (priceList && (priceList?.status != 'FAILED' && priceList?.status != 'FINISHED'))) {

      getLoadingPriceInfo();

    }
  }, [time]);


  function getLoadingPriceInfo() {
    http.priceListHttp.getLoadingPriceInfo(String(props.id))
      .then(res => setPriceList(res.data))
      .finally(() => setLoadingPrice(false))
      .catch(() => {
        setLoadingPrice(false);
        props.setOpenModalInfo(false);
        notify(t('_error'), 'error', t('_error'));
      });
  }

  function downloadFile() {
    setLoadingFile(true);
    const link = document.createElement('a');

    link.href = priceList?.file || '#';
    link.setAttribute('download', priceList?.file?.substring(priceList?.file?.lastIndexOf('/') + 1, priceList?.file?.lastIndexOf('.')) || '');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setLoadingFile(false);
  }

  return (
    <div className="upload-price-modal-info">

      {loadingPrice ? <Spinner /> :
        <React.Fragment>
          <div className="modal-header">
            <div className="modal-title">{priceList?.created_at + ' - ' + priceList?.file_name}</div>
            <div className="close-modal" onClick={() => props.setOpenModalInfo(false)}><FontAwesomeIcon icon={faClose} /></div>
          </div>
          <div className="mobile-scroll">
            <div className="modal-body">
              <div className="price-item-body">
                <div className="attached-file-title">{t('_attached_file')}:</div>
                <div className="price-data">
                  <div className="price-file-icon" onClick={downloadFile}>  {loadingFile && <Spinner class="extra-small price-file-spinner" />}<FontAwesomeIcon className={`excel-icon ${loadingFile ? 'blur' : ''}`} icon={faFileExcel} /></div>
                  <div className="price-file-details">
                    <div className="price-file-detail"><strong>{t('_name')}:</strong> {priceList?.file_name}</div>

                    <div className="price-file-detail"><strong>{t('_codfou')}:</strong> {priceList?.codfou}</div>
                    <div className="price-file-detail"><strong>{t('_created_at')}:</strong> {priceList?.created_at}</div>
                    <div className="price-file-detail"><strong>{t('_started_at')}:</strong> {priceList?.started_at ?? '-'}</div>
                    <div className="price-file-detail"><strong>{t('_finished_at')}:</strong> {priceList?.finished_at ?? '-'}</div>
                    <div className="price-file-detail-status"><strong>{t('_status')}:</strong> <div className={`status ${priceList?.status == 'FAILED' ? 'failed' : ''} ${priceList?.status == 'FINISHED' ? 'finished' : ''} ${priceList?.status == 'IN_PROGRESS' ? 'inprogress' : ''}${priceList?.status == 'PENDING' ? 'pending' : ''} `}>{priceList?.status ? t(priceList?.status) : ''}</div></div>
                    <div className="price-file-detail-progress"><strong>{t('_progress')}:</strong> <div className="progressBar"> <ProgressBar completed={priceList?.progress ?? 0} maxCompleted={100} bgColor="#355099" labelSize="13px" height="20px" borderRadius="5px"></ProgressBar></div></div>
                  </div>
                </div>
              </div>

            </div>

            <div className="price-message ">
              <textarea className="price-textarea" rows={4} defaultValue={priceList?.comments} readOnly placeholder="Message" />
            </div>
            {priceList?.logs &&
              <div className="price-message ">
                <textarea className="price-textarea" defaultValue={priceList?.logs} readOnly placeholder="Logs" />
              </div>
            }

          </div>
          <div className="modal-footer">
            <DefaultButton text={t('_close')} className="close-btn" onClick={() => { props.setOpenModalInfo(false); }} />
          </div>
        </React.Fragment>
      }
    </div>

  );
};

export default UploadPriceModalInfo;