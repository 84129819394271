import React from 'react';

import ButtonLinkView from './ButtonLink.view';

import './ButtonLink.scss';

type Props = {
  text: string | string[];
  className?: string;
  onClick?: () => void;
  image?: string;
  pdf_name?: string;
  showPdf?: boolean;
  selected?: boolean;
  itemId?: string;
  disabled?: boolean
};

const ButtonLink: React.FC<Props> = (props: Props) => {
  const classesContainer = ['container-button-link'];

  if (props.className) {
    classesContainer.push(props.className);
  }

  return (
    <ButtonLinkView
      disabled={props.disabled}
      image={props.image}
      className={classesContainer.join(' ')}
      text={props.text}
      pdf_name={props.pdf_name}
      showPdf={props.showPdf}
      onClick={props.onClick}
      selected={props.selected}
    ></ButtonLinkView>
  );
};

ButtonLink.displayName = 'ButtonLink';

export default ButtonLink;
