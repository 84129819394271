import SkeletonSpinner from 'components/skeleton-spinner/SkeletonSpinner';
import React, { MutableRefObject, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { IGlobalSearchData } from '../../../../interfaces/globalSearch';
import { createSearchParams, generatePath, useNavigate } from 'react-router-dom';
import { getBackendAssetsBasePath } from '../../../../utils/marketplace';
import { useOnClickOutside } from '../../../../utils/hooks/useOnClickOutside';
import DropdownContentTable from 'components/DropdownContentTable/DropdownContentTable';
import { Column } from 'react-table';
import Head from 'components/tables/Cells/Head/Head';
import { IData } from 'utils/hooks/http/search-by-reference.http';
import { IOilSearch } from 'interfaces/oil';
import { ITyreSearchData } from 'interfaces/tyre';
import { IBatterySearch } from 'interfaces/battery';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import { IConsumableSearch } from 'interfaces/consumable';
import './GlobalSearchContent.scss';
import { isRetailerMecadepotChild } from 'utils/isRetailerMecadepotChild';

type Props = {
  data: IGlobalSearchData | undefined;
  setIsOpen: (isOpen: boolean) => void;
  loading: boolean;
  queryValue?: string;
  size?: 's' | 'm' | 'l'
};

const GlobalSearchContent: React.FC<Props> = (props) => {
  const globalContentRef = useRef() as MutableRefObject<HTMLDivElement>;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isMobile } = useDeviceDetect();

  const isMecadepotChild = isRetailerMecadepotChild();

  useOnClickOutside(globalContentRef, () => props.setIsOpen(false));

  function handleOilClick(item) {
    const param = {
      ...{ page: 1 },
      ...(item.category_id && { category_ids: item.category_id }),
      ...(item.oil_id && { oil_id: item.oil_id }),
      ...(item.brand_id && { brand_id: item.brand_id }),
      ...(item.family && { family: item.family }),
      ...(item.quantity && { quantity: item.quantity }),
      ...(item.viscosity && { viscosity: item.viscosity }),
      ...(props.queryValue && { q: props.queryValue }),
    };

    navigate({
      pathname: generatePath('/oils'),
      search: `?${createSearchParams(param)}`,
    });
    props.setIsOpen(false);
  }

  // function handleOilCategoryClick(e, item) {
  //   e.stopPropagation();
  //   const param = {
  //     ...{ page: 1 },
  //     ...(item.category_id && { category_ids: item.category_id }),
  //     ...(props.queryValue && { q: props.queryValue }),
  //   };

  //   navigate({
  //     pathname: generatePath('/oils'),
  //     search: `?${createSearchParams(param)}`,
  //   });
  //   props.setIsOpen(false);
  // }

  function handlePartsClick(item) {
    const param = {
      ...{ page: 1 },
      ...(item.source && { source: item.source }),
      ...(item.subcategory_id && { subcategory_id: item.subcategory_id }),
      ...(item.man_sup_id && { man_sup_id: item.man_sup_id }),
      ...(item.reference && { q: item.reference }),
      ...(item.type && { type: item.type }),
    };

    props.setIsOpen(false);
    navigate({
      pathname: generatePath('/car-parts'),
      search: `?${createSearchParams(param)}`,
    });

  }

  function handleConsumableClick(item) {
    const param = {
      ...{ page: 1 },
      ...(item.category_id && { category_ids: item.category_id }),
      ...(item.consumable_id && { consumable_id: item.consumable_id }),
      ...(item.brand_id && { brand_id: item.brand_id }),
      ...(props.queryValue && { q: props.queryValue }),
    };

    navigate({
      pathname: generatePath('/consumables'),
      search: `?${createSearchParams(param)}`,
    });
    props.setIsOpen(false);
  }

  // function handleConsumableCategoryClick(e, item) {
  //   e.stopPropagation();
  //   const param = {
  //     ...{ page: 1 },
  //     ...(item.category_id && { category_ids: item.category_id }),
  //     ...(props.queryValue && { q: props.queryValue }),
  //   };

  //   navigate({
  //     pathname: generatePath('/consumables'),
  //     search: `?${createSearchParams(param)}`,
  //   });
  //   props.setIsOpen(false);
  // }

  function handleTyreClick(item) {
    const param = {
      ...{ page: 1 },
      ...(item.width && { widths: item.width.toString() }),
      ...(item.height && { heights: item.height.toString() }),
      ...(item.diameter && { diameters: item.diameter.toString() }),
      ...(item.load_index_id && { load_index_id: item.load_index_id.toString() }),
      ...(item.speed_rating_id && { speed_rating_id: item.speed_rating_id.toString() }),
      ...(item.brand_id && { brand: item.brand_id.toString() }),
      ...(item.tyre_id && { tyre_id: item.tyre_id.toString() }),
    };

    navigate({
      pathname: generatePath('/tyres'),
      search: `?${createSearchParams(param)}`,
    });
    props.setIsOpen(false);
  }

  function handleBatteryClick(item) {
    const param = {
      ...{ page: 1 },
      ...(item.battery_id && { battery_id: item.battery_id }),
      ...(item.category_id && { category_ids: item.category_id }),
      ...(item.brand_id && { brand_ids: item.brand_id }),
      ...(item.ah && { ah: item.ah }),
      ...(item.amp && { amp: item.amp }),
      ...(props.queryValue && { q: props.queryValue }),
    };

    navigate({
      pathname: generatePath('/batteries'),
      search: `?${createSearchParams(param)}`,
    });
    props.setIsOpen(false);
  }

  // function handleBatteryCategoryClick(e, item) {
  //   e.stopPropagation();
  //   const param = {
  //     ...{ page: 1 },
  //     ...(item.category_id && { category_ids: item.category_id }),
  //     ...(props.queryValue && { q: props.queryValue }),
  //   };

  //   navigate({
  //     pathname: generatePath('/batteries'),
  //     search: `?${createSearchParams(param)}`,
  //   });
  //   props.setIsOpen(false);
  // }

  const partsData: Column<IData>[] = [
    {
      Header: <Head text={t('_brand').toLocaleUpperCase()}></Head>,
      Cell: (cell) => cell.cell.row.original.replacement ?
        (<div className="suggestion-item-replacement">
          <span>{props.queryValue}</span> {t('_referance_replaced')}
          <div>{cell.cell.row.original.reference}</div>
        </div>) : cell.cell.row.original.reference_commerciale ?
          <div className="suggestion-item-replacement"><div>{cell.cell.row.original.reference}</div> <span className="commercial-ref">{t('_reference_commerciale')}: {cell.cell.row.original.reference_commerciale}</span></div>
          : <div>{cell.cell.row.original.reference}</div>,
      accessor: 'reference',
    },
    {
      Header: <Head text={t('_reference').toLocaleUpperCase()}></Head>,
      accessor: 'supplier_or_manufacturer',
    },
    {
      Header: <Head text={t('_category').toLocaleUpperCase()}></Head>,
      accessor: 'category_name',
    },
  ];


  const oilData: Column<IOilSearch>[] =
    isMecadepotChild ?
      [
        {
          Header: <Head text=""></Head>,
          Cell: (cell) => (
            <img src={cell.cell.row.original.image ? `${getBackendAssetsBasePath()}/${cell.cell.row.original.image}` : '/images/car-part-placeholder.png'} width={30} height={30} alt="oil" onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = '/images/car-part-placeholder.png';
            }}
            />)
          ,
          accessor: 'image',
        },
        {
          Header: <Head text={t('_description').toLocaleUpperCase()}></Head>,
          accessor: 'description',
        },

      ] :
      [
        {
          Header: <Head text=""></Head>,
          Cell: (cell) => (
            <img src={cell.cell.row.original.image ? `${getBackendAssetsBasePath()}/${cell.cell.row.original.image}` : '/images/car-part-placeholder.png'} width={30} height={30} alt="oil" onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = '/images/car-part-placeholder.png';
            }}
            />)
          ,
          accessor: 'image',
        },
        {
          Header: <Head text={t('_brand').toLocaleUpperCase()}></Head>,
          accessor: 'brand_name',
        },
        {
          Header: <Head text={t('_reference').toLocaleUpperCase()}></Head>,
          accessor: 'reference',
        },
        {
          Header: <Head text={t('_category').toLocaleUpperCase()}></Head>,
          accessor: 'category_name',
        },
      ];

  const tyreData: Column<ITyreSearchData>[] =
    isMecadepotChild ?
      [
        {
          Header: <Head text=""></Head>,
          Cell: (cell) => (
            <img src={cell.cell.row.original.image ? `${getBackendAssetsBasePath()}/${cell.cell.row.original.image}` : '/images/car-part-placeholder.png'} width={30} height={30} alt="oil" onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = '/images/car-part-placeholder.png';
            }}
            />)
          ,
          accessor: 'image',
        },

        {
          Header: <Head text={t('_description').toLocaleUpperCase()}></Head>,
          accessor: 'description',
        },
      ] :
      [
        {
          Header: <Head text=""></Head>,
          Cell: (cell) => (
            <img src={cell.cell.row.original.image ? `${getBackendAssetsBasePath()}/${cell.cell.row.original.image}` : '/images/car-part-placeholder.png'} width={30} height={30} alt="oil" onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = '/images/car-part-placeholder.png';
            }}
            />)
          ,
          accessor: 'image',
        },
        {
          Header: <Head text={t('_reference').toLocaleUpperCase()}></Head>,
          accessor: 'reference',
        },
        {
          Header: <Head text={t('_brand').toLocaleUpperCase()}></Head>,
          accessor: 'brand_name',
        },
      ];

  const batteryData: Column<IBatterySearch>[] = isMecadepotChild ?
    [
      {
        Header: <Head text=""></Head>,
        Cell: (cell) => (
          <img src={cell.cell.row.original.image ? `${getBackendAssetsBasePath()}/${cell.cell.row.original.image}` : '/images/car-part-placeholder.png'} width={30} height={30} alt="battery" onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = '/images/car-part-placeholder.png';
          }}
          />)
        ,
        accessor: 'image',
      },

      {
        Header: <Head text={t('_description').toLocaleUpperCase()}></Head>,
        accessor: 'description',
      },
    ]
    :
    [
      {
        Header: <Head text=""></Head>,
        Cell: (cell) => (
          <img src={cell.cell.row.original.image ? `${getBackendAssetsBasePath()}/${cell.cell.row.original.image}` : '/images/car-part-placeholder.png'} width={30} height={30} alt="battery" onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = '/images/car-part-placeholder.png';
          }}
          />)
        ,
        accessor: 'image',
      },
      {
        Header: <Head text={t('_conditioning').toLocaleUpperCase()}></Head>,
        Cell: (cell) =>
          <div className="item-ref">
            <span>{cell.cell.row.original.brand_name}</span>
            {' '}
            <span>{cell.cell.row.original.reference}</span>
            {' '}
            <span>{cell.cell.row.original.ah}AH</span>
            {' '}
            <span>{cell.cell.row.original.amp}AMP</span>
            {' '}
            {!isMobile ? <span>{cell.cell.row.original.size}</span> : null}
          </div>,
        accessor: 'brand_name',
      },
      {
        Header: <Head text={t('_category').toLocaleUpperCase()}></Head>,
        accessor: 'category_name',
      },
    ];

  const consumableSearch: Column<IConsumableSearch>[] =
    isMecadepotChild ?
      [
        {
          Header: <Head text=""></Head>,
          Cell: (cell) => (
            <img src={cell.cell.row.original.image ? `${getBackendAssetsBasePath()}/${cell.cell.row.original.image}` : '/images/car-part-placeholder.png'} width={30} height={30} alt="oil" onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = '/images/car-part-placeholder.png';
            }}
            />)
          ,
          accessor: 'image',
        },
        {
          Header: <Head text={t('_description').toLocaleUpperCase()}></Head>,
          accessor: 'description',
        }

      ] :
      [
        {
          Header: <Head text=""></Head>,
          Cell: (cell) => (
            <img src={cell.cell.row.original.image ? `${getBackendAssetsBasePath()}/${cell.cell.row.original.image}` : '/images/car-part-placeholder.png'} width={30} height={30} alt="oil" onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = '/images/car-part-placeholder.png';
            }}
            />)
          ,
          accessor: 'image',
        },
        {
          Header: <Head text={t('_conditioning').toLocaleUpperCase()}></Head>,
          accessor: 'conditioning',
        },
        {
          Header: <Head text={t('_reference').toLocaleUpperCase()}></Head>,
          accessor: 'reference',
        },
        {
          Header: <Head text={t('_category').toLocaleUpperCase()}></Head>,
          accessor: 'category_name',
        },
      ];

  return (
    <div ref={globalContentRef} className={`global-search-content content-${props.size}`}>
      <div className="global-search-header">
        <div className="global-search-header-title">{t('_search_result')}</div>
      </div>

      <div className="global-results">
        {props.loading ? (
          <SkeletonSpinner />
        ) : (
          <React.Fragment>
            {props.data && Object.keys(props.data).length ? (
              <React.Fragment>
                {props.data.parts && props.data.parts.length > 0 && (
                  <div className="parts-results">
                    <div className="content-table-title">{t('_parts')}</div>
                    <DropdownContentTable columns={partsData} data={props.data.parts} loading={props.loading} itemClick={handlePartsClick} />
                    {/* {props.data.parts?.map((item, index) => {
                      return (
                        <li key={index} className="suggestion-list-item" onClick={() => handlePartsClick(item)}>
                          {item.replacement ? (
                            <div className="suggestion-item-replacement">
                              <span>{props.queryValue}</span> {t('_referance_replaced')}
                            </div>
                          ) : (
                            ''
                          )}
                          <div className="suggestion-item-title">
                            <span>{item.reference}</span>
                            <span>{item.supplier_or_manufacturer}</span>
                            <span>{item.category_name}</span>
                          </div>
                        </li>
                      );
                    })} */}
                  </div>
                )}
                {props.data.consumables && props.data.consumables.length > 0 && (
                  <div className="consumables-results">
                    <div className="content-table-title">{t('_consumables')}</div>

                    <DropdownContentTable columns={consumableSearch} itemClick={handleConsumableClick} data={props.data.consumables} />

                    {/* {props.data.consumables?.map((item, index) => {
                      return (
                        <li key={index} className="suggestion-list-item">
                          <div className="suggestion-item-title">
                            <div className="suggestion-item-ref" onClick={() => handleConsumableClick(item)}>
                              <img src={`${getBackendAssetsBasePath()}/${item.image}`} alt=""></img>
                              <div>
                                <span>{item.conditioning}</span>
                                <span>{item.reference}</span>
                              </div>
                            </div>
                            <span onClick={() => handleConsumableCategoryClick(item)}>{item.category_name}</span>
                          </div>
                        </li>
                      );
                    })} */}
                  </div>
                )}
                {props.data.oils && props.data.oils.length > 0 && (
                  <div className="oils-results">
                    <div className="content-table-title">{t('_oil')}</div>

                    <DropdownContentTable columns={oilData} itemClick={handleOilClick} data={props.data.oils} />

                    {/* {props.data.oils?.map((item, index) => {
                      return (
                        <li key={index} className="suggestion-list-item">
                          <div className="suggestion-item-title">
                            <div className="suggestion-item-ref" onClick={() => handleOilClick(item)}>
                              <img src={`${getBackendAssetsBasePath()}/${item.image}`} alt=""></img>
                              <div>
                                <span>{item.brand_name}</span>
                                <span>
                                  {item.viscosity} {item.quantity}
                                  {item.unity}
                                </span>
                              </div>
                            </div>
                            <span onClick={() => handleOilCategoryClick(item)}>{item.category_name}</span>
                          </div>
                        </li>
                      );
                    })} */}
                  </div>
                )}
                {props.data.tyres && props.data.tyres.length > 0 && (
                  <div className="tyres-results">
                    <div className="content-table-title">{t('_tyres')}</div>

                    <DropdownContentTable columns={tyreData} itemClick={handleTyreClick} data={props.data.tyres} />

                    {/* {props.data.tyres?.map((item, index) => {
                      return (
                        <li key={index} className="suggestion-list-item">
                          <div onClick={() => handleTyreClick(item)} className="suggestion-item-title">

                            <span>
                              {item.width}&nbsp;{item.height}&nbsp;{item.diameter}&nbsp; {item.load_index}&nbsp;{item.speed_rating}&nbsp;
                            </span>


                            <span>{item.brand_name}</span>
                            <span>{t('_tyres')}</span>
                          </div>
                        </li>
                      );
                    })} */}
                  </div>
                )}
                {props.data.batteries && props.data.batteries.length > 0 && (
                  <div className="batteries-results">
                    <div className="content-table-title">{t('_batteries')}</div>
                    <DropdownContentTable columns={batteryData} itemClick={handleBatteryClick} data={props.data.batteries} />

                    {/* {props.data.batteries?.map((item, index) => {
                      return (
                        <li key={index} className="suggestion-list-item">
                          <div className="suggestion-item-title">
                            <div className="suggestion-item-ref" onClick={() => handleBatteryClick(item)}>
                              <img src={`${getBackendAssetsBasePath()}/${item.image}`} alt=""></img>
                              <div className="item-ref">
                                <span>{item.brand_name}</span>
                                <span>{item.reference}</span>
                                <span>{item.ah}AH</span>
                                <span>{item.amp}AMP</span>
                                <span>{item.size}</span>
                              </div>
                            </div>
                            <span onClick={() => handleBatteryCategoryClick(item)}>{item.category_name}</span>
                          </div>
                        </li>
                      );
                    })} */}
                  </div>
                )}
              </React.Fragment>
            ) : (
              <div className="no-data">{t('_no_data').toLocaleUpperCase()}</div>
            )}
            { }
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default GlobalSearchContent;
