import { IDropdownModel } from 'components/dropdowns/Dropdown.model';
import MiniDropdown from 'components/dropdowns/MiniDropdown/MiniDropdown';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { languages } from 'utils/constants/locales';
import { IUserPut } from 'utils/hooks/http/user.http';
import useHttp from 'utils/hooks/useHttp';
import { getCountryFromLocale, getLanguageFromLocale, getLanguageFromLocalStorage } from 'utils/locale';
import { notify } from 'utils/marketplace';

import './Footer.scss';

const Footer: React.FC = () => {
  const http = useHttp();
  const {t} = useTranslation();
  const { i18n } = useTranslation();


  const [selectedLanguage, setSelectedLanguage] = useState<IDropdownModel>(getLanguageFromLocalStorage() ?? languages[0]);

  useEffect(() => {
    const language = getLanguageFromLocale(i18n);

    if (language) {
      setSelectedLanguage(language);
    }
  }, [i18n.language]);

  const changeLanguage = (item: IDropdownModel) => {
    const country = getCountryFromLocale(i18n);

    if (!country) {
      return;
    }

    const locale = `${item.id}-${country.id}`;

    const userData: IUserPut = {
      language: String(item.id),
    };

    try {
      http.user.putUser(userData);
    } catch (e) {
      notify('Error');
    }

    localStorage.setItem('locale', locale);
    i18n.changeLanguage(locale);
  };

  return (
    <div className="footer-container">
      <div className="logos">
        <div className="logo">
          <img src={require('../../assets/images/e-reliable.png')} width="87" height="10" alt="e-reliable" />
        </div>
      </div>
      <div className="links">
        <div className="text copyright">© 2022 Amerigo international</div>
        <div className="text legal-mentions">{t('_legal_notice')}</div>
        <div className="text privacy-policy">{t('_privacy_policy')}</div>
      </div>
      <div className="language-container">
        <div className="label">{t('_language')}</div>
        <MiniDropdown openUp onClick={changeLanguage} content={selectedLanguage?.id} data={languages} />
      </div>
    </div>
  );
};

Footer.displayName = 'Footer';

export default Footer;
