import { IStats } from 'applications/automotor/models/statistics';
import Spinner from 'components/spinner/Spinner';
import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';

type Props = {
    data: IStats[]
    loading?: boolean
}

const StatisticsChart: React.FC<Props> = (props) => {
  const {isTablet} = useDeviceDetect();

  return (
    props.loading ? <Spinner class="small"/> :
      <ResponsiveContainer width="95%" height={isTablet ? 300 : 400}>
        <BarChart
          data={props.data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          {props.loading ? 'no data' : null}
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" textAnchor="end" interval={0} angle={-40} stroke="#27509f" height={100} />
          <YAxis />
          <Tooltip />
          <Bar dataKey="count" fill="#27509f" />
        </BarChart>
      </ResponsiveContainer>
  );
};
      

export default StatisticsChart;