import React, {PropsWithChildren, useState} from 'react';
import './PasswordStrengthInput.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEye, faEyeSlash} from '@fortawesome/free-solid-svg-icons';

type Props = {
  placeholder?: string;
  required?: boolean;
};

const PasswordStrengthInput = React.forwardRef<HTMLInputElement, Props>( (props: PropsWithChildren<Props>, ref) => {

  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [progress, setProgress] = useState('');
  const [showPasswordState, setShowPasswordState] = useState<boolean>(false);

  const handleShowPasswordClick = () => {
    setShowPasswordState((prev: boolean) => !prev);
  };

  const passwordHandler = (passwordValue) => {

    const strengthChecks = {
      length: false,
      hasUpperCase: false,
      hasLowerCase: false,
      hasDigit: false,
      hasSpecialChar: false,
    };

    strengthChecks.length = passwordValue.length >= 8;
    strengthChecks.hasUpperCase = /[A-Z]+/.test(passwordValue);
    strengthChecks.hasLowerCase = /[a-z]+/.test(passwordValue);
    strengthChecks.hasDigit = /[0-9]+/.test(passwordValue);
    strengthChecks.hasSpecialChar = /[^A-Za-z0-9]+/.test(passwordValue);

    const verifiedList = Object.values(strengthChecks).filter((value) => value);

    const strength =
      verifiedList.length == 5 ? 'Strong' : (verifiedList.length >= 2 ? 'Medium' : 'Weak');

    setPassword(passwordValue);
    setProgress(`${(verifiedList.length / 5) * 100}%`);
    setMessage(strength);

    // console.log('verifiedList: ', `${(verifiedList.length / 5) * 100}%`);
  };

  const getActiveColor = (type) => {
    if (type === 'Strong') {
      return '#8BC926';
    }

    if (type === 'Medium') {
      return '#FEBD01';
    }

    return '#CA312A';
  };

  return (
    <div className="password-strength-wrapper">
      <div className="input-container">
        <div ref={ref} className={`text-input-container ${props.placeholder ? 'has-eye-icon' : ''}`}>
          <input
            className="text-input"
            ref={ref}
            onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
              passwordHandler(event.target.value);
            }}
            {...props}
            type={showPasswordState ? 'text' : 'password'}
            // value={password}
          />
          <FontAwesomeIcon
            onClick={() => handleShowPasswordClick()}
            className="input-search-icon"
            icon={showPasswordState ? faEye : faEyeSlash}
          ></FontAwesomeIcon>
        </div>
        <div className="progress-bg">
          <div className="progress" style={{width: progress, backgroundColor: getActiveColor(message)}}></div>
        </div>
        <div className="message">
          {password.length !== 0 ? (
            <span className="message-text" style={{ color: getActiveColor(message) }}>
              Your password is {message}
            </span>
          ) : null}
        </div>
      </div>
    </div>
  );
});

PasswordStrengthInput.displayName = 'PasswordStrengthInput';

export default PasswordStrengthInput;

