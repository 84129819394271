import React from 'react';

import './Icon.scss';
import IconView from './Icon.view';
import icons from '../../assets/Icons';

type Props = {
  name: keyof typeof icons;
  className?: string;
  onClick?: () => void;
  ios?: boolean;
};

const Icon: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  const classesContainer = ['icon-wrapper'];

  if (props.className) {
    classesContainer.push(props.className);
  }

  return <IconView ios={props.ios} name={props.name} className={classesContainer.join(' ')} onClick={props.onClick}></IconView>;
};

Icon.displayName = 'Icon';
Icon.defaultProps = {};

export default Icon;
