import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import useCollapse from 'react-collapsed';
import { useSearchParams } from 'react-router-dom';
import './CollapseList.scss';
import SubGroupItem from './SubGroupItem';

type Props = {
  data?
  onSubGroupClick?: (item) => void;
  onItemMpClick?: (item) => void;
  onItemMpManualClick?: (value: boolean) => void;
  activeItem?: string | number
}

const SubGroup: React.FC<Props> = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();

  function handleExpand() {
    console.log('sub_group_id', String(props.data.SubGroupId), searchParams.get('sub_group_id'));
    searchParams.set('sub_group_id', String(props.data.SubGroupId));
    setSearchParams(searchParams);
  }


  const config = {
    defaultExpanded: String(props.data.SubGroupId) === searchParams.get('sub_group_id'),
    expandStyles: {
      height: '100%'
    },
    collapseStyles: {},
    duration: 0,
    onExpandStart: handleExpand
  };


  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse(config);

  return (
    <div className="collapse-list-diagnostic">
      <div className="item level-2">
        <div className="collapse-item" {...getToggleProps()}>
          <FontAwesomeIcon icon={isExpanded ? faChevronDown : faChevronRight} />
          <div  >{props.data.SubGroupName}  </div>
        </div>
      </div>
      {props.data.ItemMps.map((itemMp, index) => {
        return (
          <div {...getCollapseProps()} key={index}>
            <SubGroupItem data={itemMp} onItemMpClick={props.onItemMpClick} onItemMpManualClick={props.onItemMpManualClick} activeItem={props.activeItem} />
          </div>
        );
      })}
    </div>
  );
};

export default SubGroup;