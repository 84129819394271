import React from 'react';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import CatalogBatteries from './CatalogBatteries';
import CatalogBatteriesMobile from './CatalogBatteriesMobile/CatalogBatteriesMobile';

const CatalogBatteryView:React.FC = () => {
  const {isMobile} = useDeviceDetect();

  return (
    isMobile ? <CatalogBatteriesMobile /> : <CatalogBatteries />
  );
};

export default CatalogBatteryView;