import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import TextInput from 'components/inputs/TextInput/TextInput';
import Modal from 'components/modal/Modal';
import i18n from 'i18n';
import VehicleIframe from 'pages/CatalogVehicle/TechnicalDocumentation/VehicleIframe/VehicleIframe';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { createSearchParams, generatePath, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { RootState } from 'store/store';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';

import useHttp from 'utils/hooks/useHttp';
import useReliableCatalogCarQueryParams from 'utils/hooks/useReliableCatalogCarQueryParams';
import { RELIABLE_TAB_NAMES } from 'utils/rmi/reliableTabs';

import { getCurrentCountryRMI, getCurrentLanguageRMI, NUMBER_REGEX } from 'utils/rmi/rmi-utils/rmi-utils';
import PrintIframeModal from './PrintIframeModal/PrintIframeModal';
import { printIframeStyle } from './PrintIframeModal/PrintIframeStyle';
import './ServicePlan.scss';

interface IMaintenanceWorks {
  additionalWorkIds?: number[],
  serviceWorkIds?: number[]
}
const ServicePlan: React.FC = () => {
  const { t } = useTranslation();
  const { register, getValues, setValue } = useForm();
  const http = useHttp();
  const { vehicle_id } = useParams();
  const { typeId } = useSelector((state: RootState) => state.tecrmi);
  const [iframeHTML, setIframeHTML] = useState<string>('');
  const [loadingIframe, setLoadingIframe] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [showTyreTable, setShowTyreTable] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [maintenanceWorks, setMaintenanceWorks] = useState<IMaintenanceWorks>();
  const navigate = useNavigate();
  const catalogQueryParams = useReliableCatalogCarQueryParams();
  const { isMobile } = useDeviceDetect();

  async function fetchMaintenancePlanHtml() {
    if (typeId) {
      setLoadingIframe(true);
      await http.maintenanceHttp.fetchMaintenancePlanHtml({
        typeId,
        bodyQualColId: 0,
        countryCode: getCurrentCountryRMI(i18n.language),
        languageCode: getCurrentLanguageRMI(i18n.language),
        optionalHeaderValues: null,
        printView: false,
        serviceWorkIds: maintenanceWorks?.serviceWorkIds || [],
        showTyreTable: showTyreTable,
        additionalWorkIds: maintenanceWorks?.additionalWorkIds || []
      }).then(res => {
        const html = res.data.match(/<!--content-->([\s\S]*?)<!--content-->/g)![0];

        setIframeHTML(html);
      }).finally(() => setLoadingIframe(false));
    }
  }

  function fetchMaintenanceWorks() {
    if (typeId) {
      http.maintenanceHttp.fetchMaintenanceWorks({
        typeId: Number(typeId),
        countryCode: getCurrentCountryRMI(i18n.language),
        languageCode: getCurrentLanguageRMI(i18n.language),
        kindOfWorkTime: 0
      }).then(res => {
        try {
          const workIds = searchParams.getAll('workIds').map(Number);
          const serviceWorks: number[] = [];
          const additionalWorks: number[] = [];

          res.data.Services.forEach(service => {
            service.WorkSteps.forEach(item => {
              if (workIds.includes(item.WorkId)) {
                serviceWorks.push(item.WorkId);
              }
            });
          });

          res.data.AdditionalWorks.forEach(service => {
            service.WorkSteps.forEach(item => {
              if (workIds.includes(item.WorkId)) {
                additionalWorks.push(item.WorkId);
              }
            });
          });

          setMaintenanceWorks({
            additionalWorkIds: serviceWorks,
            serviceWorkIds: additionalWorks,
          });
        } catch (err) {
          console.log(err);
        }
      });
    }
  }

  function handleShowTyres(e) {
    setShowTyreTable(e.target.checked);
  }

  const onAnchorClickHandler = (hrefId: string): void => {
    let toRoute = '';

    if (hrefId.includes('i')) {
      toRoute = RELIABLE_TAB_NAMES.MANUALS;
    }

    if (hrefId.includes('a')) {
      toRoute = RELIABLE_TAB_NAMES.ADJUST;
    }

    searchParams.set('item_id', (hrefId?.match(NUMBER_REGEX) ?? []).join(''));
    setSearchParams(searchParams);

    navigate({
      pathname: generatePath(`/reliable/rmi/:vehicle_id/technical-documentation/${toRoute}`, {
        vehicle_id: String(vehicle_id)
      }),
      search: `${createSearchParams({ ...catalogQueryParams, item_id: String(searchParams.get('item_id')) })}`
    });
  };

  useEffect(() => {
    fetchMaintenanceWorks();
  }, [typeId, i18n.language]);

  useEffect(() => {
    if (maintenanceWorks) {
      fetchMaintenancePlanHtml();
    }
  }, [typeId, maintenanceWorks, showTyreTable]);

  useEffect(() => {
    searchParams.get('milage') && setValue('milage', searchParams.get('milage'));
  }, [searchParams.get('milage')]);

  return (
    <div className="maintenance-service-plan">
      <form className="form-container">
        <div className="inputs-first-row">
          <div className="input-item">
            <label className="first-row-label">{t('_customer_data')}</label>
            <TextInput {...register('first_name')} placeholder={t('_firstname')} whiteColor />
          </div>
          <div className="input-item">
            <TextInput {...register('last_name')} placeholder={t('_lastname')} whiteColor />
          </div>
          <div className="input-item">
            <TextInput {...register('phone')} placeholder={t('_phone')} whiteColor />
          </div>
        </div>
        <div className="inputs-second-row">
          <div className="input-item">
            <label className="second-row-label">{t('_vehicle_data')}</label>
            <TextInput {...register('plate_number')} placeholder={t('_plate')} whiteColor />
          </div>
          <div className="input-item">
            <TextInput {...register('milage')} readOnly placeholder={t('_milage')} />
          </div>
          <div className="input-item">
            <label className="second-row-label">{t('_additional_data')}</label>
            <TextInput {...register('order_number')} placeholder={t('_order_number')} whiteColor />
          </div>
        </div>
      </form>
      <div className="additional-info">
        <div className="info-wheels">
          <input type="checkbox" checked={showTyreTable} onChange={handleShowTyres} /><div className="info-wheels-text"> {t('_add_information_for_tyers_wheels')} </div>
        </div>
        <div className="print-btn">
          <DefaultButton onClick={() => setShowModal(true)} text={t('_print')} />
        </div>
      </div>
      {!isMobile &&
        <div>
          <VehicleIframe onIframeAnchorClick={onAnchorClickHandler} vehicleIframe={iframeHTML!} loading={loadingIframe} />
        </div>
      }
      <Modal
        openModal={showModal}
        setOpenModal={setShowModal}
        childrenComponent={<PrintIframeModal maintenanceWorks={maintenanceWorks} showTyreTable={showTyreTable} data={getValues()} setOpenModal={setShowModal} />}
        contentStyle={printIframeStyle}
      />
    </div>
  );
};

export default ServicePlan;