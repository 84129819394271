import { faFileExcel, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'components/modal/Modal';
import Spinner from 'components/spinner/Spinner';
import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IPriceList } from 'utils/hooks/http/price-list.http';
import useHttp from 'utils/hooks/useHttp';
import DeleteConfirmationTariffModal from './DeleteConfirmationTariffModal/DeleteConfirmationTariffModal';
import './TariffItem.scss';

type Props = {
    data: IPriceList
    deleteTariff: (id: number)=> void
}

const TariffItem:React.FC<Props> = (props) => {
  const {t} = useTranslation();
  const http = useHttp();
  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const created_at = moment(props.data?.created_at).format('DD.MM.YYYY');

  const downloadFile = async () => {
    setIsLoading(true);
    http.priceListHttp.downloadFile(props.data.file.id).then(res => {
      const element = document.createElement('a');

      const file = new Blob(
        [res.data],
        {type: res.data.type},
      );

      element.href = URL.createObjectURL(file);
      element.download = props.data.file.name;
      element.click();
    }).finally(()=>{
      setIsLoading(false);
    });

  };

  return (
    <div className="price-list-tariff-item">
      <div className="tariff-item-header">
        <div className="created-info">{created_at} - {props.data?.created_by.full_name}</div>
        <div className="delete-btn" onClick={()=> setOpenModal(true)}> <FontAwesomeIcon icon={faTrash}/></div>
      </div>
      <div className="tariff-message">{props.data?.message}</div>
      <div className="tariff-item-body">
        <div className="attached-file-title">{t('_attached_file')}:</div>
        <div className="tariff-data">
          <div className="tariff-file-icon" onClick={downloadFile}>  {isLoading && <Spinner class="extra-small tariff-file-spinner"/>}<FontAwesomeIcon className={`excel-icon ${isLoading ? 'blur' : ''}`} icon={faFileExcel} /></div>
          <div className="tariff-file-details">
            <div className="tariff-file-detail"><strong>{t('_name')}:</strong> {props.data?.file.name}</div>
            <div className="tariff-file-detail"><strong>{t('_type')}:</strong> {t(`_${props.data?.file.file_translation.toLocaleLowerCase()}`)}</div>
            <div className="tariff-file-detail"><strong>{t('_cod_fou')}:</strong> {props.data?.file.code_fou}</div>
            <div className="tariff-file-detail"><strong>{t('_special_price')}:</strong> {props.data?.file.is_special_price ? t('_yes') : t('_no')}</div>
          </div>
        </div>
      </div>
      <div className="tariff-item-footer">
        <div className={`mapping-status ${props.data?.file.load_file.status_code === 200 ? 'success' : 'error'}`}> {props.data?.file.load_file.message}</div>
      </div>
      <Modal
        openModal={openModal}
        setOpenModal={setOpenModal}
        childrenComponent={<DeleteConfirmationTariffModal deleteTariff={() => props.deleteTariff(props.data?.id)} setOpenModal={setOpenModal} />}
      />
    </div>
  );
};

export default TariffItem;