import { createAsyncThunk } from '@reduxjs/toolkit';
import { ICategoriesParams } from 'utils/hooks/http/categories.http';
import useHttp from 'utils/hooks/useHttp';

export const fetchCategoriesData = createAsyncThunk('categories/fetchCategories', async (params: ICategoriesParams, { rejectWithValue }) => {
  try {
    const http = useHttp();
    const response = await http.categories.getCategories(params);

    return response;
  } catch (e) {

    // return rejectWithValue('fetch catalogs error');
    console.log('fetch catalogs error');

    return [];
  }
});
