import React, {useEffect, useState} from 'react';
import i18n from 'i18next';
import {DEFAULT_COUNTRY_IMAGE, languages} from 'utils/constants/locales';
import {IDropdownModel} from 'components/dropdowns/Dropdown.model';
import {getCountryFromLocale, getLanguageFromLocale, getLanguageFromLocalStorage} from 'utils/locale';
import MiniDropdown from 'components/dropdowns/MiniDropdown/MiniDropdown';
import {useTranslation} from 'react-i18next';
import AutomotorFrance from 'assets/images/automotor-france.png';
import FactoryRange from 'assets/images/factory-range.png';
import  './NavBarPresentation.scss';
import { NavLink, useSearchParams } from 'react-router-dom';


const NavBarPresentation: React.FC = () => {
  const { t } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState<IDropdownModel>(getLanguageFromLocalStorage() ?? languages[0]);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const language = getLanguageFromLocale(i18n);

    if (language) {
      setSelectedLanguage(language);
    }
  }, [i18n.language]);

  const changeLanguage = (item: IDropdownModel) => {
    const country = getCountryFromLocale(i18n);

    if (!country) {
      return;
    }

    const locale = `${item.id}-${country.id}`;


    localStorage.setItem('locale', locale);
    i18n.changeLanguage(locale);
  };

  function logo(){
    if(searchParams.get('catalog_id') === 'AUT' ){
      return AutomotorFrance;
    } else {
      return FactoryRange;
    }
  }


  return (
    <div className="presentation-navbar-container">
      <div className="presentation-navbar">
        <NavLink className="presentation-nav-logo" to="/public">
          {searchParams.get('catalog_id') && <img src={logo()}/>}
        </NavLink>
        <div className="mini-dropdowns">
          <div className="language-dropdown-title"> {t('_language')}</div>
          <MiniDropdown
            onClick={changeLanguage}
            content={selectedLanguage?.id}
            data={languages}
            image={selectedLanguage ? selectedLanguage.image : DEFAULT_COUNTRY_IMAGE}
          />
        </div>
      </div>
    </div>
  );
};

NavBarPresentation.displayName = 'NavBarPresentation';

export default NavBarPresentation;
