import React, { useEffect, useState } from 'react';
import { createSearchParams, NavLink } from 'react-router-dom';

import './TabCard.scss';
import { resetCarPartsCategoriesSlice } from '../../store/slices/car-parts-categories.slice';
import { useAppDispatch } from '../../utils/hooks/redux';

type Props = {
  type: string;
  img?: string;
  date: string;
  title: string;
  description: string;
  model: string;
  modelDescription: string;
  card?;
};

const TabCardView: React.FC<Props> = (props: Props) => {
  const [link, setLink] = useState('');
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (props.type == 'vehicles') {
      dispatch(resetCarPartsCategoriesSlice());

      const newLink = ({
        pathname: `/reliable/rmi/${props.card.car_variant.id}/catalogs`,
        search: createSearchParams({
          source: props.card.car_variant.source,
          vehicle_identifier: props.card.car_variant.vehicle_identifier,
          ...(props.card.car_variant.vin && { vin: props.card.car_variant.vin }),
          ...(props.card.car_variant.immat && { plate_number: props.card.car_variant.immat }),
        }).toString(),
      });

      setLink(newLink.pathname + '?' + newLink.search);

    }

    if (props.type === 'pieces') {
      if (props.card) {
        const newLink2 = ({
          pathname: '/car-parts',
          search: createSearchParams({
            page: 1,
            source: props.card.source,
            subcategory_id: props.card.subcategory_id,
            man_sup_id: props.card.data_supplier_id ? props.card.data_supplier_id : props.card.man_sup_id,
            q: props.card.reference,
            ...(props.card.type && { type: props.card.type }),
          }).toString(),
        });

        setLink(
          newLink2.pathname + '?' + newLink2.search
        );
      }
    }
  }, [props.card]);

  return (
    <div className="tabcard-container">
      <NavLink className="image-wrapper" to={link}>
        <img alt="image" className="image" src={props.img ? props.img : '/images/car-part-placeholder-big.png'}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = '/images/car-part-placeholder-big.png';
          }}
        />
      </NavLink>
      <div className="wrapper">
        <div className="date">{props.date}</div>
        <NavLink className="nameWrapper" to={link} >
          <div className="tab-card-title">{props.title}</div>
          <div className="tab-card-description">{props.description}</div>
        </NavLink>
        <div className="model">
          <span>{props.model} </span> <div className="modelDescription">{props.modelDescription}</div>
        </div>
      </div>
    </div>
  );
};

TabCardView.displayName = 'TabCardView';

export default TabCardView;
