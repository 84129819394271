import React, { useContext } from 'react';
import Spinner from '../../../../../components/spinner/Spinner';
import { FavoriteContext, IFavoriteContext } from '../../../../../context/FavoriteContext';
import FavoriteSidebar from './FavoriteSidebar/FavoriteSidebar';
import FavoriteItems from './FavoriteItems/FavoriteItems';
import './FavoritePartTabContent.scss';

type Props = {

}

const FavoritePartTabContent: React.FC<Props> = () => {
  const { favoriteItemsLoading } = useContext(FavoriteContext) as IFavoriteContext;

  return (
    <div className="favorite-part-tab">
      <div className="favorite-sidebar-wrapper">
        <FavoriteSidebar />
      </div>
      <div className="items-wrapper">
        {
          favoriteItemsLoading ? (
            <Spinner />
          ) : (
            <FavoriteItems />
          )
        }
      </div>
    </div>
  );
};


FavoritePartTabContent.displayName = 'FavoritePartTabContent';

export default FavoritePartTabContent;
