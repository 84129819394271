import React from 'react';

import CommandsTableView from './CommandsTable.view';

type Props = {};

const CommandsTable: React.FC<Props> = () => {
  return <CommandsTableView></CommandsTableView>;
};

CommandsTable.displayName = 'CommandsTable';

export default CommandsTable;
