import AuthHttp from './http/auth.http';
import SearchByRefHttp from './http/search-by-reference.http';
import SearchByVinHttp from './http/search-by-vin.http';
import SelectClinetHttp from './http/select-client.http';
import VehicleSelectorHttp from './http/vehicle-selector.http';
import TruckSelectorHttp from './http/truck-selector.http';
import CartHttp from './http/cart.http';
import OrderHttp from './http/order.http';
import UserHttp from './http/user.http';
import HistoryHttp from './http/history.http';
import CatalogsHttp from './http/catalogs.http';
import CategoriesHttp from './http/categories.http';
import ForgotPasswordHttp from './http/forgot-password.http';
import MessagingHttp from './http/messaging';
import CarPartsHttp from './http/car-parts.http';
import TariffCatalogsHttp from './http/tariff-catalogs.http';
import EbooksHttp from './http/ebooks.http';
import VideoHttp from './http/video.http';
import FavoriteHttp from './http/favorite.http';
import ClientsHttp from './http/clients.http';
import ProformaHttp from './http/proforma.http';
import SearchByRefAllHttp from './http/search-by-reference-all.http';
import AutomotorTariffsHttp from './http/automotor-tariffs.http';
import PriceHttp from './http/price.http';
import SearchByPlateHttp from './http/search-by-plate.http';
import RemainingHttp from './http/remaining.http';
import FilterSuppliersHttp from './http/filter-suppliers.http';
import ProformaAmerigoHttp from './http/proforma-amerigo.http';
import OrdeClientsHttp from './http/order-clients.http';
import OilHttp from './http/oil.http';
import TyreHttp from './http/tyre';
import PlatformAdminMessaging from './http/platformAdminMessaging';
import ConsumableHttp from './http/consumable';
import BatteryHttp from './http/batteries-catalog.http';
import NonTecdocCatalogHttp from './http/catalog_non_tecdoc';
import OilBrandHttp from './http/oil-brand.http';
import VehicleCatalogssHttp from './http/vehicle-catalogs.http';
import RetailerHttp from './http/retailer.http';
import VehicleSelectorCatalogHttp from './http/vehicle-selector-catalog.http';
import ProductsViewModeHttp from './http/products-view-mode.http';
import StatisticsHttp from './http/statistics.http';
import CirrusHttp from './http/cirrus.http';
import NotificationsHttp from './http/notifications.http';

//TecRMI
import AdjustHttp from 'utils/rmi/api/adjust.http';
import TokenHttp from 'utils/rmi/api/token.http';
import hasTecRMITabsHttp from 'utils/rmi/api/has-tecrmi-tabs.http';
import TyreBrandHttp from './http/tyre-brand.http';
import ConsumableBrandHttp from './http/consumable-brand.http';
import ConsumableCategoryHttp from './http/category-brand.http';
import DiagnostictHttp from 'utils/rmi/api/diagnostic.http';
import ManualsHttp from 'utils/rmi/api/manuals.http';
import ActiaHttp from './http/actia.http';
import TyresHttpTecRmi from 'utils/rmi/api/tyres.http';
import ComponentsHttpTecRmi from 'utils/rmi/api/tec-comp-position.http';
import MaintenanceHttp from 'utils/rmi/api/maintenance.http';
import WiringHttp from 'utils/rmi/api/wiring.http';
import RepairItemsHttp from 'utils/rmi/api/repair-items.http';
import PricesHttp from 'utils/rmi/api/prices.http';

//Eina
import EinaHttp from '../eina/einaHttp';
import EinaInternalHttp from '../eina/einaInternalHttp';
import GraphicstHttp from 'utils/rmi/api/graphics.http';
import RelaysFusesHttp from 'utils/rmi/api/relays-fuses.http';
import ServiceNewsletterHttp from 'utils/rmi/api/service-newsletter.http';
import ManagerGroupSelectClinetHttp from './http/manager-group-select-client.http';
import OilsCatalogHttp from './http/oils-catalog.http';
import FinancialHttp from './http/financial.http';

// Presentation
import PresentationHttp from './http/presentation.http';
import ProformaMappingHttp from './http/proforma-mapping.http';
import ConsumablesCatalogHttp from './http/consumables-catalog.http';
import PriceListHttp from './http/price-list.http';
import BatteryBrandHttp from './http/battery-brand.http';
import BatteryCategoryHttp from './http/category-battery.http';
import BatteryAdminHttp from './http/battery';
import GlobalSearchHttp from './http/global-search.http';
import IndustrialHttp from './http/industrial.http';

// landing page
import NewsHttp from './http/news.http';
import PromotionsHttp from './http/promotions.http';
import PublicAppHttp from './http/public.http';
import StockHttp from './http/stock.http';
import StockPriceHttp from './http/stock-price.http';
import ProvidersHttp from './http/providers.http';
import CartEspaceHttp from './http/cart-espace.http';
import CountryRestrictionHttp from './http/country-restriction.http';
import AllowedListHttp from './http/allowed-list.http';
import PriceMatrixHttp from './http/price-matrix.http';
import PartsReplacementHttp from './http/parts-replacement.http';
import OilCategoryHttp from './http/category-oil.http';

const auth = new AuthHttp();
const selectClient = new SelectClinetHttp();
const managerGroupSelectClinetHttp = new ManagerGroupSelectClinetHttp();
const searchByRef = new SearchByRefHttp();
const searchByRefAll = new SearchByRefAllHttp();
const vehicleSelector = new VehicleSelectorHttp();
const truckSelector = new TruckSelectorHttp();
const cart = new CartHttp();
const cartEspace = new CartEspaceHttp();
const order = new OrderHttp();
const searchByVin = new SearchByVinHttp();
const user = new UserHttp();
const history = new HistoryHttp();
const catalogs = new CatalogsHttp();
const messaging = new MessagingHttp();
const platformAdminMessaging = new PlatformAdminMessaging();
const categories = new CategoriesHttp();
const forgotPassword = new ForgotPasswordHttp();
const carParts = new CarPartsHttp();
const tariffCatalogs = new TariffCatalogsHttp();
const ebooks = new EbooksHttp();
const video = new VideoHttp();
const clients = new ClientsHttp();
const proforma = new ProformaHttp();
const automotorTariff = new AutomotorTariffsHttp();
const favorite = new FavoriteHttp();
const price = new PriceHttp();
const searchByPlate = new SearchByPlateHttp();
const globalSearch = new GlobalSearchHttp();
const remainingHttp = new RemainingHttp();
const filterSuppliers = new FilterSuppliersHttp();
const proformaAmerigo = new ProformaAmerigoHttp();
const orderClients = new OrdeClientsHttp();
const oilHttp = new OilHttp();
const tyreHttp = new TyreHttp();
const consumableHttp = new ConsumableHttp();
const vehicleCatalogs = new VehicleCatalogssHttp();
const oilBrandHttp = new OilBrandHttp();
const tyreBrandHttp = new TyreBrandHttp();
const consumableBrandHttp = new ConsumableBrandHttp();
const consumableCategoryHttp = new ConsumableCategoryHttp();
const retailerHttp = new RetailerHttp();
const serviceNewsletterHttp = new ServiceNewsletterHttp();
const oilCatalogHttp = new OilsCatalogHttp();
const proformaMapping = new ProformaMappingHttp();
const financialHttp = new FinancialHttp();
const priceListHttp = new PriceListHttp();
const batteriesHttp = new BatteryHttp();
const batteryBrandHttp = new BatteryBrandHttp();
const batteryCategoryHttp = new BatteryCategoryHttp();
const batteryAdminHttp = new BatteryAdminHttp();
const vehicleSelectorCatalog = new VehicleSelectorCatalogHttp();
const productsViewModeHttp = new ProductsViewModeHttp();
const promotions = new PromotionsHttp();
const publicApp = new PublicAppHttp();
const statistics = new StatisticsHttp();
const providers = new ProvidersHttp();
const nonTecDocCatalog = new NonTecdocCatalogHttp();
const cirrus = new CirrusHttp();
const notifications = new NotificationsHttp();
const industrial = new IndustrialHttp();
const countryRestriction = new CountryRestrictionHttp();
// Diagnostique
const actiaHttp = new ActiaHttp();

const oilCategoryHttp = new OilCategoryHttp();

//TecRMI
const authTecRmi = new TokenHttp();
const wiringHttp = new WiringHttp();
const adjustHttp = new AdjustHttp();
const manualsHttp = new ManualsHttp();
const hasTecRMITabs = new hasTecRMITabsHttp();
const diagnostictHttp = new DiagnostictHttp();
const rmiManuals = new ManualsHttp();
const rmiTyresHttp = new TyresHttpTecRmi();
const componentsHttpTecRmi = new ComponentsHttpTecRmi();
const maintenanceHttp = new MaintenanceHttp();
const graphicstHttp = new GraphicstHttp();
const relaysFusesHttp = new RelaysFusesHttp();
const repairItemsHttp = new RepairItemsHttp();
const pricesHttp = new PricesHttp();
const consumableCatalogHttp = new ConsumablesCatalogHttp();
const stockHttp = new StockHttp();
const stockPriceHttp = new StockPriceHttp();
const allowedList = new AllowedListHttp();

//Eina
const eina = new EinaHttp();
const einaInternal = new EinaInternalHttp();

//Presentation
const presentation = new PresentationHttp();

// Landing Page
const news = new NewsHttp();
const priceMatrix = new PriceMatrixHttp();
const partsReplacement = new PartsReplacementHttp();

type HttpHook = {
  auth: AuthHttp;
  selectClient: SelectClinetHttp;
  managerGroupSelectClinetHttp: ManagerGroupSelectClinetHttp;
  searchByRef: SearchByRefHttp;
  searchByRefAll: SearchByRefAllHttp;
  vehicleSelector: VehicleSelectorHttp;
  truckSelector: TruckSelectorHttp;
  user: UserHttp;
  history: HistoryHttp;
  cart: CartHttp;
  order: OrderHttp;
  searchByVin: SearchByVinHttp;
  catalogs: CatalogsHttp;
  categories: CategoriesHttp;
  forgotPassword: ForgotPasswordHttp;
  messaging: MessagingHttp;
  platformAdminMessaging: PlatformAdminMessaging;
  carParts: CarPartsHttp;
  tariffCatalogs: TariffCatalogsHttp;
  ebooks: EbooksHttp;
  video: VideoHttp;
  clients: ClientsHttp;
  proforma: ProformaHttp;
  automotorTariff: AutomotorTariffsHttp;
  price: PriceHttp;
  searchByPlate: SearchByPlateHttp;
  globalSearch: GlobalSearchHttp;
  favorite: FavoriteHttp;
  remainingHttp: RemainingHttp;
  filterSuppliers: FilterSuppliersHttp;
  proformaAmerigo: ProformaAmerigoHttp;
  orderClients: OrdeClientsHttp;
  oilHttp: OilHttp;
  oilCategoryHttp: OilCategoryHttp;
  tyreHttp: TyreHttp;
  consumableHttp: ConsumableHttp;
  vehicleCatalogs: VehicleCatalogssHttp;
  oilBrandHttp: OilBrandHttp;
  adjustHttp: AdjustHttp;
  manualsHttp: ManualsHttp;
  wiringHttp: WiringHttp;
  authTecRmi: TokenHttp;
  hasTecRMITabs: hasTecRMITabsHttp;
  rmiManuals: ManualsHttp;
  tyreBrandHttp: TyreBrandHttp;
  consumableBrandHttp: ConsumableBrandHttp;
  consumableCategoryHttp: ConsumableCategoryHttp;
  diagnostictHttp: DiagnostictHttp;
  actiaHttp: ActiaHttp;
  rmiTyresHttp: TyresHttpTecRmi;
  componentsHttpTecRmi: ComponentsHttpTecRmi;
  maintenanceHttp: MaintenanceHttp;
  serviceNewsletterHttp: ServiceNewsletterHttp;
  eina: EinaHttp;
  einaInternal: EinaInternalHttp;
  graphicstHttp: GraphicstHttp;
  relaysFusesHttp: RelaysFusesHttp;
  repairItemsHttp: RepairItemsHttp;
  retailerHttp: RetailerHttp;
  oilCatalogHttp: OilsCatalogHttp;
  consumableCatalogHttp: ConsumablesCatalogHttp;
  financialHttp: FinancialHttp;
  pricesHttp: PricesHttp;
  presentation: PresentationHttp;
  proformaMapping: ProformaMappingHttp;
  priceListHttp: PriceListHttp;
  batteriesHttp: BatteryHttp;
  batteryBrandHttp: BatteryBrandHttp;
  batteryCategoryHttp: BatteryCategoryHttp;
  batteryAdminHttp: BatteryAdminHttp;
  vehicleSelectorCatalog: VehicleSelectorCatalogHttp;
  productsViewModeHttp: ProductsViewModeHttp;
  news: NewsHttp;
  promotions: PromotionsHttp;
  publicApp: PublicAppHttp;
  statistics: StatisticsHttp;
  stockHttp: StockHttp;
  stockPriceHttp: StockPriceHttp;
  providers: ProvidersHttp;
  nonTecDocCatalog: NonTecdocCatalogHttp;
  cirrus: CirrusHttp;
  notifications: NotificationsHttp;
  industrial: IndustrialHttp;
  cartEspace: CartEspaceHttp;
  countryRestriction: CountryRestrictionHttp;
  allowedList: AllowedListHttp;
  priceMatrix: PriceMatrixHttp;
  partsReplacement: PartsReplacementHttp;
};

const useHttp = (): HttpHook => {
  return {
    auth,
    categories,
    selectClient,
    catalogs,
    searchByRef,
    searchByRefAll,
    cart,
    user,
    searchByVin,
    order,
    history,
    vehicleSelector,
    truckSelector,
    messaging,
    platformAdminMessaging,
    forgotPassword,
    carParts,
    ebooks,
    video,
    tariffCatalogs,
    clients,
    proforma,
    automotorTariff,
    price,
    searchByPlate,
    favorite,
    remainingHttp,
    filterSuppliers,
    proformaAmerigo,
    orderClients,
    oilHttp,
    tyreHttp,
    vehicleCatalogs,
    consumableHttp,
    oilBrandHttp,
    adjustHttp,
    manualsHttp,
    wiringHttp,
    authTecRmi,
    hasTecRMITabs,
    rmiManuals,
    tyreBrandHttp,
    consumableBrandHttp,
    consumableCategoryHttp,
    diagnostictHttp,
    actiaHttp,
    rmiTyresHttp,
    componentsHttpTecRmi,
    maintenanceHttp,
    eina,
    einaInternal,
    graphicstHttp,
    relaysFusesHttp,
    retailerHttp,
    serviceNewsletterHttp,
    repairItemsHttp,
    oilCatalogHttp,
    managerGroupSelectClinetHttp,
    financialHttp,
    pricesHttp,
    presentation,
    proformaMapping,
    consumableCatalogHttp,
    priceListHttp,
    batteriesHttp,
    batteryBrandHttp,
    batteryCategoryHttp,
    batteryAdminHttp,
    vehicleSelectorCatalog,
    globalSearch,
    productsViewModeHttp,
    news,
    promotions,
    publicApp,
    statistics,
    stockHttp,
    stockPriceHttp,
    providers,
    nonTecDocCatalog,
    cirrus,
    notifications,
    industrial,
    cartEspace,
    countryRestriction,
    allowedList,
    priceMatrix,
    partsReplacement,
    oilCategoryHttp
  };
};

export default useHttp;
