import React, { useEffect, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import LoginView from './Login.view';
import { useAppDispatch } from 'utils/hooks/redux';
import { login } from 'store/reducers/useSlices';
import { useNavigate } from 'react-router-dom';
import useHttp from 'utils/hooks/useHttp';
import { notify } from 'utils/marketplace';
import { ILoginResponse } from 'utils/hooks/http/auth.http';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import LoginMobileView from './Mobile/LoginMobile.view';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import { DEFAULT_LOCALE, countries } from 'utils/constants/locales';
import i18n from 'i18n';
import { getCountryFromLocale } from 'utils/locale';

const Login: React.FC = () => {
  const {
    getValues,
    register,
    handleSubmit,
    formState: { errors },
    // } = useForm<ILoginForm>();
  } = useForm<FieldValues>();

  const { t } = useTranslation();

  const [isLoading, setisLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { isMobile } = useDeviceDetect();
  const dispatch = useAppDispatch();
  const http = useHttp();

  useEffect(() => {
    if (localStorage.getItem('token') && localStorage.getItem('userData')) {
      navigate('/');
    }
  }, []);

  function validCountryCode(userCountryCode){
    return countries.find(country => country.id === userCountryCode) || DEFAULT_LOCALE;
  }

  const onSubmit = async () => {
    setisLoading(true);
    const values = getValues();

    http.auth
      .login({ password: values.password, username: values.email })
      .then((res: ILoginResponse) => {
        localStorage.setItem('token', res.token);
        localStorage.setItem('userData', JSON.stringify(res.user));

        // localStorage.setItem('locale', res.user.language + '-' + validCountryCode(res.user.client_country_code)?.['id']);
        const country = getCountryFromLocale(i18n);

        if (country) {
          const locale = `${res.user.language}-${country.id}`;

          localStorage.setItem('locale', locale);
          i18n.changeLanguage(locale);
        }

        dispatch(login(res.user));
        navigate('/');
      })
      .catch((error: AxiosError) => {
        console.log(error.response?.status == 401);

        if (error.response?.status == 401) {
          notify(t('_invalid_credentials'));
        } else {
          notify(t('_something_went_wrong'));
        }

      })
      .finally(() => {
        setisLoading(false);
      });
  };

  if (isMobile) {
    return <LoginMobileView handleSubmit={handleSubmit} onSubmit={onSubmit} register={register} errors={errors} isLoading={isLoading} />;
  }

  return <LoginView handleSubmit={handleSubmit} onSubmit={onSubmit} register={register} errors={errors} isLoading={isLoading}></LoginView>;
};

Login.displayName = 'Login';

export default Login;
