import React from 'react';
import { Route, Routes } from 'react-router-dom';
import MainLayout from './main-layout/MainLayout';
import PresentationCarParts from './pages/car-parts-page/PresentationCarParts';
import PresentationCarPartsView from './pages/car-parts-view-page/PresentationCarPartsView';
import PresentationCategories from './pages/categories-page/PresentationCategories';
import PresentationSuppliers from './pages/supplier-page/PresentationSuppliers';

const Presentation: React.FC = () => {

  return (
    <Routes>
      <Route path="/public" element={<MainLayout />}>
        <Route path="" element={<PresentationSuppliers />} />
        <Route path="car-parts" element={<PresentationCarParts />} />
        <Route path="car-parts/view/:id" element={<PresentationCarPartsView />} />
        <Route path="categories" element={<PresentationCategories />} />
      </Route>
    </Routes>
  );
};

Presentation.displayName = 'Presentation';

export default Presentation;
