
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import './PromotionDescriptionModal.scss';
import { useTranslation } from 'react-i18next';

type Props = {
  data?;
  setOpenModal: (e: boolean) => void;
};

const PromotionDescriptionModal: React.FC<Props> = (props) => {
  const {t} = useTranslation();

  return (
    <div className="promotion-description-info">
      <div className="promotion-description-header">
        <div className="promotion-description-title">{t('_description')}</div>
        <div className="close-modal" onClick={() => props.setOpenModal(false)}><FontAwesomeIcon icon={faClose} /></div>
      </div>
      <div className="description">
        {props.data}
      </div>
    </div>
  );
};

export default PromotionDescriptionModal;
