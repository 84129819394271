
import {createAsyncThunk} from '@reduxjs/toolkit';
import useHttp from 'utils/hooks/useHttp';
import {IGetCountNewMessagesParams} from '../../interfaces/messaging';

export const countNewMessages = createAsyncThunk('messages/count-new', async (supplierGroupId: number, { rejectWithValue }) => {
  try {

    const http = useHttp();

    const params: IGetCountNewMessagesParams = {};

    if(supplierGroupId) {
      params.catalog_id = String(supplierGroupId);
    }

    const { data } = await http.messaging.countNewMessages(params);

    return data;
  } catch (e) {
    console.log(e);


    return rejectWithValue('count new messages error');
  }
});