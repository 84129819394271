import React from 'react';

type Props = { value: string };

const ReferenceCellView: React.FC<Props> = (props: Props) => {
  return <div dangerouslySetInnerHTML={{ __html: String(props.value) }} className="reference-cell-wrapper"></div>;
};

ReferenceCellView.displayName = 'ReferenceCellView';

export default ReferenceCellView;
