import React, { useEffect, useMemo, useState, } from 'react';
import './CountryRestriction.scss';
import Modal from 'components/modal/Modal';
import { useTranslation } from 'react-i18next';
import { Column, useExpanded, useTable } from 'react-table';
import useHttp from 'utils/hooks/useHttp';
import Spinner from 'components/spinner/Spinner';
import i18n from 'i18n';
import ModalDelete from 'components/CatalogNaf/ModalDelete/ModalDelete';
import Head from 'components/tables/Cells/Head/Head';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faEdit, faFilter, faTrash, faXmark } from '@fortawesome/free-solid-svg-icons';
import Pagination from 'components/pagination/Pagination';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import ExpandButton from 'components/tables/TableMoreInformation/ExpandButton/ExpandButton';
import TableMoreInformation from 'components/tables/TableMoreInformation/TableMoreInformation';
import CreateCountryRestriction from './ModalCreate/CreateCountryRestriction';
import { ICountryDetails } from 'utils/hooks/http/country-restriction.http';
import { ICountries, IData } from 'interfaces/selectClient';
import { IOption } from 'interfaces/select';
import EditCountryRestriction from './ModalEdit/EditCountryRestriction';
import FilterCountryRestriction from './Filter/filter';


type Props = {

};

const CountryRestriction: React.FC<Props> = () => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openModalEdit, setOpenModalEdit] = useState<boolean>(false);
  const [submit, setSubmit] = useState<boolean>(false);
  const { t } = useTranslation();
  const http = useHttp();
  const [loading, setLoading] = useState(false);
  const [data, setData] = React.useState<ICountryDetails[]>([]);
  const [params, setParams] = React.useState({ page: 1 });
  const [openModalDelete, setOpenModalDelete] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<number | undefined>();
  const [editRow, setEditRow] = useState<ICountryDetails | undefined>();
  const [pageCount, setPageCount] = useState(0);
  const [forcePage, setForcePage] = useState<number>(1);
  const [clients, setClients] = useState<IOption[]>([]);
  const { isMobile, isTablet, isDesktop } = useDeviceDetect();
  const [isActive, setIsActive] = useState(false);
  const [countries, setcountries] = useState<ICountries[]>([]);

  const request = (params) => {

    http.countryRestriction.getCountryRestriction(params).then((res) => {
      setData(res.data.data);
      setPageCount(res.data.total_pages);
    }).finally(() => {
      setLoading(false);
    })
      .catch(() => {
        setLoading(false);
      });

  };


  useEffect(() => {
    setLoading(true);
    Promise.all([
      http.countryRestriction.getCountryRestriction(params),
      http.countryRestriction.getClients(),
      http.selectClient.countries()
    ]).then((res) => {

      setData(res[0].data.data);
      setPageCount(res[0].data.total_pages);
      setClients(transformOptions(res[1].data));
      setcountries(res[2].data);
    }).finally(() => {
      setLoading(false);
    }).catch(() => {
      setLoading(false);
    });


  }, []);


  const transformOptions = (optionsFromResponse: IData[]) => {
    if (!optionsFromResponse) {
      return [];
    }


    const uniqueOptions = optionsFromResponse
      .map((item) => (

        {

          label: item.name,
          value: String(item.id),
          country: item.country
        }))
      .filter((item, index, self) => (
        self.findIndex((i) => i.label === item.label && i.value === item.value) === index
      ));

    return uniqueOptions;
  };

  useEffect(() => {

    setLoading(true);
    request(params);
    setSubmit(false);


  }, [params]);

  useEffect(() => {
    if (submit) {
      setLoading(true);
      request(params);
      setSubmit(false);
    }


  }, [submit]);

  const columnsTable = useMemo(
    (): Column<ICountryDetails>[] => columPromotions(), [i18n.language, data, isMobile, isTablet, isDesktop]
  );

  function columPromotions(): Column<ICountryDetails>[] {

    return [
      {
        id: 'expander',
        // eslint-disable-next-line react/destructuring-assignment
        Header: '',
        width: 15,
        // eslint-disable-next-line react/destructuring-assignment
        Cell: ({ row }) => {
          return (
            //Applying the toggle expander props i.e onClick, style and title
            <ExpandButton row={row} />
          );
        }
      },

      {
        Header: <Head text={t('_client')}></Head>,
        accessor: 'client_name',


      },
      {
        Header: <Head text={t('_origin_country')}></Head>,
        accessor: 'country_origin'
      },
      // {
      //   Header: <Head text={t('_delivery_country')}></Head>,
      //   accessor: 'country_delivery',

      // },

      {
        Header: <Head text={t('_actions')}></Head>,
        accessor: 'id',
        width: isDesktop ? 30 : 60,
        maxWidth: isDesktop ? 30 : 60,
        Cell: (cell) => (<div className="row-aling-right"> <FontAwesomeIcon icon={faEdit} onClick={() => { setEditRow(cell.row.original); setOpenModalEdit(true); }} /> <FontAwesomeIcon icon={faTrash} onClick={() => { setDeleteId(Number(cell.row.original.id)); setOpenModalDelete(true); }} /></div>),
      }


    ];

  }


  if (!isMobile) {
    columnsTable.shift();
  }

  const hiddenColumns = isMobile ? ['country_origin'] : [];

  const initialState = hiddenColumns ? {
    'hiddenColumns': hiddenColumns
  } : {};

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns: columnsTable,
    data: data,
    initialState
  }, useExpanded);

  const handlePageClick = (page: number) => {

    setForcePage(page);

    setParams({ ...params, ...{ page } });


  };


  return (
    <div className="country_restriction_header ">

      <div className="country_restriction_title">
        {t('_country_restriction')}
      </div>

      {
        isMobile ?
          <div className="filter-btns">
            <button className="button-filter-promo" onClick={() => setIsActive(true)}>< FontAwesomeIcon icon={faFilter} /> {t('_filter')}</button >

            <div className="filter-link-btn">
              <button className="add-btn" onClick={() => setOpenModal(true)}> <FontAwesomeIcon icon={faAdd} className="icon" /> {t('_create_country_restriction')} </button>
            </div>
          </div>
          :
          null
      }
      <div className={`promo-filter ${isMobile && !isActive ? 'hidden-filter-promo' : 'show-filter-promo'}`}>
        {
          isMobile ?
            <div className="close-btn">
              <FontAwesomeIcon icon={faXmark} onClick={() => { setIsActive(false); }} className="close-btn-icon" />
            </div> :
            null
        }
        <FilterCountryRestriction setOpenModal={setOpenModal} clients={clients} setParams={setParams} setForcePage={setForcePage} setIsActive={setIsActive} countries={countries} />
      </div>


      <div className="table-wrapper">
        {loading ? <Spinner /> :
          <table {...getTableProps()} >

            <thead className="thead">
              {headerGroups.map((headerGroup, key) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={key} className="row">
                  {headerGroup.headers.map((column, index) => (
                    <th {...column.getHeaderProps({
                      style: { minWidth: column.minWidth, width: column.width, maxWidth: column.maxWidth },
                    })} key={index} className="heading"
                    >
                      <div className="table-title">{column.render('Header')}</div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            {loading && data.length ?

              <tbody>
                <tr>
                  <td colSpan={Object.keys(rows[0].original).length}>
                    <Spinner class="extra-small" /></td>
                </tr>
              </tbody>
              :
              <tbody {...getTableBodyProps} className="body">

                {data.length ?

                  rows.map((row) => {

                    prepareRow(row);
                    const { key, ...restRowProps } = row.getRowProps();

                    return (
                      <React.Fragment key={key}>
                        <tr key={key} {...restRowProps} className="cell-row">
                          {row.cells.map((cell) => {
                            const { key, ...restCellProps } = cell.getCellProps();

                            return (
                              <td key={key} {...restCellProps} className="cell">
                                {cell.render('Cell')}
                              </td>
                            );
                          })}
                        </tr>
                        {row.isExpanded ? TableMoreInformation({ row, hiddenColumns: hiddenColumns, extraFieldsNumber: 2 }) : null}
                      </React.Fragment>
                    );
                  }) : <tr className="notFond"><td colSpan={5}>{`${t('_no_data_found')}`}</td></tr>}
              </tbody>
            }
          </table>
        }
        {pageCount > 1 && !loading ? < Pagination pageCount={pageCount} forcePage={forcePage} handlePageClick={handlePageClick} hiddenParamPage /> : null}
      </div>

      <Modal shouldCloseOnOverlayClick={false}
        openModal={openModalEdit}
        setOpenModal={setOpenModalEdit}
        childrenComponent={
          <EditCountryRestriction
            setOpenModal={setOpenModalEdit}
            setSubmit={setSubmit}
            editRow={editRow}
            clients={clients}
            countries={countries}
            loading={loading}
          />}
      />
      <Modal shouldCloseOnOverlayClick={false} openModal={openModal} setOpenModal={setOpenModal} childrenComponent={<CreateCountryRestriction setOpenModal={setOpenModal} setSubmit={setSubmit} clients={clients} countries={countries} loading={loading} />} />
      <Modal
        openModal={openModalDelete}
        setOpenModal={setOpenModalDelete}
        childrenComponent={<ModalDelete setOpenModalDelete={setOpenModalDelete} setSubmit={setSubmit} id={deleteId} link={http.countryRestriction.deleteCountryRestriction} />}
      />
    </div >
  );
};

export default CountryRestriction;
