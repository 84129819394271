import React from 'react';


import FavoriteView from './Favorite.view';


const Favorite: React.FC = () => {

  return <FavoriteView></FavoriteView>;
};

Favorite.displayName = 'Favorite';

export default Favorite;
