import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';

type Props = {
  roles: Array<String>;
};

const HasRoles: React.FC<React.PropsWithChildren<Props>> = (props: React.PropsWithChildren<Props>) => {
  const userData = useSelector((state: RootState) => state.userData.user);

  const checkRole = () => {
    if (userData?.roles && props.roles.some((role: String) => userData.roles.includes(role))) {
      return props.children;
    }
  };

  return <React.Fragment>{checkRole()}</React.Fragment>;
};

HasRoles.displayName = 'HasRoles';

export default HasRoles;
