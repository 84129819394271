import { IBrand } from 'interfaces/consumableBrand';
import React from 'react';
import ItemEshopBatteryBrandView from './ItemEshopBatteryBrandView';


type Props = {
  data: IBrand[],
  loading: boolean
  openModalBatteryBrand: (id: number) => void;
  deleteItem: (id: number) => void;
  handleOnChangeIsPartner: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const ItemEshopBatteryBrand: React.FC<Props> = (props) => {
  return <ItemEshopBatteryBrandView data={props.data} loading={props.loading} openModalBatteryBrand={props.openModalBatteryBrand} deleteItem={props.deleteItem} handleOnChangeIsPartner={props.handleOnChangeIsPartner} />;
};

ItemEshopBatteryBrand.displayName = ' ItemEshopBatteryBrand';

export default ItemEshopBatteryBrand;
