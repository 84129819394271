import React, { useEffect } from 'react';
import EspaceSupplierView from './EspaceSupplier.view';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'utils/hooks/redux';
import { useSelector } from 'react-redux';
import { getCatalogData, getIsLoadingCatalogData } from 'store/selectors/catalogs.selector';
import { fetchCatalogData } from 'store/reducers/catalog/catalogs.actions';

const EspaceSupplier: React.FC = () => {
  const loadingCatalogs = useSelector(getIsLoadingCatalogData);
  const catalogsData = useSelector(getCatalogData);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!Array.isArray(catalogsData) || !catalogsData.length) {
      fetchCatalogs();
    }
  }, [catalogsData]);

  function fetchCatalogs() {
    dispatch(fetchCatalogData());
  }


  const handleCatalogClick = (id) => {
    navigate(`/catalog/${id}`);
  };

  return <EspaceSupplierView handleCatalogClick={handleCatalogClick} loadingCatalogs={loadingCatalogs} catalogsData={catalogsData} />;
};

EspaceSupplier.displayName = 'EspaceSupplier';

export default EspaceSupplier;
