import {serverAxios} from '../../http';
import {IDataResponse, IParams} from '../../../models/video';

class VideoHttp {
  private static route = 'amerigo/supplier-amerigo/video';

  public async getVideoList(params: IParams): Promise<IDataResponse> {
    return serverAxios.get(VideoHttp.route, { params });
  }
}

export default VideoHttp;
