import React, { PropsWithChildren } from 'react';
import './LogoItem.scss';

type Props = {
  name?: string;
  image: string;
  onClick?: (id) => void;
  enabled?
};

const LogoItem: React.FC<Props> = (props: PropsWithChildren<Props>) => {
  return (
    <div className="item-container">
      <div className={`item ${props.enabled ? '' : 'enabled'}`} onClick={props.onClick}>
        <img src={props.image} alt={props.image ? props.image.substring(props.image.lastIndexOf('/')) : '/images/car-part-placeholder.png'} onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src = '/images/car-part-placeholder.png';
        }}
        />
        {/*<p>{props.image.substring(props.image.lastIndexOf('/'))+1}  </p>*/}
        <div className="item-name">{props.name}</div>
      </div>
    </div>
  );
};

LogoItem.displayName = 'LogoItem';

export default LogoItem;
