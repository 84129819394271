import { IPromise } from 'interfaces/selectClient';
import { addRouteParam, IHttpResponse, serverAxios } from '../../http';

class CountryRestrictionHttp {
  private static listRoute = 'clients/country-restriction/list';
  private static saveRoute = 'clients/country-restriction/save';
  private static deleteRoute = 'clients/country-restriction/delete';
  private static updateRoute = 'clients/country-restriction/:id/update';
  private static clients = 'seller/clients-simple';

  public getCountryRestriction(urlParams: { page: number }): Promise<IHttpResponse<IPromotionsResponse>> {
    return serverAxios.get(CountryRestrictionHttp.listRoute, { params: urlParams });
  }

  public getClients(): Promise<IPromise> {
    return serverAxios.get(CountryRestrictionHttp.clients);
  }

  public editCountryRestriction(data: FormData, id: number): Promise<IHttpResponse<{}>> {
    return serverAxios.post(addRouteParam(CountryRestrictionHttp.updateRoute, { id }), data);
  }
  public saveCountryRestriction(data: FormData): Promise<IHttpResponse<{}>> {
    return serverAxios.post(CountryRestrictionHttp.saveRoute, data);
  }
  public deleteCountryRestriction(id: number): Promise<IHttpResponse<null>> {
    return serverAxios.post(CountryRestrictionHttp.deleteRoute, { id: [id] });
  }
}

interface IPostPromotions {
  start_date: string;
  end_date: string;
  image: string;
  pdf: string;
}

export interface IPromotionsResponse {
  page: number;
  per_page: number;
  total_count: number;
  total_pages: number;
  data: Array<ICountryDetails>;
}

export interface ICountryDetails {
  id: number;
  client_id: number;
  client_name: string;
  country_origin: string;
  country_delivery: string;
}

export default CountryRestrictionHttp;
