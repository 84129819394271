import React from 'react';

import './Favorite.scss';
import Tabs from 'components/tab-filter/Content/Vehicle/Tabs/Tabs';
import FavoritePartTab from './Tabs/FavoritePartTab/FavoritePartTab';
import Tab from 'components/tab-filter/Content/Vehicle/Tabs/Tab';
import { useTranslation } from 'react-i18next';


type Props = {

};

const FavoriteView: React.FC<Props> = () => {
  const { t } = useTranslation();

  return (
    <div className="favorite-page-container">
      <div className="wrapper">
        <div className="title">{t('_my_favorite')}</div>
        <div className="tabs">
          <Tabs>
            <Tab title={t('_detached_pieces')}>
              <FavoritePartTab />
            </Tab>
            <Tab title={t('_vehicles')}></Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

FavoriteView.displayName = 'FavoriteView';

export default FavoriteView;
