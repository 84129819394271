import React from 'react';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import CatalogsOilsPage from './CatalogsOilsPage';
import CatalogsOilsPageMobile from './CatalogsOilsPageMobile/CatalogsOilsPageMobile';


const CatalogsOilsPageView: React.FC = () => {
  const {isMobile} = useDeviceDetect();

  return (
    isMobile ? <CatalogsOilsPageMobile /> : <CatalogsOilsPage />
  );
};

export default CatalogsOilsPageView;