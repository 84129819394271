import { HttpResponse } from 'utils/hooks/http/history.http';
import { tecRMIAxios } from '../../http';

import { IComponentsForType, IServiceNVehicleHtmlDiagnostics, IServiceVehicleDiagnosticsForType, IServiceVehicleListDiagnostics, IVehicleManualList } from '../interface/diagnostic.interface';


class DiagnostictHttp {
  private static ComponentsForType = '/Diagnostics/ComponentsForType';
  private static List = 'Diagnostics/List';
  private static Manual = 'Diagnostics/ManualHtml';
 

  public async fetchRMIVehicleComponentsForType(params: IServiceVehicleDiagnosticsForType): Promise<HttpResponse<IComponentsForType[]>> {
    return tecRMIAxios.get(DiagnostictHttp.ComponentsForType, {params});
  }

  public async fetchRMIVehicleList(params: IServiceVehicleListDiagnostics): Promise<HttpResponse<IVehicleManualList[]>> {
    return tecRMIAxios.get(DiagnostictHttp.List, {params});
  }

  public async fetchRMIVehicleManual(params: IServiceNVehicleHtmlDiagnostics): Promise<HttpResponse<string>> {
    return tecRMIAxios.get(DiagnostictHttp.Manual, {params});
  }
}


export default DiagnostictHttp;