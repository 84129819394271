import React from 'react';
import './TechnicalManuals.scss';
import Spinner from 'components/spinner/Spinner';
import VehicleIframe from 'pages/CatalogVehicle/TechnicalDocumentation/VehicleIframe/VehicleIframe';
import SidebarDetails from 'pages/CatalogVehicle/TechnicalDocumentation/SidebarDetails/SidebarDetails';
import {useTranslation} from 'react-i18next';

type Props = {
  loadingList: boolean;
  sidebarDetails: number;
  loadingHTML: boolean;
  iframeData?: string;
  onMainGroupClick: (item) => void;
  onSubGroupClick: (item) => void;
  onItemMpClick: (item) => void;
  onManualClick: (item) => void;
  mainGroup?
  subGroup?
  itemMps?
  itemMpsManual?
  activeItem?: string | number;
  onReturnClick?: () => void;
}

const TechnicalManualsView: React.FC<Props> = (props) => {

  const { t } = useTranslation();

  return (
    props.loadingList ? <Spinner class="small" /> : (
      <React.Fragment>
        <div className="tdoc-sidebar">
          {props.sidebarDetails === 1 &&
            <SidebarDetails
              title={`<span>${t('_technical_manuals')}</span>`}
              itemName="MainGroupName"
              onClick={props.onMainGroupClick}
              data={props.mainGroup}
              onReturnClick={props.onReturnClick}
            />}
          {props.sidebarDetails === 2 &&
            <SidebarDetails
              title={`${t('_technical_manuals')} / <span>${props.subGroup?.MainGroupName}</span>`}
              itemName="SubGroupName"
              onClick={props.onSubGroupClick}
              data={props.subGroup?.SubGroups}
              onReturnClick={props.onReturnClick}
            />}
          {props.sidebarDetails === 3 &&
            <SidebarDetails
              title={`${t('_technical_manuals')} / ${props.subGroup?.MainGroupName} / <span>${props.itemMps?.SubGroupName}</span>`}
              itemId="ItemMpId" itemName="ItemMpText"
              onClick={props.onItemMpClick}
              data={props.itemMps?.ItemMps}
              onReturnClick={props.onReturnClick}
            />}
          {props.sidebarDetails === 4 &&
            <SidebarDetails
              title={`${t('_technical_manuals')} / ${props.subGroup?.MainGroupName} / ${props.itemMps?.SubGroupName} / <span>${props.itemMpsManual?.ItemMpText}</span>`}
              itemId="ManualId"
              itemName="QualColText"
              onClick={props.onManualClick}
              data={props.itemMpsManual?.Manuals}
              onReturnClick={props.onReturnClick}
              activeItem={props.activeItem}
            />}
        </div>
        <div className="tdoc-content">
          {props.iframeData &&
            <VehicleIframe
              loading={props.loadingHTML}
              vehicleIframe={props.iframeData!}
            />
          }
        </div>
      </React.Fragment>
    )
  );
};

TechnicalManualsView.displayName = 'TechnicalManualsView';

export default TechnicalManualsView;