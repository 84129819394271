import { faCircleArrowLeft, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ButtonLink from 'components/buttons/ButtonLink/ButtonLink';

import { IGetCarPartDataParams } from 'models/car-parts.model';
import { IPartsCategory } from 'models/vehicle-catalogs';

import React from 'react';
import { useTranslation } from 'react-i18next';

import './CategoriesSidebar.scss';

type Props = {

  activeCategory: IPartsCategory | undefined;
  onCloseButtonClick?: () => void;
  onSidebarSubCategoryClick: (params: Omit<IGetCarPartDataParams, 'catalog_id' | 'page' | 'per_page'>) => void;
  onReturnButtonClick: () => void;
};

const CategoriesSidebarView: React.FC<Props> = (props) => {
  const {t} = useTranslation();

  return (
  
    <div className="sidebarWrapperSidebar">
      <div className="header">
        {props.onReturnButtonClick != null && (
          <span className="returnButton" onClick={props.onReturnButtonClick}>
            <FontAwesomeIcon icon={faCircleArrowLeft} className="icon" />
            {t('_return')}
          </span>
        )}
        <ButtonLink image={props.activeCategory?.image} className="buttonLink" text={props.activeCategory?.name || ''} showPdf={false}></ButtonLink>
        <FontAwesomeIcon icon={faXmark} onClick={props.onCloseButtonClick} className="icon" />
      </div>
      <div className="content">
        <div className="items">
          {props.activeCategory?.subcategories.map((items, key) => {
            return (
              <div key={key + items.name} className="item">
                <div className="title">{items.name}</div>
                {items.subcategories.map((item, key) => {
                  return (
                    <div
                      onClick={() =>
                        props.onSidebarSubCategoryClick({
                          code_groupe: item.code_groupe,
                          code_repere: item.code_repere,
                          code_ssgroupe: item.code_ssgroupe,
                        })
                      }
                      className={`item ${item.disponible === 0 ? 'disabled' : ''}`}
                      key={key + item.name}
                    >
                      {item.name}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </div>
   
  );
};

CategoriesSidebarView.displayName = 'CategoriesSidebarView';

export default CategoriesSidebarView;
