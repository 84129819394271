import { ISupplierPromoModel } from './SupplierPromo.model';

export const supplierPromoItems: ISupplierPromoModel[] = [
  {
    id: 1,
    title: 'Promo fournisseur 1',
    discount: '-30%',
    availability: 'du 12/04/22 au 18/04/22',
  },
  {
    id: 1,
    title: 'Promo fournisseur 2',
    discount: '230,00 €',
    availability: 'Nouveau prix du 12/04/22 au 18/04/22',
  },
  {
    id: 3,
    title: 'Promo fournisseur 3',
    discount: '-25%',
    availability: 'du 18/04/22 au 21/04/22',
  },
  {
    id: 4,
    title: 'Promo fournisseur 4',
    discount: '125,00 €',
    availability: 'Nouveau prix du 14/04/22 au 16/04/22',
  },
  {
    id: 5,
    title: 'Promo fournisseur 5',
    discount: '-90%',
    availability: 'du 12/04/22 au 18/04/55',
  },
];