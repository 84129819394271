import React from 'react';
import useLogOut from 'utils/hooks/useLogOut';
import { faPowerOff } from '@fortawesome/free-solid-svg-icons';
import './LogoutButton.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const LogoutButton: React.FC = () => {
  const logout = useLogOut();

  return (
    <div className="logout-button-item" onClick={logout}>
      <FontAwesomeIcon icon={faPowerOff} />
      {/* {t('_logout')} */}
    </div>
  );
};

export default LogoutButton;
