
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import { setCarSelected, setCarInfo } from 'store/reducers/carSelectedSlices';
import { RootState } from 'store/store';
import { useAppDispatch } from './redux';
import useHttp from './useHttp';
import useReliableCatalogCarQueryParams from './useReliableCatalogCarQueryParams';


const useSetStateVehicle = (): void => {

  let { vehicle_id } = useParams();
  const [searchParams] = useSearchParams();

  if (vehicle_id == undefined) {
    if (searchParams.get('ktypnr')) {
      vehicle_id = String(searchParams.get('ktypnr'));
    }
  }

  const dispatch = useAppDispatch();
  const { car } = useSelector((state: RootState) => state.carSelected);
  const http = useHttp();
  const reliableCatalogCarQueryParams = useReliableCatalogCarQueryParams();

  const featRequestVehicle = () => {
    http.cart
      .fetchVehicleData(String(vehicle_id), reliableCatalogCarQueryParams)
      .then((res) => {
        localStorage.setItem('carSelected', JSON.stringify(res.vehicle.car != null ? { car: res.vehicle.car, car_information: res.car_information } : { car: res.vehicle.truck, car_information: res.car_information }));
        dispatch(setCarSelected(res.vehicle.car != null ? res.vehicle.car : res.vehicle.truck));
        dispatch(setCarInfo(res.car_information));

      });
  };


  useEffect(() => {

    if (vehicle_id && car == null && !localStorage.getItem('carSelected')) { // not exist vehicle
      featRequestVehicle();
    } else if (vehicle_id && car == null && localStorage.getItem('carSelected')) { //there is a vehicle in local storage
      const carSelected = JSON.parse(localStorage.getItem('carSelected') as string);

      if (vehicle_id != carSelected.car_information.id) { //there is a vehicle in local storage but the id is different

        featRequestVehicle();
      } else {

        dispatch(setCarSelected(carSelected.car));
        dispatch(setCarInfo(carSelected.carInfo));
      }
    } else if (car == null && localStorage.getItem('carSelected')) {
      const carSelected = JSON.parse(localStorage.getItem('carSelected') as string);

      dispatch(setCarSelected(carSelected.car));
      dispatch(setCarInfo(carSelected.carInfo));
    } else if (vehicle_id && car && localStorage.getItem('carSelected')) {

      if (vehicle_id != car.id) {
        featRequestVehicle();
      }
    }
  }, [vehicle_id]);
};

export default useSetStateVehicle;