import { createAsyncThunk } from '@reduxjs/toolkit';
import useHttp from 'utils/hooks/useHttp';

export const fetchCatalogInfo = createAsyncThunk('catalog/fetchCatalogInfo', async (id: string, { rejectWithValue }) => {
  try {
    const http = useHttp();

    const { data } = await http.catalogs.getCatalogInfo(id);


    return data;
  } catch (e) {
    return rejectWithValue('fetch catalog info error');
  }
});

export const fetchCatalogData = createAsyncThunk('catalog/fetchCatalogData', async (_, { rejectWithValue }) => {
  try {
    const http = useHttp();
    const data = await http.catalogs.getCatalogsData();

    return data;
  } catch (e) {
    return rejectWithValue('fetch catalog data error');
  }
});
