import React, { useRef, useState } from 'react';
import DefaultIcon from 'components/buttons/DefaultIcon/DefaultIcon';

import { faUpload, faComment, faFileContract, faHeart, faGear, faXmark } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setShowEbookSidebar } from 'store/slices/ebooks.slice';
import { RootState } from 'store/store';
import { setProformaActive } from 'store/slices/proforma.slice';
import './NavbarGroupBtns.scss';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import { useOnClickOutside } from 'utils/hooks/useOnClickOutside';
import useTimer from 'utils/hooks/useTimer';
import useCountNewMessages from 'utils/hooks/useCountNewMessages';

type Props = {};

const SupplierFolatableBtns: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isMobile } = useDeviceDetect();
  const [buttonsAreaExpanded, setButtonsAreaExpanded] = useState<boolean>(false);

  useTimer();
  useCountNewMessages();
  const clickRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const { newMessagesNumber } = useSelector((state: RootState) => state.messenger);
  const totalNotificationsNumber = newMessagesNumber + 0;

  useOnClickOutside(clickRef, () => {
    setButtonsAreaExpanded(false);
  });


  const changeAreaExpanded = () => {
    setButtonsAreaExpanded((prevState) => !prevState);
  };

  const goToCatalogMessages = () => {
    navigate('messages');
  };

  const goToCatalogFavorite = () => {
    navigate('favorite');
  };

  const { t } = useTranslation();

  const handleEbooksClick = () => {
    dispatch(setShowEbookSidebar(true));
  };

  const handleProformaUpload = () => {
    dispatch(setProformaActive(true));
  };

  return (
    isMobile ? (
      <div className="floatable-buttons-wrapper" ref={clickRef}>
        <DefaultIcon onClick={changeAreaExpanded} number={buttonsAreaExpanded ? 0 : totalNotificationsNumber} icon={buttonsAreaExpanded ? faXmark : faGear} />
        <div className={`buttons ${buttonsAreaExpanded ? 'show' : 'hidden'}`}>
          <DefaultIcon onClick={handleProformaUpload} icon={faUpload} />
          <DefaultIcon onClick={goToCatalogMessages} number={newMessagesNumber} icon={faComment} />
          <DefaultIcon onClick={goToCatalogFavorite} icon={faHeart} />
          <DefaultIcon onClick={handleEbooksClick} icon={faFileContract} />
        </div>
      </div>
    ) : (
      <React.Fragment>
        <DefaultIcon onClick={handleProformaUpload} icon={faUpload} text={t('_import_csv')} />
        <DefaultIcon onClick={goToCatalogMessages} number={newMessagesNumber} icon={faComment} text={t('_messages')} />
        <DefaultIcon onClick={goToCatalogFavorite} icon={faHeart} text={t('_favorite')} />
        <DefaultIcon onClick={handleEbooksClick} icon={faFileContract} text={t('_catalog_ebooks')} />
      </React.Fragment>
    )
  );
};

SupplierFolatableBtns.displayName = 'SupplierFolatableBtns';

export default SupplierFolatableBtns;
