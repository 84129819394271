import React, { PropsWithChildren} from 'react';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import { useTranslation } from 'react-i18next';
import './OrderConfirmationModal.scss';

type Props = {
  setOpenModal: (e: boolean) => void;
  onConfirmOrder?
  loading?: boolean
  orderAction: string
};

const OrderConfirmationModal: React.FC<Props> = (props: PropsWithChildren<Props>) => {
  const {t} = useTranslation();

  return (
    <div className="order-confirmation-modal">
      <div className="modal-body">
        <div className="content">
          <div className="message">
            <p>{props.orderAction === 'rejected' ? t('_client_order_rejection') : t('_client_order_confirmation')}</p>
          </div>
          <div className="actions">
            <div className="no">
              <DefaultButton text={t('_no')} onClick={() => props.setOpenModal(false)} red />
            </div>
            <div className="yes">
              <DefaultButton text={t('_yes')} isloading={props.loading} onClick={() => props.onConfirmOrder()} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderConfirmationModal;
