import Footer from 'components/footer/Footer';
import Pagination from 'components/pagination/Pagination';
import { IData, IFilterForm } from 'interfaces/selectClient';
import React, { PropsWithChildren } from 'react';
import InfoClient from '../InfoClient';
import Spinner from 'components/spinner/Spinner';
import { Control, Controller, FieldValues, UseFormHandleSubmit, UseFormRegister } from 'react-hook-form';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import './ClientList.scss';

interface IOption {
  label: string;
  value: string;
}

type Props = {
  handlePageClick: (page: number) => void;
  infoClients: IData[] | undefined;
  curentPage: number;
  totalPage: number;
  loading: boolean;
  optionsZoneCommercials: IOption[];
  optionsCountries: IOption[];
  onSubmit: () => void;
  handleSubmit: UseFormHandleSubmit<IFilterForm>;
  register: UseFormRegister<IFilterForm>;
  resetFilter: () => void;
  control: Control<FieldValues>;
  valueCountries: IOption | null;
  setValueCountries: (e: IOption | null) => void;
  valueZoneCommercials: IOption | null;
  setValueZoneCommercials: (e: IOption | null) => void;
  handleSelectClient: (e: number) => void;
  loadingSaveClient: boolean;
  clientIdForSpinner: number;
  logout: () => void;

  hideFooter?: boolean
};

const ClientList: React.FC<Props> = (props: PropsWithChildren<Props>) => {
  const { t } = useTranslation();
  const { isMobile, isTablet } = useDeviceDetect();


  return (
    <React.Fragment>
      <div className="container-top">
        <div className="container-top-title">{t('_select_client')}</div>
        <form onSubmit={props.handleSubmit(props.onSubmit)}>
          <div className="container-top-filter">
            <div className="filter-input">
              <div className="filter-item">
                <input
                  className="select-client-name"
                  placeholder="Par nom de client"
                  {...props.register('name')}
                />
              </div>
              <div className="filter-item">
                <Controller
                  control={props.control}
                  name="country"
                  render={({ field: { onChange } }) => (
                    <Select
                      value={props.valueCountries}
                      className="select-dropdown "
                      onChange={(val) => {
                        onChange(val?.value);
                        props.setValueCountries(val);
                      }}
                      options={props.optionsCountries}
                      isSearchable={!isMobile && !isTablet}
                      placeholder={t('_select_country')}
                    />
                  )}
                />
              </div>
              <div className="filter-item">
                <Controller
                  control={props.control}
                  name="zone_commercial"
                  render={({ field: { onChange } }) => (
                    <Select
                      value={props.valueZoneCommercials}
                      className="select-dropdown "
                      onChange={(val) => {
                        onChange(val?.value);
                        props.setValueZoneCommercials(val);
                      }}
                      options={props.optionsZoneCommercials}
                      isSearchable={!isMobile && !isTablet}
                      placeholder={t('_select_zone_commercial')}
                    />
                  )}
                />
              </div>
            </div>

            <div className="filter-button">
              <div className="filter-item">
                <button className="filter-btn-search" type="button" onClick={props.resetFilter}>
                  {t('_reset')}
                </button>
                <button className="filter-btn-reset" type="submit">
                  {t('_filter')}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>

      <div className="container-middle">
        <div className="clients-list">
          {props.loading ? (
            <Spinner class="spinner-client" />
          ) : (
            props.infoClients?.length ? (
              props.infoClients?.map((item) => {
                return (
                  <InfoClient
                    key={item.id}
                    info={item}
                    handleSelectClient={props.handleSelectClient}
                    loadingSaveClient={props.loadingSaveClient}
                    clientIdForSpinner={props.clientIdForSpinner}
                  />
                );
              })
            ) : (
              <div className="no-data">{t('_no_data').toLocaleUpperCase()}</div>
            )
          )}
        </div>
        <div className="container-pagination">
          {props.totalPage > 1 ? (
            <Pagination pageCount={props.totalPage} handlePageClick={props.handlePageClick} forcePage={props.curentPage} />
          ) : null}
        </div>
      </div>
      {!props.hideFooter ?
        < div className="container-bottom">
          <Footer />
        </div>
        : null
      }
    </React.Fragment >
  );
};

export default ClientList;
