import {useEffect} from 'react';
import {useAppDispatch} from './redux';
import { updateCount } from 'store/slices/timer.slice';

const MINUTE_MS = 15000;

const useTimer = (): void => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const timer = setInterval(() => {
      dispatch(
        updateCount()
      );
    }, MINUTE_MS);

    return () => {
      clearInterval(timer);
    };
  }, []);
};

export default useTimer;