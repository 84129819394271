import React, {useContext} from 'react';
import {
  IUploadProformaContext,
  UploadProformaAmerigoContext
} from '../../../../../../context/UploadProformaAmerigoContext';
import LoadFileModalContent from './LoadFileModalContent/LoadFileModalContent';
import SendFileModalContent from './SendFileModalContent/SendFileModalContent';

const UploadProformaModalContent: React.FC = () => {
  const {modalContentType} = useContext(UploadProformaAmerigoContext) as IUploadProformaContext;

  return (
    <div>
      {
        modalContentType === 'small' ?
          <LoadFileModalContent/> : <SendFileModalContent/>
      }
    </div>
  );
};

UploadProformaModalContent.displayName = 'UploadProformaModalContent';

export default UploadProformaModalContent;