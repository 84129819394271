import React, { ChangeEvent, useState } from 'react';
import { faOilCan, faTag, faTools, faBatteryCar, faWrench, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TextInput from '../../../inputs/TextInput/TextInput';
import { ICustomValidation, IData } from 'utils/hooks/http/search-by-reference.http';
import { useTranslation } from 'react-i18next';
import { FieldValues, UseFormRegister, UseFormResetField } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import RefDropdownContent from 'components/dropdown-content/RefDropdownContent';
import ConsumableDropdownContent from 'components/ConsumableDropdownContent/ConsumableDropdownContent';
import OilDropdownContent from 'components/OilDropdownContent/OilDropdownContent';
import TyreDropdownContent from 'components/TyreDropdownContent/TyreDropdownContent';
import BatteryDropdownContent from 'components/BatteryDropdownContent/BatteryDropdownContent';
import { IConsumableSearch } from 'interfaces/consumable';
import { IOilSearch } from 'interfaces/oil';
import { IBatterySearch } from 'interfaces/battery';
import { ReactComponent as Battery } from 'assets/Icons/battery.svg';
import { getCurrentMarketplace } from 'utils/marketplace';
import Tabs from '../../vehicle-card/Tabs/Tabs';
import Tab from '../../vehicle-card/Tabs/Tab';
import tyre from 'assets/Icons/tyre-white.svg';
import { Marketplaces } from 'utils/constants/applications';

type Props = {
  refData: IData[];
  refInputChange: (string) => ChangeEvent<HTMLInputElement> | void;
  submitData: () => void;
  isOpen: boolean;
  setIsOpen: (isOpen) => void;
  loading: boolean;
  refValue?: string;
  register: UseFormRegister<FieldValues>
  getValues: () => FieldValues;
  consumableSearch: () => void;
  oilSearch: () => void;
  batterySearch: () => void;
  openConsumableDropdown: boolean;
  setOpenConsumableDropdown: (openConsumableDropdown) => void;
  loadingConsumable: boolean;
  consumableData: IConsumableSearch[];
  openOilDropdown: boolean;
  setOpenOilDropdown: (openOilDropdown) => void;
  loadingOil: boolean;
  oilData: IOilSearch[];
  openBatteryDropdown: boolean;
  setOpenBatteryDropdown: (openBatteryDropdown) => void;
  loadingBattery: boolean;
  batteryData: IBatterySearch[];
  consumableChange: (string) => ChangeEvent<HTMLInputElement> | void;
  oilChange: (string) => ChangeEvent<HTMLInputElement> | void;
  batteryChange: (string) => ChangeEvent<HTMLInputElement> | void;
  customValidations?: ICustomValidation[]
  openPartDropdown: boolean;
  setOpenPartDropdown: (openPartDropdown) => void;
  resetField: UseFormResetField<FieldValues>;
};

const RefCardMobileView: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { user } = useSelector((state: RootState) => state.userData);
  const currentMarketplace = getCurrentMarketplace();
  const [isActive, setIsActive] = useState(false);

  return (
    <div className="search-card">
      <div className="card-title" onClick={() => setIsActive(!isActive)}>
        {t('_you_know_reference')}
        {isActive ? <FontAwesomeIcon icon={faMinus} /> : <FontAwesomeIcon icon={faPlus} />}
      </div>
      {isActive && (
        <div className="card-content">
          <Tabs resetField={props.resetField}>
            <Tab title={<FontAwesomeIcon icon={faWrench} />}>
              <div className="search-inputs-container search-reference">
                <label className="reference-label">
                  <FontAwesomeIcon className="reference-icon" icon={faTag} /> {t('_ref_parts')}
                </label>
                <TextInput
                  setIsOpen={props.setIsOpen}
                  customValidations={props.customValidations}
                  placeholder="Ex: PDC2015"
                  onChange={props.refInputChange}
                  onClick={props.submitData}
                  withIcon
                />
                {props.openPartDropdown && (
                  <RefDropdownContent
                    refValue={props.refValue}
                    loading={props.loading}
                    setIsOpen={props.setOpenPartDropdown}
                    refData={props.refData}
                  />
                )}
              </div>
            </Tab>
            {user?.hasConsumable && (currentMarketplace === Marketplaces.RETAILER || currentMarketplace === Marketplaces.PUNCHOUT) ? (
              <Tab title={<FontAwesomeIcon icon={faTools} />}>
                {user?.hasConsumable && (currentMarketplace === Marketplaces.RETAILER || currentMarketplace === Marketplaces.PUNCHOUT) && (
                  <div className="search-inputs-container search-consumable">
                    <label className="reference-label">
                      <FontAwesomeIcon className="reference-icon" icon={faTools} /> {t('_ref_consumable')}
                    </label>
                    <TextInput
                      setIsOpen={props.setIsOpen}
                      customValidations={props.customValidations}
                      placeholder="Ex: 2002"
                      {...props.register('consumable')}
                      onClick={props.consumableSearch}
                      withIcon
                      onChange={props.consumableChange}
                    />
                    {props.openConsumableDropdown && (
                      <ConsumableDropdownContent
                        loading={props.loadingConsumable}
                        setIsOpen={props.setOpenConsumableDropdown}
                        data={props.consumableData}
                        consumableValue={props.getValues()?.consumable}
                      />
                    )}
                  </div>
                )}
              </Tab>
            ) : null}
            {user?.hasOil && (currentMarketplace === Marketplaces.RETAILER || currentMarketplace === Marketplaces.PUNCHOUT) ? (
              <Tab title={<FontAwesomeIcon icon={faOilCan} />}>
                <div className="search-inputs-container search-oil">
                  <label className="reference-label">
                    <FontAwesomeIcon className="reference-icon" icon={faOilCan} /> {t('_ref_oil')}
                  </label>
                  <TextInput
                    setIsOpen={props.setIsOpen}
                    customValidations={props.customValidations}
                    placeholder="Ex: 1003001F"
                    {...props.register('oil')}
                    onClick={props.oilSearch}
                    withIcon
                    onChange={props.oilChange}
                  />
                  {props.openOilDropdown &&
                    (currentMarketplace === Marketplaces.RETAILER || currentMarketplace === Marketplaces.PUNCHOUT) && (
                      <OilDropdownContent
                        loading={props.loadingOil}
                        setIsOpen={props.setOpenOilDropdown}
                        data={props.oilData}
                        oilValue={props.getValues()?.oil}
                      />
                    )}
                </div>
              </Tab>
            ) : null}
            {user?.hasTyre && (currentMarketplace === Marketplaces.RETAILER || currentMarketplace === Marketplaces.PUNCHOUT) ? (
              <Tab title={<img className="icon-fill-white" src={tyre} width={15} height={15} alt="tyre" />}>
                <div className="search-inputs-container search-tyre">
                  {user?.hasTyre && (currentMarketplace === Marketplaces.RETAILER || currentMarketplace === Marketplaces.PUNCHOUT) && (
                    <TyreDropdownContent isOpen={props.isOpen} setIsOpen={props.setIsOpen} customValidations={props.customValidations} />
                  )}
                </div>
              </Tab>
            ) : null}
            {user?.hasBattery && (currentMarketplace === Marketplaces.RETAILER || currentMarketplace === Marketplaces.PUNCHOUT) ? (
              <Tab title={<FontAwesomeIcon icon={faBatteryCar} />}>
                <div className="search-inputs-container search-battery">
                  <label className="reference-label">
                    <Battery /> {t('_ref_battery')}
                  </label>
                  <TextInput
                    setIsOpen={props.setIsOpen}
                    customValidations={props.customValidations}
                    placeholder="Ex: T170G"
                    {...props.register('battery')}
                    onClick={props.batterySearch}
                    onChange={props.batteryChange}
                    withIcon
                  />
                  {props.openBatteryDropdown && (
                    <BatteryDropdownContent
                      loading={props.loadingBattery}
                      setIsOpen={props.setOpenBatteryDropdown}
                      data={props.batteryData}
                      value={props.getValues()?.battery}
                    />
                  )}
                </div>
              </Tab>
            ) : null}
          </Tabs>
        </div>
      )}
    </div>
  );
};

RefCardMobileView.displayName = 'RefCardMobileView';

export default RefCardMobileView;
