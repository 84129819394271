import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import './AutomotorClientManagerGrupClientButton.scss';

const AutomotorClientManagerGrupClientButton: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  function handleSelectClientClick() {
    navigate('/manager-group-select-client');
  }

  return (
    <div className="client-manager-select-client-button" onClick={handleSelectClientClick}>
      <div className="select-client-title">{t('_select_client')}</div>
    </div>
  );
};

export default AutomotorClientManagerGrupClientButton;
