import React, { useEffect, useState } from 'react';
import { getCountryFromLocale, getCountryFromLocalStorage, getLanguageFromLocale, getLanguageFromLocalStorage } from '../../utils/locale';
import { IDropdownModel } from '../../components/dropdowns/Dropdown.model';
import { countries, languages } from '../../utils/constants/locales';
import { IUserPut } from '../../utils/hooks/http/user.http';
import { notify } from '../../utils/marketplace';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import useHttp from '../../utils/hooks/useHttp';
import ParametresView from './Parametres.view';
import { setUser } from '../../store/reducers/useSlices';
import { useAppDispatch } from 'utils/hooks/redux';
import './Parametres.scss';

type Props = {};

const Parametres: React.FC<Props> = () => {
  const http = useHttp();
  const { i18n, t } = useTranslation();
  const {user} = useSelector((state: RootState) => state.userData);
  const dispatch = useAppDispatch();

  const [currentWorkValue, setCurrentWorkValue] = useState('');
  const [mediumTechnicality, setMediumTechnicality] = useState('');
  const [highTechnicality, setHighTechnicality] = useState('');
  const [activeWorkCost, setActiveWorkCost] = useState(user?.client_settings.work_tab);
  const [selectedLanguage, setSelectedLanguage] = useState<IDropdownModel>(getLanguageFromLocalStorage() ?? languages[0]);
  const [selectedCountry, setSelectedCountry] = useState<IDropdownModel>(getCountryFromLocalStorage() ?? countries[0]);
  const [submitting, setSubmitting] = useState(false);
  const userLocalStorage = JSON.parse(localStorage.getItem('userData')!);

  useEffect(() => {
    const language = getLanguageFromLocale(i18n);

    if (language) {
      setSelectedLanguage(language);
    }

    const country = getCountryFromLocale(i18n);

    if (country) {
      setSelectedCountry(country);
    }
  }, [i18n.language]);

  const onLanguageChange = (val: IDropdownModel) => {
    const userData: IUserPut = {
      language: String(val.id),
    };

    const locale = `${val.id}-${selectedCountry.id}`;

    http.user.putUser(userData).catch(() => {
      notify('Error');
    });

    localStorage.setItem('locale', locale);
    i18n.changeLanguage(locale);
  };

  const onCountryChange = (val: IDropdownModel) => {
    const locale = `${selectedLanguage.id}-${val.id}`;

    localStorage.setItem('locale', locale);
    i18n.changeLanguage(locale);
  };

  function handleWorkCostChange(e, work_cost){
    const regex = /^[0-9]*\.?[0-9]*$/; 
    const value = e.target.value;

    if (regex.test(value) && work_cost === 't1') {
      setCurrentWorkValue(value);

      return;
    }

    if (regex.test(value) && work_cost === 't2') {
      setMediumTechnicality(value);

      return;
    }

    if (regex.test(value) && work_cost === 't3') {
      setHighTechnicality(value);

      return;
    }
  }

  function handleSwitchChange(value: boolean){
    setActiveWorkCost(value);
  }

  function submitSettings(){
    if(user){
      setSubmitting(true);

      const params = {
        work_cost_t1: Number(currentWorkValue),
        work_cost_t2: Number(mediumTechnicality),
        work_cost_t3: Number(highTechnicality),
        work_tab: activeWorkCost
      };
      

      http.clients.patchClientSettings(user?.client_id, params)
        .then(() => {
          const userInfo = JSON.parse(localStorage.getItem('userData')!);
          const user = { ...userInfo, client_settings: {...userInfo.client_settings, work_tab: activeWorkCost, work_cost: {T1: Number(currentWorkValue), T2: Number(mediumTechnicality), T3: Number(highTechnicality)}} };
  
          localStorage.setItem('userData', JSON.stringify(user));
          dispatch(setUser(user));
        })
        .then(() => notify(t('_success'), 'success'))
        .catch(() => notify(t('_error')))
        .finally(() => setSubmitting(false));
    }
  }

  useEffect(() => {
    if(user){
      setActiveWorkCost(user?.client_settings.work_tab);
      setCurrentWorkValue(String(user?.client_settings.work_cost?.T1 || ''));
      setMediumTechnicality(String(user?.client_settings.work_cost?.T2 || ''));
      setHighTechnicality(String(user?.client_settings.work_cost?.T3 || ''));
    }
  },[user]);

  return (
    <ParametresView
      switchValue={activeWorkCost}
      handleSwitchChange={handleSwitchChange}
      loading={submitting}
      submitSettings={submitSettings}
      handleWorkCostChange={handleWorkCostChange}
      currentWorkValue={currentWorkValue}
      mediumWorkValue={mediumTechnicality}
      highWorkValue={highTechnicality}
      onCountryChange={onCountryChange}
      selectedCountry={selectedCountry}
      selectedLanguage={selectedLanguage}
      onLanguageChange={onLanguageChange}
      clientData={userLocalStorage}
    />
  );
};

export default Parametres;
