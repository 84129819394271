import i18n from 'i18n';
import VehicleIframe from 'pages/CatalogVehicle/TechnicalDocumentation/VehicleIframe/VehicleIframe';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import useHttp from 'utils/hooks/useHttp';
import { getCurrentCountryRMI, getCurrentLanguageRMI } from 'utils/rmi/rmi-utils/rmi-utils';

type Props = {}

const ManufacturerInformation:React.FC<Props> = () => {
  const http = useHttp();
  const [vehicleIframe, setVehicleIframe] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const {typeId} = useSelector((state: RootState)=> state.tecrmi);
 
  async function fetchMaintenanceHTML(){
    if(typeId){
      const {data} = await http.maintenanceHttp.fetchMakeInfo({
        typeId: typeId,
        countryCode: getCurrentCountryRMI(i18n.language),
        languageCode: getCurrentLanguageRMI(i18n.language)
      });

      if(data.length){
        setLoading(true);
        await http.maintenanceHttp.fetchManualsHtml({
          typeId,
          countryCode: getCurrentCountryRMI(i18n.language),
          languageCode: getCurrentLanguageRMI(i18n.language),
          printView: true,
          manualId: data[0].ManualId
        }).then((res) => setVehicleIframe(res.data)).finally(() => setLoading(false));
      }
    }
  }

  useEffect(()=>{
    fetchMaintenanceHTML();
  },[typeId]);

  return (
    typeId ? (
      <VehicleIframe vehicleIframe={vehicleIframe} loading={loading} />
    ) :  <div></div>
  );
};

export default ManufacturerInformation;