import { IManufacturers, IModels, IGenerations, IFuelType, IVersion, IParams, ICatalogModels } from 'interfaces/vehicleSelector';
import { addRouteParam, IHttpResponse, serverAxios } from '../../http';

class VehicleSelectorHttp {
  private static carManufacturers = 'reliable/car-manufacturersV2';
  private static carModels = 'reliable/car-modelsV2';
  private static carCatalogModels = ':id/models';
  private static carGeneration = 'reliable/car-generation';
  private static carFuel = 'reliable/fuel-typeV2';
  private static carVersion = 'reliable/car-versionV2';
  
  public async getCarManufacturers(): Promise<IHttpResponse<IManufacturers[]>> {
    return serverAxios.get(VehicleSelectorHttp.carManufacturers);
  }
  public async getCarModels(params: IParams): Promise<IHttpResponse<IModels[]>> {
    // return serverAxios.get(VehicleSelectorHttp.carModels, { params: { tecdoc_id: params.value, polk_id: params.polk_id } });
    return serverAxios.get(VehicleSelectorHttp.carModels, { params: { manufacturer_id: params.value } });
  } 
  public async getCatalogCarModels(params: IParams, id:string): Promise<IHttpResponse<ICatalogModels[]>> {
    return serverAxios.get(addRouteParam(VehicleSelectorHttp.carCatalogModels, {id: params.value}), { params: { catalog_id: id, polk_id: params.polk_id } });
  } 
  public async getCarGeneration(params: IParams): Promise<IHttpResponse<IGenerations[]>> {
    return serverAxios.get(VehicleSelectorHttp.carGeneration, {
      params: { model_id: params.value, source: params.source, country_code: params.country_code },
    });
  }
  public async getCarFuel(params: IParams): Promise<IHttpResponse<IFuelType[]>> {
    // return serverAxios.get(VehicleSelectorHttp.carFuel, { params: { car_generation_id: params.value, source: params.source } });
    return serverAxios.get(VehicleSelectorHttp.carFuel, { params: { model_id: params.value } });
  }
  public async getCarVersion(params: IParams): Promise<IHttpResponse<IVersion[]>> {
    // return serverAxios.get(VehicleSelectorHttp.carVersion, { params: { car_generation_id: params.value, source: params.source } });
    return serverAxios.get(VehicleSelectorHttp.carVersion, { params: { model_id: params.value } });
  }
}

export default VehicleSelectorHttp;
