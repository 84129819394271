
import TextInput from 'components/inputs/TextInput/TextInput';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useHttp from 'utils/hooks/useHttp';
import './Edit.scss';

import Spinner from 'components/spinner/Spinner';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import UploadImage from 'components/UploadImage/UploadImage';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { useParams } from 'react-router-dom';
import { languages } from 'utils/constants/locales';
import { notify } from 'utils/marketplace';
import { ICatalogInfoTranslate } from 'models/catalog.model';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';


type Props = {
  setOpenModal: (e: boolean) => void;
  setSubmit: (e: boolean) => void;
};

const EditCatalog: React.FC<Props> = (props) => {

  const { t } = useTranslation();
  let errorMessage = '';
  const http = useHttp();
  const { id: catalogId } = useParams();
  const [file, setFile] = useState<File[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingInfo, setLoadingInfo] = useState<boolean>(true);
  const [catalog, setCatalog] = useState<ICatalogInfoTranslate>();
  const [tabColor, setTabColor] = useState<string[]>([]);
  const { isMobile, isTablet } = useDeviceDetect();

  const validate = Yup.object().shape({
    image: Yup.string().required(t('_fild_required')),

  });


  const { getValues, register, handleSubmit, clearErrors, setValue, formState: { errors }, } = useForm({ resolver: yupResolver(validate) });

  useEffect(() => {
    http.catalogs.getCatalogInfoTranslate(String(catalogId)).then((res) => {
      setCatalog(res.data);
      const lang: string[] = [];

      res.data?.translations.map((trans) => {
        if (trans.locale && trans.description && trans.description != '') {
          lang.push(trans.locale);
          setValue(`translations[${trans.locale}]`, trans.description);
        }

      });
      setTabColor([...tabColor, ...lang]);
    }).finally(() => {
      setLoadingInfo(false);
    });

  }, []);

  useEffect(() => {

    if (catalog?.images && !file.length) {
      isMobile ? setValue('image', catalog?.images.mobile) : setValue('image', catalog?.images.desktop);
    }

  }, [file.length]);

  useEffect(() => {

    if (catalog?.images) {
      isMobile ? setValue('image', catalog?.images.mobile) : setValue('image', catalog?.images.desktop);
    }

  }, [catalog]);

  const onSubmit = async () => {


    setLoading(true);
    const values = getValues();
    const formData: FormData = new FormData();


    formData.append('url', values.url.trim());


    if (typeof values.image == 'object') {
      formData.append('image', values.image);
    }

    Object.keys(values.translations).forEach(key => {
      if (values.translations[key] === undefined) {
        delete values.translations[key];
      } else {
        formData.append(`translations[${key}]`, String(values.translations[key]));
      }

    });

    http.catalogs.getCatalogEdit(String(catalogId), formData).then((res) => {
      notify(t('_updated_successfully'), 'success', t('_success'));
      props.setOpenModal(false);
      props.setSubmit(true);
    }).catch((error) => {
      let erorrs = '';

      Object.entries(error).forEach(([key, value]) => {
        errorMessage = String(value);
        erorrs += `${t(key)} : ${t(errorMessage)} `;
      });
      notify(erorrs, 'error', t('_error'));
      setLoading(false);
    });
  };

  const changeTitle = (key: string, value: string) => {

    if (value != '') {
      if (!tabColor.includes(String(key))) {
        const lang: string[] = [key];

        setTabColor([...tabColor, ...lang]);
      }

    } else {
      if (tabColor.includes(String(key))) {
        tabColor.splice(tabColor.indexOf(key), 1);
        setTabColor([...tabColor]);
      }
    }

  };


  return (
    <div className="edit-catalog-container" >
      <div className="close-modal" onClick={() => { props.setOpenModal(false); }}><FontAwesomeIcon icon={faClose} /></div>
      <div className="title">
        {t('_catalog_edit')}
      </div>
      {loadingInfo ?
        <div className="spinner-info"> <Spinner /> </div> :
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="modal-info">
            <div className="row">
              <div className={`container  file ${errors.image?.type == 'required' ? 'error' : ''}`}>
                <UploadImage errors={errors} clearErrors={clearErrors} setValue={setValue} setFile={setFile} image={isMobile ? catalog?.images.mobile : catalog?.images.desktop} />
              </div>
            </div>
            <div className="row">
              <div className="container ">
                <label  >
                  {t('_catalog_url')}
                </label>
                <TextInput defaultValue={catalog?.url} {...register('url')} />
              </div>
            </div>
            <div className="row">
              <div className="container ">
                <label  >
                  {t('_catalog_description')}
                </label>
                <div className="tabs-container">
                  <Tabs>
                    <TabList >
                      {
                        languages.map((item, index) => {
                          let classActive = 'empty_text';

                          tabColor.map((trans) => {
                            if (trans == item.id) {
                              classActive = 'active_text';
                            }
                          });

                          return <Tab key={index} className={classActive} >{isMobile || isTablet ? String(item.id).toUpperCase() : item.text}</Tab>;
                        })
                      }
                    </TabList>
                    {languages.map((item, index) => {
                      return (<TabPanel key={index} >
                        <textarea {...register(`translations[${item.id}]`)} onChange={(event) => { changeTitle(String(item.id), event?.target.value); }} rows={4} />
                      </TabPanel>
                      );
                    })
                    }
                  </Tabs>
                </div>
              </div>
            </div>

          </div>
          <div className="btn-save">
            <button type="submit"> {loading ? <Spinner class="extra-small" /> : null} <div className="title-btn-save">{t('_create_article')}</div></button>
          </div>
        </form >
      }
    </div>

  );

};

EditCatalog.displayName = 'EditCatalog';

export default EditCatalog; 