import React, { PropsWithChildren } from 'react';
import './IconItem.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

type Props = {
  icon: IconProp;
  onClick: () => void;
};

const IconItem: React.FC<Props> = (props: PropsWithChildren<Props>) => {
  return (
    <div className="icon-item-container">
      <div className="item" onClick={props.onClick}>
        <div className="item-icon">
          <FontAwesomeIcon icon={props.icon} className="icon" />
        </div>
      </div>
    </div>
  );
};

IconItem.displayName = 'IconTextItem';

export default IconItem;
