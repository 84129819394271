
import React from 'react';
import './CollapseList.scss';

type Props = {
    data?
    onItemMpClick?: (item) => void;
}

const ItemGroup:React.FC<Props> = (props) => {

  return (
    <div className="collapse-list">
      <div className="item last-level">
        <div onClick={() => props.onItemMpClick!(props.data)}>{props.data.ItemMpText}</div> 
      </div>
    </div>
  );
};

export default ItemGroup;