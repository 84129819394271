import React, { PropsWithChildren, useState } from 'react';
import { IData } from 'interfaces/selectClient';
import { useTranslation } from 'react-i18next';
import Modal from 'components/modal/Modal';
import MinimumOrderValueModal from './minimum-order-value-modal/MinimumOrderValueModal';

type Props = {
  info: IData | undefined;
  fetchClients: (params) => void;
};

const MinimumOrderValueItem: React.FC<Props> = (props: PropsWithChildren<Props>) => {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);

  return (
    <div className="client-item">
      <div className="client-heder">
        <div className="client-title">
          {props.info?.name}
        </div>
      </div>
      <div className="client-info">
        <div className="client-body">
          <div className="client-lable">{t('_country')}</div>
          <div className="client-content">{props.info?.country}</div>
        </div>
        <div className="client-body">
          <div className="client-lable">{t('_address')}</div>
          <div className="client-content">{props.info?.address}</div>
        </div>
        <div className="client-body">
          <div className="client-lable">{t('_zone_commercial')}</div>
          <div className="client-content">{props.info?.zoneCommercial}</div>
        </div>
        <div className="client-body">
          <div className="client-lable">{t('_minimum_order_value')}</div>
          <div className="client-content">{props.info?.minim_order_value}</div>
        </div>
      </div>
      <button
        className="client-btn"
        type="button"
        onClick={() => setOpenModal(true)}
      >
        {t('_set_minimum_order_value')}
      </button>
      <Modal
        openModal={openModal}
        setOpenModal={setOpenModal}
        childrenComponent={<MinimumOrderValueModal data={props.info} setOpenModal={setOpenModal} fetchClients={props.fetchClients} />}
      />
    </div>
  );
};

export default MinimumOrderValueItem;
