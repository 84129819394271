import React, { PropsWithChildren, useEffect, useState } from 'react';
import './FavoriteListModal.scss';
import IconItem from '../../../../Item/IconItem/IconItem';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { IAddToFavorite, IFavoriteList } from '../../../../../models/favorite';
import FavoriteListItem from './FavoriteListItem/FavoriteListItem';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import useHttp from 'utils/hooks/useHttp';
import { notify } from 'utils/marketplace';
import { ICarPartData } from 'models/car-parts.model';
import { CATALOG_MANUFACTURER } from 'utils/constants/applications';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PlusImage } from 'assets/images/plus.svg';

type Props = {
  setOpenModal: (e: boolean) => void;
  setHeartFilled: (e: boolean) => void;
  favoriteList: IFavoriteList[] | [];
  checkedList: IFavoriteList[] | [];
  setCheckedList: (list: IFavoriteList[]) => void;
  setFavoriteList: (list: IFavoriteList[]) => void;
  carPart: ICarPartData
  onDone?: () => void;
  onListCreate?: (listItem: IFavoriteList) => void;

};

const FavoriteListModal: React.FC<Props> = (props: PropsWithChildren<Props>) => {

  const [newListActive, setNewListActive] = useState<boolean>(false);
  const [newListValue, setNewListValue] = useState<string>('');
  const [isInvalid, setIsInvalid] = useState<boolean>(false);
  const [loadingCreateList, setLoadingCreateList] = useState<boolean>(false);
  const [loadingAddToList, setLoadingAddToList] = useState<boolean>(false);
  const [buttonIsDisabled, setButtonIsDisabled] = useState<boolean>(true);
  const http = useHttp();
  const [initialCheckedList, setInitialCheckedList] = useState<IFavoriteList[]>([]);
  const { t } = useTranslation();


  useEffect(() => {
    if (!initialCheckedList.length && props.checkedList && props.carPart.is_favorite) {
      setInitialCheckedList(props.checkedList);
    }

    if (props.checkedList.length || initialCheckedList.length) {
      setButtonIsDisabled(isTheSame);
    }

    if (isTheSame) {
      setButtonIsDisabled(true);
    }

    if (props.checkedList.length === initialCheckedList.length && initialCheckedList.length === 0) {
      setButtonIsDisabled(true);
    }

  }, [props.checkedList, initialCheckedList]);
  const intersectionArray = initialCheckedList.filter(item => props.checkedList.some(item2 => item2.id === item.id));
  const isTheSame = intersectionArray.length > 0 && initialCheckedList.length === props.checkedList.length;

  useEffect(() => {
    if (newListValue.length) {
      if (props.favoriteList?.find(favCat => favCat.name === newListValue)) {
        setIsInvalid(true);
      } else if (isInvalid) {
        setIsInvalid(false);
      }
    }

    if (!newListValue.length && !isInvalid) {
      setIsInvalid(true);
    }

  }, [newListValue]);


  const createList = async () => {
    if (isInvalid || loadingCreateList) {
      return;
    }

    setLoadingCreateList(true);

    http.favorite.postFavoriteList(newListValue)
      .then((data) => {
        setNewListActive(false);
        setNewListValue('');
        setIsInvalid(false);
        props.setFavoriteList([data, ...props.favoriteList]);

        if (props.onListCreate) {
          props.onListCreate(data);
        }

      })
      .catch(() => {
        // console.log(e.response.data);
        notify('error');
      })
      .finally(() => {
        setLoadingCreateList(false);
      });
  };

  const addToFavorite = async () => {
    if (loadingAddToList || buttonIsDisabled) {
      return;
    }

    setLoadingAddToList(true);

    const favoriteCategoryIds = props.checkedList.map(list => list.id);
    const favoriteCategoryIdsUnique = favoriteCategoryIds.filter((x, i, a) => a.indexOf(x) == i);

    const params: IAddToFavorite = {
      catalog_id: props.carPart.catalog_id ? props.carPart.catalog_id : 'REL',
      cod_fou: props.carPart.cod_fou,
      is_oe_part: props.carPart?.catalog_type === CATALOG_MANUFACTURER,
      favorite_category_ids: favoriteCategoryIdsUnique,
      manufacturer_id: null,
      oe_reference: null,
      price: null,
      quantity: 1,
      supplier_id: props.carPart.data_supplier.id,
      supplier_reference: props.carPart.data_supplier_reference,
      source: props.carPart.source
    };


    if (!initialCheckedList.length && favoriteCategoryIdsUnique.length) {
      http.favorite.addToFavoriteList(params)
        .then(() => {
          props.setOpenModal(false);
          setInitialCheckedList(props.checkedList);
          props.setHeartFilled(favoriteCategoryIdsUnique.length > 0);

          if (props.onDone) {
            props.onDone();
          }
        })
        .catch(() => {
          // console.log(e.response.data);
          notify('error');
        })
        .finally(() => {
          setLoadingAddToList(false);

        });

      return;
    }

    if (favoriteCategoryIdsUnique.length) {
      http.favorite.updateFromFavoriteList(params)
        .then(() => {
          props.setOpenModal(false);
          setInitialCheckedList(props.checkedList);
          props.setHeartFilled(favoriteCategoryIdsUnique.length > 0);

          if (props.onDone) {
            props.onDone();
          }
        })
        .catch(() => {
          // console.log(e.response.data);
          notify('error');
        })
        .finally(() => {
          setLoadingAddToList(false);

        });

      return;
    }

    if (!favoriteCategoryIdsUnique.length && initialCheckedList.length) {
      delete params.favorite_category_ids;
      http.favorite.deleteFromFavoriteList(params)
        .then(() => {
          props.setOpenModal(false);
          setInitialCheckedList(props.checkedList);
          props.setHeartFilled(favoriteCategoryIdsUnique.length > 0);

          if (props.onDone) {
            props.onDone();
          }
        })
        .catch(() => {
          // console.log(e.response.data);
          notify('error');
        })
        .finally(() => {
          setLoadingAddToList(false);

        });

      return;
    }


  };


  const isChecked = (item) => {
    return props.checkedList.some(list => list.id === item.id);
  };

  return (
    <div className="modal-favorite-list">
      <div className="close" onClick={() => props.setOpenModal(false)}>
        <IconItem icon={faClose} onClick={() => null} />
      </div>
      <div className="modal-dialog">

        <div className="modal-container">
          <div className="modal-header">{t('_save_to')}</div>

          <div className="modal-body">
            <div className="content">
              <div className="list">
                {
                  props.favoriteList.map((item: IFavoriteList) => {
                    return (
                      <FavoriteListItem setCheckedList={props.setCheckedList} key={item.id} listItem={item} checkedList={props.checkedList} isChecked={isChecked(item)} />
                    );
                  })
                }
              </div>

              <div className="new-list-container">
                {
                  newListActive ? (
                    <div className="new-list-input">
                      <input type="text" className={isInvalid ? 'error' : ''} value={newListValue}
                        onChange={(e) => setNewListValue(e.target.value)}
                      />
                    </div>

                  ) : (
                    <div className="new-list-button" onClick={() => setNewListActive(!newListActive)}>
                      <PlusImage />
                      {t('_new_list')}
                    </div>
                  )
                }
              </div>
              <div className="actions">
                {
                  newListActive ? (
                    <div className="actions-content">
                      <div className="cancel">
                        <DefaultButton text={t('_cancel')} onClick={() => setNewListActive(!newListActive)} />
                      </div>
                      <div className="save">
                        <DefaultButton text={t('_save')} isloading={loadingCreateList} onClick={createList} />
                      </div>
                    </div>
                  ) : (
                    <div className="actions-content">
                      <div className={`${buttonIsDisabled ? 'disabled save-favorites' : 'save-favorites'}`}>
                        <DefaultButton text={t('_save')} isloading={loadingAddToList} onClick={addToFavorite} />
                      </div>
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};

FavoriteListModal.displayName = 'FavoriteListModal';

export default FavoriteListModal;
