import {useState} from 'react';
import {ICustomValidation} from './http/search-by-reference.http';

export function useValidationMessages(): { checkCustomValidations: (value: string, customValidations?: ICustomValidation[]) => string[]; customValidationMessages: string[] | [] } {
  const [customValidationMessages, setCustomValidationMessages] = useState<string[] | []>([]);

  function checkCustomValidations(value: string, customValidations?: ICustomValidation[]): string[] {
    if(!customValidations) {return [];}

    if(!value) {return [];}

    const customValidationSet = new Set(customValidations.map(validation => validation.message));

    customValidations.forEach((validation) => {
      if(!value.match(validation.regex)) {
        customValidationSet.add(validation.message);
      } else {
        customValidationSet.delete(validation.message);
      }
    });

    setCustomValidationMessages(Array.from(customValidationSet));

    return Array.from(customValidationSet);
  }

  return { checkCustomValidations: checkCustomValidations, customValidationMessages: customValidationMessages };
}
