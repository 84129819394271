import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as UploadCsv } from 'assets/images/upload.svg';
import { setProformaAutomotorActive } from 'store/slices/proformaAutomotor.slice';
import { useDispatch } from 'react-redux';


const c = 'automotor-menu-navbar';

const MenuUploadOrder: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleProformaUpload = () => {
    dispatch(setProformaAutomotorActive(true));
  };

  return (
    <div className={`${c}__item--parent__children__item--title`} onClick={handleProformaUpload}>
      <UploadCsv />
      <span>{t('_upload_your_proforma_file')}</span>
    </div>
  );
};

export default MenuUploadOrder;
