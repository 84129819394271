import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBook,
  faCaretDown,
  faCaretUp,
  faComment,
  faEuro,
  faEuroSign,
  faGear,
  faHeart,
  faInfo,
  faList,
  faListDots,
  faListSquares,
  faMagnifyingGlass,
  faPowerOff,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useRef, useState } from 'react';
import './AccountDropdown.scss';
import { useOnClickOutside } from '../../../utils/hooks/useOnClickOutside';
import RoundedIcon from '../../buttons/RoundedIcon/RoundedIcon';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import useLogOut from 'utils/hooks/useLogOut';
import { ROLE_SELLER, ROLE_COMMERCIAL_DIRECTOR, ROLE_SUPPLIER_AMERIGO } from 'utils/constants/roles';
import { usePermission } from 'utils/usePermission';
import useMessageText from '../../../utils/hooks/useMessageText';
import { getCurrentMarketplace } from 'utils/marketplace';
import { Marketplaces } from 'utils/constants/applications';
import { setLoadingPriceCheckedStatus } from 'store/slices/buttonPriceCheckedStatus.slice';
import { useAppDispatch } from 'utils/hooks/redux';
import { isRetailerMecadepotChild } from 'utils/isRetailerMecadepotChild';

const AccountDropdown: React.FC = () => {
  const [show, setShow] = useState<boolean>(false);
  const dropdownRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const { t } = useTranslation();
  const logout = useLogOut();
  const Supplier = usePermission([ROLE_SUPPLIER_AMERIGO]);
  const Seller = usePermission([ROLE_SELLER]);
  const Commercial = usePermission([ROLE_COMMERCIAL_DIRECTOR]);
  const isAutomotor = getCurrentMarketplace() === Marketplaces.AUTOMOTOR;
  const isAmerigo = getCurrentMarketplace() === Marketplaces.AMERIGO;
  const isRetailer = getCurrentMarketplace() === Marketplaces.RETAILER;
  const messageText = useMessageText();
  const dispatch = useAppDispatch();
  const user = JSON.parse(localStorage.getItem('userData')!);
  const isMecadepotChild = isRetailerMecadepotChild();

  const [priceCheckedStatus, setPriceCheckedStatus] = useState<boolean>(
    localStorage.getItem('priceCheckedStatus') != null ? (localStorage.getItem('priceCheckedStatus') == 'true' ? true : false) : false,
  );

  useOnClickOutside(dropdownRef, () => setShow(false));

  const openPDFInNewTab = () => {
    let pdfUrl = '';

    if (isAutomotor) {
      pdfUrl = '/guides/distributor_guide.pdf';
    }

    if (isAmerigo) {
      pdfUrl = '/guides/fournisseur_guide.pdf';
    }

    if (isRetailer) {
      pdfUrl = '/guides/retailer_guide.pdf';
    }

    window.open(pdfUrl, '_blank');
  };

  useEffect(() => {
    if (localStorage.getItem('priceCheckedStatus') != null) {
      dispatch(setLoadingPriceCheckedStatus(localStorage.getItem('priceCheckedStatus') == 'true' ? true : false));
    }
  }, []);

  return (
    <div ref={dropdownRef} className={`round-account-dropdown  ${isMecadepotChild ? 'mecadepot-width' : ''}`} onClick={() => setShow(!show)}>
      {user?.numcli !== Number(process.env.REACT_APP_MECADEPOT_NUMCLI) ? <div className="myAccount">{t('_my_account')}</div> : null}
      <FontAwesomeIcon icon={show ? faCaretUp : faCaretDown} className="caret-icon" />
      <FontAwesomeIcon icon={faUser} className="user-icon" />
      <div className={show ? 'show' : 'hide'}>
        <div className="content-data">
          <NavLink to="/my-profile" className="item">
            <RoundedIcon icon={faInfo} text={t('_my_info')} />
          </NavLink>
          <NavLink to="/messages" className="item">
            <RoundedIcon icon={faComment} text={messageText} />
          </NavLink>
          <NavLink to="/settings" className="item">
            <RoundedIcon icon={faGear} text={t('_settings')} />
          </NavLink>
          <NavLink to="/history" className="item">
            <RoundedIcon icon={faMagnifyingGlass} text={t('_history')} />
          </NavLink>
          <NavLink to="/favorite" className="item">
            <RoundedIcon icon={faHeart} text={t('_favorite')} />
          </NavLink>

          {(isAutomotor || isAmerigo || isRetailer) && (
            <div onClick={openPDFInNewTab} className="item">
              <RoundedIcon icon={faBook} text={t('_learn_about_e-reliable')} />
            </div>
          )}
          {(Seller || Commercial) && (
            <React.Fragment>
              <NavLink to="/area/select?page=1" className="item">
                <RoundedIcon icon={faUser} text={t('_select_client')} />
              </NavLink>
              <NavLink to="/area/waiting-offers" className="item">
                <RoundedIcon icon={faListSquares} text={t('_waiting_offers')} />
              </NavLink>
              {Commercial && (
                <NavLink to="/area/waiting-confirmation" className="item">
                  <RoundedIcon icon={faEuroSign} text={t('_waiting_confirmation')} />
                </NavLink>
              )}
            </React.Fragment>
          )}
          {(Supplier) && (

            <NavLink to="/select-client?page=1" className="item">
              <RoundedIcon icon={faUser} text={t('_select_client')} />
            </NavLink>


          )}

          {getCurrentMarketplace() !== Marketplaces.PUNCHOUT && (
            <a className="item" onClick={logout}>
              <RoundedIcon icon={faPowerOff} text={t('_logout')} />
            </a>
          )}
          <div className="item">
            <RoundedIcon icon={faEuro} text={t('_hide_price')} />
            <label className="switch">
              <input
                type="checkbox"
                checked={priceCheckedStatus}
                onChange={(e) => {
                  localStorage.setItem('priceCheckedStatus', String(e.target.checked)), setPriceCheckedStatus(e.target.checked);
                  dispatch(setLoadingPriceCheckedStatus(e.target.checked));
                }}
              />
              <span className="switch_slider round"></span>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

AccountDropdown.displayName = 'AccountDropdown';

export default AccountDropdown;
