import React from 'react';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import VehicleIframe from 'pages/CatalogVehicle/TechnicalDocumentation/VehicleIframe/VehicleIframe';
import './IframeModalMobileCardLevel4.scss';

type Props = {
  setOpenModal?: (boolean) => void
  title: string
  vehicleIframe: string
  loading: boolean
}

const IframeModalMobileCardLevel4: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();

  return (
    <div className="print-iframe-modal">
      <div className="print-iframe-modal-header">
        <div className="title">{props.title}</div>
        <div className="header-actions">
          <FontAwesomeIcon className="close-modal" icon={faClose} onClick={() => props.setOpenModal!(false)} />
        </div>
      </div>
      <div className="print-iframe">
        <VehicleIframe vehicleIframe={props.vehicleIframe} loading={props.loading} removeFixedHeight />
      </div>
    </div>
  );
};

export default IframeModalMobileCardLevel4;