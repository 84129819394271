import React, { useEffect, useState } from 'react';
import i18n from 'i18n';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import useHttp from 'utils/hooks/useHttp';
import { getCurrentCountryRMI, getCurrentLanguageRMI } from 'utils/rmi/rmi-utils/rmi-utils';
import './RepairItems.scss';
import Spinner from 'components/spinner/Spinner';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';

import {
  IRepairItemsWorkItemMps,
  IRepairItemsWorkList,
  IRepairItemsWorkSubGroups,
  IGroupedItemsIndex,
  IGroupedItemMp,
  IVehicleRepairItemWork,
  IVehicleRepairItemWorkInfo,
} from 'utils/rmi/interface/repair-items.interface';
import SidebarDetails from 'pages/CatalogVehicle/TechnicalDocumentation/SidebarDetails/SidebarDetails';
import Modal from 'components/modal/Modal';
import { faCircleArrowLeft, faInfo, faCartShopping } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';

const RepairItems: React.FC = () => {
  const { t } = useTranslation();
  const { isMobile } = useDeviceDetect();
  const { typeId } = useSelector((state: RootState) => state.tecrmi);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const [sidebarDetails, setSidebarDetails] = useState(Number(searchParams.get('level')) || 1);
  const [subcategoryLevel, setSubcategoryLevel] = useState(1);

  const [loadingList, setLoadingList] = useState(true);
  const [loadingWorkSteps, setLoadingWorkSteps] = useState(false);

  const [mainGroup, setMainGroup] = useState<IRepairItemsWorkList[]>();
  const [workInfo, setWorkInfo] = useState<IVehicleRepairItemWork[]>();
  const [subGroup, setSubGroup] = useState<IRepairItemsWorkList>();
  const [itemMps, setItemMps] = useState<IRepairItemsWorkSubGroups>();
  const [korItem, setKorItem] = useState<IGroupedItemMp>();

  const [openWorkInfoModal, setOpenWorkInfoModal] = useState<boolean>();
  const [exclusiveWorkPosition, setExclusiveWorkPosition] = useState<IVehicleRepairItemWorkInfo[]>();

  const http = useHttp();

  function fetchWorkListList() {
    if (typeId) {
      setLoadingList(true);
      http.repairItemsHttp
        .fetchWorkListList({
          typeId: typeId,
          countryCode: getCurrentCountryRMI(i18n.language),
          languageCode: getCurrentLanguageRMI(i18n.language),
        })
        .then((res) => {
          setMainGroup(res.data);
        })
        .finally(() => {
          setLoadingList(false);
        });
    }
  }

  function onKorClick(kor) {
    if (typeId) {
      setSubcategoryLevel(3);
      setLoadingWorkSteps(true);
      http.repairItemsHttp
        .fetchWorkStepsList({
          typeId: typeId,
          countryCode: getCurrentCountryRMI(i18n.language),
          languageCode: getCurrentLanguageRMI(i18n.language),
          itemMpId: korItem?.ItemMpId,
          korId: kor.KorId,
        })
        .then((res) => {
          setWorkInfo(res.data);
        })
        .finally(() => {
          setLoadingWorkSteps(false);
        });
    }
  }

  useEffect(() => {
    fetchWorkListList();
  }, [typeId, i18n.language]);

  useEffect(() => {
    if (searchParams.get('level')) {
      setSidebarDetails(+searchParams.get('level')! || 1);
      setOpenWorkInfoModal(false);
    }

    if (!mainGroup) {
      return;
    }

    let groupActive;

    if (searchParams.get('group_id')) {
      groupActive = mainGroup.find((main) => main.MainGroupId.toString() === searchParams.get('group_id'));
      setSubGroup(groupActive);
    }

    if (searchParams.get('group_id') && searchParams.get('subgroup_id')) {
      if (groupActive) {
        setItemMps(groupActive.SubGroups.find((subgroup) => subgroup.SubGroupId.toString() === searchParams.get('subgroup_id')));
      }
    }
  }, [searchParams.get('item_id'), searchParams.get('level'), typeId, i18n.language, mainGroup]);

  function onMainGroupClick(item) {
    setSubGroup(item);
    setSidebarDetails(2);
    searchParams.set('group_id', item.MainGroupId);
    searchParams.set('level', '2');
    setSearchParams(searchParams);
  }

  function onSubGroupClick(item) {
    setItemMps(item);
    setSidebarDetails(3);
    setSubcategoryLevel(1);
    searchParams.set('subgroup_id', item.SubGroupId);
    searchParams.set('level', '3');
    setSearchParams(searchParams);
  }

  function onItemMpClick(item) {
    setKorItem(item);
    setSidebarDetails(3);
    setSubcategoryLevel(2);
    searchParams.set('level', '3');
    setSearchParams(searchParams);
  }

  function onReturnClick(level?) {
    if (level) {
      setSidebarDetails(level);
      searchParams.set('level', level.toString());

      if (level === 1) {
        setSubGroup(undefined);
        searchParams.delete('group_id');
        searchParams.delete('subgroup_id');
      }
    } else {
      setSidebarDetails((prev) => {
        searchParams.set('level', (prev - 1).toString());

        return prev - 1;
      });
      navigate(-1);
    }

    setSearchParams(searchParams);
  }

  function onReturnSubcategoryClick() {
    if (subcategoryLevel === 1) {
      setSidebarDetails(2);
      setItemMps(undefined);
    }

    setSubcategoryLevel((prev) => prev - 1);
  }

  const groupItemsByName = (item: IRepairItemsWorkSubGroups | undefined): IGroupedItemMp[] => {
    if (!item) {
      return [];
    }

    const groupedItems = item.ItemMps.reduce((groupedItems: IGroupedItemsIndex, item: IRepairItemsWorkItemMps) => {
      if (item.ItemMpId && !groupedItems[item.ItemMpId]) {
        groupedItems[item.ItemMpId] = [];
      }

      groupedItems[item.ItemMpId].push(item);

      return groupedItems;
    }, {});

    return Object.keys(groupedItems).map((ItemMpId) => {
      return { ItemMpId: Number(ItemMpId), ItemMpText: groupedItems[ItemMpId][0].ItemMpText, kors: groupedItems[ItemMpId] };
    });
  };

  const handleWorkInfoClick = (exclusiveWorkPositions) => {
    setOpenWorkInfoModal(true);
    setExclusiveWorkPosition(exclusiveWorkPositions);
  };

  return (
    <div className="technical-documentation-repair-items">
      {loadingList ? (
        <Spinner class="extra-small" />
      ) : (
        <div className="vehicle-repair-container">
          <div className="repair-group">
            {sidebarDetails === 1 || (!isMobile && sidebarDetails > 1) ? (
              <SidebarDetails
                title={`<span>${t('_main_group')}</span>`}
                itemName="MainGroupName"
                itemId="MainGroupId"
                onClick={onMainGroupClick}
                data={mainGroup}
                onReturnClick={() => onReturnClick()}
                activeItem={subGroup?.MainGroupId}
              />
            ) : (
              ''
            )}
          </div>
          <div className="repair-group">
            {sidebarDetails === 2 || (!isMobile && sidebarDetails > 2) ? (
              <SidebarDetails
                title={`<span>${t('_sub_group')}</span>`}
                itemName="SubGroupName"
                itemId="SubGroupId"
                onClick={onSubGroupClick}
                data={subGroup?.SubGroups}
                onReturnClick={() => onReturnClick(1)}
                activeItem={itemMps?.SubGroupId}
              />
            ) : (
              ''
            )}
          </div>
          <div className="repair-group">
            {sidebarDetails === 3 && (
              <div>
                {subcategoryLevel === 1 && (
                  <SidebarDetails
                    title={`<span>${t('_repair_items')}</span>`}
                    itemId="ItemMpId"
                    itemName="ItemMpText"
                    onClick={onItemMpClick}
                    data={groupItemsByName(itemMps)}
                    onReturnClick={onReturnSubcategoryClick}
                  />
                )}
                {subcategoryLevel === 2 && (
                  <SidebarDetails
                    title={`${t('_repair_items')} / <span>${korItem?.ItemMpText}</span>`}
                    itemId="KorId"
                    itemName="KorText"
                    onClick={onKorClick}
                    data={korItem?.kors}
                    onReturnClick={onReturnSubcategoryClick}
                  />
                )}
                {subcategoryLevel === 3 && (
                  <div className="work-info-sidebar-details">
                    <div className="header">
                      <span className="return-button" onClick={onReturnSubcategoryClick}>
                        <FontAwesomeIcon icon={faCircleArrowLeft} className="icon" />
                      </span>
                      <div
                        className="title"
                        dangerouslySetInnerHTML={{
                          __html: `${t('_repair_items')} / ${
                            workInfo?.length ? workInfo[0].ItemMpText + ' / <span>' + workInfo[0].KorText + '</span>' : ''
                          }`,
                        }}
                      ></div>
                    </div>
                    <div className="content">
                      {loadingWorkSteps ? (
                        <Spinner class="extra-small" />
                      ) : (
                        <div className="items">
                          {workInfo?.map((item, key) => {
                            return (
                              <div key={key + item.WorkText} className="item">
                                {`${item.WorkText} [${item.QualColText}]`}
                                <div className="icons-container">
                                  <div className="cart-icon">
                                    <FontAwesomeIcon icon={faCartShopping} className="" />
                                  </div>
                                  {item.ExclusiveWorkPositions.length ? (
                                    <div className="info-icon" onClick={() => handleWorkInfoClick(item.ExclusiveWorkPositions)}>
                                      <FontAwesomeIcon icon={faInfo} />
                                    </div>
                                  ) : (
                                    ''
                                  )}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  </div>
                )}
                <Modal
                  openModal={openWorkInfoModal}
                  setOpenModal={setOpenWorkInfoModal}
                  onRequestClose={() => setOpenWorkInfoModal(false)}
                  childrenComponent={
                    <div className="work-info-modal-content">
                      <div className="title">{t('_repair_items_contained')}</div>
                      {exclusiveWorkPosition?.map((workPosition, index) => (
                        <li key={index}>{`${workPosition.WorkText} [${workPosition.QualColText}]`}</li>
                      ))}
                      <DefaultButton onClick={() => setOpenWorkInfoModal(false)} text={t('_close')} />
                    </div>
                  }
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default RepairItems;
