import React, { useEffect, useState } from 'react';
import CatalogsPartsPageView from './CatalogsPartsPage.view';
import { useParams, generatePath, createSearchParams, useNavigate } from 'react-router-dom';
import { IGetCarPartDataParams } from 'models/car-parts.model';
import useReliableCatalogCarQueryParams from 'utils/hooks/useReliableCatalogCarQueryParams';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { setActivePartCategory } from 'store/slices/car-parts-categories.slice';
import { useAppDispatch } from 'utils/hooks/redux';
import { resetPartsList } from 'store/slices/parts-list.slice';

const CatalogsPartsPage: React.FC = () => {
  const { vehicle_id } = useParams<'vehicle_id'>();
  const { itemMpIds, vehicleManualsList, loadingCategories, loadingVehicleManualsList, loadingItemMpIds, activePartCategory } = useSelector((state: RootState) => state.carPartsCategories);
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const reliableCatalogCarQueryParams = useReliableCatalogCarQueryParams();


  const onCategoryClick = (category) => {
    dispatch(setActivePartCategory(category));
  };

  const onClickHide = () => {
    dispatch(setActivePartCategory(null));
  };

  const handleSubcategoryClick = (params: Omit<IGetCarPartDataParams, 'catalog_id' | 'page' | 'per_page'>) => {
    const searchParams = createSearchParams({
      ...reliableCatalogCarQueryParams,
      code_groupe: String(params.code_groupe),
      code_ssgroupe: String(params.code_ssgroupe),
      code_repere: String(params.code_repere),
      reference_count: String(params.code_repere),
      page: '1',
    });

    // navigate({
    //   pathname: generatePath('/reliable/rmi/:vehicle_id/catalogs/parts/part-catalog', {
    //     vehicle_id: String(vehicle_id),
    //   }),
    //   search: `?${searchParams}`,
    // });


    const newLink = ({
      pathname: generatePath('/reliable/rmi/:vehicle_id/catalogs/parts/part-catalog', { vehicle_id: String(vehicle_id), }),
      search: `?${searchParams}`,
    });

    return newLink.pathname + newLink.search;


  };

  const redirectToManual = (event, manualId) => {
    event.stopPropagation();
    navigate({
      pathname: `/reliable/rmi/${vehicle_id}/technical-documentation/technical-manuals`, //`/reliable/rmi/${manualId}`,
      search: `?${createSearchParams({ ...reliableCatalogCarQueryParams, manual_id: manualId })}`,
    });
  };

  useEffect(() => {
    dispatch(resetPartsList());
  }, []);

  return (
    <CatalogsPartsPageView
      loading={loadingCategories}
      activeCategory={activePartCategory}
      handleSubcategoryClick={handleSubcategoryClick}
      onCategoryClick={onCategoryClick}
      onClickHide={onClickHide}
      itemMpIds={itemMpIds}
      vehicleManuals={vehicleManualsList}
      redirectToManual={redirectToManual}
    />
  );
};

CatalogsPartsPage.displayName = 'CatalogsPartsPage';

export default CatalogsPartsPage;
