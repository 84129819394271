import CatalogSupplierNavbar from 'components/navbar/catalog-supplier-navbar/CatalogSupplierNavbar';
import TariffFile from 'components/tariff-file/TariffFile';
import { ICatalogInfo } from 'models/catalog.model';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './CatalogTariffs.scss';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import { contentStyle } from 'applications/amerigo/views/select-client/ModalClient/ContentStyleModal';
import Modal from '../../../../components/modal/Modal';
import NewTariff from '../NewTariff/NewTariff';
import { IOption } from 'interfaces/select';
import Spinner from 'components/spinner/Spinner';
import { usePermission } from '../../../../utils/usePermission';

type Props = {
  clients: IOption[];
  catalogInfo: ICatalogInfo | null;
  openModal: boolean;
  setOpenModal: (e: boolean) => void;
  preselectedClient?: IOption;
  loading?: boolean;
};

const CatalogTariffsView: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const useBtnAddNew = usePermission(['ROLE_SUPPLIER_AMERIGO']);

  return (
    <div className="catalog-supplier-layout-container">
      <CatalogSupplierNavbar title={`${t('_tariffs')} /`} />
      {!useBtnAddNew ? (
        ''
      ) : (
        <div className="add-tariff-btn">
          <div>
            <DefaultButton text={t('_add_new_tariff')} onClick={() => props.setOpenModal(true)} />
          </div>
        </div>
      )}
      <div className="tariff-page-container">
        {props.loading ? (
          <Spinner />
        ) : (
          <div className="tariff-item">
            {(props.catalogInfo && props.catalogInfo.tariff_file) ? <TariffFile file={props.catalogInfo?.tariff_file} /> : <div className="no-data">{t('_no_data')}</div>}
          </div>
        )}
        <div className="tariff-add-container">
          <Modal
            openModal={props.openModal}
            setOpenModal={props.setOpenModal}
            childrenComponent={
              <NewTariff preselectedClient={props.preselectedClient} setOpenModal={props.setOpenModal} options={props.clients} />
            }
            contentStyle={contentStyle}
          />
        </div>
      </div>
    </div>
  );
};

CatalogTariffsView.displayName = 'CatalogTariffsView';

export default CatalogTariffsView;
