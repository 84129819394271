import React, { useCallback, useEffect, useState } from 'react';
import { faClose, faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { FileWithPath, useDropzone } from 'react-dropzone';
import { faFileExcel } from '@fortawesome/free-regular-svg-icons';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import useHttp from 'utils/hooks/useHttp';
import { notify } from 'utils/marketplace';
import i18n from 'i18n';
import './UploadPartReplacementModal.scss';

type Props = {
  setOpenModal: (openModal: boolean) => void
}

const UploadPartReplacementModal: React.FC<Props> = (props: Props) => {
  const http = useHttp();
  const { t } = useTranslation();
  const [loadingImport, setLoadingImport] = useState(false);
  const [invalidFile, setInvalidFile] = useState(false);
  const [file, setFile] = useState<FileWithPath | null>(null);

  const onDrop = useCallback((acceptedFiles) => {
    setFile(acceptedFiles[0]);
    setInvalidFile(false);
  }, [file]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    accept: {
      'text/csv': ['.xls', '.xlsx'],
      'application/vnd.ms-excel': ['.xls', '.xlsx'],
    },
  });

  function submit(e) {
    e.preventDefault();

    const formData: FormData = new FormData();

    if (!file) {
      return;
    }

    setLoadingImport(true);
    formData.append('file', file);

    http.partsReplacement.importPartsReplacement(formData)
      .then(() => {
        notify(t('_success'), 'success');
        props.setOpenModal(false);
      })
      .catch((error) => {
        Object.keys(error).forEach((key) => {
          notify(t(error[key]?.[0]) || t('_error'));
        });
      })
      .finally(() => {
        setLoadingImport(false);
      });
  }

  useEffect(() => {
    console.log(i18n.language);
  }, [i18n.language]);

  return (
    <form className="upload-part-replacement-modal">
      <div className="modal-header">
        <div className="modal-title">{t('_import_part_replacement')}</div>
        <div className="close-modal" onClick={() => props.setOpenModal(false)}><FontAwesomeIcon icon={faClose} /></div>
      </div>
      <div className="mobile-scroll">
        <div className="modal-body">
          <div className={`part-replacement-file-upload-container ${invalidFile && 'invalid'}`}>
            <i className="fas fa-folder-open part-replacement-folder-upload" />
            <div {...getRootProps()} className="part-replacement-title-upload ">
              {t('_drag_drop_here')}
              <br />
              <strong className="browse-files">{t('_csv_browse')}</strong>
            </div>
            <input
              {...getInputProps()}
              accept="text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              type="file"
              style={{ display: 'none' }}
            />
          </div>
          <div className="download-template">
            {i18n.language.split('-')[0] === 'fr' ?
              <a onClick={(e) => e.stopPropagation()} href="/csv-template/remplacement_de_pieces.xlsx" download><FontAwesomeIcon icon={faDownload} />{t('_download_template')}</a>
              :
              <a onClick={(e) => e.stopPropagation()} href="/csv-template/template_part_replacement.xlsx" download><FontAwesomeIcon icon={faDownload} />{t('_download_template')}</a>
            }
          </div>
          {
            file &&
            <div className="part-replacement-details">
              <div className="uploaded-file">
                <FontAwesomeIcon className="file-icon" icon={faFileExcel} />
                <div className="file-data">
                  <div className="file-name">{file.path}</div>
                  <FontAwesomeIcon className="remove-file" icon={faClose} onClick={() => setFile(null)} />
                </div>
              </div>
            </div>
          }
        </div>
      </div>
      <div className="modal-footer">
        <div className="upload-part-replacement-btn">
          <DefaultButton text={t('_import')} isloading={loadingImport} onClick={submit} disabled={!file} />
        </div>
      </div>
    </form>
  );
};

export default UploadPartReplacementModal;