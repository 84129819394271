import React, { MutableRefObject, useRef, useState } from 'react';
import TextInput from 'components/inputs/TextInput/TextInput';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import DatePicker from 'react-datepicker';
import { useOnClickOutside } from 'utils/hooks/useOnClickOutside';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import '../RetailerOrders.scss';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faXmark } from '@fortawesome/free-solid-svg-icons';
import { useMobileDetect } from 'utils/hooks/use-mobile-detect';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import i18n from 'i18n';
import { useLocaleDatePicker } from 'utils/localeDatePicker';

type Props = {
  catalogId?: string;
  register,
  onSubmit,
  handleSubmit,
  handleClick,
  isActive,
  setIsActive,
  reset
  setStartDate,
  startDate
};

const RetailrOrdersFilter: React.FC<Props> = (props) => {

  const { t } = useTranslation();
  const [valueDate, setValueDate] = useState('');
  const [openDate, setOpenDate] = useState(false);
  const dateRef = useRef() as MutableRefObject<HTMLDivElement>;
  const { isMobile } = useDeviceDetect();

  useLocaleDatePicker(i18n.language);
  useOnClickOutside(dateRef, () => setOpenDate(false));

  function onDateChange(date) {

    props.setStartDate(date);

    setValueDate(`${moment(date).format('DD/MM/YYYY')}`);

  }

  const handleResetFilter = () => {
    props.reset({ status: '', date: '', id: '', system_order_id: '', customer_id: '' });
    props.setStartDate(null);
    setValueDate('');
  };

  return (
    <React.Fragment>

      {
        isMobile ?
          <button className="button-filter" onClick={props.handleClick}>< FontAwesomeIcon icon={faFilter} /> {t('_filter')}</button > :
          null
      }

      <div className={`orders-filter ${isMobile && !props.isActive ? 'hidden-filter' : 'show-filter'}`}>

        {
          isMobile ?
            <div className="close-btn">
              <FontAwesomeIcon icon={faXmark} onClick={() => { props.setIsActive(false); }} className="close-btn-icon" />
            </div> :
            null
        }

        <div className="orders-filter-header">

          <div className="filter-title">{t('_order_search_client')}</div>

        </div>
        <form onSubmit={props.handleSubmit(() => props.onSubmit())}>
          <div className="orders-filter-body">
            <div className="orders-filter-input-item">
              <TextInput {...props.register('id')} placeholder={t('_order_number')} />
            </div>
            <div className="orders-filter-input-item">
              <TextInput {...props.register('system_order_id')} placeholder={t('_by_system_af_id')} />
            </div>
            <div className="orders-filter-input-item">
              <div className={`orders-react-picker-dropdown ${openDate ? 'disable' : ''}`} onClick={() => setOpenDate(true)}>
                <TextInput readOnly placeholder={t('_select_date')} defaultValue={valueDate ? valueDate : ''} />
              </div>
              {openDate && (
                <div className="orders-date-picker" ref={dateRef}>
                  <DatePicker
                    {...props.register('date')}
                    onChange={onDateChange}
                    inline
                  />
                </div>
              )}
            </div>
            <div className="orders-filter-input-item">
              <TextInput {...props.register('customer_id')} placeholder={t('_by_customer_id')} />
            </div>

            <DefaultButton text={t('_filter')} type="submit" />
            <DefaultButton red text={t('_reset')} onClick={handleResetFilter} />
          </div>
        </form>
      </div>

    </React.Fragment>
  );
};

export default RetailrOrdersFilter;
