import React, { useCallback, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import DragComponent from '../ProformaMappingTable/DragAndDropComponents/DragComponent';
import { IRow } from '../schema';
import Spinner from 'components/spinner/Spinner';
import useHttp from 'utils/hooks/useHttp';
import './ProformaMappingFile.scss';
import { notify } from 'utils/marketplace';

interface IProformaMappingFileProps {
  fileHeader?,
  uploadFileRef?,
  uploadingFile: boolean,
  setFileHeader: (fileHeader) => void,
  setUploadingFile: (uploadingFile: boolean) => void,
  setRows: (rows: IRow[]) => void,
  rows: IRow[],
  disableMapping: boolean,
  headerLine: number,
  setHeaderLine: (nuber: number) => void,

}

const ProformaMappingFile = (props: IProformaMappingFileProps): JSX.Element => {
  const { t } = useTranslation();
  const [waringMessage, setWaringMessage] = useState(false);
  const http = useHttp();

  const onDrop = useCallback(async acceptedFiles => {
    try {
      const fileUploaded = acceptedFiles[0];

      props.setUploadingFile(true);
      const { data } = await http.proformaMapping.getFileHeader(fileUploaded, String(props.headerLine));

      const emptyRows = props.rows.map(row => ({
        ...row,
        name: '',
      }));

      props.setFileHeader(data);
      props.setRows(emptyRows);
    } catch (res) {
      notify(t(`_${String(res).toLocaleLowerCase()}`));
    } finally {
      props.setUploadingFile(false);
    }
  }, [props.headerLine]);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const handleHeaderLineChange = (value: number) => {
    if (value < 1 || value > 200) {
      setWaringMessage(true);
    } else {
      setWaringMessage(false);
    }

    props.setHeaderLine(Math.floor(value));
  };

  return (
    <div className="proforma-file">
      <div className="proforma-file-mapping">
        <div className={`proforma-file-title ${props.disableMapping ? 'proforma-disabled-text' : ''}`}>{t('_custom_mapping')}</div>

        <div className="input-container">
          <div className="custom-input">
            <label
              className={`header-line-label ${props.disableMapping ? 'disabled' : ''}`}
              htmlFor="header-line-number"
            >
              {t('_header_line')}:
            </label>
            <input
              className={`header-line-input ${props.disableMapping ? 'disabled' : ''}`}
              value={Number(props.headerLine).toString()}
              type="number"
              disabled={props.disableMapping}
              min={1}
              max={200}
              step={1}
              onChange={e => handleHeaderLineChange(Number(e.target.value))}
            />
          </div>
          {waringMessage && <div className="waring-message">{t('_limit_1_to_200')}</div>}
        </div>
        <div className={`proforma-file-subtitle ${props.disableMapping ? 'proforma-disabled-text' : ''}`}>{t('_file_upload')}</div>
        <div className={`proforma-file-upload-container ${props.disableMapping ? 'proforma-disabled-file' : ''}`}>
          <i className={`fas fa-folder-open proforma-folder-upload ${props.disableMapping ? 'proforma-disabled-icon' : ''}`} />
          <div {...getRootProps()} className={`proforma-title-upload ${props.disableMapping || waringMessage ? 'proforma-disabled-text' : ''}`}>
            {t('_drag_drop_here')}
            <br />
            <strong className="browse-files">{t('_csv_browse')}</strong>
          </div>
          {!props.disableMapping
            ? (
              <input
                {...getInputProps()}
                type="file"
                style={{ display: 'none' }}
              />
            )
            : (
              <input
                {...getInputProps()}
                type="file"
                style={{ display: 'none' }}
              />
            )}
          <span className="proforma-subtitle-upload">
            {t('_csv_support')}
          </span>
        </div>
        <div className={`proforma-file-columns-title ${props.disableMapping ? 'proforma-disabled-text' : ''}`}>{t('_columns')}</div>
        <div className="proforma-file-columns-container">

          {props.uploadingFile ? (
            <Spinner class="proforma-file-upload-spinner" />
          )
            : (
              <div className="proforma-file-columns">
                {props.fileHeader ? Object.entries(props.fileHeader).map(([order, name]) => {
                  return name ? (<DragComponent key={order} name={String(name)} number={+order} disableMapping={props.disableMapping} />) : null;
                }) : <React.Fragment>{t('_please_upload_a_file')}</React.Fragment>}
              </div>
            )}
        </div>
      </div>
    </div>
  );
};

export default ProformaMappingFile;
