import React, { useEffect, useState } from 'react';
import { ReactComponent as CarPartIcon } from 'assets/Icons/car-chassis.svg';
import { useSelector } from 'react-redux';
import { createSearchParams, generatePath, NavLink, useParams } from 'react-router-dom';
import { getCurrentCountryRMI, getCurrentLanguageRMI } from 'utils/rmi/rmi-utils/rmi-utils';
import { RootState } from 'store/store';
import useReliableCatalogCarQueryParams from 'utils/hooks/useReliableCatalogCarQueryParams';
import useHttp from 'utils/hooks/useHttp';
import i18n from 'i18n';
import './GenartCarPartsLink.scss';

type Props = {
  workId?: string
}

const GenartCarPartsLink: React.FC<Props> = (props) => {
  const http = useHttp();
  const { typeId } = useSelector((state: RootState) => state.tecrmi);
  const [generatedLink, setGeneratedLink] = useState<string>('');
  const { vehicle_id } = useParams();
  const carQueryParams = useReliableCatalogCarQueryParams();

  async function fetchPartsForWorks(itemWorkId) {
    if (typeId) {
      const { data } = await http.maintenanceHttp.fetchPartsForWorks({
        countryCode: getCurrentCountryRMI(i18n.language),
        languageCode: getCurrentLanguageRMI(i18n.language),
        mdBodyQualColId: 0,
        typeId,
        selectedWorkIds: [Number(itemWorkId)]
      });

      return data.map(partWork => Number(partWork.ItemMpId));
    }
  }

  async function fetchGenArts(ItemMpIds: Array<number>) {
    const { data } = await http.maintenanceHttp.fetchGenartForItemMpIds({
      ItemMpIds
    });

    return data.map(genArt => genArt.GenArtNo);
  }

  const generateLinkToGenArts = async (itemWorkId: number): Promise<string> => {
    let genArts;

    try {
      const itemMpIds = await fetchPartsForWorks(itemWorkId);

      if (itemMpIds) {
        genArts = await fetchGenArts(itemMpIds);
      }
    } catch (e) {
      console.error(e);
    }

    let genArtsQuery;

    if (genArts && genArts.length) {
      genArtsQuery = `&genartnrs[]=${genArts.join('&genartnrs[]=')}`;
    }

    const params = { ...carQueryParams };

    return genArtsQuery ? `${generatePath('/reliable/rmi/:vehicle_id/catalogs/parts/parts-by-genart', {
      vehicle_id: String(vehicle_id),
      vehicle_identifier: String(params.vehicle_identifier) || '002',
    } as {vehicle_id: string, vehicle_identifier: string} )}?${createSearchParams({source: params.source, vehicle_identifier: params.vehicle_identifier, ...carQueryParams})}${genArtsQuery}` : '';
  };

  useEffect(() => {
    if (props.workId) {
      generateLinkToGenArts(Number(props.workId)).then(res => {
        setGeneratedLink(res);
      });
    }
  }, [props.workId]);

  return (
    <NavLink className={`genart-link-btn ${!generatedLink ? 'disabled' : ''}`} to={generatedLink}>
      <CarPartIcon />
    </NavLink>
  );
};

export default GenartCarPartsLink;