import React from 'react';
import { useTranslation } from 'react-i18next';

import { NavLink } from 'react-router-dom';

import { ReactComponent as Image } from 'assets/images/price-list-icon.svg';

type Props = {};

const WaitingOffers: React.FC<Props> = () => {
  const { t } = useTranslation();

  return (
    <div className="item-client">
      <NavLink to="/area/waiting-offers">
        <div className="title">{t('_waiting_offers')}</div>
        <div className="upload-btn">
          <div className="icon">
            <Image />
          </div>
          <div className="text">{t('_waiting_offers')}</div>
        </div>
      </NavLink>
    </div>
  );
};

WaitingOffers.displayName = 'WaitingOffers';

export default WaitingOffers;
