import React from 'react';
import { faArrowLeft, faComment } from '@fortawesome/free-solid-svg-icons';

import './MessagesNavbar.scss';
import {useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {RootState} from 'store/store';
import Spinner from 'components/spinner/Spinner';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { Trans, useTranslation } from 'react-i18next';

type Props = {
  title: string;
}
const MessagesNavbar:React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const { currentCatalog, isLoadingData } = useSelector((state: RootState) => state.catalogs);
  const {t} = useTranslation();

  return (
    <div className="messages-catalog-navbar-container">
      {
        isLoadingData ? (
          <Spinner class="small"/>
        ) : (
          <div>
            <div className="row">
              <div className="breadcrumbs" onClick={()=> navigate('/messages')}>
                <div className="icon">
                  <FontAwesomeIcon icon={faArrowLeft}/>
                </div>
                <p className="back-description">{t('_return_messages')}</p>
              </div>
              <div className="title">
                <h1>{props.title}</h1>
              </div>
              <div className="options"></div>
            </div>
            <div className="supplier-name">
              <div className="icon">
                <FontAwesomeIcon icon={faComment}/>
              </div>
              <p className="description">
                <Trans
                  i18nKey="_exchanges_with"
                  values={{catalogName: currentCatalog?.name}}
                /></p>
              <div/>
            </div>
          </div>
        )
      }

    </div>
  );
};

MessagesNavbar.displayName = 'MessagesNavbar';

export default MessagesNavbar;