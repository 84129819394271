import { faCircleArrowLeft, faDownload, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Spinner from 'components/spinner/Spinner';
import { useAppSelector } from 'utils/hooks/redux';
import { removeImportReferenceData, setImportReferenceData } from 'store/reducers/importReferenceSlice';
import { useDispatch } from 'react-redux';
import { IImportFileNew } from 'interfaces/price';
import { useTranslation } from 'react-i18next';
import useHttp from 'utils/hooks/useHttp';
import moment from 'moment';
import { notify } from 'utils/marketplace';
import SendEmailToSeller from 'applications/automotor/components/espace-automotor/send-email-to-seller/SendEmailToSeller';
import TariffTable from '../TariffTable/TariffTable';
import ProformaNew from '../BtnTariff/ProformaNew';
import Modal from 'components/modal/Modal';
import AddReferenceModal from '../../espace-automotor-order/order-summary/table-section-details/add-reference-modal/AddReferenceModal';
import TextInput from 'components/inputs/TextInput/TextInput';
import i18n from 'i18n';
import DatePicker from 'react-datepicker';
import './TariffImport.scss';
import { useOnClickOutside } from 'utils/hooks/useOnClickOutside';
import { useForm } from 'react-hook-form';


const TariffImport: React.FC = () => {

  const user = JSON.parse(localStorage.getItem('userData')!);
  const importReference = useAppSelector((state) => state.importReferenceData.importReference);
  const [data, setData] = useState<IImportFileNew[]>(importReference || []);
  const [openModal, setOpenModal] = useState(false);
  const [newRefData, setNewRefData] = useState<IImportFileNew[]>([]);
  const [loading, setLoading] = useState(true);
  const [disableBtn, setDisableBtn] = useState(false);
  const [total, setTotal] = useState<number>(0);
  const [removeReferenceKey, setRemoveReferenceKey] = useState<number | null>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [valueItems, setValueItems] = useState<IValueItems>();
  const [loadingContent, setLoadingContent] = useState(false);
  const http = useHttp();
  const [loadingExport, setLoadingExport] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [startDate, setStartDate] = useState(null);
  const [valueDate, setValueDate] = useState('');
  const [openDate, setOpenDate] = useState(false);
  const dateRef = useRef() as MutableRefObject<HTMLDivElement>;

  const {
    register,
    setValue,
    getValues
  } = useForm();
  
  useOnClickOutside(dateRef, () => setOpenDate(false));

  const exportFile = () => {
    setLoadingExport(true);

    if (!data.length) {
      notify(t('_file_could_not_be_downloaded') + ' ' + t('_empty_data'), 'error');
      setLoadingExport(false);

      return;
    }

    http.price.export(data).then(res => {
      const element = document.createElement('a');

      const file = new Blob(
        [res.data],
        { type: res.type },
      );

      element.href = URL.createObjectURL(file);
      element.download = `${t('_reference')} - ${moment().format('DD.MM.YYYY')}.xlsx`;
      element.click();

    }).finally(() => {
      setLoadingExport(false);
    });

  };

  useEffect(() => {
    setData([]);

    if (importReference) {
      setLoading(true);
      setLoadingContent(true);
      setData(importReference);
      let total_price = 0;

      importReference.map((item) => {

        item.parts.map(i => {
          if (i.ind_equivalence && i.ind_equivalence_select) {
            if (Number(i.ind_equivalence.quantity) >= i.ind_equivalence.min_quantity && (Number(i.ind_equivalence.quantity) % i.ind_equivalence.uv) == 0) {
              total_price += Number(i.ind_equivalence.total);
            }

          } else {
            if (Number(i.quantity) >= i.min_quantity && (Number(i.quantity) % i.uv) == 0) {
              total_price += Number(i.total);
            }

          }
        });
      });
      // const newData = importReference.map((item) => {
      //   return newRefData.map(i => {
      //     if (item.code_fou === i?.code_fou) {
      //       return { ...item, parts: [...i.parts, ...item.parts] };
      //     } else {
      //       return item;
      //     }
      //   });
      // }).flat();

      setTotal(total_price);
    } else if (!Array.isArray(importReference) && localStorage.getItem('importReference') !== null) {
      const ref = JSON.parse(localStorage.getItem('importReference') as string);

      dispatch(setImportReferenceData(ref));
    } else {

      setData([]);
    }


    setValueItems(undefined);
    setLoading(false);
    setLoadingContent(false);


  }, [importReference]);

  useEffect(() => {
    if (newRefData.length) {
      const refDataMap = new Map();

      newRefData.forEach((item) => {
        const { code_fou, parts } = item;

        if (refDataMap.has(code_fou)) {
          const existingItem = refDataMap.get(code_fou);
          const mergedParts = [...existingItem.parts, ...parts];

          refDataMap.set(code_fou, { ...existingItem, parts: mergedParts });
        } else {
          refDataMap.set(code_fou, { ...item });
        }
      });

      const mergedRefData = Array.from(refDataMap.values());

      dispatch(setImportReferenceData(mergedRefData));
      localStorage.setItem('importReference', JSON.stringify(mergedRefData));
    }
  }, [newRefData]);

  useEffect(() => {
    if (searchParams.get('file')) {
      setLoading(true);
      setLoadingContent(true);

      setTimeout(() => {
        setValueItems(undefined);
        setLoading(false);
        setLoadingContent(false);
        searchParams.delete('file');
        setSearchParams(searchParams);
      }, 500);
    }
  }, [searchParams.get('file')]);


  const redirect = () => {
    navigate('/');
    dispatch(removeImportReferenceData());
    localStorage.removeItem('importReference');
  };

  const removeRow = (index: number) => {
    setRemoveReferenceKey(index);
  };


  useEffect(() => {
    if (removeReferenceKey !== null) {

      const newData = data.map((items) => {
        const result_parts = items.parts.filter(item => {
          if (item.index === removeReferenceKey) {
            setTotal(total - Number(item.total));
          }

          return item.index !== removeReferenceKey;
        });

        const objCopy = { ...items };

        objCopy.parts = result_parts;


        return objCopy;
      });

      const newDataFilter = newData.filter(item => {
        return item.parts.length !== 0;
      });

      if (newDataFilter.length) {
        dispatch(setImportReferenceData(newDataFilter));
        localStorage.removeItem('importReference');
        localStorage.setItem('importReference', JSON.stringify(newDataFilter));
      } else {
        dispatch(removeImportReferenceData());
        localStorage.removeItem('importReference');
        localStorage.setItem('importReference', JSON.stringify([]));

      }
    }
  }, [removeReferenceKey]);


  useEffect(() => {
    if (valueItems !== undefined) {

      const newData = [...JSON.parse(JSON.stringify(data))];

      newData.forEach((items: IImportFileNew) => {
        items.parts.forEach((item) => {
          if (item.index === valueItems.index) {
            item.quantity = valueItems.quantity;
            item.total = Number(parseFloat(String(valueItems.quantity * Number(item.price))).toFixed(2));

            if (item.ind_equivalence) {
              item.ind_equivalence.quantity = valueItems.quantity;
              item.ind_equivalence.total = Number(parseFloat(String(valueItems.quantity * Number(item.ind_equivalence.price))).toFixed(2));
            }
          }
        });
      });

      if (newData.length) {
        dispatch(setImportReferenceData(newData));
        localStorage.removeItem('importReference');
        localStorage.setItem('importReference', JSON.stringify(newData));
      }
    }

  }, [valueItems]);

  const valueChangeRepaced = (index: number) => {
    const obj = [...JSON.parse(JSON.stringify(data))];

    const newData = obj.map((items) => {
      const result_parts = items.parts.map(item => {
        if (item.index === index) {
          const replace = item.replace;
          const itemDefault = { replace_status: !item.replace_status, index: Number(item.index), quantity: item.quantity };

          delete item.replace_status;
          delete item.index;
          delete item.replace;
          delete item.quantity;
          const itemNew = { ...itemDefault, ...replace };

          return { ...itemNew, ...{ replace: item } };
        }

        return item;

      });

      const objCopy = { ...items };

      objCopy.parts = result_parts;

      return objCopy;
    });

    if (newData.length) {
      dispatch(setImportReferenceData(newData));
      localStorage.removeItem('importReference');
      localStorage.setItem('importReference', JSON.stringify(newData));
    }
  };

  const changePartInd = (status, index: number) => {
    const obj = [...JSON.parse(JSON.stringify(data))];

    const newData = obj.map((items) => {
      const result_parts = items.parts.map(item => {
        if (item.index === index) {
          if (status) {
            item.ind_equivalence_select = true;
          } else {
            item.ind_equivalence_select = false;
          }
        }

        return item;
      });

      const objCopy = { ...items };

      objCopy.parts = result_parts;

      return objCopy;
    });

    if (newData.length) {
      dispatch(setImportReferenceData(newData));
      localStorage.removeItem('importReference');
      localStorage.setItem('importReference', JSON.stringify(newData));
    }
  };

  useEffect(() => {
    const hasItemWithZeroQuantity = data.some((item) => {
      return item.parts.some((part) => part.quantity === 0);
    });

    if (hasItemWithZeroQuantity) {
      setDisableBtn(true);
    } else {
      setDisableBtn(false);
    }
  }, [data]);


  const redirectDelete = () => {

    localStorage.removeItem('importReference');
    dispatch(removeImportReferenceData());
    navigate('/');
  };

  function onDateChange(date) {
    setStartDate(date);
    setValue('date', date);
    setValueDate(`${moment(date).format('DD/MM/YYYY')}`);
  }


  return (
    <div className="automotor-tariff-cards">
      <div className="automotor-tariff-espace-import">
        {
          loading ? <Spinner /> :
            <React.Fragment>
              {/* <span className="returnButton" onClick={redirect} >
                <FontAwesomeIcon icon={faCircleArrowLeft} className="icon" />
                {`${t('_return')}`}
              </span> */}
              <div className="tariff-header">
                <div className="tariff-title">
                  {`${t('_summary_of_your_quotation')}`}
                </div>
                <div className="tariff-btn">
                  <div className="tariff-btn-export">
                    <button className="btn-export" type="button" onClick={exportFile} disabled={loadingExport}>
                      {loadingExport ? <Spinner class="extra-small" /> : <FontAwesomeIcon icon={faDownload} className="icon" />}    {`${t('_export_to_excel')}`}
                    </button>
                    <button className="btn-export" type="button" onClick={() => setOpenModal(true)}>
                      <FontAwesomeIcon icon={faPlus} className="icon" /> {t('_new_reference')}
                    </button>
                  </div>
                  <div className={`tariff-btn-comand ${disableBtn ? 'disable' : ''}`}>
                    <ProformaNew deliveryDate={getValues('date')} data={data} is_submit_quotation={1} />
                  </div>
                </div>
              </div>

              <TariffTable
                loadingContent={loadingContent}
                changePartInd={changePartInd}
                data={data}
                setValueItems={setValueItems}
                removeRow={removeRow}
                total={total}
                valueChangeRepaced={valueChangeRepaced}
                redirect={redirectDelete}
              />
              {user?.client_settings.show_proforma_delivery_date ? (
                <div className="form-date-input-item">
                  <div className="label">{t('_delivery_date')}</div>
                  <div className={`form-react-picker-dropdown ${openDate ? 'disable' : ''}`} onClick={() => setOpenDate(true)}>
                    <TextInput readOnly placeholder={t('_select_date')} defaultValue={valueDate ? valueDate : ''} />
                  </div>
                  {openDate && (
                    <div className="form-date-picker" ref={dateRef}>
                      <DatePicker
                        {...register('date')}
                        onChange={onDateChange}
                        minDate={moment().toDate()}
                        inline
                        locale={i18n.language.split('-')[0]}
                      />
                    </div>
                  )}
                </div>
              ) : (
                ''
              )}
              <SendEmailToSeller />
            </React.Fragment>
        }
      </div>
      <Modal
        openModal={openModal}
        shouldCloseOnOverlayClick={false}
        setOpenModal={setOpenModal}
        childrenComponent={
          <AddReferenceModal tableData={data} setNewRefData={setNewRefData} setOpenModal={setOpenModal} importReference />
        }
      />
    </div>

  );
};

TariffImport.displayName = 'TariffImport';

export interface IValueItems {
  index: number;
  quantity: number;
}

export default TariffImport;
