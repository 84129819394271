import Spinner from 'components/spinner/Spinner';
import i18n from 'i18n';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import useHttp from 'utils/hooks/useHttp';
import { ITyres, ITyresCategorie } from 'utils/rmi/interface/tyres.interface';
import { getCurrentCountryRMI, getCurrentLanguageRMI } from 'utils/rmi/rmi-utils/rmi-utils';
import './Tyres.scss';
import TyresSearchInput from './TyresSearchInput';
import { TyresView } from './TyresView';
import { ReactComponent as PartsNotFoundImage } from 'assets/images/parts-not-found.svg';
import { useTranslation } from 'react-i18next';

type Props = {
  tyreCategories?: ITyresCategorie[];
  onRowClick?: (row) => void;
};

const Tyres: React.FC<Props> = (props) => {
  const [loading, setLoading] = useState<boolean>(true);
  const http = useHttp();

  const { typeId } = useSelector((state: RootState) => state.tecrmi);

  const [tyresData, setTyresData] = useState<ITyres[]>([]);
  const [inputValue, setInputValue] = useState<string>('');
  const [searchedTyres, setSearchedTyres] = useState<ITyres[]>([]);
  const [showSearchIcon, setShowSearchIcon] = useState<boolean>(true);
  const { t } = useTranslation();

  function searchTyresData() {
    if (inputValue === '') {
      setSearchedTyres(tyresData);
    } else {
      const lowerValue = inputValue.toLocaleLowerCase();

      const filteredTyres = tyresData.filter(
        (tyre) =>
          tyre.FrontRimSize.toLowerCase().includes(lowerValue) ||
          tyre.FrontTyreSize.toLowerCase().includes(lowerValue) ||
          tyre.RearRimSize.toLowerCase().includes(lowerValue) ||
          tyre.RearTyreSize.toLowerCase().includes(lowerValue) ||
          tyre.ApplicationText.toLowerCase().includes(lowerValue) ||
          tyre.InfoText.toLowerCase().includes(lowerValue),
      );

      setSearchedTyres(filteredTyres);
    }
  }

  function resetTable() {
    if (props.tyreCategories) {
      setTyresData(props.tyreCategories);
      setSearchedTyres(props.tyreCategories);
    } else {
      fetchData();
    }

    setInputValue('');
  }

  const fetchData = () => {
    if (typeId) {
      setLoading(true);
      http.rmiTyresHttp
        .fetchRMIVehicleHasTyres({
          typeId: typeId,
          countryCode: getCurrentCountryRMI(i18n.language),
          languageCode: getCurrentLanguageRMI(i18n.language),
        })
        .then(({ data }) => {
          setTyresData(data);
          setSearchedTyres(data);
        })
        .catch((e: Error) => console.log(e))
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    if (props.tyreCategories) {
      setTyresData(props.tyreCategories);
      setSearchedTyres(props.tyreCategories);
      setLoading(false);
    } else {
      fetchData();
    }
  }, [typeId, i18n.language, props.tyreCategories]);

  return (
    <div className="tyre-tcrmi-container">
      <div className="tyres-input-search">
        <TyresSearchInput resetTable={resetTable} value={inputValue} setValue={setInputValue} searchTyresData={searchTyresData} />
      </div>
      {loading ? (
        <Spinner class="small tyres-tcrmi-table-spinner" />
      ) : (
        <div className="search-tyres-container">
          <div className="tyres-container">
            {tyresData.length ? (
              <TyresView data={searchedTyres} activeTab="#tyres" loading={false} onRowClick={props.onRowClick} />
            ) : (
              <div className="notFoundSvg">
                <PartsNotFoundImage />
                <h1>{t('_tyres_not_found')}</h1>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

Tyres.displayName = 'Tyres';

export default Tyres;
