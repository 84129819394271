import { ICategorieGroup } from 'applications/automotor/components/espace/navbar/Navbar.model';
import { ICarPartData } from 'models/car-parts.model';
import { presentationAxios } from 'utils/presentation_http';
import { addRouteParam, IHttpResponse } from '../../http';


class PresentationHttp {
  private static categories = '/af-presentation/categories';
  private static carParts = '/af-presentation/car-parts';
  private static breadCrumb = '/af-presentation/category';
  private static carPartView = 'af-presentation/car-parts/view/:id';


  public async getCategories(params: { catalog_id: string }): Promise<IHttpResponse<ICategorieGroup[]>> {
    return presentationAxios.get(PresentationHttp.categories, { params });
  }
  public async getCarParts(params: ICarPartsParams): Promise<IHttpResponse<ICarPartData[]>> {
    return presentationAxios.get(PresentationHttp.carParts, { params });
  }
  public async getCarPartsView(id: string, params: ICarPartsViewParams): Promise<IHttpResponse<ICarPartData>> {
    return presentationAxios.get(addRouteParam(PresentationHttp.carPartView, { id }), { params });
  }
  public async getBreadCrumb(params: ICarPartsParams): Promise<IHttpResponse<IBreadCrumb>> {
    return presentationAxios.get(PresentationHttp.breadCrumb, { params });
  }
}


export interface IBreadCrumb {
  name: string;
  image: string;
  code_groupe: number;
  subcategories: {
    name: string;
    code_ssgroupe: number;
    is_three: boolean;
    subcategories: {
      name: string;
      code_groupe: number;
      code_ssgroupe: number;
      code_repere: number;
      sourceg: string;
    };
  };
}

export interface ICarPartsParams {
  catalog_id: string;
  code_groupe: number;
  code_ssgroupe: number;
  code_repere: number;
}

export interface ICarPartsViewParams {

}

export default PresentationHttp;
