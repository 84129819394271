import React, { useEffect, useState } from 'react';
import useHttp from 'utils/hooks/useHttp';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { getCurrentCountryRMI, getCurrentLanguageRMI } from 'utils/rmi/rmi-utils/rmi-utils';
import i18n from 'i18n';
import { IMaintenanceWorks } from 'utils/rmi/interface/maintenance.interface';
import ServiceCard from './components/ServiceCard/ServiceCard';
import './MaintenanceWorks.scss';


const MaintenanceWorks:React.FC = () => {
  const http = useHttp();
  const {typeId} = useSelector((state: RootState) => state.tecrmi);
  const [data, setData] =useState<IMaintenanceWorks>();
  const [loading, setLoading] = useState(false);

  function fetchMaintenanceWorks(){
    setLoading(true);

    if(typeId){
      http.maintenanceHttp.fetchMaintenanceWorks({
        typeId: Number(typeId),
        countryCode: getCurrentCountryRMI(i18n.language),
        languageCode: getCurrentLanguageRMI(i18n.language),
        kindOfWorkTime: 0
      }).then(res => setData(res.data)).finally(()=>{
        setLoading(false);
      });
    }
  }

  useEffect(()=>{
    fetchMaintenanceWorks();
  },[typeId, i18n.language]);

  return (
    <React.Fragment>
      <ServiceCard title="_main_service_jobs" data={data?.Services} loading={loading} />
      <ServiceCard title="_additional_service_jobs" data={data?.AdditionalWorks} loading={loading} />
    </React.Fragment>
  );
};

export default MaintenanceWorks;