import React from 'react';
import './PresentationBanner.scss';

type Props = {}

const PresentationBanner:React.FC<Props> = () => {
  return (
    <div className="presentation-banner">
      <div className="side-banner"/>
      <div className="presentation-banner-title">
        <img src={require('assets/images/automotor-online.png')}/>
      </div>
      <div className="side-banner" />
    </div>
  );
};

export default PresentationBanner;