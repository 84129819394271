import React from 'react';
import { useTranslation } from 'react-i18next';
import useLogOut from 'utils/hooks/useLogOut';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPowerOff } from '@fortawesome/free-solid-svg-icons';


const c = 'automotor-menu-navbar';

const MenuLogout: React.FC = () => {
  const { t } = useTranslation();
  const logout = useLogOut();

  return (
    <div className={`${c}__item--parent__children__item--title`} onClick={logout}>
      <FontAwesomeIcon icon={faPowerOff} />
      <span>{t('_logout')}</span>
    </div>
  );
};

export default MenuLogout;
