import TextInput from 'components/inputs/TextInput/TextInput';
import React, {FC, useEffect, useRef, useState} from 'react';
import './Tyres.scss';
import i18n from '../../../../../i18n';

type Props = {
  value: string;
  setValue: (inputValue: string) => void;
  withIcon?: boolean;
  searchTyresData: () => void;
  resetTable: () => void;
};

const TyresSearchInput: FC<Props> = (props) => {
  const [clearable, setClearable] = useState<boolean>(false);
  const ref = useRef<HTMLInputElement>(null);

  const clickHandler = () => {
    props.setValue(ref.current!.value);
    props.searchTyresData();
  };

  function resetInput() {
    props.resetTable();
    ref.current!.value = '';
    setClearable(false);
  }

  useEffect(() => {
    if (ref.current!.value === '') {
      resetInput();
    }
  }, [props.value]);

  return (
    <div>
      <TextInput
        placeholder="Search"
        onChange={(e) => {
          props.setValue(e.target.value);
          setClearable(true);
        }}
        onClick={clickHandler}
        onClickReset={resetInput}
        type="text"
        clearable={clearable}
        ref={ref}
        withIcon
      />
    </div>
  );
};

export default TyresSearchInput;
