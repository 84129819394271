import { createSlice } from '@reduxjs/toolkit';
import { fetchCatalogInfo, fetchCatalogData } from 'store/reducers/catalog/catalogs.actions';
import { ICatalogInfo, ICatalogs } from 'models/catalog.model';

type CatalogSlice = {
  currentCatalog: ICatalogInfo | null;
  catalogData: ICatalogs[];
  isLoadingInfo: boolean;
  isLoadingData: boolean;
  language?: string
};

const initialState: CatalogSlice = {
  currentCatalog: null,
  catalogData: [],
  isLoadingInfo: false,
  isLoadingData: false,
};

const catalogSlice = createSlice({
  name: 'catalog',
  initialState,
  reducers: {
    setCatalogInfo(state, { payload }) {
      state.currentCatalog = payload;
    },
    setIsCatalogsLoading(state, { payload }) {
      state.isLoadingInfo = payload;
    },
    setCatalogLangauge(state, {payload} ){
      state.language = payload;
    },
    resetCurrentCatalog(state) {
      state.currentCatalog = null;
    },
    setCurrentCatalog(state, {payload}) {
      state.currentCatalog = payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCatalogInfo.pending, (state) => {
      state.isLoadingInfo = true;
    });
    builder.addCase(fetchCatalogInfo.fulfilled, (state, { payload }) => {
      state.currentCatalog = payload;
      state.isLoadingInfo = false;
    });
   
    builder.addCase(fetchCatalogData.pending, (state) => {
      state.isLoadingData = true;
    });
    builder.addCase(fetchCatalogData.fulfilled, (state, { payload }) => {
      state.catalogData = payload;
      state.isLoadingData = false;
    });
  },
});

export const { setCatalogInfo, setIsCatalogsLoading, setCatalogLangauge, resetCurrentCatalog, setCurrentCatalog } = catalogSlice.actions;

export default catalogSlice.reducer;
