import SkeletonSpinner from 'components/skeleton-spinner/SkeletonSpinner';
import React, { MutableRefObject, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { createSearchParams, generatePath, useNavigate, useParams } from 'react-router-dom';
import { IData } from 'utils/hooks/http/search-by-reference.http';
import { useOnClickOutside } from 'utils/hooks/useOnClickOutside';
import DropdownContentTable from 'components/DropdownContentTable/DropdownContentTable';
import Head from 'components/tables/Cells/Head/Head';
import { Column } from 'react-table';
import './RefDropdownContent.scss';

type Props = {
  refData: IData[] | undefined;
  setIsOpen: (isOpen: boolean) => void;
  loading: boolean;
  refValue?: string;
  inCatalog?: boolean;
};

const RefDropdownContent: React.FC<Props> = (props) => {
  const referenceDropdownRef = useRef() as MutableRefObject<HTMLDivElement>;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams<'id'>();

  useOnClickOutside(referenceDropdownRef, () => props.setIsOpen(false));

  const tableData: Column<IData>[] = [
    {
      Header: <Head text={t('_brand').toLocaleUpperCase()}></Head>,
      Cell: (cell) => cell.cell.row.original.replacement ?
        (<div className="suggestion-item-replacement">
          <span>{props.refValue}</span> {t('_referance_replaced')}
          <div>{cell.cell.row.original.reference}</div>
        </div>) : cell.cell.row.original.reference_commerciale ?
          <div className="suggestion-item-replacement"><div>{cell.cell.row.original.reference}</div> <span className="commercial-ref">{t('_reference_commerciale')}: {cell.cell.row.original.reference_commerciale}</span></div> 
          : <div>{cell.cell.row.original.reference}</div>,
      accessor: 'reference',
    },
    {
      Header: <Head text={t('_reference').toLocaleUpperCase()}></Head>,
      accessor: 'supplier_or_manufacturer',
    },
    {
      Header: <Head text={t('_category').toLocaleUpperCase()}></Head>,
      accessor: 'category_name',
    },
  ];
  

  function handleRefClick(item) {
    let pathName = generatePath('/car-parts');

    if (id && props.inCatalog) {
      pathName = generatePath('/catalog/:id/car-parts', {
        id: String(id),
      });
    }

    const params = {
      page: '1',
      source: item.source,
      subcategory_id: item.subcategory_id,
      man_sup_id: item.man_sup_id,
      q: item.reference,
      type: item?.type,
    };

    const validParams = Object.entries(params).reduce((acc, [key, value]) => {
      if (!value) {
        return acc;
      }

      acc[key] = value;

      return acc;
    }, {});

    navigate({
      pathname: pathName,
      search: `?${createSearchParams(validParams)}`,
    });
  }

  return (
    <div ref={referenceDropdownRef} className="ref-dropdown-content">
      <div className="ref-dropdown-header">
        <div className="ref-dropdown-title">{t('_search_result')}</div>
      </div>
      <div className="ref-search-result-content">
        {props.loading ? (
          <SkeletonSpinner />
        ) : (
          props.refData?.length ? (
            <DropdownContentTable columns={tableData} data={props.refData} loading={props.loading} itemClick={handleRefClick} />
          // props.refData?.map((item, index) => {
          //   return (
          //     <li key={index} className="suggestion-list-item" onClick={() => handleRefClick(item)}>
          //       {item.replacement ? (
          //         <div className="suggestion-item-replacement">
          //           <span>{props.refValue}</span> {t('_referance_replaced')}
          //         </div>
          //       ) : (
          //         ''
          //       )}
          //       <div className="suggestion-item-title">
          //         <span>{item.reference}</span>
          //         <span>{item.supplier_or_manufacturer}</span>
          //         <span>{item.category_name}</span>
          //       </div>
          //     </li>
          //   );
          // })
          ) : (
            <div className="no-data">{t('_no_data').toLocaleUpperCase()}</div>
          )
        )}
      </div>
    </div>
  );
};

RefDropdownContent.displayName = 'RefDropdownContent';

export default RefDropdownContent;
