import React, { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
import { ReactComponent as PartsNotFoundImage } from 'assets/images/parts-not-found.svg';
import Pagination from 'components/pagination/Pagination';
import Spinner from 'components/spinner/Spinner';
import { useTranslation } from 'react-i18next';
import useHttp from 'utils/hooks/useHttp';
import { useSearchParams, useLocation, useParams } from 'react-router-dom';
import UniversalPartsNavbar from 'components/navbar/universal-parts-navbar/UniversalPartsNavbar';
import PartsFilter from './NewPartsFilter/NewPartsFilter';
import ProductsViewMode from '../ProductsViewMode/ProductsViewMode';
import { DEFAULT_VIEW } from 'components/ProductsViewMode/ProductsViewMode.constants';
import { NEW_CATALOG_PART_OEM } from '../ProductsViewModeChanger/products-view-mode-changer-utils';
import ProductsViewModeChanger from '../ProductsViewModeChanger/ProductsViewModeChanger';
import { CAR_PRO, CAR_TECH } from 'utils/rmi/reliableTabs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import PopupInfo from '../PopupInfo/PopupInfo';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { useOnClickOutside } from 'utils/hooks/useOnClickOutside';
import { getCurrentCountryRMI, getCurrentLanguageRMI } from 'utils/rmi/rmi-utils/rmi-utils';
import { IRepairItemsWorkItemMps, IRepairItemsWorkList, IVehicleRepairItemWork } from 'utils/rmi/interface/repair-items.interface';
import SortBy, { SortByParams } from '../SortBy/SortBy';
import { sortByOptions } from '../SortBy/SortBy.map';
import { useAppDispatch } from 'utils/hooks/redux';
import { fetchPartsList, fetchPartsListFilter, fetchPartsListOE } from 'store/reducers/car-parts-catalog/car-parts.actions';
import './NewPartsList.scss';

const NewPartsList: React.FC = () => {
  const { t, i18n } = useTranslation();
  const http = useHttp();
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [loadingOem, setLoadingOem] = useState(false);
  const [filterLoading, setFilterLoading] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [page, setPage] = useState(Number(searchParams.get('page')));
  const { pathname } = useLocation();
  const isVehiclePartCatalog = pathname.includes('part-catalog');
  const { vehicle_id } = useParams<'vehicle_id'>();
  const { typeId } = useSelector((state: RootState) => state.tecrmi);
  const [popupInfoIsOpen, setPopupInfoIsOpen] = useState<boolean>(false);
  const popupInfoRef = useRef() as MutableRefObject<HTMLDivElement>;
  const [popupInfoLoading, setPopupInfoLoading] = useState(false);
  const [selectedWork, setSelectedWork] = useState<IVehicleRepairItemWork>();
  const user = JSON.parse(localStorage.getItem('userData')!);
  const [viewMode, setViewMode] = useState(user?.user_settings.products_view_mode || DEFAULT_VIEW);
  const dispatch = useAppDispatch();
  const { parts, filters, oeInfo } = useSelector((state: RootState) => state.partsList);
  const isFirstRender = useRef(true);
  const prevLanguage = useRef(i18n.language);

  useOnClickOutside(popupInfoRef, () => setPopupInfoIsOpen(false));

  function getData(filterData) {
    if (isVehiclePartCatalog) {
      getPartsByCar(filterData);
    } else {
      getPartsByGenart(filterData);
    }
  }

  function getPartsByCar(filterData?) {
    setLoading(true);

    const sortBySelectedOption = sortByOptions.filter((item) => String(item.value) == String(searchParams.get('sort')))[0] ?? [];

    const sortParams: SortByParams = {
      sort_by_column: String(searchParams.get('sort') ?? ''),
      sort_by_asc: String(searchParams.get('asc') ?? sortBySelectedOption?.default_sort_asc ?? ''),
    };

    const params = {
      ...filterData,
      ...sortParams,
      vehicle_identifier: searchParams.get('vehicle_identifier') || '002',
      code_groupe: searchParams.get('code_groupe'),
      code_ssgroupe: searchParams.get('code_ssgroupe'),
      code_repere: searchParams.get('code_repere'),
      page: filterData.page || page,
      per_page: 20,
    };

    const validParams = validateParams(params);

    dispatch(fetchPartsList({ vehicle_id: vehicle_id!, data: validParams })).finally(() => setLoading(false));
  }

  function getPartsByGenart(filterData) {
    setLoading(true);
    const params = {
      ...filterData,
      vehicle_identifier: searchParams.get('vehicle_identifier') || '002',
      genartnrs: searchParams.getAll('genartnrs[]'),
      page: filterData.page || page,
    };

    const validParams = validateParams(params);

    dispatch(fetchPartsList({ vehicle_id: vehicle_id!, data: validParams })).finally(() => setLoading(false));
  }

  function getFilters(data) {
    setFilterLoading(true);
    const params = {
      ...data,
      vehicle_identifier: searchParams.get('vehicle_identifier') || '002',
      code_groupe: searchParams.get('code_groupe'),
      code_ssgroupe: searchParams.get('code_ssgroupe'),
      code_repere: searchParams.get('code_repere'),
      genartnrs: searchParams.getAll('genartnrs[]'),
    };

    const validParams = validateParams(params);

    dispatch(fetchPartsListFilter({ vehicle_id: vehicle_id!, data: validParams }))
      .finally(() => {
        setFilterLoading(false);
      });
  }

  function getOEInfo(data) {
    setLoadingOem(true);
    const params = {
      ...data,
      vehicle_identifier: searchParams.get('vehicle_identifier') || '002',
      code_groupe: searchParams.get('code_groupe'),
      code_ssgroupe: searchParams.get('code_ssgroupe'),
      code_repere: searchParams.get('code_repere'),
      ...(searchParams.getAll('genartnrs[]') && { genartnrs: searchParams.getAll('genartnrs[]') }),
    };

    const validParams = validateParams(params);

    dispatch(fetchPartsListOE({ vehicle_id: vehicle_id!, data: validParams }))
      .finally(() => {
        setLoadingOem(false);
      });
  }

  const validateParams = (data) => {
    return Object.entries(data).reduce((acc, [key, value]) => {
      if (!value) {
        return acc;
      }

      acc[key] = value;

      return acc;
    }, {});
  };

  const handlePageClick = (pageN: number) => {
    setPage(pageN);
  };

  function handleOpenPopup() {
    setPopupInfoIsOpen(true);
  }

  const getWorkTimeInfo = async () => {
    setPopupInfoLoading(true);

    if (parts?.data.length) {
      const genArtNr = parts?.data[0]?.genartnr;


      let vehicleRepairItems: IRepairItemsWorkList[] = [];

      if (typeId) {
        const data = await http.repairItemsHttp.fetchWorkListList({
          typeId: typeId,
          countryCode: getCurrentCountryRMI(i18n.language),
          languageCode: getCurrentLanguageRMI(i18n.language),
        });

        vehicleRepairItems = data.data;
      }


      if (vehicleRepairItems.length > 0) {
        const { data: itemIds } = await http.pricesHttp.fetchRMIItemMpIdForGenArt({ genArtNo: genArtNr ?? null });
        const vehicleItems: IRepairItemsWorkItemMps[] = [];

        vehicleRepairItems.forEach((group) => {
          group.SubGroups.forEach((subGroup) => {
            subGroup.ItemMps.forEach((item) => {
              vehicleItems.push(item);
            });
          });
        });

        const itemMps = vehicleItems.filter(
          (v) =>
            v.ItemMpId ===
            (() => {
              return itemIds.find((itemId) => vehicleItems.find((v) => v.ItemMpId === itemId) !== undefined);
            })(),
        );

        if (itemMps.length > 0) {
          const workDifficulty = await http.carParts.getWorkDifficulty({
            genArtNr,
            itemMpId: itemMps[0]?.ItemMpId,
          });

          const { data: vehicleRepairItem } = await http.repairItemsHttp.fetchWorkStepsList({
            typeId,
            itemMpId: itemMps[0].ItemMpId,
            korId: itemMps[0].KorId,
            countryCode: getCurrentCountryRMI(i18n.language),
            languageCode: getCurrentLanguageRMI(i18n.language),
          });

          setSelectedWork({ ...vehicleRepairItem[0], ...workDifficulty });
        }
      }
    }

    setPopupInfoLoading(false);
  };

  useEffect(() => {
    if (vehicle_id) {
      getWorkTimeInfo();
    }
  }, [parts, vehicle_id]);

  const fetchData = useCallback(async (sort?) => {
    setLoading(true);

    const sortBySelectedOption = sortByOptions.find(
      (item) => String(item.value) === String(searchParams.get('sort'))
    );

    const sortParams: SortByParams = {
      sort_by_column: String(sort || searchParams.get('sort')),
      sort_by_asc: String(searchParams.get('asc') || sortBySelectedOption?.default_sort_asc || ''),
    };

    const params = {
      ...sortParams,
      vehicle_identifier: searchParams.get('vehicle_identifier') || '002',
      code_groupe: searchParams.get('code_groupe'),
      code_ssgroupe: searchParams.get('code_ssgroupe'),
      code_repere: searchParams.get('code_repere'),
      page: Number(searchParams.get('page')) || page,
      ...(searchParams.getAll('genartnrs[]') && { genartnrs: searchParams.getAll('genartnrs[]') }),
      per_page: 20,
    };


    const validParams = validateParams(params);

    dispatch(fetchPartsListFilter({ vehicle_id: vehicle_id!, data: validParams }));
    dispatch(fetchPartsListOE({ vehicle_id: vehicle_id!, data: validParams }));

    try {
      await dispatch(fetchPartsList({ vehicle_id: vehicle_id!, data: validParams }));
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      setLoadingOem(false);
    }

  }, [i18n.language, page, searchParams.get('sort'), searchParams.get('asc')]);

  useEffect(() => {
    if (!parts) {
      fetchData();
    } else {
      setLoading(false);
    }

  }, [parts]);

  useEffect(() => {
    isFirstRender.current = false;

    if (prevLanguage.current !== i18n.language && !isFirstRender.current && parts) {
      fetchData();
      prevLanguage.current = i18n.language;
    }

    if (page && !isFirstRender.current && parts) {
      fetchData();
    }
  }, [i18n.language, page]);

  function filterOptionsElement() {
    return (
      <div className="catalog-parts-filter">
        <button onClick={() => setOpenFilter(true)} className={`catalog-parts-button ${openFilter ? 'active' : ''}`}>
          {t('_filter_results_btn')}
        </button>
      </div>
    );
  }

  return (
    <div className={`new-wrapper ${isVehiclePartCatalog ? 'new-part-catalog-wrapper' : ''}`}>
      <UniversalPartsNavbar option={filterOptionsElement()} title="_find_neccessary_parts" />
      <div className="filterOptions">
        {isVehiclePartCatalog && parts?.data && parts?.data?.length > 0 ? <SortBy fetchData={fetchData} /> : null}
        {!loading &&
          parts?.data &&
          parts?.data?.length > 0 &&
          typeId &&
          vehicle_id &&
          user && [CAR_PRO, CAR_TECH.includes(user?.client_settings?.rmi_modules_type)] ? (
            <div ref={popupInfoRef} className="work-time" onClick={handleOpenPopup}>
              {t('_work_time')}
              <FontAwesomeIcon icon={faClock} className="clock-icon" />
              {popupInfoIsOpen && <PopupInfo loading={popupInfoLoading} data={selectedWork} />}
            </div>
          ) : null}
        <div className="filter-body-part">
          {parts?.data?.length ? (
            <ProductsViewMode viewMode={viewMode} setViewMode={setViewMode} />
          ) : null}
        </div>
      </div>

      {loading || loadingOem ? (
        <Spinner class="car-parts-spinner" />
      ) : parts?.data?.length && oeInfo ? (
        <ProductsViewModeChanger
          catalog={NEW_CATALOG_PART_OEM}
          viewMode={viewMode}
          data={parts.data}
          dataOE={oeInfo}
          workTimeInfo={selectedWork}
        />
      ) : (
        <div className="no-parts-data">
          <div className="notFoundSvg">
            <PartsNotFoundImage />
          </div>
          <h1>{t('_parts_not_found')}</h1>
        </div>
      )}

      {parts && parts?.total_pages && parts?.page && parts?.total_pages > 1 ? (
        <Pagination pageCount={parts.total_pages} handlePageClick={handlePageClick} forcePage={parts.page} />
      ) : (
        ''
      )}
      <PartsFilter
        getData={getData}
        filterData={filters}
        getFilters={getFilters}
        getOEInfo={getOEInfo}
        openFilter={openFilter}
        setOpenFilter={setOpenFilter}
        loading={filterLoading}
      />
    </div>
  );
};

export default NewPartsList;
