import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Trans, useTranslation } from 'react-i18next';
import IconItem from 'components/Item/IconItem/IconItem';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import UploadedMessageFile from 'components/message/uploaded-message-file/UploadedMessageFile';
import fileUpload from 'assets/Icons/file-upload.svg';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import { notify } from 'utils/marketplace';
import { Controller, useForm } from 'react-hook-form';
import useHttp from 'utils/hooks/useHttp';
import { IOption } from 'interfaces/select';
import Select from 'react-select';
import { catalogTypeOptions, nonTecdocTypeOptions } from '../catalog/Catalog.map';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import './ImportCatalog.scss';
import { RootState } from 'store/store';
import { useSelector } from 'react-redux';
import ProgressBar from '@ramonak/react-progress-bar';
import axios from 'axios';
import { isEmpty } from 'lodash';
import { ICatalogItem } from 'interfaces/selectClient';
import ImportCatalogPreview, { IImportCatalog } from './ImportCatalogPreview';

type Props = {
  setOpenModal: (e: boolean) => void;
};

const ImportCatalog: React.FC<Props> = (props: PropsWithChildren<Props>) => {
  const { t } = useTranslation();
  const { selectClient, publicApp } = useHttp();
  const [files, setFiles] = useState<File[] | []>([]);
  const [loading, setLoading] = useState(false);
  const [previewCatalog, setPreviewCatalog] = useState(false);
  const [previewCatalogData, setPreviewCatalogData] = useState<ICatalogItem[]>([]);
  const { isMobile } = useDeviceDetect();
  const { user } = useSelector((state: RootState) => state.userData);
  const [redisInfo, setRedisInfo] = useState<{ total_number: number; current_number: number; progress: number }>();
  const [keyUpload, setkeyUpload] = useState<string>('');
  const [progressBarlLoading, setProgressBarLoading] = useState<boolean>(false);
  const [errorResponse, setErrorResponse] = useState<boolean>(true);
  const [countBarlLoading, setCountBarlLoading] = useState<number>(0);
  const [success, setSuccess] = useState(false);
  const cancelToken = axios.CancelToken.source();

  const {
    getValues,
    handleSubmit,
    control,
    setValue,
    setError,
    watch,
    clearErrors,
    register,
    formState: { errors },
  } = useForm<IImportCatalog>();

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    const fileExtension = file.name.split('.').pop()?.toLowerCase();

    if (fileExtension === 'csv') {
      setFiles([file]);
      setValue('files', file);
    } else {
      setError('files', {
        type: 'filetype',
        message: 'Only CSV files are valid.',
      });
    }
  }, [files]);


  useEffect(() => {
    if (files.length) {
      setRedisInfo(undefined);
    }
  }, [files]);

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    onDrop,
    multiple: false,
  });

  const removeFile = (file: File) => {
    const newFiles = [...files];

    newFiles.splice(newFiles.indexOf(file), 1);
    setFiles(newFiles);
    clearErrors('files');
    setSuccess(false);

    if (previewCatalog) {
      setPreviewCatalogData([]);
      setProgressBarLoading(false);
    }
  };

  const progresUpload = async (keyUpload: string) => {
    await publicApp.progresUpload(keyUpload, cancelToken.token).then((res) => {
      setRedisInfo(res.data);
    });

  };

  useEffect(() => {


    if (!errorResponse && keyUpload == '') {
      notify('SUCCESS', 'success');

    }
  }, [errorResponse, keyUpload]);


  useEffect(() => {
    if (keyUpload != '') {

      if (redisInfo && redisInfo.progress == 1) {
        setkeyUpload('');
        // setProgressBarLoading(false);
        setCountBarlLoading(0);
        setSuccess(true);


      } else {
        if (countBarlLoading < 500) {
          progresUpload(keyUpload);
          setCountBarlLoading(countBarlLoading + 1);
        }
      }
    } else {
      setCountBarlLoading(0);
    }
  }, [keyUpload, redisInfo]);


  // useEffect(() => {
  //   if (success && countBarlLoading > 20) {
  //     console.log(redisInfo);

  //     if (redisInfo && Math.round(((redisInfo?.current_number / redisInfo?.total_number) * 100)) < 100) {
  //       setCountBarlLoading(501);
  //     }

  //   }
  // }, [success, countBarlLoading]);

  const onSubmit = async () => {
    const values = getValues();
    const catalogType = values.catalog_type.value;
    const key_upload = Math.random().toString(36).slice(2, 7);

    const formData: FormData = new FormData();

    if (files.length) {
      formData.append('file', files[0]);
    } else {
      setError('files', {
        type: 'filetype',
        message: 'File is required.',
      });

      return;
    }

    formData.append('key_upload', key_upload);

    setkeyUpload(key_upload);
    setRedisInfo(undefined);
    setLoading(true);

    setProgressBarLoading(true);

    switch (catalogType) {
      case 'oil':

        selectClient.importCatalogOil(formData)
          .then(() => {
            setErrorResponse(false);
          }).catch(() => {
            notify('CONTENT_IS_NOT_VALID');
            setkeyUpload('');
            setErrorResponse(true);
          })
          .finally(() => {
            setLoading(false);
          });

        break;
      case 'consumable':

        selectClient.importCatalogConsumable(formData)
          .then(() => {
            setErrorResponse(false);
          })
          .catch(() => {
            notify('CONTENT_IS_NOT_VALID');
            setErrorResponse(true);
            setkeyUpload('');
          })
          .finally(() => {
            setLoading(false);

          });

        break;
      case 'tyre':

        selectClient.importCatalogTyre(formData)
          .then(() => {
            setErrorResponse(false);
          })
          .catch(() => {
            notify('CONTENT_IS_NOT_VALID');

            setkeyUpload('');
            setErrorResponse(true);
          })
          .finally(() => {
            setLoading(false);

          });

        break;
      case 'battery':

        selectClient.importCatalogBattery(formData)
          .then(() => {
            setErrorResponse(false);
          })
          .catch(() => {
            notify('CONTENT_IS_NOT_VALID');
            setErrorResponse(true);
            setkeyUpload('');
          })
          .finally(() => {
            setLoading(false);
          });

        break;
      case 'non_tec_doc_catalog':
        if (isEmpty(previewCatalogData)) {
          selectClient.importPreviewCatalogNonTecdoc(formData)
            .then((res) => {
              setPreviewCatalogData(res.data);
              setkeyUpload('');
              setCountBarlLoading(0);
              setErrorResponse(false);
              // if (redisInfo && Math.round(((redisInfo?.current_number / redisInfo?.total_number) * 100)) < 100) {
              //   setCountBarlLoading(501);
              // }

              // notify('SUCCESS', 'success');
            }).catch(() => {
              notify('CONTENT_IS_NOT_VALID');
              setErrorResponse(true);
              setkeyUpload('');
            })
            .finally(() => {
              setLoading(false);
              setTimeout(() => {
                setProgressBarLoading(false);
              }, 3000);
            });

        } else {
          formData.append('catalog_non_tecdoc', JSON.stringify(values.catalog_non_tecdoc));

          selectClient.importCatalogNonTecdoc(formData)
            .then(() => {
              setErrorResponse(false);

            }).catch(() => {
              notify('CONTENT_IS_NOT_VALID');
              setErrorResponse(true);
              setkeyUpload('');
            })
            .finally(() => {
              setLoading(false);

            });
        }

        break;
    }


  };

  const getCatalogOptions = () => {
    if (user) {
      return (
        catalogTypeOptions(user)?.map((item) => {
          return {
            label: t(String(item.label)),
            value: item.value,
            available: item.available
          };
        }
        ) || []
      );
    }
  };

  const getNonTecdocOptions = () => {
    if (user) {
      return (
        nonTecdocTypeOptions(user)?.map((item) => {
          return {
            label: t(String(item.label)),
            value: item.value,
            available: item.available
          };
        }
        ) || []
      );
    }
  };

  const filterCatalogOptions = () => {
    return getCatalogOptions()?.filter((option: IOption) => {
      if (option.available) {
        return {
          label: option.label,
          value: option.value,
        };
      }
    });
  };

  const filterNonTecdocOptions = () => {
    return getNonTecdocOptions()?.filter((option: IOption) => {
      if (option.available) {
        return {
          label: option.label,
          value: option.value,
        };
      }
    });
  };

  const groupedOptions = [
    {
      label: 'CATALOG',
      options: filterCatalogOptions() as IOption[]
    },
    {
      label: 'NON-TECDOC',
      options: filterNonTecdocOptions() as IOption[]
    },
  ];

  useEffect(() => {
    if (files.length && errors.files && files[0].type === 'text/csv') {
      clearErrors('files');
    }
  }, [files, errors]);

  return (
    <div className={`modal-new-tariff ${isMobile ? 'mobile' : ''}`}>
      <div className="close" onClick={() => props.setOpenModal(false)}>
        <IconItem icon={faClose} onClick={() => null} />
      </div>
      <div className="modal-dialog">
        <div className="modal-container">
          <div className="modal-body">
            <form className="modal-form" onSubmit={handleSubmit(onSubmit)}>
              <div className="title">{t('_import_non_catalog_file')}</div>
              <div className="import-modal-container">
                <div className="import-upload import-content">
                  <label>{t('_list_type')}</label>
                  <Controller
                    control={control}
                    name="catalog_type"
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        className={`select-catalog-type ${errors.catalog_type?.type == 'required' ? 'error' : ''}`}
                        value={value}
                        onChange={(val) => {
                          onChange(val);
                          setPreviewCatalog(watch()?.catalog_type?.value == 'non_tec_doc_catalog');
                          setProgressBarLoading(false);
                          removeFile(files[0]);
                          setSuccess(false);
                        }}
                        options={groupedOptions}
                        placeholder={t('_select')}
                        styles={{
                          menu: provided => ({ ...provided, zIndex: 9999 })
                        }}
                      />
                    )}
                  />
                  {watch()?.catalog_type?.value &&
                    <div className="download-catalog-template">
                      <a onClick={(e) => e.stopPropagation()} href={`/csv-template/catalogs/${watch()?.catalog_type?.value}_template.csv`} download>
                        <Trans
                          i18nKey="_download_catalog_template"
                          values={{ catalogName: t('_' + watch().catalog_type.value) }}
                        />
                      </a>
                    </div>
                  }
                </div>
                {previewCatalog &&
                  <ImportCatalogPreview
                    previewCatalog={previewCatalog}
                    setPreviewCatalog={setPreviewCatalog}
                    previewCatalogData={previewCatalogData}
                    setPreviewCatalogData={setPreviewCatalogData}
                    setValue={setValue}
                    getValues={getValues}
                    register={register}
                    control={control}
                    errors={errors}
                    watch={watch}
                  />
                }
                <div className="import-upload import-content">
                  <div {...getRootProps({ className: `${errors.files ? 'content errors' : 'content'}` })}>
                    <input {...getInputProps()} />

                    <img src={fileUpload} alt="file-upload tariff-content" />
                    <p>{t('_import')}</p>
                  </div>
                  {errors.files && <div className="file-error">{errors.files.message}</div>}

                </div>
                {progressBarlLoading ?
                  <div className="import-progressBar import-content">
                    {
                      redisInfo ? <div className="import-progressBar-title">
                        <span>{`${t('_total_number')} : ${redisInfo?.total_number} `}</span>
                        <span>{`${t('_progress_number')} : ${redisInfo?.current_number} `}</span>
                      </div> : null
                    }

                    <ProgressBar completed={countBarlLoading > 500 ? 100 : redisInfo?.current_number ? Math.round(((redisInfo?.current_number / redisInfo?.total_number) * 100)) : 0} maxCompleted={100} bgColor="#355099" labelSize="13px" height="13px"></ProgressBar>
                  </div> : null
                }
                {files.length ? (
                  <div className="import-files import-content">
                    {files.map((file, fileIndex) => {
                      return <UploadedMessageFile key={fileIndex} messageFile={file} removeFile={() => removeFile(file)} />;
                    })}
                  </div>
                ) : null}
                <div className="import-submit import-content">
                  {success ? <DefaultButton text={t('_close')} onClick={() => props.setOpenModal(false)} /> : <DefaultButton isloading={loading} text={t('_update_information')} type="submit" />}

                </div>
                {fileRejections.length > 0 ? <div className="modal-message-dropzone__error">{t('FILE_RESTRICT')}</div> : ''}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

ImportCatalog.displayName = 'ImportCatalog';

export default ImportCatalog;
