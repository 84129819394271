import React, { useEffect, useRef, useState } from 'react';

import './CartSlidingV2.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faCartShopping } from '@fortawesome/free-solid-svg-icons';
import { RootState } from 'store/store';
import { useSelector } from 'react-redux';

import useHttp from 'utils/hooks/useHttp';
import { GetCartResponse } from 'models/cart.model';
import { getMarketplaceBaseUrl, notify } from 'utils/marketplace';
import { CartTableItem } from 'applications/retailer/views/Cart/CartTable/CartTable.model';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Spinner from 'components/spinner/Spinner';
import { useAppDispatch } from 'utils/hooks/redux';
import { setCartMecadepot, setCartMecadepotQuantityToolbar, setCartMecadepotTotalPrice } from 'store/slices/cart-mecadepot.slice';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import { boolean } from 'yup';


type Props = {
  isActive: boolean;
};

const CartSlidingV2: React.FC<Props> = (props) => {

  const { quantityToolbar } = useSelector((state: RootState) => state.cart);
  const { quantityToolbarMecadepot, cart, totalPriceMecadepot } = useSelector((state: RootState) => state.cartMecadepotSlice);
  const { addToCartLoading } = useSelector((state: RootState) => state.cart);
  const [dataState, setDataState] = useState<CartTableItem[]>([]);
  const [loadingData, setLoadingData] = useState(true);
  const [totalPartsPrice, setTotalPartsPrice] = useState<number>(0);
  const [totalQuantity, setTotalQuantity] = useState<number>(0);
  const dispatch = useAppDispatch();
  const http = useHttp();
  const { t } = useTranslation();
  const navigate = useNavigate();


  const handleCartButtonClick = () => {
    navigate('/cart');
  };


  useEffect(() => {
    if (quantityToolbar != quantityToolbarMecadepot && !addToCartLoading) {
      fetchData();
    } else {
      setLoadingData(false);
      setTotalPartsPrice(totalPriceMecadepot);
      setTotalQuantity(quantityToolbarMecadepot);
      setDataState(cart);
    }
  }, [quantityToolbar, addToCartLoading]);


  const fetchData = async (page = 1) => {
    try {
      setLoadingData(true);
      const response = await http.cart.getCartData(page, 1000).finally(() => {
        setLoadingData(false);
      });

      const data = response.data.map((item: GetCartResponse): CartTableItem => {
        const images = item.images?.filter(image => {
          if(!image) {return false;}

          const lastDotIndex = image.lastIndexOf('.');
          const extension = image.substring(lastDotIndex + 1).toLocaleLowerCase();

          if (extension === 'jpg' || extension === 'jpeg' || extension === 'png' || extension === 'bmp') {
            return extension;
          }
        });

        const workTimeDescription: string = (item?.work_description ? `<span class="work-time-description">${item?.work_description?.data_supplier_name} ${item?.work_description?.data_supplier_reference}</span>` : '');

        return {
          id: item.cart_id,
          available: item.available,
          image: (images ? images[0] : (item?.image ? item?.image : item?.data_supplier_logo)),
          barCode: { image: `${getMarketplaceBaseUrl()}/${item?.barcode?.image}`, code: item?.barcode?.code },
          categories: item?.category ? item?.category : (item?.subcategory ? item?.subcategory.name : ''),
          quantity_availability: { quantity: item.cart_quantity || 0, availability: typeof (item.available) === 'number' ? item.available : item.available.quantity },
          price: item?.price?.price || 0,
          priceVAT: String(item?.price?.price_with_vat),
          work_id: item?.work_id,
          reference: `${item.data_supplier_reference} ${workTimeDescription}`,
          cart_quantity: item.cart_quantity
        };
      });

      setTotalPartsPrice(response.total_price ? Number(response.total_price) : 0);
      setTotalQuantity(response.total_quantity ? Number(response.total_quantity) : 0);
      setDataState!(() => data);
      dispatch(setCartMecadepot(data));
      dispatch(setCartMecadepotQuantityToolbar(response.total_quantity ? Number(response.total_quantity) : 0));
      dispatch(setCartMecadepotTotalPrice(response.total_price ? Number(response.total_price) : 0));

    } catch (error) {
      console.log(error);
      setLoadingData(false);
      notify('Error');
      setDataState!(() => []);
    }
  };

  return (
    <div className={`cart-sliding-v2  ${props.isActive ? 'show-sliding' : 'hidden-sliding'}`}>

      <div className={`cart-sliding-body  ${props.isActive ? 'show-sliding-body' : 'hidden-sliding-body'} ${loadingData || !dataState.length ? 'fixed-position' : ''}`}>

        <div className="cart-sliding-container">
          {loadingData ?
            <Spinner class="small" />
            : dataState.length ? (
              <React.Fragment>
                <div className="cart-title">
                  <p >{t('_your_shopping_carts')}</p>

                  {/* <div className="btn">
                    <DefaultButton text={<React.Fragment><FontAwesomeIcon icon={faCartShopping} className="cart-icon" /> {t('_go_to_cart')}</React.Fragment>} onClick={() => handleCartButtonClick()} />
                  </div> */}
                </div>

                <div className="cart-content">
                  <div className="table">
                    <table>
                      <thead>
                        <tr>
                          <th></th>
                          <th> {t('_reference')}</th>
                          {/* <th> {t('_category')}</th> */}
                          <th> {t('_stock_quantity')}</th>
                          <th style={{ width: '8rem' }}> {t('_price')}</th>
                        </tr>
                      </thead>
                      <tbody>

                        {dataState.map((item, index) => {
                          return (
                            <tr key={item.reference + index} >
                              <td><img src={item.image} alt="" className="img" /></td>
                              <td dangerouslySetInnerHTML={{ __html: String(item.reference) }}></td>
                              {/* <td> {item.categories}  </td> */}
                              <td> {item.cart_quantity}</td>
                              <td>	 {item.price} €</td>
                            </tr>);
                        })
                        }

                      </tbody>

                    </table>
                  </div>
                  <div className="tfooter">


                    <div>{t('_total')} : {totalQuantity}</div>
                    <div> {Number(totalPartsPrice).toFixed(2)} €</div>

                  </div>
                  <div className="btn">
                    <DefaultButton text={<React.Fragment><FontAwesomeIcon icon={faCartShopping} className="cart-icon" /> {t('_go_to_cart')}</React.Fragment>} onClick={() => handleCartButtonClick()} />
                  </div>
                </div>
              </React.Fragment>
            ) : (
              <div className="empty_cart">
                <div className="empty_cart_image">
                  <img src={require('assets/images/empty_cart.png')} alt="empty_cart" />
                </div>
                <div className="empty_cart_title">
                  {t('_your_shopping_carts_empty')}
                </div>
              </div>
            )
          }
        </div>
      </div>
    </div >
  );
};

CartSlidingV2.displayName = 'CartSlidingV2';

export default CartSlidingV2;
