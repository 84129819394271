import React, { useEffect, useState } from 'react';
import HistoryView from './History.view';

import Tab from 'components/Tab/Tab';
import TabCard from 'components/TabCard/TabCard';

import './History.scss';
import { IDataHistoryCars, IDataHistoryParts, IHistory, IPagination } from '../../models/IHistory';
import useHttp from 'utils/hooks/useHttp';
import HistoryHttp, { IHistoryGet } from 'utils/hooks/http/history.http';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';

type Props = {
  noChangeUrl?: boolean
  partView?: boolean
  hideSearch?: boolean
};

type Tab = { id: HistoryTabs; title: string; component: JSX.Element };

enum HistoryTabs {
  Vehicles = 'vehicles',
  DetachedPieces = 'pieces',
}
const c = 'history-page';

const renderHistoryCardModelDescription = (card: IDataHistoryCars) => {
  if (card.car_variant?.vin) {
    return card.car_variant?.vin;
  }

  if (card.car_variant.immat) {
    return card.car_variant.immat;
  }

  return '-';
};

const updateTabs = (dataHistoryCars: IDataHistoryCars[], dataHistoryParts: IDataHistoryParts[], t): Tab[] => {
  return [
    {
      id: HistoryTabs.Vehicles,
      title: '_vehicles',
      component: (
        <div className={`${c}__wrapper-tabs`}>
          {dataHistoryCars.map((card: IDataHistoryCars, index) => {
            return (
              <TabCard
                type="vehicles"
                key={`${index}car`}
                img={card.car_variant?.image}
                card={card}
                date={card.updated_at ?? ''}
                title={`${card.car_variant?.manufacturer_name ?? ''} ${card.car_variant?.model_name ?? ''} ${card.car_variant?.capacity_qualifier ?? ''}`}
                description={`${card.car_variant?.capacity_qualifier ?? ''} ${card.car_variant?.model_name ?? ''} ${card.car_variant?.engine_capacity ?? ''} ${card.car_variant?.power_hp_from ?? ''} ${card.car_variant?.power_hp_to ?? ''}`}
                model={t('_vin_plate')}
                modelDescription={renderHistoryCardModelDescription(card)}
              ></TabCard>
            );
          })}
        </div>
      ),
    },
    {
      id: HistoryTabs.DetachedPieces,
      title: '_pieces',
      component: (
        <div className={`${c}__wrapper-tabs`}>
          {dataHistoryParts.map((card: IDataHistoryParts, index) => {
            return (
              <TabCard
                type="pieces"
                card={card}
                key={`${index}part`}
                img={card.image}
                date={card.updated_at}
                title={card.name ?? ''}
                description={`${card.category_name ?? ''}`}
                model={t('_part_reference')}
                modelDescription={card.reference ?? ''}
              ></TabCard>
            );
          })}
        </div>
      ),
    },
  ];
};

const History: React.FC<Props> = (props) => {
  const { register, getValues, watch, reset } = useForm();
  const [searchParams, setSearchParams] = useSearchParams();
  const [partsPagination, setPartsPagination] = useState<IPagination | null>(null);
  const [carsPagination, setCarsPagination] = useState<IPagination | null>(null);
  const [historyData, setHistoryData] = useState<IHistory | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [tabs, setTabs] = useState<Tab[] | []>([]);
  const http = useHttp();
  const cancelToken = axios.CancelToken.source();
  const [isRendered, setIsRendered] = useState(false);

  const { t, i18n } = useTranslation();

  const validateParams = (data) => {
    return Object.entries(data).reduce((acc, [key, value]) => {
      if (!value) {
        return acc;
      }

      acc[key] = value;

      return acc;
    }, {});
  };


  const handleHistoryRequest = async (urlParams: IHistoryGet) => {
    setIsLoading(true);

    try {
      const historyResponse = await http.history.getHistory(urlParams, cancelToken.token);

      setHistoryData(historyResponse.data);

      const tabsUpdated = updateTabs(historyResponse.data.cars.data, historyResponse.data.parts.data, t);

      setTabs(tabsUpdated);

      setPartsPagination({
        page: historyResponse.data.parts.page,
        per_page: historyResponse.data.parts.per_page,
        total_count: historyResponse.data.parts.total_count,
        total_pages: historyResponse.data.parts?.total_pages,
      });
      setCarsPagination({
        page: historyResponse.data.cars.page,
        per_page: historyResponse.data.cars.per_page,
        total_count: historyResponse.data.cars.total_count,
        total_pages: historyResponse.data.cars?.total_pages,
      });


      setIsLoading(false);
    } catch (e) {
      // setIsLoading(false);
    }
  };

  const handlePartsPagination = (page: number) => {
    if (partsPagination) {
      const partsPaginationCopy = partsPagination;

      partsPaginationCopy.page = page ?? 1;
      setPartsPagination(partsPaginationCopy);
    }

    const params = {
      page_parts: page ?? 1,
      page_cars: carsPagination?.page ?? 1,
      per_page: HistoryHttp.PER_PAGE,
      q_cars: watch('q_cars'),
      q_parts: watch('q_parts')
    };

    const validParams = validateParams(params);

    handleHistoryRequest(validParams);
  };

  const handleCarsPagination = (page: number) => {
    if (carsPagination) {
      const carsPaginationCopy = carsPagination;

      carsPaginationCopy.page = page ?? 1;
      setCarsPagination(carsPaginationCopy);
    }

    const params = {
      page_parts: partsPagination?.page ?? 1,
      page_cars: page ?? 1,
      per_page: HistoryHttp.PER_PAGE,
      q_cars: watch('q_cars'),
      q_parts: watch('q_parts')
    };

    const validParams = validateParams(params);

    handleHistoryRequest(validParams);
  };

  useEffect(() => {
    setIsRendered(true);
  }, []);

  useEffect(() => {
    const params = {
      page_parts: 1,
      page_cars: 1,
      per_page: HistoryHttp.PER_PAGE,
      q_cars: getValues('q_cars'),
      q_parts: getValues('q_parts')
    };

    const validParams = validateParams(params);

    if (isRendered) {
      handleHistoryRequest(validParams);
    }

    return () => {
      cancelToken.cancel();
    };

  }, [i18n.language, getValues('q_cars'), getValues('q_parts'), isRendered]);

  useEffect(() => {
    if (watch('q_cars') && !props.partView) {
      searchParams.set('page', '1');
      setSearchParams(searchParams);
    }


    if (watch('q_parts') && !props.partView) {
      searchParams.set('page', '1');
      setSearchParams(searchParams);
    }
  }, [watch('q_cars'), watch('q_parts')]);

  return (
    <HistoryView
      register={register}
      reset={reset}
      isLoading={isLoading}
      historyData={historyData}
      handleCarsPagination={handleCarsPagination}
      handlePartsPagination={handlePartsPagination}
      carsPagination={carsPagination}
      partsPagination={partsPagination}
      tabs={tabs}
      noChangeUrl={props.noChangeUrl}
      partView={props.partView}
      hideSearch={props.hideSearch}
    />
  );
};

History.displayName = 'History';

export default History;
