import { createSlice } from '@reduxjs/toolkit';
import {countNewMessages} from '../actions/messenger.actions';

type IMessengerState = {
  newMessagesNumber: number;
  newMessagesLoading: boolean;
};

const initialState: IMessengerState = {
  newMessagesNumber: 0,
  newMessagesLoading: false
};

const messengerSlice = createSlice({
  name: 'messenger',
  initialState,
  reducers: {
    setNewMessagesNumber(state, {payload}) {
      state.newMessagesNumber = payload;
    },
    setNewMessagesLoading(state, {payload}) {
      state.newMessagesLoading = payload;
    },
    resetNewMessages(state) {
      state.newMessagesNumber = 0;
      state.newMessagesLoading = false;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(countNewMessages.pending, (state) => {
      state.newMessagesLoading = true;
    });
    builder.addCase(countNewMessages.fulfilled, (state, { payload }) => {
      state.newMessagesNumber = payload;
      state.newMessagesLoading = false;
    });
  }
});

export const { setNewMessagesNumber, setNewMessagesLoading, resetNewMessages} = messengerSlice.actions;

export default messengerSlice.reducer;
