import React, { useState } from 'react';
import OrderInfo from './OrderInfo/OrderInfo';
import OrderTable from './OrderTable/OrderTable';
import './OrderClientsView.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { faCircleArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import PlatformAdminOrderMessages
  from '../../components/messages/PlatformAdminOrderMessages/PlatformAdminOrderMessages';
import { MessagesContextProvider } from '../../context/MessagesContext';

type Props = {}

const OrderClientsView: React.FC<Props> = () => {
  const { order_id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [clientName, setClientName] = useState<string>();
  const [fullUserName, setFullUserName] = useState<string>();
  const [clientId, setClientId] = useState<number>();

  return (
    <div className="order-clients-view-page">

      <div className="order-clients-title-view">
        <div className="order-clients-back" onClick={() => navigate('/espace-clients/orders-clients')}>
          <FontAwesomeIcon icon={faCircleArrowLeft} className="icon" />
          {t('_return_order')}
        </div>
        <div className="order-clients-title">
          {t('_order')}
          <span style={{ fontWeight: 'bold', marginLeft: '0.5rem' }} > N° {order_id}</span>
        </div>


      </div>
      <hr />
      <OrderInfo setClientName={setClientName} setClientId={setClientId} setFullUSerName={setFullUserName} />
      <div className="order-view-body">
        <div className="order-table">
          <OrderTable />
        </div>
        <div className="chat">
          <MessagesContextProvider>
            <PlatformAdminOrderMessages clientId={clientId} clientName={clientName} fullUserName={fullUserName} />
          </MessagesContextProvider>
        </div>
      </div>
    </div>
  );
};

export default OrderClientsView;