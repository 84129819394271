import { IClientInfo, ISupplierMapping, ITecomMapping, IUpdateForm } from 'interfaces/selectClient';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import useHttp from 'utils/hooks/useHttp';
import ModalClientView from './ModalClient.view';

type Props = {
  setOpenModal: (e: boolean) => void;
  openModalClientId: number;
};

const ModalClient: React.FC<Props> = (props: PropsWithChildren<Props>) => {
  const [reference, setReference] = useState<string>('');
  const [teccomMappingData, setTeccomMappingData] = useState<IUpdateForm[]>([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [infoClient, setInfoClient] = useState<IClientInfo>({} as IClientInfo);
  const [componentMounted, setComponentMounted] = useState<boolean>(true);
  const [supplierMapping, setSupplierMapping] = useState<ISupplierMapping[]>([]);
  const [teccomMapping, setTeccomMapping] = useState<ITecomMapping[]>([]);

  const http = useHttp();

  useEffect(() => {
    if (componentMounted) {
      fetchRequests();
    }
  }, []);

  const fetchRequests = () => {
    setLoading(true);
    Promise.all([
      http.selectClient.clientInfo(props.openModalClientId),
      http.selectClient.supplierMapping(),
      http.selectClient.teccomMapping(props.openModalClientId),
    ])
      .then((res) => {
        setInfoClient(res[0].data);
        setSupplierMapping(res[1].data);
        setTeccomMapping(res[2].data);
      })
      .finally(() => {
        setLoading(false);
        setComponentMounted(false);
      });
  };

  function combineRequests() {
    const data: IUpdateForm[] = [];

    for (let i = 0; i < supplierMapping.length; i++) {
      for (let j = 0; j < teccomMapping.length; j++) {
        if (supplierMapping[i].dlnr === teccomMapping[j].dlnr) {
          data.push({
            name: supplierMapping[i].name,
            seller_id: teccomMapping[j].seller_id,
            buyer_id: teccomMapping[j].buyer_id,
            dlnr: supplierMapping[i].dlnr,
          });
        }
      }
    }

    if (!data.length && supplierMapping.length) {
      data.push({ name: supplierMapping[0].name, dlnr: supplierMapping[0].dlnr, seller_id: '', buyer_id: '' });
    }

    setTeccomMappingData(data);
  }

  const optionsSupplierMapping = () => {
    return (
      teccomMappingData?.map((item) => ({
        label: item.name,
        value: item.dlnr,
        seller_id: item.seller_id,
        buyer_id: item.buyer_id,
      })) || []
    );
  };

  useEffect(() => {
    combineRequests();
  }, [supplierMapping, teccomMapping]);

  const save = () => {
    setLoading(true);

    const payload = {
      ...teccomMappingData,
    };

    http.selectClient.supplierUpdate(props.openModalClientId, payload).finally(() => {
      setLoading(false);
    });
  };

  return (
    <ModalClientView
      teccomMappingData={teccomMappingData}
      setTeccomMappingData={setTeccomMappingData}
      reference={reference}
      setReference={setReference}
      setOpenModal={props.setOpenModal}
      tabIndex={tabIndex}
      setTabIndex={setTabIndex}
      loading={loading}
      infoClient={infoClient}
      optionsSupplierMapping={optionsSupplierMapping()}
      openModalClientId={props.openModalClientId}
      save={save}
    />
  );
};

ModalClient.displayName = 'ModalClient';

export default ModalClient;
