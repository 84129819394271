import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import './SelectClientButton.scss';

const SelectClientButton: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  function handleSelectClientClick() {
    navigate('/area/select?page=1');
  }

  return (
    <div className="select-client-button" onClick={handleSelectClientClick}>
      <div className="select-client-title">{t('_select_client')}</div>
    </div>
  );
};

export default SelectClientButton;
