import React, { useState } from 'react';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { IProvider } from 'utils/hooks/http/providers.http';
import TextInput from 'components/inputs/TextInput/TextInput';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import './ProviderModal.scss';
import useHttp from 'utils/hooks/useHttp';
import { notify } from 'utils/marketplace';

type Props = {
    setOpenModal: (openModal: boolean) => void
    data: IProvider
    getAllProviders?: (data) => void
}

const ProviderModal:React.FC<Props> = (props: Props) => {
  const {t} = useTranslation();
  const http = useHttp();
  const [value, setValue] = useState(props.data.franco ? String(parseFloat(String(props.data.franco).replaceAll(',', ''))) : '');
  const [loading, setLoading] = useState(false);

  function submit(){
    setLoading(true);
    const params = {
      ...props.data,
      franco: value ? +value : null,
    };

    delete params.logo;

    http.providers.updateFranco(params)
      .then(() => {
        notify(t('_success'), 'success');
        props.setOpenModal(false);
        props.getAllProviders!({});
      })
      .catch(() => notify(t('_error')))
      .finally(() => setLoading(false));
  }

  function handleChange(e){
    const regex = /^[0-9]*\.?[0-9]*$/; 
    const value = e.target.value;

    if (regex.test(value) && +value <= 100_000_000_000) {
      setValue(value);
    }
  }

  return (
    <div className="provider-modal">
      <div className="provider-modal-header">
        <div className="provider-modal-title">{t('_edit_franco')}</div>
        <FontAwesomeIcon className="provider-modal-close" icon={faClose} onClick={() => props.setOpenModal(false)}/>
      </div>
      <div className="provider-modal-body">
        <div className="franco-input">
          <TextInput
            value={value || ''}
            onChange={handleChange}
            placeholder="FRANCO"
          />
        </div>
        <div className="provider-modal-save">
          <DefaultButton isloading={loading} type="submit" text={t('_save')} onClick={submit} />
        </div>
      </div>
    </div>
  );
};

export default ProviderModal;