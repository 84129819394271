import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Spinner from 'components/spinner/Spinner';
import { IData } from 'interfaces/selectClient';
import React, { PropsWithChildren } from 'react';
import { ReactComponent as TeccomIcon } from 'assets/Icons/teccom-logo.svg';

type Props = {
  setOpenModal: (e: boolean) => void;
  info: IData | undefined;
  handleSelectClient: (e: number) => void;
  loadingSaveClient: boolean;
  clientIdForSpinner: number;
  setOpenModalClientId: (e: number) => void;
};


const InfoClient: React.FC<Props> = (props: PropsWithChildren<Props>) => {

  return (
    <div className="client-item">
      <div className="client-heder">
        <div className="client-title">
          {props.info?.name}
          {!props.loadingSaveClient && props.info?.id === props.clientIdForSpinner ? (
            <FontAwesomeIcon icon={faCircleCheck} className="client-check" />
          ) : null}
        </div>
        <div className="teccom-container">
          <div className="client-teccom">
            <TeccomIcon
              className={`${props?.info?.hasTeccomConnection ? 'client-icons' : 'client-disabled-icons'}`}
              onClick={() => {
                props.setOpenModal(true);
                props.setOpenModalClientId(Number(props.info?.id));
              }}
            />
          </div>
          {props.info?.teccomUsername && <div className="teccom-label" title={`TOM Username: ${props.info?.teccomUsername}`}>TOM Username: {props.info?.teccomUsername}</div>}
          {props.info?.teccomId && <div className="teccom-label" title={`TecId: ${props.info?.teccomId}`}>TecId: {props.info?.teccomId}</div>}
        </div>
      </div>
      <div className="client-info">
        <div className="client-body">
          <div className="client-lable">Pays</div>
          <div className="client-content">{props.info?.country}</div>
        </div>
        <div className="client-body">
          <div className="client-lable">Adresse</div>
          <div className="client-content">{props.info?.address}</div>
        </div>
      </div>
      <button
        className="client-btn"
        type="button"
        onClick={() => props.handleSelectClient(Number(props.info?.id))}
        disabled={props.loadingSaveClient && props.info?.id === props.clientIdForSpinner}
      >
        <div className="container-spiner">
          {props.loadingSaveClient && props.info?.id === props.clientIdForSpinner ? <Spinner class="extra-small" /> : null}
        </div>{' '}
        Sélectionner le client
      </button>
    </div>
  );
};

InfoClient.displayName = 'InfoClient';

export default InfoClient;
