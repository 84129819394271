import React from 'react';
import { ReactComponent as PartsIcon } from 'assets/Icons/parts.svg';
import './EquivalenceTitle.scss';
import {faCar} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

type Props = {
  title: string;
  iconType? :  string
};

// eslint-disable-next-line react/destructuring-assignment
const EquivalenceTitle = ({title, iconType}: Props): JSX.Element => {

  const renderIconType = () => {
    if(iconType === 'parts') {
      return <PartsIcon/>;
    }else if(iconType === 'cars') {
      return <FontAwesomeIcon icon={faCar} />;
    }
  };

  return (
    <div className="equivalence-title">
      <div className="icon">
        {renderIconType()}
      </div>
      <span className="title">{title}</span>
    </div>
  );
};

EquivalenceTitle.displayName = 'EquivalenceTitle';

export default EquivalenceTitle;