import React, { ChangeEvent } from 'react';
import { ReactComponent as PartsIcon } from 'assets/Icons/parts.svg';
import TextInput from 'components/inputs/TextInput/TextInput';

import './RefContent.scss';
import { useTranslation } from 'react-i18next';
import RefDropdownContent from '../../../dropdown-content/RefDropdownContent';
import {IData} from 'utils/hooks/http/search-by-reference.http';
import { createSearchParams, generatePath, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getCurrentCatalog } from 'store/selectors/catalogs.selector';

type Props = {
  refInputChange: (string) => ChangeEvent<HTMLInputElement> | void;
  onSubmit: (data: object) => void;
  isLoading: boolean;
  isOpen: boolean;
  setIsOpen: (data: boolean) => void;
  refDataAutocomplete: IData[] |[];
  inputValue: string;
  getRefData: (q: string) => void;
};

const RefContentView: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  const handleSearchByRef = () => {
    props.getRefData(props.inputValue);
  };

  return (
    <div className="ref-search-card">
      <div className="ref-search-card-content">
        <form className="form" onSubmit={props.onSubmit}>
          <label className="reference-label">
            {' '}
            <PartsIcon className="reference-icon-parts" /> {t('_ref_parts')}
          </label>
          <TextInput
            placeholder={t('Ex: PDC2015')}
            onClick={handleSearchByRef}
            onChange={props.refInputChange}
            withIcon
          />
          {props.isOpen && (
            <RefDropdownContent inCatalog refValue={props.inputValue} loading={props.isLoading} setIsOpen={props.setIsOpen} refData={props.refDataAutocomplete} />
          )}
          {/*{props.isOpen && <RefDropdownContentAutocompleteCatalog refData={props.refDataAutocomplete} setIsOpen={props.setIsOpen} loading={props.isLoading} refValue={props.inputValue} />}*/}
        </form>
      </div>
    </div>
  );
};

RefContentView.displayName = 'RefContentView';

export interface IRefForm {
  reference: string;
}

export default RefContentView;
