import React, { useCallback } from 'react';
import { IDropdownModel } from '../../components/dropdowns/Dropdown.model';
import StandardDropdown from '../../components/dropdowns/StandardDropdown/StandardDropdown';
import { countries, languages } from '../../utils/constants/locales';
import { useTranslation } from 'react-i18next';
import TextInput from 'components/inputs/TextInput/TextInput';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import CustomSwitch from 'components/custom-switch/CustomSwitch';
import { usePermission } from 'utils/usePermission';
import { ROLE_CLIENT_PLATFORM } from 'utils/constants/roles';
import './Parametres.scss';
import CURRENCY from 'utils/constants/currency';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import fileUpload from '../../assets/Icons/file-upload.svg';
import useHttp from '../../utils/hooks/useHttp';
import { IClient } from '../../models/client.model';
import { useDropzone } from 'react-dropzone';
import { getBackendAssetsBasePath, getCurrentMarketplace, notify } from '../../utils/marketplace';
import { setUser } from '../../store/reducers/useSlices';
import { useAppDispatch } from '../../utils/hooks/redux';
import { Marketplaces } from '../../utils/constants/applications';
import useLocalStorage from 'use-local-storage';

type Props = {
  handleSwitchChange?:(value) => void
  switchValue?: boolean
  onLanguageChange: (val: IDropdownModel) => void;
  onCountryChange: (val: IDropdownModel) => void;
  submitSettings?: () => void
  handleWorkCostChange?: (e, work_cost) => void
  selectedCountry: IDropdownModel;
  selectedLanguage: IDropdownModel;
  currentWorkValue?: string | number,
  mediumWorkValue?: string | number,
  highWorkValue?: string | number,
  loading?: boolean,
  files?;
  clientData?: IClient | null,
};

const ParametresView: React.FC<Props> = (props:Props) => {
  const user = JSON.parse(localStorage.getItem('userData')!);
  const http = useHttp();
  const {t} = useTranslation();
  const isClientPlatform = usePermission([ROLE_CLIENT_PLATFORM]);
  const dispatch = useAppDispatch();

  function deleteLogo(id) {
    http.clients.deleteLogoWeb(id).then(() => {
      const userInfo = JSON.parse(localStorage.getItem('userData')!);

      //Get Image Extension
      const user = { ...userInfo, client_logo:  null };

      localStorage.setItem('userData', JSON.stringify(user));
      dispatch(setUser(user));
    });


  }

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const formData = new FormData();

      formData.append('logo', acceptedFiles[0] ? acceptedFiles[0] : 'null');

      http.user.postUser(formData).then(() => {
        const userInfo = JSON.parse(localStorage.getItem('userData')!);

        //Get Image Extension
        const extension = acceptedFiles[0].name.split('.').pop();

        const user = { ...userInfo, client_logo: `${getBackendAssetsBasePath()}/images/client-logos/logo_${userInfo.client_id}.${extension}` };

        localStorage.setItem('userData', JSON.stringify(user));
        dispatch(setUser(user));
      }).catch((error) => {
        Object.keys(error).forEach((key) => {
          notify(t(error[key]?.[0]) || t('_error'));
        });
      });
    },
    [props.files],
  );

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    onDrop,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpg', '.jpeg'],
    },
  });


  function getFileLogo() {
    const { clientData } = props;

    if (!clientData?.client_logo) {
      return;
    }

    return clientData?.client_logo+'?'+new Date().getTime();
  }

  const fileLogo = getFileLogo();
  const isAutomotor = getCurrentMarketplace() === Marketplaces.AUTOMOTOR;
  const isAmerigo = getCurrentMarketplace() === Marketplaces.AMERIGO;

  return (
    <div className="parameters-container">
      <div className="wrapper">
        <h1>{t('_settings')}</h1>
        <div className="form">
          <div className="settings-separator" />
          <h2>{t('_default_language_and_country').toLocaleUpperCase()}</h2>
          <div className="row language-country">
            <div className="language">
              <p className="label">{t('_language')}</p>
              <StandardDropdown isFlagDimension onClick={props.onLanguageChange} selectedItem={props.selectedLanguage} data={languages} />
            </div>
            <div className="country">
              <p className="label">{t('_country')}</p>
              <StandardDropdown isFlagDimension onClick={props.onCountryChange} selectedItem={props.selectedCountry} data={countries} />
            </div>
          </div>
          {(isAutomotor || isAmerigo) && (
            <div className="row add_logo">
              <h2>{t('_logo')}</h2>
              <div className="logo-upload-container">
                <div className="logo-upload">
                  {fileLogo ? (
                    <div className="uploaded-message-file">
                      <div className="icon">
                        <img alt="icon" className="logo" src={fileLogo} />
                      </div>

                      <div className="remove-file" onClick={() => deleteLogo(props.clientData?.client_id)}>
                        <FontAwesomeIcon className="remove-client-img" icon={faClose} />
                      </div>
                    </div>
                  ):(
                    <div className="select-logo-title">{t('_please_select_a_logo')}</div>
                  )}
                </div>
                <div {...getRootProps({ className: 'content' })}>
                  <div className="logo-upload-button">
                    <h2>{t('_file_upload')}</h2>
                    <input {...getInputProps()}  />
                    <img src={fileUpload} className="upload-image-btn" alt="file-upload" />
                  </div>
                </div>
              </div>
            </div>
          )}
          {isClientPlatform && (
            <React.Fragment>
              <div className="settings-separator" />
              <h2 className="settings-row-header">{t('_hourly_labor_rates').toLocaleUpperCase()} <CustomSwitch value={props.switchValue} onChange={props.handleSwitchChange!} name="work_cost"></CustomSwitch></h2>
              <div className={`row ${!props.switchValue ? 'disabled' : ''}`}>
                <div className="t1">
                  <div className="t-label">
                    <span>T1</span> ({t('_current_work')})
                  </div>
                  <div className="t-input">
                    <TextInput disabled={!props.switchValue} onChange={(e) => props.handleWorkCostChange!(e, 't1')} value={String(props.currentWorkValue) || ''} placeholder="0.00" />
                  </div>
                  <div className="t-currency">
                    {CURRENCY[user!.currency]}/h
                  </div>
                </div>
                <div className="t1">
                  <div className="t-label">
                    <span>T2</span> ({t('_medium_technicality')})
                  </div>
                  <div className="t-input">
                    <TextInput disabled={!props.switchValue} onChange={(e) => props.handleWorkCostChange!(e, 't2')} value={String(props.mediumWorkValue) || ''} placeholder="0.00" />
                  </div>
                  <div className="t-currency">
                    {CURRENCY[user!.currency]}/h
                  </div>
                </div>
                <div className="t1">
                  <div className="t-label">
                    <span>T3</span> ({t('_high_technicality')})
                  </div>
                  <div className="t-input">
                    <TextInput disabled={!props.switchValue} onChange={(e) => props.handleWorkCostChange!(e, 't3')} value={String(props.highWorkValue) || ''} placeholder="0.00" />
                  </div>
                  <div className="t-currency">
                    {CURRENCY[user!.currency]}/h
                  </div>
                </div>
              </div>

              <div className="submit-button">
                <DefaultButton text={t('_save')} type="submit" onClick={props.submitSettings} isloading={props.loading} />
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

ParametresView.displayName = 'ParametresView';

export default ParametresView;
