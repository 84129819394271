import React, { ChangeEvent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Column, useExpanded, useTable } from 'react-table';
import useHttp from 'utils/hooks/useHttp';
import { notify } from 'utils/marketplace';
import { CartTableItem } from './CartTable.model';
import CartTableView from './CartTable.view';
import CategoriesCell from './Cells/CategoriesCell/CategoriesCell';
import CodeCell from './Cells/CodeCell/CodeCell';
import DeleteCell from './Cells/DeleteCell/DeleteCell';
import PriceCell from './Cells/PriceCell/PriceCell';
import ReferenceCell from './Cells/ReferenceCell/ReferenceCell';
import QuantityAvailability from './Cells/QuantityAvailabilityCell/QuantityAvailability';
import ImageCell from './Cells/ImageCell/ImageCell';
import CheckboxCell from './Cells/CheckboxCell/CheckboxCell';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { setQuantityToolbar } from 'store/slices/cart.slice';
import { useAppDispatch } from 'utils/hooks/redux';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import { CartTableContext, ICartTableContext } from '../../../../../context/CartTableContext';
import ExpandButton from '../../../../../components/tables/TableMoreInformation/ExpandButton/ExpandButton';
import PriceQuantityCell from './Cells/PriceQuantityCell/PriceQuantityCell';
import { capitalizeFirstLetter } from '../../../../../utils/utils';
import { isRetailerMecadepotChild } from 'utils/isRetailerMecadepotChild';

type Props = {
  rowLoading?: boolean
  setRowLoading?: (rowLoading) => void
  hiddenColumns: string[];
  handleCheckout?: () => void;
  setCheckedOrderList?: (checkedOrderList: []) => void;
  handleDeleteAll?: () => void;
  checkoutLoading?: boolean;
  register?
  dataState?: CartTableItem[];
  setDataState?
  fetchData?: () => Promise<void>;
  totalPartsPrice: number;
  setTotalPartsPrice?: (number) => void
  setLoading?: (state: boolean) => void;
  loading?: boolean;
};

const CartTable: React.FC<Props> = (props: Props) => {
  const http = useHttp();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { getValues, setValue, register } = useForm();
  const { isMobile, isTablet, isDesktop } = useDeviceDetect();
  const { quantityToolbar } = useSelector((state: RootState) => state.cart);
  const { setSelectAll, selectedData, setSelectedData } = useContext(CartTableContext) as ICartTableContext;
  const isMecadepotChild = isRetailerMecadepotChild();
  const [idsDelete, setIdsDelete] = useState([]);


  useEffect(() => {

    if (props.dataState && props.dataState.length && props.dataState.length === selectedData.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [selectedData]);

  const handleSelectAll = (event) => {
    const isChecked = event.target.checked;

    setSelectAll(isChecked);


    if (isChecked) {
      setValue('checkbox-cart', props.dataState?.map((item) => String(item.id)));
      setSelectedData(props.dataState ?? []);
    } else {
      setValue('checkbox-cart', props.dataState && props.dataState?.length > 1 ? [] : '');
      setSelectedData([]);
    }
  };

  // useEffect(() => {
  //   if (selectAll) {
  //     setValue('checkbox-cart', props.dataState?.map((item) => String(item.id)));
  //   } else {
  //     setValue('checkbox-cart', []);
  //   }
  // }, [selectAll]);

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    const id = Number(e.target.value);

    if (!props.dataState) {
      return;
    }

    // find the item in the data array by id
    const item = props.dataState.find((item) => item.id === id);

    if (!item) {
      return;
    }

    // check if item is already in the selectedData array
    const itemInSelectedData = selectedData.find((item) => item.id === id);

    // if item is not in the selectedData array and the checkbox is checked, add it to the array
    if (isChecked && !itemInSelectedData) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setSelectedData((prevState) => [...prevState, item]);
    } else if (!isChecked && itemInSelectedData) {
      // if item is in the selectedData array and the checkbox is unchecked, remove it from the array
      const selectedDataFiltered = selectedData.filter((item) => item.id !== id);

      setSelectedData(selectedDataFiltered);
    }

    // if all items are selected, check the select all checkbox
    if (selectedData.length === props.dataState.length) {
      setSelectAll(true);
    }
  };

  const handleDeleteAll = async () => {

    let items;

    if (Array.isArray(getValues()['checkbox-cart'])) {
      items = getValues()['checkbox-cart']?.map(Number);
    } else {
      items = [Number(getValues()['checkbox-cart'])];
    }

    if (items.length === 0) {
      notify(t('_cart_select_item'), 'info');

      return;
    }

    props.setLoading!(true);

    await http.cart.deleteCartData(items).then(() => {
      notify(t('_cart_deleted'), 'success');
      updateCartQuantityToolbar();
    }).catch(() => {
      notify('Error');
    }).finally(() => {
      props.setLoading!(false);
    });

    props.fetchData!();
  };

  const updateCartQuantityToolbar = () => {
    http.cart.getCartQuantity().then((res) => {
      dispatch(setQuantityToolbar(res.quantity));
    });
  };

  const handleDelete = useCallback(
    (newProps) => <DeleteCell {...newProps} idsDelete={idsDelete} setIdsDelete={setIdsDelete} data={props.dataState} setData={props.setDataState} setRowLoading={props.setRowLoading} setTotalPartsPrice={props.setTotalPartsPrice} />,
    [props.dataState, idsDelete]
  );


  const columns = useMemo(
    (): Column<CartTableItem>[] => (isMecadepotChild ?
      [
        {
          id: 'expander',
          Header: '',
          width: 10,
          maxWidth: 10,
          Cell: (props) => {
            return (
              <ExpandButton row={props.row} />
            );
          }
        },
        {
          Header: ' ',
          Cell: (props) => <CheckboxCell handleOnChange={handleOnChange} {...props} register={register}></CheckboxCell>,
          width: isMobile || isTablet ? 42 : 32,
        },
        {
          Header: ' ',
          accessor: 'image',
          Cell: (newProps) => <ImageCell {...newProps} />,
          width: 80,
        },
        {
          Header: t('_reference'),
          accessor: 'data_supplier_reference',
          Cell: ReferenceCell,
          minWidth: isMobile || isTablet ? 80 : 100,
        },
        {
          Header: t('_designation'),
          accessor: 'short_description',
          Cell: CategoriesCell,
          minWidth: 200,
        },

        {
          Header: t('_price') + ' / U',
          accessor: 'price',
          Cell: (props) => <PriceCell {...props} unspecified={false}></PriceCell>,
          maxWidth: 100,
        },
        {
          Header: t('_quantity_availability'),
          accessor: 'quantity_availability',
          Cell: (newProps) => <QuantityAvailability availabilityUnspecified={false} totalPartsPrice={props.totalPartsPrice} setTotalPartsPrice={props.setTotalPartsPrice} {...newProps} refetch={props.fetchData!} />,
          maxWidth: isMobile || isTablet ? 130 : 120,
          minWidth: isMobile || isTablet ? 130 : 120,
        },
        {
          Header: capitalizeFirstLetter(t('_total_price')),
          accessor: 'price_total',
          Cell: (props) => <PriceQuantityCell quantity={props.row.original?.quantity_availability?.quantity} price={props.row.original.price} priceVAT={props.row.original?.priceVAT} {...props} unspecified={false}></PriceQuantityCell>,
          maxWidth: 125,
        },
        // {
        //   Header: t('_barcode'),
        //   accessor: 'barCode',
        //   Cell: CodeCell,
        //   maxWidth: 100,
        // },
        {
          Header: t('_delete').toString(),
          Cell: handleDelete,
          width: 40,
        },
      ]
      :
      [
        {
          id: 'expander',
          Header: '',
          width: 10,
          maxWidth: 10,
          Cell: (props) => {
            return (
              <ExpandButton row={props.row} />
            );
          }
        },
        {
          Header: ' ',
          Cell: (props) => <CheckboxCell handleOnChange={handleOnChange} {...props} register={register}></CheckboxCell>,
          width: isMobile || isTablet ? 42 : 32,
        },
        {
          Header: ' ',
          accessor: 'image',
          Cell: (newProps) => <ImageCell {...newProps} />,
          width: 80,
        },
        {
          Header: t('_reference'),
          accessor: 'reference',
          Cell: ReferenceCell,
          minWidth: isMobile || isTablet ? 80 : 250,
        },
        {
          Header: t('_category'),
          accessor: 'categories',
          Cell: CategoriesCell,
          minWidth: 200,
        },

        {
          Header: t('_price'),
          accessor: 'price',
          Cell: (props) => <PriceCell {...props} unspecified={false}></PriceCell>,
          maxWidth: 100,
        },
        {
          Header: t('_quantity_availability'),
          accessor: 'quantity_availability',
          Cell: (newProps) => <QuantityAvailability availabilityUnspecified={false} totalPartsPrice={props.totalPartsPrice} setTotalPartsPrice={props.setTotalPartsPrice} {...newProps} refetch={props.fetchData!} />,
          maxWidth: isMobile || isTablet ? 130 : 120,
          minWidth: isMobile || isTablet ? 130 : 120,
        },
        {
          Header: capitalizeFirstLetter(t('_total_price')),
          accessor: 'price_total',
          Cell: (props) => <PriceQuantityCell quantity={props.row.original?.quantity_availability?.quantity} price={props.row.original.price} priceVAT={props.row.original?.priceVAT} {...props} unspecified={false}></PriceQuantityCell>,
          maxWidth: 125,
        },
        {
          Header: t('_barcode'),
          accessor: 'barCode',
          Cell: CodeCell,
          maxWidth: 100,
        },
        {
          Header: t('_delete').toString(),
          Cell: handleDelete,
          width: 40,
        },
      ]),
    [props.totalPartsPrice, isMobile, isTablet, isDesktop, selectedData, props.dataState, idsDelete],
  );

  let hiddenColumns: string[] = [];

  if (isTablet) {
    hiddenColumns = ['subcategory', 'categories'];
  }

  if (isMobile) {
    hiddenColumns = ['subcategory', 'price', 'barCode', 'image', 'short_description', 'categories'];
  }


  const initialState = hiddenColumns ? {
    'hiddenColumns': hiddenColumns
  } : {};

  if (!isMobile && !isTablet) {
    columns.shift();
  }


  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data: props.dataState!,
    initialState
  }, useExpanded);

  return (
    <CartTableView
      rowLoading={props.rowLoading}
      getTableBodyProps={getTableBodyProps}
      getTableProps={getTableProps}
      headerGroups={headerGroups}
      prepareRow={prepareRow}
      rows={rows}
      hiddenColumns={hiddenColumns}
      loading={props.loading!}
      handleCheckout={props.handleCheckout}
      handleSelectAll={handleSelectAll}
      handleDeleteAll={handleDeleteAll}
      register={props.register}
      checkoutLoading={props.checkoutLoading}
      totalPartsPrice={props.totalPartsPrice}
      quantityToolbar={quantityToolbar}
    ></CartTableView>
  );
};

CartTable.displayName = 'CartTable';

export default CartTable;
