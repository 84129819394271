import React, { useEffect } from 'react';
import EspaceClientView from './EspaceClient.view';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import { ROLE_CLIENT_PLATFORM_ADMIN } from '../../../../utils/constants/roles';
import { useNavigate } from 'react-router-dom';

const EspaceClient: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state: RootState) => state.userData);
  const isDisabled = !user?.roles.includes(ROLE_CLIENT_PLATFORM_ADMIN);

  useEffect(() => {
    if (isDisabled) {
      navigate('/');
    }
  }, []);

  return <EspaceClientView />;
};

EspaceClient.displayName = 'EspaceClient';

export default EspaceClient;
