import React from 'react';
import { generatePath, Outlet, useParams } from 'react-router-dom';
import ErrorCodesSearch from 'pages/CatalogVehicle/ErrorCodes/ErrorCodesSearch';
import './ErrorCodesLayout.scss';
import ErrorCodesNavLinks from 'pages/CatalogVehicle/ErrorCodes/ErrorCodesNavLinks/ErrorCodesNavLinks';
import {ISearchLinks} from 'pages/CatalogVehicle/TechnicalDocumentation/components/ErrorCodes/Solutions/Solutions';

const ErrorCodesLayout: React.FC = () => {
  const { vehicle_id } = useParams<'vehicle_id'>();

  const searchLinks: ISearchLinks = {
    code: generatePath('/reliable/rmi/:vehicle_id/error-codes/manuals', { vehicle_id: String(vehicle_id) }),
    keyword: generatePath('/reliable/rmi/:vehicle_id/error-codes/solutions', { vehicle_id: String(vehicle_id) }),
  };

  return (
    <div className="error-codes-layout">
      <div className="error-codes-container">
        <ErrorCodesSearch searchLinks={searchLinks} />
      </div>
      <div className="error-codes-container container-fluid-medium">
        <ErrorCodesNavLinks />
        <Outlet context={{searchLinks}} />
      </div>
    </div>
  );
};

ErrorCodesLayout.displayName = 'ErrorCodesLayout';

export default ErrorCodesLayout;
