import { IParams } from 'models/order';
import { IHttpResponse, serverAxios } from '../../http';
import { CancelToken } from 'axios';
import { IDataResponseStockPrice, IDataStockPrice, IDataSuppliersPrice } from 'models/stockPrice';

class StockPriceHttp {
  private static route = '/retailer/price/all';
  private static routeSave = '/retailer/price';
  private static routeDelete = '/retailer/price';
  private static suppliers = '/retailer/stock-management/short-list';
  private static suppliersFilter = '/retailer/price/suppliers-list';


  public async getStockPriceList(params: IParams, cancelToken: CancelToken): Promise<IHttpResponse<IDataResponseStockPrice>> {
    return serverAxios.get(StockPriceHttp.route, { params: params, cancelToken: cancelToken });
  }
  public async getSuppliers(): Promise<IHttpResponse<IDataSuppliersPrice[]>> {
    return serverAxios.get(StockPriceHttp.suppliers);
  }
  public async getSupplierFilter(): Promise<IHttpResponse<IDataSuppliersPrice[]>> {
    return serverAxios.get(StockPriceHttp.suppliersFilter);
  }
  public async createStock(data: { reference: string; supplier: number; priceEur: number; priceGbp: number; }): Promise<IHttpResponse<IDataStockPrice>> {
    return serverAxios.post(StockPriceHttp.routeSave, data);
  }

  public async updateStock(id: string, data: { reference: string; supplier: number; priceEur: number; priceGbp: number; }): Promise<IHttpResponse<IDataStockPrice>> {

    return serverAxios.patch(`${StockPriceHttp.routeSave}/${id}`, data);
  }
  public async deleteStock(id: string): Promise<IHttpResponse<null>> {

    return serverAxios.delete(`${StockPriceHttp.routeDelete}/${id}`);
  }
}

export default StockPriceHttp;
