import React from 'react';
import CartAvailabilityView from './CartAvailability.view';
import './CartAvailability.scss';

type Props = {
  value: number;
  limit: number;
  unspecified?: boolean;
  available?
};

const CartAvailability: React.FC<Props> = (props: Props) => {

  return (
    <CartAvailabilityView available={props.available} value={props.value} unspecified={props.unspecified} limit={props.limit} isAvailable={props.limit > 0 && props.limit >= props.value}/>
  );
};

CartAvailability.displayName = 'CartAvailability';

export default CartAvailability;