import React, { useEffect, useState } from 'react';
import BarCode from './BarCode/BarCode';
import { IAddToCartParams, ICarPartData, ITeccomDisponibility } from '../../../models/car-parts.model';
import IconButton from '../../buttons/IconButton/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping } from '@fortawesome/free-solid-svg-icons';
import './PartItemOptions.scss';
import Disponibility from '../../disponibility/Disponibility';
import QuantityInput from '../../inputs/QuantityInput/QuantityInput';
import { ReactComponent as TeccomIcon } from '../../../assets/Icons/teccom-logo.svg';
import CarPartPrice from '../../CarPartPrice/CarPartPrice';
import { currencies } from 'utils/enums/marketplace';
import { useAppDispatch } from 'utils/hooks/redux';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { addToCartAction } from '../../../store/actions/cart.actions';
import Spinner from '../../spinner/Spinner';
import useHttp from 'utils/hooks/useHttp';

import FavoriteButton from './FavoriteButton/FavoriteButton';
import { CATALOG_MANUFACTURER } from 'utils/constants/applications';
import { useTranslation } from 'react-i18next';
import { setQuantityToolbar } from 'store/slices/cart.slice';
import useTeccomButtonDisponibility from 'utils/hooks/use-teccom-button-disponibility';

type Props = {
  carPart: ICarPartData;
};

const PartItemOptions: React.FC<Props> = (props) => {
  const carPart = props.carPart;
  const [quantityValue, setQuantityValue] = useState<number>(1);
  const dispatch = useAppDispatch();
  const { addToCartLoading } = useSelector((state: RootState) => state.cart);
  const [teccomData, setTeccomData] = useState<ITeccomDisponibility>();
  const [loading, setLoading] = useState(false);
  const http = useHttp();
  const useBtnDisponibility = useTeccomButtonDisponibility();
  const { t } = useTranslation();
  const { quantityToolbar } = useSelector((state: RootState) => state.cart);

  useEffect(() => {
    checkTeccomData();
  }, []);

  const handleAddToCart = () => {
    if (addToCartLoading || !quantityValue || !carPart.add_to_cart) {
      return;
    }

    const params: IAddToCartParams[] = [];

    let param: IAddToCartParams = {
      catalog_id: carPart.catalog_id,
      cod_fou: carPart.cod_fou,
      is_oe_part: props.carPart?.catalog_type === CATALOG_MANUFACTURER,
      price: props.carPart?.price?.price,
      quantity: quantityValue,
      supplier_id: carPart.data_supplier.id,
      supplier_reference: carPart.data_supplier_reference,
    };

    if (localStorage.getItem('external_cart_id')) {
      param = {
        ...param,
        ...{ external_cart_id: localStorage.getItem('external_cart_id') },
      };
    }

    params.push(param);

    dispatch(addToCartAction(params));
    dispatch(setQuantityToolbar(+quantityValue + +quantityToolbar));
  };

  function checkTeccomData() {
    if (loading) {
      return;
    }

    if (useBtnDisponibility && carPart.add_to_cart) {
      setLoading(true);
      const params = {
        dlnr: carPart.data_supplier.id,
        reference: carPart.data_supplier_reference_original,
        supplier_cod_fou: carPart.cod_fou,
      };

      http.carParts
        .getTeccomDisponibility(params)
        .then((res) => setTeccomData(res[0]))
        .finally(() => {
          setLoading(false);
        });
    }
  }

  return (
    <div className="car-part-options-wrapper">
      <div className="top">
        <BarCode item={carPart} />
        <FavoriteButton text={t('favorite')} carPart={carPart} />
      </div>

      <div className="teccom">
        {useBtnDisponibility && carPart.add_to_cart ? <TeccomIcon onClick={checkTeccomData} className="teccom-icon" /> : null}
      </div>

      <div className="bottom">
        <div className="availability">
          <QuantityInput setValue={setQuantityValue} value={quantityValue} />
          <Disponibility loading={loading} value={quantityValue} limit={props.carPart.available ? props.carPart.available : teccomData?.available} />
          <CarPartPrice loading={loading} currency={currencies.EUR} price={teccomData?.price ? teccomData?.price : props.carPart?.price} quantityValue={quantityValue} />
        </div>
        <div className="button">
          <IconButton
            onClick={handleAddToCart}
            text={t('_add_to_cart')}
            className="iconButton"
            disabled={!quantityValue || !carPart.add_to_cart}
            icon={addToCartLoading ? <Spinner class="extra-small"></Spinner> : <FontAwesomeIcon icon={faCartShopping} className="" />}
          ></IconButton>
        </div>
      </div>
    </div>
  );
};

PartItemOptions.displayName = 'PartItemOptions';

export default PartItemOptions;
