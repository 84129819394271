import { ICars, ITrucks } from './hooks/http/search-by-vin.http';

export function getCarDescription(data: ICars): string {
  const {
    manufacturer_name,
    short_name,
    name,
    engine_capacity,
    capacity_qualifier,
    // gear_box_description,
    body_description,
    start_year,
    start_month,
    end_year,
    end_month,
    fap_description,
    // nc_of_Cylinder,
    kw_power,
    horse_power,
    model_id,
    manufacturerName,
    model_name
  } = data;

  let carName = '';

  if (manufacturer_name) {
    carName = manufacturer_name;
  }

  if (short_name || model_name) {
    carName = `${carName} ${short_name || model_name}`;
  }

  if (manufacturerName) {
    carName = manufacturerName;
  }

  if (name) {
    carName = `${carName} ${name}`;
  }

  if (engine_capacity) {
    carName = `${carName} ${engine_capacity}`;
  }

  if (capacity_qualifier) {
    carName = `${carName} ${capacity_qualifier}`;
  }

  if (body_description) {
    carName = `${carName} ${body_description}`;
  }

  // if (gear_box_description) {
  //   carName = `${carName} ${gear_box_description}`;
  // }

  if (start_month && start_year) {
    carName = `${carName} ${start_month}/${start_year}`;
  }

  if (end_month && end_year) {
    carName = `${carName} - ${end_month}/${end_year}`;
  }

  if (fap_description) {
    carName = `${carName} ${fap_description}`;
  }

  // if (nc_of_Cylinder || nb_of_cylinder) {
  //   carName = `${carName} ${nc_of_Cylinder || nb_of_cylinder}`;
  // }

  if (kw_power) {
    carName = `${carName} - ${kw_power} KW`;
  }

  if (horse_power) {
    carName = `${carName} - ${horse_power} CH DIN`;
  }

  if (model_id) {
    carName = `${carName} ${model_id}`;
  }

  return carName;
}

export function getTruckDescription(data: ITrucks): string {
  const {
    model_name,
    manufacturer_name,
    name,
    engine_capacity,
    body_description,
    start_year,
    start_month,
    end_year,
    end_month,
    weight,
    power_kw_from,
    power_kw_to,
    power_hp_from,
    power_hp_to,
  } = data;

  let truckName = '';

  if (manufacturer_name) {
    truckName = manufacturer_name;
  }

  if (model_name) {
    truckName = `${truckName} ${model_name}`;
  }

  if (name) {
    truckName = `${truckName} ${name}`;
  }

  if (engine_capacity) {
    truckName = `${truckName} ${engine_capacity}`;
  }

  if (body_description) {
    truckName = `${truckName} ${body_description}`;
  }

  if (start_month && start_year) {
    truckName = `${truckName} ${start_month}/${start_year}`;
  }

  if (end_month && end_year) {
    truckName = `${truckName} - ${end_month}/${end_year}`;
  }

  if (weight) {
    truckName = `${truckName} ${weight}`;
  }

  if (power_kw_from) {
    truckName = `${truckName} ${power_kw_from}`;
  }

  if (power_kw_to) {
    truckName = `${truckName} ${power_kw_to}`;
  }

  if (power_hp_from) {
    truckName = `${truckName} ${power_hp_from}`;
  }

  if (power_hp_to) {
    truckName = `${truckName} - ${power_hp_to}`;
  }

  return truckName;
}
