import { ICatalogs } from 'models/catalog.model';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useHttp from 'utils/hooks/useHttp';
import MySuppliersView from './MySuppliers.view';

type Prop = {};

const MySuppliers: React.FC<Prop> = () => {
  const http = useHttp();
  const [catalogsData, setCatalogsData] = useState<ICatalogs[]>([]);
  const [loadingCatalogs, setLoadingCatalogs] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const navigate = useNavigate();
  const [initialCatalogData, setInitialCatalogData] = useState<ICatalogs[]>([]);

  useEffect(() => {
    fetchCatalogs();
  }, []);

  function fetchCatalogs() {
    setLoadingCatalogs(true);
    http.catalogs
      .getCatalogsData()
      .then((res) => {
        setCatalogsData(res);
        setInitialCatalogData(res);
      })
      .finally(() => setLoadingCatalogs(false));
  }

  function handleCatalogClick(id) {
    console.log(id);
    navigate(`/catalog/${id}`);
  }

  useEffect(() => {
    if (inputValue === '') {
      setCatalogsData(initialCatalogData);
    } else {
      const lowerValue = inputValue.toLowerCase();
      const filteredCatalogs = initialCatalogData.filter((catalog) => catalog.name.toLowerCase().includes(lowerValue));

      setCatalogsData(filteredCatalogs);
    }
  }, [inputValue]);

  return (
    <MySuppliersView
      inputValue={inputValue}
      setInputValue={setInputValue}
      handleCatalogClick={handleCatalogClick}
      loadingCatalogs={loadingCatalogs}
      catalogsData={catalogsData}
    />
  );
};

MySuppliers.displayName = 'MySupplier';

export default MySuppliers;
