import React, { PropsWithChildren } from 'react';
import './NewPagination.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

type Props = {
  handlePageClick: (page: number) => void;
  page: number;
  data?;
  hiddenParamPage?: boolean;
};
const c = 'new-pagination';

const NewPagination: React.FC<Props> = (props: PropsWithChildren<Props>) => {
  return (
    <div className={c}>
      <div
        className={`${c}__btn ${Number(props.page) === 1 ? 'disabled-page-btn' : ''}`}
        onClick={() => props.handlePageClick(Number(props.page) - 1)}
      >
        <FontAwesomeIcon icon={faChevronLeft} />
      </div>
      <div
        className={`${c}__btn ${props.data?.data.length < props.data?.per_page ? 'disabled-page-btn' : ''}`}
        onClick={() => props.handlePageClick(Number(props.page) + 1)}
      >
        <FontAwesomeIcon icon={faChevronRight} />
      </div>
    </div>
  );
};

export default NewPagination;
