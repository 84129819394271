import React from 'react';
import './MySuppliers.scss';
import MainSearchInput from 'components/inputs/MainSearchInput/MainSearchInput';
import LogoItem from 'components/Item/LogoItem/LogoItem';
import Spinner from 'components/spinner/Spinner';
import { ICatalogs } from 'models/catalog.model';
import { useTranslation } from 'react-i18next';
import { getBackendAssetsBasePath } from 'utils/marketplace';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { usePermission } from 'utils/usePermission';
import { ROLE_CLIENT_PLATFORM_ADMIN } from 'utils/constants/roles';

type Props = {
  catalogsData: ICatalogs[];
  loadingCatalogs: boolean;
  handleCatalogClick: (id) => void;
  setInputValue: (value: string) => void;
  inputValue: string;
};

const MySuppliersView: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { user } = useSelector((state: RootState) => state.userData);
  const isPlatformAdmin = usePermission([ROLE_CLIENT_PLATFORM_ADMIN]);

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.setInputValue(e.target.value);
  };

  return (
    <div className="my-suppliers-container">
      <div className="my-suppliers-filter">
        <div className="title-search">{t('_my_suppliers')}</div>
        <div className="my-suppliers-main-search">
          <MainSearchInput placeholder={t('_search_one_supplier')} value={props.inputValue} onChange={changeHandler} withIcon />
        </div>
        <div className="my-suppliers-checkbox-container">
          <div className="datached-pieces-container">
            <input type="checkbox" name="Detached-pieces"></input>
            <label htmlFor="#Detached-pieces">{t('_detached_pieces')}</label>
          </div>
          {user?.hasTyre && isPlatformAdmin && (
            <div className="tyres-checkbox-container">
              <input type="checkbox" name="tyres"></input>
              <label htmlFor="#tyres">{t('_tyre')}</label>
            </div>
          )}
          {user?.hasOil && isPlatformAdmin && (
            <div className="oils-checkbox-container">
              <input type="checkbox" name="oils"></input>
              <label htmlFor="#oils">{t('_oil')}</label>
            </div>
          )}
        </div>
      </div>
      <div className="my-suppliers-all-brands-container">
        {props.loadingCatalogs ? (
          <Spinner class="catalogs-spinner" />
        ) : (
          <div className="items">
            {props.catalogsData.length ? (
              props.catalogsData.map(({ images, name, id }, index) => {
                return (
                  <LogoItem
                    name={name}
                    image={`${getBackendAssetsBasePath()}${images[1]}`}
                    onClick={() => props.handleCatalogClick(id)}
                    key={index}
                  />
                );
              })
            ) : (
              <div className="no-data">{t('_no_data').toLocaleUpperCase()}</div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

MySuppliersView.displayName = 'MySupplierView';

export default MySuppliersView;
