import React, { useEffect, useState } from 'react';
import './TruckEquivalenceItem.scss';
import { SingleValue } from 'react-select';
import { IEquivalenceTruck } from '../../../../../../models/car-parts.model';
import { IOption } from '../../../../../../interfaces/select';
import { createSearchParams, Link } from 'react-router-dom';

type Props = {
  version: IEquivalenceTruck;
  selectedManufacturer: SingleValue<IOption> | undefined;
};

const TruckEquivalenceItem: React.FC<Props> = (props) => {
  const [link, setLink] = useState('');

  const carPartName = () => {
    const manufacturerName = props.selectedManufacturer?.label;
    const name = props.version.truck_name;
    const modelName = props.version.model_name;
    const engineCapacity = props.version.engine_capacity;
    const startYear = props.version.start_year;
    const startMonth = props.version.start_month;
    const endYear = props.version.end_year;
    const endMonth = props.version.end_month;
    const fapDescription = props.version.body_description;

    let carName = '';

    if (manufacturerName) {
      carName = manufacturerName;
    }

    if (modelName) {
      carName = `${carName} ${modelName}`;
    }

    if (name) {
      carName = `${carName} ${name}`;
    }

    if (engineCapacity) {
      carName = `${carName} ${engineCapacity}`;
    }

    if (startMonth && startYear) {
      carName = `${carName} ${startMonth}/${startYear}`;
    }

    if (endMonth && endYear) {
      carName = `${carName} - ${endMonth}/${endYear}`;
    }

    if (fapDescription) {
      carName = `${carName} ${fapDescription}`;
    }

    return carName;
  };


  useEffect(() => {
    const newLink = {
      pathname: `/reliable/rmi/${props.version.truck_id}/catalogs`,
      search: createSearchParams({
        source: 'T',
        vehicle_identifier: '016',
      }).toString(),
    };

    setLink(newLink.pathname + '?' + newLink.search);
  }, [props.version.truck_id]);

  return (
    <div className="vehicle-equivalence-item-list">
      <Link className="vehicle-equivalence-item" to={link}>
        <div className="content">
          <div className="title">
            <p>
              {props.selectedManufacturer?.label} {props.version.model_name}
            </p>
          </div>
          <div className="description">
            <p>{carPartName()}</p>
          </div>
        </div>
      </Link>
    </div>
  );
};

TruckEquivalenceItem.displayName = 'TruckEquivalenceItem';

export default TruckEquivalenceItem;
