import { IHttpResponse, serverAxios } from '../../http';
import {ICars} from './search-by-vin.http';

class SearchByPlateHttp {
  private static route = 'reliable/plate-number';

  public async search(params: IParams): Promise<IHttpResponse<IPlateNumberData>> {
    return serverAxios.get(SearchByPlateHttp.route, { params });
  }

  public async updateVinCar(data: { registration_plate: string, ktypnr: string }): Promise<IHttpResponse<null>> {
    return serverAxios.patch(SearchByPlateHttp.route, { ...data });
  }
}

interface IParams{
    registration_plate: string
    registration_country: string
}

export interface IPlateNumberData {
    cars: null | ICars[];
    trucks: null | ICars[];
}

export interface ICarData{
    body_description: string
    capacity_qualifier: string
    end_month: string
    end_year: string
    engine_capacity: string
    fap_description: string
    gear_box_description:string
    horse_power: string
    id: number
    is_selected: boolean
    kw_power: string
    manufacturer_name: string
    model_id: string
    model_name: string
    name: string
    nc_of_Cylinder: string
    short_name: string
    source: string
    start_month: string
    start_year: string
}

export default SearchByPlateHttp;
