import React, { useState } from 'react';
import { ISellers } from './Tab.model';
import TabView from './Tab.view';

type Props = {
  tabs: ISellers[];
};

const Tab: React.FC<Props> = (props: Props) => {
  const [selectedTabState, setSelectedTabState] = useState(props.tabs[0]);

  const handleSelectedTabClick = (tab: ISellers) => {
    setSelectedTabState(tab);
  };

  return <TabView tabs={props.tabs} selectedTab={selectedTabState} onClickChanageTab={handleSelectedTabClick}></TabView>;
};

Tab.displayName = 'Tab';

export default Tab;
