export const contentStyle = {
  overlay: {
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(172, 173, 185, 0.75)',
    zIndex: 10,
  },
  content: {
    position: 'absolute',
    maxWidth: '99.3rem',
    width: '100%',
    border: 'none',
    backgroundColor: '#ffffff',
    borderRadius: '1rem',
  },
};
  