import i18n from 'i18n';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import useHttp from 'utils/hooks/useHttp';
import { IServiceProposalCooGroups } from 'utils/rmi/interface/maintenance.interface';
import { getCurrentCountryRMI, getCurrentLanguageRMI } from 'utils/rmi/rmi-utils/rmi-utils';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import Spinner from 'components/spinner/Spinner';
import { useSearchParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGears } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import './Step.scss';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';

type Props = {
  setStep?: (string) => void
  control?
}
const StepTwo: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const http = useHttp();
  const { control, setValue } = useForm();
  const { typeId } = useSelector((state: RootState) => state.tecrmi);
  const [gearBox, setGearBox] = useState<IServiceProposalCooGroups[]>();
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { isMobile, isTablet } = useDeviceDetect();

  function fetchServiceProposalCooGroups() {
    setLoading(true);
    const monthToNumber = searchParams.get('registration_date_month') ? moment().month(String(searchParams.get('registration_date_month'))).format('M') : 1;

    if (typeId) {
      http.maintenanceHttp.fetchServiceProposalCooGroups({
        typeId,
        countryCode: getCurrentCountryRMI(i18n.language),
        equipmentFilter: [{ FilterValue: true, KindOfFilter: searchParams.getAll('equipmentFilter')?.map(filter => filter && Number(filter)) }],
        InitalRegistrationDate: new Date(searchParams.get('registration_date_year') ? Number(searchParams.get('registration_date_year')) : 1998, Number(monthToNumber)).toISOString(),
        kindOfGearBox: Number(searchParams.get('kindOfGearBox')) ? Number(searchParams.get('kindOfGearBox')) : null,
        languageCode: getCurrentLanguageRMI(i18n.language)
      }).then(res => setGearBox(res.data)).finally(() => setLoading(false));
    }
  }

  function gearBoxOptions() {
    return gearBox?.map(item => {
      return {
        value: item.QualColId,
        label: item.QualColText,
        CooId: item.CooId
      };
    });
  }

  function getPreviewItem() {
    return gearBox?.find(item => String(item.QualColId) === searchParams.get('cooGroupId'));
  }

  useEffect(() => {
    if (searchParams.get('cooGroupId')) {
      setValue('gearbox', { value: Number(getPreviewItem()?.QualColId), label: getPreviewItem()?.QualColText });
    }
  }, [gearBox]);

  useEffect(() => {
    fetchServiceProposalCooGroups();
  }, [i18n.language, typeId]);


  return (
    <div className="maintenance-step">
      {loading ? <Spinner class="small" /> :
        <React.Fragment>
          {searchParams.get('step')?.includes('3') ?
            <div className="preview-maintenance-step">
              <div className="preview-item">
                <div className="preview-icon">
                  <FontAwesomeIcon icon={faGears} />
                </div>
                <div className="preview-text">
                  {getPreviewItem()?.QualColText}
                </div>
              </div>
            </div>
            :
            <Controller
              control={control}
              name="gearbox"
              render={({ field: { onChange, value } }) => (
                <Select
                  value={value}
                  className="configuration-dropdown"
                  onChange={(val) => {
                    onChange(val);
                    props.setStep!('123');
                    searchParams.set('step', '123');
                    searchParams.set('cooGroupId', val.value);
                    setSearchParams(searchParams);
                  }}
                  options={gearBoxOptions()}
                  placeholder={t('_select_service_variant')}
                  menuPlacement="auto"
                  isSearchable={!isMobile && !isTablet}
                />
              )}
            />
          }
          { }
        </React.Fragment>
      }
    </div>
  );
};

export default StepTwo;