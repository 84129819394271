import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import React, { useRef, useState } from 'react';
import './PhoneDropdown.scss';
import { useOnClickOutside } from '../../../utils/hooks/useOnClickOutside';
import { useTranslation } from 'react-i18next';

const PhoneDropdown: React.FC = () => {
  const [show, setShow] = useState<boolean>(false);
  const dropdownRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const { t } = useTranslation();

  useOnClickOutside(dropdownRef, () => setShow(false));
  const user = JSON.parse(localStorage.getItem('userData')!) || {};
  const { distributor_info } = user;

  return (
    <div ref={dropdownRef} className="account-dropdown-phone" onClick={() => setShow(!show)}>
      <FontAwesomeIcon icon={faPhone} className="phone-icon" />
      <div className={show ? 'show' : 'hide'}>
        <div className="content-data">
          <FontAwesomeIcon icon={faPhone} className="phone-info" />
          {t('_service_client')}:{' '}
          <a href={`tel:${distributor_info?.main_address?.tel}`} className="phone-text">
            {distributor_info?.main_address?.tel}
          </a>
        </div>
      </div>
    </div>
  );
};

PhoneDropdown.displayName = 'PhoneDropdown';

export default PhoneDropdown;
