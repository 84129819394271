import React from 'react';
import { ICarPartData } from '../../../models/car-parts.model';
import './PartItemDetails.scss';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { notify } from 'utils/marketplace';
import { faFileClipboard } from '@fortawesome/free-regular-svg-icons';
import { isRetailerMecadepotChild } from 'utils/isRetailerMecadepotChild';

type Props = {
  carPart: ICarPartData;
}

const PartItemDetails: React.FC<Props> = (props) => {
  const carPart = props.carPart;
  const { t } = useTranslation();
  const isMecadepotChild = isRetailerMecadepotChild();

  function attributesMap(attributes) {
    const attributesMap = {};

    attributes.forEach((attribute) => {
      const { name, value } = attribute;

      if (attributesMap[name]) {
        attributesMap[name].push(value);
      } else {
        attributesMap[name] = [value];
      }
    });

    const resultArray = Object.keys(attributesMap).map((name) => {
      const values = attributesMap[name].map((val, index) => (
        <span key={index} style={{ fontWeight: 'bold' }}>{val}</span>
      )).reduce((prev, curr) => [prev, ', ', curr]);

      return <div key={name}>{name}: {values}</div>;
    });

    return resultArray;
  }

  function copyToClipboard() {
    navigator.clipboard.writeText(carPart.data_supplier_reference_original);
    notify(t('_reference_copied'), 'success');
  }

  return (

    <div className="car-part-details-wrapper">
      <div className="car-detail__header">
        <div className="car-detail__header__article">
          <span>{t('_reference')}</span> :
          {` ${carPart.data_supplier_reference_original}`}  {isMecadepotChild && <FontAwesomeIcon icon={faFileClipboard} onClick={copyToClipboard} />}
        </div>
        <h5 className="car-detail__header__title">{`${carPart.data_supplier.name} ${carPart.data_supplier_reference_original}`}</h5>
        <div className="car-detail__header__category">
          {`${carPart.subcategory?.name ? carPart.subcategory?.name : ''}`}
        </div>
        <div className="car-detail__header__set">
          {carPart.quantity && Number(carPart.quantity) ? `${carPart.quantity} ${t('_items_in_pack')}, ` : ''}
        </div>
      </div>
      {
        carPart.attributes ?
          <div className="car-detail__content_description_attributes">
            {
              attributesMap(carPart.attributes).map((attribute, index) => (
                <div className="content-item" key={index}>
                  {attribute}
                </div>
              ))
            }
          </div> : null
      }
      <div className="car-detail__content">
        {
          carPart.characteristics.map((characteristic, index) => (
            <div className="content-item" key={index}>
              <p className="label">{`${characteristic.name.startsWith('_') ? t(characteristic.name) : characteristic.name}`}: </p>
              <p className="value">{characteristic.name === '_reference_commerciale' ? <span className="commercial-ref-value">{characteristic.value}</span> : characteristic.value} {characteristic.unit ? characteristic.unit : ''}</p>
            </div>
          ))
        }
      </div>
    </div>
  );
};

PartItemDetails.displayName = 'PartItemDetails';


export default PartItemDetails;