import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileLines } from '@fortawesome/free-solid-svg-icons';

import './FileButton.scss';
import Spinner from 'components/spinner/Spinner';
import { getCurrentMarketplace } from 'utils/marketplace';
import { Marketplaces } from 'utils/constants/applications';

type Props = {
  onClick?: () => void;
  loading?: boolean;
  value?: number;
  limit?: number | string | { quantity: number };
  addToCart?: boolean
  price?: string
  disabled?: boolean
}
const FileButton: React.FC<Props> = (props) => {
  function disableCartBtn(){
    if(currentMarketplace == Marketplaces.RETAILER || currentMarketplace == Marketplaces.PUNCHOUT) {
      if(props.disabled) {
        return true;
      }

      if(props.limit && Number(props.limit) < value && !props.addToCart){
        return true;
      }

      if(props.loading || !props.value){
        return true;
      }

      if(Number(props.limit) === 0 && !props.price){
        return true;
      }

      if(!(props.limit && Number(props.limit) < value) && props.addToCart){
        return false;
      }
    }
  }

  const value = props?.value ?? 0;
  const currentMarketplace = getCurrentMarketplace();

  return (
    <div className={`cart-file-button ${disableCartBtn() ? 'disable_add_cart' : ''}`} onClick={props.onClick}>
      {props.loading ? <Spinner class="extra-small round-cart-spinner"></Spinner> :  <FontAwesomeIcon icon={faFileLines} className="file-icon" />}
    </div>
  );
};

FileButton.displayName = 'FileButton';

export default FileButton;
