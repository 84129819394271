
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './ModalDelete.scss';
import Spinner from 'components/spinner/Spinner';

import { notify } from 'utils/marketplace';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faRemove } from '@fortawesome/free-solid-svg-icons';

type Props = {
  setOpenModalDelete?: (e: boolean) => void;
  setSubmit?: (e: boolean) => void;
  id?: number | undefined;
  link;
  onDone?: () => void;
  class?: string;
  selectedIds?: Array<number | string>;
  setSelectedIds?;
  setSelectedData?;
};


const ModalDelete: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);

  const deleteItem = () => {
    setLoading(true);

    let link;


    if (Number(props.id)) {
      link = props.link(Number(props.id));
    } else {
      if (props.selectedIds?.length) {
        link = props.link(props?.selectedIds);
      } else {
        link = props.link();
      }
    }

    link.then(() => {
      notify(t('_item_deleted'), 'success', t('_success'));

      if (props.setOpenModalDelete) {
        props.setOpenModalDelete(false);
      }

      if (props.setSubmit) {
        props.setSubmit(true);
      }

      if (props.onDone) {
        props.onDone();
      }

      if (props.selectedIds?.length && props.setSelectedIds) {
        props.setSelectedIds([]);
        props.setSelectedData([]);
      }

      setLoading(false);

    }).catch((error) => {
      let erorrs = '';

      Object.entries(error).forEach(([key, value]) => {
        erorrs += value;
      });
      notify(erorrs, 'error', t('_error'));
      setLoading(false);
    });

  };

  return (
    <div className={`delete-container ${props.class ? props.class : ''}`} >

      <div className="title">
        {t('_confirm_delete')}
        <br />
        <span>{t('_confirm_delete_title')}</span>
      </div>
      <div className="confirm-buttons">

        {loading ? <Spinner class="extra-small" /> :
          <React.Fragment>
            <button className="button-edit" onClick={deleteItem}>
              <FontAwesomeIcon icon={faCheck} className="icon-edit" /> {t('_yes')}
            </button>
            <button className="button-delete" onClick={() => { props.setOpenModalDelete && props.setOpenModalDelete(false); }}>
              <FontAwesomeIcon icon={faRemove} className="icon-delete" /> {t('_no')}
            </button>
          </React.Fragment>
        }


      </div>
    </div>

  );

};

ModalDelete.displayName = 'ModalDelete';

export default ModalDelete; 