
import TextInput from 'components/inputs/TextInput/TextInput';
import Select from 'react-select';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useHttp from 'utils/hooks/useHttp';
import './Edit.scss';

import UploadImage from '../../../../components/UploadImage/UploadImage';
import Spinner from 'components/spinner/Spinner';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { notify } from 'utils/marketplace';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { IBatteryInfo, IBatterySelect } from 'interfaces/battery';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import UploadPdf from 'components/UploadPdf/UploadPdf';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';

type Props = {
  setOpenModal: (e: boolean) => void;
  setSubmit: (e: boolean) => void;
  battery: number | undefined;
};


const Edit: React.FC<Props> = (props) => {

  let isMounted = true;
  const { t } = useTranslation();
  const http = useHttp();

  const [brands, setBrands] = useState<IBatterySelect[]>([]);
  const [brand, setBrand] = useState<IBatterySelect | null>();

  const [categories, setCategories] = useState<IBatterySelect[]>([]);
  const [category, setCategory] = useState<IBatterySelect | null>();
  const [loadingErpPartInfo, setLoadingErpPartInfo] = useState<boolean>(false);
  const [image, setImage] = useState<File[]>([]);
  const { isMobile, isTablet } = useDeviceDetect();
  const [file, setFile] = useState<File[]>([]);
  const [battery, setbattery] = useState<IBatteryInfo>();
  const [loading, setLoading] = useState<boolean>(false);
  const user = JSON.parse(localStorage.getItem('userData')!);

  const validate = Yup.object().shape({
    reference: Yup.string().trim().required(t('_fild_required')),
    // type: Yup.string().trim().required(t('_fild_required')),
    ah: Yup.string().trim().required(t('_fild_required')),
    amp: Yup.string().trim().required(t('_fild_required')),
    size: Yup.string().trim().required(t('_fild_required')),
    short_description: Yup.string().trim().required(t('_fild_required')),
    // description: Yup.string().trim().required(t('_fild_required')),
    // gencode: Yup.string().trim().required(t('_fild_required')),
    // image: Yup.string().required(t('_fild_required')),
  });

  const { getValues, register, resetField, handleSubmit, control, clearErrors, setValue, formState: { errors }, } = useForm<IBatteryInfo>({ resolver: yupResolver(validate) });

  useEffect(() => {
    if (props.battery && isMounted) {
      Promise.all([
        http.batteryAdminHttp.getBatteryBrand({ per_page: 1000 }),
        http.batteryAdminHttp.getBatteryCategory({ per_page: 1000 }),
        http.batteryAdminHttp.getBatteryInfo(props.battery)])
        .then((res) => {

          const setBrandData = res[0].data.data.map((item) => {
            return { value: item.id, label: item.name };
          });

          setBrands(setBrandData);

          const setCategoryData = res[1].data.data.map((item) => {
            return { value: item.id, label: item.name };
          });

          setCategories(setCategoryData);
          setbattery(res[2].data);

          setBrand({ value: res[2].data.brand.id, label: res[2].data.brand.name });
          setCategory({ value: res[2].data.category.id, label: res[2].data.category.name });
          setValue('image', res[2].data?.image ? res[2].data?.image : '');

        });
    }

    return () => {
      isMounted = false;
    };
  }, []);

  const getErpPart = () => {
    let erpClientId = null;
    const values = getValues();
    const reference = values?.reference.trim();
    const brand_id = brand?.value;

    if (localStorage.getItem('infoSelectClientErp')) {
      const info = JSON.parse(localStorage.getItem('infoSelectClientErp') as string);

      erpClientId = info.erpClientId;
    }

    if (erpClientId == null || reference == '' || brand_id == undefined) {
      if (erpClientId == null) {
        notify(t('_select_client'), 'error', t('_error'));
      }

      if (reference == '') {
        notify(t('_reference'), 'error', t('_error'));
      }

      if (brand_id == undefined) {
        notify(t('_brand_id'), 'error', t('_error'));
      }

      return;
    } else {

      const param = {
        erp_id: erpClientId,
        reference: reference,
        brand_id: brand_id,
        type: 'battery'
      };

      setLoadingErpPartInfo(true);

      if (values.short_description) {
        resetField('short_description');
      }

      http.retailerHttp.getErpPart(param).then((data) => {

        if (data.data?.description == undefined) {
          notify(t('_not_found_description'), 'error', t('_error'));
        } else {
          setValue('short_description', data.data?.description);
        }

      }).finally(() => {
        setLoadingErpPartInfo(false);
      });
    }


  };

  const onSubmit = async () => {
    setLoading(true);
    const values = getValues();

    const formData: FormData = new FormData();

    formData.append('reference', values.reference.trim());
    formData.append('brand', String(brand?.value));
    formData.append('category', String(category?.value));
    formData.append('type', String(values.type).trim() ?? '');
    formData.append('ah', String(values.ah).trim());
    formData.append('amp', values.amp.trim());
    formData.append('size', String(values.size).trim());
    formData.append('description', String(values.description).trim());
    formData.append('short_description', String(values.short_description).trim());
    formData.append('top_offer', String(values.top_offer));
    formData.append('gencode', String(values.gencode).trim());

    if (file.length) {
      formData.append('file', file[0]);
    }

    if (image.length) {
      formData.append('image', image[0]);
    }

    http.batteryAdminHttp.postBattery(Number(props?.battery), formData).then(() => {
      notify(t('_battery_updated'), 'success', t('_success'));
    })
      .finally(() => {
        setLoading(false);
        props.setOpenModal(false);
        props.setSubmit(true);
      });
  };

  return (
    <div className="battery-edit-container" >
      <div className="close-modal" onClick={() => { props.setOpenModal(false); }}><FontAwesomeIcon icon={faClose} /></div>

      <div className="title">
        {t('_battery_title_edit')}
        <br />
        <span>{t('_battery_title_edit_catalog')}</span>
      </div>


      {
        !battery ?
          <div className="spinner-info"> <Spinner />  </div> :
          <React.Fragment >
            <div className="info-item">
              {t('_battery_item_count')}
              <br />
              <span>{battery.brand.name} {battery?.reference}</span>
            </div>

            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="battery-edit-body">
                <div className="row">

                  <div className="container">
                    <label  >
                      {t('_battery_brand')} <span className="placeholderRed"> * </span>
                    </label>
                    <Controller
                      control={control}
                      name="brand"
                      render={({ field: { onChange } }) => (
                        <Select
                          value={brand}
                          className="select-dropdown "
                          onChange={(val) => {
                            onChange(val?.value);
                            setBrand(val);
                          }}
                          options={brands}
                          isSearchable={!isMobile && !isTablet}
                        />
                      )}
                    />
                  </div>
                  <div className="container">
                    <label  >
                      {t('_category')} <span className="placeholderRed"> * </span>
                    </label>
                    <Controller
                      control={control}
                      name="category"
                      render={({ field: { onChange } }) => (
                        <Select
                          value={category}
                          className="select-dropdown "
                          onChange={(val) => {
                            onChange(val?.value);
                            setCategory(val);
                          }}
                          options={categories}
                          isSearchable={!isMobile && !isTablet}
                        />
                      )}
                    />
                  </div>

                  <div className={`container ${errors.reference?.type == 'required' ? 'error' : ''}`}>
                    <label  >
                      {t('_reference')} <span className="placeholderRed"> * </span>
                    </label>
                    <TextInput defaultValue={battery?.reference} {...register('reference')} />
                    <p className="error-message">{errors.reference?.type == 'required' ? errors.reference?.message : ''}</p>
                  </div>

                  <div className="container">
                    <label >
                      {t('_battery_type')}
                    </label>
                    <TextInput defaultValue={battery?.type} {...register('type')} />
                    <p className="error-message">{errors.type?.type == 'required' ? errors.type?.message : ''}</p>
                  </div>
                </div>

                <div className="row">
                  <div className={`container ${errors.ah?.type == 'required' ? 'error' : ''}`}>
                    <label  >
                      {t('_battery_ah')} <span className="placeholderRed"> * </span>
                    </label>
                    <TextInput {...register('ah')} defaultValue={battery?.ah} />
                    <p className="error-message">{errors.ah?.type == 'required' ? errors.ah?.message : ''}</p>
                  </div>

                  <div className={`container ${errors.amp?.type == 'required' ? 'error' : ''}`}>
                    <label>
                      {t('_battery_amp')} <span className="placeholderRed"> * </span>
                    </label>
                    <TextInput {...register('amp')} defaultValue={battery?.amp} />
                    <p className="error-message">{errors.amp?.type == 'required' ? errors.amp?.message : ''}</p>
                  </div>


                </div>
                <div className="row">
                  <div className={`container ${errors.size?.type == 'required' ? 'error' : ''}`}>
                    <label  >
                      {t('_battery_size')} <span className="placeholderRed"> * </span>
                    </label>
                    <TextInput {...register('size')} defaultValue={battery?.size} />
                    <p className="error-message">{errors.size?.type == 'required' ? errors.size?.message : ''}</p>
                  </div>
                  <div className="container">
                    <label>
                      {t('_gencode')}
                    </label>
                    <TextInput defaultValue={battery?.gencode} {...register('gencode')} />
                    <p className="error-message">{errors.gencode?.type == 'required' ? errors.gencode?.message : ''}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="container top-offer-checkbox-container">
                    <label htmlFor="top_offer">{t('_top_offer')}</label>
                    <input type="checkbox" id="top_offer" defaultChecked={battery?.top_offer} {...register('top_offer')} />
                  </div>
                  {user?.hasTopSales ?
                    <div className="container top-offer-checkbox-container">
                      <label htmlFor="top_sales">{t('_top_sales')}</label>
                      <input type="checkbox" id="top_sales" defaultChecked={battery?.top_sales} {...register('top_sales')} />
                    </div>
                    : null}
                </div>
                <div className="row">


                  <div className={`container ${errors.image?.type == 'required' ? 'error' : ''}`}>
                    <UploadImage setFile={setImage} image={battery?.image} errors={errors} setValue={setValue} clearErrors={clearErrors} />
                    <span className="upload_image_info"> {t('_upload_image_info')}</span>
                  </div>

                  <div className={`container ${errors.file?.type == 'required' ? 'error' : ''}`}>
                    <UploadPdf setFile={setFile} errors={errors} pdf={battery?.file} clearErrors={clearErrors} setValue={setValue} title="_technical_sheet" />
                  </div>
                </div>

                <div className={`container ${errors.short_description?.type == 'required' ? 'error' : ''}`}>
                  <div className="container">
                    <label>
                      {t('_short_description')} <span className="placeholderRed"> * </span>
                    </label>
                    <TextInput defaultValue={battery?.short_description} {...register('short_description')} />
                    <p className="error-message">{errors.short_description?.type == 'required' ? errors.short_description?.message : ''}</p>
                  </div>
                </div>

                <div className="row file-container">
                  <div className="container">

                    <div className="description_info">
                      <label>
                        {t('_battery_description')}
                      </label>
                      <DefaultButton type="button" red text={t('_get_erp_description')} onClick={() => getErpPart()} isloading={loadingErpPartInfo} />
                    </div>
                    <textarea rows={7} defaultValue={battery?.description}
                      {...register('description', {})}
                    />
                    <p className="error-message">{errors.description?.type == 'required' ? errors.description?.message : ''}</p>

                  </div>

                </div>
              </div>
              <div className="btn-save">
                <button type="submit" className={loading ? 'disabled' : ''}> {loading ? <Spinner class="extra-small" /> : null} <div className="title-btn-save">{t('_battery_update_article')}</div></button>
              </div>
            </form>
          </React.Fragment>
      }

    </div>

  );

};

Edit.displayName = 'Edit';

export default Edit; 