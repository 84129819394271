import React, { PropsWithChildren, useState } from 'react';
import './DeleteConfirmationListModal.scss';
import { IFavoriteList } from 'models/favorite';
import useHttp from 'utils/hooks/useHttp';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import { useTranslation } from 'react-i18next';
import { ICarPartData } from 'models/car-parts.model';
import { notify } from '../../../../../../../utils/marketplace';

type Props = {
  setOpenModal: (e: boolean) => void;
  favoriteList: IFavoriteList | null;
  onDone: () => void;
  setFavoriteItems: (favoriteItems: ICarPartData[]) => void;

};

const DeleteConfirmationListModal: React.FC<Props> = (props: PropsWithChildren<Props>) => {

  const [loading, isLoading] = useState<boolean>(false);
  const http = useHttp();
  const { t } = useTranslation();


  const deleteListItems = async () => {

    try {
      isLoading(true);
      await http.favorite.deleteFromList(String(props.favoriteList?.id));
      props.setOpenModal(false);
      props.setFavoriteItems([]);
      props.onDone();
    } catch (error) {
      notify(t('_error'));

    } finally {
      console.log('finally');
      isLoading(false);
    }
  };

  return (
    <div className="delete-confirmation-modal-list">
      <div className="modal-dialog">

        <div className="modal-container">

          <div className="modal-body">
            <div className="content">
              <div className="message">
                <p dangerouslySetInnerHTML={{ __html: t('_confirmation_list_modal', { list: props.favoriteList?.name }) }} />
              </div>
              <div className="actions">
                <div className="no">
                  <DefaultButton text={t('_no')} onClick={() => props.setOpenModal(false)} />
                </div>
                <div className="yes">
                  <DefaultButton text={t('_yes')} isloading={loading} onClick={deleteListItems} blue />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

DeleteConfirmationListModal.displayName = 'DeleteConfirmationListModal';

export default DeleteConfirmationListModal;
