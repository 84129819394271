import Modal from 'components/modal/Modal';
import Spinner from 'components/spinner/Spinner';
import i18n from 'i18n';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { generatePath, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { RootState } from 'store/store';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';

import useHttp from 'utils/hooks/useHttp';
import useReliableCatalogCarQueryParams from 'utils/hooks/useReliableCatalogCarQueryParams';
import { converObjectToParams } from 'utils/marketplace';
import { IAdjustListMainGroup, IAdjustListSubGroup } from 'utils/rmi/interface/adjust.interface';
import { getCurrentCountryRMI, getCurrentLanguageRMI } from 'utils/rmi/rmi-utils/rmi-utils';
import IframeModal from '../../ModulesMobileCard/IframeModal/IframeModal';
import ModulesMobileCard from '../../ModulesMobileCard/ModulesMobileCard';
import SidebarDetails from '../../SidebarDetails/SidebarDetails';
import VehicleIframe from '../../VehicleIframe/VehicleIframe';
import './Adjust.scss';

const Adjust: React.FC = () => {
  const http = useHttp();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { typeId } = useSelector((state: RootState) => state.tecrmi);
  const { isMobile } = useDeviceDetect();

  const [mainGroup, setMainGroup] = useState<IAdjustListMainGroup[]>();
  const [itemMps, setItemMps] = useState<IAdjustListSubGroup>();
  const [subGroup, setSubGroup] = useState<IAdjustListMainGroup>();

  const [loadingList, setLoadingList] = useState(false);
  const [loadingHTML, setLoadingHTML] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const { vehicle_id } = useParams();

  const [iframeData, setIframeData] = useState<string>();
  const [sidebarDetails, setSidebarDetails] = useState(Number(searchParams.get('level')) || 1);

  const [activeItem, setActiveItem] = useState<number>();
  const [showModal, setShowModal] = useState(false);

  const carParams = useReliableCatalogCarQueryParams();

  function fetchAdjustList() {
    if (typeId) {
      setLoadingList(true);
      http.adjustHttp
        .fetchRMIVehicleAdjustList({
          typeId: typeId,
          countryCode: getCurrentCountryRMI(i18n.language),
          languageCode: getCurrentLanguageRMI(i18n.language),
        })
        .then((res) => setMainGroup(res.data))
        .finally(() => {
          setLoadingList(false);
        });
    }
  }

  function fetchAdjustMainGroupHtml(id) {
    setLoadingHTML(true);
    http.adjustHttp
      .fetchRMIVehicleAdjustMainGroupHtml({
        typeId: typeId,
        countryCode: getCurrentCountryRMI(i18n.language),
        languageCode: getCurrentLanguageRMI(i18n.language),
        mainGroupId: id,
        printView: true,
      })
      .then((res) => setIframeData(res.data))
      .finally(() => {
        setLoadingHTML(false);
      });
  }

  function fetchAdjustSubGroupHtml(id) {
    setLoadingHTML(true);
    http.adjustHttp
      .fetchRMIVehicleAdjustSubGroupHtml({
        typeId: typeId,
        countryCode: getCurrentCountryRMI(i18n.language),
        languageCode: getCurrentLanguageRMI(i18n.language),
        subGroupId: id,
        printView: true,
      })
      .then((res) => setIframeData(res.data))
      .finally(() => {
        setLoadingHTML(false);
      });
  }

  function fetchAdjustItemMpsHtml(id) {
    setLoadingHTML(true);
    http.adjustHttp
      .fetchRMIVehicleAdjustItemHtml({
        typeId: typeId,
        countryCode: getCurrentCountryRMI(i18n.language),
        languageCode: getCurrentLanguageRMI(i18n.language),
        itemMpId: id,
        printView: true,
      })
      .then((res) => setIframeData(res.data))
      .finally(() => {
        setLoadingHTML(false);
      });
  }

  function onMainGroupClick(item) {
    setSubGroup(item);
    setSidebarDetails(2);
    searchParams.set('group_id', item.MainGroupId);
    searchParams.set('level', '2');
    setSearchParams(searchParams);
    isMobile && fetchAdjustMainGroupHtml(Number(searchParams.get('group_id')));
    iframeData && setShowModal(true);
  }

  function onSubGroupClick(item) {
    setItemMps(item);
    setSidebarDetails(3);
    searchParams.set('subgroup_id', item.SubGroupId);
    searchParams.set('level', '3');
    setSearchParams(searchParams);
    isMobile && fetchAdjustSubGroupHtml(Number(searchParams.get('subgroup_id')));
    iframeData && setShowModal(true);
  }

  function onItemMpClick(item) {
    setActiveItem(item.ItemMpId);
    searchParams.set('item_id', item.ItemMpId);
    setSearchParams(searchParams);
    isMobile && fetchAdjustItemMpsHtml(Number(searchParams.get('item_id')));
    iframeData && setShowModal(true);
  }

  function onReturnClick() {
    if (sidebarDetails === 1) {
      navigate({
        pathname: generatePath('/reliable/rmi/:vehicle_id/technical-documentation', {
          vehicle_id: String(vehicle_id),
        }),
        search: converObjectToParams(carParams),
      });

      return;
    }

    if (searchParams.get('level') === '3') {
      searchParams.delete('item_id');
      setSearchParams(searchParams);
    }

    if (searchParams.get('level') === '2') {
      searchParams.delete('subgroup_id');
      setSearchParams(searchParams);
    }

    navigate(-1);
    setSidebarDetails((prev) => prev - 1);
  }

  useEffect(() => {
    fetchAdjustList();
  }, [typeId, i18n.language]);

  useEffect(() => {
    if (!mainGroup) {
      return;
    }

    if (Number(searchParams.get('group_id'))) {
      setSubGroup(mainGroup.find((item) => item.MainGroupId === Number(searchParams.get('group_id'))));
    }

    if (!subGroup) {
      return;
    }

    if (Number(searchParams.get('subgroup_id'))) {
      setItemMps(subGroup?.SubGroups.find((item) => item.SubGroupId === Number(searchParams.get('subgroup_id'))));
    }

    if (Number(searchParams.get('item_id'))) {
      setActiveItem(Number(searchParams.get('item_id')));
    }
  }, [mainGroup, subGroup]);

  useEffect(() => {
    if (searchParams.get('group_id') && typeId && !searchParams.get('subgroup_id') && !isMobile) {
      fetchAdjustMainGroupHtml(Number(searchParams.get('group_id')));
    } else if (searchParams.get('group_id') && typeId && !searchParams.get('subgroup_id') && isMobile) {
      fetchAdjustMainGroupHtml(Number(searchParams.get('group_id')));
      iframeData && setShowModal(true);
    }

    if (searchParams.get('subgroup_id') && typeId && !searchParams.get('item_id') && !isMobile) {
      fetchAdjustSubGroupHtml(Number(searchParams.get('subgroup_id')));
    } else if (searchParams.get('subgroup_id') && typeId && !searchParams.get('item_id') && isMobile) {
      fetchAdjustSubGroupHtml(Number(searchParams.get('subgroup_id')));
      iframeData && setShowModal(true);
    }

    if (searchParams.get('item_id') && typeId && !isMobile) {
      fetchAdjustItemMpsHtml(Number(searchParams.get('item_id')));
    }
    else if (searchParams.get('item_id') && typeId && isMobile && !activeItem) {
      fetchAdjustItemMpsHtml(Number(searchParams.get('item_id')));
      iframeData && setShowModal(true);
    }
  }, [searchParams.get('group_id'), searchParams.get('subgroup_id'), searchParams.get('item_id'), typeId, i18n.language, isMobile, iframeData]);

  useEffect(() => {
    if (searchParams.get('item_id')) {
      mainGroup?.forEach(subGroup => subGroup.SubGroups.forEach(itemMps => itemMps.ItemMps.forEach(itemMp => {
        if (String(itemMp.ItemMpId) === searchParams.get('item_id')) {
          setSubGroup(subGroup);
          setItemMps(itemMps);
          setActiveItem(itemMp.ItemMpId);
          setSidebarDetails(3);
        }
      })
      )
      );
    }
  }, [mainGroup]);

  return (
    <div className="technical-documentation-adjust">
      {loadingList ? (
        <Spinner class="extra-small" />
      ) : (
        !isMobile ?
          <React.Fragment>
            <div className="tdoc-sidebar">
              {sidebarDetails === 1 && (
                <SidebarDetails
                  title={`<span>${t('_adjust')}</span>`}
                  itemName="MainGroupName"
                  onClick={onMainGroupClick}
                  data={mainGroup}
                  onReturnClick={onReturnClick}
                />
              )}
              {sidebarDetails === 2 && (
                <SidebarDetails
                  title={`${t('_adjust')} / <span>${subGroup?.MainGroupName}</span>`}
                  itemName="SubGroupName"
                  onClick={onSubGroupClick}
                  data={subGroup?.SubGroups}
                  onReturnClick={onReturnClick}
                />
              )}
              {sidebarDetails === 3 && (
                <SidebarDetails
                  title={`${t('_adjust')} / ${subGroup?.MainGroupName} / <span>${itemMps?.SubGroupName}</span>`}
                  itemId="ItemMpId"
                  itemName="ItemMpText"
                  onClick={onItemMpClick}
                  data={itemMps?.ItemMps}
                  onReturnClick={onReturnClick}
                  activeItem={activeItem}
                />
              )}
            </div>
            <div className="tdoc-content">{iframeData && <VehicleIframe loading={loadingHTML} vehicleIframe={iframeData!} />}</div>
          </React.Fragment>
          :
          <div className="tdoc-content">
            <ModulesMobileCard
              iframeInfo
              title={t('_adjust')}
              data={mainGroup}
              onMainGroupClick={onMainGroupClick}
              onSubGroupClick={onSubGroupClick}
              onItemMpClick={onItemMpClick}
              setOpenModal={setShowModal}
            />
            <Modal
              openModal={showModal}
              setOpenModal={setShowModal}
              childrenComponent={<IframeModal title={t('_adjust')} loading={loadingHTML} setOpenModal={setShowModal} vehicleIframe={iframeData!} />}
            />
          </div>
      )}
    </div>
  );
};

export default Adjust;
