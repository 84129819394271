import React from 'react';
import {  faPen, faRemove } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Placeholder from 'assets/images/car-part-placeholder.png';
import Spinner from 'components/spinner/Spinner';
import { useTranslation } from 'react-i18next';
import { getMarketplaceBaseUrl } from 'utils/marketplace';
import { INonTecdocPartSupplier } from 'interfaces/nonTecdocCatalog';
import './NonTecdocSupplierPart.scss';

type Props = {
  data:INonTecdocPartSupplier[],
  loading:boolean,
  openModalNonTecdoc:(id:number)=>void;
  deleteItem:(id:number)=>void
};

const NonTecdocSupplierPart: React.FC<Props> = (props) => {
  const {t} = useTranslation();

  return (
    <div className="item-container-shop-non_tecdoc_supplier">
      {
        props.loading ? <Spinner /> :
          props.data.length ?
            props.data.map((item, index) => {
              return (
                <div className="item-shop" key={index}>
                  <div className="header">
                    <div className="content">
                      <div className="title">{item.name}</div>
                    </div>
                  </div>
                  <div className="body">
                    <div className="img">
                      <img src={item.image ? `${getMarketplaceBaseUrl()}${item.image}` : Placeholder}  />
                    </div>
                  </div>
                  <div className="footer">
                    <div className="cart">
                      <div className="cart-button">
                        <button className="button-edit" onClick={() => props.openModalNonTecdoc(item.id)}>
                          <FontAwesomeIcon icon={faPen} className="icon-edit"  />
                        </button>
                        <button className="button-delete" onClick={() => props.deleteItem(item.id)}>
                          <FontAwesomeIcon icon={faRemove} className="icon-delete" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }) :
            <div className="no-data">
              {t('_no_data')}
            </div>
      }
    </div>
  );
};

export default NonTecdocSupplierPart;