import { ICountries, IData, IDataPagination, IFilterForm, IZoneCommercials } from 'interfaces/selectClient';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { login } from 'store/reducers/useSlices';
import { useAppDispatch } from 'utils/hooks/redux';
import useHttp from 'utils/hooks/useHttp';
import useLogOut from 'utils/hooks/useLogOut';
import SelectClientView from './SelectClient.view';
import { IOption } from '../../../../interfaces/select';
import { resetTariffs } from 'store/slices/automotor-tariff.slice';

type Props = {
  slider?: boolean;
};

const SelectClient: React.FC<Props> = (props) => {
  const http = useHttp();
  const [responseClient, setResponseClient] = useState<IDataPagination & IData[]>();
  const [zoneCommercials, setZoneCommercials] = useState<IZoneCommercials[]>([]);
  const [countries, setcountries] = useState<ICountries[]>([]);
  const [curentPage, setCurentPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingSaveClient, setLoadingSaveClient] = useState<boolean>(false);
  const [infoClients, setInfoClients] = useState<IData[]>([]);
  const { getValues, register, handleSubmit, reset, control } = useForm<IFilterForm>();
  const [valueCountries, setValueCountries] = useState<IOption | null>(null);
  const [valueZoneCommercials, setValueZoneCommercials] = useState<IOption | null>(null);
  const [clientIdForSpinner, setClientIdForSpinner] = useState<number>(Number(localStorage.getItem('clientId')) || 0);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const logout = useLogOut();
  const dispatch = useAppDispatch();

  useEffect(() => {
    feachRequest();
    fetchZoneCommercial();
  }, []);

  const fetchZoneCommercial = () => {
    http.selectClient.zoneCommercials().then((res) => setZoneCommercials(res.data));
  };

  useEffect(() => {
    if (responseClient) {
      setLoading(true);
      setCurentPage(Number(searchParams.get('page')) || 1);
      setTotalPage(responseClient.total_pages);
      setInfoClients(responseClient.data);
      setLoading(false);
    }
  }, [responseClient]);

  const optionsZoneCommercials = () => {
    return (
      zoneCommercials?.map((item) => ({
        label: item.name,
        value: item.id,
      })) || []
    );
  };

  const optionsCountries = () => {
    return (
      countries?.map((item) => ({
        label: item.name,
        value: item.id,
      })) || []
    );
  };

  const onSubmit = async () => {
    setLoading(true);
    const data = getValues();

    await feachSelectClient(data);
    setLoading(false);
  };

  const resetFilter = async () => {
    resetData();

    const data = getValues();

    await feachSelectClient(data);
  };

  function resetData() {
    setLoading(true);
    setLoading(false);
    setValueCountries(null);
    setValueZoneCommercials(null);
    reset({ name: '' });
    setLoading(false);
  }

  const feachSelectClient = async (param: IFilterForm) => {

    setLoading(true);
    const responseClient = await http.selectClient.selectClient(param);

    setResponseClient(responseClient.data);
  };

  const feachRequest = async () => {
    Promise.all([feachSelectClient({ page: Number(searchParams.get('page') || 1) }), http.selectClient.countries()])
      .then((res) => {
        setcountries(res[1].data);
      })
      .finally(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handlePageClick = async (page: number) => {
    setLoading(true);
    const selectPage = page;
    const params = getValues();

    await feachSelectClient({ ...params, page: selectPage || 1 });
    setLoading(false);
  };

  const handleSelectClient = async (clientId: number) => {
    setClientIdForSpinner(clientId);
    setLoadingSaveClient(true);
    localStorage.setItem('clientId', String(clientId));
    await http.selectClient.saveClientForSeller(clientId);
    const user = await http.user.infoUser();

    localStorage.setItem('userData', JSON.stringify(user.data));
    dispatch(login(user.data));
    setLoadingSaveClient(false);
    navigate('/');
    dispatch(resetTariffs());
  };

  return (
    <SelectClientView
      curentPage={curentPage}
      totalPage={totalPage}
      infoClients={infoClients}
      handlePageClick={handlePageClick}
      loading={loading}
      optionsZoneCommercials={optionsZoneCommercials()}
      optionsCountries={optionsCountries()}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      register={register}
      resetFilter={resetFilter}
      control={control}
      valueCountries={valueCountries}
      setValueCountries={setValueCountries}
      valueZoneCommercials={valueZoneCommercials}
      setValueZoneCommercials={setValueZoneCommercials}
      handleSelectClient={handleSelectClient}
      loadingSaveClient={loadingSaveClient}
      clientIdForSpinner={clientIdForSpinner}
      logout={logout}
      slider={props.slider}
    />
  );
};

SelectClient.displayName = 'SelectClient';

export default SelectClient;
