import { CancelToken } from 'axios';
import {
  IBodyResponseConsumable,
  IBodyResponseConsumableBrands,
  IBodyResponseConsumableCategories,
  IConsumableInfo,
  IConsumableSearch,
  IParamsConsumable,
  IParamsPagination,
} from 'interfaces/consumable';
import { addRouteParam, IHttpResponse, serverAxios } from '../../http';

class ConsumableHttp {
  private static list = '/consumable/list';
  private static brands = '/consumable/brand';
  private static category = '/consumable/category';
  private static info = '/consumable/';
  private static update = '/consumable/update/';
  private static updateTopOffer = '/consumable/update/:id/top_offer';
  private static updateTopSales = '/consumable/update/:id/top_sales';
  private static create = '/consumable/register';
  private static delete = '/consumable/';
  private static search = '/consumable/search';
  private static deleteAll = '/consumable/delete_all';
  private static deleteSelected = '/consumable/delete_all';

  public getConsumable(urlParams: IParamsConsumable | {}, cancelToken: CancelToken): Promise<IHttpResponse<IBodyResponseConsumable>> {
    return serverAxios.get(ConsumableHttp.list, { params: urlParams, cancelToken: cancelToken });
  }
  public getConsumableBrand(urlParams: IParamsPagination): Promise<IHttpResponse<IBodyResponseConsumableBrands>> {
    return serverAxios.get(ConsumableHttp.brands, { params: urlParams });
  }
  public getConsumableCategory(urlParams: IParamsPagination): Promise<IHttpResponse<IBodyResponseConsumableCategories>> {
    return serverAxios.get(ConsumableHttp.category, { params: urlParams });
  }

  public getConsumableInfo(id: number): Promise<IHttpResponse<IConsumableInfo>> {
    return serverAxios.get(ConsumableHttp.info + id);
  }

  public postConsumable(id: number, data: FormData): Promise<IHttpResponse<IConsumableInfo>> {
    return serverAxios.post(ConsumableHttp.update + id, data);
  }

  public postConsumableTopOffer(id: number, data: {}): Promise<IHttpResponse<[]>> {
    return serverAxios.post(addRouteParam(ConsumableHttp.updateTopOffer, { id }), data);
  }

  public postConsumableTopSales(id: number, data: {}): Promise<IHttpResponse<[]>> {
    return serverAxios.post(addRouteParam(ConsumableHttp.updateTopSales, { id }), data);
  }

  public createConsumable(data: FormData): Promise<IHttpResponse<IConsumableInfo>> {
    return serverAxios.post(ConsumableHttp.create, data);
  }
  public deleteConsumable(id: number): Promise<IHttpResponse<null>> {
    return serverAxios.delete(ConsumableHttp.delete + id);
  }

  public consumableSearch(params: { q: string }): Promise<IHttpResponse<IConsumableSearch[]>> {
    return serverAxios.get(ConsumableHttp.search, { params });
  }

  public deleteConsumableAll(): Promise<IHttpResponse<null>> {
    return serverAxios.delete(ConsumableHttp.deleteAll);
  }
  public deleteConsumableSelected(ids: Array<string>): Promise<IHttpResponse<null>> {
    return serverAxios.delete(ConsumableHttp.deleteSelected, { params: { ids: ids } });
  }
}

export default ConsumableHttp;
