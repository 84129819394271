import React from 'react';
import { IProformaAmerigoRowItem } from 'utils/hooks/http/proforma.http';
import './ProformaDataItem.scss';

import DeleteItem from './DeleteItem/DeleteItem';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';

type Props = {
  data: IProformaAmerigoRowItem;
  rowKey: string;
  index: number;
  header: string[];
}

const ProformaDataItem: React.FC<Props> = (props) => {
  const allowedIndexKeys = ['index'];
  const allowedReferenceKeys = ['reference', 'index', 'ref', 'reference internal'];
  const allowedAvailableKeys = ['available', 'availability'];
  const allowedQuantityKeys = ['quantity', 'qte'];
  const allowedPriceKeys = ['price'];
  const { isMobile } = useDeviceDetect();

  const renderValueItem = (item) => {


    if (allowedIndexKeys.includes(item.toLowerCase())) {
      return props.index + 1 ?? '-';
    }

    if (allowedReferenceKeys.includes(item.toLowerCase())) {
      return props.data.reference ?? '-';
    }

    if (allowedAvailableKeys.includes(item.toLowerCase())) {
      return props.data.available ?? '-';
    }

    if (allowedQuantityKeys.includes(item.toLowerCase())) {
      return props.data.quantity ?? '-';
    }

    if (allowedPriceKeys.includes(item.toLowerCase())) {
      return props.data.price ?? '-';
    }

    return '-';
  };


  return (
    <div className="proforma-data-item">
      <div className="content">
        {
          props.header.map((item, index) => {


            return (
              <span key={index}>{isMobile ? <b>{item} :</b> : ''} {renderValueItem(item)}</span>
            );
          })
        }
        <span className="delete">
          <DeleteItem data={props.data} rowKey={props.rowKey} />
        </span>
      </div>

    </div>
  );
};

ProformaDataItem.displayName = 'ProformaDataItem';

export default ProformaDataItem;