import SkeletonSpinner from 'components/skeleton-spinner/SkeletonSpinner';
import { IData } from 'models/order';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import useHttp from 'utils/hooks/useHttp';
import './OrderInfo.scss';
import {IUser} from '../../../models/IUser';

type Props = {}

const OrderInfo: React.FC<Props> = () => {
  const http = useHttp();
  const { order_id } = useParams();
  const [data, setData] = useState<IData>();
  const [loading, setLoading] = useState(false);
  const { isMobile, isTablet } = useDeviceDetect();
  const user: IUser = JSON.parse(localStorage.getItem('userData') || '');


  function getOrderInfo() {
    setLoading(true);
    http.order.getOrderById(order_id!).then(res => setData(res.data)).finally(() => {
      setLoading(false);
    });
  }

  useEffect(() => {
    getOrderInfo();
  }, []);

  return (
    <div className="order-info" style={loading ? { display: 'flex' } : isMobile || isTablet ? { display: 'grid' } : { display: 'flex' }}>
      {loading ? <SkeletonSpinner /> : (
        <React.Fragment>
          <div className="order-info-item">
            <div className="order-label">STATUS:</div>
            <div className="order-value">{data?.status}</div>
          </div>
          <div className="order-info-item">
            <div className="order-label">ID:</div>
            <div className="order-value">#{data?.id}</div>
          </div>
          <div className="order-info-item">
            <div className="order-label">INTERNAL CMD:</div>
            <div className="order-value">{data?.customer_id ? data?.customer_id : '#'}</div>
          </div>
          <div className="order-info-item">
            <div className="order-label">NAME:</div>
            <div className="order-value">{data?.user_full_name}</div>
          </div>
          <div className="order-info-item">
            <div className="order-label">EMAIL:</div>
            <div className="order-value">{data?.user_email}</div>
          </div>
          <div className="order-info-item">
            <div className="order-label">PROFORMA_ID:</div>
            <div className="order-value">{data?.proforma_id ? data?.proforma_id : '#'}</div>
          </div>
          <div className="order-info-item">
            <div className="order-label">DATE:</div>
            <div className="order-value">{data?.time}</div>
          </div>
          <div className="order-info-item">
            <div className="order-label">AMOUNT:</div>
            <div className="order-value">{data?.total_price.toFixed(2)}</div>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default OrderInfo;