import React from 'react';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import './CategoryCard.scss';

type Props = {
    data
}

const CategoryCard:React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  function handleClick(item){
    navigate({
      pathname: '/public/car-parts',
      search: createSearchParams({
        catalog_id: String(searchParams.get('catalog_id')),
        code_groupe: item.code_groupe,
        code_ssgroupe: item.code_ssgroupe,
        code_repere: item.code_repere
      }).toString()
    });
  }

  return (
    <div className="category-card">
      <div className="category-card-header">
        <div className="category-card-title">{props.data?.name}</div>
      </div>
      <div className="category-card-body">
        {props.data?.subcategories.map((item, index) => {
          return (
            <div key={index} className="subcategory-title">
              <span className="title">{item.name}</span>
              <ul>
                {item.subcategories?.map((item, index)=> (
                  <li key={index} onClick={() => handleClick(item)}>{item.name}</li>
                ))}
              </ul>
            </div>);
        })}
      </div>
    </div>
  );
};

export default CategoryCard;