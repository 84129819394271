import QuantityInput from 'components/inputs/QuantityInput/QuantityInput';
import Spinner from 'components/spinner/Spinner';
import { IAddToCartParams, ICarPartBarcode, ICarPartData, ITeccomDisponibility } from 'models/car-parts.model';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { createSearchParams, generatePath, useNavigate } from 'react-router-dom';
import { getCurrentCatalog } from 'store/selectors/catalogs.selector';
import useHttp from 'utils/hooks/useHttp';
import { usePermission } from 'utils/usePermission';
import { ReactComponent as TeccomIcon } from 'assets/images/teccom-logo.svg';
import './CatalogPartsItem.scss';
import Disponibility from 'components/disponibility/Disponibility';
import CarPartPrice from 'components/CarPartPrice/CarPartPrice';
import { currencies } from 'utils/enums/marketplace';
import IconButton from 'components/buttons/IconButton/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch } from 'utils/hooks/redux';
import { addToCartAction } from 'store/actions/cart.actions';
import { CATALOG_MANUFACTURER } from '../../utils/constants/applications';
import FavoriteButton from '../PartItem/PartItemOptions/FavoriteButton/FavoriteButton';
import { Trans } from 'react-i18next';
import { setQuantityToolbar } from 'store/slices/cart.slice';
import { RootState } from 'store/store';
import useTeccomButtonDisponibility from 'utils/hooks/use-teccom-button-disponibility';
import {
  MagnifierContainer,
  MagnifierPreview,
  MagnifierZoom,
} from '@datobs/react-image-magnifiers';

type Props = {
  isLoading: boolean;
  image: string;
  logo: string;
  title: string;
  description: string;
  tags: [{ name: string; value: string }];
  info: string[];
  barcode: ICarPartBarcode;
  codeNumber: string;
  available: number;
  price?;
  isFavorite: boolean;
  data: ICarPartData;
  updateData?;
};

const CatalogPartsItemView: React.FC<Props> = (props: Props) => {
  const navigate = useNavigate();
  const currentCatalog = useSelector(getCurrentCatalog);
  const dispatch = useAppDispatch();
  const http = useHttp();
  const [cartLoading, setCartLoading] = useState(false);
  const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });


  const useBtnDisponibility = useTeccomButtonDisponibility();
  const [teccomData, setTeccomData] = useState<ITeccomDisponibility>();
  const [loading, setLoading] = useState(false);
  const [quantityValue, setQuantityValue] = useState<number>(1);
  const { quantityToolbar } = useSelector((state: RootState) => state.cart);

  function generateLink() {
    const searchParams = createSearchParams([
      ['data_supplier_reference', String(props.data.data_supplier_reference)],
      ['data_supplier_id', String(props.data.data_supplier.id)],
      ['provider_id', String(props.data.cod_fou)],
      ['catalog_id', String(currentCatalog?.id)],
    ]);

    navigate({
      pathname: generatePath('/catalog/:id/view/:reference', {
        id: String(currentCatalog?.id),
        reference: props.data.data_supplier_reference,
      }),
      search: `?${searchParams}`,
    });
  }

  async function checkTeccomData() {
    if (useBtnDisponibility && props.data.add_to_cart) {
      setLoading(true);
      const params = {
        dlnr: props.data.data_supplier.id,
        reference: props.data.data_supplier_reference,
        supplier_cod_fou: props.data.cod_fou,
      };

      await http.carParts
        .getTeccomDisponibility(params)
        .then((res) => setTeccomData(res[0]))
        .finally(() => {
          setLoading(false);
        });
    }
  }

  const handleAddToCart = async () => {

    if (!props.data.add_to_cart) {
      return;
    }

    setCartLoading(true);

    if (!teccomData && !props.data.price) {
      await checkTeccomData();
    }

    if (cartLoading || !quantityValue) {
      return;
    }

    const params: IAddToCartParams[] = [];

    let param: IAddToCartParams = {
      catalog_id: currentCatalog?.id,
      cod_fou: props.data.cod_fou,
      price: props.data?.price?.price,
      is_oe_part: props.data?.catalog_type === CATALOG_MANUFACTURER,
      quantity: quantityValue,
      supplier_id: props.data?.data_supplier.id,
      supplier_reference: props.data?.data_supplier_reference,
    };


    if (localStorage.getItem('external_cart_id')) {
      param = {
        ...param, ...{ external_cart_id: localStorage.getItem('external_cart_id') }
      };
    }

    params.push(param);
    dispatch(addToCartAction(params)).finally(() => {
      setCartLoading(false);
    });

    dispatch(setQuantityToolbar(+quantityValue + +quantityToolbar));
  };

  function getPrice() {
    if (props.price?.price) {
      return props.price.price;
    }

    if (props.price) {
      return props.price;
    } else {
      return teccomData?.price;
    }
  }

  const updateFavorite = (isChecked: boolean) => {
    if (props.updateData) {
      props.updateData(props.data, isChecked);
    }

  };

  useEffect(() => {
    const img = new Image();

    img.src = props.data.image || props.data.data_supplier_logo || '/images/car-part-placeholder-big.png';

    img.onload = () => {

      setImageDimensions({
        width: img.width > 200 ? 200 : img.width,
        height: img.height > 200 ? 200 : img.height,
      });
    };
  }, [props.data.image]);

  return !props.isLoading ? (
    <div className="catalog-parts-items-wrapper">
      <div>
        <div className="favorite-button-wrapper">
          <FavoriteButton carPart={props.data} updateFavorite={updateFavorite} />
        </div>
        <div className="topContent">
          <MagnifierContainer className="img-zoom-container" autoInPlace>
            <div className="img">
              <MagnifierPreview
                imageSrc={props.data.image || props.data.data_supplier_logo || '/images/car-part-placeholder.png'}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = '/images/car-part-placeholder-big.png';
                }}
              />
            </div>
            <MagnifierZoom className="img-zoom" style={imageDimensions} imageSrc={props.data.image || props.data.data_supplier_logo || '/images/car-part-placeholder.png'} />
          </MagnifierContainer>
          {/* <img onClick={generateLink} className="image" src={props.image ? props.image : '/images/car-part-placeholder-big.png'}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = '/images/car-part-placeholder-big.png';
            }}

          /> */}
          <div className="info">
            <div onClick={generateLink} className="amerigo-part-title">
              {props.title}
            </div>
            <div className="description">{props.description}</div>
          </div>
        </div>
        {props.data.quantity && Number(props.data.quantity) ?
          <div className="articles-quantity">
            <Trans i18nKey="_articles_pack" values={{ quantity: props.data.quantity && props.data.quantity }} />
          </div> : null
        }
        <div className="tag">
          {props.tags &&
            props.tags.map((tag) => {
              return `${tag.name}: ${tag.value}; `;
            })}
        </div>
      </div>

      <div>
        <div className="separator">
          <div className="hr"></div>
          {useBtnDisponibility && props.data.add_to_cart ? (
            <div onClick={checkTeccomData} className="teccom-logo">
              <TeccomIcon />
            </div>
          ) : (
            <img onClick={checkTeccomData} className="logo" src={props.logo ? props.logo : '/images/car-part-placeholder-big.png'}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = '/images/car-part-placeholder-big.png';
              }}

            />
          )}
          <div className="hr"></div>
        </div>
        <div className="bottomContent">

          {props.barcode && (
            <div className="barcode">
              <img className="barcode-img" src={props.barcode.image} />
              <div className="a">{props.barcode.code}</div>
              <div className="number">{props.codeNumber}</div>
            </div>
          )}
          <div className="disponible">
            {loading ? (
              <Spinner class="teccom-spinner" />
            ) : (
              <div className="itemInfo">
                <div className="dispo">
                  <Disponibility
                    loading={loading}
                    value={quantityValue}
                    limit={props.data.available ? props.data.available : teccomData?.available}
                  />
                </div>
                <div className="price">
                  <CarPartPrice loading={loading} currency={currencies.EUR} price={props.data.price} quantityValue={quantityValue} part />
                </div>
              </div>
            )}
            <div className="itemValue">
              <QuantityInput setValue={setQuantityValue} value={quantityValue} />
              <div className="button" onClick={handleAddToCart}>
                <IconButton
                  disabled={!quantityValue || !props.data.add_to_cart}
                  className="iconButton"
                  icon={cartLoading ? <Spinner class="extra-small"></Spinner> : <FontAwesomeIcon icon={faCartShopping} className="" />}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Spinner></Spinner>
  );
};

CatalogPartsItemView.displayName = 'CatalogItemView';

export default CatalogPartsItemView;
