import React, { useEffect, useState } from 'react';
import i18n from 'i18n';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import useHttp from 'utils/hooks/useHttp';
import { getCurrentCountryRMI, getCurrentLanguageRMI } from 'utils/rmi/rmi-utils/rmi-utils';
import './Wiring.scss';
import Spinner from 'components/spinner/Spinner';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import VehicleIframe from 'pages/CatalogVehicle/TechnicalDocumentation/VehicleIframe/VehicleIframe';
import { IBoxBuildPeriodQualCols, IManualsListItemMps, IWiringListMainGroup, IWiringListSubGroup } from 'utils/rmi/interface/manuals.interface';
import SidebarDetails from 'pages/CatalogVehicle/TechnicalDocumentation/SidebarDetails/SidebarDetails';
import Modal from 'components/modal/Modal';
import ComponentsLinkModal from '../ComponentsLinkModal/ComponentsLinkModal';
import { IRMIModulesLinkChildren, IRMIModulesLinkModal, RELIABLE_TAB_NAMES } from 'utils/rmi/reliableTabs';
import { getQueryParamsEntryLikeObject } from 'utils/http';
import useReliableCatalogCarQueryParams from 'utils/hooks/useReliableCatalogCarQueryParams';

import ModulesMobileCard from '../../ModulesMobileCard/ModulesMobileCard';
import IframeModal from '../../ModulesMobileCard/IframeModal/IframeModal';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';

const Wiring: React.FC = () => {

  const { t } = useTranslation();
  const { typeId } = useSelector((state: RootState) => state.tecrmi);
  const reliableCatalogCarQueryParams = useReliableCatalogCarQueryParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { vehicle_id } = useParams();
  const navigate = useNavigate();
  const { isMobile } = useDeviceDetect();
  const [showModal, setShowModal] = useState(false);

  const [sidebarDetails, setSidebarDetails] = useState(Number(searchParams.get('level')) || 1);


  const [loadingList, setLoadingList] = useState(false);
  const [loadingModalLink, setLoadingModalLink] = useState(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [rmiModulesLinkModal, setRmiModulesLinkModal] = useState<Array<IRMIModulesLinkModal>>([]);

  const [mainGroup, setMainGroup] = useState<IWiringListMainGroup[]>();
  const [subGroup, setSubGroup] = useState<IWiringListMainGroup>();
  const [itemMps, setItemMps] = useState<IWiringListSubGroup>();
  const [activeItem, setActiveItem] = useState<IManualsListItemMps>();


  const [loadingHTML, setLoadingHTML] = useState(false);


  const http = useHttp();

  const [iframeData, setIframeData] = useState<string>();


  function fetchRMIWiringManualsList() {
    if (typeId) {
      setLoadingList(true);
      http.wiringHttp.fetchRMIWiringManualList({
        typeId: typeId,
        countryCode: getCurrentCountryRMI(i18n.language),
        languageCode: getCurrentLanguageRMI(i18n.language)
      }).then(res => {
        setMainGroup(res.data);
      }
      ).finally(() => {
        setLoadingList(false);
      });
    }
  }

  const fetchHtml = (manualId: number): void => {

    if (!typeId) {
      return;
    }

    setLoadingHTML(true);
    http.wiringHttp.fetchRMIManualHtml({
      typeId,
      manualId,
      countryCode: getCurrentCountryRMI(i18n.language),
      languageCode: getCurrentLanguageRMI(i18n.language),
    })
      .then(({ data }) => {
        setIframeData(data);
        isMobile && setShowModal(true);

      })
      .catch((e: Error) => console.log(e))
      .finally(() => setLoadingHTML(false));
  };

  useEffect(() => {
    fetchRMIWiringManualsList();
  }, [typeId, i18n.language]);

  function onMainGroupClick(item) {
    setSubGroup(item);
    setSidebarDetails(2);
    searchParams.set('group_id', item.MainGroupId);
    searchParams.set('level', '2');
    setSearchParams(searchParams);
  }

  function onSubGroupClick(item) {
    setItemMps(item);
    setSidebarDetails(3);
    searchParams.set('subgroup_id', item.SubGroupId);
    searchParams.set('level', '3');
    setSearchParams(searchParams);
  }

  function onItemMpClick(item) {
    fetchLinkedWiringDiagram(item.ItemMpId);
  }

  function fetchLinkedWiringDiagram(itemMpId: number) {
    setLoadingModalLink(true);
    const fetchParams = {
      typeId,
      countryCode: getCurrentCountryRMI(i18n.language),
      languageCode: getCurrentLanguageRMI(i18n.language),
      itemMpId: itemMpId,
      linkId: itemMpId,
      linkedId: itemMpId,

    };

    Promise.allSettled([
      http.wiringHttp.fetchRMILinkedWiringDiagrams(fetchParams),
      http.wiringHttp.fetchRMILinkedFuseRelais(fetchParams),
      http.wiringHttp.fetchRMILinkedPartPosition(fetchParams),
    ]).then(([
      Wiring,
      FuseRelais,
      PartPosition,
    ]) => {
      const arrayToSend: Array<IRMIModulesLinkModal> = [];

      let ManualsWiring;
      let ManualsPartPosition;
      let BoxConfigurations: IBoxBuildPeriodQualCols[] = [];

      if (Wiring.status !== 'rejected') {
        ManualsWiring = Wiring?.value?.data?.Manuals;

        const paramsObject = getQueryParamsEntryLikeObject(searchParams);

        const childrenTree: Array<IRMIModulesLinkChildren> = [];


        ManualsWiring && ManualsWiring.forEach((manual) => {
          paramsObject['item_id'] = itemMpId.toString();
          paramsObject['manual_id'] = manual.ManualId.toString();

          childrenTree.push(
            {
              label: manual.QualColText,
              link: `/reliable/rmi/${vehicle_id}/technical-documentation/${RELIABLE_TAB_NAMES.WIRING}?${(new URLSearchParams(paramsObject)).toString()}`,
              children: null
            }
          );
        });

        if (childrenTree.length) {
          if (Wiring?.value?.data?.ItemMpText) {
            arrayToSend.push(
              {
                tabName: t('_wiring_diagrams'),
                children: [{
                  label: Wiring?.value?.data?.ItemMpText,
                  link: null,
                  children: childrenTree
                }
                ]
              });
          } else {
            childrenTree.length && arrayToSend.push(
              {
                tabName: t('_wiring_diagrams'),
                children: childrenTree
              }
            );
          }

        }

      }


      if (FuseRelais.status !== 'rejected') {
        BoxConfigurations = FuseRelais?.value?.data[0]?.BoxConfigurations;

        const childrenTree: Array<IRMIModulesLinkChildren> = [];

        BoxConfigurations && BoxConfigurations.forEach(box => {
          const paramsObject: { [key: string]: string } = { ...reliableCatalogCarQueryParams };


          paramsObject.buildPeriodQualColId = box.BuildPeriodQualColId.toString();
          paramsObject.boxConfigurationMappingId = box.BoxConfigurationId.toString();
          paramsObject.componentTypeId = '0';

          childrenTree.push(
            {
              label: box.BuildPeriodQualColText,
              link: `/reliable/rmi/${vehicle_id}/technical-documentation/${RELIABLE_TAB_NAMES.RELAYSFUSES}?${(new URLSearchParams(paramsObject)).toString()}`,
              children: null
            }
          );
        });

        if (childrenTree.length) {
          if (FuseRelais?.value?.data[0]?.ItemMpText) {
            arrayToSend.push(
              {
                tabName: t('_relays_fuses'),
                children: [{
                  label: FuseRelais?.value?.data[0]?.ItemMpText,
                  link: null,
                  children: childrenTree
                }
                ]
              });
          } else {
            childrenTree.length && arrayToSend.push(
              {
                tabName: t('_relays_fuses'),
                children: childrenTree
              }
            );
          }

        }

      }


      if (PartPosition.status !== 'rejected') {
        ManualsPartPosition = PartPosition?.value?.data[0]?.Manuals;
        const childrenTree: Array<IRMIModulesLinkChildren> = [];

        ManualsPartPosition && ManualsPartPosition.forEach(manual => {
          const paramsObject: { [key: string]: string } = { ...reliableCatalogCarQueryParams };

          paramsObject.manual_id = manual.ManualId.toString();
          paramsObject.item_id = itemMpId.toString();
          paramsObject.source = searchParams.get('source')!;
          paramsObject.vehicle_identifier = searchParams.get('vehicle_identifier')!;

          childrenTree.push(
            {
              label: manual.QualColText,
              link: `/reliable/rmi/${vehicle_id}/technical-documentation/${RELIABLE_TAB_NAMES.TEC_COMP_POSITION}?${(new URLSearchParams(paramsObject)).toString()}`,
              children: null
            }
          );
        });

        if (childrenTree.length) {
          if (PartPosition?.value?.data[0]?.ItemMpText) {
            arrayToSend.push(
              {
                tabName: t('_tec_comp_position'),
                children: [
                  {
                    label: PartPosition?.value?.data[0]?.ItemMpText,
                    link: null,
                    children: childrenTree
                  }
                ]
              });
          } else {
            childrenTree.length && arrayToSend.push(
              {
                tabName: t('_tec_comp_position'),
                children: childrenTree
              }
            );
          }

        }


      }

      setLoadingModalLink(false);

      if (arrayToSend.length == 1 && arrayToSend[0].tabName === t('_wiring_diagrams') && arrayToSend[0].children[0].children?.length == 1) {
        navigate(arrayToSend[0].children[0].children[0].link!);
        iframeData && isMobile && setShowModal(true);

      } else {
        setRmiModulesLinkModal(arrayToSend);
        setOpenModal(true);
      }


    }).catch((e: Error) => console.error(e));

  }

  useEffect(() => {
    if (searchParams.get('level')) {
      setSidebarDetails(+searchParams.get('level')! || 1);
    }


    if (searchParams.get('manual_id')) {
      const manualId = searchParams.get('manual_id');

      fetchHtml(+manualId!);
    } else {
      setActiveItem(undefined);
    }

    if (!mainGroup) { return; }

    if (searchParams.get('manual_id') && !searchParams.get('group_id') && !searchParams.get('subgroup_id')) {

      let isManualFound = false;

      for (const groups of mainGroup) {

        for (const subgroups of groups.SubGroups) {
          for (const itemps of subgroups.ItemMps) {
            for (const manual of itemps.Manuals) {
              if (manual.ManualId == +searchParams.get('manual_id')!) {
                isManualFound = true;
                setSidebarDetails(3);

                break;
              }
            }

            if (isManualFound) {
              setActiveItem(itemps);
              searchParams.set('item_id', String(itemps.ItemMpId));

              break;

            }

          }

          if (isManualFound) {
            setItemMps(subgroups);
            searchParams.set('subgroup_id', String(subgroups.SubGroupId));

            break;
          }

        }

        if (isManualFound) {
          setSubGroup(groups);
          searchParams.set('group_id', String(groups.MainGroupId));

          break;

        }
      }

    }

    setSearchParams(searchParams);

    let subGroupFound;

    if (Number(searchParams.get('group_id'))) {
      subGroupFound = mainGroup.find(item => item.MainGroupId === Number(searchParams.get('group_id')));
      setSubGroup(subGroupFound);
    }


    if (!subGroupFound) {
      return;
    }

    let subItemMpsFound;

    if (Number(searchParams.get('subgroup_id'))) {
      subItemMpsFound = subGroupFound.SubGroups.find(item => item.SubGroupId === Number(searchParams.get('subgroup_id')));
      setItemMps(subItemMpsFound);
    }

    let manualsFound;

    if (Number(searchParams.get('item_id'))) {
      manualsFound = subItemMpsFound.ItemMps.find(item => item.ItemMpId === Number(searchParams.get('item_id')));
      setActiveItem(manualsFound);
    }


  }, [searchParams.get('item_id'), searchParams.get('manual_id'), searchParams.get('level'), typeId, i18n.language, mainGroup, isMobile]);

  function onReturnClick() {
    setActiveItem(undefined);
    navigate(-1);
    setSidebarDetails(prev => prev - 1);

  }


  const fetchLinkedWiringDiagramIframe = (hrefId: string): void => {
    const itemMpId = Number(hrefId?.match(/\d+/)?.join(''));

    if (itemMpId) {
      fetchLinkedWiringDiagram(itemMpId);
    }

  };


  return (

    <div className="technical-documentation-wiring-diagrams">
      {loadingModalLink && <Spinner />}
      <Modal
        openModal={openModal}
        setOpenModal={setOpenModal}
        childrenComponent={<ComponentsLinkModal setOpenModal={setOpenModal} rmiModulesLink={rmiModulesLinkModal} />}
      />
      {loadingList ? <Spinner class="extra-small" /> : (
        !isMobile ?
          <React.Fragment>
            <div className="tdoc-sidebar">
              {sidebarDetails === 1 &&
                <SidebarDetails
                  title={`<span>${t('_wiring_diagrams')}</span>`}
                  itemName="MainGroupName"
                  onClick={onMainGroupClick}
                  data={mainGroup}
                  onReturnClick={onReturnClick}
                />}
              {sidebarDetails === 2 &&
                <SidebarDetails
                  title={`${t('_wiring_diagrams')} / <span>${subGroup?.MainGroupName}</span>`}
                  itemName="SubGroupName"
                  onClick={onSubGroupClick}
                  data={subGroup?.SubGroups}
                  onReturnClick={onReturnClick}
                />}
              {sidebarDetails === 3 &&
                <SidebarDetails
                  title={`${t('_wiring_diagrams')} / ${subGroup?.MainGroupName} / <span>${itemMps?.SubGroupName}</span>`}
                  itemId="ItemMpId"
                  itemName="ItemMpText"
                  onClick={onItemMpClick}
                  data={itemMps?.ItemMps}
                  onReturnClick={onReturnClick}
                  activeItem={activeItem?.ItemMpId}
                />}
            </div>
            <div className="tdoc-content">
              {iframeData &&
                <VehicleIframe
                  onIframeAnchorClick={fetchLinkedWiringDiagramIframe}
                  loading={loadingHTML}
                  vehicleIframe={iframeData!}
                />
              }
            </div>
          </React.Fragment>
          : (
            <div className="tdoc-content">
              <ModulesMobileCard
                title={t('_wiring_diagrams')}
                data={mainGroup}
                onItemMpClick={onItemMpClick}
                setOpenModal={setShowModal}
              />
              <Modal
                openModal={showModal}
                setOpenModal={setShowModal}
                childrenComponent={<IframeModal title={t('_wiring_diagrams')} loading={loadingHTML} setOpenModal={setShowModal} vehicleIframe={iframeData!} onIframeAnchorClick={fetchLinkedWiringDiagramIframe} />}
              />
            </div>
          )
      )}
    </div>

  );
};

export default Wiring;