import React, {useEffect, useState} from 'react';
import VideoSectionView from './VideoSection.view';
import {IDataResponse, IVideoData} from '../../models/video';
import i18n from 'i18next';
import useHttp from 'utils/hooks/useHttp';
import {useParams} from 'react-router-dom';

type Props = {
  previewImage?: string;
  perPage?:number;
  showPagination?:boolean;
};

const VideoSection: React.FC<Props> = (props) => {

  const [videoItems, setVideoItems] = useState<IVideoData[]>([]);

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalPage, setTotalPage] = useState<number>(0);

  const { id: catalogId } = useParams();
  const [loading, setLoading] = useState(false);
  const http = useHttp();

  function getVideoList(params) {
    setLoading(true);
    http.video
      .getVideoList(params)
      .then((res) => {
        const { data, page, total_pages } = res as IDataResponse;

        setVideoItems(data);
        setCurrentPage(page);
        setTotalPage(total_pages);
      }
      )
      .finally(() => {
        setLoading(false);
      });
  }

  const handlePageClick = async (page) => {
    await getVideoList({ catalog_id: catalogId , per_page: props.perPage, page: page + 1 });
  };

  useEffect(() => {
    getVideoList({ catalog_id: catalogId , per_page: props.perPage});
  }, [i18n.language]);

  return (
    <div>
      {!videoItems.length ? '' : (
        <VideoSectionView
          previewImage={props.previewImage}
          items={videoItems}
          loading={loading}
          showPagination={props.showPagination}
          currentPage={currentPage}
          totalPage={totalPage}
          handlePageClick={handlePageClick}
        />
      )}
    </div>
  );
};

VideoSection.displayName = 'VideoSection';

export default VideoSection;
