
import React from 'react';
import { Row } from 'react-table';
import { MobileTableExampleData } from '../../MobileTableExample/table-mobile';
import { IActiaTableData } from '../../../DiagnosticItems/Actia/actia';
import { CartTableItem } from '../../../../pages/Cart/CartTable/CartTable.model';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import './ExpandButton.scss';
import { GetCartResponseEspace } from 'models/cart.model';

type ITableMoreInformationRow = Row<MobileTableExampleData> | Row<IActiaTableData> | Row<CartTableItem> | Row<GetCartResponseEspace>;

type Props = {
  row
}

const ExpandButton: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  return (
    <div className="table-expand">
      <div
        {...props.row.getToggleRowExpandedProps()}
        title={t('_more_info')}
        className="expander"
      >
        <div className={`${props.row.isExpanded ? ' open' : 'close'}`}>
          {props.row.isExpanded ? <FontAwesomeIcon icon={faMinusCircle} /> : <FontAwesomeIcon icon={faPlusCircle} />}
        </div>
      </div>
    </div>
  );
};

export default ExpandButton;

