import { ICategorieGroup } from 'applications/automotor/components/espace/navbar/Navbar.model';
import { addRouteParam, IHttpResponse, serverAxios } from '../../http';

class FilterSuppliersHttp {
  private static route = 'retailer/filter-suppliers';
  private static erpRoute = 'retailer/filter-suppliers/:id/equivalance';
  private static routeV2 = 'retailer/filter-suppliers-v2';
  private static allSuppliers = 'retailer/short-list';
  private static actionSupplier = 'retailer/filter-suppliers/:id';
  private static submitSupplier = 'retailer/filter-suppliers/all/save';
  private static checkCatalogTyre = 'retailer/catalog_tyre/show';
  private static checkCatalogOil = 'retailer/catalog_oil/show';
  private static checkCatalogConsumable = 'retailer/catalog_consumable/show';
  private static checkCatalogBattery = 'retailer/catalog_battery/show';
  private static checkNotTecDocCatalog = 'retailer/catalog_non_tec_dock/show';
  private static supplierCategory = 'retailer/filter-suppliers/:id/categories';
  private static erpSave = 'retailer/filter-suppliers/:id/equivalance';

  public async getFilterSuppliers(): Promise<IHttpResponse<IFilterSuppliers[]>> {
    const { data } = await serverAxios.get(FilterSuppliersHttp.route, { params: { per_page: 1000 } });

    return data;
  }
  
  public async getErpSuppliers(id: string): Promise<IHttpResponse<IFilterSuppliers[]>> {
    const { data } = await serverAxios.get(addRouteParam(FilterSuppliersHttp.erpRoute, {id}));

    return data;
  }

  public async getFilterSuppliersV2(): Promise<IHttpResponse<IFilterSuppliers[]>> {
    const { data } = await serverAxios.get(FilterSuppliersHttp.routeV2, { params: { per_page: 1000 } });

    return data;
  }
  public async getAllSuppliers(): Promise<ISupplier[]> {
    const { data } = await serverAxios.get(FilterSuppliersHttp.allSuppliers);

    return data;
  }
  public async addSupplier(id: string): Promise<ISupplier[]> {
    return await serverAxios.post(addRouteParam(FilterSuppliersHttp.actionSupplier, { id }));

  }
  public async deleteSupplier(id: string): Promise<ISupplier[]> {
    return await serverAxios.delete(addRouteParam(FilterSuppliersHttp.actionSupplier, { id }));

  }
  public async submitSuppliers(params: ISupplierSubmit[]): Promise<IHttpResponse<string>> {
    return await serverAxios.post(FilterSuppliersHttp.submitSupplier, { ...params });
  }
  public async checkCatalogTyre(params: ISupplierCatalog): Promise<string> {
    return await serverAxios.post(FilterSuppliersHttp.checkCatalogTyre, { ...params });
  }
  public async checkCatalogOil(params: ISupplierCatalog): Promise<string> {
    return await serverAxios.post(FilterSuppliersHttp.checkCatalogOil, { ...params });
  }
  public async checkCatalogConsumable(params: ISupplierCatalog): Promise<string> {
    return await serverAxios.post(FilterSuppliersHttp.checkCatalogConsumable, { ...params });
  }
  public async checkCatalogBattery(params: ISupplierCatalog): Promise<string> {
    return await serverAxios.post(FilterSuppliersHttp.checkCatalogBattery, { ...params });
  }
  public async checkNotTecDocCatalog(params: ISupplierCatalog): Promise<string> {
    return await serverAxios.post(FilterSuppliersHttp.checkNotTecDocCatalog, { ...params });
  }

  public async addSupplierCategory(id: string): Promise<IHttpResponse<ICategorieGroup[]>> {
    return serverAxios.get(addRouteParam(FilterSuppliersHttp.supplierCategory, { id }));

  }
  public async addSupplierCategorySave(id: string, params: Array<{}>): Promise<IHttpResponse<ICategorieGroup[]>> {
    return serverAxios.put(addRouteParam(FilterSuppliersHttp.supplierCategory, { id }), params);

  }

  public async saveErpSupplier(id: string, params: {erpId: string}): Promise<IHttpResponse<{}>> {
    return serverAxios.put(addRouteParam(FilterSuppliersHttp.erpSave, { id }), params);

  }
}

export interface IFilterSuppliers {
  id: string,
  name: string,
  status: boolean,
  logo: string
}

export interface ISupplier {
  id: string,
  name: string,
}

export interface ISupplierSubmit {
  dlnr: string
  status: boolean
}

export interface ISupplierCatalog {
  status: number
}

export default FilterSuppliersHttp;
