import { addRouteParam, serverAxios } from '../../http';
import { IPromiseInfoUser } from 'interfaces/user';
import { IForgotPassword } from 'models/forgot-password.model';

class ForgotPasswordHttp {
  private static route = '/forgot-password';
  private static restorePassword = '/restore-password/:token';

  public async forgotPassword(data: { email: string; url: string }): Promise<IForgotPassword> {
    return serverAxios.post(ForgotPasswordHttp.route, { email: data.email, url: data.url });
  }
  public async restorePassword(token: string, { first, second }: { first: string; second: string }): Promise<IPromiseInfoUser> {
    return serverAxios.post(addRouteParam(ForgotPasswordHttp.restorePassword, { token }), { password: { first, second } });
  }
}

export type IUserPut = {
  language: string;
};

export default ForgotPasswordHttp;
