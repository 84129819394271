import { IItemClick } from 'models/catalog.model';
import React from 'react';
import ButtonLink from '../buttons/ButtonLink/ButtonLink';
import './ListSupplier.scss';

type Props = {
  title: string;
  content;
  images?: string[];
  className?: string;
  itemClassName?: string;
  onItemClick?: (item: IItemClick) => void;
  showPdf?: boolean;
  activeCategory?;
  itemId?: string;
  handleOnChangeCategory: (e: React.ChangeEvent<HTMLInputElement>, activeCategoryCheck) => void;
  register;
};


const ListSupplierView: React.FC<Props> = (props: Props) => {

  return (
    <div className="list-container-supp">
      {props.title && <h1 className={`heading ${props.className ? props.className : ''}`}>{props.title}</h1>}
      {props.content &&
        props.content.map((item, index: number) => {
          return (
            <div className={`list-container-item ${props.activeCategory?.[props.itemId!] == item[props.itemId!] ? 'active' : ''}`} key={index} >
              <ButtonLink
                text={typeof item === 'object' ? item.name : item}
                disabled={item.number_of_products === 0}
                onClick={() => props.onItemClick?.(item)}
                className={`check ${props.itemClassName}`}
                showPdf={props.showPdf}
                image={props.images ? props.images[index] : undefined}
              ></ButtonLink>
              <div className="checkbox-container-supp">
                <label className="checkbox-container">
                  <input type="checkbox"
                    {...props.register(`checkbox-parent[${item.code_groupe}]`)}
                    value={props.activeCategory.code_groupe}
                    onChange={(event) => { props.handleOnChangeCategory(event, item); }}
                  />
                </label>
              </div>
            </div>
          );
        })}
    </div>
  );
};

ListSupplierView.displayName = 'ListSupplierView';

export default ListSupplierView;
