import { GetCartEspace } from 'models/cart.model';
import { IHttpResponse, serverAxios } from '../../http';
import { IProformaResponse } from './proforma.http';

class CartEspaceHttp {
  private static cartEspace = '/aol/cart';
  private static pachCartEspace = '/aol/cart';
  private static countCartEspace = '/aol/cart/quantity';
  private static deleteCartEspace = '/aol/cart/';
  private static checkout = '/aol/cart/checkout';

  public async patchCartDataEspace(
    id: number,
    values: { quantity: number },
  ): Promise<IHttpResponse<{ total_price: number; total_quantity: number }>> {
    return serverAxios.patch(`${CartEspaceHttp.pachCartEspace}/${id}`, values);
  }
  public async getCountCartEspace(): Promise<IHttpResponse<{ quantity: number }>> {
    return serverAxios.get(CartEspaceHttp.countCartEspace);
  }
  public async deleteCartEspace(id: number): Promise<IHttpResponse<GetCartEspace>> {
    return serverAxios.delete(CartEspaceHttp.deleteCartEspace + id);
  }
  public async getCartDataEspace(): Promise<IHttpResponse<GetCartEspace>> {
    return serverAxios.get(CartEspaceHttp.cartEspace);
  }
  public async cartDataEspaceCheckout(): Promise<IHttpResponse<IProformaResponse>> {
    return serverAxios.post(CartEspaceHttp.checkout);
  }
}

export default CartEspaceHttp;
