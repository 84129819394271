import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { ReactComponent as Image } from 'assets/images/task-checkmark-icon.svg';

type Props = {};

const WaitingConfirmation: React.FC<Props> = () => {
  const { t } = useTranslation();

  return (
    <div className="item-client">
      <NavLink to="/area/waiting-confirmation">
        <div className="title">{t('_waiting_confirmation')}</div>
        <div className="upload-btn">
          <div className="icon">
            <Image />
          </div>
          <div className="text">{t('_waiting_confirmation')}</div>
        </div>
      </NavLink>
    </div>
  );
};

WaitingConfirmation.displayName = 'WaitingConfirmation';

export default WaitingConfirmation;
