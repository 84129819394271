import { addRouteParam, serverAxios } from '../../http';

class ProvidersHttp {
  private static providers = 'providers/all';
  private static update = 'provider/update-franco';
  private static updateOrderValue = 'client/:id/minim-order';

  public async getProviders(params: IProviderParams): Promise<IPaginationData> {
    const { data } = await serverAxios.get(ProvidersHttp.providers, { params });

    return data;
  }
  public async updateFranco(params: IProvider): Promise<{}> {
    return serverAxios.post(ProvidersHttp.update, { ...params });
  }
  public async updateMinimumOrderValue(id: number, params: { minim_order_value: number | null }): Promise<{}> {
    return serverAxios.post(addRouteParam(ProvidersHttp.updateOrderValue, { id }), { ...params });
  }
}

export interface IProviderParams {
  page?: number;
  code_fou?: string;
  per_page?: number;
}

export interface IProvider {
  code_fou: string;
  logo?: string;
  name: string;
  franco: number | null;
  source: string;
}

export interface IPaginationData {
  data: IProvider[];
  page: number;
  per_page: number;
  total_count: number;
  total_pages: number;
}

export default ProvidersHttp;
