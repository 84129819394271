import { faArrowAltCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ICategorieGroup } from 'applications/automotor/components/espace/navbar/Navbar.model';
import Spinner from 'components/spinner/Spinner';
import i18n from 'i18n';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Masonry from 'react-masonry-css';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useHttp from 'utils/hooks/useHttp';
import CategoryCard from './category-card/CategoryCard';
import './PresentationCategories.scss';

type Props = {};

const PresentationCategories: React.FC<Props> = () => {
  const { t } = useTranslation();
  const http = useHttp();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [data, setData] = useState<ICategorieGroup[]>([]);
  const [loading, setLoading] = useState(false);

  const breakpointColumnsObj = {
    default: 4,
    1100: 3,
    800: 2,
    500: 1,
  };

  function getData() {
    setLoading(true);
    http.presentation
      .getCategories({ catalog_id: String(searchParams.get('catalog_id')) })
      .then((res) => setData(res.data))
      .finally(() => setLoading(false));
  }

  function goBack() {
    navigate(-1);
  }

  useEffect(() => {
    getData();
  }, [i18n.language]);

  return (
    <div className="presentation-categories">
      <div className="categories-header">
        <div className="categories-return" onClick={goBack}>
          <FontAwesomeIcon icon={faArrowAltCircleLeft} /> {t('_return')}
        </div>
        <div className="categories-title">{t('_category')}</div>
      </div>
      <div className="category-cards">
        {loading ? (
          <Spinner class="small categories-spinner" />
        ) : (
          <Masonry breakpointCols={breakpointColumnsObj} className="masonry-grid" columnClassName="masonry-grid-column">
            {data?.map((item, index) => (
              <CategoryCard key={index} data={item} />
            ))}
          </Masonry>
        )}
      </div>
    </div>
  );
};

export default PresentationCategories;
