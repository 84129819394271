import React from 'react';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import CatalogsConsumablesPage from './CatalogsConsumablesPage';
import CatalogsConsumablesPageMobile from './CatalogsConsumablesPageMobile/CatalogsConsumablesPageMobile';


const CatalogsConsumablesPageView: React.FC = () => {
  const {isMobile} = useDeviceDetect();

  return (
    isMobile ? <CatalogsConsumablesPageMobile /> : <CatalogsConsumablesPage />
  );
};

export default CatalogsConsumablesPageView;