import React, { PropsWithChildren } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './DefaultIcon.scss';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

type Props = {
  icon: IconProp;
  text?: string;
  number?: number;
  onClick: () => void;
};

const DefaultIcon: React.FC<Props> = (props: PropsWithChildren<Props>) => {

  return (
    <div className="DefaultIcon file-button" onClick={() => props.onClick()}>
      <div className="icon">
        {
          props.number && props.number > 0 ? (
            <span className="notification">{props.number}</span>
          ) : null
        }
        <FontAwesomeIcon icon={props.icon} className="file-icon" />
      </div>
      {
        props.text && (
          <span className="button-text">{props.text}</span>
        )
      }
    </div>
  );
};

DefaultIcon.displayName = 'DefaultIcon';

export default DefaultIcon;
