import { faBoxOpen, faFileContract, faFileLines, faList, faNewspaper, faSearch, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IDropdownModel } from 'components/dropdowns/Dropdown.model';
import MiniDropdown from 'components/dropdowns/MiniDropdown/MiniDropdown';
import TechnicalHelpMobile from 'components/dropdowns/TechnicalHelp/Mobile/TechnicalHelpMobile';
import Modal from 'components/modal/Modal';
import ModalCars from 'components/navbar/ModalCars/ModalCars';
import { SidebarContext, ISidebarContext } from 'context/SidebarContext';
import i18n from 'i18n';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setShowEbookSidebar } from 'store/slices/ebooks.slice';
import { RootState } from 'store/store';
import { languages, DEFAULT_COUNTRY_IMAGE, countries } from 'utils/constants/locales';
import { IUserPut } from 'utils/hooks/http/user.http';
import useHttp from 'utils/hooks/useHttp';
import { getLanguageFromLocalStorage, getCountryFromLocalStorage, getCountryFromLocale, getLanguageFromLocale } from 'utils/locale';
import { notify } from 'utils/marketplace';
import { TabCategories } from 'views/Sidebar/Sidebar.model';
import './Sidenav.scss';

type Props = {
  isActive: boolean;
  setIsActive: (e: boolean) => void;
};

const Sidenav: React.FC<Props> = (props) => {
  const { setSidebarTab } = useContext(SidebarContext) as ISidebarContext;
  const { user } = useSelector((state: RootState) => state.userData);
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [link, setLink] = useState<string>('');
  const { carInfo } = useSelector((state: RootState) => state.carSelected);
  const carInfoSelected = localStorage.getItem('carSelected') || carInfo ? JSON.parse(localStorage.getItem('carSelected') as string) : null;
  const http = useHttp();
  const [selectedLanguage, setSelectedLanguage] = useState<IDropdownModel>(getLanguageFromLocalStorage() ?? languages[0]);
  const [selectedCountry, setSelectedCountry] = useState<IDropdownModel>(getCountryFromLocalStorage() ?? countries[0]);
  const { t } = useTranslation();
  const [show, setShow] = useState<boolean>(false);
  const dispatch = useDispatch();

  const technicalDocumentationIsDisabled = () => {
    return user?.client_rmi_modules_type === 'none' && !user.client_settings.eina_subscription;
  };

  const clickCategoryPieces = () => {
    if (carInfoSelected) {
      setSidebarTab(TabCategories.Pieces);
    } else {
      setOpenModal(true);
    }
  };

  const changeLanguage = (item: IDropdownModel) => {
    const country = getCountryFromLocale(i18n);

    if (!country) {
      return;
    }

    const locale = `${item.id}-${country.id}`;

    const userData: IUserPut = {
      language: String(item.id),
    };

    try {
      http.user.putUser(userData);
    } catch (e) {
      notify('Error');
    }

    localStorage.setItem('locale', locale);
    i18n.changeLanguage(locale);
  };

  const changeCountry = (item: IDropdownModel) => {
    const language = getLanguageFromLocale(i18n);

    if (!language) {
      return;
    }

    const locale = `${language.id}-${item.id}`;

    localStorage.setItem('locale', locale);
    i18n.changeLanguage(locale);
  };

  useEffect(() => {
    const language = getLanguageFromLocale(i18n);

    if (language) {
      setSelectedLanguage(language);
    }

    const country = getCountryFromLocale(i18n);

    if (country) {
      setSelectedCountry(country);
    }
  }, [i18n.language]);

  const handleEbooksClick = () => {
    dispatch(setShowEbookSidebar(true));
  };

  return (
    <div className={`sidenav ${props.isActive ? 'show-sidenav' : 'hidden-sidenav'}`}>
      <div className="header-sidenav">
        <div
          className="logo"
          onClick={() => {
            navigate('/'), props.setIsActive(false);
          }}
        >
          <img
            src={
              sessionStorage.getItem('logo_url') !== 'null' || !sessionStorage.getItem('logo_url')
                ? sessionStorage.getItem('logo_url')!
                : String(user?.client_logo)
            }
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = '/images/car-part-placeholder.png';
            }}


          />
        </div>
        <div className="close-btn">
          <FontAwesomeIcon
            icon={faXmark}
            onClick={() => {
              props.setIsActive(false), setShow(false);
            }}
            className="close-btn-icon"
          />
        </div>
      </div>
      <div className="tabs-nav">
        <div
          className="tab "
          onClick={() => {
            navigate('/rapid-search'), props.setIsActive(false);
          }}
        >
          <FontAwesomeIcon className="tab-icon" icon={faSearch} width="15" height="15" />
          <div className="text">{t('_rapid_search')}</div>
        </div>
        <div
          className="tab"
          onClick={() => {
            clickCategoryPieces(), props.setIsActive(false);
            setShow(false);
          }}
        >
          <FontAwesomeIcon className="tab-icon" icon={faList} width="15" height="15" />
          <div className="text">{t('_catalogs')}</div>
        </div>
        <div className={`${technicalDocumentationIsDisabled() ? 'disabled' : ''} tab ${show ? 'tehnical' : ''}`}>
          <TechnicalHelpMobile setOpenModal={setOpenModal} setLink={setLink} setShow={setShow} setIsActive={props.setIsActive} />
        </div>
        <div
          className="tab "
          onClick={() => {
            navigate('/orders'), props.setIsActive(false);
          }}
        >
          <FontAwesomeIcon className="tab-icon" icon={faBoxOpen} width="15" height="15" />
          <div className="text">{t('_my_orders')}</div>
        </div>
        <div className="tab ">
          <FontAwesomeIcon className="tab-icon disabled" icon={faFileLines} width="15" height="15" />
          <div className="text disabled">{t('_my_quotes')}</div>
        </div>
        <div
          className="tab "
          onClick={() => {
            navigate('/news'), props.setIsActive(false);
          }}
        >
          <FontAwesomeIcon className="tab-icon" icon={faNewspaper} width="15" height="15" />
          <div className="text">{t('_news')}</div>
        </div>
      </div>
      <div className="setting">
        <button
          type="button"
          className="e-book"
          onClick={() => {
            handleEbooksClick(), props.setIsActive(false), setShow(false);
          }}
        >
          <div className="e-book-button">{t('_catalog_ebooks')}</div>
          <FontAwesomeIcon icon={faFileContract} className="e-book-icon" />
        </button>

        <div className="mini-dropdowns">
          <MiniDropdown onClick={changeLanguage} content={selectedLanguage?.id} data={languages} />
          <MiniDropdown onClick={changeCountry} image={selectedCountry ? selectedCountry.image : DEFAULT_COUNTRY_IMAGE} data={countries} />
        </div>
      </div>
      <div className="footer">
        <img src={require('../../../../assets/images/e-reliable.png')} width="121" alt="e-reliable" />
      </div>
      <Modal openModal={openModal} setOpenModal={setOpenModal} childrenComponent={<ModalCars setOpenModal={setOpenModal} link={link} />} />
    </div>
  );
};

Sidenav.displayName = 'Sidenav';

export default Sidenav;
