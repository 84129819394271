import { ICarPartData } from 'models/car-parts.model';
import React from 'react';
import { getMarketplaceBaseUrl } from 'utils/marketplace';
import './CarPartBarCode.scss';

type Props = {
  item: ICarPartData;
  className?: string;
};

const CarPartBarCode: React.FC<Props> = (props): JSX.Element => {
  const item = props.item;

  if (!item?.barcode?.image) {
    return <div className="car-part-barcode"> </div>;
  }

  return (
    <div className="car-part-barcode">
      <img src={`${getMarketplaceBaseUrl() ?? ''}/${item.barcode.image}`} alt="barcode" />
      <span>{item.barcode.code}</span>
    </div>
  );
};

CarPartBarCode.displayName = 'CarPartBarCode';

export default CarPartBarCode;
