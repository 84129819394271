import { faCar, faTruck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import CatalogCarSelector from './Contents/CatalogCarSelector';
import CatalogTruckSelector from './Contents/CatalogTruckSelector';
import Tab from './Tabs/Tab';
import Tabs from './Tabs/Tabs';
import './VehicleContent.scss';

type Props = {};

const VehicleContentView: React.FC<Props> = () => {
  return (
    <div className="catalog-vehicle-search-card">
      <Tabs>
        <Tab title={<FontAwesomeIcon icon={faCar} />}>
          <CatalogCarSelector />
        </Tab>
        <Tab title={<FontAwesomeIcon icon={faTruck} />}>
          <CatalogTruckSelector />
        </Tab>
      </Tabs>
    </div>
  );
};

VehicleContentView.displayName = 'VehicleContentView';

export default VehicleContentView;
