import { addRouteParam, IHttpResponse, serverAxios } from '../../http';

class PartsReplacementHttp {
  private static listRoute = '/aol/replacements';
  private static createRoute = '/aol/replacement';
  private static deleteRoute = '/aol/replacements/:id';
  private static updateRoute = '/aol/replacements/:id';
  private static importRoute = '/aol/replacements/import';

  public getPartsReplacement(params: { page: number }): Promise<IHttpResponse<IResponse>> {
    return serverAxios.get(PartsReplacementHttp.listRoute, { params });
  }

  public getPartsReplacementById(id: number): Promise<IHttpResponse<IPartsReplacementDetails>> {
    return serverAxios.get(PartsReplacementHttp.listRoute, { params: { id } });
  }

  public editPartsReplacement(data: IPartsReplacementParams, id: number): Promise<IHttpResponse<IPartsReplacementDetails>> {
    return serverAxios.post(addRouteParam(PartsReplacementHttp.updateRoute, { id }), data);
  }

  public createPartsReplacement(data: IPartsReplacementParams): Promise<IHttpResponse<{}>> {
    return serverAxios.post(PartsReplacementHttp.createRoute, data);
  }

  public deletePartsReplacement(id: number): Promise<IHttpResponse<null>> {
    return serverAxios.delete(addRouteParam(PartsReplacementHttp.deleteRoute, { id }));
  }
  public importPartsReplacement(data: FormData): Promise<IHttpResponse<{}>> {
    return serverAxios.post(PartsReplacementHttp.importRoute, data);
  }
}

export interface IResponse {
  page: number;
  per_page: number;
  total_count: number;
  total_pages: number;
  data: Array<IPartsReplacementDetails>;
}

export interface IPartsReplacementDetails {
  codfou_new: string;
  codfou_old: string;
  id: number;
  ref_new: string;
  ref_new_raw: string;
  ref_old: string;
  ref_old_raw: string;
}

export interface IPartsReplacementParams {
  codfou_new: string | null;
  codfou_old: string;
  ref_new: string;
  ref_old: string;
  price: string;
}

export default PartsReplacementHttp;
