
import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import './ExpandButtonDefaut.scss';


type Props = {
  row
}

const ExpandButtonDefaut: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  return (
    <div className="table-expand">
      <div
        {...props.row.getToggleRowExpandedProps()}
        title={t('_more_info')}
        className="expander"
      >
        <div className={`${props.row.isExpanded ? ' open' : 'close'}`}>
          {props.row.isExpanded ? <FontAwesomeIcon icon={faMinusCircle} /> : <FontAwesomeIcon icon={faPlusCircle} />}
        </div>
      </div>
    </div>
  );
};

export default ExpandButtonDefaut;

