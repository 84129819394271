
import { serverAxios, tecRMIAxios } from '../../http';

class TokenHttp {
  private static token = '/reliable/rmi-proxy/token';
  private static polk = '/reliable/TcdIdByPlkId';
  private static tecdoc = '/VehicleTree/AdcTypeIdByTcdTypeAndClass';

  public async fetchRMIAuthorizationToken(): Promise<{ data?}> {
    return serverAxios.get(TokenHttp.token, { params: { origin: window.location.origin } });
  }
  public async fetchTcdIdByPlkId(params: { vehicle_id?: string }): Promise<ITecRMITypeId> {
    return serverAxios.get(TokenHttp.polk, { params });
  }
  public async fetchVehicleTreeAdcTypeIdByTcdTypeAndClass(params: { tcdTypeId?: number, classId: number }): Promise<ITecRMITypeId> {
    return tecRMIAxios.get(TokenHttp.tecdoc, { params });
  }
}

interface ITecRMITypeId {
  data?: number;
}

export default TokenHttp;