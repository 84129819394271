import { createSlice } from '@reduxjs/toolkit';

import { fetchAutomotorTariffInfo, fetchAutomotorTariffs } from 'store/reducers/automotor-tariff/automotor-tariff.actions';

type AutomotorTariffSlice = {
automotorTariff?;
tariffs?
loadingTariff: boolean
};

const initialState: AutomotorTariffSlice = {
  automotorTariff: null,
  tariffs: null,
  loadingTariff: false
};

const automotorTariffSlice = createSlice({
  name: 'automotor_tariff',
  initialState,
  reducers: {
    setAutomotorTariff(state, { payload }) {
      state.automotorTariff = payload;
    },
    setTariffs(state, {payload}){
      state.tariffs = payload;
    },
    resetTariffs(state){
      state.tariffs = null;
    },
    setLoadingTariff(state, {payload}){
      state.loadingTariff = payload;
    }
  
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAutomotorTariffInfo.fulfilled, (state, { payload }) => {
      state.automotorTariff = payload;
    });
    builder.addCase(fetchAutomotorTariffs.pending, (state) => {
      state.loadingTariff = true;
    });
    builder.addCase(fetchAutomotorTariffs.fulfilled, (state, { payload }) => {
      state.tariffs = payload;
      state.loadingTariff = false;
    });
  },
});

export const {setAutomotorTariff, setTariffs, setLoadingTariff, resetTariffs} = automotorTariffSlice.actions;

export default automotorTariffSlice.reducer;
