import {  IHttpResponse, serverAxios } from '../../http';

class ProformaMappingHttp {
  private static submit = '/sellers/profroma-mapping';
  private static get = '/sellers/profroma-mapping';
  private static export = '/proforma-file-header';

  public async commitProforma(data: {}): Promise<IHttpResponse<{}>> {
    return serverAxios.post(ProformaMappingHttp.submit, { ...data });
  }

  public async getProforma(): Promise<IHttpResponse<IGetProforma>> {
    return serverAxios.get(ProformaMappingHttp.get);
  }

  public async getFileHeader(file: string | Blob, headerLine: string | Blob): Promise<IHttpResponse<Blob>> {
    const data = new FormData();

    data.append('csv', file);
    data.append('header_line', headerLine);

    return serverAxios.post(ProformaMappingHttp.export, data ,{ headers: {'content-type': 'multipart/form-data'} } );
  }
}

interface IGetProforma{
client_id: string
columns: {
    code_fou_amf_name: string
    code_fou_amf_number: number
    code_fou_internal_name: string
    code_fou_internal_number:number
    description_name: string
    description_number: number
    item_name: string
    item_number: number
    price_name: string
    price_number: number
    quantity_name: string
    quantity_number: number
    reference_amf_name: string
    reference_amf_number: number
    reference_internal_name: string
    reference_internal_number: number
    }
header_line: number
id: number
status: true
zone_commercial: string
}

export default ProformaMappingHttp;
