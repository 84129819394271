import React, { useMemo } from 'react';
import { Column, useTable } from 'react-table';
import Icon from 'components/Icon/Icon';
import Head from '../Cells/Head/Head';

import './CommandsTable.scss';

type Props = {};

type IData = {
  date: string;
  client: string;
  command: string;
  reference: string;
  vehicol: string;
  article: number;
  price: string;
  envoi: string;
  comment: JSX.Element;
  status: string;
};

const data = [
  {
    date: '02/05/2022',
    client: 'Detaillant vert',
    command: '123456789',
    reference: '987654321',
    vehicol: 'AUDI A3 Limousine (8VS, 8VM) 01395',
    article: 1,
    price: '00,00 €',
    envoi: '-',
    comment: <Icon name="comment" className="comment"></Icon>,
    status: 'Transmis par le client',
  },
  {
    date: '02/05/2022',
    client: 'Auto Plaisirs',
    command: '123456789',
    reference: '987654321',
    vehicol: 'Citroen C4 PICASSO II 05160',
    article: 1,
    price: '00,00 €',
    envoi: '-',
    comment: <Icon name="comment" className="comment"></Icon>,
    status: 'Transmis par le client',
  },
  {
    date: '02/05/2022',
    client: 'Yvelines Auto S.A.',
    command: '123456789',
    reference: '987654321',
    vehicol: 'AUDI A3 Limousine (8VS, 8VM) 01395',
    article: 8,
    price: '00,00 €',
    envoi: '-',
    comment: <Icon name="comment" className="comment"></Icon>,
    status: 'Transmis par le client',
  },
  {
    date: '02/05/2022',
    client: 'AG Stars Auto',
    command: '123456789',
    reference: '987654321',
    vehicol: 'RENAULT Clio 1.2',
    article: 1,
    price: '00,00 €',
    envoi: '-',
    comment: <Icon name="comment" className="comment"></Icon>,
    status: 'Transmis par le client',
  },
  {
    date: '02/05/2022',
    client: 'AM Auto 78',
    command: '123456789',
    reference: '987654321',
    vehicol: 'Citroen C4 PICASSO II 05160',
    article: 1,
    price: '00,00 €',
    envoi: '-',
    comment: <Icon name="comment" className="comment"></Icon>,
    status: 'Transmis par le client',
  },
];

const CommandsTableView: React.FC<Props> = () => {
  const columns = useMemo(
    (): Column<IData>[] => [
      {
        Header: <Head text="Date commande"></Head>,
        accessor: 'date',
      },
      {
        Header: <Head text="Client"></Head>,
        accessor: 'client',
      },
      {
        Header: <Head text="N° commande"></Head>,
        accessor: 'command',
      },
      {
        Header: <Head text="Ref commande (interne)"></Head>,
        accessor: 'reference',
      },
      {
        Header: <Head text="Vehicule"></Head>,
        accessor: 'vehicol',
      },
      {
        Header: <Head text="Nbre article"></Head>,
        accessor: 'article',
      },
      {
        Header: <Head text="Prix ttc"></Head>,
        accessor: 'price',
      },
      {
        Header: <Head text="Envoi commande"></Head>,
        accessor: 'envoi',
      },
      {
        Header: 'Comment.',
        accessor: 'comment',
      },
      {
        Header: <Head text="Statut"></Head>,
        accessor: 'status',
      },
    ],
    [],
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
  });

  return (
    <div className="wrapper">
      <table {...getTableProps()}>
        <thead className="headings">
          {headerGroups.map((headerGroup, index) => {
            return (
              <tr key={headerGroup.headers[index].id} className="row">
                {headerGroup.headers.map((column) => {
                  const { key, ...restColumn } = column.getHeaderProps();

                  return (
                    <th key={key} {...restColumn} className="heading">
                      {column.render('Header')}
                    </th>
                  );
                })}
              </tr>
            );
          })}
        </thead>
        <tbody {...getTableBodyProps} className="body">
          {rows.map((row) => {
            prepareRow(row);
            const { key, ...restRowProps } = row.getRowProps();

            return (
              <tr key={key} {...restRowProps} className="row">
                {row.cells.map((cell) => {
                  const { key, ...restCellProps } = cell.getCellProps();

                  return (
                    <td key={key} {...restCellProps} className="cell">
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

CommandsTableView.displayName = 'CommandsTableView';

export default CommandsTableView;
