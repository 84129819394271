import React from 'react';

import './DistributorCell.scss';

type Props = { value: string };

const DistributorCellView: React.FC<Props> = (props: Props) => {
  return <div className="wrapper-distributor-cell-cart">{props.value}</div>;
};

DistributorCellView.displayName = 'DistributorCellView';

export default DistributorCellView;
