export const Marketplaces = {
  AUTOMOTOR: 'automotor',
  PUBLIC_TR: process.env.REACT_APP_MARKETPLACE_PUBLIC_TR,
  PUBLIC_MA: process.env.REACT_APP_MARKETPLACE_PUBLIC_MA,
  PUBLIC_TN: process.env.REACT_APP_MARKETPLACE_PUBLIC_TN,
  PUBLIC_DZ: process.env.REACT_APP_MARKETPLACE_PUBLIC_DZ,
  MRA: process.env.REACT_APP_MARKETPLACE_MRA,
  FOURNISSEUR: process.env.REACT_APP_MARKETPLACE_FOURNISSEUR,
  DISTRIBUTEUR: process.env.REACT_APP_MARKETPLACE_DISTRIBUTEUR,
  AMERIGO: 'amerigo',
  RETAILER: 'retailer',
  PUBLIC: 'public',
  AF: 'af',
  PUNCHOUT: 'punchout',
  PRESENTATION: 'presentation',
};

// Catalogs
export const CATALOG_REL = 'REL';

export const CATALOG_AUT = 'AUT';

export const CATALOG_TECDOC_SUPPLIER = 1;

export const CATALOG_MANUFACTURER = 2;

export const MAX_ALLOWED_CAR_PARTS = 10000;

export const VALID_ZONE_COMMERCIAL_FOR_AUTOMOTOR_ORDER = 'ZCW';
