import NavBarAutomotor from 'applications/automotor/components/espace/navbar/NavBarAutomotor';
import Footer from 'components/footer/Footer';
import DefaultToastContainer from 'components/toast-container/DefaultToastContainer';
import React from 'react';
import { Outlet } from 'react-router-dom';
import '../../../DefaultCss.scss';
import Sidebar from 'views/Sidebar/Sidebar';
import { SidebarContextProvider } from 'context/SidebarContext';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';

const MainLayout: React.FC = () => {
  const { isMobile, isTablet } = useDeviceDetect();

  return (
    <div
      className={`app-automotor container-wrapper ${isMobile ? 'app-layout-mobile' : isTablet ? 'app-layout-tablet' : 'app-layout-desktop'
      }`}
    >
      <DefaultToastContainer />
      <div className="container">
        <SidebarContextProvider>
          <div className="sidebar">
            <Sidebar />
          </div>
          <div className="header">
            <NavBarAutomotor />
          </div>
          <div className="body">
            <Outlet />
          </div>
          <div className="footer">
            <Footer />
          </div>
        </SidebarContextProvider>
      </div>
    </div>
  );
};

MainLayout.displayName = 'MainLayout';

export default MainLayout;
