

import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useHttp from 'utils/hooks/useHttp';
import './EditCountryRestriction.scss';
import Spinner from 'components/spinner/Spinner';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import 'react-datepicker/dist/react-datepicker.css';
import { notify } from 'utils/marketplace';
import i18n from 'i18n';
import { useLocaleDatePicker } from 'utils/localeDatePicker';
import { IOption } from 'interfaces/select';
import Select from 'react-select';
import { IData } from 'interfaces/selectClient';
import { IFilterValue } from 'interfaces/tyre';


type Props = {
  setOpenModal: (e: boolean) => void;
  setSubmit: (e: boolean) => void;
  clients
  countries
  loading: boolean
  editRow?
};


const EditCountryRestriction: React.FC<Props> = (props) => {

  const { t } = useTranslation();
  const http = useHttp();


  const [loading, setLoading] = useState<boolean>(props.loading);
  const [loadingSave, setLoadingSave] = useState<boolean>(false);
  const { isMobile } = useDeviceDetect();
  const [country, setCountry] = useState<IOption[]>([]);
  const [client, setClient] = useState<IOption | null>();
  const [selectCountry, setSelectCountry] = useState<IOption | null>();


  const { getValues, handleSubmit, control, formState: { errors }, } = useForm<{ origin_country: IFilterValue; client_id: IFilterValue; }>();


  useLocaleDatePicker(i18n.language);

  useEffect(() => {
    if (!props.loading) {
      setCountry(props.countries.map((item) => ({
        label: String(item.name),
        value: String(item.iso_code),
      })));
      setClient({ value: props.editRow.client_id, label: String(props.editRow.client_name) });
      setSelectCountry({ value: props.editRow.iso_code, label: String(props.editRow.country_origin) });
      setLoading(false);
    }


  }, [props.loading]);

  const onSubmit = async () => {
    const values = getValues();

    setLoadingSave(true);
    const formData: FormData = new FormData();

    formData.append('client_id', String(client?.value));
    formData.append('origin_country', String(selectCountry?.value));


    http.countryRestriction.editCountryRestriction(formData, props.editRow.id).then(() => {
      notify(t('_created_successfully'), 'success', t('_success'));
      props.setOpenModal(false);
      props.setSubmit(true);
      setLoadingSave(false);
    }).catch((error) => {
      let erorrs = '';

      Object.entries(error).forEach(([key, value]) => {
        erorrs += `${t('_' + String(value))}`;
      });
      notify(erorrs, 'error', t('_error'));
      setLoadingSave(false);
    });

  };

  const customStyles = (hasError) => ({
    control: (styles) => ({
      ...styles,
      ...(hasError ? { borderColor: 'red' } : { border: 'none' }),
    }),
  });


  return (
    <div className="create-country-restriction-container" >
      <div className="close-modal" onClick={() => { props.setOpenModal(false); }}><FontAwesomeIcon icon={faClose} /></div>
      <div className="title">
        {t('_edit_country_restriction')}
      </div>


      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-info">


          <div className="row">
            <div className="container">
              <label  >
                {t('_client')}
              </label>
              <div className="input-item">
                <Controller
                  control={control}
                  name="client_id"
                  render={({ field: { onChange } }) => {
                    return (
                      <Select
                        value={client}
                        className="select-dropdown"
                        onChange={(val) => {
                          onChange(val);
                          setClient(val);
                        }}
                        options={props.clients}
                        placeholder={t('_client')}
                        isDisabled
                        styles={customStyles(errors.client_id?.label?.type)}
                        isSearchable={!isMobile}
                      />
                    );
                  }}
                />
                <p className="error" >{errors.client_id?.label?.type == 'required' ? errors.client_id?.label.message : ''}</p>
              </div>


            </div>
            <div className="container">
              <label  >
                {t('_origin_country')}
              </label>
              <div className="input-item">
                <Controller
                  control={control}
                  name="origin_country"
                  render={({ field: { onChange } }) => {
                    return (
                      <Select
                        value={selectCountry}
                        className="select-dropdown"
                        onChange={(val) => {
                          onChange(val);
                          setSelectCountry(val);
                        }}
                        options={country}
                        placeholder={t('_origin_country')}
                        styles={customStyles(errors.origin_country?.label?.type)}
                        isSearchable={!isMobile}
                      />
                    );
                  }}
                />
                <p className="error" >{errors.origin_country?.label?.type == 'required' ? errors.origin_country?.label.message : ''}</p>
              </div>


            </div>
          </div>

        </div>
        <div className="btn-save">
          <button type="submit"> {loadingSave ? <Spinner class="extra-small" /> : null} <div className="title-btn-save">{t('_save')}</div></button>
        </div>
      </form >


    </div >

  );

};

EditCountryRestriction.displayName = 'EditCountryRestriction';

export default EditCountryRestriction; 