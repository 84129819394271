
import TextInput from 'components/inputs/TextInput/TextInput';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useHttp from 'utils/hooks/useHttp';
import './BrandEdit.scss';

import Spinner from 'components/spinner/Spinner';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { notify } from 'utils/marketplace';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { IBrand, IBrandValidate } from 'interfaces/batteryBrand';
import UploadImage from 'components/UploadImage/UploadImage';


type Props = {
  setOpenModal: (e: boolean) => void;
  setSubmit: (e: boolean) => void;
  id: number | undefined
};


const BrandEdit: React.FC<Props> = (props) => {

  let isMounted = true;
  const { t } = useTranslation();
  const http = useHttp();
  const [file, setFile] = useState<File[]>([]);
  const [brandInfo, setBrandInfo] = useState<IBrand>();
  const [loading, setLoading] = useState<boolean>(false);


  const validate = Yup.object().shape({

    name: Yup.string().trim().required(t('_fild_required')),
    erp_brand_id: Yup.string().trim().required(t('_fild_required'))
  });

  const { getValues, register, handleSubmit, formState: { errors } } = useForm<IBrandValidate>({ resolver: yupResolver(validate) });

  useEffect(() => {

    if (props.id && isMounted) {
      http.batteryBrandHttp.getBrandInfo(props.id)
        .then((res) => {
          setBrandInfo(res.data);
        });
    }

    return () => {
      isMounted = false;
    };

  }, []);


  const onSubmit = async () => {
    setLoading(true);
    const values = getValues();
    const formData: FormData = new FormData();

    formData.append('name', values.name.trim());
    formData.append('erp_brand_id', values.erp_brand_id.trim());
    formData.append('is_partner', String(values.is_partner));


    if (file.length) {
      formData.append('image', file[0]);
    }

    http.batteryBrandHttp.edit(Number(props?.id), formData).then(() => {
      notify(t('_battery_brand_updated'), 'success', t('_success'));
      props.setOpenModal(false);
      props.setSubmit(true);
    }).catch((error) => {
      let erorrs = '';

      Object.entries(error).forEach(([key, value]) => {
        erorrs += `${t('_' + key)} : ${value} `;
      });
      notify(erorrs, 'error', t('_error'));
      setLoading(false);
    });

  };


  return (
    <div className="battery-brand-edit-container" >
      <div className="close-modal" onClick={() => { props.setOpenModal(false); }}><FontAwesomeIcon icon={faClose} /></div>

      <div className="title">
        {t('_battery_title_edit')}
        <br />
        <span>{t('_battery_brand_title_edit')}</span>
      </div>


      {
        !brandInfo ?
          <div className="spinner-info"> <Spinner />  </div> :
          <React.Fragment >
            <div className="info-item">
              {t('_battery_item_count')}
              <br />
              <span>{brandInfo?.name}</span>
            </div>

            <form onSubmit={handleSubmit(onSubmit)}>
              <div  >


                <div className="row">
                  <div className={`container ${errors.name?.type == 'required' ? 'error' : ''}`}>
                    <label  >
                      {t('_name')}
                    </label>
                    <TextInput defaultValue={brandInfo?.name} {...register('name')} />
                    <p className="error-message">{errors.name?.type == 'required' ? errors.name?.message : ''}</p>

                  </div>
                  <div className={`container ${errors.erp_brand_id?.type == 'required' ? 'error' : ''}`}>
                    <label  >
                      {t('_erp_brand_id')}
                    </label>
                    <TextInput defaultValue={brandInfo?.erp_brand_id} {...register('erp_brand_id')} />
                    <p className="error-message">{errors.erp_brand_id?.type == 'required' ? errors.erp_brand_id?.message : ''}</p>

                  </div>
                  <div className="container">
                    <UploadImage errors={errors} setFile={setFile} image={brandInfo?.image} />
                  </div>

                </div>
                <div className="row">
                  <div className="container is-partner-checkbox-container">
                    <label htmlFor="is_partner">{t('_partner_brand')}</label>
                    <input type="checkbox" id="is_partner" {...register('is_partner')} defaultChecked={brandInfo?.is_partner} />
                  </div>
                </div>
              </div>
              <div className="btn-save">
                <button type="submit" className={loading ? 'disabled' : ''}> {loading ? <Spinner class="extra-small" /> : null} <div className="title-btn-save">{t('_battery_update_article')}</div></button>
              </div>
            </form>
          </React.Fragment>
      }

    </div>

  );

};

BrandEdit.displayName = 'BrandEdit';

export default BrandEdit; 