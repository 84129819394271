import React, {useEffect} from 'react';
import {
  fetchRMIVehicleManualsList,
  IRMIVehicleManualsList
} from '../../../store/reducers/car-parts-categories/car-parts-categories.actions';
import {getCurrentCountryRMI, getCurrentLanguageRMI} from '../../rmi/rmi-utils/rmi-utils';
import {useSelector} from 'react-redux';
import {RootState} from '../../../store/store';
import {useTranslation} from 'react-i18next';
import {useAppDispatch} from '../redux';

const useFetchRmiVehicleManualsList = (): void => {
  const { typeId } = useSelector((state: RootState) => state.tecrmi);
  const { vehicleManualsList, partsCategories} = useSelector((state: RootState) => state.carPartsCategories);
  const { i18n } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {

    if (typeId && typeId > 0 && !vehicleManualsList && partsCategories.length ) {
      const rmiVehicleManualsList: IRMIVehicleManualsList = {
        typeId,
        countryCode: getCurrentCountryRMI(i18n.language),
        languageCode: getCurrentLanguageRMI(i18n.language),
      };

      dispatch(fetchRMIVehicleManualsList({...rmiVehicleManualsList}));
    }

  }, [typeId, partsCategories]);

};

export default useFetchRmiVehicleManualsList;