import {
  options,
  power,
  info,
  heart,
  gear,
  search,
  comment,
  arrowUp,
  arrowDown,
  arrowLeft,
  arrowRight,
  upload,
  favorite,
  calendar,
  applications,
  close,
  cart,
  emptyHeart,
  home,
  grid,
  line,
  circleLeftRegular,
} from './custom';

const icons = {
  info,
  power,
  heart,
  gear,
  search,
  comment,
  arrowUp,
  arrowDown,
  arrowLeft,
  arrowRight,
  upload,
  options,
  favorite,
  calendar,
  applications,
  close,
  cart,
  emptyHeart,
  home,
  GRID : grid,
  LIST : line,
  circleLeftRegular,
};

export default icons;
