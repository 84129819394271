import React, { useEffect, useState } from 'react';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import TextInput from 'components/inputs/TextInput/TextInput';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import './RegisterClientModal.scss';
import useHttp from 'utils/hooks/useHttp';
import { IDistributor, IStore } from 'utils/hooks/http/public.http';
import { getCurrentMarketplace, notify } from 'utils/marketplace';
import { Marketplaces } from 'utils/constants/applications';

type Props = {
  setOpenModal?: (openModal: boolean) => void;
  hasAccess?: string | null
}

const RegisterClientModal: React.FC<Props> = (props) => {
  const { handleSubmit, control, register, getValues, formState: { errors } } = useForm();

  const [distributors, setDistributors] = useState<IDistributor[]>([]);
  const [stores, setStores] = useState<IStore[]>([]);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState(false);

  const http = useHttp();
  const { t } = useTranslation();

  function getDistributors() {
    http.publicApp.getDistributors().then(res => {
      setDistributors(res.data);
    });
  }

  function getStores() {
    http.publicApp.getStores().then(res => {
      setStores(res.data);
    });
  }

  function getDistributorsOptions() {
    return distributors.map(distributor => {
      return {
        value: distributor.numcli,
        label: distributor.name,
      };
    });
  }

  function geStoresOptions() {
    return stores.map(store => {
      return {
        value: store.id,
        label: store.name,
      };
    });
  }

  function submitForm() {
    if (!getValues().phone || !getValues().name || !getValues().address || !getValues().email || !getValues().distributor) { return; }


    setIsSubmitting(true);

    const data = {
      phone: getValues().phone,
      full_name: getValues().name,
      address: getValues().address,
      email: getValues().email,
      store_type_id: getValues().store?.value,
      distributor_id: getValues().distributor?.value,
    };

    http.publicApp.postInvitation(data)
      .then(() => {
        notify(t('_invitation_sent'), 'success');
        setSubmitted(true);
      })
      .catch(err => {
        notify(err?.email);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  }


  useEffect(() => {
    if (localStorage.getItem('locale')) {
      getDistributors();
      getStores();
    }
  }, [localStorage.getItem('locale')]);

  if (getCurrentMarketplace() === Marketplaces.PUBLIC_DZ) {
    return <div className="register-client-modal">
      <div className="register-client-header">
        <div className="register-client-ereliable-logo">
          <img src={require('assets/images/e-reliable.png')} alt="" />
        </div>
        {props.hasAccess &&
          <div className="register-client-close-btn">
            <FontAwesomeIcon icon={faClose} onClick={() => props.setOpenModal!(false)} />
          </div>
        }
      </div>
      <div className="register-client-content dz">
        <h1 className="title">{t('_registration')}</h1>
        <div className="dz-message-content">
          <div className="dz-message-ar">.من أجل إعادة تنشيط حسابك ، يُرجى التواصل مع الموزع الخاص بك</div>
          <div className="dz-message-fr">Afin de réactiver votre compte, merci de prendre contact avec votre distributeur.</div>
          <div className="dz-message-en">In order to reactivate your account, please get in touch with your distributor.</div>
        </div>
        <div className="dz-client-list">
          {distributors?.map(distributor => (
            <div key={distributor.numcli} className="dz-client">
              {distributor?.name}: TEL: {distributor?.phone} / E-mail: {distributor?.email}
            </div>
          ))}
        </div>
      </div>
    </div>;
  }

  return (
    <div className="register-client-modal">
      <div className="register-client-header">
        <div className="register-client-ereliable-logo">
          <img src={require('assets/images/e-reliable.png')} alt="" />
        </div>
        {props.hasAccess &&
          <div className="register-client-close-btn">
            <FontAwesomeIcon icon={faClose} onClick={() => props.setOpenModal!(false)} />
          </div>
        }
      </div>
      {!submitted ?
        <form className="register-client-content" onSubmit={handleSubmit(submitForm)}>
          <h1 className="title">{t('_registration')}</h1>
          <div className="form-items">
            <div className="input-item">
              <div className="input-label">
                {t('_select_your_distributor')}
                :
              </div>
              <Controller
                control={control}
                name="distributor"
                rules={{ required: t('_please_select_distributor') }}
                render={({ field: { onChange } }) => (
                  <Select
                    className={`select-dropdown ${errors.distributor ? 'dropdown-error' : ''}`}
                    onChange={val => {
                      onChange(val);
                    }}
                    options={getDistributorsOptions()}
                    placeholder={t('_select_your_distributor')}
                  />
                )}
              />
              {errors.distributor ? <div className="input-error-message">{errors.distributor?.message as string}</div> : null}
            </div>
            <div className="input-item">
              <div className="input-label">
                {t('_name_surname')}
                :
              </div>
              <TextInput
                whiteColor
                validationError={!!errors.name}
                {...register('name', { required: t('_name_required') })}
              />
              {errors.name ? <div className="input-error-message">{errors.name?.message as string}</div> : null}
            </div>
            <div className="input-item">
              <div className="input-label">
                {t('_store_type')}
                :
              </div>
              <Controller
                control={control}
                name="store"
                rules={{ required: t('_please_select_store_type') }}
                render={({ field: { onChange } }) => (
                  <Select
                    className={`select-dropdown ${errors.store ? 'dropdown-error' : ''}`}
                    onChange={val => {
                      onChange(val);
                    }}
                    options={geStoresOptions()}
                    placeholder={t('_select_store_type')}
                  />
                )}
              />
              {errors.store ? <div className="input-error-message">{errors.store?.message as string}</div> : null}
            </div>
            <div className="inputs-container">
              <div className="input-item">
                <div className="input-label">
                  {t('_email')}
                  :
                </div>
                <TextInput
                  whiteColor
                  validationError={!!errors.email}
                  {...register('email', {
                    required: t('_email_required'),
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: t('_email_invalid'),
                    },
                  })}
                />
                {errors.email ? <div className="input-error-message">{errors.email?.message as string}</div> : null}
              </div>
              <div className="input-item">
                <div className="input-label">
                  {t('_phone')}
                  :
                </div>
                <TextInput
                  whiteColor
                  validationError={!!errors.phone}
                  {...register('phone', { required: t('_phone_required') })}
                />
                {errors.phone ? <div className="input-error-message">{errors.phone?.message as string}</div> : null}
              </div>
            </div>
            <div className="input-item">
              <div className="input-label">
                {t('_address')}
                :
              </div>
              <TextInput
                whiteColor
                validationError={!!errors.address}
                {...register('address', {
                  required: t('_address_required'),
                })}
              />
              {errors.address ? <div className="input-error-message">{errors.address?.message as string}</div> : null}
            </div>
          </div>
          <div className="submit-form-btn">
            <DefaultButton isloading={isSubmitting} type="submit" text={t('_save')} />
          </div>
        </form>
        :
        <div className="submitted-register-client">
          <div className="submitted-message">
            {t('_invitation_sent')}
          </div>
        </div>
      }
    </div>
  );
};

export default RegisterClientModal;
