import React from 'react';
import { useTranslation } from 'react-i18next';
import '../Solutions.scss';
import {IEinaTechnotes} from 'utils/eina/interface/eina.interface';
import Spinner from 'components/spinner/Spinner';
import {isEmpty} from 'lodash';
import {createSearchParams, NavLink} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircleArrowLeft} from '@fortawesome/free-solid-svg-icons';
import useReliableCatalogCarQueryParams from 'utils/hooks/useReliableCatalogCarQueryParams';

type Props = {
  loading: boolean;
  technote: IEinaTechnotes;
  solutionListLink?: string;
  queryParams?: object
};

const SolutionsItem: React.FC<Props> = (props: Props) => {
  const { t }= useTranslation();
  const reliableCatalogCarQueryParams = useReliableCatalogCarQueryParams();

  return (
    props.loading ? <Spinner /> : (
      isEmpty(props.technote) ? <div className="no-data">{t('_no_data')}</div> : (
        <React.Fragment>
          <div className="returnButton">
            <NavLink className="returnLink" to={`${props.solutionListLink}?${createSearchParams({...reliableCatalogCarQueryParams, ...props.queryParams})}`}>
              <FontAwesomeIcon icon={faCircleArrowLeft} className="icon" />
              {`${t('_return')}`}
            </NavLink>
          </div>
          {props.technote['dtc error codes'] &&
            <div className="technotes-title">
              {String(props.technote['dtc error codes'].join(', '))}
            </div>
          }
          <div className="technotes-container inner-container">
            <div className="simptoms">
              <strong>{t('_error_code_symptom')}</strong>
              {props.technote['symptom']}
            </div>
            <div className="separator-line"></div>
            <div className="cause">
              <strong>{t('_error_code_cause')}</strong>
              {props.technote['cause']}
            </div>
          </div>
          <div className="technotes-container reparation-container">
            <div className="reparation-title">
              <strong>{t('_error_code_reparation_procedure')}</strong>
            </div>
            <div className="reparation-description inner-container">
              {props.technote['solution']}
            </div>
          </div>
        </React.Fragment>
      )
    )
  );
};

SolutionsItem.displayName = 'SolutionsItem';

export default SolutionsItem;
