import SkeletonSpinner from 'components/skeleton-spinner/SkeletonSpinner';
import React, { MutableRefObject, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { createSearchParams, generatePath, useNavigate } from 'react-router-dom';
import { IData } from 'utils/hooks/http/search-by-reference.http';
import { useOnClickOutside } from 'utils/hooks/useOnClickOutside';
import './RefDropdownContentPartsAdmin.scss';

type Props = {
  refData: IData[] | undefined;
  setIsOpen: (isOpen: boolean) => void;
  loading: boolean;
  refValue?: string
};

const RefDropdownContentPartsAdmin: React.FC<Props> = (props) => {
  const referenceDropdownRef = useRef() as MutableRefObject<HTMLDivElement>;
  const { t } = useTranslation();
  const navigate = useNavigate();

  useOnClickOutside(referenceDropdownRef, () => props.setIsOpen(false));

  function handleRefClick(item) {
    navigate({
      pathname: generatePath('/retailer/car-parts'),
      search: `?${createSearchParams([
        ['page', '1'],
        ['source', item.source],
        ['subcategory_id', item.subcategory_id],
        ['man_sup_id', item.man_sup_id],
        ['q', item.reference],
        ['type', item.type],
        ['is_platform_admin', true]
      ])}`,
    });
  }

  return (
    <div ref={referenceDropdownRef} className="ref-dropdown-content-parts-admin">
      <div className="ref-dropdown-header">
        <div className="ref-dropdown-title">{t('_search_result')}</div>
      </div>
      <div className="ref-search-result-content">
        {props.loading ? (
          <SkeletonSpinner />
        ) : (
          <React.Fragment>
            {props.refData?.length ? (
              props.refData?.map((item, index) => {
                return (
                  <li key={index} className="suggestion-list-item" onClick={() => handleRefClick(item)}>
                    <div className={`suggestion-item-title ${item.reference_commerciale ? 'has-commercial-reference' : '' }`}>
                      <span>
                        {
                          item.reference_commerciale ?
                            <div className="suggestion-item-replacement">
                              <div>{item.reference}</div>
                              <span className="commercial-ref">{t('_reference_commerciale')}: {item.reference_commerciale}</span>
                            </div>
                            :
                            <div>{item.reference}</div>
                        }
                      </span>
                      <span>{item.supplier_or_manufacturer}</span>
                      <span>{item.category_name}</span>
                    </div>
                  </li>
                );
              })
            ) : (
              <div className="no-data">{t('_no_data').toLocaleUpperCase()}</div>
            )}
            { }
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

RefDropdownContentPartsAdmin.displayName = 'RefDropdownContentPartsAdmin';

export default RefDropdownContentPartsAdmin;
