
import React, {useEffect, useState } from 'react';
import MinimumOrderValueItem from './MinimumOrderValueItem';
import Pagination from 'components/pagination/Pagination';
import Spinner from 'components/spinner/Spinner';
import useHttp from 'utils/hooks/useHttp';
import Select from 'react-select';
import { ICountries, IData, IDataPagination, IFilterForm, IZoneCommercials } from 'interfaces/selectClient';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import { Controller, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './MinimumOrderValue.scss';

interface IOption {
  label: string;
  value: string;
}

const MinimumOrderValue: React.FC = () => {
  const { t } = useTranslation();
  const { isMobile, isTablet } = useDeviceDetect();
  const http = useHttp();
  const [responseClient, setResponseClient] = useState<IDataPagination & IData[]>();
  const [zoneCommercials, setZoneCommercials] = useState<IZoneCommercials[]>([]);
  const [countries, setcountries] = useState<ICountries[]>([]);
  const [curentPage, setCurentPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(true);
  const [infoClients, setInfoClients] = useState<IData[]>([]);
  const { getValues, register, handleSubmit, reset, control } = useForm<IFilterForm>();
  const [valueCountries, setValueCountries] = useState<IOption | null>(null);
  const [valueZoneCommercials, setValueZoneCommercials] = useState<IOption | null>(null);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    fetchRequest();
    fetchZoneCommercial();
  }, []);

  const fetchZoneCommercial = () => {
    http.selectClient.zoneCommercials().then((res) => setZoneCommercials(res.data));
  };

  useEffect(() => {
    if (responseClient) {
      setLoading(true);
      setCurentPage(Number(searchParams.get('page')) || 1);
      setTotalPage(responseClient.total_pages);
      setInfoClients(responseClient.data);
      setLoading(false);
    }
  }, [responseClient]);

  const optionsZoneCommercials = (): IOption[] => {
    return (
      zoneCommercials?.map((item) => ({
        label: item.name,
        value: item.id,
      })) || []
    );
  };

  const optionsCountries = () => {
    return (
      countries?.map((item) => ({
        label: item.name,
        value: item.id,
      })) || []
    );
  };

  const onSubmit = async () => {
    setLoading(true);
    const data = getValues();

    await fetchClients(data);
    setLoading(false);
  };

  const resetFilter = async () => {
    resetData();

    const data = getValues();

    await fetchClients(data);
  };

  function resetData() {
    setLoading(true);
    setLoading(false);
    setValueCountries(null);
    setValueZoneCommercials(null);
    reset({ name: '' });
    setLoading(false);
  }

  const fetchClients = async (param: IFilterForm) => {
    setLoading(true);
    const responseClient = await http.selectClient.selectClient(param);

    setResponseClient(responseClient.data);
  };

  const fetchRequest = async () => {
    Promise.all([fetchClients({ page: Number(searchParams.get('page') || 1) }), http.selectClient.countries()])
      .then((res) => {
        setcountries(res[1].data);
      })
      .finally(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handlePageClick = async (page: number) => {
    setLoading(true);
    const selectPage = page;
    const params = getValues();

    await fetchClients({ ...params, page: selectPage || 1 });
    setLoading(false);
  };

  return (
    <div className="minimum-order-value">
      <div className="order-value__container-top">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="container-top-filter">
            <div className="filter-input">
              <div className="filter-item">
                <input
                  className="select-client-name"
                  placeholder="Par nom de client"
                  {...register('name')}
                />
              </div>
              <div className="filter-item">
                <Controller
                  control={control}
                  name="country"
                  render={({ field: { onChange } }) => (
                    <Select
                      value={valueCountries}
                      className="select-dropdown"
                      onChange={(val) => {
                        onChange(val?.value);
                        setValueCountries(val);
                      }}
                      options={optionsCountries()}
                      isSearchable={!isMobile && !isTablet}
                      placeholder={t('_select_country')}
                    />
                  )}
                />
              </div>
              <div className="filter-item">
                <Controller
                  control={control}
                  name="zone_commercial"
                  render={({ field: { onChange } }) => (
                    <Select
                      value={valueZoneCommercials}
                      className="select-dropdown"
                      onChange={(val) => {
                        onChange(val?.value);
                        setValueZoneCommercials(val);
                      }}
                      options={optionsZoneCommercials()}
                      isSearchable={!isMobile && !isTablet}
                      placeholder={t('_select_zone_commercial')}
                    />
                  )}
                />
              </div>
            </div>

            <div className="filter-button">
              <div className="filter-item">
                <button className="filter-btn-search" type="button" onClick={resetFilter}>
                  {t('_reset')}
                </button>
                <button className="filter-btn-reset" type="submit">
                  {t('_filter')}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>

      <div className="order-value__container-middle">
        <div className="clients-list">
          {loading ? (
            <Spinner class="spinner-client" />
          ) : (
            infoClients?.length ? (
              infoClients?.map((item) => {
                return (
                  <MinimumOrderValueItem
                    key={item.id}
                    fetchClients={fetchClients}
                    info={item}
                  />
                );
              })
            ) : (
              <div className="no-data">{t('_no_data').toLocaleUpperCase()}</div>
            )
          )}
        </div>
        <div className="container-pagination">
          {totalPage > 1 ? (
            <Pagination pageCount={totalPage} handlePageClick={handlePageClick} forcePage={curentPage} />
          ) : null}
        </div>
      </div>
    </div >
  );
};

export default MinimumOrderValue;
