import React from 'react';
import { IPromotionDetails } from 'utils/hooks/http/promotions.http';
import SimpleAdView from './SimpleAd.view';

type Props = {
  items: IPromotionDetails[];
  slidesPerView?: number | 'auto';
  navigation?: boolean;
  pagination?: boolean;
  autoplay?: boolean;
  loop?: boolean;
  onClick?: () => void;
  speed?: number
  delay?: number
};

const SimpleAd: React.FC<Props> = (props) => {
  return (
    <SimpleAdView {...props} />
  );
};

SimpleAd.displayName = 'SimpleAdView';

export default SimpleAd;