import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { ReactComponent as PartReplacement } from 'assets/images/part-replacement.svg';

const PartsReplacement: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="item-price">
      <NavLink to="/price/replacement">
        <div className="title">{t('_parts_replacement')}</div>
        <div className="icon-wrapper">
          <div className="icon">
            <PartReplacement />
          </div>
          <div className="text">{t('_parts_replacement')}</div>
        </div>
      </NavLink>
    </div>
  );
};

export default PartsReplacement;
