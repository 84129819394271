import Modal from 'components/modal/Modal';
import ComponentsForTypeModal from 'components/ModalTCRMI/ComponentsForTypeModal';
import Spinner from 'components/spinner/Spinner';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { generatePath, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { RootState } from 'store/store';
import useHttp from 'utils/hooks/useHttp';
import useReliableCatalogCarQueryParams from 'utils/hooks/useReliableCatalogCarQueryParams';
import { converObjectToParams } from 'utils/marketplace';

import { IBoxConfigurationsDiagnostic, IComponentsForType, ISelectModalDiagnostic } from 'utils/rmi/interface/diagnostic.interface';
import {
  ITecCompPositionGroup,
  ITecCompPositionItemManual,
  ITecCompPositionItemMp,
  ITecCompPositionSubGroup,
} from 'utils/rmi/interface/tec-com-positions';
import { IRMIModulesLinkChildren, IRMIModulesLinkModal, RELIABLE_TAB_NAMES } from 'utils/rmi/reliableTabs';
import { getCurrentCountryRMI, getCurrentLanguageRMI } from 'utils/rmi/rmi-utils/rmi-utils';
import SidebarDetails from '../../SidebarDetails/SidebarDetails';
import VehicleIframe from '../../VehicleIframe/VehicleIframe';
import ComponentsLinkModal from '../ComponentsLinkModal/ComponentsLinkModal';
import './ComponentsPosition.scss';

const ComponentsPosition: React.FC = () => {
  const http = useHttp();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  let componentMounted = true;
  const [searchParams, setSearchParams] = useSearchParams();
  const [loadingList, setLoadingList] = useState(false);
  const [loadingHTML, setLoadingHTML] = useState(false);
  const { typeId } = useSelector((state: RootState) => state.tecrmi);
  const [iframeData, setIframeData] = useState<string>();
  const [sidebarDetails, setSidebarDetails] = useState(Number(searchParams.get('level')) || 1);
  const [componentType, setcomponentType] = useState<IComponentsForType[]>([]);
  const [componentTypeId, setComponentTypeId] = useState<number>(Number(searchParams.get('component_type_id')) || 0);
  const [manualList, setManualList] = useState<ITecCompPositionGroup[]>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectHandler, setSelectHandler] = useState<ISelectModalDiagnostic | null>(null);
  const [mainGroup, setMainGroup] = useState<ITecCompPositionGroup>();
  const [subGroups, setSubGroups] = useState<ITecCompPositionSubGroup>();
  const [itemMpsManual, setItemMpsManual] = useState<ITecCompPositionItemMp>();
  const [activeItem, setActiveItem] = useState<number | string | undefined>();
  const { vehicle_id } = useParams();
  const [moreThanOneManual, setMoreThanOneManual] = useState<boolean>(false);
  const [boxDetailOptions, setBoxDetailOptions] = useState<ISelectModalDiagnostic[]>([]);
  const [rmiModulesLinkModal, setRmiModulesLinkModal] = useState<Array<IRMIModulesLinkModal>>([]);
  const [openModalLink, setOpenModalLink] = useState<boolean>(false);
  const reliableCatalogCarQueryParams = useReliableCatalogCarQueryParams();

  function hasMoreThanOneManual(groups: ITecCompPositionGroup[]): [boolean, ITecCompPositionItemManual | null] {
    if (groups.length === 0) {
      return [false, null];
    }

    if (
      groups.length > 1 ||
      groups[0]?.SubGroups.length > 1 ||
      groups[0]?.SubGroups[0]?.ItemMps.length > 1 ||
      groups[0]?.SubGroups[0]?.ItemMps[0]?.Manuals.length > 1
    ) {
      return [true, null];
    }

    const manual = groups[0]?.SubGroups[0]?.ItemMps[0]?.Manuals[0];

    return [false, manual];
  }

  useEffect(() => {
    if (typeId && componentMounted) {
      setLoadingList(true);
      http.componentsHttpTecRmi
        .fetchRMIVehicleList({
          typeId,
          countryCode: getCurrentCountryRMI(i18n.language),
          languageCode: getCurrentLanguageRMI(i18n.language),
        })
        .then(({ data }) => {
          setManualList(data);

          const [moreThanOne, manual] = hasMoreThanOneManual(data);

          setMoreThanOneManual(moreThanOne);

          if (manual) {
            setActiveItem(manual?.ManualId);
            searchParams.set('manual_id', String(manual?.ManualId));

            setSearchParams(searchParams);

            return;
          }
        })
        .finally(() => {
          setLoadingList(false);
        });

      http.diagnostictHttp
        .fetchRMIVehicleComponentsForType({
          typeId,
          countryCode: getCurrentCountryRMI(i18n.language),
          languageCode: getCurrentLanguageRMI(i18n.language),
        })
        .then((res) => {
          if (componentTypeId) {
            setComponentTypeId(componentTypeId);
          } else if (res.data?.length > 1) {
            setcomponentType(res.data);
          } else {
            setComponentTypeId(res.data[0].ComponentTypeId);
            searchParams.set('component_type_id', String(res.data[0].ComponentTypeId));
            setSearchParams(searchParams);
          }
        });
    }

    return () => {
      componentMounted = false;
    };
  }, [typeId, i18n.language]);

  useEffect(() => {
    if (selectHandler) {
      setComponentTypeId(Number(selectHandler?.value));
      searchParams.set('component_type_id', String(selectHandler?.value));
      setSearchParams(searchParams);
    }
  }, [selectHandler]);

  useEffect(() => {
    if (componentType?.length > 1) {
      setOpenModal(true);
      fillProposalBoxDetailList(componentType);
    }
  }, [componentType]);

  const fillProposalBoxDetailList = (optionsList: IBoxConfigurationsDiagnostic[]): void => {
    const boxDetailListOptions: Array<ISelectModalDiagnostic> = [];

    optionsList.forEach((opt: IBoxConfigurationsDiagnostic): void => {
      boxDetailListOptions.push({
        value: opt.ComponentTypeId,
        label: opt.ComponentTypeName,
      });
    });

    setBoxDetailOptions(boxDetailListOptions);
  };

  function onMainGroupClick(item) {
    setMainGroup(item);
    setSidebarDetails(2);
    searchParams.set('main_group_id', item.MainGroupId);
    searchParams.set('level', '2');
    setSearchParams(searchParams);
  }

  function onSubGroupClick(item) {
    setSubGroups(item);
    setSidebarDetails(3);
    searchParams.set('sub_group_id', item.SubGroupId);
    searchParams.set('level', '3');
    setSearchParams(searchParams);
  }

  function onItemMpClick(item) {
    setItemMpsManual(item);
    setSidebarDetails(4);
    searchParams.set('level', '4');
    searchParams.set('item_id', item.ItemMpId);
    setSearchParams(searchParams);
  }

  function onItemMpManualClick(item) {
    setActiveItem(item.ManualId);
    searchParams.set('manual_id', item.ManualId);
    searchParams.set('qual_col_id', item.QualColId);
    setSearchParams(searchParams);
  }

  const getHTML = () => {
    setLoadingHTML(true);
    const param = {
      typeId,
      countryCode: getCurrentCountryRMI(i18n.language),
      languageCode: getCurrentLanguageRMI(i18n.language),
      printView: false,
      manualId: Number(searchParams.get('manual_id')),
      kindOfWorkTime: 0,
    };

    http.componentsHttpTecRmi
      .fetchRMIVehicleManual(param)
      .then((res) => {
        setIframeData(res.data);
      })
      .finally(() => {
        setLoadingHTML(false);
      });
  };

  function onReturnClick() {
    if (sidebarDetails === 1) {
      navigate({
        pathname: generatePath('/reliable/rmi/:vehicle_id/technical-documentation', {
          vehicle_id: String(vehicle_id),
        }),
        search: converObjectToParams(reliableCatalogCarQueryParams),
      });

      return;
    }

    //  navigate(-1);
    setSidebarDetails((prev) => prev - 1);
  }

  useEffect(() => {
    if (Number(searchParams.get('main_group_id')) && manualList) {
      setMainGroup(manualList?.find((item) => item.MainGroupId === Number(searchParams.get('main_group_id'))));
    }

    if (Number(searchParams.get('sub_group_id')) && mainGroup) {
      setSubGroups(mainGroup?.SubGroups.find((item) => item.SubGroupId === Number(searchParams.get('sub_group_id'))));
    }

    if (Number(searchParams.get('item_id')) && subGroups) {
      const item = subGroups?.ItemMps.find((item) => item.ItemMpId === Number(searchParams.get('item_id')));

      setItemMpsManual(item);
    }

    if (searchParams.get('manual_id') && itemMpsManual) {
      const item = itemMpsManual?.Manuals.find((item) => item.ManualId === Number(searchParams.get('manual_id')));

      setActiveItem(item?.ManualId);
    }
  }, [manualList, subGroups, mainGroup, itemMpsManual]);

  useEffect(() => {
    if (Number(searchParams.get('manual_id')) && typeId) {
      setActiveItem(Number(searchParams.get('manual_id')));
      getHTML();
    }
  }, [searchParams.get('manual_id'), typeId]);

  const onClickHandler = (event) => {
    const tabClickedElementId = event?.target?.parentElement?.getAttribute('data-id');
    const iconClickedElementId = event?.target?.parentElement?.parentElement?.getAttribute('data-id');
    const elementId = tabClickedElementId ? tabClickedElementId : iconClickedElementId;
    const isClickable = event?.target?.attributes?.onmouseover || event?.target?.localName === 'img';

    if (!elementId || !isClickable) {
      return;
    }

    Promise.allSettled([
      http.componentsHttpTecRmi.fetchRMIVehicleTecCompPositionLinkedWiringDiagram({
        typeId: Number(typeId),
        countryCode: getCurrentCountryRMI(i18n.language),
        languageCode: getCurrentLanguageRMI(i18n.language),
        imageLinkId: elementId,
      }),
      http.componentsHttpTecRmi.fetchRMIVehicleTecCompPositionLinkedDiagnostics({
        typeId: Number(typeId),
        countryCode: getCurrentCountryRMI(i18n.language),
        languageCode: getCurrentLanguageRMI(i18n.language),
        imageLinkId: elementId,
        componentTypeId,
      }),
    ])
      .then(([Wiring, Diagnistic]) => {
        const arrayToSend: Array<IRMIModulesLinkModal> = [];

        if (Wiring.status !== 'rejected') {
          const ManualsWiring = Wiring?.value?.data;
          let paramsObject: { [key: string]: string } = {};

          paramsObject = { ...paramsObject, ...reliableCatalogCarQueryParams };
          const tabWiring: IRMIModulesLinkModal = {
            tabName: t('_wiring_diagrams'),
            children: [],
          };

          ManualsWiring &&
          ManualsWiring.forEach((manuals) => {
            const tabs: IRMIModulesLinkChildren = {
              label: manuals.ItemMpText,
              link: null,
              children: [],
            };

            manuals.Manuals.forEach((manual) => {
              paramsObject['item_id'] = String(manuals?.ItemMpId);
              paramsObject['qual_col_id'] = String(manual.QualColId);
              paramsObject['manual_id'] = String(manual.ManualId);
              tabs.children?.push({
                label: manual.QualColText,
                link: `/reliable/rmi/${vehicle_id}/technical-documentation/${RELIABLE_TAB_NAMES.WIRING}?${new URLSearchParams(
                  paramsObject,
                ).toString()}`,
                children: null,
              });
            });

            tabWiring?.children.push(tabs);
          });

          if (tabWiring.children.length) {
            arrayToSend.push(tabWiring);
          }
        }

        if (Diagnistic.status !== 'rejected') {
          const ManualsDiagnistic = Diagnistic?.value?.data;

          let paramsObject: { [key: string]: string } = {};

          paramsObject = { ...paramsObject, ...reliableCatalogCarQueryParams };
          const tabDiagnistic: IRMIModulesLinkModal = {
            tabName: t('_diagnostic'),
            children: [],
          };

          ManualsDiagnistic &&
          ManualsDiagnistic.forEach((manuals, index) => {
            paramsObject['manual_id'] = String(manuals.ItemMpId + index);
            paramsObject['component_type_id'] = String(manuals.ComponentTypeId);
            paramsObject['item_id'] = String(manuals?.ItemMpId);
            paramsObject['system_qual_col_id'] = String(manuals?.SystemQualColId);
            paramsObject['printView'] = String(true);
            const tabs: IRMIModulesLinkChildren = {
              label: manuals.ItemMpText,
              link: null,
              children: [],
            };

            manuals.Manuals.forEach((manual) => {
              paramsObject['qual_col_id'] = String(manual?.QualColId);

              tabs.children?.push({
                label: manual.QualColText,
                link: `/reliable/rmi/${vehicle_id}/technical-documentation/${RELIABLE_TAB_NAMES.DIAGNOSTICS}?${new URLSearchParams(
                  paramsObject,
                ).toString()}`,
                children: null,
              });
            });

            tabDiagnistic?.children.push(tabs);
          });

          if (tabDiagnistic.children.length) {
            arrayToSend.push(tabDiagnistic);
          }
        }

        setRmiModulesLinkModal(arrayToSend);
        setOpenModalLink(true);
      })
      .catch((e: Error) => console.error(e));
  };

  return (
    <div className="technical-documentation-components-position">
      {loadingList ? (
        <Spinner class="extra-small" />
      ) : (
        <React.Fragment>
          {moreThanOneManual ? (
            <div className="tdoc-sidebar">
              {sidebarDetails === 1 && (
                <SidebarDetails
                  title={`<span>${t('_tec_comp_position')}</span>`}
                  itemName="MainGroupName"
                  onClick={onMainGroupClick}
                  data={manualList}
                  onReturnClick={onReturnClick}
                />
              )}
              {sidebarDetails === 2 && (
                <SidebarDetails
                  title={`${t('_tec_comp_position')} / <span>${mainGroup?.MainGroupName}</span>`}
                  itemName="SubGroupName"
                  onClick={onSubGroupClick}
                  data={mainGroup?.SubGroups}
                  onReturnClick={onReturnClick}
                />
              )}
              {sidebarDetails === 3 && (
                <SidebarDetails
                  title={`${t('_tec_comp_position')} / ${mainGroup?.MainGroupName} / <span>${subGroups?.SubGroupName}</span>`}
                  itemId="ItemMpId"
                  itemName="ItemMpText"
                  onClick={onItemMpClick}
                  data={subGroups?.ItemMps}
                  onReturnClick={onReturnClick}
                />
              )}
              {sidebarDetails === 4 && (
                <SidebarDetails
                  title={`${t('_tec_comp_position')} / ${mainGroup?.MainGroupName} / ${subGroups?.SubGroupName} / <span>${itemMpsManual?.ItemMpText}</span>`}
                  itemId="ManualId"
                  itemName="QualColText"
                  onClick={onItemMpManualClick}
                  data={itemMpsManual?.Manuals}
                  onReturnClick={onReturnClick}
                  activeItem={activeItem}
                />
              )}
            </div>
          ) : null}

          <div className="tdoc-content">
            {iframeData && <VehicleIframe loading={loadingHTML} vehicleIframe={iframeData!} onClickHandler={onClickHandler} />}
          </div>
        </React.Fragment>
      )}
      <Modal
        openModal={openModal}
        setOpenModal={setOpenModal}
        shouldCloseOnOverlayClick={false}
        childrenComponent={
          <ComponentsForTypeModal setOpenModal={setOpenModal} boxDetailOptions={boxDetailOptions} setSelectHandler={setSelectHandler} />
        }
      />
      <Modal
        openModal={openModalLink}
        setOpenModal={setOpenModalLink}
        childrenComponent={<ComponentsLinkModal setOpenModal={setOpenModalLink} rmiModulesLink={rmiModulesLinkModal} />}
      />
    </div>
  );
};

export default ComponentsPosition;
