import { IUser } from 'models/IUser';


export const isRetailerMecadepotChild = (): boolean => {
  const userInfo: IUser = JSON.parse(localStorage.getItem('userData')!);

  if (userInfo?.numcli === Number(process.env.REACT_APP_MECADEPOT_NUMCLI)) {
    return true;
  }

  return false;

};
