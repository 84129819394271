import {createAsyncThunk} from '@reduxjs/toolkit';
import useHttp from 'utils/hooks/useHttp';
import {IItemCategory} from '../../../models/vehicle-catalogs';
import {IManualsListItemMps} from '../../../utils/rmi/interface/manuals.interface';

export type ICarPartsCategoriesState = {
  vehicleId: string;
  params:{ [key: string]: string }
}

export type IRMIVehicleManualsList = {
  typeId: number;
  countryCode: string;
  languageCode: string;
}

export const fetchCarPartsCategoriesData = createAsyncThunk('categoriesV2', async (params: ICarPartsCategoriesState, { rejectWithValue }) => {
  try {
    const http = useHttp();

    return await http.vehicleCatalogs.getCategories(params.vehicleId, params.params);
  } catch (e) {

    // return rejectWithValue('fetch catalogs error');
    console.log('fetch catalogs error');

    return [];
  }
});


export const fetchItemIdsByCategory = createAsyncThunk('categories/itemMpIds', async (params: IItemCategory[], { rejectWithValue }) => {
  try {
    const http = useHttp();

    return await http.vehicleCatalogs.getItemIdsByCategory(params);
  } catch (e) {

    // return rejectWithValue('fetch catalogs error');
    console.log('fetch catalogs error');

    return [];
  }
});

export const fetchRMIVehicleManualsList = createAsyncThunk('/Manuals/ManualList', async (params: IRMIVehicleManualsList, { rejectWithValue }) => {
  try {
    const http = useHttp();

    const {data} = await http.manualsHttp.fetchRMIVehicleManualsList({...params});
    let mappedManuals: IManualsListItemMps[] = [];

    data.forEach((mainGroup) => {
      mainGroup.SubGroups.forEach((subGroup) => {
        mappedManuals = mappedManuals.concat(subGroup.ItemMps);
      });
    });

    return mappedManuals;

  } catch (e) {

    // return rejectWithValue('fetch catalogs error');
    console.log('fetch catalogs error');

    return [];
  }
});