import React from 'react';

import './IconButton.scss';

type Props = {
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  text?: string;
  disabled?: boolean;
  className?: string;
  icon: JSX.Element;
};

const IconButtonView: React.FC<Props> = (props: Props) => {
  return (
    <button disabled={props.disabled} className={`button-view ${props.className || ''}`.trim()} onClick={props.onClick}>
      {props.icon}
      {props.text}
    </button>
  );
};

IconButtonView.displayName = 'IconButtonView';

export default IconButtonView;
