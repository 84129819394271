import React from 'react';
import './EditContact.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faRefresh, faXmark } from '@fortawesome/free-solid-svg-icons';
import { Controller, UseFormHandleSubmit, UseFormRegister } from 'react-hook-form';
import { IClientContactsForm } from 'models/client.model';
import { IOption } from 'interfaces/select';
import Spinner from 'components/spinner/Spinner';
import { Trans, useTranslation } from 'react-i18next';
import PasswordStrengthInput from '../../../../components/inputs/PasswordStrengthInput/PasswordStrengthInput';
import Select from 'react-select';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';

type Props = {
  handleNewClientModal: () => void;
  handleSubmit: UseFormHandleSubmit<IClientContactsForm>;
  register: UseFormRegister<IClientContactsForm>;
  onSubmit: () => void;
  control?
  errors?;
  loading: boolean;
  newContactMode: boolean;
  languagesOptions: IOption[];
  passwordMarchError: boolean;
  lang
  setLang
};


const EditContactView: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();
  const { isMobile, isTablet } = useDeviceDetect();

  return (
    <div className="edit-contact-container">
      <form className="popup-wrapper" onSubmit={props.handleSubmit(props.onSubmit)} noValidate >
        <FontAwesomeIcon icon={faXmark} onClick={props.handleNewClientModal} className="close-btn" />
        <h1 className="popup-title">
          {props.newContactMode ? <Trans i18nKey="_create_contact_header" components={{ b: <b /> }} /> : <Trans i18nKey="_edit_contact_header" components={{ b: <b /> }} />}
        </h1>
        <div className="form">
          <h4 className="form-title">{t('_contact').toLocaleUpperCase()}</h4>
          <div className="client-select">
            <div className="radio">
              <input className="check" type="radio" value="m" {...props.register('gender')} />
              <label htmlFor="gender-male">{t('_male')}</label>
              <FontAwesomeIcon icon={faCheck} className="check-icon" />
            </div>
            <div className="radio">
              <input className="check" type="radio" value="f" {...props.register('gender')} />
              <label htmlFor="gender-female">{t('_female')}</label>
              <FontAwesomeIcon icon={faCheck} className="check-icon" />
            </div>
          </div>
          <div className="client-select">
            <div className="radio">
              <input className="check" type="radio" value="true" {...props.register('is_active')} />
              <label htmlFor="is-active-true">{t('_active')}</label>
              <FontAwesomeIcon icon={faCheck} className="check-icon" />
            </div>
            <div className="radio">
              <input className="check" type="radio" value="false" {...props.register('is_active')} />
              <label htmlFor="is-active-false">{t('_inactive')}</label>
              <FontAwesomeIcon icon={faCheck} className="check-icon" />
            </div>
          </div>
          <div className="row">
            <div className={`container ${props.errors.last_name?.type == 'required' ? 'error' : ''}`}>
              <label htmlFor="#client.name">{t('_lastname')}</label>
              <div className="text-input-container">
                <input
                  className="text-input"
                  type="text"
                  placeholder="Cesarelli"
                  {...props.register('last_name', { required: true })}
                ></input>
                {props.errors?.last_name?.type === 'required' && <p className="error-message">Last Name Required</p>}
              </div>
            </div>
            <div className={`container ${props.errors.first_name?.type == 'required' ? 'error' : ''}`}>
              <label htmlFor="#client.surname">{t('_firstname')}</label>
              <div className="text-input-container">
                <input
                  className="text-input"
                  type="text"
                  placeholder="Sylvain"
                  {...props.register('first_name', { required: true })}
                ></input>
                {props.errors?.first_name?.type === 'required' && <p className="error-message">First Name Required</p>}
              </div>
            </div>
            <div className={`container ${props.errors.language?.type == 'required' ? 'error' : ''}`}>
              <label>{t('_language')}</label>
              <Controller
                control={props.control}
                name="language"
                rules={{ required: true }}
                render={({ field: { onChange } }) => (
                  <Select
                    className="select-dropdown"
                    value={props.lang}
                    onChange={(val) => {
                      onChange(val?.value);
                      props.setLang(val);
                    }}
                    options={props.languagesOptions}
                    isSearchable={!isMobile && !isTablet}
                    placeholder={t('_language')}
                  />
                )}
              />
              {props.errors?.language?.type === 'required' && <p className="error-message">Language Required</p>}
            </div>
          </div>
          <div className="row">
            <div className={`container ${props.errors.email?.type == 'required' ? 'error' : ''}`}>
              <label htmlFor="#client.mail">{t('_email')}</label>
              <div className="text-input-container">
                <input
                  className="text-input"
                  type="email"
                  placeholder="cesarelli.s@gmail.com"
                  {...props.register('email', { required: true })}
                ></input>
                {props.errors?.email?.type === 'required' && <p className="error-message">Email Required</p>}
              </div>
            </div>
            <div className="container">
              <label htmlFor="#client.telephone.mobile">{t('_mobile')}</label>
              <div className="text-input-container">
                <input className="text-input" type="tel" placeholder="06 55 11 99 55" {...props.register('mobile')}></input>
              </div>
            </div>
            <div className="container">
              <label htmlFor="#client.telephone.fix">{t('_phone')}</label>
              <div className="text-input-container">
                <input className="text-input" type="tel" placeholder="06 55 11 99 55" {...props.register('phone')}></input>
              </div>
            </div>
          </div>
          <div className="row">
            <div className={`container ${props.errors.password?.first?.type == 'required' ? 'error' : ''}`}>
              <label htmlFor="#client.password.first">{t('_password')}</label>
              <div className="text-input-container">
                <PasswordStrengthInput placeholder="* * * * * *" {...props.register('password.first')} required={props.newContactMode} />
                {props.errors.password?.first?.type === 'required' && <p className="error-message">Password Required</p>}
              </div>
            </div>
            <div className={`container ${props.passwordMarchError ? 'error' : ''}`}>
              <label htmlFor="#client.password.second">{t('_password_confirm')}</label>
              <div className="text-input-container">
                <PasswordStrengthInput placeholder="* * * * * *" {...props.register('password.second')} required={props.newContactMode} />
                {props.passwordMarchError && <p className="error-message">Passwords do not match</p>}
              </div>
            </div>
            <div className="container">
              <label htmlFor="#client.commentary">{t('_comment')}</label>
              <div className="text-input-container">
                <textarea className="text-input" placeholder="" {...props.register('commentary')}></textarea>
              </div>
            </div>
          </div>
        </div>
        <button className="update-button">
          {props.loading && <Spinner />}
          <FontAwesomeIcon icon={faRefresh} className="icon" />
          {t('_save')}
        </button>
      </form>
    </div>
  );
};

EditContactView.displayName = 'EditContactView';

export default EditContactView;
