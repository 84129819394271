import React, { useRef, useState, useEffect } from 'react';
import { faClose, faRedo, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import LinkButton from 'components/buttons/LinkButton/LinkButton';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { Trans, useTranslation } from 'react-i18next';
import './PartsFilterV3.scss';
import { useSearchParams } from 'react-router-dom';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import { getCurrentMarketplace } from 'utils/marketplace';
import { Marketplaces } from 'utils/constants/applications';

type Props = {
  setOpenFilter?: (boolean) => void;
  openFilter?: boolean;
  filterData?;
  getData?;
  setPage?: (page: number) => void
};

const PartsFilterV3: React.FC<Props> = (props) => {
  const { control, getValues, setValue, register } = useForm();
  const { t } = useTranslation();
  const [disableSuppliers, setDisableSuppliers] = useState(false);
  const filterRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const [searchParams, setSearchParams] = useSearchParams();
  const { isMobile, isTablet } = useDeviceDetect();

  useEffect(() => {
    if (props.filterData) {
      if (Array.from(searchParams).length) {
        searchParams.forEach((value, key) => {
          if (key == 'sup') {
            const activeBrand = searchParams.getAll('sup').map((id) => {
              return getSupplierOptions()?.find((item) => item.value === id);
            });

            setValue('supplier', activeBrand);
          } else {
            setValue('supplier', null);
          }

          if (key == 'man') {
            const activeSeason = searchParams.getAll('man').map((id) => {
              return getBrandOptions().find((item) => item.value === Number(id));
            });

            setValue('brand', activeSeason);
          } else {
            setValue('brand', null);
          }

          if (key == 'oe_reference') {
            const selectedRef = searchParams.getAll('oe_reference').map((id) => {

              return getOeReferenceOptions().find((item) => String(item.value) === String(id));
            });

            setValue('oe_reference', selectedRef);
          }
        });
      }
    }
  }, [props.filterData, searchParams.get('sup[]'), searchParams.get('man'), searchParams.get('oe_reference')]);

  // useOnClickOutside(filterRef, (event) => {
  //   if (
  //     props.openFilter &&
  //     (event?.target as HTMLElement).tagName !== 'svg' &&
  //     (event?.target as HTMLElement).tagName !== 'parh' &&
  //     !(event?.target as HTMLElement).className.includes('indicatorContainer')
  //   ) {
  //     props.setOpenFilter?.(false);
  //   }
  // });

  const getSupplierOptions = () => {
    if (!props.filterData?.data_suppliers?.length) {
      return;
    }

    return (
      props.filterData?.data_suppliers?.map((item) => ({
        label: item.name,
        value: item.id,
        is_amerigo: item.is_amerigo,
        isDisabled: getCurrentMarketplace() === Marketplaces.AMERIGO && (item.is_amerigo && item.is_amerigo === disableSuppliers),
      })) || []
    );
  };

  const getBrandOptions = () => {
    if (!props.filterData?.car_manufacturers?.length) {
      return;
    }

    return (
      props.filterData?.car_manufacturers?.map((item) => ({
        label: item.name,
        value: item.id,
      })) || []
    );
  };

  const getOeReferenceOptions = () => {
    if (!props.filterData?.oe_reference?.length) {
      return;
    }

    return (
      props.filterData?.oe_reference?.map((item) => ({
        label: item.name,
        value: item.id,
      })) || []
    );
  };

  const buildAttributesValue = () => {
    if (!props.filterData?.attributes) {
      return [];
    }

    return Object.keys(props.filterData?.attributes).reduce((attributesArray: string[], key) => {
      getValues()[key] && getValues()[key].length ? getValues()[key].map(({ value }) => attributesArray.push(value)) : [];

      return attributesArray;
    }, []);
  };

  const getAtributesOptions = (key) => {
    if (!props.filterData?.attributes[key]?.length) {
      return;
    }

    const nonNullOptions = props.filterData?.attributes[key]?.filter((option) => option !== '');

    return (
      nonNullOptions.map((item) => ({
        label: item,
        value: item,
      })) || []
    );
  };

  function handleClick() {
    const params = {
      sup: getValues().supplier && getValues().supplier.length ? getValues().supplier.map(({ value }) => value) : [],
      man: getValues().brand && getValues().brand.length ? getValues().brand.map(({ value }) => value) : [],
      oe_reference: getValues()?.oe_reference?.value,
      attributes: buildAttributesValue(),
      isAmerigo: getValues().isAmerigo || undefined,
    };

    const validParams = Object.entries(params).reduce((acc, [key, value]) => {
      if (value === undefined || value === '' || value.length === 0) {
        searchParams.delete(key);

        return acc;
      }

      acc[key] = value;

      return acc;
    }, {});

    setFilterQueryParam({ sup: params.sup, man: params.man, oe_reference: params.oe_reference });
    searchParams.set('page', '1');
    props.setPage!(1);
    setSearchParams(searchParams);
    props.getData(validParams);
    props.setOpenFilter!(false);
  }

  const setFilterQueryParam = (params) => {
    searchParams.delete('sup');
    searchParams.delete('man');
    searchParams.delete('oe_reference');
    Object.keys(params).forEach((key) => {
      if (params[key]?.length > 0 && Array.isArray(params[key])) {
        params[key].forEach((param) => searchParams.append(key, param));
      }

      if (params[key] && !Array.isArray(params[key])) {
        searchParams.append(key, params[key]);
      }
    }, {});

    setSearchParams(searchParams);
  };

  function handleReset() {
    setValue('brand', null);
    setValue('supplier', null);
    setValue('oe_reference', null);
    setDisableSuppliers(false);
    props.getData({});
    props.setOpenFilter!(false);
    searchParams.delete('sup');
    searchParams.delete('man');
    searchParams.delete('oe_reference');
    setSearchParams(searchParams);
  }

  function handleSupplierChange(val) {
    if (val.some((item) => item.is_amerigo)) {
      setDisableSuppliers(true);
    } else {
      setDisableSuppliers(false);
    }
  }

  return (
    <div ref={filterRef} className={`parts-filter-tyre ${props.openFilter ? 'active' : ''}`}>
      <div className="header-filters">
        <div className="buttons-container">
          <LinkButton title={t('_reset')} icon={faRedo} onClick={handleReset} />
        </div>
        <div onClick={() => props.setOpenFilter!(false)}>
          <FontAwesomeIcon className="icon" icon={faClose} />
        </div>
      </div>
      <div className="title">
        <Trans i18nKey="_filter_results" components={{ b: <strong /> }} />
      </div>
      <div className="filters">
        <div className={`dropdowns-container ${!getBrandOptions()?.length && !getOeReferenceOptions()?.length ? 'single' : ''}`}>
          <div className="dropdown-item">
            {props.filterData?.attributes && (
              <div className="checkbox-select">
                <div className="checkbox-item">
                  <input className="check" type="checkbox" value="true" {...register('isAmerigo')} />
                  <label htmlFor="is-amerigo">{t('_is_amerigo')}</label>
                  <FontAwesomeIcon icon={faCheck} className="check-icon" />
                </div>
              </div>
            )}
            <label className="dropdown-label">{t('_supplier')}</label>
            <Controller
              control={control}
              name="supplier"
              render={({ field: { onChange, value } }) => (
                <Select
                  value={value}
                  isMulti
                  className="parts-filter-select-dropdown"
                  onChange={(val) => {
                    onChange(val);
                    handleSupplierChange(val);
                  }}
                  isDisabled={value?.is_amerigo}
                  options={getSupplierOptions()}
                  placeholder={t('_supplier')}
                  menuPlacement="auto"
                  menuPosition="fixed"
                  styles={{ menuPortal: base => ({ ...base, zIndex: 50 }) }}
                  isSearchable={!isMobile && !isTablet}
                />
              )}
            />
          </div>
          {!!getBrandOptions()?.length && (
            <div className="dropdown-item">
              <label className="dropdown-label">{t('_oil_brand')}</label>
              <Controller
                control={control}
                name="brand"
                render={({ field: { onChange, value } }) => (
                  <Select
                    value={value}
                    isMulti
                    className="parts-filter-select-dropdown"
                    onChange={(val) => {
                      onChange(val);
                    }}
                    options={getBrandOptions()}
                    placeholder={t('_oil_brand')}
                    menuPlacement="top"
                    menuPosition="fixed"
                    styles={{ menuPortal: base => ({ ...base, zIndex: 50 }) }}
                    isSearchable={!isMobile && !isTablet}
                  />
                )}
              />
            </div>
          )}
          {!!getOeReferenceOptions()?.length && (
            <div className="dropdown-item">
              <label className="dropdown-label">{t('_oe_reference')}</label>
              <Controller
                control={control}
                name="oe_reference"
                render={({ field: { onChange, value } }) => (
                  <Select
                    value={value}
                    className="parts-filter-select-dropdown"
                    onChange={(val) => {
                      onChange(val);
                    }}
                    options={getOeReferenceOptions()}
                    placeholder={t('_oe_reference')}
                    menuPlacement="top"
                    menuPosition="fixed"
                    styles={{ menuPortal: base => ({ ...base, zIndex: 50 }) }}
                    isSearchable={!isMobile && !isTablet}
                  />
                )}
              />
            </div>
          )}
        </div>
        {props.filterData?.attributes && Object.keys(props.filterData?.attributes).length ? (
          <div className="container-criteria">
            <div className="dropdown-label">{t('_aim_criteria')}</div>
            <div className={`dropdowns-container-criteria criteria-length-${Object.keys(props.filterData?.attributes).length % 3}`}>
              {Object.keys(props.filterData?.attributes).map((key) => (
                <div key={key} className="dropdown-item">
                  <label className="dropdown-second-label">{key}</label>
                  <Controller
                    control={control}
                    name={key}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        value={value}
                        isMulti
                        className="parts-filter-select-dropdown"
                        onChange={(val) => {
                          onChange(val);
                        }}
                        options={getAtributesOptions(key)}
                        menuPlacement="top"
                        placeholder={key}
                        menuPosition="fixed"
                        styles={{ menuPortal: base => ({ ...base, zIndex: 50 }) }}
                        isSearchable={!isMobile && !isTablet}
                      />
                    )}
                  />
                </div>
              ))}
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
      <div className="apply-btn">
        <DefaultButton text={t('_apply')} onClick={() => handleClick()} />
      </div>
    </div>
  );
};

export default PartsFilterV3;
