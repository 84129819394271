import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import './CarPartView.scss';
import { ICarPartData } from 'models/car-parts.model';
import useHttp from 'utils/hooks/useHttp';
import Spinner from '../spinner/Spinner';
import { notify } from 'utils/marketplace';
import { AxiosError } from 'axios';
import PartItemOptions from './CarPartOptions/PartItemOptions';
import PartItemDetails from './CarPartDetails/PartItemDetails';
import PartItemPhotoGallery from './CarPartPhotoGallery/PartItemPhotoGallery';
import { useTranslation } from 'react-i18next';
import EquivalenceV2 from './EquivalenceV2/EquivalenceV2';

type Props = {
  paramsModal?

};
const CarPartView: React.FC<Props> = (props) => {
  const http = useHttp();
  const { id } = useParams<'id'>();
  const { i18n } = useTranslation();
  const [searchParams] = useSearchParams();
  const [carPart, setCarPart] = useState<ICarPartData | null>(null);
  const [loading, isLoading] = useState(false);
  const { vehicle_id } = useParams<'vehicle_id'>();

  useEffect(() => {
    getCarPart();


  }, [
    searchParams.get('data_supplier_id'),
    searchParams.get('provider_id'),
    searchParams.get('catalog_id'),
    searchParams.get('reference'),
    id,
    searchParams.get('is_platform_admin'),
    i18n.language,
  ]);


  const getCarPart = async () => {
    isLoading(true);

    try {
      let response;


      if (props.paramsModal) {
        const params = {
          data_supplier_id: props.paramsModal?.data_supplier_id,
          provider_id: props.paramsModal?.provider_id,
          ...(searchParams.get('is_platform_admin') && { is_platform_admin: true }),
          ...(searchParams.get('vehicle_identifier') && { vehicle_identifier: searchParams.get('vehicle_identifier') }),
          ...(vehicle_id && { ktypnr: vehicle_id }),
        };

        if (props.paramsModal?.catalog_id && props.paramsModal?.catalog_id !== 'T') {
          const param = {
            ...params,
            ...{
              catalog_id: props.paramsModal?.catalog_id,
            },
            ...(searchParams.get('is_platform_admin') && { is_platform_admin: true }),
          };

          response = await http.carParts.getCarPartView(props.paramsModal?.reference, param);
        } else {
          response = await http.carParts.getReliableCarPartView(props.paramsModal?.reference, params);
        }
      } else {
        const params = {
          data_supplier_id: String(searchParams.get('data_supplier_id')),
          provider_id: String(searchParams.get('provider_id')),
          ...(searchParams.get('is_platform_admin') && { is_platform_admin: true }),
          ...(searchParams.get('vehicle_identifier') && { vehicle_identifier: searchParams.get('vehicle_identifier') }),
          ...(vehicle_id && { ktypnr: vehicle_id }),
        };

        if (searchParams.get('catalog_id') && searchParams.get('catalog_id') !== 'T') {
          const param = {
            ...params,
            ...{
              catalog_id: String(searchParams.get('catalog_id')),
            },
            ...(searchParams.get('is_platform_admin') && { is_platform_admin: true }),
          };

          response = await http.carParts.getCarPartView(String(searchParams.get('reference')), param);
        } else {
          response = await http.carParts.getReliableCarPartView(String(id), params);
        }

      }


      setCarPart(response);
    } catch (e: unknown) {
      if (e instanceof AxiosError) {
        const message = e?.response?.data?.detail;

        notify(message);
      }
    } finally {
      isLoading(false);
    }
  };

  const [has360, setHas360] = useState(false);
  const [checked, setChecked] = useState(true);

  useEffect(() => {

    if (checked && carPart != null && carPart.image_360 && carPart.image_360.endsWith('.html')) {
      http.ebooks.checkImage({ url: carPart.image_360 }).then(reps => {
        setChecked(false);

        if (reps.data.status) {
          setHas360(true);
        }
      });
    }
  }, [carPart]);

  return (
    <div className="part-item-wrapper">
      {loading ? (
        <Spinner />
      ) : (
        <div>
          {carPart && (
            <div>
              <div className="part-item">
                <div className="gallery">
                  <PartItemPhotoGallery carPart={carPart} has360={has360} />
                </div>
                <div className="details">
                  <PartItemDetails carPart={carPart} />
                </div>
                <div className="options">
                  <PartItemOptions carPart={carPart} paramsModal={props.paramsModal} />
                </div>
              </div>
              <div className="equivalence">

                <EquivalenceV2 carPart={carPart} paramsModal={props.paramsModal} />
                {/* <Equivalence carPart={carPart} /> */}

              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CarPartView;
