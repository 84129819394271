import TextInput from 'components/inputs/TextInput/TextInput';
import Select from 'react-select';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useHttp from 'utils/hooks/useHttp';
import './ConsumableCreate.scss';

import UploadImage from '../../../../components/UploadImage/UploadImage';
import Spinner from 'components/spinner/Spinner';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { notify } from 'utils/marketplace';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { IConsumableSelect, IConsumableValidate } from 'interfaces/consumable';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import UploadPdf from 'components/UploadPdf/UploadPdf';

type Props = {
  setOpenModalCreate: (e: boolean) => void;
  setSubmit: (e: boolean) => void;
};


const ConsumableCreate: React.FC<Props> = (props) => {

  let isMounted = true;
  const { t } = useTranslation();
  const http = useHttp();
  const { isMobile, isTablet } = useDeviceDetect();
  const [brands, setBrands] = useState<IConsumableSelect[]>([]);
  const [categories, setCategories] = useState<IConsumableSelect[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingModal, setLoadingModal] = useState<boolean>(true);
  const [loadingErpPartInfo, setLoadingErpPartInfo] = useState<boolean>(false);

  const validate = Yup.object().shape({
    reference: Yup.string().trim().required(t('_fild_required')),
    // gencode: Yup.string().trim().required(t('_fild_required')),
    // conditioning: Yup.string().trim().required(t('_fild_required')),
    // unity: Yup.string().trim().required(t('_fild_required')),
    // description: Yup.string().trim().required(t('_fild_required')),
    short_description: Yup.string().trim().required(t('_fild_required')),
    brand: Yup.object().shape({
      label: Yup.string().required(t('_fild_required')),
      value: Yup.string().required(t('_fild_required')),
    }),
    // image: Yup.string().required(t('_fild_required')),
    category: Yup.object().shape({
      label: Yup.string().required(t('_fild_required')),
      value: Yup.string().required(t('_fild_required')),
    }),

  });

  const { getValues, resetField, register, handleSubmit, control, clearErrors, setValue, formState: { errors }, } = useForm<IConsumableValidate>({ resolver: yupResolver(validate) });

  useEffect(() => {

    if (isMounted) {
      setLoadingModal(true);
      Promise.all([
        http.consumableHttp.getConsumableBrand({ per_page: 1000 }),
        http.consumableHttp.getConsumableCategory({ per_page: 1000 })])
        .then((res) => {


          const setBrandData = res[0].data.data.map((item) => {
            return { value: item.id, label: item.name };
          });

          setBrands(setBrandData);

          const setCategoryData = res[1].data.data.map((item) => {
            return { value: item.id, label: item.name };
          });

          setCategories(setCategoryData);

          setLoadingModal(false);
        }).catch(() => {
        setLoadingModal(false);
      });
    }


    return () => {
      isMounted = false;
    };

  }, []);

  const customStyles = (hasError) => ({
    control: (styles) => ({
      ...styles,
      ...(hasError ? { borderColor: 'red' } : { border: 'none' }),
    }),
  });

  const getErpPart = () => {
    let erpClientId = null;
    const values = getValues();
    const reference = values?.reference.trim();
    const brand_id = values?.brand?.value;


    if (localStorage.getItem('infoSelectClientErp')) {
      const info = JSON.parse(localStorage.getItem('infoSelectClientErp') as string);

      erpClientId = info.erpClientId;
    }

    if (erpClientId == null || reference == '' || brand_id == undefined) {
      if (erpClientId == null) {
        notify(t('_select_client'), 'error', t('_error'));
      }

      if (reference == '') {
        notify(t('_reference'), 'error', t('_error'));
      }

      if (brand_id == undefined) {
        notify(t('_brand_id'), 'error', t('_error'));
      }

      return;
    } else {

      const param = {
        erp_id: erpClientId,
        reference: reference,
        brand_id: brand_id,
        type: 'consumable',
      };

      setLoadingErpPartInfo(true);

      if (values.short_description) {
        resetField('short_description');
      }

      http.retailerHttp.getErpPart(param).then((data) => {

        if (data.data?.description == undefined) {
          notify(t('_not_found_description'), 'error', t('_error'));
        } else {
          setValue('short_description', data.data?.description);
        }

      }).finally(() => {
        setLoadingErpPartInfo(false);


      });
    }


  };

  const onSubmit = async () => {
    setLoading(true);
    const values = getValues();

    const formData: FormData = new FormData();

    formData.append('reference', values.reference.trim());
    formData.append('brand', String(values.brand?.value));
    formData.append('category', String(values.category?.value));
    formData.append('gencode', String(values.gencode).trim());
    formData.append('conditioning', String(values.conditioning).trim());
    formData.append('description', values.description.trim());
    formData.append('short_description', values.short_description.trim());
    // formData.append('unity', String(values.unity).trim());

    if (values.image) {
      formData.append('image', values.image);
    }

    if (values.pdf) {
      formData.append('file', values.pdf);
    }

    formData.append('top_offer', String(values.top_offer));

    http.consumableHttp.createConsumable(formData).then(() => {
      notify(t('_consumable_updated'), 'success', t('_success'));
      props.setOpenModalCreate(false);
      props.setSubmit(true);
    }).catch((error) => {
      let erorrs = '';

      Object.entries(error).forEach(([key, value]) => {
        erorrs += `${t('_' + key)} : ${value} `;
      });
      notify(erorrs, 'error', t('_error'));
      setLoading(false);
    });

  };


  return (
    <div className="consumable-create-container" >
      <div className="close-modal" onClick={() => { props.setOpenModalCreate(false); }}><FontAwesomeIcon icon={faClose} /></div>

      <div className="title">
        {t('_consumable_title_create')}

      </div>


      {
        loadingModal ?
          <div className="spinner-info"> <Spinner />  </div> :
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="consumable-create-body">
              <div className="row">
                <div className="container ">
                  <label>
                    {t('_consumable_brand')}  <span className="placeholderRed"> * </span>
                  </label>
                  <Controller
                    control={control}
                    name="brand"
                    render={({ field: { onChange, value } }) => (
                      <Select
                        value={value}
                        className="select-dropdown "
                        styles={customStyles(errors.brand?.label?.type)}
                        onChange={(val) => {
                          onChange(val);
                        }}
                        options={brands}
                        placeholder={`${t('_select')}  ${t('_consumable_brand')}`}
                        isSearchable={!isMobile && !isTablet}
                      />
                    )}
                  />
                  <p className="error">{errors.brand?.label?.type == 'required' ? errors.brand?.label?.message : ''}</p>
                </div>

                <div className="container ">
                  <label>
                    {t('_category')}  <span className="placeholderRed"> * </span>
                  </label>
                  <Controller
                    control={control}
                    name="category"
                    render={({ field: { onChange, value } }) => (
                      <Select
                        value={value}
                        styles={customStyles(errors.category?.label?.type)}
                        className="select-dropdown "
                        onChange={(val) => {
                          onChange(val);

                        }}
                        options={categories}
                        placeholder={`${t('_select')}  ${t('_category')}`}
                        isSearchable={!isMobile && !isTablet}
                      />
                    )}
                  />
                  <p className="error">{errors.category?.label?.type == 'required' ? errors.category?.label.message : ''}</p>
                </div>

                <div className={`container ${errors.reference?.type == 'required' ? 'error' : ''}`}>
                  <label>
                    {t('_reference')}  <span className="placeholderRed"> * </span>
                  </label>
                  <TextInput {...register('reference')} />
                  <p className="error-message">{errors.reference?.type == 'required' ? errors.reference?.message : ''}</p>
                </div>

                <div className="container">
                  <label>
                    {t('_gencode')}
                  </label>
                  <TextInput {...register('gencode')} />
                  <p className="error-message">{errors.gencode?.type == 'required' ? errors.gencode?.message : ''}</p>
                </div>
              </div>

              <div className="row">
                <div className="container">
                  <label>
                    {t('_conditioning')} / {t('_unity')}
                  </label>
                  <TextInput {...register('conditioning')} />
                  <p className="error-message">{errors.conditioning?.type == 'required' ? errors.conditioning?.message : ''}</p>
                </div>

                {/*<div className={`container ${errors.unity?.type == 'required' ? 'error' : ''}`}>*/}
                {/*  <label>*/}
                {/*    {t('_unity')}  <span className="placeholderRed"> * </span>*/}
                {/*  </label>*/}
                {/*  <TextInput {...register('unity')} />*/}
                {/*  <p className="error-message">{errors.unity?.type == 'required' ? errors.unity?.message : ''}</p>*/}
                {/*</div>*/}
              </div>

              <div className="row">
                <div className="container top-offer-checkbox-container">
                  <label htmlFor="top_offer">{t('_top_offer')}</label>
                  <input type="checkbox" id="top_offer" {...register('top_offer')} />
                </div>
              </div>
              <div className="row">


                <div className={`container ${errors.image?.type == 'required' ? 'error' : ''}`}>
                  <UploadImage errors={errors} clearErrors={clearErrors} setValue={setValue} />
                  <span className="upload_image_info"> {t('_upload_image_info')}</span>
                </div>

                <div className={`container ${errors.file?.type == 'required' ? 'error' : ''}`}>
                  <UploadPdf errors={errors} clearErrors={clearErrors} setValue={setValue} title="_technical_sheet" />
                </div>
              </div>

              <div className="row">
                <div className={`container ${errors.short_description?.type == 'required' ? 'error' : ''}`}>
                  <label>
                    {t('_short_description')} <span className="placeholderRed"> * </span>
                  </label>
                  <TextInput {...register('short_description')} />
                  <p className="error-message">{errors.short_description?.type == 'required' ? errors.short_description?.message : ''}</p>
                </div>
              </div>

              <div className="row file-container">
                <div className="container">

                  <div className="description_info">
                    <label>
                      {t('_consumable_description')}
                    </label>
                    <DefaultButton type="button" red text={t('_get_erp_description')} onClick={() => getErpPart()} isloading={loadingErpPartInfo} />
                  </div>
                  <textarea rows={7}
                            {...register('description', {
                    })}
                  />
                  <p className="error-message">{errors.description?.type == 'required' ? errors.description?.message : ''}</p>
                </div>
              </div>
            </div>

            <div className="btn-save">
              <button type="submit" className={loading ? 'disabled' : ''}> {loading ?
                <Spinner class="extra-small" /> : null} <div className="title-btn-save">{t('_consumable_create_article')}</div>
              </button>
            </div>
          </form>
      }

    </div>

  );

};

ConsumableCreate.displayName = 'ConsumableCreate';

export default ConsumableCreate; 