import React, { PropsWithChildren } from 'react';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import { useTranslation } from 'react-i18next';
import './ConfirmationModal.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileCsv, faFileExcel } from '@fortawesome/free-solid-svg-icons';

type Props = {
  setOpenModal: (openModal: boolean) => void
  setSendFile: (sendFile: boolean) => void
  file
};

const ConfirmationModal: React.FC<Props> = (props: PropsWithChildren<Props>) => {
  const { t } = useTranslation();

  return (
    <div className="confirmation-modal">

      <div className="title">
        <p>{t('_confirmation_file_order')}</p>
      </div>
      <div className="message">
        <FontAwesomeIcon icon={props.file.type == 'text/csv' ? faFileCsv : faFileExcel} fontSize={55} />
        <p>    {props.file.name}</p>
      </div>
      <div className="actions">
        <div className="no">
          <DefaultButton text={t('_no')} onClick={() => { props.setOpenModal(false), props.setSendFile(false); }} red />
        </div>
        <div className="yes">
          <DefaultButton text={t('_yes')} onClick={() => { props.setOpenModal(false), props.setSendFile(true); }} blue />
        </div>
      </div>


    </div>
  );
};

export default ConfirmationModal;
