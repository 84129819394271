import React, { useEffect, useState } from 'react';
import {
  IEquivalenceCarsResponse,
  IEquivalenceModel,
  IEquivalenceVersion,
  INameId,
} from '../../../../../models/car-parts.model';
import { IOption } from '../../../../../interfaces/select';
import Select, { SingleValue } from 'react-select';
import Spinner from '../../../../spinner/Spinner';
import VehicleEquivalenceItem from './VehicleEquivalenceItem/VehicleEquivalenceItem';
import { useTranslation } from 'react-i18next';
import SwiperSlider from 'components/SwiperSlider/SwiperSlider';
import { SwiperSlide } from 'swiper/react';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import { useSearchParams } from 'react-router-dom';
import './EquivalenceCar.scss';

type Props = {
  loading: boolean;
  data?: IEquivalenceCarsResponse;
  filterData?: IEquivalenceCarsResponse;
  isLoading?: boolean;
  items: IEquivalenceVersion[];
  slidesPerView?: number;
  navigation?: boolean;
};


const transformOptions = (optionsFromResponse: INameId[]) => {
  return optionsFromResponse?.map((item) => ({
    label: item.name,
    value: String(item.id),
  }));
};


const EquivalenceCar: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  const [allModels, setAllModels] = useState<IEquivalenceModel[]>([]);

  const [modelsOptions, setModelsOptions] = useState<IOption[]>([]);
  const [selectedModel, setSelectedModel] = useState<SingleValue<IOption>>();

  const [selectedManufacturer, setSelectedManufacturer] = useState<SingleValue<IOption>>();
  const [manufacturersOptions, setManufacturersOptions] = useState<IOption[]>([]);

  const [dataFiltered, setDataFiltered] = useState<IEquivalenceVersion[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const { isMobile, isTablet } = useDeviceDetect();

  useEffect(() => {
    getEquivalences();
  }, []);

  useEffect(() => {
    setModelsOptions([]);
    setSelectedModel(null);
    const filteredEquivalences = allModels.filter((model) => {
      return String(model.manufacturer_id) === String(selectedManufacturer?.value);
    });

    if (filteredEquivalences.length > 0) {
      const filteredEquivalencesOptions = transformModelOptions(filteredEquivalences);

      setModelsOptions(filteredEquivalencesOptions);
      setSelectedModel(filteredEquivalencesOptions[0]);

      searchParams.set('car_model_id', String(filteredEquivalencesOptions[0]?.value));
      setSearchParams(searchParams);
    }
  }, [selectedManufacturer]);

  useEffect(() => {
    if (props.data) {
      const dataEquivalenceCarManufacturerFiltered = props.data?.car_versions.filter((model) => {
        return Number(model.manufacturer_id) === Number(selectedManufacturer?.value);
      });

      setDataFiltered(dataEquivalenceCarManufacturerFiltered![0]?.cars);
      // if (dataEquivalenceCarManufacturerFiltered.length) {
      // const dataEquivalenceCarVersionsFiltered = dataEquivalenceCarManufacturerFiltered[0].cars.filter((version) => {
      //   return version.model_id === selectedModel?.value;
      // });

      // }
    }
  }, [props.data, selectedManufacturer]);

  const getEquivalences = async () => {
    if (!props.data || !props.filterData) {
      return;
    }

    try {
      setAllModels(props.filterData?.models);
      // setDataEquivalenceCarVersions(props.data.car_versions);
      const optionsManufacturerFromResponse: INameId[] = props.filterData?.filters;

      if (optionsManufacturerFromResponse) {
        const optionFromResponseTransform = transformOptions(optionsManufacturerFromResponse);

        setManufacturersOptions(optionFromResponseTransform);
        setSelectedManufacturer(optionFromResponseTransform[0]);
      }
    } catch (e) {
      console.log(e);
    }
  };


  useEffect(() => {
    if (props.filterData) {
      try {
        setAllModels(props.filterData?.models);
        const optionFromResponseTransform = transformOptions(props.filterData?.filters as INameId[]);

        setManufacturersOptions(optionFromResponseTransform);
      } catch (e) {
        console.log(e);
      }
    }
  }, [props.filterData]);

  useEffect(() => {
    if (props.filterData?.filters) {
      const optionFromResponseTransform = transformOptions(props.filterData?.filters as INameId[]);

      setSelectedManufacturer(optionFromResponseTransform[0]);
    }
  }, [props.filterData?.filters]);

  const transformModelOptions = (optionsFromResponse: IEquivalenceModel[]) => {
    return optionsFromResponse?.map((item) => ({
      label: item.name,
      value: String(item.id),
    }));
  };


  return (
    <div className="equivalence-vehicle eq-car-parts-view">
      <div className="content">
        {manufacturersOptions?.length ? (
          <div className="filters">
            <div className="manufacturers">
              <p className="select-title">{t('_manufacturer')}</p>
              <Select
                className="select-element"
                placeholder={t('_manufacturer')}
                value={selectedManufacturer}
                onChange={(val) => {
                  setSelectedManufacturer(val);
                }}
                options={manufacturersOptions}
                isSearchable={false}
              />
            </div>

            <div className="manufacturers">
              <p className="select-title">{t('_model')}</p>
              <Select
                className="select-element"
                placeholder={t('_model')}
                value={selectedModel}
                onChange={(val) => {
                  setSelectedModel(val);
                  searchParams.set('car_model_id', String(val?.value));
                  setSearchParams(searchParams);
                }}
                options={modelsOptions}
                isSearchable={false}
              />
            </div>
          </div>
        ) : ('')}
        <div className={`list ${props.loading ? 'loading' : ''}`}>
          {props.loading ? (
            <Spinner class="small" />
          ) : (
            dataFiltered?.length ? (
              isMobile || isTablet ? (
                // <SwiperSlider slidesPerView="auto" isLoading={props.isLoading} navigation centeredSlides={false}></SwiperSlider>
                <SwiperSlider slidesPerView="auto" isLoading={props.isLoading} centeredSlides={false}>
                  <div>
                    {dataFiltered.map((item, index) => {
                      return (
                        <SwiperSlide className="swiper-slide" key={index}>
                          <VehicleEquivalenceItem key={index} selectedManufacturer={selectedManufacturer} version={item} />
                        </SwiperSlide>
                      );
                    })}
                  </div>
                </SwiperSlider>
              ) : (
                dataFiltered.map((item, index) => {
                  return <VehicleEquivalenceItem key={index} selectedManufacturer={selectedManufacturer} version={item} />;
                })
              )
            ) : (
              <div className="items-no-data">
                <p>{t('_no_data')}</p>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

EquivalenceCar.displayName = 'EquivalenceCar';

export default EquivalenceCar;
