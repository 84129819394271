import { createSlice } from '@reduxjs/toolkit';
import { addToCartAction } from '../actions/cart.actions';

type ICartState = {

  quantityEspaceAutomotor: number;
  addToCartLoading: boolean;
};

const initialState: ICartState = {

  quantityEspaceAutomotor: 0,
  addToCartLoading: false,
};

const cartEspaceAutomotor = createSlice({
  name: 'cartEspaceAutomotor',
  initialState,
  reducers: {
    setQuantityEspaceAutomotor(state, { payload }) {
      state.quantityEspaceAutomotor = payload;
    },

  },
  extraReducers: (builder) => {
    builder.addCase(addToCartAction.pending, (state) => {
      state.addToCartLoading = true;
    });
    builder.addCase(addToCartAction.rejected, (state) => {
      state.addToCartLoading = false;
    });
    builder.addCase(addToCartAction.fulfilled, (state) => {
      state.addToCartLoading = false;
    });
  }
});

export const { setQuantityEspaceAutomotor } = cartEspaceAutomotor.actions;

export default cartEspaceAutomotor.reducer;
