import MainSearchInput from 'components/inputs/MainSearchInput/MainSearchInput';
import React from 'react';
import './EspaceTariffs.scss';
import Spinner from 'components/spinner/Spinner';
import { ITariff } from 'models/catalog.model';
import TariffFile from 'components/tariff-file/TariffFile';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { usePermission } from 'utils/usePermission';
import { ROLE_CLIENT_PLATFORM_ADMIN } from 'utils/constants/roles';

type Props = {
  catalogsData: ITariff[];
  loadingCatalogs: boolean;
  handleCatalogClick: (id) => void;
  setInputValue: (value: string) => void;
  inputValue: string;
};

const EspaceTariffsView: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { user } = useSelector((state: RootState) => state.userData);
  const isPlatformAdmin = usePermission([ROLE_CLIENT_PLATFORM_ADMIN]);

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.setInputValue(e.target.value);
  };

  return (
    <div className="espace-tariffs-container">
      <div className="espace-tariffs-filter">
        <div className="espace-filter-title">{t('_espace_tariffs')}</div>
        <div className="espace-tariffs-search">
          <MainSearchInput placeholder={t('_search_one_supplier')} value={props.inputValue} onChange={changeHandler} withIcon />
        </div>
        <div className="espace-tariffs-checkbox-container">
          <div className="datached-pieces-container">
            <input type="checkbox" name="Detached-pieces"></input>
            <label htmlFor="#Detached-pieces">{t('_detached_pieces')}</label>
          </div>
          {user?.hasTyre && isPlatformAdmin && (
            <div className="tyres-checkbox-container">
              <input type="checkbox" name="tyres"></input>
              <label htmlFor="#tyres">{t('_tyre')}</label>
            </div>
          )}
          {user?.hasOil && isPlatformAdmin && (
            <div className="oils-checkbox-container">
              <input type="checkbox" name="oils"></input>
              <label htmlFor="#oils">{t('_oil')}</label>
            </div>
          )}
        </div>
      </div>
      <div className="espace-tariffs-suppliers-container">
        {props.loadingCatalogs ? (
          <Spinner class="catalogs-spinner" />
        ) : (
          <div className="items">
            {props.catalogsData.length ? (
              props.catalogsData.map((data) => {
                return (
                  <div key={data.id} className="tariff-item-container">
                    <TariffFile file={data} />
                  </div>
                );
              })
            ) : (
              <div className="no-data">{t('_no_data').toLocaleUpperCase()}</div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

EspaceTariffsView.displayName = 'EspaceTariffsView';

export default EspaceTariffsView;
