// Marketplace
export const MARKETPLACE_AUTOMOTOR = 'automotor';

export const MARKETPLACE_AMERIGO = 'amerigo';

export const MARKETPLACE_CATALOG_AF = 'AF';

// Categories from DB
export const CATEGORY_FROM_POLK = 'P';

export const CATEGORY_FROM_POLK_2 = 'PT';

// Others
export const FADE_DELAY_TIME = 1000;

export const MANUFACTURER = 'man';

// Source
export const SOURCE_TECDOC = 'T';

export const SOURCE_POLK = 'P';

export const TRUCK_IDENTIFIER = '016';

export const CAR_IDENTIFIER = '002';

// Catalogs
export const CATALOG_REL = 'REL';

export const CATALOG_AUT = 'AUT';

export const CATALOG_TECDOC_SUPPLIER = 1;

export const CATALOG_MANUFACTURER = 2;

export const PROVIDER_LAUTRETTE = 'LAT';

export const PROVIDER_AF = 'AUT';

export const FETCH_CATALOG_DATA_IDENTIFIER = 'FETCH_CATALOG_DATA_IDENTIFIER';

export const FETCH_CATEGORIES_IDENTIFIER = 'FETCH_CATEGORIES_IDENTIFIER';

export const FETCH_BRANDS_IDENTIFIER = 'FETCH_BRANDS_IDENTIFIER';

export const FETCH_CAR_PARTS_IDENTIFIER = 'FETCH_CAR_PARTS_IDENTIFIER';

export const FETCH_MODELS_IDENTIFIER = 'FETCH_MODELS_IDENTIFIER';

export const FETCH_EBOOKS_IDENTIFIER = 'FETCH_EBOOKS_IDENTIFIER';

// csv array statuses
export const OK = 'OK';

export const SELECT_SUPPLIER = 'SELECT_SUPPLIER';

export const REFERENCE_NOT_FOUND = 'REFERENCE_NOT_FOUND';

export const TECRMI_HEADER_REGEX = /<td[\s\S]*?(Logo.png)[\s\S]*?<\/td>/gm;

export const TECRMI_FOOTER_REGEX = /<hr \/>\r\n {4}<t[\s\S]*<\/table>/gm;

export const TECRMI_FOOTER_REGEX_ADJUST = /<hr>\r\n {4}<t[\s\S]*<\/table>/gm;

export const TECRMI_DOC_LOCATION_HREF = /document.location.href\s*='Þ'\+id\+'Þ';/gi;

export const TECRMI_ANCHOR_HREF = /<a\s+(?:[^id]*?\s+)?href=(.*?)\1/gm;

export const CONSUMABLES_GROUP_ID = 20;

export const ERROR_CODE_GROUP_ID = 154;

export const MAX_ALLOWED_CAR_PARTS = 10000;
