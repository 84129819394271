import React from 'react';
import { useTranslation } from 'react-i18next';
import RefContent from './Content/Ref/RefContent';
import VehicleContent from './Content/Vehicle/VehicleContent';
import VinContent from './Content/Vin/VinContent';
import Tab from './Tabs/Tab';
import Tabs from './Tabs/Tabs';

type Props = {};

const TabFilterView: React.FC<Props> = () => {
  const {t} = useTranslation();

  return (
    <div className="tab-filter">
      <Tabs>
        <Tab title={t('_you_know_reference_or_equivalance')}>
          <RefContent />
        </Tab>
        <Tab title={t('_you_know_vehicle_in_front')}>
          <VinContent />
        </Tab>
        <Tab title={t('_you_know_vehicle')}>
          <VehicleContent />
        </Tab>
      </Tabs>
    </div>
  );
};

TabFilterView.displayName = 'TabFilterView';

export default TabFilterView;
