import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import { useSearchParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IPriceMatrixDetails } from 'utils/hooks/http/price-matrix.http';
import { Column, useTable } from 'react-table';
import { faAdd, faCheck, faEdit, faTrash, faXmark } from '@fortawesome/free-solid-svg-icons';
import { IOption } from 'interfaces/select';
import { IData } from 'interfaces/selectClient';
import { notify } from 'utils/marketplace';
import Modal from 'components/modal/Modal';
import useHttp from 'utils/hooks/useHttp';
import Spinner from 'components/spinner/Spinner';
import i18n from 'i18n';
import ModalDelete from 'components/CatalogNaf/ModalDelete/ModalDelete';
import Head from 'components/tables/Cells/Head/Head';
import Pagination from 'components/pagination/Pagination';
import PriceManagementFilter from './Filter/PriceManagementFilter';
import CreatePriceManagement from './ModalCreate/CreatePriceManagement';
import './PriceManagement.scss';

type Props = {};

const PriceManagement: React.FC<Props> = () => {
  const http = useHttp();
  const { t } = useTranslation();
  const { isMobile, isTablet, isDesktop } = useDeviceDetect();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = React.useState<IPriceMatrixDetails[]>([]);
  const [params, setParams] = React.useState({ page: Number(searchParams.get('page') || 1) });
  const [openModalDelete, setOpenModalDelete] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<number | undefined>();
  const [pageCount, setPageCount] = useState(0);
  const [forcePage, setForcePage] = useState<number>(Number(searchParams.get('page') || 1));
  const [submit, setSubmit] = useState<boolean>(false);
  const [isActive, setIsActive] = useState(false);
  const [providers, setProviders] = useState<IOption[]>([]);
  const [clients, setClients] = useState<IOption[]>([]);
  const [editRow, setEditRow] = useState<IPriceMatrixDetails | null>(null);
  const [editedRowId, setEditedRowId] = useState<number | null>(null);
  const [tariffValue, setTariffValue] = useState<number>(0);
  const [countries, setCountries] = useState<IOption[]>([]);
  const [commercialZones, setCommercialZones] = useState<IOption[]>([]);

  const request = (params) => {
    http.priceMatrix
      .getPriceMatrix(params)
      .then((res) => {
        setData(res.data.data);
        setPageCount(res.data.total_pages);
      })
      .finally(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };


  useEffect(() => {
    setLoading(true);
    Promise.all([
      http.allowedList.getClients(),
      http.allowedList.getProviders(),
      http.selectClient.countries(),
      http.selectClient.zoneCommercials(),
    ])
      .then((res) => {
        setClients(transformOptions(res[0].data));
        setProviders(res[1].data.map((item) => ({ value: item.code, label: `${item.company} (${item.code})` })));
        setCountries(res[2].data.map((item) => ({ value: item.id, label: item.name })));
        setCommercialZones(res[3].data.map((item) => ({ value: item.id, label: `${item.id} (${item.name})` })));
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const transformOptions = (optionsFromResponse: IData[]) => {
    if (!optionsFromResponse) {
      return [];
    }

    const uniqueOptions = optionsFromResponse
      .map((item) => ({
        label: item.name,
        value: String(item.id),
        country: item.country,
        zone_commercial: item.zone_commercial
      }))
      .filter((item, index, self) => self.findIndex((i) => i.label === item.label && i.value === item.value) === index);

    return uniqueOptions;
  };

  useEffect(() => {
    setLoading(true);
    request(params);
    setSubmit(false);
    setSearchParams({ page: String(params.page) });
  }, [params]);

  useEffect(() => {
    if (submit) {
      setLoading(true);
      request(params);
      setSubmit(false);
    }
  }, [submit]);

  function handleEdit(tauxValue) {

    const params = {
      numcli: editRow?.client?.numcli,
      codfou: editRow?.itgr?.codfou,
      group: editRow?.itgr?.group,
      taux: tauxValue || null,
    };

    http.priceMatrix.editPriceMatrix(params, Number(editRow?.id))
      .then((res) => {
        setTariffValue(Number(res.data?.taux));
      })
      .catch(() => {
        notify(t('_error'));
      })
      .finally(() => {
        setEditRow(null);
      });
  }


  const EditableCell = (cellProps) => {
    const { cell, inputValue } = cellProps;
    const [value, setValue] = useState(cell.row?.original.taux);

    useEffect(() => {
      if (cell.row?.original.id == editedRowId) {
        setValue(inputValue);
      }


    }, [editedRowId, editRow, inputValue]);

    const handleInputChange = (e) => {
      const inputValue = e.target.value;

      if (inputValue == '') {
        setValue('');
      } else {
        const numericValue = parseFloat(inputValue.replace(',', '.'));

        setValue(numericValue);
      }
    };


    return (
      editRow?.id === cell.row?.original.id ?
        <span className="edit-taux">
          <span className="edit-taux-input-container">
            <input
              type="number"
              step="0.1"
              onChange={handleInputChange}
              value={value}
              min={0}
            />
          </span>
          <FontAwesomeIcon icon={faCheck} className="edit-taux-accept" onClick={() => handleEdit(!isNaN(value) ? value : 0)} />
          <FontAwesomeIcon icon={faXmark} className="edit-taux-cancel" onClick={() => setEditRow(null)} />
        </span>
        : (editedRowId == cell.row.original.id) && inputValue ? <span>{Number(inputValue)}</span> : <span>{Number(cell.row.original?.taux) ? parseFloat(cell.row.original?.taux) : t('_not_defined')}</span>
    );
  };

  const columnsTable = useMemo((): Column<IPriceMatrixDetails>[] => columns(), [i18n.language, data, isMobile, isTablet, isDesktop, editRow, tariffValue, editedRowId]);

  function columns(): Column<IPriceMatrixDetails>[] {
    return [
      {
        Header: <Head text={t('_client')}></Head>,
        accessor: 'client',
        Cell: (cell) => <span>{cell.row.original?.client?.name}</span>,
      },
      {
        id: 'codfou',
        Header: <Head text={t('_provider')}></Head>,
        Cell: (cell) => <span>{cell.row.original?.itgr?.codfou}</span>,
      },
      {
        Header: <Head text={t('_group')}></Head>,
        accessor: 'itgr',
        Cell: (cell) => <span>{cell.row.original?.itgr?.group}</span>,
      },
      {
        Header: <Head text={t('_coefficient')}></Head>,
        accessor: 'taux',
        width: 200,
        Cell: (cell) => {
          if (cell.row.original.id == editedRowId) {
            cell.row.original.taux = String(tariffValue).replace(',', '.');
          }

          return <EditableCell cell={cell} inputValue={tariffValue} />;
        }
      },
      {
        Header: <Head text={t('_actions')}></Head>,
        accessor: 'id',
        width: isDesktop ? 30 : 60,
        maxWidth: isDesktop ? 30 : 60,
        Cell: (cell) => (
          <div className="row-align-right">
            <FontAwesomeIcon
              icon={faEdit}
              onClick={() => {
                setEditRow(cell.row.original);
                setEditedRowId(Number(cell.row.original?.id));
                setTariffValue(parseFloat(cell.row.original.taux));

              }}
            />
            <FontAwesomeIcon
              icon={faTrash}
              onClick={() => {
                setDeleteId(Number(cell.row.original.id));
                setOpenModalDelete(true);
              }}
            />
          </div>
        ),
      },
    ];
  }

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns: columnsTable,
      data: data
    }
  );

  const handlePageClick = (page: number) => {
    setForcePage(page);
    setSearchParams({ page: String(page) });
    setParams({ ...params, ...{ page } });
  };

  return (
    <div className="price_management_header ">
      <div className="price_management_title">{t('_price_management')}</div>

      {isMobile ? (
        <div className="filter-btns">
          
          <div className="filter-link-btn">
            <button className="add-btn" onClick={() => setOpenModal(true)}>
              <FontAwesomeIcon icon={faAdd} className="icon" /> {t('_create_price_rate')}
            </button>
          </div>
        </div>
      ) : null}

      <div className={`price_management-filter ${isMobile && !isActive ? 'hidden-filter-price' : 'show-filter-price'}`}>
        <PriceManagementFilter
          setOpenModal={setOpenModal}
          setParams={setParams}
          setForcePage={setForcePage}
          setIsActive={setIsActive}
          clients={clients}
          providers={providers}
          countries={countries}
          commercialZones={commercialZones}
        />
      </div>

      <div className="table-wrapper">
        {loading ? (
          <Spinner />
        ) : (
          data.length ? (
            <table {...getTableProps()}>
              <thead className="thead">
                {headerGroups.map((headerGroup, key) => (
                  <tr {...headerGroup.getHeaderGroupProps()} key={key} className="row">
                    {headerGroup.headers.map((column, index) => (
                      <th
                        {...column.getHeaderProps({
                          style: { minWidth: column.minWidth, width: column.width, maxWidth: column.maxWidth },
                        })}
                        key={index}
                        className="heading"
                      >
                        <div className="table-title">{column.render('Header')}</div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              {loading && data.length ? (
                <tbody>
                  <tr>
                    <td colSpan={Object.keys(rows[0].original).length}>
                      <Spinner class="extra-small" />
                    </td>
                  </tr>
                </tbody>
              ) : (
                <tbody {...getTableBodyProps} className="body">
                  {!loading && data.length ? (
                    rows.map((row) => {
                      prepareRow(row);
                      const { key, ...restRowProps } = row.getRowProps();

                      return (
                        <React.Fragment key={key}>
                          <tr key={key} {...restRowProps} className="cell-row">
                            {row.cells.map((cell) => {
                              const { key, ...restCellProps } = cell.getCellProps();

                              return (
                                <td key={key} {...restCellProps} className="cell">
                                  {cell.render('Cell')}
                                </td>
                              );
                            })}
                          </tr>
                        </React.Fragment>
                      );
                    })
                  ) : null}
                </tbody>
              )}
            </table>
          ) : <div className="order-no-data">{t('_no_data')}</div>
        )}
        {pageCount > 1 && !loading ? (
          <Pagination pageCount={pageCount} forcePage={forcePage} handlePageClick={handlePageClick} hiddenParamPage />
        ) : null}
      </div>
      <Modal
        shouldCloseOnOverlayClick={false}
        openModal={openModal}
        setOpenModal={setOpenModal}
        childrenComponent={
          <CreatePriceManagement
            setOpenModal={setOpenModal}
            setSubmit={setSubmit}
            loading={loading}
            clients={clients}
            providers={providers}
            countries={countries}
            commercialZones={commercialZones}
          />
        }
      />
      <Modal
        openModal={openModalDelete}
        setOpenModal={setOpenModalDelete}
        childrenComponent={
          <ModalDelete
            setOpenModalDelete={setOpenModalDelete}
            setSubmit={setSubmit}
            id={deleteId}
            link={http.priceMatrix.deletePriceMatrix}
          />
        }
      />
    </div>
  );
};

export default PriceManagement;

