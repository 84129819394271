import { HttpResponse } from 'utils/hooks/http/history.http';
import { tecRMIAxios } from '../../http';
import { ILinkedFuseRelais, ILinkedPartPosition, IManualsListItemMps, IWiringListMainGroup } from '../interface/manuals.interface';

class WiringHttp {

  private static WiringList = '/Wiring/ManualList';
  private static LinkedFuseRelais = '/Wiring/LinkedFuseRelais';
  private static LinkedWiringDiagrams = '/Wiring/LinkedWiringDiagrams';
  private static LinkedPartPosition = '/Wiring/LinkedPartPosition';
  private static ManualHtml = '/Wiring/ManualHtml';

  public async fetchRMIWiringManualList(params: {}): Promise<HttpResponse<IWiringListMainGroup[]>> {
    return tecRMIAxios.get(WiringHttp.WiringList, { params });
  }

  public async fetchRMILinkedFuseRelais(params: {}): Promise<HttpResponse<Array<ILinkedFuseRelais>>> {
    return tecRMIAxios.get(WiringHttp.LinkedFuseRelais, { params });
  }

  public async fetchRMILinkedWiringDiagrams(params: {}): Promise<HttpResponse<IManualsListItemMps>> {
    return tecRMIAxios.get(WiringHttp.LinkedWiringDiagrams, { params });
  }

  public async fetchRMILinkedPartPosition(params: {}): Promise<HttpResponse<ILinkedPartPosition>> {
    return tecRMIAxios.get(WiringHttp.LinkedPartPosition, { params });
  }

  public async fetchRMIManualHtml(params: {}): Promise<HttpResponse<string>> {
    return tecRMIAxios.get(WiringHttp.ManualHtml, { params });
  }

}

export default WiringHttp;