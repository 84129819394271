import React, { useState } from 'react';
import { ReactComponent as UploadCsv } from 'assets/images/upload.svg';
import { useTranslation } from 'react-i18next';
import Modal from 'components/modal/Modal';
import SubmitQuotationModal from './SubmitQuotationModal/SubmitQuotationModal';

const c = 'automotor-menu-navbar';

const MenuSubmitQuotation: React.FC = () => {
  const {t} = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <React.Fragment>
      <div onClick={() => setIsOpen(true)} className={`${c}__item--parent__children__item--title`}>
        <UploadCsv />
        <span>{t('_submit_quotation')}</span>
      </div>
      <Modal
        openModal={isOpen}
        setOpenModal={setIsOpen}
        childrenComponent={<SubmitQuotationModal setOpenModal={setIsOpen} />}
      />
    </React.Fragment>
  );
};

export default MenuSubmitQuotation;
