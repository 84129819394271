import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import Modal from 'components/modal/Modal';
import Pagination from 'components/pagination/Pagination';
import Spinner from 'components/spinner/Spinner';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { IFileTypes, IMappingList, IPaginationData, ISellers } from 'utils/hooks/http/price-list.http';
import useHttp from 'utils/hooks/useHttp';
import { notify } from 'utils/marketplace';
import TariffItem from './TariffItem/TariffItem';
import UploadTariffModal from './UploadTariffModal/UploadTariffModal';
import './EspaceAutomotorTariffs.scss';
import { IOption } from 'interfaces/select';

type Props = {}

const EspaceAutomotorTariffs:React.FC<Props> = () => {
  const http = useHttp();
  const {t} = useTranslation();
  const [searchParams] = useSearchParams();
  const [priceList, setPriceList] = useState<IPaginationData>();
  const [fileTypes, setFileTypes] = useState<IFileTypes[]>([]);
  const [supplierList, setSupplierList] = useState<IMappingList[]>([]);
  const [manufacturerList, setManufacturerList] = useState<IMappingList[]>([]);
  const [sellers, setSellers] = useState<ISellers[]>([]);
  const [countries, setCountries] = useState<IOption[]>([]);
  const [commercialZones, setCommercialZones] = useState<IOption[]>([]);
  const [pagination, setPagination] = useState<IPaginationData>();
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
 

  function getTariffs(page){
    setLoading(true);
    http.priceListHttp.getPriceList({page}).then(res=> {
      setPriceList(res.data);
      setPagination(res.data);
    }).finally(()=>{
      setLoading(false);
    });
  }

  function getFileTypes(){
    http.priceListHttp.getFileTypes().then(res=> setFileTypes(res.data));
  }

  function getSupplierMappingList(){
    http.priceListHttp.getSupplierMappingList().then(res=> setSupplierList(res.data));
  }

  function getManufacturerMappingList(){
    http.priceListHttp.getManufacturerMappingList().then(res=> setManufacturerList(res.data));
  }

  function getAllSellers(){
    http.priceListHttp.getSellers().then(res => setSellers(res.data));
  }

  function getCountries(){
    http.selectClient.countries().then(res => setCountries(res.data?.map((item) => ({ value: item.id, label: item.name }))));

  }

  function getCommercialZones(){
    http.selectClient.zoneCommercials().then(res => setCommercialZones(res.data?.map((item) => ({ value: item.id, label: `${item.id} (${item.name})` }))));
  }

  async function deleteTariff(id){
    setLoading(true);
    await http.priceListHttp.deleteTariff(id).then(()=>{
      notify(t('_successfully_deleted'), 'success');
    }).finally(()=> setLoading(false));
    getTariffs(searchParams.get('page') || 1);
  }

  const handlePageClick = (page) => {
    if(pagination){
      const paginationCopy = pagination;

      paginationCopy.page = page;
      setPagination(paginationCopy);
      getTariffs(page);
    }
  };


  useEffect(()=>{
    getTariffs(+searchParams.get('page')! || 1);
    getFileTypes();
    getSupplierMappingList();
    getManufacturerMappingList();
    getAllSellers();
    getCommercialZones();
    getCountries();
  },[]);

  return (
    <div className="espace-automotor-tariffs">
      <div className="price-list-container">
        <div className="price-list-header">
          <div className="price-list-title">{t('_price_list')}</div>
          <div className="add-new-tariff-btn">
            <DefaultButton text={t('_add_new_tariff')} onClick={() => setOpenModal(true)}/>
          </div>
        </div>
        {loading ? <Spinner class="small tariffs-spinner" /> : 
          <div className="tariff-items">
            {priceList?.data.length ? priceList?.data?.map((tariff, index) => {
              return <TariffItem deleteTariff={deleteTariff} data={tariff} key={index} />;
            }) : <div className="no-data">{t('_no_data')}</div>
            }
            {pagination && <Pagination pageCount={pagination.total_pages} handlePageClick={handlePageClick!} forcePage={pagination.page!} />}
          </div>
        }
        <Modal
          openModal={openModal}
          setOpenModal={setOpenModal}
          shouldCloseOnOverlayClick
          childrenComponent={
            <UploadTariffModal
              fileTypes={fileTypes}
              manufacturers={manufacturerList}
              suppliers={supplierList}
              clients={sellers}
              countries={countries}
              commercialZones={commercialZones}
              getTariffs={getTariffs}
              setOpenModal={setOpenModal}
            />
          }
        />
      </div>
      
      
    </div>
  );
};

export default EspaceAutomotorTariffs;