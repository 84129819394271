import { IClient } from 'models/client.model';
import React, { useMemo } from 'react';
import { Column, useTable } from 'react-table';
import Head from '../Cells/Head/Head';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';
import './MyClientsTable.scss';
import { useTranslation } from 'react-i18next';

type Props = {
  clients: IClient[];
  handleClientClick: (key: React.Key) => void;
};

const MyClientTableView: React.FC<Props> = (props: Props) => {
  const data = useMemo((): IClient[] => props.clients, [props.clients]);
  const { t } = useTranslation();

  const columns = useMemo(
    (): Column<IClient>[] => [
      {
        Header: <Head text={t('_client_id')}></Head>,
        accessor: 'client_id',
      },
      {
        Header: <Head text={t('_client_erp_id')}></Head>,
        accessor: 'client_erp_id',
      },

      {
        Header: <Head text={t('_name')}></Head>,
        accessor: 'name',
      },
      // {
      //   Header: <Head text={t('_city')}></Head>,
      //   accessor: 'zone_commercial',
      // },
      {
        Header: <Head text={t('_active')}></Head>,
        accessor: 'is_active',
        Cell: (cell) => {
          return (
            <div className="email">
              {cell.row.values.is_active ? (
                <FontAwesomeIcon className="relicats-green" icon={faCheck} />
              ) : (
                <FontAwesomeIcon className="relicats-red" icon={faXmark} />
              )}
            </div>
          );
        },
      },
    ],
    [],
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data });

  return (
    <div>
      <table className="wrapper-clients-table" {...getTableProps()}>
        <thead className="headings">
          {headerGroups.map((headerGroup, index) => {
            return (
              <tr key={headerGroup.headers[index].id} className="row">
                {headerGroup.headers.map((column) => {
                  const { key, ...restColumn } = column.getHeaderProps();

                  return (
                    <th key={key} {...restColumn} className="heading">
                      {column.render('Header')}
                    </th>
                  );
                })}
              </tr>
            );
          })}
        </thead>
        <tbody {...getTableBodyProps} className="body">
          {rows.length ? (
            rows.map((row) => {
              prepareRow(row);
              const { key, ...restRowProps } = row.getRowProps();

              return (
                <tr key={key} {...restRowProps} className="row" onClick={() => props.handleClientClick(key)}>
                  {row.cells.map((cell) => {
                    const { key, ...restCellProps } = cell.getCellProps();

                    return (
                      <td key={key} {...restCellProps} className="cell">
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={20} className="notFond">{`${t('_no_data')}`}</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

MyClientTableView.displayName = 'MyClientTableView';

export default MyClientTableView;
