import React from 'react';
import {NavLink} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IErrorCodesNavLinks} from '../errorCodes';
import useReliableCatalogCarQueryParams from 'utils/hooks/useReliableCatalogCarQueryParams';
import {SwiperSlide} from 'swiper/react';
import SwiperSlider from 'components/SwiperSlider/SwiperSlider';
import './ErrorCodesNavLinksMobile.scss';

type Props = {
  navLinks?: IErrorCodesNavLinks[];
}

const ErrorCodesNavLinksMobileView:React.FC<Props> = (props) => {
  const reliableCatalogCarQueryParams = useReliableCatalogCarQueryParams();

  const getActiveSlideFromDom = () => {
    const activeNavItem = document.querySelector('.error-codes-navbar-links .swiper-container.items .navbar-link .active');
    const activeIndex = activeNavItem?.parentElement?.parentElement?.getAttribute('data-swiper-slide-index');

    return Number(activeIndex);
  };

  return (
    <div className="error-codes-navbar-links">
      <div className="navbar-links">
        <SwiperSlider
          slidesPerView="auto"
          spaceBetween={0}
          slideToIndex="auto"
          loop
          getActiveSlideFromDom={getActiveSlideFromDom}
        >
          {
            props.navLinks?.map((item, index) => {

              return (
                <SwiperSlide className="swiper-slide" key={index} >
                  <div className="navbar-link">
                    {
                      item.disabled ? (
                        <a className="disabled" href="#">
                          <FontAwesomeIcon icon={item.icon} /> {item.text}
                        </a>
                      ) : (
                        <NavLink to={`${item.link}?${(new URLSearchParams({...reliableCatalogCarQueryParams, ...item.queryParams})).toString()}`}>
                          <FontAwesomeIcon icon={item.icon} /> {item.text}
                        </NavLink>
                      )
                    }
                  </div>
                </SwiperSlide>
              );
            })
          }
        </SwiperSlider>
      </div>
    </div>
  );
};

ErrorCodesNavLinksMobileView.displayName = 'ErrorCodesNavLinksMobileView';

export default ErrorCodesNavLinksMobileView;
