import React, { ReactNode, ReactElement } from 'react';
import './TabStyle.scss';

type Props = {
  title: string | ReactElement;
  children?: ReactNode;
};

const Tab: React.FC<Props> = (props) => {
  return <div>{props.children}</div>;
};

Tab.displayName = 'Tab';

export default Tab;
