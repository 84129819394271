import React, { useEffect, useState } from 'react';
import './TechnicalDocumentationPage.scss';
import List from './Sidebar/List';
import { RELIABLE_CONTENT_TABS } from 'utils/rmi/reliableTabs';

import { createSearchParams, useOutletContext } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getCurrentCountryRMI } from 'utils/rmi/rmi-utils/rmi-utils';
import i18n from 'i18n';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import useHttp from 'utils/hooks/useHttp';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import useReliableCatalogCarQueryParams from 'utils/hooks/useReliableCatalogCarQueryParams';


type Props = {};

const TechnicalDocumentationPage: React.FC<Props> = () => {
  const [hasTab] = useOutletContext<Array<{ [key: string]: boolean }>>();
  const { typeId } = useSelector((state: RootState) => state.tecrmi);
  const http = useHttp();
  const [hasServiceProposal, setHasServiceProposal] = useState(true);
  const { t } = useTranslation();
  const { isMobile } = useDeviceDetect();
  const carParams = useReliableCatalogCarQueryParams();

  function serviceProposal() {
    if (typeId) {
      http.maintenanceHttp.hasServiceProposal({
        typeId,
        countryCode: getCurrentCountryRMI(i18n.language)
      }).then(res => setHasServiceProposal(res.data));
    }
  }

  function itemLink(item) {
    const queryParams = createSearchParams({ ...carParams, source: 'T', vehicle_identifier: '002', level: '1' });


    if (item.tab_name === 'maintenance') {
      if (hasServiceProposal) {
        return `maintenance/suggested?${queryParams}`;
      } else {
        return `maintenance/repair?${queryParams}`;
      }
    }

    return `${item.tab_name}?${queryParams}`;
  }

  useEffect(() => {
    serviceProposal();
  }, [typeId]);

  return (
    <div className="technical-documentations-page">
      <div className="technical-documentations-sidebar">
        <List
          content={RELIABLE_CONTENT_TABS}
          className="sidebar-title"
          itemClassName="sidebar-list-item"
          itemLink={itemLink}
          hasTab={hasTab}
        ></List>
      </div>
      {!isMobile ? <div className="technical-documentations-dashboard">
        <div className="dashboard-title">{t('_build_dashboard')}</div>
        <div className="dashboard-first-row">
          <div className="dashboard-first-row-item"></div>
          <div className="dashboard-first-row-item"></div>
          <div className="dashboard-first-row-item"></div>
        </div>
        <div className="dashboard-second-row">
          <div className="dashboard-second-row-item"></div>
          <div className="dashboard-second-row-item"></div>
        </div>
      </div> : null}
    </div>
  );
};

export default TechnicalDocumentationPage;
