import {IHttpResponse, serverAxios} from '../../http';
import {IAddToFavorite, IFavoriteCheckedList, IFavoriteGetItems, IFavoriteList} from '../../../models/favorite';

class FavoriteHttp {
  private static getFavoriteListUrl = '/favorite/category';
  private static getFavoriteCheckedListUrl = '/favorite/categories/ids';
  private static postFavoriteListUrl = '/favorite/category';
  private static deleteFavoriteListUrl = '/favorite/category/{id}';
  private static patchFavoriteListUrl = '/favorite/category/{id}';
  private static favoriteUrl = '/favorite';
  private static deleteFavoriteUrlItems = '/favorite/category/{id}/parts';

  public async getFavoriteLists(): Promise<IFavoriteList[]> {
    const { data } = await serverAxios.get(FavoriteHttp.getFavoriteListUrl);

    return data;
  }

  public async getFavoriteCheckedList(addToFavoriteParams: IFavoriteCheckedList): Promise<IFavoriteList[]> {
    const { data } = await serverAxios.post(FavoriteHttp.getFavoriteCheckedListUrl, {...addToFavoriteParams});

    return data;
  }

  public async postFavoriteList(name: string): Promise<IFavoriteList> {
    const { data } = await serverAxios.post(FavoriteHttp.postFavoriteListUrl, {name});

    return data;
  }

  public async patchFavoriteList(name: string, favorite_category_id: number): Promise<IFavoriteList> {
    const url = FavoriteHttp.patchFavoriteListUrl.replace('{id}', String(favorite_category_id));

    const { data } = await serverAxios.patch(url, {name});

    return data;
  }

  public async getFavoriteListItems( favorite_category_id: string, page: string, per_page: string): Promise<IFavoriteGetItems> {
    const { data } = await serverAxios.get(FavoriteHttp.favoriteUrl, {params: {favorite_category_id, page, per_page}});

    return data;

  }

  public async addToFavoriteList(addToFavoriteParams: IAddToFavorite): Promise<IHttpResponse<null>> {
    return serverAxios.post(FavoriteHttp.favoriteUrl, {...addToFavoriteParams});

  }

  public async updateFromFavoriteList(addToFavoriteParams: IAddToFavorite): Promise<IHttpResponse<null>> {
    return serverAxios.patch(FavoriteHttp.favoriteUrl, {...addToFavoriteParams});

  }

  public async deleteFromFavoriteList(addToFavoriteParams: IAddToFavorite): Promise<IHttpResponse<null>> {
    return serverAxios.delete(FavoriteHttp.favoriteUrl, {data: {...addToFavoriteParams}});

  }

  public async deleteFromList(favorite_category_id: string): Promise<IHttpResponse<null>> {
    const url = FavoriteHttp.deleteFavoriteListUrl.replace('{id}', favorite_category_id);

    return serverAxios.delete(url);

  }


  public async deleteFavoriteListItems(addToFavoriteParams: IAddToFavorite[], favorite_category_id: string): Promise<IHttpResponse<null>> {
    const url = FavoriteHttp.deleteFavoriteUrlItems.replace('{id}', favorite_category_id);

    return serverAxios.delete(url, {data: {...addToFavoriteParams}});

  }
}

export default FavoriteHttp;
