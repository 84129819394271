import React, {ChangeEventHandler, InputHTMLAttributes, KeyboardEventHandler, PropsWithChildren, useState} from 'react';
import TextInputView from './TextInput.view';
import {ICustomValidation} from '../../../utils/hooks/http/search-by-reference.http';
import {useValidationMessages} from '../../../utils/hooks/useValidationMessages';


type Props = {
  placeholder?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onBlur?: ChangeEventHandler<HTMLInputElement>;
  name?: string;
  value?: string;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
  withIcon?: boolean;
  onClick?: () => void;
  clearable?: boolean;
  onClickReset?: () => void;
  register?
  type?: string;
  defaultValue?: string | number;
  disabled?
  readOnly?: boolean;
  validationError?: boolean;
  whiteColor?: boolean,
  customValidations?: ICustomValidation[]
  setIsOpen?: (isOpen: boolean) => void
  pattern?: string
  autoComplete?: string;
  min?: number | string;
  step?: number | string;
};

const TextInput = React.forwardRef<HTMLInputElement, Props>((props: PropsWithChildren<Props>, ref) => {
  const {checkCustomValidations, customValidationMessages} = useValidationMessages();


  function onChangeHandler(event) {
    if (props.onChange) {
      const customValidationMessages =  checkCustomValidations(event.target.value, props.customValidations);

      if (customValidationMessages.length) {
        if(props.setIsOpen) {
          props.setIsOpen(false);
        }

        return;
      }

      props.onChange(event);
    }
  }

  function onClickHandler(event) {
    event.preventDefault();

    if (props.onClick) {
      const customValidationMessages = checkCustomValidations(event.target.value, props.customValidations);

      if (customValidationMessages.length) {
        if(props.setIsOpen) {
          props.setIsOpen(false);
        
        }

        return;
      }

      props.onClick();
    }
  }

  function keyDownHandler(event) {
    if (event.key === 'Enter') {
      event.preventDefault();

      props.onClick && props.onClick();
    }
  }

  const newProps = {...props};

  delete newProps.onChange;
  delete newProps.onClick;
  delete newProps.setIsOpen;

  return <TextInputView autoComplete={props.autoComplete} customValidationMessages={customValidationMessages} onChange={onChangeHandler} onClick={onClickHandler} onKeyDown={keyDownHandler} ref={ref} {...newProps}></TextInputView>;
});

TextInput.displayName = 'TextInput';

export default TextInput;
