import {  faPen, faRemove } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Spinner from 'components/spinner/Spinner';
import { ICategory } from 'interfaces/consumableCategory';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { getMarketplaceBaseUrl } from 'utils/marketplace';
import './ItemEshopConsumableCategory.scss';

type Props = {
  data:ICategory[],
  loading:boolean,
  openModalConsumableCategory:(id:number)=>void;
  deleteItem:(id:number)=>void
};

const ItemEshopConsumableCategoryView: React.FC<Props> = (props) => {

  const {t} = useTranslation();

  return (
    <div className="item-container-shop-consumable-category">
      {
        props.loading ? <Spinner /> :

          props.data.length ?
            props.data.map((item, index) => {
              return (
                <div className="item-shop" key={index}>
   
                  <div className="header">
                
                    <div className="content">
                      <div className="title">{item.name}</div>
                    </div>
                  </div>
                  <div className="body">
                    <div className="img">
                      <img src={`${getMarketplaceBaseUrl()}${item.image}`}  />
                    </div>
                  </div>
                  <div className="footer">
                    <div className="cart">
                      <div className="cart-button">
                        <button className="button-edit" onClick={()=>props.openModalConsumableCategory(item.id )}>
                          <FontAwesomeIcon icon={faPen} className="icon-edit"  />
                        </button>
                        <button className="button-delete" onClick={()=>props.deleteItem(item.id )}>
                          <FontAwesomeIcon icon={faRemove} className="icon-delete"  />
                        </button>
            
                      </div>
                    </div>
                  </div>
           
                </div>
              );
            }) : 
            <div className="no-data"> 
              {t('_no_data')}
            </div>
        
      }
    </div>
  );
};

ItemEshopConsumableCategoryView.displayName = 'ItemEshopConsumableCategoryView';

export default ItemEshopConsumableCategoryView;
