import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Spinner from 'components/spinner/Spinner';
import { IOrderItem } from 'models/order.model';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Column, useTable } from 'react-table';
import useHttp from 'utils/hooks/useHttp';
import './OrderTable.scss';

type Props = {
  hiddenColumns,
  setSupplierName: (supplierName: string) => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
};

const OrderTable: React.FC<Props> = (props) => {
  const [data, setData] = useState<IOrderItem[]>([]);
  const { order_id } = useParams();
  const http = useHttp();
  const { t } = useTranslation();

  const fetchData = () => {
    props.setLoading(true);
    http.order.getOrderItems(order_id!).then(res => setData(res.data.data)).finally(() => {
      props.setLoading(false);
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (data.length) {
      props.setSupplierName(data[0].data_supplier.name);
    }
  }, [data]);

  const columns = useMemo(
    (): Column<IOrderItem>[] => [
      {
        Header: '',
        accessor: 'images',
        Cell: (rows) => rows.cell.value ? <img src={rows.cell.value[0]} width={40} height={35}></img> : <img src={require('../../../assets/images/placeholder.png')}></img>,
        width: 60
      },
      {
        Header: t('_reference').toLocaleUpperCase(),
        accessor: (rows) => rows.data_supplier.name + ' ' + rows.data_supplier_reference,
        Cell: (rows) => <div className="order-table-ref-cell">{rows.cell.value}</div>

      },
      {
        Header: t('_quantity').toLocaleUpperCase(),
        accessor: 'order_quantity',

      },
      {
        Header: t('_unit').toLocaleUpperCase(),
        accessor: 'order_unit_price',

      },
      {
        Header: t('_price').toLocaleUpperCase(),
        accessor: 'order_total_price',
      },
      {
        Header: t('_leftovers').toLocaleUpperCase(),
        accessor: 'name',
        Cell: <div className="order-view-relicats">0 <FontAwesomeIcon className="relicats-red" icon={faCheck} /></div>
      },

    ],
    [],
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
    initialState: props.hiddenColumns
  });


  return (
    <div className={`order-table-wrapper ${!rows.length && !props.loading ? 'centered' : ''}`} >
      {props.loading ? <Spinner class="order-table-spinner" /> : (
        <React.Fragment>
          {!rows.length ? <div className="no-data">{t('_no_data').toLocaleUpperCase()}</div> : (
            <table {...getTableProps()}>
              <thead className="headings">
                {headerGroups.map((headerGroup, index) => {
                  return (
                    <tr key={headerGroup.headers[index].id} className="row">
                      {headerGroup.headers.map((column) => {
                        const { key, ...restColumn } = column.getHeaderProps({
                          style: { minWidth: column.minWidth, width: column.width, maxWidth: column.maxWidth },
                        });

                        return (
                          <th key={key} {...restColumn} className="heading">
                            {column.render('Header')}
                          </th>
                        );
                      })}
                    </tr>
                  );
                })}
              </thead>
              <tbody {...getTableBodyProps} className="body">
                {rows.map((row) => {
                  prepareRow(row);
                  const { key, ...restRowProps } = row.getRowProps();

                  return (
                    <tr key={key} {...restRowProps} className="row">
                      {row.cells.map((cell) => {
                        const { key, ...restCellProps } = cell.getCellProps();

                        return (
                          <td key={key} {...restCellProps} className="cell">
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
          { }
        </React.Fragment>
      )}
    </div>
  );
};

OrderTable.displayName = 'OrderTable';

export default OrderTable;
