import { GetCartResponse } from 'models/cart.model';
import React, { useEffect, useMemo, useState } from 'react';
import { Column, useExpanded, useTable } from 'react-table';
import useHttp from 'utils/hooks/useHttp';
import { getMarketplaceBaseUrl, notify } from 'utils/marketplace';
import { CartTableItem } from './CartTable.model';
import CartTableView from './CartTable.view';
import CategoriesCell from './Cells/CategoriesCell/CategoriesCell';
import CodeCell from './Cells/CodeCell/CodeCell';
import DeleteCell from './Cells/DeleteCell/DeleteCell';
import DistributorCell from './Cells/DistributorCell/DistributorCell';
import PriceCell from './Cells/PriceCell/PriceCell';
import ReferenceCell from './Cells/ReferenceCell/ReferenceCell';
import QuantityAvailability from './Cells/QuantityAvailabilityCell/QuantityAvailability';
import { useTranslation } from 'react-i18next';
import ExpandButton
  from '../../../components/tables/TableMoreInformation/ExpandButton/ExpandButton';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';

type Props = {
  getSuppliers?: () => void;
};

const CartTable: React.FC<Props> = (props: Props) => {
  const [dataState, setDataState] = useState<CartTableItem[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const http = useHttp();
  const { isMobile, isTablet } = useDeviceDetect();

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await http.cart.getCartData().finally(() => {
        setLoading(false);
      });

      const data = response.data.map((item: GetCartResponse): CartTableItem => {
        return {
          id: item.cart_id,
          // availability: typeof(item.available) === 'number' ? item.available : item.available.quantity,
          barCode: { image: `${getMarketplaceBaseUrl()}/${item?.barcode?.image}`, code: item?.barcode?.code },
          categories: item.subcategory?.name,
          distributor: item.data_supplier?.name,
          quantity_availability: { quantity: item.cart_quantity || 0, availability: typeof (item.available) === 'number' ? item.available : item.available.quantity },
          price: item?.price?.price || 0,
          reference: item.data_supplier_reference,
        };
      });

      setDataState(() => data);
    } catch (error) {
      notify('Internal Server Error');
      setDataState(() => []);
    }
  };


  const columns = useMemo(
    (): Column<CartTableItem>[] => [
      {
        id: 'expander',
        // eslint-disable-next-line react/destructuring-assignment
        Header: '',
        width: 15,
        // eslint-disable-next-line react/destructuring-assignment
        Cell: ({ row }) => {
          return (
            //Applying the toggle expander props i.e onClick, style and title
            <ExpandButton row={row} />
          );
        }
      },
      {
        Header: t('_distributer'),
        accessor: 'distributor',
        Cell: DistributorCell,
        maxWidth: 200,
      },
      {
        Header: t('_reference'),
        accessor: 'reference',
        Cell: ReferenceCell,
        maxWidth: 200,
      },
      {
        Header: t('_category'),
        accessor: 'categories',
        Cell: CategoriesCell,
        width: 250,
      },
      {
        Header: t('_quantity_availability'),
        accessor: 'quantity_availability',
        Cell: (props) => <QuantityAvailability availabilityUnspecified {...props} refetch={fetchData} setLoading={setLoading}></QuantityAvailability>,
        maxWidth: 200,
      },
      {
        Header: t('_price'),
        accessor: 'price',
        Cell: (props) => <PriceCell {...props} unspecified></PriceCell>,
        maxWidth: 100
      },
      {
        Header: t('_barcode'),
        accessor: 'barCode',
        Cell: CodeCell,
        maxWidth: 100,
      },
      {
        Header: t('_delete').toString(),
        Cell: (newProps) => <DeleteCell {...newProps} refetch={props.getSuppliers} setLoading={setLoading}></DeleteCell>,
        maxWidth: 50,
      },
    ],
    [],
  );

  if (!isMobile && !isTablet) {
    columns.shift();
  }

  let hiddenColumns = ['price'];

  if (isTablet) {
    hiddenColumns = ['id', 'distributor', 'categories'];
  }

  if (isMobile) {
    hiddenColumns = ['id', 'distributor', 'categories', 'barCode'];
  }

  const initialState = hiddenColumns ? {
    'hiddenColumns': hiddenColumns
  } : {};


  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data: dataState,
    initialState
  }, useExpanded
  );

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <CartTableView
      hiddenColumns={hiddenColumns}
      getTableBodyProps={getTableBodyProps}
      getTableProps={getTableProps}
      headerGroups={headerGroups}
      prepareRow={prepareRow}
      rows={rows}
      loading={loading}
    ></CartTableView>
  );
};

CartTable.displayName = 'CartTable';

export default CartTable;
