import React from 'react';
import MyEshopView from './MyEshopView.view';

type Props = {};

const MyEshop: React.FC<Props> = () => {
  return (
    <div className="container">
      <MyEshopView />
    </div>
  );
};

MyEshop.displayName = 'MyEshop';

export default MyEshop;
