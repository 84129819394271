import React, { useEffect, useState } from 'react';
import BarCode from './BarCode/BarCode';
import { IAddToCartParams, ITeccomDisponibility } from '../../../models/car-parts.model';
import IconButton from '../../buttons/IconButton/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping } from '@fortawesome/free-solid-svg-icons';
import Disponibility from '../../disponibility/Disponibility';
import QuantityInput from '../../inputs/QuantityInput/QuantityInput';
import { ReactComponent as TeccomIcon } from '../../../assets/Icons/teccom-logo.svg';
import CarPartPrice from '../../CarPartPrice/CarPartPrice';
import { currencies } from 'utils/enums/marketplace';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import Spinner from '../../spinner/Spinner';
import useHttp from 'utils/hooks/useHttp';

import FavoriteButton from './FavoriteButton/FavoriteButton';
import { useTranslation } from 'react-i18next';
import { CATALOG_REL, Marketplaces } from 'utils/constants/applications';
import { addToCartAction } from 'store/actions/cart.actions';
import { setQuantityToolbar } from 'store/slices/cart.slice';
import { useAppDispatch } from 'utils/hooks/redux';
import { getCurrentMarketplace, notify } from 'utils/marketplace';
import useTeccomButtonDisponibility from 'utils/hooks/use-teccom-button-disponibility';
import { useSearchParams } from 'react-router-dom';
import CirrusDisponibility from 'components/CirrusDisponibility/CirrusDisponibility';
import './PartItemOptions.scss';
import { isRetailerMecadepotChild } from 'utils/isRetailerMecadepotChild';

type Props = {
  carPart?;
  item?;
  paramsModal?;
};

const PartItemOptions: React.FC<Props> = (props) => {
  const carPart = props.carPart;
  const [quantityValue, setQuantityValue] = useState<number>(Number(carPart.available.uv) || 1);
  const [showQuantityAndCart, setShowQuantityAndCart] = useState<boolean>(false);
  const { addToCartLoading } = useSelector((state: RootState) => state.cart);
  const [teccomData, setTeccomData] = useState<ITeccomDisponibility>();
  const useBtnDisponibility = useTeccomButtonDisponibility();
  const [loading, setLoading] = useState(false);
  const [, setaddToCartButtonLoading] = useState(false);
  const [disableCartBtn, setDisableCartBtn] = useState(false);
  const [contactLoading, setContactLoading] = useState(false);
  const http = useHttp();
  const { user } = useSelector((state: RootState) => state.userData);
  const { quantityToolbar } = useSelector((state: RootState) => state.cart);
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const marketplace = getCurrentMarketplace();
  const isMecadepotChild = isRetailerMecadepotChild();

  useEffect(() => {
    checkTeccomData();
    displayCartBlock();
  }, []);

  function displayCartBlock() {
    setShowQuantityAndCart(![Marketplaces.PUBLIC, Marketplaces.PUBLIC_TR, Marketplaces.AMERIGO].includes(marketplace)
      || (marketplace == Marketplaces.AMERIGO && searchParams.get('is_platform_admin') !== 'true'));
  }


  function calcLimit() {
    if (typeof carPart.available === 'object' && 'quantity' in carPart.available) {
      return carPart.available['quantity'];
    }

    if (carPart.available && typeof carPart.available === 'object') {
      const newLimit: { uv?: string; warehouse_main } = { ...carPart.available };

      delete newLimit.uv;

      return Object.values(newLimit).reduce((acc, item) => Number(acc) + Number(item?.quantity), 0);
    }

    return carPart.available;
  }

  const handleAddToCart = () => {
    if (addToCartLoading || !quantityValue || !carPart.add_to_cart) {
      return;
    }

    setaddToCartButtonLoading(true);

    const params: IAddToCartParams[] = [];

    let param: IAddToCartParams = {
      cod_fou: props.carPart?.cod_fou,
      is_oe_part: false,
      price: props.carPart?.price?.price ? props.carPart?.price?.price : props.carPart?.price,
      quantity: quantityValue,
      supplier_id: props.carPart?.data_supplier.id,
      supplier_reference: props.carPart?.data_supplier_reference,
    };

    if (props.paramsModal) {
      param = { ...param, ...{ catalog_id: props.paramsModal?.catalog_id ? props.paramsModal?.catalog_id : CATALOG_REL } };
    } else {
      param = { ...param, ...{ catalog_id: searchParams.get('catalog_id') ? String(searchParams.get('catalog_id')) : CATALOG_REL } };

    }

    if (localStorage.getItem('external_cart_id')) {
      param = {
        ...param,
        ...{ external_cart_id: localStorage.getItem('external_cart_id') },
      };
    }

    if (props.carPart.catalog_id === 'CNT') {
      param = {
        non_tecdoc_reference: props.carPart.data_supplier_reference_original,
        supplier_id: props.carPart?.data_supplier.id,
        price: props.carPart?.promotion_price
          ? props.carPart.promotion_price
          : props.carPart.price?.price
            ? props.carPart.price?.price
            : null,
        quantity: quantityValue,
        is_oe_part: false,
      };
    }

    params.push(param);

    dispatch(addToCartAction(params))
      .then(() => {
        dispatch(setQuantityToolbar(+quantityValue + +quantityToolbar));
      })
      .finally(() => {
        setaddToCartButtonLoading(false);
      });
  };

  function getPrice() {
    if (props.carPart.price?.price) {
      return props.carPart.price.price;
    }

    return props.carPart.price;
  }

  function checkTeccomData() {
    if (loading) {
      return;
    }

    if (useBtnDisponibility && carPart.add_to_cart) {
      setLoading(true);
      const params = {
        dlnr: carPart.data_supplier.id,
        reference: carPart.data_supplier_reference_original,
        supplier_cod_fou: carPart.cod_fou,
      };

      http.carParts
        .getTeccomDisponibility(params)
        .then((res) => setTeccomData(res[0]))
        .finally(() => {
          setLoading(false);
        });
    }
  }

  function getAvailablityTitle() {
    if (props.carPart?.available?.warehouse_main) {
      const warehouses = { ...props.carPart.available };

      let totalQuantity = 0;
      let deliveryTime = 0;

      Object.keys(warehouses).forEach((key) => {
        if (key !== 'uv' && warehouses.hasOwnProperty!(key)) {
          totalQuantity += Number(warehouses[key].quantity);

          if (warehouses[key].warehouse_delivery_time) {
            deliveryTime += Number(warehouses[key].warehouse_delivery_time);
          }
        }
      });

      if (quantityValue <= Number(warehouses.warehouse_main?.quantity)) {
        return 'Disponible:';
      }

      if (quantityValue > Number(warehouses.warehouse_main?.quantity) && quantityValue <= totalQuantity) {
        return `Disponible dans un délai de ${deliveryTime} jours:`;
      }

      if (!getPrice()) {
        return '';
      }

      return '';
    }

    if (props.carPart.available?.quantity) {
      if (props.carPart.available.quantity >= quantityValue) {
        return 'Disponible: ';
      }
    }
  }

  function cirrusContactUs() {
    setContactLoading(true);
    const params = {
      source: props.carPart?.source,
      reference: props.carPart?.data_supplier_reference_original,
      supplier_id: props.carPart?.data_supplier?.id,
      product_type: props.carPart?.catalog_id === 'CNT' ? 'non_tecdoc_parts' : 'part'
    };

    http.cirrus
      .contactUs(params)
      .then(() => notify(t('_distributor_contact_soon'), 'success'))
      .catch((err) => notify(err.message))
      .finally(() => setContactLoading(false));
  }

  function disableCart() {
    if (disableCartBtn) {
      return true;
    }

    if (Number(calcLimit()) < quantityValue && !props.carPart.add_to_cart) {
      return true;
    }

    if (addToCartLoading || !quantityValue) {
      return true;
    }

    // if (Number(calcLimit()) === 0 && !getPrice()) {
    //   return true;
    // }

    if (Number(calcLimit()) < quantityValue && props.carPart.add_to_cart) {
      return false;
    }
  }

  function contactUsBtn() {
    if (props.carPart.available && typeof props.carPart.available.warehouse_main === 'object') {
      if (
        (props.carPart.available?.warehouse_main.quantity === 0 && props.carPart.available?.warehouse_all.quantity > 0) ||
        (props.carPart.available?.warehouse_main.quantity === 0 && props.carPart.available?.warehouse_all.quantity === 0) ||
        props.carPart.available?.warehouse_main.quantity === 0
      ) {
        return (
          <div className="cirrus-contact-us" onClick={cirrusContactUs}>
            {contactLoading ? <Spinner class="extra-small"></Spinner> : t('_contact_us')}
          </div>
        );
      }

      return '';
    }

    return (
      <div className="cirrus-contact-us" onClick={cirrusContactUs}>
        {contactLoading ? <Spinner class="extra-small"></Spinner> : t('_contact_us')}
      </div>
    );
  }

  useEffect(() => {
    if (!calcLimit() && isMecadepotChild) {
      setDisableCartBtn(true);
    }
  }, [props.carPart]);

  return (
    <div className="car-part-options-wrapper">
      <div className="top">
        <BarCode code={carPart.barcode?.code} image={carPart.barcode?.image} />
        {marketplace !== Marketplaces.PUBLIC && marketplace !== Marketplaces.PUBLIC_TR && carPart.catalog_id != 'CNT' && (
          <FavoriteButton text={t('_favorite')} carPart={carPart} />
        )}
      </div>

      {useBtnDisponibility && carPart.add_to_cart ? (
        <div onClick={checkTeccomData} className="teccom">
          <TeccomIcon className="teccom-icon" />
        </div>
      ) : null}
      {showQuantityAndCart && (
        <div className="bottom">
          {marketplace == Marketplaces.PUNCHOUT && carPart?.price ?
            <div className="cirrus-prices">
              <div className="cirrus-price">
                <div className="cirrus-price-label">{t('_public_rate')}:</div>
                <div className="cirrus-price-value">{carPart?.price?.initial_price ? `€${Number(carPart?.price?.initial_price).toFixed(2)}` : '-'}</div>
              </div>
              <div className="price-separator" />
              <div className="cirrus-price">
                <div className="cirrus-price-label">{t('_your_rate')}:</div>
                <div className="cirrus-price-value">{carPart?.price?.price_simple ? `€${carPart?.price?.price_simple}` : '-'} </div>
              </div>
              <div className="price-separator" />
              <div className="cirrus-price">
                <div className="cirrus-price-label">{t('_discount')}:</div>
                <div className="cirrus-price-value">{(carPart?.price?.discount && carPart?.price?.discount != 0) ? `${carPart?.price?.discount}%` : '-'} </div>
              </div>
            </div>
            : null}
          <div className="availability">
            {marketplace == Marketplaces.RETAILER || marketplace == Marketplaces.PUNCHOUT ? (
              calcLimit() ? (
                carPart.add_to_cart && (
                  <QuantityInput uv={Number(carPart?.available?.uv)} setValue={setQuantityValue} value={quantityValue} />
                )
              ) : !calcLimit() && carPart.add_to_cart ? (
                <QuantityInput setValue={setQuantityValue} value={quantityValue} uv={carPart.available.uv} />
              ) : null
            ) : (
              carPart.add_to_cart && <QuantityInput setValue={setQuantityValue} value={quantityValue} uv={carPart.available.uv} />
            )}

            {marketplace === Marketplaces.RETAILER || marketplace === Marketplaces.PUNCHOUT ? (
              <React.Fragment>
                {user?.numcli === Number(process.env.REACT_APP_CIRRUS_NUMCLI) ? (
                  <CirrusDisponibility setDisableCartBtn={setDisableCartBtn} loading={loading} value={quantityValue} data={props.carPart} />
                ) : (
                  <Disponibility
                    loading={loading}
                    value={quantityValue}
                    limit={props.carPart?.available}
                    title={getAvailablityTitle()}
                    price={props.carPart.price}
                  />
                )}
                <CarPartPrice
                  loading={loading}
                  priceVAT={Number(props.carPart?.price?.price_with_vat)}
                  quantityValue={quantityValue}
                  currency={currencies.EUR}
                  price={Number(props.carPart?.price?.price)}
                />
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Disponibility
                  loading={loading}
                  value={quantityValue}
                  limit={useBtnDisponibility && teccomData ? teccomData?.available : props.carPart?.available}
                />
                <CarPartPrice
                  loading={loading}
                  currency={currencies.EUR}
                  quantityValue={quantityValue}
                  price={useBtnDisponibility && teccomData ? teccomData?.price : (props.carPart?.price?.price ? props.carPart?.price?.price : props.carPart?.price)}
                />
              </React.Fragment>
            )}
          </div>
          {user?.numcli === Number(process.env.REACT_APP_CIRRUS_NUMCLI) && contactUsBtn()
            ? contactUsBtn()
            : props.carPart?.add_to_cart && (
              <div className="button">
                <IconButton
                  disabled={disableCart()}
                  onClick={handleAddToCart}
                  text={t('_add_to_cart')}
                  className="iconButton"
                  icon={
                    addToCartLoading ? <Spinner class="extra-small"></Spinner> : <FontAwesomeIcon icon={faCartShopping} className="" />
                  }
                ></IconButton>
              </div>
            )}
        </div>
      )}
    </div>
  );
};

PartItemOptions.displayName = 'PartItemOptions';

export default PartItemOptions;
