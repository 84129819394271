import React, { useState } from 'react';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import TextInput from 'components/inputs/TextInput/TextInput';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import useHttp from 'utils/hooks/useHttp';
import { notify } from 'utils/marketplace';
import './MinimumOrderValueModal.scss';

type Props = {
    setOpenModal: (openModal: boolean) => void
    data;
    fetchClients: (params) => void;
}

const MinimumOrderValueModal:React.FC<Props> = (props: Props) => {
  const {t} = useTranslation();
  const http = useHttp();
  const [value, setValue] = useState(props.data.minim_order_value);
  const [loading, setLoading] = useState(false);

  function submit(){
    setLoading(true);

    const params = {
      minim_order_value: value ? +value : null,
    };

    http.providers.updateMinimumOrderValue(props.data.id, params)
      .then(() => {
        notify(t('_success'), 'success');
        props.setOpenModal(false);
        props.fetchClients({});
      })
      .catch(() => notify(t('_error')))
      .finally(() => setLoading(false));
  }

  function handleChange(e){
    const regex = /^[0-9]*\.?[0-9]*$/; 
    const value = e.target.value;

    if (regex.test(value) && +value <= 100_000_000_000) {
      setValue(value);
    }
  }

  return (
    <div className="order-value-modal">
      <div className="order-value-modal-header">
        <div className="order-value-modal-title">{t('_minimum_order_value')}</div>
        <FontAwesomeIcon className="order-value-modal-close" icon={faClose} onClick={() => props.setOpenModal(false)}/>
      </div>
      <div className="order-value-modal-body">
        <div className="order-value-input">
          <TextInput
            value={value || ''}
            onChange={handleChange}
            placeholder={t('_minimum_order_value')}
          />
        </div>
        <div className="order-value-modal-save">
          <DefaultButton isloading={loading} type="submit" text={t('_save')} onClick={submit} />
        </div>
      </div>
    </div>
  );
};

export default MinimumOrderValueModal;