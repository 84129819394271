import React from 'react';
import './NewsPage.scss';
import NewsPageView from './NewsPage.view';

const NewsPage: React.FC = () => {
  return (
    <div className="news-page-main-container">
      <NewsPageView />
    </div>
  );
};

NewsPage.displayName = 'NewsPage';

export default NewsPage;
